import { createSlice } from "@reduxjs/toolkit";

const defaultLink = `${window.location.origin}/admin/dashboard`;

const initialState = localStorage.getItem("bottomTab")
  ? JSON.parse(localStorage.getItem("bottomTab"))
  : {
    noOfTabs: 1,
    currentIndex: 0,
    bottomTabs: [{ id: 1, route: defaultLink, isNewTab: true, prevTabs: [], isPageLoaded: false, label: 'Dashboard', title: 'Dashboard' }],
    currentTab: { id: 1, route: defaultLink, isNewTab: true, prevTabs: [], isPageLoaded: false, label: 'Dashboard', title: 'Dashboard' },
    tabCount: 1,
    isAlert: false,
  };

const bottomTabSlice = createSlice({
  name: "bottomTab",
  initialState,
  reducers: {
    bottomTabSuccess: (state, action) => {
      return action.payload;
    },

    changeNoofTabs: (state, action) => {
      return {
        noOfTabs: action.payload,
        currentIndex: 0,
        bottomTabs: [{ id: 1, route: defaultLink, isNewTab: true, prevTabs: [], isPageLoaded: false, label: 'Dashboard', title: 'Dashboard' }],
        currentTab: { id: 1, route: defaultLink, isNewTab: true, prevTabs: [], isPageLoaded: false, label: 'Dashboard', title: 'Dashboard' },
        tabCount: 1,
        isAlert: false,
      };
    },

    bottomNewTab: (state, action) => {
      return {
        ...state,
        bottomTabs: action.payload.bottomTabs,
        currentIndex: action.payload.currentIndex,
        currentTab: action.payload.currentTab,
        tabCount: action.payload.tabCount,
      };
    },

    removeCurrentTab: (state, action) => {
      return {
        ...state,
        bottomTabs: action.payload.bottomTabs,
        currentTab: action.payload.currentTab,
        tabCount: action.payload.tabCount,
      };
    },

    removeTab: (state, action) => {
      return {
        ...state,
        tabCount: action.payload.tabCount,
        bottomTabs: action.payload.bottomTabs,
      };
    },

    setTabCurrent: (state, action) => {
      return {
        ...state,
        bottomTabs: action.payload.bottomTabs,
        currentIndex: action.payload.currentIndex,
        currentTab: action.payload.currentTab,
        tabCount: action.payload.tabCount,
      };
    },

    bottomTabLogoutSuccess: () => initialState,
    updateState: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  bottomTabSuccess,
  bottomTabLogoutSuccess,
  changeNoofTabs,
  bottomNewTab,
  removeCurrentTab,
  setTabCurrent,
  removeTab,
} = bottomTabSlice.actions;

export default bottomTabSlice.reducer;
