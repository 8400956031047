
export function isCheckArr(arr,value){
    var status = false;
   
    for(var i=0; i<arr.length; i++){
      var name = arr[i];
      if(name == value){
        status = true;
        break;
      }
    }
    return status;
  }

  export function numberRange(allpages,lowEnd,highEnd) {
    var list = [];
    if(lowEnd<highEnd)
      for (var i = lowEnd; i <= highEnd; i++) {
        if(!allpages.includes(i)){
          list.push(i);
        }
      }
    else if(lowEnd>highEnd){
        for (var i = highEnd; i <= lowEnd; i++) {
          if(!allpages.includes(i)){
            list.push(i);
          }
      }
    }else{
      list.push(lowEnd)
    }
    return list
  }


  export const getPositionPdf = (value)=>{
    // const rotation = i.firstChild.style.transform;

 // code here
 let position = 0;
                
//  if (rotation) {
//      if (rotation === 'rotate(90deg)') {
//        position = 90;
//       //  arr.push(position)
//      } else if (rotation === 'rotate(3.142rad)') {
//        position = 180;
//       //  arr.push(position)
//      } else if (rotation === 'rotate(-0.25turn)') {
//        position = 270;
//       }
//     }
  const isDivisibleBy90 = value % 90 === 0;
  const index = value / 90;

  if (isDivisibleBy90) {
    const remainder = index % 4;
    // alert(remainder)
    switch (remainder) {
      case 0:
        position = 0;
        break;
      // ClockWise
      case 1:
        position = 90;
        break;
      case 2:
        position = 180;
        break;
      case 3:
        position = 270;
      // Anticlockwise
      case -1:
        position = 270;
        break;
      case -2:
        position = 180;
        break;
      case -3:
        position = 90;
        break;
      default:
        break;
    }
    return position;
  
  }
}