import React, { useRef, useState } from "react";
import { Form, Modal, OverlayTrigger, Toast, Button, Tooltip } from 'react-bootstrap';
import userSample from "../../../assets/sample/user-sample.csv"

const CsvUploaderModal = ({ csvFormik, handleFileChange, setModalUpload, modalUpload }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "90%",
        },
    };
    const downloadTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Sample Csv
        </Tooltip>
    );
    return <Modal show={modalUpload} onHide={() => setModalUpload(!modalUpload)}>
        <Modal.Header closeButton>
            <Modal.Title>
                <div className='d-flex flex-row justify-content-around gap-4'>
                    <span>User CSV Upload</span>
                    <a className='' style={{ cursor: 'pointer' }} target="_blank" download href={userSample}>
                        <OverlayTrigger
                            placement="top"

                            overlay={downloadTooltip}
                        >

                            <span className=''><i className='bi bi-download'></i></span>
                        </OverlayTrigger>
                    </a>
                </div>
            </Modal.Title>
        </Modal.Header>
        <Form onSubmit={csvFormik.handleSubmit}>

            <Modal.Body>
                <Form.Control
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    className='form-control form-control-solid-file  mt-3'
                    required
                    style={{ padding: " 8px 15px !important" }}
                    onChange={(e) => handleFileChange(e)}
                    name="csv_file"
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModalUpload(!modalUpload)}>
                    Close
                </Button>
                <Button className="btn btn-primary btnHover" disabled={csvFormik.isSubmitting} variant="primary" type="submit" >
                    Upload
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>
}
export default CsvUploaderModal;




