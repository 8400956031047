import React from 'react'
import Highlighter from 'react-highlight-words';
import { useState, useEffect } from 'react';

const PayerSearch = (props) => {
    const {
        loading,

        __handleSearchPayer,
        togglePayerHoverEnter,
        activatePayerLookup,
        setPayerFocus,
        setPayerLookup,
        payerValue,
        payerData,
        payerIndex,
        setPayerValue,

    } = props;

    useEffect(() => {
        // if (payerValue?.length >= 3) {
            const timeout = setTimeout(() => {
                __handleSearchPayer()

            }, 800);
            return () => {
                clearTimeout(timeout);
            };
        // }
        // else{

        // }
    }, [payerValue]);
    return (
        <React.Fragment>
            <div className="bg-color h-search" >
                <div className="search-grid">
                    <div className=" py-2  row d-flex align-items-center">
                        <div className="col-lg-1 text-end">
                            <span className="fw-bolder text-white">Search Payer :</span>
                        </div>
                        <div className="col-lg-10">
                            <input type="search" placeholder="Search Payer (at least 3 char) "
                                onChange={(e) => setPayerValue(e.target.value)} autoFocus
                                // onFocus={__handleSearchPayer}
                                className="form-control b-r-10 ms-2 form-control-search" value={payerValue} />
                        </div>
                        <div className="col-lg-1">
                            <button type="button" onClick={() => { setPayerLookup(false) }} className="btn text-white fw-bolder">X</button>
                        </div>
                    </div>
                </div>
                <div className="" style={{ marginTop: '80px' }}>
                    <ul className="list-group list-group-horizontal payer-ul-scroll">
                        <li className="list-group-item item-width-full py-1 fs-7">Name</li>
                        <li className="list-group-item item-width-full py-1 fs-7">Payer Identification</li>
                        <li className="list-group-item item-width-full py-1 fs-7">Id Code Qualifier</li>
                        <li className="list-group-item item-width-full py-1 fs-7">Address</li>
                        <li className="list-group-item item-width-full py-1 fs-7">City</li>
                        <li className="list-group-item item-width-full py-1 fs-7">Zip</li>
                        <li className="list-group-item item-width-full py-1 fs-7">State</li>

                    </ul>
                    {loading ? (
                        <React.Fragment>
                            {/* <img src={searchData} style={{ height: '250px' }} alt="search" /> */}
                            <div className="text-center p-5">
                                <span className="fw-bolder fs-4">
                                    Searching...
                                </span>
                            </div>
                        </React.Fragment>
                    ) :
                        (
                            <React.Fragment>
                                {payerData && payerData.length > 0 ? (
                                    <React.Fragment>

                                        {payerData.map((payer, ind) => (
                                            <ul
                                                onMouseEnter={() => togglePayerHoverEnter(ind)}
                                                onClick={() => { activatePayerLookup(); setPayerFocus(true) }}
                                                key={ind}
                                                className={payerIndex == ind ? "list-group list-group-horizontal active-payer" : "list-group list-group-horizontal"}
                                            >

                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payerValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payer.name}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payerValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payer.identification_code}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payerValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payer.id_code_qualifier}
                                                    />

                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payerValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payer.address}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payerValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payer.city}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payerValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payer.zip}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 " >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payerValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payer.state}
                                                    />
                                                </li>

                                            </ul>
                                        ))}


                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className='py-3 text-center'>
                                            <span className='fw-bolder'>No data</span>
                                        </div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default PayerSearch