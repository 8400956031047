import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createContact } from './../../../../../Redux/client/action';
import { useNavigate } from 'react-router-dom';
import { changeAllTitleFunction } from '../../../Dashboard/functions';
import { CLIENT } from '../../../../../routeNames';

function Contact(props) {
    const { contact, client_data, __toggleToast, setLoading, fetchClientDetails } = props;
    const [state, setState] = useState({
        error: false,
        error_message: "",

    });
    const navigate = useNavigate()
    useEffect(() => {
        if (props?.contact) {
            contactformik.setValues({
                primary_name: contact[0]?.name,
                primary_telephone: contact[0]?.telephone,
                primary_email: contact[0]?.email,
                primary_designation: contact[0]?.designation,
                secondary_name: contact[1]?.name,
                secondary_telephone: contact[1]?.telephone,
                secondary_email: contact[1]?.email,
                secondary_designation: contact[1]?.designation,
                it_name: contact[2]?.name,
                it_telephone: contact[2]?.telephone,
                it_email: contact[2]?.email,
                it_designation: contact[2]?.designation,
                help_name: contact[3]?.name,
                help_telephone: contact[3]?.telephone,
                help_email: contact[3]?.email,
                help_designation: contact[3]?.designation,
                lockbox_name: contact[4]?.name,
                lockbox_telephone: contact[4]?.telephone,
                lockbox_email: contact[4]?.email,
                lockbox_designation: contact[4]?.designation,

                // contact_name: contact[4]?.name,
                // contact_telephone: contact[4]?.telephone,
                // contact_email: contact[4]?.email,
                // contact_designation: contact[4]?.designation,

                email_name: contact[5]?.name,
                // email_telephone: contact[5]?.telephone,
                email_email: contact[5]?.email,
                // email_designation: contact[5]?.designation,
            })
        }

    }, [contact]);

    const contactformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            primary_name: "",
            primary_telephone: "",
            primary_email: "",
            primary_designation: "",

            secondary_name: "",
            secondary_telephone: "",
            secondary_email: "",
            secondary_designation: "",

            it_name: "",
            it_telephone: "",
            it_email: "",
            it_designation: "",

            help_name: "",
            help_telephone: "",
            help_email: "",
            help_designation: "",

            lockbox_name: "",
            lockbox_telephone: "",
            lockbox_email: "",
            lockbox_designation: "",

            // contact_name: "",
            // contact_telephone: "",
            // contact_email: "",
            // contact_designation: "",

            email_name: "",
            // email_telephone: "",
            email_email: "",
            // email_designation: "",
        },
        validationSchema: Yup.object().shape({
            // primary_name: Yup.string()
            //     .min(1, 'at least 1 character')
            //     .required('Required'),
            primary_telephone: Yup.string()
                .matches(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/, 'Invalid phone number')
                .min(1, ' at least 1 character'),
            //     .required('Required'),
            primary_email: Yup.string()
                .email('Invalid Email'),
                // .required('Required'),
            // primary_designation: Yup.string()
            //     .required('Required'),

            // secondary_name: Yup.string()
            //     .min(1, 'at least 1 character')
                // .required('Required'),
            secondary_telephone: Yup.string()
                .matches(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/, 'Invalid phone number'),
                // .min(1, ' at least 1 character'),
            //     .required('Required'),
            secondary_email: Yup.string()
                .email('Invalid Email'),
                // .required('Required'),
            // secondary_designation: Yup.string()
            //     .required('Required'),

            // it_name: Yup.string()
            //     .min(1, 'at least 1 character')
            //     .required('Required'),
            it_telephone: Yup.string()
                .matches(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/, 'Invalid phone number'),
            //     .min(1, ' at least 1 character')
            //     .required('Required'),
            it_email: Yup.string()
                .email('Invalid Email'),
                // .required('Required'),
            // it_designation: Yup.string()
            //     .required('Required'),

            // help_name: Yup.string()
            //     .min(1, 'at least 1 character')
            //     .required('Required'),
            help_telephone: Yup.string()
                .matches(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/, 'Invalid phone number'),
            //     .min(1, ' at least 1 character')
            //     .required('Required'),
            help_email: Yup.string()
                .email('Invalid Email'),
                // .required('Required'),
            // help_designation: Yup.string()
            //     .required('Required'),

            // lockbox_name: Yup.string()
            //     .min(1, 'at least 1 character')
            //     .required('Required'),
            lockbox_telephone: Yup.string()
                .matches(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/, 'Invalid phone number'),
            //     .min(1, ' at least 1 character')
            //     .required('Required'),
            lockbox_email: Yup.string()
                .email('Invalid Email'),
                // .required('Required'),
            // lockbox_designation: Yup.string()
                // .required('Required'),


            // contact_name: Yup.string()
            //     .min(1, 'at least 1 character')
            //     .required('Required'),
            contact_telephone: Yup.string()
                .matches(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/, 'Invalid phone number'),
                // .min(1, ' at least 1 character')
            //     .required('Required'),
            // contact_email: Yup.string()
            //     .email('Invalid Email')
            //     .required('Required'),
            // contact_designation: Yup.string()
            //     .required('Required'),

            // email_name: Yup.string()
                // .min(1, 'at least 1 character')
                // .required('Required'),
            // email_telephone: Yup.string()
            //     .matches(/^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})( x\d{4})?$/, 'Invalid phone number')
            //     .min(1, ' at least 1 character')
            //     .required('Required'),
            email_email: Yup.string()
                .email('Invalid Email'),
                // .required('Required'),
            // email_designation: Yup.string()
            //     .required('Required'),
        }),
        onSubmit: (values, { setSubmitting }) => {


            setLoading(true)
            setSubmitting(true)
            var data = {};
            data['client'] = props.client;
            if (contact && contact[0]?.id)
                data['primary'] = { name: values.primary_name, telephone: values.primary_telephone, email: values.primary_email, designation: values.primary_designation, type: 1, client_id: props.client_id, contact: contact[0]?.id };
            else
                data['primary'] = { name: values.primary_name, telephone: values.primary_telephone, email: values.primary_email, designation: values.primary_designation, type: 1, client_id: props.client_id };
            if (contact && contact[1]?.id)
                data['secondary'] = { name: values.secondary_name, telephone: values.secondary_telephone, email: values.secondary_email, designation: values.secondary_designation, type: 2, client_id: props.client_id, contact: contact[1]?.id };
            else
                data['secondary'] = { name: values.secondary_name, telephone: values.secondary_telephone, email: values.secondary_email, designation: values.secondary_designation, type: 2, client_id: props.client_id };
            if (contact && contact[2]?.id)
                data['it_contact'] = { name: values.it_name, telephone: values.it_telephone, email: values.it_email, designation: values.it_designation, type: 3, client_id: props.client_id, contact: contact[2]?.id };
            else
                data['it_contact'] = { name: values.it_name, telephone: values.it_telephone, email: values.it_email, designation: values.it_designation, type: 3, client_id: props.client_id };
            if (contact && contact[3]?.id)
                data['helpdesk_contact'] = { name: values.help_name, telephone: values.help_telephone, email: values.help_email, designation: values.help_designation, type: 4, client_id: props.client_id, contact: contact[3]?.id };
            else
                data['helpdesk_contact'] = { name: values.help_name, telephone: values.help_telephone, email: values.help_email, designation: values.help_designation, type: 4, client_id: props.client_id };
            if (contact && contact[4]?.id)
                data['lockbox_contact'] = { name: values.lockbox_name, telephone: values.lockbox_telephone, email: values.lockbox_email, designation: values.lockbox_designation, type: 5, client_id: props.client_id, contact: contact[4]?.id };
            else
                data['lockbox_contact'] = { name: values.lockbox_name, telephone: values.lockbox_telephone, email: values.lockbox_email, designation: values.lockbox_designation, type: 5, client_id: props.client_id };

            if (contact && contact[5]?.id)
                data['email_contact'] = { name: values.email_name, telephone: values.email_telephone, email: values.email_email, designation: values.email_designation, type: 6, client_id: props.client_id, contact: contact[5]?.id };
            else
                data['email_contact'] = { name: values.email_name, telephone: values.email_telephone, email: values.email_email, designation: values.email_designation, type: 6, client_id: props.client_id };
            createContact({ data })((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    // setState({ clientValidated: true })
                    // props.changeState(response?.data);
                    __toggleToast("success", response?.message, true)
                    fetchClientDetails()
                    setLoading(false)
                    setSubmitting(false)
                } else {
                    const errors = response.response?.data?.errors;
                    clientformik.setErrors({ errors })
                    // props.changeState(response);
                    __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                    setLoading(false)
                    setSubmitting(false)
                }
            });
        },
    });

    const __handleContactSubmit = (values) => {

        setLoading(true)
        var data = {};
        data['client'] = props.client;
        if (contact && contact[0]?.id)
            data['primary'] = { name: values.primary_name, telephone: values.primary_telephone, email: values.primary_email, designation: values.primary_designation, type: 1, client_id: props.client_id, contact: contact[0]?.id };
        else
            data['primary'] = { name: values.primary_name, telephone: values.primary_telephone, email: values.primary_email, designation: values.primary_designation, type: 1, client_id: props.client_id };
        if (contact && contact[1]?.id)
            data['secondary'] = { name: values.secondary_name, telephone: values.secondary_telephone, email: values.secondary_email, designation: values.secondary_designation, type: 2, client_id: props.client_id, contact: contact[1]?.id };
        else
            data['secondary'] = { name: values.secondary_name, telephone: values.secondary_telephone, email: values.secondary_email, designation: values.secondary_designation, type: 2, client_id: props.client_id };
        if (contact && contact[2]?.id)
            data['it_contact'] = { name: values.it_name, telephone: values.it_telephone, email: values.it_email, designation: values.it_designation, type: 3, client_id: props.client_id, contact: contact[2]?.id };
        else
            data['it_contact'] = { name: values.it_name, telephone: values.it_telephone, email: values.it_email, designation: values.it_designation, type: 3, client_id: props.client_id };
        if (contact && contact[3]?.id)
            data['helpdesk_contact'] = { name: values.help_name, telephone: values.help_telephone, email: values.help_email, designation: values.help_designation, type: 4, client_id: props.client_id, contact: contact[3]?.id };
        else
            data['helpdesk_contact'] = { name: values.help_name, telephone: values.help_telephone, email: values.help_email, designation: values.help_designation, type: 4, client_id: props.client_id };
        if (contact && contact[4]?.id)
            data['lockbox_contact'] = { name: values.lockbox_name, telephone: values.lockbox_telephone, email: values.lockbox_email, designation: values.lockbox_designation, type: 5, client_id: props.client_id, contact: contact[4]?.id };
        else
            data['lockbox_contact'] = { name: values.lockbox_name, telephone: values.lockbox_telephone, email: values.lockbox_email, designation: values.lockbox_designation, type: 5, client_id: props.client_id };

        if (contact && contact[5]?.id)
            data['email_contact'] = { name: values.email_name, telephone: values.email_telephone, email: values.email_email, designation: values.email_designation, type: 6, client_id: props.client_id, contact: contact[5]?.id };
        else
            data['email_contact'] = { name: values.email_name, telephone: values.email_telephone, email: values.email_email, designation: values.email_designation, type: 6, client_id: props.client_id };
        createContact({ data })((response) => {
            if (!response?.data?.status && response?.status && response?.status_code === 201) {
                // setState({ clientValidated: true })
                // props.changeState(response?.data);
                __toggleToast("success", response?.message, true)
                fetchClientDetails()
                setLoading(false)
            } else {
                setLoading(false)
                const errors = response.response?.data?.errors;
                contactformik.setErrors({ errors })
                // props.changeState(response);
                __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
            }
        });
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    return (
        <div>
            <Form
                onSubmit={contactformik.handleSubmit}
            >
                <div className='row fw-bolder clr-heading mb-3'>
                    Primary Contact
                </div>
                <div className="mb-3 row border-bottom-dashed " >
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Name</Form.Label>
                            <Form.Control
                                name='primary_name'
                                type="text"
                                maxLength={250}
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.primary_name && !contactformik.errors?.primary_name}
                                isInvalid={!!contactformik.errors?.primary_name}
                                value={contactformik.values?.primary_name}
                                className='b-r-10 form-control-solid'

                                placeholder="Name"
                            />
                            <Form.Control.Feedback type="invalid">{contactformik.errors?.primary_name}</Form.Control.Feedback>

                        </Form.Group>

                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold' >Telephone</Form.Label>

                            <Form.Control
                                name='primary_telephone'
                                type="text"
                                autoComplete="false"
                                // isValid={contactformik.touched.primary_telephone && !contactformik?.errors?.primary_telephone}
                                isInvalid={!!contactformik?.errors?.primary_telephone}
                                value={contactformik.values?.primary_telephone}
                                className='b-r-10 form-control-solid'
                                onChange={contactformik.handleChange}

                                placeholder="Telephone Number"
                                maxLength={14}
                            />
                            <Form.Control.Feedback type="invalid">{contactformik?.errors?.primary_telephone}</Form.Control.Feedback>

                        </Form.Group>
                    </div>


                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Email</Form.Label>
                            <Form.Control
                                name='primary_email'
                                type="email"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.primary_email && !contactformik?.errors?.primary_email}
                                isInvalid={!!contactformik.errors?.primary_email}
                                value={contactformik.values?.primary_email}
                                className='b-r-10 form-control-solid'

                                placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.primary_email}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Designation</Form.Label>
                            <Form.Control
                                name='primary_designation'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.primary_designation && !contactformik?.errors?.primary_designation}
                                isInvalid={!!contactformik.errors?.primary_designation}
                                value={contactformik.values?.primary_designation}
                                className='b-r-10 form-control-solid'

                                placeholder="Designation"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.primary_designation}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                </div>
                <div className='row fw-bolder clr-heading mb-3'>
                    Secondary Contact
                </div>
                <div className="mb-3 row border-bottom-dashed" >
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Name</Form.Label>
                            <Form.Control
                                name='secondary_name'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                maxLength={250}
                                // isValid={contactformik.touched.secondary_name && !contactformik.errors?.secondary_name}
                                isInvalid={!!contactformik.errors?.secondary_name}
                                value={contactformik.values?.secondary_name}
                                className='b-r-10 form-control-solid'

                                placeholder="Name"
                            />
                            <Form.Control.Feedback type="invalid">{contactformik.errors?.secondary_name}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold' >Telephone</Form.Label>

                            <Form.Control
                                name='secondary_telephone'
                                type="text"
                                autoComplete="false"
                                // isValid={contactformik.touched.secondary_telephone && !contactformik?.errors?.secondary_telephone}
                                isInvalid={!!contactformik?.errors?.secondary_telephone}
                                value={contactformik.values?.secondary_telephone}
                                className='b-r-10 form-control-solid'
                                onChange={contactformik.handleChange}

                                placeholder="Telephone Number"
                                maxLength={14}
                            />
                            <Form.Control.Feedback type="invalid">{contactformik?.errors?.secondary_telephone}</Form.Control.Feedback>

                        </Form.Group>
                    </div>


                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Email</Form.Label>
                            <Form.Control
                                name='secondary_email'
                                type="email"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.secondary_email && !contactformik?.errors?.secondary_email}
                                isInvalid={!!contactformik.errors?.secondary_email}
                                value={contactformik.values?.secondary_email}
                                className='b-r-10 form-control-solid'

                                placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.secondary_email}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Designation</Form.Label>
                            <Form.Control
                                name='secondary_designation'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.secondary_designation && !contactformik?.errors?.secondary_designation}
                                isInvalid={!!contactformik.errors?.secondary_designation}
                                value={contactformik.values?.secondary_designation}
                                className='b-r-10 form-control-solid'

                                placeholder="Designation"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.secondary_designation}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                </div>
                <div className='row fw-bolder clr-heading mb-3'>
                    IT Contact
                </div>
                <div className="mb-3 row border-bottom-dashed" >
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Name</Form.Label>
                            <Form.Control
                                name='it_name'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                maxLength={250}
                                // isValid={contactformik.touched.it_name && !contactformik.errors?.it_name}
                                isInvalid={!!contactformik.errors?.it_name}
                                value={contactformik.values?.it_name}
                                className='b-r-10 form-control-solid'

                                placeholder="Name"
                            />
                            <Form.Control.Feedback type="invalid">{contactformik.errors?.it_name}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold' >Telephone</Form.Label>

                            <Form.Control
                                name='it_telephone'
                                type="text"
                                autoComplete="false"
                                // isValid={contactformik.touched.it_telephone && !contactformik?.errors?.it_telephone}
                                isInvalid={!!contactformik?.errors?.it_telephone}
                                value={contactformik.values?.it_telephone}
                                className='b-r-10 form-control-solid'
                                onChange={contactformik.handleChange}

                                placeholder="Telephone Number"
                                maxLength={14}
                            />
                            <Form.Control.Feedback type="invalid">{contactformik?.errors?.it_telephone}</Form.Control.Feedback>

                        </Form.Group>
                    </div>


                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Email</Form.Label>
                            <Form.Control
                                name='it_email'
                                type="email"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.it_email && !contactformik?.errors?.it_email}
                                isInvalid={!!contactformik.errors?.it_email}
                                value={contactformik.values?.it_email}
                                className='b-r-10 form-control-solid'

                                placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.it_email}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Designation</Form.Label>
                            <Form.Control
                                name='it_designation'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.it_designation && !contactformik?.errors?.it_designation}
                                isInvalid={!!contactformik.errors?.it_designation}
                                value={contactformik.values?.it_designation}
                                className='b-r-10 form-control-solid'

                                placeholder="Designation"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.it_designation}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                </div>
                <div className='row fw-bolder clr-heading mb-3'>
                    HelpDesk Contact
                </div>
                <div className="mb-3 row border-bottom-dashed" >
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Name</Form.Label>
                            <Form.Control
                                name='help_name'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                maxLength={250}
                                // isValid={contactformik.touched.help_name && !contactformik.errors?.help_name}
                                isInvalid={!!contactformik.errors?.help_name}
                                value={contactformik.values?.help_name}
                                className='b-r-10 form-control-solid'

                                placeholder="Name"
                            />
                            <Form.Control.Feedback type="invalid">{contactformik.errors?.help_name}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold' >Telephone</Form.Label>

                            <Form.Control
                                name='help_telephone'
                                type="text"
                                autoComplete="false"
                                // isValid={contactformik.touched.help_telephone && !contactformik?.errors?.help_telephone}
                                isInvalid={!!contactformik?.errors?.help_telephone}
                                value={contactformik.values?.help_telephone}
                                className='b-r-10 form-control-solid'
                                onChange={contactformik.handleChange}

                                placeholder="Telephone Number"
                                maxLength={14}
                            />
                            <Form.Control.Feedback type="invalid">{contactformik?.errors?.help_telephone}</Form.Control.Feedback>

                        </Form.Group>
                    </div>


                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Email</Form.Label>
                            <Form.Control
                                name='help_email'
                                type="email"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.help_email && !contactformik?.errors?.help_email}
                                isInvalid={!!contactformik.errors?.help_email}
                                value={contactformik.values?.help_email}
                                className='b-r-10 form-control-solid'

                                placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.help_email}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Designation</Form.Label>
                            <Form.Control
                                name='help_designation'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.help_designation && !contactformik?.errors?.help_designation}
                                isInvalid={!!contactformik.errors?.help_designation}
                                value={contactformik.values?.help_designation}
                                className='b-r-10 form-control-solid'

                                placeholder="Designation"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.help_designation}</Form.Control.Feedback>

                        </Form.Group>
                    </div>



                </div>
                <div className='row fw-bolder clr-heading mb-3'>
                    LockBoxRep Contact
                </div>
                <div className="mb-3 row border-bottom-dashed" >
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Name</Form.Label>
                            <Form.Control
                                name='lockbox_name'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                maxLength={250}
                                // isValid={contactformik.touched.lockbox_name && !contactformik.errors?.lockbox_name}
                                isInvalid={!!contactformik.errors?.lockbox_name}
                                value={contactformik.values?.lockbox_name}
                                className='b-r-10 form-control-solid'

                                placeholder="Name"
                            />
                            <Form.Control.Feedback type="invalid">{contactformik.errors?.lockbox_name}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold' >Telephone</Form.Label>

                            <Form.Control
                                name='lockbox_telephone'
                                type="text"
                                autoComplete="false"
                                // isValid={contactformik.touched.lockbox_telephone && !contactformik?.errors?.lockbox_telephone}
                                isInvalid={!!contactformik?.errors?.lockbox_telephone}
                                value={contactformik.values?.lockbox_telephone}
                                className='b-r-10 form-control-solid'
                                onChange={contactformik.handleChange}

                                placeholder="Telephone Number"
                                maxLength={14}
                            />
                            <Form.Control.Feedback type="invalid">{contactformik?.errors?.lockbox_telephone}</Form.Control.Feedback>

                        </Form.Group>
                    </div>


                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Email</Form.Label>
                            <Form.Control
                                name='lockbox_email'
                                type="email"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.lockbox_email && !contactformik?.errors?.lockbox_email}
                                isInvalid={!!contactformik.errors?.lockbox_email}
                                value={contactformik.values?.lockbox_email}
                                className='b-r-10 form-control-solid'

                                placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.lockbox_email}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Designation</Form.Label>
                            <Form.Control
                                name='lockbox_designation'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.lockbox_designation && !contactformik?.errors?.lockbox_designation}
                                isInvalid={!!contactformik.errors?.lockbox_designation}
                                value={contactformik.values?.lockbox_designation}
                                className='b-r-10 form-control-solid'

                                placeholder="Designation"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.lockbox_designation}</Form.Control.Feedback>

                        </Form.Group>
                    </div>



                </div>



                <div className='row fw-bolder clr-heading mb-3'>
                    Email Contact
                </div>

                <div className="mb-3 row border-bottom-dashed" >
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Name</Form.Label>
                            <Form.Control
                                name='email_name'
                                type="text"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                maxLength={250}
                                // isValid={contactformik.touched.email_name && !contactformik.errors?.email_name}
                                isInvalid={!!contactformik.errors?.email_name}
                                value={contactformik.values?.email_name}
                                className='b-r-10 form-control-solid'

                                placeholder="Name"
                            />
                            <Form.Control.Feedback type="invalid">{contactformik.errors?.email_name}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    


                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Email</Form.Label>
                            <Form.Control
                                name='email_email'
                                type="email"
                                onChange={contactformik.handleChange}
                                autoComplete="false"
                                // isValid={contactformik.touched.email_email && !contactformik?.errors?.email_email}
                                isInvalid={!!contactformik.errors?.email_email}
                                value={contactformik.values?.email_email}
                                className='b-r-10 form-control-solid'

                                placeholder="Email"
                            />
                            <Form.Control.Feedback type="invalid">  {contactformik.errors?.email_email}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                   



                </div>


                <div>
                    {state.error &&
                        <span className='text-danger'>{state.error_message}</span>}
                </div>
                <div className='d-flex flex-row justify-content-end'>
                    <div className=''>
                        <button
                            type='button'
                            className='btn btn-secondary btn-active-primary me-2'
                            onClick={() => { changeAllTitleFunction('Client Setup', CLIENT) }}
                        >
                            Cancel
                        </button>
                    </div>
                    {client_data ? (checkUserPermission('edit_client') &&
                        <div className=''>
                            <button
                                type='submit'
                                className='btn btn-primary btnHover'
                                disabled={contactformik.isSubmitting || !contactformik.isValid}
                            >
                                {contactformik.isSubmitting ? 'Updating' : 'Update'}
                            </button>
                        </div>
                    ) : (
                        <div className=''>
                            <button
                                type='submit'
                                className='btn btn-primary btnHover'
                                disabled={contactformik.isSubmitting || !contactformik.isValid}
                            >
                                {contactformik.isSubmitting ? 'Saving' : 'Save'}
                            </button>
                        </div>
                    )
                    }
                </div>
            </Form>

        </div>
    )
}

export default Contact;