import React from 'react'

export default function NoJobPage({ saveSuccess }) {
    return (
        <div>
            <React.Fragment>
                <div className='d-flex flex-column justify-content-center align-items-center height-100'>
                    <span className='text-primary badge badge-light-primary fs-1'>
                        NO PENDING JOBS
                    </span>
                    <button
                        disabled={!saveSuccess}
                        onClick={() => {
                            // setFirstLoad(false)
                            // setSecondLoad(false)
                            // setActivePdf('FIRST')
                            window.location.reload()
                        }} className='btn btn-light btn-lg rounded-1 mt-3 btn-active-primary '>
                        Refresh
                    </button>
                </div>
            </React.Fragment>
        </div>
    )
}
