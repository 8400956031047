
import React, { Component, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const CkEditor = ({ formik, editorRef }) => {
    return (
        <div className="">

            <CKEditor
                ref={editorRef}
                editor={ClassicEditor}
                data={formik.values.contents}
                config={{
                    toolbar: {
                        items: [

                            '|',
                            'heading',
                            'bold',
                            'italic',
                            'underline',
                            'strikethrough',
                            'subscript',
                            'superscript',
                            'link',
                            'blockQuote',

                            'insertTable',
                            'undo',
                            'redo',
                            'alignment',
                            'fontSize',
                            'fontColor',
                            'backgroundColor',
                            'list',
                            'bulletedList',
                            'indent',
                            'outdent',
                            'code',
                            'codeBlock',
                        ]
                    },
                    language: 'en',
                    height: 300,
                }}
                // onReady={editor => {
                //     // You can store the "editor" and use when it is needed.
                // }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    // alert(JSON.stringify(formik.values))
                    formik.setValues({
                        ...formik.values,
                        contents: data
                    })
                }}
            />
        </div>
    );
};
export default CkEditor;


