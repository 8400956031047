import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LOGIN } from './routeNames';
import { useEffect } from 'react';

const Verthe = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(LOGIN)
    }, [])

    return (
        <div>

        </div>
    )
}

export default Verthe
