import React, { useContext, useRef } from "react";
import { useState, useEffect } from 'react';
import Ink from 'react-ink';
import { array } from 'yup';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import bottomTabSlice, { bottomNewTab, bottomTabSuccess, changeNoofTabs, removeCurrentTab, setTabCurrent, removeTab } from "../../Redux/slices/bottomTab/bottomTabSlice";
import SweetAlert from 'react-bootstrap-sweetalert';
import { getAllPermissions, logooutSession } from "../../Redux/auth/action";
import { InputGroup, Form, OverlayTrigger, Overlay, Tooltip } from 'react-bootstrap';
import { VIEW_LOG_DETAIL } from "../../routeNames";
import { getPageHead } from "../DashBoard/Dashboard/functions";
import { releaseSplitFile } from "../../Redux/fileSplit/action";
import { loginSuccess } from "../../Redux/auth/reducer";
import Swal from 'sweetalert2'
import { keepAliveApi } from "../../Redux/common/action";
import GlobelHotkey from "../DashBoard/DataEntry/Grid/Elements/globelHotkey";


function Iframe(props) {
    const [reload, setReload] = useState(false)
    const [viewPermission, setViewPermission] = useState(false)
    const closeTab = async () => {

        const refresh = localStorage.getItem('refresh_token')
        await logooutSession(refresh)((response) => {
            if (response?.status) {
            }
        })
    }




    const dispatch = useDispatch()
    const [hotKey, setHotKey] = useState(false)
    const [isMyWorkOrQAScreen, setIsMyWorkOrQAScreen] = useState(false)
    const defaultLink = `${window.location.origin}/admin/dashboard`
    const defaultPrevTabs = [{ link: `${window.location.origin}/admin/dashboard`, label: "DashBoard" }]
    const settings = useSelector((state) => state?.auth?.settings ?? state?.auth?.user?.settings);
    const permissions = useSelector((state) => state?.auth?.permissions ?? state?.auth?.user?.permissions);
    const tabs = useSelector((state) => state?.bottomTab);
    const user = useSelector((state) => state?.auth);
    const [fullScreenView, setFullScreenView] = useState(false)
    const tab_count = settings?.filter((i) => i?.key_name === 'number_of_bottom_tabs_allowed_per_user')[0]?.value

    const checkUserPermission = (check) => {
        let status = false;

        if (user?.is_superuser) {
            status = true;
        }

        else {
            if (permissions) {
                status = permissions?.includes(check)
            }
        }
        return status
    }


    const [state, setState] = useState({
        noOfTabs: tab_count ? tab_count : 1,
        currentIndex: 0,
        bottomTabs: [{ id: 1, route: defaultLink, prevTabs: [], isPageLoaded: false, filterData: [], isNewTab: true, label: 'Dashboard', title: 'Dashboard' }],
        currentTab: { id: 1, route: defaultLink, prevTabs: [], isPageLoaded: false, filterData: [], isNewTab: true, label: 'Dashboard', title: 'Dashboard' },
        tabCount: 1,
        isAlert: false,
    })

    useEffect(() => {
        dispatch(changeNoofTabs(tab_count));
    }, [tab_count]);
    const fetchPermissions = async () => {
        getAllPermissions()((response) => {
            if (response) {
            }
        })
    }

    useEffect(() => {
        const fullscreen = localStorage.getItem('fullscreen');
        if (!fullscreen) {
            const userAgent = window.navigator.userAgent.toLowerCase();
            if (userAgent.includes("windows")) {
                Swal.fire({
                    title: 'Press F11 to continue',
                    showConfirmButton: false,
                    backdrop: true,
                    allowOutsideClick: false,
                    icon: 'warning',
                    allowEscapeKey: false
                });

            }
            else {
                setFullScreenView(true)
            }

        }
        else {
            setFullScreenView(true)
        }

    }, []);
    window.onkeyup = function (event) {
        if (event.key == 'Tab' && event.ctrlKey && event.shiftKey) {
            switchCurrentTab(true)
            // parent.document.getElementById('id-ctrl-tab-shift-switch').click()
        }
        else if (event.key == 'Tab' && event.ctrlKey) {
            switchCurrentTab(false)
            // parent.document.getElementById('id-ctrl-tab-switch').click()
        } else if (event.altKey && event.keyCode == 78) {
            __handleNewTab()
        } else if (event.altKey && event.keyCode == 88) {
            shortCutRemoveTab()
        }
        else if (event.altKey && event.keyCode == 72) {
            setHotKey(true)
        }
        else if (event.key == 'Escape') {
            setHotKey(false)
        }

        const userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.includes("windows")) {
            if (event.keyCode === 122) {
                localStorage.setItem('fullscreen', true)
                Swal.close()
                setFullScreenView(true)
            }
        }

    }

    ////////////////////////////////////idleTimeout////////////////////////////

    useEffect(() => {
        let alive = false
        let minute = 1;
        let timeout = setInterval(() => {
            if (alive) {
                keepAliveApi()((response) => {
                    if (response?.data) {
                        alive = false
                        minute = 1
                    }
                })
            }
            else {
                minute = minute + 1
            }
        }, 60000);
        const resetTimer = () => {
            if (!alive) {
                alive = true
                if (minute > 1) {
                    keepAliveApi()((response) => {
                        if (response?.data) {
                            // alive = false
                            minute = 1
                        }
                    })
                }

            }
        };
        if (tabs?.bottomTabs?.length) {
            window.addEventListener('mousemove', resetTimer);
            window.addEventListener('keydown', resetTimer);
            window.addEventListener('click', resetTimer);
            window.addEventListener('scroll', resetTimer);
            // setTimeout(() => {
            tabs?.bottomTabs?.map((item, index) => {
                const frame = document.getElementById(`myIframe${item?.id}`)
                if (frame && item?.isPageLoaded) {
                    frame.contentDocument.addEventListener('mousemove', resetTimer);
                    frame.contentDocument.addEventListener('keydown', resetTimer);
                    frame.contentDocument.addEventListener('click', resetTimer);
                    frame.contentDocument.addEventListener('scroll', resetTimer);
                }
            })

            // }, 1000);
        }

        return () => {
            clearInterval(timeout);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('s   croll', resetTimer);
            if (tabs?.bottomTabs?.length) {
                tabs?.bottomTabs?.map((item, index) => {
                    const frame = document.getElementById(`myIframe${item?.id}`)
                    if (frame) {
                        frame.contentDocument.removeEventListener('mousemove', resetTimer);
                        frame.contentDocument.removeEventListener('keydown', resetTimer);
                        frame.contentDocument.removeEventListener('click', resetTimer);
                        frame.contentDocument.removeEventListener('scroll', resetTimer);
                    }
                })
            }
        };
    }, [tabs]);

    ////////////////////////////////////idleTimeout End////////////////////////////


    const [isSwal, setIsSwal] = useState({
        current: "",
        is_active: false
    });


    const setGroupInitialBottom = () => {
        const initialState = {
            noOfTabs: 1,
            currentIndex: 0,
            bottomTabs: [{ id: 1, route: defaultLink, isNewTab: true, prevTabs: [], isPageLoaded: true, filterData: [], label: 'Dashboard', title: 'Dashboard' }],
            currentTab: { id: 1, route: defaultLink, isNewTab: true, prevTabs: [], isPageLoaded: true, filterData: [], label: 'Dashboard', title: 'Dashboard' },
            tabCount: 1,
            isAlert: false,
        }
        dispatch(
            bottomNewTab(initialState)
        )
        window.parent.localStorage.removeItem('newTab')
        window.parent.localStorage.setItem('bottomTab', JSON.stringify(initialState))
    }

    const setfiltrationHistory = () => {
        const filter = localStorage.getItem("filterData")
        let filtredData;
        // if (!filter || !JSON.parse(filter)) {

        const list_tabs = tabs;
        const curentfilter = {
            ...list_tabs.currentTab,
            filterData: filter ? JSON.parse(filter) : []

        }
        const data = {
            ...list_tabs,
            currentTab: curentfilter
        }
        dispatch(
            bottomNewTab(data)
        )
        window.parent.localStorage.removeItem('filterData')
        window.parent.localStorage.setItem('bottomTab', JSON.stringify(data))
        // }
    }
    const setIframePageLoadeFun = () => {
        const updated_tabs = [...tabs.bottomTabs];
        const indexToadd = updated_tabs.findIndex((i) => i?.id === tabs.currentTab.id);
        if (indexToadd !== -1) {
            updated_tabs[indexToadd] = { ...updated_tabs[indexToadd], isPageLoaded: true };
        }
        const data = {
            ...tabs,
            bottomTabs: updated_tabs,
            currentTab: { ...tabs?.currentTab, isPageLoaded: true },
        }
        dispatch(
            setTabCurrent(data)
        )
        window.parent.localStorage.setItem('bottomTab', JSON.stringify(data))
    }
    const switchCurrentTab = (isShwift) => {
        setHotKey(false)
        const list_tabs = tabs;
        const bottomTabsList = tabs.bottomTabs
        const removedUndifinedTabs = bottomTabsList.filter((i) => i != undefined)
        const currentIndex = removedUndifinedTabs.findIndex(i => i?.id === tabs?.currentTab?.id);
        let currentTab = undefined
        if (isShwift) {

            currentTab = removedUndifinedTabs[currentIndex - 1]
            if (currentTab == undefined) {
                currentTab = removedUndifinedTabs[removedUndifinedTabs.length - 1];
            }

        } else {
            currentTab = removedUndifinedTabs[currentIndex + 1]
            if (currentTab == undefined) {
                currentTab = removedUndifinedTabs[0];
            }
            // alert("*CTRL*")

        }
        setTimeout(() => {
            document.getElementById(`myTab${currentTab.id}`)?.focus()
            document.getElementById(`myTab${currentTab.id}`).scrollIntoViewIfNeeded()
            document.getElementById(`myIframe${currentTab?.id}`)?.focus()
        }, 10)
        dispatch(
            setTabCurrent({

                ...list_tabs,
                currentTab: currentTab,
                currentIndex,
            })
        )
        myWorkQaScreenFun(currentTab?.title)
        const data = {

            ...list_tabs,
            currentTab: currentTab,
            currentIndex,
        }
        window.parent.localStorage.setItem('bottomTab', JSON.stringify(data))
        setIsSwal({
            current: "",
            is_active: false
        })


    }


    const setCurrentTab = (item, index) => {
        const list_tabs = tabs;
        const ind = tabs?.bottomTabs.findIndex(i => i?.id === item?.id);

        dispatch(
            setTabCurrent({

                ...list_tabs,
                currentTab: list_tabs.bottomTabs[ind],
                currentIndex: index,
            })
        )
        myWorkQaScreenFun(list_tabs.bottomTabs[ind]?.title)

        const data = {

            ...list_tabs,
            currentTab: list_tabs.bottomTabs[ind],
            currentIndex: index,
        }
        window.parent.localStorage.setItem('bottomTab', JSON.stringify(data))
        setIsSwal({
            current: "",
            is_active: false
        })
        setTimeout(() => {
            document.getElementById(`myIframe${list_tabs.bottomTabs[ind]?.id}`)?.focus()
        }, 10)

    }

    const handleRemoveTab = (item, index) => {
        setTimeout(() => {
            document.getElementById(`delete-cancel-${item.id}`).scrollIntoViewIfNeeded()
        }, 50)
        setIsSwal({
            ...isSwal,
            current: index,
            is_active: true
        })

    }
    const shortCutRemoveTab = () => {
        if (tabs?.bottomTabs?.length > 1) {
            setHotKey(false)
            const index = tabs.bottomTabs.findIndex((i) => i?.id == tabs?.currentTab?.id)
            setIsSwal({
                ...isSwal,
                current: index,
                is_active: true
            })
            setTimeout(() => {
                const curentCancelButton = document.getElementById(`delete-cancel-${tabs.currentTab.id}`)
                const curentConfirmButton = document.getElementById(`delete-confirm-${tabs.currentTab.id}`)
                curentConfirmButton?.focus()
                curentCancelButton.scrollIntoViewIfNeeded()
            }, 50)
        }
    }
    const removeSingleTab = (item, index1) => {
        const list_tabs = tabs;

        const curentNullTabs = [...list_tabs?.bottomTabs]
        const nonullTabs = curentNullTabs.filter(i => i != null && i != undefined)
        const index = nonullTabs.findIndex((i) => i.id == item.id)
        const url = window[index].location.href
        if (url.includes("edit/split")) {
            const parts = url.split("/");
            const file_id = parts[parts.length - 1];

            if (!isNaN(file_id)) {
                releaseSplitFile(file_id)((response) => {
                    if (response?.status && response?.status_code == 202) {
                    }
                });
            }
        }
        setIsSwal({
            ...isSwal,
            current: null,
            is_active: false
        })

        // const list_tabs = window.parent.localStorage.getItem('bottomTab') ? JSON.parse(localStorage.getItem('bottomTab')) : [];

        var iframe = document.getElementById(`myIframe${item?.id}`);
        var tab = document.getElementById(`myTab${item?.id}`);
        let bottomTabs = [...list_tabs?.bottomTabs]
        const updated_tabs = [...bottomTabs];
        const indexToRemove = updated_tabs.findIndex((i) => i?.id === item?.id);

        if (indexToRemove !== -1) {
            updated_tabs[indexToRemove] = undefined;
        }
        let tab_items = Array.from(document.getElementsByClassName('common-tab')).map((i) => { return i.getAttribute('data-id') })
        if (tab_items.length != 1 && iframe && tab) {
            let active_tabs = Array.from(document.getElementsByClassName('common-tab')).map((i) => { return i.getAttribute('data-id') })

            if (tabs?.currentTab?.id === item?.id) {
                const bottomTabsList = list_tabs.bottomTabs
                const removedUndifinedTabs = bottomTabsList.filter((i) => i != undefined)
                const currentIndex = removedUndifinedTabs.findIndex(i => i?.id === item?.id);

                dispatch(
                    removeCurrentTab({
                        ...list_tabs,
                        bottomTabs: updated_tabs,
                        currentTab: removedUndifinedTabs[currentIndex == 0 ? currentIndex + 1 : currentIndex - 1],
                        tabCount: list_tabs.tabCount - 1
                    })
                )
                myWorkQaScreenFun(removedUndifinedTabs[currentIndex == 0 ? currentIndex + 1 : currentIndex - 1]?.title)
            }
            else {
                dispatch(

                    removeCurrentTab({
                        ...list_tabs,
                        bottomTabs: updated_tabs,
                        currentTab: list_tabs.currentTab,
                        tabCount: list_tabs.tabCount - 1
                    })
                )
                myWorkQaScreenFun(list_tabs.currentTab?.title)
            }
        }

    }

    const __handleNewTab = () => {
        setHotKey(false)
        let newTab = window.parent.localStorage.getItem('newTab')
        const list_tabs = tabs
        if (newTab) {
            let data1 = JSON.parse(newTab);
            let existing_arr = []
            list_tabs.bottomTabs.map(i => { i && existing_arr.push(i.id) })
            const max = existing_arr.reduce((a, b) => Math.max(a, b), -Infinity);
            let bottomTabs = [...list_tabs.bottomTabs]
            let isSplitTabExist = false
            if (data1?.title == 'File Split' && data1?.route.includes('edit/split/')) {
                bottomTabs.map(item => {
                    if (item?.route == data1?.route) {
                        Swal.fire({
                            title: 'Already in another tab',
                            confirmButtonColor: '#1ba1a6',
                            backdrop: true,
                            allowOutsideClick: false,
                            icon: 'warning',
                            allowEscapeKey: true,
                            customClass: {
                                title: 'fs-5'
                            }
                        });
                        isSplitTabExist = true;
                    }
                });
            }
            // debugger;
            if (isSplitTabExist == false) {

                const insertIndex = list_tabs.currentIndex + 1;
                data1.id = max + 1
                data1.isPageLoaded = true
                // bottomTabs.splice(insertIndex, 0, data1)
                bottomTabs.push(data1)
                let data2 = {
                    bottomTabs: bottomTabs,
                    currentTab: data1,
                    currentIndex: insertIndex,
                    noOfTabs: list_tabs.noOfTabs,
                    tabCount: list_tabs.tabCount + 1
                }
                if (data1.isCurrentTab) {
                    data2 = {
                        ...list_tabs,
                        bottomTabs: bottomTabs,
                        currentIndex: insertIndex,
                        noOfTabs: list_tabs.noOfTabs,
                        tabCount: list_tabs.tabCount + 1
                    }
                }
                dispatch(
                    bottomNewTab(data2)
                )
                myWorkQaScreenFun(data2?.currentTab?.title)
            }
            window.parent.localStorage.removeItem('newTab')
        }
        else {
            let existing_arr = []
            list_tabs?.bottomTabs.filter(i => { i && existing_arr.push(i?.id) })
            const max = existing_arr.reduce((a, b) => Math.max(a, b), -Infinity);
            let tab_items = list_tabs?.bottomTabs?.filter(obj => obj?.isNewTab === true)?.length;
            // if (tab_items < list_tabs.noOfTabs) {

            let bottomTabs = [...list_tabs?.bottomTabs]
            bottomTabs.push({
                id: max + 1, route: defaultLink, isNewTab: true, prevTabs: [], isPageLoaded: false, filterData: [], label: 'Dashboard', title: 'Dashboard'
            })

            dispatch(
                bottomNewTab({
                    ...list_tabs,
                    bottomTabs,
                    currentTab: { id: max + 1, route: defaultLink, isNewTab: true, prevTabs: [], isPageLoaded: false, filterData: [], label: 'Dashboard', title: 'Dashboard' },
                    currentIndex: bottomTabs.length - 1,
                    tabCount: tabs.tabCount + 1

                })
            )
            const data = {
                ...list_tabs,
                bottomTabs,
                currentTab: { id: max + 1, route: defaultLink, isNewTab: true, prevTabs: [], isPageLoaded: false, filterData: [], label: 'Dashboard', title: 'Dashboard' },
                currentIndex: bottomTabs.length - 1,
                tabCount: tabs.tabCount + 1

            }
            window.parent.localStorage.setItem('bottomTab', JSON.stringify(data))

            setTimeout(() => {
                document.getElementById('bottom-tab-add')?.focus()
                document.getElementById('bottom-tab-add').scrollIntoViewIfNeeded()
            }, 10)
            myWorkQaScreenFun("Dashboard")
        }

        setIsSwal({
            ...isSwal,
            current: null,
            is_active: false
        })
    }

    useEffect(() => {
        const initialState = {
            noOfTabs: 1,
            currentIndex: 0,
            bottomTabs: [{ id: 1, route: defaultLink, label: 'Dashboard', title: 'Dashboard' }],
            currentTab: { id: 1, route: defaultLink, label: 'Dashboard', title: 'Dashboard' },
            tabCount: 1,
            isAlert: false,
        }
    }, []);

    const updateState = newState => {
        window.frames.forEach(frame => {
            frame.postMessage({ type: 'UPDATE_STATE', payload: newState }, '*');
        });
    };

    const renderingTab = (data) => {
        const storage_permissions = localStorage.getItem('permissions')
        const storage_user = localStorage.getItem('user')
        if (storage_permissions && storage_user) {
            const updatedObject = {
                ...JSON.parse(storage_user),
                permissions: JSON.parse(storage_permissions), // empty array for permissions
            };
            dispatch(loginSuccess(updatedObject));

        }
        setViewPermission(checkUserPermission('bottom_navigation', false))
    }

    const changeTitle = () => {
        let currentTitle = window.parent.localStorage.getItem('currentTitle')
        let currentUrl = window.parent.localStorage.getItem('currentUrl')
        let isBackActive = window.parent.localStorage.getItem('isBackActive')
        let isFileSplitBackActive = window.parent.localStorage.getItem('isFileSplitBackActive')
        let isRedirectId = window.parent.localStorage.getItem('isRedirectId')
        const isClearFilterData = window.parent.localStorage.getItem('clearFilterData')


        let url = null
        if (currentUrl) {
            url = `${window.location.origin + currentUrl}`
        }
        let data = tabs
        if (isClearFilterData) {
            const dict = {
                ...data.currentTab,
                filterData: ""
            }
            data = {
                ...data,
                currentTab: dict
            }
        }
        var frame = document.getElementById('myIframe' + data?.currentTab.id)
        var tabText = document.getElementById('myTabText' + data?.currentTab.id)
        let split_tab_duplicate = false
        if (currentTitle == 'File Split' && currentUrl.includes('edit/split/')) {
            data?.bottomTabs?.map((item, index) => {
                if (item?.route == url) {
                    split_tab_duplicate = true
                }
            })
        }
        if (split_tab_duplicate == true) {
            Swal.fire({
                title: 'Already in another tab',
                confirmButtonColor: '#1ba1a6',
                backdrop: true,
                allowOutsideClick: false,
                icon: 'warning',
                allowEscapeKey: true,
                customClass: {
                    title: 'fs-5'
                }
            });
        }
        else if (data) {
            let bottomTabs = data?.bottomTabs
            let index = bottomTabs.findIndex((item) => item?.id === data?.currentTab?.id);
            if ((isRedirectId != 'null' || !isRedirectId) && data?.currentTab?.id != isRedirectId) {
                index = bottomTabs.findIndex((item) => item?.id == isRedirectId);
            }
            const prevousTab = [...bottomTabs[index]?.prevTabs]
            let prevousFilterData = []
            if (data?.currentTab?.prevTabs?.length > 0) {
                prevousFilterData = data?.currentTab?.prevTabs[data?.currentTab?.prevTabs?.length - 1]
            }
            if (isFileSplitBackActive == 'true') {
                prevousTab.push({ link: `${window.location.origin + '/admin/dashboard'}`, label: 'Dashboard', filterData: [] })
            }
            else if (isBackActive) {
                prevousTab.pop()
            } else {
                prevousTab.push({ link: data?.currentTab.route, label: data?.currentTab.label, filterData: data?.currentTab?.filterData })
            }
            let newData = {}
            const updatedItem = {
                ...bottomTabs[index],
                prevTabs: prevousTab,
                label: currentTitle,
                title: currentTitle,
                route: url ?? `${frame, window[index].location.href}`,
                filterData: prevousFilterData?.filterData
            };
            const newBottomTabs = [...bottomTabs];
            newBottomTabs[index] = updatedItem;
            if ((isRedirectId != 'null' || !isRedirectId) && data?.currentTab?.id != isRedirectId) {

                newData = {
                    ...data,
                    bottomTabs: newBottomTabs,
                };
                window.parent.localStorage.setItem('isRedirectId', '')
            } else {
                newData = {
                    ...data,
                    bottomTabs: newBottomTabs,
                    currentTab: updatedItem
                };

            }
            setIsSwal({
                current: "",
                is_active: false
            })
            dispatch(bottomTabSuccess(newData));
        }
        window.parent.localStorage.setItem('currentUrl', '')
        window.parent.localStorage.setItem('currentTitle', '')
        window.parent.localStorage.setItem('isBackActive', '')
        window.parent.localStorage.setItem('isFileSplitBackActive', '')
        window.parent.localStorage.setItem('clearFilterData', '')
        myWorkQaScreenFun(currentTitle)

    };
    const removeCurentTab = () => {
        removeSingleTab(tabs?.currentTab)
    }
    const handleInitialData = () => {
        dispatch(changeNoofTabs(tab_count));

        localStorage.removeItem("bottomTab");
        const defaultLink = `${window.location.origin}/admin/dashboard`;

        const initialData = { "noOfTabs": tab_count, "currentIndex": 0, "bottomTabs": [{ "id": 1, "route": defaultLink, "isNewTab": true, "prevTabs": [], "label": "Dashboard", "title": "Dashboard" }], "currentTab": { "id": 1, "route": defaultLink, "isNewTab": true, "prevTabs": [], "label": "Dashboard", "title": "Dashboard" }, "tabCount": 1, "isAlert": false }

        localStorage.setItem("bottomTab", JSON.stringify(initialData))
    }

    const myWorkQaScreenFun = (name) => {
        if (['My Work', 'QA Review', 'Snipping'].includes(name)) {
            setIsMyWorkOrQAScreen(true)
        } else {
            setIsMyWorkOrQAScreen(false)

        }
    }
    useEffect(() => {
        if (checkUserPermission('bottom_navigation')) {
            setViewPermission(checkUserPermission('bottom_navigation'))
        }
    }, [viewPermission])

    const fullscreenRef = useRef(null);

    const newTabCount = tabs?.bottomTabs?.filter(obj => obj?.isNewTab === true)?.length;

    return (
        <React.Fragment>
            <footer id="footer" className="footer">
                <button type="button" id="frame-filtration-tabs-btn" className="d-none" onClick={() => setfiltrationHistory()} />

                <button type="button" id="frame-group-initial-bottom-tabs-btn" className="d-none" onClick={() => setGroupInitialBottom()} />

                <button type="button" id="id-ctrl-x-remove-tab" className="d-none" onClick={() => shortCutRemoveTab(false)} />
                <button type="button" id="id-ctrl-tab-switch" className="d-none" onClick={() => switchCurrentTab(false)} />
                <button type="button" id="id-ctrl-tab-shift-switch" className="d-none" onClick={() => switchCurrentTab(true)} />
                <button type="button" id="initial-data-btn" className="d-none" onClick={() => handleInitialData()} />
                <button type="button" id="frame-group-change-btn" className="d-none" onClick={() => renderingTab()} />
                <button type="button" id="frame-title-btn" className="d-none" onClick={() => changeTitle()} />
                <button type="button" id="delete-current-tab" className="d-none" onClick={() => removeCurentTab()} />
                <button type="button" id="frame-page-load" className="d-none" onClick={() => setIframePageLoadeFun()} />

                {fullScreenView && tabs && tabs?.bottomTabs && tabs?.bottomTabs.map((item, index) => {
                    return item && <iframe key={index}
                        style={tabs?.currentTab?.id === item?.id ? { display: 'block', height: '100vh' } : { display: 'none', height: '100vh' }}
                        id={`myIframe${item.id}`}
                        src={item.route}
                        width={'100%'}

                        title="Tabs"

                    />
                })}
                {checkUserPermission('bottom_navigation') &&
                    <div
                        style={isMyWorkOrQAScreen === true ? { visibility: 'hidden' } : { visibility: 'visible' }}

                        className='d-flex flex-row align-items-end bottom-tabs'>
                        <div className=''>
                            <div id='tab' className='d-flex flex-row justify-content-start   px-1'>
                                {tabs?.bottomTabs?.map((item, index) => {
                                    return (
                                        item && <div key={index} style={{ position: 'relative' }} id={`myTab${item?.id}`} data-id={item?.id} className={tabs?.currentTab.id === item?.id ? 'common-tab p-2 bottom-tab-active d-flex flex-row align-items-center justify-content-between' : 'common-tab p-2 bottom-tab-common d-flex flex-row align-items-center justify-content-between'}>
                                            <span className="text-truncate" onClick={() => { setCurrentTab(item, index) }} style={{ color: 'black', fontSize: "0.9em" }} id={`myTabText${item.id}`}>
                                                {item.title}
                                            </span>
                                            {isSwal.is_active && isSwal.current == index &&
                                                <div className='hide confirm-wrapper-delete-alert'>
                                                    <button id={`delete-confirm-${item.id}`} className="confirm-button" onClick={() => { removeSingleTab(item, index) }} >Yes</button>
                                                    <button id={`delete-cancel-${item.id}`} className="confirm-button" onClick={() => {
                                                        setIsSwal({
                                                            ...isSwal,
                                                            current: null,
                                                            is_active: false
                                                        })
                                                    }} >No</button>
                                                </div>}
                                            {tabs.bottomTabs.length != 1 &&
                                                <span onClick={(e) => { handleRemoveTab(item, index) }}><i className={tabs?.currentTab.id === item?.id ? 'ms-2 bi bi-x-circle-fill' : 'ms-2 bi bi-x'}></i></span>
                                            }

                                        </div>)
                                })}

                            </div>
                        </div>
                        <div className='flex-shrink-1'>
                            <div className='pb-1 '>
                                <button type="button" id="bottom-tab-add"
                                    style={{ fontSize: '20px', background: 'none', border: 'none' }

                                    }
                                    onClick={() => __handleNewTab()}>
                                    <i className="bi bi-plus-circle-fill" ></i>
                                </button>
                            </div>

                        </div>
                    </div>
                }
                <GlobelHotkey hotKey={hotKey} setHotKey={setHotKey} />
            </footer>
        </React.Fragment>
    )
}

export default Iframe