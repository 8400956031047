import { combineReducers } from "redux";
import authReducer from '../Redux/auth/reducer'
import bottomTabSlice from "./slices/bottomTab/bottomTabSlice";
import fireBaseSlice from "./firebase/reducer";
import data837Slice from "./grid/reducer";
export default combineReducers({

    auth: authReducer,
    bottomTab: bottomTabSlice,
    firebase: fireBaseSlice,
    data837: data837Slice,
});