import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW } from '../../../../../routeNames';
import { DbkField } from '../Elements/DbkField';
import { isActiveDbkField } from '../../../Dashboard/functions';
import { handlePreventEKeyPress, preventWhiteSpaceValidate } from '../gridFunctions'

const Claim = (props) => {
    const { formik,
        index,
        setClaim835Focus,
        currentPage,
        setDefaultValueAssignedWork,
        eobLiteFocus,
        setEobLiteFocus,
        handleFocusEobLite,
        isDbkPermission,
        jobData,
        handlePageNavigate,
        log_id,
        job_id,
        pdfLoaded
    } = props;

    const handleChangeFrom = () => {
        formik.setValues((prevState) => ({
            ...prevState,
            claims: prevState.claims.map((claim, i) => {
                if (i === index) {
                    return {
                        ...claim,
                        page_from: currentPage + 1
                    };
                }
                return claim;
            })
        }));
    }
    const handleChangeTo = () => {
        formik.setValues((prevState) => ({
            ...prevState,
            claims: prevState.claims.map((claim, i) => {
                if (i === index) {
                    return {
                        ...claim,
                        page_to: currentPage + 1
                    };
                }
                return claim;
            })
        }));
        // }
    }
    const pathname = window.location.pathname;
    const gridData = jobData?.grid_data;
    const qaCorrectedData = gridData?.qa_corrected_data;
    const renderClass = (name, type, defaultClass) => {
        if (formik.errors &&
            formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData &&
                qaCorrectedData?.claims &&
                qaCorrectedData?.claims[index] &&
                qaCorrectedData?.claims[index]?.[name]) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'DATE' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
                moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]).format("MM/DD/YYYY")) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
                formik.values?.claims[index]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
                parseFloat(formik.values?.claims[index]?.[name] ? formik.values?.claims[index]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name] ?
                    gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }

            else if (gridData?.transaction_type != "PAYMENT835_LITE" || formik.values?.claims[index]?.corrected_claim) {
                return defaultClass + ' qa-field'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
            qaCorrectedData &&
            qaCorrectedData?.claims &&
            qaCorrectedData?.claims[index] &&
            qaCorrectedData?.claims[index]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else {
            return defaultClass;
        }
    }

    return (
        <div className="bottom-wrapper claimlite_details accordion-collapse collapse show" id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#claim">
            <div className="sub-section">
                <div className="">

                    <label className="btn btn-claim">Claim Details </label>
                </div>
                <div className="wrapper-patient">
                    <InputGroup>
                        <InputGroup.Text>Patient Account #</InputGroup.Text>
                        <div className="dbk-main-container">

                            <Form.Control
                                type="text"
                                name={`claims[${index}].patient_account_number`}
                                onChange={(val) => {
                                    const e = preventWhiteSpaceValidate(val)
                                    formik.handleChange(e);
                                }}
                                onDoubleClick={() => handlePageNavigate(index, 'LITE')}
                                onFocus={(e) => {
                                    handleFocusEobLite("patient_account_number", "claims", index, isDbkPermission?.payment_lite?.paymentlite_patient_acc_number); setClaim835Focus(true)
                                }}
                                onKeyUp={(e) => { setDefaultValueAssignedWork(e, 'account_number', 'patient_account_number', 'dbk_patient_account_number', index) }}
                                value={formik.values?.claims[index]?.patient_account_number}
                                tabIndex={formik.values?.claims[index]?.patient_account_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                                className={renderClass('patient_account_number', 'TEXT', 'w-claim-patient')}
                                disabled={isActiveDbkField(eobLiteFocus, formik, isDbkPermission?.payment_lite?.paymentlite_patient_acc_number) ? !eobLiteFocus.claims[index]?.patient_account_number : false}
                            />

                            {isDbkPermission?.payment_lite
                                ?.paymentlite_patient_acc_number &&
                                formik.values?.claims[index].patient_account_number !=
                                jobData?.default?.account_number &&
                                formik.errors?.claims &&
                                formik.errors?.claims[index] &&
                                formik.errors?.claims[index].patient_account_number == 'Dont Match' &&
                                eobLiteFocus.claims[index]
                                    ?.patient_account_number ? (
                                <DbkField
                                    formik={formik}
                                    index={index}
                                    type="text"
                                    fieldName="patient_account_number"
                                    fieldNameDbk="dbk_patient_account_number"
                                    fieldType="claims"
                                    setCorresPondanceFocus={setEobLiteFocus}
                                    corresPondanceFocus={eobLiteFocus}
                                />
                            ) : null}
                        </div>
                    </InputGroup>
                </div>
                <div className="sub-section ml-40" >
                    <div className="">

                        <label className="btn btn-claim" >Patient </label>
                    </div>
                    <div className="wrapper-patient">
                        <InputGroup>
                            <InputGroup.Text>First Name</InputGroup.Text>
                            <div className="dbk-main-container">

                                <Form.Control
                                    type="text"
                                    name={`claims[${index}].patient_first_name`}
                                    onChange={formik.handleChange}
                                    onDoubleClick={() => handlePageNavigate(index, 'LITE')}
                                    onFocus={(e) => {
                                        handleFocusEobLite(
                                            "patient_first_name",
                                            "claims",
                                            index,
                                            isDbkPermission?.payment_lite?.paymentlite_patient_first_name
                                        ), setClaim835Focus(true)
                                    }}
                                    onKeyUp={(e) => { setDefaultValueAssignedWork(e, 'first_name', 'patient_first_name', 'dbk_patient_first_name', index) }}
                                    value={formik.values?.claims && formik.values?.claims[index]?.patient_first_name}
                                    tabIndex={formik.values?.claims[index]?.patient_first_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                                    className={renderClass('patient_first_name', 'TEXT', 'w-fsname')}
                                    disabled={isActiveDbkField(eobLiteFocus, formik, isDbkPermission?.payment_lite?.paymentlite_patient_first_name) ? !eobLiteFocus.claims[index]?.patient_first_name : false}

                                />
                                {isDbkPermission?.payment_lite?.paymentlite_patient_first_name &&
                                    formik.errors?.claims &&
                                    formik.errors?.claims[index] &&
                                    formik.errors?.claims[index].patient_first_name == 'Dont Match' &&
                                    eobLiteFocus.claims[index]?.patient_first_name ? (
                                    <DbkField
                                        formik={formik}
                                        index={index}
                                        type="text"
                                        fieldName="patient_first_name"
                                        fieldNameDbk="dbk_patient_first_name"
                                        fieldType="claims"
                                        setCorresPondanceFocus={setEobLiteFocus}
                                        corresPondanceFocus={eobLiteFocus}
                                    />
                                ) : null}
                            </div>
                        </InputGroup>
                    </div>
                    <div className="wrapper-miname">
                        <InputGroup>
                            <InputGroup.Text>Middle Name</InputGroup.Text>
                            <div className="dbk-main-container">

                                <Form.Control
                                    type="text"
                                    name={`claims[${index}].patient_middle_name`}
                                    onChange={formik.handleChange}
                                    value={formik.values?.claims && formik.values?.claims[index]?.patient_middle_name}
                                    tabIndex={formik.values?.claims[index]?.patient_middle_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                                    className={renderClass('patient_middle_name', 'TEXT', 'w-miname')}
                                    onDoubleClick={() => handlePageNavigate(index, 'LITE')}
                                    onFocus={(e) => {
                                        handleFocusEobLite(
                                            "patient_middle_name",
                                            "claims",
                                            index,
                                            isDbkPermission?.payment_lite?.paymentlite_patient_middle_name
                                        ), setClaim835Focus(true)
                                    }}
                                    disabled={isActiveDbkField(eobLiteFocus, formik, isDbkPermission?.payment_lite?.paymentlite_patient_middle_name) ? !eobLiteFocus.claims[index]?.patient_middle_name : false}

                                />
                                {isDbkPermission?.payment_lite
                                    ?.paymentlite_patient_middle_name &&
                                    formik.errors?.claims &&
                                    formik.errors?.claims[index] &&
                                    formik.errors?.claims[index].patient_middle_name == 'Dont Match' &&
                                    eobLiteFocus.claims[index]
                                        ?.patient_middle_name ? (
                                    <DbkField
                                        formik={formik}
                                        index={index}
                                        type="text"
                                        fieldName="patient_middle_name"
                                        fieldNameDbk="dbk_patient_middle_name"
                                        fieldType="claims"
                                        setCorresPondanceFocus={setEobLiteFocus}
                                        corresPondanceFocus={eobLiteFocus}
                                    />
                                ) : null}
                            </div>
                        </InputGroup>
                    </div>

                    <div className="wrapper-patient">
                        <InputGroup>
                            <InputGroup.Text>Last Name</InputGroup.Text>
                            <div className="dbk-main-container">

                                <Form.Control
                                    name={`claims[${index}].patient_last_name`}
                                    onChange={formik.handleChange}
                                    onKeyUp={(e) => { setDefaultValueAssignedWork(e, 'last_name', 'patient_last_name', 'dbk_patient_last_name', index) }}
                                    value={formik.values?.claims && formik.values?.claims[index]?.patient_last_name}
                                    tabIndex={formik.values?.claims[index]?.patient_last_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                                    className={renderClass('patient_last_name', 'TEXT', 'w-fsname')}
                                    onDoubleClick={() => handlePageNavigate(index, 'LITE')}
                                    onFocus={(e) => {
                                        handleFocusEobLite(
                                            "patient_last_name",
                                            "claims",
                                            index,
                                            isDbkPermission?.payment_lite?.paymentlite_patient_last_name
                                        ), setClaim835Focus(true)
                                    }}
                                    disabled={isActiveDbkField(eobLiteFocus, formik, isDbkPermission?.payment_lite?.paymentlite_patient_last_name) ? !eobLiteFocus.claims[index]?.patient_last_name : false}

                                />
                                {isDbkPermission?.payment_lite
                                    ?.paymentlite_patient_last_name &&
                                    formik.values?.claims[index].patient_last_name !=
                                    jobData?.default?.account_number &&
                                    formik.errors?.claims &&
                                    formik.errors?.claims[index] &&
                                    formik.errors?.claims[index].patient_last_name == 'Dont Match' &&
                                    eobLiteFocus.claims[index]
                                        ?.patient_last_name ? (
                                    <DbkField
                                        formik={formik}
                                        index={index}
                                        type="text"
                                        fieldName="patient_last_name"
                                        fieldNameDbk="dbk_patient_last_name"
                                        fieldType="claims"
                                        setCorresPondanceFocus={setEobLiteFocus}
                                        corresPondanceFocus={eobLiteFocus}
                                    />
                                ) : null}
                            </div>
                        </InputGroup>
                    </div>
                    <div className="w-claim-from">
                        <InputGroup>
                            <InputGroup.Text>From</InputGroup.Text>

                            <Form.Control
                                data-disabled={!pdfLoaded}
                                type="number"
                                onFocus={handleChangeFrom}
                                name={`claims[${index}].page_from`}
                                onChange={formik.handleChange}
                                onKeyDown={(e) => {
                                    handlePreventEKeyPress(e);
                                }}
                                value={formik.values?.claims[index]?.page_from}
                                className={renderClass('page_from', 'NUMBER', 'page-claim-from')}
                            />

                        </InputGroup>
                    </div>
                    <div className="">
                        <InputGroup>
                            <InputGroup.Text>To</InputGroup.Text>
                            <Form.Control
                                data-disabled={!pdfLoaded}
                                type="number"
                                onFocus={handleChangeTo}
                                name={`claims[${index}].page_to`}
                                onChange={formik.handleChange}
                                value={formik.values?.claims[index]?.page_to}
                                onKeyDown={(e) => {
                                    handlePreventEKeyPress(e);
                                }}
                                className={renderClass('page_to', 'NUMBER', 'page-claim-to')}
                            />
                        </InputGroup>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Claim