import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
    createOrUpdateDbk, createOrUpdateOperationLog,
} from "../../../../../Redux/client/action";
import { changeAllTitleFunction } from "../../../Dashboard/functions";
import { CLIENT } from "../../../../../routeNames";
const OperationLog = (props) => {
    const [state, setState] = useState({
        error: false,
        error_message: "",
    });
    const {
        client_data,
        client,
        __toggleToast,
        setLoading,
        dbk_fields,
        fetchClientDetails,
    } = props;

    const [isSubmit, setIsSubmit] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            "operation_log_header_id": "",
            "client": '',
            "batch_name": false,
            "deposit_date": false,
            "export_date": false,
            "is_check": false,
            "aba_routing_number": false,
            "payer_account_number": false,
            "check_number": false,
            "check_date": false,
            "patient_account_number": false,
            "image_page_no": false,
            "check_amount": false,
            "payer_name": false,
            "status": false,
            "image_id": false,
            "zip_file_name": false,
            "reject_reason": false,
            "onbase_name": false,
            "correspondence": false,
            "statement_number": false,
            "harp_source": false,
            "amount_835": false,
            "date_of_service": false,
            "total_charge": false,
            "patient_last_name": false,
            "patient_first_name": false,
            "member_id": false,
            "patient_date_of_birth": false,
            "xpeditor_document_number": false,
            "xpeditor_default_value": "",
            "document_classification": false,
            "file_type_837": false,
            "client_code": false,
            "plb": false,
            "unique_identifier": false,
            "lockbox_id": false,
            "mrn": false,
            "payee_id": false,
            "service_provider_id": false,
            "payment_trans_code": false,
            "payment_method": false,
            "transaction_type": false,
            "claim_status": false,
            "ztech_payer_id": false,
            "ansi_line_of_business": false,
            "financial_class": false,
            "client_payer_id": false,
        },
        validationSchema,
        onSubmit: (values) => {
            setIsSubmit(true);
            setLoading(true);
            // values.client = client;
            // if (client_data?.dbk_fields?.id) {
            //     values.dbk_id = client_data?.dbk_fields?.id;
            // }
            createOrUpdateOperationLog(values)((response) => {
                if (
                    response?.status &&
                    response?.status_code === 200
                ) {
                    __toggleToast("success", response?.message, true);
                    setLoading(false);
                    setIsSubmit(false);
                    fetchClientDetails();
                } else {
                    const errors = response.response?.data?.errors;
                    formik.setErrors({ errors });
                    // props.changeState(response);
                    setLoading(false);
                    setIsSubmit(false);
                    __toggleToast(
                        "danger",
                        response?.response?.data?.message
                            ? response?.response?.data?.message
                            : "Something Went Wrong",
                        true
                    );
                }
            });
        },
    });

    useEffect(() => {
        if (client_data?.operation_log) {
            formik.setValues({
                "operation_log_header_id": client_data?.operation_log?.operation_log_header_id ? client_data?.operation_log?.operation_log_header_id : '',
                "client": client ? client : '',
                "batch_name": client_data?.operation_log?.batch_name ? client_data?.operation_log?.batch_name : false,
                "deposit_date": client_data?.operation_log?.deposit_date ? client_data?.operation_log?.deposit_date : false,
                "export_date": client_data?.operation_log?.export_date ? client_data?.operation_log?.export_date : false,
                "is_check": client_data?.operation_log?.is_check ? client_data?.operation_log?.is_check : false,
                "aba_routing_number": client_data?.operation_log?.aba_routing_number ? client_data?.operation_log?.aba_routing_number : false,
                "payer_account_number": client_data?.operation_log?.payer_account_number ? client_data?.operation_log?.payer_account_number : false,
                "check_number": client_data?.operation_log?.check_number ? client_data?.operation_log?.check_number : false,
                "check_date": client_data?.operation_log?.check_date ? client_data?.operation_log?.check_date : false,
                "patient_account_number": client_data?.operation_log?.patient_account_number ? client_data?.operation_log?.patient_account_number : false,
                "image_page_no": client_data?.operation_log?.image_page_no ? client_data?.operation_log?.image_page_no : false,
                "check_amount": client_data?.operation_log?.check_amount ? client_data?.operation_log?.check_amount : false,
                "payer_name": client_data?.operation_log?.payer_name ? client_data?.operation_log?.payer_name : false,
                "status": client_data?.operation_log?.status ? client_data?.operation_log?.status : false,
                "image_id": client_data?.operation_log?.image_id ? client_data?.operation_log?.image_id : false,
                "zip_file_name": client_data?.operation_log?.zip_file_name ? client_data?.operation_log?.zip_file_name : false,
                "reject_reason": client_data?.operation_log?.reject_reason ? client_data?.operation_log?.reject_reason : false,
                "onbase_name": client_data?.operation_log?.onbase_name ? client_data?.operation_log?.onbase_name : false,
                "correspondence": client_data?.operation_log?.correspondence ? client_data?.operation_log?.correspondence : false,
                "statement_number": client_data?.operation_log?.statement_number ? client_data?.operation_log?.statement_number : false,
                "harp_source": client_data?.operation_log?.harp_source ? client_data?.operation_log?.harp_source : false,
                "amount_835": client_data?.operation_log?.amount_835 ? client_data?.operation_log?.amount_835 : false,
                "date_of_service": client_data?.operation_log?.date_of_service ? client_data?.operation_log?.date_of_service : false,
                "total_charge": client_data?.operation_log?.total_charge ? client_data?.operation_log?.total_charge : false,
                "patient_last_name": client_data?.operation_log?.patient_last_name ? client_data?.operation_log?.patient_last_name : false,
                "patient_first_name": client_data?.operation_log?.patient_first_name ? client_data?.operation_log?.patient_first_name : false,
                "member_id": client_data?.operation_log?.member_id ? client_data?.operation_log?.member_id : false,
                "patient_date_of_birth": client_data?.operation_log?.patient_date_of_birth ? client_data?.operation_log?.patient_date_of_birth : false,
                "xpeditor_document_number": client_data?.operation_log?.xpeditor_document_number ? client_data?.operation_log?.xpeditor_document_number : false,
                "xpeditor_default_value": client_data?.operation_log?.xpeditor_default_value ? client_data?.operation_log?.xpeditor_default_value : "",
                "document_classification": client_data?.operation_log?.document_classification ? client_data?.operation_log?.document_classification : false,
                "file_type_837": client_data?.operation_log?.file_type_837 ? client_data?.operation_log?.file_type_837 : false,
                "client_code": client_data?.operation_log?.client_code ? client_data?.operation_log?.client_code : false,
                "plb": client_data?.operation_log?.plb ? client_data?.operation_log?.plb : false,
                "unique_identifier": client_data?.operation_log?.unique_identifier ? client_data?.operation_log?.unique_identifier : false,
                "lockbox_id": client_data?.operation_log?.lockbox_id ? client_data?.operation_log?.lockbox_id : false,
                "mrn": client_data?.operation_log?.mrn ? client_data?.operation_log?.mrn : false,
                "payee_id": client_data?.operation_log?.payee_id ? client_data?.operation_log?.payee_id : false,
                "service_provider_id": client_data?.operation_log?.service_provider_id ? client_data?.operation_log?.service_provider_id : false,
                "payment_trans_code": client_data?.operation_log?.payment_trans_code ? client_data?.operation_log?.payment_trans_code : false,
                "payment_method": client_data?.operation_log?.payment_method ? client_data?.operation_log?.payment_method : false,
                "transaction_type": client_data?.operation_log?.transaction_type ? client_data?.operation_log?.transaction_type : false,
                "claim_status": client_data?.operation_log?.claim_status ? client_data?.operation_log?.claim_status : false,
                "ztech_payer_id": client_data?.operation_log?.ztech_payer_id ? client_data?.operation_log?.ztech_payer_id : false,
                "ansi_line_of_business": client_data?.operation_log?.ansi_line_of_business ? client_data?.operation_log?.ansi_line_of_business : false,
                "financial_class": client_data?.operation_log?.financial_class ? client_data?.operation_log?.financial_class : false,
                "client_payer_id": client_data?.operation_log?.client_payer_id ? client_data?.operation_log?.client_payer_id : false
            });
        }
    }, [client_data?.operation_log]);

    const validationSchema = Yup.object().shape({
        "operation_log_header_id": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "client": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "batch_name": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "deposit_date": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "export_date": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "is_check": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "aba_routing_number": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "payer_account_number": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "check_number": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "check_date": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "patient_account_number": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "image_page_no": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "check_amount": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "payer_name": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "status": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "image_id": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "zip_file_name": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "reject_reason": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "onbase_name": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "correspondence": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "statement_number": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "harp_source": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "amount_835": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "date_of_service": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "total_charge": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "patient_last_name": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "patient_first_name": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "member_id": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "patient_date_of_birth": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "xpeditor_document_number": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "xpeditor_default_value": Yup.string(),
        "document_classification": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "file_type_837": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "client_code": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "plb": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "unique_identifier": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "lockbox_id": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "mrn": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "payee_id": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "service_provider_id": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "payment_trans_code": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "payment_method": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "transaction_type": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "claim_status": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "ztech_payer_id": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "ansi_line_of_business": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "financial_class": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        "client_payer_id": Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        )
    });


    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem("permissions"));
        var data = permissions.find((element) => {
            return element == check;
        });
        if (data && data != undefined) {
            return true;
        } else {
            return false;
        }
    };
    return (
        <React.Fragment>
            <Form onSubmit={formik.handleSubmit}>


                <div
                    className="tab-pane fade show active"
                    id="EOB-835"
                    role="tabpanel"
                    aria-labelledby="pills-EOB-835"
                >
                    <div className="dbk-grid-repeat gap-3 pb-3 border-bottom-dashed">

                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.batch_name}
                                isInvalid={
                                    formik.errors?.batch_name &&
                                    !!formik.errors?.batch_name
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.batch_name &&
                                    formik.errors?.batch_name
                                }
                                // id="validationFormik0"
                                name="batch_name"
                                label="Batch Name"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.deposit_date}
                                isInvalid={
                                    formik.errors?.deposit_date &&
                                    !!formik.errors?.deposit_date
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.deposit_date &&
                                    formik.errors?.deposit_date
                                }
                                // id="validationFormik0"
                                name="deposit_date"
                                label="Deposit Date"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.export_date}
                                isInvalid={
                                    formik.errors?.export_date &&
                                    !!formik.errors?.export_date
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.export_date &&
                                    formik.errors?.export_date
                                }
                                // id="validationFormik0"
                                name="export_date"
                                label="Export Date"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.is_check}
                                isInvalid={
                                    formik.errors?.is_check &&
                                    !!formik.errors?.is_check
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.is_check &&
                                    formik.errors?.is_check
                                }
                                // id="validationFormik0"
                                name="is_check"
                                label="Is Check"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.aba_routing_number}
                                isInvalid={
                                    formik.errors?.aba_routing_number &&
                                    !!formik.errors?.aba_routing_number
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.aba_routing_number &&
                                    formik.errors?.aba_routing_number
                                }
                                // id="validationFormik0"
                                name="aba_routing_number"
                                label="Aba Routing Number"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.payer_account_number}
                                isInvalid={
                                    formik.errors?.payer_account_number &&
                                    !!formik.errors?.payer_account_number
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.payer_account_number &&
                                    formik.errors?.payer_account_number
                                }
                                // id="validationFormik0"
                                name="payer_account_number"
                                label="Payer Account Number"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.check_number}
                                isInvalid={
                                    formik.errors?.check_number &&
                                    !!formik.errors?.check_number
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.check_number &&
                                    formik.errors?.check_number
                                }
                                // id="validationFormik0"
                                name="check_number"
                                label="Check Number"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.check_date}
                                isInvalid={
                                    formik.errors?.check_date &&
                                    !!formik.errors?.check_date
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.check_date &&
                                    formik.errors?.check_date
                                }
                                // id="validationFormik0"
                                name="check_date"
                                label="Check Date"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.patient_account_number}
                                isInvalid={
                                    formik.errors?.patient_account_number &&
                                    !!formik.errors?.patient_account_number
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.patient_account_number &&
                                    formik.errors?.patient_account_number
                                }
                                // id="validationFormik0"
                                name="patient_account_number"
                                label="Patient Account Number"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.image_page_no}
                                isInvalid={
                                    formik.errors?.image_page_no &&
                                    !!formik.errors?.image_page_no
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.image_page_no &&
                                    formik.errors?.image_page_no
                                }
                                // id="validationFormik0"
                                name="image_page_no"
                                label="Image Page No"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.check_amount}
                                isInvalid={
                                    formik.errors?.check_amount &&
                                    !!formik.errors?.check_amount
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.check_amount &&
                                    formik.errors?.check_amount
                                }
                                // id="validationFormik0"
                                name="check_amount"
                                label="Check Amount"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.payer_name}
                                isInvalid={
                                    formik.errors?.payer_name &&
                                    !!formik.errors?.payer_name
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.payer_name &&
                                    formik.errors?.payer_name
                                }
                                // id="validationFormik0"
                                name="payer_name"
                                label="Payer Name"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.status}
                                isInvalid={
                                    formik.errors?.status &&
                                    !!formik.errors?.status
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.status &&
                                    formik.errors?.status
                                }
                                // id="validationFormik0"
                                name="status"
                                label="Status"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.image_id}
                                isInvalid={
                                    formik.errors?.image_id &&
                                    !!formik.errors?.image_id
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.image_id &&
                                    formik.errors?.image_id
                                }
                                // id="validationFormik0"
                                name="image_id"
                                label="Image ID"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.zip_file_name}
                                isInvalid={
                                    formik.errors?.zip_file_name &&
                                    !!formik.errors?.zip_file_name
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.zip_file_name &&
                                    formik.errors?.zip_file_name
                                }
                                // id="validationFormik0"
                                name="zip_file_name"
                                label="Zip File Name"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.reject_reason}
                                isInvalid={
                                    formik.errors?.reject_reason &&
                                    !!formik.errors?.reject_reason
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.reject_reason &&
                                    formik.errors?.reject_reason
                                }
                                // id="validationFormik0"
                                name="reject_reason"
                                label="Reject Reason"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.onbase_name}
                                isInvalid={
                                    formik.errors?.onbase_name &&
                                    !!formik.errors?.onbase_name
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.onbase_name &&
                                    formik.errors?.onbase_name
                                }
                                // id="validationFormik0"
                                name="onbase_name"
                                label="OnBase Name"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.correspondence}
                                isInvalid={
                                    formik.errors?.correspondence &&
                                    !!formik.errors?.correspondence
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.correspondence &&
                                    formik.errors?.correspondence
                                }
                                // id="validationFormik0"
                                name="correspondence"
                                label="Correspondence"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.statement_number}
                                isInvalid={
                                    formik.errors?.statement_number &&
                                    !!formik.errors?.statement_number
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.statement_number &&
                                    formik.errors?.statement_number
                                }
                                // id="validationFormik0"
                                name="statement_number"
                                label="Statement Number"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.harp_source}
                                isInvalid={
                                    formik.errors?.harp_source &&
                                    !!formik.errors?.harp_source
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.harp_source &&
                                    formik.errors?.harp_source
                                }
                                // id="validationFormik0"
                                name="harp_source"
                                label="Harp Source"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.amount_835}
                                isInvalid={
                                    formik.errors?.amount_835 &&
                                    !!formik.errors?.amount_835
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.amount_835 &&
                                    formik.errors?.amount_835
                                }
                                // id="validationFormik0"
                                name="amount_835"
                                label="835 amount"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.date_of_service}
                                isInvalid={
                                    formik.errors?.date_of_service &&
                                    !!formik.errors?.date_of_service
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.date_of_service &&
                                    formik.errors?.date_of_service
                                }
                                // id="validationFormik0"
                                name="date_of_service"
                                label="Date Of Service"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.total_charge}
                                isInvalid={
                                    formik.errors?.total_charge &&
                                    !!formik.errors?.total_charge
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.total_charge &&
                                    formik.errors?.total_charge
                                }
                                // id="validationFormik0"
                                name="total_charge"
                                label="Total Charge"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.patient_last_name}
                                isInvalid={
                                    formik.errors?.patient_last_name &&
                                    !!formik.errors?.patient_last_name
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.patient_last_name &&
                                    formik.errors?.patient_last_name
                                }
                                // id="validationFormik0"
                                name="patient_last_name"
                                label="Patient Last Name"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.patient_first_name}
                                isInvalid={
                                    formik.errors?.patient_first_name &&
                                    !!formik.errors?.patient_first_name
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.patient_first_name &&
                                    formik.errors?.patient_first_name
                                }
                                // id="validationFormik0"
                                name="patient_first_name"
                                label="Patient First Name"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.member_id}
                                isInvalid={
                                    formik.errors?.member_id &&
                                    !!formik.errors?.member_id
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.member_id &&
                                    formik.errors?.member_id
                                }
                                // id="validationFormik0"
                                name="member_id"
                                label="Member ID"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.patient_date_of_birth}
                                isInvalid={
                                    formik.errors?.patient_date_of_birth &&
                                    !!formik.errors?.patient_date_of_birth
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.patient_date_of_birth &&
                                    formik.errors?.patient_date_of_birth
                                }
                                // id="validationFormik0"
                                name="patient_date_of_birth"
                                label="Patient Date Of Birth"
                            />
                        </div>
                        <div className="">
                            <div>

                                <Form.Check
                                    type="switch"
                                    reverse={true}
                                    onChange={formik.handleChange}
                                    checked={formik?.values?.xpeditor_document_number}
                                    isInvalid={
                                        formik.errors?.xpeditor_document_number &&
                                        !!formik.errors?.xpeditor_document_number
                                    }
                                    feedbackType="invalid"
                                    feedback={
                                        formik.errors?.xpeditor_document_number &&
                                        formik.errors?.xpeditor_document_number
                                    }
                                    // id="validationFormik0"
                                    name="xpeditor_document_number"
                                    label="Xpeditor Document Number"
                                />
                            </div>
                            <div>
                                {formik?.values?.xpeditor_document_number &&
                                    <Form.Control
                                        name="xpeditor_default_value"
                                        as="input"
                                        className='form-control-solid b-r-10 mt-2'
                                        style={{ width: '180px' }}
                                        onChange={formik.handleChange}
                                        placeholder='Xpeditor Default Value'
                                        value={formik.values?.xpeditor_default_value}
                                    />
                                }
                            </div>
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.document_classification}
                                isInvalid={
                                    formik.errors?.document_classification &&
                                    !!formik.errors?.document_classification
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.document_classification &&
                                    formik.errors?.document_classification
                                }
                                // id="validationFormik0"
                                name="document_classification"
                                label="Document Classification"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.file_type_837}
                                isInvalid={
                                    formik.errors?.file_type_837 &&
                                    !!formik.errors?.file_type_837
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.file_type_837 &&
                                    formik.errors?.file_type_837
                                }
                                // id="validationFormik0"
                                name="file_type_837"
                                label="837 File Type"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.client_code}
                                isInvalid={
                                    formik.errors?.client_code &&
                                    !!formik.errors?.client_code
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.client_code &&
                                    formik.errors?.client_code
                                }
                                // id="validationFormik0"
                                name="client_code"
                                label="Client Code"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.plb}
                                isInvalid={
                                    formik.errors?.plb &&
                                    !!formik.errors?.plb
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.plb &&
                                    formik.errors?.plb
                                }
                                // id="validationFormik0"
                                name="plb"
                                label="PLB"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.unique_identifier}
                                isInvalid={
                                    formik.errors?.unique_identifier &&
                                    !!formik.errors?.unique_identifier
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.unique_identifier &&
                                    formik.errors?.unique_identifier
                                }
                                // id="validationFormik0"
                                name="unique_identifier"
                                label="Unique Identifier"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.lockbox_id}
                                isInvalid={
                                    formik.errors?.lockbox_id &&
                                    !!formik.errors?.lockbox_id
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.lockbox_id &&
                                    formik.errors?.lockbox_id
                                }
                                // id="validationFormik0"
                                name="lockbox_id"
                                label="Lockbox ID"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.mrn}
                                isInvalid={
                                    formik.errors?.mrn &&
                                    !!formik.errors?.mrn
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.mrn &&
                                    formik.errors?.mrn
                                }
                                // id="validationFormik0"
                                name="mrn"
                                label="MRN"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.payee_id}
                                isInvalid={
                                    formik.errors?.payee_id &&
                                    !!formik.errors?.payee_id
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.payee_id &&
                                    formik.errors?.payee_id
                                }
                                // id="validationFormik0"
                                name="payee_id"
                                label="Payee ID"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.service_provider_id}
                                isInvalid={
                                    formik.errors?.service_provider_id &&
                                    !!formik.errors?.service_provider_id
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.service_provider_id &&
                                    formik.errors?.service_provider_id
                                }
                                // id="validationFormik0"
                                name="service_provider_id"
                                label="Service Provider ID"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.payment_trans_code}
                                isInvalid={
                                    formik.errors?.payment_trans_code &&
                                    !!formik.errors?.payment_trans_code
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.payment_trans_code &&
                                    formik.errors?.payment_trans_code
                                }
                                // id="validationFormik0"
                                name="payment_trans_code"
                                label="Payment Trans Code"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.payment_method}
                                isInvalid={
                                    formik.errors?.payment_method &&
                                    !!formik.errors?.payment_method
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.payment_method &&
                                    formik.errors?.payment_method
                                }
                                // id="validationFormik0"
                                name="payment_method"
                                label="Payment Method"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.transaction_type}
                                isInvalid={
                                    formik.errors?.transaction_type &&
                                    !!formik.errors?.transaction_type
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.transaction_type &&
                                    formik.errors?.transaction_type
                                }
                                // id="validationFormik0"
                                name="transaction_type"
                                label="Transaction Type"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.claim_status}
                                isInvalid={
                                    formik.errors?.claim_status &&
                                    !!formik.errors?.claim_status
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.claim_status &&
                                    formik.errors?.claim_status
                                }
                                // id="validationFormik0"
                                name="claim_status"
                                label="Claim Status"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.ztech_payer_id}
                                isInvalid={
                                    formik.errors?.ztech_payer_id &&
                                    !!formik.errors?.ztech_payer_id
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.ztech_payer_id &&
                                    formik.errors?.ztech_payer_id
                                }
                                // id="validationFormik0"
                                name="ztech_payer_id"
                                label="ZTECH Payer ID"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.ansi_line_of_business}
                                isInvalid={
                                    formik.errors?.ansi_line_of_business &&
                                    !!formik.errors?.ansi_line_of_business
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.ansi_line_of_business &&
                                    formik.errors?.ansi_line_of_business
                                }
                                // id="validationFormik0"
                                name="ansi_line_of_business"
                                label="ANSI Line of Business"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.financial_class}
                                isInvalid={
                                    formik.errors?.financial_class &&
                                    !!formik.errors?.financial_class
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.financial_class &&
                                    formik.errors?.financial_class
                                }
                                // id="validationFormik0"
                                name="financial_class"
                                label="Financial Class"
                            />
                        </div>
                        <div className="">
                            <Form.Check
                                type="switch"
                                reverse={true}
                                onChange={formik.handleChange}
                                checked={formik?.values?.client_payer_id}
                                isInvalid={
                                    formik.errors?.client_payer_id &&
                                    !!formik.errors?.client_payer_id
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.client_payer_id &&
                                    formik.errors?.client_payer_id
                                }
                                // id="validationFormik0"
                                name="client_payer_id"
                                label="Client Payer ID"
                            />
                        </div>


                    </div>

                </div>



                <div className="mb-3"></div>

                <div>
                    {state.error && (
                        <span className="text-danger">{state.error_message}</span>
                    )}
                </div>
                <div className="d-flex flex-row justify-content-end">
                    <div className="">
                        <button
                            type="button"
                            className="btn btn-secondary btn-active-primary me-2"
                            onClick={() => {
                                changeAllTitleFunction('Client Setup', CLIENT);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                    {checkUserPermission("edit_client") && (
                        <div className="">
                            <button
                                disabled={isSubmit || !formik.isValid}
                                type="submit"
                                className="btn btn-primary btnHover"
                            >
                                {isSubmit ? "Saving" : 'Save'}
                            </button>
                        </div>
                    )}
                </div>
            </Form>
        </React.Fragment>
    );
};

export default OperationLog;
