import React from 'react';
import { useParams } from 'react-router-dom';
import {
    CLIENT,
    CORRESPONDENCE_CODES,
    DASHBOARD,
    DATA_ENTRY,
    GROUPS,
    HOLIDAY_CALENDAR,
    PAYEE,
    PAYER,
    REASON_CODES,
    ROLES,
    SETTINGS,
    USERS,
    X12_CODES,
    FILE_SPLIT,
    QMS,
    QMSQACOMPlETED,
    LOCKBOXFILES,
    LOCKBOX,
    REJECT_REASONS,
    CLIENT_GROUP,
    GET_PATIENT_PAY,
    GET_CORRESPONDANCE,
    GET_ENCOUNTER_LIST,
    GET_835_LIST,
    CREATE_PAYER,
    EDIT_PAYER,
    EDIT_PAYEE,
    CREATE_PAYEE,
    SFTP_CLIENT_LIST,
    EDIT_HOLIDAY_CALENDAR,
    CREATE_HOLIDAY_CALENDAR,
    CREATE_REJECT_REASONS,
    EDIT_REJECT_REASONS,
    EDIT_REASON_CODES,
    CREATE_REASON_CODES,
    CREATE_CLAIMS_FILING,
    EDIT_CLAIMS_FILING,
    CLAIMS_FILING,
    EDIT_CORRESPONDENCE_CODE,
    CREATE_CORRESPONDENCE_CODE,
    EDIT_CLIENT_GROUP,
    CREATE_CLIENT_GROUP,
    CREATE_ASSIGN_USERS,
    SINGLE_ASSIGN_GROUP_USERS,
    EDIT_CLIENT,
    CREATE_CLIENT,
    COPY_CLIENT,
    CREATE_ROLES,
    EDIT_ROLES,
    CREATE_GROUPS,
    EDIT_GROUPS,
    CREATE_USERS,
    EDIT_USERS,
    QA_REVIEW,
    GET_835_LITE_LIST,
    PARTNER_LIST,
    PARTNER_CREATE,
    PARTNER_EDIT,
    DOCUMENT_CLASSIFICATION,
    REASON,
    SINGLE_LOCKBOX_LOCKBOX_FILES,
    CREATE_REASON,
    EDIT_REASON,
    EMAIL_TEMPLATE,
    DBK_LIST,
    CREATE_DBK,
    SFTP_LOG_LIST,
    OFFLINE_STATUS,
    EMAIL_LOG_LIST,
    HOUSE_KEEPING_LOG_LIST,
    GET_ACKNOWLEDGMENT_LIST,
    SNIPPET,
    PAYER_ID_LIST,
    CREATE_PAYER_ID_LIST,
    EDIT_PAYER_ID_LIST
} from './../../../routeNames';
import { useSelector } from 'react-redux';
import { changeAllTitleFunction } from '../../../components/DashBoard/Dashboard/functions';
import { IoCloudOfflineOutline } from "react-icons/io5";
import Swal from 'sweetalert2';

const Sidebar = (props) => {
    const bottom_tabs = useSelector((state) => state?.bottomTab);
    let { role_id, id, group_id, payee_id, payer_id, user_id, job_id, file_id, copy_id, lockbox_no, claimsFiling_id, reason_id } = useParams();
    const { user } = props;

    const checkUserPermission = (check) => {
        let status = false
        if (user?.is_superuser) {
            status = true;
        }
        else {
            if (localStorage.getItem('permissions') != 'undefined' && localStorage.getItem('permissions')) {
                var permissions = JSON.parse(localStorage.getItem('permissions'))
                status = permissions.includes(check)
            }
        }
        return status
    }


    const changeTitleFunction = (event) => {


        let duplicate = false;
        const parentElement = event.target.closest('li');
        const tabs = JSON.parse(localStorage.getItem('bottomTab'))
        const linkText = parentElement.querySelector('span').textContent; // Gets the text content of the span element
        const linkUrl = parentElement.querySelector('div').getAttribute('data-link'); // Gets the text content of the span element
        let title = null
        if (linkText == 'My Work' || linkText == 'QA Review' || linkText == 'Snipping') {
            tabs?.bottomTabs?.map((tab, index) => {
                if (tab?.title == 'My Work' || tab?.title == 'QA Review') {
                    duplicate = true
                    title = "My Work/QA Review is already opened"
                } else if (tab?.title == "Snipping") {
                    duplicate = true
                    title = "Snipping is already opened"

                }
            })
        }
        if (duplicate) {
            Swal.fire({
                title: title,
                confirmButtonColor: '#1ba1a6',
                backdrop: true,
                allowOutsideClick: false,
                icon: 'warning',
                allowEscapeKey: true,
                customClass: {
                    title: 'fs-5'
                }
            });
        }
        else if (tabs.currentTab.label !== linkText) {
            window.parent.localStorage.setItem('clearFilterData', 'true')

            changeAllTitleFunction(linkText, linkUrl)
        } else if (linkText === "Dashboard") {
            window.location.reload()
        }
    }

    return (
        <React.Fragment>
            <aside id="navbarToggleExternalContent" className="sidebar">
                <div className="l-navbar" id="navbar">
                    <nav className="nav nav-padding hide-scroll-bar">
                        <ul className="sidebar-nav" id="sidebar-nav">

                            <li className="nav-item">
                                <div onClick={(e) => { changeTitleFunction(e) }} data-link={DASHBOARD} className="nav-link active bottom-dashboard">
                                    <i className="bi bi-grid"></i>
                                    <span>Dashboard</span>
                                </div>
                            </li>
                            {checkUserPermission('view_job_monitor') &&
                                <li onClick={(e) => { changeTitleFunction(e) }} className="nav-item">
                                    <div className={window.location.pathname == QMS ? "nav-link active" : "nav-link"} data-link={QMS}>
                                        <i className="bi bi-person-lines-fill"></i><span>Job Monitor </span>
                                    </div>
                                </li>
                            }
                            {checkUserPermission('my_work') &&
                                <li onClick={(e) => { changeTitleFunction(e) }} className="nav-item">
                                    <div className="nav-link" data-link={DATA_ENTRY}>
                                        <i className="bi bi-journal-text"></i><span>My Work</span>
                                    </div>
                                </li>
                            }
                            {checkUserPermission('qa_review') &&
                                <li onClick={(e) => { changeTitleFunction(e) }} className="nav-item">
                                    <div className="nav-link" data-link={QA_REVIEW}>
                                        <i className="bi bi-journal-text"></i><span>QA Review</span>
                                    </div>
                                </li>
                            }
                            {checkUserPermission('snippet') &&
                                <li onClick={(e) => { changeTitleFunction(e) }} className="nav-item">
                                    <div className="nav-link" data-link={SNIPPET}>
                                        <i className="bi bi-journal-text"></i><span>Snipping</span>
                                    </div>
                                </li>
                            }
                            {checkUserPermission('view_sftp') || checkUserPermission('view_lock_box') || checkUserPermission('view_file_split') || checkUserPermission('view_lockbox_files') || checkUserPermission('view_837_list') ?
                                <li className="nav-item">
                                    <a className="nav-link collapsed" data-bs-target="#file-managment" data-bs-toggle="collapse" href="#">
                                        <i className="bi bi-files"></i><span>File Management</span><i className="bi bi-chevron-down ms-auto"></i>
                                    </a>
                                    <ul id="file-managment"
                                        className={window.location.pathname == FILE_SPLIT
                                            || window.location.pathname == SFTP_CLIENT_LIST
                                            || window.location.pathname == QMSQACOMPlETED
                                            || window.location.pathname == LOCKBOX
                                            || window.location.pathname == LOCKBOXFILES
                                            || window.location.pathname == GET_ENCOUNTER_LIST
                                            || window.location.pathname == SINGLE_LOCKBOX_LOCKBOX_FILES + file_id

                                            ? "nav-content" : "nav-content collapse"}
                                        data-bs-parent="#sidebar-nav">
                                        {checkUserPermission('view_sftp') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == SFTP_CLIENT_LIST ? "nav-link active" : "nav-link"} data-link={SFTP_CLIENT_LIST}>
                                                    <i className="bi bi-dot"></i><span>SFTP Pull </span>
                                                </div>
                                            </li>
                                        }
                                        {/* {checkUserPermission('view_sftp_log') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == SFTP_LOG_LIST ? "nav-link active" : "nav-link"} data-link={SFTP_LOG_LIST}>
                                                    <i className="bi bi-dot"></i><span>SFTP Log </span>
                                                </div>
                                            </li>
                                        } */}
                                        {checkUserPermission('view_lock_box') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == LOCKBOX ? "nav-link active" : "nav-link"} data-link={LOCKBOX}>
                                                    <i className="bi bi-dot"></i><span>LockBox </span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_lockbox_files') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == LOCKBOXFILES || window.location.pathname == SINGLE_LOCKBOX_LOCKBOX_FILES + decodeURIComponent(file_id).replace(/%20/g, ' ') ? "nav-link active" : "nav-link"} data-link={LOCKBOXFILES}>
                                                    <i className="bi bi-dot"></i><span>LockBox Files</span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_file_split') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == FILE_SPLIT ? "nav-link active" : "nav-link"} data-link={FILE_SPLIT}>
                                                    <i className="bi bi-dot"></i><span>File Split</span>
                                                </div>
                                            </li>
                                        }

                                        {checkUserPermission('view_837_list') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == GET_ENCOUNTER_LIST ? "nav-link active" : "nav-link"} data-link={GET_ENCOUNTER_LIST}>
                                                    <i className="bi bi-dot"></i><span>837 List </span>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </li>
                                :
                                <React.Fragment></React.Fragment>
                            }
                            {checkUserPermission('view_patient_pay') || checkUserPermission('view_correspondence') || checkUserPermission('view_835_lite') || checkUserPermission('view_835_list') || checkUserPermission('view_acknowledgement') ?
                                <li className="nav-item">
                                    <a className="nav-link collapsed" data-bs-target="#output-schema" data-bs-toggle="collapse" href="#">
                                        <i className="bi bi-files"></i><span>Output Schema</span><i className="bi bi-chevron-down ms-auto"></i>
                                    </a>
                                    <ul id="output-schema"
                                        className={window.location.pathname == GET_PATIENT_PAY
                                            || window.location.pathname == GET_CORRESPONDANCE
                                            || window.location.pathname == GET_835_LIST
                                            || window.location.pathname == GET_835_LITE_LIST
                                            || window.location.pathname == GET_ACKNOWLEDGMENT_LIST


                                            ? "nav-content  " : "nav-content collapse"}
                                        data-bs-parent="#sidebar-nav">

                                        {checkUserPermission('view_835_list') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == GET_835_LIST ? "nav-link active" : "nav-link"} data-link={GET_835_LIST}>
                                                    <i className="bi bi-dot"></i><span>835 List </span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_835_lite') &&
                                            <React.Fragment>


                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == GET_835_LITE_LIST ? "nav-link active" : "nav-link"} data-link={GET_835_LITE_LIST}>
                                                        <i className="bi bi-dot"></i><span>835 Lite </span>
                                                    </div>
                                                </li>
                                            </React.Fragment>
                                        }
                                        {checkUserPermission('view_patient_pay') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == GET_PATIENT_PAY ? "nav-link active" : "nav-link"} data-link={GET_PATIENT_PAY}>
                                                    <i className="bi bi-dot"></i><span>Patient Pay </span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_correspondence') &&
                                            <React.Fragment>

                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == GET_CORRESPONDANCE ? "nav-link active" : "nav-link"} data-link={GET_CORRESPONDANCE}>
                                                        <i className="bi bi-dot"></i><span>Correspondence </span>
                                                    </div>
                                                </li>

                                            </React.Fragment>
                                        }
                                        {checkUserPermission('view_acknowledgement') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == GET_ACKNOWLEDGMENT_LIST ? "nav-link active" : "nav-link"} data-link={GET_ACKNOWLEDGMENT_LIST}>
                                                    <i className="bi bi-dot"></i><span>Acknowledgement </span>
                                                </div>
                                            </li>
                                        }
                                    </ul>
                                </li>
                                :
                                <React.Fragment></React.Fragment>
                            }

                            {checkUserPermission('view_partner') ?
                                <li className="nav-item">
                                    <a className={window.location.pathname == PARTNER_LIST
                                        || window.location.pathname == PARTNER_CREATE
                                        || window.location.pathname == PARTNER_EDIT

                                        ? "nav-link  " : "nav-link collapsed"} data-bs-target="#partner-nav" data-bs-toggle="collapse" href="#">
                                        <i className="bi bi-people-fill"></i><span>Partners</span><i className="bi bi-chevron-down ms-auto"></i>
                                    </a>
                                    <ul id="partner-nav" className={window.location.pathname == PARTNER_LIST
                                        || window.location.pathname == PARTNER_CREATE
                                        || window.location.pathname == PARTNER_EDIT
                                        ? "nav-content  " : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                                        <li onClick={(e) => { changeTitleFunction(e) }}>
                                            <div className="nav-link" data-link={PARTNER_LIST}>
                                                <i className="bi bi-dot"></i><span>Partners</span>
                                            </div>
                                        </li>

                                    </ul>
                                </li>
                                :
                                <React.Fragment></React.Fragment>
                            }
                            {checkUserPermission('view_client_group') || checkUserPermission('view_client') ?
                                <li className="nav-item">
                                    <a className={window.location.pathname == CLIENT_GROUP
                                        || window.location.pathname == CREATE_CLIENT_GROUP
                                        || window.location.pathname == EDIT_CLIENT_GROUP
                                        || window.location.pathname == CREATE_ASSIGN_USERS
                                        || window.location.pathname == SINGLE_ASSIGN_GROUP_USERS
                                        || window.location.pathname == CLIENT
                                        || window.location.pathname == CREATE_CLIENT
                                        || window.location.pathname == EDIT_CLIENT
                                        || window.location.pathname == COPY_CLIENT

                                        ? "nav-link  " : "nav-link collapsed"} data-bs-target="#clients-nav" data-bs-toggle="collapse" href="#">
                                        <i className="bi bi-person-bounding-box"></i><span>Clients</span><i className="bi bi-chevron-down ms-auto"></i>
                                    </a>
                                    <ul id="clients-nav" className={window.location.pathname == CLIENT_GROUP
                                        || window.location.pathname == CREATE_CLIENT_GROUP
                                        || window.location.pathname == EDIT_CLIENT_GROUP
                                        || window.location.pathname == CREATE_ASSIGN_USERS
                                        || window.location.pathname == SINGLE_ASSIGN_GROUP_USERS
                                        || window.location.pathname == CLIENT
                                        || window.location.pathname == CREATE_CLIENT
                                        || window.location.pathname == EDIT_CLIENT + id
                                        || window.location.pathname == COPY_CLIENT + copy_id
                                        ? "nav-content  " : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                                        {checkUserPermission('view_client') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className="nav-link" data-link={CLIENT}>
                                                    <i className="bi bi-dot"></i><span>Client Setup</span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_client_group') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className="nav-link" data-link={CLIENT_GROUP}>
                                                    <i className="bi bi-dot"></i><span>Client Groups</span>
                                                </div>
                                            </li>
                                        }

                                    </ul>
                                </li>
                                :
                                <React.Fragment></React.Fragment>
                            }

                            {checkUserPermission('view_role') || checkUserPermission('view_group') || checkUserPermission('view_users') || checkUserPermission('view_userwise_dbk') ?
                                <li className="nav-item">
                                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                                        <i className="bi bi-person"></i><span>User Management</span><i className="bi bi-chevron-down ms-auto"></i>
                                    </a>
                                    <ul id="components-nav" className={window.location.pathname == ROLES
                                        || window.location.pathname == CREATE_ROLES
                                        || window.location.pathname == EDIT_ROLES + role_id
                                        || window.location.pathname == GROUPS
                                        || window.location.pathname == CREATE_GROUPS
                                        || window.location.pathname == EDIT_GROUPS + group_id
                                        || window.location.pathname == USERS
                                        || window.location.pathname == CREATE_USERS
                                        || window.location.pathname == EDIT_USERS + user_id
                                        || window.location.pathname == DBK_LIST
                                        || window.location.pathname == CREATE_DBK

                                        ? "nav-content  " : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                                        {checkUserPermission('view_role') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className="nav-link" data-link={ROLES}>
                                                    <i className="bi bi-dot"></i><span>Roles</span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_group') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className="nav-link" data-link={GROUPS}>
                                                    <i className="bi bi-dot"></i><span>Groups</span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_users') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className="nav-link" data-link={USERS}>
                                                    <i className="bi bi-dot"></i><span>Users</span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_userwise_dbk') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className="nav-link" data-link={DBK_LIST}>
                                                    <i className="bi bi-dot"></i><span>Userwise DBK</span>
                                                </div>
                                            </li>
                                        }

                                    </ul>
                                </li>
                                :
                                <React.Fragment></React.Fragment>
                            }

                            {checkUserPermission('view_payee') ||
                                checkUserPermission('view_payer') ||
                                checkUserPermission('view_payer_id_list') ||
                                checkUserPermission('view_reason_code') ||
                                checkUserPermission('view_x12_code') ||
                                checkUserPermission('view_correspondence_category') ||
                                checkUserPermission('view_reject_reasons') ||
                                checkUserPermission('view_holiday_calender') ||
                                checkUserPermission('view_document_classification') ||
                                checkUserPermission('view_incomplete_status') ||
                                checkUserPermission('view_allowed_values') ||
                                checkUserPermission('view_email_templates') ?
                                <React.Fragment>
                                    <li className="nav-item">
                                        <a
                                            className={window.location.pathname == PAYER
                                                || window.location.pathname == CREATE_PAYER
                                                || window.location.pathname == EDIT_PAYER + payer_id
                                                || window.location.pathname == PAYEE
                                                || window.location.pathname == CREATE_PAYEE
                                                || window.location.pathname == EDIT_PAYEE + payee_id
                                                || window.location.pathname == HOLIDAY_CALENDAR
                                                || window.location.pathname == CREATE_HOLIDAY_CALENDAR
                                                || window.location.pathname == EDIT_HOLIDAY_CALENDAR + id
                                                || window.location.pathname == REASON_CODES
                                                || window.location.pathname == CREATE_REASON_CODES
                                                || window.location.pathname == EDIT_REASON_CODES + id
                                                || window.location.pathname == CREATE_CLAIMS_FILING
                                                || window.location.pathname == EDIT_CLAIMS_FILING + id
                                                || window.location.pathname == CLAIMS_FILING
                                                || window.location.pathname == X12_CODES
                                                || window.location.pathname == CORRESPONDENCE_CODES
                                                || window.location.pathname == CREATE_CORRESPONDENCE_CODE
                                                || window.location.pathname == EDIT_CORRESPONDENCE_CODE + id
                                                || window.location.pathname == REJECT_REASONS
                                                || window.location.pathname == CREATE_REJECT_REASONS
                                                || window.location.pathname == EDIT_REJECT_REASONS + id
                                                || window.location.pathname == DOCUMENT_CLASSIFICATION
                                                || window.location.pathname == REASON
                                                ? "nav-link  " : "nav-link collapsed"}
                                            data-bs-target="#components-nav1" data-bs-toggle="collapse" href="#">
                                            <i className="bi bi-person"></i><span>Master</span><i className="bi bi-chevron-down ms-auto"></i>
                                        </a>
                                        <ul id="components-nav1"
                                            className={window.location.pathname == PAYER
                                                || window.location.pathname == CREATE_PAYER
                                                || window.location.pathname == EDIT_PAYER + payer_id
                                                || window.location.pathname == PAYEE
                                                || window.location.pathname == CREATE_PAYEE
                                                || window.location.pathname == EDIT_PAYEE + payee_id
                                                || window.location.pathname == PAYER_ID_LIST
                                                || window.location.pathname == CREATE_PAYER_ID_LIST
                                                || window.location.pathname == EDIT_PAYER_ID_LIST + payee_id
                                                || window.location.pathname == HOLIDAY_CALENDAR
                                                || window.location.pathname == CREATE_HOLIDAY_CALENDAR
                                                || window.location.pathname == EDIT_HOLIDAY_CALENDAR + id
                                                || window.location.pathname == REASON_CODES
                                                || window.location.pathname == CREATE_REASON_CODES
                                                || window.location.pathname == EDIT_REASON_CODES + id
                                                || window.location.pathname == CREATE_CLAIMS_FILING
                                                || window.location.pathname == EDIT_CLAIMS_FILING + id
                                                || window.location.pathname == CLAIMS_FILING
                                                || window.location.pathname == X12_CODES
                                                || window.location.pathname == CORRESPONDENCE_CODES
                                                || window.location.pathname == CREATE_CORRESPONDENCE_CODE
                                                || window.location.pathname == EDIT_CORRESPONDENCE_CODE + id
                                                || window.location.pathname == REJECT_REASONS
                                                || window.location.pathname == CREATE_REJECT_REASONS
                                                || window.location.pathname == EDIT_REJECT_REASONS + id
                                                || window.location.pathname == DOCUMENT_CLASSIFICATION
                                                || window.location.pathname == EDIT_CLAIMS_FILING + claimsFiling_id
                                                || window.location.pathname == REASON
                                                || window.location.pathname == CREATE_REASON
                                                || window.location.pathname == EDIT_REASON + reason_id
                                                || window.location.pathname == EMAIL_TEMPLATE
                                                ? "nav-content  " : "nav-content collapse"}
                                            data-bs-parent="#sidebar-nav"
                                        >
                                            {checkUserPermission('view_payee') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == PAYEE || window.location.pathname == PAYEE
                                                        || window.location.pathname == CREATE_PAYEE
                                                        || window.location.pathname == EDIT_PAYEE + ":id" ? "nav-link active" : "nav-link"} data-link={PAYEE}>
                                                        <i className="bi bi-dot"></i><span>Payee</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_payer') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == PAYER
                                                        || window.location.pathname == CREATE_PAYER
                                                        || window.location.pathname == EDIT_PAYER ? "nav-link active" : "nav-link"} data-link={PAYER}>
                                                        <i className="bi bi-dot"></i><span>Payer</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_payer_id_list') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == PAYER_ID_LIST
                                                        || window.location.pathname == CREATE_PAYER_ID_LIST
                                                        || window.location.pathname == EDIT_PAYER_ID_LIST ? "nav-link active" : "nav-link"} data-link={PAYER_ID_LIST}>
                                                        <i className="bi bi-dot"></i><span>Payer ID List</span>
                                                    </div>
                                                </li>
                                            }

                                            {checkUserPermission('view_reason_code') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == REASON_CODES ? "nav-link active" : "nav-link"} data-link={REASON_CODES}>
                                                        <i className="bi bi-dot"></i><span>Reason Codes</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_x12_code') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == X12_CODES ? "nav-link active" : "nav-link"} data-link={X12_CODES}>
                                                        <i className="bi bi-dot"></i><span>X12 Codes</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_correspondence_category') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == CORRESPONDENCE_CODES ? "nav-link active" : "nav-link"} data-link={CORRESPONDENCE_CODES}>
                                                        <i className="bi bi-dot"></i><span>Correspondence Category</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_reject_reasons') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == REJECT_REASONS ? "nav-link active" : "nav-link"} data-link={REJECT_REASONS}>
                                                        <i className="bi bi-dot"></i><span>Rejection Reasons</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_holiday_calender') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == HOLIDAY_CALENDAR ? "nav-link active" : "nav-link"} data-link={HOLIDAY_CALENDAR}>
                                                        <i className="bi bi-dot"></i><span>Holiday Calendar</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_document_classification') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == DOCUMENT_CLASSIFICATION ? "nav-link active" : "nav-link"} data-link={DOCUMENT_CLASSIFICATION}>
                                                        <i className="bi bi-dot"></i><span>Document Classification</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_incomplete_status') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == REASON ? "nav-link active" : "nav-link"} data-link={REASON}>
                                                        <i className="bi bi-dot"></i><span>Incomplete Status</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_allowed_values') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == CLAIMS_FILING ? "nav-link active" : "nav-link"} data-link={CLAIMS_FILING}>
                                                        <i className="bi bi-dot"></i><span>Allowed Values</span>
                                                    </div>
                                                </li>
                                            }
                                            {checkUserPermission('view_email_templates') &&
                                                <li onClick={(e) => { changeTitleFunction(e) }}>
                                                    <div className={window.location.pathname == EMAIL_TEMPLATE ? "nav-link active" : "nav-link"} data-link={EMAIL_TEMPLATE}>
                                                        <i className="bi bi-dot"></i><span>Email Templates</span>
                                                    </div>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                            }
                            <React.Fragment>
                                {checkUserPermission('view_system_settings') &&
                                    <li onClick={(e) => { changeTitleFunction(e) }} className="nav-item">
                                        <div className="nav-link" data-link={SETTINGS}>
                                            <i className="bi bi-gear"></i><span>System Settings</span>
                                        </div>
                                    </li>
                                }
                            </React.Fragment>
                            <React.Fragment>
                                {checkUserPermission('view_offline_running_jobs') &&
                                    <li onClick={(e) => { changeTitleFunction(e) }} className="nav-item">
                                        <div className="nav-link" data-link={OFFLINE_STATUS}>
                                            <i className=''> <IoCloudOfflineOutline /></i>
                                            <span>Offline Task</span>
                                        </div>
                                    </li>
                                }
                            </React.Fragment>
                            {checkUserPermission('view_log_files') || checkUserPermission('view_sftp_log') ?
                                <li className="nav-item">
                                    <a className={window.location.pathname == SFTP_LOG_LIST
                                        || window.location.pathname == EMAIL_LOG_LIST
                                        || window.location.pathname == HOUSE_KEEPING_LOG_LIST

                                        ? "nav-link  " : "nav-link collapsed"} data-bs-target="#log-nav" data-bs-toggle="collapse" href="#">
                                        <i className="bi bi-people-fill"></i><span>Logs</span><i className="bi bi-chevron-down ms-auto"></i>
                                    </a>
                                    <ul id="log-nav"
                                        className={window.location.pathname == EMAIL_LOG_LIST
                                            || window.location.pathname == SFTP_LOG_LIST

                                            ? "nav-content" : "nav-content collapse"}
                                        data-bs-parent="#sidebar-nav">

                                        {checkUserPermission('view_log_files') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == EMAIL_LOG_LIST ? "nav-link active" : "nav-link"} data-link={EMAIL_LOG_LIST}>
                                                    <i className="bi bi-dot"></i><span>Email Log </span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_sftp_log') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == SFTP_LOG_LIST ? "nav-link active" : "nav-link"} data-link={SFTP_LOG_LIST}>
                                                    <i className="bi bi-dot"></i><span>SFTP Log </span>
                                                </div>
                                            </li>
                                        }
                                        {checkUserPermission('view_house_keeping_log_files') &&
                                            <li onClick={(e) => { changeTitleFunction(e) }}>
                                                <div className={window.location.pathname == HOUSE_KEEPING_LOG_LIST ? "nav-link active" : "nav-link"} data-link={HOUSE_KEEPING_LOG_LIST}>
                                                    <i className="bi bi-dot"></i><span>House Keeping Log </span>
                                                </div>
                                            </li>
                                        }

                                    </ul>
                                </li>
                                :
                                <React.Fragment></React.Fragment>
                            }
                        </ul>
                    </nav>
                </div>
            </aside>
        </React.Fragment>
    )
}

export default Sidebar;