import React, { useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap';
import Master from '../../../../Layout/admin/Master';
import { Formik, Field, FormikProvider, useFormik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { createX12Code, getSingleX12Code } from '../../../../Redux/user/action';
import { Link, useNavigate } from 'react-router-dom';
import { DatePicker } from 'rsuite';
import { X12_CODES } from '../../../../routeNames';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import moment from 'moment';

const options = [];
function CreateX12Code() {

    const navigate = useNavigate();

    const [toaster, setToaster] = useState(false)
    const [datetime, setDateTime] = useState("");
    const [state, setState] = useState({
        error: false,
        error_message: ""
    })
    const SignupSchema = Yup.object().shape({
        description: Yup.string()
            .required('Required'),
        code: Yup.string(),


    });
    const formik = useFormik({
        initialValues: {


            type: '',
            description: "",
            code: '',
            start_date: "",
            stop_date: "",


        },
        validate: (values) => {
            let errors = {}

            if (values?.type == "") {
                errors.type = "Select Type"
            }

            return errors;
        },

        validationSchema: Yup.object().shape({


            type: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            code: Yup.string().required('Required').max(10, 'Ensure this field have no more than 10 charecters').matches(/^[ A-Za-z0-9_@./#&+-]*$/, 'Enter a valid Code'),
            start_date: Yup.date(),
            stop_date: Yup.date()
                .min(
                    Yup.ref('start_date'),
                    'Stop date must be greater than or equal to the start date'
                ),


        }),
        onSubmit: (values) => {
            __submitForm(values)
        },
    });

    const __submitForm = (values) => {
        const RedirectId = getRedirectTabId()
        createX12Code(values?.type, values?.code, values?.description, values?.start_date, values?.stop_date)((response) => {

            if (response && response.status) {
                setToaster('success')
                setTimeout(() => {
                    // setToaster('success')
                    changeAllTitleFunction("X12 Codes", X12_CODES, RedirectId)
                }, 2000);
                // return () => clearTimeout(timer);

            }
            else {
                setToaster('error')
                setState({ error_message: response?.response?.data?.errors.x12_codes[0] })
                const timer = setTimeout(() => {
                    setToaster(false)
                }, 2000);
                return () => clearTimeout(timer);
            }

            setDateTime("")

        });
    }

    const handleOnDateTimeChange = ({ name, value }) => {

        let created_date = ""
        if (value !== null) {
            const dateFrom = moment(value).format('MM/DD/YYYY')

            created_date = dateFrom
        }
        setDateTime(created_date)



        formik.setValues({
            ...formik.values,
            [name]: created_date
        })
    }
    return (
        <React.Fragment>
            <Master />
            <main id="main" className="main user-select-none">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{state.error_message ? state.error_message : "Failed"}</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1>Create X12 Code</h1>
                </div>

                <section className="section mt-5 ">
                    <div className="row align-items-top py-3 gap-2 gap-md-5">

                        <div className="col-lg-12">
                            {/* <Formik
                                initialValues={
                                    x12_codes:[{

                                    }]
                                }
                                
                                // onSubmit={(e) => __showDataEntry(e)}
                            onSubmit={(initialValues) => {
                                // // console.log(initialValues);
                                __submitForm(initialValues);
                            }}
                            validationSchema={SignupSchema}

                            >
                            {({
                                handleSubmit,
                                handleChange,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => ( */}

                            <div className="card mb-5 mb-xl-8">
                                <div className="card-body py-3 ">
                                    <Form
                                        onSubmit={formik.handleSubmit}
                                    >


                                        <div className='mb-3 row'>
                                            <div className='col-lg-3'>

                                                <Form.Group className="mb-3">

                                                    <Form.Label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Type</Form.Label>

                                                    <Form.Select
                                                        type="text"
                                                        name='type'
                                                        // isValid={formik?.errors && !formik?.errors?.type}
                                                        isInvalid={formik?.errors && !!formik.errors?.type}
                                                        required
                                                        onChange={formik.handleChange}
                                                        className="form-control form-control-solid b-r-10"
                                                        style={{ color: "black" }}
                                                        placeholder="Select Type"
                                                        value={formik.values?.type}
                                                    >
                                                        <option value="">
                                                            Select An Option
                                                        </option>
                                                        <option value="CARC">
                                                            CARC
                                                        </option>
                                                        <option value="RARC">
                                                            RARC
                                                        </option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.type}</Form.Control.Feedback>
                                                </Form.Group>

                                            </div>

                                            {/* <div className='col-lg-3'>



                                                <label htmlhtmlFor="exampleFormControlInput1" className="form-label">CARC</label>

                                                <Form.Control
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name='carc'
                                                    isValid={formik?.errors && !formik?.errors?.carc}
                                                    isInvalid={formik?.errors && !!formik?.errors.carc}
                                                    maxLength={10}
                                                    placeholder="CARC"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.carc}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.carc}</Form.Control.Feedback>

                                            </div> */}
                                            <div className='col-lg-3'>



                                                <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Code</label>

                                                <Form.Control
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name='code'
                                                    isValid={formik?.errors && !formik?.errors?.code}
                                                    isInvalid={formik?.errors && !!formik.errors?.code}
                                                    placeholder="Code"
                                                    maxLength={10}
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.code}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.code}</Form.Control.Feedback>

                                            </div>
                                            <div className='col-lg-6'>

                                                <Form.Group className="mb-3">

                                                    <Form.Label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Description</Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name='description'
                                                        isValid={formik?.errors && !formik?.errors?.description}
                                                        isInvalid={formik?.errors && !!formik.errors?.description}
                                                        required
                                                        onChange={formik.handleChange}
                                                        className="form-control form-control-solid b-r-10"
                                                        placeholder="Description"
                                                        value={formik.values?.description}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.description}</Form.Control.Feedback>
                                                </Form.Group>

                                            </div>
                                            <div className='col-lg-3 color-black' style={{ display: "flex", flexDirection: "column" }}>





                                                <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Start Date</label>

                                                {/* <Form.Control
                                                        
                                                            // className="form-control form-control-solid b-r-10"
                                                            // classNamePrefix="select"
                                                            // name={`x12_codes[${index}].start_date`}
                                                            // isValid={formik?.errors && formik?.errors?.x12_codes && !formik?.errors?.x12_codes[index]?.start_date}
                                                            // isInvalid={formik?.errors && formik?.errors?.x12_codes && !!formik.errors?.x12_codes[index]?.start_date}
                                                            // placeholder="Start Date"
                                                            // maxLength={10}
                                                            // onChange={formik.handleChange}
                                                            // value={formik.values?.x12_codes[index]?.start_date}
                                                        /> */}
                                                <DatePicker
                                                    oneTap
                                                    placeholder="Select Date"
                                                    name="start_date"
                                                    format={"MM/dd/yyyy"}

                                                    onChange={(e) => { handleOnDateTimeChange({ name: "start_date", value: e }) }}
                                                    defaultValue={datetime}


                                                />
                                                {formik?.errors && formik?.errors?.start_date &&

                                                    <span className='text-danger' style={{ fontSize: "12px", padding: "4px" }}>
                                                        {formik?.errors?.start_date}
                                                    </span>}
                                                {/* <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.start_date}</Form.Control.Feedback> */}

                                            </div>
                                            {/* <div className='col-lg-3'>



                                                        <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Last Modified</label>

                                                        <Form.Control
                                                            className="form-control form-control-solid b-r-10"
                                                            classNamePrefix="select"
                                                            name={`x12_codes[${index}].last_modified`}
                                                            isValid={formik?.errors && formik?.errors?.x12_codes && !formik?.errors?.x12_codes[index]?.last_modified}
                                                            isInvalid={formik?.errors && formik?.errors?.x12_codes && !!formik.errors?.x12_codes[index]?.last_modified}
                                                            placeholder="Last Modified"
                                                            maxLength={10}
                                                            onChange={formik.handleChange}
                                                            value={formik.values?.x12_codes[index]?.last_modified}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.x12_codes && formik?.errors?.x12_codes[index]?.last_modified}</Form.Control.Feedback>

                                                    </div> */}
                                            <div className='col-lg-3 color-black' style={{ display: "flex", flexDirection: "column" }}>



                                                <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Stop Date</label>

                                                {/* <Form.Control
                                                            className="form-control form-control-solid b-r-10"
                                                            classNamePrefix="select"
                                                            name={`x12_codes[${index}].stop_date`}
                                                            isValid={formik?.errors && formik?.errors?.x12_codes && !formik?.errors?.x12_codes[index]?.stop_date}
                                                            isInvalid={formik?.errors && formik?.errors?.x12_codes && !!formik.errors?.x12_codes[index]?.stop_date}
                                                            placeholder="Stop Date"
                                                            maxLength={10}
                                                            onChange={formik.handleChange}
                                                            value={formik.values?.x12_codes[index]?.stop_date}
                                                        /> */}
                                                <DatePicker
                                                    oneTap
                                                    placeholder="Select Date"
                                                    name="stop_date"
                                                    style={{
                                                        color: "#000 !important"
                                                    }}
                                                    format={"MM/dd/yyyy"}
                                                    onChange={(e) => { handleOnDateTimeChange({ name: "stop_date", value: e }) }}
                                                    defaultValue={datetime}


                                                />
                                                {formik?.errors && formik?.errors?.stop_date &&

                                                    <span className='text-danger' style={{ fontSize: "12px", padding: "4px" }}>
                                                        {formik?.errors?.stop_date}
                                                    </span>}
                                                {/* <Form.Control.Feedback type="invalid"></Form.Control.Feedback> */}

                                            </div>
                                            {/* <div className='col-lg-3'>



                                                        <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Notes</label>

                                                        <Form.Control
                                                            className="form-control form-control-solid b-r-10"
                                                            classNamePrefix="select"
                                                            name={`x12_codes[${index}].notes`}
                                                            isValid={formik?.errors && formik?.errors?.x12_codes && !formik?.errors?.x12_codes[index]?.notes}
                                                            isInvalid={formik?.errors && formik?.errors?.x12_codes && !!formik.errors?.x12_codes[index]?.notes}
                                                            placeholder="notes"
                                                            maxLength={10}
                                                            onChange={formik.handleChange}
                                                            value={formik.values?.x12_codes[index]?.notes}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.x12_codes && formik?.errors?.x12_codes[index]?.notes}</Form.Control.Feedback>

                                                    </div> */}

                                        </div>












                                        {state.error &&
                                            <div className='text-center mt-3'>

                                                <span className='text-danger fw-bold '>{state.error_message}</span>
                                            </div>
                                        }
                                        <div className='d-flex flex-row justify-content-end'>

                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("X12 Codes", X12_CODES) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className=''>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary btnHover'
                                                    disabled={!formik.isValid}
                                                >
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>





                            {/* )} */}
                            {/* </Formik> */}
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default CreateX12Code;