import { Formik, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { createOrUpdateAdvanceCustomisation, createOutBound } from '../../../../../Redux/client/action';
import { useNavigate } from 'react-router-dom';
import { CLIENT } from '../../../../../routeNames';
import { changeAllTitleFunction } from '../../../Dashboard/functions';
import Swal from 'sweetalert2';


const AdvanceCustom = (props) => {

    const { client, client_data, advanceCustom, __toggleToast, setLoading, fetchClientDetails } = props;
    const [state, setState] = useState({
        error: false,
        error_message: "",

    });

    const navigate = useNavigate()
    const [isSubmit, setIsSubmit] = useState(false)
    const [togglePassword, setTogglePassword] = useState(false);

    useEffect(() => {
        if (advanceCustom) {
            formik.setValues({
                is_835_cors_available: advanceCustom?.is_835_cors_available ? advanceCustom?.is_835_cors_available : "",
                is_835_pp_available: advanceCustom?.is_835_pp_available ? advanceCustom?.is_835_pp_available : "",
                is_additional_info_available: advanceCustom?.is_additional_info_available ? advanceCustom?.is_additional_info_available : "",
                is_edi_available: advanceCustom?.is_edi_available ? advanceCustom?.is_edi_available : "",
                is_overall_page_from_to: advanceCustom?.is_overall_page_from_to ? advanceCustom?.is_overall_page_from_to : "",
                is_incomplete_check_number: advanceCustom?.is_incomplete_check_number ? advanceCustom?.is_incomplete_check_number : "",
                is_payer_page_from_to: advanceCustom?.is_payer_page_from_to ? advanceCustom?.is_payer_page_from_to : "",
                is_payee_page_from_to: advanceCustom?.is_payee_page_from_to ? advanceCustom?.is_payee_page_from_to : "",
                is_additional_info_status: advanceCustom?.is_additional_info_status ? advanceCustom?.is_additional_info_status : "",
                is_additional_info_reason: advanceCustom?.is_additional_info_reason ? advanceCustom?.is_additional_info_reason : "",
                is_additional_info_document_classification: advanceCustom?.is_additional_info_document_classification ? advanceCustom?.is_additional_info_document_classification : "",
                is_additional_info_extra_info: advanceCustom?.is_additional_info_extra_info ? advanceCustom?.is_additional_info_extra_info : "",
                is_generated_check_number: advanceCustom?.is_generated_check_number ? advanceCustom?.is_generated_check_number : "",
                is_operation_log_available: advanceCustom?.is_operation_log_available ? advanceCustom?.is_operation_log_available : "",
                operation_log_file_format: advanceCustom?.operation_log_file_format ? advanceCustom?.operation_log_file_format : "",
                operation_log_file_path: advanceCustom?.operation_log_file_path ? advanceCustom?.operation_log_file_path : "",
                is_single_ge_gs_available: advanceCustom?.is_single_ge_gs_available ? advanceCustom?.is_single_ge_gs_available : "",
                elimination_n1pr_0304: advanceCustom?.elimination_n1pr_0304 ? advanceCustom?.elimination_n1pr_0304 : "",
                bpr05_eliminate_ccp: advanceCustom?.bpr05_eliminate_ccp ? advanceCustom?.bpr05_eliminate_ccp : "",
                is_ref_ev_available: advanceCustom?.is_ref_ev_available ? advanceCustom?.is_ref_ev_available : "",
                default_clp06: advanceCustom?.default_clp06 ? advanceCustom?.default_clp06 : "",
                self_pay_default_clp06: advanceCustom?.self_pay_default_clp06 ? advanceCustom?.self_pay_default_clp06 : "",
                is_default_clp06: advanceCustom?.is_default_clp06 ? advanceCustom?.is_default_clp06 : "",
                is_self_pay_default_clp06: advanceCustom?.is_self_pay_default_clp06 ? advanceCustom?.is_self_pay_default_clp06 : "",
                is_email_enabled: advanceCustom?.is_email_enabled ? advanceCustom?.is_email_enabled : "",

                is_conversion_report_available: advanceCustom?.is_conversion_report_available ? advanceCustom?.is_conversion_report_available : false,
                conversion_report_file_naming_format: advanceCustom?.conversion_report_file_naming_format ? advanceCustom?.conversion_report_file_naming_format : "",
                conversion_report_delimiter: advanceCustom?.conversion_report_delimiter ? advanceCustom?.conversion_report_delimiter : "",
                is_exception_report_available: advanceCustom?.is_exception_report_available ? advanceCustom?.is_exception_report_available : false,
                exception_report_file_naming_format: advanceCustom?.exception_report_file_naming_format ? advanceCustom?.exception_report_file_naming_format : "",
                exception_report_delimiter: advanceCustom?.exception_report_delimiter ? advanceCustom?.exception_report_delimiter : "",
                is_credit_card_report_available: advanceCustom?.is_credit_card_report_available ? advanceCustom?.is_credit_card_report_available : false,
                credit_card_report_file_naming_format: advanceCustom?.credit_card_report_file_naming_format ? advanceCustom?.credit_card_report_file_naming_format : "",
                credit_card_report_delimiter: advanceCustom?.credit_card_report_delimiter ? advanceCustom?.credit_card_report_delimiter : "",
                is_image_snippets_report_available: advanceCustom?.is_image_snippets_report_available ? advanceCustom?.is_image_snippets_report_available : false,
                image_snippets_report_file_naming_format: advanceCustom?.image_snippets_report_file_naming_format ? advanceCustom?.image_snippets_report_file_naming_format : "",
                image_snippets_report_delimiter: advanceCustom?.image_snippets_report_delimiter ? advanceCustom?.image_snippets_report_delimiter : "",
                image_snippets_report_output_format: advanceCustom?.image_snippets_report_output_format ? advanceCustom?.image_snippets_report_output_format : "",


                is_pp_skinny_available: advanceCustom?.is_pp_skinny_available ? advanceCustom?.is_pp_skinny_available : false,

                is_ack_available: advanceCustom?.is_ack_available ? advanceCustom?.is_ack_available : false,
                is_processing_ack_available: advanceCustom?.is_processing_ack_available ? advanceCustom?.is_processing_ack_available : false,
                is_outgoing_ack_available: advanceCustom?.is_outgoing_ack_available ? advanceCustom?.is_outgoing_ack_available : false,
                arrival_ack_file_naming_format: advanceCustom?.arrival_ack_file_naming_format ? advanceCustom?.arrival_ack_file_naming_format : "",
                arrival_ack_output_file_directory: advanceCustom?.arrival_ack_output_file_directory ? advanceCustom?.arrival_ack_output_file_directory : "",
                outgoing_ack_file_naming_format: advanceCustom?.outgoing_ack_file_naming_format ? advanceCustom?.outgoing_ack_file_naming_format : "",
                outgoing_ack_output_file_directory: advanceCustom?.outgoing_ack_output_file_directory ? advanceCustom?.outgoing_ack_output_file_directory : "",

                processing_ack_file_naming_format: advanceCustom?.processing_ack_file_naming_format ? advanceCustom?.processing_ack_file_naming_format : "",
                processing_ack_output_file_directory: advanceCustom?.processing_ack_output_file_directory ? advanceCustom?.processing_ack_output_file_directory : "",


                epic_self_pay_output_file: advanceCustom?.epic_self_pay_output_file ? advanceCustom?.epic_self_pay_output_file : "",
                is_epic_available: advanceCustom?.is_epic_available ? advanceCustom?.is_epic_available : false,

                is_custom_check_number_format: advanceCustom?.is_custom_check_number_format ? advanceCustom?.is_custom_check_number_format : "",
                is_custom_check_number_available: advanceCustom?.is_custom_check_number_available ? advanceCustom?.is_custom_check_number_available : false,

                is_grid_customzation_enable: advanceCustom?.is_grid_customzation_enable ? advanceCustom?.is_grid_customzation_enable : false,


                is_auto_populate_payer_id: advanceCustom?.is_auto_populate_payer_id ? advanceCustom?.is_auto_populate_payer_id : false,
                payer_id_identification: advanceCustom?.payer_id_identification ? advanceCustom?.payer_id_identification : false,

                merged_claim_info_in_financial_class_field: advanceCustom?.merged_claim_info_in_financial_class_field ? advanceCustom?.merged_claim_info_in_financial_class_field : false,
                line_of_business_based_on_payer: advanceCustom?.line_of_business_based_on_payer ? advanceCustom?.line_of_business_based_on_payer : false,

                default_payee_code_qualifier: advanceCustom?.default_payee_code_qualifier ? advanceCustom?.default_payee_code_qualifier : "",
                default_payer_identification: advanceCustom?.default_payer_identification ? advanceCustom?.default_payer_identification : "",
                default_payer_code_qualifier: advanceCustom?.default_payer_code_qualifier ? advanceCustom?.default_payer_code_qualifier : "",
            })
        }

    }, [advanceCustom, client]);


    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            is_835_cors_available: false,
            is_835_pp_available: false,
            is_additional_info_available: false,
            is_overall_page_from_to: false,
            is_incomplete_check_number: false,
            is_payer_page_from_to: false,
            is_payee_page_from_to: false,
            is_operation_log_available: false,
            is_additional_info_status: false,
            is_additional_info_reason: false,
            is_additional_info_document_classification: false,
            is_additional_info_extra_info: false,
            is_generated_check_number: false,
            is_edi_available: false,
            operation_log_file_format: false,
            operation_log_file_path: false,
            is_single_ge_gs_available: false,
            elimination_n1pr_0304: false,
            bpr05_eliminate_ccp: false,
            is_ref_ev_available: false,
            is_default_clp06: false,
            default_clp06: "",
            is_self_pay_default_clp06: false,
            is_email_enabled: false,
            self_pay_default_clp06: "",

            is_conversion_report_available: false,
            conversion_report_file_naming_format: "",
            conversion_report_delimiter: "",

            is_exception_report_available: false,
            exception_report_file_naming_format: "",
            exception_report_delimiter: "",

            is_credit_card_report_available: false,
            credit_card_report_file_naming_format: "",
            credit_card_report_delimiter: "",

            is_image_snippets_report_available: false,
            image_snippets_report_file_naming_format: "",
            image_snippets_report_delimiter: "",
            image_snippets_report_output_format: "",

            is_pp_skinny_available: false,

            is_ack_available: false,
            is_processing_ack_available: false,
            is_outgoing_ack_available: false,
            arrival_ack_file_naming_format: "",
            arrival_ack_output_file_directory: "",
            outgoing_ack_file_naming_format: "",
            outgoing_ack_output_file_directory: "",

            processing_ack_file_naming_format: "",
            processing_ack_output_file_directory: "",

            epic_self_pay_output_file: "",
            is_epic_available: "",

            is_custom_check_number_available: false,
            is_custom_check_number_format: "",

            is_grid_customzation_enable: false,

            merged_claim_info_in_financial_class_field: false,
            is_auto_populate_payer_id: false,
            payer_id_identification: false,

            line_of_business_based_on_payer: false,

            default_payee_code_qualifier: "",
            default_payer_identification: "",
            default_payer_code_qualifier: "",


        },
        validate: (values) => {
            let errors = {};
            // Source File Transferred
            if (values?.is_operation_log_available == true) {
                if (!values?.operation_log_file_format.trim()) {

                    errors.operation_log_file_format = 'Required'
                }
            }
            if (values?.is_conversion_report_available == true) {
                if (!values?.conversion_report_file_naming_format.trim()) {

                    errors.conversion_report_file_naming_format = 'Required'
                }
                if (!values?.conversion_report_delimiter.trim()) {

                    errors.conversion_report_delimiter = 'Required'
                }
            }

            if (values?.is_exception_report_available == true) {
                if (!values?.exception_report_file_naming_format.trim()) {

                    errors.exception_report_file_naming_format = 'Required'
                }
                if (!values?.exception_report_delimiter.trim()) {

                    errors.exception_report_delimiter = 'Required'
                }
            }

            if (values?.is_credit_card_report_available == true) {
                if (!values?.credit_card_report_file_naming_format.trim()) {

                    errors.credit_card_report_file_naming_format = 'Required'
                }
                if (!values?.credit_card_report_delimiter.trim()) {

                    errors.credit_card_report_delimiter = 'Required'
                }
            }

            if (values?.is_image_snippets_report_available == true) {
                if (!values?.image_snippets_report_file_naming_format.trim()) {

                    errors.image_snippets_report_file_naming_format = 'Required'
                }
                if (!values?.image_snippets_report_delimiter.trim()) {

                    errors.image_snippets_report_delimiter = 'Required'
                }
            }

            if (values?.is_ack_available == true) {
                if (!values?.arrival_ack_file_naming_format.trim()) {

                    errors.arrival_ack_file_naming_format = 'Required'
                }

                if (!values?.arrival_ack_output_file_directory.trim()) {

                    errors.arrival_ack_output_file_directory = 'Required'
                }
            }
            if (values?.is_outgoing_ack_available == true) {

                if (!values?.outgoing_ack_file_naming_format.trim()) {

                    errors.outgoing_ack_file_naming_format = 'Required'
                }
                if (!values?.outgoing_ack_output_file_directory.trim()) {

                    errors.outgoing_ack_output_file_directory = 'Required'
                }
            }
            if (values?.is_processing_ack_available == true) {

                if (!values?.processing_ack_file_naming_format.trim()) {

                    errors.processing_ack_file_naming_format = 'Required'
                }
                if (!values?.processing_ack_output_file_directory.trim()) {

                    errors.processing_ack_output_file_directory = 'Required'
                }
            }


            if (values?.is_epic_available == true) {
                if (!values.epic_self_pay_output_file.trim()) {
                    errors.epic_self_pay_output_file = "Required"
                }
            }
            if (values?.is_custom_check_number_available == true) {
                if (values.is_custom_check_number_format == "") {
                    errors.is_custom_check_number_format = "Required"
                }
            }


            return errors;
        },

        // validationSchema: Yup.object().shape({
        //     file_transfer_period: Yup.string()
        //         .required('Required'),

        // }),
        onSubmit: (values, { setSubmitting }) => {
            setIsSubmit(true)
            setLoading(true)

            const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
            const epic_self_pay_output_file = values?.is_epic_available && !urlPattern.test(values?.epic_self_pay_output_file) ? values?.epic_self_pay_output_file : ""

            let formData = new FormData();    //formdata object
            formData.append('client', !client == 0 ? client : "");
            if (advanceCustom?.id)
                formData.append('id', advanceCustom?.id);
            formData.append('is_835_cors_available', values?.is_835_cors_available);
            formData.append('is_835_pp_available', values?.is_835_pp_available);
            formData.append('is_additional_info_available', values?.is_additional_info_available);
            formData.append('is_additional_info_status', values?.is_additional_info_status);
            formData.append('is_additional_info_reason', values?.is_additional_info_reason);
            formData.append('is_additional_info_document_classification', values?.is_additional_info_document_classification);
            formData.append('is_additional_info_extra_info', values?.is_additional_info_extra_info);
            formData.append('is_generated_check_number', values?.is_generated_check_number);
            formData.append('is_edi_available', values?.is_edi_available);
            formData.append('is_overall_page_from_to', values?.is_overall_page_from_to);
            formData.append('is_incomplete_check_number', values?.is_incomplete_check_number);
            formData.append('is_payer_page_from_to', values?.is_payer_page_from_to);
            formData.append('is_payee_page_from_to', values?.is_payee_page_from_to);
            formData.append('is_operation_log_available', values?.is_operation_log_available);
            formData.append('operation_log_file_format', values?.operation_log_file_format);
            formData.append('operation_log_file_path', values?.operation_log_file_path);
            formData.append('is_single_ge_gs_available', values?.is_single_ge_gs_available);
            formData.append('elimination_n1pr_0304', values?.elimination_n1pr_0304);
            formData.append('bpr05_eliminate_ccp', values?.bpr05_eliminate_ccp);
            formData.append('is_ref_ev_available', values?.is_ref_ev_available);
            formData.append('default_clp06', values?.default_clp06);
            formData.append('self_pay_default_clp06', values?.self_pay_default_clp06);
            formData.append('is_default_clp06', values?.is_default_clp06);
            formData.append('is_self_pay_default_clp06', values?.is_self_pay_default_clp06);
            formData.append('is_email_enabled', values?.is_email_enabled);

            formData.append('is_conversion_report_available', values?.is_conversion_report_available);
            formData.append('conversion_report_file_naming_format', values?.conversion_report_file_naming_format);
            formData.append('conversion_report_delimiter', values?.conversion_report_delimiter);
            formData.append('is_exception_report_available', values?.is_exception_report_available);
            formData.append('exception_report_file_naming_format', values?.exception_report_file_naming_format);
            formData.append('exception_report_delimiter', values?.exception_report_delimiter);
            formData.append('is_credit_card_report_available', values?.is_credit_card_report_available);
            formData.append('credit_card_report_file_naming_format', values?.credit_card_report_file_naming_format);
            formData.append('credit_card_report_delimiter', values?.credit_card_report_delimiter);
            formData.append('is_image_snippets_report_available', values?.is_image_snippets_report_available);
            formData.append('image_snippets_report_file_naming_format', values?.image_snippets_report_file_naming_format);
            formData.append('image_snippets_report_delimiter', values?.image_snippets_report_delimiter);
            formData.append('image_snippets_report_output_format', values?.image_snippets_report_output_format);


            formData.append('is_pp_skinny_available', values?.is_pp_skinny_available);

            formData.append('is_ack_available', values?.is_ack_available);
            formData.append('is_processing_ack_available', values?.is_processing_ack_available);
            formData.append('is_outgoing_ack_available', values?.is_outgoing_ack_available);
            formData.append('arrival_ack_file_naming_format', values?.arrival_ack_file_naming_format);
            formData.append('arrival_ack_output_file_directory', values?.arrival_ack_output_file_directory);
            formData.append('outgoing_ack_file_naming_format', values?.outgoing_ack_file_naming_format);
            formData.append('outgoing_ack_output_file_directory', values?.outgoing_ack_output_file_directory);
            formData.append('processing_ack_file_naming_format', values?.processing_ack_file_naming_format);
            formData.append('processing_ack_output_file_directory', values?.processing_ack_output_file_directory);



            formData.append('epic_self_pay_output_file', epic_self_pay_output_file);
            formData.append('is_epic_available', values?.is_epic_available);

            formData.append('is_custom_check_number_available', values?.is_custom_check_number_available);
            formData.append('is_custom_check_number_format', values?.is_custom_check_number_format);

            formData.append('is_grid_customzation_enable', values?.is_grid_customzation_enable);

            formData.append('is_auto_populate_payer_id', values?.is_auto_populate_payer_id);
            formData.append('payer_id_identification', values?.payer_id_identification);
            formData.append('merged_claim_info_in_financial_class_field', values?.merged_claim_info_in_financial_class_field);
            formData.append('line_of_business_based_on_payer', values?.line_of_business_based_on_payer);

            formData.append('default_payee_code_qualifier', values?.default_payee_code_qualifier);
            formData.append('default_payer_identification', values?.default_payer_identification);
            formData.append('default_payer_code_qualifier', values?.default_payer_code_qualifier);
            // setSubmitting(false)
            // setIsSubmit(true)

            createOrUpdateAdvanceCustomisation({ formData })((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 200) {

                    setIsSubmit(false)
                    setLoading(false)
                    __toggleToast("success", response?.message, true)
                    fetchClientDetails()

                } else {
                    // alert('error')

                    // const errors = response?.response?.data?.errors;
                    formik.setErrors({
                        ...formik,
                        is_835_cors_available: response?.response?.data?.errors?.is_835_cors_available,
                        is_835_pp_available: response?.response?.data?.errors?.is_835_pp_available,
                        is_additional_info_available: response?.response?.data?.errors?.is_additional_info_available,
                        is_additional_info_status: response?.response?.data?.errors?.is_additional_info_status,
                        is_additional_info_reason: response?.response?.data?.errors?.is_additional_info_reason,
                        is_additional_info_document_classification: response?.response?.data?.errors?.is_additional_info_document_classification,
                        is_additional_info_extra_info: response?.response?.data?.errors?.is_additional_info_extra_info,
                        is_generated_check_number: response?.response?.data?.errors?.is_generated_check_number,
                        is_edi_available: response?.response?.data?.errors?.is_edi_available,
                        is_overall_page_from_to: response?.response?.data?.errors?.is_overall_page_from_to,
                        is_incomplete_check_number: response?.response?.data?.errors?.is_incomplete_check_number,
                        is_payer_page_from_to: response?.response?.data?.errors?.is_payer_page_from_to,
                        is_payee_page_from_to: response?.response?.data?.errors?.is_payee_page_from_to,
                        is_operation_log_available: response?.response?.data?.errors?.is_operation_log_available,

                    })

                    setIsSubmit(false)
                    setLoading(false)
                    __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                    // props.changeState(response);
                }
            });
        },
    });
    const handleSwitchChange = (e) => {
        formik.setValues({
            ...formik.values,
            [e.target.name]: e.target.checked
        })
    }
    const addFileFormat = (type, name) => {

        formik.setValues({
            ...formik.values,
            operation_log_file_format: formik.values?.operation_log_file_format + type,
        })
    }
    const addReportFileFormat = (type, name) => {

        formik.setValues({
            ...formik.values,
            [name]: formik.values?.[[name]] + type,
        })
    }

    const handleFileChange = (event) => {

        let selectedFile = event.target.files;
        let file = null;
        let fileName = "";
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];
            let fileType = fileToLoad.type; // Get the MIME type of the selected file
            if (fileType === 'application/json') {

                // FileReader function for read the file.
                let fileReader = new FileReader();
                // Onload of file read the file content
                fileReader.onload = function (fileLoadedEvent) {
                    file = fileLoadedEvent.target.result;
                    // Print data in // console
                    formik.setValues({
                        ...formik.values,
                        epic_self_pay_output_file: file ?? "",
                    })
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
            } else {
                event.target.value = ""
                Swal.fire({
                    icon: 'error',
                    confirmButtonColor: '#1ba1a6',
                    title: 'Not a json file',
                    customClass: {
                        title: 'swal-titleText'
                    }
                    // text: 'Session',
                }).then((result) => {

                })
            }
        }
    }
    // const onButtonClick = (file) => {
    //     console.log(file);
    //     // using Java Script method to get PDF file
    //     const fileURL = file;
    //     // Setting various property values
    //     let alink = document.createElement('a');
    //     alink.href = fileURL;
    //     alink.download = 'Sample';
    //     alink.target = '_blank';
    //     alink.click();
    // }
    const onButtonClick = async (fileUrl, fileName = 'test') => {
        try {
            const response = await fetch(fileUrl);
            const data = await response.text();
            const blob = new Blob([data], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            const urlParts = new URL(fileUrl);
            const filename = urlParts.pathname.split('/').pop();
            // Check if the URL points to a JSON file
            const isJSON = filename.toLowerCase().endsWith('.json');

            link.download = `${filename}.json`;
            link.click();
        } catch (err) {
        }
    };



    return (
        <div>
            <Form
                onSubmit={formik.handleSubmit}
            >
                <Form.Label className='fw-bold clr-heading'>Grid Customization</Form.Label>

                <div className='grid-customization-grid pb-3' >


                    <div className="mt-2">
                        <Form.Check
                            as="input"
                            inline
                            type="switch"
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik?.values?.is_835_cors_available}
                            isInvalid={
                                formik.errors?.is_835_cors_available &&
                                !!formik.errors?.is_835_cors_available
                            }

                            feedbackType="invalid"

                            feedback={
                                formik.errors?.is_835_cors_available &&
                                formik.errors?.is_835_cors_available
                            }
                            id="is_835_cors_available"
                            name="is_835_cors_available"
                            label="Corres 835 Output"
                        />
                    </div>
                    <div className="mt-2">
                        <Form.Check
                            as="input"
                            inline
                            type="switch"
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik?.values?.is_835_pp_available}
                            isInvalid={
                                formik.errors?.is_835_pp_available &&
                                !!formik.errors?.is_835_pp_available
                            }
                            feedbackType="invalid"
                            feedback={
                                formik.errors?.is_835_pp_available &&
                                formik.errors?.is_835_pp_available
                            }
                            id="is_835_pp_available"
                            name="is_835_pp_available"
                            label="PP 835 Output"
                        />
                    </div>
                    {/* <div className="mt-2">
                        <Form.Check
                            as="input"
                            inline
                            type="switch"
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik?.values?.is_additional_info_available}
                            isInvalid={
                                formik.errors?.is_additional_info_available &&
                                !!formik.errors?.is_additional_info_available
                            }
                            feedbackType="invalid"
                            feedback={
                                formik.errors?.is_additional_info_available &&
                                formik.errors?.is_additional_info_available
                            }
                            id="is_additional_info_available"
                            name="is_additional_info_available"
                            label=" Additional Info "
                        />
                    </div> */}
                    <div className="mt-2">
                        <Form.Check
                            as="input"
                            inline
                            type="switch"
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik?.values?.is_edi_available}
                            isInvalid={
                                formik.errors?.is_edi_available &&
                                !!formik.errors?.is_edi_available
                            }
                            feedbackType="invalid"
                            feedback={
                                formik.errors?.is_edi_available &&
                                formik.errors?.is_edi_available
                            }
                            id="is_edi_available"
                            name="is_edi_available"
                            label=" Enable EDI Validator"
                        />
                    </div>
                    <div className="mt-2">
                        <Form.Check
                            as="input"
                            inline
                            type="switch"
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik?.values?.is_generated_check_number}
                            isInvalid={
                                formik.errors?.is_generated_check_number &&
                                !!formik.errors?.is_generated_check_number
                            }

                            feedbackType="invalid"

                            feedback={
                                formik.errors?.is_generated_check_number &&
                                formik.errors?.is_generated_check_number
                            }
                            id="is_generated_check_number"
                            name="is_generated_check_number"
                            label="Custom Check Number"
                        />
                    </div>
                </div>
                <div className='grid-customization-grid pb-3' >
                    <div className="mt-2">
                        <Form.Check
                            as="input"
                            inline
                            type="switch"
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik?.values?.is_incomplete_check_number}
                            isInvalid={
                                formik.errors?.is_incomplete_check_number &&
                                !!formik.errors?.is_incomplete_check_number
                            }

                            feedbackType="invalid"

                            feedback={
                                formik.errors?.is_incomplete_check_number &&
                                formik.errors?.is_incomplete_check_number
                            }
                            id="is_incomplete_check_number"
                            name="is_incomplete_check_number"
                            label="Custom Incomplete Check Number"
                        />
                    </div>
                    <div className="mt-2">
                        <Form.Check
                            as="input"
                            inline
                            type="switch"
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik?.values?.is_overall_page_from_to}
                            isInvalid={
                                formik.errors?.is_overall_page_from_to &&
                                !!formik.errors?.is_overall_page_from_to
                            }

                            feedbackType="invalid"

                            feedback={
                                formik.errors?.is_overall_page_from_to &&
                                formik.errors?.is_overall_page_from_to
                            }
                            id="is_overall_page_from_to"
                            name="is_overall_page_from_to"
                            label="Overall Page From and To"
                        />
                    </div>
                    <div className="mt-2">
                        <Form.Check
                            as="input"
                            inline
                            type="switch"
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik?.values?.is_payer_page_from_to}
                            isInvalid={
                                formik.errors?.is_payer_page_from_to &&
                                !!formik.errors?.is_payer_page_from_to
                            }

                            feedbackType="invalid"

                            feedback={
                                formik.errors?.is_payer_page_from_to &&
                                formik.errors?.is_payer_page_from_to
                            }
                            id="is_payer_page_from_to"
                            name="is_payer_page_from_to"
                            label="Payer Page From and To"
                        />
                    </div>
                    <div className="mt-2">
                        <Form.Check
                            as="input"
                            inline
                            type="switch"
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik?.values?.is_payee_page_from_to}
                            isInvalid={
                                formik.errors?.is_payee_page_from_to &&
                                !!formik.errors?.is_payee_page_from_to
                            }

                            feedbackType="invalid"

                            feedback={
                                formik.errors?.is_payee_page_from_to &&
                                formik.errors?.is_payee_page_from_to
                            }
                            id="is_payee_page_from_to"
                            name="is_payee_page_from_to"
                            label="Payee Page From and To"
                        />
                    </div>
                </div>

                <div className='grid-customization-grid-output pb-4'>
                    <div className="">
                        <FormCheck
                            inline
                            type='switch'
                            reverse={true}
                            onChange={handleSwitchChange}
                            checked={formik.values.is_operation_log_available}
                            inValid={formik.errors?.is_operation_log_available &&
                                !!formik.errors?.is_operation_log_available}
                            feedbackType='invalid'
                            feedback={
                                formik.errors?.is_operation_log_available &&
                                formik.errors?.is_operation_log_available
                            }
                            id='is_operation_log_available'
                            name='is_operation_log_available'
                            label='Operation Log'
                        >

                        </FormCheck>
                    </div>

                    {formik.values?.is_operation_log_available &&
                        <React.Fragment>
                            <div>
                                <Form.Group className="" >
                                    <div className='d-flex flex-row align-items-center'>
                                        <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                        <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormat('[Client Name]')}>Client Name</Button>{' '}
                                        <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormat('[Date]')}>Date</Button>{' '}
                                        {/* <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormat('[File type]')}>File Type</Button>{' '} */}
                                        <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormat('[Lockbox File Name]')}>Lockbox File Name</Button>{' '}
                                    </div>

                                    <Form.Control
                                        name='operation_log_file_format'
                                        type="text"
                                        autoComplete="false"
                                        className='b-r-10 form-control-solid'
                                        maxLength="255"
                                        placeholder="File Naming Formats"
                                        isValid={formik.touched?.operation_log_file_format && !formik?.errors?.operation_log_file_format}
                                        isInvalid={!!formik.errors?.operation_log_file_format}
                                        value={formik.values?.operation_log_file_format}
                                        onChange={formik.handleChange}
                                    // required={client_data?.eob}
                                    />
                                    <Form.Control.Feedback type="invalid">{formik?.errors?.operation_log_file_format}</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                            {/* <div>  // removed by athira and suhail
                                <Form.Group className="mb-3" >

                                    <Form.Label className='fw-bold'>Folder Path</Form.Label>
                                    <Form.Control
                                        name='operation_log_file_path'
                                        className='b-r-10 form-control-solid form-control'
                                        maxLength="255"
                                        isValid={formik.touched?.operation_log_file_path && !formik?.errors?.operation_log_file_path}
                                        isInvalid={!!formik.errors?.operation_log_file_path}
                                        value={formik.values?.operation_log_file_path}
                                        onChange={formik.handleChange}
                                        placeholder="Folder Path"
                                    />
                                    <Form.Control.Feedback type="invalid">{formik?.errors?.operation_log_file_path}</Form.Control.Feedback>

                                </Form.Group>
                            </div> */}
                        </React.Fragment>

                    }

                </div>

                <div className="pb-4">

                    {/* <Form.Label className='fw-bold clr-heading'>Additional Info</Form.Label> */}
                    <Form.Check
                        as="input"
                        inline
                        className="clr-heading"
                        type="switch"
                        reverse={true}
                        onChange={handleSwitchChange}
                        checked={formik?.values?.is_additional_info_available}
                        isInvalid={
                            formik.errors?.is_additional_info_available &&
                            !!formik.errors?.is_additional_info_available
                        }
                        feedbackType="invalid"
                        feedback={
                            formik.errors?.is_additional_info_available &&
                            formik.errors?.is_additional_info_available
                        }
                        id="is_additional_info_available"
                        name="is_additional_info_available"
                        label="Additional Info"
                    />
                    {formik?.values?.is_additional_info_available &&
                        <div className='grid-customization-grid-835  '>
                            <div className="mt-2">
                                <Form.Check
                                    as="input"
                                    inline
                                    type="switch"
                                    reverse={true}
                                    onChange={handleSwitchChange}
                                    checked={formik?.values?.is_additional_info_status}
                                    isInvalid={
                                        formik.errors?.is_additional_info_status &&
                                        !!formik.errors?.is_additional_info_status
                                    }

                                    feedbackType="invalid"

                                    feedback={
                                        formik.errors?.is_additional_info_status &&
                                        formik.errors?.is_additional_info_status
                                    }
                                    id="is_additional_info_status"
                                    name="is_additional_info_status"
                                    label="Status"
                                />
                            </div>
                            <div className="mt-2">
                                <Form.Check
                                    as="input"
                                    inline
                                    type="switch"
                                    reverse={true}
                                    onChange={handleSwitchChange}
                                    checked={formik?.values?.is_additional_info_reason}
                                    isInvalid={
                                        formik.errors?.is_additional_info_reason &&
                                        !!formik.errors?.is_additional_info_reason
                                    }

                                    feedbackType="invalid"

                                    feedback={
                                        formik.errors?.is_additional_info_reason &&
                                        formik.errors?.is_additional_info_reason
                                    }
                                    id="is_additional_info_reason"
                                    name="is_additional_info_reason"
                                    label="Reason"
                                />
                            </div>
                            <div className="mt-2">
                                <Form.Check
                                    as="input"
                                    inline
                                    type="switch"
                                    reverse={true}
                                    onChange={handleSwitchChange}
                                    checked={formik?.values?.is_additional_info_document_classification}
                                    isInvalid={
                                        formik.errors?.is_additional_info_document_classification &&
                                        !!formik.errors?.is_additional_info_document_classification
                                    }

                                    feedbackType="invalid"

                                    feedback={
                                        formik.errors?.is_additional_info_document_classification &&
                                        formik.errors?.is_additional_info_document_classification
                                    }
                                    id="is_additional_info_document_classification"
                                    name="is_additional_info_document_classification"
                                    label="Document Classification"
                                />
                            </div>
                            <div className="mt-2">
                                <Form.Check
                                    as="input"
                                    inline
                                    type="switch"
                                    reverse={true}
                                    onChange={handleSwitchChange}
                                    checked={formik?.values?.is_additional_info_extra_info}
                                    isInvalid={
                                        formik.errors?.is_additional_info_extra_info &&
                                        !!formik.errors?.is_additional_info_extra_info
                                    }

                                    feedbackType="invalid"

                                    feedback={
                                        formik.errors?.is_additional_info_extra_info &&
                                        formik.errors?.is_additional_info_extra_info
                                    }
                                    id="is_additional_info_extra_info"
                                    name="is_additional_info_extra_info"
                                    label="Extra Info"
                                />
                            </div>

                        </div>
                    }

                </div>

                <div>

                    <Form.Label className='fw-bold clr-heading'>835 Customization</Form.Label>

                    <div className='grid-customization-grid-835  '>
                        <div className="mt-2">
                            <Form.Check
                                as="input"
                                inline
                                type="switch"
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik?.values?.elimination_n1pr_0304}
                                isInvalid={
                                    formik.errors?.elimination_n1pr_0304 &&
                                    !!formik.errors?.elimination_n1pr_0304
                                }

                                feedbackType="invalid"

                                feedback={
                                    formik.errors?.elimination_n1pr_0304 &&
                                    formik.errors?.elimination_n1pr_0304
                                }
                                id="elimination_n1pr_0304"
                                name="elimination_n1pr_0304"
                                label="ID Code Qualifier (N103)"
                            />
                        </div>
                        <div className="mt-2">
                            <Form.Check
                                as="input"
                                inline
                                type="switch"
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik?.values?.bpr05_eliminate_ccp}
                                isInvalid={
                                    formik.errors?.bpr05_eliminate_ccp &&
                                    !!formik.errors?.bpr05_eliminate_ccp
                                }

                                feedbackType="invalid"

                                feedback={
                                    formik.errors?.bpr05_eliminate_ccp &&
                                    formik.errors?.bpr05_eliminate_ccp
                                }
                                id="bpr05_eliminate_ccp"
                                name="bpr05_eliminate_ccp"
                                label="Payer Identifier (N104)"
                            />
                        </div>
                        <div className="mt-2">
                            <Form.Check
                                as="input"
                                inline
                                type="switch"
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik?.values?.is_single_ge_gs_available}
                                isInvalid={
                                    formik.errors?.is_single_ge_gs_available &&
                                    !!formik.errors?.is_single_ge_gs_available
                                }

                                feedbackType="invalid"

                                feedback={
                                    formik.errors?.is_single_ge_gs_available &&
                                    formik.errors?.is_single_ge_gs_available
                                }
                                id="is_single_ge_gs_available"
                                name="is_single_ge_gs_available"
                                label="Single GS/GE Segment"
                            />
                        </div>
                        <div className="mt-2">
                            <Form.Check
                                as="input"
                                inline
                                type="switch"
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik?.values?.is_ref_ev_available}
                                isInvalid={
                                    formik.errors?.is_ref_ev_available &&
                                    !!formik.errors?.is_ref_ev_available
                                }
                                feedbackType="invalid"
                                feedback={
                                    formik.errors?.is_ref_ev_available &&
                                    formik.errors?.is_ref_ev_available
                                }
                                id="is_ref_ev_available"
                                name="is_ref_ev_available"
                                label="Enable REF*EV"
                            />
                        </div>
                        <div className='mt-2' >
                            <div className='d-flex flex-column'>
                                <div>
                                    <Form.Check
                                        as="input"
                                        inline
                                        type="switch"
                                        reverse={true}
                                        onChange={handleSwitchChange}
                                        checked={formik?.values?.is_default_clp06}
                                        isInvalid={
                                            formik.errors?.is_default_clp06 &&
                                            !!formik.errors?.is_default_clp06
                                        }

                                        feedbackType="invalid"

                                        feedback={
                                            formik.errors?.is_default_clp06 &&
                                            formik.errors?.is_default_clp06
                                        }
                                        id="is_default_clp06"
                                        name="is_default_clp06"
                                        label="CLP06 Default Value"
                                    />
                                </div>

                                <div>
                                    {formik?.values?.is_default_clp06 &&
                                        <Form.Control
                                            name="default_clp06"
                                            as="input"
                                            className='form-control-solid b-r-10 mt-2'
                                            style={{ width: '180px' }}
                                            onChange={formik.handleChange}
                                            placeholder='CLP06 Default Value'
                                            value={formik.values?.default_clp06}
                                        />
                                    }
                                </div>

                            </div>


                        </div>
                        <div className="mt-2">
                            <div className='d-flex flex-column '>
                                <div>

                                    <Form.Check
                                        as="input"
                                        inline
                                        type="switch"
                                        reverse={true}
                                        onChange={handleSwitchChange}
                                        checked={formik?.values?.is_self_pay_default_clp06}
                                        isInvalid={
                                            formik.errors?.is_self_pay_default_clp06 &&
                                            !!formik.errors?.is_self_pay_default_clp06
                                        }

                                        feedbackType="invalid"

                                        feedback={
                                            formik.errors?.is_self_pay_default_clp06 &&
                                            formik.errors?.is_self_pay_default_clp06
                                        }
                                        id="is_self_pay_default_clp06"
                                        name="is_self_pay_default_clp06"
                                        label="Self Pay Default CLP06"
                                    />

                                </div>
                                <div>

                                    {formik?.values?.is_self_pay_default_clp06 &&

                                        <Form.Control
                                            name="self_pay_default_clp06"
                                            as="input"
                                            className='form-control-solid b-r-10 mt-2'
                                            onChange={formik.handleChange}
                                            style={{ width: '200px' }}
                                            placeholder='Self Pay Default CLP06'
                                            value={formik.values?.self_pay_default_clp06}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center gap-4  my-3'>

                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center  my-3'>

                    </div>
                </div >
                <div className='pb-4'>

                    <Form.Label className='fw-bold clr-heading'>Email</Form.Label>

                    <div className='grid-customization-grid-835  '>
                        <div className="mt-2">
                            <Form.Check
                                as="input"
                                inline
                                type="switch"
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik?.values?.is_email_enabled}
                                isInvalid={
                                    formik.errors?.is_email_enabled &&
                                    !!formik.errors?.is_email_enabled
                                }

                                feedbackType="invalid"

                                feedback={
                                    formik.errors?.is_email_enabled &&
                                    formik.errors?.is_email_enabled
                                }
                                id="is_email_enabled"
                                name="is_email_enabled"
                                label="Enable Email"
                            />
                        </div>

                    </div>

                </div>
                <div className='pb-4'>

                    <Form.Label className='fw-bold clr-heading'>Patient Payment Skinny 835</Form.Label>

                    <div className='grid-customization-grid-835  '>
                        <div className="mt-2">
                            <Form.Check
                                as="input"
                                inline
                                type="switch"
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik?.values?.is_pp_skinny_available}
                                isInvalid={
                                    formik.errors?.is_pp_skinny_available &&
                                    !!formik.errors?.is_pp_skinny_available
                                }

                                feedbackType="invalid"

                                feedback={
                                    formik.errors?.is_pp_skinny_available &&
                                    formik.errors?.is_pp_skinny_available
                                }
                                id="is_pp_skinny_available"
                                name="is_pp_skinny_available"
                                label="Enable PP Skinny"
                            />
                        </div>

                    </div>

                </div>
                <div>
                    <Form.Label className='fw-bold clr-heading'>Conversion Report</Form.Label>

                    <div className='grid-customization-grid-output pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_conversion_report_available}
                                inValid={formik.errors?.is_conversion_report_available &&
                                    !!formik.errors?.is_conversion_report_available}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_conversion_report_available &&
                                    formik.errors?.is_conversion_report_available
                                }
                                id='is_conversion_report_available'
                                name='is_conversion_report_available'
                                label='Enable Conversion Report'
                            >

                            </FormCheck>
                        </div>

                        {formik.values?.is_conversion_report_available &&
                            <React.Fragment>
                                <div >
                                    <Form.Group className="" >
                                        <div className='d-flex flex-row align-items-center'>
                                            <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[Lockbox ID]', 'conversion_report_file_naming_format')}>Lockbox ID</Button>{' '}
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[Date]', 'conversion_report_file_naming_format')}>Date</Button>{' '}
                                        </div>

                                        <Form.Control
                                            name='conversion_report_file_naming_format'
                                            type="text"
                                            autoComplete="false"
                                            className='b-r-10 form-control-solid'
                                            maxLength="255"
                                            placeholder="File Naming Formats"
                                            isValid={formik.touched?.conversion_report_file_naming_format && !formik?.errors?.conversion_report_file_naming_format}
                                            isInvalid={!!formik.errors?.conversion_report_file_naming_format}
                                            value={formik.values?.conversion_report_file_naming_format}
                                            onChange={formik.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.conversion_report_file_naming_format}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Group className="mb-3" >

                                        <Form.Label className='fw-bold'>Delimiter</Form.Label>
                                        <Form.Control
                                            name='conversion_report_delimiter'
                                            className='b-r-10 form-control-solid form-control'
                                            maxLength="255"
                                            isValid={formik.touched?.conversion_report_delimiter && !formik?.errors?.conversion_report_delimiter}
                                            isInvalid={!!formik.errors?.conversion_report_delimiter}
                                            value={formik.values?.conversion_report_delimiter}
                                            onChange={formik.handleChange}
                                            placeholder="Delimiter"
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.conversion_report_delimiter}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                            </React.Fragment>

                        }

                    </div>
                </div>
                <div>
                    <Form.Label className='fw-bold clr-heading'>Exception Report</Form.Label>

                    <div className='grid-customization-grid-output pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_exception_report_available}
                                inValid={formik.errors?.is_exception_report_available &&
                                    !!formik.errors?.is_exception_report_available}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_exception_report_available &&
                                    formik.errors?.is_exception_report_available
                                }
                                id='is_exception_report_available'
                                name='is_exception_report_available'
                                label='Enable Exception Report'
                            >

                            </FormCheck>
                        </div>

                        {formik.values?.is_exception_report_available &&
                            <React.Fragment>
                                <div >
                                    <Form.Group className="" >
                                        <div className='d-flex flex-row align-items-center'>
                                            <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Name]', 'exception_report_file_naming_format')}>File Name</Button>{' '}
                                        </div>

                                        <Form.Control
                                            name='exception_report_file_naming_format'
                                            type="text"
                                            autoComplete="false"
                                            className='b-r-10 form-control-solid'
                                            maxLength="255"
                                            placeholder="File Naming Formats"
                                            isValid={formik.touched?.exception_report_file_naming_format && !formik?.errors?.exception_report_file_naming_format}
                                            isInvalid={!!formik.errors?.exception_report_file_naming_format}
                                            value={formik.values?.exception_report_file_naming_format}
                                            onChange={formik.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.exception_report_file_naming_format}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Group className="mb-3" >

                                        <Form.Label className='fw-bold'>Delimiter</Form.Label>
                                        <Form.Control
                                            name='exception_report_delimiter'
                                            className='b-r-10 form-control-solid form-control'
                                            maxLength="255"
                                            isValid={formik.touched?.exception_report_delimiter && !formik?.errors?.exception_report_delimiter}
                                            isInvalid={!!formik.errors?.exception_report_delimiter}
                                            value={formik.values?.exception_report_delimiter}
                                            onChange={formik.handleChange}
                                            placeholder="Delimiter"
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.exception_report_delimiter}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                            </React.Fragment>

                        }

                    </div>
                </div>
                <div>
                    <Form.Label className='fw-bold clr-heading'>Credit Card</Form.Label>

                    <div className='grid-customization-grid-output pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_credit_card_report_available}
                                inValid={formik.errors?.is_credit_card_report_available &&
                                    !!formik.errors?.is_credit_card_report_available}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_credit_card_report_available &&
                                    formik.errors?.is_credit_card_report_available
                                }
                                id='is_credit_card_report_available'
                                name='is_credit_card_report_available'
                                label='Enable Credit Card Report'
                            >

                            </FormCheck>
                        </div>

                        {formik.values?.is_credit_card_report_available &&
                            <React.Fragment>
                                <div >
                                    <Form.Group className="" >
                                        <div className='d-flex flex-row align-items-center'>
                                            <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Name]', 'credit_card_report_file_naming_format')}>File Name</Button>{' '}
                                        </div>

                                        <Form.Control
                                            name='credit_card_report_file_naming_format'
                                            type="text"
                                            autoComplete="false"
                                            className='b-r-10 form-control-solid'
                                            maxLength="255"
                                            placeholder="File Naming Formats"
                                            isValid={formik.touched?.credit_card_report_file_naming_format && !formik?.errors?.credit_card_report_file_naming_format}
                                            isInvalid={!!formik.errors?.credit_card_report_file_naming_format}
                                            value={formik.values?.credit_card_report_file_naming_format}
                                            onChange={formik.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.credit_card_report_file_naming_format}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='col-lg-4'>
                                    <Form.Group className="mb-3" >

                                        <Form.Label className='fw-bold'>Delimiter</Form.Label>
                                        <Form.Control
                                            name='credit_card_report_delimiter'
                                            className='b-r-10 form-control-solid form-control'
                                            maxLength="255"
                                            isValid={formik.touched?.credit_card_report_delimiter && !formik?.errors?.credit_card_report_delimiter}
                                            isInvalid={!!formik.errors?.credit_card_report_delimiter}
                                            value={formik.values?.credit_card_report_delimiter}
                                            onChange={formik.handleChange}
                                            placeholder="Delimiter"
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.credit_card_report_delimiter}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                            </React.Fragment>

                        }

                    </div>
                </div>
                <div>
                    <Form.Label className='fw-bold clr-heading'>Indexing/Image Snippets</Form.Label>

                    <div className='grid-customization-grid-index-image pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_image_snippets_report_available}
                                inValid={formik.errors?.is_image_snippets_report_available &&
                                    !!formik.errors?.is_image_snippets_report_available}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_image_snippets_report_available &&
                                    formik.errors?.is_image_snippets_report_available
                                }
                                id='is_image_snippets_report_available'
                                name='is_image_snippets_report_available'
                                label='Enable Indexing/Image Snippets Report'
                            >

                            </FormCheck>
                        </div>

                        {formik.values?.is_image_snippets_report_available &&
                            <React.Fragment>
                                <div >
                                    <Form.Group className="" >
                                        <div className='d-flex flex-row align-items-center'>
                                            <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Name]', 'image_snippets_report_file_naming_format')}>File Name</Button>{' '}
                                        </div>

                                        <Form.Control
                                            name='image_snippets_report_file_naming_format'
                                            type="text"
                                            autoComplete="false"
                                            className='b-r-10 form-control-solid'
                                            maxLength="255"
                                            placeholder="File Naming Formats"
                                            isValid={formik.touched?.image_snippets_report_file_naming_format && !formik?.errors?.image_snippets_report_file_naming_format}
                                            isInvalid={!!formik.errors?.image_snippets_report_file_naming_format}
                                            value={formik.values?.image_snippets_report_file_naming_format}
                                            onChange={formik.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.image_snippets_report_file_naming_format}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className=''>
                                    <Form.Group className="mb-3" >

                                        <Form.Label className='fw-bold'>Delimiter</Form.Label>
                                        <Form.Control
                                            name='image_snippets_report_delimiter'
                                            className='b-r-10 form-control-solid form-control'
                                            maxLength="255"
                                            isValid={formik.touched?.image_snippets_report_delimiter && !formik?.errors?.image_snippets_report_delimiter}
                                            isInvalid={!!formik.errors?.image_snippets_report_delimiter}
                                            value={formik.values?.image_snippets_report_delimiter}
                                            onChange={formik.handleChange}
                                            placeholder="Delimiter"
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.image_snippets_report_delimiter}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                                <div className=''>
                                    <Form.Group className="" >

                                        <Form.Label className='fw-bold'>Original Format</Form.Label>
                                        <Form.Control as="select"
                                            name='image_snippets_report_output_format'
                                            className='b-r-10 form-control-solid form-control'
                                            maxLength="255"
                                            value={formik.values?.image_snippets_report_output_format}
                                            onChange={formik.handleChange}

                                        >
                                            <option value="">Select an Option</option>
                                            <option value="1" >TIFF</option>
                                            <option value="2">PDF</option>
                                            <option value="3">AS RECEIVED</option>


                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.image_snippets_report_output_format}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                            </React.Fragment>

                        }

                    </div>
                </div>
                <div>
                    <Form.Label className='fw-bold clr-heading'>Acknowledgement</Form.Label>

                    <div className='grid-customization-grid-output pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_ack_available}
                                inValid={formik.errors?.is_ack_available &&
                                    !!formik.errors?.is_ack_available}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_ack_available &&
                                    formik.errors?.is_ack_available
                                }
                                id='is_ack_available'
                                name='is_ack_available'
                                label='File Received'
                            >

                            </FormCheck>
                        </div>



                    </div>
                </div>
                <div>
                    {formik.values?.is_ack_available &&
                        <div className='grid-customization-grid-output-2-item pb-4'>


                            <React.Fragment>
                                <div >
                                    <Form.Group className="" >
                                        <div className='d-flex flex-row align-items-center'>
                                            <Form.Label className='fw-bold'>Arrival ACK File Naming Format</Form.Label>
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[Timestamp]', 'arrival_ack_file_naming_format')}>Timestamp</Button>{' '}
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Name]', 'arrival_ack_file_naming_format')}>File Name</Button>{' '}
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Extension]', 'arrival_ack_file_naming_format')}>File Extension</Button>{' '}
                                        </div>

                                        <Form.Control
                                            name='arrival_ack_file_naming_format'
                                            type="text"
                                            autoComplete="false"
                                            className='b-r-10 form-control-solid'
                                            maxLength="255"
                                            placeholder="Arrival ACK File Naming Format"
                                            isValid={formik.touched?.arrival_ack_file_naming_format && !formik?.errors?.arrival_ack_file_naming_format}
                                            isInvalid={!!formik.errors?.arrival_ack_file_naming_format}
                                            value={formik.values?.arrival_ack_file_naming_format}
                                            onChange={formik.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.arrival_ack_file_naming_format}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className=''>
                                    <Form.Group className="mb-3" >

                                        <Form.Label className='fw-bold'>Arrival ACK Output File Directory</Form.Label>
                                        <Form.Control
                                            name='arrival_ack_output_file_directory'
                                            className='b-r-10 form-control-solid form-control'
                                            maxLength="255"
                                            isValid={formik.touched?.arrival_ack_output_file_directory && !formik?.errors?.arrival_ack_output_file_directory}
                                            isInvalid={!!formik.errors?.arrival_ack_output_file_directory}
                                            value={formik.values?.arrival_ack_output_file_directory}
                                            onChange={formik.handleChange}
                                            placeholder="Arrival ACK Output File Directory"
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.arrival_ack_output_file_directory}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                            </React.Fragment>


                        </div>
                    }
                </div>
                <div>
                    <div className='grid-customization-grid-output pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_processing_ack_available}
                                inValid={formik.errors?.is_processing_ack_available &&
                                    !!formik.errors?.is_processing_ack_available}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_processing_ack_available &&
                                    formik.errors?.is_processing_ack_available
                                }
                                id='is_processing_ack_available'
                                name='is_processing_ack_available'
                                label='File Processed '
                            >

                            </FormCheck>
                        </div>



                    </div>
                </div>
                <div>
                    {formik.values?.is_processing_ack_available &&
                        <div className='grid-customization-grid-output-2-item pb-4'>


                            <React.Fragment>
                                <div >
                                    <Form.Group className="" >
                                        <div className='d-flex flex-row align-items-center'>
                                            <Form.Label className='fw-bold'>Processing ACK File Naming Format</Form.Label>
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[Timestamp]', 'processing_ack_file_naming_format')}>Timestamp</Button>{' '}
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Name]', 'processing_ack_file_naming_format')}>File Name</Button>{' '}
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Extension]', 'processing_ack_file_naming_format')}>File Extension</Button>{' '}                                        </div>

                                        <Form.Control
                                            name='processing_ack_file_naming_format'
                                            type="text"
                                            autoComplete="false"
                                            className='b-r-10 form-control-solid'
                                            maxLength="255"
                                            placeholder="Processing ACK File Naming Format"
                                            isValid={formik.touched?.processing_ack_file_naming_format && !formik?.errors?.processing_ack_file_naming_format}
                                            isInvalid={!!formik.errors?.processing_ack_file_naming_format}
                                            value={formik.values?.processing_ack_file_naming_format}
                                            onChange={formik.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.processing_ack_file_naming_format}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className=''>
                                    <Form.Group className="mb-3" >

                                        <Form.Label className='fw-bold'>Processing ACK Output File Directory</Form.Label>
                                        <Form.Control
                                            name='processing_ack_output_file_directory'
                                            className='b-r-10 form-control-solid form-control'
                                            maxLength="255"
                                            isValid={formik.touched?.processing_ack_output_file_directory && !formik?.errors?.processing_ack_output_file_directory}
                                            isInvalid={!!formik.errors?.processing_ack_output_file_directory}
                                            value={formik.values?.processing_ack_output_file_directory}
                                            onChange={formik.handleChange}
                                            placeholder="Processing ACK Output File Directory"
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.processing_ack_output_file_directory}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                            </React.Fragment>


                        </div>
                    }
                </div>
                <div>
                    <div className='grid-customization-grid-output pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_outgoing_ack_available}
                                inValid={formik.errors?.is_outgoing_ack_available &&
                                    !!formik.errors?.is_outgoing_ack_available}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_outgoing_ack_available &&
                                    formik.errors?.is_outgoing_ack_available
                                }
                                id='is_outgoing_ack_available'
                                name='is_outgoing_ack_available'
                                label='Output Sent'
                            >

                            </FormCheck>
                        </div>



                    </div>
                </div>
                <div>
                    {formik.values?.is_outgoing_ack_available &&
                        <div className='grid-customization-grid-output-2-item pb-4'>


                            <React.Fragment>
                                <div >
                                    <Form.Group className="" >
                                        <div className='d-flex flex-row align-items-center'>
                                            <Form.Label className='fw-bold'>Outgoing ACK File Naming Format</Form.Label>
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[Timestamp]', 'outgoing_ack_file_naming_format')}>Timestamp</Button>{' '}
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Name]', 'outgoing_ack_file_naming_format')}>File Name</Button>{' '}
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Extension]', 'outgoing_ack_file_naming_format')}>File Extension</Button>{' '}                                        </div>

                                        <Form.Control
                                            name='outgoing_ack_file_naming_format'
                                            type="text"
                                            autoComplete="false"
                                            className='b-r-10 form-control-solid'
                                            maxLength="255"
                                            placeholder="Outgoing ACK File Naming Format"
                                            isValid={formik.touched?.outgoing_ack_file_naming_format && !formik?.errors?.outgoing_ack_file_naming_format}
                                            isInvalid={!!formik.errors?.outgoing_ack_file_naming_format}
                                            value={formik.values?.outgoing_ack_file_naming_format}
                                            onChange={formik.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.outgoing_ack_file_naming_format}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className=''>
                                    <Form.Group className="mb-3" >

                                        <Form.Label className='fw-bold'>Outgoing ACK Output File Directory</Form.Label>
                                        <Form.Control
                                            name='outgoing_ack_output_file_directory'
                                            className='b-r-10 form-control-solid form-control'
                                            maxLength="255"
                                            isValid={formik.touched?.outgoing_ack_output_file_directory && !formik?.errors?.outgoing_ack_output_file_directory}
                                            isInvalid={!!formik.errors?.outgoing_ack_output_file_directory}
                                            value={formik.values?.outgoing_ack_output_file_directory}
                                            onChange={formik.handleChange}
                                            placeholder="Outgoing ACK Output File Directory"
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.outgoing_ack_output_file_directory}</Form.Control.Feedback>

                                    </Form.Group>
                                </div>
                            </React.Fragment>


                        </div>
                    }
                </div>

                <div>
                    <Form.Label className='fw-bold clr-heading'>EPIC</Form.Label>

                    <div className='grid-customization-grid-output pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_epic_available}
                                inValid={formik.errors?.is_epic_available &&
                                    !!formik.errors?.is_epic_available}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_epic_available &&
                                    formik.errors?.is_epic_available
                                }
                                id='is_epic_available'
                                name='is_epic_available'
                                label='Enable EPIC'
                            >

                            </FormCheck>
                        </div>
                        {formik?.values?.is_epic_available &&
                            <div className='d-flex col-lg-6'>
                                <Form.Group className="mb-3 me-3" >

                                    <Form.Label className='fw-bold'>EPIC Output Schema</Form.Label>
                                    <Form.Control
                                        name='epic_self_pay_output_file '
                                        type="file"
                                        onChange={(e) => handleFileChange(e)}
                                        autoComplete="false"
                                        isValid={formik?.values?.epic_self_pay_output_file && !!formik?.errors?.epic_self_pay_output_file}
                                        isInvalid={!!formik?.errors?.epic_self_pay_output_file}
                                        // value={formik?.values?.epic_self_pay_output_file }
                                        className='b-r-10 form-control-solid browse-file-padd'
                                        accept='application/json'
                                    />
                                    <span className='text-danger'>{formik?.errors?.epic_self_pay_output_file}</span>

                                </Form.Group>
                                {formik?.values?.epic_self_pay_output_file &&
                                    <div className='col-lg-3'>
                                        <Form.Group className="mb-2" >

                                            <Form.Label className='fw-bold'>EPIC File : </Form.Label>

                                            <div className='overflow-hidden'>
                                                <div>
                                                    <button className='btn btn-dark' onClick={() => onButtonClick(formik?.values?.epic_self_pay_output_file)} type="button"  ><i className='bi bi-download text-white'></i></button>
                                                </div>
                                                {/* <span className='fw-bold'>{formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.index_file_schema}</span> */}
                                            </div>

                                        </Form.Group>
                                    </div>
                                }
                            </div>


                        }

                    </div>
                </div>


                <div>
                    <Form.Label className='fw-bold clr-heading'>Custom Check Number</Form.Label>

                    <div className='grid-customization-grid-output pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_custom_check_number_available}
                                inValid={formik.errors?.is_custom_check_number_available &&
                                    !!formik.errors?.is_custom_check_number_available}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_custom_check_number_available &&
                                    formik.errors?.is_custom_check_number_available
                                }
                                id='is_custom_check_number_available'
                                name='is_custom_check_number_available'
                                label='Enable Custom Check Number'
                            >

                            </FormCheck>
                        </div>



                    </div>
                </div>
                <div>
                    <div className='grid-customization-grid-output-2-item pb-4'>


                        {formik.values?.is_custom_check_number_available &&
                            <React.Fragment>
                                <div >
                                    <Form.Group className="" >
                                        <div className='d-flex flex-row align-items-center'>
                                            <Form.Label className='fw-bold'>Custom Check Number Format</Form.Label>
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[date]', 'is_custom_check_number_format')}>date</Button>{' '}
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[datetime]', 'is_custom_check_number_format')}>datetime</Button>{' '}
                                            <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[inc_num(000000-999999)]', 'is_custom_check_number_format')}>inc_num(000000-999999)</Button>{' '}
                                        </div>

                                        <Form.Control
                                            name='is_custom_check_number_format'
                                            type="text"
                                            autoComplete="false"
                                            className='b-r-10 form-control-solid'
                                            maxLength="255"
                                            placeholder="Custom Check Number Format"
                                            isValid={formik.touched?.is_custom_check_number_format && !formik?.errors?.is_custom_check_number_format}
                                            isInvalid={!!formik.errors?.is_custom_check_number_format}
                                            value={formik.values?.is_custom_check_number_format}
                                            onChange={formik.handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">{formik?.errors?.is_custom_check_number_format}</Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                            </React.Fragment>

                        }

                    </div>
                </div>
                <div>
                    <Form.Label className='fw-bold clr-heading'>PLB Only</Form.Label>

                    <div className='grid-customization-grid-output pb-4'>
                        <div className="">
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.is_grid_customzation_enable}
                                inValid={formik.errors?.is_grid_customzation_enable &&
                                    !!formik.errors?.is_grid_customzation_enable}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_grid_customzation_enable &&
                                    formik.errors?.is_grid_customzation_enable
                                }
                                id='is_grid_customzation_enable'
                                name='is_grid_customzation_enable'
                                label='Enable PLB Only'
                            >

                            </FormCheck>
                        </div>



                    </div>
                </div>
                <div>
                    <Form.Label className='fw-bold clr-heading'>Others</Form.Label>
                    <div className='grid-customization-grid pb-4'>
                        <div className="">
                            {/* <Tooltip disableInteractive title={'ANSI Line of Business'} placement='top' arrow> */}
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.merged_claim_info_in_financial_class_field}
                                inValid={formik.errors?.merged_claim_info_in_financial_class_field &&
                                    !!formik.errors?.merged_claim_info_in_financial_class_field}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.merged_claim_info_in_financial_class_field &&
                                    formik.errors?.merged_claim_info_in_financial_class_field
                                }
                                id='merged_claim_info_in_financial_class_field'
                                name='merged_claim_info_in_financial_class_field'
                                label='ANSI Line of Business'
                            >

                            </FormCheck>
                            {/* </Tooltip> */}
                        </div>




                        <div className="">
                            {/* <Tooltip disableInteractive title={'Auto Populate Payer ID'} placement='top' arrow> */}
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={(e) => {
                                    formik.setValues({
                                        ...formik.values,
                                        [e.target.name]: e.target.checked,
                                        payer_id_identification: false
                                    })
                                }}
                                checked={formik.values.is_auto_populate_payer_id}
                                inValid={formik.errors?.is_auto_populate_payer_id &&
                                    !!formik.errors?.is_auto_populate_payer_id}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.is_auto_populate_payer_id &&
                                    formik.errors?.is_auto_populate_payer_id
                                }
                                id='is_auto_populate_payer_id'
                                name='is_auto_populate_payer_id'
                                label='Auto Populate Payer ID'
                            >

                            </FormCheck>
                            {/* </Tooltip> */}
                        </div>
                        <div className="">
                            {/* <Tooltip disableInteractive title={'Auto Populate Payer ID'} placement='top' arrow> */}
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={(e) => {
                                    formik.setValues({
                                        ...formik.values,
                                        [e.target.name]: e.target.checked,
                                        is_auto_populate_payer_id: false
                                    })
                                }}
                                checked={formik.values.payer_id_identification}
                                inValid={formik.errors?.payer_id_identification &&
                                    !!formik.errors?.payer_id_identification}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.payer_id_identification &&
                                    formik.errors?.payer_id_identification
                                }
                                id='payer_id_identification'
                                name='payer_id_identification'
                                label='Payer ID and Payer Identification'
                            >

                            </FormCheck>
                            {/* </Tooltip> */}
                        </div>
                        <div>
                            {/* <Tooltip disableInteractive title={'Auto Populate Payer ID'} placement='top' arrow> */}
                            <FormCheck
                                inline
                                type='switch'
                                reverse={true}
                                onChange={handleSwitchChange}
                                checked={formik.values.line_of_business_based_on_payer}
                                inValid={formik.errors?.line_of_business_based_on_payer &&
                                    !!formik.errors?.line_of_business_based_on_payer}
                                feedbackType='invalid'
                                feedback={
                                    formik.errors?.line_of_business_based_on_payer &&
                                    formik.errors?.line_of_business_based_on_payer
                                }
                                id='line_of_business_based_on_payer'
                                name='line_of_business_based_on_payer'
                                label='Line Of Business Based On Payer'
                            >

                            </FormCheck>
                            {/* </Tooltip> */}
                        </div>



                    </div>
                </div>
                <Form.Label className='fw-bold clr-heading'>Defaults</Form.Label>

                <div className="mb-3 row border-bottom-dashed " >
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Payee ID Code Qualifier</Form.Label>
                            <Form.Control
                                name='default_payee_code_qualifier'
                                type="text"
                                onChange={formik.handleChange}
                                autoComplete="false"
                                isInvalid={!!formik.errors?.default_payee_code_qualifier}
                                value={formik.values?.default_payee_code_qualifier}
                                className='b-r-10 form-control-solid'

                                placeholder="Payee Id"
                            />
                            <Form.Control.Feedback type="invalid">{formik.errors?.default_payee_code_qualifier}</Form.Control.Feedback>

                        </Form.Group>

                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold' >Payer Identification</Form.Label>

                            <Form.Control
                                name='default_payer_identification'
                                type="text"
                                autoComplete="false"
                                isInvalid={!!formik?.errors?.default_payer_identification}
                                value={formik.values?.default_payer_identification}
                                className='b-r-10 form-control-solid'
                                onChange={formik.handleChange}

                                placeholder="Payer Id"
                            />
                            <Form.Control.Feedback type="invalid">{formik?.errors?.default_payer_identification}</Form.Control.Feedback>

                        </Form.Group>
                    </div>


                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Payer ID Code Qualifier</Form.Label>
                            <Form.Control
                                name='default_payer_code_qualifier'
                                type="text"
                                onChange={formik.handleChange}
                                autoComplete="false"
                                isInvalid={!!formik.errors?.default_payer_code_qualifier}
                                value={formik.values?.default_payer_code_qualifier}
                                className='b-r-10 form-control-solid'

                                placeholder="Payer Code Qualifier"
                            />
                            <Form.Control.Feedback type="invalid">  {formik.errors?.default_payer_code_qualifier}</Form.Control.Feedback>

                        </Form.Group>
                    </div>

                </div>
                <div className='d-flex flex-row justify-content-end pt-3'>
                    <div className=''>
                        <button
                            type='button'
                            className='btn btn-secondary btn-active-primary me-2'
                            onClick={() => { changeAllTitleFunction('Client Setup', CLIENT) }}
                        >
                            Cancel
                        </button>
                    </div>
                    {client_data ? (

                        <div className=''>
                            <button
                                disabled={!formik.isValid || isSubmit}
                                type='submit'
                                className='btn btn-primary btnHover'
                            >
                                {isSubmit ? "Updating" : "Update"}
                            </button>
                        </div>
                    ) : (
                        <div className=''>
                            <button
                                disabled={!formik.isValid || isSubmit}
                                type='submit'
                                className='btn btn-primary btnHover'
                            >
                                {isSubmit ? "Saving" : "Save"}
                            </button>
                        </div>
                    )}
                    {/* <div className=''>
                        <button
                            type='submit'
                            disabled={!formik.isValid}
                            className='btn btn-light btn-active-primary'
                        >
                            Save
                        </button>
                    </div> */}
                </div>
            </Form >
        </div >
    )
}

export default AdvanceCustom;