import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import AdditionalInfo from './additionalInfo';
import { useRef } from 'react';
import { handleKeyPress } from '../gridFunctions';
import { DATA_ENTRY } from './../../../../../routeNames';
import { evaluate } from 'mathjs'; // Import the evaluate function from math.js


const GridFooter = (props) => {

    const { state, isAdditionalInfoOptions, formEob, formPP, formCorres, eobLiteFormik, isDisabled, aditionalInfoFormik, additionalModal, setAdditionalModal, setIsDrag, isAdditional, jobData, activePdf, firstSaveLoad, secondSaveLoad, claim835Index, isViewOnllyGrid, currentJobVerified
    } = props;
    const [result, setResult] = useState(0);
    const aditionalInfoRef = useRef(null)
    const pathname = window.location.pathname;
    const handleInputExpression = (event) => {
        setResult(evaluate(event.target.value));
    };

    const handleKeyPressEnter = () => {
        // alert('asdf')
    }
    const handleTabFunction = (e, field) => {
        if (e.key == 'Tab' && !e.shiftKey && pathname == DATA_ENTRY) {

            if (field == 'additional') {
                if ((state?.Payment835 && !formEob.isValid) || (state?.Payment835Lite && !eobLiteFormik.isValid) || (state?.PatientPay && !formPP.isValid) || (state?.Correspondence && !formCorres.isValid)) {
                    e.preventDefault();
                }
            }
            else if (field == 'save') {
                e.preventDefault();
            }
        }
        else if (e.key == 'Tab' && e.shiftKey && field == 'additional') {
            e.preventDefault();
            var divElem = document.getElementById("grid-full-container");
            var inputs = Array.prototype.slice.call(divElem.querySelectorAll("input, select"));


            const input = inputs[inputs.length - 1];
            input.focus();
        }
    }
    const handleAdditionalModal = () => {
        if (state?.Payment835) {
            aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: formEob.values.qdx_status ? formEob.values.qdx_status : "",
                reason: formEob.values.qdx_reason ? formEob.values.qdx_reason : "",
                document_classification: formEob.values.qdx_classification ? formEob.values.qdx_classification : '',
                is_orphaned_check: formEob.values.is_orphaned_check ? formEob.values.is_orphaned_check : false,
                is_missing_check_image: formEob.values.is_missing_check_image ? formEob.values.is_missing_check_image : false,
                is_forced_balancing: formEob.values.is_forced_balancing ? formEob.values.is_forced_balancing : false,
                is_payer_black_listing: formEob.values.is_payer_black_listing ? formEob.values.is_payer_black_listing : false,
                is_payer_excluding_indexing: formEob.values.is_payer_excluding_indexing ? formEob.values.is_payer_excluding_indexing : false,
            })
        }
        else if (state?.Payment835Lite) {
            aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: eobLiteFormik.values.qdx_status ? eobLiteFormik.values.qdx_status : "",
                reason: eobLiteFormik.values.qdx_reason ? eobLiteFormik.values.qdx_reason : "",
                document_classification: eobLiteFormik.values.qdx_classification ? eobLiteFormik.values.qdx_classification : '',
                is_orphaned_check: eobLiteFormik.values.is_orphaned_check ? eobLiteFormik.values.is_orphaned_check : false,
                is_missing_check_image: eobLiteFormik.values.is_missing_check_image ? eobLiteFormik.values.is_missing_check_image : false,
                is_forced_balancing: eobLiteFormik.values.is_forced_balancing ? eobLiteFormik.values.is_forced_balancing : false,
                is_payer_black_listing: eobLiteFormik.values.is_payer_black_listing ? eobLiteFormik.values.is_payer_black_listing : false,
                is_payer_excluding_indexing: eobLiteFormik.values.is_payer_excluding_indexing ? eobLiteFormik.values.is_payer_excluding_indexing : false,
            })

        }
        else if (state?.PatientPay) {
            aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: formPP.values.qdx_status ? formPP.values.qdx_status : "",
                reason: formPP.values.qdx_reason ? formPP.values.qdx_reason : "",
                document_classification: formPP.values.qdx_classification ? formPP.values.qdx_classification : '',
                is_orphaned_check: formPP.values.is_orphaned_check ? formPP.values.is_orphaned_check : false,
                is_missing_check_image: formPP.values.is_missing_check_image ? formPP.values.is_missing_check_image : false,
                is_forced_balancing: formPP.values.is_forced_balancing ? formPP.values.is_forced_balancing : false,
                is_payer_black_listing: formPP.values.is_payer_black_listing ? formPP.values.is_payer_black_listing : false,
                is_payer_excluding_indexing: formPP.values.is_payer_excluding_indexing ? formPP.values.is_payer_excluding_indexing : false,
            })

        }
        else if (state?.Correspondence) {
            aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: formCorres.values.qdx_status ? formCorres.values.qdx_status : "",
                reason: formCorres.values.qdx_reason ? formCorres.values.qdx_reason : "",
                document_classification: formCorres.values.qdx_classification ? formCorres.values.qdx_classification : '',
                is_orphaned_check: formCorres.values.is_orphaned_check ? formCorres.values.is_orphaned_check : false,
                is_missing_check_image: formCorres.values.is_missing_check_image ? formCorres.values.is_missing_check_image : false,
                is_forced_balancing: formCorres.values.is_forced_balancing ? formCorres.values.is_forced_balancing : false,
                is_payer_black_listing: formCorres.values.is_payer_black_listing ? formCorres.values.is_payer_black_listing : false,
                is_payer_excluding_indexing: formCorres.values.is_payer_excluding_indexing ? formCorres.values.is_payer_excluding_indexing : false,
            })
        }
        setAdditionalModal(true);
        setIsDrag(false)

    }
    return (
        <div className='quick-calc'>

            <div className='leftside-block'>
                <div>
                    <input data-not-take-field-count="true" tabIndex="-1" className='quickcalc-btn' placeholder='Quick Calculator' onChange={(event) => handleInputExpression(event)} type="text" />
                </div>
                <div>
                    <span style={{ color: "#fff", fontWeight: 900 }}>=</span>
                </div>
                <div className='quickcalcResult'>
                    {parseFloat(result ? result : 0).toFixed(2)}
                </div>
            </div>

            {state?.Payment835 &&
                <React.Fragment>
                    {formEob.isSubmitting ? (

                        <div className='rightside-block gap-4'>
                            <div>
                                <button data-save-loading={true} disabled className='btn btn-reject' type="button"  >
                                    <span className='  '> {'SAVE'} </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button data-save-loading={true} disabled className='btn btn-reject ' type="button"  ><span className='  '> {formEob.isSubmitting ? 'SAVE & EXIT' : 'SAVE & EXIT'} </span></button>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className='rightside-block gap-4'>
                                {isAdditional &&
                                    <React.Fragment>
                                        <div>
                                            <AdditionalInfo
                                                {...props}
                                                formEob={formEob}
                                                aditionalInfoRef={aditionalInfoRef}
                                                formik={aditionalInfoFormik}
                                                setAdditionalModal={setAdditionalModal}
                                                additionalModal={additionalModal}
                                                setIsDrag={setIsDrag}

                                            />

                                            <button id='additional-btn' tabIndex='0' onClick={handleAdditionalModal} ref={aditionalInfoRef} className={!aditionalInfoFormik?.isValid ? 'btn btn-reject border-danger exclude-disabled btn-focus-shadow' : "btn btn-reject exclude-disabled btn-focus-shadow"} type="button"
                                                onKeyDown={(e) => {
                                                    handleTabFunction(e, 'additional')
                                                }} >
                                                <span className='  '> Additional Info </span>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                }
                                <div>
                                    <button id='save-btn' tabIndex='0' className='btn btn-reject btn-focus-shadow' data-save-loading={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formEob.isValid} disabled={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formEob.isValid} type="button" onClick={() => { localStorage.removeItem('SAVE_EXIT'); formEob.handleSubmit() }} >
                                        <span className='  '> SAVE</span>
                                    </button>
                                </div>
                                <div className='power-off'>
                                    <button id='save-exit-btn' tabIndex='0' className='btn btn-reject btn-focus-shadow' onKeyDown={(e) => handleTabFunction(e, 'save')} onClick={() => { localStorage.setItem('SAVE_EXIT', true); formEob.handleSubmit() }} type="button" data-save-loading={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formEob.isValid} disabled={isViewOnllyGrid ? true : currentJobVerified == false ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : !formEob.isValid} ><span className='  '>SAVE & EXIT</span></button>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                    }
                </React.Fragment>
            }

            {state?.Payment835Lite &&
                <React.Fragment>
                    {eobLiteFormik.isSubmitting ? (
                        <div className='rightside-block gap-4'>
                            <div>
                                <button data-save-loading={true} className='btn btn-reject' type="button"
                                    disabled onClick={eobLiteFormik.handleSubmit}
                                >
                                    <span className='  '> {eobLiteFormik.isSubmitting ? 'SAVE' : 'SAVE '} </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button data-save-loading={true} className='btn btn-reject ' type="button" disabled ><span className='  '>{eobLiteFormik.isSubmitting ? 'SAVE & EXIT' : 'SAVE & EXIT'}</span></button>
                            </div>
                        </div>
                    ) : (
                        <div className='rightside-block gap-4'>
                            {isAdditional &&
                                <React.Fragment>
                                    <div>
                                        <AdditionalInfo
                                            {...props}
                                            formEob={eobLiteFormik}
                                            aditionalInfoRef={aditionalInfoRef}
                                            formik={aditionalInfoFormik}
                                            setAdditionalModal={setAdditionalModal}
                                            additionalModal={additionalModal}
                                            setIsDrag={setIsDrag}

                                        />

                                        <button id='additional-btn' onKeyDown={(e) => handleTabFunction(e, 'additional')} tabIndex='0' onClick={handleAdditionalModal} ref={aditionalInfoRef} className={!aditionalInfoFormik?.isValid ? 'btn btn-reject border-danger exclude-disabled btn-focus-shadow' : "btn btn-reject exclude-disabled btn-focus-shadow"} type="button" >
                                            <span className='  '> Additional Info </span>
                                        </button>

                                    </div>
                                </React.Fragment>
                            }
                            <div>
                                <button id='save-btn' tabIndex='0' className='btn btn-reject btn-focus-shadow' type="button" onKeyDown={handleKeyPress} data-save-loading={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !eobLiteFormik.isValid} disabled={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !eobLiteFormik.isValid} onClick={() => { localStorage.removeItem('SAVE_EXIT'); eobLiteFormik.handleSubmit() }} >
                                    <span className='  '>SAVE</span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button id='save-exit-btn' onKeyDown={(e) => handleTabFunction(e, 'save')} tabIndex='0' className='btn btn-reject btn-focus-shadow' onClick={() => { localStorage.setItem('SAVE_EXIT', true); eobLiteFormik.handleSubmit() }} type="button" data-save-loading={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !eobLiteFormik.isValid} disabled={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !eobLiteFormik.isValid} ><span className='  '>SAVE & EXIT</span></button>
                            </div>
                        </div>

                    )
                    }
                </React.Fragment>
            }

            {state?.PatientPay &&
                <React.Fragment>
                    {formPP.isSubmitting ? (
                        <div className='rightside-block gap-4'>
                            <div>
                                <button data-save-loading={true} className='btn btn-reject' type="button" disabled onClick={formPP.handleSubmit} >
                                    <span className='  '> {formPP.isSubmitting ? 'SAVE' : 'SAVE '} </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button data-save-loading={true} className='btn btn-reject ' type="button" disabled ><span className='  '>{formPP.isSubmitting ? 'SAVE & EXIT' : 'SAVE & EXIT'}</span></button>
                            </div>
                        </div>
                    ) : (
                        <div className='rightside-block gap-4'>
                            {isAdditional &&
                                <React.Fragment>
                                    <div>
                                        <AdditionalInfo
                                            {...props}
                                            formEob={formPP}
                                            aditionalInfoRef={aditionalInfoRef}
                                            formik={aditionalInfoFormik}
                                            setAdditionalModal={setAdditionalModal}
                                            additionalModal={additionalModal}
                                            setIsDrag={setIsDrag}

                                        />

                                        <button id='additional-btn' onKeyDown={(e) => handleTabFunction(e, 'additional')} tabIndex='0' onClick={handleAdditionalModal} ref={aditionalInfoRef} className={!aditionalInfoFormik?.isValid ? 'btn btn-reject border-danger exclude-disabled btn-focus-shadow' : "btn btn-reject exclude-disabled btn-focus-shadow"} type="button" >
                                            <span className='  '> Additional Info </span>
                                        </button>
                                    </div>
                                </React.Fragment>
                            }
                            <div>
                                <button id='save-btn' tabIndex='0' className='btn btn-reject btn-focus-shadow' onKeyDown={handleKeyPress} type="button" data-save-loading={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formPP.isValid} disabled={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formPP.isValid} onClick={() => { localStorage.removeItem('SAVE_EXIT'); formPP.handleSubmit() }} >
                                    <span className='  '>SAVE</span>
                                </button>
                            </div>

                            <div className='power-off'>
                                <button id='save-exit-btn' onKeyDown={(e) => handleTabFunction(e, 'save')} tabIndex='0' className='btn btn-reject btn-focus-shadow ' onClick={() => { localStorage.setItem('SAVE_EXIT', true); formPP.handleSubmit() }} type="button" data-save-loading={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formPP.isValid} disabled={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formPP.isValid} ><span className='  '>SAVE & EXIT</span></button>
                            </div>
                        </div>

                    )
                    }
                </React.Fragment>

            }

            {state?.Correspondence &&

                <React.Fragment>
                    {formCorres.isSubmitting ? (
                        <div className='rightside-block gap-4'>
                            <div>
                                <button data-save-loading={true} className='btn btn-reject' type="button" disabled onClick={formCorres.handleSubmit} >
                                    <span className='  '> {formCorres.isSubmitting ? 'SAVE' : 'SAVE '} </span>
                                </button>
                            </div>
                            <div className='power-off'>
                                <button data-save-loading={true} className='btn btn-reject ' type="button" disabled ><span className='  '>{formCorres.isSubmitting ? 'SAVE & EXIT' : 'SAVE & EXIT'}</span></button>
                            </div>
                        </div>
                    ) : (
                        <div className='rightside-block gap-4'>
                            {isAdditional &&
                                <React.Fragment>
                                    <div>
                                        <AdditionalInfo
                                            {...props}
                                            formEob={formCorres}
                                            aditionalInfoRef={aditionalInfoRef}
                                            formik={aditionalInfoFormik}
                                            setAdditionalModal={setAdditionalModal}
                                            additionalModal={additionalModal}
                                            setIsDrag={setIsDrag}

                                        />

                                        <button id='additional-btn' onKeyDown={(e) => handleTabFunction(e, 'additional')} tabIndex='0' onClick={handleAdditionalModal} ref={aditionalInfoRef} className={!aditionalInfoFormik?.isValid ? 'btn btn-reject border-danger btn-focus-shadow' : "btn btn-reject exclude-disabled btn-focus-shadow"} type="button" >
                                            <span className='  '> Additional Info </span>
                                        </button>
                                    </div>
                                </React.Fragment>
                            }

                            <div>
                                <button id='save-btn' tabIndex='0' className='btn btn-reject btn-focus-shadow' type="button" data-save-loading={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formCorres.isValid} disabled={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formCorres.isValid} onClick={() => { localStorage.removeItem('SAVE_EXIT'); formCorres.handleSubmit() }} >
                                    <span className=''>SAVE </span>
                                </button>
                            </div>
                            <div id='save-exit-btn' className='power-off'>
                                <button className='btn btn-reject  btn-focus-shadow' onKeyDown={(e) => handleTabFunction(e, 'save')} tabIndex='0' onClick={() => { localStorage.setItem('SAVE_EXIT', true); formCorres.handleSubmit() }} type="button" data-save-loading={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formCorres.isValid} disabled={isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formCorres.isValid} ><span className='  '>SAVE & EXIT</span></button>
                            </div>
                        </div>

                    )
                    }
                </React.Fragment>
            }

        </div>
    )
}

export default GridFooter;