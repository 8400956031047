import React, { useState } from "react";
import DataTable from "react-data-table-component";
import datatableCustomStyles from "../../../Layout/Elements/DatatableCustomStyles";
import { GET_CORRESPONDANCE } from "../../../routeNames";
import styled from 'styled-components/macro';
import AdminMaster from "../../../Layout/admin/Master";
import { useMemo } from "react";
import { getCorrespondanceData } from "../../../Redux/outputSchema/action";
import { useEffect } from "react";
import { Form, Button, OverlayTrigger } from "react-bootstrap";
import { getAllClientDropdown } from "../../../Redux/client/action";
import axiosInstance from "../../../axiosInstance";
import moment from "moment";
import { useFormik } from "formik";
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { DateRangePicker } from "rsuite";
import { BiReset, BiSearch } from "react-icons/bi";
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { Tooltip } from '@mui/material';
import { DownloadJsonUrl, DownloadXmlUrl } from "../Dashboard/functions";


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;
function CorrespondanceList(params) {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [pageno, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [filterText, setFilterText] = useState('');
    const [data, setData] = useState({})
    const [limit, setLimit] = useState(100)
    const [selectValue, setSelectValue] = useState([])
    const [colorRow, setColorRow] = React.useState({});
    const [datetime, setDateTime] = useState([]);
    const [clientList, setClientList] = useState();
    const [searchedData, setSearchedData] = useState({
        search: "",
        client: "",
        created_date: "",
        deposit_date_range: "",
    })



    const correspondenceFormik = useFormik({
        initialValues: {
            search: "",
            client: "",
            created_date: "",
            deposit_date_range: "",
        },
        onSubmit: (values) => {
            searchData(values)

            setSearchedData({
                search: values?.search ? values?.search : "",
                client: values?.client ? values?.client : "",
                created_date: values?.created_date ? values?.created_date : "",
                deposit_date_range: values?.deposit_date_range ? values?.deposit_date_range : "",
            })
            handleResetDataTable()
        }
    })

    const searchData = async ({ search, client, deposit_date_range }) => {

        fetchData(1, limit, search, selectValue, datetime);

    }

    useEffect(() => {
        // fetchData(1, limit)
        // console.log(response?.data?.count)
        getAllClientDropdown()((response) => {

            setClientList(response?.data)
        })
    }, []);

    const handleOnDateTimeChange = ({ name, value }) => {
        let created_date = ""
        if (value !== null) {
            const datefrom = moment(value['0']).format('MM/DD/YYYY')
            const dateto = moment(value['1']).format('MM/DD/YYYY')

            created_date = datefrom + " - " + dateto
        }

        setDateTime(created_date)

        correspondenceFormik.setValues({ ...correspondenceFormik.values, created_date, deposit_date_range: value ?? "" })


    }

    const handlePerRowsChange = async (newPerPage, page) => {


        setLoading(true);
        fetchData(1, newPerPage, searchedData?.search, searchedData?.client, searchedData?.created_date)
        setPageNo(1)
        setLimit(newPerPage)
        handleResetDataTable()
        setLoading(false);


    };

    const handlePageChange = page => {
        setPageNo(page)
        fetchData(page, limit, searchedData?.search, searchedData?.client, searchedData?.created_date);
    };

    const handleSearchSplits = async (e) => {
        setFilterText(e)
        let data = e ? e : '';
        fetchData(1, limit, data)
        //const response = await axiosInstance.get(GET_CORRESPONDANCE + "?limit=" + limit + "&search=" + data);
        //setData(response?.data);
        setLoading(false);
    }
    const fetchData = (page, pageLimit, search, client, deposit_date_range) => {
        // console.log("#$#$#$#$#$#$#$#$#$#$#$#$#$#$");
        setLoading(true)
        getCorrespondanceData({ page, pageLimit, search, client, deposit_date_range })((response) => {
            setData(response?.data);
            setLimit(pageLimit)
            setPageNo(page)
            setTotalRows(response?.data?.count);
            setLoading(false);
        });

    }
    const handleClear = () => {
        if (filterText) {
            //setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            fetchData(1, limit)
        }
    };
    useEffect(() => {
        fetchData(1, limit)
    }, []);
    const onButtonClick = (file) => {
        // using Java Script method to get PDF file
        const fileURL = file;
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();
    }


    const handleRowClick = (row) => {
        setColorRow(row);
    };

    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );
    const columns = useMemo(
        () => [
            {
                id: 'S.NO',
                name: 'S.NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                cell: (row, index) => <span style={{ width: "150px" }} onClick={() => handleRowClick(row)}>{pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1}</span>,
                width: "50px",
                wrap: true,
                ignoreRowClick: false,

            },
            {
                id: 'JOB ID',
                name: 'JOB ID',
                selector: row => row?.unique,
                cell: (row) => <span style={{ width: "120px" }} onClick={() => handleRowClick(row)}> {row?.unique}</span>,
                sortable: true,
                wrap: true,
                reorder: true,
                //width: "140px",
            },
            {
                id: 'CLIENT NAME',
                name: 'CLIENT NAME',
                selector: row => row?.client_name,
                cell: (row) => <div className="" onClick={() => handleRowClick(row)} >
                    {row?.client_name &&

                        <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' style={{ width: "120px", height: "80%" }}>
                            <Tooltip disableInteractive title={row?.client_name} placement='top' arrow>
                                <span className='text-truncate' > {row?.client_name}</span>
                            </Tooltip>
                        </div>

                    }
                </div>,
                sortable: true,
                wrap: true,
                reorder: true,
                width: "160px",
            },
            {
                id: 'OUTPUT FILE NAME',
                name: 'OUTPUT FILE NAME',
                selector: row => row?.file_name,
                cell: (row) => <div className="d-flex flex-row ">
                    {row?.file_name &&
                        <div className="overflow-hidden d-flex flex-row  flex-wrap text-truncate" onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                            <Tooltip disableInteractive title={row?.file_name} placement='top' arrow>

                                <span className="text-truncate " >{row?.file_name}</span>
                            </Tooltip>
                        </div>
                    }
                    {/* <div className="ms-3" role="button" onClick={() => onButtonClick(row?.file)}>
                        <i className="bi bi-download"></i>
                    </div> */}
                </div>,
                width: '160px',
                sortable: true,
                wrap: true,
                reorder: true,
            },
            {
                id: 'CHECK NUMBER',
                name: 'CHECK NUMBER',
                cell: (row) => <div onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                    <span style={!row?.job_acc_number ? { visibility: 'hidden' } : { visibility: 'show' }}> {row?.job_acc_number ? row?.job_acc_number : "nodata"}</span>

                </div>,
                sortable: true,
                wrap: true,
                reorder: true,
                //width: "120px",
            },
            {
                id: 'CHECK AMOUNT',
                name: 'CHECK AMOUNT',
                // selector: row => row?.job_amount,
                cell: (row) =>
                    <div onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                        <span style={!row?.job_amount ? { visibility: 'hidden' } : { visibility: 'show' }}> {row?.job_amount ? row?.job_amount : "nodata"}</span>

                    </div>,
                sortable: true,
                wrap: true,
                reorder: true,
                //width: '120px',
            },
            {
                id: 'JOB FILE NAME',
                name: 'JOB FILE NAME',
                selector: row => row?.job_file_name,
                cell: (row) => <div className="d-flex flex-row ">
                    {row?.job_file_name &&
                        <React.Fragment>

                            <div className="d-flex" onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                                <div className="overflow-hidden d-flex flex-row  flex-wrap text-truncate" style={{ width: '120px' }}>
                                    <Tooltip disableInteractive title={row?.job_file_name} placement='top' arrow>
                                        <span className="text-truncate " >{row?.job_file_name}</span>
                                    </Tooltip>
                                </div>
                                <div className="ms-3" role="button" onClick={() => { onButtonClick(row?.job_split_file) }}>
                                    <i className="bi bi-download"></i>
                                </div>
                            </div>


                        </React.Fragment>
                    }

                </div>,
                width: '160px',
                // sortable: true,
                // wrap: true,
                // reorder: true,

            },
            // {
            //     id: 'Version',
            //     name: 'Version',
            //     selector: row => row?.version,
            //     sortable: true,
            //     left: true,
            //     wrap: true,
            //     reorder: true,
            // },
            {
                id: 'USER NAME',
                name: 'USER NAME',
                selector: row => row?.staff_username,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                        <Tooltip disableInteractive title={row?.staff_username} placement='top' arrow>
                            <span className='text-truncate'> {row?.staff_username}</span>
                        </Tooltip>
                    </div>,
                sortable: true,
                wrap: true,
                //width: "120px",
                reorder: true,
            },
            {
                id: 'GENERATED DATE',
                name: 'GENERATED DATE',
                selector: row => moment(row?.generated_date).format('M/DD/YY, H:mm'),
                cell: (row) => <div style={{ width: "100px" }} onClick={() => handleRowClick(row)}>
                    <span >{row?.generated_date ? moment(row?.generated_date).format('M/DD/YY, H:mm') : '-----'}</span>

                </div>,
                sortable: true,
                wrap: true,
                reorder: true,
                //width: "100px",
            },

            {
                id: 'STATUS',
                name: 'STATUS',
                selector: row => row?.status,
                cell: (row) =>
                    <div className='text-truncate d-flex flex-row  flex-wrap'
                        onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                        <Tooltip disableInteractive title={row?.status} placement='top' arrow>
                            <span className='text-truncate'> {row?.status}</span>
                        </Tooltip>
                    </div>,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                //width: "80px",
            },
            {
                id: 'ACTION',
                name: 'ACTION',
                selector: row => row?.push_status,
                cell: (row) => <div className="text-end" role="button" onClick={() => handleRowClick(row)}>
                    <i className="bi bi-download" onClick={(e) => DownloadXmlUrl(e, row?.file)}></i>
                </div>,
                //sortable: true,
                right: true,
                //wrap: true,
                buttpn: false,
                width: "80px",
                // right: true,
            },

            // {
            //     id: 'status',
            //     name: (row) => row?.status == "Assigned" ? 'Assignee' : 'Status',

            //     cell: (row) =>
            //         <div className="d-flex justify-content-end flex-shrink-0 ">

            //             <React.Fragment>
            //                 <span className='badge badge-light-danger'>

            //                     {row?.staff?.username}
            //                 </span>
            //             </React.Fragment>
            //         </div>,
            //     ignoreRowClick: true,
            //     allowOverflow: true,
            //     button: true,
            // },

        ],
        [pageno],
    );
    var i = 0;
    const conditionalRowStyles = [


        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
            }),
        },
    ];

    const handleOnSelectClientChange = async (e, name) => {
        let selectedId = []
        let selectedOptons = []

        e.map((option) => {
            selectedId.push(option.value)
            selectedOptons.push({ option })
        })

        correspondenceFormik.setValues({ ...correspondenceFormik.values, [name]: selectedId })
        setSelectValue(selectedId)
    }

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }
    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {correspondenceFormik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const handleRowClickCheck = (row) => {

        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
    }
    return (
        <React.Fragment>
            <AdminMaster />
            <main id="main" className="main">

                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Correspondence</h1>
                </div>

                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                {/* <div className="d-flex align-items-center  position-relative  ms-4">
                                        <FilterComponent onFilter={(e) => handleSearchSplits(e.target.value)} onClear={handleClear} filterText={filterText} />
                                    </div> */}
                                <Form onSubmit={correspondenceFormik.handleSubmit} onReset={() => window.location.reload()}>
                                    <div className=" ">
                                        <div className=" px-3 py-3 d-flex flex-row justify-content-start gap-2 align-items-center">
                                            <div>
                                                <Form.Control
                                                    className="form-control-solid b-r-10 outputSchema-search"
                                                    onChange={correspondenceFormik.handleChange}
                                                    name="search"
                                                    type="search"
                                                    style={{ width: '220px', fontSize: "14px" }}
                                                    placeholder="Filename / Check Number"
                                                    value={correspondenceFormik.values?.search}
                                                />
                                            </div>
                                            <div>
                                                <Select
                                                    isMulti
                                                    options={clientList}
                                                    className="select-search-835-list"
                                                    classNamePrefix="select"
                                                    placeholder="Select Client"
                                                    values={selectValue}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={300}
                                                    isClearable
                                                    name="client"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client" /> }}
                                                    onChange={(e) => handleOnSelectClientChange(e, 'client')}
                                                    defaultValue={correspondenceFormik?.values?.client}
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px", minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),
                                                    }} />
                                            </div>

                                            <div className="correspondenceDatePicker">

                                                <Stack direction="column" spacing={8} alignItems="flex-start">
                                                    <DateRangePicker
                                                        // style={{ backgroundColor: "#000" }}
                                                        ranges={predefinedBottomRanges}
                                                        placement="auto"
                                                        cleanable={true}
                                                        appearance="default" format={"MM/dd/yyyy"}
                                                        placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                                                        className="form-date-control form-control-solid b-r-1 text-dark"
                                                        name="deposit_date_range"
                                                        onChange={(e) => { handleOnDateTimeChange({ name: 'deposit_date_range', value: e }) }} value={correspondenceFormik?.values?.deposit_date_range} />
                                                </Stack>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary" type="submit">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiSearch />
                                                        </span>

                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary " type='reset' >
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiReset />
                                                        </span>

                                                    </Button>
                                                </Tooltip>

                                            </div>
                                        </div>

                                    </div>

                                </Form>


                                <div className="card-body" style={{ padding: "0px 2.25rem" }}>
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}
                                                    progressPending={loading}
                                                    data={data?.results}
                                                    columns={columns}
                                                    pointerOnHover={true}
                                                    pagination
                                                    paginationServer
                                                    onRowClicked={handleRowClickCheck}
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}

                                                    conditionalRowStyles={conditionalRowStyles}
                                                    // subHeader
                                                    // subHeaderComponent={subHeaderComponentMemo}
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}

                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </main>
        </React.Fragment>
    )
}
export default CorrespondanceList




