import React, { useState } from "react";
import { Alert, Form, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import { handleWheel, handleKeyPress, preventEFromNumber, handlePreventEKeyPress } from "../gridFunctions";
import search from "../../../../../assets/img/searchicon.png";
import { FieldArray } from "formik";
import { DbkField } from "../Elements/DbkField";
import moment from "moment";
import { DATA_ENTRY, EDIT_WORK, IFRAME, QA_REVIEW } from "../../../../../routeNames";
import { isActiveDbkField } from "../../../Dashboard/functions";
import { async } from "@firebase/util";
import SweetAlert from "react-bootstrap-sweetalert";

const Eob = (props) => {
  const {
    formik,
    handleFloatValue,
    setcheckNum,
    totalPages,
    handleChangeIds,

    handlePLbFloatValue,
    handlePlbDeleteAmount,
    handlePlbAmount,
    insertRemainingAmount,

    __handlePayerLookupSearch,
    setPayerValue,
    setPayerFocus,
    payerFocus,

    __handlePayeeLookupSearch,
    setPayeeValue,
    setPayeeFocus,
    payeeFocus,

    cleareEobFocus,
    eobFocus,
    setEobFocus,
    handleFocusEob,
    addingNewEobClaimDbk,
    removeEobClaimDbk,
    isDbkPermission,
    jobData,
    qdxTransaction,
    isCheckNumber,
    setIsCheckNumber,
    isAlert,
    setIsAlert,
    isCorres835,
    isPP835,
    isViewOnllyGrid,
    isSubJob,
    isFirstJob,
    job_id,
    log_id,
    isGeneratedCheckNumber,
    isPayerPage,
    isPayeePage,
    pdfLoaded,
    currentPage,
    disableCorresField,
    claim835Index,
    setDefaultValueMainGrid
  } = props;

  const [options, setOptions] = useState(
    jobData?.correspondence_categories ? jobData?.correspondence_categories : []
  );
  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  const qaCorrectedData = gridData?.qa_corrected_data;

  const [plbOnFocus, setPlbOnFocus] = useState(false)
  const handleChangeCheckAmount = async (e) => {
    const value = e.target.value;
    if (value.includes(".") && value.split(".")[1].length > 2) {
      return;
    }

    await formik.setValues((prevState) => {

      if (e.target.value > 0 && qdxTransaction == "CORRESPONDENCE") {
        setIsAlert(true);
        return {
          ...prevState,
        };
      }

      if (window.location.pathname == DATA_ENTRY) {
        var payment_method = "";
        var check_number = prevState?.check_number;
        if (parseFloat(value) == parseFloat(0)) {
          if (qdxTransaction != "CORRESPONDENCE") {
            payment_method = "NON";

            if (isCheckNumber) {
              let num = Math.floor(Math.random() * 10000);

              num = num.toString().padStart(4, '0');
              if (isGeneratedCheckNumber) {
                check_number = "RX" + jobData?.grid_data?.timezone
              }

            }
            else {
              check_number = prevState?.check_number ? prevState?.check_number : ""
            }
          } else {
            payment_method = "COR";
            if (isCheckNumber) {
              let num = Math.floor(Math.random() * 10000);

              num = num.toString().padStart(4, '0');
              if (isGeneratedCheckNumber) {
                check_number = "LT" + jobData?.grid_data?.timezone
              }

            }
            else {
              check_number = prevState?.check_number ? prevState?.check_number : ""
            }
          }

        }
        let remaining = 0;
        let paid_total = 0;
        let plb_total = 0;
        prevState?.claims?.map((claim, ind) => {
          paid_total =
            parseFloat(paid_total ? paid_total : 0) +
            parseFloat(claim.claim_paid ? claim.claim_paid : 0);
        });

        prevState?.plb_adjustments?.map((plb, ind) => {
          plb_total =
            parseFloat(paid_total ? plb_total : 0) +
            parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
        });

        remaining = parseFloat(
          parseFloat(value ? value : 0) -
          parseFloat(paid_total ? paid_total : 0) +
          parseFloat(plb_total ? plb_total : 0)
        ).toFixed(2);

        if (remaining == -0.0) {
          remaining = parseFloat(0).toFixed(2);
        }

        return {
          ...prevState,
          check_amount: e.target.value,
          payment_method: payment_method ? payment_method : prevState?.payment_method,
          remaining_balance: remaining,
          dbk_check_number: check_number,
          check_number: check_number,
        };
      } else {
        var payment_method = prevState?.payment_method;
        if (parseFloat(value) == parseFloat(0) || parseFloat(value) == "") {
          if (qdxTransaction != "CORRESPONDENCE") {
            payment_method = "NON";
          }
          else {
            payment_method = "COR";

          }
        }

        let remaining = 0;
        let paid_total = 0;
        let plb_total = 0;
        prevState?.claims?.map((claim, ind) => {
          paid_total =
            parseFloat(paid_total ? paid_total : 0) +
            parseFloat(claim.claim_paid ? claim.claim_paid : 0);
        });

        prevState?.plb_adjustments?.map((plb, ind) => {
          plb_total =
            parseFloat(paid_total ? plb_total : 0) +
            parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
        });

        remaining = parseFloat(
          parseFloat(value ? value : 0) -
          parseFloat(paid_total ? paid_total : 0) +
          parseFloat(plb_total ? plb_total : 0)
        ).toFixed(2);

        if (remaining == -0.0) {
          remaining = parseFloat(0).toFixed(2);
        }

        return {
          ...prevState,
          check_amount: e.target.value,
          remaining_balance: remaining,
          payment_method: payment_method
        };
      }
    });

  }

  const handleInputPaymentMethod = async (e) => {

    let check_data = "";

    if (e.target.value == "COR" && qdxTransaction != "CORRESPONDENCE") {
      setIsAlert(true)
      return
    } else if (e.target.value != "COR" && qdxTransaction == "CORRESPONDENCE") {
      setIsAlert(true)
      return
    }

    else {

      if (isCheckNumber && window.location.pathname == DATA_ENTRY) {

        if (e.target.value == "ACH") {
          let num = Math.floor(Math.random() * 10000);

          num = num.toString().padStart(4, '0');
          if (isGeneratedCheckNumber) {

            check_data = "RM" + jobData?.grid_data?.timezone
          }
        }
        if (e.target.value == "VCP") {

          check_data = formik.values?.check_number ? formik.values?.check_number : ""

        }
        if (e.target.value == "CHK") {

          check_data = formik.values?.check_number ? formik.values?.check_number : ""

        }
        if (e.target.value == "NON") {
          let num = Math.floor(Math.random() * 10000);
          num = num.toString().padStart(4, '0');
          if (isGeneratedCheckNumber) {

            check_data = "RX" + jobData?.grid_data?.timezone
          }
        }
        if (e.target.value == "OTH") {
          let num = Math.floor(Math.random() * 10000);
          num = num.toString().padStart(4, '0');
          if (isGeneratedCheckNumber) {

            check_data = "RX" + jobData?.grid_data?.timezone
          }
        }
        if (e.target.value == "CCI") {
          let num = Math.floor(Math.random() * 10000);
          num = num.toString().padStart(4, '0');
          if (isGeneratedCheckNumber) {

            check_data = "CI" + jobData?.grid_data?.timezone
          }
        }
        if (e.target.value == "COR") {
          let num = Math.floor(Math.random() * 10000);
          num = num.toString().padStart(4, '0');
          if (isGeneratedCheckNumber) {

            check_data = "LT" + jobData?.grid_data?.timezone
          }
        }

        await formik.setValues({
          ...formik.values,
          check_number: check_data,
          dbk_check_number: check_data,
          payment_method: e.target.value,
        })

      }
      else {
        await formik.setValues({
          ...formik.values,
          payment_method: e.target.value,
        })
      }
    }
  }

  const handleChangeFromTo = async (e) => {
    if (!e.target.disabled) {
      await formik.setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: currentPage + 1,
        }
      })
    }
  }

  const handleCheckNumber = (e) => {
    if (e.target.value.length > 0) {
      setIsCheckNumber(false)
    }
    else {
      setIsCheckNumber(true)
    }
    formik.handleChange(e)
  }



  const handleDefaultValue = async (e, type) => {
    if (event.ctrlKey == true && event.keyCode == 32) {
      if (type == 'payee_npi' && jobData?.default_payee?.default_npi && jobData?.default_payee?.default_npi != '') {
        await formik.setValues((prevState) => {
          return {
            ...prevState,
            payee_npi: jobData?.default_payee?.default_npi ? jobData?.default_payee?.default_npi : prevState?.payee_npi,
            payee_name: jobData?.default_payee?.facility_name ? jobData?.default_payee?.facility_name : prevState?.payee_name,
            payee_address: jobData?.default_payee?.facility_address ? jobData?.default_payee?.facility_address : prevState?.payee_address,
            payee_city: jobData?.default_payee?.facility_city ? jobData?.default_payee?.facility_city : prevState?.payee_city,
            payee_state: jobData?.default_payee?.facility_state ? jobData?.default_payee?.facility_state : prevState?.payee_state,
            payee_zip: jobData?.default_payee?.facility_zip ? jobData?.default_payee?.facility_zip : prevState?.payee_zip,
          }
        })
      }
    }
  }

  const handleGoToAccountNumber = (e, dbkCheck) => {
    if (formik?.values?.grid_customization != 'PLB_ONLY') {
      const firstRowFields = document.querySelectorAll('.first-row-grid')
      let isNull = false
      firstRowFields.forEach((i) => {
        if (parseInt(i.getAttribute('data-custome-index')) > parseInt(e.target.getAttribute('data-custome-index')) && !i.value) {
          if (!(qdxTransaction == 'CORRESPONDENCE' && i.name == 'check_page_number')) {
            isNull = true

          }
        }
      })
      if (!e.shiftKey && e.key == 'Tab' && !isNull && pathname == DATA_ENTRY && !dbkCheck) {
        e.preventDefault()
        var claimAccountNo = document.getElementsByName(`claims[${claim835Index}].patient_account_number`);
        claimAccountNo[0]?.focus();
      }
    }
  }

  const renderClass = (name, type, defaultClass) => {
    if (formik.errors?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' && moment(formik.values?.[name]).format("MM/DD/YYYY") != moment(gridData?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' && formik.values?.[name]?.toUpperCase() !=
        gridData?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' && parseFloat(formik.values?.[name] ? formik.values?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.[name] ?
          gridData?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }
      else if (gridData?.transaction_type != "PAYMENT835") {
        return defaultClass + ' qa-field'
      }


      else if (type == 'DATE' && formik.values?.[name] &&
        formik.values?.data_837 &&
        formik.values?.data_837[0] &&
        formik.values?.data_837[0]?.[name] &&
        formik.values?.[name]?.toUpperCase() ==
        formik.values?.data_837[0]?.[name].toUpperCase()) {
        return defaultClass + ' data-837'
      }
      else if (type == 'TEXT' && formik.values?.[name] &&
        formik.values?.data_837 &&
        formik.values?.data_837[0] &&
        formik.values?.data_837[0]?.[name] &&
        formik.values?.[name]?.toUpperCase() ==
        formik.values?.data_837[0]?.[name].toUpperCase()) {
        return defaultClass + ' data-837'
      }
      else if (type == 'NUMBER' && formik.values?.[name] &&
        formik.values?.data_837 &&
        formik.values?.data_837[0] &&
        formik.values?.data_837[0]?.[name] &&
        formik.values?.[name] ==
        formik.values?.data_837[0]?.[name]) {
        return defaultClass + ' data-837'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData && qaCorrectedData?.[name]) {
      return defaultClass + ' qa-field'
    }
    else if (type == 'DATE' && formik.values?.[name] &&
      formik.values?.data_837 &&
      formik.values?.data_837[0] &&
      formik.values?.data_837[0]?.[name] &&
      formik.values?.[name]?.toUpperCase() ==
      formik.values?.data_837[0]?.[name].toUpperCase()) {
      return defaultClass + ' data-837'
    }
    else if (type == 'TEXT' && formik.values?.[name] &&
      formik.values?.data_837 &&
      formik.values?.data_837[0] &&
      formik.values?.data_837[0]?.[name] &&
      formik.values?.[name]?.toUpperCase() ==
      formik.values?.data_837[0]?.[name].toUpperCase()) {
      return defaultClass + ' data-837'
    }
    else if (type == 'NUMBER' && formik.values?.[name] &&
      formik.values?.data_837 &&
      formik.values?.data_837[0] &&
      formik.values?.data_837[0]?.[name] &&
      formik.values?.[name] ==
      formik.values?.data_837[0]?.[name]) {
      return defaultClass + ' data-837'
    }
    else {
      return defaultClass;
    }
  }
  const renderPlbClass = (name, type, defaultClass, index) => {
    if (formik.errors &&
      formik.errors?.plb_adjustments &&
      formik.errors?.plb_adjustments[index] &&
      formik.errors?.plb_adjustments[index]?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.plb_adjustments &&
        qaCorrectedData?.plb_adjustments[index] &&
        qaCorrectedData?.plb_adjustments[index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[formik.values?.plb_adjustments[index]?.plb_index ? formik.values?.plb_adjustments[index]?.plb_index : formik.values?.plb_adjustments[index]?.plb_index == 0 ? formik.values?.plb_adjustments[index]?.plb_index : index] &&
        moment(formik.values?.plb_adjustments[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.plb_adjustments[formik.values?.plb_adjustments[index]?.plb_index ? formik.values?.plb_adjustments[index]?.plb_index : formik.values?.plb_adjustments[index]?.plb_index == 0 ? formik.values?.plb_adjustments[index]?.plb_index : index]?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[formik.values?.plb_adjustments[index]?.plb_index ? formik.values?.plb_adjustments[index]?.plb_index : formik.values?.plb_adjustments[index]?.plb_index == 0 ? formik.values?.plb_adjustments[index]?.plb_index : index] &&
        formik.values?.plb_adjustments[index]?.[name]?.toUpperCase() !=
        gridData?.plb_adjustments[formik.values?.plb_adjustments[index]?.plb_index ? formik.values?.plb_adjustments[index]?.plb_index : formik.values?.plb_adjustments[index]?.plb_index == 0 ? formik.values?.plb_adjustments[index]?.plb_index : index]?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'STRING' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[formik.values?.plb_adjustments[index]?.plb_index ? formik.values?.plb_adjustments[index]?.plb_index : formik.values?.plb_adjustments[index]?.plb_index == 0 ? formik.values?.plb_adjustments[index]?.plb_index : index] &&
        formik.values?.plb_adjustments[index]?.[name] !=
        gridData?.plb_adjustments[formik.values?.plb_adjustments[index]?.plb_index ? formik.values?.plb_adjustments[index]?.plb_index : formik.values?.plb_adjustments[index]?.plb_index == 0 ? formik.values?.plb_adjustments[index]?.plb_index : index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[formik.values?.plb_adjustments[index]?.plb_index ? formik.values?.plb_adjustments[index]?.plb_index : formik.values?.plb_adjustments[index]?.plb_index == 0 ? formik.values?.plb_adjustments[index]?.plb_index : index] &&
        parseFloat(formik.values?.plb_adjustments[index]?.[name] ? formik.values?.plb_adjustments[index]?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.plb_adjustments[formik.values?.plb_adjustments[index]?.plb_index ? formik.values?.plb_adjustments[index]?.plb_index : formik.values?.plb_adjustments[index]?.plb_index == 0 ? formik.values?.plb_adjustments[index]?.plb_index : index]?.[name] ?
          gridData?.plb_adjustments[formik.values?.plb_adjustments[index]?.plb_index ? formik.values?.plb_adjustments[index]?.plb_index : formik.values?.plb_adjustments[index]?.plb_index == 0 ? formik.values?.plb_adjustments[index]?.plb_index : index]?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }

      if (formik.values?.plb_adjustments[index]?.corrected_plb || gridData?.transaction_type != "PAYMENT835") {
        return defaultClass + ' qa-field'
      }

      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.plb_adjustments &&
      qaCorrectedData?.plb_adjustments[index] &&
      qaCorrectedData?.plb_adjustments[index]?.[name]) {
      return defaultClass + ' qa-field'
    }

    else {
      return defaultClass;
    }
  }
  return (
    <React.Fragment>

      <div
        className="topblock-wrapper eob_block accordion-collapse  collapse show form-eob-container"
        id="collapseOne"
        aria-labelledby="headingOne"
        data-bs-parent="#payment835"
      >
        <div className="sub-job-eob">
          <div className="sub-section">
            <div className="">
              <label className="btn btn-payment835">Payment835</label>
            </div>
            <div className="paymentmethod-wi">
              <InputGroup>
                <InputGroup.Text>Payment Method</InputGroup.Text>
                <Form.Control
                  data-prevent-shift-tab="true"
                  as="select"
                  name="payment_method"
                  onKeyDown={(e) => handleGoToAccountNumber(e, false)}
                  data-custome-index={1}
                  onChange={(e) => handleInputPaymentMethod(e)}
                  value={formik.values?.payment_method}
                  id="payment_method"
                  tabIndex={formik.values?.payment_method != '' && pathname == DATA_ENTRY ? -1 : 0}
                  className={renderClass('payment_method', 'TEXT', 'selc-bg-1 ps-1  first-row-grid')}
                >
                  <option
                    value=""
                    disabled={
                      formik.values?.check_amount > parseFloat(0) ||
                      formik.values?.check_amount == parseFloat(0)
                    }
                  >
                    Select an Option
                  </option>
                  <option value="ACH">ACH</option>
                  <option
                    value="CHK"
                    selected={formik.values?.check_amount > parseFloat(0)}
                  >
                    CHK
                  </option>
                  <option
                    value="NON"
                    selected={formik.values?.check_amount == parseFloat(0)}
                  >
                    NON
                  </option>
                  <option
                    value="VCP"
                  >
                    VCP
                  </option>
                  {isCorres835 || isPP835 ?
                    <React.Fragment>

                      <option
                        value="OTH"
                        selected={formik.values?.check_amount == parseFloat(0)}
                      >
                        OTH
                      </option>
                      <option
                        value="CCI"
                        selected={formik.values?.check_amount == parseFloat(0)}
                      >
                        CCI
                      </option>
                      {isCorres835 &&
                        <option
                          value="COR"
                          selected={formik.values?.check_amount == parseFloat(0)}
                        >
                          COR
                        </option>
                      }
                    </React.Fragment>
                    : ""
                  }
                </Form.Control>
              </InputGroup>
            </div>
            <div className="checknum-wi">
              <InputGroup>
                <InputGroup.Text>Check Number</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    data-custome-index={2}
                    type="text"
                    className={renderClass('check_number', 'TEXT', 'w-78 first-row-grid')}
                    tabIndex={!formik?.values?.check_number?.includes("CUSTOM_CHECK_NUMBER") && formik.values?.check_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                    onChange={(e) => {
                      handleCheckNumber(e)
                    }}
                    value={formik.values?.check_number}
                    onFocus={() => {
                      handleFocusEob("check_number", "main", null, isDbkPermission?.payment?.payment_check_number);
                    }}
                    name="check_number"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_check_number) ? !eobFocus.check_number : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false}
                    onKeyDown={(e) => {
                      setDefaultValueMainGrid(e, 'check_number', formik); handleGoToAccountNumber(e, isDbkPermission?.payment?.payment_check_number &&
                        formik.errors?.check_number === "Dont Match" &&
                        eobFocus.check_number)
                    }}

                  />

                  {
                    isDbkPermission?.payment?.payment_check_number &&
                      formik.errors?.check_number === "Dont Match" &&
                      eobFocus.check_number ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="text"
                        fieldName="check_number"
                        fieldNameDbk="dbk_check_number"
                        setCorresPondanceFocus={setEobFocus}
                        corresPondanceFocus={eobFocus}
                      />
                    ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="checkdate-wi">
              <InputGroup>
                <InputGroup.Text>Check Date</InputGroup.Text>
                <div className="dbk-main-container">
                  <InputMask
                    data-custome-index={3}

                    mask="99/99/9999"
                    onKeyDown={(e) => handleGoToAccountNumber(e, isDbkPermission?.payment?.payment_check_date &&
                      formik.errors?.check_date === "Dont Match" &&
                      eobFocus.check_date)}
                    className={renderClass('check_date', 'DATE', 'w-48 first-row-grid')}
                    style={{ fontSize: "0.7em" }}
                    onChange={formik.handleChange}
                    value={formik.values?.check_date}
                    placeholder={"mm/dd/yyyy"}
                    onFocus={() => {
                      handleFocusEob("check_date", "main", null, isDbkPermission?.payment?.payment_check_date);
                    }}
                    name="check_date"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_check_date) ? !eobFocus.check_date : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false}
                    tabIndex={formik.values?.check_date != '' && formik.values?.check_date != "__/__/____" && pathname == DATA_ENTRY ? -1 : 0}
                  />
                  {
                    isDbkPermission?.payment?.payment_check_date &&
                      formik.errors?.check_date === "Dont Match" &&
                      eobFocus.check_date ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="date"
                        fieldName="check_date"
                        fieldNameDbk="dbk_check_date"
                        setCorresPondanceFocus={setEobFocus}
                        corresPondanceFocus={eobFocus}
                      />
                    ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="checkamt-wi">
              <InputGroup>
                <InputGroup.Text>Check Amount</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    data-custome-index={4}

                    type="number"
                    max="0.00"
                    onWheel={handleWheel}
                    aria-describedby="empty"
                    onChange={(e) => {
                      handleChangeCheckAmount(e)
                    }}
                    onFocus={() => {
                      handleFocusEob("check_amount", "main", null, isDbkPermission?.payment?.payment_check_amount);
                    }}
                    onKeyDown={(e) => {
                      handleKeyPress(e); handlePreventEKeyPress(e); handleGoToAccountNumber(e, isDbkPermission?.payment?.payment_check_amount &&
                        formik.errors?.check_amount == "Dont Match" &&
                        eobFocus.check_amount)
                    }}
                    value={formik.values?.check_amount}
                    onBlur={(e) => handleFloatValue(e, "check_amount", null)}
                    className={renderClass('check_amount', 'NUMBER', 'w-78 text-end first-row-grid')}
                    name="check_amount"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_check_amount) ? !eobFocus.check_amount : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false}
                    tabIndex={formik.values?.check_amount != '' && pathname == DATA_ENTRY ? -1 : 0}

                  />
                  {isDbkPermission?.payment?.payment_check_amount &&
                    formik.errors?.check_amount == "Dont Match" &&
                    eobFocus.check_amount ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="number"
                      fieldName="check_amount"
                      fieldNameDbk="dbk_check_amount"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="remainingbln-wi">
              <InputGroup>
                <InputGroup.Text>Remaining Balance</InputGroup.Text>
                <Form.Control
                  type="number"
                  disabled
                  onKeyDown={(e) => handleGoToAccountNumber(e, false)}
                  onChange={formik.handleChange}
                  onWheel={handleWheel}
                  id="remaining_balance_check"
                  value={parseFloat(formik.values?.remaining_balance).toFixed(2)}
                  className={
                    !!formik.errors?.remaining_balance
                      ? "w-100 error-field text-end first-row-grid prevent-shift"
                      : "w-100 text-end first-row-grid prevent-shift"
                  }
                  name="remaining_balance"
                />
              </InputGroup>
            </div>
            <div className="ChkPgNo-wi">
              <InputGroup>
                <InputGroup.Text>ChkPgNo</InputGroup.Text>
                <Form.Control
                  data-custome-index={5}

                  type="number"
                  min="1"
                  onChange={(e) => {
                    formik.handleChange(e);
                    setcheckNum(e.target.value);
                  }}
                  onWheel={handleWheel}
                  onKeyDown={(e) => {
                    handleGoToAccountNumber(e, false);
                    handlePreventEKeyPress(e)
                  }}
                  value={formik.values?.check_page_number}
                  className={renderClass('check_page_number', 'NUMBER', 'w-82 first-row-grid')}
                  disabled={isSubJob || disableCorresField('check_page_number')}
                  data-corress-disable={disableCorresField('check_page_number')}
                  name="check_page_number"
                  tabIndex={formik.values?.check_page_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                />
              </InputGroup>
            </div>
          </div>
          <div className="sub-section">
            <div className="">
              <label className="btn btn-payer">Payer </label>
            </div>
            <form className="wrapper-payer">
              <InputGroup>
                <InputGroup.Text>
                  Name
                  {!isSubJob || isFirstJob && isSubJob || !isFirstJob && !isSubJob ?
                    <a
                      type="button"
                      className="payersearch"
                      // disabled={isSubJob ? true : false}

                      onClick={__handlePayerLookupSearch}
                    >
                      <img src={search} alt="" />
                    </a>
                    :
                    <React.Fragment>

                    </React.Fragment>
                  }
                </InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control

                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payer");
                      setPayerValue(e.target.value);
                    }}
                    onFocus={(e) => {
                      handleFocusEob("payer_name", "main", null, isDbkPermission?.payment?.payment_payer_name);
                      setPayerFocus(true);
                      setPayerValue(e.target.value);
                    }}
                    onBlur={() => setPayerFocus(false)}
                    value={formik.values?.payer_name}
                    autoFocus={payerFocus}
                    // style={
                    //   formik.values?.payer_name &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payer_name &&
                    //     formik.values?.payer_name?.toUpperCase() ==
                    //     formik.values?.data_837[0]?.payer_name.toUpperCase()
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payer_name != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    autoComplete={"off"}
                    className={renderClass('payer_name', 'TEXT', 'w-payer text-uppercase')}

                    name="payer_name"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payer_name) ? !eobFocus.payer_name : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false}
                  />
                  {isDbkPermission?.payment?.payment_payer_name &&
                    formik.errors?.payer_name == "Dont Match" &&
                    eobFocus.payer_name ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payer_name"
                      fieldNameDbk="dbk_payer_name"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </form>
            <form className="wrapper-add">
              <InputGroup>
                <InputGroup.Text>Address</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payer");
                    }}
                    autoComplete={"off"}
                    onFocus={(e) => {
                      handleFocusEob("payer_address", "main", null, isDbkPermission?.payment?.payment_payer_address);
                    }}
                    value={formik.values?.payer_address}
                    // style={
                    //   formik.values?.payer_address &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payer_address &&
                    //     formik.values?.payer_address ==
                    //     formik.values?.data_837[0]?.payer_address
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payer_address != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    className={renderClass('payer_address', 'TEXT', 'w-address')}

                    name="payer_address"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payer_address) ? !eobFocus.payer_address : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false || disableCorresField('payer_address')}
                    data-corress-disable={disableCorresField('payer_address')}
                  />
                  {isDbkPermission?.payment?.payment_payer_address &&
                    formik.errors?.payer_address == "Dont Match" &&
                    eobFocus.payer_address ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payer_address"
                      fieldNameDbk="dbk_payer_address"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </form>
            <form className="wrap">
              <InputGroup>
                <InputGroup.Text>City</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control

                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payer");
                    }}
                    autoComplete={"off"}
                    onFocus={(e) => {
                      handleFocusEob("payer_city", "main", null, isDbkPermission?.payment?.payment_payer_city);
                    }}
                    value={formik.values?.payer_city}
                    // style={
                    //   formik.values?.payer_city &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payer_city?.toUpperCase() &&
                    //     formik.values?.payer_city ==
                    //     formik.values?.data_837[0s]?.payer_city.toUpperCase()
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payer_city != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    className={renderClass('payer_city', 'TEXT', 'w-82')}
                    name="payer_city"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payer_city) ? !eobFocus.payer_city : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false || disableCorresField('payer_city')}
                    data-corress-disable={disableCorresField('payer_city')}
                  />
                  {isDbkPermission?.payment?.payment_payer_city &&
                    formik.errors?.payer_city == "Dont Match" &&
                    eobFocus.payer_city ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payer_city"
                      fieldNameDbk="dbk_payer_city"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </form>
            <div className="wrap">
              <InputGroup>
                <InputGroup.Text>State</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payer");
                    }}
                    autoComplete={"off"}
                    onFocus={(e) => {
                      handleFocusEob("payer_state", "main", null, isDbkPermission?.payment?.payment_payer_state);
                    }}
                    maxLength="2"
                    value={formik.values?.payer_state}
                    // style={
                    //   formik.values &&
                    //     formik.values?.payer_state &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payer_state &&
                    //     formik.values?.payer_state?.toUpperCase() ==
                    //     formik.values?.data_837[0]?.payer_state.toUpperCase()
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payer_state != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    className={renderClass('payer_state', 'TEXT', 'w-82')}

                    name="payer_state"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payer_state) ? !eobFocus.payer_state : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false || disableCorresField('payer_state')}
                    data-corress-disable={disableCorresField('payer_state')}
                  />
                  {isDbkPermission?.payment?.payment_payer_state &&
                    formik.errors?.payer_state == "Dont Match" &&
                    eobFocus.payer_state ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payer_state"
                      fieldNameDbk="dbk_payer_state"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrap">
              <InputGroup>
                <InputGroup.Text>Zip</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payer");
                    }}
                    autoComplete={"off"}
                    onFocus={(e) => {
                      handleFocusEob("payer_zip", "main", null, isDbkPermission?.payment?.payment_payer_zip);
                    }}
                    maxLength={10}
                    value={formik.values?.payer_zip}
                    // style={
                    //   formik.values?.payer_zip &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payer_zip &&
                    //     formik.values?.payer_zip ==
                    //     formik.values?.data_837[0]?.payer_zip
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payer_zip != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    className={renderClass('payer_zip', 'TEXT', 'w-82')}

                    name="payer_zip"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payer_zip) ? !eobFocus.payer_zip : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false || disableCorresField('payer_zip')}
                    data-corress-disable={disableCorresField('payer_zip')}
                  />
                  {isDbkPermission?.payment?.payment_payer_zip &&
                    formik.errors?.payer_zip == "Dont Match" &&
                    eobFocus.payer_zip ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payer_zip"
                      fieldNameDbk="dbk_payer_zip"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            {isPayerPage &&
              <div className="d-flex flex-row">
                <div className="">
                  <InputGroup>
                    <InputGroup.Text>From</InputGroup.Text>
                    <Form.Control
                      type="number"
                      min={1}
                      name="payer_page_from"
                      // onBlur={handleCurrentPageChange}
                      // onClick={handleChangeFrom}
                      data-disabled={!pdfLoaded}
                      onChange={formik.handleChange}
                      onWheel={handleWheel}
                      // onFocus={() => setClaim835Focus(true)}
                      onClick={(e) => {
                        handleChangeFromTo(e);
                      }}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                      }}
                      onFocus={(e) => {
                        handleChangeFromTo(e);
                      }}
                      value={formik.values?.payer_page_from}
                      // tabIndex={formik.values?.payer_page_from != '' && pathname == DATA_ENTRY ? -1 : 0}
                      className={renderClass('payer_page_from', 'NUMBER', 'pagefrom')}

                    />
                  </InputGroup>
                </div>
                <div className="">
                  <InputGroup>
                    <InputGroup.Text>To</InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="payer_page_to"
                      min={1}
                      data-disabled={!pdfLoaded}
                      onWheel={handleWheel}
                      onChange={formik.handleChange}
                      onClick={(e) => {
                        handleChangeFromTo(e);
                      }}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                      }}
                      onFocus={(e) => {
                        // setClaim835Focus(true);
                        handleChangeFromTo(e);
                      }}
                      value={formik.values?.payer_page_to}
                      // tabIndex={formik.values?.payer_page_to != '' && pathname == DATA_ENTRY ? -1 : 0}
                      className={renderClass('payer_page_to', 'NUMBER', 'pageto')}

                    />
                  </InputGroup>
                </div>
              </div>
            }

          </div>
          <div className="sub-section">
            <div className="">
              <label className="btn btn-payer">Payee </label>
            </div>
            <div className="wrapper-payer">
              <InputGroup>
                <InputGroup.Text>
                  Name{" "}
                  {!isSubJob || isFirstJob && isSubJob || !isFirstJob && !isSubJob ?

                    <a className="payersearch" onClick={__handlePayeeLookupSearch}>
                      <img src={search} alt="" />
                    </a>
                    :
                    <React.Fragment>

                    </React.Fragment>
                  }
                </InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payee");
                      setPayeeValue(e.target.value);
                    }}
                    onFocus={(e) => {
                      handleFocusEob("payee_name", "main", null, isDbkPermission?.payment?.payment_payee_name), setPayeeFocus(true);
                      setPayeeValue(e.target.value);
                    }}
                    onBlur={() => setPayeeFocus(false)}

                    autoFocus={payeeFocus}

                    // style={
                    //   formik.values?.payee_name &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payee_name &&
                    //     formik.values?.payee_name?.toUpperCase() ==
                    //     formik.values?.data_837[0].payee_name.toUpperCase()
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }

                    tabIndex={
                      formik.values?.payee_name != '' && pathname == DATA_ENTRY ? -1 : 0
                    }

                    value={formik.values?.payee_name}
                    className={renderClass('payee_name', 'TEXT', 'w-payer')}

                    name="payee_name"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payee_name) ? !eobFocus.payee_name : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false}

                  />
                  {isDbkPermission?.payment?.payment_payee_name &&
                    formik.errors?.payee_name == "Dont Match" &&
                    eobFocus.payee_name ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payee_name"
                      fieldNameDbk="dbk_payee_name"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>

            <div className="wrapper-add">
              <InputGroup>
                <InputGroup.Text>NPI</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payee");
                    }}
                    // style={
                    //   formik.values?.payee_npi &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payee_npi &&
                    //     formik.values?.payee_npi ==
                    //     formik.values?.data_837[0]?.payee_npi
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payee_npi != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    maxLength={10}
                    onFocus={() => {
                      handleFocusEob("payee_npi", "main", null, isDbkPermission?.payment?.payment_payee_npi);
                    }}
                    value={formik.values?.payee_npi}
                    className={renderClass('payee_npi', 'TEXT', 'w-address')}

                    name="payee_npi"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payee_npi) ? !eobFocus.payee_npi : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false}
                    onKeyUp={(e) => {
                      handleDefaultValue(
                        e,
                        "payee_npi"
                      );
                    }}
                  />
                  {isDbkPermission?.payment?.payment_payee_npi &&
                    formik.errors?.payee_npi == "Dont Match" &&
                    eobFocus.payee_npi ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payee_npi"
                      fieldNameDbk="dbk_payee_npi"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-add">
              <InputGroup>
                <InputGroup.Text>Address</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payee");
                    }}
                    onFocus={() => {
                      handleFocusEob("payee_address", "main", null, isDbkPermission?.payment?.payment_payee_address);
                    }}
                    // style={
                    //   formik.values?.payee_address &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payee_address &&
                    //     formik.values?.payee_address ==
                    //     formik.values?.data_837[0]?.payee_address
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payee_address != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    value={formik.values?.payee_address}
                    className={renderClass('payee_address', 'TEXT', 'w-address')}

                    name="payee_address"

                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payee_address) ? !eobFocus.payee_address : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false || disableCorresField('payee_address')}
                    data-corress-disable={disableCorresField('payee_address')}
                  />
                  {isDbkPermission?.payment?.payment_payee_address &&
                    formik.errors?.payee_address == "Dont Match" &&
                    eobFocus.payee_address ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payee_address"
                      fieldNameDbk="dbk_payee_address"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrap">
              <InputGroup>
                <InputGroup.Text>City</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payee");
                    }}
                    onFocus={() => {
                      handleFocusEob("payee_city", "main", null, isDbkPermission?.payment?.payment_payee_city);
                    }}
                    // style={
                    //   formik.values?.payee_city &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payee_city &&
                    //     formik.values?.payee_city ==
                    //     formik.values?.data_837[0]?.payee_city
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payee_city != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    value={formik.values?.payee_city}
                    className={renderClass('payee_city', 'TEXT', 'w-82')}

                    name="payee_city"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payee_city) ? !eobFocus.payee_city : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false || disableCorresField('payee_city')}
                    data-corress-disable={disableCorresField('payee_city')}
                  />
                  {isDbkPermission?.payment?.payment_payee_city &&
                    formik.errors?.payee_city == "Dont Match" &&
                    eobFocus.payee_city ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payee_city"
                      fieldNameDbk="dbk_payee_city"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrap">
              <InputGroup>
                <InputGroup.Text>State</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payee");
                    }}
                    onFocus={() => {
                      handleFocusEob("payee_state", "main", null, isDbkPermission?.payment?.payment_payee_state);
                    }}
                    // style={
                    //   formik.values?.payee_state &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payee_state &&
                    //     formik.values?.payee_state ==
                    //     formik.values?.data_837[0]?.payee_state
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payee_state != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    maxLength={2}
                    value={formik.values?.payee_state}
                    className={renderClass('payee_state', 'TEXT', 'w-82')}

                    name="payee_state"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payee_state) ? !eobFocus.payee_state : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false || disableCorresField('payee_state')}
                    data-corress-disable={disableCorresField('payee_state')}
                  />
                  {isDbkPermission?.payment?.payment_payee_state &&
                    formik.errors?.payee_state == "Dont Match" &&
                    eobFocus.payee_state ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payee_state"
                      fieldNameDbk="dbk_payee_state"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrap">
              <InputGroup>
                <InputGroup.Text>Zip</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleChangeIds(e, "payee");
                    }}
                    onFocus={() => {
                      handleFocusEob("payee_zip", "main", null, isDbkPermission?.payment?.payment_payee_zip);
                    }}
                    // style={
                    //   formik.values?.payee_zip &&
                    //     formik.values?.data_837 &&
                    //     formik.values?.data_837[0] &&
                    //     formik.values?.data_837[0]?.payee_zip &&
                    //     formik.values?.payee_zip ==
                    //     formik.values?.data_837[0]?.payee_zip
                    //     ? { backgroundColor: "#65a1b4", color: "#fff !important" }
                    //     : {}
                    // }
                    tabIndex={
                      formik.values?.payee_zip != '' && pathname == DATA_ENTRY ? -1 : 0
                    }
                    maxLength={10}
                    value={formik.values?.payee_zip}
                    className={renderClass('payee_zip', 'TEXT', 'w-82')}

                    name="payee_zip"
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payee_zip) ? !eobFocus.payee_zip : !isSubJob && isFirstJob ? true : isSubJob && !isFirstJob ? true : false || disableCorresField('payee_zip')}
                    data-corress-disable={disableCorresField('payee_zip')}
                  />
                  {isDbkPermission?.payment?.payment_payee_zip &&
                    formik.errors?.payee_zip == "Dont Match" &&
                    eobFocus.payee_zip ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="payee_zip"
                      fieldNameDbk="dbk_payee_zip"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            {isPayeePage &&
              <div className="d-flex flex-row">
                <div className="">
                  <InputGroup>
                    <InputGroup.Text>From</InputGroup.Text>
                    <Form.Control
                      type="number"
                      min={1}
                      name="payee_page_from"
                      // onBlur={handleCurrentPageChange}
                      // onClick={handleChangeFrom}
                      data-disabled={!pdfLoaded}
                      onChange={formik.handleChange}
                      onWheel={handleWheel}
                      // onFocus={() => setClaim835Focus(true)}
                      onClick={(e) => {
                        handleChangeFromTo(e);
                      }}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                      }}
                      onFocus={(e) => {
                        handleChangeFromTo(e);
                      }}
                      value={formik.values?.payee_page_from}
                      // tabIndex={formik.values?.payee_page_from != '' && pathname == DATA_ENTRY ? -1 : 0}

                      className={renderClass('payee_page_from', 'NUMBER', 'pagefrom')}

                    />
                  </InputGroup>
                </div>
                <div className="">
                  <InputGroup>
                    <InputGroup.Text>To</InputGroup.Text>
                    <Form.Control
                      type="number"
                      name="payee_page_to"
                      min={1}
                      data-disabled={!pdfLoaded}
                      onWheel={handleWheel}
                      onChange={formik.handleChange}
                      onClick={(e) => {
                        handleChangeFromTo(e);
                      }}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                      }}
                      onFocus={(e) => {
                        // setClaim835Focus(true);
                        handleChangeFromTo(e);
                      }}
                      value={formik.values?.payee_page_to}
                      // tabIndex={formik.values?.payee_page_to != '' && pathname == DATA_ENTRY ? -1 : 0}
                      className={renderClass('payee_page_to', 'NUMBER', 'pageto')}

                    />
                  </InputGroup>
                </div>
              </div>
            }

          </div>
        </div>


        <React.Fragment>
          <div className="row">
            {formik.values?.plb_adjustments?.map((plb, index) => (
              <React.Fragment key={index}>
                <div className="col-lg-6">
                  <div className="sub-section">
                    <div className="">
                      <div className="label-claim">
                        <button
                          disabled={isViewOnllyGrid}
                          type="button"
                          tabIndex="-1"
                          onClick={() => {
                            handlePlbDeleteAmount(index);
                          }}
                          className="btn btn-outline-danger"
                        >
                          ×
                        </button>
                        PLB Adjustment{" "}
                      </div>
                    </div>
                    <div className="wrapper-plb">
                      <InputGroup>
                        <InputGroup.Text>NPI </InputGroup.Text>
                        <Form.Control
                          type="text"
                          onChange={formik.handleChange}
                          className={renderPlbClass('npi', 'TEXT', 'w-npi', index)}
                          value={plb?.npi}
                          disabled={isViewOnllyGrid}
                          maxLength={10}
                          name={`plb_adjustments[${index}].npi`}
                          onFocus={() => { setPlbOnFocus(true) }}
                          onBlur={() => { setPlbOnFocus(false) }}
                          tabIndex={plbOnFocus ? 0 : -1}
                        />
                      </InputGroup>
                    </div>

                    <div className="wrapper-add">
                      <InputGroup>
                        <InputGroup.Text>PLB Page</InputGroup.Text>
                        <Form.Control
                          as="select"
                          type="text"
                          onChange={formik.handleChange}
                          value={plb?.plb_page}
                          disabled={isViewOnllyGrid}
                          className={renderPlbClass('plb_page', 'NUMBER', 'w-patient', index)}
                          name={`plb_adjustments[${index}].plb_page`}
                          // tabIndex={plb?.plb_page != '' && pathname == DATA_ENTRY ? -1 : 0}
                          onFocus={() => { setPlbOnFocus(true) }}
                          onBlur={() => { setPlbOnFocus(false) }}
                          tabIndex={plbOnFocus ? 0 : -1}
                        >
                          {/* <option value="0">---select---</option> */}
                          {Array.from(Array(totalPages).keys()).map(
                            (i, index) => (
                              <option key={index} value={i + 1}>
                                {i + 1}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </InputGroup>
                    </div>
                    <div className="wrapper-add">
                      <InputGroup>
                        <InputGroup.Text>Reason</InputGroup.Text>
                        <Form.Control
                          as="select"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values?.plb_adjustments[index]?.reason}
                          onFocus={() => { setPlbOnFocus(true) }}
                          onBlur={() => { setPlbOnFocus(false) }}
                          tabIndex={plbOnFocus ? 0 : -1}
                          className={renderPlbClass('reason', 'STRING', 'select-pan', index)}
                          name={`plb_adjustments[${index}].reason`}
                          disabled={isViewOnllyGrid}
                        >
                          <option value="">---Select---</option>
                          <option value="L6">L6 - Interest</option>
                          <option value="WO">WO - Overpayment Recovery</option>
                          <option value="FB">FB - Forward Balance</option>
                          <option value="BD">BD - Bad Debt</option>
                          <option value="72">72 - Authorized Return</option>
                          <option value="BN">BN - Bonus</option>
                          <option value="CS">CS - Adjustment</option>
                          <option value="AH">AH - Origination</option>
                          <option value="AM">AM - Borrower's Account</option>
                          <option value="AP">
                            AP - Acceleration of Benefits
                          </option>
                          <option value="B2">B2 - Rebate</option>
                          <option value="B3">B3 - Recovery</option>
                          <option value="C5">C5 - Temporary Allowance</option>
                          <option value="CR">CR - Capitation Interest</option>
                          <option value="CT">CT - Capitation Payment</option>
                          <option value="CV">CV - Capital Passthru</option>
                          <option value="CW">
                            CW - Registered Nurse Passthru
                          </option>
                          <option value="DM">
                            DM - Direct Medical Passthru
                          </option>
                          <option value="E3">E3 - Withholding</option>
                          <option value="FC">FC - Fund Allocation</option>
                          <option value="GO">GO - Graduate Passthru</option>
                          <option value="HM">HM - Hemophilia Clotting</option>
                          <option value="IP">IP - Incentive Payment</option>
                          <option value="IR">IR - IRS withholding</option>
                          <option value="IS">IS - Interim Settlement</option>
                          <option value="J1">J1 - Nonreimbursable</option>
                          <option value="L3">L3 - Penalty</option>
                          <option value="LE">LE - Levy</option>
                          <option value="LS">LS - Lump Sum</option>
                          <option value="OA">OA - Organ Passthru</option>
                          <option value="OB">OB - Provider Offset</option>
                          <option value="PI">PI - Periodic Payment</option>
                          <option value="PL">PL - Final Payment</option>
                          <option value="RA">
                            RA - Retroactive Adjustment
                          </option>
                          <option value="RE">RE - Return on Equity</option>
                          <option value="SL">
                            SL - Student Loan Repayment
                          </option>
                          <option value="TL">TL - Third Party Liability</option>
                          <option value="WU">WU - Unspecified Recovery</option>
                          <option value="50">50 - Late charge</option>
                          <option value="51">51 - Interest</option>
                          <option value="90">90 - Early Payment</option>
                        </Form.Control>
                      </InputGroup>
                    </div>
                    <div className="wrap-pan">
                      <InputGroup>
                        <InputGroup.Text>
                          Patient Account Number
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          onChange={formik.handleChange}
                          value={
                            formik.values?.plb_adjustments[index]
                              ?.patient_account_number
                          }
                          className={renderPlbClass('patient_account_number', 'TEXT', 'w-pat-a-n', index)}
                          disabled={isViewOnllyGrid}
                          name={`plb_adjustments[${index}].patient_account_number`}
                          onFocus={() => { setPlbOnFocus(true) }}
                          onBlur={() => { setPlbOnFocus(false) }}
                          tabIndex={plbOnFocus ? 0 : -1}
                        />
                      </InputGroup>
                    </div>
                    <div className="wrap-amt">
                      <InputGroup>
                        <InputGroup.Text>Amt</InputGroup.Text>
                        <Form.Control
                          type="number"
                          onChange={(e) => handlePlbAmount(e, index)}
                          onWheel={handleWheel}
                          onKeyDown={(e) => { handleKeyPress(e); handlePreventEKeyPress(e) }}
                          onDoubleClick={() => insertRemainingAmount(index)}
                          onBlur={(e) => { handlePLbFloatValue(e, index); setPlbOnFocus(false) }}
                          onFocus={() => { setPlbOnFocus(true) }}
                          tabIndex={plbOnFocus ? 0 : -1}
                          value={formik.values?.plb_adjustments[index]?.plb_amt}
                          className={renderPlbClass('plb_amt', 'NUMBER', 'w-patient', index)}
                          disabled={isViewOnllyGrid}
                          name={`plb_adjustments[${index}].plb_amt`}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>

      </div>
    </React.Fragment>
  );
};

export default Eob;
