
import React, { useEffect, useState } from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { Tab, Tabs, Toast, ToastContainer, Form, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { createClient, getClientDetails } from './../../../../Redux/client/action';
import ClientForm from './Forms/client';
import Payer from './Forms/payer';
import DocumentTypes from './Forms/documentTypes';
import OutboundForm from './Forms/outbound';
import LockboxForm from './Forms/lockbox';
import ContactForm from './Forms/Contact';
import TatForm from './Forms/tat';
import InboundForm from './Forms/inbound';
import Defaults from './Forms/defaults';
import FacilityForm from './Forms/facility';
import ClaimForm from './Forms/claims';
import { useParams } from 'react-router-dom';
import { EDIT_CLIENT } from '../../../../routeNames'
import DbkForm from './Forms/dbk';
import AdvanceCustom from './Forms/advancedCustom';
import Claims from '../../DataEntry/Grid/Eob835/claims';
import Claim from '../../DataEntry/Grid/EobLite/claim';
import OperationLog from './Forms/operationLog';
import Snippet from './Forms/snippet';
function CreateClient() {
    const [state, setState] = useState({
        status: false,
        message: "",
        showToast: false,
        clientValidated: false,
        client_id: 0,
        client_data: [],
    });
    const [data, setData] = useState();
    const { id, copy_id } = useParams();
    const fetchClientDetails = async () => {
        await getClientDetails(id ? id : client_id)((response) => {
            if (response?.status && response?.data) {
                setData(response?.data?.client_data[0]);
                setState({ ...state, clientValidated: true, client_id: response?.data?.client_data[0]?.id, client_data: response?.data?.client_data[0] })
            }
            else {
                setToast({
                    type: "danger",
                    message: response?.response?.data?.message,
                    toast: true,
                })
            }
        });
    }
    useEffect(() => {

        if (window.location.pathname == EDIT_CLIENT + id) {
            setState({ ...state, client_id: id })
        }

        if (id) {
            fetchClientDetails()
        }
        else if (copy_id) {
            getClientDetails(copy_id)((response) => {
                if (response?.status && response?.data) {
                    // // console.log(response.data)
                    setData(response?.data?.client_data[0]);
                    setState({ ...state, clientValidated: true, client_id: response?.data?.client_data[0]?.id, client_data: response?.data?.client_data[0] })
                    // // console.log(response?.data?.client_data[0]?.id)

                }
                else {
                    setToast({
                        type: "danger",
                        message: response?.response?.data?.message,
                        toast: true,
                    })
                }
            });
        }

    }, [id, copy_id]);


    const __handleStateClientValidated = (response) => {
        if (response?.response?.data?.errors) {
            setState({ client_id: 0, clientValidated: false, })
        }
        else {

            setState({ ...state, clientValidated: true, client_id: response?.data?.id, client_data: response?.data })
            localStorage.setItem('client_id', response?.data?.id)
        }
    }

    const [toast, setToast] = useState({
        type: "",
        message: "",
        toast: false,

    });
    const __toggleToast = (type, message, toastData) => {
        console.log(toast.toast, type, message, toastData)
        if (toast.toast == false) {

            setToast({
                ...toast,
                type: type,
                message: message,
                toast: toastData,
            })
        }

    }
    const __closeToast = () => {
        setToast({ toast: false })
    }

    const [loading, setLoading] = useState(false);
    return (
        <React.Fragment>
            <div>
                <ToastContainer containerPosition="fixed" className="p-3" position={"top-end"}>

                    <Toast bg="success" onClose={__closeToast} show={toast.type == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>

                    <Toast bg="danger" onClose={__closeToast} show={toast.type == 'danger' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{toast?.message ?? 'Failed'}</span></Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
            <AdminMaster />
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1 className='d-none'>Clients</h1>

                </div>
                <section className="section">
                    {loading &&
                        <React.Fragment>
                            <div className='loading-component position-absolute top-50 start-50 translate-middle'>
                                <Spinner animation="border" size="lg" />
                            </div>
                        </React.Fragment>
                    }
                    {/* // ) : ( */}
                    <React.Fragment>
                        <div className="row align-items-top  gap-2 gap-md-5 ">
                            <div className="col-lg-12">
                                <div className="card mx-3 my-2 mb-xl-8 ">

                                    <div className=' card-body  py-3'>
                                        <div className=''>
                                            <Tabs

                                                className=" tab-fixed-header d-flex flex-row gap-4 "
                                            >
                                                <Tab className='fw-bolder pt-7' eventKey="client" title="Client" >
                                                    <ClientForm
                                                        client={state.client_id}
                                                        client_data={data}
                                                        copy_id={copy_id}
                                                        clientValidated={state.clientValidated}
                                                        setLoading={setLoading}
                                                        changeState={__handleStateClientValidated}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>
                                                <Tab eventKey="facility " className='fw-bolder pt-7' client={state.client_id} title="Facility"
                                                    disabled={!state.clientValidated && state.client_id != null}
                                                // className={!state.client_data?.is_facility_available ? "d-none" : ""}
                                                >
                                                    <FacilityForm
                                                        fetchClientDetails={fetchClientDetails}
                                                        client_data={state.client_data}
                                                        facilities={data?.facility}
                                                        setLoading={setLoading}
                                                        client={state.client_id}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>

                                                <Tab eventKey="claims" className='fw-bolder pt-7' title="Claims"
                                                    disabled={!state.client_data?.is_claim_available}
                                                >
                                                    <ClaimForm
                                                        fetchClientDetails={fetchClientDetails}
                                                        client_data={state.client_data}
                                                        setLoading={setLoading}
                                                        client={state.client_id}
                                                        claim={data?.claims}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>
                                                <Tab eventKey="outbound" className='fw-bolder pt-7' title="Outbound"
                                                    disabled={!state.clientValidated && state.client_id != null}
                                                >
                                                    <OutboundForm
                                                        fetchClientDetails={fetchClientDetails}
                                                        client_data={state?.client_data}
                                                        setLoading={setLoading}
                                                        outbond={data?.outbound}
                                                        __toggleToast={__toggleToast}
                                                        client={state.client_id}
                                                    />
                                                </Tab>


                                                <Tab eventKey="payer" className='fw-bolder pt-7' title="Excluded Payers"
                                                    disabled={!state.clientValidated && state.client_id != null}
                                                >
                                                    <Payer
                                                        fetchClientDetails={fetchClientDetails}
                                                        client_data={state.client_data}
                                                        client={state.client_id}
                                                        setLoading={setLoading}
                                                        excluded_payers={data?.excluded_payers}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>

                                                <Tab eventKey="contact" className='fw-bolder pt-7' client={state.client_id} title="Contact"
                                                    disabled={!state.clientValidated && state.client_id != null}
                                                >
                                                    <ContactForm className='fw-bolder pt-7'
                                                        fetchClientDetails={fetchClientDetails}
                                                        contact={data?.contact}
                                                        setLoading={setLoading}
                                                        client={state.client_id}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>

                                                <Tab eventKey="defaults" className='fw-bolder pt-7' title="Defaults"
                                                    disabled={!state.clientValidated && state.client_id != null}
                                                >
                                                    <Defaults
                                                        fetchClientDetails={fetchClientDetails}
                                                        setLoading={setLoading}
                                                        defaults={data?.default}
                                                        client={state.client_id}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>

                                                <Tab eventKey="dbk-tab" className='fw-bolder pt-7' title="DBK"
                                                    disabled={!state.client_data?.is_dbk_available}
                                                >
                                                    <DbkForm
                                                        fetchClientDetails={fetchClientDetails}
                                                        client_data={state.client_data}
                                                        client={state.client_id}
                                                        setLoading={setLoading}
                                                        dbk_fields={data?.dbk}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>
                                                <Tab eventKey="operation-log" className='fw-bolder pt-7' title="Operation Log"
                                                    disabled={!state.clientValidated && state.client_id != null}
                                                >
                                                    <OperationLog
                                                        fetchClientDetails={fetchClientDetails}
                                                        client_data={state.client_data}
                                                        client={state.client_id}
                                                        setLoading={setLoading}
                                                        operation_log={data?.operation_log}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>
                                                <Tab eventKey="advance-customisation" className='fw-bolder pt-7' title="Customization"
                                                    disabled={!state.clientValidated}
                                                >
                                                    <AdvanceCustom
                                                        fetchClientDetails={fetchClientDetails}
                                                        client_data={state.client_data}
                                                        client={state.client_id}
                                                        setLoading={setLoading}
                                                        advanceCustom={data?.advance_customisation}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>
                                                <Tab eventKey="order" className='fw-bolder pt-7' title="Snipping"
                                                    disabled={!state.client_data?.is_snippet_available}
                                                >
                                                    <Snippet
                                                        setLoading={setLoading}
                                                        client_data={state.client_data}
                                                        snippet={data?.snipping_customisation}
                                                        client={state.client_id}
                                                        fetchClientDetails={fetchClientDetails}
                                                        __toggleToast={__toggleToast}
                                                    />
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    {/* // )} */}

                </section>


            </main>
        </React.Fragment>
    )
}

export default CreateClient