
import axiosInstance from './../../axiosInstance';
import {
    CREATE_ASSIGN_USERS_URL,
    CREATE_CLIENT_GROUP_URL, DESTROY_CLIENT_GROUP_URL, GET_ALL_CLIENT_GROUP_URL, GET_ALL_STAFF_URL, GET_CLIENTS_GROUPS_CHOICE_URL, GET_CLIENT_DEFFERENCE_REPORT_URL, GET_CLIENT_GROUP_DETAILS_URL, GET_MYWORK_DATA_ENTRY_REPORT_URL, GET_MY_WORK_DETAIL_REPORT_URL, GET_USER_DIFFERENCE_REPORT_URL
} from './../../api/index';
import { SINGLE_ASSIGN_GROUP_USERS } from '../../routeNames';

// reorts 
export const myWorkDataEntryReport = (id) => onResponse => {
    // console.log(props);
    // try {
    axiosInstance.get(GET_MYWORK_DATA_ENTRY_REPORT_URL + "?job_id=" + id)
        .then((response) => {
            // console.log(response)
            onResponse(response.data);
        })
        .catch((err) => {
            console.log(err)
            onResponse(err);
        });

    // } catch (error) {

    // }

};

export const clientDifferenceReport = (id) => onResponse => {
    // console.log(props);
    try {
        axiosInstance.get(GET_CLIENT_DEFFERENCE_REPORT_URL + "?client_id=" + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getJobDetailReport = (log_id) => onResponse => {
    // console.log(props);
    try {
        axiosInstance.get(GET_MY_WORK_DETAIL_REPORT_URL + "?log_id=" + log_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const userDifferenceReport = (id) => onResponse => {
    // console.log(props);
    try {
        axiosInstance.get(GET_USER_DIFFERENCE_REPORT_URL + "?user_id=" + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
