
import React from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { useFormik, FieldArray, FormikProvider, Formik } from 'formik';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import * as Yup from 'yup';
import { createOrUpdateHolidayCalendar, getSingleHolidayCalender } from '../../../../Redux/holidaycalendar/action';
import { useState, useEffect } from 'react';
import Select, { Option } from 'react-select';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import { useNavigate, useParams } from 'react-router-dom';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import { HOLIDAY_CALENDAR } from '../../../../routeNames';


const CreateCalendar = () => {
    const { id } = useParams();
    const [state, setState] = useState({
        removed_ids: [],
        error: false,
        error_message: "",
    })


    // const [value, onChange] = useState(new Date());
    const [toaster, setToaster] = useState(false)
    const navigate = useNavigate()

    const validationSchema = Yup.object().shape({
        holiday_year: Yup.string()

            .required("Required"),
        holiday_reason: Yup.string().required("Required"),
        holiday_month: Yup.string().required("Required"),
        holiday_day: Yup.string().required("Required"),

    });
    const formik = useFormik({
        initialValues: {
            holiday_id: "",
            holiday_year: "",
            client: "",
            holiday_reason: "",
            holiday_month: "",
            holiday_day: "",

            // holiday_reason: "",
        },
        validationSchema,
        onSubmit: (values) => {
            __handleCreateCalendar(values)
        },
    });
    const __handleCreateCalendar = (values) => {

        const RedirectId = getRedirectTabId()

        createOrUpdateHolidayCalendar(values.holiday_id, values.holiday_year, values.holiday_reason, values.holiday_month, values.holiday_day, values?.client)((response) => {
            if (response && response.status) {
                setToaster('success')
                const timer = setTimeout(() => {
                    setToaster(false)
                    changeAllTitleFunction("Holiday Calendar", HOLIDAY_CALENDAR, RedirectId)
                }, 2000);
                return () => clearTimeout(timer);
            }
            else {
                setToaster('error')
                const timer = setTimeout(() => {
                    setToaster(false)
                    //navigate('/view/holiday-calendar')
                }, 2000);
                return () => clearTimeout(timer);
            }
        });
    }
    const __toggleToast = () => {
        setState({ showToast: !state.showToast })

    }
    const __toggleError = () => {
        setState({ showToast: !state.showToast })

    }

    // const handleOnSelectChange = ({ name, value }) => {
    //     // console.log(name, value);
    //     formik.setValues({ ...formik.values, [name]: value.value })
    // }
    // const handleDataChange = (name, value, index) => {
    //     console.log(name, value, index, "??????????????????????????????????????????");
    //     var data = formik.values.holiday_list;
    //     data[index][name] = value;
    //     formik.setValues({ ...formik.values, 'holiday_list': data })
    // }

    // const
    //     handleRemoveItems = (index, arrayHelpers, value) => {
    //         let removed_ids = [...state.removed_ids]
    //         let data = formik.values.holiday_list
    //         data = data.filter((i) => i != value)
    //         formik.setValues({ ...formik.values, 'holiday_list': data })
    //         if (value?.id) {
    //             removed_ids.push(String(value?.id))
    //             setState({ ...state, removed_ids })
    //         }
    //     }
    // const handleOnPickerChange = ({ name, value }) => {
    //     // console.log(name, value);
    //     formik.setValues({ ...formik.values, [name]: value })
    // }
    const [yearOptions, SetYearOptions] = useState([]);
    const [clientOptions, SetClientOptions] = useState([]);
    const [monthOptions, SetMonthOptions] = useState([
        { value: "1", label: "January" },
        { value: "2", label: "February" },
        { value: "3", label: "March" },
        { value: "4", label: "April" },
        { value: "5", label: "May" },
        { value: "6", label: "June" },
        { value: "7", label: "July" },
        { value: "8", label: "August" },
        { value: "9", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
    ]);
    const [dateOptions, SetDateOptions] = useState([

    ]);

    const getFullYears = () => {
        // let years = [];
        const start = new Date().getFullYear();
        const end = new Date().getFullYear() + 10;

        for (let i = start; i <= end; i++) {
            yearOptions.push({ value: i, label: i })
        }
    }
    const getdateOptions = () => {
        // let years = [];
        const start = 1;
        const end = 31;

        for (let i = start; i <= end; i++) {
            dateOptions.push({ value: i, label: i })
            if (i == 31) {

                break;
            }
        }
    }
    const getClientDropdowns = () => {
        getAllClientDropdown()((response) => {
            // console.log('====================================');
            // console.log(response);
            // console.log('====================================');
            SetClientOptions(response?.data)
        })
    }

    // useEffect(() => {
    //     get
    // }, [id]);


    useEffect(() => {
        getdateOptions()
        getFullYears()
        getClientDropdowns()
    }, []);

    useEffect(() => {
        if (id) {
            getSingleHolidayCalender(id)((response) => {
                if (response?.status && response?.data) {
                    // const data = [];
                    // response?.data?.results?.map((holiday, index) => {
                    //     data.push({
                    //         holiday_day: holiday.holiday_day,
                    //         holiday_month: holiday.holiday_month,
                    //         holiday_reason: holiday.holiday_reason
                    //     })
                    // })
                    formik.setValues({
                        ...formik.values,
                        holiday_id: response?.data?.results[0].id,
                        holiday_year: response?.data?.results[0].holiday_year,
                        client: response?.data?.results[0].client,
                        holiday_day: response?.data?.results[0].holiday_day,
                        holiday_month: response?.data?.results[0].holiday_month,
                        holiday_reason: response?.data?.results[0].holiday_reason

                    })
                }
            })
        }
    }, [id])

    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    return (
        <React.Fragment>
            <AdminMaster />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster && toaster == 'success' ? true : false} animation={true} delay={2000} autohide={200}>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster && toaster == 'error' ? true : false} animation={true} delay={2000} autohide={200}>

                        <Toast.Body ><span className='text-white'>Failed</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1>{id ? "Edit Holiday Calendar" : "Create Holiday Calendar"}</h1>

                </div>
                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">
                                    {/* <Formik
                                        initialValues={{
                                            client: "",
                                            from_date: "",
                                            to_date: "",
                                            holiday_reason: "",

                                        }}
                                        onSubmit={(initialValues) => {
                                            __handleCreateCalendar(initialValues)
                                        }}
                                        validationSchema={SignupSchema}

                                    >
                                        {({
                                            handleSubmit,
                                            formik.handleChange,
                                            formik?,
                                            touched,
                                            isValid,
                                            errors,
                                        }) => ( */}
                                    <FormikProvider value={formik}>

                                        <Form
                                            onSubmit={formik.handleSubmit}
                                        >
                                            <div className="mb-3 row" >


                                                <div className='col-lg-3'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold' >Client</Form.Label>

                                                        <Form.Control as="select"
                                                            className="form-control form-control-solid b-r-10"
                                                            name="client"
                                                            value={formik.values?.client}
                                                            //isValid={formik.touched.client && !formik?.errors?.client}
                                                            //isInvalid={!!formik.errors?.client}
                                                            placeholder="Select an Option"
                                                            style={{ padding: "6px !important" }}

                                                            // options={clientOptions}
                                                            onChange={formik.handleChange}
                                                        // onChange={(option: Option) => { formik.setFieldValue("client", option.value) }}
                                                        >
                                                            <option value="">Select An Option</option>
                                                            {clientOptions?.map((option, index) => (
                                                                <option key={index} value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Form.Control>
                                                        {/* {!!formik.errors?.client &&
                                                            <div className='text-danger  py-1'>

                                                                {formik?.errors?.client}
                                                            </div>
                                                        } */}
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-9">
                                                    <Form.Group className="mb-3" >


                                                        <Form.Label className='fw-bold' >Holiday Description</Form.Label>


                                                        <Form.Control
                                                            name='holiday_reason'
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            //isValid={formik?.touched?.holiday_reason && !formik?.errors?.holiday_reason}
                                                            //isInvalid={!!formik?.errors?.holiday_reason}
                                                            value={formik.values?.holiday_reason}
                                                            className='form-control form-control-solid b-r-10'

                                                            placeholder=" Description"
                                                        />

                                                        {!!formik.errors?.holiday_reason &&
                                                            <div className='text-danger  py-1'>

                                                                {formik.errors?.holiday_reason}
                                                            </div>
                                                        }

                                                    </Form.Group>
                                                </div>

                                                <div className="col-lg-3" >
                                                    <Form.Group className="mb-3" >


                                                        <Form.Label className='fw-bold' >Month</Form.Label>


                                                        <Form.Control as="select"
                                                            className="form-control form-control-solid b-r-10"
                                                            name='holiday_month'
                                                            //value={formik?.values?.holiday_list[index]?.holiday_month}
                                                            value={formik.values?.holiday_month}
                                                            //isValid={formik.touched.client && !formik?.errors?.holiday_month}
                                                            //isInvalid={!!formik.errors?.holiday_month}
                                                            placeholder="Select an Option"

                                                            //options={monthOptions}
                                                            onChange={formik.handleChange}
                                                        >
                                                            <option value="">--Select--</option>
                                                            {monthOptions?.map((month, index) => (
                                                                <option key={index} value={month?.value}>{month?.label}</option>
                                                            ))}
                                                        </Form.Control>

                                                        {!!formik.errors?.holiday_month &&
                                                            <div className='text-danger  py-1'>

                                                                {formik.errors?.holiday_month}
                                                            </div>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className="col-lg-3">
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold' >Date</Form.Label>




                                                        <Form.Control as="select"
                                                            className="form-control form-control-solid b-r-10"
                                                            name='holiday_day'
                                                            //value={formik?.values?.holiday_list[index]?.holiday_month}
                                                            value={formik.values?.holiday_day}
                                                            //isValid={formik.touched.client && !formik?.errors?.holiday_day}
                                                            //isInvalid={!!formik.errors?.holiday_day}
                                                            placeholder="Select an Option"

                                                            //options={monthOptions}
                                                            onChange={formik.handleChange}
                                                        >
                                                            <option value="">--Select--</option>
                                                            {dateOptions?.map((date, index) => (
                                                                <option key={index} value={date?.value}>{date?.label}</option>
                                                            ))}
                                                        </Form.Control>
                                                        {!!formik.errors?.holiday_day &&
                                                            <div className='text-danger  py-1'>

                                                                {formik.errors?.holiday_day}
                                                            </div>
                                                        }
                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-3'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold' >Year</Form.Label>

                                                        {/* <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            name="holiday_year"
                                                            defaultValue={formik.values.holiday_year}
                                                            // isValid={formik.touched.client && !formik?.errors?.holiday_year}
                                                            // isInvalid={!!formik.errors?.holiday_year}
                                                            placeholder="Select an Option"
                                                            required
                                                            options={yearOptions}
                                                            onChange={(option: Option) => { formik.setFieldValue("holiday_year", option.value) }}
                                                        /> */}
                                                        <Form.Control as="select"
                                                            className="form-control form-control-solid b-r-10"
                                                            name="holiday_year"
                                                            value={formik.values?.holiday_year}
                                                            //isValid={formik.touched.client && !formik?.errors?.holiday_year}
                                                            //isInvalid={!!formik.errors?.holiday_year}
                                                            placeholder="Select Year"

                                                            // options={clientOptions}
                                                            onChange={formik.handleChange}
                                                        // onChange={(option: Option) => { formik.setFieldValue("client", option.value) }}
                                                        >
                                                            <option >Select An Option</option>
                                                            {yearOptions?.map((option, index) => (
                                                                <option key={index} value={option.value}>{option.label}</option>
                                                            ))}
                                                        </Form.Control>
                                                        {!!formik.errors?.holiday_year &&
                                                            <div className='text-danger  py-1'>

                                                                {formik?.errors?.holiday_year}
                                                            </div>
                                                        }
                                                    </Form.Group>
                                                </div>
                                            </div>


                                            {/* <React.Fragment>
                                                <div className='d-flex flex-row justify-content-start gap-4 flex-wrap mt-2'>
                                                </div>
                                            </React.Fragment> */}



                                            {/* <div>
                                                {state.error &&
                                                    <span className='text-danger'>{state.error_message}</span>}
                                            </div> */}
                                            <div className='d-flex flex-row justify-content-end mt-2'>
                                                <div className=''>
                                                    <button
                                                        type='button'
                                                        className='btn btn-secondary btn-active-primary me-2'
                                                        onClick={() => { changeAllTitleFunction("Holiday Calendar", HOLIDAY_CALENDAR) }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                {checkUserPermission('edit_holiday_calender') &&
                                                    <div className=''>
                                                        <button
                                                            type='submit'
                                                            className='btn btn-primary btnHover'
                                                            disabled={!formik.isValid}

                                                        >
                                                            {id ?
                                                                'Update'
                                                                :
                                                                'Create'

                                                            }
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </Form>
                                        {/* )}
                                    </Formik> */}
                                    </FormikProvider>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </React.Fragment>
    )
}

export default CreateCalendar;