import React, { useEffect, useRef, useState } from 'react';
import '../../../assets/css/dashboard.css';
import Header from '../Includes/header';
import Sidebar from '../Includes/sidebar';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getAllPermissions } from '../../../Redux/auth/action';
import { Toast, ToastContainer } from 'react-bootstrap';
import { onMessageListener } from '../../../firebase';
import { getNotificationList } from '../../../Redux/notification/action';
import GlobelHotkey from '../../../components/DashBoard/DataEntry/Grid/Elements/globelHotkey';


const AdminMaster = (props) => {
    const [hotKey, setHotKey] = useState(false)
    const token = localStorage.getItem("token");
    const refresh_token = localStorage.getItem("refresh_token");
    const user = JSON.parse(localStorage.getItem("user"));
    const { ToastMessage, message } = props;
    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '', job: '' });
    const [togglePopup, setTogglePopup] = useState(false);
    const [groupId, setGroupId] = useState({});
    const [update, setUpdate] = useState(false);

    useEffect(() => {
        if (!token || !refresh_token) {
            setTogglePopup(true);
        }
        fetchNotifications();
    }, [togglePopup]);
    const [notificationData, setNotificationData] = useState({ count: "", messages: "" })
    const fetchNotifications = () => {
        getNotificationList()((response) => {
            if (response?.status && response?.status_code == 200) {
                setNotificationData({ count: response?.data?.unread_count, messages: response?.data?.unread_messages })
            }
        })
    }
    onMessageListener().then(payload => {
        setShow(true);
        setNotification({ title: payload.data.title, body: payload.data.body, job: payload?.data?.job_instance ? payload?.data?.job_instance : "" })
        fetchNotifications()
    });

    useEffect(() => {
        if (groupId) {
            if (groupId?.value) {
                if (localStorage.getItem('user_group')) {
                    if (JSON.parse(localStorage.getItem('user_group')).value != groupId?.value) {
                        getAllPermissions(groupId?.value)((response) => {
                            if (response) {
                                localStorage.setItem('user_group', JSON.stringify(groupId))
                                localStorage.setItem('permissions', JSON.stringify(response?.codename))
                                window.location.reload()
                                var titlebtn = parent.document.getElementById('frame-group-change-btn');
                                if (titlebtn) {
                                    titlebtn.click()
                                }
                                const initial_bottomTab = parent.document.getElementById('frame-group-initial-bottom-tabs-btn');
                                if (initial_bottomTab) {
                                    initial_bottomTab.click()
                                }
                            }
                        });
                    }
                }
                else {
                    getAllPermissions(groupId?.value)((response) => {
                        if (response) {
                            localStorage.setItem('user_group', JSON.stringify(groupId))
                            localStorage.setItem('permissions', JSON.stringify(response?.codename))
                            const initial_bottomTab = parent.document.getElementById('frame-group-initial-bottom-tabs-btn');
                            if (initial_bottomTab) {
                                initial_bottomTab.click()
                            }
                        }
                    });
                }
            }
            else {
                if (!localStorage.getItem('user_group') && !user?.is_superuser) {
                }
            }
        } else {
            let permissions = localStorage.getItem('permissions')
            if (!permissions) {
                getAllPermissions()((response) => {
                    if (response) {
                        localStorage.setItem('permissions', JSON.stringify(response?.codename))
                        setUpdate(!update)
                        const initial_bottomTab = parent.document.getElementById('frame-group-initial-bottom-tabs-btn');
                        if (initial_bottomTab) {
                            initial_bottomTab.click()
                        }
                        parent.location.reload()
                    }
                });
            }
        }
    }, [groupId]);

    const handleLogin = () => {
        localStorage.clear();
        parent.open("about:blank", "_self");
        parent.close();

    }
    window.onkeyup = function (event) {
        if (event.key == 'Tab' && event.ctrlKey && event.shiftKey) {
            parent.document.getElementById('id-ctrl-tab-shift-switch').click()
        }
        else if (event.key == 'Tab' && event.ctrlKey) {
            parent.document.getElementById('id-ctrl-tab-switch').click()
        }
        else if (event.altKey && event.keyCode == 78) {
            parent.document.getElementById('bottom-tab-add').click()
        }
        else if (event.altKey && event.keyCode == 88) {
            parent.document.getElementById('id-ctrl-x-remove-tab').click()
        }
        else if (event.altKey && event.keyCode == 72) {
            setHotKey(true)
        }
        else if (event.key == 'Escape') {
            setHotKey(false)
        }
    }

    const fullscreenRef = useRef(null);
    return (
        <React.Fragment>
            <div ref={fullscreenRef} >
                {/* {togglePopup &&
                    <SweetAlert
                        custom
                        danger
                        showCloseButton
                        confirmBtnText="Close the window and try again"
                        confirmBtnCssClass="btnHover"
                        confirmBtnBsStyle="primary"
                        cancelBtnBsStyle="light"
                        onConfirm={handleLogin}
                        title="Session Token Expired"
                    >
                    </SweetAlert>
                } */}
                <GlobelHotkey hotKey={hotKey} setHotKey={setHotKey} />
                <Header notificationData={notificationData} setGroupId={setGroupId} ToastMessage={ToastMessage} message={message} />
                <ToastContainer containerPosition="fixed" position={"top-end"}>
                    <Toast bg='success' onClose={() => setShow(false)} show={show} delay={6000} autohide animation
                        style={{
                            position: 'fixed',
                            top: 100,
                            right: 20,
                            minWidth: 200,
                            zIndex: '99999'
                        }}
                    >
                        <Toast.Header closeButton closeLabel="close" closeVariant="black">
                            <strong className="me-auto ">{notification.title}</strong>
                        </Toast.Header>
                        <Toast.Body className='text-white'>{notification.body}</Toast.Body>
                    </Toast>
                </ToastContainer>
                <Sidebar user={user} update={update} />
            </div>
        </React.Fragment>
    );
}

export default AdminMaster;
