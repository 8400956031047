import React from 'react'
import Highlighter from 'react-highlight-words';
import { useState, useEffect } from 'react';

const PayeeSearch = (props) => {
    const {
        loading,

        __handleSearchPayee,
        togglePayeeHoverEnter,
        activatePayeeLookup,
        setPayeeFocus,
        setPayeeLookup,
        payeeValue,
        payeeData,
        payeeIndex,
        setPayeeValue

    } = props;

    useEffect(() => {
        // if (payeeValue?.length >= 3) {
            const timeout = setTimeout(() => {
                __handleSearchPayee()

            }, 800);
            return () => {
                clearTimeout(timeout);
            };
        // }
    }, [payeeValue]);
    return (
        <React.Fragment>
            <div className="bg-color h-search" >
                <div className="search-grid">
                    <div className=" py-2  row d-flex align-items-center">
                        <div className="col-lg-1 text-end">
                            <span className="fw-bolder text-white">Search Payee :</span>
                        </div>
                        <div className="col-lg-10">
                            <input type="search" placeholder="Search Payee (at least 3 char) "
                                onChange={(e)=>setPayeeValue(e.target.value)} autoFocus
                                // onFocus={__handleSearchPayee}
                                className="form-control b-r-10 ms-2 form-control-search" value={payeeValue} />
                        </div>
                        <div className="col-lg-1">
                            <button type="button" onClick={() => { setPayeeLookup(false) }} className="btn text-white fw-bolder">X</button>
                        </div>
                    </div>
                </div>
                <div className="" style={{ marginTop: '80px' }}>
                    <ul className="list-group list-group-horizontal payer-ul-scroll">
                        <li className="list-group-item item-width-full py-1 fs-7" >Name</li>
                        <li className="list-group-item item-width-full py-1 fs-7" >NPI</li>
                        <li className="list-group-item item-width-full py-1 fs-7" >Id Code Qualifier</li>
                        <li className="list-group-item item-width-full py-1 fs-7" >Address</li>
                        <li className="list-group-item item-width-full py-1 fs-7" >City</li>
                        <li className="list-group-item item-width-full py-1 fs-7" >Zip</li>
                        <li className="list-group-item item-width-full py-1 fs-7" >State</li>

                    </ul>
                    {loading ? (
                        <React.Fragment>
                            {/* <img src={searchData} style={{ height: '250px' }} alt="search" /> */}
                            <div className="text-center p-5">
                                <span className="fw-bolder fs-4">
                                    Searching...
                                </span>
                            </div>
                        </React.Fragment>
                    ) :
                        (
                            <div className='mt-4'>
                                {payeeData && payeeData.length > 0 ? (
                                    <React.Fragment>
                                    
                                    {payeeData.map((payee, ind) => (
                                            <ul
                                                onMouseEnter={() => togglePayeeHoverEnter(ind)}
                                                onClick={() => { activatePayeeLookup(); setPayeeFocus(true) }}
                                                key={ind} className={payeeIndex == ind ? "list-group list-group-horizontal active-payee" : "list-group list-group-horizontal"}>

                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payeeValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payee.name}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payeeValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payee.identification_code}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payeeValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payee.id_code_qualifier}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payeeValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payee.address}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payeeValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payee.city}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payeeValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payee.zip}
                                                    />
                                                </li>
                                                <li className="list-group-item option-item py-1 fs-7" >
                                                    <Highlighter
                                                        highlightClassName="YourHighlightClass"
                                                        searchWords={[payeeValue]}
                                                        autoEscape={true}
                                                        textToHighlight={payee.state}
                                                    />
                                                </li>

                                            </ul>
                                        ))}
                                   
                                       
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <div className='py-3 text-center'>
                                            <span className='fw-bolder'>No data</span>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        )
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

export default PayeeSearch