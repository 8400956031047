import React, { Fragment, useEffect } from 'react'
import { Tooltip } from '@mui/material';
import Select from 'react-select';


function UserDashboardCard({ dashboardData, handleChangeShift, shiftOption }) {
    const removedCph = dashboardData?.data?.filter((i) => i.label != "CPH")

    const options = [
        { value: 'morning', label: 'MORNING' },
        { value: 'evening', label: 'EVENING' },
        { value: 'night', label: 'NIGHT' }
    ]
    return (

        <div div className="card-main-container user-dashboard-container" >
            {removedCph?.length > 0 &&
                <div className="card card-tale-2 " >
                    <div className="card-body d-flex align-items-center justify-content-center">
                        <div className='shift-select-container'>
                            <h4 class="me-4">SHIFT</h4>
                            <Select
                                className=""
                                name="shift-select"
                                options={options}
                                onChange={(e) => handleChangeShift(e)}
                                // placeholder="Select Shift..."
                                value={shiftOption}
                            />
                        </div>


                    </div>
                </div>
            }
            {removedCph?.map((item) =>

                <div className="card card-tale-2 " >
                    <div className="card-body ">
                       
                        <div className='d-flex flex-row justify-content-between'>
                            <div className='user-inner-card'>
                                <h4 className='mb-4'>{item?.label}</h4>
                                <div className="user-middile-container">
                                    {item?.label !== 'CPH' &&
                                        <div className='card-left-user me-3'>
                                            <i className="bi bi-stopwatch-fill"></i>
                                        </div>
                                    }
                                    <div className='card-right-user'>
                                        <p className='left-text mb-2'>{item?.label === 'CPH' ? 'PREVIOUS CPH' : 'TIME TAKEN'}</p>
                                        <div className='dashboard-left-count'>
                                            {item?.previous_cph ?? item?.time_taken}
                                        </div>
                                        {item?.rejected_time_taken &&
                                            <div className='dashboard-left-count mt-2'>
                                                {item?.rejected_time_taken}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='dash-rounds-container'>
                                <Tooltip disableInteractive title={item?.label == 'TOTAL' ? 'Total Jobs' : item?.label == 'CPH' ? 'CPH' : 'Jobs'} placement='top' arrow>
                                    <div className='dashboard-round-count'>
                                        {item?.cph ?? item?.transaction_count}

                                    </div>
                                </Tooltip>
                                {item?.label != 'CPH' &&
                                    <div className='d-flex flex-row justify-content-end'>
                                        <Tooltip disableInteractive title={item?.label == 'TOTAL' ? 'Rejected Jobs' : 'CPH'} placement='top' arrow>
                                            <div className='mt-3 me-2 dashboard-round-count dashboard-second-round-count'>
                                                {item?.rejected_count ?? item?.individual_cph}

                                            </div>
                                        </Tooltip>



                                        <Tooltip disableInteractive title={item?.label == 'TOTAL' ? 'Total Claims' : 'Claims'} placement='top' arrow>
                                            <div className='mt-3 dashboard-round-count dashboard-second-round-count'>
                                                {item?.total_claims ?? item?.claims_count}

                                            </div>
                                        </Tooltip>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            )}

            {/* <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-between'>
                        <div className='user-inner-card'>
                            <h4 className='mb-4'>TOTAL</h4>
                            <div className="user-middile-container">
                                <div className='card-left-user me-3'>
                                    <i className="bi bi-stopwatch-fill"></i>
                                </div>
                                <div className='card-right-user'>
                                    <p className='left-text mb-2'>TIME TAKEN</p>
                                    <span className='dashboard-left-count'>
                                        30
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <span className='dashboard-round-count'>
                                30
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-between'>
                        <div className='user-inner-card'>
                            <h4 className='mb-4'>EOB</h4>
                            <div className="user-middile-container">
                                <div className='card-left-user me-3'>
                                    <i className="bi bi-stopwatch-fill"></i>
                                </div>
                                <div className='card-right-user'>
                                    <p className='left-text mb-2'>TIME TAKEN</p>
                                    <span className='dashboard-left-count'>
                                        30
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <span className='dashboard-round-count'>
                                30
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-between'>
                        <div className='user-inner-card'>
                            <h4 className='mb-4'>EOB LITE</h4>
                            <div className="user-middile-container">
                                <div className='card-left-user me-3'>
                                    <i className="bi bi-stopwatch-fill"></i>
                                </div>
                                <div className='card-right-user'>
                                    <p className='left-text mb-2'>TIME TAKEN</p>
                                    <span className='dashboard-left-count'>
                                        30
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <span className='dashboard-round-count'>
                                30
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-between'>
                        <div className='user-inner-card'>
                            <h4 className='mb-4'>PATIENT PAY</h4>
                            <div className="user-middile-container">
                                <div className='card-left-user me-3'>
                                    <i className="bi bi-stopwatch-fill"></i>
                                </div>
                                <div className='card-right-user'>
                                    <p className='left-text mb-2'>TIME TAKEN</p>
                                    <span className='dashboard-left-count'>
                                        30
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <span className='dashboard-round-count'>
                                30
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="card card-tale-2 " >
                <div className="card-body ">
                    <div className='d-flex flex-row justify-content-between'>
                        <div className='user-inner-card'>
                            <h4 className='mb-4'>CORRESPONDANCE</h4>
                            <div className="user-middile-container">
                                <div className='card-left-user me-3'>
                                    <i className="bi bi-stopwatch-fill"></i>
                                </div>
                                <div className='card-right-user'>
                                    <p className='left-text mb-2'>TIME TAKEN</p>
                                    <span className='dashboard-left-count'>
                                        30
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <span className='dashboard-round-count'>
                                30
                            </span>
                        </div>

                    </div>
                </div>
            </div> */}

        </div>

    )
}

export default UserDashboardCard


