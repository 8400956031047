import moment from 'moment';
import React, { useRef } from 'react'
import { Form } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { useState, useEffect } from 'react';

const PatientSearch = (props) => {

    const {
        loading,
        setLoading,
        __handleSearchData837,
        patientValue,
        data_837,
        patientIndex,
        hoveredIndex,
        togglePatientHoverEnter,
        handlePatientIndex,
        patientValue1,
        patientValue2,
        setPatientValue,
        cl837Index,
        activate837,
        setData837,
        // setPatientValue1,
        // setPatientValue2,
        checkRef,
        checkAllRef,
        handleCheckServiceLines,
        setSelectedServiceLines,
        selectedServiceLines,
        qdxTransaction,
        containerRef,
        doubleClicked
    } = props;


    const handleAllCheck = async (e) => {
        if (e.target.checked) {
            // console.log(checkRef.current.)
            checkRef?.current?.map(el =>
                el.checked = true,
                // el.defaultChecked = false
            )
            await handleCheckServiceLines()
        }
        else {
            checkRef?.current?.map(el =>
                el.checked = false,
                // el.defaultChecked = false
            )
            await handleCheckServiceLines()

        }
    }
    useEffect(() => {
        setData837(false)
    }, [])

    useEffect(() => {
        // if (patientValue?.length >= 3) {
        const timeout = setTimeout(() => {
            __handleSearchData837()

        }, 800);
        return () => {
            clearTimeout(timeout);
        };
        // }
    }, [patientValue]);

    return (
        <React.Fragment>
            <div className="bg-color h-search" >
                <div className="search-grid ">
                    <div className=" py-2  row d-flex align-items-center ">
                        <div className="col-lg-1 text-end">
                            <span className="fw-bolder text-white">Search 837 :</span>
                        </div>
                        <div className="col-lg-10">

                            <input type="search" autoFocus placeholder="Search 837 (at least 3 char) "
                                onChange={(e) => setPatientValue(e.target.value)}
                                // onFocus={(e) => {
                                // __handleSearchData837(e);
                                // }}
                                className="form-control b-r-10 ms-2 form-control-search"
                                value={patientValue}
                            />
                        </div>
                        <div className="col-lg-1">
                            {/* <button type="button" onClick={__handle837Search} className="btn text-white fw-bolder">X</button> */}
                        </div>

                    </div>
                </div>
                <div className='d-flex flex-row gap-4 justify-content-around height-claims' style={{ marginTop: '50px' }} >
                    {loading ? (
                        <React.Fragment>
                            {/* <img src={searchData} style={{ height: '250px' }} alt="search" /> */}
                            <div className="text-center p-5">
                                <span className="fw-bolder fs-6">
                                    Searching...
                                </span>
                            </div>
                        </React.Fragment>
                    ) :
                        (
                            <React.Fragment>

                                <div className="flex-grow-1 " style={{}}>
                                    <label className='form-label py-1 ps-1 fw-bold' >Claims</label>
                                    <div className={qdxTransaction == "PATIENT_PAY" ? 'scroll-claim-pp' : 'scroll-claim'}
                                    // ref={containerRef}
                                    >

                                        {qdxTransaction == "PATIENT_PAY" ? (

                                            <React.Fragment>
                                                <ul className="list-group list-group-horizontal patient-ul-scroll">
                                                    <li className="list-group-item item-width-full py-1 fs-7" >Patient Account Number</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7" >MRN Number</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7" >Patient First Name</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7" >Patient Last Name</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7" >Date Of Birth</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7" >Statement Date</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7" >Statement Address</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7" >Statement Amount</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7" >Statement Number</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7" >Date Of Service</li>

                                                </ul>

                                                <div className='mt-4'>
                                                    {data_837 && data_837.length > 0 ? (
                                                        <React.Fragment>
                                                            {data_837.map((patient, ind) => (
                                                                <ul key={ind}
                                                                    className={patientIndex == ind ? "list-group list-group-horizontal  active-payee" : hoveredIndex == ind ? "list-group  list-group-horizontal active-mouse-payee" : "list-group  list-group-horizontal"}
                                                                    onMouseEnter={() => togglePatientHoverEnter(ind)}
                                                                    onClick={() => {
                                                                        handlePatientIndex(ind);
                                                                        // setPatientFocus(true)
                                                                    }}
                                                                    onDoubleClick={!doubleClicked && activate837}
                                                                // onKeyUp={() => setCl837Index(ind)}
                                                                >
                                                                    <li className="list-group-item option-item py-1 fs-7" >
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={[patientValue1, patientValue2]}
                                                                            autoEscape={true}
                                                                            textToHighlight={patient?.patient_account_number}
                                                                        />
                                                                    </li>
                                                                    <li className="list-group-item option-item py-1 fs-7" >
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={[patientValue1, patientValue2]}
                                                                            autoEscape={true}
                                                                            textToHighlight={patient?.mrn_number}
                                                                        />
                                                                    </li>
                                                                    <li className="list-group-item option-item py-1 fs-7" >
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={[patientValue1, patientValue2]}
                                                                            autoEscape={true}
                                                                            textToHighlight={patient?.patient_first_name}
                                                                        />
                                                                    </li>
                                                                    <li className="list-group-item option-item py-1 fs-7" >
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={[patientValue1, patientValue2]}
                                                                            autoEscape={true}
                                                                            textToHighlight={patient?.patient_last_name}
                                                                        />
                                                                    </li>
                                                                    <li className="list-group-item option-item py-1 fs-7" >
                                                                        {/* <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={[patientValue1, patientValue2]}
                                                                            autoEscape={true}
                                                                            textToHighlight={moment(patient?.date_of_birth).format("MM/DD/YYYY")}
                                                                        /> */}
                                                                        {patient?.date_of_birth != "" ? moment(patient?.date_of_birth)?.format("MM/DD/YYYY") : ""}
                                                                    </li>
                                                                    <li className="list-group-item option-item py-1 fs-7" >
                                                                        {/* <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={[patientValue1, patientValue2]}
                                                                            autoEscape={true}
                                                                            textToHighlight={moment(patient?.date_of_birth).format("MM/DD/YYYY")}
                                                                        /> */}
                                                                        {patient?.statement_date != "" ? moment(patient?.statement_date)?.format("MM/DD/YYYY") : ""}
                                                                    </li>
                                                                    <li className="list-group-item option-item py-1 fs-7" >
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={[patientValue1, patientValue2]}
                                                                            autoEscape={true}
                                                                            textToHighlight={patient?.statement_address}
                                                                        />
                                                                    </li>
                                                                    <li className="list-group-item option-item py-1 fs-7" >
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={[patientValue1, patientValue2]}
                                                                            autoEscape={true}
                                                                            textToHighlight={parseFloat(patient?.statement_amount ? patient?.statement_amount : 0).toFixed(2)}
                                                                        />
                                                                    </li>
                                                                    <li className="list-group-item option-item py-1 fs-7" >
                                                                        <Highlighter
                                                                            highlightClassName="YourHighlightClass"
                                                                            searchWords={[patientValue1, patientValue2]}
                                                                            autoEscape={true}
                                                                            textToHighlight={patient?.statement_number}
                                                                        />
                                                                    </li>

                                                                    <li className="list-group-item option-item py-1 fs-7" >

                                                                        {patient?.date_of_service != "" ? moment(patient?.date_of_service).format("MM/DD/YYYY") : ""}
                                                                    </li>


                                                                </ul>
                                                            ))}
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <div className='py-3 text-center'>
                                                                <span className='fw-bolder'>No data</span>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </React.Fragment>

                                        ) : (
                                            <React.Fragment>
                                                <ul className="list-group list-group-horizontal patient-ul-scroll">
                                                    <li className="list-group-item item-width-full py-1 fs-7 width-header-pan" >Patient Account Number</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7 width-header-pfn" >Patient First Name</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7 width-header-pmn" >Patient Middle Name</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7 width-header-pln" >Patient Last Name</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7 width-header-identifier" >Patient Identifier</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7 width-header-payer-name" >Payer Name</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7 width-header-insurance-type" >Insurance Type</li>
                                                    <li className="list-group-item item-width-full py-1 fs-7 width-header-tba" >Total Billed Amount</li>

                                                </ul>

                                                <React.Fragment>
                                                    {data_837 && data_837.length > 0 ? (
                                                        <React.Fragment>
                                                            <div style={{ marginTop: '24px' }}>

                                                                {data_837.map((patient, ind) => (
                                                                    <ul key={ind}
                                                                        className={patientIndex == ind ? "list-group list-group-horizontal  active-payee" : hoveredIndex == ind ? "list-group  list-group-horizontal active-mouse-payee" : "list-group  list-group-horizontal"}
                                                                        onMouseEnter={() => togglePatientHoverEnter(ind)}
                                                                        onClick={() => {
                                                                            handlePatientIndex(ind);
                                                                            // setPatientFocus(true)
                                                                        }}
                                                                        // onKeyDown={handleListKeyDown}

                                                                        onDoubleClick={!doubleClicked && activate837}
                                                                    // onKeyUp={() => setCl837Index(ind)}
                                                                    >
                                                                        <li className="list-group-item option-item width-header-pan py-1 fs-7" >
                                                                            <Highlighter
                                                                                highlightClassName="YourHighlightClass"
                                                                                searchWords={[patientValue1, patientValue2]}
                                                                                autoEscape={true}
                                                                                textToHighlight={patient?.claim_number}
                                                                            />
                                                                        </li>
                                                                        <li className="list-group-item option-item width-header-pfn py-1 fs-7" >
                                                                            <Highlighter
                                                                                highlightClassName="YourHighlightClass"
                                                                                searchWords={[patientValue1, patientValue2]}
                                                                                autoEscape={true}
                                                                                textToHighlight={patient?.patient_first_name}
                                                                            />
                                                                        </li>
                                                                        <li className="list-group-item option-item width-header-pmn py-1 fs-7" >
                                                                            <Highlighter
                                                                                highlightClassName="YourHighlightClass"
                                                                                searchWords={[patientValue1, patientValue2]}
                                                                                autoEscape={true}
                                                                                textToHighlight={patient?.patient_middle_name}
                                                                            />
                                                                        </li>
                                                                        <li className="list-group-item option-item width-header-pln py-1 fs-7" >
                                                                            <Highlighter
                                                                                highlightClassName="YourHighlightClass"
                                                                                searchWords={[patientValue1, patientValue2]}
                                                                                autoEscape={true}
                                                                                textToHighlight={patient?.patient_last_name}
                                                                            />
                                                                        </li>
                                                                        <li className="list-group-item option-item width-header-identifier py-1 fs-7" >
                                                                            <Highlighter
                                                                                highlightClassName="YourHighlightClass"
                                                                                searchWords={[patientValue1, patientValue2]}
                                                                                autoEscape={true}
                                                                                textToHighlight={patient?.identifier_code}
                                                                            />
                                                                        </li>
                                                                        <li className="list-group-item option-item width-header-payer-name py-1 fs-7" >
                                                                            <Highlighter
                                                                                highlightClassName="YourHighlightClass"
                                                                                searchWords={[patientValue1, patientValue2]}
                                                                                autoEscape={true}
                                                                                textToHighlight={patient?.payer_name}
                                                                            />
                                                                        </li>
                                                                        <li className="list-group-item option-item width-header-insurance-type py-1 fs-7" >
                                                                            <Highlighter
                                                                                highlightClassName="YourHighlightClass"
                                                                                searchWords={[patientValue1, patientValue2]}
                                                                                autoEscape={true}
                                                                                textToHighlight={patient?.insurance_type}
                                                                            />
                                                                        </li>
                                                                        <li className="list-group-item option-item width-header-tba py-1 fs-7" >

                                                                            {parseFloat(patient?.total_charge_amount ? patient?.total_charge_amount : 0).toFixed(2)}
                                                                        </li>


                                                                    </ul>
                                                                ))}
                                                            </div>

                                                        </React.Fragment>
                                                    ) : (

                                                        <React.Fragment>
                                                            <div className='py-5 text-center'>
                                                                <span className='fw-bolder'>No data</span>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            </React.Fragment>

                                        )}

                                    </div>

                                </div>
                                <div className="flex-shrink-1" style={{ overflowX: "scroll" }}>
                                    {cl837Index >= 0 && data_837 && data_837[cl837Index] && data_837[cl837Index]?.procedure_codes && data_837[cl837Index]?.procedure_codes?.length > 0 &&
                                        <React.Fragment>
                                            <label className='form-label py-1  fw-bold' >Service Lines</label>
                                            <ul className="list-group list-group-horizontal">
                                                <li className="list-group-item item-width-full-servicelines py-1 fs-7 null-width " >
                                                    <Form.Check.Input
                                                        className=' checked-servicelines never-disable-field'
                                                        type={"checkbox"}
                                                        style={{ height: '20px !important' }}
                                                        // id={`default-${ind}`}
                                                        // name='is_check_servicelines'
                                                        onChange={(e) => handleAllCheck(e)}
                                                        // ref={checkAllRef}
                                                        // ref={(el) => (checkRef.current[ind] = el)}
                                                        defaultChecked={true}

                                                    />
                                                </li>
                                                <li className="list-group-item item-width-full-servicelines py-1 fs-7">#</li>
                                                <li className="list-group-item item-width-full-servicelines py-1 fs-7">Date From</li>
                                                <li className="list-group-item item-width-full-servicelines date-service-to py-1 fs-7">Date To</li>
                                                <li className="list-group-item item-width-full-servicelines py-1 fs-7">Procedure Code</li>
                                                <li className="list-group-item item-width-full-servicelines py-1 fs-7">Billed Amount</li>
                                                <li className="list-group-item item-width-full-servicelines py-1 fs-7">Units</li>

                                            </ul>

                                            <React.Fragment>
                                                {data_837[cl837Index]?.procedure_codes?.map((patient, ind) => (
                                                    <ul key={ind} className="list-group list-group-horizontal">
                                                        <li className="list-group-item option-item-service  py-1 fs-7 check-width" >
                                                            <Form.Check.Input
                                                                className=' checked-servicelines never-disable-field'
                                                                type={"checkbox"}
                                                                disabled={false}
                                                                // id={`default-${ind}`}
                                                                name='is_check_servicelines'
                                                                onChange={() => { handleCheckServiceLines(); }}
                                                                // onFocus={() => handleCheckServiceLines()}
                                                                ref={(el) => (checkRef.current[ind] = el)}
                                                                // checked={checkRef.current && checkRef.current[ind] && checkRef.current[ind]?.checked ? checkRef.current[ind]?.checked : false}
                                                                // autoFocus={(el) => (checkRef.current[ind] = el)}
                                                                value={ind}
                                                                defaultChecked={true}
                                                            />

                                                        </li>
                                                        <li className="list-group-item option-item-service  py-1 fs-7 " >
                                                            {ind + 1}
                                                        </li>
                                                        <li className="list-group-item option-item-service py-1 fs-7 date-service" >
                                                            {moment(patient?.date_from)?.format("MM/DD/YYYY")}
                                                        </li>
                                                        <li className="list-group-item option-item-service py-1 fs-7 date-service-to" >
                                                            {moment(patient?.date_to)?.format("MM/DD/YYYY")}
                                                        </li>
                                                        <li className="list-group-item option-item-service proc-service py-1 fs-7 " >
                                                            {patient?.procedure_code}
                                                        </li>
                                                        <li className="list-group-item option-item-service charge-service py-1 fs-7 " >
                                                            {parseFloat(patient?.chargeamount ? patient?.chargeamount : 0).toFixed(2)}
                                                        </li>
                                                        <li className="list-group-item option-item-service units-service py-1 fs-7 " >
                                                            {patient?.units}
                                                        </li>


                                                    </ul>
                                                ))}
                                            </React.Fragment>


                                        </React.Fragment>
                                    }
                                </div>
                            </React.Fragment>
                        )
                    }

                </div>
            </div>
        </React.Fragment >
    )
}

export default PatientSearch