import React from 'react'
import { useState } from 'react';
import { getOfflineJobLogHistory, getOfflineStatusData, toggleOfflineStatus } from '../../../Redux/sftpClient/action';
import { useEffect } from 'react';
import AdminMaster from '../../../Layout/admin/Master';
import { Button, Form, Modal, Toast, ToastContainer } from 'react-bootstrap';
import { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import datatableCustomStyles from '../../../Layout/Elements/DatatableCustomStyles';
import { Tooltip } from '@mui/material';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BiComment, BiHistory } from 'react-icons/bi';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>

    </>
);

const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;


const ViewOfflineStatus = () => {

    const [pageno, setPageNo] = useState(1);
    const [loader, setLoader] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [filterText, setFilterText] = useState('');
    const [colorRow, setColorRow] = useState({});
    const [data, setData] = useState({})
    const [limit, setLimit] = useState(100)
    const [toaster, setToaster] = useState('')

    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })
    const [isSwal, setIsSwal] = useState({
        status: false,
        message: "",
    })
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        fetchData(page, newPerPage)
        setLoading(false);

    };

    const handlePageChange = page => {
        setPageNo(page)
        fetchData(page, limit, '')
    };

    const handleSearchSplits = async (e) => {
        setFilterText(e)
        let data = e ? e : '';
        // const response = await axiosInstance.get(SFTP_CLIENT_LIST_URL + "?limit=" + limit + "&search=" + data);
        // setData(response?.data);
        fetchData(1, limit, e)
    }

    const fetchData = (page, pageLimit, search = '') => {
        setLoading(true);
        // console.log("#$#$#$#$#$#$#$#$#$#$#$#$#$#$");
        getOfflineStatusData({ page, pageLimit, search })((response) => {
            // console.log(response);
            setPageNo(page)
            setLimit(pageLimit)
            setData(response?.data);
            setTotalRows(response?.data?.count);
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchData(1, limit, '')
    }, []);

    const __toggleActive = (id) => {
        let formData = new FormData();    //formdata object

        formData.append('periodic_task', id);

        toggleOfflineStatus({ formData })((response) => {
            if (response?.status && response?.status_code === 201) {
                // setState({ showToast: !state.showToast })
                setToaster('success')
                setTimeout(() => {
                    setToaster('')
                }, 2000);
                // getAllPayers()((response) => {
                //     setData(response);
                // });
            } else {
                setToaster('error')
                setTimeout(() => {
                    setToaster('')
                }, 2000);
                setState({ error: true, error_message: response?.message });
            }
        });
    }
    const capitalizeWords = (str) => {
        if (!str) return '';

        return str.replace(/_/g, ' ')
            .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    };
    const columns = useMemo(
        () => [
            {
                id: 'S.NO',
                name: 'S.NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                width: "60px",
                wrap: true,


            },
            {
                id: 'name',
                name: 'Name',
                selector: row => row?.name,
                cell: (row) =>


                    <div className="overflow-hidden d-flex flex-row  flex-wrap text-truncate " onClick={() => handleRowClick(row)} >
                        <Tooltip disableInteractive title={capitalizeWords(row?.name)} className='text-capitalize' placement='top' arrow>

                            <span className='text-truncate text-capitalize '  >
                                {capitalizeWords(row?.name)}
                            </span>
                        </Tooltip>
                    </div>
                ,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
            },
            // {
            //     id: 'scheduler',
            //     name: 'Scheduler',
            //     selector: row => row?.external_tat,
            //     cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.external_tat}</span>,
            //     sortable: true,
            //     left: true,
            //     wrap: true,
            //     reorder: true,
            // },

            //width: "160px",

            // },
            // {
            //     id: 'start_date_time',
            //     name: 'START DATE TIME',
            //     selector: row => moment(row?.start_time).format('M/DD/YY, H:mm'),
            //     cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.start_time ? moment(row?.start_time).format('M/DD/YY, H:mm') : "-----"}</span>,
            //     // sortable: true,
            //     left: true,
            //     wrap: true,
            //     width: "200px",
            //     reorder: true,

            // },
            // {
            //     id: 'last_run_at',
            //     name: 'Last Run Date',
            //     selector: row => row?.last_run_at,
            //     cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.last_run_at ? moment(row?.last_run_at).format('M/DD/YY, H:mm') : "-----"}</span>,
            //     sortable: true,
            //     left: true,
            //     wrap: true,
            //     width: "200px",
            //     reorder: true,

            // },




            {
                id: 'Actions',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex justify-content-start" onClick={() => handleRowClick(row)}>
                        {/* <Tooltip disableInteractive title="Status" placement='top' arrow>
                            <div className="form-check form-switch " onClick={() => { setCommentShow(true); commentFormik.setValues({ ...commentFormik.values, reason: "", lock_box: row?.file_id }) }}>
                                <input className="form-check-input" type="checkbox" defaultChecked={row.enabled} role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </Tooltip> */}
                        <Tooltip disableInteractive title={row?.enabled ? "Active" : "InActive"} placement='top' arrow>
                            <div className='d-flex flex-row justify-content-end'>
                                {row?.enabled ?
                                    <div className="form-check form-switch " onClick={() => { toggleFormik.setValues({ ...toggleFormik.values, comment: "", periodic_task: row?.id }); setOpen(true); toggleFormik.validateForm() }}>
                                        <input className="form-check-input" type="checkbox" checked={row.enabled} defaultChecked={row.enabled} role="switch" id="flexSwitchCheckDefault" />
                                    </div>
                                    // <button className='btn btn-success btn-sm ' onClick={() => { toggleFormik.setValues({ ...toggleFormik.values, reason: "", lock_box: row?.file_id }); setOpen(true); toggleFormik.validateForm() }}><i className='bi bi-power fs-7'></i></button>
                                    :
                                    <div className="form-check form-switch " onClick={() => { toggleFormik.setValues({ ...toggleFormik.values, comment: "", periodic_task: row?.id }); setOpen(true); toggleFormik.validateForm() }}>
                                        <input className="form-check-input" type="checkbox" checked={row.enabled} defaultChecked={row.enabled} role="switch" id="flexSwitchCheckDefault" />
                                    </div>
                                }
                            </div>
                        </Tooltip>


                        <Tooltip disableInteractive title="Comment History" placement='top' arrow>
                            <button type='button' className='btn fs-7 fw-bold btn-sm' onClick={() => { handleSingleLogJobHistory(row?.id); }} >
                                <BiHistory className='icon-style' />
                            </button>
                        </Tooltip>
                    </div>
                ,
                //ignoreRowClick: false,
                right: true,
                button: false,
                width: "80px",

            },


        ],
        [loader, pageno],
    );



    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow.id == row.id ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
    };
    const handleClear = () => {
        if (filterText) {
            setFilterText('');
            fetchData(1, limit, '');
        }
    };
    const handleRowClickCheck = (row) => {

        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
    }

    const [logShow, setLogShow] = useState(false)

    const toggleFormik = useFormik({
        initialValues: {
            periodic_task: "",
            comment: "",
        },
        validate: values => {
            let errors = {};
            if (values?.comment == "") {
                errors.comment = "Required";
            }
            return errors;
        },
        validationSchema: Yup.object().shape({
            comment: Yup.string().required('Required'),
        }),

        onSubmit: (values, { setSubmitting }) => {

            setSubmitting(true)
            let formData = new FormData();    //formdata object

            formData.append('periodic_task', values?.periodic_task);
            formData.append('comment', values?.comment);

            // toggleActive(values?.lock_box, values?.reason, setSubmitting);
            toggleOfflineStatus({ formData })((response) => {
                if (response?.status) {



                    setOpen(false)
                    fetchData(pageno, limit, filterText)
                    setToaster('success')
                    setTimeout(() => {
                        setToaster('')
                    }, 2000);
                    toggleFormik.handleReset();

                }
                else {
                    setToaster('error')
                    setTimeout(() => {
                        setToaster('')
                    }, 2000);
                    setState({ error: true, error_message: response?.message });
                    setOpen(false)
                    toggleFormik.handleReset();

                }

            })

        }
    })

    const [logData, setLogData] = useState([]);

    const handleSingleLogJobHistory = (periodic_task) => {
        getOfflineJobLogHistory(periodic_task)((response) => {
            if (response?.status) {
                setLogShow(true)
                setLogData(response?.data)

            }
            else {

                setLogShow(false)

            }
        })
    }

    const [open, setOpen] = useState(false);


    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} />
            <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                    {
                        status: false,
                        message: "",
                        type: "",
                    }

                )}
                    show={ToastMessage?.status} animation={true} delay={2000} autohide>
                    <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                </Toast>

            </ToastContainer>
            <main id="main" className="main">

                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Offline Task</h1>
                </div>

                <section className="section mt-5">
                    <div className="row align-items-top py-2  gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                {/* <div className='card-header pt-3'>
                                    <div className="d-flex align-items-center  position-relative ms-4">
                                        <FilterComponent onFilter={(e) => handleSearchSplits(e.target.value)} onClear={handleClear} filterText={filterText} />
                                    </div>
                                </div> */}

                                <div className="card-body" style={{ padding: "0px 2.25rem" }}>
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            <DataTable
                                                className="custom-datatable"
                                                fixedHeader={true}

                                                data={data?.results}
                                                columns={columns}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationPerPage={limit}
                                                paginationTotalRows={totalRows}
                                                paginationRowsPerPageOptions={[25, 50, 100]}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                conditionalRowStyles={conditionalRowStyles}
                                                onRowClicked={handleRowClickCheck}
                                                // subHeader
                                                // subHeaderComponent={subHeaderComponentMemo}
                                                theme="solarized"
                                                customStyles={datatableCustomStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <Modal size="lg" show={logShow} onHide={() => { setLogShow(false), setLogData([]) }} centered className='manual-tat'>
                    <Modal.Header closeButton>
                        <Modal.Title>Logs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table history-log-table" >
                            <thead>
                                <tr >
                                    <th >Offline Task</th>
                                    <th >Status</th>
                                    <th >User </th>
                                    <th >Date</th>
                                    <th >Comment</th>
                                </tr>
                            </thead>
                            {logData && logData?.map((log, index) =>
                                <tbody key={index}>
                                    <tr >
                                        <td className='text-break text-capitalize'>{capitalizeWords(log?.offlinetask)}</td>
                                        <td>{log?.is_active ? "Enabled" : "Disabled"}</td>
                                        <td className='text-break ' style={{ width: '100px' }}>{log?.user}</td>
                                        <td className='text-break ' style={{ width: '100px' }}>{log?.created_date ? moment(log?.created_date).format('M/DD/YY') : "-----"}</td>
                                        <td className='text-break '>{log?.comment}</td>

                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </Modal.Body>
                </Modal>
                <Modal show={open} onHide={() => { toggleFormik.handleReset(); setOpen(false) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Comment</Modal.Title>
                    </Modal.Header>
                    <Form className='py-3' onSubmit={toggleFormik.handleSubmit} onReset={() => { toggleFormik.handleReset(); setOpen(false) }} >
                        <Modal.Body>
                            <Form.Group>
                                <Form.Control onChange={toggleFormik.handleChange} isInvalid={!!toggleFormik.errors.comment} className='b-r-10 text-uppercase' name='comment' as="textarea" rows={3} />
                                <Form.Control.Feedback type='invalid'>{toggleFormik.errors.comment}</Form.Control.Feedback>
                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button type='button' onClick={() => { toggleFormik.handleReset(); setOpen(false) }} variant="secondary" >
                                Close
                            </Button>
                            <Button type="submit" disabled={toggleFormik.isSubmitting} className='btn btn-light btn-active-primary' >
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

            </main>
        </React.Fragment>
    )
}

export default ViewOfflineStatus
