
import {
    CREATE_HOLIDAY_CALENDAR_URL,
    GET_HOLIDAY_CALENDAR_URL,
    GET_NEW_SINGLE_HOLIDAY_CALENDER_URL,
    TOGGLE_HOLIDAY_CALENDAR_URL
} from '../../api';
import axiosInstance from './../../axiosInstance';

export const toggleHolidayCalendar = (props) => onResponse => {
    try {
        axiosInstance.post(TOGGLE_HOLIDAY_CALENDAR_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getAllHolidayCalendars = (page, limit, search) => onResponse => {
    try {
        let data = search ? search : ''
        axiosInstance.get(GET_HOLIDAY_CALENDAR_URL + "?page=" + page + "&limit=" + limit + '&search=' + data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdateHolidayCalendar = (holidaycalendar, holiday_year,  holiday_reason, holiday_month, holiday_day, client) => onResponse => {
    try {
        axiosInstance.post(CREATE_HOLIDAY_CALENDAR_URL, {
            holidaycalendar, holiday_year, holiday_reason, holiday_month, holiday_day, client
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {

    }

};

export const getSingleHolidayCalender = (holiday_calendar) => onResponse => {
    try{
        axiosInstance.get(GET_NEW_SINGLE_HOLIDAY_CALENDER_URL + '?holiday_calendar=' + holiday_calendar)
        .then((response)=> {
            onResponse(response.data)
        })
        .catch((err) => {
            onResponse(err)
        })
    } catch (error) {

    }
}