import React from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { Formik, useFormik } from 'formik';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import * as Yup from 'yup';
import { createPayer, getFacilityDropdown, getPayerPayeeDefaultvalue } from '../../../../Redux/user/action';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSinglePayerDetails } from './../../../../Redux/user/action';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import CheckImageModal from '../../Payee/checkImageModal';
import { PAYER } from '../../../../routeNames';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import styled from 'styled-components';

const EditPayer = () => {
    const navigate = useNavigate()
    const [showCheckView, setShowCheckView] = useState(false)
    const [defaultData, setDefaultData] = useState(null)
    let { payer_id, type } = useParams();
    const [clients, setClients] = useState([])
    const [facilities, setFacilities] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [selectedFacility, setSelectedFacility] = useState([])
    const [data, setData] = useState('');

    const [state, setState] = useState({
        showToast: false,
        error: '',
        error_message: "",
    })

    const __handleEditPayer = (values) => {
        const RedirectId = getRedirectTabId()
        // console.log('====================================');
        // console.log(payer_id);
        // console.log('====================================');
        let formData = new FormData();    //formdata object

        formData.append('payer', payer_id);   //append the values with key, value pair
        formData.append('payer_id', values.payer_id.toUpperCase());
        formData.append('entity_identifier_code', values.entity_identifier_code.toUpperCase());
        formData.append('id_code_qualifier', values.id_code_qualifier.toUpperCase());
        formData.append('payer_identification', values.payer_identification.toUpperCase());
        if (type == 'approve')
            formData.append('is_approved', true);
        formData.append('data_from', 1);
        formData.append('name', values.name.toUpperCase());
        formData.append('address', values.address.toUpperCase());
        formData.append('city', values.city.toUpperCase());
        formData.append('state', values.state.toUpperCase());
        formData.append('zip_code', values.zip_code.toUpperCase());
        formData.append('country', values.country.toUpperCase());
        formData.append('country_subdivision_code', values.country_subdivision_code.toUpperCase());
        formData.append('client', values.clients);
        formData.append('is_default_industry_standard_code_available', values.is_default_industry_standard_code_available);
        formData.append('line_of_business', values.line_of_business);

        // values.clients?.map((client) => {
        //     formData.append('client', client);
        // })
        values.facility?.map((data) => {
            formData.append('facility', data);
        })

        createPayer({ formData })((response) => {
            if (!response?.data?.status && response?.status && response?.status_code === 201) {
                setState({ showToast: !state.showToast, error: 'success', error_message: 'Success' })

                setTimeout(() => {
                    changeAllTitleFunction("Payer", PAYER, RedirectId)
                }, 1000);

            } else {
                formik.setErrors({
                    payer_id: response?.response?.data?.errors?.payer_id,
                    entity_identifier_code: response?.response?.data?.errors?.entity_identifier_code,
                    id_code_qualifier: response?.response?.data?.errors?.id_code_qualifier,
                    payer_identification: response?.response?.data?.errors?.payer_identification,
                    name: response?.response?.data?.errors?.payer,
                    address: response?.response?.data?.errors?.address,
                    city: response?.response?.data?.errors?.city,
                    state: response?.response?.data?.errors?.state,
                    zip_code: response?.response?.data?.errors?.zip_code,
                    country: response?.response?.data?.errors?.country,
                    country_subdivision_code: response?.response?.data?.errors?.country_subdivision_code,
                    is_default_industry_standard_code_available: response?.response?.data?.errors?.is_default_industry_standard_code_available,

                })
                setState({ showToast: !state.showToast, error: 'danger', error_message: 'Duplicate Entry!' })
                // setState({ error: true, error_message: response?.message });
            }
        });
    }
    const __toggleToast = () => {
        setState({ showToast: !state.showToast })

    }
    const __toggleError = () => {
        setState({ showToast: !state.showToast })

    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            payer_id: "",
            entity_identifier_code: "",
            id_code_qualifier: "",
            payer_identification: "",
            name: "",
            address: "",
            city: "",
            state: "",
            zip_code: "",
            country: "",
            country_subdivision_code: "",
            is_default_industry_standard_code_available: false,
            clients: "",
            facility: "",
            line_of_business: ""

        },
        validationSchema: Yup.object().shape({
            clients: Yup.string()
                .required('Required'),
            // payer_id: Yup.string()
            //     .min(1, ' At least 1 character')
            //     .required('Required'),
            entity_identifier_code: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            id_code_qualifier: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            payer_identification: Yup.string()
                // .matches(/^[0-9]+$/, "Must be only digits")
                // .min(10, 'at least 10 character')
                // .max(10, 'at least 10 character')
                .required('Required'),
            name: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            address: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            entity_identifier_code: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            city: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            state: Yup.string()
                .matches(/^[A-Z]+$/i, 'Enter a valid State Abbrevation')
                .min(2, 'Only two characters')
                .max(2, 'Only two characters')
                .required('Required'),
            zip_code: Yup.string()
                .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{5}\d{4}$)/, 'Invalid Zip code')
                .required('Required'),
            country: Yup.string()
                .matches(/^[a-zA-Z ]*$/, 'Invalid Country '),
            country_subdivision_code: Yup.string()
                .min(1, 'At least 1 character'),
            is_default_industry_standard_code_available: Yup.bool().oneOf([true, false], 'Select either on of these'),

        }),
        onSubmit: (values) => {
            __handleEditPayer(values)
        },
    });

    const fetchDefaultvalue = (clientId) => {
        getPayerPayeeDefaultvalue(clientId)((response) => {
            if (response?.status) {
                setDefaultData(response?.data)
            }
        })
    }

    useEffect(() => {
        getSinglePayerDetails(payer_id)((response) => {
            if (response?.status && response?.data) {
                setData(response?.data?.payer);
                fetchDefaultvalue(response?.data?.payer?.client?.[0]?.value)
                formik.setValues({
                    ...formik.values,
                    payer: payer_id,
                    payer_id: response?.data?.payer?.payer_id,
                    entity_identifier_code: response?.data?.payer?.entity_identifier_code,
                    id_code_qualifier: response?.data?.payer?.id_code_qualifier,
                    payer_identification: response?.data?.payer?.payer_identification,
                    name: response?.data?.payer?.name,
                    address: response?.data?.payer?.address,
                    city: response?.data?.payer?.city,
                    state: response?.data?.payer?.state,
                    zip_code: response?.data?.payer?.zip_code,
                    country: response?.data?.payer?.country,
                    country_subdivision_code: response?.data?.payer?.country_subdivision_code,
                    is_default_industry_standard_code_available: response?.data?.payer?.is_default_industry_standard_code_available,
                    clients: response?.data?.payer?.client?.[0]?.value,
                    facility: response?.data?.payer?.facility?.map((data) => data?.value),
                    line_of_business: response?.data?.payer?.line_of_business,
                })
                setSelectedFacility(response?.data?.payer?.facility);
                setSelectValue(response?.data?.payer?.client)
            }
        });

    }, []);

    useEffect(() => {
        getAllClientDropdown()((response) => {
            if (response?.status) {
                setClients(response?.data)
            }
        })
    }, [])

    useEffect(() => {
        if (formik?.values?.clients) {
            getFacilityDropdown([formik?.values?.clients])((response) => {
                if (response && response.status) {
                    setFacilities(response.data.facilities?.map(result => { return { options: result?.facilities, label: result?.client } }))
                }
            });
        }

    }, [selectValue])

    window.onkeyup = function (event) {
        if (event.keyCode === 27) {
            setShowCheckView(false)
        }
    }

    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const options = [
        // { value: "all", label: "All" },
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];
    const handleSelectChange = (selected) => {
        formik.setValues({
            ...formik.values, "clients": selected.value, "facility": []
        });
        fetchDefaultvalue(selected?.value)
        setSelectValue(selected ?? "")
        setSelectedFacility([])

    };
    const handleFacilityChange = (selected) => {
        formik.setFieldValue("facility", selected.map((option) => option?.value));
        setSelectedFacility(selected)


    };

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {formik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}
            </components.DropdownIndicator>
        );
    };
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);
    // console.log(formik?.errors);
    return (
        <React.Fragment>

            <AdminMaster />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={state.error} onClose={__toggleToast} show={state.showToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{state.error_message}</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    {type == 'approve' ?
                        <h1 className='text-uppercase'>Approve Payer</h1>
                        :
                        <h1 className='text-uppercase'>Edit Payer</h1>
                    }

                </div>
                <MainContainer className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">

                                    <Form
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="mb-3 row" >
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold' >Payer ID</Form.Label>

                                                    <Form.Control
                                                        name='payer_id'
                                                        type="text"
                                                        autoComplete="false"
                                                        // isValid={formik.touched.payer_id && !formik?.errors.payer_id}
                                                        // isInvalid={!!formik?.errors.payer_id}
                                                        value={formik.values?.payer_id}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        onChange={formik.handleChange}
                                                        maxLength="255"
                                                        placeholder="Payer Id"

                                                    />

                                                    <Form.Control.Feedback type="invalid">{formik?.errors?.payer_id}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold' >Entity Identifier Code</Form.Label>

                                                    <Form.Control
                                                        name='entity_identifier_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik?.touched?.entity_identifier_code && !formik?.errors?.entity_identifier_code}
                                                        isInvalid={!!formik?.errors?.entity_identifier_code}
                                                        value={formik.values?.entity_identifier_code}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Entity Identifier Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik?.errors?.entity_identifier_code}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>ID Code Qualifier</Form.Label>
                                                    <Form.Control
                                                        name='id_code_qualifier'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.id_code_qualifier && !formik.errors?.id_code_qualifier}
                                                        isInvalid={!!formik?.errors.id_code_qualifier}
                                                        value={formik.values?.id_code_qualifier}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="ID Code Qualifier"
                                                        onKeyUp={(e) => {
                                                            if (e.ctrlKey && e.key === " ") {
                                                                formik.values?.id_code_qualifier == '' && defaultData?.default_payer_code_qualifier && formik?.values?.clients && formik?.setValues({
                                                                    ...formik?.values,
                                                                    id_code_qualifier: defaultData?.default_payer_code_qualifier,
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.id_code_qualifier}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Payer Identification</Form.Label>
                                                    <Form.Control
                                                        name='payer_identification'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik?.touched?.payer_identification && !formik?.errors.payer_identification}
                                                        isInvalid={!!formik?.errors?.payer_identification}
                                                        value={formik?.values?.payer_identification}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        // maxLength={10}
                                                        placeholder="Payer Identification"
                                                        onKeyUp={(e) => {
                                                            if (e.ctrlKey && e.key === " ") {
                                                                formik?.values?.payer_identification == '' && defaultData?.default_payer_identification && formik?.values?.clients && formik?.setValues({
                                                                    ...formik?.values,
                                                                    payer_identification: defaultData?.default_payer_identification,
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.payer_identification}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Default Industry Standard Code</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        onChange={() => formik?.setFieldValue('is_default_industry_standard_code_available', !formik?.values?.is_default_industry_standard_code_available)}
                                                        checked={formik?.values?.is_default_industry_standard_code_available}
                                                        isInvalid={
                                                            formik?.errors?.is_default_industry_standard_code_available &&
                                                            !!formik?.errors?.is_default_industry_standard_code_available
                                                        }
                                                        feedbackType="invalid"
                                                        feedback={
                                                            formik?.errors?.is_default_industry_standard_code_available &&
                                                            formik?.errors?.is_default_industry_standard_code_available
                                                        }

                                                        name="is_default_industry_standard_code_available"
                                                    />
                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Name</Form.Label>
                                                    <Form.Control
                                                        name='name'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik?.touched.name && !formik.errors.name}
                                                        isInvalid={!!formik?.errors?.name}
                                                        value={formik.values?.name}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Name"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.name}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Address</Form.Label>
                                                    <Form.Control
                                                        name='address'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.address && !formik.errors.address}
                                                        isInvalid={!!formik?.errors.address}
                                                        value={formik.values?.address}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Address"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.address}</Form.Control.Feedback>


                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>City</Form.Label>
                                                    <Form.Control
                                                        name='city'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.city && !formik.errors.city}
                                                        isInvalid={!!formik?.errors.city}
                                                        value={formik.values?.city}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="City"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.city}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>State</Form.Label>
                                                    <Form.Control
                                                        name='state'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.state && !formik.errors.state}
                                                        isInvalid={!!formik?.errors.state}
                                                        value={formik.values?.state}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength={2}
                                                        placeholder="State"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.state}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Zip Code</Form.Label>
                                                    <Form.Control
                                                        name='zip_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.zip_code && !formik.errors.zip_code}
                                                        isInvalid={!!formik?.errors.zip_code}
                                                        value={formik.values?.zip_code}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength={10}
                                                        placeholder="Zip Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.zip_code}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Country</Form.Label>
                                                    <Form.Control
                                                        name='country'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.country && !formik.errors.country}
                                                        isInvalid={!!formik?.errors.country}
                                                        value={formik.values?.country}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Country"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.country}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Country Subdivision Code</Form.Label>
                                                    <Form.Control
                                                        name='country_subdivision_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.country_subdivision_code && !formik.errors.country_subdivision_code}
                                                        isInvalid={!!formik?.errors.country_subdivision_code}
                                                        value={formik.values?.country_subdivision_code}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Country Subdivision Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.country_subdivision_code}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-5'>
                                                <div className="mb-10">
                                                    <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Clients</label>


                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        // isMulti
                                                        value={selectValue}
                                                        name="clients"
                                                        options={options}
                                                        components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select Clients..."
                                                        onFocus={() => setIsFocusedClient(true)}
                                                        onBlur={() => setIsFocusedClient(false)}
                                                        styles={{
                                                            container: (styles, state) => ({
                                                                ...styles,
                                                                borderRadius: "9px",
                                                            }),
                                                            control: (styles, state) => ({
                                                                ...styles,
                                                                backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                borderStyle: "none", boxShadow: "none",
                                                                borderRadius: "10px",
                                                                maxHeight: state.isFocused ? "auto" : "30px",
                                                            }),
                                                            indicatorSeparator: styles => ({
                                                                ...styles, width: "0px", minHeight: "20px"
                                                            }),

                                                            valueContainer: (styles, state) => ({
                                                                ...styles,
                                                                maxHeight: isFocusedClient ? "auto" : "30px",
                                                            }),
                                                            placeholder: (styles) => ({
                                                                ...styles,
                                                                textTransform: 'uppercase' // Change placeholder text to uppercase
                                                            })
                                                        }}
                                                    />

                                                    {!!formik.errors?.clients &&
                                                        <span className='text-danger'>{formik.errors?.clients}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-lg-6 mb-3'>
                                                <div className="mb-10">
                                                    <Form.Group>
                                                        <Form.Label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Facility</Form.Label>
                                                        {/* <Form.Control
                                                            type="text"
                                                            name='facility'
                                                            required
                                                            isValid={reasonformik.touched.facility && !reasonformik?.errors?.facility}
                                                            isInvalid={!!reasonformik.errors?.facility}
                                                            onChange={reasonformik.handleChange}
                                                            className="form-control form-control-solid b-r-10"
                                                            placeholder="Reason Code"
                                                            value={reasonformik.values?.facility}
                                                        /> */}
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isMulti
                                                            value={selectedFacility}
                                                            name="facility"
                                                            options={facilities}
                                                            components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="facility" /> }}
                                                            onChange={handleFacilityChange}
                                                            placeholder="Select Facility..."
                                                            isDisabled={selectValue.length > 1 ? true : false}
                                                            onFocus={() => setIsFocusedFacility(true)}
                                                            onBlur={() => setIsFocusedFacility(false)}
                                                            styles={{
                                                                container: (styles, state) => ({
                                                                    ...styles,
                                                                    borderRadius: "9px",
                                                                }),
                                                                control: (styles, state) => ({
                                                                    ...styles,
                                                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                    borderStyle: "none", boxShadow: "none",
                                                                    borderRadius: "10px",
                                                                    maxHeight: state.isFocused ? "auto" : "30px",
                                                                }),
                                                                indicatorSeparator: styles => ({
                                                                    ...styles, width: "0px", minHeight: "20px"
                                                                }),

                                                                valueContainer: (styles, state) => ({
                                                                    ...styles,
                                                                    maxHeight: isFocusedFacility ? "auto" : "30px",
                                                                }),
                                                                placeholder: (styles) => ({
                                                                    ...styles,
                                                                    textTransform: 'uppercase' // Change placeholder text to uppercase
                                                                })

                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facility}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Line of Business</Form.Label>
                                                    <Form.Control
                                                        name='line_of_business'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        // isValid={formik.touched.line_of_business && !formik.errors.line_of_business}
                                                        // isInvalid={!!formik?.errors.line_of_business}
                                                        value={formik.values?.line_of_business}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        placeholder="Line of Business"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.line_of_business}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            {/* <div className='col-lg-4 mt-3'>

                                                <Form.Group className="" >

                                                    <Form.Label className='fw-bold'>Default industry standard code</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        // reverse
                                                        onChange={() => formik?.setFieldValue('is_default_industry_standard_code_available', !formik?.values?.is_default_industry_standard_code_available)}
                                                        checked={formik?.values?.is_default_industry_standard_code_available}
                                                        isInvalid={
                                                            formik?.errors?.is_default_industry_standard_code_available &&
                                                            !!formik?.errors?.is_default_industry_standard_code_available
                                                        }
                                                        feedbackType="invalid"
                                                        feedback={
                                                            formik?.errors?.is_default_industry_standard_code_available &&
                                                            formik?.errors?.is_default_industry_standard_code_available
                                                        }

                                                        name="is_default_industry_standard_code_available"
                                                    // label='Is supervisor dashboard'
                                                    />
                                                </Form.Group>

                                            </div> */}
                                        </div>
                                        {/* <div>
                                            {state.error &&
                                                <span className='text-danger'>{state.error_message}</span>}
                                        </div> */}
                                        <div className='d-flex flex-row justify-content-end'>
                                            {data?.job_id &&
                                                <div className='me-2'>
                                                    <p
                                                        className='pt-2'
                                                        style={{ fontSize: '0.9375rem', color: '#6ba2b6' }}
                                                    >
                                                        {data?.job_id}
                                                    </p>
                                                </div>
                                            }


                                            {data?.image_url &&
                                                <div className=''>
                                                    <button
                                                        type='button'
                                                        className='text-uppercase btn btn-secondary btn-active-primary me-2'
                                                        onClick={() => { setShowCheckView(true) }}
                                                    >
                                                        Image
                                                    </button>
                                                </div>
                                            }
                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='text-uppercase btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("Payer", PAYER) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            {checkUserPermission('edit_payer') &&
                                                <div className=''>
                                                    <button
                                                        type='submit'
                                                        className='text-uppercase btn btn-primary btnHover'
                                                        disabled={!formik.isValid}
                                                    >
                                                        {type == 'approve' ? "Approve" : "Update"} Payer
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </MainContainer>

                {/* Check View Modal Start */}
                {showCheckView &&

                    <CheckImageModal fileUrl={data?.image_url} setShowCheckView={setShowCheckView} showCheckView={showCheckView} />
                }

                {/* Check View Modal Start */}
            </main>
        </React.Fragment >
    )
}

export default EditPayer

const MainContainer = styled.div`
    label{
        text-transform:uppercase
    }
`