import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import AdminMaster from '../../../Layout/admin/Master';
import { Link, useNavigate } from 'react-router-dom';
import { Toast, ToastContainer, OverlayTrigger, Modal, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import axiosInstance from './../../../axiosInstance';
import { useFormik } from 'formik';
import { BiComment, BiExport, BiFile, BiHistory, BiPaperPlane, BiReset, BiSearch, BiTimer, BiUserPlus } from 'react-icons/bi';
import DateTimePicker from 'react-datetime-picker';
import { ediValidate835List, fetchAllLockboxFiles, generateOperationLog, generateOutputZip, getChangeJobTat, getLockboxLogDetailsReport, getUserlist, getUserManualAssign, lockboxComment, resendEobOutputFIleSchema, toggleLockbox } from '../../../Redux/qms/action';
import { GET_ALL_SFTP_FILES_URL } from '../../../api';
import { IoIosSwitch } from 'react-icons/io';
// import { LOCKBOX_FILES_QMS, SINGLE_LOCKBOX_LOCKBOX_FILES, SINGLE_SPLIT } from '../../../routeNames';
import moment from 'moment';
import { getAllClients } from '../../../Redux/client/action';
import { getAllSftpFilesFilter, rejectSftpFile } from '../../../Redux/fileSplit/action';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import queueDatatableCustomStyles from '../../../Layout/Elements/QueueDatatableCustomStyles';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { getAllClientDropdown } from '../../../Redux/client/action';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import * as Yup from 'yup';
import 'rsuite/dist/rsuite.min.css';
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import DateRangePicker from 'rsuite/DateRangePicker';

import { LOCKBOX_FILES_QMS, SINGLE_LOCKBOX_LOCKBOX_FILES } from '../../../routeNames';
import { HiChevronDown } from 'react-icons/hi';
import { TbGridDots } from 'react-icons/tb';
import { changeAllTitleFunction } from '../Dashboard/functions';
import { style } from '@mui/system';
import { GrSend } from 'react-icons/gr';
import { Tooltip } from '@mui/material';
import { getEmailLogListData, getSFTPLogListData } from '../../../Redux/sftpClient/action';
const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
var input = {
    client: '',
    file: ''
};


const EmailLogList = () => {
    const [datetime, setDateTime] = useState([]);
    //////////////////////////////////////////////////////////////////
    const [state, setState] = useState({
        s_no: true,
        client: true,
        facility: true,
        lbx_file_name: true,
        lbx_file_count: true,
        total_file: true,
        index_file_amount: true,
        check_amount: true,
        balance: true,
        eft_amount: true,
        plb_amount: true,
        no_of_check: true,
        total_pages: true,
        status: true,
        internal_tat: true,
        lbx_file_size: true,
        file_received_on: true,
        output_generated_time: true,
        split_type: true,
        reason: true,
        actions: true,

    });

    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(null);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [data, setData] = useState({})
    const [colorRow, setColorRow] = React.useState({});

    const [filterText, setFilterText] = React.useState('');
    const [filterClient, setFilterClient] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [clientName, setClientName] = useState("")
    const [selectValue, setSelectValue] = useState([])
    const [selectTypeValue, setSelectTypeValue] = useState([])
    const [selectStatusValue, setSelectStatusValue] = useState([])

    const [isSwal, setIsSwal] = useState({
        show: false,
        id: '',
        data: '',
    })
    const [isEdi, setIsEdi] = useState({
        show: false,
        id: '',
        data: '',
    })

    const [pageno, setPageNo] = useState(1);
    const [userlist, setUserlist] = useState();
    const [clientList, setClientList] = useState();

    const [settings, setSettings] = useState();
    const [toaster, setToaster] = useState(null);

    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })

    useEffect(() => {
        // getUserlist(1)((response) => {
        //     setUserlist(response?.data?.results)
        // });

        getAllClientDropdown()((response) => {

            setClientList(response?.data)
        })

    }, []);




    const formik = useFormik({
        initialValues: {
            assignedqueue: "",
            staff: "",
        },
        // validationSchema,
        onSubmit: (values) => {
            __formSubmit(values)
        },
    });

    const __formSubmit = (values) => {
        getUserManualAssign(values?.staff, values?.assignedqueue)((response) => {
            setShowModal(false);
            if (response && response.status) {
                setToaster('success')
            }
            else {
                setToaster('error')
            }

        });
    };

    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );
    // const searchTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Search
    //     </Tooltip>
    // );
    // const resetTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Reset
    //     </Tooltip>
    // );
    // const HeadingsTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Table Headings
    //     </Tooltip>
    // );
    // const assignTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Assign
    //     </Tooltip>
    // );
    // const tatTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Tat
    //     </Tooltip>
    // );
    // const renderDeleteTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Download Operation Log
    //     </Tooltip>
    // );
    // const renderOutputTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Download Output File
    //     </Tooltip>
    // );
    // const renderSendTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Send
    //     </Tooltip>
    // );
    // const renderOutputGenerateTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Generate Output File
    //     </Tooltip>
    // );
    // const renderOutputReGenerateTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Re Generate Output File
    //     </Tooltip>
    // );
    // const renderGenerateTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Generate Operation Log
    //     </Tooltip>
    // );
    // const renderRegenerateTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Re Generate Operation Log
    //     </Tooltip>
    // );

    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];

    const handleDownloadSftpLog = (file) => {
        const fileURL = file;

        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();
        fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status, searchedData?.email_type)
    }

    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                width: "50px",
                grow: 0,

            },

            {
                id: 'client',
                name: 'client',
                selector: row => row.client,
                cell: (row) =>
                    <div className='text-truncate ' style={{ width: "320px", overflow: 'hidden' }} onClick={() => handleRowClick(row)}>
                        <Tooltip disableInteractive title={row?.client} placement='top' arrow>
                            <span className='text-truncate '>
                                {row?.client}
                            </span>
                        </Tooltip>
                    </div>
                ,
                sortable: true,
                wrap: true,
                width: "400px",
                grow: 0.3,

            },

            {
                id: 'user',
                name: 'user',
                selector: row => row.user,
                cell: (row) =>
                    <div className='text-truncate ' style={{ width: "320px", overflow: 'hidden' }} onClick={() => handleRowClick(row)}>
                        <Tooltip disableInteractive title={row?.user} placement='top' arrow>
                            <span className='text-truncate '>
                                {row?.user}
                            </span>
                        </Tooltip>
                    </div>
                ,
                sortable: true,
                wrap: true,
                width: "300px",
                grow: 0.3,

            },
            {
                id: 'Email Type',
                name: 'Email Type',
                selector: row => row.email_type,
                cell: (row) =>
                    <div className='text-truncate ' style={{ width: "520px", overflow: 'hidden' }} onClick={() => handleRowClick(row)}>
                        <Tooltip disableInteractive title={row?.email_type} placement='top' arrow>
                            <span className='text-truncate '>
                                {row?.email_type}
                            </span>
                        </Tooltip>
                    </div>
                ,
                sortable: true,
                wrap: true,
                width: "250px",
                grow: 0.3,

            },
            {
                id: 'Status',
                name: 'Status',
                selector: row => row.is_mail_sent,
                cell: (row) =>
                    <div className='text-truncate ' style={{ width: "100px", overflow: 'hidden' }} onClick={() => handleRowClick(row)}>
                        <div className='text-truncate '>
                            {row?.is_mail_sent ? "Sent" : "Failed"}
                        </div>
                    </div>
                ,
                sortable: true,
                wrap: true,
                width: "100px",
                grow: 0.3,

            },
            // {
            //     id: 'Log File',
            //     name: 'Log File',
            //     selector: row => row.emaillog_file,
            //     cell: (row) =>
            //         <div className='text-truncate ' style={{ width: "350px", overflow: 'hidden' }} onClick={() => handleRowClick(row)}>
            //             <Tooltip disableInteractive title={row?.emaillog_file} placement='top' arrow>
            //                 <div className='text-truncate '>
            //                     {row?.emaillog_file}
            //                 </div>
            //             </Tooltip>
            //         </div>
            //     ,
            //     sortable: true,
            //     wrap: true,
            //     width: "350px",
            //     grow: 0.3,

            // },
            {
                id: 'created_date',
                name: 'created date',
                selector: row => row.created_date,
                cell: (row) =>
                    <div onClick={() => handleRowClick(row)}>
                        {/* <Tooltip disableInteractive title={row?.user} placement='top' arrow> */}
                        <div className='text-truncate '>
                            {moment(row?.created_date).format('M/DD/YY, H:mm')}
                        </div>
                        {/* </Tooltip> */}
                    </div>
                ,
                sortable: true,
                wrap: true,
                width: "120px",
                grow: 0.3,

            },


            {
                id: 'edit',
                name: 'Action',

                cell: (row) =>
                    <React.Fragment>
                        <div>
                            <div className=" d-flex flex-row gap-2  justify-content-start align-items-center no-wrap" onClick={() => handleRowClick(row)}>


                                <div className=''>

                                    <Tooltip disableInteractive title="Log File" placement='top' arrow>
                                        <button className='btn fs-7 fw-bold btn-sm' onClick={() => { handleDownloadSftpLog(row.emaillog_file) }} >
                                            <i className='bi bi-download fs-6' ></i>
                                        </button>
                                    </Tooltip>
                                </div>


                            </div>
                        </div>

                    </React.Fragment>
                ,
                ignoreRowClick: false,
                width: '120px',
                allowOverflow: true,
                //button: true,
                right: true
            },

        ],
        [pageno, loading],
    );


    const [defaultValues, setDefaultValues] = useState({})

    const fetchQueueListing = async (page, page_limit, search, client, deposit_date_range, status, email_type) => {
        setLoading(true);

        getEmailLogListData({ page, page_limit, client, deposit_date_range, status, email_type })((response) => {
            if (response?.status) {
                setData(response?.data);
                setPageNo(page)
                setDefaultValues(response?.data?.results?.default_values);
                setLimit(page_limit);
                setTotalRows(response?.data?.count);
                setLoading(false);
            }
        })
    };
    const handlePageChange = page => {
        setPageNo(page)
        let created_date = datetime

        fetchQueueListing(page, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status, searchedData?.email_type);
    };

    const handlePerRowsChange = async (newPerPage, page) => {


        let created_date = datetime

        fetchQueueListing(1, newPerPage, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status, searchedData?.email_type)
        setLimit(newPerPage)
        handleResetDataTable()

    };
    useEffect(() => {
        // fetchQueueListing(1, limit,);
        fetchQueueListing(1, limit, queueformik.values?.search, clientName, queueformik.values.created_date, queueformik.values?.status, searchedData?.email_type)

    }, []);


    const [searchedData, setSearchedData] = useState({
        search: "",
        client: "",
        deposit_date_range: "",
        created_date: "",
        status: "",
        sent_status: '',
        email_type: '',
    })
    const [reRenderDataTable, setRerenderDataTable] = useState(false)

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }
    const queueformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            search: "",
            client: '',
            deposit_date_range: [],
            created_date: "",
            email_type: "",
            sent_status: "",
            status: "",

        },

        onSubmit: (values) => {


            fetchQueueListing(1, limit, values?.search, values?.client, values.created_date, values?.status, values?.email_type)
            setSearchedData({
                search: values?.search ? values?.search : "",
                client: values?.client ? values?.client : "",
                deposit_date_range: values?.deposit_date_range ? values?.deposit_date_range : "",
                created_date: values?.created_date ? values?.created_date : "",
                status: values?.status ? values?.status : "",
                sent_status: values?.sent_status ? values?.sent_status : "",
                email_type: values?.email_type ? values?.email_type : "",


            })
            handleResetDataTable()
        },

    });

    const handleRowSelected = React.useCallback(state => {
        var ids = [];
        state.selectedRows.map((row, index) => {
            ids.push(row.id);
        })
        setSelectedRows(ids);
    }, []);

    const handleOnDateTimeChange = ({ name, value }) => {
        let created_date = ""
        if (value !== null) {
            const datefrom = moment(value['0']).format('MM/DD/YYYY')
            const dateto = moment(value['1']).format('MM/DD/YYYY')

            created_date = datefrom + " - " + dateto
        }
        setDateTime(created_date)

        queueformik.setValues({ ...queueformik.values, created_date, deposit_date_range: value ?? "" })


    }

    const handleOnSelectClientChange = async (e, name) => {
        let selectedId = []
        let selectedOptons = []

        e.map((option) => {
            selectedId.push(option.value)
            selectedOptons.push({ option })
        })

        queueformik.setValues({ ...queueformik.values, [name]: selectedId })
        setSelectValue(selectedOptons)
        setClientName(selectedId)
    }
    const handleOnSelectChange = async (e, name) => {
        queueformik.setValues({ ...queueformik.values, [name]: e?.value ?? "" })

    }
    var i = 0;

    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.pk == row.pk ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];

    const handleRowClick = (row) => {
        setColorRow(row);
    };
    const handleRowCheckClick = (row) => {
        data?.results?.map((item, index) => {
            if (row?.pk == item.pk) {
                setColorRow(row)
            }
        })
    };

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {queueformik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };

    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedType, setIsFocusedType] = useState(false);
    const emailTypeList = [
        { label: 'Arrival Of FTP File', value: 'Arrival_Of_FTP_File' },
        { label: 'Absence Of Expected File', value: 'Absence_Of_Expected_File' },
        { label: 'Non Recognized File', value: 'Non_Recognized_File' },
        { label: 'File Transfer To Client', value: 'File_Transfer_To_Client' },
        { label: 'File Split', value: 'File_Split' },
    ]
    const statusList = [
        { label: 'Sent', value: 'sent' },
        { label: 'Failed', value: 'failed' },

    ]
    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} />

            <main id="main" className="main">

                <div className="pagetitle tab-fixed-header ">
                    <h1 id='page-title'>Email Log</h1>
                </div>

                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                        {
                            status: false,
                            message: "",
                            type: "",
                        }

                    )}
                        show={ToastMessage?.status} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                    </Toast>

                </ToastContainer>

                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <Form onSubmit={queueformik.handleSubmit} onReset={() => {
                                    window.location.reload()
                                }}>
                                    <div className='' >
                                        <div className="d-flex flex-row justify-content-start gap-2 align-items-center px-4 py-4 flex-wrap py-3">
                                            <div className='jobmonitor-select'>
                                                <Select
                                                    isMulti
                                                    options={clientList}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client" /> }}
                                                    className="select-search-client "
                                                    classNamePrefix="select"
                                                    placeholder="Select Client"
                                                    values={selectValue}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={300}
                                                    isClearable
                                                    name="client"
                                                    onChange={(e) => handleOnSelectClientChange(e, 'client')}
                                                    defaultValue={queueformik?.values?.client}
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),

                                                    }} />
                                            </div>
                                            <div className='jobmonitor-select'>
                                                <Select
                                                    options={emailTypeList}
                                                    // components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="email_type" /> }}
                                                    className="select-search-client "
                                                    classNamePrefix="select"
                                                    placeholder="Email Type"
                                                    values={selectValue}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={300}
                                                    isClearable
                                                    name="email_type"
                                                    onChange={(e) => handleOnSelectChange(e, 'email_type')}
                                                    defaultValue={queueformik?.values?.email_type}
                                                    onFocus={() => setIsFocusedType(true)}
                                                    onBlur={() => setIsFocusedType(false)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedType ? "auto" : "30px",
                                                        }),

                                                    }} />
                                            </div>
                                            <div className='jobmonitor-select'>
                                                <Select
                                                    options={statusList}
                                                    // components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="status" /> }}
                                                    className="select-search-client "
                                                    classNamePrefix="select"
                                                    placeholder="Status"
                                                    values={selectValue}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={300}
                                                    isClearable
                                                    name="status"
                                                    onChange={(e) => handleOnSelectChange(e, 'status')}
                                                    defaultValue={queueformik?.values?.status}

                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: "30px",
                                                        }),

                                                    }} />
                                            </div>

                                            <div className='' >
                                                <Stack direction="column" spacing={8} alignItems="flex-start">

                                                    <DateRangePicker
                                                        ranges={predefinedBottomRanges}
                                                        // style={{ backgroundColor: "#000" }}
                                                        cleanable={true}
                                                        placement='auto'
                                                        appearance="default"
                                                        format={"MM/dd/yyyy"}
                                                        placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                                                        className="form-date-control form-control-solid b-r-1 text-dark jobmonitor-datepicker"
                                                        name="deposit_date_range"
                                                        onChange={(e) => { handleOnDateTimeChange({ name: 'deposit_date_range', value: e }) }} value={queueformik?.values?.deposit_date_range} />

                                                </Stack>
                                            </div>

                                            <div className='' style={{ width: '' }}>
                                                <div className='d-flex justify-content-start gap-2'>
                                                    <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                        <Button className="btn btn-light btn-active-primary  " type="submit">
                                                            <span className="svg-icon svg-icon-3">
                                                                <BiSearch />
                                                            </span>

                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                        <Button className="btn btn-light btn-active-primary " type='reset' >
                                                            <span className="svg-icon svg-icon-3">
                                                                <BiReset />
                                                            </span>

                                                        </Button>
                                                    </Tooltip>



                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </Form>
                                <div className="card-body" style={{ padding: "0px 2.25rem" }}>
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}

                                                    data={data?.results}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    onSelectedRowsChange={handleRowSelected}
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    theme="solarized"
                                                    pointerOnHover
                                                    customStyles={queueDatatableCustomStyles}
                                                    responsive={true}
                                                // onSort={handleSort}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        </React.Fragment>
    )
}

export default EmailLogList;