import React from 'react'
import Master from '../../../../Layout/admin/Master'
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { Toast, ToastContainer } from 'react-bootstrap';
import { editGroup, getRoles, getSingleGroupDetails, getSingleX12Code, updateX12Code } from '../../../../Redux/user/action';
import { X12_CODES } from '../../../../routeNames';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import moment from 'moment';
import { DatePicker } from 'rsuite';



function EditX12Code() {
    const navigate = useNavigate();

    let { id } = useParams();
    const [state, setState] = useState({
        error: false,
        error_message: ""
    })
    const [data, setData] = useState([]);
    const [datetime, setDateTime] = useState(new Date());
    const [datetimeStop, setDateTimeStop] = useState(new Date());
    const [toaster, setToaster] = useState(false)

    useEffect(() => {



        getSingleX12Code(id)((response) => {
            if (response?.status && response?.data?.x12code) {
                setData(response?.data?.x12code);
                const date = response?.data?.x12code?.start_date ? new Date(response?.data?.x12code?.start_date) : null
                const dateStop = response?.data?.x12code?.stop_date ? new Date(response?.data?.x12code?.stop_date) : null
                formik.setValues({
                    ...formik.values,

                    x12code_id: response?.data?.x12code?.x12code_id,
                    description: response?.data?.x12code?.description,
                    code: response?.data?.x12code?.code,
                    type: response?.data?.x12code?.type,
                    start_date: date,
                    stop_date: dateStop,



                })
            }
        });

    }, []);





    const x12Schema = Yup.object().shape({
        description: Yup.string()
            .required('Required'),
        code: Yup.array()
            .required('Required'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            x12code_id: "",
            description: "",
            type: '',
            code: '',
            start_date: null,
            stop_date: null,


        },
        validate: (values) => {
            const errors = {};
            if (values?.type == "") {
                errors.type = "Select Type"
            }


            if (values.stop_date && values.stop_date < values.start_date) {
                errors.stop_date = 'Stop date must be greater than or equal to the start date';
            }
            return errors;
        },
        validationSchema: Yup.object().shape({



            description: Yup.string().required('Required'),
            type: Yup.string().required('Required'),
            code: Yup.string().required('Required').max(10, 'Ensure this field have no more than 10 charecters').matches(/^[ A-Za-z0-9_@./#&+-]*$/, 'Enter a valid code'),
            // start_date: Yup.date().required('Start date is required'),
            // stop_date: Yup.date()
            //     .required('End date is required')
            //     .min(s
            //         Yup.ref('start_date'),
            //         'End date must be greater than or equal to the start date'
            //     ),


        }),
        onSubmit: (values) => {
            // __handleEditUser(values)'
            const start_date = values?.start_date ? moment(values?.start_date).format("MM/DD/YYYY") : ""
            const stop_date = values?.stop_date ? moment(values?.stop_date).format("MM/DD/YYYY") : ""
            const RedirectId = getRedirectTabId()
            updateX12Code(values?.x12code_id, values?.type, values?.code, values?.description, start_date, stop_date)((response) => {
                if (response?.status) {
                    setToaster('success')
                    // setToaster(false)
                    const timer = setTimeout(() => {
                        changeAllTitleFunction("X12 Codes", X12_CODES, RedirectId)

                    }, 2000);

                    // return () => clearTimeout(timer);

                }
                else {
                    setToaster('error')
                    setState({ error_message: response?.response?.data?.errors.x12_codes[0] })
                    const timer = setTimeout(() => {
                        setToaster(false)
                    }, 2000);
                    return () => clearTimeout(timer);

                }
            });
        },
    });

    const handleOnDateTimeChange = ({ name, value }) => {

        let created_date = ""
        if (value !== null) {
            const dateFrom = moment(value).format('MM/DD/YYYY')

            created_date = dateFrom
        }
        //     setDateTime(created_date)
        //    setDateTimeStop(created_date)


        formik.setValues({
            ...formik.values,
            [name]: value
        })
        //  formik.setFieldValue(start_date,created_date ?? "")
    }
    return (
        <React.Fragment>
            <Master />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{state.error_message ? state.error_message : "Failed"}</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1>Edit X12 Code</h1>

                </div>

                <section className="section mt-5 ">
                    <div className="row align-items-top py-3 gap-2 gap-md-5">

                        <div className="col-lg-12">


                            <div className="card mb-5 mb-xl-8">
                                <div className="card-body py-3 ">
                                    <Form
                                        onSubmit={formik.handleSubmit}
                                    >

                                        <div className='mb-3 row'>
                                            <div className='col-lg-3'>

                                                <Form.Group className="mb-3">

                                                    <Form.Label htmlFor="exampleFormControlInput1" className=" form-label">Type</Form.Label>

                                                    <Form.Select
                                                        type="text"
                                                        name='type'
                                                        // isValid={formik?.errors && !formik?.errors?.type}
                                                        isInvalid={formik?.errors && !!formik.errors?.type}
                                                        required
                                                        onChange={formik.handleChange}
                                                        className="form-control form-control-solid b-r-10"
                                                        style={{ color: "black" }}
                                                        placeholder="Select Type"
                                                        value={formik.values?.type}
                                                    >
                                                        <option value="">
                                                            Select An Option
                                                        </option>
                                                        <option value="CARC">
                                                            CARC
                                                        </option>
                                                        <option value="RARC">
                                                            RARC
                                                        </option>
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.type}</Form.Control.Feedback>
                                                </Form.Group>

                                            </div>
                                            <div className='col-lg-3'>



                                                <label htmlFor="exampleFormControlInput1" className=" form-label">Code</label>

                                                <Form.Control
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name='code'
                                                    isValid={formik?.errors && !formik?.errors?.code}
                                                    isInvalid={formik?.errors && !!formik.errors?.code}
                                                    placeholder="Code"
                                                    maxLength={10}
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.code}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.code}</Form.Control.Feedback>

                                            </div>
                                            <div className='col-lg-6'>

                                                <Form.Group className="mb-3">

                                                    <Form.Label htmlFor="exampleFormControlInput1" className=" form-label">Description</Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name='description'
                                                        isValid={formik?.errors && !formik?.errors?.description}
                                                        isInvalid={formik?.errors && !!formik.errors?.description}
                                                        required
                                                        onChange={formik.handleChange}
                                                        className="form-control form-control-solid b-r-10 "
                                                        placeholder="Description"
                                                        value={formik.values?.description}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.description}</Form.Control.Feedback>
                                                </Form.Group>

                                            </div>
                                            <div className='col-lg-3 color-black' style={{ display: "flex", flexDirection: "column" }}>



                                                <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Start Date</label>

                                                {/* <Form.Control
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name="start_date"
                                                    isValid={formik?.errors && !formik?.errors?.start_date}
                                                    isInvalid={formik?.errors && !!formik.errors?.start_date}
                                                    placeholder="Start Date"
                                                    maxLength={10}
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.start_date}
                                                /> */}
                                                <DatePicker
                                                    oneTap
                                                    placeholder="Select Date"
                                                    format={"MM/dd/yyyy"}
                                                    name="start_date"
                                                    // value={"06/02/2022"}
                                                    className='datePicker-style'
                                                    onChange={(e) => { handleOnDateTimeChange({ name: "start_date", value: e }) }}
                                                    value={formik.values.start_date}


                                                />
                                                {formik?.errors && formik?.errors?.start_date &&

                                                    <span className='text-danger' style={{ fontSize: "12px", padding: "4px" }}>
                                                        {formik?.errors?.start_date}
                                                    </span>}
                                                {/* <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.start_date}</Form.Control.Feedback> */}

                                            </div>
                                            {/* <div className='col-lg-3'>



                                                <label htmlhtmlFoformikr="exampleFormControlInput1" className=" form-label">Last Modified</label>

                                                <Form.Control
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name="last_modified"
                                                    isValid={formik?.errors && !formik?.errors?.last_modified}
                                                    isInvalid={formik?.errors && !!formik.errors?.last_modified}
                                                    placeholder="Last Modified"
                                                    maxLength={10}
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.last_modified}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.last_modified}</Form.Control.Feedback>

                                            </div> */}
                                            <div className='col-lg-3 color-black' style={{ display: "flex", flexDirection: "column" }}>



                                                <label htmlFor="exampleFormControlInput1" className=" form-label">Stop Date</label>

                                                {/* <Form.Control
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name="stop_date"
                                                    isValid={formik?.errors && !formik?.errors?.stop_date}
                                                    isInvalid={formik?.errors && !!formik.errors?.stop_date}
                                                    placeholder="Stop Date"
                                                    maxLength={10}
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.stop_date}
                                                /> */}
                                                <DatePicker
                                                    oneTap
                                                    placeholder="Select Date"
                                                    format={"MM/dd/yyyy"}
                                                    name="stop_date"
                                                    onChange={(e) => { handleOnDateTimeChange({ name: "stop_date", value: e }) }}
                                                    value={formik.values.stop_date}
                                                    className='datePicker-style'

                                                />
                                                {formik?.errors && formik?.errors?.stop_date &&

                                                    <span className='text-danger' style={{ fontSize: "12px", padding: "4px" }}>
                                                        {formik?.errors?.stop_date}
                                                    </span>}
                                                {/* <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.stop_date}</Form.Control.Feedback> */}
                                            </div>
                                            {/* <div className='col-lg-3'>



                                                <label htmlFor="exampleFormControlInput1" className=" form-label">Notes</label>

                                                <Form.Control
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name="notes"
                                                    isValid={formik?.errors && !formik?.errors?.notes}
                                                    isInvalid={formik?.errors && !!formik.errors?.notes}
                                                    placeholder="notes"
                                                    maxLength={10}
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.notes}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.notes}</Form.Control.Feedback>

                                            </div> */}

                                        </div>












                                        {state.error &&
                                            <div className='text-center mt-3'>

                                                <span className='text-danger fw-bold '>{state.error_message}</span>
                                            </div>
                                        }
                                        <div className='d-flex flex-row justify-content-end'>

                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("X12 Codes", X12_CODES) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className=''>
                                                <button
                                                    type='submit'
                                                    className='btn btn-primary btnHover'
                                                    disabled={!formik.isValid}
                                                >
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>





                            {/* )} */}
                            {/* </Formik> */}
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default EditX12Code