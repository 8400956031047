

import React from 'react'
import Modal from 'react-modal';

const ShortCutPage = (props) => {
    const { hotKey, setHotKey } = props;
    function __afterOpenModal() {
        subtitle.style.color = '#f00';
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "30%",
        },
    };
    return (
        <React.Fragment>
            <Modal
                isOpen={hotKey}
                onAfterOpen={__afterOpenModal}
                onRequestClose={() => { setHotKey(false); }}
                style={customStyles}
                contentLabel="Example Modal"
            >

                <ul className="list-group">
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">1</div>
                        <div className="col-lg-5 text-nowrap">Check. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">2</div>
                        <div className="col-lg-5 text-nowrap">Payer. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">3</div>
                        <div className="col-lg-5 text-nowrap">Payee. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">4</div>
                        <div className="col-lg-5 text-nowrap">Claim Header. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">5</div>
                        <div className="col-lg-5 text-nowrap">Claim. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">6</div>
                        <div className="col-lg-5 text-nowrap">Footer. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">7</div>
                        <div className="col-lg-5 text-nowrap">Span. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">8</div>
                        <div className="col-lg-5 text-nowrap">Blackout. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Ctrl+Mouse Scroll</div>
                        <div className="col-lg-5 text-nowrap">Zoom. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Alt+S</div>
                        <div className="col-lg-5 text-nowrap">Save Snippet. </div>
                    </li>
                </ul>
                <div className="mb-2">
                    <h6>SELECTION ON</h6>
                </div>
                <ul className="list-group">

                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Shift+Left Click</div>
                        <div className="col-lg-5 text-nowrap">Page Full View. </div>
                    </li>

                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Right Click</div>
                        <div className="col-lg-5 text-nowrap">Full Page Assign. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Ctrl+Mouse Drag</div>
                        <div className="col-lg-5 text-nowrap">Select Specific Area. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Mouse Click</div>
                        <div className="col-lg-5 text-nowrap">Select Area. </div>
                    </li>

                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Ctrl+Right Click</div>
                        <div className="col-lg-5 text-nowrap">Remove Marked Item. </div>
                    </li>
                    <div className="mb-2 ">
                        <h6>SELECTION OFF</h6>
                    </div>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Ctrl+Click</div>
                        <div className="col-lg-5 text-nowrap">Select Pages. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Double Click</div>
                        <div className="col-lg-5 text-nowrap">Page Full View. </div>
                    </li>
                    <div className="checkviewbtn text-start">
                        <button className="btn btn-primary" onClick={() => { setHotKey(false); }}>Close</button>
                    </div>
                </ul>
            </Modal>
        </React.Fragment>

    )
}

export default ShortCutPage