import React, { Fragment } from 'react'
import { Form } from 'react-bootstrap'
import newicon from "../../../../../assets/img/new-icon.svg";
import deleteicon from "../../../../../assets/img/delete-icon.svg";
import copyicon from "../../../../../assets/img/copy-icon.svg";
import Lite from './lite'
import { BiChevronLeft, BiChevronRight, BiFirstPage, BiLastPage } from 'react-icons/bi';
import Claim from './claim';
import ServiceLine from './serviceLine';
import Highlighter from 'react-highlight-words';
import ClaimSummaryLite from './claimSummaryLite';
import { useState, useEffect } from 'react';

const EobLite = (props) => {
    const { formik,
        jobData,
        totalPages,
        currentPage,
        handleEobLite835Data,
        __handlePayerLiteLookupSearch,
        payerLiteLookup,
        __handleSearchLitePayer,
        activatePayerLiteLookup,
        loading,
        payerLiteData,
        payerLiteIndex,
        payerLiteValue,
        setPayerLiteValue,
        setPayerLiteIndex,
        setPayerLiteFocus,
        payerLiteFocus,
        payeeLiteFocus,
        setPayeeLiteFocus,
        payeeLiteLookup,
        __handlePayeeLiteLookupSearch,
        __handleSearchLitePayee,
        payeeLiteData,
        activatePayeeLiteLookup,
        payeeLiteIndex,
        payeeLiteValue,
        setPayeeLiteValue,
        setPayeeLiteIndex,
        claim835,
        handleLiteRemainingBalanceCalculation,

        // DBK
        eobLiteFocus,
        setEobLiteFocus,
        handleFocusEobLite,
        addingNewEobLiteClaimDbk,
        removeEobLiteClaimDbk,
        isDbkPermission,
        claimIndex,
        setClaimIndex,
        handleNewClaim,
        claimLiteIndex,
        setClaimLiteIndex,
        setState,
        state,
        jumpToPage,
        setClaim835,
        setControlledPosition,
        pdfLoaded,
        isViewOnllyGrid
    } = props;



    useEffect(() => {

        handleEobLite835Data();
    }, [jobData])

    const handlePlbDeleteAmount = async (index) => {
        await formik.setValues(prevValues => {
            const datas = [...prevValues.plb_adjustments];
            datas.splice(index, 1);

            return {
                ...prevValues,
                plb_adjustments: [...datas],
            };
        });

        const remaining = await handleLiteRemainingBalanceCalculation()
    }

    const handleBackspace = (e) => {
        if (e.keyCode == 8) {

            e.preventDefault();

            e.target.select();
        }
    }

    const handleClaimPagination = (value, e) => {

        if (value < 1 || value == 0) {
            setClaimLiteIndex(0);
        } else if (value <= formik.values?.claims?.length) {
            setClaimLiteIndex(value - 1);
        } else if (value > formik.values?.claims?.length) {
        }

    };
    const setDefaultValueAssignedWork = (event, defaultName, formikName, dbk_name, clindex, type) => {
        if (jobData?.default && jobData?.default[defaultName]) {
            if (event.ctrlKey == true && event.keyCode == 32) {
                const claims = formik.values.claims;
                if (type == 'service_line') {
                    claims[clindex].service_lines[formikName] = jobData?.default[defaultName]
                    setTimeout(() => {

                        handleLiteRemainingBalanceCalculation();
                    }, 100);
                }
                else {
                    claims[clindex][formikName] = jobData?.default[defaultName]
                    claims[clindex][dbk_name] = jobData?.default[defaultName]
                }
                formik.setValues({
                    ...formik.values,
                    claims: claims
                })
            }
        }
    }


    const handleDeleteClaim = async () => {
        formik.setValues((prevState) => ({
            ...prevState,
            claims: prevState.claims.filter((_, index) => index !== claimLiteIndex),
        }));

        setTimeout(() => {
            handleLiteRemainingBalanceCalculation();
        }, 100);
        removeEobLiteClaimDbk(claimLiteIndex)
    }

    const handleClearClaim = async () => {

        var data = [{
            claim_id: "",
            corrected_claim: true,
            order: "",
            patient_id: "",
            patient_account_number: "",
            patient_first_name: "",
            patient_middle_name: "",
            patient_last_name: "",

            page_from: "1",
            page_to: "1",
            service_lines: {
                service_line_id: "",
                period_start: "",
                period_end: "",
                billed: "",
                paid: "",
            },

            dbk_patient_account_number: "",
            dbk_patient_first_name: "",
            dbk_patient_middle_name: "",
            dbk_patient_last_name: "",

        }]

        /////////// Remaining balance calculation start\\\\\\\

        await formik.setValues((prevValues) => {
            let remaining = 0;
            let paid_total = 0;
            let plb_total = 0;

            prevValues.claims?.forEach((claim) => {
                paid_total +=
                    parseFloat(claim?.service_lines?.paid ? claim?.service_lines?.paid : 0);
            });

            prevValues?.plb_adjustments?.forEach((plb) => {
                plb_total += parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
            });

            remaining =
                parseFloat(prevValues.check_amount ? prevValues.check_amount : 0) -
                parseFloat(paid_total ? paid_total : 0) +
                parseFloat(plb_total ? plb_total : 0);

            if (parseFloat(remaining).toFixed(2) === "-0.00") {
                remaining = parseFloat(0).toFixed(2);
            }

            return {
                ...prevValues,
                claims: data,
                remaining_balance: parseFloat(remaining).toFixed(2),
            };
        });


    }

    const handleCopyClaim = async () => {
        await formik.setValues(prevValues => {
            const datas = [...prevValues.claims];
            const initialValues = prevValues.claims[claimLiteIndex];

            const new_claim = {
                ...initialValues,
                claim_id: "",
                corrected_claim: true,
                page_from: currentPage + 1,
                page_to: currentPage + 1,
                patient_id: "",

                service_lines: {
                    ...initialValues.service_lines,
                    service_line_id: "",
                },
            };

            const updatedDatas = [...datas];
            updatedDatas.splice(claimLiteIndex + 1, 0, new_claim);
            setClaimLiteIndex(claimLiteIndex + 1);

            return {
                ...prevValues,
                claims: [...updatedDatas],
            };
        });


        await handleLiteRemainingBalanceCalculation();
        await addingNewEobLiteClaimDbk()
        await formik.validateForm()
    }


    const handlePlbAmount = async (e, plbindex) => {
        const value = e.target.value;

        if (value.includes('.') && value.split('.')[1].length > 2) {
            return;
        }
        var plb = formik.values.plb_adjustments
        plb[plbindex].plb_amt = value
        await formik.setValues({
            ...formik.values,
            plb_adjustments: plb
        })
        await handleLiteRemainingBalanceCalculation()
    }

    const handlePLbFloatValue = (e, index) => {

        const value = parseFloat(e.target.value).toFixed(2);
        var data = formik.values?.plb_adjustments;

        data[index].plb_amt = value;

        formik.setValues({
            ...formik.values,
            plb_adjustments: data,
        })
    }


    const insertRemainingAmount = async (index) => {
        var datas = formik?.values?.plb_adjustments;
        if (parseFloat(formik.values?.remaining_balance).toFixed(2) !== parseFloat(0).toFixed(2))
            datas[index].plb_amt = -formik.values?.remaining_balance;
        await formik.setValues({
            ...formik.values,
            plb_adjustments: datas,
        })

        const remaining = await handleLiteRemainingBalanceCalculation()


    }


    useEffect(() => {
        const timeout = setTimeout(() => {
            __handleSearchLitePayer()

        }, 800);
        return () => {
            clearTimeout(timeout);
        };
    }, [payerLiteValue]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            __handleSearchLitePayee()

        }, 800);
        return () => {
            clearTimeout(timeout);
        };
    }, [payeeLiteValue]);

    return (
        <React.Fragment>
            {claim835 ? (
                <React.Fragment>
                    <ClaimSummaryLite
                        setClaimLiteIndex={setClaimLiteIndex}
                        jumpToPage={jumpToPage}
                        setClaim835={setClaim835}
                        setControlledPosition={setControlledPosition}
                        setState={setState}
                        isViewOnllyGrid={isViewOnllyGrid}
                        formik={formik} />
                </React.Fragment>
            ) : payerLiteLookup ?
                <Fragment>
                    <div className="bg-color h-search" >
                        <div className="search-grid">
                            <div className=" py-2  row d-flex align-items-center">
                                <div className="col-lg-1 text-end">
                                    <span className="fw-bolder text-white">Search Payer :</span>
                                </div>
                                <div className="col-lg-10">
                                    <input type="search" placeholder="Search Payer (at least 3 char) "
                                        onChange={(e) => setPayerLiteValue(e.target.value)}
                                        onFocus={(e) => setPayerLiteValue(e.target.value)}
                                        defaultValue={formik.values.payer_name}
                                        autoFocus
                                        className="form-control b-r-10 ms-2 form-control-search" />
                                </div>
                                <div className="col-lg-1">
                                    <button type="button" onClick={__handlePayerLiteLookupSearch} className="btn text-white fw-bolder">X</button>
                                </div>
                            </div>
                        </div>
                        <div className="" style={{ marginTop: '80px' }}>
                            <ul className="list-group list-group-horizontal payerLite-ul-scroll">
                                <li className="list-group-item item-width-full py-1 fs-7">Name</li>
                                <li className="list-group-item item-width-full py-1 fs-7">Payer Identification</li>
                                <li className="list-group-item item-width-full py-1 fs-7">Id Code Qualifier</li>
                                <li className="list-group-item item-width-full py-1 fs-7">Address</li>
                                <li className="list-group-item item-width-full py-1 fs-7">City</li>
                                <li className="list-group-item item-width-full py-1 fs-7">Zip</li>
                                <li className="list-group-item item-width-full py-1 fs-7">State</li>

                            </ul>
                            {loading ? (
                                <React.Fragment>
                                    <div className="text-center p-5">
                                        <span className="fw-bolder fs-6">
                                            Searching...
                                        </span>
                                    </div>
                                </React.Fragment>
                            ) :
                                (
                                    <React.Fragment>
                                        {payerLiteData && payerLiteData.length > 0 ? (
                                            <React.Fragment>
                                                {payerLiteData.map((payer, ind) => (
                                                    <ul
                                                        onMouseEnter={() => setPayerLiteIndex(ind)}
                                                        onClick={() => activatePayerLiteLookup(payer)}
                                                        key={ind} className={payerLiteIndex == ind ? "list-group list-group-horizontal active-payer" : "list-group list-group-horizontal"}>

                                                        <li className="list-group-item option-item py-1 fs-7" >
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={[payerLiteValue]}
                                                                autoEscape={true}
                                                                textToHighlight={payer.name}
                                                            />
                                                        </li>
                                                        <li className="list-group-item option-item py-1 fs-7" >
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={[payerLiteValue]}
                                                                autoEscape={true}
                                                                textToHighlight={payer.identification_code}
                                                            />
                                                        </li>
                                                        <li className="list-group-item option-item py-1 fs-7" >
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={[payerLiteValue]}
                                                                autoEscape={true}
                                                                textToHighlight={payer.id_code_qualifier}
                                                            />

                                                        </li>
                                                        <li className="list-group-item option-item py-1 fs-7" >
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={[payerLiteValue]}
                                                                autoEscape={true}
                                                                textToHighlight={payer.address}
                                                            />
                                                        </li>
                                                        <li className="list-group-item option-item py-1 fs-7" >
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={[payerLiteValue]}
                                                                autoEscape={true}
                                                                textToHighlight={payer.city}
                                                            />
                                                        </li>
                                                        <li className="list-group-item option-item py-1 fs-7" >
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={[payerLiteValue]}
                                                                autoEscape={true}
                                                                textToHighlight={payer.zip}
                                                            />
                                                        </li>
                                                        <li className="list-group-item option-item py-1 fs-7" >
                                                            <Highlighter
                                                                highlightClassName="YourHighlightClass"
                                                                searchWords={[payerLiteValue]}
                                                                autoEscape={true}
                                                                textToHighlight={payer.state}
                                                            />
                                                        </li>

                                                    </ul>
                                                ))}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <div className='py-3 text-center'>
                                                    <span className='fw-bolder'>No data</span>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                </Fragment>

                : payeeLiteLookup ? (
                    <Fragment>
                        <div className="bg-color h-search" >
                            <div className="search-grid">
                                <div className=" py-2  row d-flex align-items-center">
                                    <div className="col-lg-1 text-end">
                                        <span className="fw-bolder text-white">Search Payee :</span>
                                    </div>
                                    <div className="col-lg-10">
                                        <input type="search" defaultValue={formik.values.payee_name} autoFocus placeholder="Search Payee (at least 3 char) " onChange={(e) => setPayeeLiteValue(e.target.value)} onFocus={(e) => setPayeeLiteValue(e.target.value)} className="form-control b-r-10 ms-2 form-control-search" />
                                    </div>
                                    <div className="col-lg-1">
                                        <button type="button"
                                            onClick={__handlePayeeLiteLookupSearch}
                                            className="btn text-white fw-bolder">X</button>
                                    </div>
                                </div>
                            </div>
                            <div className="" style={{ marginTop: '80px' }}>
                                <ul className="list-group list-group-horizontal payerLite-ul-scroll">
                                    <li className="list-group-item item-width-full py-1 fs-7" >Name</li>
                                    <li className="list-group-item item-width-full py-1 fs-7" >NPI</li>
                                    <li className="list-group-item item-width-full py-1 fs-7" >Id Code Qualifier</li>
                                    <li className="list-group-item item-width-full py-1 fs-7" >Address</li>
                                    <li className="list-group-item item-width-full py-1 fs-7" >City</li>
                                    <li className="list-group-item item-width-full py-1 fs-7" >Zip</li>
                                    <li className="list-group-item item-width-full py-1 fs-7" >State</li>

                                </ul>
                                {loading ? (
                                    <React.Fragment>
                                        <div className="text-center p-5">
                                            <span className="fw-bolder fs-6">
                                                Searching...
                                            </span>
                                        </div>
                                    </React.Fragment>
                                ) :
                                    (
                                        <React.Fragment>
                                            {payeeLiteData && payeeLiteData.length > 0 ? (
                                                <React.Fragment>
                                                    {payeeLiteData.map((payee, ind) => (
                                                        <ul
                                                            onMouseEnter={() => setPayeeLiteIndex(ind)}
                                                            onClick={() => activatePayeeLiteLookup(payee)}
                                                            key={ind} className={payeeLiteIndex == ind ? "list-group list-group-horizontal active-payee" : "list-group list-group-horizontal"}>

                                                            <li className="list-group-item option-item py-1 fs-7" >
                                                                <Highlighter
                                                                    highlightClassName="YourHighlightClass"
                                                                    searchWords={[payeeLiteValue]}
                                                                    autoEscape={true}
                                                                    textToHighlight={payee.name}
                                                                />
                                                            </li>
                                                            <li className="list-group-item option-item py-1 fs-7" >
                                                                <Highlighter
                                                                    highlightClassName="YourHighlightClass"
                                                                    searchWords={[payeeLiteValue]}
                                                                    autoEscape={true}
                                                                    textToHighlight={payee.identification_code}
                                                                />
                                                            </li>
                                                            <li className="list-group-item option-item py-1 fs-7" >
                                                                <Highlighter
                                                                    highlightClassName="YourHighlightClass"
                                                                    searchWords={[payeeLiteValue]}
                                                                    autoEscape={true}
                                                                    textToHighlight={payee.id_code_qualifier}
                                                                />
                                                            </li>
                                                            <li className="list-group-item option-item py-1 fs-7" >
                                                                <Highlighter
                                                                    highlightClassName="YourHighlightClass"
                                                                    searchWords={[payeeLiteValue]}
                                                                    autoEscape={true}
                                                                    textToHighlight={payee.address}
                                                                />
                                                            </li>
                                                            <li className="list-group-item option-item py-1 fs-7" >
                                                                <Highlighter
                                                                    highlightClassName="YourHighlightClass"
                                                                    searchWords={[payeeLiteValue]}
                                                                    autoEscape={true}
                                                                    textToHighlight={payee.city}
                                                                />
                                                            </li>
                                                            <li className="list-group-item option-item py-1 fs-7" >
                                                                <Highlighter
                                                                    highlightClassName="YourHighlightClass"
                                                                    searchWords={[payeeLiteValue]}
                                                                    autoEscape={true}
                                                                    textToHighlight={payee.zip}
                                                                />
                                                            </li>
                                                            <li className="list-group-item option-item py-1 fs-7" >
                                                                <Highlighter
                                                                    highlightClassName="YourHighlightClass"
                                                                    searchWords={[payeeLiteValue]}
                                                                    autoEscape={true}
                                                                    textToHighlight={payee.state}
                                                                />
                                                            </li>

                                                        </ul>
                                                    ))}
                                                </React.Fragment>
                                            ) : (

                                                <React.Fragment>
                                                    <div className='py-3 text-center'>
                                                        <span className='fw-bolder'>No data</span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}

                            </div>
                        </div>
                    </Fragment>
                ) : (
                    <Form id="payment-835-lite-form" autoComplete={"off"} noValidate>
                        <Lite
                            {...props}

                            formik={formik}
                            __handlePayerLiteLookupSearch={__handlePayerLiteLookupSearch}
                            __handlePayeeLiteLookupSearch={__handlePayeeLiteLookupSearch}
                            setPayerLiteFocus={setPayerLiteFocus}
                            setPayeeLiteFocus={setPayeeLiteFocus}
                            payeeLiteFocus={payeeLiteFocus}
                            payerLiteFocus={payerLiteFocus}
                            handlePlbDeleteAmount={handlePlbDeleteAmount}
                            insertRemainingAmount={insertRemainingAmount}
                            totalPages={totalPages}
                            handlePlbAmount={handlePlbAmount}
                            handlePLbFloatValue={handlePLbFloatValue}
                            eobLiteFocus={eobLiteFocus}
                            setEobLiteFocus={setEobLiteFocus}
                            handleFocusEobLite={handleFocusEobLite}
                            addingNewEobLiteClaimDbk={addingNewEobLiteClaimDbk}
                            removeEobLiteClaimDbk={removeEobLiteClaimDbk}
                            isDbkPermission={isDbkPermission}
                            jobData={jobData}
                        />
                        {formik?.values?.grid_customization == 'NORMAL' &&
                            <div className="claim835-mt">
                                <div className="healthcare-sub">
                                    <div className="healthcare-top">
                                        <div className="leftside-block">
                                            <div className="accordion" id="claim">
                                                <button tabIndex="-1" type="button" className="btn btn-healthcare accordion-icon" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">Claim835</button>
                                            </div>
                                            <div className="">
                                                <button tabIndex="-1" type="button" id="btn-add-lite-claim" className="btn btn-healthcare" onClick={handleNewClaim}> <img src={newicon} alt="" />  New</button>
                                            </div>
                                            <div className="">
                                                {formik.values?.claims?.length == 1 ?
                                                    <button tabIndex="-1" type="button"
                                                        className="btn btn-healthcare" onClick={handleClearClaim}> <img src={deleteicon} alt="" /> Delete</button>
                                                    :
                                                    <button tabIndex="-1" type="button"
                                                        className="btn btn-healthcare" onClick={() => {
                                                            setClaimLiteIndex(claimLiteIndex + 1 == formik.values.claims.length ? claimLiteIndex - 1 : claimLiteIndex);

                                                            handleDeleteClaim()

                                                        }}>
                                                        <img src={deleteicon} alt="" />
                                                        Delete
                                                    </button>
                                                }
                                            </div>
                                            <div className="">
                                                <button tabIndex="-1" type="button" className="btn btn-healthcare" onClick={handleCopyClaim}> <img src={copyicon} alt="" /> Copy</button>
                                            </div>
                                        </div>
                                        <div className="rightside-block exclude-disabled">

                                            <button type="button" tabIndex="-1" className='py-0 m-0 btn pagination-btn btn-sm ' onClick={(e) => { setClaimLiteIndex(0); setState({ ...state, updateDisable: !state.updateDisable }) }} >
                                                <span><BiFirstPage size="1.3em" style={claimLiteIndex + 1 === 1 ? { color: '#ffefef' } : { color: 'black' }} /></span>
                                            </button>

                                            <button type="button" tabIndex="-1" className='py-0 m-0 btn pagination-btn btn-sm' disabled={claimLiteIndex + 1 === 1 ? true : false} onClick={(e) => { setClaimLiteIndex(claimLiteIndex - 1); setState({ ...state, updateDisable: !state.updateDisable }) }} >
                                                <BiChevronLeft size="1.3em" style={claimLiteIndex + 1 === 1 ? { color: '#ffefef' } : { color: 'black' }} />
                                            </button>

                                            <div className="pagenum-sub"><input tabIndex="-1" className="prevent-shift viewer-toolbar-current-page-input text-center w-82" type="text" value={claimLiteIndex + 1}
                                                onKeyDown={(e) => handleBackspace(e)}

                                                onChange={((e) => { handleClaimPagination(e.target.value, e); })} /> / {formik.values?.claims?.length}</div>

                                            <button type="button" tabIndex="-1" className='py-0 m-0 pagination-btn btn btn-sm' disabled={formik.values?.claims?.length - 1 === claimLiteIndex ? true : false} onClick={(e) => { setClaimLiteIndex(claimLiteIndex + 1); setState({ ...state, updateDisable: !state.updateDisable }) }}>
                                                <BiChevronRight size="1.3em" style={formik.values?.claims?.length - 1 === claimLiteIndex ? { color: '#ffefef' } : { color: 'black' }} />
                                            </button>

                                            <button type="button" tabIndex="-1" className='py-0  btn pagination-btn btn-sm ' disabled={formik.values?.claims?.length - 1 === claimLiteIndex ? true : false} onClick={(e) => { setClaimLiteIndex(formik.values?.claims.length - 1); setState({ ...state, updateDisable: !state.updateDisable }) }}>
                                                <span><BiLastPage size="1.3em" style={formik.values?.claims?.length - 1 === claimLiteIndex ? { color: '#ffefef' } : { color: 'black' }} /></span>
                                            </button>

                                        </div>
                                    </div>
                                </div>

                                {formik.values?.claims.map((claim, index) => (
                                    <React.Fragment key={index}>
                                        {claimLiteIndex == index &&
                                            <React.Fragment key={index}>
                                                <Claim formik={formik} index={index} totalPages={totalPages} currentPage={currentPage} setDefaultValueAssignedWork={setDefaultValueAssignedWork}
                                                    {...props}
                                                    eobLiteFocus={eobLiteFocus}
                                                    setEobLiteFocus={setEobLiteFocus}
                                                    handleFocusEobLite={handleFocusEobLite}
                                                    addingNewEobLiteClaimDbk={addingNewEobLiteClaimDbk}
                                                    removeEobLiteClaimDbk={removeEobLiteClaimDbk}
                                                    isDbkPermission={isDbkPermission}
                                                    jobData={jobData}
                                                />
                                                <ServiceLine {...props} formik={formik} index={index} setDefaultValueAssignedWork={setDefaultValueAssignedWork} />
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                ))}
                            </div>
                        }
                    </Form>
                )
            }
        </React.Fragment>
    )
}

export default EobLite