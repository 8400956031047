
import { KEEP_ALIVE_URL, PAYEE_APPROVE_URL, PAYER_APPROVE_URL, RESON_CODES_APPROVE_URL } from '../../api';
import axiosInstance from './../../axiosInstance';


// dashboard 
export const approveFunction = (id, type) => onResponse => {
    let url = null
    let dic = null
    if (type === 'payee_approve') {
        url = PAYEE_APPROVE_URL
        dic = { payee: id }
    } else if (type === 'payer_approve') {
        dic = { payer: id }
        url = PAYER_APPROVE_URL
    } else if (type === 'reson_codes_approve') {
        dic = { reasoncode: id }
        url = RESON_CODES_APPROVE_URL
    }
    try {
        axiosInstance.post(url, dic)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {
    }

};
export const keepAliveApi = () => onResponse => {
    try {
        axiosInstance.get(KEEP_ALIVE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {
    }

};