import React, { useEffect } from 'react'
import { useFormik } from "formik";
import InputMask from "react-input-mask";
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import * as Yup from "yup";
import moment from 'moment';

function DateMaskInput({ setCustomeDateTime, customeDateTime, fromDateRef, dateTimeRef }) {
    const formik = useFormik({
        initialValues: {
            fromDate: "",
            toDate: "",
            fromTime: "",
            toTime: "",

        },
        validationSchema: Yup.object().shape({
            fromDate: Yup.string(),
            toDate: Yup.string(),
            fromTime: Yup.string(),
            toTime: Yup.string()
        }),
        validate: (values) => {
            let errors = {};

            if (values.fromDate !== '' && values.fromDate !== '__/__/____' && !moment(values.fromDate, "MM/DD/YYYY", true).isValid()) {
                errors.fromDate = "Invalid date format";
            } else {
                errors.fromDate = "";

            }
            if (values.toDate !== '' && values.toDate !== '__/__/____' && !moment(values.toDate, "MM/DD/YYYY", true).isValid()) {
                errors.toDate = "Invalid date format";
            }

            // Add time validation here
            if (values.fromTime && values.fromTime !== '__:__' && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(values.fromTime)) {
                errors.fromTime = "Invalid time format";
            }

            if (values.toTime && values.toTime !== '__:__' && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(values.toTime)) {
                errors.toTime = "Invalid time format";
            }
            if (values.toDate == '' && values.fromDate == '' && values.toTime && values.fromTime && values.toTime !== '__:__' && values.fromTime !== '__:__' && !(!/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(values.toTime)) && !(!/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(values.fromTime))) {
                if (values.fromTime > values.toTime) {
                    errors.toTime = "Invalid time format";
                    errors.fromTime = "Invalid time format";

                }
            }
            // Compare fromDate and toDate
            if (moment(values.fromDate, "MM/DD/YYYY").isAfter(moment(values.toDate, "MM/DD/YYYY"))) {
                errors.fromDate = "From date must not be after To date";
                errors.toDate = "From date must not be after To date";
            }

            // if ((values.fromDate !== '' && values.fromDate !== '__/__/____' && !moment(values.fromDate, "MM/DD/YYYY", true).isValid()) && (values.toDate !== '' && values.toDate !== '__/__/____' && !moment(values.toDate, "MM/DD/YYYY", true).isValid()) && (values.fromTime && values.fromTime !== '__:__' && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(values.fromTime)) && (values.toTime && values.toTime !== '__:__' && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(values.toTime))) {

            //     if (moment(values.fromDate, "MM/DD/YYYY").isAfter(moment(values.toDate, "MM/DD/YYYY"))) {
            //         errors.fromDate = "From date must not be after To date";
            //         errors.toDate = "From date must not be after To date";
            //     }
            // }
            return errors;
        },
        onSubmit: (values) => {
            // if (!moment(values.fromDate, "MM/DD/YYYY", true).isValid()) {
            //     // errors.fromDate = 'Invalid date format';
            //     formik.setErrors({
            //         fromDate: "Invalid date format",
            //     });
            // } else {
            // }
        },
    });
    const handleInputChange = (e, n) => {
        if (n == "fromDate") {
            formik.setValues({
                ...formik.values,
                fromDate: e.target.value,
            });
        }
    };
    // useEffect(() => {
    //     filterClients([], [], formik.values.fromDate, formik.values.toDate, formik.values.fromTime, formik.values.toTime)
    // }, [formik.values])
    return (
        <div style={{ zIndex: '1000000000' }} className="">
            <Form
                autoComplete={"off"}
                noValidate
                onSubmit={formik.handleSubmit}
            >

                <div ref={dateTimeRef} className='dashboard-date-picker'>
                    <div className='d-flex custome-date-time-picker'>
                        <InputMask
                            id="from-date-custome-input"
                            mask="99/99/9999"
                            className={formik.errors.fromDate ?
                                "date-mask-input form-control b-r-10 " :
                                "date-mask-input form-control b-r-10"
                            }
                            // style={errorDate ? { border: '1px solid #dc3545 !important' } : { border: '1px solid #dc3545 !important' }}
                            onChange={(e) => {
                                formik.handleChange(e);
                                setCustomeDateTime((prevState) => {
                                    return {
                                        ...prevState,
                                        [e.target.name]: e.target.value,
                                        // fromTime,

                                    };
                                });
                                // if (e.target.value == '__/__/____') {
                                //     filterClients(selectIds, selectedFacilityIds, null, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)
                                //     setCustomeDateTime({
                                //         ...customeDateTime,
                                //         fromDate: null,
                                //     })
                                // }
                            }}
                            onFocus={(e) => {
                                if (customeDateTime.fromDate && customeDateTime.fromDate.length === 10) {
                                    const inputElement = document.getElementById('from-date-custome-input'); // Replace 'your-input-id' with the actual ID of your input element
                                    inputElement.select();
                                }
                            }}
                            // value={customeDateTime.initialfromDate}
                            inputRef={fromDateRef}

                            name="fromDate"

                            placeholder={"mm/dd/yyyy"}
                            value={formik.values.fromDate}
                            // onPaste={(e) => {
                            //     console.log('onPasteonPasteonPasteonPasteonPaste={(e) => {')
                            //     if (isCopieValue) {
                            //         // console.log("curentDHALOOOOOOOateTime", e.target.value, curentDateTime[0], "HALOOOOOOOOOOOOOOOOIIIIIII", !isNaN(new Date(curentDateTime[0])), new Date(curentDateTime[0]))
                            //         const curentDateTime = isCopieValue.split(" ")
                            //         const value = curentDateTime[1] ? curentDateTime[1].replace("\r\n", "") : customeDateTime.fromTime
                            //         const ToDate = !isNaN(new Date(curentDateTime[0])) ? new Date(curentDateTime[0]) : null
                            //         // document.getElementById('to-time-current').setAttribute('value', "10:00")

                            //         // setCustomeDateTime({
                            //         //     ...customeDateTime,
                            //         //     // toDate: ToDate,
                            //         //     toTime: value,
                            //         // })
                            //         setCustomeDateTime((prevState) => {
                            //             return {
                            //                 ...prevState,
                            //                 fromTime: value,

                            //             }
                            //         })
                            //         // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, ToDate, customeDateTime.fromTime, value)
                            //     }

                            // }}
                            // onBeforeInput={(e) => {
                            //     console.log("curentDHALOOOOOOOateTime", e.target.value, "HALOOOOOOOOOOOOOOOOIIIIIII")
                            //     const curentDateTime = e.data.split(" ")
                            //     const value = curentDateTime[1] ? curentDateTime[1].replace("\r\n", "") : customeDateTime.fromTime
                            //     const FromDate = !isNaN(new Date(curentDateTime[0])) ? new Date(curentDateTime[0]) : null
                            //     if (value != '__:__' && value != '') {
                            //         // document.getElementById('from-time-current').value = '12:22'

                            //     }
                            //     setCustomeDateTime({
                            //         ...customeDateTime,
                            //         fromDate: FromDate,
                            //         fromTime: value,
                            //     })
                            //     filterClients(selectIds, selectedFacilityIds, FromDate, customeDateTime.toDate, value, customeDateTime.toTime)
                            // }}
                            onBeforeInput={(e) => {

                                const curentDateTime = e.data.split(" ")
                                let value = curentDateTime[1] ? curentDateTime[1].replace("\r\n", "") : customeDateTime.fromTime
                                // const FromDate = !isNaN(new Date(curentDateTime[0])) ? new Date(curentDateTime[0]) : null
                                // console.log("curentDHALOOOOOOOateTime", e.target.value, curentDateTime[0], "HALOOOOOOOOOOOOOOOOIIIIIII", !isNaN(new Date(curentDateTime[0])), new Date(curentDateTime[0]))
                                if (value.includes('\n')) {
                                    // Remove \n if it exists
                                    value = value.replace(/\n/g, '');
                                }
                                setCustomeDateTime({
                                    ...customeDateTime,
                                    // fromDate: curentDateTime[0],
                                    fromTime: value,
                                })
                                formik.setValues({
                                    ...formik.values,
                                    // fromDate: curentDateTime[0],
                                    fromTime: value,
                                })
                                // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, ToDate, customeDateTime.fromTime, value)
                            }}
                        />
                        <div className='time-mask-input-container' >
                            <InputMask
                                mask="99:99"
                                id='from-time-current'

                                className={formik.errors.fromTime ?
                                    "time-mask-input form-control b-r-10 " :
                                    "time-mask-input form-control b-r-10"
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setCustomeDateTime((prevState) => {
                                        return {
                                            ...prevState,
                                            [e.target.name]: e.target.value,
                                            // fromTime,

                                        };
                                    });

                                }}
                                // isInvalid={customeDateTime.toDateInValid}
                                name="fromTime"
                                value={formik.values.fromTime}
                                placeholder={"HH:MM"}
                            />
                            {/* <input
                                    id='from-time-current'
                                    className="time-mask-input form-control b-r-10"
                                    onChange={(e) => {
                                        handleCustomTimeChange(e, "fromTime");
                                    }}
                                    name="fromTime"
                                    value={customeDateTime.fromTime}
                                    placeholder={"HH:MM"}
                                /> */}
                        </div>
                    </div>
                    <div className='mt-1 mb-2 d-flex  custome-date-time-picker'>
                        <InputMask
                            mask="99/99/9999"
                            id="to-date-custome-input"

                            className={formik.errors.toDate ?
                                "date-mask-input form-control b-r-10 " :
                                "date-mask-input form-control b-r-10"
                            }
                            onChange={(e) => {
                                formik.handleChange(e);
                                setCustomeDateTime((prevState) => {
                                    return {
                                        ...prevState,
                                        [e.target.name]: e.target.value,
                                        // fromTime,

                                    };
                                });
                                // console.log(isCopie, 'onChange={(e) => {')
                                // if (e.target.value == '__/__/____') {
                                //     filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, null, customeDateTime.fromTime, customeDateTime.toTime)
                                //     setCustomeDateTime({
                                //         ...customeDateTime,
                                //         toDate: null,
                                //     })
                                // }
                            }}
                            onFocus={(e) => {
                                if (customeDateTime.toDate && customeDateTime.toDate.length === 10) {
                                    const inputElement = document.getElementById('to-date-custome-input'); // Replace 'your-input-id' with the actual ID of your input element
                                    inputElement.select();
                                }
                            }}
                            // onPaste={(e) => {
                            //     console.log('onPasteonPasteonPasteonPasteonPaste={(e) => {')
                            //     if (isCopieValue) {
                            //         // console.log("curentDHALOOOOOOOateTime", e.target.value, curentDateTime[0], "HALOOOOOOOOOOOOOOOOIIIIIII", !isNaN(new Date(curentDateTime[0])), new Date(curentDateTime[0]))
                            //         const curentDateTime = isCopieValue.split(" ")
                            //         const value = curentDateTime[1] ? curentDateTime[1].replace("\r\n", "") : customeDateTime.toTime
                            //         const ToDate = !isNaN(new Date(curentDateTime[0])) ? new Date(curentDateTime[0]) : null
                            //         // document.getElementById('to-time-current').setAttribute('value', "10:00")

                            //         // setCustomeDateTime({
                            //         //     ...customeDateTime,
                            //         //     // toDate: ToDate,
                            //         //     toTime: value,
                            //         // })
                            //         setCustomeDateTime((prevState) => {
                            //             return {
                            //                 ...prevState,
                            //                 toTime: value,

                            //             }
                            //         })
                            //         // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, ToDate, customeDateTime.fromTime, value)
                            //     }

                            // }}
                            onBeforeInput={(e) => {

                                const curentDateTime = e.data.split(" ")
                                let value = curentDateTime[1] ? curentDateTime[1].replace("\r\n", "") : customeDateTime.toTime
                                if (value.includes('\n')) {
                                    // Remove \n if it exists
                                    value = value.replace(/\n/g, '');
                                }
                                setCustomeDateTime({
                                    ...customeDateTime,
                                    // toDate: ToDate,
                                    toTime: value,
                                })
                                formik.setValues({
                                    ...formik.values,
                                    // toDate: ToDate,
                                    toTime: value,
                                })
                                // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, ToDate, customeDateTime.fromTime, value)
                            }}
                            value={customeDateTime.toDate}
                            name="toDate"

                            placeholder={"mm/dd/yyyy"}
                        />
                        <div className='time-mask-input-container'>
                            <InputMask
                                mask="99:99"
                                id='to-time-current'

                                className={formik.errors.toTime ?
                                    "time-mask-input form-control b-r-10 " :
                                    "time-mask-input form-control b-r-10"
                                }
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setCustomeDateTime((prevState) => {
                                        return {
                                            ...prevState,
                                            [e.target.name]: e.target.value,
                                            // fromTime,

                                        };
                                    });
                                }}
                                // isInvalid={!!formik.errors?.checkDate}
                                name="toTime"
                                value={formik.values.toTime}

                                placeholder={"HH:MM"}
                            />

                        </div>
                    </div>
                </div>
            </Form>
        </div>
    )
}
export default DateMaskInput