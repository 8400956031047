import React, { useEffect, useState } from 'react'
import Board, { moveCard } from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import { Button, Card, Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import Drag from './snippet/drag'
import { createOrUpdateSnippet } from '../../../../../Redux/client/action';
import { changeAllTitleFunction } from '../../../Dashboard/functions';
import { CLIENT } from '../../../../../routeNames';
import * as Yup from 'yup';

const Order = ({ snippet, client, fetchClientDetails, __toggleToast, setLoading, client_data }) => {
  const [isSubmit, setIsSubmit] = useState(false)
  const [update, setUpdate] = useState(false)
  const board = {
    columns: [
      {
        id: 1,
        title: "Sections",
        cards: [
          {
            id: 1,
            content: "Check",
          },
          {
            id: 2,
            content: "Payer",
          },
          {
            id: 3,
            content: "Payee",
          },
          {
            id: 4,
            content: "Claim Header"
          },
          {
            id: 5,
            content: "Claim",
          },
          {
            id: 6,
            content: "Claim Footer",
          }
        ]

      },
      {
        id: 2,
        title: "Selections",
        cards: [

        ]
      },


    ]
  }
  useEffect(() => {
    if (snippet) {
      let output_file_type = snippet?.output_file_type
      let pdf_tiff_type = ""
      if (output_file_type.includes("TIFF")) {
        pdf_tiff_type = "TIFF"
        output_file_type = output_file_type.replace("_TIFF", "");
      } else {
        pdf_tiff_type = "PDF"
        output_file_type = output_file_type.replace("_PDF", "");
      }
      console.log(snippet, 'output_file_type')
      formik.setValues({
        ...formik.values,
        zip_file_naming_format: snippet?.zip_file_naming_format,
        output_file_type: output_file_type,
        is_incomplete_file_snippet: snippet?.is_incomplete_file_snippet,
        pdf_tiff_type: pdf_tiff_type,
        eob835: {
          ...formik.values.eob835,
          is_snippet: snippet?.is_eob_snippet_enabled ?? false,
          color: snippet?.eob_gray_out_color ?? '#cccccc',
          inverse: snippet?.eob_gray_out_invert ?? false,
          order: snippet?.eob_claim_wise ? snippet?.eob_order_header ?? [] : snippet?.eob_draggable_menu_order ?? [],
          claim_order: snippet?.eob_order_footer ? snippet?.eob_order_footer : [],
          static_order: snippet?.eob_order_static ? snippet?.eob_order_static : [],
          file_naming_format: snippet?.eob_file_naming_format ?? '',
          file_path: snippet?.eob_file_directory ?? '',
          board: snippet?.eob_board && snippet?.eob_board != '' ? snippet?.eob_board : board,
          masking: snippet?.eob_masking ?? false,
          claim_wise: snippet?.eob_claim_wise ?? false,
          group_static: snippet?.eob_group_static ?? false,

        },
        eob_lite: {
          ...formik.values.eob_lite,
          is_snippet: snippet?.is_eob_lite_snippet_enabled ?? false,
          color: snippet?.eob_lite_gray_out_color ?? '#cccccc',
          inverse: snippet?.eob_lite_gray_out_invert ?? false,
          order: snippet?.eob_lite_draggable_menu_order ?? [],
          file_naming_format: snippet?.eob_lite_file_naming_format ?? '',
          file_path: snippet?.eob_lite_file_directory ?? '',
          board: snippet?.eob_lite_board && snippet?.eob_lite_board != '' ? snippet?.eob_lite_board : board,
          masking: snippet?.eob_lite_masking ?? false,
        },
        Patient_pay: {
          ...formik.values.Patient_pay,
          is_snippet: snippet?.is_patient_pay_snippet_enabled ?? false,
          color: snippet?.patient_pay_gray_out_color ?? '#cccccc',
          inverse: snippet?.patient_pay_gray_out_invert ?? false,
          order: snippet?.patient_pay_draggable_menu_order ?? [],
          file_naming_format: snippet?.patient_pay_file_naming_format ?? '',
          file_path: snippet?.patient_pay_file_directory ?? '',
          board: snippet?.patient_pay_board && snippet?.patient_pay_board != '' ? snippet?.patient_pay_board : board,
          masking: snippet?.patient_pay_masking ?? false,
        },
        correspondance: {
          ...formik.values.correspondance,
          is_snippet: snippet?.is_correspondance_snippet_enabled ?? false,
          color: snippet?.correspondance_gray_out_color ?? '#cccccc',
          inverse: snippet?.correspondance_gray_out_invert ?? false,
          order: snippet?.correspondance_draggable_menu_order ?? [],
          file_naming_format: snippet?.correspondance_file_naming_format ?? '',
          file_path: snippet?.correspondance_file_directory ?? '',
          board: snippet?.correspondance_board && snippet?.correspondance_board ? snippet?.correspondance_board : board,
          masking: snippet?.correspondance_masking ?? false,
        },

      })
    }

  }, [snippet, client]);

  const formik = useFormik({
    initialValues: {
      zip_file_naming_format: '',
      output_file_type: '',
      pdf_tiff_type: "PDF",
      is_incomplete_file_snippet: false,
      eob835: {
        name: 'EOB',
        is_snippet: false,
        color: '#cccccc',
        inverse: false,
        order: [],
        claim_order: [],
        static_order: [],
        file_naming_format: '',
        file_path: '',
        board: board,
        masking: false,
        claim_wise: false,
        group_static: false
      },
      eob_lite: {
        name: 'EOB Lite',
        is_snippet: false,
        color: '#cccccc',
        inverse: false,
        order: [],
        file_naming_format: '',
        file_path: '',
        board: board,
        masking: false
      },
      Patient_pay: {
        name: 'Patient Pay',
        is_snippet: false,
        color: '#cccccc',
        inverse: false,
        order: [],
        file_naming_format: '',
        file_path: '',
        board: board,
        masking: false
      },
      correspondance: {
        name: 'Corres',
        is_snippet: false,
        color: '#cccccc',
        inverse: false,
        order: [],
        file_naming_format: '',
        file_path: '',
        board: board,
        masking: false
      },

    },
    validationSchema: Yup.object().shape({
      zip_file_naming_format: Yup.string().required('Required'),
      output_file_type: Yup.string().required('Required'),
    }),
    validate: (values) => {
      let errors = {};

      // Validate each section if the corresponding boolean flag is true
      if (values?.eob835?.is_snippet) {
        if (values?.eob835.file_naming_format === "") {
          errors.eob835 = { file_naming_format: "Required" };
        }
        if (values.eob835?.file_path === "") {
          errors.eob835 = { ...errors.eob835, file_path: "Required" };
        }
      }

      if (values?.eob_lite?.is_snippet) {
        if (values?.eob_lite.file_naming_format === "") {
          errors.eob_lite = { file_naming_format: "Required" };
        }
        if (values.eob_lite?.file_path === "") {
          errors.eob_lite = { ...errors.eob_lite, file_path: "Required" };
        }
      }

      if (values?.Patient_pay?.is_snippet) {
        if (values?.Patient_pay.file_naming_format === "") {
          errors.Patient_pay = { file_naming_format: "Required" };
        }
        if (values.Patient_pay?.file_path === "") {
          errors.Patient_pay = { ...errors.Patient_pay, file_path: "Required" };
        }
      }

      if (values?.correspondance?.is_snippet) {
        if (values?.correspondance.file_naming_format === "") {
          errors.correspondance = { file_naming_format: "Required" };
        }
        if (values.correspondance?.file_path === "") {
          errors.correspondance = { ...errors.correspondance, file_path: "Required" };
        }
      }

      // If there are no errors, return an empty object
      if (Object.keys(errors).length === 0) {
        errors = {};
      }

      return errors;
    },

    onSubmit: (values, { setSubmitting }) => {
      setIsSubmit(true)
      setLoading(true)

      let formData = {};
      formData.client = !client == 0 ? client : "";
      if (snippet?.id)
        formData.id = snippet?.id;

      formData.zip_file_naming_format = values.zip_file_naming_format
      formData.output_file_type = `${values.output_file_type}_${values.pdf_tiff_type}`
      formData.is_incomplete_file_snippet = values.is_incomplete_file_snippet

      formData.is_eob_snippet_enabled = values?.eob835?.is_snippet;
      formData.eob_file_naming_format = values?.eob835?.file_naming_format;
      formData.eob_gray_out_invert = values?.eob835?.inverse;
      formData.eob_gray_out_color = values?.eob835?.color

      formData.eob_board = values?.eob835?.board
      formData.eob_masking = values?.eob835?.masking
      formData.eob_file_directory = values?.eob835?.file_path
      formData.eob_claim_wise = values?.eob835?.claim_wise
      formData.eob_group_static = values?.eob835?.group_static ? true : false
      if (values?.eob835?.claim_wise) {
        formData.eob_order_footer = values?.eob835?.claim_order
        formData.eob_order_header = values?.eob835?.order
        formData.eob_order_static = values?.eob835?.static_order
      }
      else {
        formData.eob_order_static = ''
        formData.eob_order_footer = ''
        formData.eob_draggable_menu_order = values?.eob835?.order
      }


      formData.is_eob_lite_snippet_enabled = values?.eob_lite?.is_snippet;
      formData.eob_lite_file_naming_format = values?.eob_lite?.file_naming_format;
      formData.eob_lite_gray_out_invert = values?.eob_lite?.inverse;
      formData.eob_lite_gray_out_color = values?.eob_lite?.color
      formData.eob_lite_draggable_menu_order = values?.eob_lite?.order
      formData.eob_lite_board = values?.eob_lite?.board
      formData.eob_lite_masking = values?.eob_lite?.masking
      formData.eob_lite_file_directory = values?.eob_lite?.file_path


      formData.is_patient_pay_snippet_enabled = values?.Patient_pay?.is_snippet;
      formData.patient_pay_file_naming_format = values?.Patient_pay?.file_naming_format;
      formData.patient_pay_gray_out_invert = values?.Patient_pay?.inverse;
      formData.patient_pay_gray_out_color = values?.Patient_pay?.color
      formData.patient_pay_draggable_menu_order = values?.Patient_pay?.order
      formData.patient_pay_board = values?.Patient_pay?.board
      formData.patient_pay_masking = values?.Patient_pay?.masking
      formData.patient_pay_file_directory = values?.Patient_pay?.file_path


      formData.is_correspondance_snippet_enabled = values?.correspondance?.is_snippet;
      formData.correspondance_file_naming_format = values?.correspondance?.file_naming_format;
      formData.correspondance_gray_out_invert = values?.correspondance?.inverse;
      formData.correspondance_gray_out_color = values?.correspondance?.color
      formData.correspondance_draggable_menu_order = values?.correspondance?.order
      formData.correspondance_board = values?.correspondance?.board
      formData.correspondance_masking = values?.correspondance?.masking
      formData.correspondance_file_directory = values?.correspondance?.file_path

      createOrUpdateSnippet({ formData })((response) => {
        if (!response?.data?.status && response?.status && response?.status_code === 200) {
          setIsSubmit(false)
          setLoading(false)
          __toggleToast("success", response?.message, true)
          fetchClientDetails()

        } else {
          setIsSubmit(false)
          setLoading(false)
          __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
        }
      });
    },
  });

  const handleSwitchChange = async (e, type) => {
    if (e.target.name == 'claim_wise' && type == 'eob835') {
      let column = board
      if (e.target.checked) {
        if (column?.columns?.length == 2) {
          column.columns.push(
            {
              id: 2,
              title: "Header",
              cards: [

              ]
            },
            {
              id: 3,
              title: "Footer",
              cards: [

              ]
            },
          )
          column.columns[0].cards.splice(4, 1)
          column.columns[1].title = 'Static'
          column.columns[1].id = 4
        }
      }
      formik.setValues({
        ...formik.values,
        [type]: {
          ...formik.values[type],
          [e.target.name]: e.target.checked,
          board: column,
          order: [],
          claim_order: [],

        }
      })
    }
    else if (e.target.name == 'group_static' && type == 'eob835') {
      formik.setValues({
        ...formik.values,
        [type]: {
          ...formik.values[type],
          [e.target.name]: e.target.checked,
        }
      })
    }
    else if (e.target.name == 'masking') {
      formik.setValues({
        ...formik.values,
        [type]: {
          ...formik.values[type],
          [e.target.name]: e.target.checked,
          claim_wise: false,
          group_static: false,
          inverse: false,
          color: '#cccccc',
          order: [],
          claim_order: [],
          board: board,

        }
      })
    }
    else if (e.target.name == 'is_snippet') {

      formik.setValues({
        ...formik.values,
        [type]: {
          ...formik.values[type],
          [e.target.name]: e.target.checked,
          color: '#cccccc',
          inverse: false,
          order: [],
          claim_order: [],
          file_naming_format: '',
          file_path: '',
          board: board,
          masking: false,
          claim_wise: false,
          group_static: false,

        }
      })
    }
    else {
      formik.setValues({
        ...formik.values,
        [type]: { ...formik.values[type], [e.target.name]: e.target.checked }
      })
    }
  }
  const handleDrag = (card, source, destination, type) => {
    if (destination?.toColumnId == 1) {

      let filtered = formik.values[type].board?.columns?.[0]?.cards.filter(item => item.id != card.id)
      let index = filtered?.findIndex(item => item.id > card.id);
      if (index < 0) {
        index = filtered?.length
      }
      destination.toPosition = index;
      const updatedBoard = moveCard(formik.values[type].board, source, destination);
      formik.setValues({ ...formik.values, [type]: { ...formik.values[type], order: updatedBoard?.columns?.[1]?.cards, board: updatedBoard } })
    }
    else {
      const updatedBoard = moveCard(formik.values[type].board, source, destination);
      if (type == 'eob835') {
        if (formik.values.eob835.claim_wise && updatedBoard?.columns?.length == 4) {
          formik.setValues({ ...formik.values, [type]: { ...formik.values[type], order: updatedBoard?.columns?.[2]?.cards, claim_order: updatedBoard?.columns?.[3]?.cards, static_order: updatedBoard?.columns?.[1]?.cards, board: updatedBoard } })
        }
        else {
          formik.setValues({ ...formik.values, [type]: { ...formik.values[type], order: updatedBoard?.columns?.[1]?.cards, claim_order: [], static_order: [], board: updatedBoard } })
        }

      }
      else {
        formik.setValues({ ...formik.values, [type]: { ...formik.values[type], order: updatedBoard?.columns?.[1]?.cards, board: updatedBoard } })
      }
    }
  }
  const addReportFileFormat = (value, name, type) => {

    formik.setValues({
      ...formik.values,
      [type]: { ...formik.values[type], file_naming_format: formik.values[type]?.[[name]] + value },
    })
  }
  const addZipFileFormat = (value) => {

    formik.setValues({
      ...formik.values,
      zip_file_naming_format: formik.values.zip_file_naming_format + value,
    })
  }
  const onChangefileType = (e) => {
    formik.setValues({
      ...formik.values,
      output_file_type: e.target.value,
      eob835: {
        ...formik.values.eob835,
        // claim_wise: false,
        // group_static: false,
        // board: board,
        // order: [],
        // claim_order: [],
        masking: !["MULTI_PAGE_PDF", "MULTI_PAGE_TIFF", "MULTI_FILE_PDF", "MULTI_FILE_TIFF"].includes(`${formik.values?.output_file_type}_${formik.values?.pdf_tiff_type}`) ? false : formik.values.eob835.masking

      },
      eob_lite: {
        ...formik.values.eob_lite,
        masking: !["MULTI_PAGE_PDF", "MULTI_PAGE_TIFF", "MULTI_FILE_PDF", "MULTI_FILE_TIFF"].includes(`${formik.values?.output_file_type}_${formik.values?.pdf_tiff_type}`) ? false : formik.values.eob_lite.masking

      },
      Patient_pay: {
        ...formik.values.Patient_pay,
        masking: !["MULTI_PAGE_PDF", "MULTI_PAGE_TIFF", "MULTI_FILE_PDF", "MULTI_FILE_TIFF"].includes(`${formik.values?.output_file_type}_${formik.values?.pdf_tiff_type}`) ? false : formik.values.eob835.masking

      },
      correspondance: {
        ...formik.values.correspondance,
        masking: !["MULTI_PAGE_PDF", "MULTI_PAGE_TIFF", "MULTI_FILE_PDF", "MULTI_FILE_TIFF"].includes(`${formik.values?.output_file_type}_${formik.values?.pdf_tiff_type}`) ? false : formik.values.eob835.masking

      }

    })
  }
  console.log(formik.values, 'formik.errors')
  return (
    <div>

      <Form onSubmit={formik.handleSubmit} >
        <div className='row'>
          <div className='col-lg-6'>
            <Form.Group className="" >
              <div className='d-flex flex-row align-items-center'>
                <Form.Label className='fw-bold'>Zip File Naming Formats</Form.Label>
                <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addZipFileFormat('[Client ID]')}>Client ID</Button>{' '}
                <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addZipFileFormat('[Client Name]')}>Client Name</Button>{' '}
                <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addZipFileFormat('[Date]')}>Date</Button>{' '}
                <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addZipFileFormat('[LockBox File Name]')}>LockBox File Name</Button>{' '}
                <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addZipFileFormat('[Transaction Type]')}>Transaction Type</Button>{' '}
              </div>

              <Form.Control
                name='zip_file_naming_format'
                type="text"
                autoComplete="false"
                className='b-r-10 form-control-solid'
                maxLength="255"
                placeholder="Zip File Naming Formats"
                isValid={formik.errors?.zip_file_naming_format && !!formik.errors?.zip_file_naming_format}
                isInvalid={!!formik.errors?.zip_file_naming_format}
                value={formik.values?.zip_file_naming_format}
                onChange={formik.handleChange}
              />
              <Form.Control.Feedback type="invalid">{formik.errors?.zip_file_naming_format}</Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className='col-lg-2'>
            <Form.Group className="mb-3" >

              <Form.Label className='fw-bold'>Output File Type</Form.Label>
              <Form.Control as="select"
                name='output_file_type'
                className='b-r-10 form-control-solid form-control'
                maxLength="255"
                value={formik.values?.output_file_type}
                onChange={onChangefileType}
                isValid={formik.errors?.output_file_type && !!formik.errors?.output_file_type}
                isInvalid={!!formik.errors?.output_file_type}
              >
                <option value="">Select an Option</option>
                {/* {formik.values?.pdf_tiff_type == "TIFF" && <option value="SINGLE_PAGE_TIFF">Single Page</option>}
                {formik.values?.pdf_tiff_type == "TIFF" && <option value="MULTI_PAGE_TIFF">Multi Page</option>}
                {formik.values?.pdf_tiff_type == "TIFF" && <option value="MULTI_FILE_TIFF">Multi File</option>}
                {formik.values?.pdf_tiff_type == "PDF" && <option value="SINGLE_PAGE_PDF" >Single Page</option>}
                {formik.values?.pdf_tiff_type == "PDF" && <option value="MULTI_PAGE_PDF">Multi Page</option>}
                {formik.values?.pdf_tiff_type == "PDF" && <option value="MULTI_FILE_PDF">Multi File</option>} */}
                <option value="SINGLE_PAGE">Single Page</option>
                <option value="MULTI_PAGE">Multi Page</option>
                <option value="MULTI_FILE">Multi File</option>

              </Form.Control>
              <Form.Control.Feedback type="invalid">{formik?.errors?.output_file_type}</Form.Control.Feedback>

            </Form.Group>
          </div>
          <div className='col-lg-1'>
            <Form.Group className="mb-3" >

              <Form.Label className='fw-bold'>PDF/TIFF</Form.Label>
              <Form.Control as="select"
                name='pdf_tiff_type'
                className='b-r-10 form-control-solid form-control'
                maxLength="255"
                value={formik.values?.pdf_tiff_type}
                onChange={(e) => {
                  formik.setValues({
                    ...formik.values,
                    pdf_tiff_type: e.target.value,
                  })
                }}
                isValid={formik.errors?.pdf_tiff_type && !!formik.errors?.pdf_tiff_type}
                isInvalid={!!formik.errors?.pdf_tiff_type}
              >
                {/* <option value="">Select an Option</option> */}
                <option value="PDF">PDF</option>
                <option value="TIFF">TIFF</option>



              </Form.Control>
              <Form.Control.Feedback type="invalid">{formik?.errors?.pdf_tiff_type}</Form.Control.Feedback>

            </Form.Group>
          </div>

          <div className="col-lg-3 mt-4">
            <Form.Check
              as="input"
              inline
              type="switch"
              reverse={true}
              onChange={(e) => formik.setFieldValue('is_incomplete_file_snippet', e.target.checked)}
              checked={formik.values?.is_incomplete_file_snippet}
              isInvalid={
                formik.errors?.is_incomplete_file_snippet &&
                !!formik.errors?.is_incomplete_file_snippet
              }

              feedbackType="invalid"

              feedback={
                formik.errors?.is_incomplete_file_snippet &&
                formik.errors?.is_incomplete_file_snippet
              }
              id="is_incomplete_file_snippet"
              name="is_incomplete_file_snippet"
              label="Snipping For Incomplete Job"
            />
          </div>
        </div>
        <hr className='custom-hr' />
        <Drag type={'eob835'} errors={formik.errors.eob835} formik={formik} values={formik.values.eob835} handleDrag={handleDrag} handleSwitchChange={handleSwitchChange} addReportFileFormat={addReportFileFormat} />
        <Drag type={'eob_lite'} errors={formik.errors.eob_lite} formik={formik} values={formik.values.eob_lite} handleDrag={handleDrag} handleSwitchChange={handleSwitchChange} addReportFileFormat={addReportFileFormat} />
        <Drag type={'Patient_pay'} errors={formik.errors.Patient_pay} formik={formik} values={formik.values.Patient_pay} handleDrag={handleDrag} handleSwitchChange={handleSwitchChange} addReportFileFormat={addReportFileFormat} />
        <Drag type={'correspondance'} errors={formik.errors.correspondance} formik={formik} values={formik.values.correspondance} handleDrag={handleDrag} handleSwitchChange={handleSwitchChange} addReportFileFormat={addReportFileFormat} />





        <div className='d-flex flex-row justify-content-end pt-3'>
          <div className=''>
            <button
              type='button'
              className='btn btn-secondary btn-active-primary me-2'
              onClick={() => { changeAllTitleFunction('Client Setup', CLIENT) }}
            >
              Cancel
            </button>
          </div>
          {snippet ? (

            <div className=''>
              <button
                disabled={!formik.isValid || isSubmit}
                type='submit'
                className='btn btn-primary btnHover'
              >
                {isSubmit ? "Updating" : "Update"}
              </button>
            </div>
          ) : (
            <div className=''>
              <button
                disabled={!formik.isValid || isSubmit}
                type='submit'
                className='btn btn-primary btnHover'
              >
                {isSubmit ? "Saving" : "Save"}
              </button>
            </div>
          )}

        </div>
      </Form>
    </div>
  )
}

export default Order