import "../../../../assets/css/filesplit.css";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../../Elements/Header";
import styled from "styled-components/macro";
import { FiZoomIn, FiZoomOut } from "react-icons/fi";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import { MdDeleteSweep } from "react-icons/md";

import { Tooltip } from '@mui/material';
import { FaArrowRotateLeft, FaArrowRotateRight, FaArrowLeft } from "react-icons/fa6";
import * as Yup from "yup";

import { changeAllTitleFunction, getRedirectTabId } from "../../Dashboard/functions";
import { getSingleFile, postManualSplitFile, releaseSplitFile } from "../../../../Redux/fileSplit/action";
import { useParams } from "react-router-dom";
import FileSPlitSkeleton from "../../Snippet/Component/FileSPlitSkeleton";
import { FaList } from "react-icons/fa";
import { useFormik } from "formik";
import moment from "moment";
import DragContainer from "./drag";
import Draggable from "react-draggable";
import { Form, OverlayTrigger } from "react-bootstrap";
import InputMask from "react-input-mask";
import { numberRange } from "./functions";
import SweetAlert from "react-bootstrap-sweetalert";
import SPlitListSkeleton from "../../Snippet/Component/SPlitListSkeleton";
import LargeBatchSPlitSkeleton from "../../Snippet/Component/LargeBatchSPlitSkeleton";
import { minValidDate } from "../../DataEntry/Grid/gridFunctions";


export default function PdfViewer(props) {
    const { file_id } = useParams();
    const [initialWidthValue, setInitialWidthValue] = useState(100);

    // =============STATE============
    const [isResize, setIsResize] = useState(false)
    const [leftWidth, setLeftWidth] = useState(20); // initial left width

    const [state, setState] = useState({
        isCheck: false,
        isCommonPage: false,
        isHeaderPage: false,

        multyTransaction: false,
        autoManual: 1,
        autoPage: "",

        checkPageIndex: "",
        commonPage: [],
    });
    const [chekDetail, setChekDetail] = useState({
        accountNumber: "",
        checkDate: "",
        checkAmount: "",
        aba_routing_number: "",
        payer_account_number: "",
    });
    const [addedElemnts, setAddedElemnts] = useState({
        splitedFiles: [],
    });

    const [signleView, setSingleView] = useState({
        isOpen: false,
        id: null,
        index: null,
        curentCanvas: null,
    });
    const [groupOfPages, setGroupOfPages] = useState({
        fromIndex: null,
        toIndex: null,
    });
    const [errorAlert, setErrorAlert] = useState({
        status: false,
        message: null,
        type: null,

    });
    const [controlledPosition, setControlledPosition] = React.useState({
        x: 20,
        y: 15,
    });
    const [swalContent, setSwalContent] = useState({
        title: "",
        text: "",
        icon: "",
        onConfirm: null,
        onCancel: null,
        showCancel: false
    });
    const [isSwal, setIsSwal] = useState(false);
    const [selectedPages, setSelectedPages] = useState([]);
    const [selectedCheck, setSelectedCheck] = useState([]);
    const [tabSwich, setTabSwich] = useState(1);
    const [isEdit, setisEdit] = useState(false);
    const [isMultiCheckEdit, setIsMultiCheckEdit] = useState(false);
    const [isMultiCommonEdit, setIsMultiCommonEdit] = useState(false);
    const [isMultiHeaderEdit, setIsMultiHeaderEdit] = useState(false);
    const [isMultiNormalEdit, setIsMultiNormalEdit] = useState(false);

    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [canvasDetails, setCanvasDetails] = useState([]);
    const [singleImageLoader, setSingleImageLoader] = useState(false)
    const [singleZoomValue, setSingleZoomValue] = useState(100);
    const [zoomValue, setZoomValue] = useState(10);
    const [activePage, setActivePage] = useState([])
    const [goBackLoader, setGoBackLoader] = useState(false);

    const [singleData, setSingleData] = useState({});
    const [finaldata, setFinalData] = useState([]);
    const [totalpf, setTotalPdf] = useState(0);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [allpages, setAllPages] = useState([]);
    const [checkPopup, setCheckPopup] = useState(false);
    const [activecheckID, setActiveCheckID] = useState(null);
    const [togglePopup, setTogglePopup] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [automaticSplitLoader, setAutomaticSplitLoader] = useState(false);

    // ================Functions==============
    const formik = useFormik({
        initialValues: {
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        },
        validationSchema: Yup.object().shape({
            accountNumber: Yup.string()
                .required("Required")
                .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "enter valid check number"),
            checkDate: Yup.date().required("Required"),
            checkAmount: Yup.number().required("Required"),
            aba_routing_number: Yup.number().required("Required"),
            payer_account_number: Yup.string()
                .required("Required")
                .matches(/^[a-zA-Z0-9]+$/, "Enter a valid Payer Account Number"),
        }),
        validate: (values) => {
            let errors = {};

            if (!moment(values.checkDate, "MM/DD/YYYY", true).isValid() || !moment(values.checkDate, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                errors.checkDate = "Invalid date format";
            }
            return errors;
        },
        onSubmit: (values) => {
            if (!moment(values.checkDate, "MM/DD/YYYY", true).isValid() || !moment(values.checkDate, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                formik.setErrors({
                    checkDate: "Invalid date format",
                });
            } else {
                handleSubmitCheck();
            }
        },
    });
    function rotateBase64Image90deg(items, type) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.id = `capturedCanvas-${items.id}`;
                canvas.setAttribute("data-id", items.id);

                let rotateValue = (items.rotate - 90) % 360;
                if (type == "right") {
                    rotateValue = (items.rotate + 90) % 360;
                }

                if (rotateValue < 0) {
                    rotateValue += 360;
                }

                let width, height;
                if (Math.abs(rotateValue) === 90 || Math.abs(rotateValue) === 270) {
                    width = img.height;
                    height = img.width;
                } else {
                    width = img.width;
                    height = img.height;
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');

                ctx.translate(width / 2, height / 2);
                ctx.rotate(rotateValue * Math.PI / 180);
                ctx.drawImage(img, -img.width / 2, -img.height / 2);

                const rotatedImageData = {
                    ...items,
                    image: canvas.toDataURL("image/png", 1.0),
                    canvas: canvas,
                    rotate: rotateValue,
                    imageSize: {
                        width: canvas.width,
                        height: canvas.height
                    }
                };

                resolve(rotatedImageData);
            };

            img.src = items.original_image;
            img.onerror = (error) => reject(error);
        });
    }
    var count = 0;
    var trans_count = 0;
    const SingleSplitComponent = ({ id, index, item, isActive }) => {
        return (
            <SplitSingleDivBox id={id} isActive={isActive}>
                <div className="text-center d-flex flex-wrap">
                    {item.pages.map((j, ind) => {
                        // Check if it's not the last index in the array
                        const isNotLastIndex = ind !== item.pages.length - 1;
                        return (
                            <h5 className="ms-2" key={ind}>
                                {count += 1}
                                {isNotLastIndex && ","} {/* Render comma if not the last index */}
                            </h5>
                        );
                    })}
                </div>
                <div className="icons-list">
                    <FaEdit onClick={() => handleEditListedPdf(item, index)} className="me-2" />
                    <MdDeleteSweep onClick={(e) => { handleAutoRemoveListedPdf(item, e) }} />
                </div>
            </SplitSingleDivBox>
        );
    };

    const handleZoomIn = useCallback(() => {

        if (signleView.isOpen) {
            setSingleZoomValue((prevScale) => prevScale > 10 ? prevScale - 10 : 10);
        } else {
            setZoomValue((prevScale) => prevScale > 10 ? prevScale - 10 : 10);
        }

    }, [zoomValue, singleZoomValue, signleView]);

    const handleZoomOut = useCallback(() => {
        if (signleView.isOpen) {
            if (singleZoomValue < 100) {
                setSingleZoomValue((prevScale) => prevScale + 10);
            }
        } else {
            if (zoomValue < 100) {
                setZoomValue((prevScale) => prevScale + 10);
            }
        }


    }, [zoomValue, singleZoomValue, signleView]);
    const handleRotate = async (type, pageId = null) => {
        const copyAddedElements = [...addedElemnts.splitedFiles]
        const copyCanvasDetail = [...canvasDetails];
        if (pageId != null) {
            const pageIndex = copyCanvasDetail.findIndex(i => i.id == pageId)
            const rotatedImageData = await rotateBase64Image90deg(copyCanvasDetail[pageIndex], type);
            copyCanvasDetail[pageIndex] = rotatedImageData
            const elementToUpdate = copyAddedElements.find(i => i.index === pageIndex);
            // If the element is found, update its src property
            if (elementToUpdate) {
                elementToUpdate.src = rotatedImageData?.image;
                elementToUpdate.position = rotatedImageData?.rotate;
            }
            setAddedElemnts({ ...addedElemnts, splitedFiles: copyAddedElements })
            setCanvasDetails(copyCanvasDetail);

        } else {
            const selectedIndex = selectedPages.reduce((acc, value, index) => {
                if (value) {
                    acc.push(index);
                }
                return acc;
            }, []);
            if (selectedIndex.length > 0) {
                setSingleImageLoader(true);
                try {
                    const updatedCanvas = await Promise.all(copyCanvasDetail.map(async (canvasItem, ind) => {
                        if (selectedIndex?.some((i) => i == ind)) {
                            const rotatedImageData = await rotateBase64Image90deg(canvasItem, type);
                            // Find the element with the matching index
                            const elementToUpdate = copyAddedElements.find(i => i.index === ind);
                            // If the element is found, update its src property
                            if (elementToUpdate) {
                                elementToUpdate.src = rotatedImageData?.image;
                                elementToUpdate.position = rotatedImageData?.rotate;
                            }
                            return rotatedImageData;
                        } else {
                            return canvasItem;
                        }
                    }));
                    setAddedElemnts({ ...addedElemnts, splitedFiles: copyAddedElements })
                    setCanvasDetails(updatedCanvas);
                } catch (error) {
                    console.error("Error rotating images:", error);
                    // Handle error as needed, e.g., show error message to the user
                } finally {
                    setSingleImageLoader(false);
                }
            }
        }
    };
    const loadPdfFiles = (file_url) => {
        if (file_url) {
            function convertPDFtoImages() {
                return pdfjsLib.getDocument(file_url).promise.then((pdf) => {
                    const promises = [];
                    // Calculate scaling factor based on window dimensions and PDF dimensions
                    const windowWidth = window.innerWidth;
                    const windowHeight = window.innerHeight;
                    const fixedScale = 2.0; // Adjust this value based on your requirements

                    for (let i = 1; i <= pdf.numPages; i++) {
                        promises.push(
                            pdf.getPage(i).then((page) => {
                                const viewport = page.getViewport({ scale: fixedScale });

                                const canvas = document.createElement("canvas");
                                canvas.id = `capturedCanvas-${i}`;
                                canvas.setAttribute("data-id", i);
                                canvas.height = viewport.height;
                                canvas.width = viewport.width;
                                const context = canvas.getContext("2d");

                                const renderContext = {
                                    canvasContext: context,
                                    viewport: viewport,
                                };

                                return page.render(renderContext).promise.then(() => {
                                    return new Promise((resolve) => {
                                        canvas.toBlob((blob) => {
                                            const reader = new FileReader();
                                            reader.onloadend = () => {
                                                const base64data = reader.result;
                                                let isPageSizeSmall = false;
                                                const cal_width = viewport.width / 2
                                                // if ((cal_width + 100) >= viewport.height) {
                                                if (viewport.width >= (viewport.height + 200)) {
                                                    isPageSizeSmall = true
                                                }
                                                resolve({
                                                    page_size: isPageSizeSmall,
                                                    rotate: 0,
                                                    id: i,
                                                    canvas,
                                                    image: base64data,
                                                    original_image: base64data,
                                                    imageSize: {
                                                        width: viewport.width,
                                                        height: viewport.height,
                                                    },
                                                });
                                            };
                                            reader.readAsDataURL(blob);
                                        }, 'image/png'); // You can specify the image format here, e.g., 'image/jpeg'
                                    });
                                });
                            })
                        );
                    }
                    return Promise.all(promises);
                });
            }

            convertPDFtoImages()
                .then((canvasDetailsArray) => {
                    setSelectedPages(Array(canvasDetailsArray.length).fill(false))
                    setTotalPdf(canvasDetailsArray.length)
                    setCanvasDetails(canvasDetailsArray);
                    setPdfLoaded(false)
                })
                .catch((error) => {
                    console.error("Error during PDF conversion:", error);
                });
        }
    }
    const handleGoBack = () => {
        setGoBackLoader(true)
        releaseSplitFile(file_id)((response) => {
            if (response?.status && response?.status_code == 202) {
                const json_tabs = window.parent.localStorage.getItem('bottomTab')
                let tabs = JSON.parse(json_tabs);
                const priviousTab = tabs?.currentTab?.prevTabs[tabs?.currentTab?.prevTabs.length - 1]
                if (tabs?.currentTab?.prevTabs.length > 0 && priviousTab) {
                    window.parent.localStorage.setItem('isBackActive', 'true')

                    const baseUrl = window.location.origin
                    const previousURL = priviousTab?.link.replace(baseUrl, "");
                    const previousLabel = priviousTab?.label;
                    changeAllTitleFunction(previousLabel, previousURL)
                } else {
                    window.parent.localStorage.setItem('isFileSplitBackActive', 'true')
                    changeAllTitleFunction("File Split", "/view/file-split")

                }
                setGoBackLoader(false)

            }
        });
    };
    const showTransactionTab = (is_auto = false) => {
        const checkAccordion = document.getElementById("collapseOne");
        const commonAccordion = document.getElementById("collapseTwo");
        const headerAccordion = document.getElementById("collapseFive");
        const normalAccordion = document.getElementById("collapseThree");
        if (
            (state.multyTransaction &&
                !checkAccordion?.classList?.contains("show") &&
                !commonAccordion?.classList?.contains("show") &&
                !headerAccordion?.classList?.contains("show") &&
                !normalAccordion?.classList?.contains("show")) ||
            (state.multyTransaction &&
                is_auto &&
                !normalAccordion?.classList?.contains("show"))
        ) {
            const headingThreeBtn = document.querySelector("#headingThree > button");
            headingThreeBtn.click();
        }
    };
    function swapDiv(ind, is_remove = false) {
        const checkAccordion = document.getElementById("collapseOne");
        const commonAccordion = document.getElementById("collapseTwo");
        const headerAccordion = document.getElementById("collapseFive");
        const normalAccordion = document.getElementById("collapseThree");

        const isMultiCheck = checkAccordion?.classList?.contains("show");
        const isMultiCommon = commonAccordion?.classList?.contains("show");
        const isMultiHeader = headerAccordion?.classList?.contains("show");
        const isMultiNormal = normalAccordion?.classList?.contains("show");





        const editCheckOnCheckBox =
            !isMultiCheckEdit &&
            hasMultyTransactionCheck &&
            isMultiCheck;

        const editNormalOnCheckBox =
            isMultiNormalEdit &&
            hasMultyTransactionNormal &&
            isMultiNormal &&
            selectedPages.includes(true);

        setTabSwich(1);
        // handleChoosePage(ind)
        // const pdfItems = document.getElementsByClassName(
        //     "rpv-thumbnail__container"
        // );
        const img_src = canvasDetails[ind]?.image;
        // Array.from(pdfItems).map((i) => {
        //     if (i.getAttribute("data-testid") === `thumbnail__container-${ind}`) {
        //         img_src = i.firstChild.getAttribute("src");
        //         return img_src;
        //     }
        // });

        let splitedFiles = [...addedElemnts.splitedFiles];
        if (
            (state.multyTransaction && isMultiCheckEdit) ||
            (state.multyTransaction && isMultiCheck)
        ) {
            if (is_remove) {
                handleChoosePage(ind);
                if (finaldata.some((e) => Number(e.check) == ind)) {
                    // setFinalData(finaldata.filter((i) => i.check !== ind));
                    let arr = allpages.filter((i) => i != ind);
                    setAllPages(arr);
                    handleClearCheckDtata()
                }
            } else {
                if (!splitedFiles.some((e) => Number(e.index) == ind)) {
                    splitedFiles = splitedFiles.filter(
                        (i) => !i.is_multyTransactionCheck
                    );
                }
                setSelectedPages((prevState) => {
                    const oldVal = selectedPages[ind];
                    const newState = Array(selectedPages.length).fill(false);
                    newState[ind] = !oldVal;
                    return newState;
                });
            }
        } else {
            handleChoosePage(ind);
        }

        let id = addedElemnts.splitedFiles.length + 1;
        if (splitedFiles.some((e) => Number(e.index) == ind)) {
            splitedFiles = splitedFiles.filter((i) => Number(i.index) !== ind)
            setAddedElemnts({
                ...addedElemnts,
                splitedFiles,
            });
        } else {
            splitedFiles.push({
                id: ind + 1,
                name: String(ind + 1) + "split",
                index: ind,
                // src:pdfItems.item(ind).firstChild.getAttribute('src'),
                src: img_src,
                is_check:
                    state.multyTransaction && isMultiCheck
                        ? true
                        : false,
                is_multyTransactionCheck:
                    state.multyTransaction && isMultiCheck
                        ? true
                        : false,
                // is_multyTransactionCheck: state.multyTransaction && !hasMultyTransactionCheck || state.multyTransaction && isMultiCheckEdit ? true : false,
                is_multyTransactionCommon:
                    state.multyTransaction && isMultiCommon ? isMultiCommon : false,
                is_multyTransactionHeader:
                    state.multyTransaction && isMultiHeader ? isMultiHeader : false,
                position: canvasDetails[ind].rotate,
            });
            if (state.multyTransaction) {
                if (editCheckOnCheckBox) {
                    setAddedElemnts({
                        ...addedElemnts,
                        splitedFiles,
                    });

                    finaldata.map((i, index) => {
                        if (i.is_multyTransactionCheck) {
                            setIsMultiCheckEdit(true);
                            handleEditListedPdfAuto(i, index);
                        }
                    });
                } else {
                    if (!editNormalOnCheckBox) {
                    } else {
                    }
                    setAddedElemnts({
                        ...addedElemnts,
                        splitedFiles: splitedFiles,
                    });
                }
            } else {
                setAddedElemnts({
                    ...addedElemnts,
                    splitedFiles,
                });
            }
        }
        if (editCheckOnCheckBox) {
            if (!is_remove) {
                selectedPages[ind] = !selectedPages[ind];
                setSelectedPages(selectedPages);
            }

            finaldata.map((i, check_ind) => {
                if (i.is_multyTransactionCheck) {
                    setIsMultiCheckEdit(true);
                    handleEditListedPdfAuto(i, check_ind);
                }
            });
        }
        if (
            !isMultiCommonEdit &&
            hasMultyTransactionCommon &&
            commonAccordion?.classList?.contains("show") &&
            selectedPages.includes(true)
        ) {
            finaldata.map((i, common_ind) => {
                if (i.is_multyTransactionCommon == true) {
                    setIsMultiCommonEdit(true);
                    handleEditListedPdfAuto(i, common_ind);
                }
            });
        } else if (
            !isMultiHeaderEdit &&
            hasMultyTransactionHeader &&
            headerAccordion?.classList?.contains("show") &&
            selectedPages.includes(true)
        ) {
            finaldata.map((i, header_ind) => {
                if (i.is_multyTransactionHeader == true) {
                    setIsMultiHeaderEdit(true);
                    handleEditListedPdfAuto(i, header_ind);
                }
            });
        }

        setGroupOfPages({ ...groupOfPages, fromIndex: null });


        if (isMultiHeader && !splitedFiles.some((e) => e.is_multyTransactionHeader) && hasMultyTransactionHeader) {
            setFinalData(finaldata.filter((i) => !i.is_multyTransactionHeader));
            setIsMultiHeaderEdit(false)
        } else if (isMultiCommon && !splitedFiles.some((e) => e.is_multyTransactionCommon) && hasMultyTransactionCommon) {
            setFinalData(finaldata.filter((i) => !i.is_multyTransactionCommon));
            setIsMultiCommonEdit(false)
        }
        else if (isMultiCheck && !splitedFiles.some((e) => e.is_multyTransactionCheck) && hasMultyTransactionCheck) {
            setFinalData(finaldata.filter((i) => !i.is_multyTransactionCheck));
            setIsMultiCheckEdit(false)


        }
    }
    const handleEditListedPdfAuto = (item, index) => {
        const isMultiCheck = checkAccordion?.classList?.contains("show");
        const isMultiCommon = commonAccordion?.classList?.contains("show");
        const isMultiHeader = headerAccordion?.classList?.contains("show");
        const editCheckOnCheckBox =
            !isMultiCheckEdit &&
            hasMultyTransactionCheck &&
            isMultiCheck &&
            selectedPages;

        setEditIndex(index);
        // setisEdit(true)
        let splitedFiles = [];
        // let selectedPages = [...selectedPages]
        item.pages.map((i, ind) => {
            if (item.check === i) {
                setChekDetail({
                    ...chekDetail,
                    accountNumber: item.check_detail.accountNumber,
                    checkDate: item.check_detail.checkDate,
                    checkAmount: item.check_detail.checkAmount,
                    aba_routing_number: item.check_detail.aba_routing_number,
                    payer_account_number: item.check_detail.payer_account_number,
                });
            }
            splitedFiles.push({
                id: ind + 1,
                name: String(ind + 1) + "split",
                index: i,
                src: canvasDetails[i]?.image,
                is_check: item.check === i ? true : false,
                is_multyTransactionCheck: item.is_multyTransactionCheck,
                is_multyTransactionCommon: item.is_multyTransactionCommon,
                is_multyTransactionHeader: item.is_multyTransactionHeader,
                position: item?.position[ind] ?? canvasDetails[i].rotate,
            });
            selectedPages[i] = !selectedPages[i];

        });
        setSelectedPages(selectedPages);
        let arr = allpages.filter((i) => !item.pages.includes(i));
        setAllPages(arr);

        setTabSwich(1);
        if (!isMultiCheck &&
            !isMultiCommon &&
            !isMultiHeader) {

            setAddedElemnts((prevState) => ({
                ...prevState,
                splitedFiles: [...prevState.splitedFiles, ...splitedFiles],
            }));
        }


    };
    const handleEditListedPdf = (item, index) => {

        setEditIndex(index);
        setisEdit(true);
        let splitedFiles = [];
        // let selectedPages = [...selectedPages]
        item.pages.map((i, ind) => {
            if (item.check === i) {
                setChekDetail({
                    ...chekDetail,
                    accountNumber: item.check_detail.accountNumber,
                    checkDate: item.check_detail.checkDate,
                    checkAmount: item.check_detail.checkAmount,
                    aba_routing_number: item.check_detail.aba_routing_number,
                    payer_account_number: item.check_detail.payer_account_number,
                });
            }
            splitedFiles.push({
                id: ind + 1,
                name: String(ind + 1) + "split",
                index: i,
                src: canvasDetails[i]?.image,
                is_check: item.check === i ? true : false,
                is_multyTransactionCheck: false,
                is_multyTransactionCommon: false,
                is_multyTransactionHeader: false,
                position: item?.position[ind] ?? canvasDetails[i].rotate,
            });
            selectedPages[i] = !selectedPages[i];
        });
        setSelectedPages(selectedPages);

        // handleRemoveListedPdf(item)
        let arr = allpages.filter((i) => !item.pages.includes(i));
        setAllPages(arr);

        setTabSwich(1);

        setAddedElemnts({
            ...addedElemnts,
            splitedFiles,
        });
        formik.setValues({
            accountNumber: item?.check_detail?.accountNumber,
            checkDate: item?.check_detail?.checkDate,
            checkAmount: item?.check_detail?.checkAmount,
            aba_routing_number: item?.check_detail?.aba_routing_number,
            payer_account_number: item?.check_detail?.payer_account_number,
        });
    };
    const handleRemoveListedPdf = (item) => {
        let deleting_index = [];
        item.pages.filter((i) => deleting_index.push(allpages.indexOf(i)));
        let arr = allpages.filter((i) => !item.pages.includes(i));

        setAllPages(arr);
        setFinalData(finaldata.filter((i) => i !== item));
    };
    const handleAutoRemoveListedPdf = (item, event) => {
        let deleting_index = [];
        item.pages.filter((i) => deleting_index.push(allpages.indexOf(i)));
        let arr = allpages.filter((i) => !item.pages.includes(i));

        setAllPages(arr);
        setFinalData(finaldata.filter((i) => i !== item));
        setAddedElemnts({
            ...addedElemnts,
            splitedFiles: addedElemnts.splitedFiles.filter(
                (i) => !item?.pages.includes(i.index)
            ),
        });
    };
    const handleChoosePage = (pageIndex) => {
        selectedPages[pageIndex] = !selectedPages[pageIndex];
    };
    const handleClearCheckDtata = () => {
        formik.setValues({
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        });
        setChekDetail({
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        });
    }
    const selectGroupOfPages = (index) => {
        const checkAccordion = document.getElementById("collapseOne");
        const commonAccordion = document.getElementById("collapseTwo");
        const headerAccordion = document.getElementById("collapseFive");
        const normalAccordion = document.getElementById("collapseThree");

        const isMultiCheck = checkAccordion?.classList?.contains("show");
        const isMultiCommon = commonAccordion?.classList?.contains("show");
        const isMultiHeader = headerAccordion?.classList?.contains("show");
        setTabSwich(1);
        let splitedFiles = [...addedElemnts.splitedFiles];

        if (groupOfPages.fromIndex === null) {
            if (splitedFiles.some((e) => Number(e.index) == index)) {
                splitedFiles = splitedFiles.filter((i) => Number(i.index) !== index);
            } else {
                splitedFiles.push({
                    id: index + 1,
                    name: String(index + 1) + "split",
                    index: index,
                    src: canvasDetails[index]?.image,
                    is_check:
                        (state.multyTransaction && isMultiCheck) ||
                            (state.multyTransaction && isMultiCheckEdit)
                            ? true
                            : false,
                    is_multyTransactionCheck:
                        (state.multyTransaction && isMultiCheck) ||
                            (state.multyTransaction && isMultiCheckEdit)
                            ? true
                            : false,
                    is_multyTransactionCommon:
                        state.multyTransaction && isMultiCommon ? isMultiCommon : false,
                    is_multyTransactionHeader:
                        state.multyTransaction && isMultiHeader ? isMultiHeader : false,
                    position: canvasDetails[index]?.rotate,
                });
            }
            setGroupOfPages({ ...groupOfPages, fromIndex: index, toIndex: null });
            selectedPages[index] = !selectedPages[index];
        } else if (
            groupOfPages.fromIndex ||
            (groupOfPages.fromIndex === 0 && !groupOfPages.toIndex)
        ) {
            let range_pages = numberRange(allpages, groupOfPages.fromIndex, index);

            let removeArr = [];

            selectedPages.map((i, ind) => {
                if (range_pages.includes(ind) && ind !== groupOfPages.fromIndex) {
                    selectedPages[ind] = !selectedPages[ind];
                    if (splitedFiles.some((e) => e.index === ind)) {
                        removeArr.push(ind);
                    } else {
                        splitedFiles.push({
                            id: ind + 1,
                            name: String(ind + 1) + "split",
                            index: ind,
                            src: canvasDetails[ind]?.image,
                            is_check:
                                (state.multyTransaction && isMultiCheck) ||
                                    (state.multyTransaction && isMultiCheckEdit)
                                    ? true
                                    : false,
                            is_multyTransactionCheck:
                                (state.multyTransaction && isMultiCheck) ||
                                    (state.multyTransaction && isMultiCheckEdit)
                                    ? true
                                    : false,
                            is_multyTransactionCommon:
                                state.multyTransaction && isMultiCommon ? isMultiCommon : false,
                            is_multyTransactionHeader:
                                state.multyTransaction && isMultiHeader ? isMultiHeader : false,
                            position: canvasDetails[ind]?.rotate,
                        });
                    }
                }
            });
            splitedFiles = splitedFiles.filter(
                (value) => !removeArr.includes(value.index)
            );
            setGroupOfPages({ ...groupOfPages, fromIndex: null });
        } else {
            // console.log("3333333333333333333333333333..................");
        }
        setAddedElemnts({
            ...addedElemnts,
            splitedFiles,
        });
        setSelectedPages(selectedPages);

        if (
            !isMultiCommonEdit &&
            hasMultyTransactionCommon &&
            commonAccordion?.classList?.contains("show") &&
            selectedPages.includes(true)
        ) {
            finaldata.map((i, index) => {
                if (i.is_multyTransactionCommon == true) {
                    setIsMultiCommonEdit(true);
                    handleEditListedPdfAuto(i, index);
                }
            });
        } else if (
            !isMultiHeaderEdit &&
            hasMultyTransactionHeader &&
            headerAccordion?.classList?.contains("show") &&
            selectedPages.includes(true)
        ) {
            finaldata.map((i, index) => {
                if (i.is_multyTransactionHeader == true) {
                    setIsMultiHeaderEdit(true);
                    handleEditListedPdfAuto(i, index);
                }
            });
        }
    };
    const handleActualBreak = () => {
        if (addedElemnts.splitedFiles.length > 0) {
            setisEdit(false);
            setGroupOfPages({ ...groupOfPages, fromIndex: null });
            let items = document.getElementsByClassName("single-splited-pdf");

            let selected_items = Array.from(items).map((i) => {
                return Number(i.getAttribute("data-index"));
            });
            let selected_positions = Array.from(items).map((i) => {
                let position = 0;
                if (i.firstChild.style.transform) {
                    if (i.firstChild.style.transform === "rotate(90deg)") {
                        position = 90;
                    } else if (i.firstChild.style.transform === "rotate(3.142rad)") {
                        position = 180;
                    } else if (i.firstChild.style.transform === "rotate(-0.25turn)") {
                        position = 270;
                    }
                }
                return position;
            });
            let selected_check = "";
            Array.from(items).map((i) => {
                if (i.getAttribute("data-ischeck") === "true") {
                    selected_check = Number(i.getAttribute("data-index"));
                }
                return selected_check;
            });
            if (selected_check !== "" || togglePopup) {
                setCheckPopup(true);
            } else {
                setTogglePopup(true);
            }
        } else {
            // alert("Pleasffge Select PDF")
        }
    };
    const finalbreakButtonClick = () => {
        const isMultiCheck = checkAccordion?.classList?.contains("show");
        if (state.multyTransaction) {
            if (isMultiCheck) {
                setCheckPopup(true);
                return; // add this line to exit the function
            }
            isMultiCheckEdit ||
                isMultiCommonEdit ||
                isMultiHeaderEdit ||
                isMultiNormalEdit
                ? autoEditFinalBreak()
                : autoFinalBreak();
        } else {
            !isEdit ? handleActualBreak() : handleEditActualBreak();
        }
    };
    const handleEditActualBreak = () => {
        // handleActualBreak()
        if (addedElemnts.splitedFiles.length > 0) {
            // console.log("BREAK EDIT............");
            let items = document.getElementsByClassName("single-splited-pdf");
            let selected_check = "";
            Array.from(items).map((i) => {
                if (i.getAttribute("data-ischeck") === "true") {
                    selected_check = Number(i.getAttribute("data-index"));
                }
                return selected_check;
            });
            if (selected_check !== "" || togglePopup) {
                setCheckPopup(true);

            } else {
                finalEditBreak();
            }
        } else {
        }
    };
    const autoEditFinalBreak = () => {
        const checkAccordion = document.getElementById("collapseOne");
        const commonAccordion = document.getElementById("collapseTwo");
        const headerAccordion = document.getElementById("collapseFive");
        const normalAccordion = document.getElementById("collapseThree");

        const isMultiCheck = checkAccordion?.classList?.contains("show");
        const isMultiCommon = commonAccordion?.classList?.contains("show");
        const isMultiHeader = headerAccordion?.classList?.contains("show");

        let final_array = [...finaldata];

        let items = document.getElementsByClassName("single-splited-pdf");
        const selected_items = [];
        const selected_positions = [];
        Array.from(items).map((i, ind) => {
            const rotation = i.firstChild.style.transform;


            if (isMultiCheck && i.getAttribute("data-multi-check") == "true") {
                selected_positions.push(Number(i.getAttribute("data-position")));
                // selected_positions.push(getPositionPdf(i))
                selected_items.push(Number(i.getAttribute("data-index")));
            } else if (
                isMultiCommon &&
                i.getAttribute("data-multi-common") == "true"
            ) {
                // selected_positions.push(getPositionPdf(i))
                selected_positions.push(Number(i.getAttribute("data-position")));

                selected_items.push(Number(i.getAttribute("data-index")));
            } else if (
                isMultiHeader &&
                i.getAttribute("data-multi-header") == "true"
            ) {
                selected_positions.push(Number(i.getAttribute("data-position")));
                // selected_positions.push(getPositionPdf(i))
                selected_items.push(Number(i.getAttribute("data-index")));
            } else if (
                !isMultiCheck &&
                !isMultiCommon &&
                !isMultiHeader &&
                i.getAttribute("data-multi-check") == "false" &&
                i.getAttribute("data-multi-common") == "false" &&
                i.getAttribute("data-multi-header") == "false"
            ) {
                // else{

                // selected_positions.push(getPositionPdf(i))
                selected_positions.push(Number(i.getAttribute("data-position")));

                selected_items.push(Number(i.getAttribute("data-index")));
            }
        });

        if (selected_items.length === 0) {
            alert("PLEASE SELECT A PAGE.......");
            final_array = final_array.filter((i, ind) => ind != editIndex);
        } else {
            let selected_check = "";
            let check_detail = {};
            Array.from(items).map((i) => {
                if (i.getAttribute("data-ischeck") === "true") {
                    selected_check = Number(i.getAttribute("data-index"));
                    check_detail = {
                        accountNumber: chekDetail.accountNumber,
                        checkDate: chekDetail.checkDate,
                        checkAmount: chekDetail.checkAmount,
                        aba_routing_number: chekDetail.aba_routing_number,
                        payer_account_number: chekDetail.payer_account_number,
                    };
                }
                return selected_check;
            });

            final_array[editIndex].check = selected_check;
            final_array[editIndex].check_detail = check_detail;
            final_array[editIndex].pages = selected_items;
            final_array[editIndex].position = selected_positions;
            final_array[editIndex].is_multyTransactionCheck = isMultiCheck ?? false;
            final_array[editIndex].is_multyTransactionCommon = isMultiCommon ?? false;
            final_array[editIndex].is_multyTransactionHeader = isMultiHeader ?? false;
        }
        setFinalData(final_array);
        setTabSwich(0);
        const addedElementList = addedElemnts.splitedFiles;

        !isMultiCheck &&
            !isMultiCommon &&
            !isMultiHeader &&
            setAddedElemnts({
                ...addedElemnts,
                // splitedFiles: []
                splitedFiles: addedElementList.filter(
                    (i) =>
                        i.is_multyTransactionCheck == true ||
                        i.is_multyTransactionCommon == true ||
                        i.is_multyTransactionHeader == true
                ),
            });
        setSelectedPages(Array(totalpf).fill(false));
        selectedPages.filter((i, index) => {
            if (i) {
                allpages.push(index);
            }
        });
        setAllPages(allpages);
        setEditIndex(null);
        setisEdit(false);
        setChekDetail({
            ...chekDetail,
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        });
        setCheckPopup(false);
        setIsMultiCommonEdit(false);
        setIsMultiHeaderEdit(false);
        setIsMultiCheckEdit(false);
        setIsMultiNormalEdit(false);
    };
    const autoFinalBreak = () => {
        const checkAccordion = document.getElementById("collapseOne");
        const commonAccordion = document.getElementById("collapseTwo");
        const headerAccordion = document.getElementById("collapseFive");
        const normalAccordion = document.getElementById("collapseThree");

        const isMultiCheck = checkAccordion?.classList?.contains("show");
        const isMultiCommon = commonAccordion?.classList?.contains("show");
        const isMultiHeader = headerAccordion?.classList?.contains("show");

        setisEdit(false);
        setGroupOfPages({ ...groupOfPages, fromIndex: null });
        let items = document.getElementsByClassName("single-splited-pdf");

        const selected_items = [];
        const selected_positions = [];
        Array.from(items).map((i, ind) => {

            if (isMultiCheck && i.getAttribute("data-multi-check") == "true") {
                selected_positions.push(Number(i.getAttribute("data-position")));
                selected_items.push(Number(i.getAttribute("data-index")));
            } else if (
                isMultiCommon &&
                i.getAttribute("data-multi-common") == "true"
            ) {
                // selected_positions.push(getPositionPdf(i))
                selected_positions.push(Number(i.getAttribute("data-position")));

                selected_items.push(Number(i.getAttribute("data-index")));
            } else if (
                isMultiHeader &&
                i.getAttribute("data-multi-header") == "true"
            ) {
                // selected_positions.push(getPositionPdf(i))
                selected_positions.push(Number(i.getAttribute("data-position")));

                selected_items.push(Number(i.getAttribute("data-index")));
            } else if (
                !isMultiCheck &&
                !isMultiCommon &&
                !isMultiHeader &&
                i.getAttribute("data-multi-check") == "false" &&
                i.getAttribute("data-multi-common") == "false" &&
                i.getAttribute("data-multi-header") == "false"
            ) {
                // else{
                // selected_positions.push(getPositionPdf(i))
                selected_positions.push(Number(i.getAttribute("data-position")));

                selected_items.push(Number(i.getAttribute("data-index")));
            }
        });

        let selected_check = "";
        let check_detail = {};
        Array.from(items).map((i) => {
            if (i.getAttribute("data-ischeck") === "true") {
                selected_check = Number(i.getAttribute("data-index"));
                check_detail = {
                    accountNumber: chekDetail.accountNumber,
                    checkDate: chekDetail.checkDate,
                    checkAmount: chekDetail.checkAmount,
                    aba_routing_number: chekDetail.aba_routing_number,
                    payer_account_number: chekDetail.payer_account_number,
                };
            }
            return selected_check;
        });
        setTogglePopup(false);
        let final_array = [...finaldata];
        selectedPages.filter((i, index) => {
            if (i) {
                allpages.push(index);
            }
        });

        setAllPages(allpages);
        setSelectedPages(Array(totalpf).fill(false));
        let dic = {
            check: selected_check,
            check_detail: check_detail,
            pages: selected_items,
            position: selected_positions,
            is_multyTransactionCheck: hasMultyTransactionCheck ? false : isMultiCheck,
            is_multyTransactionCommon: hasMultyTransactionCommon
                ? false
                : isMultiCommon,
            is_multyTransactionHeader: hasMultyTransactionHeader
                ? false
                : isMultiHeader,
        };
        if (selected_items.length === 0) {
            alert("PLEASE SELECT A PAGE.......");
        } else {
            final_array.push(dic);
            setFinalData(final_array);
        }

        // !isMultiCheck && !isMultiCommon&& splitedFiles.filter((i)=>!i.is_multyTransactionCheck&&!i.is_multyTransactionCommon)
        const addedElementList = addedElemnts.splitedFiles;

        !isMultiCheck &&
            !isMultiCommon &&
            !isMultiHeader &&
            setAddedElemnts({
                ...addedElemnts,
                // splitedFiles: []
                splitedFiles: addedElementList.filter(
                    (i) =>
                        i.is_multyTransactionCheck == true ||
                        i.is_multyTransactionCommon == true ||
                        i.is_multyTransactionHeader == true
                ),
            });

        // ===========
        setTabSwich(0);
        setCheckPopup(false);
        setActiveCheckID(null);
        setChekDetail({
            ...chekDetail,
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        });
        setIsMultiCommonEdit(false);
        setIsMultiHeaderEdit(false);

        setIsMultiCheckEdit(false);
        setIsMultiNormalEdit(false);
    };
    const finalBreak = () => {
        const isMultiCheck = checkAccordion?.classList?.contains("show");
        const isMultiCommon = commonAccordion?.classList?.contains("show");
        const isMultiHeader = headerAccordion?.classList?.contains("show");

        setisEdit(false);
        setGroupOfPages({ ...groupOfPages, fromIndex: null });
        let items = document.getElementsByClassName("single-splited-pdf");
        let selected_items = Array.from(items).map((i, ind) => {
            return Number(i.getAttribute("data-index"));
        });

        let selected_positions = Array.from(items).map((i) => {
            let position = Number(i.getAttribute("data-position"));
            return position;
        });
        let selected_check = "";
        let check_detail = {};
        Array.from(items).map((i) => {
            if (i.getAttribute("data-ischeck") === "true") {
                selected_check = Number(i.getAttribute("data-index"));
                check_detail = {
                    accountNumber: chekDetail.accountNumber,
                    checkDate: chekDetail.checkDate,
                    checkAmount: chekDetail.checkAmount,
                    aba_routing_number: chekDetail.aba_routing_number,
                    payer_account_number: chekDetail.payer_account_number,
                };
            }
            return selected_check;
        });
        setTogglePopup(false);
        let final_array = [...finaldata];
        selectedPages.filter((i, index) => {
            if (i) {
                allpages.push(index);
            }
        });
        setAllPages(allpages);
        setSelectedPages(Array(totalpf).fill(false));
        let dic = {
            check: selected_check,
            check_detail: check_detail,
            pages: selected_items,
            position: selected_positions,
            is_multyTransactionCheck: hasMultyTransactionCheck ? false : isMultiCheck,
            is_multyTransactionCommon: hasMultyTransactionCommon
                ? false
                : isMultiCommon,
            is_multyTransactionHeader: hasMultyTransactionHeader
                ? false
                : isMultiHeader,
        };
        final_array.push(dic);
        setFinalData(final_array);
        setTabSwich(0);

        if (!state.multyTransaction) {
            setAddedElemnts({
                ...addedElemnts,
                splitedFiles: [],
            });
        } else {
        }
        // ===========
        setCheckPopup(false);
        setActiveCheckID(null);
        setChekDetail({
            ...chekDetail,
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        });
    };
    const finalEditBreak = () => {
        let final_array = [...finaldata];

        let items = document.getElementsByClassName("single-splited-pdf");
        let selected_items = Array.from(items).map((i) => {
            return Number(i.getAttribute("data-index"));
        });
        let selected_positions = Array.from(items).map((i) => {
            let position = Number(i.getAttribute("data-position"));
            return position;
        });
        let selected_check = "";
        let check_detail = {};
        Array.from(items).map((i) => {
            if (i.getAttribute("data-ischeck") === "true") {
                selected_check = Number(i.getAttribute("data-index"));
                check_detail = {
                    accountNumber: chekDetail.accountNumber,
                    checkDate: chekDetail.checkDate,
                    checkAmount: chekDetail.checkAmount,
                    aba_routing_number: chekDetail.aba_routing_number,
                    payer_account_number: chekDetail.payer_account_number,
                };
            }
            return selected_check;
        });

        final_array[editIndex].check = selected_check;
        final_array[editIndex].check_detail = check_detail;
        final_array[editIndex].pages = selected_items;
        final_array[editIndex].position = selected_positions;
        final_array[editIndex].is_multyTransactionCheck = isMultiCheckEdit ?? false;
        final_array[editIndex].is_multyTransactionCommon =
            isMultiCommonEdit ?? false;
        final_array[editIndex].is_multyTransactionHeader =
            isMultiHeaderEdit ?? false;

        setFinalData(final_array);
        setTabSwich(0);
        setAddedElemnts({
            ...addedElemnts,
            splitedFiles: [],
        });
        setSelectedPages(Array(totalpf).fill(false));
        selectedPages.filter((i, index) => {
            if (i) {
                allpages.push(index);
            }
        });
        setAllPages(allpages);
        setEditIndex(null);
        setisEdit(false);
        setChekDetail({
            ...chekDetail,
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        });
        setCheckPopup(false);
        setIsMultiCommonEdit(false);
        setIsMultiHeaderEdit(false);
        setIsMultiCheckEdit(false);
        setIsMultiNormalEdit(false);
    };
    const handleSubmitCheck = () => {
        if (state.multyTransaction) {
            isMultiCheckEdit || isMultiCommonEdit || isMultiHeaderEdit
                ? autoEditFinalBreak()
                : autoFinalBreak();
        } else if (!isEdit) {
            finalBreak();
        } else {
            finalEditBreak();
        }
        formik.resetForm();
    };
    const handleInputChange = (e, n) => {
        if (n == "checkDate") {
            setChekDetail({ ...chekDetail, [n]: e.target.value });
            formik.setValues({
                ...formik.values,
                checkDate: e.target.value,
            });
        } else if (n === "checkAmount") {
            const decimalCount = (e.target.value.split(".")[1] || "").length;
            if (decimalCount > 2) {
                // e.target.value = parseFloat(e.target.value).toFixed(2)
            } else {
                setChekDetail({ ...chekDetail, [n]: e.target.value });
                formik.setValues({
                    ...formik.values,
                    checkAmount: e.target.value,
                });
            }
        } else {
            setChekDetail({ ...chekDetail, [n]: e.target.value });
        }
    };
    const onSelectPage = (e, onJumpToPage, ind) => {
        const isMultiCheck = checkAccordion?.classList?.contains("show");
        const editCheckOnCheckBox =
            !isMultiCheckEdit &&
            hasMultyTransactionCheck &&
            isMultiCheck &&
            selectedPages;


        // onJumpToPage();
        setCurrentPage(ind);

        if (e.ctrlKey) {
            e.preventDefault();
            swapDiv(ind);
        } else if (e.shiftKey) {
            if (groupOfPages.fromIndex === ind) {
                swapDiv(ind);
            } else {
                (state.multyTransaction && isMultiCheckEdit) ||
                    (state.multyTransaction && isMultiCheck) ||
                    editCheckOnCheckBox
                    ? swapDiv(ind)
                    : selectGroupOfPages(ind);
            }
        }
        // }
    };
    const handlePdfSubmit = () => {
        const RedirectId = getRedirectTabId()

        const checkFinalData = finaldata;
        let footerLength = []
        let headerLength = []
        if (totalpf === allpages.length && !submitLoader) {
            setSubmitLoader(true)
            let data = null;
            if (state.multyTransaction) {
                if (!hasMultyTransactionNormal) {
                    setIsSwal(true);
                    setSwalContent({
                        title: `Transaction is empty`,
                        text: "Ok",
                        icon: "danger",
                        onConfirm: () => {
                            setIsSwal(false);

                        }
                    })
                    setSubmitLoader(false)
                    return;
                }

                const checkPosition = findPositions(
                    checkFinalData,
                    "is_multyTransactionCheck"
                );
                const commonPosition = findPositions(
                    checkFinalData,
                    "is_multyTransactionCommon"
                );
                const headerPosition = findPositions(
                    checkFinalData,
                    "is_multyTransactionHeader"
                );
                const checkPages = findPages(
                    checkFinalData,
                    "is_multyTransactionCheck"
                );
                const commonPages = findPages(
                    checkFinalData,
                    "is_multyTransactionCommon"
                );
                const headerPages = findPages(
                    checkFinalData,
                    "is_multyTransactionHeader"
                );
                footerLength = commonPages
                headerLength = headerPages
                const newPositions = [
                    ...headerPosition,
                    ...commonPosition,
                    ...checkPosition,
                ];
                const newPages = [...headerPages, ...commonPages, ...checkPages];

                const check_detail =
                    checkFinalData.find((obj) => obj.is_multyTransactionCheck)
                        ?.check_detail || {};

                const Lastdata = checkFinalData
                    .filter(
                        (obj) =>
                            !obj.is_multyTransactionCheck &&
                            !obj.is_multyTransactionCommon &&
                            !obj.is_multyTransactionHeader
                    )
                    .map((obj) => ({
                        ...obj,
                        check_detail,
                        check: checkPages[0],
                        pages: [
                            ...checkPages,
                            ...headerPages,
                            ...obj.pages,
                            ...commonPages,
                        ],
                        position: [
                            ...checkPosition,
                            ...headerPosition,
                            ...obj.position,
                            ...commonPosition,
                        ],
                        is_master_job: false,
                    }));

                const transactionPages = finaldata
                    .filter(
                        (tran) =>
                            !tran.is_multyTransactionCheck &&
                            !tran.is_multyTransactionCommon &&
                            !tran.is_multyTransactionHeader
                    )
                    .flatMap((item) => item.pages);
                const transactionPositions = finaldata
                    .filter(
                        (tran) =>
                            !tran.is_multyTransactionCheck &&
                            !tran.is_multyTransactionCommon &&
                            !tran.is_multyTransactionHeader
                    )
                    .flatMap((item) => item.position);

                const qaDict = {
                    check_detail,
                    check: checkPages[0],
                    pages: [
                        ...checkPages,
                        ...headerPages,
                        ...transactionPages,
                        ...commonPages,
                    ],
                    position: [
                        ...checkPosition,
                        ...headerPosition,
                        ...transactionPositions,
                        ...commonPosition,
                    ],
                    is_master_job: true,
                    is_multyTransactionCheck: false,
                    is_multyTransactionCommon: false,
                    is_multyTransactionHeader: false,
                };

                data = [qaDict, ...Lastdata];
            } else {
                data = finaldata;
            }

            const submitData = {
                pdf_file: file_id,
                headers: headerLength,
                footers: footerLength,
                split_list: data,
            };

            postManualSplitFile(submitData)((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    // navigate('/view/file-split')
                    changeAllTitleFunction("File Split", "/view/file-split", RedirectId)
                } else {
                    setSubmitLoader(false)
                }
            })
        }
    };

    function findPositions(arr, key) {
        return arr.find((obj) => obj[key])?.position || [];
    }

    function findPages(arr, key) {
        return arr.find((obj) => obj[key])?.pages || [];
    }
    const deselectAllPages = () => {
        setSelectedPages((selectedPages) =>
            Array(selectedPages.length).fill(false)
        );
    };
    const handleAllPdfReset = () => {
        setZoomValue(10)
        setLeftWidth(20)
        setisEdit(false)
        setAddedElemnts({ splitedFiles: [] });
        setFinalData([]);
        setAllPages([]);
        deselectAllPages();

        setIsMultiCommonEdit(false)
        setIsMultiHeaderEdit(false)
        setIsMultiNormalEdit(false)
        setIsMultiCheckEdit(false)

        formik.setValues({
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        });
        setChekDetail({
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        });
        setCanvasDetails(prevState => prevState.map(item => ({ ...item, image: item.original_image, rotate: 0 })))
    };
    const handleSwichAutoMaticSplit = (e) => {
        setState({
            ...state,
            multyTransaction: e.target.checked,
            isCheck: e.target.checked
        })
        handleAllPdfReset()
    };
    const handleMultyTransactionChange = (event, name) => {
        // Get the element that triggered the event
        let element = null;
        if (!selectedPages.includes(true)) {
            if (isMultiNormalEdit && normalAccordion?.classList?.contains("show")) {
                element = document
                    .getElementById("headingThree")
                    .querySelector("button");
                element.style.boxShadow = "1px 1px 8px red";
                setTimeout(() => {
                    if (element) {
                        element.style.boxShadow = "unset";
                    }
                }, 300);
            } else {
                setTimeout(() => {
                    if (name == 'check') {
                        if (commonAccordion?.classList?.contains("show")) {
                            commonAccordion?.classList?.remove("show")

                            document.getElementById('headingTwo').querySelector('button').classList.add('collapsed')
                        }
                        normalAccordion?.classList?.contains("show") && normalAccordion?.classList?.remove("show"); document.getElementById('headingThree')?.querySelector('button')?.classList.add('collapsed')
                        headerAccordion?.classList?.contains("show") && headerAccordion?.classList?.remove("show"); document.getElementById('headingFive')?.querySelector('button')?.classList.add('collapsed')

                    }
                    else if (name == 'headerpage') {
                        checkAccordion?.classList?.contains("show") && checkAccordion?.classList?.remove("show"); document.getElementById('headingOne')?.querySelector('button')?.classList.add('collapsed')
                        commonAccordion?.classList?.contains("show") && commonAccordion?.classList?.remove("show"); document.getElementById('headingTwo')?.querySelector('button')?.classList.add('collapsed')
                        normalAccordion?.classList?.contains("show") && normalAccordion?.classList?.remove("show"); document.getElementById('headingThree')?.querySelector('button')?.classList.add('collapsed')
                    }
                    else if (name == 'commonpage') {
                        checkAccordion?.classList?.contains("show") && checkAccordion?.classList?.remove("show"); document.getElementById('headingOne')?.querySelector('button')?.classList.add('collapsed')
                        headerAccordion?.classList?.contains("show") && headerAccordion?.classList?.remove("show"); document.getElementById('headingFive')?.querySelector('button')?.classList.add('collapsed')
                        normalAccordion?.classList?.contains("show") && normalAccordion?.classList?.remove("show"); document.getElementById('headingThree')?.querySelector('button')?.classList.add('collapsed')
                    }
                    else if (name == 'transaction') {
                        checkAccordion?.classList?.contains("show") && checkAccordion?.classList?.remove("show"); document.getElementById('headingOne')?.querySelector('button')?.classList.add('collapsed')
                        commonAccordion?.classList?.contains("show") && commonAccordion?.classList?.remove("show"); document.getElementById('headingTwo')?.querySelector('button')?.classList.add('collapsed')
                        headerAccordion?.classList?.contains("show") && headerAccordion?.classList?.remove("show"); document.getElementById('headingFive')?.querySelector('button')?.classList.add('collapsed')
                    }
                }, 300)

            }
        }
        else if (selectedPages.includes(true)) {

            if (checkAccordion?.classList?.contains("show")) {
                document.getElementById("headingOne").querySelector("button");
                element = document.getElementById("headingOne").querySelector("button");
                // Change the box-shadow color of the element
                element.style.boxShadow = "1px 1px 8px red";
            }
            if (headerAccordion?.classList?.contains("show")) {
                element = document
                    .getElementById("headingFive")
                    .querySelector("button");
                element.style.boxShadow = "1px 1px 8px red";
            }
            if (commonAccordion?.classList?.contains("show")) {
                element = document.getElementById("headingTwo").querySelector("button");
                element.style.boxShadow = "1px 1px 8px red";
            }
            if (normalAccordion?.classList?.contains("show")) {
                element = document
                    .getElementById("headingThree")
                    .querySelector("button");
                element.style.boxShadow = "1px 1px 8px red";
            }

            setTimeout(() => {
                if (element) {
                    element.style.boxShadow = "unset";
                }
            }, 300);
        }

    };
    const handleEditCheck = (e, ind) => {
        setIsMultiCheckEdit(true)
        setCheckPopup(true)
        const checkData = finaldata.find((i) => i.is_multyTransactionCheck)?.check_detail ?? null
        const checkIndex = finaldata.findIndex((i) => i.is_multyTransactionCheck) ?? null
        setEditIndex(checkIndex);

        formik.setValues((prevState) => {
            return {
                ...prevState,
                accountNumber: checkData?.accountNumber ?? "",
                checkDate: checkData?.checkDate ?? "",
                checkAmount: checkData?.checkAmount ?? "",
                aba_routing_number: checkData?.aba_routing_number ?? "",
                payer_account_number: checkData?.payer_account_number ?? "",
            }
        })
        setChekDetail((prevState) => {
            return {
                ...prevState,
                accountNumber: checkData?.accountNumber ?? "",
                checkDate: checkData?.checkDate ?? "",
                checkAmount: checkData?.checkAmount ?? "",
                aba_routing_number: checkData?.aba_routing_number ?? "",
                payer_account_number: checkData?.payer_account_number ?? "",
            }
        })

    }
    function handleAutoManualChange(e) {

        let value = e.target.value;
        if (e.target.name === "multiTransaction") {
            value = e.target.checked ? true : false;
        } else if (e.target.name === "autoPage") {
            value = e.target.value ?? ""
            // value = modifiedText.replace(/\./g, "");
        }
        setState({
            ...state,
            [e.target.name]: value,
        });
    }
    const handleEnterAutoSplit = (e) => {
        const filteredPages = finaldata
            .filter((d) => d.is_multyTransactionCheck || d.is_multyTransactionCommon)
            .map((d) => d.pages);

        let counter = 0;
        const divisibleValue = e.target.value;
        let final_array1 = [...finaldata];
        let final_all = [...allpages];
        let final_array = [];
        let position = [];

        if (
            e.key === "Enter" &&
            totalpf !== allpages.length &&
            state.autoPage != null &&
            state.autoPage != 0 &&
            state.autoPage > 0
        ) {
            if (divisibleValue <= selectedPages.length - allpages.length) {
                showTransactionTab(true);
                setAutomaticSplitLoader(true)
                // checkAllPagesLoaded()
                setAllPages(selectedPages.map((value, index) => value ? index : -1).filter(index => index !== -1));
                newActualAutoMaticSpliting(counter, divisibleValue, final_array1, final_all, final_array, position)

                setFinalData(final_array1);
                setAllPages(final_all);
                setState({
                    ...state,
                    autoPage: "",
                });
                // }
            } else {
                setIsSwal(true);
                setSwalContent({
                    title: `You can only split ${selectedPages.length - allpages.length} items`,
                    text: "Ok",
                    icon: "danger",
                    onConfirm: () => {
                        setIsSwal(false);
                    }
                })
            }
        }
    };
    const newActualAutoMaticSpliting = (counter, divisibleValue, final_array1, final_all, final_array, position) => {
        selectedPages.map((i, ind) => {
            if (!final_all.includes(ind)) {
                final_all.push(ind);
                final_array.push(ind);
                position.push(0);
                counter++;

                if (counter % divisibleValue === 0) {
                    const dic = {
                        check: state.checkPageIndex,
                        check_detail: {},
                        pages: final_array,
                        position: position,
                        is_multyTransactionCheck: false,
                        is_multyTransactionCommon: false,
                        is_multyTransactionHeader: false,
                    };

                    final_array1.push(dic);
                    final_array = [];
                    position = [];
                }
            }
        });

        // Add remaining items to the last dict
        if (final_array.length > 0) {
            const dic = {
                check: state.checkPageIndex,
                check_detail: {},
                pages: final_array,
                position: position,
                is_multyTransactionCheck: false,
                is_multyTransactionCommon: false,
                is_multyTransactionHeader: false,
            };

            final_array1.push(dic);
        }
    }
    const checkAllPagesLoaded = () => {
        const singlePdfPageLoaded = () => {
            const allItem = [];
            const return_value = selectedPages.every((_, ind) => {
                const pageThumbnail = document?.querySelector(`[aria-label="Thumbnail of page ${ind + 1}"]`);
                allItem.push(ind);
                setAllPages(allItem);
                return pageThumbnail && pageThumbnail.getAttribute("src");
            });
            return return_value;
        };

        const checkLoaded = () => {
            if (singlePdfPageLoaded() === false) {
                setTimeout(checkLoaded, 1000); // Retry after 1 second
            } else {
                setAutomaticSplitLoader(false)
                // All pages are loaded
                // Perform any additional actions
            }
        };

        checkLoaded();
    };

    function __onControlledDrag(e, position) {
        setControlledPosition({ x: position.x, y: position.y });
    }
    useEffect(() => {
        setPdfLoaded(true)
        getSingleFile(file_id)((response) => {
            if (
                response?.status &&
                response?.status_code === 200 &&
                response?.message === "Already In Progress"
            ) {
                setIsSwal(true);
                setSwalContent({
                    title: "File already in progress",
                    text: "Go back",
                    icon: "danger",
                    onConfirm: () => {
                        setIsSwal(false);
                        const json_tabs = window.parent.localStorage.getItem('bottomTab')
                        let tabs = JSON.parse(json_tabs);
                        const priviousTab = tabs?.currentTab?.prevTabs[tabs?.currentTab?.prevTabs.length - 1]
                        if (priviousTab) {
                            window.parent.localStorage.setItem('isBackActive', 'true')
                            const baseUrl = window.location.origin
                            const previousURL = priviousTab?.link.replace(baseUrl, "");
                            const previousLabel = priviousTab?.label;
                            changeAllTitleFunction(previousLabel, previousURL)
                        } else {
                            window.parent.localStorage.setItem('isFileSplitBackActive', 'true')
                            changeAllTitleFunction("File Split", "/view/file-split")

                        }
                        // navigate(-1);
                    }
                })
            } else if (
                response?.status &&
                response?.status_code === 200 &&
                response?.data
            ) {

                loadPdfFiles(response?.data?.converted_file)
                setSingleData(response?.data);
                // setState({
                //     ...state,
                //     isCheck: response?.data?.is_large_batch,
                //     multyTransaction: response?.data?.is_large_batch,
                // });
                // setIsSwal(false);
                // setSwalContent({
                //     title: "",
                //     text: "",
                //     icon: "",
                //     onConfirm: null
                // })
            }
        });
    }, []);
    window.onkeyup = function (event) {
        const keyCode = event.keyCode;
        if (keyCode === 27) {
            setTimeout(() => {
                setSingleZoomValue(100)
                setSingleView({
                    id: null,
                    isOpen: false,
                    index: null,
                    canvas: null,
                });
            }, 1)

        }
    };
    window.onkeydown = function (event) {
        const keyCode = event.keyCode;

        if (event.key == 'Tab' && event.ctrlKey && event.shiftKey) {
            parent.document.getElementById('id-ctrl-tab-shift-switch').click()
        }
        else if (event.key == 'Tab' && event.ctrlKey) {
            parent.document.getElementById('id-ctrl-tab-switch').click()
        }
        else if (event.altKey && keyCode == 78) {
            parent.document.getElementById('bottom-tab-add').click()
        }
        else if (event.altKey && keyCode == 88) {
            parent.document.getElementById('id-ctrl-x-remove-tab').click()
        }
        else if (event.ctrlKey && (event.key === "+" || event.key === "-" || event.key === "=")) {
            event.preventDefault(); // Prevent the default browser behavior
            event.key === "-" ? handleZoomIn() : handleZoomOut()
        }
        else if (event.ctrlKey && (keyCode === 48 || keyCode === 96)) {
            event.preventDefault(); // Prevent the default browser behavior
        }

    };
    document.getElementById('root').addEventListener('wheel', event => {
        if (event.ctrlKey) {
            event.preventDefault()
        }
    }, true)
    // ==========Variables===============
    const selectedCanavsIndex = signleView?.id && canvasDetails.findIndex(dict => dict.id == signleView.id);
    const hasMultyTransactionCheck = finaldata.some(
        (item) => item.is_multyTransactionCheck === true
    );
    const hasMultyTransactionCommon = finaldata.some(
        (item) => item.is_multyTransactionCommon === true
    );
    const hasMultyTransactionHeader = finaldata.some(
        (item) => item.is_multyTransactionHeader === true
    );
    const hasMultyTransactionNormal = finaldata.some(
        (item) =>
            item.is_multyTransactionCheck === false &&
            item.is_multyTransactionCommon === false &&
            item.is_multyTransactionHeader === false
    );
    const checkAccordion = document.getElementById("collapseOne");
    const commonAccordion = document.getElementById("collapseTwo");
    const headerAccordion = document.getElementById("collapseFive");
    const normalAccordion = document.getElementById("collapseThree");
    const handleMouseMove = (e) => {
        if (isResize) {
            const containerWidth = window.innerWidth; // Get the total width of the viewport
            const newLeftWidth = (e.clientX / containerWidth) * 100; // Calculate the new width in percentage

            // Clamp the values to avoid overflow
            let checkWidth = 10
            if (state.multyTransaction) { checkWidth = 15; }
            if (newLeftWidth >= checkWidth && newLeftWidth <= 30) {
                setLeftWidth(newLeftWidth);
            }
        }
    };

    const handleMouseUp = () => {
        setIsResize(false);
    };
    useEffect(() => {
        if (isResize) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResize]);

    return (
        <FileSplitMainContainer >
            <Header />
            <>
                {pdfLoaded ?
                    <FileSPlitSkeleton />
                    :
                    <MainDivContainer isVisible={singleImageLoader} className="App">
                        <div className="page-full-freeze"></div>
                        <LeftDivContainer leftWidth={leftWidth}>
                            <BottomClaimContainer>
                                <ClaimHeader><span>SPLIT DETAILS</span>
                                    {state.multyTransaction &&
                                        <Tooltip disableInteractive title={"Remaining Pages"} placement='top' arrow>
                                            <div className="d-flex align-items-center">
                                                <b>{`${selectedPages.length - allpages.length} / ${selectedPages.length}`}</b>
                                            </div>
                                        </Tooltip>
                                    }
                                </ClaimHeader>
                                {tabSwich == 0 && !state.multyTransaction ? (
                                    <div className="claim-list-container">
                                        {submitLoader ?
                                            <SPlitListSkeleton />
                                            :
                                            <>
                                                {finaldata?.map((i, index) => {
                                                    return !i.is_multyTransactionCheck &&
                                                        !i.is_multyTransactionCommon &&
                                                        !i.is_multyTransactionHeader && (
                                                            <SingleSplitComponent id={i.id} index={index} isActive={""} item={i} />
                                                        )
                                                }
                                                )}
                                            </>
                                        }

                                    </div>
                                ) : !state.multyTransaction ? (
                                    <div style={{ position: 'relative' }}>
                                        {togglePopup && (
                                            <ModalContainer isVisible="flex">
                                                <ModalContent >
                                                    <ModalTopBox>
                                                        <Icon className="bi bi-exclamation-circle-fill"></Icon>
                                                    </ModalTopBox>
                                                    <h4>Check Not Selected</h4>
                                                    <ModalBottomBox>
                                                        <Button className="btn-countinue" onClick={() => (!isEdit ? finalBreak() : handleEditActualBreak())}>Continue</Button>
                                                        <Button className="cncl-btn" onClick={() => setTogglePopup(false)}>Cancel</Button>
                                                    </ModalBottomBox>
                                                </ModalContent>
                                            </ModalContainer>

                                        )}
                                        <div
                                            onWheel={(event) => {
                                                if (event.ctrlKey) {
                                                    if (event.deltaY > 0) {
                                                        setInitialWidthValue((prevWidth) => prevWidth > 100 ? Math.max(prevWidth - 50, 50) : prevWidth); // Ensures a minimum width

                                                    } else if (event.deltaY < 0) {

                                                        setInitialWidthValue((prevWidth) => prevWidth < 550 ? prevWidth + 50 : prevWidth); // Increment width
                                                    }
                                                }
                                            }}
                                            className="dragable-container">
                                            <DragContainer

                                                handleRotate={handleRotate}
                                                initialWidthValue={initialWidthValue}
                                                canvasDetails={canvasDetails}
                                                setActiveCheckID={setActiveCheckID}
                                                setCheckPopup={setCheckPopup}
                                                swapDiv={swapDiv}
                                                allpages={allpages}
                                                dragType={null}
                                                addedElemnts={addedElemnts}
                                                setAddedElemnts={setAddedElemnts}
                                                formik={formik}
                                                finaldata={finaldata}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                {checkPopup && (
                                    <Draggable position={controlledPosition} onDrag={__onControlledDrag} cancel="input">
                                        <div className="check-details-modal">
                                            <Form
                                                autoComplete={"off"}
                                                noValidate
                                                onSubmit={formik.handleSubmit}
                                            >
                                                <h4 className="text-center">Check Details</h4>
                                                <div className="d-flex check-modal-text-box">
                                                    <label>Check Number:</label>
                                                    <Form.Control
                                                        className="b-r-10"
                                                        name="accountNumber"
                                                        isInvalid={!!formik.errors?.accountNumber}
                                                        value={formik.values?.accountNumber}
                                                        onChange={(e) => {
                                                            handleInputChange(e, "accountNumber");
                                                            formik.handleChange(e);
                                                        }}
                                                        type="text"
                                                        placeholder="Check Number"
                                                    />
                                                    <label>Check Date:</label>
                                                    <InputMask
                                                        mask="99/99/9999"
                                                        className={
                                                            formik.errors?.checkDate
                                                                ? "border-danger form-control b-r-10 is-invalid"
                                                                : "form-control b-r-10"
                                                        }
                                                        onChange={(e) => {
                                                            handleInputChange(e, "checkDate");
                                                        }}
                                                        // isInvalid={!!formik.errors?.checkDate}
                                                        value={formik.values?.checkDate}
                                                        name="checkDate"
                                                        isInvalid={!!formik.errors?.checkDate}

                                                        placeholder={"mm/dd/yyyy"}
                                                    />
                                                    <label>Check Amount:</label>
                                                    <Form.Control
                                                        onBlur={(e) => {
                                                            if (e.target.value) {
                                                                formik.setValues({
                                                                    ...formik.values,
                                                                    checkAmount: parseFloat(
                                                                        e.target.value
                                                                    ).toFixed(2),
                                                                });
                                                                setChekDetail({
                                                                    ...chekDetail,
                                                                    checkAmount: e.target.value,
                                                                });
                                                            } else {
                                                                null;
                                                            }
                                                        }}
                                                        className="b-r-10"
                                                        name="checkAmount"
                                                        isInvalid={!!formik.errors?.checkAmount}
                                                        value={formik.values?.checkAmount}
                                                        onChange={(e) => {
                                                            handleInputChange(e, "checkAmount");
                                                        }}
                                                        type="number"
                                                        placeholder="Check Amount"
                                                    />

                                                    <label>ABA Routing Number:</label>
                                                    <Form.Control
                                                        className="b-r-10"
                                                        name="aba_routing_number"
                                                        isInvalid={!!formik.errors?.aba_routing_number}
                                                        value={formik.values?.aba_routing_number}
                                                        onChange={(e) => {
                                                            handleInputChange(e, "aba_routing_number");
                                                            formik.handleChange(e);
                                                        }}
                                                        type="text"
                                                        placeholder="Routing Number"
                                                    />

                                                    <label>Payer Account Number:</label>
                                                    <Form.Control
                                                        className="b-r-10"
                                                        name="payer_account_number"
                                                        isInvalid={
                                                            !!formik.errors?.payer_account_number
                                                        }
                                                        value={formik.values?.payer_account_number}
                                                        onChange={(e) => {
                                                            handleInputChange(e, "payer_account_number");
                                                            formik.handleChange(e);
                                                        }}
                                                        type="text"
                                                        placeholder="Account Number"
                                                    />
                                                </div>
                                                <div className="d-flex modal-bottom-box">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-secondary"
                                                    // onClick={() => handleSubmitCheck()}
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        onClick={() => {
                                                            setCheckPopup(false);
                                                            setActiveCheckID(null);
                                                            formik.resetForm()
                                                            setChekDetail({
                                                                accountNumber: "",
                                                                checkDate: "",
                                                                checkAmount: "",
                                                                aba_routing_number: "",
                                                                payer_account_number: "",
                                                            });
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </Form>
                                        </div>
                                    </Draggable>
                                )}

                                {/* ==============LARGE BATCH============ */}
                                {state.multyTransaction && !isEdit && (
                                    <div className="mb-2">
                                        {!submitLoader ?
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    {selectedPages.length - allpages.length > 0 &&
                                                        !selectedPages.includes(true) && (
                                                            <h2 className="accordion-header" id="headingFour">
                                                                <button
                                                                    className="accordion-button collapsed"
                                                                    type="button"
                                                                    data-bs-toggle={""}
                                                                    data-bs-target="#collapseFour"
                                                                    aria-expanded="false"
                                                                    aria-controls="collapseFour"
                                                                >
                                                                    <div
                                                                        className="d-flex"
                                                                        style={{
                                                                            alignItems: "center",
                                                                            width: "100%",
                                                                            justifyContent: "space-between",
                                                                            margin: "unset",
                                                                        }}
                                                                    >
                                                                        <h6
                                                                            style={{ width: "50%", margin: "unset" }}
                                                                        >
                                                                            Split Type
                                                                        </h6>

                                                                        <Form.Select
                                                                            style={{ width: "40%" }}
                                                                            value={state.autoManual}
                                                                            name="autoManual"
                                                                            className="b-r-10 form-control-solid form-control"
                                                                            onChange={(e) => {
                                                                                handleAutoManualChange(e);
                                                                            }}
                                                                        >
                                                                            <option value={1}>Manual</option>
                                                                            <option value={2}>Auto</option>
                                                                        </Form.Select>
                                                                        {state.autoManual == 2 && (
                                                                            <Form.Control
                                                                                style={{ width: "25%" }}
                                                                                className="ms-2 b-r-10 form-control-solid form-control"
                                                                                name="autoPage"
                                                                                value={state.autoPage ? Math.floor(state.autoPage) : ""}
                                                                                onKeyUp={(e) => {
                                                                                    handleEnterAutoSplit(e);
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    handleAutoManualChange(e);
                                                                                }}
                                                                                type="number"
                                                                                placeholder="Page"
                                                                            />
                                                                        )}
                                                                    </div>
                                                                </button>
                                                            </h2>
                                                        )}
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button
                                                            onClick={(e) => {
                                                                handleMultyTransactionChange(e, "check");
                                                            }}
                                                            className="accordion-button"
                                                            type="button"
                                                            data-bs-toggle={
                                                                selectedPages.includes(true) ||
                                                                    (checkAccordion?.classList?.contains("show") &&
                                                                        selectedPages.includes(true))
                                                                    ? "" :
                                                                    (normalAccordion?.classList?.contains(
                                                                        "show"
                                                                    ) &&
                                                                        !selectedPages.includes(true)) && isMultiNormalEdit
                                                                        ? ""

                                                                        : "collapse"
                                                            }
                                                            data-bs-target="#collapseOne"
                                                            aria-expanded={state.isCheck}
                                                            aria-controls="collapseOne"
                                                        >
                                                            <h6 style={{ width: "50%", margin: "unset" }}>
                                                                Check
                                                            </h6>
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseOne"
                                                        className="accordion-collapse collapse show"
                                                        aria-labelledby="headingOne"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            {!addedElemnts.splitedFiles.find((e) => e.is_multyTransactionCheck) &&
                                                                !selectedPages.includes(true) &&
                                                                "Please Select A Check"}

                                                            <div
                                                                onWheel={(event) => {
                                                                    if (event.ctrlKey) {
                                                                        if (event.deltaY > 0) {
                                                                            setInitialWidthValue((prevWidth) => prevWidth > 100 ? Math.max(prevWidth - 50, 50) : prevWidth); // Ensures a minimum width

                                                                        } else if (event.deltaY < 0) {

                                                                            setInitialWidthValue((prevWidth) => prevWidth < 550 ? prevWidth + 50 : prevWidth); // Increment width
                                                                        }
                                                                    }
                                                                }}
                                                                className="dragable-container">
                                                                <DragContainer

                                                                    handleRotate={handleRotate}
                                                                    initialWidthValue={initialWidthValue}
                                                                    canvasDetails={canvasDetails}
                                                                    dragType={"multy_check"}
                                                                    setActiveCheckID={setActiveCheckID}
                                                                    setCheckPopup={setCheckPopup}
                                                                    swapDiv={swapDiv}
                                                                    allpages={allpages}
                                                                    addedElemnts={addedElemnts}
                                                                    setAddedElemnts={setAddedElemnts}
                                                                    formik={formik}
                                                                    finaldata={finaldata}
                                                                    handleEditCheck={handleEditCheck}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Check Accordian End Heare  */}

                                                {/* Header Accordian Start Heare  */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingFive">
                                                        <button
                                                            onClick={(e) =>
                                                                handleMultyTransactionChange(e, "headerpage")
                                                            }
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle={
                                                                selectedPages.includes(true) ||
                                                                    (headerAccordion?.classList?.contains("show") &&
                                                                        selectedPages.includes(true))
                                                                    ? "" :
                                                                    (normalAccordion?.classList?.contains(
                                                                        "show"
                                                                    ) &&
                                                                        !selectedPages.includes(true)) && isMultiNormalEdit
                                                                        ? ""
                                                                        : "collapse"
                                                            }
                                                            data-bs-target="#collapseFive"
                                                            aria-expanded={state.isHeaderPage}
                                                            aria-controls="collapseFive"
                                                        >
                                                            <h6 style={{ width: "50%", margin: "unset" }}>
                                                                Header
                                                            </h6>
                                                            {finaldata.length > 0
                                                                ? finaldata.map((i, index) => {
                                                                    return (
                                                                        i.is_multyTransactionHeader &&
                                                                        !selectedPages.includes(true) && (
                                                                            <div
                                                                                style={{
                                                                                    width: "50%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "end",
                                                                                }}
                                                                                className="list-actions"
                                                                            ></div>
                                                                        )
                                                                    );
                                                                })
                                                                : null}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseFive"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="headingFive"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            <div
                                                                onWheel={(event) => {
                                                                    if (event.ctrlKey) {
                                                                        if (event.deltaY > 0) {
                                                                            setInitialWidthValue((prevWidth) => prevWidth > 100 ? Math.max(prevWidth - 50, 50) : prevWidth); // Ensures a minimum width

                                                                        } else if (event.deltaY < 0) {

                                                                            setInitialWidthValue((prevWidth) => prevWidth < 550 ? prevWidth + 50 : prevWidth); // Increment width
                                                                        }
                                                                    }
                                                                }}
                                                                className="dragable-container">
                                                                <DragContainer

                                                                    handleRotate={handleRotate}
                                                                    initialWidthValue={initialWidthValue}
                                                                    canvasDetails={canvasDetails}
                                                                    dragType={"multy_header"}
                                                                    setActiveCheckID={setActiveCheckID}
                                                                    setCheckPopup={setCheckPopup}
                                                                    swapDiv={swapDiv}
                                                                    allpages={allpages}
                                                                    addedElemnts={addedElemnts}
                                                                    setAddedElemnts={setAddedElemnts}
                                                                    formik={formik}
                                                                    finaldata={finaldata}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Header Accordian End Heare  */}

                                                {/* Footer Accordian Start Heare  */}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button
                                                            onClick={(e) =>
                                                                handleMultyTransactionChange(e, "commonpage")
                                                            }
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle={
                                                                selectedPages.includes(true) ||
                                                                    (commonAccordion?.classList?.contains("show") &&
                                                                        selectedPages.includes(true))
                                                                    ? "" :
                                                                    (normalAccordion?.classList?.contains(
                                                                        "show"
                                                                    ) &&
                                                                        !selectedPages.includes(true)) && isMultiNormalEdit
                                                                        ? ""
                                                                        : "collapse"
                                                            }
                                                            data-bs-target="#collapseTwo"
                                                            aria-expanded={state.isCommonPage}
                                                            aria-controls="collapseTwo"
                                                        >
                                                            <h6 style={{ width: "50%", margin: "unset" }}>
                                                                Footer
                                                            </h6>
                                                            {finaldata.length > 0
                                                                ? finaldata.map((i, index) => {
                                                                    return (
                                                                        i.is_multyTransactionCommon &&
                                                                        !selectedPages.includes(true) && (
                                                                            <div
                                                                                style={{
                                                                                    width: "50%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "end",
                                                                                }}
                                                                                className="list-actions"
                                                                            >

                                                                            </div>
                                                                        )
                                                                    );
                                                                })
                                                                : null}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseTwo"
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby="headingTwo"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">

                                                            <div
                                                                onWheel={(event) => {
                                                                    if (event.ctrlKey) {
                                                                        if (event.deltaY > 0) {
                                                                            setInitialWidthValue((prevWidth) => prevWidth > 100 ? Math.max(prevWidth - 50, 50) : prevWidth); // Ensures a minimum width

                                                                        } else if (event.deltaY < 0) {

                                                                            setInitialWidthValue((prevWidth) => prevWidth < 550 ? prevWidth + 50 : prevWidth); // Increment width
                                                                        }
                                                                    }
                                                                }}
                                                                className="dragable-container">
                                                                <DragContainer

                                                                    handleRotate={handleRotate}
                                                                    initialWidthValue={initialWidthValue}
                                                                    canvasDetails={canvasDetails}
                                                                    dragType={"multy_common"}
                                                                    setActiveCheckID={setActiveCheckID}
                                                                    setCheckPopup={setCheckPopup}
                                                                    swapDiv={swapDiv}
                                                                    allpages={allpages}
                                                                    addedElemnts={addedElemnts}

                                                                    setAddedElemnts={setAddedElemnts}
                                                                    formik={formik}
                                                                    finaldata={finaldata}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Footer Accordian End Heare  */}

                                                {/* Transaction Accordian Start Heare  */}

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button
                                                            onClick={(e) =>
                                                                handleMultyTransactionChange(e, "transaction")
                                                            }
                                                            className="accordion-button collapsed"
                                                            type="button"
                                                            data-bs-toggle={
                                                                !normalAccordion?.classList?.contains("show") &&
                                                                    !checkAccordion?.classList?.contains("show") &&
                                                                    !commonAccordion?.classList?.contains("show") &&
                                                                    !headerAccordion?.classList?.contains("show")
                                                                    ? "collapse" :
                                                                    (normalAccordion?.classList?.contains(
                                                                        "show"
                                                                    ) &&
                                                                        !selectedPages.includes(true)) && isMultiNormalEdit
                                                                        ? ""
                                                                        : selectedPages.includes(true) ||
                                                                            (normalAccordion?.classList?.contains(
                                                                                "show"
                                                                            ) &&
                                                                                selectedPages.includes(true))
                                                                            ? ""
                                                                            : "collapse"
                                                            }
                                                            data-bs-target="#collapseThree"
                                                            aria-expanded={state.isCommonPage}
                                                            aria-controls="collapseThree"
                                                        >
                                                            <h6 style={{ width: "50%", margin: "unset" }}>
                                                                Transactions
                                                            </h6>
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="collapseThree"
                                                        className="transaction-collapseThree accordion-collapse collapse"
                                                        aria-labelledby="headingThree"
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            {finaldata.length > 0 && !isMultiNormalEdit
                                                                ? finaldata.map((i, index) => {
                                                                    return (
                                                                        !i.is_multyTransactionCommon &&
                                                                        !i.is_multyTransactionCheck &&
                                                                        !i.is_multyTransactionHeader && (
                                                                            <div className="split-list-items p-2 mb-2">

                                                                                <div className="text-center d-flex flex-wrap">
                                                                                    {i.pages.map((j, ind) => {
                                                                                        const isNotLastIndex = ind !== i.pages.length - 1;
                                                                                        return (
                                                                                            <h5 key={ind} className="ms-2">
                                                                                                {/* {ind + 1} */}
                                                                                                {(trans_count += 1)}
                                                                                                {isNotLastIndex && ","}
                                                                                            </h5>
                                                                                        )
                                                                                    }

                                                                                    )}
                                                                                </div>
                                                                                {/* {hasMultyTransactionCheck && */}

                                                                                <div className="list-actions">
                                                                                    <span
                                                                                        className="me-2"
                                                                                        onClick={() => {
                                                                                            setIsMultiNormalEdit(true),
                                                                                                setIsMultiCheckEdit(false),
                                                                                                setIsMultiCommonEdit(false),
                                                                                                setIsMultiHeaderEdit(false),
                                                                                                handleEditListedPdfAuto(
                                                                                                    i,
                                                                                                    index
                                                                                                );
                                                                                        }}
                                                                                    >
                                                                                        <i className="bi bi-pencil-square"></i>
                                                                                    </span>
                                                                                    <span
                                                                                        onClick={(e) =>
                                                                                            handleAutoRemoveListedPdf(i, e)
                                                                                        }
                                                                                    >
                                                                                        <i className="bi bi-trash"></i>
                                                                                    </span>
                                                                                </div>
                                                                                {/* } */}
                                                                            </div>
                                                                        )
                                                                    );
                                                                })
                                                                : null}

                                                            {addedElemnts.splitedFiles.some(
                                                                (i) =>
                                                                    !i.is_multyTransactionCommon &&
                                                                    !i.is_multyTransactionCheck &&
                                                                    !i.is_multyTransactionHeader
                                                            ) && (
                                                                    <div
                                                                        onWheel={(event) => {
                                                                            if (event.ctrlKey) {
                                                                                if (event.deltaY > 0) {
                                                                                    setInitialWidthValue((prevWidth) => prevWidth > 100 ? Math.max(prevWidth - 50, 50) : prevWidth); // Ensures a minimum width

                                                                                } else if (event.deltaY < 0) {

                                                                                    setInitialWidthValue((prevWidth) => prevWidth < 550 ? prevWidth + 50 : prevWidth); // Increment width
                                                                                }
                                                                            }
                                                                        }}

                                                                        className="dragable-container">
                                                                        <DragContainer

                                                                            handleRotate={handleRotate}
                                                                            initialWidthValue={initialWidthValue}
                                                                            canvasDetails={canvasDetails}
                                                                            dragType={"normal"}
                                                                            setActiveCheckID={setActiveCheckID}
                                                                            setCheckPopup={setCheckPopup}
                                                                            swapDiv={swapDiv}
                                                                            allpages={allpages}
                                                                            addedElemnts={addedElemnts}
                                                                            setAddedElemnts={setAddedElemnts}
                                                                            formik={formik}
                                                                            finaldata={finaldata}
                                                                        />
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Transaction Accordian End Heare  */}
                                            </div> :
                                            <LargeBatchSPlitSkeleton />}
                                    </div>
                                )}
                                {/* ==============LARGE BATCH END============ */}
                            </BottomClaimContainer>

                        </LeftDivContainer>
                        <ResizeLine onMouseDown={(e) => {
                            setIsResize(true)

                        }}>

                        </ResizeLine>
                        <RightDivContainer onWheel={(event) => {
                            if (event.ctrlKey) {
                                console.log(event)
                                if (event.deltaY > 0) {
                                    // setZoomValue((prevScale) => prevScale > 100 ? prevScale - 100 : 100);
                                    handleZoomIn()

                                } else if (event.deltaY < 0) {
                                    handleZoomOut()
                                    // setZoomValue((prevScale) => prevScale + 100);
                                }
                            }

                        }} leftWidth={leftWidth} >
                            <ButtonIconConatiner isOpen={signleView.isOpen}>

                                < Tooltip disableInteractive title={"Back"} placement='top' arrow>

                                    <button className="button-round-style"
                                        onClick={() => {
                                            !goBackLoader ?
                                                handleGoBack()
                                                : null
                                        }}
                                    >
                                        <FaArrowLeft />
                                    </button>
                                </Tooltip>
                                {!state.multyTransaction &&
                                    < Tooltip disableInteractive title={tabSwich == 1 ? "Grid" : "List"} placement='top' arrow>

                                        <button disabled={isEdit || selectedPages.includes(true)} className="button-round-style" onClick={() => {
                                            setTabSwich(prevState => prevState == 1 ? 0 : 1)
                                        }}>
                                            {tabSwich == 1 ?
                                                <BsFillGrid3X3GapFill />
                                                : <FaList />}
                                        </button>
                                    </Tooltip>
                                }
                                < Tooltip disableInteractive title={"Zoom Out"} placement='top' arrow>

                                    <button className="button-round-style" onClick={() => {
                                        handleZoomIn()
                                    }}>
                                        <FiZoomOut />
                                    </button>
                                </Tooltip>

                                < Tooltip disableInteractive title={"Zoom In"} placement='top' arrow>
                                    <button className="button-round-style" onClick={() => {
                                        handleZoomOut()
                                    }} >
                                        <FiZoomIn />
                                    </button>
                                </Tooltip>

                                <Tooltip disableInteractive title={"Left"} placement='top' arrow>
                                    <button className="button-round-style" onClick={() => {
                                        handleRotate("left")
                                    }}>
                                        <FaArrowRotateLeft />
                                    </button>
                                </Tooltip>
                                < Tooltip disableInteractive title={"Right"} placement='top' arrow>

                                    <button className="button-round-style" onClick={() => {
                                        handleRotate("right")

                                    }}>
                                        <FaArrowRotateRight />
                                    </button>
                                </Tooltip>
                                <Tooltip disableInteractive title="Normal/Large batch split" placement="top">

                                    <div className='custome-toggle-switch-btn ms-2 me-2'>
                                        <label class="switch">
                                            <input onChange={(e) => { handleSwichAutoMaticSplit(e) }} className="form-check-input height-25" type="checkbox" defaultChecked={state.multyTransaction} role="switch" id="flexSwitchCheckDefault" />

                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </Tooltip>


                                <SaveButton
                                    type='submit' onClick={() => setErrorAlert({ status: "reset", type: null, message: null })}>
                                    Reset
                                </SaveButton>
                                {/* <SaveButton disabled={false} type='submit' > */}

                                {totalpf != 0 && totalpf === allpages.length ? (
                                    <>
                                        {!submitLoader ? (
                                            <SaveButton
                                                className=""
                                                onClick={() => handlePdfSubmit()}
                                            >
                                                Save
                                            </SaveButton>
                                        ) : (
                                            <SaveButton
                                                className=""
                                            >
                                                <div
                                                    className="spinner-border spinner-border-sm text-light"
                                                    role="status"
                                                >
                                                </div>
                                            </SaveButton>
                                        )}
                                    </>
                                ) : (
                                    tabSwich == 1 &&
                                    selectedPages.includes(true) && (
                                        <SaveButton
                                            className=""
                                            onClick={() => finalbreakButtonClick()}
                                        >
                                            {!isEdit ? "Break" : "Update"}
                                        </SaveButton>
                                    )
                                )}
                                {/* </SaveButton> */}
                            </ButtonIconConatiner>
                            <div

                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    justifyContent: signleView.isOpen ? "center" : 'left',
                                    overflowY: 'scroll',
                                    paddingLeft: signleView.isOpen ? '5px' : '15px',
                                    paddingRight: signleView.isOpen ? '5px' : 'unset',
                                    gap: '25px',
                                    maxHeight: "90vh",
                                    minHeight: "20vh"
                                }}
                                id="main-canavs-container"
                            >

                                {canvasDetails.map((canvas, index) => {

                                    return signleView.isOpen && selectedCanavsIndex == index ?
                                        <MainSingleContainer
                                            zoomWidth={singleZoomValue > 100 ? 100 : singleZoomValue}
                                            rotateVal={canvas.rotate}
                                            rotateHeight={Math.abs(canvas.rotate) === 90 || Math.abs(canvas.rotate) === 270 ? `${zoomValue}%` : "auto"}
                                        >
                                            <CanvasContainer
                                                countBoxColor={"white"}
                                                className="common-list-rectangle-box"
                                                data-id={canvas?.canvas?.getAttribute("data-id")}
                                                id={`${canvas?.canvas?.getAttribute("data-id")}-rectangle-box`}

                                                style={{
                                                    position: "relative",
                                                    width: '100%',
                                                    transition: "transform 0.5s ease-in-out",
                                                }}
                                            >
                                                <img
                                                    draggable={false}
                                                    src={canvas.image}
                                                    alt={`Canvas ${index}`}
                                                    style={{
                                                        border: "1px solid #000",
                                                        display: "block",
                                                        width: "100%",
                                                        height: "100%",
                                                        transition: "transform 0.6s ease-in-out 0s",
                                                        userSelect: "none",
                                                        boxShadow: activePage.length > 0 && activePage?.some(i => i == canvas?.canvas?.getAttribute("data-id")) === true ? '#65a1b4 1px 0px 3px 4px' : 'unset'
                                                    }}

                                                />

                                            </CanvasContainer>
                                        </MainSingleContainer>
                                        : !signleView.isOpen && !allpages.includes(index) ?
                                            <MainSingleContainer
                                                zoomWidth={() => {
                                                    let finAlZoomVal = zoomValue
                                                    if (canvas.rotate == 270 || canvas.rotate == 90) {
                                                        if (canvas.page_size) {
                                                            finAlZoomVal = zoomValue / 2
                                                        }
                                                    }

                                                    return finAlZoomVal
                                                }}
                                                rotateVal={canvas.rotate}
                                            >
                                                <span style={{ fontWeight: 'bold' }}>{canvas?.id}</span>
                                                <BlurBackground isVisible={() => {
                                                    const selectedIndex = selectedPages.reduce((acc, value, ind) => {
                                                        if (value) {
                                                            acc.push(ind);
                                                        }
                                                        return acc;
                                                    }, []);
                                                    return selectedIndex?.some((i) => i == index) && singleImageLoader ? "flex" : "none"
                                                }}>
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </BlurBackground>

                                                <CanvasContainer
                                                    draggable={activePage.length > 0 && activePage.includes(canvas?.canvas?.getAttribute("data-id"))}
                                                    onDragStart={activePage.length > 0 ? dragStart : null}
                                                    onDragOver={activePage.length > 0 ? dragOver : null}
                                                    onDragEnter={activePage.length > 0 ? dragEnter : null}
                                                    onDragLeave={activePage.length > 0 ? dragLeave : null}
                                                    onDrop={activePage.length > 0 ? dragDrop : null}
                                                    onDragEnd={activePage.length > 0 ? dragEnd : null}
                                                    countBoxColor={"white"}
                                                    className="common-list-rectangle-box"
                                                    data-id={canvas?.canvas?.getAttribute("data-id")}
                                                    id={`${canvas?.canvas?.getAttribute("data-id")}-rectangle-box`}
                                                    onClick={(e) => {
                                                        onSelectPage(e, null, index);
                                                        showTransactionTab();
                                                    }}
                                                    onDoubleClick={() => {
                                                        const id = canvas?.canvas?.getAttribute("data-id")
                                                        setSingleView({
                                                            id,
                                                            isOpen: true,
                                                            index,
                                                        })

                                                    }}

                                                    style={{
                                                        position: "relative",
                                                        width: '100%',
                                                        transition: "transform 0.5s ease-in-out",
                                                    }}
                                                >




                                                    <img
                                                        draggable={false}
                                                        src={canvas.image}
                                                        alt={`Canvas ${index}`}

                                                        style={{
                                                            border: "1px solid #000",
                                                            display: "block",
                                                            width: "100%",
                                                            height: "100%",
                                                            transition: "transform 0.6s ease-in-out 0s",
                                                            userSelect: "none",
                                                            boxShadow: activePage.length > 0 && activePage?.some(i => i == canvas?.canvas?.getAttribute("data-id")) === true ? '#65a1b4 1px 0px 3px 4px' : 'unset'
                                                        }}

                                                    />
                                                    <div className="check-box-container">
                                                        <input type="checkbox" checked={selectedPages[index] || false}
                                                            onClick={(e) => {
                                                                if (!e.shiftKey) {
                                                                    e.stopPropagation();
                                                                    swapDiv(index);
                                                                }
                                                                showTransactionTab();
                                                            }} />
                                                    </div>
                                                </CanvasContainer>
                                            </MainSingleContainer>
                                            : null

                                })}
                            </div>
                        </RightDivContainer>

                        {errorAlert.status == "reset" &&

                            <SweetAlert
                                warning
                                closeOnClickOutside={false}
                                showCancel={true}
                                confirmBtnText={"Yes"}
                                cancelBtnText="No"
                                confirmBtnBsStyle="primary"
                                cancelBtnBsStyle="light"
                                title={"Are you sure?"}
                                focusCancelBtn
                                onConfirm={() => {
                                    handleAllPdfReset()
                                    setErrorAlert({
                                        status: false,
                                        message: null,
                                        type: null,

                                    })
                                }}
                                onCancel={() => {
                                    setErrorAlert({
                                        status: false,
                                        message: null,
                                        type: null,

                                    })
                                }}

                            >
                                {errorAlert.message}
                            </SweetAlert>}

                    </MainDivContainer>
                }
                {isSwal && (
                    <SweetAlert
                        error
                        confirmBtnText={swalContent.text}
                        confirmBtnBsStyle={swalContent.icon}
                        title={swalContent.title}
                        onConfirm={swalContent.onConfirm}
                        showCancel={swalContent.showCancel}
                        onCancel={swalContent.onCancel}
                        cancelBtnText="Cancel"
                        cancelBtnBsStyle={'danger'}



                    ></SweetAlert>
                )}
            </>
        </FileSplitMainContainer >
    )
}

const FileSplitMainContainer = styled.div`
height: 100vh;

`;
const MainDivContainer = styled.div`
  position: relative;

  display: flex;
  position: relative;
  margin-top: 10px;
  padding-left: 20px;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* WebKit/Blink browsers */
  -moz-user-select: none; /* Gecko browsers */
  -ms-user-select: none; /* Internet Explorer/Edge */
.page-full-freeze{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: ${(props) => props.isVisible ? "block" : "none"};
  :hover{
    cursor: not-allowed;
  }
;
}
`;
const LeftDivContainer = styled.div`
  width: ${(props) => props.leftWidth}%;
`;
const RightDivContainer = styled.div`
  width: ${(props) => 100 - props.leftWidth}%;

`;
const ResizeLine = styled.div`
    width 5px;
    border-right:3px solid white;
    cursor:col-resize;
    box-shadow: rgb(232, 232, 232) 4px 4px 0px 0px;
`
const BottomClaimContainer = styled.div`
  height: 88vh;
  margin-top: 10px;
  box-shadow: 0px 1px 2px 1px rgb(232 232 232);
  border-radius: 5px;
  padding: 10px 5px;
  .claim-list-container{
    height: 80vh;
    overflow-y: scroll;

  }
`;
const ClaimHeader = styled.div`
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
  color: #036b87;
  display:flex;
  align-items: center;
  justify-content: center;
  gap: 35px;
`;
const SplitSingleDivBox = styled.div`
    border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px;
  position: relative;
  border: 1px solid;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  background: green;
  
  background: ${(props) =>
        props.isActive == "completed"
            ? "linear-gradient(rgba(97, 182, 106, 0.5), rgba(97, 182, 106, 0.5))" : props.isActive == "active" ? "rgb(222, 235, 255);"
                : "transparent"};

color:  ${(props) =>
        props.isActive == "completed"
            ? "white" : props.isActive == "active" ? "black"
                : "black"};

  :hover{
    background: rgb(222, 235, 255);;
    color: black;
  }
  .icons-list{
    svg{
        font-size:20px;
        color: #646464;
    }
  }
`;
const MainSingleContainer = styled.div`
position: relative;
  /* width: 200px; */
  transition: transform 0.5s ease-in-out;
  width: ${(props) => props.zoomWidth}%;
  /* height: ${(props) => props.rotateHeight}; */

  /* height: 200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loader-container{
    width: 100%;
    height: 100%;
    background-color: black;
  }
`
const CanvasReactangleBox = styled.div`
  position: absolute;
`;
const BlurBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.5)
  );
  z-index: 1;
  display: ${(props) => props.isVisible};
  align-items: center;
  justify-content: center;
  color: white;
`;
const CanvasContainer = styled.div`
    .dragging {
        opacity: 0.5;
    }

    .selected {
        border: 1px solid blue !important;
    }
  img{
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  .rectangle-box {
    position: absolute;
    top: 0;

  }
.common-rectangle-box{
  display: flex;
  align-items: flex-start;
  justify-content: end;
  .order-item-box{
    font-weight: bold;
    font-size: 10px;
    /* width: 5%;
    height: 5%; */
    border-radius: 10%;
    background: linear-gradient(rgb(209 205 205 / 50%), rgb(213 206 206 / 50%));
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.countBoxColor};
  }
}

.order-item-box{
    /* position: absolute;
    top: 50%;
    left:50%; */
    font-weight: bold;
    font-size: 12px;
    /* width: 25px;
    height: 25px; */
    border-radius: 10%;
    background: linear-gradient(rgb(209 205 205 / 50%), rgb(213 206 206 / 50%));
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.countBoxColor};
}
// :hover{
//     border: 2px dotted;
// }
  }
  .hover-drag-line{
  font-size: 60px;
  color:rgb(107, 162, 182);
  height: 100%;
  position: absolute;
  .left-arrow{
    display: none;
    height: 100%;
    width: 14px;

  }
  .right-arrow{
    display: none;
    height: 100%;
    width: 14px;


  }
}
.check-box-container{
    position:absolute;
    right:6px;
    bottom:0;
}
`
const SaveButton = styled.button`   
    color: #fff;
    border-color: #6ba2b6;
    background-color: #6ba2b6 !important;
    font-size: 0.9375rem;
    font-weight: 500;
    border-radius: 5px;
    min-width: 60px;
    height: 30px;
`
const ButtonIconConatiner = styled.div`
  padding-left:15px;
  display: ${(props) => props.isOpen ? "none" : "flex"};
  justify-content: space-between;
  margin-bottom: 10px;
  width: 35%;

  transition: all 0.4s ease-in-out;
  align-items: center;
  button.re-assign{
      width: 95px !important;

    }
  button.save-exit-button{
      width: 95px !important;

    }

    .button-round-style:hover {
    color: #000000;
    box-shadow: 0px 0px 9px #595959;
  }
  .button-round-style {
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    svg {
      font-size: 16px;
    }
  }
`;
const DragDivContainer = styled.div`
/* background: rgba(0, 0, 0, 0.09) */
background: ${(props) => props.isActive ? "rgb(222, 235, 255)" : "rgba(0, 0, 0, 0.09)"} !important;
box-shadow: ${(props) => props.isActive ? "0px 0px 9px #595959" : "unset"} !important;

`

const ModalContainer = styled.div`
 position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.5)
  );
  z-index: 2;
  display: ${(props) => props.isVisible};
  align-items: center;
  justify-content: center;

  h4{
    margin : 20px 0;
  }
  .cncl-btn{
    background-color: rgb(87, 87, 87);
  }
  .btn-countinue{
    background-color: rgb(107, 162, 182);
  }
`;

const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 255px;
`;

const ModalTopBox = styled.div`
  display: flex;
  justify-content: center;
`;

const ModalBottomBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.i`
  font-size: 24px;
`;

const Button = styled.span`
  padding: 8px 20px;
  color: white;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }

`;