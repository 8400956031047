import { GET_DOCUMENT_CLASSIFICAITON_URL, GET_DOCUMENT_CLASSIFICATION_DROP_DOWN_URL, GET_SINGLE_DOCUMENT_CLASSIFICATION_URL, POST_CREATE_UPDATE_DOCUMENT_CLASSIFICAITON_URL } from "../../api";
import axiosInstance from "../../axiosInstance";
import { DELETE_DOCUMENT_CLASSIFICATION } from "../../routeNames";

export const getDocumentList = (page, limit, search) => onResponse => {
    try {

        let data = search ? search : "";
        axiosInstance.get(GET_DOCUMENT_CLASSIFICAITON_URL + "?page=" + page + "&limit=" + limit + "&search=" + data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createDocument = (client, classifications) => onResponse => {
    try {

        axiosInstance.post(POST_CREATE_UPDATE_DOCUMENT_CLASSIFICAITON_URL, { client, classifications })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const updateDocument = (client, id, classification) => onResponse => {
    try {
        axiosInstance.post(POST_CREATE_UPDATE_DOCUMENT_CLASSIFICAITON_URL, {
            client,
            classifications: [{
                id: id, classification: classification,
            }]
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleDocumentDetail = (classification) => onResponse => {
    try {

        axiosInstance.get(GET_SINGLE_DOCUMENT_CLASSIFICATION_URL + "?classification=" + classification)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getDocumentsDropdown = (client) => onResponse => {
    try {

        axiosInstance.get(GET_DOCUMENT_CLASSIFICATION_DROP_DOWN_URL + "?client=" + client)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getDeleteDocument = (id) => onResponse => {
    try {
        axiosInstance.put(DELETE_DOCUMENT_CLASSIFICATION, {
            classifications: id

        })
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {

    }
} 