import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form } from 'react-bootstrap'
import * as Yup from 'yup';
import Select from 'react-select';
import TimePicker from 'react-time-picker';
import { createClaim } from '../../../../../Redux/client/action';
import { useNavigate } from 'react-router-dom';
import { valuesIn } from 'lodash';
import { changeAllTitleFunction } from '../../../Dashboard/functions';
import { CLIENT } from '../../../../../routeNames';


const ClaimForm = (props) => {
    const { claim, __toggleToast, client_data, setLoading, fetchClientDetails, client } = props;
    const [isSubmit, setIsSubmit] = useState(false)
    const navigate = useNavigate()

    const [state, setState] = useState({
        error: false,
        error_message: "",

    });
    useEffect(() => {
        if (props?.claim) {
            claimformik.setValues({
                is_institutional: claim?.is_institutional,
                is_professional: claim?.is_professional,
                is_dental: claim?.is_dental,
                file_transfer_period: claim?.file_transfer_period,
                transfer_time: claim?.transfer_time,
                // data_retention_period: claim?.data_retention_period,
                sftp_server: claim?.sftp_server,
                self_pay: claim?.self_pay,
                address: claim?.sftp_address,
                port: claim?.sftp_port,
                username: claim?.sftp_username,
                password: claim?.sftp_password,
                file_path: claim?.file_path,
            })
        }

    }, [claim]);
    const claimSchema = Yup.object().shape({
        file_format: Yup.string()
            .required('Required'),
        file_transfer_period: Yup.string()
            .required('Required'),
        transfer_time: Yup.string()
            .required('Required'),
        // data_retention_period: Yup.string()
        //     .required('Required'),
        sftp_server: Yup.string()
            .required('Required'),
        address: Yup.string()
            .required('Required'),
        port: Yup.string()
            .required('Required'),
        username: Yup.string()
            .required('Required'),
        password: Yup.string().min(8, 'Password is too short - should be 8 chars minimum.')
            .required('Required'),
    });
    const [togglePassword, setTogglePassword] = useState(false);

    const claimformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            // file_format: "",
            is_dental: false,
            is_institutional: false,
            is_professional: false,
            file_transfer_period: "",
            transfer_time: "",
            self_pay: "",
            // data_retention_period: "",
            sftp_server: "",
            address: "",
            port: "",
            username: "",
            password: "",
            file_path: "",
        },

        validate: values => {
            let errors = {};
            if (values?.file_transfer_period == "0") {
                errors.file_transfer_period = "Required";
            }
            if (values?.file_transfer_period == '1') {
                if (values?.transfer_time == "") {
                    errors.transfer_time = "Required"
                }
            }
            return errors;

        },
        validationSchema: Yup.object().shape({
            is_dental: Yup.bool().oneOf([true, false], 'Select either on of these'),
            is_institutional: Yup.bool().oneOf([true, false], 'Select either on of these'),
            is_professional: Yup.bool().oneOf([true, false], 'Select either on of these'),

            // file_format: Yup.string()
            //     .required('Required'),
            // file_transfer_period: Yup.string()
            //     .required('Required'),
            // // transfer_time: Yup.string()
            // transfer_time: Yup.string()
            //     .required('Required').nullable(),
            //     .required('Required'),
            // data_retention_period: Yup.string()
            //     .required('Required'),
            sftp_server: Yup.string()
                .required('Required'),
            address: Yup.string()
                .required('Required'),
            port: Yup.string()
                .required('Required'),
            username: Yup.string()
                .required('Required'),
            password: props?.claim ? Yup.string() : Yup.string().required('Required'),
            file_path: Yup.string()
                .required('Required'),
            self_pay: Yup.string().required('Required')
        }),
        onSubmit: (values, { setSubmitting }) => {
            setLoading(true)
            setSubmitting(true)
            let formData = new FormData();    //formdata object
            formData.append('client', !props.client == 0 ? props.client : "");
            if (claim?.id)
                formData.append('claims', claim?.id);
            if (claim?.sftp_id)
                formData.append('sftp_id', claim?.sftp_id);
            formData.append('is_dental', values.is_dental);
            formData.append('is_institutional', values.is_institutional);
            formData.append('is_professional', values.is_professional);
            formData.append('file_transfer_period', values.file_transfer_period);
            if (values.transfer_time)
                formData.append('transfer_time', values.transfer_time);
            // if (values.data_retention_period)
            //     formData.append('data_retention_period', values.data_retention_period);
            formData.append('self_pay', values.self_pay);
            formData.append('sftp_server', values.sftp_server);
            formData.append('sftp_address', values.address);
            formData.append('sftp_port', values.port);
            formData.append('sftp_username', values.username);
            formData.append('sftp_password', values.password);
            formData.append('file_path', values.file_path);

            createClaim({ formData })((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    __toggleToast("success", response?.message, true)
                    //setIsSubmit(true)
                    setLoading(false)
                    setSubmitting(false)
                    fetchClientDetails()
                } else {
                    const errors = response.response?.data?.errors;
                    claimformik.setErrors({ errors })
                    setLoading(false)
                    setSubmitting(false)
                    __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                }
            });
        },
    });

    // const __handleclaimSubmit = (values) => {
    //     // let formData = new FormData();    //formdata object

    //     const data = values;
    //     createclaim({ data })((response) => {
    //         if (!response?.data?.status && response?.status && response?.status_code === 201) {

    //         } else {
    //             const errors = response.response?.data?.errors;
    //             claimformik.setErrors({ errors })
    //         }
    //     });
    // }


    //options

    const [sftpServerOptions, SetSftpServerOptions] = useState([
        { value: "1", label: 'Client' },
        { value: "2", label: 'Our Own' },
    ]);

    const [fileFormatOpitons, SetFileFormatOptions] = useState([
        { value: "1", label: 'EDI837 Professional' },
        { value: "2", label: 'EDI837 Institutional' },
        { value: "3", label: 'EDI837 Dental' },
    ]);
    const [fileTransferPeriodOptions, SetFileTransferPeriodOptions] = useState([
        { value: "1", label: 'Fixed Time' },
        { value: "2", label: 'Any Time' },
    ]);


    const handleOnSelectclaimChange = ({ name, value }) => {
        claimformik.setValues({ ...claimformik.values, [name]: value.value })
        // setRoles(value.map((val) => val.value))
    }
    const handleOnSelectChange = ({ name, value }) => {
        claimformik.setValues({ ...claimformik.values, [name]: value.value })
        // setRoles(value.map((val) => val.value))
    }
    const handleOnTimeChange = ({ name, value }) => {
        claimformik.setValues({ ...claimformik.values, [name]: value ?? "" })
        // setRoles(value.map((val) => val.value))
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    return (
        <React.Fragment>
            <FormikProvider value={claimformik}>

                <Form id='form-1'
                    onSubmit={claimformik.handleSubmit}
                >

                    <div className="mb-3 row" >



                        <Form.Label className='fw-bold clr-heading'>Claim Information</Form.Label>
                        {/* <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'> File Format</Form.Label>
                                <Form.Select
                                    placeholder="Select an Option"

                                    className="form-control form-control-solid b-r-10"
                                    classNamePrefix="select"
                                    name="file_format"
                                    value={claimformik.values?.file_format}
                                    isValid={claimformik.touched?.file_format && !claimformik?.errors?.file_format}
                                    isInvalid={!!claimformik.errors?.errors?.file_format}
                                    required
                                    onChange={claimformik.handleChange}
                                >
                                    <option >Select an Option</option>
                                    {fileFormatOpitons && fileFormatOpitons?.length > 0 && fileFormatOpitons?.map((option, i) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                                {!!claimformik.errors?.file_format && !claimformik.touched?.file_format &&
                                    <div className='text-danger  py-1'>

                                        {claimformik?.errors?.file_format}
                                    </div>
                                }
                            </Form.Group>
                        </div> */}
                        <Form.Group>
                            <div className='mb-3 pb-3 row border-bottom-dashed'>
                                <div className='col-lg-3'>
                                    <Form.Check
                                        type="checkbox"
                                        onChange={claimformik.handleChange}
                                        checked={claimformik?.values?.is_dental}
                                        isInvalid={claimformik.errors?.is_dental && !!claimformik.errors?.is_dental}
                                        feedbackType="invalid"
                                        feedback={claimformik.errors?.is_dental && claimformik.errors?.is_dental}
                                        id="validationFormik0"
                                        name="is_dental"
                                        label="EDI837 Dental"
                                    />
                                </div>
                                <div className='col-lg-3'>

                                    <Form.Check
                                        type="checkbox"
                                        checked={claimformik?.values?.is_institutional}
                                        onChange={claimformik.handleChange}
                                        isInvalid={claimformik.errors?.is_institutional && !!claimformik.errors?.is_institutional}
                                        feedbackType="invalid"
                                        feedback={claimformik.errors?.is_institutional && claimformik.errors?.is_institutional}
                                        id="validationFormik01"
                                        name="is_institutional"
                                        label="EDI837 Institutional"
                                    />
                                </div>
                                <div className='col-lg-3'>
                                    <Form.Check
                                        type="checkbox"
                                        onChange={claimformik.handleChange}
                                        checked={claimformik?.values?.is_professional}
                                        isInvalid={claimformik.errors?.is_professional && !!claimformik.errors?.is_professional}
                                        feedbackType="invalid"
                                        feedback={claimformik.errors?.is_professional && claimformik.errors?.is_professional}
                                        id="validationFormik20"
                                        name="is_professional"
                                        label="EDI837 Professional"
                                    />
                                </div>

                            </div>
                        </Form.Group>
                    </div>
                    <div className="mb-3 row" >

                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>File Transfer Period</Form.Label>
                                <Form.Select
                                    placeholder="Select an Option"

                                    className="form-control form-control-solid b-r-10"
                                    name="file_transfer_period"
                                    classNamePrefix="select"
                                    value={claimformik.values?.file_transfer_period}
                                    isValid={claimformik.touched?.file_transfer_period && !claimformik?.errors?.file_transfer_period}
                                    isInvalid={!!claimformik.errors?.file_transfer_period}
                                    required
                                    onChange={claimformik.handleChange}
                                // options={fileTransferPeriodOptions}
                                // onChange={(e) => { handleOnSelectChange({ name: 'file_transfer_period', value: e }) }}
                                >
                                    <option value="0" >Select an Option</option>
                                    {fileTransferPeriodOptions && fileTransferPeriodOptions?.length > 0 && fileTransferPeriodOptions?.map((option, i) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                                {/* <Form.Control.Feedback type="invalid"> {claimformik?.errors?.file_transfer_period}</Form.Control.Feedback> */}

                                {!!claimformik.errors?.file_transfer_period && !claimformik.touched?.file_transfer_period &&
                                    <div className='text-danger  py-1'>

                                        {claimformik?.errors?.file_transfer_period}
                                    </div>
                                }
                            </Form.Group>
                        </div>
                        {claimformik.values?.file_transfer_period == "1" &&
                            <React.Fragment>

                                <div className='col-lg-4'>
                                    <Form.Group className="mb-3" >

                                        <Form.Label className='fw-bold'>Transfer Time (UTC)</Form.Label>

                                        <div>
                                            <TimePicker
                                                className="b-r-10 "
                                                disableClock={true}
                                                onChange={(e) => { handleOnTimeChange({ name: "transfer_time", value: e }) }}
                                                value={claimformik.values?.transfer_time} />
                                        </div>
                                        {!!claimformik.errors?.transfer_time && !claimformik.touched?.transfer_time &&
                                            <div className='text-danger  py-1'>

                                                {claimformik?.errors?.transfer_time}
                                            </div>
                                        }
                                    </Form.Group>
                                </div>
                                {/* <div className='col-lg-4'>
                                    <Form.Group className="mb-3" >

                                        <Form.Label className='fw-bold'>Data Retention Period</Form.Label>

                                        <Form.Control
                                            name="data_retention_period"
                                            className="b-r-10 form-control-solid form-control"
                                            onChange={claimformik.handleChange}
                                            isValid={claimformik.touched?.data_retention_period && !claimformik?.errors?.data_retention_period}
                                            isInvalid={!!claimformik.errors?.data_retention_period}
                                            maxLength="255"
                                            placeholder=" Data Retention Period"
                                            required
                                            value={claimformik.values?.data_retention_period}
                                        />

                                        <Form.Control.Feedback type="invalid"> {claimformik?.errors?.data_retention_period}</Form.Control.Feedback>

                                    </Form.Group>
                                </div> */}
                            </React.Fragment>
                        }


                    </div>

                    <div className='row pt-3'>
                        <Form.Label className='fw-bold clr-heading'>Claims SFTP Credentials</Form.Label>
                        <div className='col-lg-3'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>SFTP Server</Form.Label>
                                <Form.Select
                                    placeholder="Select an Option"

                                    className="form-control form-control-solid b-r-10"
                                    classNamePrefix="select"
                                    name="sftp_server"
                                    value={claimformik.values?.sftp_server}
                                    isValid={claimformik.touched?.sftp_server && !claimformik?.errors?.errors?.sftp_server}
                                    isInvalid={!!claimformik.errors?.errors?.sftp_server}
                                    required
                                    onChange={claimformik.handleChange}
                                // options={sftpServerOptions}
                                >
                                    <option >Select an Option</option>
                                    {sftpServerOptions && sftpServerOptions?.length > 0 && sftpServerOptions?.map((option, i) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>

                                {!!claimformik.errors?.sftp_server && !claimformik.touched?.sftp_server &&
                                    <div className='text-danger  py-1'>

                                        {claimformik?.errors?.sftp_server}
                                    </div>
                                }
                            </Form.Group>
                        </div>
                        <div className='col-lg-3'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Address</Form.Label>
                                <Form.Control
                                    name='address'
                                    type="text"
                                    onChange={claimformik.handleChange}
                                    autoComplete="false"
                                    isValid={claimformik.touched?.address && !claimformik?.errors?.address}
                                    isInvalid={!!claimformik.errors?.address}
                                    value={claimformik.values?.address}
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    required
                                    placeholder=" Address"
                                />
                                {!!claimformik.errors?.address && !claimformik.touched?.address &&
                                    <div className='text-danger  py-1'>

                                        {claimformik?.errors?.address}
                                    </div>
                                }
                                {/* <Form.Control.Feedback type="invalid"> Address</Form.Control.Feedback> */}

                            </Form.Group>
                        </div>
                        <div className='col-lg-3'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Port</Form.Label>
                                <Form.Control
                                    name='port'
                                    type="text"
                                    onChange={claimformik.handleChange}
                                    autoComplete="false"
                                    isValid={claimformik.touched?.port && !claimformik?.errors?.port}
                                    isInvalid={!!claimformik.errors?.port}
                                    value={claimformik.values?.port}
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    required
                                    placeholder=" Port"
                                />
                                {/* <Form.Control.Feedback type="invalid"> Port</Form.Control.Feedback> */}
                                {!!claimformik.errors?.port && !claimformik.touched?.port &&
                                    <div className='text-danger  py-1'>

                                        {claimformik?.errors?.port}
                                    </div>
                                }

                            </Form.Group>
                        </div>
                        <div className='col-lg-3'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Username</Form.Label>
                                <Form.Control
                                    name='username'
                                    type="text"
                                    onChange={claimformik.handleChange}
                                    autoComplete="false"
                                    isValid={claimformik.touched?.username && !claimformik?.errors?.username}
                                    isInvalid={!!claimformik.errors?.username}
                                    value={claimformik.values?.username}
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    required
                                    placeholder=" Username"
                                />
                                {!!claimformik.errors?.username && !claimformik.touched?.username &&
                                    <div className='text-danger  py-1'>

                                        {claimformik?.errors?.username}
                                    </div>
                                }
                                {/* <Form.Control.Feedback type="invalid"> Username</Form.Control.Feedback> */}

                            </Form.Group>
                        </div>
                        <div className='col-lg-3'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Password</Form.Label>
                                <div className='input-group claims-password-input'>
                                    <Form.Control
                                        name='password'
                                        type={togglePassword ? "text" : "password"}
                                        onChange={claimformik.handleChange}
                                        autoComplete="false"
                                        isValid={claimformik.touched?.password && !claimformik?.errors?.password}
                                        isInvalid={!!claimformik.errors?.password}
                                        value={claimformik.values?.password}
                                        className=' form-control-solid border-radius-password'
                                        maxLength="255"
                                        placeholder=" Password"
                                    />
                                    <div className=''><button className='btn btn-secondary border-radius-eye' type="button" onClick={() => setTogglePassword(!togglePassword)}><i className={togglePassword ? 'bi bi-eye' : 'bi bi-eye-slash'}></i></button></div>
                                </div>
                                {!!claimformik.errors?.password && !claimformik.touched?.password &&
                                    <div className='text-danger  py-4'>

                                        {claimformik?.errors?.password}
                                    </div>
                                }
                                {/* <Form.Control.Feedback type="invalid"> {claimformik?.errors?.password}</Form.Control.Feedback> */}

                            </Form.Group>
                        </div>
                        <div className='col-lg-3'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Claims Path</Form.Label>
                                <Form.Control
                                    name='file_path'
                                    type="text"
                                    onChange={claimformik.handleChange}
                                    autoComplete="false"
                                    isValid={claimformik.touched?.file_path && !claimformik?.errors?.file_path}
                                    isInvalid={!!claimformik.errors?.file_path}
                                    value={claimformik.values?.file_path}
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    required
                                    placeholder=" Claims Path"
                                />
                                {!!claimformik.errors?.file_path && !claimformik.touched?.file_path &&
                                    <div className='text-danger  py-1'>

                                        {claimformik?.errors?.file_path}
                                    </div>
                                }
                                {/* <Form.Control.Feedback type="invalid"> Claims Path</Form.Control.Feedback> */}

                            </Form.Group>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Self Pay Path</Form.Label>
                                <Form.Control
                                    placeholder="Self Pay Path"

                                    className="form-control form-control-solid b-r-10"
                                    classNamePrefix="select"
                                    name="self_pay"
                                    value={claimformik.values?.self_pay}
                                    isValid={claimformik.touched?.self_pay && !claimformik?.errors?.errors?.self_pay}
                                    isInvalid={!!claimformik.errors?.errors?.self_pay}
                                    required
                                    onChange={claimformik.handleChange}
                                // options={sftpServerOptions}

                                />

                                {!!claimformik.errors?.self_pay && !claimformik.touched?.self_pay &&
                                    <div className='text-danger  py-1'>

                                        {claimformik?.errors?.self_pay}
                                    </div>
                                }
                            </Form.Group>
                        </div>
                    </div>




                    <div >
                        {state.error &&
                            <span className='text-danger'>{state.error_message}</span>
                        }
                    </div>
                    <div className='d-flex flex-row justify-content-end'>
                        <div className=''>
                            <button
                                type='button'
                                className='btn btn-secondary btn-active-primary me-2'
                                onClick={() => { changeAllTitleFunction('Client Setup', CLIENT) }}
                            >
                                Cancel
                            </button>
                        </div>
                        {client_data ? (checkUserPermission('edit_client') &&
                            <div className=''>
                                <button
                                    disabled={!claimformik.isValid || claimformik.isSubmitting}
                                    type='submit'
                                    className={isSubmit ? 'btn btn-light btn-active-primary' : 'btn btn-primary btnHover'}
                                >
                                    {claimformik.isSubmitting ? 'Updating' : 'Update'}
                                </button>
                            </div>
                        ) : (
                            <div className=''>
                                <button
                                    disabled={!claimformik.isValid || claimformik.isSubmitting}
                                    type='submit'
                                    className={isSubmit ? 'btn btn-light btn-active-primary' : 'btn btn-primary btnHover'}
                                >
                                    {claimformik.isSubmitting ? 'Saving' : 'Save'}
                                </button>
                            </div>
                        )
                        }
                    </div>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}

export default ClaimForm