import React from 'react'
import Lottie from 'react-lottie';
import errorLoader from '../assets/animations/error-loader.json'
import logo from '../assets/img/Xtract-logo-final.png'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { IFRAME, INDEX } from '../routeNames';
function ErrorLoader() {
    const Error_Loader = {
        loop: true,
        autoplay: true,
        animationData: errorLoader,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const navigate = useNavigate();
    const [count, setCount] = useState(false)
    function handleRefresh() {


        if (count) {
            navigate(IFRAME)
            setCount(false)
        }
        else {
            // navigate(IFRAME)
            setTimeout(() => {
                window.location.reload()
            }, 200);
        }




    }
    return (
        <React.Fragment>
            <div className='container d-flex flex-column  gap-5 justify-content-center align-items-center align-content-center' style={{ height: '90vh' }}>
                <div className='my-5'>
                    <img src={logo} style={{ height: '100px', }} />
                </div>
                <div className='d-flex flex-column justify-content-center my-2'>
                    <span className=' fw-bolder text-center' style={{ fontSize: '2.8em', color: '#1ba1a7' }}>Oops!</span>
                    <span className=' fw-bolder text-center' style={{ fontSize: '1em', color: '#176c89' }}>Something Went Wrong</span>
                </div>
                <div>

                    <button onClick={() => { setCount(true); handleRefresh() }} className='btn btn-light btn-lg rounded-1 btn-active-primary '>
                        Back to Home
                    </button>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ErrorLoader