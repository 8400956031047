import React, { useEffect, useState } from 'react'
import Master from '../../../../Layout/admin/Master/index'
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import { createPayerIdList, getFacilityDropdown, getPayerDropdown, getPayerDropdownV2, getSinglePayerIdList, getX12CodeDropdown } from '../../../../Redux/user/action';
import { useParams, useNavigate, } from 'react-router-dom';
import { PAYER_ID_LIST } from '../../../../routeNames';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import CheckImageModal from '../../Payee/checkImageModal';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import styled from 'styled-components';


const CreatePayerIdList = () => {
    const navigate = useNavigate();
    const [showCheckView, setShowCheckView] = useState(false)

    const { id, type } = useParams()
    const [payers, setPayers] = useState([])
    const [clients, setClients] = useState([])
    const [facilities, setFacilities] = useState([])
    const [x12codes, setX12Codes] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [data, setData] = useState([])

    const [selectedFacility, setSelectedFacility] = useState([])
    const [state, setState] = useState({
        error: false,
        error_message: "",
        removed_ids: []
    })
    const [toaster, setToaster] = useState(false)

    const [toast, setToast] = useState({
        type: "",
        message: "",
        toast: false,

    });



    const validationSchema = Yup.object().shape({
        clients: Yup.number().required('Required'),
        unique_code: Yup.string()
            .required('Required'),
        data: Yup.array().of(
            Yup.object().shape({
                payer_name: Yup.string().required('Required'),
                payer_id: Yup.string().required('Required'),
            }))
    });

    const payerIdListformik = useFormik({
        initialValues: {
            unique_code: '',
            clients: "",
            data: [{
                payer_name: '',
                payer_id: '',
            }]
        },
        validationSchema,
        validate: (values) => {
            let errors = {}
            return errors
        },
        onSubmit: (values) => {
            __submitForm(values)
        },
    });
    useEffect(() => {
        getAllClientDropdown()((response) => {
            if (response?.status) {
                setClients(response?.data)
            }
        })
    }, []);


    const options = [
        // { value: "all", label: "All" },
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];
    useEffect(() => {
        if (id) {
            getSinglePayerIdList(id)((response) => {

                if (response?.status && response?.data) {
                    const data = [];
                    response.data?.data.length > 0 && response.data?.data?.map((item, index) => (
                        data.push({
                            id: item.id,
                            payer_name: item.payer_name,
                            payer_id: item.payer_id,
                        })
                    ))
                    payerIdListformik.setValues({
                        ...payerIdListformik.values,
                        unique_code: response?.data?.unique_code,
                        data: data,
                        clients: response?.data?.client?.value,

                    })
                    setSelectValue(response?.data?.client)
                    setData(response?.data)
                }
            });

        }
    }, [id])

    const __toggleToast = (type, message, toast) => {
        setToast({
            type: type,
            message: message,
            toast: toast,
        })
        const timer = setTimeout(() => {
            setToast({
                type: false,
                message: '',
                toast: false,
            })
        }, 2000);
        return () => clearTimeout(timer);

    }
    const __submitForm = async (values) => {
        const RedirectId = getRedirectTabId()
        const data_from = 1;
        let formData = new FormData();
        formData.append('unique_code', values.unique_code.toUpperCase())
        formData.append('data', JSON.stringify(values.data))
        formData.append('data_from', data_from)
        if (id) {
            var payer_list_id = id
        } else {
            var payer_list_id = ""

        }
        formData.append('removed_ids', state.removed_ids)
        if (type == 'approve') {

            var is_approved = true
        }
        else {
            var is_approved = false

        }
        await createPayerIdList(values.unique_code.toUpperCase(), values.data, payer_list_id, state.removed_ids, values.clients)((response) => {
            if (response && response.status) {
                __toggleToast("success", response?.message, true);
                setTimeout(() => {

                    changeAllTitleFunction("Payer ID List", PAYER_ID_LIST, RedirectId)
                }, 2000);
            }
            else {
                __toggleToast("error", response?.response?.data?.errors?.description[0] ? response?.response?.data?.errors?.description[0] : "12123123", true)
                return () => clearTimeout(timer);
            }
        });
    }

    const handleRemoveItems = (index, arrayHelpers, value) => {

        let removed_ids = [...state.removed_ids]
        var data = payerIdListformik.values.data;
        data = data.filter((i) => i != value)
        payerIdListformik.setValues({ ...payerIdListformik.values, 'data': data })
        if (value?.id) {
            removed_ids.push(String(value?.id))
            setState({ ...state, removed_ids })
        }
    }

    const handleSelectChange = (selected) => {


        payerIdListformik.setValues({ ...payerIdListformik.values, "clients": selected?.value ?? "" });



        setSelectValue(selected ?? "")
    };

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {payerIdListformik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}
            </components.DropdownIndicator>
        );
    };

    window.onkeyup = function (event) {
        if (event.keyCode === 27) {
            setShowCheckView(false)
        }
    }
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);


    const changeDatavalue = (e) => {
        e.target.value = e.target.value.toUpperCase();
        payerIdListformik.handleChange(e)
    }

    const group_options = [
        { label: '-Select-', value: '' },
        { label: 'CO', value: 'CO' },
        { label: 'OA', value: 'OA' },
        { label: 'PI', value: 'PI' },
        { label: 'PR', value: 'PR' },
    ]
    console.log(payerIdListformik.values)
    return (

        <React.Fragment>
            <Master
            // ToastMessage={toast}
            />
            <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg="success" show={toast.type == 'success' ? true : false} animation={true} delay={2000} autohide>

                    <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                </Toast>
                <Toast bg="danger" show={toast.type == 'error' ? true : false} onClose={() => setToast({ toast: false })} animation={true} delay={2000} autohide>

                    <Toast.Body ><span className='text-white'>{toast.message ? toast.message : "Failed"}</span></Toast.Body>
                </Toast>
            </ToastContainer>
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1 className='text-uppercase'>{type == 'approve' && id ? "Approve Payer ID List" : id ? 'Edit Payer ID List' : 'Create Payer ID List'}</h1>
                </div>
                <MainContainer className="section mt-5">
                    <div className="card card-flush py-4">
                        <div className="card-body pt-0 ">
                            <FormikProvider value={payerIdListformik}>
                                <Form onSubmit={payerIdListformik.handleSubmit}>
                                    <div className='d-flex justify-content-between'>
                                        <div className='col-lg-5'>
                                            <div className="mb-10">
                                                <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Clients</label>


                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    value={selectValue}
                                                    name="clients"
                                                    options={options}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select Clients"
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px", minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),
                                                        placeholder: (styles) => ({
                                                            ...styles,
                                                            textTransform: 'uppercase' // Change placeholder text to uppercase
                                                        })
                                                    }} />

                                                {!!payerIdListformik.errors?.clients &&
                                                    <span className='text-danger'>{payerIdListformik.errors?.clients}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="mb-10">
                                                <Form.Group>
                                                    <Form.Label htmlFor="exampleFormControlInput1" className=" form-label">Payer ID (TRN03)</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name='unique_code'

                                                        isValid={payerIdListformik.touched.unique_code && !payerIdListformik?.errors?.unique_code}
                                                        isInvalid={!!payerIdListformik.errors?.unique_code}
                                                        onChange={payerIdListformik.handleChange}
                                                        className="form-control form-control-solid b-r-10 text-uppercase"
                                                        placeholder="Payer ID (TRN03)"
                                                        value={payerIdListformik.values?.unique_code}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{payerIdListformik?.errors?.unique_code}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>

                                    <FieldArray name="data"
                                        render={(arrayHelpers) => (
                                            <React.Fragment>
                                                {payerIdListformik?.values?.data.map((value, index) => (
                                                    <div key={index}>
                                                        <div className='d-flex mt-2 gap-2 align-items-center'>

                                                            <div className='col-lg-2'>
                                                                <div className="mb-10">
                                                                    {index == 0 &&
                                                                        <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Payer Name</label>
                                                                    }
                                                                    <Form.Control
                                                                        className="form-control form-control-solid b-r-10 text-uppercase"
                                                                        classNamePrefix="select"
                                                                        name={`data[${index}].payer_name`}
                                                                        isInvalid={payerIdListformik?.errors && payerIdListformik?.errors?.data && !!payerIdListformik.errors?.data[index]?.payer_name}
                                                                        placeholder="Payer Name"
                                                                        onChange={(e) => changeDatavalue(e)}
                                                                        value={payerIdListformik.values?.data[index]?.payer_name}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{payerIdListformik?.errors && payerIdListformik?.errors?.data && payerIdListformik?.errors?.data[index]?.payer_name}</Form.Control.Feedback>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <div className="mb-10">
                                                                    {index == 0 &&
                                                                        <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Payer ID</label>
                                                                    }
                                                                    <Form.Control
                                                                        className="form-control form-control-solid b-r-10 text-uppercase"
                                                                        classNamePrefix="select"
                                                                        name={`data[${index}].payer_id`}
                                                                        isInvalid={payerIdListformik?.errors && payerIdListformik?.errors?.data && !!payerIdListformik.errors?.data[index]?.payer_id}
                                                                        placeholder="Payer ID"
                                                                        onChange={(e) => changeDatavalue(e)}
                                                                        value={payerIdListformik.values?.data[index]?.payer_id}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{payerIdListformik?.errors && payerIdListformik?.errors?.data && payerIdListformik?.errors?.data[index]?.payer_id}</Form.Control.Feedback>
                                                                </div>
                                                            </div>

                                                            <div className='pt-3'>
                                                                <button
                                                                    className='btn btn-danger '
                                                                    type="button"
                                                                    disabled={payerIdListformik.values?.data?.length === 1}
                                                                    onClick={() => handleRemoveItems(index, arrayHelpers, value)}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className='pt-4'>
                                                    <button
                                                        className='text-uppercase btn btn-light btn-active-primary'
                                                        type="button"
                                                        onClick={() => arrayHelpers.push({ payer_name: '', payer_id: "" })}
                                                    >
                                                        Add New
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        )} />
                                    {state.error &&
                                        <div className='text-center mt-3'>
                                            <span className='text-danger fw-bold '>{state.error_message}</span>
                                        </div>
                                    }
                                    <div className='row mt-2'>
                                        <div className='d-flex flex-row justify-content-end'>
                                            {data?.job_id &&
                                                <div className='me-2'>
                                                    <p
                                                        className='pt-2'
                                                        style={{ fontSize: '0.9375rem', color: '#6ba2b6' }}
                                                    >
                                                        {data?.job_id}
                                                    </p>
                                                </div>
                                            }


                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='text-uppercase btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("Payer ID List", PAYER_ID_LIST) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <button
                                                type='submit'
                                                className='text-uppercase btn btn-primary btnHover new-window'
                                                disabled={!payerIdListformik.isValid}
                                            >
                                                {id ?
                                                    type == 'approve' ?
                                                        'Approve'
                                                        :
                                                        'Update'
                                                    :
                                                    'Create'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </FormikProvider>
                        </div>
                    </div>
                </MainContainer>

            </main>
        </React.Fragment >
    )
}

export default CreatePayerIdList;

const MainContainer = styled.div`
    label{
        text-transform:uppercase
    }
`