import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import AdminMaster from '../../../Layout/admin/Master';
import { Link } from 'react-router-dom';
import { exportAllPayees, getAllPayees, importCsvPayeeCode, togglePayee } from './../../../Redux/user/action';
import { useEffect, useState } from 'react';
import { Form, Modal, OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap';
import powerbtn from '../../../assets/img/power-button.svg';
import { BiExport, BiReset, BiSearch, BiTimer, BiUserPlus } from 'react-icons/bi';

import DataTable from 'react-data-table-component';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import { Stack } from 'rsuite';
import { CREATE_PAYEE, EDIT_PAYEE } from '../../../routeNames';
import { approveFunction } from '../../../Redux/common/action';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import payeeSample from "../../../assets/sample/sample-payee.csv"
import Select from 'react-select'
import { changeAllTitleFunction, downloadFile } from '../Dashboard/functions';
import { Tooltip } from '@mui/material';

import DateRangePicker from 'rsuite/DateRangePicker';
import { getAllClientDropdown } from '../../../Redux/client/action';
import { HiChevronDown } from 'react-icons/hi';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { setHours, setMinutes, setSeconds, startOfYear, endOfYear } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import { components } from 'react-select';
import { TbGridDots, TbHandGrab } from 'react-icons/tb';
import moment from 'moment';





const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;

const Payee = () => {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [toaster, setToaster] = useState(false)
    const [clientList, setClientList] = useState();
    const [isFocusedClient, setIsFocusedClient] = useState(false);

    const [pageno, setPageNo] = useState(1);
    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
    });
    const [exportLoading, setExportLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [data, setData] = useState({})
    const [modalUpload, setModalUpload] = useState(false);
    const [colorRow, setColorRow] = useState({});
    const [filterText, setFilterText] = React.useState({
        name: "",
        status: "",
    });
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [searchedData, setSearchedData] = useState({
        search: "",
        status: "",
        updated_date_range: "",
        clients: "",
        sort_key: "",
        sort_order: "",
    })
    const [filterState, setFilterState] = useState([{
        clients: [],
    }])
    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {filterState && filterState[state] && filterState[state].length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 59)],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date().setHours(0, 0, 0, 0), -1), addDays(new Date().setHours(23, 59, 59, 59), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date().setHours(0, 0, 0, 0)), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date().setHours(0, 0, 0, 0), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date().setHours(0, 0, 0, 0), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date().setHours(0, 0, 0, 0)), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date().setHours(0, 0, 0, 0), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [setSeconds(setMinutes(setHours(startOfYear(new Date(new Date().getFullYear() - 1, 0, 1)), 0), 0), 0), setSeconds(setMinutes(setHours(endOfYear(new Date(new Date().getFullYear(), 0, 0)), 23), 59), 59)],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date().setHours(0, 0, 0, 0)] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];
    const handleNewSingleTab = async (name, url) => {
        let new_tab = {
            id: 0,
            route: `${window.location.origin}` + url,
            // route: `${window.location.origin}` + EDIT_WORK + 1278 +'?log_id='+20208,
            label: name,
            prevTabs: [],
            isNewTab: false,
            isCurrentTab: true,
            title: name
        }
        // window.parent.localStorage.setItem('bbtabs', JSON.stringify(data))
        window.parent.localStorage.setItem('newTab', JSON.stringify(new_tab))

        parent.document.getElementById('bottom-tab-add').click()
    }
    const columns = useMemo(
        (row) => [

            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                width: "40px",
                //grow: 0.2,

            },

            {
                id: 'payee_id',
                name: 'ID',
                selector: row => row.payee_id,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                        <Tooltip disableInteractive title={row?.payee_id} placement='top' arrow>
                            <span className='text-truncate '> {row?.payee_id}</span>
                        </Tooltip>
                    </div>,
                sortable: true,
                left: true,
                wrap: true,
                maxWidth: '100px',
                minWidth: '100px'
            },

            {
                id: 'client',
                name: 'Client',
                selector: row => row.client,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "170px" }}>
                        <Tooltip disableInteractive title={row?.client} placement='top' arrow>
                            <span className='text-truncate '> {row?.client}</span>
                        </Tooltip>
                    </div>,
                sortable: true,
                left: true,
                wrap: true,
                maxWidth: '170px',
                minWidth: '170px'


            },

            {
                id: 'payee_identification',
                name: 'Identification',
                selector: row => row.payee_identification,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                        <Tooltip disableInteractive title={row?.payee_identification} placement='top' arrow>
                            <span className='text-truncate '> {row?.payee_identification}</span>
                        </Tooltip>
                    </div>,
                left: true,
                wrap: true,
                maxWidth: '120px',
                minWidth: '120px'
            },

            {
                id: 'name',
                name: 'Name',
                selector: row => row.name,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "190px" }}>
                        <Tooltip disableInteractive title={row?.name} placement='top' arrow>
                            <span className='text-truncate '> {row?.name}</span>
                        </Tooltip>
                    </div>,
                sortable: true,
                left: true,
                wrap: true,
                maxWidth: '200px',
                minWidth: '200px'
            },

            {
                id: 'address',
                name: 'Address',
                selector: row => row.address,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "350px" }}>
                        <Tooltip disableInteractive title={row?.address} placement='top' arrow>
                            <span className='text-truncate '> {row?.address}</span>
                        </Tooltip>
                    </div>,
                left: true,
                wrap: true,
                maxWidth: '250px',
                minWidth: '250px'


            },
            {
                id: 'city',
                name: 'City',
                selector: row => row.city,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                        <Tooltip disableInteractive title={row?.city} placement='top' arrow>
                            <span className='text-truncate '> {row?.city}</span>
                        </Tooltip>
                    </div>,
                left: true,
                wrap: true,
                maxWidth: '100px',
                minWidth: '100px'


            },
            {
                id: 'state',
                name: 'State',
                selector: row => row.state,
                cell: (row) =>
                    <div>{row?.state}</div>,
                left: true,
                wrap: true,
                maxWidth: '50px',
                minWidth: '50px'


            },

            {
                id: 'zip_code',
                name: 'Zipcode',
                selector: row => row.zip_code,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "70px" }}>
                        <Tooltip disableInteractive title={row?.zip_code} placement='top' arrow>
                            <span className='text-truncate '> {row?.zip_code}</span>
                        </Tooltip>
                    </div>,
                left: true,
                wrap: true,
                maxWidth: '70px',
                minWidth: '70px'


            },

            {
                id: 'approved',
                name: 'Approved',
                selector: row => row.is_approved ? 'Yes' : 'No',
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.is_approved ? 'Yes' : 'No'}</span>,
                left: true,
                fixed: true,
                wrap: true,
                maxWidth: '70px',
                minWidth: '70px'


            },
            {
                id: 'data_from',
                name: 'Source',
                selector: row => row.data_from,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "80px" }}>
                        <Tooltip disableInteractive title={row?.data_from} placement='top' arrow>
                            <span className='text-truncate '> {row?.data_from}</span>
                        </Tooltip>
                    </div>,
                left: true,
                wrap: true,
                maxWidth: '80px',
                minWidth: '80px'


            },
            {
                id: 'modified_date',
                name: 'Updated Date',
                selector: row => row.modified_date,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                        <Tooltip disableInteractive title={row?.modified_date} placement='top' arrow>
                            <span className='text-truncate '> {row?.modified_date}</span>
                        </Tooltip>
                    </div>,
                sortable: true,
                left: true,
                wrap: true,
                maxWidth: '100px',
                minWidth: '100px'


            },

            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex gap-2 align-items-center" onClick={() => handleRowClick(row)}>
                        {!row.is_approved && checkUserPermission('approve_payee') &&
                            <Tooltip disableInteractive title="Approve" placement='top' arrow>
                                <div style={{ color: '#6ba2b6' }} className={"btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"} onClick={(e) => {
                                    if (e.ctrlKey) {
                                        handleNewSingleTab("Approve Payee", "/edit-payee/" + row.pk + '/approve')
                                    } else {
                                        changeAllTitleFunction("Approve Payee", "/edit-payee/" + row.pk + '/approve')
                                    }
                                }} >
                                    <span className="svg-icon svg-icon-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                        </svg>
                                    </span>
                                </div>
                            </Tooltip>
                        }

                        <Tooltip disableInteractive title="Edit" placement='top' arrow>
                            <div style={{ color: '#6ba2b6' }} onClick={(e) => {
                                if (e.ctrlKey) {
                                    handleNewSingleTab("Edit Payee", EDIT_PAYEE + row.pk)
                                } else {
                                    changeAllTitleFunction('Edit Payee', EDIT_PAYEE + row.pk)
                                }
                            }
                            } className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </div>
                        </Tooltip>

                        {checkUserPermission('enable_payee') &&
                            <Tooltip disableInteractive title="Status" placement='top' arrow>
                                <div className=" ms-2" >
                                    {row.is_active ? (

                                        <Form.Check
                                            onClick={() => __togglePayee(row.pk)}
                                            type="switch"
                                            role="switch" defaultChecked={row.is_active} id="flexSwitchCheckDefault"
                                        />
                                    ) : (
                                        <Form.Check
                                            onClick={() => __togglePayee(row.pk)}
                                            type="switch"
                                            role="switch" defaultChecked={row.is_active} id="flexSwitchCheckDefault"
                                        />
                                    )}
                                    {/* <Form.Check className="form-check-input" type="checkbox" checked={row.is_active ? true : false} role="switch" id="flexSwitchCheckDefault" /> */}
                                </div>
                            </Tooltip>
                        }
                    </div>,
                ignoreRowClick: false,
                // allowOverflow: true,
                button: false,
                //left: false,
                right: true,
                maxWidth: '150px',
                minWidth: '150px'

            },

        ],
        [pageno],
    );
    const handleFileChange = (event) => {

        let selectedFile = event.target.files;
        // let file = null;
        // let fileName = "";
        // //Check File is not Empty
        // if (selectedFile.length > 0) {
        //     // Select the very first file from list
        //     let fileToLoad = selectedFile[0];
        //     fileName = fileToLoad.name;
        //     // FileReader function for read the file.
        //     let fileReader = new FileReader();
        //     // Onload of file read the file content
        //     fileReader.onload = function (fileLoadedEvent) {
        //         file = fileLoadedEvent.target.result;
        //         // Print data in // console
        csvFormik.setValues({
            ...csvFormik.values,
            csv_file: selectedFile
        })
        //     };
        //     // Convert data to base64
        //     fileReader.readAsDataURL(fileToLoad);
    }
    const csvFormik = useFormik({
        initialValues: {
            csv_file: ""
        },
        // validationSchema: Yup.object().shape({
        //     csv_file: Yup.mixed()
        //         .required('Required'),
        // }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            const formData = new FormData();
            formData.append('csv_file', values?.csv_file[0]);
            importCsvPayeeCode({ formData })((response) => {
                if (response && response?.status) {
                    setSubmitting(false)
                    setToaster('success')
                    setModalUpload(!modalUpload)
                }
                else {
                    setSubmitting(false)
                    setToaster('error')

                }
            })
        }
    })
    const handleSort = async (column, order) => {
        payeeFilterFormik.setValues({
            ...payeeFilterFormik.values,
            sort_order: order,
            sort_key: column.id
        })
        setSearchedData({
            ...searchedData,
            sort_order: order,
            sort_key: column.id
        })
        fetchPayee(pageno, limit, searchedData.search, searchedData.status, searchedData.updated_date_range, searchedData.clients, searchedData.data_from, order, column.id);
    };
    const converToStrDate = (date) => {
        let updated_date_range = ''
        if (date) {
            const datefrom = moment(date['0']).format('MM/DD/YYYY')
            const dateto = moment(date['1']).format('MM/DD/YYYY')
            updated_date_range = datefrom + " - " + dateto
        }
        return updated_date_range
    }
    const payeeFilterFormik = useFormik({
        initialValues: {
            search: "",
            status: "",
            clients: "",
            updated_date_range: "",
            sort_key: "",
            sort_order: "",
        },
        onSubmit: (values) => {
            const dict = { ...values }
            dict.updated_date_range = converToStrDate(values.updated_date_range)
            searchData(dict)
            setSearchedData({
                ...searchedData,
                sort_order: dict?.sort_order ? dict?.sort_order : "",
                sort_key: dict?.sort_key ? dict?.sort_key : "",
                search: dict?.search ? dict?.search : "",
                status: dict?.status ? dict?.status : "",
                updated_date_range: dict?.updated_date_range ? dict?.updated_date_range : "",
                clients: dict?.clients ? dict?.clients : [],
                data_from: dict?.data_from ? dict?.data_from : ""
            })

            // const formData = new FormData();
            // formData.appent('payeeFilter', values?.payeeFilter)
            // formData.appent('approve', values?.approve)
            handleResetDataTable()
        }
    })

    const searchData = async ({ search, status, updated_date_range, clients, data_from, sort_order, sort_key }) => {
        ;
        fetchPayee(1, limit, search, status, updated_date_range, clients, data_from, sort_order, sort_key)
    }

    // const searchTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Search
    //     </Tooltip>
    // );
    // const resetTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Reset
    //     </Tooltip>
    // );
    console.log(payeeFilterFormik.values)
    const fetchPayee = async (page, limit, search, status, updated_date_range, clients, data_from, sort_order, sort_key) => {
        setLoading(true);
        // setLimit(limit);
        getAllPayees({ page, limit, search, status, updated_date_range, clients, data_from, sort_order, sort_key })((response) => {
            setData(response?.data);
            setLimit(limit);
            setTotalRows(response?.data?.count);
            setLoading(false);
        })
        // const response = await axiosInstance.get(GET_ALL_PAYEE_URL + "?page=" + page + "&limit=" + limit);

    };
    const handlePageChange = page => {
        setPageNo(page)
        fetchPayee(page, limit, searchedData.search, searchedData.status, searchedData.updated_date_range, searchedData.clients, searchedData.data_from, searchedData.sort_order, searchedData.sort_key);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        // const response = await axiosInstance.get(GET_ALL_PAYEE_URL + "?page=" + page + "&limit=" + newPerPage);
        // setData(response?.data);
        setLimit(newPerPage);
        setPageNo(1)
        // setTotalRows(response?.data?.data?.count);
        fetchPayee(1, newPerPage, searchedData.search, searchedData.status, searchedData.updated_date_range, searchedData.clients, searchedData.data_from, searchedData.sort_order, searchedData.sort_key)
        setLoading(false);
        handleResetDataTable()

    };
    useEffect(() => {
        // getAllPayees()((response) => {
        //     setData(response);
        // });
        setLoading(true);
        let page = 1
        getAllPayees({ page, limit })((response) => {
            setData(response?.data);
            setTotalRows(response?.data?.count);
            setLoading(false);
        })
        getAllClientDropdown()((response) => {
            setClientList(response?.data)
        })
    }, []);

    const __togglePayee = (payee) => {
        let formData = new FormData();    //formdata object

        formData.append('payee', payee);

        togglePayee({ formData })((response) => {
            if (response?.status && response?.status_code === 201) {
                setState({ showToast: !state.showToast })
                setToaster('success')
                // fetchPayee(1)
                // getAllPayees()((response) => {
                //     setData(response);
                // });
            } else {
                setToaster('error')
                setState({ error: true, error_message: response?.message });
            }
        });
    }
    const searchGroups = async (e, name) => {
        setFilterText({
            ...filterText,
            [name]: e.target.value
        })
        let data = e.target.value ? e.target.value : '';

        // const response = await axiosInstance.get(GET_ALL_PAYEE_URL + "?limit=" + limit + "&search=" + data);
        //setData(response?.data);
    }

    // const filteredItems = data?.data?.results?.filter(
    //     item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    // );
    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, resetPaginationToggle]);
    const handleClear = () => {
        if (filterText) {
            //setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            fetchPayee(1, limit);
        }
    };
    const handleApprovePayee = (id, url) => {
        approveFunction(id, url)((response) => {
            if (response && response.status) {

                fetchPayee(1)
                setToaster('success')
                // setToaster("generated_schema")

            } else {
                setToaster('error')
            }
        })
    }
    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster('')
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [toaster])
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })

        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.pk == row.pk ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
        // // console.log(row);
    };

    const options = [
        // { label: '--Select--' },
        { value: 'true', label: 'Approved' },
        { value: 'false', label: 'Not Approved' },

    ]
    const data_from_options = [
        { value: '1', label: 'Other' },
        { value: '2', label: 'Grid' },
        { value: '3', label: '837' },
        { value: '4', label: 'CSV' },

    ]
    const handleOnSelectStatus = async (e, name) => {

        payeeFilterFormik.setValues({ ...payeeFilterFormik.values, [name]: e?.value ? e?.value : "" })
    }

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }
    const handleExportPayee = (search, status, updated_date_range, clients, data_from, sort_order, sort_key) => {
        setExportLoading(true)
        exportAllPayees({ search, status, updated_date_range, clients, data_from, sort_order, sort_key })((response) => {
            setExportLoading(false)
            const currentDate = new Date();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
            const day = currentDate.getDate().toString().padStart(2, '0');
            const year = currentDate.getFullYear();

            const strDate = `${month}/${day}/${year}`;
            downloadFile(response?.data, `payee-${strDate}`)
        })
    };


    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} />
            <main id="main" className="main">
                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster === true ? true : false} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide={200}>
                        <Toast.Body ><span className='text-white'>Something went wrong</span></Toast.Body>
                    </Toast>
                </ToastContainer> */}
                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Payee</h1>

                </div>
                <Modal show={modalUpload} onHide={() => setModalUpload(!modalUpload)}>
                    <Modal.Header closeButton>
                        <Modal.Title> <div className='d-flex flex-row justify-content-around gap-4'>
                            <span>Payee CSV Upload</span>
                            <a className='' style={{ cursor: 'pointer' }} target="_blank" download href={payeeSample}>
                                <Tooltip disableInteractive title="Sample Csv" placement='top' arrow>

                                    <span className=''><i className='bi bi-download'></i></span>
                                </Tooltip>
                            </a>
                        </div></Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={csvFormik.handleSubmit}>

                        <Modal.Body>
                            <Form.Control type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className='form-control form-control-solid-file mt-3' required onChange={(e) => handleFileChange(e)} name="csv_file" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setModalUpload(!modalUpload)}>
                                Close
                            </Button>
                            <Button className='btn btn-primary btnHover' disabled={csvFormik.isSubmitting} variant="primary" type="submit" >
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <Form onSubmit={payeeFilterFormik.handleSubmit} onReset={() => window.location.reload()}>
                                    <div className="card-header border-0  ">

                                        <div className="d-flex align-items-center  position-relative payee-search ">
                                            <Form.Control
                                                id="search"
                                                className="form-control form-control-solid ms-3 b-r-10"
                                                name='search'
                                                type="search"
                                                style={{ width: "100%" }}
                                                placeholder="Search"
                                                aria-label="Search Input"
                                                value={payeeFilterFormik?.values?.search}
                                                onChange={payeeFilterFormik.handleChange}
                                            />
                                        </div>
                                        <div className='d-flex p-2  align-items-center'>
                                            <Select
                                                tabSelectsValue={false}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                options={clientList}
                                                className="select-search"
                                                components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                classNamePrefix="select"
                                                placeholder="Clients"
                                                isClearable
                                                name="clients"
                                                onFocus={() => setIsFocusedClient(true)}
                                                onBlur={() => setIsFocusedClient(false)}
                                                onChange={(e) => {
                                                    setFilterState({
                                                        ...filterState,
                                                        clients: e
                                                    })
                                                    payeeFilterFormik.setValues({ ...payeeFilterFormik.values, clients: e?.map(i => i.value) })
                                                }}
                                                value={clientList?.filter((i) => payeeFilterFormik?.values?.clients?.includes(i?.value))}

                                                styles={{
                                                    container: (styles, state) => ({
                                                        ...styles,
                                                        borderRadius: "9px",
                                                    }),
                                                    control: (styles, state) => ({
                                                        ...styles,
                                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                        borderStyle: "none", boxShadow: "none",
                                                        borderRadius: "10px",
                                                        width: '250px',
                                                        maxHeight: state.isFocused ? "auto" : "30px",
                                                    }),
                                                    indicatorSeparator: styles => ({
                                                        ...styles, width: "0px",
                                                        minHeight: "20px",
                                                    }),

                                                    valueContainer: (styles, state) => ({
                                                        ...styles,
                                                        maxHeight: isFocusedClient ? "auto" : "30px",
                                                    }),
                                                }} />
                                        </div>
                                        <div className="d-flex p-2  align-items-center">
                                            <Select
                                                options={options}
                                                className="select-search-approve"
                                                aria-label='asdf'
                                                placeholder="Status"
                                                name="status"
                                                onChange={(e) => handleOnSelectStatus(e, 'status')}
                                                isClearable
                                                styles={{ control: (styles) => ({ ...styles, backgroundColor: "hsl(204deg 33.33% 97.06%)", borderStyle: "none", boxShadow: "none", borderRadius: "10px" }), indicatorSeparator: styles => ({ ...styles, width: "0px", minHeight: "20px" }), container: styles => ({ ...styles, borderRadius: "9px", }) }}
                                            />
                                        </div>
                                        <div className="d-flex p-2  align-items-center">
                                            <Select
                                                isClearable
                                                options={data_from_options}
                                                className="select-search-approve"
                                                aria-label='asdf'
                                                placeholder="Source"
                                                name="data_from"
                                                onChange={(e) => handleOnSelectStatus(e, 'data_from')}
                                                styles={{ control: (styles) => ({ ...styles, backgroundColor: "hsl(204deg 33.33% 97.06%)", borderStyle: "none", boxShadow: "none", borderRadius: "10px" }), indicatorSeparator: styles => ({ ...styles, width: "0px", minHeight: "20px" }), container: styles => ({ ...styles, borderRadius: "9px", }) }}
                                            />
                                        </div>


                                        <div className="correspondenceDatePicker d-flex me-1 p-2  align-items-center">
                                            <Stack direction="column" spacing={8} alignItems="flex-start">

                                                <DateRangePicker
                                                    // style={{ backgroundColor: "#000" }}
                                                    ranges={predefinedBottomRanges}
                                                    cleanable={true}
                                                    // placement="auto"
                                                    appearance="default" format={"MM/dd/yyyy"}
                                                    className="form-date-control form-control-solid b-r-1 text-dark"
                                                    placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                                                    name="deposit_date_range"
                                                    onChange={(e) => {
                                                        payeeFilterFormik.setValues({
                                                            ...payeeFilterFormik.values,
                                                            updated_date_range: e
                                                        })
                                                    }} value={payeeFilterFormik?.values?.updated_date_range} />
                                            </Stack>
                                        </div>
                                        <div className='d-flex me-auto p-2 align-items-center gap-2'>
                                            {/* <div className="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1"> */}

                                            <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                <Button className="btn btn-light btn-active-primary  " type="submit">
                                                    <span className="svg-icon svg-icon-3">
                                                        <BiSearch />
                                                    </span>

                                                </Button>
                                            </Tooltip>
                                            {/* <div className="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1"> */}
                                            <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                <Button className="btn btn-light btn-active-primary " type="reset">
                                                    <span className="svg-icon svg-icon-3">
                                                        <BiReset />
                                                    </span>

                                                </Button>
                                            </Tooltip>
                                            <Tooltip disableInteractive title="Export" placement='top' arrow>
                                                {exportLoading ?
                                                    <Button className="btn btn-light btn-active-primary">
                                                        <span className="svg-icon svg-icon-3">
                                                            <div class="spinner-border spinner-border-sm" role="status">
                                                                <span class="sr-only"></span>
                                                            </div>
                                                        </span>

                                                    </Button>
                                                    :
                                                    <Button className="btn btn-light btn-active-primary " onClick={() => { handleExportPayee(searchedData.search, searchedData.status, searchedData.updated_date_range, searchedData.clients, searchedData.data_from, searchedData.sort_order, searchedData.sort_key) }}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiExport />
                                                        </span>

                                                    </Button>


                                                }
                                            </Tooltip>
                                        </div>
                                        <div className="card-toolbar gap-2 me-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                            <button className='btn btn-light btn-active-primary' onClick={() => setModalUpload(true)}>
                                                File Upload
                                            </button>
                                            {checkUserPermission('create_payee') &&
                                                <Link onClick={() => changeAllTitleFunction('Create Payee', CREATE_PAYEE)} className="btn btn-light btn-active-primary">
                                                    <span className="svg-icon svg-icon-3">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                    Create
                                                </Link>
                                            }

                                        </div>

                                    </div>
                                </Form>

                                <div className="p-2">
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}

                                                    data={data?.results}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    paginationPerPage={limit}
                                                    paginationTotalRows={totalRows}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    // subHeader
                                                    // subHeaderComponent={subHeaderComponentMemo}
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}
                                                    sortServer
                                                    onSort={handleSort}

                                                />
                                            }
                                            {/* <table id="example" className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" >
                                                <thead>
                                                    <tr className="fw-bold text-muted">
                                                        <th className="min-w-200px">Payee ID</th>
                                                        <th className="min-w-150px">Entity Identifier Code</th>
                                                        <th className="min-w-150px">ID Code Qualifier</th>
                                                        <th className="min-w-150px">Name</th>
                                                        <th className="min-w-150px">Address</th>
                                                        <th className="min-w-150px">Country Subdivision Code</th>
                                                        <th className="min-w-100px text-end">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.status && data?.data.results && data?.data?.results.map((payee, index) =>

                                                        <tr key={index}>
                                                            <td>{payee.payee_id}</td>
                                                            <td>{payee.entity_identifier_code}</td>
                                                            <td>{payee.id_code_qualifier}</td>
                                                            <td>{payee.name}</td>
                                                            <td>{payee.address}</td>
                                                            <td>{payee.country_subdivision_code}</td>
                                                            <td className='text-end'>
                                                                <div className='btn-group'>
                                                                    <Link to={"/edit-payee/" + payee.pk} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                                                                        <span className="svg-icon svg-icon-3">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Link>
                                                                    <button className={payee.is_active ? "btn btn-icon btn-bg-light btn-active-color-primary btn-sm " : "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"} onClick={() => __togglePayee(payee.pk)}>
                                                                        <img src={powerbtn} alt="" className='powerbtn' />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>

        </React.Fragment>
    )
}

export default Payee;