import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
// import Button from 'react-bootstrap/Button';
import Master from '../../../Layout/admin/Master/index';
import { deleteRoles, getRoles } from '../../../Redux/user/action';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import { OverlayTrigger } from 'react-bootstrap';
import axiosInstance from './../../../axiosInstance';
import { GET_ROLES_URL } from '../../../api';
import { CREATE_ROLES, EDIT_ROLES } from '../../../routeNames';
import DeleteSweetAlert from '../../SweetAlert/DeleteSweetAlert';
import { Button, Toast, ToastContainer } from 'react-bootstrap';
import { changeAllTitleFunction } from '../Dashboard/functions';
import { Tooltip } from '@mui/material';



const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
const TextField = styled.input`
    height: 25px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;


const Roles = () => {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [toaster, setToaster] = useState(false)
    const [isSwal, setIsSwal] = useState({
        show: false,
        id: ''
    })
    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
    });
    const [loading, setLoading] = useState(false);
    const [colorRow, setColorRow] = useState({});
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [pageno, setPageNo] = useState(1);
    const [data, setData] = useState({})

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    // const renderEditTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Edit
    //     </Tooltip>
    // );
    // const renderDeleteTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Delete
    //     </Tooltip>
    // );
    const sortByIndex = () => {
        // console.log(a);
    }
    const caseInsensitiveSort = (rowA, rowB) => {
        const a = rowA;
        const b = rowB;
        // console.log('====================================');
        // console.log(a, b);
        // console.log('====================================');
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    };
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions?.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    const showTooltip = (text, props) => (
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    );
    const columns = useMemo(
        () => [

            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                width: "70px",
                //grow: 0,
                // sortFunction: caseInsensitiveSort,
            },

            {
                id: 'name',
                name: 'Name',
                selector: row => row.name,
                cell: (row) => <div className="" >
                    {row?.name &&

                        <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: "500px" }}>
                            <Tooltip title={row?.name} placement='top' arrow>
                                <span className='text-truncate' > {row?.name}</span>
                            </Tooltip>
                        </div>

                    }

                </div>,
                sortable: true,
                left: true,
                wrap: true,
                //grow: 1,


            },


            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0">
                        <Tooltip title="Edit" placement='top' arrow>
                            <Link onClick={() => changeAllTitleFunction("Edit Role", EDIT_ROLES + row.id)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </Link>
                        </Tooltip>
                        {/* {checkUserPermission('delete_role') && */}
                        <Tooltip title="Delete" placement='top' arrow>
                            <Link className={row.is_active ? "btn btn-icon btn-bg-light btn-active-color-primary btn-sm " : "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"} onClick={() => setIsSwal({ ...isSwal, show: true, id: row.id })}>
                                <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </Link>
                        </Tooltip>
                        {/* } */}

                    </div>,
                ignoreRowClick: false,
                allowOverflow: true,
                right: true,
                button: false,
                //width: "100px",
                //button: true,
            },

        ],
        [pageno],
    );
    const __deleteRole = (role) => {


        deleteRoles(role)((response) => {
            if (response?.status) {


                fetchRoles(pageno, limit, filterText)


                setToaster('success')
                setTimeout(() => {
                    setToaster('')

                }, 2000);

            } else {
                setToaster('error')
                setTimeout(() => {
                    setToaster('')

                }, 2000);
            }
        });
    }
    const fetchRoles = async (page, limit, search) => {
        setLoading(true);

        //setLimit(limit);
        getRoles({ page, limit, search })((response) => {
            setData(response?.data);
            setLimit(limit);
            setPageNo(page)
            setTotalRows(response?.data?.count);
            setLoading(false);
        })
        // const response = await axiosInstance.get(GET_ROLES_URL + "?page=" + page + "&limit=" + limit);

    };
    const handlePageChange = page => {
        setPageNo(page)
        fetchRoles(page, limit, filterText);
    };

    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        // const response = await axiosInstance.get(GET_ROLES_URL + "?page=" + page + "&limit=" + newPerPage);
        // setData(response?.data);
        setLimit(newPerPage);
        setPageNo(1)
        // setTotalRows(response?.data?.data?.count);
        fetchRoles(1, newPerPage, filterText)
        setLoading(false);
        handleResetDataTable()
    };
    // // console.log(data);
    useEffect(() => {

        // getRoles({ limit })((response) => {
        //     setData(response);
        //     setLimit(limit);
        //     setTotalRows(response?.data?.count)
        // });


        fetchRoles(1, limit);
    }, []);
    const searchGroups = async (e) => {
        setFilterText(e.target.value)
        let data = e.target.value ? e.target.value : '';
        await fetchRoles(1, limit, data)
        // const response = await axiosInstance.get(GET_ROLES_URL + "?limit=" + data?.data?.count + "&search=" + data);
        // setData(response?.data);
        handleResetDataTable()
    }
    // const filteredItems = data?.data?.results?.filter(
    //     item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    // );
    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, resetPaginationToggle]);
    const handleClear = () => {
        if (filterText) {
            //setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            fetchRoles(1, limit);
        }
    };
    const handleDelete = (pk) => {
        setIsSwal({ ...isSwal, show: 2 })
        __deleteRole(pk)
        fetchRoles(pageno, limit);

    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
        // // console.log(row);
    };

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }

    return (
        <React.Fragment>
            <Master ToastMessage={toaster} />
            <main id="main" className="main">

                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Roles</h1>

                </div>

                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">


                                <div className="card-header border-0 ">
                                    <div className='d-flex align-items-center ms-3'>
                                        <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
                                    </div>
                                    {checkUserPermission('create_role') &&
                                        <div className="card-toolbar gap-2 me-4" data-bs-toggle="tooltip" data-bs-placement="top">
                                            <Link onClick={() => changeAllTitleFunction('Create Role', CREATE_ROLES)} className="btn btn-light btn-active-primary">
                                                <span className="plus-svg-icon svg-icon-3">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                    </svg>
                                                </span>
                                                Create</Link>
                                        </div>
                                    }
                                </div>
                                <div className="card-body py-3">
                                    <div className="table-responsive">
                                        <div className='dashboardtable'>
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    data={data?.results}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    fixedHeader={true}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangePage={handlePageChange}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}

                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <DeleteSweetAlert handleDelete={handleDelete} isSwal={isSwal} setIsSwal={setIsSwal} />

            </main>
        </React.Fragment>
    )
}

export default Roles;
