


import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components/macro";

const SPlitListSkeleton = () => {
  return (
    <div>
      <LeftListContainer style={{ display: "flex" }}>
        <div>
          <Skeleton height={42} width={350} />
        </div>
        <div>
          <Skeleton height={42} width={350} />
        </div>
        <div>
          <Skeleton height={42} width={350} />
        </div>
        <div>
          <Skeleton height={42} width={350} />
        </div>
        <div>
          <Skeleton height={42} width={350} />
        </div>
        <div>
          <Skeleton height={42} width={350} />
        </div>
        <div>
          <Skeleton height={42} width={350} />
        </div>
        <div>
          <Skeleton height={42} width={350} />
        </div>
        <div>
          <Skeleton height={42} width={350} />
        </div>
        <div>
          <Skeleton height={42} width={350} />
        </div>
      </LeftListContainer>
    </div>
  );
};

export default SPlitListSkeleton;


const LeftListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

`