import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { createDefault, getReasonDropdown } from '../../../../../Redux/client/action';
import * as Yup from 'yup';
import { getCptDropdown } from './../../../../../Redux/client/action';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { changeAllTitleFunction } from '../../../Dashboard/functions';
import { CLIENT } from '../../../../../routeNames';

const Defaults = (props) => {

    const { defaults, client, client_data, fetchClientDetails, __toggleToast } = props;
    // console.log(defaults);
    const [state, setState] = useState({
        error: false,
        error_message: "",

    });
    const navigate = useNavigate()

    const [cptDropdown, setCptDropdown] = useState({});
    const [reasonDropdown, setReasonDropdown] = useState({});

    useEffect(() => {
        getCptDropdown()((response) => {

            if (response?.status && response?.status_code === 200) {
                setCptDropdown(response?.data?.results)
            }
        })
        getReasonDropdown()((response) => {

            if (response?.status && response?.status_code === 200) {
                setReasonDropdown(response?.data?.results)
            }
        })


        if (props?.defaults) {
            defaultformik.setValues({
                date_of_service: defaults?.date_of_service,
                account_number: defaults?.account_number,
                cpt_codes: defaults?.cpt_codes,
                first_name: defaults?.first_name,
                last_name: defaults?.last_name,
                payer_claim_number: defaults?.payer_claim_number,
                sender_id: defaults?.sender_id,
                check_number: defaults?.check_number,
                receiver_id: defaults?.receiver_id,
                field_deliminator: defaults?.field_deliminator,
                line_deliminator: defaults?.line_deliminator,
                composite_value: defaults?.composite_value,
            })
        }

    }, [defaults]);

    const defaultformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            date_of_service: "",
            account_number: "",
            cpt_codes: "",
            first_name: "",
            last_name: "",
            payer_claim_number: "",
            field_deliminator: "",
            line_deliminator: "",
            composite_value: "",
            receiver_id: "",
            sender_id: "",
            check_number: "",
        },
        validate: (values) => {
            let errors = {};
            if (!moment(values.date_of_service, 'MM/DD/YYYY', true).isValid()) {
                errors.date_of_service = "This field is required and Enter a valid date"
            }

            return errors;
        },
        validationSchema: Yup.object().shape({
            // date_of_service: Yup.string(),
            // account_number: Yup.string(),
            cpt_codes: Yup.string().min(5),
            field_deliminator: Yup.string().matches(/[:~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/, 'Please enter special characters only').max(1),
            line_deliminator: Yup.string().matches(/[:~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/, 'Please enter special characters only').max(1),
            composite_value: Yup.string().matches(/[:~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/, 'Please enter special characters only').max(1),
            // payer_claim_number: Yup.string().matches(/^[0-9]+$/, "Must be only digits"),

            receiver_id: Yup.string(),
            sender_id: Yup.string(),
            check_number: Yup.string(),
            date_of_service: Yup.date().nullable().default(null).required('Required'),

            // last_name: Yup.string(),
            // charge: Yup.string(),
            // allowed_amount: Yup.string(),
            // interest_amount: Yup.string(),
            // take_backandrepay: Yup.string(),
            // zero_payEOBs: Yup.string(),
            // HRA_payments: Yup.string(),
            // unspecified_patient_responsibility: Yup.string(),
            // amounts: Yup.string(),
            // claim_level_adjustments: Yup.string(),
            //  mismatch_image: Yup.mixed()
            //  .test('fileType', 'Only image files are allowed', (value) => {
            //     console.log(value);
            //    return value && ['image/jpeg', 'image/png'].includes(value.type);

            //  }),
            //  missing_image: Yup.mixed()
            //  .test('fileType', 'Only image files are allowed', (value) => {
            //     console.log(value);
            //    return value && ['image/jpeg', 'image/png'].includes(value.type);
            //  }),
            //  unreadable_image: Yup.mixed()
            //  .test('fileType', 'Only image files are allowed', (value) => {
            //     console.log(value);

            //    return value && ['image/jpeg', 'image/png'].includes(value.type);
            //  }),

            // reason_codes: Yup.string(),
            // excluded_payers: Yup.string(),
            // multiple_instances_ofpatient: Yup.string(),
        }),
        // DefaultSchema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            let formData = new FormData();    //formdata object
            if (props?.client) {

                formData.append('client', props?.client);
            }
            if (defaults[0]?.id) {

                formData.append('defaultId', defaults?.id);
            }
            formData.append('date_of_service', values.date_of_service);
            formData.append('account_number', values.account_number);
            formData.append('cpt_codes', values.cpt_codes);
            formData.append('first_name', values.first_name);
            formData.append('last_name', values.last_name);
            formData.append('payer_claim_number', values.payer_claim_number);
            formData.append('field_deliminator', values.field_deliminator);
            formData.append('line_deliminator', values.line_deliminator);
            formData.append('composite_value', values.composite_value);
            formData.append('receiver_id', values.receiver_id);
            formData.append('sender_id', values.sender_id);
            formData.append('check_number', values.check_number);

            createDefault({ formData })((response) => {
                if (response?.status && response?.status_code === 201) {
                    // setState({ clientValidated: true })
                    fetchClientDetails()
                    setSubmitting(false)
                    __toggleToast("success", response?.message, true)
                } else {
                    __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                    let errors = defaultformik.errors
                    defaultformik.setErrors(errors)
                    setSubmitting(false)
                    // const errors = response.response?.data?.errors;
                    // defaultformik.setErrors({ errors })
                    // props.changeState(response);
                }
            });
        },
    });
    const DefaultSchema = Yup.object().shape({
        date_of_service: Yup.string(),
        account_number: Yup.string(),
        cpt_codes: Yup.string(),
        last_name: Yup.string(),
    });

    const __handleDefaultSubmit = (values, setSubmitting) => {
        let formData = new FormData();    //formdata object
        if (props?.client) {

            formData.append('client', props?.client);
        }
        if (defaults[0]?.id) {

            formData.append('defaultId', defaults?.id);
        }
        formData.append('date_of_service', values.date_of_service);
        formData.append('account_number', values.account_number);
        formData.append('cpt_codes', values.cpt_codes);
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('field_deliminator', values.field_deliminator);
        formData.append('line_deliminator', values.line_deliminator);
        formData.append('composite_value', values.composite_value);
        formData.append('receiver_id', values.receiver_id);
        formData.append('sender_id', values.sender_id);
        formData.append('check_number', values.check_number);

        createDefault({ formData })((response) => {
            if (response?.status && response?.status_code === 201) {
                // setState({ clientValidated: true })
                fetchClientDetails()
                setSubmitting(false)
                __toggleToast("success", response?.message, true)
            } else {
                __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                setSubmitting(false)
                // const errors = response.response?.data?.errors;
                // defaultformik.setErrors({ errors })
                // props.changeState(response);
            }
        });
    }

    const handleOnDateChange = ({ name, value }) => {
        // console.log(value);
        defaultformik.setValues({ ...defaultformik.values, [name]: value })
        // setRoles(value.map((val) => val.value))
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const checkSpecialCharector = (e) => {
        const key = e.key;
        const specialKeys = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+', '-', '=', '[', ']', '{', '}', '|', ';', "'", '"', ',', '.', '/', '<', '>', '?', '~', ':', '\\'];
        if (!specialKeys.includes(key) && key !== 'Backspace' && key !== 'Tab') {
            e.preventDefault();
        }
    }
    return (
        <React.Fragment>
            <Form
                onSubmit={defaultformik.handleSubmit}
            >
                <div className="mb-3 row" >

                    <div className='col-lg-4'>
                        <Form.Group className="mb-1" >

                            <Form.Label className='fw-bold'>Date of Service</Form.Label>


                            {/* <DatePicker
                                onChange={(e) => { handleOnDateChange({ name: 'date_of_service', value: e }) }}
                                disableCalendar={true}
                                value={defaultformik.values?.date_of_service}
                            /> */}
                            <InputMask
                                mask="99/99/9999"
                                className='b-r-10 form-control form-control-solid'
                                // className={formik.errors?.check_date ? 'w-48 error-field' : 'w-48'}

                                onChange={defaultformik.handleChange}
                                // onChange={(e) => { handleOnDateChange({ name: 'date_of_service', value: e }) }}

                                // onChange={formik.handleChange}
                                // onBlur={(e) => handleCheckDate(e.target.value)}
                                value={defaultformik.values?.date_of_service}
                                // tabIndex={formik.values?.check_date && "-1"}

                                name="date_of_service"
                                placeholder={"mm/dd/yyyy"}
                            />


                            {defaultformik.errors && defaultformik.errors?.date_of_service &&
                                <span className='text-danger'>{defaultformik.errors?.date_of_service}</span>
                            }

                        </Form.Group>
                    </div>
                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Account Number</Form.Label>
                            <Form.Control
                                name='account_number'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.account_number}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                maxLength="255"
                                placeholder="Account Number"
                            />

                        </Form.Group>
                    </div>

                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>CPT Codes</Form.Label>
                            {/* <Select
                                name='cpt_codes'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.cpt_codes}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                maxLength="255"
                                placeholder="CPT Codes"
                            />*/}
                            {/* <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isMulti
                                value={defaultformik.values?.cpt_codes}
                                name="cpt_codes"
                                options={cptDropdown}
                                onChange={(e) => defaultformik.setFieldValue("cpt_codes", e.map((val) => (
                                    val.value
                                )))}
                                placeholder="Select Cpt Type..."
                            /> */}

                            <Form.Control
                                name='cpt_codes'
                                type="text"
                                autoComplete="false"
                                isInvalid={!!defaultformik.errors?.cpt_codes}
                                value={defaultformik.values?.cpt_codes}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                maxLength={"5"}
                                placeholder="CPT Codes"
                            />

                            <Form.Control.Feedback type='invalid' >
                                {defaultformik.errors?.cpt_codes}
                            </Form.Control.Feedback>

                        </Form.Group>
                    </div>

                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>First Name</Form.Label>
                            <Form.Control
                                name='first_name'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.first_name}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                maxLength="255"
                                placeholder="First Name"
                            />

                        </Form.Group>
                    </div>

                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Last Name</Form.Label>
                            <Form.Control
                                name='last_name'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.last_name}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                maxLength="255"
                                placeholder="Last Name"
                            />

                        </Form.Group>
                    </div>

                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Payer Claim Number</Form.Label>
                            <Form.Control
                                name='payer_claim_number'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.payer_claim_number}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                placeholder="Payer Claim Number"
                            />

                        </Form.Group>
                        {defaultformik.errors.payer_claim_number && defaultformik.errors.payer_claim_number != '' &&
                            <span className='text-danger mb-4'>{defaultformik.errors.payer_claim_number}</span>
                        }
                    </div>



                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Field Delimiter </Form.Label>
                            <Form.Control
                                name='field_deliminator'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.field_deliminator}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                maxLength="1"
                                placeholder="Field Delimiter "
                                onKeyDown={(e) => {
                                    checkSpecialCharector(e)
                                }}
                            />

                        </Form.Group>
                    </div>


                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Line Delimiter </Form.Label>
                            <Form.Control
                                name='line_deliminator'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.line_deliminator}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                maxLength="1"
                                placeholder="Line Delimiter "
                                onKeyDown={(e) => {
                                    checkSpecialCharector(e)
                                }}
                            />

                        </Form.Group>
                    </div>


                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Composite Value</Form.Label>
                            <Form.Control
                                name='composite_value'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.composite_value}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                maxLength="1"
                                placeholder="Composite Value"
                                onKeyDown={(e) => {
                                    checkSpecialCharector(e)
                                }}
                            />

                        </Form.Group>
                    </div>


                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Receiver Id</Form.Label>
                            <Form.Control
                                name='receiver_id'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.receiver_id}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                // maxLength="6"
                                placeholder="Receiver"
                            />

                        </Form.Group>
                    </div>
                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >
                            <Form.Label className='fw-bold'>Sender Id</Form.Label>
                            <Form.Control
                                name='sender_id'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.sender_id}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                // maxLength="6"
                                placeholder="Sender"
                            />

                        </Form.Group>
                    </div>
                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >
                            <Form.Label className='fw-bold'>Check Number</Form.Label>
                            <Form.Control
                                name='check_number'
                                type="text"
                                autoComplete="false"
                                value={defaultformik.values?.check_number}
                                className='b-r-10 form-control-solid'
                                onChange={defaultformik.handleChange}
                                // maxLength="6"
                                placeholder="Check Number"
                            />

                        </Form.Group>
                    </div>

                </div>
                <div>
                    {state.error &&
                        <span className='text-danger'>{state.error_message}</span>}
                </div>
                <div className='d-flex flex-row justify-content-end'>
                    <div className=''>
                        <button
                            type='button'
                            className='btn btn-secondary btn-active-primary me-2'
                            onClick={() => { changeAllTitleFunction('Client Setup', CLIENT) }}
                        >
                            Cancel
                        </button>
                    </div>
                    {client_data ? (checkUserPermission('edit_client') &&
                        <div className=''>
                            <button
                                type='submit'
                                className='btn btn-primary btnHover'
                                disabled={defaultformik.isSubmitting || !defaultformik.isValid}
                            >
                                {defaultformik.isSubmitting ? "Updating" : "Update"}
                            </button>
                        </div>
                    ) : (
                        <div className=''>
                            <button
                                type='submit'
                                className='btn btn-primary btnHover'
                                disabled={defaultformik.isSubmitting || !defaultformik.isValid}
                            >
                                {defaultformik.isSubmitting ? "Saving" : "Save"}
                            </button>
                        </div>
                    )
                    }
                </div>
            </Form>
        </React.Fragment>
    )
}

export default Defaults;