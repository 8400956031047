import React from 'react';
import { Link } from 'react-router-dom';
import Logo from "../assets/img/Xtract.png";
import profileimg from "../assets/img/profile-img.jpg";
import { useParams, useNavigate } from 'react-router-dom';
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW, SINGLE_SPLIT, SNIPPET } from '../routeNames';
import { releaseSplitFile } from '../Redux/fileSplit/action';
import { exitGrid } from '../Redux/grid/action';
import { changeAllTitleFunction, setTriggerIframePageLoad } from '../components/DashBoard/Dashboard/functions';
import { useRef } from 'react';
import { useEffect } from 'react';


const Header = (props) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const { file_id, job_id } = useParams();
    const { job } = props;
    const navigate = useNavigate()
    const handleGoBack = () => {
        if (window.location.pathname == SINGLE_SPLIT + file_id) {
            releaseSplitFile(file_id)((response) => {
                if (response?.status && response?.status_code == 202) {
                    changeAllTitleFunction('Dashboard', '/admin/dashboard')

                }
            })
        }
        else if (window.location.pathname == DATA_ENTRY || window.location.pathname == QA_REVIEW || window.location.pathname == SNIPPET) {
            let task_type = ""
            if (window.location.pathname == DATA_ENTRY) {
                task_type = "1"
            }
            else if (window.location.pathname == QA_REVIEW) {
                task_type = "2"
            }
            else if (window.location.pathname == SNIPPET) {
                task_type = "3"
            }
            exitGrid(job, task_type, false)((response) => {
                if (response?.status && response?.status_code == 200) {
                    // navigate(-1)
                    changeAllTitleFunction('Dashboard', '/admin/dashboard')
                } else {
                    changeAllTitleFunction('Dashboard', '/admin/dashboard')
                }
            })
        }
        else {
            // navigate(-1)
            changeAllTitleFunction('Dashboard', '/admin/dashboard')
        }
    }

    // const user_idle_time_out = user?.settings?.filter((i) => i?.key_name === 'ideal_user_timeOut')[0]?.value


    // const logoutTimer = useRef(null);
    // const TIMEOUT_DURATION = user_idle_time_out * 1000; // 10 minutes in milliseconds
    // useEffect(() => {
    //     const resetTimer = () => {
    //         if (logoutTimer.current) {
    //             clearTimeout(logoutTimer.current);
    //         }

    //         logoutTimer.current = setTimeout(logout, TIMEOUT_DURATION);
    //     };

    //     const clearTimer = () => {
    //         if (logoutTimer.current) {
    //             clearTimeout(logoutTimer.current);
    //             logoutTimer.current = null;
    //         }
    //     };

    //     const handleKeyDown = () => {
    //         resetTimer();
    //     };

    //     const handleMouseMove = () => {
    //         resetTimer();
    //     };

    //     const logout = () => {
    //         // Perform logout logic here
    //         if (window.location.pathname == DATA_ENTRY || window.location.pathname == QA_REVIEW) {
    //             changeAllTitleFunction('Dashboard', '/admin/dashboard')
    //         }
    //         //navigate("/login")
    //     };

    //     window.addEventListener('keydown', handleKeyDown);
    //     window.addEventListener('mousemove', handleMouseMove);

    //     resetTimer();

    //     return () => {
    //         window.removeEventListener('keydown', handleKeyDown);
    //         window.removeEventListener('mousemove', handleMouseMove);
    //         clearTimer();
    //     };
    // }, []);
    useEffect(() => {
        // trigger iframe page load to Iframe Compo
        setTriggerIframePageLoad()
    }, [])

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className='col-lg-6'>
                        <div className="Logo">
                            <div >
                                <img style={{ cursor: 'pointer' }} onClick={handleGoBack} src={Logo} alt="" />
                            </div>

                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <nav className="header-nav ms-auto">
                            <ul className="d-flex align-items-center justify-content-end">

                                <li className="nav-item d-block d-lg-none">
                                    <Link className="nav-link nav-icon search-bar-toggle" tabIndex={-1} >
                                        <i className="bi bi-search"></i>
                                    </Link>
                                </li>

                                <li className="nav-item dropdown pe-3 p-1">

                                    <Link className="nav-link nav-profile d-flex align-items-center pe-0" tabIndex={-1} data-bs-toggle="dropdown">
                                        <img src={profileimg} alt="Profile" className="rounded-circle" style={{ maxHeight: 28 }} />
                                        <span className="d-none d-md-block  ps-2">{user?.username}</span>
                                    </Link>


                                </li>

                            </ul>
                        </nav>
                    </div>

                </div>
            </div>

        </React.Fragment>
    );
}

export default Header;
