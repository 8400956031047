import React, { useState } from "react";
import DataTable from "react-data-table-component";
import datatableCustomStyles from "../../../Layout/Elements/DatatableCustomStyles";
import { GET_CORRESPONDANCE } from "../../../routeNames";
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
// import styled from 'styled-components/macro';
import AdminMaster from "../../../Layout/admin/Master";
import { useMemo } from "react";
import { useEffect } from "react";
import { getSFTPClientListData, pullSFTPFileManually } from "../../../Redux/sftpClient/action";
import axiosInstance from "../../../axiosInstance";
import { SFTP_CLIENT_LIST_URL } from "../../../api";
import { OverlayTrigger, Toast, ToastContainer } from "react-bootstrap";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Tooltip } from '@mui/material';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>

    </>
);
const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;



const SftpClientList = (params) => {
    const [pageno, setPageNo] = useState(1);
    const [loader, setLoader] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [filterText, setFilterText] = useState('');
    const [data, setData] = useState({})
    const [limit, setLimit] = useState(100)
    const [colorRow, setColorRow] = useState({});
    const [toaster, setToaster] = useState('')
    const [sortColumn, setSortColumn] = useState('client_name');
    const [sortOrder, setSortOrder] = useState('asc');

    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        // const response = await axiosInstance.get(SFTP_CLIENT_LIST_URL + "?page=" + page + "&limit=" + newPerPage);
        // // console.log(response);
        // setData(response?.data);
        // setLimit(newPerPage);
        // setTotalRows(response?.data?.data?.count);
        fetchData(page, newPerPage, filterText, sortOrder, sortColumn)
        setLoading(false);

    };

    const handlePageChange = page => {
        setPageNo(page)
        fetchData(page, limit, filterText, sortOrder, sortColumn)
    };
    const handleSearchSplits = async (e) => {
        setFilterText(e)
        let data = e ? e : '';
        // const response = await axiosInstance.get(SFTP_CLIENT_LIST_URL + "?limit=" + limit + "&search=" + data);
        // setData(response?.data);
        fetchData(1, limit, e, sortOrder, sortColumn)
    }
    const fetchData = (page, pageLimit, search = '', order, column) => {
        setLoading(true);
        // console.log("#$#$#$#$#$#$#$#$#$#$#$#$#$#$");
        getSFTPClientListData({ page, pageLimit, search, order, column })((response) => {
            // console.log(response);
            setPageNo(page)
            setLimit(pageLimit)
            setData(response?.data);
            setTotalRows(response?.data?.count);
            setLoading(false);
        });

    }
    useEffect(() => {
        fetchData(1, limit, '', sortOrder, sortColumn)
    }, []);
    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );
    const columns = useMemo(
        () => [
            {
                id: 'S.NO',
                name: 'S.NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                width: "60px",
                wrap: true,


            },
            {
                id: 'client_name',
                name: 'Client Name',
                selector: row => row?.client_name,
                cell: (row) =>


                    <div className="overflow-hidden d-flex flex-row  flex-wrap text-truncate " onClick={() => handleRowClick(row)} style={{ width: "140px" }}>
                        <Tooltip disableInteractive title={row?.client_name} placement='top' arrow>

                            <span className='text-truncate'  >
                                {row?.client_name}
                            </span>
                        </Tooltip>
                    </div>



                ,
                sortable: true,
                left: true,
                wrap: true,
                width: '160px',
                reorder: true,
            },
            {
                id: 'external_tat',
                name: 'External Tat',
                selector: row => row?.external_tat,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.external_tat}</span>,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                //width: "160px",

            },

            {
                id: 'internal_tat',
                name: 'Internal Tat',
                selector: row => row?.internal_tat,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.internal_tat}</span>,
                sortable: true,
                left: true,
                wrap: true,
                //width: "160px",
                reorder: true,

            },

            {
                id: 'country',
                name: 'Country',
                selector: row => row?.country,
                cell: (row) =>


                    <div className="overflow-hidden text-truncate" onClick={() => handleRowClick(row)} style={{ width: "180px" }}>

                        <Tooltip disableInteractive title={row?.country} placement='top' arrow>
                            <span className='text-truncate '>
                                {row?.country}
                            </span>
                        </Tooltip>
                    </div>,


                sortable: true,
                left: true,
                wrap: true,
                //width: "200px",
                reorder: true,

            },
            {
                id: 'Last Pulled',
                name: 'Last Pulled',
                selector: row => moment(row?.last_pulled).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.last_pulled ? moment(row?.last_pulled).format('M/DD/YY, H:mm') : "-----"}</span>,
                // sortable: true,
                left: true,
                wrap: true,
                //width: "200px",
                reorder: true,

            },

            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex justify-content-start" onClick={() => handleRowClick(row)}>
                        {loader == row.client ?

                            <div className="spinner-border spinner-border-sm me-3" style={{ margin: '9px' }} role="status">
                                {/* <span className="sr-only">Loading...</span> */}
                            </div>
                            :
                            checkUserPermission('pull_sftp') &&
                            <div style={{ cursor: "pointer" }} className=""
                            // onClick={() => pullSftpClient(row.client)}
                            >
                                <span className='badge badge-light-danger'>
                                    <div style={{ cursor: "pointer" }} className=""
                                        onClick={() => pullSftpClient(row.client)}
                                    >
                                        <span className='badge badge-light-danger'>


                                            Pull
                                        </span>
                                    </div>
                                </span>
                            </div>
                        }

                    </div>

                ,
                //ignoreRowClick: false,
                right: true,
                button: false,
                width: "80px",
                //button: true,

            },


        ],
        [loader, pageno],
    );

    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })
    const [isSwal, setIsSwal] = useState({
        status: false,
        message: "",
    })
    const pullSftpClient = async (pk) => {
        setLoader(pk)
        await pullSFTPFileManually({ client: pk })((response) => {
            if (response?.status && response?.status_code == 201) {
                // console.log(response?.data);
                setLoader(null)
                // setToaster('success')
                setToastMessage({
                    status: true,
                    message: response?.message,
                    type: 'success',
                })
                fetchData(pageno, limit, filterText, sortOrder, sortColumn)
                // const timer = setTimeout(() => {
                //     setToaster('')
                // }, 2000);
                // return () => clearTimeout(timer);
            }
            else if (response?.response?.data?.status_code == 400) {
                setLoader(null)
                setIsSwal({
                    status: true,
                    message: response?.response?.data?.message,
                })

            }
            else {
                setLoader(null)

                setIsSwal({
                    status: true,
                    message: response?.message,
                })
                // setToastMessage({
                //     status: true,
                //     message: response?.message,
                //     type: 'danger',
                // })
                // setToaster('error')
                // const timer = setTimeout(() => {
                //     setToaster('')
                // }, 2000);
                // return () => clearTimeout(timer);
            }
        });
    }

    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    // console.log(loader);
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.client == row.client ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow.client == row.client ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
    };

    const handleRowClickCheck = (row) => {

        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
    }

    const handleClear = () => {
        if (filterText) {
            setFilterText('');
            fetchData(1, limit, '', sortOrder, sortColumn);
        }
    };
    const handleSort = async (column, order) => {
        setSortColumn(column.id);
        setSortOrder(order);

        fetchData(1, limit, filterText, order, column.id);
    };


    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} />
            <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                    {
                        status: false,
                        message: "",
                        type: "",
                    }

                )}
                    show={ToastMessage?.status} animation={true} delay={2000} autohide>
                    <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                </Toast>

            </ToastContainer>


            {isSwal.status === true &&
                <SweetAlert
                    danger

                    confirmBtnText="Ok!"
                    //confirmBtnBsStyle="danger"
                    confirmBtnCssClass="custom-confirm-button-class"
                    onConfirm={() => { setIsSwal({ ...isSwal, status: false, message: "" }) }}
                >
                    <span className="fw-bold ">  {isSwal.message}</span>
                </SweetAlert>}

            <main id="main" className="main">

                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>SFTP Pull</h1>
                </div>

                <section className="section mt-5">
                    <div className="row align-items-top py-2  gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className='card-header pt-3'>
                                    <div className="d-flex align-items-center  position-relative ms-4">
                                        <FilterComponent onFilter={(e) => handleSearchSplits(e.target.value)} onClear={handleClear} filterText={filterText} />
                                    </div>
                                </div>

                                <div className="card-body" style={{ padding: "0px 2.25rem" }}>
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            <DataTable
                                                className="custom-datatable"
                                                fixedHeader={true}

                                                data={data?.results}
                                                columns={columns}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationPerPage={limit}
                                                paginationTotalRows={totalRows}
                                                paginationRowsPerPageOptions={[25, 50, 100]}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                conditionalRowStyles={conditionalRowStyles}
                                                onRowClicked={handleRowClickCheck}
                                                // subHeader
                                                // subHeaderComponent={subHeaderComponentMemo}
                                                theme="solarized"
                                                customStyles={datatableCustomStyles}
                                                onSort={handleSort}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </main>
        </React.Fragment>
    )
}
export default SftpClientList

