import React, { useEffect } from 'react';
import { useState } from 'react';

import { jobTimeout } from '../../../Redux/grid/action';
import PdfViewer from './PdfViewer/index';
import { useLocation, useParams } from 'react-router-dom';
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW } from './../../../routeNames';

const DataEntry = () => {
    const { job_id } = useParams();
    // const [activeFile, setActiveFile] = useState('FIRST')
    return (
        <React.Fragment>
            <h1 id='page-title' className='d-none'> {window.location.pathname == DATA_ENTRY ? "MY WORK" : window.location.pathname == EDIT_WORK + job_id ? "VIEW WORK" : window.location.pathname == QA_REVIEW ? "QA REVIEW" : "GRID"} </h1>

            <div className='ex2' >
                <PdfViewer />
            </div>

        </React.Fragment>
    );
}

export default DataEntry;
