import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from '@redux-devtools/extension';
import { configureStore } from '@reduxjs/toolkit'
import reducers from "./reducers";

export default (initialState) => {
    initialState = JSON.parse(window.localStorage.getItem("state")) || initialState;

    const middleware = [thunk];
    const composeEnhancers = composeWithDevTools({
        // options like actionSanitizer, stateSanitizer
    });
    // const store = configureStore(
    //     rootReducer,
    //     initialState,
    //     compose(
    //         applyMiddleware(...middleware)
    //         // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    //     )
    // );
    const store = createStore(reducers, /* preloadedState, */ composeEnhancers(
        applyMiddleware(...middleware),
        // other store enhancers if any
    ));
    store.subscribe(() => {
        const state = store.getState();
        //save the config to localstorage
        const persist = {
            auth: state.auth,

        };
        localStorage.setItem("state", JSON.stringify(persist));
        localStorage.setItem("bottomTab", JSON.stringify(state.bottomTab));

        // if (window.ReactNativeWebView) {
        //     const response = {
        //         type: 'state',
        //         data: persist
        //     };
        //     window.ReactNativeWebView.postMessage(JSON.stringify(response));
        // }
        // const user = {
        //     user: state.user
        // };
        // window.localStorage.setItem("user", JSON.stringify(user.user));
    });

    return store;
};