import React from 'react'
import Modal from 'react-modal';

const GlobelHotkey = (props) => {
    const { hotKey, setHotKey } = props;
    function __afterOpenModal() {
        subtitle.style.color = '#f00';
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "30%",
        },
    };
    return (
        <React.Fragment>
            <Modal
                isOpen={hotKey}
                onAfterOpen={__afterOpenModal}
                onRequestClose={() => { setHotKey(false); }}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <ul className="list-group">
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-5">Alt+N</div>
                        <div className="col-lg-5 text-nowrap">Add a new bottom tab. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-5">Alt+X</div>
                        <div className="col-lg-5 text-nowrap">Remove the current tab. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-5">Ctrl+Tab</div>
                        <div className="col-lg-5 text-nowrap">Switch bottom tab to forward. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-5">Ctrl+Shift+Tab</div>
                        <div className="col-lg-5 text-nowrap">Switch bottom tab to backward. </div>
                    </li>


                    <div className="checkviewbtn text-start">
                        <button className="btn btn-primary" onClick={() => { setHotKey(false); }}>Close</button>
                    </div>
                </ul>
            </Modal>
        </React.Fragment>

    )
}

export default GlobelHotkey