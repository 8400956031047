import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Button, OverlayTrigger } from 'react-bootstrap';
import AdminMaster from './../../../Layout/admin/Master/index';
import { Toast, ToastContainer } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import axiosInstance, { BACKEND_URL } from './../../../axiosInstance';
import { GET_CLIENTS_PDF_URL, GET_CLIENT_URL } from '../../../api';
import { getAllClients, syncToRevArc, toggleClient } from '../../../Redux/client//action';
import toggle from "../../../assets/img/toggle.svg";
import { COPY_CLIENT, CREATE_CLIENT, EDIT_CLIENT } from '../../../routeNames';
import { getPdf } from '../../../Redux/user/action';
import ClientDiffrenceReportModal from '../../BootstrapModal/ClientDiffrenceReportModal';
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';
import { clientDifferenceReport } from '../../../Redux/reports/action';
import { changeAllTitleFunction } from '../Dashboard/functions';
import { Tooltip } from '@mui/material';


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Client ID/Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;

const ClientOnBoard = () => {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [pageno, setPageNo] = useState(1);
    const [toaster, setToaster] = useState(false)
    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
    });
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [colorRow, setColorRow] = useState({});
    const [data, setData] = useState({})

    const [toggleReportPopup, setToggleReportPopup] = useState(false);
    const [reportData, setReportData] = useState([]);

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    // const pdfTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Download
    //     </Tooltip>
    // );
    // const editTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Edit
    //     </Tooltip>
    // );
    // const cloneTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Clone
    //     </Tooltip>
    // );
    // const toggleTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Status
    //     </Tooltip>
    // );
    // const reportTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Report
    //     </Tooltip>
    // );
    // const showTooltip = (text, props) => (
    //     text ? <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip> : ""


    // );

    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                width: "40px",
                //grow: 0,

            },
            {
                id: 'client id',
                name: 'Client ID',
                selector: row => row.client_id,
                cell: (row) =>
                    <React.Fragment>
                        {row.client_id &&
                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                                <Tooltip disableInteractive title={row?.client_id} placement='top' arrow>
                                    <span className='text-truncate' >{row.client_id}</span>
                                </Tooltip>
                            </div>
                        }
                    </React.Fragment>
                ,
                sortable: true,
                wrap: true,
                width: "160px",
                //grow: 0.2,

            },

            {
                id: 'name',
                name: 'Name',
                selector: row => row.name,
                cell: (row) =>
                    <React.Fragment>
                        {row.name &&
                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                                <Tooltip disableInteractive title={row?.name} placement='top' arrow>
                                    <span className='text-truncate'>{row.name}</span>
                                </Tooltip>
                            </div>
                        }
                    </React.Fragment>
                ,
                sortable: true,
                left: true,
                wrap: true,
                width: "180px",
                // grow: 0.2,


            },
            {
                id: 'username',
                name: 'Username',
                selector: row => row.username,
                cell: (row) =>
                    <React.Fragment>
                        {row.username &&
                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                                <Tooltip disableInteractive title={row?.username} placement='top' arrow>

                                    <span className='text-truncate' >{row.username}</span>
                                </Tooltip>
                            </div>
                        }
                    </React.Fragment>
                ,
                sortable: true,
                left: true,
                wrap: true,
                width: "160px",
                //grow: 0.3,


            },




            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex justify-content-center align-items-center gap-2 flex-shrink-0" onClick={() => { handleRowClick(row) }} style={{ width: "240px" }}>
                        <Tooltip disableInteractive title="Download" placement='top' arrow>
                            <div className=" fs-7 me-1 icon-style-client-pdf" onClick={() => __getPdf(row.id)}>
                                {/* <i className='bi bi-filetype-pdf fs-6'></i> */} PDF
                            </div>
                        </Tooltip>
                        <Tooltip disableInteractive title="Edit" placement='top' arrow>
                            <Link onClick={() => changeAllTitleFunction('Edit Client', EDIT_CLIENT + row?.id)}  >
                                <button className="btn  py-0 px-2 fs-7 btn-sm me-1">

                                    <span className='icon-style'>

                                        <i className='bi bi-pencil '></i>
                                    </span>
                                </button>
                            </Link>
                        </Tooltip>

                        {checkUserPermission('create_client') &&
                            <Tooltip disableInteractive title="Clone" placement='top' arrow>
                                <Link onClick={() => changeAllTitleFunction('Create Client', COPY_CLIENT + row?.id)} className="btn me-2 py-0 px-2" >
                                    <i className='bi bi-files fs-6' ></i>
                                </Link>
                            </Tooltip>
                        }

                        <Tooltip disableInteractive title="Status" placement='top' arrow>
                            <div className="form-check form-switch form-switch-toggle" onClick={() => __toggleClient(row.id)}>
                                <input className="form-check-input height-25" type="checkbox" defaultChecked={row.is_active} role="switch" id="flexSwitchCheckDefault" />
                            </div>
                        </Tooltip>
                        <Tooltip disableInteractive title="Report" placement='top' arrow>
                            <Link onClick={() => handleClientReport(row.id)} className="btn py-0 px-2" >
                                <BsLayoutTextSidebarReverse className='fs-6' />

                            </Link>
                        </Tooltip>
                    </div>,
                // ignoreRowClick: true,
                // allowOverflow: true,
                right: true,
                button: false,
                //width: "80px",
            },

        ],
        [pageno],
    );

    const fetchRoles = async (page, limit, search) => {
        setLoading(true);

        //setLimit(limit);
        getAllClients(page, limit, search)((response) => {
            if (response?.status && response?.status_code == 200) {
                setData(response?.data);
                setPageNo(page)
                setLimit(limit);
                setTotalRows(response?.data?.count);
                setLoading(false);

            }
        })
        //const response = await axiosInstance.get(GET_CLIENT_URL + "?page=" + page + "&limit=" + limit + "&search=" + data );


    };

    const handlePageChange = page => {
        setPageNo(page)
        fetchRoles(page, limit, filterText);
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        // const response = await axiosInstance.get(GET_CLIENT_URL + "?page=" + page + "&limit=" + newPerPage);
        // setData(response?.data);
        setLimit(newPerPage);
        setPageNo(1)
        // setTotalRows(response?.data?.data?.count);
        fetchRoles(1, newPerPage, filterText)
        setLoading(false);
        handleResetDataTable()

    };
    useEffect(() => {


        fetchRoles(1, limit);

    }, []);


    const __toggleClient = (client) => {
        let formData = new FormData();    //formdata object

        formData.append('client_id', client);

        toggleClient({ formData })((response) => {
            if (response?.status && response?.status_code === 201) {
                setState({ showToast: !state.showToast })
                setToaster('success')
                // getAllClients()((response) => {
                //     setData(response);
                // });
            } else {
                setState({ error: true, error_message: response?.message });
                setToaster('error')
            }
        });
    }
    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster('')
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [toaster])
    const searchGroups = async (e) => {
        setFilterText(e.target.value)
        let data = e.target.value ? e.target.value : '';
        // const response = await axiosInstance.get(GET_CLIENT_URL + "?limit=" + data?.data?.count + "&search=" + data);
        // setData(response?.data);
        fetchRoles(1, limit, data)
        handleResetDataTable()
    }
    // const filteredItems = data?.data?.results?.filter(
    //     item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    // );
    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, resetPaginationToggle]);
    const handleClear = () => {
        if (filterText) {
            //setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            // const response = await axiosInstance.get(GET_CLIENT_URL + "?page=" + 1 + "&limit=" + limit);
            // setData(response?.data);
            fetchRoles(1, limit)
        }
    };
    // file.replace('http://', 'https://');
    const __getPdf = async (id) => {
        const fileURL = await axiosInstance.get(GET_CLIENTS_PDF_URL + "?client=" + id)
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL?.data?.data;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const __closeToast = () => {
        setState({ showToast: !state.showToast })
    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
        // // console.log(row);
    };



    const [rotate, setRotate] = useState(false)
    const syncDataToRevArc = () => {

        setRotate(true)


        syncToRevArc()((response) => {

            if (response?.status) {

                setRotate(false)
                setToaster('success')

            }
            else {
                setRotate(false)
                if (response?.status_code == 208) {
                    setMessage(response.message)
                }
                setToaster('error')

            }


            // setData(response);
        });
    }
    const handleClientReport = (id) => {
        clientDifferenceReport(id)((response) => {
            if (response && response.status) {
                setReportData(response?.data)
                setToggleReportPopup(true)
            } else {
                setToggleReportPopup(false)
                setToaster("error")
            }
        })
    }

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }

    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} message={message} />
            <main id="main" className="main">
                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={state.showToast} onClose={__closeToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                </ToastContainer> */}
                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Clients</h1>

                </div>

                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0 px-4">
                                    <div className="d-flex align-items-center position-relative my-1">
                                        <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
                                    </div>

                                    <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                        <button className="btn btn-light btn-active-primary me-2" onClick={() => syncDataToRevArc()}>

                                            {rotate ?
                                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                                    {/* <span className="svg-icon svg-icon-3 me-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="22px" fill="#ffffff" height="22px"><path d="M 25 5 C 13.964844 5 5 13.964844 5 25 C 4.996094 25.359375 5.183594 25.695313 5.496094 25.878906 C 5.808594 26.058594 6.191406 26.058594 6.503906 25.878906 C 6.816406 25.695313 7.003906 25.359375 7 25 C 7 15.046875 15.046875 7 25 7 C 31.246094 7 36.726563 10.179688 39.957031 15 L 33 15 C 32.640625 14.996094 32.304688 15.183594 32.121094 15.496094 C 31.941406 15.808594 31.941406 16.191406 32.121094 16.503906 C 32.304688 16.816406 32.640625 17.003906 33 17 L 43 17 L 43 7 C 43.003906 6.730469 42.898438 6.46875 42.707031 6.277344 C 42.515625 6.085938 42.253906 5.980469 41.984375 5.984375 C 41.433594 5.996094 40.992188 6.449219 41 7 L 41 13.011719 C 37.347656 8.148438 31.539063 5 25 5 Z M 43.984375 23.984375 C 43.433594 23.996094 42.992188 24.449219 43 25 C 43 34.953125 34.953125 43 25 43 C 18.753906 43 13.269531 39.820313 10.042969 35 L 17 35 C 17.359375 35.007813 17.695313 34.816406 17.878906 34.507813 C 18.058594 34.195313 18.058594 33.808594 17.878906 33.496094 C 17.695313 33.1875 17.359375 32.996094 17 33 L 8.445313 33 C 8.316406 32.976563 8.1875 32.976563 8.058594 33 L 7 33 L 7 43 C 6.996094 43.359375 7.183594 43.695313 7.496094 43.878906 C 7.808594 44.058594 8.191406 44.058594 8.503906 43.878906 C 8.816406 43.695313 9.003906 43.359375 9 43 L 9 36.984375 C 12.648438 41.847656 18.460938 45 25 45 C 36.035156 45 45 36.035156 45 25 C 45.003906 24.730469 44.898438 24.46875 44.707031 24.277344 C 44.515625 24.085938 44.253906 23.980469 43.984375 23.984375 Z" /></svg>
                                                    </span> */}
                                                </div>
                                                :
                                                // <div class="spinner-border spinner-border-sm text-light" role="status">
                                                <span className="svg-icon svg-icon-3 me-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="22px" fill="#ffffff" height="22px"><path d="M 25 5 C 13.964844 5 5 13.964844 5 25 C 4.996094 25.359375 5.183594 25.695313 5.496094 25.878906 C 5.808594 26.058594 6.191406 26.058594 6.503906 25.878906 C 6.816406 25.695313 7.003906 25.359375 7 25 C 7 15.046875 15.046875 7 25 7 C 31.246094 7 36.726563 10.179688 39.957031 15 L 33 15 C 32.640625 14.996094 32.304688 15.183594 32.121094 15.496094 C 31.941406 15.808594 31.941406 16.191406 32.121094 16.503906 C 32.304688 16.816406 32.640625 17.003906 33 17 L 43 17 L 43 7 C 43.003906 6.730469 42.898438 6.46875 42.707031 6.277344 C 42.515625 6.085938 42.253906 5.980469 41.984375 5.984375 C 41.433594 5.996094 40.992188 6.449219 41 7 L 41 13.011719 C 37.347656 8.148438 31.539063 5 25 5 Z M 43.984375 23.984375 C 43.433594 23.996094 42.992188 24.449219 43 25 C 43 34.953125 34.953125 43 25 43 C 18.753906 43 13.269531 39.820313 10.042969 35 L 17 35 C 17.359375 35.007813 17.695313 34.816406 17.878906 34.507813 C 18.058594 34.195313 18.058594 33.808594 17.878906 33.496094 C 17.695313 33.1875 17.359375 32.996094 17 33 L 8.445313 33 C 8.316406 32.976563 8.1875 32.976563 8.058594 33 L 7 33 L 7 43 C 6.996094 43.359375 7.183594 43.695313 7.496094 43.878906 C 7.808594 44.058594 8.191406 44.058594 8.503906 43.878906 C 8.816406 43.695313 9.003906 43.359375 9 43 L 9 36.984375 C 12.648438 41.847656 18.460938 45 25 45 C 36.035156 45 45 36.035156 45 25 C 45.003906 24.730469 44.898438 24.46875 44.707031 24.277344 C 44.515625 24.085938 44.253906 23.980469 43.984375 23.984375 Z" /></svg>
                                                </span>
                                                // </div>
                                            }
                                            <span className='mx-2'>

                                                Sync
                                            </span>

                                        </button>
                                        {checkUserPermission('create_client') &&
                                            <Link onClick={() => changeAllTitleFunction('Create Client', CREATE_CLIENT)} className="btn btn-light btn-active-primary">
                                                <span className="svg-icon svg-icon-3">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                    </svg>
                                                </span>
                                                Onboard
                                            </Link>
                                        }

                                    </div>
                                </div>
                                <div className="card-body py-3">
                                    <div className="table-responsive">
                                        <div className='dashboardtable'>
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}

                                                    data={data?.results}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangePage={handlePageChange}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}

                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                        </div>
                    </div>
                </section>
                <ClientDiffrenceReportModal
                    toggleReportPopup={toggleReportPopup}
                    setToggleReportPopup={setToggleReportPopup}
                    reportData={reportData}
                    setReportData={setReportData}
                />
            </main>
        </React.Fragment>
    )
}

export default ClientOnBoard;