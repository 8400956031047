
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Tooltip } from '@mui/material';


const ClientDiffrenceReportModal = ({ toggleReportPopup, setToggleReportPopup, reportData, setReportData }) => {
    const urlRegex = /https?:\/\/(?:www\.|(?!www))[^\s.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,}/;

    function convertToTitleCase(arg, key = '') {
        let str = arg



        if (typeof arg === 'boolean' || typeof arg === 'number' || typeof arg === 'string') {
            if (str == 'QA') {
                str = 'QA%'
            }
            else if (str == 'Take_back_Re_pay') {
                str = "Take back & Re-pay"
            }
            else {
                str = String(str)
            }
        } else if (typeof arg === 'object') {
            str = JSON.stringify(arg)
        }
        else {
            str = urlRegex.test(arg) && key == 'old' ? "" : urlRegex.test(arg) ? "file updated" : arg
        }


        if (str) {
            str = str.replace("_array", "")
            if (str == 'npi') {
                str = 'NPI'
            } else if (str == 'tin') {
                str = 'TIN'
            }
        }

        // Split the string into an array of words
        const words = str?.split('_');
        // Capitalize the first letter of each word
        if (urlRegex.test(str)) {
            let returnValue = "File Updated"
            return returnValue
        } else {
            const capitalizedWords = words?.map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            });

            // Join the capitalized words with a space
            const titleCaseStr = capitalizedWords?.join(' ');
            return titleCaseStr;
        }
    }
    function convertToTitleCaseDBK(arg, key = '') {
        let str = arg
        // Split the string into an array of words
        const words = str?.split('_');

        // Capitalize the first letter of each word
        const heading = ['EOB835', 'EOB835Lite', 'PatientPay', 'Correspondence']
        const capitalizedWords = words?.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        capitalizedWords.shift()
        // Join the capitalized words with a space
        const titleCaseStr = capitalizedWords?.join(' ');
        return titleCaseStr;

    }

    function convertCustomisationToTitleCase(str, key = '') {

        if (str === 'Single_GS_GE_Segment') {
            str = 'Single GS/GE Segment'
        }
        else if (str === 'Enable_REF_EV') {
            str = 'Enable REF*EV'
        }

        // Remove "is_" prefix if present
        if (str?.startsWith('is_')) {
            str = str?.slice(3);
        }

        // Split the string into an array of words
        const words = str?.split('_');

        // Capitalize the first letter of each word
        const capitalizedWords = words?.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });

        // Join the capitalized words with a space
        const titleCaseStr = capitalizedWords?.join(' ');
        return titleCaseStr;
    }
    const dataBasedOnType = (arg) => {
        if (typeof arg === 'boolean') {
            return arg == true ? 'True' : 'False'
        } else if (typeof arg === 'string') {
            return arg
        } else {
            return arg

        }
    }
    const checkIsUrl = (val, key = '') => {
        let data = val
        if (urlRegex.test(val) && key == 'new') {
            data = 'File Updated'
        }
        else if (urlRegex.test(val) && key == 'old') {
            data = ''
        }

        else if (val == 'Password') {
            data = 'Password Updated'

        }
        return data
    }
    const isExists = (data) => data?.length > 0 && data.some(i => {
        return (i.old && typeof i.old === 'object' && Object.keys(i.old).length !== 0) ||
            (i.old && typeof i.old === 'string' && i.old.trim() !== '') ||
            (i.new && typeof i.new === 'object' && Object.keys(i.new).length !== 0) ||
            (i.new && typeof i.new === 'string' && i.new.trim() !== '') ||
            (i.new && typeof i.new === 'boolean') ||
            (i.old && typeof i.old === 'boolean');
    });


    const header_arr = []
    const headerIsExists = (name) => {
        let text = false
        if (header_arr.includes(name)) {
            text = true
        }
        header_arr.push(name)
        return text
    }
    const CommonComponent = (data, component_type = "") => {
        const isExists = data?.length > 0 && data.some(i => {
            return (i.old && typeof i.old === 'object' && Object.keys(i.old).length !== 0) ||
                (i.old && typeof i.old === 'string' && i.old.trim() !== '') ||
                (i.new && typeof i.new === 'object' && Object.keys(i.new).length !== 0) ||
                (i.new && typeof i.new === 'string' && i.new.trim() !== '') ||
                (i.new && typeof i.new === 'boolean') ||
                (i.old && typeof i.old === 'boolean');
        });
        return data?.length > 0 && data?.map((i, index) =>
            // Object.keys(i).length !== 0 && (i.old || i.new) &&
            isExists &&
            <>

                {index == 0 && !headerIsExists(i?.header) ?
                    <>
                        <tr>
                            <th colSpan="3"><span className=" badge table-report-badge">{i?.header && index != 0 ? "" : component_type}</span><p className="table-report-patient-account-text"> </p> </th>
                            {/* <th colSpan="3"><span className=" badge table-report-badge">{i?.header && index != 0 ? "" : component_type}</span><p className="table-report-patient-account-text"> </p> </th> */}
                        </tr>
                        {i?.header &&
                            <tr>
                                <th colSpan="3"><span className=" badge table-report-badge"></span><p className="table-report-patient-account-text"> {i?.header}</p> </th>
                                {/* <th colSpan="3"><span className=" badge table-report-badge">{i?.header && index != 0 ? "" : component_type}</span><p className="table-report-patient-account-text"> </p> </th> */}
                            </tr>
                        }
                    </>
                    : null
                }
                <tr key={index}>
                    {Object.keys(i).map((key, index) => (
                        typeof i[key] === 'object' && key != 'header' ?
                            <td key={index}>

                                <table>
                                    {i[key] && typeof i[key] === 'object' &&

                                        Object.keys(i[key]).map((k, idx) => (
                                            k != 'value' && k != 'id' && i[key][k] &&

                                            <tr>
                                                {convertToTitleCase(k) != 'Group Name' &&
                                                    <th>{convertToTitleCase(k)}</th>
                                                }
                                                <td>{k == 'Password' ? 'Updated' : i[key][k] != null && typeof i[key][k] == 'object' ? JSON.stringify(i[key][k]) : checkIsUrl(i[key][k])}</td>
                                            </tr>
                                        ))

                                    }
                                </table>
                            </td> : typeof i[key] === 'boolean' && key != 'header' ?
                                <td key={index}>
                                    <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                        {i[key] == true ? "True" : "False"}
                                    </div>
                                </td> : typeof i[key] === 'string' && key != 'header' ?
                                    <td key={index}>
                                        {key == "key" ?
                                            <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                {!['Outbound', 'Claims', 'Client', 'Default', 'Excluded Payers'].includes(convertToTitleCase(i[key], key)) ? convertToTitleCase(i[key], key) : ""}

                                            </div>
                                            :
                                            <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                {i['key'] == 'Password' && key == 'new' ? 'Updated' : i['key'] == 'Password' && key == 'old' ? '' : checkIsUrl(i[key], key)}
                                            </div>
                                        }
                                    </td> : typeof i[key] === 'number' && key != 'header' ?
                                        <td key={index}>
                                            <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                {convertToTitleCase(i[key], key)}

                                            </div>
                                        </td> : null

                    ))}
                </tr>
            </>


        )
    }
    const handleBoolean = (value) => {
        const val = value ? "True" : "False"
        return val
    }
    const arrayOrDictTableComponent = (data) => {
        return <table>
            {data && Array.isArray(data) ?
                data.map((i, idx) => (

                    Object.keys(i).map((k, idx) => (
                        k != 'image_id' && k != 'value' && k != 'id' && i[k] && i[k] != '---Select---' &&
                        <tr>

                            <th>{convertToTitleCase(k)}</th>
                            {k == 'Password' ?
                                <td>Updated</td>
                                :
                                <td>{i[k] != null && typeof i[k] == 'object' ? JSON.stringify(i[k]) : typeof i[k] == 'boolean' ? handleBoolean(i[k]) : checkIsUrl(i[k])}</td>
                            }
                        </tr>
                    ))
                )) :
                typeof data == 'string' ? data : typeof data == 'boolean' ? handleBoolean(data) : null}
        </table>

    }
    const advance_customisation = reportData?.client_data?.advance_customisation
    const dbk_data = reportData?.client_data?.dbk
    const contact_data = reportData?.client_data?.contact
    const operation_log_data = reportData?.client_data?.operationlog_headers

    const dbk_data_grouped = dbk_data && dbk_data?.reduce((acc, curr) => {
        const heading = curr.key.split('_')[0];
        let og_headding = heading
        if (heading == 'EOB835') {
            og_headding = 'EOB 835'
        }
        else if (heading == 'EOB835Lite') {
            og_headding = 'EOB835 Lite'
        }
        else if (heading == 'PatientPay') {
            og_headding = 'Patient Pay'
        }
        if (!acc[og_headding]) {
            acc[og_headding] = [];
        }
        acc[og_headding].push(curr);
        return acc;
    }, {});



    return (
        <Modal size="xl" show={toggleReportPopup} onHide={() => { setToggleReportPopup(false), setReportData([]) }} centered className='manual-tat'>
            <Modal.Header closeButton>
                <Modal.Title className="table-report-modelTitle">Report</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <Table >
                    <tbody>
                        <tr>

                            <th className="d-flex flex-row align-items-center  justify-content-around " >
                                <div className="flex-shrink-1">
                                    <span className="my-2 badge table-report-badge " >Previous Updated User</span>
                                </div>
                                <div className="flex-grow-1 ms-3">

                                    <span className="table-report-tr  " >{reportData?.created_user?.old}</span>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="d-flex flex-row align-items-center  justify-content-around ">
                                <div className="flex-shrink-1">
                                    <span className="my-2 badge table-report-badge">Previous Date</span>
                                </div>
                                <div className="flex-grow-1 ms-3">

                                    <span className="table-report-tr">{reportData?.time?.old ? moment(reportData?.time?.old).format('MM/DD/YYYY HH:mm') : "--------"}</span>
                                </div>

                            </th>
                        </tr>

                        <tr>
                            <th className="d-flex flex-row align-items-center  justify-content-around ">
                                <div className="flex-shrink-1">

                                    <span className="my-2 badge table-report-badge">Last Updated User</span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <span className="table-report-tr">{reportData?.created_user?.new}</span>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="d-flex flex-row align-items-center  justify-content-around ">
                                <div className="flex-shrink-1">

                                    <span className="my-2 badge table-report-badge">Last Date</span>
                                </div>
                                <div className="flex-grow-1 ms-3">

                                    <span className="table-report-tr ">{reportData?.time?.new ? moment(reportData?.time?.new).format('MM/DD/YYYY HH:mm') : "--------"}</span>
                                </div>
                            </th>
                        </tr>
                    </tbody>

                </Table>
                <table className="table history-log-table" >

                    <thead>
                        <tr >
                            <th >Field</th>
                            <th >Previous</th>
                            <th >Updated</th>
                        </tr>
                    </thead>
                    {reportData?.client_data?.facility?.map((i, index) =>
                        <tbody key={index}>
                            {index === 0 &&
                                <tr>
                                    <th colSpan="3"><span className=" badge table-report-badge">{index == 0 && 'Facility'}</span><p className="table-report-patient-account-text"> </p> </th>
                                </tr>
                            }
                            {i.facility_name &&
                                <tr>
                                    <th colSpan="3"><span className=" badge table-report-badge"></span><p className="table-report-patient-account-text">Facility: {i.facility_name} </p> </th>
                                </tr>
                            }
                            {i?.facility_name ?
                                i?.facility_data?.map((j, index) =>
                                    <>

                                        {convertToTitleCase(j.old) !== '---Select---' && convertToTitleCase(j.new) !== '---Select---' &&
                                            <tr>
                                                <td>
                                                    <div style={{ width: '170px', wordWrap: 'break-word' }}>
                                                        {convertToTitleCase(j.key)}
                                                    </div>
                                                </td>
                                                <td><div style={{ width: '240px', wordWrap: 'break-word' }}> {j.key == "Password" ? "" : arrayOrDictTableComponent(j.old)}</div></td>
                                                <td>
                                                    <div style={{ width: '240px', wordWrap: 'break-word' }}> {j.key == "Password" ? "Updated" : arrayOrDictTableComponent(j.new)}</div>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                )
                                :
                                (i?.old?.length > 0 || i?.new?.length > 0) &&
                                <tr>
                                    {
                                        Object.keys(i).map((key, index) =>
                                            key != 'section' &&
                                            < td className="report-td-style-defulat 1" >
                                                <table>
                                                    {i[key] && typeof i[key] === 'object' &&

                                                        Object.keys(i[key]).map((k, idx) => (
                                                            k != 'value' && k != 'id' && i[key][k] &&

                                                            <tr>
                                                                <td className="report-td-style-defulat 2">{i[key][k] != null && typeof i[key][k] == 'object' ?
                                                                    Object.keys(i[key][k]).map((j, idxj) => (
                                                                        Array.isArray(i[key][k][j]) ?
                                                                            <>
                                                                                {i[key][k][j]?.length > 0 &&
                                                                                    <tr>
                                                                                        <th>{convertToTitleCase(j)}</th>
                                                                                    </tr>}

                                                                                <tr>
                                                                                    <td className="report-td-style-defulat 3">
                                                                                        <table>
                                                                                            {Object.keys(i[key][k][j]).map((f, idxj) => (
                                                                                                Object.keys(i[key][k][j][f]).map((g, idxj) => (
                                                                                                    i[key][k][j][f][g] && i[key][k][j][f][g] != '---Select---' &&
                                                                                                    <tr>
                                                                                                        <td className="report-td-style-defulat 4">{convertToTitleCase(g)}:</td>
                                                                                                        {convertToTitleCase(g).includes('Folder') ?

                                                                                                            <td className="report-td-style-defulat 5">
                                                                                                                <Tooltip title={JSON.stringify(i[key][k][j][f][g])} placement='top' arrow>
                                                                                                                    <div style={{ maxWidth: '15px' }}>
                                                                                                                        <i class="bi bi-file-earmark"></i>
                                                                                                                    </div>
                                                                                                                </Tooltip>
                                                                                                            </td>
                                                                                                            :
                                                                                                            <td className="report-td-style-defulat 5">{g == 'Password' ? 'Updated' : typeof i[key][k][j][f][g] == 'string' ? checkIsUrl(i[key][k][j][f][g]) : JSON.stringify(i[key][k][j][f][g])}</td>
                                                                                                        }
                                                                                                    </tr>
                                                                                                ))
                                                                                            ))}

                                                                                        </table>

                                                                                    </td>
                                                                                </tr>


                                                                            </> :
                                                                            <>
                                                                                {idxj == 0 &&
                                                                                    <tr>
                                                                                        <th className="clientReport-facility">Facility</th>

                                                                                    </tr>
                                                                                }

                                                                                <tr>
                                                                                    <td className="report-td-style-defulat">
                                                                                        <table>

                                                                                            <tr>
                                                                                                <td className="report-td-style-defulat">{convertToTitleCase(j)}:</td>
                                                                                                <td className="report-td-style-defulat">{j == 'Password' ? 'Updated' : typeof i[key][k][j] == 'string' ? i[key][k][j] : JSON.stringify(i[key][k][j])}</td>
                                                                                            </tr>


                                                                                        </table>
                                                                                    </td>
                                                                                </tr>

                                                                            </>
                                                                    ))

                                                                    : checkIsUrl(i[key][k])}</td>
                                                            </tr>
                                                        ))

                                                    }
                                                </table>
                                            </td>

                                        )
                                    }
                                </tr>
                            }
                        </tbody>
                    )}

                    {/* <tbody>
                        {CommonComponent(reportData?.client_data?.facility, 'Facility')}
                    </tbody> */}
                    <tbody>
                        {/* {CommonComponent(contact_data, 'Contact')} */}
                        {contact_data?.length > 0 && contact_data?.map((i, index) =>
                            // Object.keys(i).length !== 0 && (i.old || i.new) &&
                            isExists(contact_data) &&
                            <>

                                {index == 0 &&
                                    <>
                                        <tr>
                                            <th colSpan="3"><span className=" badge table-report-badge">{i?.header && index != 0 ? "" : 'Contact'}</span><p className="table-report-patient-account-text"> </p> </th>
                                            {/* <th colSpan="3"><span className=" badge table-report-badge">{i?.header && index != 0 ? "" : component_type}</span><p className="table-report-patient-account-text"> </p> </th> */}
                                        </tr>
                                        {/* {i?.header &&
                                            <tr>
                                                <th colSpan="3"><span className=" badge table-report-badge"></span><p className="table-report-patient-account-text"> {i?.header}</p> </th>
                                            </tr>
                                        } */}
                                    </>
                                }
                                {i?.header && !headerIsExists(i?.header) &&
                                    <tr>
                                        <th colSpan="3"><span className=" badge table-report-badge"></span><p className="table-report-patient-account-text"> {i?.header}</p> </th>
                                    </tr>}


                                <tr key={index}>
                                    {Object.keys(i).map((key, index) => (
                                        typeof i[key] === 'object' && key != 'header' ?
                                            <td key={index}>

                                                <table>
                                                    {i[key] && typeof i[key] === 'object' &&

                                                        Object.keys(i[key]).map((k, idx) => (
                                                            k != 'value' && k != 'id' && i[key][k] && k != 'type' &&

                                                            <tr>
                                                                <th>{convertToTitleCase(k)}</th>
                                                                <td>{k == 'Password' ? 'Updated' : i[key][k] != null && typeof i[key][k] == 'object' ? JSON.stringify(i[key][k]) : checkIsUrl(i[key][k])}</td>
                                                            </tr>
                                                        ))

                                                    }
                                                </table>
                                            </td> : typeof i[key] === 'boolean' && key != 'header' ?
                                                <td key={index}>
                                                    <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                        {i[key] == true ? "True" : "False"}
                                                    </div>
                                                </td> : typeof i[key] === 'string' && key != 'header' ?
                                                    <td key={index}>
                                                        <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                            {i[key] == 'Password' ? 'Updated' : key === 'key' ? convertToTitleCase(i[key], key) : checkIsUrl(i[key])}

                                                        </div>
                                                    </td> : typeof i[key] === 'number' && key != 'header' ?
                                                        <td key={index}>
                                                            <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                                {key === 'key' ? convertToTitleCase(i[key], key) : checkIsUrl(i[key])}

                                                            </div>
                                                        </td> : null

                                    ))}
                                </tr>
                            </>


                        )}
                    </tbody>
                    <tbody>

                        {CommonComponent(reportData?.client_data?.default, 'Default')}
                    </tbody>
                    <tbody>

                        {CommonComponent(reportData?.client_data?.outbound, 'Outbound')}

                    </tbody>
                    <tbody>

                        {CommonComponent(reportData?.client_data?.snipping_customisation, 'Snippet')}

                    </tbody>

                    <tbody>
                        {CommonComponent(reportData?.client_data?.client, 'Client')}
                    </tbody>

                    <tbody>

                        {CommonComponent(reportData?.client_data?.claims, 'Claims')}

                    </tbody>
                    <tbody>
                        {CommonComponent(reportData?.client_data?.excluded_payers, "Excluded Payers")}
                    </tbody>
                    <tbody>
                        {advance_customisation?.length > 0 && advance_customisation?.map((i, index) =>
                            isExists(advance_customisation) &&
                            <>
                                {index == 0 &&
                                    <tr>
                                        <th colSpan="3"><span className=" badge table-report-badge">{i?.header ?? 'Customisation'}</span><p className="table-report-patient-account-text"> </p> </th>
                                    </tr>
                                }
                                <tr key={index}>
                                    {Object.keys(i).map((key, index) => (
                                        i[key] != null && typeof i[key] === 'object' && key != 'header' ?
                                            <td key={index}>

                                                <table>
                                                    {i[key] && typeof i[key] === 'object' &&

                                                        Object.keys(i[key]).map((k, idx) => (
                                                            k != 'value' && k != 'id' && i[key][k] &&
                                                            <tr>
                                                                <th>{k}</th>
                                                                <td>{i[key][k]}</td>
                                                            </tr>
                                                        ))

                                                    }
                                                </table>
                                            </td> : i[key] != null && typeof i[key] === 'boolean' && key != 'header' ?
                                                <td key={index}>
                                                    <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                        {i[key] == true ? "True" : "False"}
                                                    </div>
                                                </td> : i[key] != null && typeof i[key] === 'string' && key != 'header' ?
                                                    <td key={index}>
                                                        <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                            {key === 'key' ? convertCustomisationToTitleCase(i[key], key) : checkIsUrl(i[key])}
                                                        </div>
                                                    </td> : <td></td>

                                    ))}
                                </tr>
                            </>


                        )}

                    </tbody>

                    <tbody>
                        {dbk_data_grouped && Object.keys(dbk_data_grouped)?.map((heading, index) => (
                            <>
                                {index === 0 && (
                                    <tr>
                                        <th colSpan="3">
                                            <span className=" badge table-report-badge">{dbk_data_grouped[heading][0]?.header ?? 'DBK'}</span>
                                            <p className="table-report-patient-account-text"> </p>
                                        </th>
                                    </tr>
                                )}
                                <tr>
                                    <th>{heading}</th>
                                    <td></td>
                                    <td></td>
                                </tr>
                                {dbk_data_grouped[heading].map((i, index) => (
                                    <tr key={index}>
                                        {Object.keys(i).map((key, index) => (
                                            i[key] != null && typeof i[key] === 'boolean' && key != 'header' ? (
                                                <td key={index}>
                                                    <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                        {i[key] == true ? "True" : "False"}
                                                    </div>
                                                </td>
                                            ) : i[key] != null && typeof i[key] === 'string' && key != 'header' ? (
                                                <td key={index}>
                                                    <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                                        {key === 'key' && convertToTitleCaseDBK(i[key], key)}
                                                    </div>
                                                </td>
                                            ) : (
                                                <td></td>
                                            )
                                        ))}
                                    </tr>
                                ))}
                            </>
                        ))}
                    </tbody>
                    <tbody>
                        {operation_log_data && Object.keys(operation_log_data)?.map((i, index) => (
                            <>
                                {index === 0 && (
                                    <tr>
                                        <th colSpan="3">
                                            <span className=" badge table-report-badge">{'OPERATION LOG'}</span>
                                            <p className="table-report-patient-account-text"> </p>
                                        </th>
                                    </tr>
                                )}
                                {/* {operation_log_data.map((i, index) => ( */}
                                <tr key={index}>
                                    <td key={index}>
                                        <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                            {convertToTitleCase(operation_log_data[i].key)}
                                        </div>
                                    </td>
                                    <td key={index}>
                                        <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                            {operation_log_data[i].old}
                                        </div>
                                    </td>
                                    <td key={index}>
                                        <div style={{ width: '240px', wordWrap: 'break-word' }}>
                                            {operation_log_data[i].new}
                                        </div>
                                    </td>


                                </tr>
                                {/* ))} */}
                            </>
                        ))}
                    </tbody>
                </table >

            </Modal.Body >
        </Modal >
    )


}
export default ClientDiffrenceReportModal;







