import React, { useRef, useState } from "react";
import { ScrollMode, SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import Modal from "react-modal";

const CheckViewModal = ({ fileUrl, initialPage, rotatePosition, showCheckView, setShowCheckView, setIsDrag }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "90%",
        },
    };
    return <Modal
        isOpen={showCheckView}
        onRequestClose={() => { setShowCheckView(false); setIsDrag(true) }}
        style={customStyles}
        contentLabel="Example Modal"
    >
        <div className="modal-custome-header">
            <h3>Check</h3>
            <span style={{ cursor: 'pointer' }} onClick={() => { setShowCheckView(false); setIsDrag(true) }} className='close-icon'><i className="bi bi-x-square"></i></span>
        </div>
        <div>
            <Worker workerUrl="../../../../../assets/js/pdf.worker.min.js">
                <div className=""
                    style={{
                        height: '500px',
                        overflowY: 'hidden !important',
                    }}
                >
                    <Viewer initialRotation={rotatePosition == 'straight' ? 0 : rotatePosition == 'right' ? 90 : rotatePosition == 'bottom' ? 180 : rotatePosition == 'left' ? 270 : 0} initialPage={initialPage - 1} scrollMode={ScrollMode.Page} fileUrl={fileUrl} defaultScale={SpecialZoomLevel.PageWidth} />
                </div>
            </Worker>
        </div>
    </Modal>
}
export default CheckViewModal;




