import React from 'react'
import Master from '../../../../Layout/admin/Master'
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { getFacilityDropdown, getSingleCorrespondenceCategory, getSingleGroupDetails, getSingleReasonCode, getX12CodeDropdown, updateCorrespondenceCategory, updateReasonCodes } from '../../../../Redux/user/action';
import { useNavigate } from 'react-router-dom';
import { CORRESPONDENCE_CODES } from '../../../../routeNames';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import AdminMaster from '../../../../Layout/admin/Master';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';



const EditCorrespondenceCategory = () => {


    let { id } = useParams();
    const navigate = useNavigate()

    const [clients, setClients] = useState([])
    const [facilities, setFacilities] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [selectedFacility, setSelectedFacility] = useState([])
    useEffect(() => {

        getAllClientDropdown()((response) => {
            if (response?.status) {


                setClients(response?.data)
            }
        })

    }, []);
    useEffect(() => {
        if (categoryformik?.values?.clients && categoryformik?.values?.clients.length > 0) {
            getFacilityDropdown(categoryformik?.values?.clients)((response) => {
                if (response && response.status) {
                    setFacilities(response.data.facilities?.map(result => { return { options: result?.facilities, label: result?.client } }))
                }
            });
        }

    }, [selectValue])

    const [state, setState] = useState({
        error: false,
        error_message: ""
    })
    // const [payers, setPayers] = useState([]);
    // const [x12Codes, setX12Codes] = useState([])
    // const [data, setData] = useState([]);
    const [toaster, setToaster] = useState('')
    const CategorySchema = Yup.object().shape({
        category: Yup.string()
            .required('Required').matches(/^[ A-Za-z0-9]*$/, 'Enter Valid Category Codes'),



    });
    const categoryformik = useFormik({
        initialValues: {
            clients: [],
            facility: [],
            category: ""

        },
        CategorySchema,
        validate: (values) => {
            if (values.category !== "") {
                setState({ error: false, error_message: "" })
            }
            else if (values.category == "") {
                setState({ error: true, error_message: "Required" })
            }
            else {
                setState({ error: true, error_message: "Required" })
            }

        },
        onSubmit: (values) => {
            __submitForm(values)
        },
    });
    useEffect(() => {
        // getX12CodeDropdown(1)((response) => {
        //     if (response && response.status && response.data && response.data.results) {
        //         setX12Codes(response.data.results)
        //     }
        // });
        // getPayerDropdown(1)((response) => {
        //     if (response && response.status && response.data && response.data.results) {

        //         setPayers(response.data.results)

        //     }
        // });


        getSingleCorrespondenceCategory(id)((response) => {

            // console.log(response);
            if (response?.status && response?.data) {
                // console.log(response?.data)
                // setData(response?.data);

                categoryformik.setValues({
                    category: response?.data?.category,
                    clients: response?.data?.client?.map((data) => data.value),
                    facility: response?.data?.facility?.map((data) => data?.value),
                })
                setSelectedFacility(response?.data?.facility);

                setSelectValue(response?.data?.client)

                // categoryformik.setValues({
                //     ...categoryformik.values,
                //     category_type: type.find((element) => {
                //         return element.value == response?.data?.results[0]?.category_type;
                //     }),
                //     x12codes: response?.data?.results[0]?.x12codes,
                //     codes: response?.data?.results[0]?.codes,
                //     values: response?.data?.results[0]?.values,
                // })
            }
        });

    }, []);

    const options = [
        // { value: "all", label: "All" },
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];

    const [facilitySelected, setFacilitySelected] = useState([])

    const handleSelectChange = (selected) => {
        let selectedIds = [];
        let selectedOptions = [];

        if (selected && selected?.length > 0) {

            selected?.map((option) => {
                selectedIds.push(option.value)
                selectedOptions.push(option)
            })
            if (selected?.length > 1) {
                setSelectedFacility([])
                setFacilities([])
                setFacilitySelected([])
            }
        }
        else {
            selectedOptions = [];
            selectedIds = [];
            setFacilities([])
            setSelectedFacility([])
        }
        categoryformik.setValues({ ...categoryformik.values, "clients": selectedIds ?? "", "facility": selected?.length > 1 ? "" : facilitySelected });
        setSelectValue(selectedOptions ?? "")

    };
    const handleFacilityChange = (selected) => {
        categoryformik.setFieldValue("facility", selected.map((option) => option?.value ?? ""));
        setSelectedFacility(selected)
        setFacilitySelected(selected.map((option) => option?.value ?? ""))


    };
    const [message, setMessage] = useState(null);

    const __submitForm = (values) => {

        const RedirectId = getRedirectTabId()
        // const categories
        updateCorrespondenceCategory(id, values?.clients, facilitySelected, categoryformik.values.category)((response) => {
            if (response?.status) {
                setToaster('success')
                const timer = setTimeout(() => {
                    changeAllTitleFunction("Correspondence Category", CORRESPONDENCE_CODES, RedirectId)
                    // navigate(CORRESPONDENCE_CODES)
                }, 2000);
                return () => clearTimeout(timer);
            }
            else {
                setToaster('error')
                if (response?.response?.data?.errors?.category && response?.response?.data?.errors?.category[0]) {
                    setMessage(response?.response?.data?.errors?.category[0])
                }
                const timer = setTimeout(() => {
                    setToaster(false)
                }, 2000);
                return () => clearTimeout(timer);

            }
        });
    }


    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {categoryformik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}
            </components.DropdownIndicator>
        );
    };


    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);
    // console.log(toaster);
    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} message={message} />
            <main id="main" className="main">
                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" onClose={() => setToaster(!toaster)} show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" onClose={() => setToaster(!toaster)} show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Something went wrong</span></Toast.Body>
                    </Toast>
                </ToastContainer> */}
                <div className="pagetitle tab-fixed-header ">
                    <h1 id='page-title'>Edit Correspondence Category</h1>
                </div>

                <section className="section mt-5">
                    <div className="card card-flush py-4">

                        <div className="card-body pt-0 ">
                            <FormikProvider value={categoryformik}>
                                <Form
                                    onSubmit={categoryformik.handleSubmit}
                                >

                                    <div >
                                        <div className='row'>
                                            <div className='col-lg-5'>
                                                <div className="mb-10">
                                                    <label htmlFor="exampleFormControlInput1" className=" form-label">Clients</label>


                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isMulti
                                                        value={selectValue}
                                                        name="clients"
                                                        options={options}
                                                        components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select Clients..."
                                                        onFocus={() => setIsFocusedClient(true)}
                                                        onBlur={() => setIsFocusedClient(false)}
                                                        styles={{
                                                            container: (styles, state) => ({
                                                                ...styles,
                                                                borderRadius: "9px",
                                                            }),
                                                            control: (styles, state) => ({
                                                                ...styles,
                                                                backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                borderStyle: "none", boxShadow: "none",
                                                                borderRadius: "10px",
                                                                maxHeight: state.isFocused ? "auto" : "30px",
                                                            }),
                                                            indicatorSeparator: styles => ({
                                                                ...styles, width: "0px", minHeight: "20px"
                                                            }),

                                                            valueContainer: (styles, state) => ({
                                                                ...styles,
                                                                maxHeight: isFocusedClient ? "auto" : "30px",
                                                            }),
                                                        }}
                                                    />

                                                    {!!categoryformik.errors?.clients &&
                                                        <span className='text-danger'>{categoryformik.errors?.clients}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="mb-10">
                                                    <Form.Group>
                                                        <Form.Label htmlFor="exampleFormControlInput1" className=" form-label">Facility</Form.Label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isMulti
                                                            value={selectedFacility}
                                                            name="facility"
                                                            options={facilities}
                                                            components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="facility" /> }}
                                                            onChange={handleFacilityChange}
                                                            placeholder="Select Facility..."
                                                            isDisabled={selectValue.length > 1 ? true : false}
                                                            onFocus={() => setIsFocusedFacility(true)}
                                                            onBlur={() => setIsFocusedFacility(false)}
                                                            styles={{
                                                                container: (styles, state) => ({
                                                                    ...styles,
                                                                    borderRadius: "9px",
                                                                }),
                                                                control: (styles, state) => ({
                                                                    ...styles,
                                                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                    borderStyle: "none", boxShadow: "none",
                                                                    borderRadius: "10px",
                                                                    maxHeight: state.isFocused ? "auto" : "30px",
                                                                }),
                                                                indicatorSeparator: styles => ({
                                                                    ...styles, width: "0px", minHeight: "20px"
                                                                }),

                                                                valueContainer: (styles, state) => ({
                                                                    ...styles,
                                                                    maxHeight: isFocusedFacility ? "auto" : "30px",
                                                                }),


                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{categoryformik?.errors?.facility}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row mt-2'>

                                            <div className='col-lg-6'>
                                                <div className="">

                                                    <label htmlFor="exampleFormControlInput1" className="required form-label">Category</label>

                                                    <input type="text" name='category' value={categoryformik?.values?.category} onChange={categoryformik.handleChange} className="form-control form-control-solid b-r-10" placeholder=" " />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {state.error &&
                                        <div className='mt-3'>

                                            <span className='text-danger'>{state.error_message}</span>
                                        </div>
                                    }

                                    <div className=' mt-2 d-flex flex-row justify-content-end'>
                                        <div className=''>
                                            <button
                                                type='button'
                                                className='btn btn-secondary btn-active-primary me-2'
                                                onClick={() => { changeAllTitleFunction("Correspondence Category", CORRESPONDENCE_CODES) }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        {checkUserPermission('create_correspondence_category') &&
                                            <div className=' '>
                                                <button
                                                    disabled={state.error}
                                                    type='submit'
                                                    className='btn btn-primary btnHover new-window'
                                                >
                                                    Update
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </Form>
                            </FormikProvider>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default EditCorrespondenceCategory;