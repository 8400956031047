import React, { useEffect, useRef } from 'react'
import DataTable from 'react-data-table-component';
import { createTheme } from 'react-data-table-component';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import useAutocomplete from '@mui/base/useAutocomplete';
import styled from 'styled-components/macro';
import { useState } from 'react';
import DetailDataTable from './ClientDetailDataTable';
import { useCallback } from 'react';
import { DateRangePicker, DatePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment';
import { getDashboardReportData } from '../../../Redux/auth/action';
import { Tooltip } from "@mui/material";
import { getAllClientDropdown } from '../../../Redux/client/action';
import { getFacilityDropdown } from '../../../Redux/user/action';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import InputMask from "react-input-mask";
import DateMaskInput from './componenets/DateMaskInput';

import { useFormik } from "formik";
import * as Yup from "yup";

const queueDatatableCustomStyles = {
    rows: {
        style: {
            minHeight: '20px', // override the row height
            fontSize: '1em',
            color: '#016b87',
            backgroundColor: '#D8E5E9',
            borderBottom: '1px solid white !important'

            // width: '100%'

        },
    },

    headCells: {
        style: {
            backgroundColor: '#F0F5F6',
            border: '1px solid #fff !important',
            borderBottom: '1px solid #fff !important',

            paddingLeft: '5px', // override the cell padding for head cells
            paddingRight: '5px',
            paddingTop: '10px',
            textTransform: "uppercase",
            fontSize: '0.9em',
            // fontWeight: '800',
            color: '#016b87 !important',
            opacity: 1,
            minHeight: "100px",

        },
        draggingStyle: {
            cursor: 'move',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            width: "100%",
            fontColor: '#000000',
            fontWeight: "550",
            borderRight: '1px solid #fff !important',
        },
    },

};


function DashboardDataTable() {
    const [errorDate, setErrorDate] = useState(false)

    const fromDateRef = useRef(null)
    const fromTimeRef = useRef(null)
    const [isCopieValue, setIsCopieValue] = useState(false);

    const [loading, setLoading] = useState(false);
    const [fullClientExpand, setFullClientExpand] = useState(false);
    const [fullFacilityExpand, setFullFacilityExpand] = useState(false);


    const clientRef = useRef(null)
    const facilityRef = useRef(null)
    const dateTimeRef = useRef(null)

    const [isFocusedStatus, setIsFocusedStatus] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const [detailExpandedRows, setDetailExpandedRows] = useState([]);


    const [colorRow, setColorRow] = useState([]);
    const [dateTime, setDateTime] = useState(null);
    const [data, setData] = useState({});
    const [isNoData, setIsNoData] = useState(false);
    const [copyData, setCopyData] = useState([]);

    const [facilities, setFacilities] = useState([])
    const [fullFacilities, setFullFacilities] = useState([])

    const [clients, setClients] = useState([]);
    const [selectIds, setSelectIds] = useState()

    const [selectValue, setSelectValue] = useState([])
    const [selectedFacility, setSelectedFacility] = useState([])
    const [selectedFacilityIds, setSelectedFacilityIds] = useState([])

    const [filterDateTime, setFilterDateTime] = useState(null);
    const [fromSinglrDate, setFromSinglrDate] = useState(null);
    const [toSingleDate, setToSingleDate] = useState(null);

    const [customeDateTime, setCustomeDateTime] = useState({
        toDateInValid: false,
        fromDateInValid: false,
        initialfromDate: null,
        initialtoDate: null,
        fromDate: null,
        fromTime: "",
        toDate: null,
        toTime: "",
    })



    const formik = useFormik({
        initialValues: {
            accountNumber: "",
            checkDate: "",
            checkAmount: "",
            aba_routing_number: "",
            payer_account_number: "",
        },
        validationSchema: Yup.object().shape({
            accountNumber: Yup.string()
                .required("Required")
                .matches(/^[ A-Za-z0-9_@./#&+-]*$/, "enter valid check number"),
            checkDate: Yup.date().required("Required"),
            checkAmount: Yup.number().required("Required"),
            aba_routing_number: Yup.number().required("Required"),
            payer_account_number: Yup.string()
                .required("Required")
                .matches(/^[a-zA-Z0-9]+$/, "Enter a valid Payer Account Number"),
        }),
        validate: (values) => {
            let errors = {};

            if (!moment(values.checkDate, "MM/DD/YYYY", true).isValid()) {
                errors.checkDate = "Invalid date format";
            }
            return errors;
        },
        onSubmit: (values) => {
            if (!moment(values.checkDate, "MM/DD/YYYY", true).isValid()) {
                // errors.checkDate = 'Invalid date format';
                formik.setErrors({
                    checkDate: "Invalid date format",
                });
            } else {
            }
        },
    });
    const handleKeyDown = (e, name) => {
        if (e.shiftKey === true && e.key === 'Tab') {
            e.preventDefault(); // Prevent the tab key from changing the selection
            if (name === 'facility') {
                clientRef.current.focus()
            }
        }
        else if (e.key === 'Tab') {
            e.preventDefault(); // Prevent the tab key from changing the selection
            if (name === 'client') {
                facilityRef.current.focus();
            } else {
                const dateRangePicker = dateTimeRef.current;
                if (dateRangePicker) {
                    const inputElement = dateRangePicker.querySelector('input');
                    if (inputElement) {
                        inputElement.focus();
                    }
                }
            }
        }
    }
    const ExpandedComponent = useCallback(
        ({ data }) => (
            <pre style={{ marginBottom: 'unset' }}>
                {expandedRows?.length > 0 && (
                    <DetailDataTable setIsCopieValue={setIsCopieValue} dataId={data.id} data={data?.facility} detailExpandedRows={detailExpandedRows} setDetailExpandedRows={setDetailExpandedRows}
                    />
                )}
            </pre>
        ),
        [expandedRows]
    );
    const handleRowExpand = useCallback((row) => {

        const test = [...expandedRows]
        const isRowExpanded = expandedRows?.find((i) => i?.id === row?.id) ? true : false;
        const curentRaw = isRowExpanded ? [] : row

        // test.push(row)

        setExpandedRows(isRowExpanded ? test.filter(i => i.id != row.id) : [...expandedRows, row]);
        setColorRow(curentRaw);
    });


    const clientTotal = (data) => {
        const totals = {
            close_to_tat: 0,
            completed_jobs: 0,
            missed_tat: 0,
            my_work: 0,
            pending_jobs: 0,
            qa_jobs: 0,
            split_files: 0,
            total_jobs: 0,
            my_work_pending_jobs: 0,
            qa_review_pending_jobs: 0,
            snipping_jobs: 0,
            snipping_pending_jobs: 0
        };
        data?.facility?.forEach(facility => {
            facility?.tat?.forEach(tat => {
                totals.close_to_tat += tat.close_to_tat;
                totals.completed_jobs += tat.completed_jobs;
                totals.missed_tat += tat.missed_tat;
                totals.my_work += tat.my_work;
                totals.pending_jobs += tat.pending_jobs;
                totals.qa_jobs += tat.qa_jobs;
                totals.split_files += tat.split_files;
                totals.total_jobs += tat.total_jobs;
                totals.my_work_pending_jobs += tat.my_work_pending_jobs;
                totals.qa_review_pending_jobs += tat.qa_review_pending_jobs;
                totals.snipping_jobs += tat.snipping_jobs;
                totals.snipping_pending_jobs += tat.snipping_pending_jobs;
            });
        });
        return totals
    }
    const handleFullClientExpand = () => {
        setFullClientExpand(!fullClientExpand)
    }
    const handleFullFacilityExpand = () => {
        setFullFacilityExpand(!fullClientExpand)
    }
    const beforeMaskedValueChange = (newState, oldState, userInput) => {
        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    }
    const columns = [
        {
            name: (
                <div style={{ width: '100%' }}>
                    <div className='mb-3 d-flex align-items-center justify-content-between'>
                        <p> Client</p>
                        {/* <span className='dashboard-detail-plus-icon' onClick={() => { handleFullClientExpand() }}>
                            {fullClientExpand ?

                                <i class="bi bi-dash"></i>
                                :
                                <i class="bi bi-plus"></i>
                            }
                        </span> */}
                    </div>
                    <div id='dashboard-client-select-container'>
                        <Select
                            ref={clientRef}
                            isMulti
                            options={clients}
                            value={selectValue}
                            className=""
                            classNamePrefix="select"
                            placeholder="Client"
                            menuPlacement="auto"
                            maxMenuHeight={300}
                            isClearable
                            name="client"
                            onChange={(val) => handleSelectChange(val)}
                            onKeyDown={(e) => { handleKeyDown(e, 'client') }}
                            onFocus={() => setIsFocusedStatus(true)}
                            onBlur={() => setIsFocusedStatus(false)}
                            styles={{
                                container: (styles, state) => ({
                                    ...styles,
                                    borderRadius: "9px",
                                }),
                                control: (styles, state) => ({
                                    ...styles,
                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                    borderStyle: "none", boxShadow: "none",
                                    borderRadius: "10px",
                                    maxHeight: state.isFocused ? "auto" : "30px",
                                }),
                                indicatorSeparator: styles => ({
                                    ...styles, width: "0px",
                                    minHeight: "20px"
                                }),

                                valueContainer: (styles, state) => ({
                                    ...styles,
                                    maxHeight: isFocusedStatus ? "auto" : "30px",
                                }),
                            }}
                            // styles={{
                            //     container: (styles, state) => ({
                            //         ...styles,
                            //         borderRadius: "9px",
                            //         position: "relative"
                            //     }),
                            //     control: (styles, state) => ({
                            //         ...styles,
                            //         backgroundColor: "hsl(0deg 0% 100%)",
                            //         overflow: 'hidden',
                            //         borderStyle: "none", boxShadow: "none",
                            //         borderRadius: "3px",
                            //         minHeight: "0px",
                            //         position: "absolute",
                            //         zIndex: "2",
                            //         top: "-12px",
                            //         width: "100%",
                            //         maxHeight: state.isFocused ? "auto" : "26px",
                            //     }),
                            //     placeholder: styles => ({
                            //         ...styles, marginBottom: !isFocusedStatus && "8px", marginTop: isFocusedStatus && "4px"
                            //     }),
                            //     dropdownIndicator: styles => ({
                            //         ...styles, paddingTop: !isFocusedStatus ? "0px" : "10px", marginBottom: !isFocusedStatus && "3px"
                            //     }),
                            //     clearIndicator: styles => ({
                            //         ...styles, paddingTop: !isFocusedStatus && "0px", marginBottom: !isFocusedStatus && "3px"
                            //     }),
                            //     multiValue: styles => ({
                            //         ...styles, fontSize: "8px", marginBottom: "10px", marginLeft: "0px", marginRight: "0px", marginTop: "0px"
                            //     }),
                            //     indicatorSeparator: styles => ({
                            //         ...styles, width: "0px", minHeight: "20px",
                            //     }),

                            //     valueContainer: (styles, state) => ({
                            //         ...styles,
                            //         maxHeight: isFocusedStatus ? "auto" : "30px",
                            //     }),
                            // }}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                    </div>

                </div>
            ),
            selector: row => row.name,
            cell: (row) => <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <div className='overflow-hidden text-truncate' style={{ width: "230px" }} onClick={() => handleRowExpand(row)}>
                    <Tooltip disableInteractive title={row?.name} placement="top" arrow>
                        <span style={{ display: "block" }} className="text-truncate"> {row?.name}</span>
                    </Tooltip>
                </div>
                {!isNoData &&
                    <span className='dashboard-detail-plus-icon' onClick={() => { handleRowExpand(row) }}>
                        {expandedRows.find((i) => i?.id === row?.id) ?

                            <i class="bi bi-dash"></i>
                            :
                            <i class="bi bi-plus"></i>
                        }
                    </span>
                }

            </div>,
            conditionalCellStyles: [
                {
                    when: row => row.id == colorRow.id,
                    style: {
                        backgroundColor: '#D8E5E9',
                        color: '#016b87',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },
                {
                    when: row => row.id != colorRow.id,
                    style: {
                        backgroundColor: '#D8E5E9',
                        color: '#016b87',
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    },
                },

            ],
            sortable: false,
            width: '15%',
            colSpan: 10

        },
        {
            name: (
                <div style={{ width: '100%' }}>
                    <div className='mb-3 d-flex align-items-center justify-content-between'>
                        <p> Facility</p>
                        {/* <span className='dashboard-detail-plus-icon' onClick={() => { handleFullClientExpand() }}>
                            {fullClientExpand ?

                                <i class="bi bi-dash"></i>
                                :
                                <i class="bi bi-plus"></i>
                            }
                        </span> */}
                    </div>
                    <div>
                        <Select
                            ref={facilityRef}
                            options={facilities}
                            className=""
                            classNamePrefix="select"
                            placeholder="Facility"
                            menuPlacement="auto"
                            maxMenuHeight={200}
                            isClearable
                            isMulti
                            name="facility"
                            value={selectedFacility}
                            onChange={(val) => handleSelectChange(val, 'facility')}
                            onFocus={() => setIsFocusedFacility(true)}
                            onBlur={() => setIsFocusedFacility(false)}
                            onKeyDown={(e) => { handleKeyDown(e, 'facility') }}

                            styles={{
                                container: (styles, state) => ({
                                    ...styles,
                                    borderRadius: "9px",
                                }),
                                control: (styles, state) => ({
                                    ...styles,
                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",


                                    borderStyle: "none", boxShadow: "none",
                                    borderRadius: "10px",
                                    maxHeight: state.isFocused ? "auto" : "30px",
                                }),
                                indicatorSeparator: styles => ({
                                    ...styles, width: "0px",
                                    minHeight: "20px"
                                }),

                                valueContainer: (styles, state) => (
                                    {
                                        ...styles,
                                        maxHeight: isFocusedFacility ? "auto" : "30px",
                                    }
                                ),
                            }}
                            menuPortalTarget={document.body}
                            closeMenuOnSelect={false} // Keep the menu open after selecting an option

                        />
                    </div>

                </div>
            ),
            selector: row => "",
            width: '13%',

            sortable: false,
        },
        {
            name: (
                <div style={{ width: '100%' }}>
                    <div className='mb-3'>TAT (Date:Time)</div>

                    <DateMaskInput setCustomeDateTime={setCustomeDateTime} customeDateTime={customeDateTime} fromDateRef={fromDateRef} dateTimeRef={dateTimeRef} />
                </div>
            ),
            selector: row => row.tat_datetime,
            sortable: false,
            width: '11%'
        },
        {
            name:
                (
                    <div>
                        <div>CLOSE</div>
                        <div>TO TAT</div>
                    </div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.close_to_tat : "",
            sortable: false,
            right: true,
            width: '6%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                    <div>
                        <div>Missed</div>
                        <div>TAT</div>
                    </div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.missed_tat : "NO DATA",
            sortable: false,
            right: true,
            width: '5.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                    <div>
                        <div>File</div>
                        <div>SPlit</div>
                    </div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.split_files : "",
            sortable: false,
            right: true,
            width: '4.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                    <div>
                        <div>Total</div>
                        <div>Jobs</div>
                    </div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.total_jobs : "",
            sortable: false,
            right: true,
            width: '5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'IMPORTED',
            selector: row => !isNoData ? clientTotal(row)?.my_work_pending_jobs : "",
            sortable: false,
            right: true,
            width: '5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                    <div>
                        <div>My</div>
                        <div>Work</div>
                    </div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.my_work : "",
            sortable: false,
            right: true,
            width: '4.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                    <div>
                        <div>QA</div>
                        <div>Review</div>
                    </div>
                ),
            selector: row => !isNoData ? clientTotal(row)?.qa_review_pending_jobs : "",
            sortable: false,
            right: true,
            width: '6%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'QA',
            selector: row => !isNoData ? clientTotal(row)?.qa_jobs : "",
            sortable: false,
            right: true,
            width: '3.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'Snipping',
            selector: row => !isNoData ? clientTotal(row)?.snipping_jobs : "",
            sortable: false,
            right: true,
            width: '5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name:
                (
                    <div>
                        <div>Snipping</div>
                        <div>Inprocess</div>
                    </div>
                ),

            selector: row => !isNoData ? clientTotal(row)?.snipping_pending_jobs : "",
            sortable: false,
            right: true,
            width: '6%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },

        {
            name: 'Pending',
            selector: row => !isNoData ? clientTotal(row)?.pending_jobs : "",
            sortable: false,
            right: true,
            width: '4.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
        {
            name: 'Completed',
            selector: row => !isNoData ? clientTotal(row)?.completed_jobs : "",
            sortable: false,
            right: true,
            width: '5.5%',
            conditionalCellStyles: [
                {
                    when: row => row,
                    style: {
                        fontWeight: '900'
                    },
                },


            ],
        },
    ];

    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];

    const handleOpen = (e) => {
        // console.log(fromSinglrDate)
        // if (fromSinglrDate == null) {
        //     alert("$$")
        //     const button = document.querySelector('button.rs-calendar-header-title.rs-calendar-header-title-time.rs-btn.rs-btn-subtle.rs-btn-xs')
        //     button.textContent = '00:00'
        // }
    }

    const handleOnDateTimeChange = ({ name, value }) => {
        setExpandedRows([])
        let created_date = ""
        const nowDate = new Date()
        if (value !== null) {
            if (name === 'from') {
                setFromSinglrDate(value)
                filterClients(selectIds, selectedFacilityIds, value, null)

            } else if (name === 'to') {
                // if (
                //     (fromSinglrDate == null) &&
                //     (value.getHours() === nowDate.getHours() ||
                //         value.getMinutes() === nowDate.getMinutes())
                // ) {
                //     value = value.setHours(2, 3, 5, 9);

                // }
                setToSingleDate(value)
                filterClients(selectIds, selectedFacilityIds, fromSinglrDate, value)

            }
            // const datefrom = moment(value['0']).format('MM/DD/YYYY')
            // const dateto = moment(value['1']).format('MM/DD/YYYY')
            // setFilterDateTime(value)
            // filterClients(selectIds, selectedFacilityIds, value['0'], value['1'])
            // created_date = datefrom + " - " + dateto
        } else {
            setFromSinglrDate(null)
            setToSingleDate(null)
            filterClients(selectIds, selectedFacilityIds, null, null)

        }

        setDateTime(created_date)

    }
    const checkSelectedFecilityInitialClient = (selectedOptions) => {
        // Initialize an empty result array and a set to track unique labels
        var result = [...selectValue];
        var uniqueLabels = new Set();
        const clientId = selectValue?.map((value) => String(value?.value))

        // Loop through selected options
        for (var i = 0; i < selectedOptions.length; i++) {
            var selectedOption = selectedOptions[i];

            // Loop through the list of dictionaries to find matching options
            for (var j = 0; j < fullFacilities.length; j++) {
                var options = fullFacilities[j].options;
                for (var k = 0; k < options.length; k++) {
                    if (options[k].value === selectedOption.value && options[k].label === selectedOption.label) {
                        // Check if the label is unique before adding it to the result
                        if (!uniqueLabels.has(fullFacilities[j].label) && !clientId.includes(fullFacilities[j].id)) {
                            result.push({
                                "value": Number(fullFacilities[j].id),
                                "label": fullFacilities[j].label,
                            });
                            uniqueLabels.add(fullFacilities[j].label);
                        }
                        break;
                    }
                }
            }
        }
        ("Result:", result);
        return result
    }

    const handleSelectChange = (val, name = null) => {
        let selectedOptions = [];
        let selectedIds = [];

        if (val && val?.length > 0) {

            val?.map((option) => {
                selectedIds.push(option.value)
                selectedOptions.push(option)
            })
            // if (val?.length > 1) {
            //     setSelectedFacility([])
            //     setFacilities([])
            // }
        } else {
            // setFacilities([])
            setSelectedFacility([])
            setSelectedFacilityIds([])
        }

        if (name == "facility") {
            const initiaClientsOptions = checkSelectedFecilityInitialClient(selectedOptions)
            const clientIds = initiaClientsOptions?.map((value) => value?.value)
            setSelectedFacility(selectedOptions)
            setSelectedFacilityIds(selectedIds)
            setSelectValue(initiaClientsOptions)
            setFacilityOptions(clientIds)
            filterClients(clientIds, selectedIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)
        } else {
            // setClients(val.includes('all') ? ['all'] : selectedId)
            if (!(val && val?.length > 0)) {
                if (clients && clients.length > 0) {
                    const clientId = clients?.map((value) => value?.value)
                    setFacilityOptions(clientId)
                }
                filterClients([], [], customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)

            } else {
                getFacilityDropdown(selectedIds)((response) => {
                    if (response && response.status) {
                        const feciltyIdArr = []
                        setFacilities(response.data.facilities?.map(result => {
                            result?.facilities?.map(item => feciltyIdArr.push(item.value));
                            return { options: result?.facilities, label: result?.client }

                        }))

                        const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
                        setSelectedFacility(newArray)
                    }
                });
                filterClients(selectedIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)

            }

            setSelectValue(selectedOptions)
            setSelectIds(selectedIds)
            // setSelectedFacility([])
        }
    };

    const handleFacilityChange = (selected, setValues, values) => {
        setValues({ ...values, "facility": selected.map((option) => option?.value) });
        setSelectedFacility(selected)


    };
    const addClientsToSelect = (is_all = true) => {
        const clientData = []
        for (let i = 0; i < data?.length; i++) {
            let dict = {
                value: data[i].id,
                label: data[i].name
            }
            clientData.push(dict)
        }
        // console.log(clientData)
        // clientData.unshift({
        //     value: "all",
        //     label: "All"
        // })
        setClients(clientData)
    }

    const setFacilityOptions = (clientId) => {
        if (clientId.length > 0) {
            getFacilityDropdown(clientId)((response) => {
                if (response && response.status) {
                    const feciltyIdArr = []
                    setFacilities(response.data.facilities?.map(result => {
                        result?.facilities?.map(item => feciltyIdArr.push(item.value));
                        return { options: result?.facilities, label: result?.client, id: result?.pk }

                    }))

                }
            });
        }
    }
    useEffect(() => {
        setLoading(true)
        getDashboardReportData()((response) => {
            // console.log(response);
            if (response?.status && response.status_code == 200) {

                const data = response?.data
                setData(data)
                setIsNoData(false)

                setCopyData(data)
                const clientData = []
                const clientId = []
                for (let i = 0; i < data?.length; i++) {
                    let dict = {
                        value: data[i].id,
                        label: data[i].name
                    }
                    clientData.push(dict)
                    clientId.push(data[i].id)

                }
                getFacilityDropdown(clientId)((response) => {
                    if (response && response.status) {
                        const feciltyIdArr = []
                        const FullFacilities = []
                        setFacilities(response.data.facilities?.map(result => {
                            result?.facilities?.map(item => feciltyIdArr.push(item.value));
                            FullFacilities.push({ options: result?.facilities, label: result?.client, id: result?.pk })
                            return { options: result?.facilities, label: result?.client, id: result?.pk }

                        }))

                        setFullFacilities(FullFacilities)
                        const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
                        setSelectedFacility(newArray)
                    }
                });
                // clientData.unshift({
                //     value: "all",
                //     label: "All"
                // })
                setClients(clientData)
                setLoading(false)
            }
            else {
                setIsNoData(true)

            }
        })
        // getAllClientDropdown()((response) => {
        //     if (response?.status) {
        //         setClients(response?.data)
        //     }
        // })
    }, []);
    // useEffect(() => {
    //     if (selectValue && selectValue.length > 0) {
    //         if (!selectValue.includes('all')) {
    //             const clientId = selectValue?.map((value) => value?.value)
    //             getFacilityDropdown(selectValue?.map((value) => value?.value))((response) => {
    //                 if (response && response.status) {
    //                     const feciltyIdArr = []
    //                     setFacilities(response.data.facilities?.map(result => {
    //                         console.log(result?.facilities, 'EEEEE')
    //                         result?.facilities?.map(item => feciltyIdArr.push(item.value));
    //                         return { options: result?.facilities, label: result?.client }

    //                     }))

    //                     const newArray = selectedFacility.filter(item => feciltyIdArr.includes(item.value));
    //                     setSelectedFacility(newArray)
    //                 }
    //             });
    //             // setFacilityOptions(clientId)

    //         } else {
    //             setFacilities([])
    //         }
    //     }

    // }, [selectValue])

    function isEmptyDataAllConditions(fromDate, toDate, fromTime, toTime) {
        let isEmpty = false
        if (fromDate && fromDate !== '__/__/____' && !moment(fromDate, "MM/DD/YYYY", true).isValid()) {
            isEmpty = true;
        }
        if (toDate && toDate !== '__/__/____' && !moment(toDate, "MM/DD/YYYY", true).isValid()) {
            isEmpty = true;
        }

        // Add time validation here
        if (fromTime && fromTime !== '__:__' && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(fromTime)) {
            isEmpty = true;
        }

        if (toTime && toTime !== '__:__' && !/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(toTime)) {
            isEmpty = true;
        }
        if (toDate == '' && fromDate == '' && toTime && fromTime && toTime !== '__:__' && fromTime !== '__:__' && !(!/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(toTime)) && !(!/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(fromTime))) {
            if (fromTime > toTime) {
                isEmpty = true;

            }
        }
        // Compare fromDate and toDate
        if (moment(fromDate, "MM/DD/YYYY").isAfter(moment(toDate, "MM/DD/YYYY"))) {
            isEmpty = true;
        }
        return isEmpty;
    }

    function filterClients(clientId = [], facilityId = [], fromDate = null, toDate = null, fromTime = null, toTime = null) {
        fromTime = fromTime === '' || fromTime === '__:__' ? null : fromTime
        toTime = toTime === '' || toTime === '__:__' ? null : toTime

        if (fromDate != '' && fromDate != null && fromDate != '__/__/____') {
            fromDate = new Date(fromDate)
        }
        else {
            fromDate = null
        }
        if (toDate != '' && toDate != null && toDate != '__/__/____') {
            toDate = new Date(toDate)

        }
        else {
            toDate = null
        }


        let filteredData = [...copyData]; // Create a copy of the original data

        if (clientId.length > 0 && !clientId.includes('all')) {
            filteredData = filteredData.filter(client => clientId.includes(client.id));
        }

        if (facilityId.length > 0) {
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.filter(facility => facilityId.includes(facility.id));
                return { ...client, facility: filteredFacilities };
            });
            filteredData = filteredData.filter(client => client.facility.length > 0);

        }
        if (isEmptyDataAllConditions(fromDate, toDate, fromTime, toTime)) {
            filteredData = []
        }
        else if (fromDate != null && toDate != null && fromTime != null && toTime != null) {

            const fromTimeArr = fromTime.split(':')
            const toTimeArr = toTime.split(':')
            fromDate.setHours(fromTimeArr[0]);
            fromDate.setMinutes(fromTimeArr[1]);
            toDate.setHours(toTimeArr[0]);
            toDate.setMinutes(toTimeArr[1]);
            fromDate.setSeconds(0);
            toDate.setSeconds(0);


            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat);

                        return tatDate.getTime() >= fromDate.getTime() && tatDate.getTime() <= toDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromDate != null && fromTime != null && toDate != null) {

            const fromTimeArr = fromTime.split(':')
            fromDate.setHours(fromTimeArr[0]);
            fromDate.setMinutes(fromTimeArr[1]);
            fromDate.setSeconds(0);
            toDate.setSeconds(0);


            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat);

                        return tatDate.getTime() >= fromDate.getTime() && tatDate.getTime() <= toDate.getTime() || tatDate.getTime() == fromDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }


        else if (fromDate != null && toDate != null && toTime != null) {
            const toTimeArr = toTime.split(':')


            toDate.setHours(toTimeArr[0]);
            toDate.setMinutes(toTimeArr[1]);
            fromDate.setSeconds(0);
            toDate.setSeconds(0);


            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat);

                        return tatDate.getTime() >= fromDate.getTime() && tatDate.getTime() <= toDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromDate != null && fromTime != null) {
            const fromTimeArr = fromTime.split(':')
            fromDate.setHours(fromTimeArr[0]);
            fromDate.setMinutes(fromTimeArr[1]);
            fromDate.setSeconds(0);
            fromDate = fromDate.getTime();
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        let tatDate = new Date(tat.internal_tat);
                        tatDate = tatDate.getTime();
                        return tatDate == fromDate;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (toDate != null && toTime != null) {
            // alert("toDate !== null && fromTime !== null")           
            const toTimeArr = toTime.split(':')
            toDate.setHours(toTimeArr[0]);
            toDate.setMinutes(toTimeArr[1]);
            // toDate.setSeconds(0);
            toDate = toDate.getTime();
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        let tatDate = new Date(tat.internal_tat)
                        // tatDate = tatDate.setSeconds(0);
                        tatDate = tatDate.getTime();
                        return tatDate <= toDate;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromTime != null && toTime != null) {

            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat)
                        // const tatTime = tatDate.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

                        // return tatTime >= fromDate && tatTime <= toDate
                        const tatTime = tat.internal_tat.split(" ")[1]; // Extract time part from internal_tat
                        return tatTime >= fromTime && tatTime <= toTime;

                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromDate != null && toDate != null) {
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat).setHours(0, 0, 0, 0)
                        fromDate.setHours(0, 0, 0, 0)
                        toDate.setHours(0, 0, 0, 0)
                        return tatDate >= fromDate.getTime() && tatDate <= toDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromDate != null) {

            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat)
                        tatDate.setHours(0, 0, 0, 0)
                        fromDate.setHours(0, 0, 0, 0)
                        return tatDate.getTime() == fromDate.getTime();
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (toDate != null) {
            toDate = toDate.setHours(0, 0, 0, 0)
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat).setHours(0, 0, 0, 0);
                        return tatDate <= toDate;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (fromTime != null) {
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat)
                        const tatTime = tatDate.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

                        return tatTime == fromTime;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }
        else if (toTime != null) {
            filteredData = filteredData.map(client => {
                const filteredFacilities = client.facility.map(facility => {
                    const filteredTat = facility.tat.filter(tat => {
                        const tatDate = new Date(tat.internal_tat)
                        const tatTime = tatDate.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute: '2-digit' });

                        return tatTime <= toTime;
                    });
                    return { ...facility, tat: filteredTat };
                }).filter(facility => facility.tat.length > 0); // Remove facilities with empty tat arrays

                return { ...client, facility: filteredFacilities };
            }).filter(client => client.facility.length > 0); // Remove clients with empty facility arrays

        }



        if (filteredData.length > 0) {
            setData(filteredData);
            setIsNoData(false)
        } else {

            setIsNoData(true)

            const emptyDict = [{
                id: "",
                name: "",
                fecilty: []
            }]
            setData(emptyDict);

        }

        return filteredData;
    }
    const validateTime = (value) => {
        // Modify the value based on your requirements.
        let formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
        formattedValue = formattedValue.slice(0, 4); // Ensure it's not longer than 4 characters (HHMM format)
        formattedValue = formattedValue.replace(/(\d{2})(\d{2})/, "$1:$2"); // Add the colon

        return formattedValue
    }

    const validateDate = (value, cursorPosition) => {
        // Remove non-numeric characters
        let numericValue = value.replace(/\D/g, "");

        // Ensure it's not longer than 8 characters (MM/DD/YYYY format)
        numericValue = numericValue.slice(0, 8);

        // Add the slashes when the second digit is entered for month and day
        if (numericValue.length >= 2 && numericValue.charAt(2) !== '/') {
            numericValue = numericValue.slice(0, 2) + "/" + numericValue.slice(2);
        }
        if (numericValue.length >= 5 && numericValue.charAt(5) !== '/') {
            numericValue = numericValue.slice(0, 5) + "/" + numericValue.slice(5);
        }

        // Adjust formatting if cursor is before a slash
        if (cursorPosition <= 2 && numericValue.charAt(2) === '/') {
            numericValue = numericValue.slice(0, 2) + numericValue.slice(3);
        }
        if (cursorPosition <= 5 && numericValue.charAt(5) === '/') {
            numericValue = numericValue.slice(0, 5) + numericValue.slice(6);
        }

        return numericValue;
    }




    const handleCustomTimeChange = (e, name) => {
        const { value, selectionStart } = e.target;
        // Ensure that the cursor position is maintained.
        const caretPosition = selectionStart;

        // Modify the value based on your requirements.
        let formattedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
        formattedValue = formattedValue.slice(0, 4); // Ensure it's not longer than 4 characters (HHMM format)
        formattedValue = formattedValue.replace(/(\d{2})(\d{2})/, "$1:$2"); // Add the colon

        setCustomeDateTime((prevState) => {
            return {
                ...prevState,
                [name]: formattedValue,
            };
        });
        if (name === 'fromTime') {
            if (formattedValue) {
                filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, formattedValue, customeDateTime.toTime)

            } else {
                filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, null, customeDateTime.toTime)

            }
        } else if (name === 'toTime') {
            if (formattedValue) {
                filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.toDate, formattedValue)

            }
            else {
                filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.toDate, null)

            }
        }

        // Restore cursor position
        const input = e.target;
        input.selectionStart = caretPosition;
        input.selectionEnd = caretPosition;
    };

    const handleCustomDateTimeChange = (e, name) => {
        const value = e.target.value

        if (value != '__/__/____' && value != '' && value != '__:__') {
            if (name === 'fromDate') {
                if (!isNaN(new Date(value))) {


                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            // [name]: new Date(value),
                            [name]: value,
                            initialfromDate: value,
                            fromDateInValid: false

                        };
                    });

                    // filterClients(selectIds, selectedFacilityIds, new Date(value), customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)
                } else {

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            fromDateInValid: true

                        };
                    });
                }

            } else if (name === 'fromTime' && value != '__:__' && value != '') {
                const numberValue = value.replace(/:/g, '')
                if (numberValue.length == 4 && !isNaN(numberValue)) {

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            [name]: validateTime(value),

                        };
                    });
                    // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, value, customeDateTime.toTime)

                }
                else if (numberValue.length <= 4 && !isNaN(numberValue)) {
                    // const replacedString = value && value?.replace(/\D/g, '')

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            [name]: value,

                        };
                    });

                }


            }
            else if (name === 'toDate') {
                if (!isNaN(new Date(value))) {

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            // [name]: new Date(value),
                            [name]: value,
                            initialtoDate: value,
                            toDateInValid: false
                        };
                    });
                    // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, new Date(value), customeDateTime.fromTime, customeDateTime.toTime)

                } else {

                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            toDateInValid: true

                        };
                    });
                }
            }
            else if (name === 'toTime' && value != '__:__' && value != '') {
                const numberValue = value.replace(/:/g, '')
                if (numberValue.length == 4 && !isNaN(numberValue)) {


                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            [name]: validateTime(value),

                        };
                    });
                    // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, value)
                } else if (numberValue.length <= 4 && !isNaN(numberValue)) {
                    setCustomeDateTime((prevState) => {
                        return {
                            ...prevState,
                            [name]: value,

                        };
                    });
                }

            }
        }
        else {

            if (name === 'fromDate') {


                setCustomeDateTime((prevState) => {
                    return {
                        ...prevState,
                        [name]: null,
                        initialfromDate: ""

                    };
                });
                // filterClients(selectIds, selectedFacilityIds, null, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)

            }
            else if (name === 'fromTime') {
                setCustomeDateTime((prevState) => {
                    return {
                        ...prevState,
                        [name]: '',

                    };
                });
                // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, null, customeDateTime.toTime)
            }
            else if (name === 'toDate') {


                setCustomeDateTime((prevState) => {
                    return {
                        ...prevState,
                        [name]: null,
                        initialtoDate: ""

                    };
                });
                // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, null, customeDateTime.fromTime, customeDateTime.toTime)

            }
            else if (name === 'toTime') {
                setCustomeDateTime((prevState) => {
                    return {
                        ...prevState,
                        [name]: '',

                    };
                });
                // filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, null)

            }

        }

    }
    const handleDateInputChange = (e, name) => {
        const value = e.target.value
        const cursorPosition = e.target.selectionStart; // Get the cursor position

        const splitedValue = value.split(" ")
        if (name === 'fromDate') {
            let fromTime = customeDateTime.fromTime
            if (splitedValue[1] != undefined && splitedValue[1] != '' && splitedValue[1].length <= 4) {
                fromTime = splitedValue[1]
            } else if (splitedValue[1] != undefined && splitedValue[1].length > 4) {
                fromTime = splitedValue[1].split(':').slice(0, 2).join(':'); // Extract only the first two parts (HH:MM)

            }

            setCustomeDateTime((prevState) => {
                return {
                    ...prevState,
                    [name]: validateDate(value, cursorPosition),
                    fromTime,

                };
            });
        }
        else if (name === 'toDate') {
            let toTime = customeDateTime.toTime
            if (splitedValue[1] != undefined && splitedValue[1] != '') {
                toTime = splitedValue[1]
            }
            setCustomeDateTime((prevState) => {
                return {
                    ...prevState,
                    [name]: validateDate(value, cursorPosition),
                    toTime,


                };
            });
        }
    }



    useEffect(() => {
        filterClients(selectIds, selectedFacilityIds, customeDateTime.fromDate, customeDateTime.toDate, customeDateTime.fromTime, customeDateTime.toTime)

    }, [customeDateTime])
    return (
        <DataTable
            progressPending={loading}
            columns={columns}
            data={data}
            customStyles={queueDatatableCustomStyles}

            expandableRows
            expandOnRowClicked={false}
            onRowExpandToggled={handleRowExpand}
            expandableRowsComponent={ExpandedComponent}
            expandableRowsHideExpander
            expandableRowExpanded={(row) => expandedRows.find((i) => i?.id === row?.id) ? true : false}
        // theme="solarized"

        />
    )
}

export default DashboardDataTable


