import React, { useEffect, useState } from 'react';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { createAssignUsers, getAllStaffs } from '../../../../../Redux/clientGroup/action';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { changeAllTitleFunction, getRedirectTabId } from '../../../Dashboard/functions';
import { CLIENT_GROUP } from '../../../../../routeNames';

function CreateassignUsersForm(props) {
    const navigate = useNavigate()
    const { client_data } = props;
    const { id } = useParams();
    const [users, setUsers] = useState([])
    const [data, setData] = useState([]);
    const [toaster, setToaster] = useState(false)
    const [state, setState] = useState({
        error: false,
        error_message: "",
        // selected_value:[1,2]
    })
    const [options, setOptions] = useState([]);
    const validationSchema = Yup.object().shape({
        // users: Yup.string()
        //     .required(),
    });
    const formik = useFormik({
        initialValues: {
            users: [],

        },
        validationSchema,
        onSubmit: (values) => {
            // console.log(values);
            __submitForm(values)
        },
    });
    useEffect(() => {
        getAllStaffs()((response) => {
            if (response?.status && response?.data?.results) {
                response.data.results.forEach((item) => {
                    options.push({ value: item.value, label: item.label })
                })
            }
        });


    }, []);
    const __submitForm = (values) => {
        const RedirectId = getRedirectTabId()
        let formData = new FormData();

        // formData.append('staff', [users])
        // formData.append('client_group', id)
        // if (values?.rejection)
        // formData.append('rejection', values.rejection)

        createAssignUsers(users, id, client_data?.data?.id)((response) => {
            if (response?.status) {
                // setToaster('success')
                // const timer = setTimeout(() => {
                //     setToaster(false)
                // }, 2000);
                // return () => clearTimeout(timer);
                // navigate('/view/client-groups')
                changeAllTitleFunction('Client Groups', CLIENT_GROUP, RedirectId)
            }
            else {
                setToaster('error')
                const timer = setTimeout(() => {
                    setToaster(false)
                }, 2000);
                return () => clearTimeout(timer);

            }
        });
    }
    const handleOnSelectChange = ({ name, value }) => {
        // let selected_values = []
        // value.forEach((item) => {
        //     selected_values.push({ value: item.value, label: item.label })
        // })
        // // console.log(selected_values);
        // if (value) {
        // // console.log(value)
        formik.setValues({ ...formik.values, [name]: value })
        setUsers(value.map((val) => val.value))
        // }
    }

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {formik.values?.users?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}

            </components.DropdownIndicator>
        );
    };
    useEffect(() => {
        let selected_values = []


        if (client_data && client_data?.data && client_data?.data?.staff_in_group && client_data?.data?.staff_in_group[0]) {
            client_data?.data?.staff_in_group?.forEach((item) => {
                selected_values.push({ value: item.id, label: item.username })
            })

        }
        formik.setValues({ ...formik.values, users: selected_values })
        setUsers(selected_values.map((val) => val.value))
        // {id: 1, username: 'xtract'}
    }, [client_data])
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    return (
        <React.Fragment>


            <Form
                onSubmit={formik.handleSubmit}
            >
                <Form.Label className='fw-bold clr-heading' >Assign Users</Form.Label>
                <div className="mb-3 row" >
                    <div className='col-lg-8'>
                        <div className="mb-10">
                            <label htmlhtmlFor="exampleFormControlInput1" className="form-label">Users</label>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isMulti
                                value={formik.values.users}

                                name="users"
                                options={options}
                                onChange={(e) => { handleOnSelectChange({ name: 'users', value: e }) }}
                                components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client" /> }}
                                placeholder="Select Users..."
                                onFocus={() => setIsFocusedClient(true)}
                                onBlur={() => setIsFocusedClient(false)}
                                styles={{
                                    container: (styles, state) => ({
                                        ...styles,
                                        borderRadius: "9px",
                                    }),
                                    control: (styles, state) => ({
                                        ...styles,
                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                        borderStyle: "none", boxShadow: "none",
                                        borderRadius: "10px",
                                        maxHeight: state.isFocused ? "auto" : "30px",
                                    }),
                                    indicatorSeparator: styles => ({
                                        ...styles, width: "0px", minHeight: "20px"
                                    }),

                                    valueContainer: (styles, state) => ({
                                        ...styles,
                                        maxHeight: isFocusedClient ? "auto" : "30px",
                                    }),
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-row justify-content-end'>
                    <div className=''>
                        <button
                            type='button'
                            className='btn btn-secondary btn-active-primary me-2'
                            onClick={() => { changeAllTitleFunction('Client Groups', CLIENT_GROUP) }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className=''>
                        <button
                            type='submit'
                            className='btn btn-light btn-active-primary new-window'
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </Form>
        </React.Fragment >
    )
}

export default CreateassignUsersForm