import { GET_TOTAL_QUEUE_URL, GENERATE_V2_OUTPUT_SCHEMA_URL, GET_ALL_SFTP_SPLITTED_FILES_URL, GET_REPORTS_LOCKBOX_LOG_DETAILS, POST_LOCKBOX_COMMENT_URL, QUEUE_V2_HOLD_USER, QUEUE_V2_RELEASE_STATUS_CHANGE, EDI_VALIDATE_835_LIST_URL, GET_V2_RESEND_OUTPUT_FILE_URL } from "../../api";
import { CLIENT_JOB_HISTORY_URL, EXPORT_JOB_MONITOR_URL, REJECT_JOB_API, GENERATE_OPERATION_LOG, GENERATE_OUTPUT_FILE_SCHEMA, GENERATE_OUTPUT_SCHEMA_URL, GENERATE_OUTPUT_XML_SCHEMA_URL, GET_ALL_SFTP_FILES_URL, GET_CHANGE_JOB_TAT_URL, GET_QUEUE_LISTING_URL, GET_USERLIST_DROPDOWN_URL, GET_USER_MANUAL_ASSIGN_URL, GET_V2_QUEUE_LISTING_URL, QUEUE_HOLD_USER, QUEUE_RELEASE_STATUS_CHANGE, RESENT_OUTPUT_SCHEMA_URL, TOGGLE_LOCKBOX_URL } from "../../api";
import axiosInstance from "../../axiosInstance";


export const getChangeJobTat = (new_tat, job) => onResponse => {
    try {
        axiosInstance.post(GET_CHANGE_JOB_TAT_URL, { new_tat, job })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const ediValidate835List = (outputid) => onResponse => {
    try {
        axiosInstance.get(EDI_VALIDATE_835_LIST_URL + "?outputid=" + outputid)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const fetchQueueListing = (page) => onResponse => {
    try {
        axiosInstance.get(GET_QUEUE_LISTING_URL + "?page=" + page)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const generateOperationLog = (lock_box) => onResponse => {
    try {
        axiosInstance.post(GENERATE_OPERATION_LOG, { lock_box })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const generateOutputZip = (lock_box) => onResponse => {
    try {
        axiosInstance.post(GENERATE_OUTPUT_FILE_SCHEMA, { lock_box })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};




export const getUserlist = (page) => onResponse => {
    try {

        axiosInstance.get(GET_USERLIST_DROPDOWN_URL + "?page=" + page)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getUserManualAssign = (staff, assignedqueue, qa_or_snipping) => onResponse => {
    try {
        axiosInstance.post(GET_USER_MANUAL_ASSIGN_URL, { staff, assignedqueue, qa_or_snipping })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const filterQueueDetails = (props) => onResponse => {
    try {

        const staffs = props?.staff ? props?.staff : "";
        const clients = props?.client ? props?.client : "";
        const status = props?.status ? props?.status : "";
        const client_groups = props?.client_group ? props?.client_group : "";
        const filename = props?.filename ? props?.filename : "";
        const check_number = props?.check_number ? props?.check_number : "";
        const batch_id = props?.batch_id ? props?.batch_id : "";
        const sort_order = props?.sort_order ? props?.sort_order : "";
        const sort_key = props?.sort_key ? props?.sort_key : "";
        const date_from = props?.start_date ? props?.start_date : "";
        const date_to = props?.end_date ? props?.end_date : "";
        const deposit_date_range = date_from && date_to ? date_from + "-" + date_to : "";
        // const deposit_date_range = "09/01/2022-04/28/2023";
        const tat_status = props?.tat_status ? props?.tat_status : "";
        const station = props?.station ? props?.station : "";
        const process_date = props?.process_date ? props?.process_date : "";

        // console.log(staffs, clients, status, client_groups, filename, check_number, batch_id, date_from, date_to, ':::::>>>>>>>><<<<<<<<<<::::::::::::');

        axiosInstance.get(GET_V2_QUEUE_LISTING_URL + "?page=" + props?.page + "&limit=" + props?.limit + "&staffs=" + staffs + '&clients=' + clients + '&status=' + status + '&client_groups=' + client_groups + '&filename=' + filename + '&check_number=' + check_number + '&batch_id=' + batch_id + '&deopsit_date_range=' + deposit_date_range + "&sort_order=" + sort_order + "&sort_key=" + sort_key + "&tat_status=" + tat_status + "&station=" + station + "&processed_date_range=" + process_date)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const filterTotalQueueDetails = (props) => onResponse => {
    try {

        const staffs = props?.staff ? props?.staff : "";
        const clients = props?.client ? props?.client : "";
        const status = props?.status ? props?.status : "";
        const client_groups = props?.client_group ? props?.client_group : "";
        const filename = props?.filename ? props?.filename : "";
        const check_number = props?.check_number ? props?.check_number : "";
        const batch_id = props?.batch_id ? props?.batch_id : "";
        const sort_order = props?.sort_order ? props?.sort_order : "";
        const sort_key = props?.sort_key ? props?.sort_key : "";
        const date_from = props?.start_date ? props?.start_date : "";
        const date_to = props?.end_date ? props?.end_date : "";
        const deposit_date_range = date_from && date_to ? date_from + "-" + date_to : "";
        // const deposit_date_range = "09/01/2022-04/28/2023";
        const tat_status = props?.tat_status ? props?.tat_status : "";
        const station = props?.station ? props?.station : "";
        const process_date = props?.process_date ? props?.process_date : "";

        // console.log(staffs, clients, status, client_groups, filename, check_number, batch_id, date_from, date_to, ':::::>>>>>>>><<<<<<<<<<::::::::::::');

        axiosInstance.get(GET_TOTAL_QUEUE_URL + "?page=" + props?.page + "&limit=" + props?.limit + "&staffs=" + staffs + '&clients=' + clients + '&status=' + status + '&client_groups=' + client_groups + '&filename=' + filename + '&check_number=' + check_number + '&batch_id=' + batch_id + '&deopsit_date_range=' + deposit_date_range + "&sort_order=" + sort_order + "&sort_key=" + sort_key + "&tat_status=" + tat_status + "&station=" + station + "&processed_date_range=" + process_date)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};


export const changeQueueReleaseStatus = (id, reason) => onResponse => {
    try {
        axiosInstance.post(QUEUE_V2_RELEASE_STATUS_CHANGE, { job: id, comment: reason })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const queueHoldUser = (job_id, reason) => onResponse => {
    try {
        axiosInstance.post(QUEUE_V2_HOLD_USER, { job_id: job_id, reason: reason })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const generateOutputFileSchema835 = (id) => onResponse => {
    try {
        axiosInstance.post(GENERATE_V2_OUTPUT_SCHEMA_URL, { queues: id })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {
        console.log(error);
    }

};
export const clientJobHistory = (id) => onResponse => {
    try {
        axiosInstance.get(CLIENT_JOB_HISTORY_URL + "?job_id=" + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {
        console.log(error);
    }

};
export const generateOutputFileSchema = (id) => onResponse => {
    try {
        axiosInstance.post(GENERATE_V2_OUTPUT_SCHEMA_URL, { queues: id })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {
        console.log(error);
    }

};

export const generateOutputXmlFileSchema = (id) => onResponse => {
    try {
        axiosInstance.post(GENERATE_OUTPUT_XML_SCHEMA_URL, { job: id })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {
        console.log(error);
    }

};

export const resentOutputFileSchema = (id) => onResponse => {
    try {
        axiosInstance.post(RESENT_OUTPUT_SCHEMA_URL, { queue: id })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {
        console.log(error);
    }

};

export const fetchAllLockboxFiles = (props) => onResponse => {
    try {
        console.log(props);
        const search = props?.search ? props?.search : "";
        // const filename = filename ? filename : "";
        const client = props?.client ? props?.client : "";
        const deposit_date_range = props?.deposit_date_range ? props?.deposit_date_range : "";
        const status = props?.status ? props?.status : "";

        axiosInstance.get(GET_ALL_SFTP_FILES_URL + "?page=" + props?.page + "&limit=" + props?.page_limit
            + "&search=" + search + "&client=" + client + "&deposit_date_range=" + deposit_date_range + "&status=" + status
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const fetchAllLockboxFilesData = (props) => onResponse => {
    try {
        const name = props?.name ? props?.name : "";
        const lock_box_file_name = props?.lock_box_file_name ? props?.lock_box_file_name : "";
        const client = props?.clients ? props?.clients : [];
        const facility = props?.facility ? props?.facility : [];
        const split_user = props?.split_user ? props?.split_user : [];

        // const status = props?.status ? props?.status : "";

        axiosInstance.get(GET_ALL_SFTP_SPLITTED_FILES_URL + "?page=" + props?.page + "&limit=" + props?.page_limit + '&search=' + name + '&lock_box_file_name=' + lock_box_file_name + '&client=' + client + '&facility=' + facility + '&split_user=' + split_user)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const resendEobOutputFIleSchema = (lockbox) => onResponse => {
    try {


        axiosInstance.post(GET_V2_RESEND_OUTPUT_FILE_URL, { lockbox })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};


export const toggleLockbox = (lock_box, reason) => onResponse => {
    try {
        // console.log(lock_box, reason);

        axiosInstance.post(TOGGLE_LOCKBOX_URL, { lock_box: lock_box, reason: reason })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};


export const lockboxComment = (lock_box, reason) => onResponse => {
    try {
        // console.log(lock_box, reason);

        axiosInstance.post(POST_LOCKBOX_COMMENT_URL, { lock_box: lock_box, reason: reason })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getLockboxLogDetailsReport = (lockbox) => onResponse => {

    try {

        axiosInstance.get(GET_REPORTS_LOCKBOX_LOG_DETAILS + "?lockbox=" + lockbox)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const rejectJobApi = (ids) => onResponse => {

    try {

        axiosInstance.post(REJECT_JOB_API, { jobs: ids })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const exportJobMonitor = (props) => onResponse => {
    try {
        console.log(props)
        const staffs = props?.staff ? props?.staff : "";
        const clients = props?.client ? props?.client : "";
        const status = props?.status ? props?.status : "";
        const client_groups = props?.client_group ? props?.client_group : "";
        const filename = props?.filename ? props?.filename : "";
        const check_number = props?.check_number ? props?.check_number : "";
        const batch_id = props?.batch_id ? props?.batch_id : "";
        const sort_order = props?.sort_order ? props?.sort_order : "";
        const sort_key = props?.sort_key ? props?.sort_key : "";
        const date_from = props?.start_date ? props?.start_date : "";
        const date_to = props?.end_date ? props?.end_date : "";
        const deposit_date_range = date_from && date_to ? date_from + "-" + date_to : "";
        // const deposit_date_range = "09/01/2022-04/28/2023";
        const tat_status = props?.tat_status ? props?.tat_status : "";
        const header_names = props?.header_names ? props?.header_names : "";
        const station = props?.station ? props?.station : "";
        const list_type = props?.list_type ? props?.list_type : "";

        console.log(header_names, ':::::>>>>>>>><<<<<<<<<<::::::::::::');
        const process_date = props?.process_date ? props?.process_date : "";

        axiosInstance.get(EXPORT_JOB_MONITOR_URL + "?page=" + props?.page + "&limit=" + props?.limit + "&staffs=" + staffs + '&clients=' + clients + '&status=' + status + '&client_groups=' + client_groups + '&filename=' + filename + '&check_number=' + check_number + '&batch_id=' + batch_id + '&deopsit_date_range=' + deposit_date_range + "&sort_order=" + sort_order + "&sort_key=" + sort_key + "&tat_status=" + tat_status + "&header_names=" + header_names + "&station=" + station + "&list_type=" + list_type + "&processed_date_range=" + process_date)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};