import React, { useEffect, useState } from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { Tab, Tabs, Toast, ToastContainer, Form, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { useParams } from 'react-router-dom';
import { getClientGroupDetails, getSingleAssignGroupUser } from '../../../../Redux/clientGroup/action';
import CreateassignUsersForm from '../CreateClient/Forms/assignusers';

function CreateAssignUser() {
    const [state, setState] = useState({
        status: false,
        message: "",
        showToast: false,
        clientValidated: false,
        client_id: 0,
        // client_data: [],
    });
    const [data, setData] = useState();
    const [clientdata, setClientdata] = useState();
    const { id } = useParams();
    useEffect(() => {
        getSingleAssignGroupUser(id)((response) => {
            setClientdata(response)
        });

    }, []);
    useEffect(() => {
        if (id) {
            getClientGroupDetails(id)((response) => {
                if (response?.status && response?.data) {
                    // // console.log(response.data)
                    setData(response?.data);
                    setState({ ...state, clientValidated: true, client_id: response?.data?.id })
                    // // console.log(response?.data?.id)

                }
                else {
                    setToast({
                        type: "danger",
                        message: response?.response?.data?.message,
                        toast: true,
                    })
                }
            });
        }

    }, []);

    const __handleStateClientValidated = (response) => {
        if (response?.response?.data?.errors) {
            setState({ client_id: 0, clientValidated: false, })
        }
        else {

            setState({ ...state, clientValidated: true, client_id: response?.data?.id, client_data: response?.data })
            localStorage.setItem('client_id', response?.data?.id)
        }
    }

    const [toast, setToast] = useState({
        type: "",
        message: "",
        toast: false,

    });
    const __toggleToast = (type, message, toast) => {

        setToast({
            type: type,
            message: message,
            toast: toast,
        })

    }
    const __closeToast = () => {
        setToast({ toast: false })
    }

    const [loading, setLoading] = useState(false);

    return (
        <React.Fragment>
            <div>
                <ToastContainer containerPosition="fixed" className="p-3" position={"top-end"}>
                    <Toast bg={toast.type == "success" ? "success" : "danger"} onClose={__closeToast} show={toast.toast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{toast?.message}</span></Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
            <AdminMaster />
            <main id="main" className="main">
                <section className="section mt-5">
                    {loading &&
                        <React.Fragment>
                            <div className='loading-component position-absolute top-50 start-50 translate-middle'>
                                <Spinner animation="border" size="lg" />
                            </div>
                        </React.Fragment>
                    }
                    {/* // ) : ( */}
                    <React.Fragment>
                        <div className="row align-items-top py-3 gap-2 gap-md-5">
                            <div className="col-lg-6">
                                <div className="card mb-5 mb-xl-8">

                                    <div className='card-body py-3'>
                                        <div className=''>

                                            <CreateassignUsersForm

                                                client={state.client_id}
                                                client_data={clientdata}
                                                clientValidated={state.clientValidated}
                                                setLoading={setLoading}
                                                changeState={__handleStateClientValidated}
                                                __toggleToast={__toggleToast}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    {/* // )} */}

                </section>


            </main>
        </React.Fragment >
    )
}

export default CreateAssignUser