import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import AdminMaster from '../../../Layout/admin/Master/index';
import { OverlayTrigger, Toast, ToastContainer, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from '../../../Layout/Elements/DatatableCustomStyles';
import axiosInstance, { BACKEND_URL } from '../../../axiosInstance';
import { GET_ALL_CLIENT_GROUP_URL, GET_NOTIFICATION_LIST_URL } from '../../../api';
import { BsEye } from 'react-icons/bs';
import { CREATE_CLIENT_GROUP, NOTIFICATION_EDIT } from '../../../routeNames';
import { deleteClientGroup, getAllClientGroups } from '../../../Redux/clientGroup/action';
import DeleteSweetAlert from '../../SweetAlert/DeleteSweetAlert';
import { markAllAsRead } from '../../../Redux/notification/action';
import { changeAllTitleFunction } from '../Dashboard/functions';


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;

const NotificationList = () => {
    const [isSwal, setIsSwal] = useState({
        show: false,
        id: ''
    })
    const [pageno, setPageNo] = useState(1);
    const [toaster, setToaster] = useState(false)
    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
    });

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [data, setData] = useState({})
    const [colorRow, setColorRow] = useState({});

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const renderEditTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            View
        </Tooltip>
    );
    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                grow: 0,

            },
            {
                id: 'title',
                name: 'Title',
                selector: row => row.title,
                sortable: true,
                left: true,
                wrap: true,
                grow: 1.8,


            },
            {
                id: 'message',
                name: 'Message',
                selector: row => row.message,
                sortable: true,
                left: true,
                wrap: true,
                grow: 0.9,


            },
            {
                id: 'Action',
                name: 'Action',
                // selector: row => row.message,
                cell: (row) =>
                
                    <div className=" ">

                        <OverlayTrigger
                            placement="top"
                            
                            overlay={renderEditTooltip}
                        >
                            <Link onClick={()=>changeAllTitleFunction('Notification', row?.path !== "" ? row?.path : NOTIFICATION_EDIT + row.pk)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                <span className="svg-icon svg-icon-3">
                                    {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                    </svg> */}
                                    <BsEye />
                                </span>
                            </Link>
                        </OverlayTrigger>



                    </div>,
                 right: true,
                 button: false,


            },


        ],
        [pageno],
    );
    const fetchNotifications = async (page) => {
        setLoading(true);

        setLimit(limit);

        const response = await axiosInstance.get(GET_NOTIFICATION_LIST_URL + "?page=" + page + "&limit=" + limit);
        setData(response?.data);
        setLimit(limit);
        setTotalRows(response?.data?.data?.count);
        setLoading(false);
    };
    const handlePageChange = page => {
        setPageNo(page)
        fetchNotifications(page);
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        const response = await axiosInstance.get(GET_NOTIFICATION_LIST_URL + "?page=" + page + "&limit=" + newPerPage);
        setData(response?.data);
        setLimit(newPerPage);
        setTotalRows(response?.data?.data?.count);
        setLoading(false);

    };
    useEffect(() => {
        fetchNotifications(1, limit);
    }, []);


    const __deleteClientGroup = (client) => {
        let formData = new FormData();    //formdata object
        formData.append('client_group_id', client);

        deleteClientGroup(client)((response) => {
            if (response?.status && response?.status_code === 200) {
                getAllClientGroups()((response) => {
                    setData(response);
                    setToaster('success')
                });
            } else {
                setState({ error: true, error_message: response?.message });
                setToaster('error')
            }
        });
    }

    const searchGroups = async (e) => {
        setFilterText(e.target.value)
        let data = e.target.value ? e.target.value : '';
        const response = await axiosInstance.get(GET_NOTIFICATION_LIST_URL + "?limit=" + data?.data?.count + "&search=" + data);
        setData(response?.data);
    }

    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, resetPaginationToggle]);

    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.pk ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.pk ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };
    const [selectedRows, setSelectedRows] = React.useState([]);

    const rowDisabledCriteria = row => row.is_read;
    const handleRowSelected = React.useCallback(state => {
        var ids = [];
        state.selectedRows.map((row, index) => {
            ids.push(row.pk);
        })
        setSelectedRows(ids);
    }, []);
    const handleAllMarkedRead = () => {
        markAllAsRead(selectedRows)((response) => {
            if (response?.status) {
                fetchNotifications(1, limit);

            }
        })
    }
    return (
        <React.Fragment>
            <AdminMaster />
            <main id="main" className="main">

                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Notifications</h1>

                </div>


                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0 ">
                                    <div className="d-flex justify-content-end align-items-end position-relative my-1">
                                        {selectedRows.length > 0 &&
                                            <Button variant='dark' onClick={handleAllMarkedRead}>Mark Selected All As Read</Button>
                                        }
                                        {/* <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} /> */}
                                    </div>

                                </div>
                                <div className="card-body py-3">
                                    <div className="table-responsive">
                                        <div className='dashboardtable'>
                                            <DataTable
                                                className="custom-datatable"
                                                fixedHeader={true}
                                                data={data?.data?.results}
                                                columns={columns}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationPerPage={limit}
                                                paginationRowsPerPageOptions={[25, 50, 100]}
                                                onSelectedRowsChange={handleRowSelected}
                                                selectableRows
                                                selectableRowDisabled={rowDisabledCriteria}
                                                paginationTotalRows={totalRows}
                                                onChangePage={handlePageChange}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                conditionalRowStyles={conditionalRowStyles}
                                                onRowClicked={handleRowClick}
                                                theme="solarized"
                                                customStyles={datatableCustomStyles}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                        </div>
                    </div>
                </section>
                {/* <DeleteSweetAlert handleDelete={handleDelete} isSwal={isSwal} setIsSwal={setIsSwal} /> */}

            </main>
        </React.Fragment>
    )
}

export default NotificationList;