
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Tooltip } from '@mui/material';
import styled from "styled-components/macro";


const PayerDiffrenceReportModal = ({ toggleReportPopup, setToggleReportPopup, reportData, setReportData }) => {
  


    // {reportData?.map((item, index) => (
    //     Object.keys(item?.old_data).map((key, innerIndex) => (
    //         <tr key={innerIndex}>
    //             {innerIndex === 0 && <td>{item?.created_by}</td>}
    //             <td>{key}</td>
    //             <td>{item?.old_data[key]}</td>
    //             <td>{item?.new_data[key]}</td>
    //         </tr>
    //     ))
    // ))}
    return (
        <Modal size="xl" show={toggleReportPopup} onHide={() => { setToggleReportPopup(false), setReportData([]) }} centered className='manual-tat'>
            <Modal.Header closeButton>
                <Modal.Title className="table-report-modelTitle">Report</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">

                <table className="table history-log-table" >

                    <thead>
                        <tr >
                            <th >Modified By</th>
                            <th >Modified Date</th>
                            <th >Data</th>
                        </tr>
                    </thead>
                    {reportData?.map((item, index) => (
                        <tr key={index}>
                            <td>{item?.created_by}</td>
                            <td>
                                {item?.modified_date ?? ""}
                            </td>
                            <td>
                                <DivConatiner >
                                    {item?.payer_data ? JSON.stringify(item?.payer_data) : ""}
                                </DivConatiner>
                            </td>
                        </tr>
                    ))}


                </table >

            </Modal.Body >
        </Modal >
    )


}
export default PayerDiffrenceReportModal;







const DivConatiner = styled.div`   
max-width: 350px;
height: auto;
text-align: left;
display: inline-block;
width: 81%;
margin: 0 auto;
overflow: hidden; 
word-wrap: break-word; 
padding: 10px; 

`