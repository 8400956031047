import React, { useEffect, useState } from 'react'
import newicon from "../../../../../assets/img/new-icon.svg";
import addicon from "../../../../../assets/img/add-icon.svg";
import claimicon from "../../../../../assets/img/claims-icon.svg";
import clear from "../../../../../assets/img/clear-icon.svg";
import { EDIT_WORK, QA_REVIEW } from '../../../../../routeNames';
import { DATA_ENTRY } from './../../../../../routeNames';
import styled from 'styled-components';
import { Form, Modal, Overlay, Popover, InputGroup } from 'react-bootstrap';
import CheckViewModal from './checkViewModal';
import FileInfoModal from './fileInfoModal';
import { TiArrowBack } from 'react-icons/ti';
import { Formik, FormikProvider } from 'formik';
import { assignJobFromQa, getJobStaffStatus } from '../../../../../Redux/grid/action';
import { useRef } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { BsDot } from 'react-icons/bs';
import { handleWheel } from "../gridFunctions";

const TextAreaField = styled(Overlay)`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;

const GridHeader = (props) => {


    const {
        state,
        setState,
        setControlledPosition,
        setIsDrag,
        jobData,
        payeeLookup,
        claim835,
        setClaim835,
        payerLookup,
        payeeLiteLookup,
        payerLiteLookup,
        patientLookup,
        handleAddPlbAdjustment,
        handleLiteAddPlbAdjustment,

        __payment835Handler,
        __payment835LiteHandler,
        __patientPayHandler,
        __correspondenceHandler,

        rejectRef,
        fileUrl,
        totalPages,
        rotatePosition,
        checkNum,

        formPartial,
        partialSave,
        setPartialSave,
        setPartialSavePopUp,
        partialSavePopUp,
        setRejectPopup,
        rejectPopup,
        rejectLabel,
        setRejectLabel,
        handleRejectFormClose,
        handlePartialFormClose,
        // handlePartialTransactionType,
        formEob,
        formPP,
        eobLiteFormik,
        rejectForm,
        formCorres,
        cleareCorresPondanceFocus,
        clearEob835Form,
        cleareEobFocus,
        cleareEobLiteFocus,
        clearePatientPayFocus,

        assignTooltip,
        setAssignTooltip,
        userStatus,
        setUserStatus,
        assignTo,
        setAssignTo,
        assignReason,
        setAssignReason,
        reAssignUser,
        setClaimCorresIndex,
        handleEobLite835Data,
        setClaimLiteIndex,
        claim835Index,
        setClaim835Index,
        setQdxTransaction,
        qdxTransaction,
        setPlbOnlyValue,
        plbOnlyValue,
        isQdx,
        formReassign,
        formReassignMulty,
        clearServiceLines,
        handleRemainingBalanceCalculation,
        isCheckNumber,
        setIsCheckNumber,

        isCorres835,
        isPP835,
        isViewOnllyGrid,
        isSubJob,
        isFirstJob,
        jobUserData,
        subJobOptions,
        selectedSubJob,
        setSelectedSubJob,
        job_id,
        isGeneratedCheckNumber,
        isOverallPage,
        pdfLoaded,
        overallPage,
        setOverallPage,
        currentPage,
        aditionalInfoFormik,
        setIsIncomplete,
        isIncomplete,
        log_id,
        activePdf,
        firstSaveLoad,
        secondSaveLoad,
        additionalModal
    } = props;

    const clearCurrentForm = async () => {
        if (localStorage.getItem('837_pulled')) {
            localStorage.removeItem("837_pulled");
        }
        if (state.Payment835 && localStorage.getItem('clear') === 'true') {
            // console.log('hhhhhhhhhhhheeeeeeeeeeeerrrrrrrrrreeeeeeeeeeee');
            // setTimeout(() => {

            //     clearEob835Form()
            // }, 100);
            // localStorage.removeItem('clear')
            // localStorage.setItem('transaction_type', 'PAYMENT835')
            // window.location.reload();
            // await formEob.handleReset()
            // await formEob.validateForm()
            // debugger

            if (!isSubJob && isFirstJob || isSubJob && isFirstJob || !isSubJob && !isFirstJob) {
                await formEob.setValues({
                    ...formEob.values,
                    rejection_reason: "",

                    // payment_id: "",
                    transaction_type: "PAYMENT835",
                    payment_method: "",
                    check_number: "",
                    check_date: "",
                    check_amount: "",
                    remaining_balance: "",
                    check_page_number: "",
                    correspondance: "",

                    qdx_tranansaction_type: qdxTransaction,
                    qdx_status: "",
                    qdx_reason: "",
                    qdx_classification: "",

                    data_837: [],
                    plb_adjustments: [],
                    payee_id: "",
                    payee_pulled_id: "",
                    payee_name: "",
                    payee_npi: "",
                    payee_address: "",
                    payee_city: "",
                    payee_state: "",
                    payee_zip: "",

                    payee_page_from: "1",
                    payee_page_to: "1",

                    payer_id: "",
                    payer_pulled_id: "",
                    payer_name: "",
                    payer_address: "",
                    payer_city: "",
                    payer_state: "",
                    payer_zip: "",

                    payer_page_from: "1",
                    payer_page_to: "1",

                    // DBK
                    dbk_check_number: "",
                    dbk_check_date: "",
                    dbk_check_amount: "",

                    dbk_payee_name: "",
                    dbk_payee_npi: "",
                    dbk_payee_address: "",
                    dbk_payee_city: "",
                    dbk_payee_state: "",
                    dbk_payee_zip: "",

                    dbk_payer_name: "",
                    dbk_payer_address: "",
                    dbk_payer_city: "",
                    dbk_payer_state: "",
                    dbk_payer_zip: "",

                    // plb_adjustments: [
                    // {
                    //     plb_adjustment_id: "",
                    //     npi: "",
                    //     plb_page: "",
                    //     reason: "",
                    //     patient_account_number: "",
                    //     plb_amt: ""
                    // }
                    // ],
                    claims: [
                        {
                            patient: true,
                            subscriber: true,
                            rendering_provider: true,
                            // corrected_claim: true,

                            order: "",
                            color_index: "",

                            claim_id: "",
                            patient_account_number: "",
                            payer_claim_number: "",
                            period_start: "",
                            period_end: "",
                            status_code: "",
                            mrn: "",
                            statement_number: "",
                            statement_date: "",
                            page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                            page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                            claim_billed: "",
                            claim_discount: parseFloat(0).toFixed(2),
                            claim_allowed: parseFloat(0).toFixed(2),
                            claim_deduct: parseFloat(0).toFixed(2),
                            claim_coins: parseFloat(0).toFixed(2),
                            claim_copay: parseFloat(0).toFixed(2),
                            claim_cob: parseFloat(0).toFixed(2),
                            claim_patres: parseFloat(0).toFixed(2),
                            claim_paid: "",
                            claim_balance: parseFloat(0).toFixed(2),

                            sl_billed: parseFloat(0).toFixed(2),
                            sl_discount: parseFloat(0).toFixed(2),
                            sl_allowed: parseFloat(0).toFixed(2),
                            sl_deduct: parseFloat(0).toFixed(2),
                            sl_coins: parseFloat(0).toFixed(2),
                            sl_copay: parseFloat(0).toFixed(2),
                            sl_cob: parseFloat(0).toFixed(2),
                            sl_patres: parseFloat(0).toFixed(2),
                            sl_amount: parseFloat(0).toFixed(2),
                            sl_paid: parseFloat(0).toFixed(2),
                            sl_balance: parseFloat(0).toFixed(2),
                            sl_total: parseFloat(0).toFixed(2),

                            color_data: [],

                            patient_id: "",
                            patient_first_name: "",
                            patient_middle_name: "",
                            patient_last_name: "",
                            patient_identifier_code: "",

                            subscriber_id: "",
                            subscriber_first_name: "",
                            subscriber_middle_name: "",
                            subscriber_last_name: "",
                            subscriber_identifier_code: "",

                            rendering_provider_id: "",
                            rendering_provider_first_name: "",
                            rendering_provider_last_name: "",
                            rendering_provider_identifier_code: "",

                            // DBK
                            dbk_patient_account_number: "",
                            dbk_payer_claim_number: "",

                            dbk_patient_first_name: "",
                            dbk_patient_middle_name: "",
                            dbk_patient_last_name: "",
                            dbk_patient_identifier_code: "",

                            dbk_subscriber_first_name: "",
                            dbk_subscriber_middle_name: "",
                            dbk_subscriber_last_name: "",
                            dbk_subscriber_identifier_code: "",

                            dbk_rendering_provider_first_name: "",
                            dbk_rendering_provider_last_name: "",
                            dbk_rendering_provider_identifier_code: "",


                            dbk_mrn: "",
                            dbk_statement_number: "",
                            dbk_statement_date: "",

                            claim_adjustments: [
                                {
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                    claim_adjustment_id: "",
                                },
                            ],

                            service_lines: [
                                {
                                    color_index: "",
                                    order: "",
                                    service_line_id: "",
                                    from_date: "",
                                    to_date: "",
                                    code: "",
                                    procedure_type: "HC",
                                    modifier1: "",
                                    modifier2: "",
                                    modifier3: "",
                                    modifier4: "",
                                    // corrected_serviceline: true,
                                    rev_code: "",
                                    units: "",
                                    patres: "",
                                    billed: "",
                                    discount: "",
                                    allowed: "",
                                    deduct: "",
                                    coins: "",
                                    copay: "",
                                    cob: "",
                                    paid: "",
                                    color_data: [],

                                    balance: "",
                                    adjustments: [
                                        {
                                            adjustment_id: "",
                                            pc: "",
                                            group_code: "",
                                            reason_code: "",
                                            rmk: "",
                                            amount: "",
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                });

                setIsCheckNumber(true)

                setIsIncomplete(false)

                await aditionalInfoFormik.setValues({
                    status: "",
                    reason: "",
                    document_classification: "",
                    is_orphaned_check: false,
                    is_missing_check_image: false,
                    is_forced_balancing: false,
                    is_payer_black_listing: false,
                    is_payer_excluding_indexing: false,
                })
                setTimeout(() => {
                    formEob.validateForm();
                }, 300);
            }

            // else if (isSubJob && !isFirstJob) {

            // }
            else {
                await formEob.setValues({
                    ...formEob.values,
                    payee_page_from: "1",
                    payee_page_to: "1",

                    payer_page_from: "1",
                    payer_page_to: "1",

                    rejection_reason: "",

                    transaction_type: "PAYMENT835",

                    qdx_tranansaction_type: qdxTransaction,
                    qdx_status: "",
                    qdx_reason: "",
                    qdx_classification: "",

                    // data_837: [],
                    plb_adjustments: [],


                    claims: [
                        {
                            patient: true,
                            subscriber: true,
                            rendering_provider: true,
                            corrected_claim: true,

                            order: "",
                            color_index: "",

                            claim_id: "",
                            patient_account_number: "",
                            payer_claim_number: "",
                            period_start: "",
                            period_end: "",
                            status_code: "",
                            mrn: "",
                            statement_number: "",
                            statement_date: "",
                            page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                            page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                            claim_billed: "",
                            claim_discount: parseFloat(0).toFixed(2),
                            claim_allowed: parseFloat(0).toFixed(2),
                            claim_deduct: parseFloat(0).toFixed(2),
                            claim_coins: parseFloat(0).toFixed(2),
                            claim_copay: parseFloat(0).toFixed(2),
                            claim_cob: parseFloat(0).toFixed(2),
                            claim_patres: parseFloat(0).toFixed(2),
                            claim_paid: "",
                            claim_balance: parseFloat(0).toFixed(2),

                            sl_billed: parseFloat(0).toFixed(2),
                            sl_discount: parseFloat(0).toFixed(2),
                            sl_allowed: parseFloat(0).toFixed(2),
                            sl_deduct: parseFloat(0).toFixed(2),
                            sl_coins: parseFloat(0).toFixed(2),
                            sl_copay: parseFloat(0).toFixed(2),
                            sl_cob: parseFloat(0).toFixed(2),
                            sl_patres: parseFloat(0).toFixed(2),
                            sl_amount: parseFloat(0).toFixed(2),
                            sl_paid: parseFloat(0).toFixed(2),
                            sl_balance: parseFloat(0).toFixed(2),
                            sl_total: parseFloat(0).toFixed(2),

                            color_data: [],

                            patient_id: "",
                            patient_first_name: "",
                            patient_middle_name: "",
                            patient_last_name: "",
                            patient_identifier_code: "",

                            subscriber_id: "",
                            subscriber_first_name: "",
                            subscriber_middle_name: "",
                            subscriber_last_name: "",
                            subscriber_identifier_code: "",

                            rendering_provider_id: "",
                            rendering_provider_first_name: "",
                            rendering_provider_last_name: "",
                            rendering_provider_identifier_code: "",

                            // DBK
                            dbk_patient_account_number: "",
                            dbk_payer_claim_number: "",

                            dbk_patient_first_name: "",
                            dbk_patient_middle_name: "",
                            dbk_patient_last_name: "",
                            dbk_patient_identifier_code: "",

                            dbk_subscriber_first_name: "",
                            dbk_subscriber_middle_name: "",
                            dbk_subscriber_last_name: "",
                            dbk_subscriber_identifier_code: "",

                            dbk_rendering_provider_first_name: "",
                            dbk_rendering_provider_last_name: "",
                            dbk_rendering_provider_identifier_code: "",


                            dbk_mrn: "",
                            dbk_statement_number: "",
                            dbk_statement_date: "",

                            claim_adjustments: [
                                {
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                    claim_adjustment_id: "",
                                },
                            ],

                            service_lines: [
                                {
                                    color_index: "",
                                    order: "",
                                    service_line_id: "",
                                    corrected_serviceline: true,
                                    from_date: "",
                                    to_date: "",
                                    code: "",
                                    procedure_type: "HC",
                                    modifier1: "",
                                    modifier2: "",
                                    modifier3: "",
                                    modifier4: "",
                                    rev_code: "",
                                    units: "",
                                    patres: "",
                                    billed: "",
                                    discount: "",
                                    allowed: "",
                                    deduct: "",
                                    coins: "",
                                    copay: "",
                                    cob: "",
                                    paid: "",
                                    color_data: [],

                                    balance: "",
                                    adjustments: [
                                        {
                                            adjustment_id: "",
                                            pc: "",
                                            group_code: "",
                                            reason_code: "",
                                            rmk: "",
                                            amount: "",
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                });
                setIsIncomplete(false)
                await aditionalInfoFormik.setValues({
                    status: "",
                    reason: "",
                    document_classification: "",
                    is_orphaned_check: false,
                    is_missing_check_image: false,
                    is_forced_balancing: false,
                    is_payer_black_listing: false,
                    is_payer_excluding_indexing: false,
                })
                setTimeout(() => {
                    formEob.validateForm();
                }, 200);
            }

            setClaim835Index(0)
            //     var claims = await formEob.values.claims;
            //     claims[0].claim_paid = 0.00;
            //    await formEob.setValues({
            //         ...formEob.values,
            //         claims: claims,

            //     });
            // debugger
        }
        if (state.PatientPay && localStorage.getItem('clear') === 'true') {

            formPP.setValues({
                ...formPP.values,

                patient_page_from: "1",
                patient_page_to: "1",

                check_number: "",
                check_date: "",
                check_date: "",
                check_amount: "",
                patient_account_number: "",
                patient_id: "",
                patient_first_name: "",
                patient_middle_name: "",
                patient_last_name: "",
                amount_due: "",
                // statement_due: "",
                statement_number: "",
                statement_amount: "",
                statement_date: "",

                qdx_status: "",
                qdx_reason: "",
                qdx_classification: "",

                // dbk fields
                dbk_check_number: "",
                dbk_check_date: "",
                dbk_check_amount: "",
                dbk_patient_account_number: "",
                dbk_patient_first_name: "",
                dbk_patient_middle_name: "",
                dbk_patient_last_name: "",
                dbk_amount_due: "",
                dbk_statement_number: "",
                dbk_statement_amount: "",
                dbk_statement_date: "",
            })
            setIsIncomplete(false)
            await aditionalInfoFormik.setValues({
                status: "",
                reason: "",
                document_classification: "",
                is_orphaned_check: false,
                is_missing_check_image: false,
                is_forced_balancing: false,
                is_payer_black_listing: false,
                is_payer_excluding_indexing: false,
            })
            setTimeout(() => {
                formPP.validateForm();
            }, 200);

            // await formPP.handleReset()
            // await formPP.validateForm()
            // clearePatientPayFocus()
            //     localStorage.removeItem('clear')
            //     localStorage.setItem('transaction_type', 'PATIENT_PAY')
            //     window.location.reload();
            // formPP.setValues({})
        }
        if (state.Payment835Lite && localStorage.getItem('clear') === 'true') {
            // await eobLiteFormik.setValues({})
            await eobLiteFormik.setValues({
                ...eobLiteFormik.values,
                transaction_type: "PAYMENT835_LITE",
                payment_method: "",
                remaining_balance: "",
                check_number: "",
                check_date: "",
                check_amount: "",
                payer_id: "",
                payer_name: "",
                payee_id: "",
                payee_name: "",

                payee_page_from: "1",
                payee_page_to: "1",
                payer_page_from: "1",
                payer_page_to: "1",

                qdx_status: "",
                qdx_reason: "",
                qdx_classification: "",

                // dbk fields
                dbk_check_number: "",
                dbk_check_date: "",
                dbk_check_amount: "",
                dbk_payer_name: "",
                dbk_payee_name: "",

                plb_adjustments: [
                    // {
                    //     plb_adjustment_id: "",
                    //     npi: "",
                    //     plb_page: "",
                    //     reason: "",
                    //     patient_account_number: "",
                    //     plb_amt: ""
                    // }
                ],


                claims: [
                    {
                        claim_id: "",
                        order: "",
                        corrected_claim: true,
                        patient_id: "",
                        patient_account_number: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",

                        page_from: "1",
                        page_to: "1",
                        service_lines: {
                            service_line_id: "",
                            period_start: "",
                            period_end: "",
                            billed: "",
                            paid: "",
                        },

                        dbk_patient_account_number: "",
                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                    },
                ],
            })

            setClaimLiteIndex(0)
            setIsIncomplete(false)
            await aditionalInfoFormik.setValues({
                status: "",
                reason: "",
                document_classification: "",
                is_orphaned_check: false,
                is_missing_check_image: false,
                is_forced_balancing: false,
                is_payer_black_listing: false,
                is_payer_excluding_indexing: false,
            })
            setTimeout(() => {
                eobLiteFormik.validateForm();
            }, 200);
        }
        if (state.Correspondence && localStorage.getItem('clear') === 'true') {
            // await formCorres.handleReset()
            // await formCorres.validateForm()
            // cleareCorresPondanceFocus()
            if (!isSubJob && isFirstJob || isSubJob && isFirstJob || !isSubJob && !isFirstJob) {

                await formCorres.setValues({
                    ...formCorres.values,
                    payer_name: "",
                    letter_date: "",
                    correspondance: "",

                    dbk_payer_name: "",
                    dbk_letter_date: "",

                    qdx_status: "",
                    qdx_reason: "",
                    qdx_classification: "",

                    payer_page_from: "1",
                    payer_page_to: "1",

                    claims: [
                        {
                            claim_id: "",
                            order: "1",
                            corrected_claim: true,
                            patient_account_number: "",
                            period_start: "",
                            period_end: "",
                            billed: "",

                            patient_id: "",
                            patient_first_name: "",
                            patient_middle_name: "",
                            patient_last_name: "",

                            page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                            page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                            dbk_patient_account_number: "",
                            dbk_patient_first_name: "",
                            dbk_patient_middle_name: "",
                            dbk_patient_last_name: "",
                            dbk_billed: "",
                            dbk_period_start: "",
                            dbk_period_end: "",
                        },
                    ],
                })
                setIsIncomplete(false)
                await aditionalInfoFormik.setValues({
                    status: "",
                    reason: "",
                    document_classification: "",
                    is_orphaned_check: false,
                    is_missing_check_image: false,
                    is_forced_balancing: false,
                    is_payer_black_listing: false,
                    is_payer_excluding_indexing: false,
                })
                setTimeout(() => {
                    formCorres.validateForm();
                }, 200);
            }
            else {
                await formCorres.setValues({
                    ...formCorres.values,
                    // payer_name: "",
                    // letter_date: "",
                    // correspondance: "",

                    payer_page_from: "1",
                    payer_page_to: "1",

                    dbk_payer_name: "",
                    dbk_letter_date: "",

                    qdx_status: "",
                    qdx_reason: "",
                    qdx_classification: "",

                    claims: [
                        {
                            claim_id: "",
                            order: "1",
                            corrected_claim: true,
                            patient_account_number: "",
                            period_start: "",
                            period_end: "",
                            billed: "",

                            patient_id: "",
                            patient_first_name: "",
                            patient_middle_name: "",
                            patient_last_name: "",

                            page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                            page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                            dbk_patient_account_number: "",
                            dbk_patient_first_name: "",
                            dbk_patient_middle_name: "",
                            dbk_patient_last_name: "",
                            dbk_billed: "",
                            dbk_period_start: "",
                            dbk_period_end: "",
                        },
                    ],
                })
                setIsIncomplete(false)
                await aditionalInfoFormik.setValues({
                    status: "",
                    reason: "",
                    document_classification: "",
                    is_orphaned_check: false,
                    is_missing_check_image: false,
                    is_forced_balancing: false,
                    is_payer_black_listing: false,
                    is_payer_excluding_indexing: false,
                })
                setTimeout(() => {
                    formCorres.validateForm();
                }, 200);
            }
            setClaimCorresIndex(0)
            // localStorage.removeItem('clear')
            // localStorage.setItem('transaction_type', 'CORRESPONDENCE')
            // window.location.reload();
        }
    }

    const [showCheckView, setShowCheckView] = useState(false)
    const [infoModal, setInfoModal] = useState(false)
    // const [qdxTransaction, setQdxTransaction] = useState('Payment835')

    const closeClaim = () => {
        setClaim835(false)
        setState({
            ...state,
            updateDisable: !state.updateDisable,
        })
        // if (state.Payment835) {
        //     if (qdxTransaction != "PATIENT_PAY") {

        //         setControlledPosition({ x: 0, y: 560 });
        //     }
        //     else {
        //         setControlledPosition({ x: 0, y: 580 });
        //     }
        // }
        // else {
        //     setControlledPosition({ x: 0, y: 700 });
        // }

    }

    const showAssignTooltip = () => {
        getJobStaffStatus(jobData.job)((response) => {
            if (response?.status) {
                setUserStatus(response?.data)
                if (response?.data?.is_active) {
                    setAssignTo('user');
                }
                else {
                    setAssignTo('common')
                }
                setAssignTooltip(true);

            }
        })

    }

    const assignRef = useRef(null);
    const getPlbOnlyRemainingBalance = (plb_adjustments, check_amount) => {
        const old_check_amount = parseFloat(check_amount ? check_amount : 0)
        let plb_amt = 0
        if (plb_adjustments?.length > 0) {
            plb_adjustments?.map((plb, index) => {
                plb_amt += parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
            })
        }
        return parseFloat(parseFloat(old_check_amount ?? 0) + parseFloat(plb_amt ?? 0)).toFixed(2)
    }
    const handlePlbOnlyValue = async (val) => {
        if (state.Payment835 && formEob?.values?.grid_customization != val) {
            const remaining_balance = getPlbOnlyRemainingBalance(formEob?.values?.plb_adjustments, formEob?.values?.check_amount)

            formEob?.setValues({
                ...formEob.values,
                remaining_balance,
                grid_customization: val,
                claims: [
                    {
                        patient: true,
                        subscriber: true,
                        rendering_provider: true,
                        corrected_claim: true,

                        order: "",
                        color_index: "",

                        claim_id: "",
                        patient_account_number: "",
                        payer_claim_number: "",
                        period_start: "",
                        period_end: "",
                        status_code: "",
                        mrn: "",
                        statement_number: "",
                        statement_date: "",
                        page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                        page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                        claim_billed: "",
                        claim_discount: parseFloat(0).toFixed(2),
                        claim_allowed: parseFloat(0).toFixed(2),
                        claim_deduct: parseFloat(0).toFixed(2),
                        claim_coins: parseFloat(0).toFixed(2),
                        claim_copay: parseFloat(0).toFixed(2),
                        claim_cob: parseFloat(0).toFixed(2),
                        claim_patres: parseFloat(0).toFixed(2),
                        claim_paid: "",
                        claim_balance: parseFloat(0).toFixed(2),

                        sl_billed: parseFloat(0).toFixed(2),
                        sl_discount: parseFloat(0).toFixed(2),
                        sl_allowed: parseFloat(0).toFixed(2),
                        sl_deduct: parseFloat(0).toFixed(2),
                        sl_coins: parseFloat(0).toFixed(2),
                        sl_copay: parseFloat(0).toFixed(2),
                        sl_cob: parseFloat(0).toFixed(2),
                        sl_patres: parseFloat(0).toFixed(2),
                        sl_amount: parseFloat(0).toFixed(2),
                        sl_paid: parseFloat(0).toFixed(2),
                        sl_balance: parseFloat(0).toFixed(2),
                        sl_total: parseFloat(0).toFixed(2),

                        color_data: [],

                        patient_id: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",
                        patient_identifier_code: "",

                        subscriber_id: "",
                        subscriber_first_name: "",
                        subscriber_middle_name: "",
                        subscriber_last_name: "",
                        subscriber_identifier_code: "",

                        rendering_provider_id: "",
                        rendering_provider_first_name: "",
                        rendering_provider_last_name: "",
                        rendering_provider_identifier_code: "",

                        // DBK
                        dbk_patient_account_number: "",
                        dbk_payer_claim_number: "",

                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                        dbk_patient_identifier_code: "",

                        dbk_subscriber_first_name: "",
                        dbk_subscriber_middle_name: "",
                        dbk_subscriber_last_name: "",
                        dbk_subscriber_identifier_code: "",

                        dbk_rendering_provider_first_name: "",
                        dbk_rendering_provider_last_name: "",
                        dbk_rendering_provider_identifier_code: "",


                        dbk_mrn: "",
                        dbk_statement_number: "",
                        dbk_statement_date: "",

                        claim_adjustments: [
                            {
                                pc: "",
                                group_code: "",
                                reason_code: "",
                                rmk: "",
                                amount: "",
                                claim_adjustment_id: "",
                            },
                        ],

                        service_lines: [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                // corrected_serviceline: true,
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ],
                    },
                ],
            })
            setClaim835Index(0)
            setControlledPosition();
        }
        if (state.Payment835Lite && eobLiteFormik?.values?.grid_customization != val) {
            const remaining_balance = getPlbOnlyRemainingBalance(eobLiteFormik?.values?.plb_adjustments, eobLiteFormik?.values?.check_amount)

            eobLiteFormik?.setValues({
                ...eobLiteFormik.values,
                remaining_balance,
                grid_customization: val,
                claims: [
                    {
                        claim_id: "",
                        order: "",
                        corrected_claim: true,
                        patient_id: "",
                        patient_account_number: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",

                        page_from: "1",
                        page_to: "1",
                        service_lines: {
                            service_line_id: "",
                            period_start: "",
                            period_end: "",
                            billed: "",
                            paid: "",
                        },

                        dbk_patient_account_number: "",
                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                    },
                ],
            })
            setClaimLiteIndex(0)
            setControlledPosition();

        }

        // if (val == 'PLB_ONLY' && state.Payment835) {
        //     setControlledPosition({ x: 0, y: 770 });
        // }
        // else if (val == 'PLB_ONLY' && state.Payment835Lite) {
        //     setControlledPosition({ x: 0, y: 800 });


        // } else {
        //     if (state.Payment835) {
        //         if (formEob?.values?.qdx_tranansaction_type == "PATIENT_PAY") {
        //             setControlledPosition({ x: 0, y: 580 });
        //         } else {
        //             setControlledPosition({ x: 0, y: 550 });
        //         }
        //     }
        //     else if (state.Payment835Lite) {
        //         setControlledPosition({ x: 0, y: 700 });
        //     }
        // }
        setPlbOnlyValue(val)
    }
    const handleQdxTransaction = async () => {
        setClaim835Index(0)

        if (localStorage.getItem('837_pulled')) {
            localStorage.removeItem("837_pulled");
        }
        const _ = require('lodash');
        const values = _.cloneDeep(jobData?.grid_data);
        setQdxTransaction('CORRESPONDENCE');
        if (formEob.values?.grid_customization == "PLB_ONLY") {
            setControlledPosition({ x: 0, y: 770 });
        } else {
            setControlledPosition({ x: 0, y: 580 });
        }

        if (!isSubJob || isSubJob && isFirstJob) {

            let check_number = "";

            if (isCheckNumber && window.location.pathname == DATA_ENTRY) {
                // if (formEob.values.payment_method == "COR") {
                let num = Math.floor(Math.random() * 10000);
                // Convert the number to a 4-digit string with leading zeros if necessary
                num = num.toString().padStart(4, '0');
                if (isGeneratedCheckNumber) {
                    check_number = "LT" + jobData?.grid_data?.timezone
                }

            }
            else {
                check_number = jobData?.grid_data?.check_number ? jobData?.grid_data?.check_number : ""
            }

            let remaining = 0;
            let paid_total = 0;
            let plb_total = 0;

            values?.claims?.map((claim, ind) => {
                paid_total =
                    parseFloat(paid_total ? paid_total : 0) +
                    parseFloat(claim.claim_paid ? claim.claim_paid : 0);
            });
            let plb_adjustments = values?.plb_adjustments ? values?.plb_adjustments : [];

            if (plb_adjustments && plb_adjustments?.length > 0) {
                plb_adjustments.map((plb, index) => {
                    plb.plb_index = index;
                    plb.plb_amt = plb.plb_amt == 0 ? parseFloat(0).toFixed(2) : plb.plb_amt > 0 || plb.plb_amt < 0 ? parseFloat(plb.plb_amt ? plb.plb_amt : 0).toFixed(2) : parseFloat(0).toFixed(2);
                });
            }
            values?.plb_adjustments?.map((plb, ind) => {
                plb_total =
                    parseFloat(plb_total ? plb_total : 0) +
                    parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
            });

            remaining =
                parseFloat(values.check_amount ? values.check_amount : 0) -
                parseFloat(paid_total ? paid_total : 0) +
                parseFloat(plb_total ? plb_total : 0);

            if (parseFloat(remaining).toFixed(2) == -0.00) {
                remaining = parseFloat(0).toFixed(2);
            }

            var claims = []
            if (jobData?.grid_data?.transaction_type == "PAYMENT835" && values?.claims?.length > 0) {
                claims = values?.claims;

                claims.map((claim, index) => {
                    // DBK START
                    claim.corrected_claim = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;
                    claim.claim_corrected_index = index;
                    claim.page_from = claim && claim?.page_from ? claim && claim?.page_from : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.page_to = claim && claim?.page_to ? claim && claim?.page_to : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.dbk_patient_account_number = claim.patient_account_number ?? "";
                    claim.payer_claim_number = claim.payer_claim_number ?? "";
                    claim.dbk_payer_claim_number = claim.payer_claim_number ?? "";

                    claim.dbk_patient_first_name = claim.patient_first_name ?? "";
                    claim.dbk_patient_middle_name = claim.patient_middle_name ?? "";
                    claim.dbk_patient_last_name = claim.patient_last_name ?? "";
                    claim.dbk_patient_identifier_code = claim.patient_identifier_code ?? "";

                    claim.dbk_subscriber_first_name = claim.subscriber_first_name ?? "";
                    claim.dbk_subscriber_middle_name = claim.subscriber_middle_name ?? "";
                    claim.dbk_subscriber_last_name = claim.subscriber_last_name ?? "";
                    claim.dbk_subscriber_identifier_code =
                        claim.subscriber_identifier_code ?? "";

                    claim.dbk_rendering_provider_first_name =
                        claim.rendering_provider_first_name ?? "";
                    claim.dbk_rendering_provider_last_name =
                        claim.rendering_provider_last_name ?? "";
                    claim.dbk_rendering_provider_identifier_code =
                        claim.rendering_provider_identifier_code ?? "";

                    claim.dbk_mrn =
                        claim.mrn ?? "";
                    claim.dbk_statement_number =
                        claim.statement_number ?? "";
                    claim.dbk_statement_date =
                        claim.statement_date ?? "";


                    // DBK END
                    claim.patient = claim.patient_id ? true : false;
                    claim.subscriber = claim.subscriber_id ? true : false;
                    claim.rendering_provider = claim.rendering_provider_id ? true : false;
                    claim.period_start = claim.period_start != "" ? moment(claim.period_start).format("MM/DD/YYYY") : "";
                    claim.period_end = claim.period_end != "" ? moment(claim.period_end).format("MM/DD/YYYY") : "";
                    claim.encounter = claim?.encounter ? claim?.encounter : "";
                    claim.claim_billed = parseFloat(claim?.claim_billed ? claim?.claim_billed : 0).toFixed(2);
                    claim.claim_discount = parseFloat(claim?.claim_discount ? claim?.claim_discount : 0).toFixed(2);
                    claim.claim_allowed = parseFloat(claim?.claim_allowed ? claim?.claim_allowed : 0).toFixed(2);
                    claim.claim_deduct = parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0).toFixed(2);
                    claim.claim_coins = parseFloat(claim?.claim_coins ? claim?.claim_coins : 0).toFixed(2);
                    claim.claim_copay = parseFloat(claim?.claim_copay ? claim?.claim_copay : 0).toFixed(2);
                    claim.claim_cob = parseFloat(claim?.claim_cob ? claim?.claim_cob : 0).toFixed(2);
                    claim.claim_patres = parseFloat(claim?.claim_patres ? claim?.claim_patres : 0).toFixed(2);
                    claim.claim_paid = parseFloat(claim?.claim_paid ? claim?.claim_paid : 0).toFixed(2);
                    claim.claim_balance = parseFloat(claim?.claim_balance ? claim?.claim_balance : 0).toFixed(2);
                    claim.claim_adjustment_total = parseFloat(claim?.claim_adjustment_total ? claim?.claim_adjustment_total : 0).toFixed(2);
                    claim.claim_adjustment_total_pr = parseFloat(claim?.claim_adjustment_total_pr ? claim?.claim_adjustment_total_pr : 0).toFixed(2);

                    // claim.status_code = values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "";
                    claim.mrn = claim?.mrn ? claim?.mrn : "";
                    claim.statement_number = claim?.statement_number ? claim?.statement_number : "";
                    claim.statement_date = claim?.statement_date ? claim?.statement_date : "";

                    claim.sl_billed = parseFloat(claim?.sl_billed ? claim?.sl_billed : 0).toFixed(2);
                    claim.fake_sl_patres = parseFloat(claim?.fake_sl_patres ? claim?.fake_sl_patres : 0).toFixed(2);
                    claim.adjustment_total = parseFloat(claim?.adjustment_total ? claim?.adjustment_total : 0).toFixed(2);
                    claim.sl_allowed = parseFloat(claim?.sl_allowed ? claim?.sl_allowed : 0).toFixed(2);
                    claim.sl_deduct = parseFloat(claim?.sl_deduct ? claim?.sl_deduct : 0).toFixed(2);
                    claim.sl_coins = parseFloat(claim?.sl_coins ? claim?.sl_coins : 0).toFixed(2);
                    claim.sl_copay = parseFloat(claim?.sl_copay ? claim?.sl_copay : 0).toFixed(2);
                    claim.sl_cob = parseFloat(claim?.sl_cob ? claim?.sl_cob : 0).toFixed(2);
                    claim.sl_patres = parseFloat(claim?.sl_patres ? claim?.sl_patres : 0).toFixed(2);
                    claim.sl_amount = parseFloat(claim?.sl_amount ? claim?.sl_amount : 0).toFixed(2);
                    claim.sl_paid = parseFloat(claim?.sl_paid ? claim?.sl_paid : 0).toFixed(2);
                    claim.sl_balance = parseFloat(claim?.sl_balance ? claim?.sl_balance : 0).toFixed(2);
                    claim.sl_total = parseFloat(claim?.sl_total ? claim?.sl_total : 0).toFixed(2);

                    claim?.claim_adjustments && claim?.claim_adjustments?.length > 0
                        ? claim?.claim_adjustments?.map((adjustment) => {
                            adjustment.amount = parseFloat(adjustment.amount ? adjustment.amount : 0).toFixed(2);
                        })
                        : (claim.claim_adjustments =
                            formEob.initialValues.claims[0].claim_adjustments);
                    // claim?.service_lines && claim?.service_lines?.length > 0
                    //     ? 
                    if (claim?.service_lines?.length > 0) {
                        claim?.service_lines?.map((serviceline, index) => {
                            serviceline.serviceline_old_data_index = index;
                            serviceline.from_date = serviceline?.from_date != "" ? moment(serviceline?.from_date).format(
                                "MM/DD/YYYY"
                            ) : "";
                            serviceline.to_date = serviceline?.to_date != "" ? moment(serviceline?.to_date).format(
                                "MM/DD/YYYY"
                            ) : "";
                            serviceline.corrected_serviceline = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;

                            serviceline.serviceline_old_data_index = index;
                            serviceline.billed = parseFloat(serviceline?.billed ? serviceline?.billed : 0).toFixed(2);
                            serviceline.patres = parseFloat(serviceline?.patres ? serviceline?.patres : 0).toFixed(2);
                            serviceline.discount = parseFloat(serviceline?.discount ? serviceline?.discount : 0).toFixed(2);
                            serviceline.allowed = parseFloat(serviceline?.allowed ? serviceline?.allowed : 0).toFixed(2);
                            serviceline.deduct = parseFloat(serviceline?.deduct ? serviceline?.deduct : 0).toFixed(2);
                            serviceline.coins = parseFloat(serviceline?.coins ? serviceline?.coins : 0).toFixed(2);
                            serviceline.copay = parseFloat(serviceline?.copay ? serviceline?.copay : 0).toFixed(2);
                            serviceline.cob = parseFloat(serviceline?.cob ? serviceline?.cob : 0).toFixed(2);
                            serviceline.paid = parseFloat(serviceline?.paid ? serviceline?.paid : 0).toFixed(2);
                            serviceline.balance = parseFloat(serviceline?.balance ? serviceline?.balance : 0).toFixed(2);

                            serviceline?.adjustments.map((adjustment) => {
                                adjustment.amount = parseFloat(
                                    adjustment.amount ? adjustment.amount : 0
                                ).toFixed(2);
                            });
                        })
                    }
                    else {
                        claim.service_lines = [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ];
                    }
                    if (claim?.encounter && claim?.encounter != '') {
                        localStorage.setItem('837_pulled', true)
                    }
                    // : (claim.service_lines =
                    //     formEob.initialValues.claims[0].service_lines);
                });

            }
            else {

                claims = [
                    {
                        patient: true,
                        subscriber: true,
                        rendering_provider: true,

                        order: "",
                        color_index: "",

                        corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                        claim_id: "",
                        patient_account_number: "",
                        payer_claim_number: "",
                        period_start: "",
                        period_end: "",
                        status_code: values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "",
                        page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                        page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                        claim_billed: parseFloat(0).toFixed(2),
                        claim_discount: parseFloat(0).toFixed(2),
                        claim_allowed: parseFloat(0).toFixed(2),
                        claim_deduct: parseFloat(0).toFixed(2),
                        claim_coins: parseFloat(0).toFixed(2),
                        claim_copay: parseFloat(0).toFixed(2),
                        claim_cob: parseFloat(0).toFixed(2),
                        claim_patres: parseFloat(0).toFixed(2),
                        claim_paid: parseFloat(0).toFixed(2),
                        claim_balance: parseFloat(0).toFixed(2),
                        claim_adjustment_total: parseFloat(0).toFixed(2),
                        claim_adjustment_total_pr: parseFloat(0).toFixed(2),

                        sl_billed: parseFloat(0).toFixed(2),
                        sl_discount: parseFloat(0).toFixed(2),
                        sl_allowed: parseFloat(0).toFixed(2),
                        sl_deduct: parseFloat(0).toFixed(2),
                        sl_coins: parseFloat(0).toFixed(2),
                        sl_copay: parseFloat(0).toFixed(2),
                        sl_cob: parseFloat(0).toFixed(2),
                        sl_patres: parseFloat(0).toFixed(2),
                        sl_amount: parseFloat(0).toFixed(2),
                        sl_paid: parseFloat(0).toFixed(2),
                        sl_balance: parseFloat(0).toFixed(2),
                        sl_total: parseFloat(0).toFixed(2),

                        color_data: [],

                        mrn: "",
                        statement_number: "",
                        statement_date: "",

                        patient_id: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",
                        patient_identifier_code: "",

                        subscriber_id: "",
                        subscriber_first_name: "",
                        subscriber_middle_name: "",
                        subscriber_last_name: "",
                        subscriber_identifier_code: "",

                        rendering_provider_id: "",
                        rendering_provider_first_name: "",
                        rendering_provider_last_name: "",
                        rendering_provider_identifier_code: "",

                        // DBK
                        dbk_patient_account_number: "",
                        dbk_payer_claim_number: "",

                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                        dbk_patient_identifier_code: "",

                        dbk_subscriber_first_name: "",
                        dbk_subscriber_middle_name: "",
                        dbk_subscriber_last_name: "",
                        dbk_subscriber_identifier_code: "",

                        dbk_rendering_provider_first_name: "",
                        dbk_rendering_provider_last_name: "",
                        dbk_rendering_provider_identifier_code: "",

                        dbk_mrn: '',
                        dbk_statement_number: '',
                        dbk_statement_date: '',

                        claim_adjustments: [
                            {
                                pc: "",
                                group_code: "",
                                reason_code: "",
                                rmk: "",
                                amount: "",
                                claim_adjustment_id: "",
                            },
                        ],

                        service_lines: [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ],
                    },
                ]

            }
            await formEob.setValues({
                ...formEob.values,
                payment_method: "COR",
                check_number: check_number,
                dbk_check_number: check_number,
                check_amount: parseFloat(0).toFixed(2),
                dbk_check_amount: parseFloat(0).toFixed(2),
                remaining_balance: parseFloat(0).toFixed(2),
                qdx_tranansaction_type: "CORRESPONDENCE",
                job: jobData?.job,
                client_id: jobData?.client_id,
                is_first_sub_job: isFirstJob,
                payment_id:
                    values?.payment_id && values?.payment_id ? values?.payment_id : "",
                data_837: values?.data_837 ? values?.data_837 : [],
                transaction_type:
                    values?.transaction_type && values?.transaction_type
                        ? values?.transaction_type
                        : "",


                check_date: isSubJob && !isFirstJob ? values?.check_date :
                    values?.check_date && values?.check_date ? values?.check_date : "",
                check_page_number:
                    values?.check_page_number && values?.check_page_number
                        ? values?.check_page_number
                        : "",
                correspondance:
                    values?.correspondance && values?.correspondance ? values?.correspondance : "",

                payee_id:
                    values?.payee_id && values?.payee_id != "" ? values?.payee_id : "",
                payee_name:
                    values?.payee_name && values?.payee_name != ""
                        ? values?.payee_name
                        : "",
                payee_npi:
                    values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                payee_address:
                    values?.payee_address && values?.payee_address != ""
                        ? values?.payee_address
                        : "",
                payee_city:
                    values?.payee_city && values?.payee_city != ""
                        ? values?.payee_city
                        : "",
                payee_state:
                    values?.payee_state && values?.payee_state != ""
                        ? values?.payee_state
                        : "",
                payee_zip:
                    values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                payer_page_from:
                    values?.payer_page_from && values?.payer_page_from != "" ? values?.payer_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                payer_page_to:
                    values?.payer_page_to && values?.payer_page_to != "" ? values?.payer_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                payer_id:
                    values?.payer_id && values?.payer_id != "" ? values?.payer_id : "",
                payer_name:
                    values?.payer_name && values?.payer_name != ""
                        ? values?.payer_name
                        : "",
                payer_address:
                    values?.payer_address && values?.payer_address != ""
                        ? values?.payer_address
                        : "",
                payer_city:
                    values?.payer_city && values?.payer_city != ""
                        ? values?.payer_city
                        : "",
                payer_state:
                    values?.payer_state && values?.payer_state != ""
                        ? values?.payer_state
                        : "",
                payer_zip:
                    values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",
                payee_page_from:
                    values?.payee_page_from && values?.payee_page_from != "" ? values?.payee_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                payee_page_to:
                    values?.payee_page_to && values?.payee_page_to != "" ? values?.payee_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                // DBK
                // dbk_check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                //     ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",
                dbk_check_date: isSubJob && !isFirstJob ? values?.check_date :
                    values?.check_date && values?.check_date ? values?.check_date : "",
                // dbk_check_amount: isSubJob && !isFirstJob ? values?.check_amount :
                //     values?.check_amount == 0
                //         ? parseFloat(0).toFixed(2)
                //         : parseFloat(values?.check_amount)
                //             ? parseFloat(values?.check_amount).toFixed(2)
                //             : "",
                dbk_payee_name:
                    values?.payee_name && values?.payee_name != ""
                        ? values?.payee_name
                        : "",
                dbk_payee_npi:
                    values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                dbk_payee_address:
                    values?.payee_address && values?.payee_address != ""
                        ? values?.payee_address
                        : "",
                dbk_payee_city:
                    values?.payee_city && values?.payee_city != ""
                        ? values?.payee_city
                        : "",
                dbk_payee_state:
                    values?.payee_state && values?.payee_state != ""
                        ? values?.payee_state
                        : "",
                dbk_payee_zip:
                    values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                dbk_payer_name:
                    values?.payer_name && values?.payer_name != ""
                        ? values?.payer_name
                        : "",
                dbk_payer_address:
                    values?.payer_address && values?.payer_address != ""
                        ? values?.payer_address
                        : "",
                dbk_payer_city:
                    values?.payer_city && values?.payer_city != ""
                        ? values?.payer_city
                        : "",
                dbk_payer_state:
                    values?.payer_state && values?.payer_state != ""
                        ? values?.payer_state
                        : "",
                dbk_payer_zip:
                    values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",

                // qdx_tranansaction_type: values?.qdx_tranansaction_type ? values?.qdx_tranansaction_type : "PAYMENT835",
                qdx_status: values?.qdx_status ? values?.qdx_status : "",
                // qdx_reason: values?.qdx_reason ? values?.qdx_reason : "",
                qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",

                plb_adjustments: plb_adjustments,

                claims: claims,
            })

        }
        else {
            var claims = []
            if (values?.claims?.length > 0) {
                claims = values?.claims;

                claims.map((claim, index) => {
                    // DBK START
                    claim.corrected_claim = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;
                    claim.claim_corrected_index = index;
                    claim.page_from = claim && claim?.page_from ? claim && claim?.page_from : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.page_to = claim && claim?.page_to ? claim && claim?.page_to : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.dbk_patient_account_number = claim.patient_account_number ?? "";
                    claim.dbk_payer_claim_number = claim.payer_claim_number ?? "";

                    claim.dbk_patient_first_name = claim.patient_first_name ?? "";
                    claim.dbk_patient_middle_name = claim.patient_middle_name ?? "";
                    claim.dbk_patient_last_name = claim.patient_last_name ?? "";
                    claim.dbk_patient_identifier_code = claim.patient_identifier_code ?? "";

                    claim.dbk_subscriber_first_name = claim.subscriber_first_name ?? "";
                    claim.dbk_subscriber_middle_name = claim.subscriber_middle_name ?? "";
                    claim.dbk_subscriber_last_name = claim.subscriber_last_name ?? "";
                    claim.dbk_subscriber_identifier_code =
                        claim.subscriber_identifier_code ?? claim.subscriber_identifier_code;

                    claim.dbk_rendering_provider_first_name =
                        claim.rendering_provider_first_name ?? claim.rendering_provider_first_name;
                    claim.dbk_rendering_provider_last_name =
                        claim.rendering_provider_last_name ?? claim.rendering_provider_last_name;
                    claim.dbk_rendering_provider_identifier_code =
                        claim.rendering_provider_identifier_code ?? claim.rendering_provider_identifier_code;

                    claim.dbk_mrn =
                        claim.mrn ?? claim.mrn;
                    claim.dbk_statement_number =
                        claim.statement_number ?? claim.statement_number;
                    claim.dbk_statement_date =
                        claim.statement_date ?? claim.statement_date;


                    // DBK END
                    claim.patient = claim.patient_id ? true : false;
                    claim.subscriber = claim.subscriber_id ? true : false;
                    claim.rendering_provider = claim.rendering_provider_id ? true : false;
                    claim.period_start = claim.period_start != "" ? moment(claim.period_start).format("MM/DD/YYYY") : "";
                    claim.period_end = claim.period_end != "" ? moment(claim.period_end).format("MM/DD/YYYY") : "";
                    claim.encounter = claim?.encounter ? claim?.encounter : "";
                    claim.claim_billed = parseFloat(claim?.claim_billed ? claim?.claim_billed : 0).toFixed(2);
                    claim.claim_discount = parseFloat(claim?.claim_discount ? claim?.claim_discount : 0).toFixed(2);
                    claim.claim_allowed = parseFloat(claim?.claim_allowed ? claim?.claim_allowed : 0).toFixed(2);
                    claim.claim_deduct = parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0).toFixed(2);
                    claim.claim_coins = parseFloat(claim?.claim_coins ? claim?.claim_coins : 0).toFixed(2);
                    claim.claim_copay = parseFloat(claim?.claim_copay ? claim?.claim_copay : 0).toFixed(2);
                    claim.claim_cob = parseFloat(claim?.claim_cob ? claim?.claim_cob : 0).toFixed(2);
                    claim.claim_patres = parseFloat(claim?.claim_patres ? claim?.claim_patres : 0).toFixed(2);
                    claim.claim_paid = parseFloat(claim?.claim_paid ? claim?.claim_paid : 0).toFixed(2);
                    claim.claim_balance = parseFloat(claim?.claim_balance ? claim?.claim_balance : 0).toFixed(2);
                    claim.claim_adjustment_total = parseFloat(claim?.claim_adjustment_total ? claim?.claim_adjustment_total : 0).toFixed(2);
                    claim.claim_adjustment_total_pr = parseFloat(claim?.claim_adjustment_total_pr ? claim?.claim_adjustment_total_pr : 0).toFixed(2);

                    // claim.status_code = values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "";
                    claim.mrn = claim?.mrn ? claim?.mrn : "";
                    claim.statement_number = claim?.statement_number ? claim?.statement_number : "";
                    claim.statement_date = claim?.statement_date ? claim?.statement_date : "";

                    claim.sl_billed = parseFloat(claim?.sl_billed ? claim?.sl_billed : 0).toFixed(2);
                    claim.fake_sl_patres = parseFloat(claim?.fake_sl_patres ? claim?.fake_sl_patres : 0).toFixed(2);
                    claim.adjustment_total = parseFloat(claim?.adjustment_total ? claim?.adjustment_total : 0).toFixed(2);
                    claim.sl_allowed = parseFloat(claim?.sl_allowed ? claim?.sl_allowed : 0).toFixed(2);
                    claim.sl_deduct = parseFloat(claim?.sl_deduct ? claim?.sl_deduct : 0).toFixed(2);
                    claim.sl_coins = parseFloat(claim?.sl_coins ? claim?.sl_coins : 0).toFixed(2);
                    claim.sl_copay = parseFloat(claim?.sl_copay ? claim?.sl_copay : 0).toFixed(2);
                    claim.sl_cob = parseFloat(claim?.sl_cob ? claim?.sl_cob : 0).toFixed(2);
                    claim.sl_patres = parseFloat(claim?.sl_patres ? claim?.sl_patres : 0).toFixed(2);
                    claim.sl_amount = parseFloat(claim?.sl_amount ? claim?.sl_amount : 0).toFixed(2);
                    claim.sl_paid = parseFloat(claim?.sl_paid ? claim?.sl_paid : 0).toFixed(2);
                    claim.sl_balance = parseFloat(claim?.sl_balance ? claim?.sl_balance : 0).toFixed(2);
                    claim.sl_total = parseFloat(claim?.sl_total ? claim?.sl_total : 0).toFixed(2);

                    claim?.claim_adjustments && claim?.claim_adjustments?.length > 0
                        ? claim?.claim_adjustments?.map((adjustment) => {
                            adjustment.amount = parseFloat(adjustment.amount ? adjustment.amount : 0).toFixed(2);
                        })
                        : (claim.claim_adjustments =
                            formEob.initialValues.claims[0].claim_adjustments);
                    // claim?.service_lines && claim?.service_lines?.length > 0
                    //     ? 
                    if (claim?.service_lines?.length > 0) {
                        claim?.service_lines?.map((serviceline, index) => {
                            serviceline.serviceline_old_data_index = index;
                            serviceline.from_date = serviceline?.from_date != "" ? moment(serviceline?.from_date).format(
                                "MM/DD/YYYY"
                            ) : "";
                            serviceline.to_date = serviceline?.to_date != "" ? moment(serviceline?.to_date).format(
                                "MM/DD/YYYY"
                            ) : "";
                            serviceline.corrected_serviceline = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;

                            serviceline.serviceline_old_data_index = index;
                            serviceline.billed = parseFloat(serviceline?.billed ? serviceline?.billed : 0).toFixed(2);
                            serviceline.patres = parseFloat(serviceline?.patres ? serviceline?.patres : 0).toFixed(2);
                            serviceline.discount = parseFloat(serviceline?.discount ? serviceline?.discount : 0).toFixed(2);
                            serviceline.allowed = parseFloat(serviceline?.allowed ? serviceline?.allowed : 0).toFixed(2);
                            serviceline.deduct = parseFloat(serviceline?.deduct ? serviceline?.deduct : 0).toFixed(2);
                            serviceline.coins = parseFloat(serviceline?.coins ? serviceline?.coins : 0).toFixed(2);
                            serviceline.copay = parseFloat(serviceline?.copay ? serviceline?.copay : 0).toFixed(2);
                            serviceline.cob = parseFloat(serviceline?.cob ? serviceline?.cob : 0).toFixed(2);
                            serviceline.paid = parseFloat(serviceline?.paid ? serviceline?.paid : 0).toFixed(2);
                            serviceline.balance = parseFloat(serviceline?.balance ? serviceline?.balance : 0).toFixed(2);

                            serviceline?.adjustments.map((adjustment) => {
                                adjustment.amount = parseFloat(
                                    adjustment.amount ? adjustment.amount : 0
                                ).toFixed(2);
                            });
                        })
                    }
                    else {
                        claim.service_lines = [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ];
                    }
                    if (claim?.encounter && claim?.encounter != '') {
                        localStorage.setItem('837_pulled', true)
                    }
                    // : (claim.service_lines =
                    //     formEob.initialValues.claims[0].service_lines);
                });

            }
            else {

                claims = [
                    {
                        patient: true,
                        subscriber: true,
                        rendering_provider: true,

                        order: "",
                        color_index: "",

                        corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                        claim_id: "",
                        patient_account_number: "",
                        payer_claim_number: "",
                        period_start: "",
                        period_end: "",
                        status_code: values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "",
                        page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                        page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                        claim_billed: parseFloat(0).toFixed(2),
                        claim_discount: parseFloat(0).toFixed(2),
                        claim_allowed: parseFloat(0).toFixed(2),
                        claim_deduct: parseFloat(0).toFixed(2),
                        claim_coins: parseFloat(0).toFixed(2),
                        claim_copay: parseFloat(0).toFixed(2),
                        claim_cob: parseFloat(0).toFixed(2),
                        claim_patres: parseFloat(0).toFixed(2),
                        claim_paid: parseFloat(0).toFixed(2),
                        claim_balance: parseFloat(0).toFixed(2),
                        claim_adjustment_total: parseFloat(0).toFixed(2),
                        claim_adjustment_total_pr: parseFloat(0).toFixed(2),

                        sl_billed: parseFloat(0).toFixed(2),
                        sl_discount: parseFloat(0).toFixed(2),
                        sl_allowed: parseFloat(0).toFixed(2),
                        sl_deduct: parseFloat(0).toFixed(2),
                        sl_coins: parseFloat(0).toFixed(2),
                        sl_copay: parseFloat(0).toFixed(2),
                        sl_cob: parseFloat(0).toFixed(2),
                        sl_patres: parseFloat(0).toFixed(2),
                        sl_amount: parseFloat(0).toFixed(2),
                        sl_paid: parseFloat(0).toFixed(2),
                        sl_balance: parseFloat(0).toFixed(2),
                        sl_total: parseFloat(0).toFixed(2),

                        color_data: [],

                        mrn: "",
                        statement_number: "",
                        statement_date: "",

                        patient_id: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",
                        patient_identifier_code: "",

                        subscriber_id: "",
                        subscriber_first_name: "",
                        subscriber_middle_name: "",
                        subscriber_last_name: "",
                        subscriber_identifier_code: "",

                        rendering_provider_id: "",
                        rendering_provider_first_name: "",
                        rendering_provider_last_name: "",
                        rendering_provider_identifier_code: "",

                        // DBK
                        dbk_patient_account_number: "",
                        dbk_payer_claim_number: "",

                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                        dbk_patient_identifier_code: "",

                        dbk_subscriber_first_name: "",
                        dbk_subscriber_middle_name: "",
                        dbk_subscriber_last_name: "",
                        dbk_subscriber_identifier_code: "",

                        dbk_rendering_provider_first_name: "",
                        dbk_rendering_provider_last_name: "",
                        dbk_rendering_provider_identifier_code: "",

                        dbk_mrn: '',
                        dbk_statement_number: '',
                        dbk_statement_date: '',

                        claim_adjustments: [
                            {
                                pc: "",
                                group_code: "",
                                reason_code: "",
                                rmk: "",
                                amount: "",
                                claim_adjustment_id: "",
                            },
                        ],

                        service_lines: [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ],
                    },
                ]

            }
            const plbOnlyCheckAmnt = isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_amount :
                values?.check_amount == 0
                    ? parseFloat(0).toFixed(2)
                    : parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                        ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                        : ""
            await formEob.setValues({
                ...formEob.values,
                qdx_tranansaction_type: "CORRESPONDENCE",
                job: jobData?.job,
                client_id: jobData?.client_id,
                is_first_sub_job: isFirstJob,
                payment_id:
                    values?.payment_id && values?.payment_id ? values?.payment_id : "",
                data_837: values?.data_837 ? values?.data_837 : [],
                transaction_type:
                    values?.transaction_type && values?.transaction_type
                        ? values?.transaction_type
                        : "",

                payment_method: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.payment_method : values?.payment_method && values?.payment_method != "" ? values?.payment_method :
                    values?.check_amount && values?.check_amount > 0 ? "CHK" : values?.check_amount == 0 || values?.check_amount == "" && values?.qdx_tranansaction_type == "PAYMENT835" ? "NON" : values?.check_amount == 0 && values?.qdx_tranansaction_type == "CORRESPONDENCE" ? "COR" : "",
                // payment_method: values?.payment_method,
                check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                    ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",

                check_date: isSubJob && !isFirstJob ? values?.check_date :
                    values?.check_date && values?.check_date ? values?.check_date : "",
                check_amount: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_amount :
                    values?.check_amount == 0
                        ? parseFloat(0).toFixed(2)
                        : parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                            ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                            : "",
                remaining_balance: formEob?.values?.grid_customization != "PLB_ONLY" ? parseFloat(remaining ? remaining : 0).toFixed(2) : getPlbOnlyRemainingBalance(plb_adjustments, plbOnlyCheckAmnt),
                check_page_number:
                    values?.check_page_number && values?.check_page_number
                        ? values?.check_page_number
                        : "",
                correspondance:
                    values?.correspondance && values?.correspondance ? values?.correspondance : "",

                payee_id:
                    values?.payee_id && values?.payee_id != "" ? values?.payee_id : "",
                payee_name:
                    values?.payee_name && values?.payee_name != ""
                        ? values?.payee_name
                        : "",
                payee_npi:
                    values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                payee_address:
                    values?.payee_address && values?.payee_address != ""
                        ? values?.payee_address
                        : "",
                payee_city:
                    values?.payee_city && values?.payee_city != ""
                        ? values?.payee_city
                        : "",
                payee_state:
                    values?.payee_state && values?.payee_state != ""
                        ? values?.payee_state
                        : "",
                payee_zip:
                    values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                payer_page_from:
                    values?.payer_page_from && values?.payer_page_from != "" ? values?.payer_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                payer_page_to:
                    values?.payer_page_to && values?.payer_page_to != "" ? values?.payer_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                payer_id:
                    values?.payer_id && values?.payer_id != "" ? values?.payer_id : "",
                payer_name:
                    values?.payer_name && values?.payer_name != ""
                        ? values?.payer_name
                        : "",
                payer_address:
                    values?.payer_address && values?.payer_address != ""
                        ? values?.payer_address
                        : "",
                payer_city:
                    values?.payer_city && values?.payer_city != ""
                        ? values?.payer_city
                        : "",
                payer_state:
                    values?.payer_state && values?.payer_state != ""
                        ? values?.payer_state
                        : "",
                payer_zip:
                    values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",
                payee_page_from:
                    values?.payee_page_from && values?.payee_page_from != "" ? values?.payee_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                payee_page_to:
                    values?.payee_page_to && values?.payee_page_to != "" ? values?.payee_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                // DBK
                dbk_check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                    ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",
                dbk_check_date: isSubJob && !isFirstJob ? values?.check_date :
                    values?.check_date && values?.check_date ? values?.check_date : "",
                dbk_check_amount: isSubJob && !isFirstJob ? values?.check_amount :
                    values?.check_amount == 0
                        ? parseFloat(0).toFixed(2)
                        : parseFloat(values?.check_amount)
                            ? parseFloat(values?.check_amount).toFixed(2)
                            : "",
                dbk_payee_name:
                    values?.payee_name && values?.payee_name != ""
                        ? values?.payee_name
                        : "",
                dbk_payee_npi:
                    values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                dbk_payee_address:
                    values?.payee_address && values?.payee_address != ""
                        ? values?.payee_address
                        : "",
                dbk_payee_city:
                    values?.payee_city && values?.payee_city != ""
                        ? values?.payee_city
                        : "",
                dbk_payee_state:
                    values?.payee_state && values?.payee_state != ""
                        ? values?.payee_state
                        : "",
                dbk_payee_zip:
                    values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                dbk_payer_name:
                    values?.payer_name && values?.payer_name != ""
                        ? values?.payer_name
                        : "",
                dbk_payer_address:
                    values?.payer_address && values?.payer_address != ""
                        ? values?.payer_address
                        : "",
                dbk_payer_city:
                    values?.payer_city && values?.payer_city != ""
                        ? values?.payer_city
                        : "",
                dbk_payer_state:
                    values?.payer_state && values?.payer_state != ""
                        ? values?.payer_state
                        : "",
                dbk_payer_zip:
                    values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",

                // qdx_tranansaction_type: values?.qdx_tranansaction_type ? values?.qdx_tranansaction_type : "PAYMENT835",
                qdx_status: values?.qdx_status ? values?.qdx_status : "",
                // qdx_reason: values?.qdx_reason ? values?.qdx_reason : "",
                qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",

                plb_adjustments: plb_adjustments,

                claims: claims,
            })

        }
        await aditionalInfoFormik.setValues({
            ...aditionalInfoFormik.values,
            status: values?.qdx_status ? values?.qdx_status : "",
            // reason: values?.qdx_reason ? values?.qdx_reason : "",
            reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
            document_classification: values?.qdx_classification ? values?.qdx_classification : "",
            is_forced_balancing: values?.is_forced_balancing ? values?.is_forced_balancing : false,
            is_missing_check_image: values?.is_missing_check_image ? values?.is_missing_check_image : false,
            is_orphaned_check: values?.is_orphaned_check ? values?.is_orphaned_check : false,
            is_payer_black_listing: values?.is_payer_black_listing ? values?.is_payer_black_listing : false,
            is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? values?.is_payer_excluding_indexing : false,
        })
        setTimeout(() => {

            formEob.validateForm()

        }, 150);

    }


    const handleQdxPatientPay = async () => {
        setClaim835Index(0)
        setQdxTransaction('PATIENT_PAY');
        const _ = require('lodash');
        const values = _.cloneDeep(jobData?.grid_data);
        if (formEob.values?.grid_customization == "PLB_ONLY") {
            setControlledPosition({ x: 0, y: 770 });
        } else {
            setControlledPosition({ x: 0, y: 650 });
        }

        if (!isSubJob || isSubJob && isFirstJob) {



            await formEob.setValues((prevValues) => {


                const prevState = _.cloneDeep(prevValues);


                let payment_method = "";
                let check_amount = "";
                let check_number = "";
                if (values?.payment_method) {

                    payment_method = values?.payment_method
                    // payment_method = "COR"


                    if (payment_method == "COR") {

                        payment_method = values?.payment_method && values?.payment_method != "COR" ? values?.payment_method : "NON"

                        if (isCheckNumber && window.location.pathname == DATA_ENTRY) {
                            let num = Math.floor(Math.random() * 10000);
                            // Convert the number to a 4-digit string with leading zeros if necessary
                            num = num.toString().padStart(4, '0');
                            if (isGeneratedCheckNumber) {
                                check_number = "RX" + values?.timezone
                            }
                        }
                        else {
                            check_number = values?.check_number ? values?.check_number : ""
                        }
                    }

                    else {
                        if (values?.check_amount && parseFloat(values?.check_amount) > parseFloat(0)) {

                            check_number = values?.check_number ? values?.check_number : ""
                            payment_method = values?.payment_method ? values?.payment_method : "CHK"
                        }

                        else if (values?.check_amount && parseFloat(values?.check_amount) == parseFloat(0)) {

                            payment_method = values?.payment_method ? values?.payment_method : "NON"

                            if (isCheckNumber && window.location.pathname == DATA_ENTRY) {
                                let num = Math.floor(Math.random() * 10000);
                                // Convert the number to a 4-digit string with leading zeros if necessary
                                num = num.toString().padStart(4, '0');
                                if (isGeneratedCheckNumber) {

                                    check_number = "RX" + values?.timezone
                                }
                            }
                            else {
                                check_number = values?.check_number ? values?.check_number : ""
                            }
                        }
                        else {
                            payment_method = values?.payment_method ? values?.payment_method : "ACH"

                            if (isCheckNumber && window.location.pathname == DATA_ENTRY) {
                                let num = Math.floor(Math.random() * 10000);
                                // Convert the number to a 4-digit string with leading zeros if necessary
                                num = num.toString().padStart(4, '0');
                                if (isGeneratedCheckNumber) {

                                    check_number = "RM" + values?.timezone

                                }
                            }
                            else {
                                check_number = values?.check_number ? values?.check_number : ""
                            }
                        }
                    }
                }
                else {
                    payment_method = values?.payment_method ? values?.payment_method : "CHK"

                    if (isCheckNumber && window.location.pathname == DATA_ENTRY) {
                        let num = Math.floor(Math.random() * 10000);
                        // Convert the number to a 4-digit string with leading zeros if necessary
                        num = num.toString().padStart(4, '0');
                        if (isGeneratedCheckNumber) {


                            check_number = "RM" + values?.timezone

                        }
                    }
                    else {
                        check_number = values?.check_number ? values?.check_number : ""
                    }

                }
                var claims = []
                if (jobData?.grid_data?.transaction_type == "PAYMENT835" && jobData?.grid_data?.claims?.length > 0) {
                    claims = values?.claims;

                    claims.map((claim, index) => {
                        // DBK START

                        claim.corrected_claim = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;
                        claim.claim_corrected_index = index;
                        claim.page_from = claim && claim?.page_from ? claim && claim?.page_from : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                        claim.page_to = claim && claim?.page_to ? claim && claim?.page_to : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                        claim.dbk_patient_account_number = claim.patient_account_number ?? "";
                        claim.dbk_payer_claim_number = claim.payer_claim_number ?? "";
                        claim.payer_claim_number = claim.payer_claim_number ?? "";

                        claim.dbk_patient_first_name = claim.patient_first_name ?? "";
                        claim.dbk_patient_middle_name = claim.patient_middle_name ?? "";
                        claim.dbk_patient_last_name = claim.patient_last_name ?? "";
                        claim.dbk_patient_identifier_code = claim.patient_identifier_code ?? "";

                        claim.dbk_subscriber_first_name = claim.subscriber_first_name ?? "";
                        claim.dbk_subscriber_middle_name = claim.subscriber_middle_name ?? "";
                        claim.dbk_subscriber_last_name = claim.subscriber_last_name ?? "";
                        claim.dbk_subscriber_identifier_code =
                            claim.subscriber_identifier_code ?? "";

                        claim.dbk_rendering_provider_first_name =
                            claim.rendering_provider_first_name ?? "";
                        claim.dbk_rendering_provider_last_name =
                            claim.rendering_provider_last_name ?? "";
                        claim.dbk_rendering_provider_identifier_code =
                            claim.rendering_provider_identifier_code ?? "";

                        claim.dbk_mrn =
                            claim.mrn ?? "";
                        claim.dbk_statement_number =
                            claim.statement_number ?? "";
                        claim.dbk_statement_date =
                            claim.statement_date ?? "";


                        // DBK END
                        claim.patient = claim.patient_id ? true : false;
                        claim.subscriber = claim.subscriber_id ? true : false;
                        claim.rendering_provider = claim.rendering_provider_id ? true : false;
                        claim.period_start = claim.period_start != "" ? moment(claim.period_start).format("MM/DD/YYYY") : "";
                        claim.period_end = claim.period_end != "" ? moment(claim.period_end).format("MM/DD/YYYY") : "";
                        claim.encounter = claim?.encounter ? claim?.encounter : "";
                        claim.claim_billed = claim?.claim_billed >= 0 && claim?.claim_billed !== '' ? parseFloat(claim?.claim_billed).toFixed(2) : '';
                        claim.claim_discount = parseFloat(claim?.claim_discount ? claim?.claim_discount : 0).toFixed(2);
                        claim.claim_allowed = parseFloat(claim?.claim_allowed ? claim?.claim_allowed : 0).toFixed(2);
                        claim.claim_deduct = parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0).toFixed(2);
                        claim.claim_coins = parseFloat(claim?.claim_coins ? claim?.claim_coins : 0).toFixed(2);
                        claim.claim_copay = parseFloat(claim?.claim_copay ? claim?.claim_copay : 0).toFixed(2);
                        claim.claim_cob = parseFloat(claim?.claim_cob ? claim?.claim_cob : 0).toFixed(2);
                        claim.claim_patres = parseFloat(claim?.claim_patres ? claim?.claim_patres : 0).toFixed(2);
                        claim.claim_paid = claim?.claim_paid >= 0 && claim?.claim_paid !== '' ? parseFloat(claim?.claim_paid).toFixed(2) : '';
                        claim.claim_balance = parseFloat(claim?.claim_balance ? claim?.claim_balance : 0).toFixed(2);
                        claim.claim_adjustment_total = parseFloat(claim?.claim_adjustment_total ? claim?.claim_adjustment_total : 0).toFixed(2);
                        claim.claim_adjustment_total_pr = parseFloat(claim?.claim_adjustment_total_pr ? claim?.claim_adjustment_total_pr : 0).toFixed(2);
                        claim.status_code = 1

                        // claim.status_code = values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "";
                        claim.mrn = claim?.mrn ? claim?.mrn : "";
                        claim.statement_number = claim?.statement_number ? claim?.statement_number : "";
                        claim.statement_date = claim?.statement_date ? claim?.statement_date : "";

                        claim.sl_billed = parseFloat(claim?.sl_billed ? claim?.sl_billed : 0).toFixed(2);
                        claim.fake_sl_patres = parseFloat(claim?.fake_sl_patres ? claim?.fake_sl_patres : 0).toFixed(2);
                        claim.adjustment_total = parseFloat(claim?.adjustment_total ? claim?.adjustment_total : 0).toFixed(2);
                        claim.sl_allowed = parseFloat(claim?.sl_allowed ? claim?.sl_allowed : 0).toFixed(2);
                        claim.sl_deduct = parseFloat(claim?.sl_deduct ? claim?.sl_deduct : 0).toFixed(2);
                        claim.sl_coins = parseFloat(claim?.sl_coins ? claim?.sl_coins : 0).toFixed(2);
                        claim.sl_copay = parseFloat(claim?.sl_copay ? claim?.sl_copay : 0).toFixed(2);
                        claim.sl_cob = parseFloat(claim?.sl_cob ? claim?.sl_cob : 0).toFixed(2);
                        claim.sl_patres = parseFloat(claim?.sl_patres ? claim?.sl_patres : 0).toFixed(2);
                        claim.sl_amount = parseFloat(claim?.sl_amount ? claim?.sl_amount : 0).toFixed(2);
                        claim.sl_paid = parseFloat(claim?.sl_paid ? claim?.sl_paid : 0).toFixed(2);
                        claim.sl_balance = parseFloat(claim?.sl_balance ? claim?.sl_balance : 0).toFixed(2);
                        claim.sl_total = parseFloat(claim?.sl_total ? claim?.sl_total : 0).toFixed(2);

                        claim?.claim_adjustments && claim?.claim_adjustments?.length > 0
                            ? claim?.claim_adjustments?.map((adjustment) => {
                                adjustment.amount = parseFloat(adjustment.amount ? adjustment.amount : 0).toFixed(2);
                            })
                            : (claim.claim_adjustments =
                                formEob.initialValues.claims[0].claim_adjustments);
                        // claim?.service_lines && claim?.service_lines?.length > 0
                        //     ? 
                        if (claim?.service_lines?.length) {
                            claim?.service_lines?.map((serviceline, index) => {
                                serviceline.serviceline_old_data_index = index;
                                serviceline.from_date = serviceline?.from_date != "" ? moment(serviceline?.from_date).format(
                                    "MM/DD/YYYY"
                                ) : "";
                                serviceline.to_date = serviceline?.to_date != "" ? moment(serviceline?.to_date).format(
                                    "MM/DD/YYYY"
                                ) : "";
                                serviceline.corrected_serviceline = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;

                                serviceline.serviceline_old_data_index = index;
                                serviceline.billed = parseFloat(serviceline?.billed ? serviceline?.billed : 0).toFixed(2);
                                serviceline.patres = parseFloat(serviceline?.patres ? serviceline?.patres : 0).toFixed(2);
                                serviceline.discount = parseFloat(serviceline?.discount ? serviceline?.discount : 0).toFixed(2);
                                serviceline.allowed = parseFloat(serviceline?.allowed ? serviceline?.allowed : 0).toFixed(2);
                                serviceline.deduct = parseFloat(serviceline?.deduct ? serviceline?.deduct : 0).toFixed(2);
                                serviceline.coins = parseFloat(serviceline?.coins ? serviceline?.coins : 0).toFixed(2);
                                serviceline.copay = parseFloat(serviceline?.copay ? serviceline?.copay : 0).toFixed(2);
                                serviceline.cob = parseFloat(serviceline?.cob ? serviceline?.cob : 0).toFixed(2);
                                serviceline.paid = parseFloat(serviceline?.paid ? serviceline?.paid : 0).toFixed(2);
                                serviceline.balance = parseFloat(serviceline?.balance ? serviceline?.balance : 0).toFixed(2);

                                serviceline?.adjustments.map((adjustment) => {
                                    adjustment.amount = parseFloat(
                                        adjustment.amount ? adjustment.amount : 0
                                    ).toFixed(2);
                                });
                            })
                        }
                        else {
                            claim.service_lines = [
                                {
                                    color_index: "",
                                    order: "",
                                    service_line_id: "",
                                    from_date: "",
                                    to_date: "",
                                    code: "",
                                    corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                    procedure_type: "HC",
                                    modifier1: "",
                                    modifier2: "",
                                    modifier3: "",
                                    modifier4: "",
                                    rev_code: "",
                                    units: "",
                                    patres: "",
                                    billed: "",
                                    discount: "",
                                    allowed: "",
                                    deduct: "",
                                    coins: "",
                                    copay: "",
                                    cob: "",
                                    paid: "",
                                    color_data: [],

                                    balance: "",
                                    adjustments: [
                                        {
                                            adjustment_id: "",
                                            pc: "",
                                            group_code: "",
                                            reason_code: "",
                                            rmk: "",
                                            amount: "",
                                        },
                                    ],
                                },
                            ];
                        }
                        if (claim?.encounter && claim?.encounter != '') {
                            localStorage.setItem('837_pulled', true)
                        }
                        // : (claim.service_lines =
                        //     formEob.initialValues.claims[0].service_lines);
                    });

                }
                else {
                    claims = [
                        {
                            patient: true,
                            subscriber: true,
                            rendering_provider: true,

                            order: "",
                            color_index: "",

                            corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                            claim_id: "",
                            patient_account_number: "",
                            payer_claim_number: "",
                            period_start: "",
                            period_end: "",
                            // status_code: values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "",
                            page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                            page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                            claim_billed: '',
                            claim_discount: parseFloat(0).toFixed(2),
                            claim_allowed: parseFloat(0).toFixed(2),
                            claim_deduct: parseFloat(0).toFixed(2),
                            claim_coins: parseFloat(0).toFixed(2),
                            claim_copay: parseFloat(0).toFixed(2),
                            claim_cob: parseFloat(0).toFixed(2),
                            claim_patres: parseFloat(0).toFixed(2),
                            claim_paid: '',
                            claim_balance: parseFloat(0).toFixed(2),
                            claim_adjustment_total: parseFloat(0).toFixed(2),
                            claim_adjustment_total_pr: parseFloat(0).toFixed(2),

                            sl_billed: parseFloat(0).toFixed(2),
                            sl_discount: parseFloat(0).toFixed(2),
                            sl_allowed: parseFloat(0).toFixed(2),
                            sl_deduct: parseFloat(0).toFixed(2),
                            sl_coins: parseFloat(0).toFixed(2),
                            sl_copay: parseFloat(0).toFixed(2),
                            sl_cob: parseFloat(0).toFixed(2),
                            sl_patres: parseFloat(0).toFixed(2),
                            sl_amount: parseFloat(0).toFixed(2),
                            sl_paid: parseFloat(0).toFixed(2),
                            sl_balance: parseFloat(0).toFixed(2),
                            sl_total: parseFloat(0).toFixed(2),
                            status_code: 1,
                            color_data: [],

                            mrn: "",
                            statement_number: "",
                            statement_date: "",

                            patient_id: "",
                            patient_first_name: "",
                            patient_middle_name: "",
                            patient_last_name: "",
                            patient_identifier_code: "",

                            subscriber_id: "",
                            subscriber_first_name: "",
                            subscriber_middle_name: "",
                            subscriber_last_name: "",
                            subscriber_identifier_code: "",

                            rendering_provider_id: "",
                            rendering_provider_first_name: "",
                            rendering_provider_last_name: "",
                            rendering_provider_identifier_code: "",

                            // DBK
                            dbk_patient_account_number: "",
                            dbk_payer_claim_number: "",

                            dbk_patient_first_name: "",
                            dbk_patient_middle_name: "",
                            dbk_patient_last_name: "",
                            dbk_patient_identifier_code: "",

                            dbk_subscriber_first_name: "",
                            dbk_subscriber_middle_name: "",
                            dbk_subscriber_last_name: "",
                            dbk_subscriber_identifier_code: "",

                            dbk_rendering_provider_first_name: "",
                            dbk_rendering_provider_last_name: "",
                            dbk_rendering_provider_identifier_code: "",

                            dbk_mrn: '',
                            dbk_statement_number: '',
                            dbk_statement_date: '',

                            claim_adjustments: [
                                {
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                    claim_adjustment_id: "",
                                },
                            ],

                            service_lines: [
                                {
                                    color_index: "",
                                    order: "",
                                    service_line_id: "",
                                    from_date: "",
                                    to_date: "",
                                    code: "",
                                    corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                    procedure_type: "HC",
                                    modifier1: "",
                                    modifier2: "",
                                    modifier3: "",
                                    modifier4: "",
                                    rev_code: "",
                                    units: "",
                                    patres: "",
                                    billed: "",
                                    discount: "",
                                    allowed: "",
                                    deduct: "",
                                    coins: "",
                                    copay: "",
                                    cob: "",
                                    paid: "",
                                    color_data: [],

                                    balance: "",
                                    adjustments: [
                                        {
                                            adjustment_id: "",
                                            pc: "",
                                            group_code: "",
                                            reason_code: "",
                                            rmk: "",
                                            amount: "",
                                        },
                                    ],
                                },
                            ],
                        },
                    ]
                }

                let remaining = 0;
                let paid_total = 0;
                let plb_total = 0;

                values?.claims?.map((claim, ind) => {
                    paid_total =
                        parseFloat(paid_total ? paid_total : 0) +
                        parseFloat(claim.claim_paid ? claim.claim_paid : 0);
                });
                let plb_adjustments = values?.plb_adjustments ? values?.plb_adjustments : [];

                if (plb_adjustments && plb_adjustments?.length > 0) {
                    plb_adjustments.map((plb, index) => {
                        plb.plb_index = index;
                        plb.plb_amt = plb.plb_amt == 0 ? parseFloat(0).toFixed(2) : plb.plb_amt > 0 || plb.plb_amt < 0 ? parseFloat(plb.plb_amt ? plb.plb_amt : 0).toFixed(2) : parseFloat(0).toFixed(2);
                    });
                }
                values?.plb_adjustments?.map((plb, ind) => {
                    plb_total =
                        parseFloat(plb_total ? plb_total : 0) +
                        parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
                });

                remaining =
                    parseFloat(values.check_amount ? values.check_amount : 0) -
                    parseFloat(paid_total ? paid_total : 0) +
                    parseFloat(plb_total ? plb_total : 0);

                if (parseFloat(remaining).toFixed(2) == -0.00) {
                    remaining = parseFloat(0).toFixed(2);
                }
                const plbOnlyCheckAmnt = payment_method === "NON" ? parseFloat(0).toFixed(2) : values?.check_amount ? parseFloat(values?.check_amount).toFixed(2) : ""
                return {
                    ...prevState,
                    payment_method: payment_method,
                    check_amount: payment_method === "NON" ? parseFloat(0).toFixed(2) : values?.check_amount ? parseFloat(values?.check_amount).toFixed(2) : "",
                    dbk_check_amount: payment_method === "NON" ? parseFloat(0).toFixed(2) : values?.check_amount ? parseFloat(values?.check_amount).toFixed(2) : "",
                    // remaining_balance: parseFloat(remaining).toFixed(2),
                    remaining_balance: formEob?.values?.grid_customization != "PLB_ONLY" ? parseFloat(remaining ? remaining : 0).toFixed(2) : getPlbOnlyRemainingBalance(plb_adjustments, plbOnlyCheckAmnt),

                    check_number: check_number,
                    dbk_check_number: check_number,
                    qdx_tranansaction_type: "PATIENT_PAY",
                    job: jobData?.job,
                    client_id: jobData?.client_id,
                    is_first_sub_job: isFirstJob,
                    payment_id:
                        values?.payment_id && values?.payment_id ? values?.payment_id : "",
                    data_837: values?.data_837 ? values?.data_837 : [],
                    transaction_type:
                        values?.transaction_type && values?.transaction_type
                            ? values?.transaction_type
                            : "",


                    check_date: isSubJob && !isFirstJob ? values?.check_date :
                        values?.check_date && values?.check_date ? values?.check_date : "",
                    check_page_number:
                        values?.check_page_number && values?.check_page_number
                            ? values?.check_page_number
                            : "",
                    correspondance:
                        values?.correspondance && values?.correspondance ? values?.correspondance : "",

                    payee_id:
                        values?.payee_id && values?.payee_id != "" ? values?.payee_id : "",
                    payee_name:
                        values?.payee_name && values?.payee_name != ""
                            ? values?.payee_name
                            : "",
                    payee_npi:
                        values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                    payee_address:
                        values?.payee_address && values?.payee_address != ""
                            ? values?.payee_address
                            : "",
                    payee_city:
                        values?.payee_city && values?.payee_city != ""
                            ? values?.payee_city
                            : "",
                    payee_state:
                        values?.payee_state && values?.payee_state != ""
                            ? values?.payee_state
                            : "",
                    payee_zip:
                        values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                    payer_page_from:
                        values?.payer_page_from && values?.payer_page_from != "" ? values?.payer_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payer_page_to:
                        values?.payer_page_to && values?.payer_page_to != "" ? values?.payer_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                    payer_id:
                        values?.payer_id && values?.payer_id != "" ? values?.payer_id : "",
                    payer_name:
                        values?.payer_name && values?.payer_name != ""
                            ? values?.payer_name
                            : "",
                    payer_address:
                        values?.payer_address && values?.payer_address != ""
                            ? values?.payer_address
                            : "",
                    payer_city:
                        values?.payer_city && values?.payer_city != ""
                            ? values?.payer_city
                            : "",
                    payer_state:
                        values?.payer_state && values?.payer_state != ""
                            ? values?.payer_state
                            : "",
                    payer_zip:
                        values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",
                    payee_page_from:
                        values?.payee_page_from && values?.payee_page_from != "" ? values?.payee_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payee_page_to:
                        values?.payee_page_to && values?.payee_page_to != "" ? values?.payee_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                    // DBK
                    // dbk_check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                    //     ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",
                    dbk_check_date: isSubJob && !isFirstJob ? values?.check_date :
                        values?.check_date && values?.check_date ? values?.check_date : "",
                    // dbk_check_amount: isSubJob && !isFirstJob ? values?.check_amount :
                    //     values?.check_amount == 0
                    //         ? parseFloat(0).toFixed(2)
                    //         : parseFloat(values?.check_amount)
                    //             ? parseFloat(values?.check_amount).toFixed(2)
                    //             : "",
                    dbk_payee_name:
                        values?.payee_name && values?.payee_name != ""
                            ? values?.payee_name
                            : "",
                    dbk_payee_npi:
                        values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                    dbk_payee_address:
                        values?.payee_address && values?.payee_address != ""
                            ? values?.payee_address
                            : "",
                    dbk_payee_city:
                        values?.payee_city && values?.payee_city != ""
                            ? values?.payee_city
                            : "",
                    dbk_payee_state:
                        values?.payee_state && values?.payee_state != ""
                            ? values?.payee_state
                            : "",
                    dbk_payee_zip:
                        values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                    dbk_payer_name:
                        values?.payer_name && values?.payer_name != ""
                            ? values?.payer_name
                            : "",
                    dbk_payer_address:
                        values?.payer_address && values?.payer_address != ""
                            ? values?.payer_address
                            : "",
                    dbk_payer_city:
                        values?.payer_city && values?.payer_city != ""
                            ? values?.payer_city
                            : "",
                    dbk_payer_state:
                        values?.payer_state && values?.payer_state != ""
                            ? values?.payer_state
                            : "",
                    dbk_payer_zip:
                        values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",

                    // qdx_tranansaction_type: values?.qdx_tranansaction_type ? values?.qdx_tranansaction_type : "PAYMENT835",
                    qdx_status: values?.qdx_status ? values?.qdx_status : "",
                    // qdx_reason: values?.qdx_reason ? values?.qdx_reason : "",
                    qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                    qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",

                    plb_adjustments: plb_adjustments,

                    claims: claims,

                }
            });
        }
        else {
            var claims = []
            if (jobData?.grid_data?.claims?.length > 0) {
                claims = jobData?.grid_data?.claims;

                claims.map((claim, index) => {
                    // DBK START
                    claim.corrected_claim = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;
                    claim.claim_corrected_index = index;
                    claim.page_from = claim && claim?.page_from ? claim && claim?.page_from : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.page_to = claim && claim?.page_to ? claim && claim?.page_to : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.dbk_patient_account_number = claim.patient_account_number ?? "";
                    claim.dbk_payer_claim_number = claim.payer_claim_number ?? "";
                    claim.payer_claim_number = claim.payer_claim_number ?? "";

                    claim.dbk_patient_first_name = claim.patient_first_name ?? "";
                    claim.dbk_patient_middle_name = claim.patient_middle_name ?? "";
                    claim.dbk_patient_last_name = claim.patient_last_name ?? "";
                    claim.dbk_patient_identifier_code = claim.patient_identifier_code ?? "";
                    claim.status_code = 1
                    claim.dbk_subscriber_first_name = claim.subscriber_first_name ?? "";
                    claim.dbk_subscriber_middle_name = claim.subscriber_middle_name ?? "";
                    claim.dbk_subscriber_last_name = claim.subscriber_last_name ?? "";
                    claim.dbk_subscriber_identifier_code =
                        claim.subscriber_identifier_code ?? claim.subscriber_identifier_code;

                    claim.dbk_rendering_provider_first_name =
                        claim.rendering_provider_first_name ?? "";
                    claim.dbk_rendering_provider_last_name =
                        claim.rendering_provider_last_name ?? "";
                    claim.dbk_rendering_provider_identifier_code =
                        claim.rendering_provider_identifier_code ?? "";

                    claim.dbk_mrn =
                        claim.mrn ?? "";
                    claim.dbk_statement_number =
                        claim.statement_number ?? "";
                    claim.dbk_statement_date =
                        claim.statement_date ?? "";


                    // DBK END
                    claim.patient = claim.patient_id ? true : false;
                    claim.subscriber = claim.subscriber_id ? true : false;
                    claim.rendering_provider = claim.rendering_provider_id ? true : false;
                    claim.period_start = claim.period_start != "" ? moment(claim.period_start).format("MM/DD/YYYY") : "";
                    claim.period_end = claim.period_end != "" ? moment(claim.period_end).format("MM/DD/YYYY") : "";
                    claim.encounter = claim?.encounter ? claim?.encounter : "";
                    claim.claim_billed = claim?.claim_billed >= 0 && claim?.claim_billed !== '' ? parseFloat(claim?.claim_billed).toFixed(2) : '';
                    claim.claim_discount = parseFloat(claim?.claim_discount ? claim?.claim_discount : 0).toFixed(2);
                    claim.claim_allowed = parseFloat(claim?.claim_allowed ? claim?.claim_allowed : 0).toFixed(2);
                    claim.claim_deduct = parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0).toFixed(2);
                    claim.claim_coins = parseFloat(claim?.claim_coins ? claim?.claim_coins : 0).toFixed(2);
                    claim.claim_copay = parseFloat(claim?.claim_copay ? claim?.claim_copay : 0).toFixed(2);
                    claim.claim_cob = parseFloat(claim?.claim_cob ? claim?.claim_cob : 0).toFixed(2);
                    claim.claim_patres = parseFloat(claim?.claim_patres ? claim?.claim_patres : 0).toFixed(2);
                    claim.claim_paid = claim_paid >= 0 && claim?.claim_paid !== '' ? parseFloat(claim?.claim_paid).toFixed(2) : '';
                    claim.claim_balance = parseFloat(claim?.claim_balance ? claim?.claim_balance : 0).toFixed(2);
                    claim.claim_adjustment_total = parseFloat(claim?.claim_adjustment_total ? claim?.claim_adjustment_total : 0).toFixed(2);
                    claim.claim_adjustment_total_pr = parseFloat(claim?.claim_adjustment_total_pr ? claim?.claim_adjustment_total_pr : 0).toFixed(2);
                    // claim.status_code = values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "";
                    claim.mrn = claim?.mrn ? claim?.mrn : "";
                    claim.statement_number = claim?.statement_number ? claim?.statement_number : "";
                    claim.statement_date = claim?.statement_date ? claim?.statement_date : "";

                    claim.sl_billed = parseFloat(claim?.sl_billed ? claim?.sl_billed : 0).toFixed(2);
                    claim.fake_sl_patres = parseFloat(claim?.fake_sl_patres ? claim?.fake_sl_patres : 0).toFixed(2);
                    claim.adjustment_total = parseFloat(claim?.adjustment_total ? claim?.adjustment_total : 0).toFixed(2);
                    claim.sl_allowed = parseFloat(claim?.sl_allowed ? claim?.sl_allowed : 0).toFixed(2);
                    claim.sl_deduct = parseFloat(claim?.sl_deduct ? claim?.sl_deduct : 0).toFixed(2);
                    claim.sl_coins = parseFloat(claim?.sl_coins ? claim?.sl_coins : 0).toFixed(2);
                    claim.sl_copay = parseFloat(claim?.sl_copay ? claim?.sl_copay : 0).toFixed(2);
                    claim.sl_cob = parseFloat(claim?.sl_cob ? claim?.sl_cob : 0).toFixed(2);
                    claim.sl_patres = parseFloat(claim?.sl_patres ? claim?.sl_patres : 0).toFixed(2);
                    claim.sl_amount = parseFloat(claim?.sl_amount ? claim?.sl_amount : 0).toFixed(2);
                    claim.sl_paid = parseFloat(claim?.sl_paid ? claim?.sl_paid : 0).toFixed(2);
                    claim.sl_balance = parseFloat(claim?.sl_balance ? claim?.sl_balance : 0).toFixed(2);
                    claim.sl_total = parseFloat(claim?.sl_total ? claim?.sl_total : 0).toFixed(2);

                    claim?.claim_adjustments && claim?.claim_adjustments?.length > 0
                        ? claim?.claim_adjustments?.map((adjustment) => {
                            adjustment.amount = parseFloat(adjustment.amount ? adjustment.amount : 0).toFixed(2);
                        })
                        : (claim.claim_adjustments =
                            formEob.initialValues.claims[0].claim_adjustments);
                    // claim?.service_lines && claim?.service_lines?.length > 0
                    //     ? 
                    claim?.service_lines.map((serviceline, index) => {
                        serviceline.serviceline_old_data_index = index;
                        serviceline.from_date = serviceline?.from_date != "" ? moment(serviceline?.from_date).format(
                            "MM/DD/YYYY"
                        ) : "";
                        serviceline.to_date = serviceline?.to_date != "" ? moment(serviceline?.to_date).format(
                            "MM/DD/YYYY"
                        ) : "";
                        serviceline.corrected_serviceline = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;

                        serviceline.serviceline_old_data_index = index;
                        serviceline.billed = parseFloat(serviceline?.billed ? serviceline?.billed : 0).toFixed(2);
                        serviceline.patres = parseFloat(serviceline?.patres ? serviceline?.patres : 0).toFixed(2);
                        serviceline.discount = parseFloat(serviceline?.discount ? serviceline?.discount : 0).toFixed(2);
                        serviceline.allowed = parseFloat(serviceline?.allowed ? serviceline?.allowed : 0).toFixed(2);
                        serviceline.deduct = parseFloat(serviceline?.deduct ? serviceline?.deduct : 0).toFixed(2);
                        serviceline.coins = parseFloat(serviceline?.coins ? serviceline?.coins : 0).toFixed(2);
                        serviceline.copay = parseFloat(serviceline?.copay ? serviceline?.copay : 0).toFixed(2);
                        serviceline.cob = parseFloat(serviceline?.cob ? serviceline?.cob : 0).toFixed(2);
                        serviceline.paid = parseFloat(serviceline?.paid ? serviceline?.paid : 0).toFixed(2);
                        serviceline.balance = parseFloat(serviceline?.balance ? serviceline?.balance : 0).toFixed(2);

                        serviceline?.adjustments.map((adjustment) => {
                            adjustment.amount = parseFloat(
                                adjustment.amount ? adjustment.amount : 0
                            ).toFixed(2);
                        });
                    })
                    if (claim?.encounter && claim?.encounter != '') {
                        localStorage.setItem('837_pulled', true)
                    }
                    // : (claim.service_lines =
                    //     formEob.initialValues.claims[0].service_lines);
                });

            }
            else {

                claims = [
                    {
                        patient: true,
                        subscriber: true,
                        rendering_provider: true,

                        order: "",
                        color_index: "",

                        corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                        claim_id: "",
                        patient_account_number: "",
                        payer_claim_number: "",
                        period_start: "",
                        period_end: "",
                        // status_code: values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "",
                        page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                        page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                        claim_billed: '',
                        claim_discount: parseFloat(0).toFixed(2),
                        claim_allowed: parseFloat(0).toFixed(2),
                        claim_deduct: parseFloat(0).toFixed(2),
                        claim_coins: parseFloat(0).toFixed(2),
                        claim_copay: parseFloat(0).toFixed(2),
                        claim_cob: parseFloat(0).toFixed(2),
                        claim_patres: parseFloat(0).toFixed(2),
                        claim_paid: '',
                        claim_balance: parseFloat(0).toFixed(2),
                        claim_adjustment_total: parseFloat(0).toFixed(2),
                        claim_adjustment_total_pr: parseFloat(0).toFixed(2),

                        sl_billed: parseFloat(0).toFixed(2),
                        sl_discount: parseFloat(0).toFixed(2),
                        sl_allowed: parseFloat(0).toFixed(2),
                        sl_deduct: parseFloat(0).toFixed(2),
                        sl_coins: parseFloat(0).toFixed(2),
                        sl_copay: parseFloat(0).toFixed(2),
                        sl_cob: parseFloat(0).toFixed(2),
                        sl_patres: parseFloat(0).toFixed(2),
                        sl_amount: parseFloat(0).toFixed(2),
                        sl_paid: parseFloat(0).toFixed(2),
                        sl_balance: parseFloat(0).toFixed(2),
                        sl_total: parseFloat(0).toFixed(2),
                        status_code: 1,
                        color_data: [],

                        mrn: "",
                        statement_number: "",
                        statement_date: "",

                        patient_id: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",
                        patient_identifier_code: "",

                        subscriber_id: "",
                        subscriber_first_name: "",
                        subscriber_middle_name: "",
                        subscriber_last_name: "",
                        subscriber_identifier_code: "",

                        rendering_provider_id: "",
                        rendering_provider_first_name: "",
                        rendering_provider_last_name: "",
                        rendering_provider_identifier_code: "",

                        // DBK
                        dbk_patient_account_number: "",
                        dbk_payer_claim_number: "",

                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                        dbk_patient_identifier_code: "",

                        dbk_subscriber_first_name: "",
                        dbk_subscriber_middle_name: "",
                        dbk_subscriber_last_name: "",
                        dbk_subscriber_identifier_code: "",

                        dbk_rendering_provider_first_name: "",
                        dbk_rendering_provider_last_name: "",
                        dbk_rendering_provider_identifier_code: "",

                        dbk_mrn: '',
                        dbk_statement_number: '',
                        dbk_statement_date: '',

                        claim_adjustments: [
                            {
                                pc: "",
                                group_code: "",
                                reason_code: "",
                                rmk: "",
                                amount: "",
                                claim_adjustment_id: "",
                            },
                        ],

                        service_lines: [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ],
                    },
                ]

            }
            const plbOnlyCheckAmnt = isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_amount :
                values?.check_amount == 0
                    ? parseFloat(0).toFixed(2)
                    : parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                        ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                        : ""

            await formEob.setValues((prevState) => ({
                ...prevState,
                qdx_tranansaction_type: "PATIENT_PAY",
                job: jobData?.job,
                client_id: jobData?.client_id,
                is_first_sub_job: isFirstJob,
                payment_id:
                    values?.payment_id && values?.payment_id ? values?.payment_id : "",
                data_837: values?.data_837 ? values?.data_837 : [],
                transaction_type:
                    values?.transaction_type && values?.transaction_type
                        ? values?.transaction_type
                        : "",

                payment_method: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.payment_method : values?.payment_method && values?.payment_method != "" ? values?.payment_method :
                    values?.check_amount && values?.check_amount > 0 ? "CHK" : values?.check_amount == 0 || values?.check_amount == "" && values?.qdx_tranansaction_type == "PAYMENT835" ? "NON" : values?.check_amount == 0 && values?.qdx_tranansaction_type == "CORRESPONDENCE" ? "COR" : "",
                // payment_method: values?.payment_method,
                check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                    ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",

                check_date: isSubJob && !isFirstJob ? values?.check_date :
                    values?.check_date && values?.check_date ? values?.check_date : "",
                check_amount: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_amount :
                    values?.check_amount == 0
                        ? parseFloat(0).toFixed(2)
                        : parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                            ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                            : "",
                // remaining_balance: parseFloat(remaining ? remaining : 0).toFixed(2),
                remaining_balance: formEob?.values?.grid_customization != "PLB_ONLY" ? parseFloat(remaining ? remaining : 0).toFixed(2) : getPlbOnlyRemainingBalance(plb_adjustments, plbOnlyCheckAmnt),

                check_page_number:
                    values?.check_page_number && values?.check_page_number
                        ? values?.check_page_number
                        : "",
                correspondance:
                    values?.correspondance && values?.correspondance ? values?.correspondance : "",

                payee_id:
                    values?.payee_id && values?.payee_id != "" ? values?.payee_id : "",
                payee_name:
                    values?.payee_name && values?.payee_name != ""
                        ? values?.payee_name
                        : "",
                payee_npi:
                    values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                payee_address:
                    values?.payee_address && values?.payee_address != ""
                        ? values?.payee_address
                        : "",
                payee_city:
                    values?.payee_city && values?.payee_city != ""
                        ? values?.payee_city
                        : "",
                payee_state:
                    values?.payee_state && values?.payee_state != ""
                        ? values?.payee_state
                        : "",
                payee_zip:
                    values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                payer_page_from:
                    values?.payer_page_from && values?.payer_page_from != "" ? values?.payer_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                payer_page_to:
                    values?.payer_page_to && values?.payer_page_to != "" ? values?.payer_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                payer_id:
                    values?.payer_id && values?.payer_id != "" ? values?.payer_id : "",
                payer_name:
                    values?.payer_name && values?.payer_name != ""
                        ? values?.payer_name
                        : "",
                payer_address:
                    values?.payer_address && values?.payer_address != ""
                        ? values?.payer_address
                        : "",
                payer_city:
                    values?.payer_city && values?.payer_city != ""
                        ? values?.payer_city
                        : "",
                payer_state:
                    values?.payer_state && values?.payer_state != ""
                        ? values?.payer_state
                        : "",
                payer_zip:
                    values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",
                payee_page_from:
                    values?.payee_page_from && values?.payee_page_from != "" ? values?.payee_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                payee_page_to:
                    values?.payee_page_to && values?.payee_page_to != "" ? values?.payee_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                // DBK
                dbk_check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                    ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",
                dbk_check_date: isSubJob && !isFirstJob ? values?.check_date :
                    values?.check_date && values?.check_date ? values?.check_date : "",
                dbk_check_amount: isSubJob && !isFirstJob ? values?.check_amount :
                    values?.check_amount == 0
                        ? parseFloat(0).toFixed(2)
                        : parseFloat(values?.check_amount)
                            ? parseFloat(values?.check_amount).toFixed(2)
                            : "",
                dbk_payee_name:
                    values?.payee_name && values?.payee_name != ""
                        ? values?.payee_name
                        : "",
                dbk_payee_npi:
                    values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                dbk_payee_address:
                    values?.payee_address && values?.payee_address != ""
                        ? values?.payee_address
                        : "",
                dbk_payee_city:
                    values?.payee_city && values?.payee_city != ""
                        ? values?.payee_city
                        : "",
                dbk_payee_state:
                    values?.payee_state && values?.payee_state != ""
                        ? values?.payee_state
                        : "",
                dbk_payee_zip:
                    values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                dbk_payer_name:
                    values?.payer_name && values?.payer_name != ""
                        ? values?.payer_name
                        : "",
                dbk_payer_address:
                    values?.payer_address && values?.payer_address != ""
                        ? values?.payer_address
                        : "",
                dbk_payer_city:
                    values?.payer_city && values?.payer_city != ""
                        ? values?.payer_city
                        : "",
                dbk_payer_state:
                    values?.payer_state && values?.payer_state != ""
                        ? values?.payer_state
                        : "",
                dbk_payer_zip:
                    values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",

                // qdx_tranansaction_type: values?.qdx_tranansaction_type ? values?.qdx_tranansaction_type : "PAYMENT835",
                qdx_status: values?.qdx_status ? values?.qdx_status : "",
                // qdx_reason: values?.qdx_reason ? values?.qdx_reason : "",
                qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",

                plb_adjustments: plb_adjustments,

                claims: claims,
            }));

        }
        clearServiceLines();
        await aditionalInfoFormik.setValues({
            ...aditionalInfoFormik.values,
            status: values?.qdx_status ? values?.qdx_status : "",
            // reason: values?.qdx_reason ? values?.qdx_reason : "",
            reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
            document_classification: values?.qdx_classification ? values?.qdx_classification : "",
            is_forced_balancing: values?.is_forced_balancing ? values?.is_forced_balancing : false,
            is_missing_check_image: values?.is_missing_check_image ? values?.is_missing_check_image : false,
            is_orphaned_check: values?.is_orphaned_check ? values?.is_orphaned_check : false,
            is_payer_black_listing: values?.is_payer_black_listing ? values?.is_payer_black_listing : false,
            is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? values?.is_payer_excluding_indexing : false,
        })

        setTimeout(() => {
            formEob.validateForm()
        }, 150);

    }

    const handleQdxPayment835 = async () => {
        setClaim835Index(0)
        setQdxTransaction('PAYMENT835');
        setControlledPosition({ x: 0, y: 580 });
        const _ = require('lodash');
        const values = _.cloneDeep(jobData?.grid_data);
        if (formEob.values?.grid_customization == "PLB_ONLY") {
            setControlledPosition({ x: 0, y: 770 });
        }
        if (!isSubJob || isSubJob && isFirstJob) {
            let payment_method = "";
            let check_amount = "";
            let check_number = "";

            if (jobData?.grid_data?.payment_method) {

                payment_method = jobData?.grid_data?.payment_method

                if (payment_method == "COR") {

                    payment_method = jobData?.grid_data?.payment_method && jobData?.grid_data?.payment_method != "COR" ? jobData?.grid_data?.payment_method : "NON"
                    // if (payment_method == "NON") {
                    //     check_amount = "0.00"
                    // }
                    if (isCheckNumber && window.location.pathname == DATA_ENTRY) {
                        let num = Math.floor(Math.random() * 10000);
                        // Convert the number to a 4-digit string with leading zeros if necessary
                        num = num.toString().padStart(4, '0');
                        if (isGeneratedCheckNumber) {

                            check_number = "RX" + jobData?.grid_data?.timezone
                        }
                    }
                    else {
                        check_number = jobData?.grid_data?.check_number ? jobData?.grid_data?.check_number : ""
                    }
                }

                else {
                    if (jobData?.grid_data?.check_amount && parseFloat(jobData?.grid_data?.check_amount) > parseFloat(0)) {
                        check_number = jobData?.grid_data?.check_number ? jobData?.grid_data?.check_number : ""
                        payment_method = jobData?.grid_data?.payment_method ? jobData?.grid_data?.payment_method : "CHK"
                    }

                    else if (jobData?.grid_data?.check_amount && parseFloat(jobData?.grid_data?.check_amount) == parseFloat(0)) {

                        payment_method = "NON"

                        if (isCheckNumber && window.location.pathname == DATA_ENTRY) {
                            let num = Math.floor(Math.random() * 10000);
                            // Convert the number to a 4-digit string with leading zeros if necessary
                            num = num.toString().padStart(4, '0');
                            if (isGeneratedCheckNumber) {
                                check_number = "RX" + jobData?.grid_data?.timezone
                            }
                        }
                        else {
                            check_number = jobData?.grid_data?.check_number ? jobData?.grid_data?.check_number : ""
                        }
                    }
                    else {
                        payment_method = jobData?.grid_data?.payment_method ? jobData?.grid_data?.payment_method : "ACH"

                        if (isCheckNumber && window.location.pathname == DATA_ENTRY) {
                            let num = Math.floor(Math.random() * 10000);
                            // Convert the number to a 4-digit string with leading zeros if necessary
                            num = num.toString().padStart(4, '0');
                            if (isGeneratedCheckNumber) {
                                check_number = "RM" + jobData?.grid_data?.timezone

                            }
                        }
                        else {
                            check_number = jobData?.grid_data?.check_number ? jobData?.grid_data?.check_number : ""
                        }
                    }
                }
            }
            else {
                payment_method = jobData?.grid_data?.payment_method ? jobData?.grid_data?.payment_method : "CHK"

                if (isCheckNumber && window.location.pathname == DATA_ENTRY) {
                    let num = Math.floor(Math.random() * 10000);
                    // Convert the number to a 4-digit string with leading zeros if necessary
                    num = num.toString().padStart(4, '0');
                    if (isGeneratedCheckNumber) {
                        check_number = "RM" + jobData?.grid_data?.timezone

                    }
                }
                else {
                    check_number = jobData?.grid_data?.check_number ? jobData?.grid_data?.check_number : ""
                }
            }

            var claims = []
            if (jobData?.grid_data?.transaction_type == "PAYMENT835" && jobData?.grid_data?.claims?.length > 0) {
                claims = values?.claims;

                claims.map((claim, index) => {
                    // DBK START
                    claim.corrected_claim = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;
                    claim.claim_corrected_index = index;
                    claim.page_from = claim && claim?.page_from ? claim && claim?.page_from : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.page_to = claim && claim?.page_to ? claim && claim?.page_to : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.dbk_patient_account_number = claim.patient_account_number ?? "";
                    claim.dbk_payer_claim_number = claim.payer_claim_number ?? "";
                    claim.payer_claim_number = claim.payer_claim_number ?? "";

                    claim.dbk_patient_first_name = claim.patient_first_name ?? "";
                    claim.dbk_patient_middle_name = claim.patient_middle_name ?? "";
                    claim.dbk_patient_last_name = claim.patient_last_name ?? "";
                    claim.dbk_patient_identifier_code = claim.patient_identifier_code ?? "";

                    claim.dbk_subscriber_first_name = claim.subscriber_first_name ?? "";
                    claim.dbk_subscriber_middle_name = claim.subscriber_middle_name ?? "";
                    claim.dbk_subscriber_last_name = claim.subscriber_last_name ?? "";
                    claim.dbk_subscriber_identifier_code =
                        claim.subscriber_identifier_code ?? "";

                    claim.dbk_rendering_provider_first_name =
                        claim.rendering_provider_first_name ?? "";
                    claim.dbk_rendering_provider_last_name =
                        claim.rendering_provider_last_name ?? "";
                    claim.dbk_rendering_provider_identifier_code =
                        claim.rendering_provider_identifier_code ?? "";

                    claim.dbk_mrn =
                        claim.mrn ? claim.mrn : "";
                    claim.dbk_statement_number =
                        claim.statement_number ?? "";
                    claim.dbk_statement_date =
                        claim.statement_date ?? "";


                    // DBK END
                    claim.patient = claim.patient_id ? true : false;
                    claim.subscriber = claim.subscriber_id ? true : false;
                    claim.rendering_provider = claim.rendering_provider_id ? true : false;
                    claim.period_start = claim.period_start != "" ? moment(claim.period_start).format("MM/DD/YYYY") : "";
                    claim.period_end = claim.period_end != "" ? moment(claim.period_end).format("MM/DD/YYYY") : "";
                    claim.encounter = claim?.encounter ? claim?.encounter : "";
                    claim.claim_billed = parseFloat(claim?.claim_billed ? claim?.claim_billed : 0).toFixed(2);
                    claim.claim_discount = parseFloat(claim?.claim_discount ? claim?.claim_discount : 0).toFixed(2);
                    claim.claim_allowed = parseFloat(claim?.claim_allowed ? claim?.claim_allowed : 0).toFixed(2);
                    claim.claim_deduct = parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0).toFixed(2);
                    claim.claim_coins = parseFloat(claim?.claim_coins ? claim?.claim_coins : 0).toFixed(2);
                    claim.claim_copay = parseFloat(claim?.claim_copay ? claim?.claim_copay : 0).toFixed(2);
                    claim.claim_cob = parseFloat(claim?.claim_cob ? claim?.claim_cob : 0).toFixed(2);
                    claim.claim_patres = parseFloat(claim?.claim_patres ? claim?.claim_patres : 0).toFixed(2);
                    claim.claim_paid = parseFloat(claim?.claim_paid ? claim?.claim_paid : 0).toFixed(2);
                    claim.claim_balance = parseFloat(claim?.claim_balance ? claim?.claim_balance : 0).toFixed(2);
                    claim.claim_adjustment_total = parseFloat(claim?.claim_adjustment_total ? claim?.claim_adjustment_total : 0).toFixed(2);
                    claim.claim_adjustment_total_pr = parseFloat(claim?.claim_adjustment_total_pr ? claim?.claim_adjustment_total_pr : 0).toFixed(2);

                    // claim.status_code = values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "";
                    claim.mrn = claim?.mrn ? claim?.mrn : "";
                    claim.statement_number = claim?.statement_number ? claim?.statement_number : "";
                    claim.statement_date = claim?.statement_date ? claim?.statement_date : "";

                    claim.sl_billed = parseFloat(claim?.sl_billed ? claim?.sl_billed : 0).toFixed(2);
                    claim.fake_sl_patres = parseFloat(claim?.fake_sl_patres ? claim?.fake_sl_patres : 0).toFixed(2);
                    claim.adjustment_total = parseFloat(claim?.adjustment_total ? claim?.adjustment_total : 0).toFixed(2);
                    claim.sl_allowed = parseFloat(claim?.sl_allowed ? claim?.sl_allowed : 0).toFixed(2);
                    claim.sl_deduct = parseFloat(claim?.sl_deduct ? claim?.sl_deduct : 0).toFixed(2);
                    claim.sl_coins = parseFloat(claim?.sl_coins ? claim?.sl_coins : 0).toFixed(2);
                    claim.sl_copay = parseFloat(claim?.sl_copay ? claim?.sl_copay : 0).toFixed(2);
                    claim.sl_cob = parseFloat(claim?.sl_cob ? claim?.sl_cob : 0).toFixed(2);
                    claim.sl_patres = parseFloat(claim?.sl_patres ? claim?.sl_patres : 0).toFixed(2);
                    claim.sl_amount = parseFloat(claim?.sl_amount ? claim?.sl_amount : 0).toFixed(2);
                    claim.sl_paid = parseFloat(claim?.sl_paid ? claim?.sl_paid : 0).toFixed(2);
                    claim.sl_balance = parseFloat(claim?.sl_balance ? claim?.sl_balance : 0).toFixed(2);
                    claim.sl_total = parseFloat(claim?.sl_total ? claim?.sl_total : 0).toFixed(2);

                    claim?.claim_adjustments && claim?.claim_adjustments?.length > 0
                        ? claim?.claim_adjustments?.map((adjustment) => {
                            adjustment.amount = parseFloat(adjustment.amount ? adjustment.amount : 0).toFixed(2);
                        })
                        : (claim.claim_adjustments =
                            formEob.initialValues.claims[0].claim_adjustments);
                    // claim?.service_lines && claim?.service_lines?.length > 0
                    //     ? 
                    if (claim?.service_lines?.length > 0) {
                        claim?.service_lines?.map((serviceline, index) => {
                            serviceline.serviceline_old_data_index = index;
                            serviceline.from_date = serviceline?.from_date != "" ? moment(serviceline?.from_date).format(
                                "MM/DD/YYYY"
                            ) : "";
                            serviceline.to_date = serviceline?.to_date != "" ? moment(serviceline?.to_date).format(
                                "MM/DD/YYYY"
                            ) : "";
                            serviceline.corrected_serviceline = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;

                            serviceline.serviceline_old_data_index = index;
                            serviceline.billed = parseFloat(serviceline?.billed ? serviceline?.billed : 0).toFixed(2);
                            serviceline.patres = parseFloat(serviceline?.patres ? serviceline?.patres : 0).toFixed(2);
                            serviceline.discount = parseFloat(serviceline?.discount ? serviceline?.discount : 0).toFixed(2);
                            serviceline.allowed = parseFloat(serviceline?.allowed ? serviceline?.allowed : 0).toFixed(2);
                            serviceline.deduct = parseFloat(serviceline?.deduct ? serviceline?.deduct : 0).toFixed(2);
                            serviceline.coins = parseFloat(serviceline?.coins ? serviceline?.coins : 0).toFixed(2);
                            serviceline.copay = parseFloat(serviceline?.copay ? serviceline?.copay : 0).toFixed(2);
                            serviceline.cob = parseFloat(serviceline?.cob ? serviceline?.cob : 0).toFixed(2);
                            serviceline.paid = parseFloat(serviceline?.paid ? serviceline?.paid : 0).toFixed(2);
                            serviceline.balance = parseFloat(serviceline?.balance ? serviceline?.balance : 0).toFixed(2);

                            serviceline?.adjustments.map((adjustment) => {
                                adjustment.amount = parseFloat(
                                    adjustment.amount ? adjustment.amount : 0
                                ).toFixed(2);
                            });
                        })
                    }
                    else {
                        claim.service_lines = [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ];
                    }
                    if (claim?.encounter && claim?.encounter != '') {
                        localStorage.setItem('837_pulled', true)
                    }
                    // : (claim.service_lines =
                    //     formEob.initialValues.claims[0].service_lines);
                });

            }
            else {

                claims = [
                    {
                        patient: true,
                        subscriber: true,
                        rendering_provider: true,

                        order: "",
                        color_index: "",

                        corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                        claim_id: "",
                        patient_account_number: "",
                        payer_claim_number: "",
                        period_start: "",
                        period_end: "",
                        status_code: values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "",
                        page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                        page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                        claim_billed: parseFloat(0).toFixed(2),
                        claim_discount: parseFloat(0).toFixed(2),
                        claim_allowed: parseFloat(0).toFixed(2),
                        claim_deduct: parseFloat(0).toFixed(2),
                        claim_coins: parseFloat(0).toFixed(2),
                        claim_copay: parseFloat(0).toFixed(2),
                        claim_cob: parseFloat(0).toFixed(2),
                        claim_patres: parseFloat(0).toFixed(2),
                        claim_paid: parseFloat(0).toFixed(2),
                        claim_balance: parseFloat(0).toFixed(2),
                        claim_adjustment_total: parseFloat(0).toFixed(2),
                        claim_adjustment_total_pr: parseFloat(0).toFixed(2),

                        sl_billed: parseFloat(0).toFixed(2),
                        sl_discount: parseFloat(0).toFixed(2),
                        sl_allowed: parseFloat(0).toFixed(2),
                        sl_deduct: parseFloat(0).toFixed(2),
                        sl_coins: parseFloat(0).toFixed(2),
                        sl_copay: parseFloat(0).toFixed(2),
                        sl_cob: parseFloat(0).toFixed(2),
                        sl_patres: parseFloat(0).toFixed(2),
                        sl_amount: parseFloat(0).toFixed(2),
                        sl_paid: parseFloat(0).toFixed(2),
                        sl_balance: parseFloat(0).toFixed(2),
                        sl_total: parseFloat(0).toFixed(2),

                        color_data: [],

                        mrn: "",
                        statement_number: "",
                        statement_date: "",

                        patient_id: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",
                        patient_identifier_code: "",

                        subscriber_id: "",
                        subscriber_first_name: "",
                        subscriber_middle_name: "",
                        subscriber_last_name: "",
                        subscriber_identifier_code: "",

                        rendering_provider_id: "",
                        rendering_provider_first_name: "",
                        rendering_provider_last_name: "",
                        rendering_provider_identifier_code: "",

                        // DBK
                        dbk_patient_account_number: "",
                        dbk_payer_claim_number: "",

                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                        dbk_patient_identifier_code: "",

                        dbk_subscriber_first_name: "",
                        dbk_subscriber_middle_name: "",
                        dbk_subscriber_last_name: "",
                        dbk_subscriber_identifier_code: "",

                        dbk_rendering_provider_first_name: "",
                        dbk_rendering_provider_last_name: "",
                        dbk_rendering_provider_identifier_code: "",

                        dbk_mrn: '',
                        dbk_statement_number: '',
                        dbk_statement_date: '',

                        claim_adjustments: [
                            {
                                pc: "",
                                group_code: "",
                                reason_code: "",
                                rmk: "",
                                amount: "",
                                claim_adjustment_id: "",
                            },
                        ],

                        service_lines: [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ],
                    },
                ]

            }

            let remaining = 0;
            let paid_total = 0;
            let plb_total = 0;

            values?.claims?.map((claim, ind) => {
                paid_total =
                    parseFloat(paid_total ? paid_total : 0) +
                    parseFloat(claim.claim_paid ? claim.claim_paid : 0);
            });
            let plb_adjustments = values?.plb_adjustments ? values?.plb_adjustments : [];

            if (plb_adjustments && plb_adjustments?.length > 0) {
                plb_adjustments.map((plb, index) => {
                    plb.plb_index = index;
                    plb.plb_amt = plb.plb_amt == 0 ? parseFloat(0).toFixed(2) : plb.plb_amt > 0 || plb.plb_amt < 0 ? parseFloat(plb.plb_amt ? plb.plb_amt : 0).toFixed(2) : parseFloat(0).toFixed(2);
                });
            }
            values?.plb_adjustments?.map((plb, ind) => {
                plb_total =
                    parseFloat(plb_total ? plb_total : 0) +
                    parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
            });

            remaining =
                parseFloat(values.check_amount ? values.check_amount : 0) -
                parseFloat(paid_total ? paid_total : 0) +
                parseFloat(plb_total ? plb_total : 0);

            if (parseFloat(remaining).toFixed(2) == -0.00) {
                remaining = parseFloat(0).toFixed(2);
            }

            const plbOnlyCheckAmnt = payment_method == "NON" ? parseFloat(0).toFixed(2) : jobData?.grid_data?.check_amount ? parseFloat(jobData?.grid_data?.check_amount ? jobData?.grid_data?.check_amount : 0).toFixed(2) : ""
            setTimeout(() => {

                formEob.setValues({
                    ...formEob.values,
                    payment_method: payment_method,
                    check_amount: payment_method == "NON" ? parseFloat(0).toFixed(2) : jobData?.grid_data?.check_amount ? parseFloat(jobData?.grid_data?.check_amount ? jobData?.grid_data?.check_amount : 0).toFixed(2) : "",
                    dbk_check_amount: payment_method == "NON" ? parseFloat(0).toFixed(2) : jobData?.grid_data?.check_amount ? parseFloat(jobData?.grid_data?.check_amount ? jobData?.grid_data?.check_amount : 0).toFixed(2) : "",
                    check_number: check_number,
                    dbk_check_number: check_number,
                    // remaining_balance: parseFloat(remaining).toFixed(2),
                    remaining_balance: formEob?.values?.grid_customization != "PLB_ONLY" ? parseFloat(remaining ? remaining : 0).toFixed(2) : getPlbOnlyRemainingBalance(plb_adjustments, plbOnlyCheckAmnt),

                    // claims: claims,
                    qdx_tranansaction_type: "PAYMENT835",



                    job: jobData?.job,
                    client_id: jobData?.client_id,
                    is_first_sub_job: isFirstJob,
                    payment_id:
                        values?.payment_id && values?.payment_id ? values?.payment_id : "",
                    data_837: values?.data_837 ? values?.data_837 : [],
                    transaction_type:
                        values?.transaction_type && values?.transaction_type
                            ? values?.transaction_type
                            : "",


                    check_date: isSubJob && !isFirstJob ? values?.check_date :
                        values?.check_date && values?.check_date ? values?.check_date : "",
                    check_page_number:
                        values?.check_page_number && values?.check_page_number
                            ? values?.check_page_number
                            : "",
                    correspondance:
                        values?.correspondance && values?.correspondance ? values?.correspondance : "",

                    payee_id:
                        values?.payee_id && values?.payee_id != "" ? values?.payee_id : "",
                    payee_name:
                        values?.payee_name && values?.payee_name != ""
                            ? values?.payee_name
                            : "",
                    payee_npi:
                        values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                    payee_address:
                        values?.payee_address && values?.payee_address != ""
                            ? values?.payee_address
                            : "",
                    payee_city:
                        values?.payee_city && values?.payee_city != ""
                            ? values?.payee_city
                            : "",
                    payee_state:
                        values?.payee_state && values?.payee_state != ""
                            ? values?.payee_state
                            : "",
                    payee_zip:
                        values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                    payer_page_from:
                        values?.payer_page_from && values?.payer_page_from != "" ? values?.payer_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payer_page_to:
                        values?.payer_page_to && values?.payer_page_to != "" ? values?.payer_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                    payer_id:
                        values?.payer_id && values?.payer_id != "" ? values?.payer_id : "",
                    payer_name:
                        values?.payer_name && values?.payer_name != ""
                            ? values?.payer_name
                            : "",
                    payer_address:
                        values?.payer_address && values?.payer_address != ""
                            ? values?.payer_address
                            : "",
                    payer_city:
                        values?.payer_city && values?.payer_city != ""
                            ? values?.payer_city
                            : "",
                    payer_state:
                        values?.payer_state && values?.payer_state != ""
                            ? values?.payer_state
                            : "",
                    payer_zip:
                        values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",
                    payee_page_from:
                        values?.payee_page_from && values?.payee_page_from != "" ? values?.payee_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payee_page_to:
                        values?.payee_page_to && values?.payee_page_to != "" ? values?.payee_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                    // DBK
                    // dbk_check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                    //     ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",
                    dbk_check_date: isSubJob && !isFirstJob ? values?.check_date :
                        values?.check_date && values?.check_date ? values?.check_date : "",
                    // dbk_check_amount: isSubJob && !isFirstJob ? values?.check_amount :
                    //     values?.check_amount == 0
                    //         ? parseFloat(0).toFixed(2)
                    //         : parseFloat(values?.check_amount)
                    //             ? parseFloat(values?.check_amount).toFixed(2)
                    //             : "",
                    dbk_payee_name:
                        values?.payee_name && values?.payee_name != ""
                            ? values?.payee_name
                            : "",
                    dbk_payee_npi:
                        values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                    dbk_payee_address:
                        values?.payee_address && values?.payee_address != ""
                            ? values?.payee_address
                            : "",
                    dbk_payee_city:
                        values?.payee_city && values?.payee_city != ""
                            ? values?.payee_city
                            : "",
                    dbk_payee_state:
                        values?.payee_state && values?.payee_state != ""
                            ? values?.payee_state
                            : "",
                    dbk_payee_zip:
                        values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                    dbk_payer_name:
                        values?.payer_name && values?.payer_name != ""
                            ? values?.payer_name
                            : "",
                    dbk_payer_address:
                        values?.payer_address && values?.payer_address != ""
                            ? values?.payer_address
                            : "",
                    dbk_payer_city:
                        values?.payer_city && values?.payer_city != ""
                            ? values?.payer_city
                            : "",
                    dbk_payer_state:
                        values?.payer_state && values?.payer_state != ""
                            ? values?.payer_state
                            : "",
                    dbk_payer_zip:
                        values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",

                    // qdx_tranansaction_type: values?.qdx_tranansaction_type ? values?.qdx_tranansaction_type : "PAYMENT835",
                    qdx_status: values?.qdx_status ? values?.qdx_status : "",
                    // qdx_reason: values?.qdx_reason ? values?.qdx_reason : "",
                    qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                    qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",

                    plb_adjustments: plb_adjustments,

                    claims: claims,

                })
            }, 100);

        } else {
            var claims = []
            if (jobData?.grid_data?.claims?.length > 0) {
                claims = jobData?.grid_data?.claims;

                claims.map((claim, index) => {
                    // DBK START
                    claim.corrected_claim = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;
                    claim.claim_corrected_index = index;
                    claim.page_from = claim && claim?.page_from ? claim && claim?.page_from : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.page_to = claim && claim?.page_to ? claim && claim?.page_to : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.dbk_patient_account_number = claim.patient_account_number ?? "";
                    claim.payer_claim_number = claim.payer_claim_number ?? "";
                    claim.dbk_payer_claim_number = claim.payer_claim_number ?? "";

                    claim.dbk_patient_first_name = claim.patient_first_name ?? "";
                    claim.dbk_patient_middle_name = claim.patient_middle_name ?? "";
                    claim.dbk_patient_last_name = claim.patient_last_name ?? "";
                    claim.dbk_patient_identifier_code = claim.patient_identifier_code ?? "";

                    claim.dbk_subscriber_first_name = claim.subscriber_first_name ?? "";
                    claim.dbk_subscriber_middle_name = claim.subscriber_middle_name ?? "";
                    claim.dbk_subscriber_last_name = claim.subscriber_last_name ?? "";
                    claim.dbk_subscriber_identifier_code =
                        claim.subscriber_identifier_code ?? claim.subscriber_identifier_code;

                    claim.dbk_rendering_provider_first_name =
                        claim.rendering_provider_first_name ?? claim.rendering_provider_first_name;
                    claim.dbk_rendering_provider_last_name =
                        claim.rendering_provider_last_name ?? claim.rendering_provider_last_name;
                    claim.dbk_rendering_provider_identifier_code =
                        claim.rendering_provider_identifier_code ?? claim.rendering_provider_identifier_code;

                    claim.dbk_mrn =
                        claim.mrn ?? claim.mrn;
                    claim.dbk_statement_number =
                        claim.statement_number ?? claim.statement_number;
                    claim.dbk_statement_date =
                        claim.statement_date ?? claim.statement_date;


                    // DBK END
                    claim.patient = claim.patient_id ? true : false;
                    claim.subscriber = claim.subscriber_id ? true : false;
                    claim.rendering_provider = claim.rendering_provider_id ? true : false;
                    claim.period_start = claim.period_start != "" ? moment(claim.period_start).format("MM/DD/YYYY") : "";
                    claim.period_end = claim.period_end != "" ? moment(claim.period_end).format("MM/DD/YYYY") : "";
                    claim.encounter = claim?.encounter ? claim?.encounter : "";
                    claim.claim_billed = parseFloat(claim?.claim_billed ? claim?.claim_billed : 0).toFixed(2);
                    claim.claim_discount = parseFloat(claim?.claim_discount ? claim?.claim_discount : 0).toFixed(2);
                    claim.claim_allowed = parseFloat(claim?.claim_allowed ? claim?.claim_allowed : 0).toFixed(2);
                    claim.claim_deduct = parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0).toFixed(2);
                    claim.claim_coins = parseFloat(claim?.claim_coins ? claim?.claim_coins : 0).toFixed(2);
                    claim.claim_copay = parseFloat(claim?.claim_copay ? claim?.claim_copay : 0).toFixed(2);
                    claim.claim_cob = parseFloat(claim?.claim_cob ? claim?.claim_cob : 0).toFixed(2);
                    claim.claim_patres = parseFloat(claim?.claim_patres ? claim?.claim_patres : 0).toFixed(2);
                    claim.claim_paid = parseFloat(claim?.claim_paid ? claim?.claim_paid : 0).toFixed(2);
                    claim.claim_balance = parseFloat(claim?.claim_balance ? claim?.claim_balance : 0).toFixed(2);
                    claim.claim_adjustment_total = parseFloat(claim?.claim_adjustment_total ? claim?.claim_adjustment_total : 0).toFixed(2);
                    claim.claim_adjustment_total_pr = parseFloat(claim?.claim_adjustment_total_pr ? claim?.claim_adjustment_total_pr : 0).toFixed(2);

                    // claim.status_code = values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "";
                    claim.mrn = claim?.mrn ? claim?.mrn : "";
                    claim.statement_number = claim?.statement_number ? claim?.statement_number : "";
                    claim.statement_date = claim?.statement_date ? claim?.statement_date : "";

                    claim.sl_billed = parseFloat(claim?.sl_billed ? claim?.sl_billed : 0).toFixed(2);
                    claim.fake_sl_patres = parseFloat(claim?.fake_sl_patres ? claim?.fake_sl_patres : 0).toFixed(2);
                    claim.adjustment_total = parseFloat(claim?.adjustment_total ? claim?.adjustment_total : 0).toFixed(2);
                    claim.sl_allowed = parseFloat(claim?.sl_allowed ? claim?.sl_allowed : 0).toFixed(2);
                    claim.sl_deduct = parseFloat(claim?.sl_deduct ? claim?.sl_deduct : 0).toFixed(2);
                    claim.sl_coins = parseFloat(claim?.sl_coins ? claim?.sl_coins : 0).toFixed(2);
                    claim.sl_copay = parseFloat(claim?.sl_copay ? claim?.sl_copay : 0).toFixed(2);
                    claim.sl_cob = parseFloat(claim?.sl_cob ? claim?.sl_cob : 0).toFixed(2);
                    claim.sl_patres = parseFloat(claim?.sl_patres ? claim?.sl_patres : 0).toFixed(2);
                    claim.sl_amount = parseFloat(claim?.sl_amount ? claim?.sl_amount : 0).toFixed(2);
                    claim.sl_paid = parseFloat(claim?.sl_paid ? claim?.sl_paid : 0).toFixed(2);
                    claim.sl_balance = parseFloat(claim?.sl_balance ? claim?.sl_balance : 0).toFixed(2);
                    claim.sl_total = parseFloat(claim?.sl_total ? claim?.sl_total : 0).toFixed(2);

                    claim?.claim_adjustments && claim?.claim_adjustments?.length > 0
                        ? claim?.claim_adjustments?.map((adjustment) => {
                            adjustment.amount = parseFloat(adjustment.amount ? adjustment.amount : 0).toFixed(2);
                        })
                        : (claim.claim_adjustments =
                            formEob.initialValues.claims[0].claim_adjustments);
                    // claim?.service_lines && claim?.service_lines?.length > 0
                    //     ? 
                    if (claim?.service_lines?.length > 0) {
                        claim?.service_lines.map((serviceline, index) => {
                            serviceline.serviceline_old_data_index = index;
                            serviceline.from_date = serviceline?.from_date != "" ? moment(serviceline?.from_date).format(
                                "MM/DD/YYYY"
                            ) : "";
                            serviceline.to_date = serviceline?.to_date != "" ? moment(serviceline?.to_date).format(
                                "MM/DD/YYYY"
                            ) : "";
                            serviceline.corrected_serviceline = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;

                            serviceline.serviceline_old_data_index = index;
                            serviceline.billed = parseFloat(serviceline?.billed ? serviceline?.billed : 0).toFixed(2);
                            serviceline.patres = parseFloat(serviceline?.patres ? serviceline?.patres : 0).toFixed(2);
                            serviceline.discount = parseFloat(serviceline?.discount ? serviceline?.discount : 0).toFixed(2);
                            serviceline.allowed = parseFloat(serviceline?.allowed ? serviceline?.allowed : 0).toFixed(2);
                            serviceline.deduct = parseFloat(serviceline?.deduct ? serviceline?.deduct : 0).toFixed(2);
                            serviceline.coins = parseFloat(serviceline?.coins ? serviceline?.coins : 0).toFixed(2);
                            serviceline.copay = parseFloat(serviceline?.copay ? serviceline?.copay : 0).toFixed(2);
                            serviceline.cob = parseFloat(serviceline?.cob ? serviceline?.cob : 0).toFixed(2);
                            serviceline.paid = parseFloat(serviceline?.paid ? serviceline?.paid : 0).toFixed(2);
                            serviceline.balance = parseFloat(serviceline?.balance ? serviceline?.balance : 0).toFixed(2);

                            serviceline?.adjustments.map((adjustment) => {
                                adjustment.amount = parseFloat(
                                    adjustment.amount ? adjustment.amount : 0
                                ).toFixed(2);
                            });
                        })
                    }
                    else {
                        claim.service_lines = [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ];
                    }
                    if (claim?.encounter && claim?.encounter != '') {
                        localStorage.setItem('837_pulled', true)
                    }
                    // : (claim.service_lines =
                    //     formEob.initialValues.claims[0].service_lines);
                });

            }
            else {

                claims = [
                    {
                        patient: true,
                        subscriber: true,
                        rendering_provider: true,

                        order: "",
                        color_index: "",

                        corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                        claim_id: "",
                        patient_account_number: "",
                        payer_claim_number: "",
                        period_start: "",
                        period_end: "",
                        status_code: values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "",
                        page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                        page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                        claim_billed: parseFloat(0).toFixed(2),
                        claim_discount: parseFloat(0).toFixed(2),
                        claim_allowed: parseFloat(0).toFixed(2),
                        claim_deduct: parseFloat(0).toFixed(2),
                        claim_coins: parseFloat(0).toFixed(2),
                        claim_copay: parseFloat(0).toFixed(2),
                        claim_cob: parseFloat(0).toFixed(2),
                        claim_patres: parseFloat(0).toFixed(2),
                        claim_paid: parseFloat(0).toFixed(2),
                        claim_balance: parseFloat(0).toFixed(2),
                        claim_adjustment_total: parseFloat(0).toFixed(2),
                        claim_adjustment_total_pr: parseFloat(0).toFixed(2),

                        sl_billed: parseFloat(0).toFixed(2),
                        sl_discount: parseFloat(0).toFixed(2),
                        sl_allowed: parseFloat(0).toFixed(2),
                        sl_deduct: parseFloat(0).toFixed(2),
                        sl_coins: parseFloat(0).toFixed(2),
                        sl_copay: parseFloat(0).toFixed(2),
                        sl_cob: parseFloat(0).toFixed(2),
                        sl_patres: parseFloat(0).toFixed(2),
                        sl_amount: parseFloat(0).toFixed(2),
                        sl_paid: parseFloat(0).toFixed(2),
                        sl_balance: parseFloat(0).toFixed(2),
                        sl_total: parseFloat(0).toFixed(2),

                        color_data: [],

                        mrn: "",
                        statement_number: "",
                        statement_date: "",

                        patient_id: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",
                        patient_identifier_code: "",

                        subscriber_id: "",
                        subscriber_first_name: "",
                        subscriber_middle_name: "",
                        subscriber_last_name: "",
                        subscriber_identifier_code: "",

                        rendering_provider_id: "",
                        rendering_provider_first_name: "",
                        rendering_provider_last_name: "",
                        rendering_provider_identifier_code: "",

                        // DBK
                        dbk_patient_account_number: "",
                        dbk_payer_claim_number: "",

                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                        dbk_patient_identifier_code: "",

                        dbk_subscriber_first_name: "",
                        dbk_subscriber_middle_name: "",
                        dbk_subscriber_last_name: "",
                        dbk_subscriber_identifier_code: "",

                        dbk_rendering_provider_first_name: "",
                        dbk_rendering_provider_last_name: "",
                        dbk_rendering_provider_identifier_code: "",

                        dbk_mrn: '',
                        dbk_statement_number: '',
                        dbk_statement_date: '',

                        claim_adjustments: [
                            {
                                pc: "",
                                group_code: "",
                                reason_code: "",
                                rmk: "",
                                amount: "",
                                claim_adjustment_id: "",
                            },
                        ],

                        service_lines: [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ],
                    },
                ]

            }
            const plbOnlyCheckAmnt = isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_amount :
                values?.check_amount == 0
                    ? parseFloat(0).toFixed(2)
                    : parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                        ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                        : ""
            setTimeout(() => {

                formEob.setValues({
                    ...formEob.values,

                    qdx_tranansaction_type: "PAYMENT835",
                    job: jobData?.job,
                    client_id: jobData?.client_id,
                    is_first_sub_job: isFirstJob,
                    payment_id:
                        values?.payment_id && values?.payment_id ? values?.payment_id : "",
                    data_837: values?.data_837 ? values?.data_837 : [],
                    transaction_type:
                        values?.transaction_type && values?.transaction_type
                            ? values?.transaction_type
                            : "",

                    payment_method: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.payment_method : values?.payment_method && values?.payment_method != "" ? values?.payment_method :
                        values?.check_amount && values?.check_amount > 0 ? "CHK" : values?.check_amount == 0 || values?.check_amount == "" && values?.qdx_tranansaction_type == "PAYMENT835" ? "NON" : values?.check_amount == 0 && values?.qdx_tranansaction_type == "CORRESPONDENCE" ? "COR" : "",
                    // payment_method: values?.payment_method,
                    check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                        ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",

                    check_date: isSubJob && !isFirstJob ? values?.check_date :
                        values?.check_date && values?.check_date ? values?.check_date : "",
                    check_amount: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_amount :
                        values?.check_amount == 0
                            ? parseFloat(0).toFixed(2)
                            : parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                                ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                                : "",
                    remaining_balance: formEob?.values?.grid_customization != "PLB_ONLY" ? parseFloat(remaining ? remaining : 0).toFixed(2) : getPlbOnlyRemainingBalance(plb_adjustments, plbOnlyCheckAmnt),

                    check_page_number:
                        values?.check_page_number && values?.check_page_number
                            ? values?.check_page_number
                            : "",
                    correspondance:
                        values?.correspondance && values?.correspondance ? values?.correspondance : "",

                    payee_id:
                        values?.payee_id && values?.payee_id != "" ? values?.payee_id : "",
                    payee_name:
                        values?.payee_name && values?.payee_name != ""
                            ? values?.payee_name
                            : "",
                    payee_npi:
                        values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                    payee_address:
                        values?.payee_address && values?.payee_address != ""
                            ? values?.payee_address
                            : "",
                    payee_city:
                        values?.payee_city && values?.payee_city != ""
                            ? values?.payee_city
                            : "",
                    payee_state:
                        values?.payee_state && values?.payee_state != ""
                            ? values?.payee_state
                            : "",
                    payee_zip:
                        values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                    payer_page_from:
                        values?.payer_page_from && values?.payer_page_from != "" ? values?.payer_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payer_page_to:
                        values?.payer_page_to && values?.payer_page_to != "" ? values?.payer_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                    payer_id:
                        values?.payer_id && values?.payer_id != "" ? values?.payer_id : "",
                    payer_name:
                        values?.payer_name && values?.payer_name != ""
                            ? values?.payer_name
                            : "",
                    payer_address:
                        values?.payer_address && values?.payer_address != ""
                            ? values?.payer_address
                            : "",
                    payer_city:
                        values?.payer_city && values?.payer_city != ""
                            ? values?.payer_city
                            : "",
                    payer_state:
                        values?.payer_state && values?.payer_state != ""
                            ? values?.payer_state
                            : "",
                    payer_zip:
                        values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",
                    payee_page_from:
                        values?.payee_page_from && values?.payee_page_from != "" ? values?.payee_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payee_page_to:
                        values?.payee_page_to && values?.payee_page_to != "" ? values?.payee_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                    // DBK
                    dbk_check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                        ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",
                    dbk_check_date: isSubJob && !isFirstJob ? values?.check_date :
                        values?.check_date && values?.check_date ? values?.check_date : "",
                    dbk_check_amount: isSubJob && !isFirstJob ? values?.check_amount :
                        values?.check_amount == 0
                            ? parseFloat(0).toFixed(2)
                            : parseFloat(values?.check_amount)
                                ? parseFloat(values?.check_amount).toFixed(2)
                                : "",
                    dbk_payee_name:
                        values?.payee_name && values?.payee_name != ""
                            ? values?.payee_name
                            : "",
                    dbk_payee_npi:
                        values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                    dbk_payee_address:
                        values?.payee_address && values?.payee_address != ""
                            ? values?.payee_address
                            : "",
                    dbk_payee_city:
                        values?.payee_city && values?.payee_city != ""
                            ? values?.payee_city
                            : "",
                    dbk_payee_state:
                        values?.payee_state && values?.payee_state != ""
                            ? values?.payee_state
                            : "",
                    dbk_payee_zip:
                        values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                    dbk_payer_name:
                        values?.payer_name && values?.payer_name != ""
                            ? values?.payer_name
                            : "",
                    dbk_payer_address:
                        values?.payer_address && values?.payer_address != ""
                            ? values?.payer_address
                            : "",
                    dbk_payer_city:
                        values?.payer_city && values?.payer_city != ""
                            ? values?.payer_city
                            : "",
                    dbk_payer_state:
                        values?.payer_state && values?.payer_state != ""
                            ? values?.payer_state
                            : "",
                    dbk_payer_zip:
                        values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",

                    // qdx_tranansaction_type: values?.qdx_tranansaction_type ? values?.qdx_tranansaction_type : "PAYMENT835",
                    qdx_status: values?.qdx_status ? values?.qdx_status : "",
                    // qdx_reason: values?.qdx_reason ? values?.qdx_reason : "",
                    qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                    qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",

                    plb_adjustments: plb_adjustments,

                    claims: claims,

                })
            }, 100);

        }
        await aditionalInfoFormik.setValues({
            ...aditionalInfoFormik.values,
            status: values?.qdx_status ? values?.qdx_status : "",
            // reason: values?.qdx_reason ? values?.qdx_reason : "",
            reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
            document_classification: values?.qdx_classification ? values?.qdx_classification : "",
            is_forced_balancing: values?.is_forced_balancing ? values?.is_forced_balancing : false,
            is_missing_check_image: values?.is_missing_check_image ? values?.is_missing_check_image : false,
            is_orphaned_check: values?.is_orphaned_check ? values?.is_orphaned_check : false,
            is_payer_black_listing: values?.is_payer_black_listing ? values?.is_payer_black_listing : false,
            is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? values?.is_payer_excluding_indexing : false,
        })
        setTimeout(() => {
            formEob.validateForm();
        }, 150);

    }
    // const [selectedSubJob, setSelectedSubJob] = useState({})

    const handleSelectSubjob = (e) => {

        getJobStaffStatus(e?.value)((response) => {
            if (response?.status) {
                setUserStatus(response?.data)
                if (response?.data?.is_active) {
                    setAssignTo('user');
                }
                else {
                    setAssignTo('common')
                }
                setAssignTooltip(true);

            }
        })
        setSelectedSubJob(e);



    }
    const handleChangeOverAllFrom = (e) => {

        setOverallPage({
            ...overallPage,
            from: e.target.value,
        })


    };
    const handleChangeOverAllTo = (e) => {
        setOverallPage({
            ...overallPage,
            to: e.target.value,
        })
    };

    const handleChangeFrom = (e) => {

        if (!e.target.disabled) {
            setOverallPage({
                ...overallPage,
                from: currentPage + 1,
            })

        }
    };

    const handleChangeTo = (e) => {
        if (!e.target.disabled) {
            setOverallPage({
                ...overallPage,
                to: currentPage + 1,
            })

        }
    };
    // console.log(subJobOptions);
    // console.log(formReassignMulty.values);
    const changeUserAssignStatus = (val, index) => {
        formReassignMulty.setValues(prevValues => {
            const updatedValues = [...prevValues]; // Create a copy of the initialValues array

            if (updatedValues[index]) {
                updatedValues[index] = {
                    ...updatedValues[index],
                    assignTo: val // Update the assignTo key value
                };
            }

            return updatedValues;
        });
    }

    const pathname = window.location.pathname;
    const handleTabFunction = (e) => {
        e.preventDefault();
        if (e.relatedTarget && e.relatedTarget != null) {
            if (formEob.isValid) {

                document.getElementById('save-exit-btn').focus();
            }
            else {
                document.getElementById('additional-btn').focus();
            }
        }
        else {
            const grid = document.getElementById('grid-full-container')
            const activeTab = grid.querySelector('[tabindex="0"]')
            activeTab.focus();
        }

    }
    const isPlbOnllyActive = state.Payment835Lite && eobLiteFormik?.values?.grid_customization != 'PLB_ONLY' ? true : state.Payment835 && formEob?.values?.grid_customization != 'PLB_ONLY' ? true : false
    return (
        <React.Fragment>
            <div className={payeeLookup && payerLookup && payeeLiteLookup && payerLiteLookup && patientLookup ? "" : "healthcare"}>
                <div className={payeeLookup && payerLookup && payeeLiteLookup && payerLiteLookup && patientLookup ? "" : "healthcare-top"}>
                    <div className={payeeLookup && payerLookup && payeeLiteLookup && payerLiteLookup && patientLookup ? "" : "leftside-block"}>
                        {!claim835 && !payeeLookup && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !patientLookup ? (
                            <React.Fragment>
                                {!claim835 &&
                                    <React.Fragment>
                                        <div className="accordion" id="payment835">
                                            <button type="button" tabIndex="-1" className="btn btn-healthcare accordion-icon-top" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"></button>
                                        </div>
                                        <div className="dropdown" >
                                            <button type="button" tabIndex="-1" className="btn btn-healthcare" data-bs-toggle="dropdown"> <img src={newicon} alt="" /> Transaction Type</button>
                                            <ul className={"dropdown-menu"} >
                                                {jobData?.document_type && jobData?.document_type?.eob &&
                                                    <li><button disabled={jobData?.is_large_batch_job && !isFirstJob ? true : false} tabIndex="-1" className={state.Payment835 ? "dropdown-item active" : jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item"} onClick={__payment835Handler}>Payment835</button></li>
                                                }
                                                {!jobData?.is_large_batch_job &&
                                                    <React.Fragment>

                                                        {jobData?.document_type && jobData?.document_type?.eob_lite &&
                                                            <li><button disabled={jobData?.is_large_batch_job && !isFirstJob ? true : false} tabIndex="-1" className={state.Payment835Lite ? "dropdown-item active" : jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={__payment835LiteHandler}>Payment835 Lite</button></li>
                                                        }
                                                        {jobData?.document_type && jobData?.document_type?.patient_pay &&
                                                            <li><button disabled={jobData?.is_large_batch_job && !isFirstJob ? true : false} tabIndex="-1" className={state.PatientPay ? "dropdown-item active" : jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={__patientPayHandler}>Patient Pay</button></li>
                                                        }
                                                    </React.Fragment>
                                                }
                                                {jobData?.document_type && jobData?.document_type?.correspondence &&
                                                    <li><button disabled={jobData?.is_large_batch_job && !isFirstJob ? true : false} tabIndex="-1" className={state.Correspondence ? "dropdown-item active" : jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={__correspondenceHandler}>Correspondence</button></li>
                                                }
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                }
                                <div className="">
                                    <button type="button"
                                        tabIndex="-1"
                                        // onClick={__handleResetForm}
                                        onClick={() => { clearCurrentForm(); localStorage.setItem('clear', true) }}
                                        className="btn btn-healthcare"> <img src={clear} alt="" className="clear" />Clear</button>
                                </div>
                            </React.Fragment>
                        ) : payeeLookup && payerLookup && patientLookup ? (
                            <React.Fragment></React.Fragment>
                        ) : !payeeLookup && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !patientLookup && (
                            <React.Fragment>

                                <div className="accordion" id="claimSummary" >
                                    <button type="button" tabIndex="-1" className="btn btn-healthcare accordion-claim" data-bs-toggle="collapse" data-bs-target="#claimssummary" aria-expanded="true" aria-controls="claimssummary">Claim Summary</button>
                                </div>

                                <div className="">
                                    <div type="button" tabIndex="-1" className="btn btn-healthcare" onClick={() => closeClaim()}> <TiArrowBack /> Back</div>
                                </div>
                            </React.Fragment>
                        )
                        }

                        {state.Payment835 && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup &&
                            <div className="">
                                <button id="plb-adjustment-button" type="button" tabIndex="-1" className="btn btn-healthcare" onClick={handleAddPlbAdjustment}>  <img src={addicon} alt="add" /> PLB Adjustment</button>
                            </div>
                        }
                        {state.Payment835Lite && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup &&
                            <div className="">
                                <button type="button" tabIndex="-1" className="btn btn-healthcare" onClick={handleLiteAddPlbAdjustment}>  <img src={addicon} alt="add" /> PLB Adjustment</button>
                            </div>
                        }
                        {state.Payment835 | state.Payment835Lite && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !patientLookup && !payeeLookup && isPlbOnllyActive
                            ? (
                                <React.Fragment>

                                    <div className="">
                                        <button type="button" tabIndex="-1" className="exclude-disabled btn btn-healthcare" onClick={() => {
                                            setClaim835(true);
                                            // setControlledPosition("claim_summary")
                                            // if (qdxTransaction != "PATIENT_PAY") { setControlledPosition({ x: 0, y: 560 }); } else { setControlledPosition({ x: 0, y: 710 }); }
                                        }}> <img src={claimicon} alt="claim" /> Claims</button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {/* just a null fragment */}
                                </React.Fragment>
                            )
                        }

                        <React.Fragment>
                            {(state.Payment835 || state.Payment835Lite) && jobData?.client_level_customization?.is_grid_customzation_enable == true && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup ? (

                                <div className="dropdown">
                                    <button disabled={isViewOnllyGrid} type="button" tabIndex="-1" className="btn btn-checkview" data-bs-toggle="dropdown">{
                                        state.Payment835 && formEob?.values?.grid_customization == 'PLB_ONLY' ? "PLB ONLY" :
                                            state.Payment835Lite && eobLiteFormik?.values?.grid_customization == 'PLB_ONLY' ? "PLB ONLY"
                                                :
                                                "NORMAL"

                                    }</button>
                                    <ul className="dropdown-menu" style={{ minWidth: '100px', }}>
                                        <li><button tabIndex="-1" disabled={window.location.pathname == QA_REVIEW ? false : jobData?.is_large_batch_job && !isFirstJob ? true : false} className={"dropdown-item"} onClick={() => { handlePlbOnlyValue('NORMAL') }}>NORMAL</button></li>
                                        <li><button tabIndex="-1" disabled={window.location.pathname == QA_REVIEW ? false : jobData?.is_large_batch_job && !isFirstJob ? true : false} className={"dropdown-item"} onClick={() => { handlePlbOnlyValue('PLB_ONLY') }}>PLB ONLY</button></li>


                                    </ul>
                                </div>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}
                        </React.Fragment>

                        {state.Payment835 && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup &&

                            <React.Fragment>
                                {isCorres835 || isPP835 ? (

                                    <div className="dropdown">
                                        <button disabled={isViewOnllyGrid} type="button" tabIndex="-1" className="btn btn-checkview" data-bs-toggle="dropdown">{qdxTransaction == 'PATIENT_PAY' ? 'PATIENT PAY' : qdxTransaction}</button>
                                        <ul className="dropdown-menu">
                                            <li><button tabIndex="-1" disabled={jobData?.is_large_batch_job && !isFirstJob ? true : false} className={qdxTransaction == 'PAYMENT835' && jobData?.is_large_batch_job && isFirstJob ? "dropdown-item active" : qdxTransaction != 'PAYMENT835' && jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={() => { qdxTransaction != 'PAYMENT835' && handleQdxPayment835() }}>Payment835</button></li>

                                            {isPP835 &&
                                                <li><button tabIndex="-1" disabled={jobData?.is_large_batch_job && !isFirstJob ? true : false} className={qdxTransaction == 'PATIENT_PAY' && jobData?.is_large_batch_job && isFirstJob ? "dropdown-item active" : qdxTransaction != 'PATIENT_PAY' && jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={() => { qdxTransaction != 'PATIENT_PAY' && handleQdxPatientPay() }}>Patient Pay</button></li>
                                            }

                                            {isCorres835 &&

                                                <li><button tabIndex="-1" disabled={jobData?.is_large_batch_job && !isFirstJob ? true : false} className={qdxTransaction == 'CORRESPONDENCE' && jobData?.is_large_batch_job && isFirstJob ? "dropdown-item active" : qdxTransaction != 'CORRESPONDENCE' && jobData?.is_large_batch_job && !isFirstJob ? "dropdown-item disabled disabled-typography" : "dropdown-item "} onClick={() => { qdxTransaction != 'CORRESPONDENCE' && handleQdxTransaction() }}>Correspondence</button></li>
                                            }
                                        </ul>
                                    </div>
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </React.Fragment>
                        }

                        {isOverallPage && !claim835 && !payerLookup && !payeeLiteLookup && !payerLiteLookup && !payeeLookup && !patientLookup &&
                            <div className="gap-0 d-flex flex-row">
                                <InputGroup className="overall-from ">
                                    <InputGroup.Text className="fs-7 text-capitalize" >Overall Page From</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        min={1}
                                        tabIndex={-1}
                                        // onBlur={handleCurrentPageChange}
                                        // onClick={handleChangeFrom}
                                        data-disabled={!pdfLoaded}
                                        onChange={(e) => handleChangeOverAllFrom(e)}
                                        onWheel={handleWheel}
                                        // onFocus={() => setClaim835Focus(true)}
                                        onClick={(e) => {
                                            handleChangeFrom(e);
                                        }}
                                        onFocus={(e) => {
                                            handleChangeFrom(e);
                                        }}
                                        className={
                                            !isIncomplete && (
                                                overallPage?.from > totalPages ||
                                                overallPage?.to > totalPages ||

                                                overallPage?.from == "" ||
                                                overallPage?.to == "") || (
                                                    !isIncomplete &&
                                                    parseInt(overallPage?.from) > parseInt(overallPage?.to)) ?

                                                "pagefrom error-field" :

                                                !isIncomplete &&
                                                    overallPage?.from == 0 ? "pagefrom error-field" :

                                                    !isIncomplete && (
                                                        overallPage?.from > totalPages ||
                                                        overallPage?.from == "" ||
                                                        overallPage?.to == ""
                                                    ) || (
                                                            !isIncomplete &&
                                                            parseInt(overallPage?.from) > parseInt(overallPage?.to)) ?
                                                        "pagefrom error-field" :
                                                        // overallPage?.from > totalPages ||
                                                        //     overallPage?.from == "" ||
                                                        //     overallPage?.to == "" ||
                                                        //     parseInt(overallPage?.from) > parseInt(overallPage?.to) ||
                                                        //     overallPage?.from != jobData?.grid_data?.overall_page_from ?
                                                        //     "pagefrom error-field" :
                                                        (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                            jobData?.grid_data?.qa_corrected_data &&
                                                            jobData?.grid_data?.qa_corrected_data?.overall_page_from
                                                            ? "pagefrom qa-field " :
                                                            pathname == QA_REVIEW &&
                                                                overallPage?.from != jobData?.grid_data?.overall_page_from ?
                                                                "pagefrom qa-field" :
                                                                "pagefrom "}
                                        value={overallPage?.from}
                                    // value={formik.values?.claims[index]?.page_from}
                                    // tabIndex={colorAll837 && all837?.from ? "-1" : ""}

                                    // className={
                                    //     (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                    //         formik.errors &&
                                    //         formik.errors?.claims &&
                                    //         formik.errors?.claims[index] &&
                                    //         !!formik.errors?.claims[index]?.page_from &&
                                    //         qaCorrectedData &&
                                    //         qaCorrectedData?.claims &&
                                    //         qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                                    //         qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.page_from
                                    //         ? "pagefrom ps-1 error-field" :
                                    //         (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                    //             qaCorrectedData &&
                                    //             qaCorrectedData?.claims &&
                                    //             qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                                    //             qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.page_from
                                    //             ? "pagefrom ps-1 qa-field" :

                                    //             pathname == QA_REVIEW &&
                                    //                 formik.errors &&
                                    //                 formik.errors?.claims &&
                                    //                 formik.errors?.claims[index] &&
                                    //                 !!formik.errors?.claims[index]?.page_from &&

                                    //                 formik.values &&
                                    //                 formik.values?.claims &&
                                    //                 formik.values?.claims[index] &&
                                    //                 formik.values?.claims[index]?.page_from &&

                                    //                 gridData &&
                                    //                 gridData?.claims &&
                                    //                 gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&

                                    //                 formik.values?.claims[index]?.page_from != gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.page_from
                                    //                 ? "pagefrom ps-1 error-field " :
                                    //                 pathname == QA_REVIEW &&
                                    //                     formik.values?.claims[index]?.corrected_claim

                                    //                     ? "pagefrom ps-1  qa-field " :

                                    //                     pathname == QA_REVIEW &&
                                    //                         formik.values &&
                                    //                         formik.values?.claims &&
                                    //                         formik.values?.claims[index] &&
                                    //                         formik.values?.claims[index]?.page_from &&
                                    //                         formik.values?.claims[index]?.page_from != "" &&

                                    //                         gridData &&
                                    //                         gridData?.claims &&
                                    //                         gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                                    //                         formik.values?.claims[index]?.page_from != gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.page_from

                                    //                         ? "pagefrom ps-1  qa-field " :

                                    //                         pathname == QA_REVIEW &&
                                    //                             formik.errors &&
                                    //                             formik.errors?.claims &&
                                    //                             formik.errors?.claims[index] &&
                                    //                             formik.errors?.claims[index]?.page_from &&
                                    //                             gridData &&
                                    //                             !gridData?.claims
                                    //                             ? "pagefrom ps-1 text-uppercase error-field " :
                                    //                             pathname == QA_REVIEW &&
                                    //                                 gridData &&
                                    //                                 gridData?.claims &&
                                    //                                 gridData?.transaction_type != "PAYMENT835"
                                    //                                 ? "pagefrom ps-1 text-uppercase qa-field " :
                                    //                                 pathname == QA_REVIEW &&
                                    //                                     gridData &&
                                    //                                     !gridData?.claims
                                    //                                     ? "pagefrom ps-1 text-uppercase qa-field " :

                                    //                                     formik.errors?.claims &&
                                    //                                         formik.errors?.claims[index]?.page_from
                                    //                                         ? "pagefrom error-field "
                                    //                                         : "pagefrom "
                                    // }
                                    />
                                </InputGroup>
                                <InputGroup className="overall-from ">
                                    <InputGroup.Text className="fs-7 text-capitalize" >To</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        min={1}
                                        tabIndex={-1}
                                        // onBlur={handleCurrentPageChange}
                                        // onClick={handleChangeFrom}
                                        data-disabled={!pdfLoaded}
                                        onChange={(e) => handleChangeOverAllTo(e)}
                                        onWheel={handleWheel}
                                        // onFocus={() => setClaim835Focus(true)}
                                        onClick={(e) => {
                                            handleChangeTo(e);
                                        }}
                                        onFocus={(e) => {
                                            handleChangeTo(e);
                                        }}
                                        value={overallPage?.to}
                                        className={
                                            !isIncomplete && (
                                                overallPage?.to > totalPages ||
                                                overallPage?.from > totalPages ||
                                                overallPage?.from == "" ||
                                                overallPage?.to == "") ||
                                                !isIncomplete && (
                                                    parseInt(overallPage?.from) > parseInt(overallPage?.to)) ?
                                                "pageto error-field" :

                                                !isIncomplete &&
                                                    parseInt(overallPage?.from) > parseInt(overallPage?.to) ?
                                                    "pageto error-field" :
                                                    // overallPage?.from > totalPages ||
                                                    //     overallPage?.from == "" ||
                                                    //     overallPage?.to == "" ||
                                                    //     overallPage?.to != jobData?.grid_data?.overall_page_to ?
                                                    //     "pagefrom error-field" :

                                                    !isIncomplete &&
                                                        overallPage?.to == 0 ?
                                                        "pageto error-field " :
                                                        (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                                            jobData?.grid_data?.qa_corrected_data &&
                                                            jobData?.grid_data?.qa_corrected_data?.overall_page_to
                                                            ? "pageto qa-field " :
                                                            pathname == QA_REVIEW &&
                                                                overallPage?.to != jobData?.grid_data?.overall_page_to ?
                                                                "pageto qa-field" :

                                                                "pageto  "}
                                    // value={formik.values?.claims[index]?.page_from}
                                    // tabIndex={colorAll837 && all837?.from ? "-1" : ""}

                                    // className={
                                    //     (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                    //         formik.errors &&
                                    //         formik.errors?.claims &&
                                    //         formik.errors?.claims[index] &&
                                    //         !!formik.errors?.claims[index]?.page_from &&
                                    //         qaCorrectedData &&
                                    //         qaCorrectedData?.claims &&
                                    //         qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                                    //         qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.page_from
                                    //         ? "pagefrom ps-1 error-field" :
                                    //         (pathname == QA_REVIEW || pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
                                    //             qaCorrectedData &&
                                    //             qaCorrectedData?.claims &&
                                    //             qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                                    //             qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.page_from
                                    //             ? "pagefrom ps-1 qa-field" :

                                    //             pathname == QA_REVIEW &&
                                    //                 formik.errors &&
                                    //                 formik.errors?.claims &&
                                    //                 formik.errors?.claims[index] &&
                                    //                 !!formik.errors?.claims[index]?.page_from &&

                                    //                 formik.values &&
                                    //                 formik.values?.claims &&
                                    //                 formik.values?.claims[index] &&
                                    //                 formik.values?.claims[index]?.page_from &&

                                    //                 gridData &&
                                    //                 gridData?.claims &&
                                    //                 gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&

                                    //                 formik.values?.claims[index]?.page_from != gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.page_from
                                    //                 ? "pagefrom ps-1 error-field " :
                                    //                 pathname == QA_REVIEW &&
                                    //                     formik.values?.claims[index]?.corrected_claim

                                    //                     ? "pagefrom ps-1  qa-field " :

                                    //                     pathname == QA_REVIEW &&
                                    //                         formik.values &&
                                    //                         formik.values?.claims &&
                                    //                         formik.values?.claims[index] &&
                                    //                         formik.values?.claims[index]?.page_from &&
                                    //                         formik.values?.claims[index]?.page_from != "" &&

                                    //                         gridData &&
                                    //                         gridData?.claims &&
                                    //                         gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                                    //                         formik.values?.claims[index]?.page_from != gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.page_from

                                    //                         ? "pagefrom ps-1  qa-field " :

                                    //                         pathname == QA_REVIEW &&
                                    //                             formik.errors &&
                                    //                             formik.errors?.claims &&
                                    //                             formik.errors?.claims[index] &&
                                    //                             formik.errors?.claims[index]?.page_from &&
                                    //                             gridData &&
                                    //                             !gridData?.claims
                                    //                             ? "pagefrom ps-1 text-uppercase error-field " :
                                    //                             pathname == QA_REVIEW &&
                                    //                                 gridData &&
                                    //                                 gridData?.claims &&
                                    //                                 gridData?.transaction_type != "PAYMENT835"
                                    //                                 ? "pagefrom ps-1 text-uppercase qa-field " :
                                    //                                 pathname == QA_REVIEW &&
                                    //                                     gridData &&
                                    //                                     !gridData?.claims
                                    //                                     ? "pagefrom ps-1 text-uppercase qa-field " :

                                    //                                     formik.errors?.claims &&
                                    //                                         formik.errors?.claims[index]?.page_from
                                    //                                         ? "pagefrom error-field "
                                    //                                         : "pagefrom "
                                    // }
                                    />
                                </InputGroup>
                            </div>

                        }
                    </div>
                    <input data-not-take-field-count="true" type='' onFocus={handleTabFunction} className='input-focus-hide' />
                    {!payerLookup && !payeeLookup && !patientLookup && !payeeLiteLookup && !payerLiteLookup &&
                        <div className="rightside-block">

                            {/* {window.location.pathname == QA_REVIEW && */}
                            {(window.location.pathname == QA_REVIEW) || (window.location.pathname == EDIT_WORK + job_id && ["qa_complete", "qa_review"].includes(jobData?.grid_data?.status)) ?
                                <button type="button" tabIndex="-1" className="btn btn-reject"
                                    onClick={() => { showAssignTooltip(); setIsDrag(false) }}
                                    ref={assignRef}
                                    aria-expanded="false"
                                    data-save-loading={isViewOnllyGrid ? true : additionalModal ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : false}
                                    disabled={isViewOnllyGrid ? true : additionalModal ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : false}>Re Assign</button>
                                :
                                <React.Fragment>

                                </React.Fragment>
                            }
                            <div className="btn-group ">
                                <button type="button" tabIndex="-1"
                                    data-not-take-field-count="true"
                                    disabled={isViewOnllyGrid ? true : additionalModal ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : formPartial.isSubmitting || rejectForm.isSubmitting ? true : false}
                                    data-save-loading={isViewOnllyGrid ? true : additionalModal ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : formPartial.isSubmitting || rejectForm.isSubmitting ? true : false}
                                    data-bs-toggle="dropdown"
                                    className="btn btn-reject"
                                    id="grid-reject-button"
                                    ref={rejectRef}
                                    data-bs-display="static"
                                    aria-expanded="false">Reject</button>
                                <ul className="dropdown-menu dropdown-overflow dropdown-menu-lg-end" >

                                    {jobData?.rejections?.map((data, index) =>
                                        <li key={index}><button className={window.location.pathname == DATA_ENTRY && jobData?.is_large_batch_job ? "dropdown-item disabled disabled-typography" : "dropdown-item"} disabled={jobData.is_large_batch_job && window.location.pathname == DATA_ENTRY}
                                            onClick={() => { setRejectPopup(true); setIsDrag(false); setRejectLabel(data.label); rejectForm.setValues({ ...rejectForm.values, rejection_reason: data.value }) }}
                                        >{data?.label}</button>
                                        </li>
                                    )}
                                    {window.location.pathname == DATA_ENTRY &&

                                        <li
                                            className={state.Payment835 ?
                                                formEob?.errors && formEob?.errors?.claims
                                                : state.Payment835Lite ?
                                                    eobLiteFormik?.errors?.claims
                                                    : state.Correspondence ?
                                                        formCorres.errors && formCorres.errors?.claims : false ? " bg-disabled " : ''
                                            }

                                        >
                                            <button
                                                onClick={() => {
                                                    setPartialSavePopUp(true); setIsDrag(false); //setRejectTooltip(false)
                                                }}

                                                disabled={
                                                    state.Payment835 ?
                                                        formEob?.errors && formEob?.errors?.claims
                                                        : state.Payment835Lite ?
                                                            eobLiteFormik?.errors?.claims
                                                            : state.PatientPay ? false : state.Correspondence ?
                                                                formCorres.errors && formCorres.errors?.claims : false
                                                }

                                                className={state.Payment835 && formEob?.errors && formEob?.errors?.claims ?
                                                    "dropdown-item disabled disabled-typography"
                                                    : state.Payment835Lite && eobLiteFormik?.errors?.claims ?
                                                        "dropdown-item disabled disabled-typography" :
                                                        state.PatientPay ? "dropdown-item"
                                                            : state.Correspondence &&
                                                                formCorres.errors && formCorres.errors?.claims
                                                                ? "dropdown-item disabled disabled-typography" : "dropdown-item"
                                                }
                                            >
                                                Partial Save
                                            </button>
                                        </li>
                                    }
                                </ul>

                                <TextAreaField
                                    rootClose={true}
                                    onHide={() => { setAssignTooltip(false); setIsDrag(true) }}
                                    show={assignTooltip}
                                    target={assignRef.current}
                                    placement="left"
                                >
                                    <Popover className={jobData?.is_large_batch_job ? 'pc-popover-assign-large' : "pc-popover-assign"} >
                                        <Popover.Header as="h3">Assign Back</Popover.Header>
                                        <Popover.Body className="">
                                            {!jobData?.is_large_batch_job ?
                                                <React.Fragment>

                                                    <div className="mb-2 d-flex flex-row align-items-center justify-content-around gap-3">
                                                        <div className='flex-shrink-1'>Assign to:</div>
                                                        <div className={assignTo == 'user' ? "btn btn-light btn-active-primary flex-grow-1" : "btn btn-light btn-active-secondary flex-grow-1"} onClick={() => setAssignTo('user')} role='button'>

                                                            {/* {!jobData?.is_large_batch_job && */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={userStatus?.is_active ? "#38E54D" : "#FF1E1E"} className="bi bi-dot" viewBox="0 0 16 16">
                                                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                                                            </svg>
                                                            {/* } */}
                                                            {userStatus?.user ? userStatus?.user : ""}
                                                        </div>
                                                        <div role='button' className={assignTo == 'common' ? "btn btn-light btn-active-primary flex-grow-1" : "btn btn-light btn-active-secondary flex-grow-1"} onClick={() => setAssignTo('common')}>Common pool</div>
                                                    </div>
                                                    <Form onSubmit={formReassign.handleSubmit}>

                                                        <Form.Control
                                                            type="text"
                                                            as="textarea"
                                                            name={"assign_reason"}
                                                            isInvalid={formReassign?.errors?.assign_reason}
                                                            className="b-r-10 text-uppercase"
                                                            onChange={formReassign.handleChange}
                                                            rows={4}
                                                            value={formReassign?.values?.assign_reason}
                                                            style={{ resize: 'none', width: '100%', }}

                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {formReassign.errors?.assign_reason}
                                                        </Form.Control.Feedback>
                                                        <div className='d-flex flex-row justify-content-end'>
                                                            <button type="submit" disabled={!formReassign.isValid || formReassign.isSubmitting} className="btn btn-reject text-end " data-bs-display="static" aria-expanded="false">Assign</button>
                                                        </div>
                                                    </Form>
                                                </React.Fragment>

                                                :

                                                <React.Fragment>
                                                    <Formik
                                                        value={formReassignMulty}
                                                    >
                                                        <Form onSubmit={formReassignMulty.handleSubmit}>
                                                            <div className='d-flex flex-column scroll-claim gap-1 pb-2'>
                                                                {formReassignMulty?.values && formReassignMulty?.values?.map((subjob, index) => (
                                                                    <React.Fragment>
                                                                        <ul className="list-group list-group-horizontal">

                                                                            <li className="list-group-item d-flex align-items-center option-item-assign width-check p-1 m-0  " style={{ fontSize: '0.8em' }} >
                                                                                <Form.Check.Input
                                                                                    // id={`default-${ind}`}
                                                                                    className='checked-servicelines '
                                                                                    type={"checkbox"}
                                                                                    name={`[${index}].is_select`}
                                                                                    defaultChecked={subjob.is_select}
                                                                                    onChange={formReassignMulty.handleChange}
                                                                                />

                                                                            </li>
                                                                            <li className="list-group-item d-flex align-items-center option-item-assign width-label p-1 m-0  "  >
                                                                                <span style={{ fontSize: '0.9em' }}>

                                                                                    {subjob?.label}
                                                                                </span>
                                                                            </li>
                                                                            <li className="list-group-item d-flex align-items-center option-item-assign p-1 m-0 " >

                                                                                <button type="button" className={subjob?.assignTo == 'common' ? "btn btn-light btn-sm btn-icon btn-active-primary width-common-btn fs-7" : "btn btn-light btn-sm btn-icon btn-active-secondary width-common-btn fs-7 "} onClick={() => { changeUserAssignStatus('common', index) }}>Common Pool</button>
                                                                            </li>
                                                                            <li className="list-group-item d-flex align-items-center justify-content-center option-item-assign  p-1 m-0  "  >

                                                                                <button type="button" className={subjob.assignTo == 'user' ? "btn btn-light btn-active-primary btn-sm btn-icon  width-user-btn" : "btn btn-light btn-sm btn-icon  btn-active-secondary width-user-btn"}
                                                                                    style={{ fontSize: '0.8em' }}
                                                                                    onClick={() => { changeUserAssignStatus('user', index) }}
                                                                                >

                                                                                    <BsDot color={subjob?.is_active ? "#38E54D" : "#FF1E1E"} />

                                                                                    {subjob?.staff_name}</button>

                                                                            </li>
                                                                            <li className="list-group-item d-flex align-items-center option-item-assign p-1 m-0 fs-7 " >

                                                                                <Form.Control
                                                                                    type="text"
                                                                                    as="textarea"
                                                                                    name={`[${index}].assign_reason`}

                                                                                    className="text-uppercase pt-1"
                                                                                    style={{ resize: 'none', width: '400px', height: '30px', minHeight: '30px', fontSize: '1.2em', borderRadius: '5px' }}
                                                                                    value={subjob?.assign_reason}

                                                                                    // onChange={(e) => { setAssignReason(e.target.value) }}
                                                                                    onChange={formReassignMulty.handleChange}
                                                                                    isInvalid={!!formReassignMulty.errors[index]?.assign_reason}
                                                                                />
                                                                                {/* <Form.Control.Feedback type='invalid'>
                                                                                    {formReassignMulty.errors[index]?.assign_reason}
                                                                                </Form.Control.Feedback> */}

                                                                            </li>

                                                                        </ul>

                                                                    </React.Fragment>
                                                                ))}

                                                            </div>
                                                            <div className='d-flex flex-row justify-content-end'>
                                                                <button type="submit" disabled={!formReassignMulty.isValid || formReassignMulty.isSubmitting} className="btn btn-reject text-end me-4" data-bs-display="static" aria-expanded="false">Assign</button>
                                                            </div>
                                                        </Form>
                                                    </Formik>

                                                </React.Fragment>


                                            }

                                        </Popover.Body>
                                    </Popover>
                                </TextAreaField>


                                <TextAreaField
                                    rootClose={true}
                                    onHide={() => { setRejectPopup(false); setIsDrag(true); handleRejectFormClose() }}
                                    show={rejectPopup}
                                    target={rejectRef.current}
                                    placement="left"
                                >
                                    <Popover id="popover-contained"  >
                                        <Popover.Header as="h3">{rejectLabel}</Popover.Header>
                                        <Popover.Body className="text-end">
                                            <Formik value={rejectForm}>

                                                <Form onSubmit={rejectForm.handleSubmit}>
                                                    <Form.Group>


                                                        <Form.Control
                                                            type="text"
                                                            name="reject_message"
                                                            as="textarea"
                                                            onChange={rejectForm.handleChange}
                                                            rows={4}
                                                            className="b-r-10 text-uppercase"
                                                            isInvalid={!!rejectForm.errors.reject_message}

                                                            style={{ resize: 'none', width: '400px' }}
                                                            value={rejectForm.values?.reject_message}
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {rejectForm.errors.reject_message}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>

                                                    <button type="submit" className="btn btn-reject text-end" data-bs-display="static" aria-expanded="false">Reject</button>
                                                </Form>
                                            </Formik>
                                        </Popover.Body>
                                    </Popover>
                                </TextAreaField>
                                <TextAreaField
                                    rootClose={true}
                                    onHide={() => { setPartialSavePopUp(false); setIsDrag(true); handlePartialFormClose() }}
                                    show={partialSavePopUp}
                                    target={rejectRef.current}
                                    placement="left"
                                >
                                    <Popover id="popover-contained">
                                        <Popover.Body className="text-end"
                                        >
                                            <FormikProvider value={formPartial}>
                                                <Form onSubmit={formPartial.handleSubmit}>

                                                    <Form.Group>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={4}
                                                            name='partial_reason'
                                                            className="b-r-10 text-uppercase"
                                                            type="text"
                                                            style={{ resize: 'none', width: '400px' }}
                                                            value={formPartial.values?.partial_reason}
                                                            onChange={formPartial.handleChange}
                                                            isInvalid={!!formPartial.errors.partial_reason}
                                                        // onBlur={() => handlePartialTransactionType()}
                                                        />
                                                        <Form.Control.Feedback type='invalid'>
                                                            {formPartial.errors?.partial_reason}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                    <button disabled={formPartial.isSubmitting} type="submit" className="btn btn-reject text-end" data-bs-display="static" aria-expanded="false">Partial Save</button>
                                                </Form>
                                            </FormikProvider>
                                        </Popover.Body>
                                    </Popover>
                                </TextAreaField>
                            </div>
                            {!state.Correspondence &&
                                <div className="" style={{ marginBottom: "2px" }}>
                                    <button data-check-view={!checkNum || checkNum == 0 ? true : checkNum > totalPages ? true : false} disabled={!checkNum || checkNum == 0 ? true : checkNum > totalPages ? true : false} type="button" tabIndex="-1" className="exclude-disabled btn btn-checkview"
                                        onClick={() => { setShowCheckView(true); setIsDrag(false) }}
                                    >Check View</button>
                                </div>
                            }

                            {/* Check View Modal Start */}
                            <CheckViewModal fileUrl={fileUrl} initialPage={checkNum} setShowCheckView={setShowCheckView} showCheckView={showCheckView} rotatePosition={rotatePosition} setIsDrag={setIsDrag} />
                            {/* Check View Modal Start */}

                            <div className="" style={{ marginBottom: "2px" }}>
                                <div type="button" tabIndex="-1" className="" onClick={() => { setInfoModal(true); setIsDrag(false) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#ffffff" className="bi bi-info-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                </div>
                            </div>

                            {/* File Info Modal Start */}
                            <FileInfoModal jobData={jobData} infoModal={infoModal} setInfoModal={setInfoModal} file_info={jobData?.file_info} totalPages={totalPages} setIsDrag={setIsDrag} />
                            {/* File Info Modal End */}

                        </div>
                    }
                </div>
            </div>
        </React.Fragment >
    )
}

export default GridHeader