import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./assets/css/custom.css";
import "./assets/css/main.css";
import "./assets/css/sidebar.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap/dist/js/bootstrap.min.js";
// import "react-datepicker/dist/react-datepicker.css";
import "../src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import {
  BrowserRouter,
  createBrowserRouter,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import DataEntry from "./components/DashBoard/DataEntry";
import Dashboard from "./components/DashBoard/Dashboard/index";
import Roles from "./components/DashBoard/Roles/index";
import Root from "./Root";
import Groups from "./components/DashBoard/Groups";
import Users from "./components/DashBoard/Users";
import Create from "./components/DashBoard/Roles/Create";
import Payee from "./components/DashBoard/Payee/index";
import CreatePayee from "./components/DashBoard/Payee/CreatePayee/index";
import CreateGroup from "./components/DashBoard/Groups/Create";
import EditGroup from "./components/DashBoard/Groups/Edit";
import EditPayee from "./components/DashBoard/Payee/EditPayee/index";
import Payer from "./components/DashBoard/Payer/index";
import CreatePayer from "./components/DashBoard/Payer/CreatePayer/index";
import EditPayer from "./components/DashBoard/Payer/EditPayer/index";
import CreateUser from "./components/DashBoard/Users/CreateUser";
import EditUser from "./components/DashBoard/Users/EditUser";
import EditRole from "./components/DashBoard/Roles/EditRole";
import ClientOnBoard from "./components/DashBoard/ClientOnBoard/index";
import CreateClient from "./components/DashBoard/ClientOnBoard/CreateClient/index";
import ReasonCode from "./components/DashBoard/ResonCodes/index";
import CreateReasonCode from "./components/DashBoard/ResonCodes/CreateReasonCode/index";
import EditReasonCode from "./components/DashBoard/ResonCodes/EditReasonCode/index";
import X12Code from "./components/DashBoard/X12Code/index";
import CreateX12Code from "./components/DashBoard/X12Code/CreateX12Code/index";
import EditX12Code from "./components/DashBoard/X12Code/EditX12Code/index";
import CorrespondenceCategory from "./components/DashBoard/CorrespondenceCategory/index";
import CreateCorrespondenceCategory from "./components/DashBoard/CorrespondenceCategory/Create/index";
import EditCorrespondenceCategory from "./components/DashBoard/CorrespondenceCategory/Edit/index";
import MainIndex from "./components/DashBoard/Admin/index";
import Footer from "./Layout/admin/Includes/Footer";
import rootLoader from "./assets/animations/root-loader.json";
import {
  DASHBOARD,
  IFRAME,
  INDEX,
  ROLES,
  CREATE_ROLES,
  EDIT_ROLES,
  GROUPS,
  CREATE_GROUPS,
  EDIT_GROUPS,
  PAYEE,
  CREATE_PAYEE,
  EDIT_PAYEE,
  PAYER,
  EDIT_PAYER,
  USERS,
  EDIT_USERS,
  CREATE_USERS,
  DATA_ENTRY,
  CLIENT,
  CREATE_CLIENT,
  CREATE_PAYER,
  HOLIDAY_CALENDAR,
  EDIT_HOLIDAY_CALENDAR,
  CREATE_HOLIDAY_CALENDAR,
  REASON_CODES,
  CREATE_REASON_CODES,
  X12_CODES,
  CREATE_X12_CODES,
  EDIT_X12_CODES,
  CORRESPONDENCE_CODES,
  CREATE_CORRESPONDENCE_CODE,
  EDIT_REASON_CODES,
  EDIT_CORRESPONDENCE_CODE,
  SETTINGS,
  FACILITIES,
  EDIT_CLIENT,
  FILE_SPLIT,
  QMS,
  QMSQACOMPlETED,
  EDIT_WORK,
  SINGLE_SPLIT,
  LOCKBOXFILES,
  LOCKBOX,
  SINGLE_LOCKBOX_LOCKBOX_FILES,
  REJECT_REASONS,
  CREATE_REJECT_REASONS,
  EDIT_REJECT_REASONS,
  CLIENT_GROUP,
  CREATE_CLIENT_GROUP,
  EDIT_CLIENT_GROUP,
  CREATE_ASSIGN_USERS,
  COPY_CLIENT,
  GET_PATIENT_PAY,
  GET_CORRESPONDANCE,
  GET_ENCOUNTER_LIST,
  GET_835_LIST,
  SFTP_CLIENT_LIST,
  QA_REVIEW,
  APPROVE_PAYEE,
  GET_835_LITE_LIST,
  NOTIFICATION_LIST,
  PARTNER_LIST,
  PARTNER_CREATE,
  PARTNER_EDIT,
  DOCUMENT_CLASSIFICATION,
  CREATE_DOCUMENT,
  EDIT_DOCUMENT,
  CREATE_REASON,
  REASON,
  EDIT_REASON,
  CREATE_CLAIMS_FILING,
  CLAIMS_FILING,
  EDIT_CLAIMS_FILING,
  NOTIFICATION_EDIT,
  LOCKBOX_FILES_QMS,
  EMAIL_TEMPLATE,
  EDIT_EMAIL_TEMPLATE,
  CREATE_EMAIL_TEMPLATE,
  DBK_LIST,
  CREATE_DBK,
  EDIT_DBK,
  LOGIN,
  SFTP_LOG_LIST,
  OFFLINE_STATUS,
  EMAIL_LOG_LIST,
  HOUSE_KEEPING_LOG_LIST,
  GET_ACKNOWLEDGMENT_LIST,
  SNIPPET,
  PAYER_ID_LIST,
  EDIT_PAYER_ID_LIST,
  CREATE_PAYER_ID_LIST

} from "./routeNames";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "/node_modules/bootstrap/dist/js/bootstrap.min.js";
import "../src/assets/vendor/bootstrap-icons/bootstrap-icons.css";
import { createRoutesFromElements, RouterProvider } from "react-router-dom";
import HolidayCalendar from "./components/DashBoard/HolidayCalendar/index";
import EditHolidayCalendar from "./components/DashBoard/HolidayCalendar/EditCalendar/index";
import CreateCalendar from "./components/DashBoard/HolidayCalendar/CreateCalendar/index";
import ErrorLoader from "./Elements/ErrorLoader";
import Settings from "./components/Settings";
import Facility from "./components/DashBoard/ClientOnBoard/CreateClient/Forms/facility";
import Login from "./components/Login/index";
import FileSplit from "./components/DashBoard/FileSplit";
import QueueManagementSystem from "./components/DashBoard/QueueManagementSystem";
import QaCompleted from "./components/DashBoard/QaCompleted";
import PdfViewer from "./components/DashBoard/FileSplit/PdfViewer";
import AllLockbox from "./components/DashBoard/AllLockboxFiles";
import Iframe from "./components/Iframe";
import Lockbox from "./components/DashBoard/Lockbox";
import RejectReason from "./components/DashBoard/RejectReason";
import CreateRejectReason from "./components/DashBoard/RejectReason/CreateRejectReason";
import ClientGroup from "./components/DashBoard/ClientGroup";
import CreateClientGroup from "./components/DashBoard/ClientGroup/CreateClient";
import CreateAssignUser from "./components/DashBoard/ClientGroup/CreateAssignUser";
import CorrespondanceList from "./components/DashBoard/OutputSchema/CorrespondenceList";
import PatientPayList from "./components/DashBoard/OutputSchema/PatientPayList";
import EncounterList from "./components/DashBoard/OutputSchema/EncounterList";
import List835 from "./components/DashBoard/OutputSchema/List835";
import ListAcknowledgement from "./components/DashBoard/OutputSchema/ListAcknowledgement";
import SftpClientList from "./components/DashBoard/SftpClient/SftpClientList";
import List835Lite from "./components/DashBoard/835Lite";
import NotificationList from "./components/DashBoard/Notification";
import View from "./components/DashBoard/Partner/View";
import ViewDocument from "./components/DashBoard/Document/View";
import CreateUpdate from "./components/DashBoard/Partner/CreateUpdate";
import CreateUpdateDocument from "./components/DashBoard/Document/createUpdate";
import EditDocument from "./components/DashBoard/Document/EditDocument";
import ViewReason from "./components/DashBoard/Reason/View";
import CreateUpdateReason from "./components/DashBoard/Reason/CreateUpdate";
import EditReason from "./components/DashBoard/Reason/EditReason";
import EditNotification from "./components/DashBoard/Notification/EditNotification";
// import ViewLogDetails from "./components/DashBoard/QueueManagementSystem/ViewLogDetails";
import ViewClaimFiling from "./components/DashBoard/ClaimFilling/View";
import CreateUpdateClaimsFiling from "./components/DashBoard/ClaimFilling/CreateUpdateClaimsFiling";
import Verthe from "./verthe";
import Njan from "./njan";
import ViewEmailTemplate from "./components/DashBoard/EmailTemplate/ViewEmailTemplate";
import CreateUpdateEmailTemplate from "./components/DashBoard/EmailTemplate/CreateUpdateEmailTemplate";
import ViewDbk from "./components/DashBoard/Dbk";
import CreateUpdateDbk from "./components/DashBoard/Dbk/CreateUpdateDbk";
import SftpLogList from "./components/DashBoard/SftpLog";
import ViewOfflineStatus from "./components/DashBoard/OfflineStatus/view";
import EmailLogList from "./components/DashBoard/EmailLog";
import HouseKeepingLogList from "./components/DashBoard/HouseKeepingLog";
import SnippetView from "./components/DashBoard/Snippet/SnippetView";
import PayerIdList from "./components/DashBoard/PayerIdList";
import CreatePayerIdList from "./components/DashBoard/PayerIdList/CreatePayerIdList";

const router =
  createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorLoader />}>
        <Route
          errorElement={<ErrorLoader />}
          index
          path={INDEX}
          element={<Verthe />}
        />
        <Route
          errorElement={<ErrorLoader />}
          index
          path={"/njan"}
          element={<Njan />}
        />
        <Route
          errorElement={<ErrorLoader />}
          index
          path={LOGIN}
          element={<Login />}
        />
        <Route
          errorElement={<ErrorLoader />}
          index
          path={IFRAME}
          element={<Iframe title="iframe" />}
        />

        <Route
          errorElement={<ErrorLoader />}
          path={DASHBOARD}
          element={<Dashboard />}
        />

        {/* Roles managment starts */}
        <Route errorElement={<ErrorLoader />} path={ROLES} element={<Roles />} />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_ROLES}
          element={<Create />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_ROLES + ":role_id"}
          element={<EditRole />}
        />
        {/* Roles managment ends */}

        {/* group managment starts */}
        <Route
          errorElement={<ErrorLoader />}
          path={GROUPS}
          element={<Groups />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_GROUPS}
          element={<CreateGroup />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_GROUPS + ":group_id"}
          element={<EditGroup />}
        />
        {/* group managment ends */}

        {/* payee managment starts */}
        <Route errorElement={<ErrorLoader />} path={PAYEE} element={<Payee />} />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_PAYEE + ":payee_id"}
          element={<EditPayee />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={APPROVE_PAYEE + ":payee_id/:type"}
          element={<EditPayee />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_PAYEE}
          element={<CreatePayee />}
        />
        {/* payee managment ends */}

        {/* payer managment starts */}
        <Route errorElement={<ErrorLoader />} path={PAYER} element={<Payer />} />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_PAYER + ":payer_id"}
          element={<EditPayer />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_PAYER + ":payer_id/:type"}
          element={<EditPayer />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_PAYER}
          element={<CreatePayer />}
        />
        {/* payer managment ends */}
        {/* payer id list managment starts */}
        <Route errorElement={<ErrorLoader />} path={PAYER_ID_LIST} element={<PayerIdList />} />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_PAYER_ID_LIST + ":id"}
          element={<CreatePayerIdList />}
        />

        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_PAYER_ID_LIST}
          element={<CreatePayerIdList />}
        />
        {/* payer id list managment ends */}

        {/* user managment starts */}
        <Route errorElement={<ErrorLoader />} path={USERS} element={<Users />} />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_USERS + ":user_id"}
          element={<EditUser />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_USERS}
          element={<CreateUser />}
        />
        {/* payer managment ends */}

        {/* data entry Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={DATA_ENTRY}
          element={<DataEntry />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={QA_REVIEW}
          element={<DataEntry />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={SNIPPET}
          element={<SnippetView />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_WORK + ':job_id'}
          element={<DataEntry />}
        />
        {/* data entry Routes ends*/}

        {/* staff Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={FILE_SPLIT}
          element={<FileSplit />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={SINGLE_SPLIT + ":file_id"}
          element={<PdfViewer />}
        />
        {/* staff Routes ends*/}

        {/* client onboard Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={CLIENT}
          element={<ClientOnBoard />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_CLIENT}
          element={<CreateClient />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_CLIENT + ":id"}
          element={<CreateClient />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={COPY_CLIENT + ":copy_id"}
          element={<CreateClient />}
        />
        {/* client onboard Routes ends*/}

        {/* client group onboard Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={CLIENT_GROUP}
          element={<ClientGroup />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_CLIENT_GROUP}
          element={<CreateClientGroup />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_CLIENT_GROUP}
          element={<CreateClientGroup />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_ASSIGN_USERS}
          element={<CreateAssignUser />}
        />
        {/* client group onboard Routes ends*/}

        {/* Holiday calendar Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={HOLIDAY_CALENDAR}
          element={<HolidayCalendar />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_HOLIDAY_CALENDAR}
          element={<CreateCalendar />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_HOLIDAY_CALENDAR + ":id"}
          element={<CreateCalendar />}
        />
        {/* Holiday calendar Routes ends*/}

        {/* Reason codes Routes */}
        <Route path={REASON_CODES} element={<ReasonCode />} />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_REASON_CODES}
          element={<CreateReasonCode />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_REASON_CODES + ":id"}
          element={<CreateReasonCode />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_REASON_CODES + ":id/:type"}
          element={<CreateReasonCode />}
        />
        {/* Re errorElement={<ErrorLoader />}ason codes Routes ends*/}

        {/* X1 errorElement={<ErrorLoader />}2 codes Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={X12_CODES}
          element={<X12Code />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_X12_CODES}
          element={<CreateX12Code />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_X12_CODES + ":id"}
          element={<EditX12Code />}
        />
        {/* X12 codes Routes ends*/}

        {/* CORRESPONDENCE Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={CORRESPONDENCE_CODES}
          element={<CorrespondenceCategory />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_CORRESPONDENCE_CODE}
          element={<CreateCorrespondenceCategory />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_CORRESPONDENCE_CODE + ":id"}
          element={<EditCorrespondenceCategory />}
        />
        {/* CORRESPONDENCE END Routes */}

        {/* Reject reason Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={REJECT_REASONS}
          element={<RejectReason />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_REJECT_REASONS}
          element={<CreateRejectReason />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_REJECT_REASONS + ":id"}
          element={<CreateRejectReason />}
        />

        {/* Reject reasons END Routes */}

        {/* CORRESPONDENCE Routes */}
        <Route path={FACILITIES} element={<Facility />} />
        {/* CORRESPONDENCE END Routes */}

        {/* SFTP Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={SFTP_CLIENT_LIST}
          element={<SftpClientList />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={SFTP_LOG_LIST}
          element={<SftpLogList />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={HOUSE_KEEPING_LOG_LIST}
          element={<HouseKeepingLogList />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EMAIL_LOG_LIST}
          element={<EmailLogList />}
        />
        {/* SFTP END Routes */}

        {/* LOCKBOX Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={LOCKBOX}
          element={<AllLockbox />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={LOCKBOXFILES}
          element={<Lockbox />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={SINGLE_LOCKBOX_LOCKBOX_FILES + ":file_id"}
          element={<Lockbox />}
        />
        {/* LOCKBOX END Routes */}

        {/* SETTINGS Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={SETTINGS}
          element={<Settings />}
        />
        {/* SETTINGS END Routes */}

        {/* OFFLINE_STATUS Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={OFFLINE_STATUS}
          element={<ViewOfflineStatus />}
        />
        {/* OFFLINE_STATUS END Routes */}

        {/* QMS Routes */}
        <Route
          errorElement={<ErrorLoader />}
          path={QMS}
          element={<QueueManagementSystem />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={LOCKBOX_FILES_QMS + ":lockbox_file"}
          element={<QueueManagementSystem />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={QMSQACOMPlETED}
          element={<QaCompleted />}
        />
        {/* QMS END Routes */}

        {/* OutputSchema */}
        <Route
          errorElement={<ErrorLoader />}
          path={GET_PATIENT_PAY}
          element={<PatientPayList />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={GET_CORRESPONDANCE}
          element={<CorrespondanceList />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={GET_ENCOUNTER_LIST}
          element={<EncounterList />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={GET_835_LIST}
          element={<List835 />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={GET_ACKNOWLEDGMENT_LIST}
          element={<ListAcknowledgement />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={GET_835_LITE_LIST}
          element={<List835Lite />}
        />

        <Route
          errorElement={<ErrorLoader />}
          path={NOTIFICATION_LIST}
          element={<NotificationList />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={NOTIFICATION_EDIT + ":notification_id"}
          element={<EditNotification />}
        />

        {/* partner */}
        <Route
          errorElement={<ErrorLoader />}
          path={PARTNER_CREATE}
          element={<CreateUpdate />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={PARTNER_LIST}
          element={<View />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={PARTNER_EDIT + ":partner_id"}
          element={<CreateUpdate />}
        />

        {/* partner */}

        {/* DOCUMENT */}

        <Route
          errorElement={<ErrorLoader />}
          path={DOCUMENT_CLASSIFICATION}
          element={<ViewDocument />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_DOCUMENT}
          element={<CreateUpdateDocument />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_DOCUMENT + ":classification"}
          element={<EditDocument />}
        />

        {/* REASON */}
        <Route
          errorElement={<ErrorLoader />}
          path={REASON}
          element={<ViewReason />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_REASON}
          element={<CreateUpdateReason />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_REASON + ":reason_id"}
          element={<EditReason />}
        />
        {/* <Route
        errorElement={<ErrorLoader />}
        path={VIEW_LOG_DETAIL + ":log_id"}
        element={<ViewLogDetails />}
      /> */}
        <Route
          errorElement={<ErrorLoader />}
          path={CLAIMS_FILING}
          element={<ViewClaimFiling />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_CLAIMS_FILING}
          element={<CreateUpdateClaimsFiling />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_CLAIMS_FILING + ":claimsFiling_id"}
          element={<CreateUpdateClaimsFiling />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_CLAIMS_FILING + ":claimsFiling_id/:type"}
          element={<CreateUpdateClaimsFiling />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EMAIL_TEMPLATE}
          element={<ViewEmailTemplate />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_EMAIL_TEMPLATE}
          element={<CreateUpdateEmailTemplate />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_EMAIL_TEMPLATE + ":template_id"}
          element={<CreateUpdateEmailTemplate />}
        />
        {/* DOCUMENT */}
        <Route
          errorElement={<ErrorLoader />}
          path={DBK_LIST}
          element={<ViewDbk />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={CREATE_DBK}
          element={<CreateUpdateDbk />}
        />
        <Route
          errorElement={<ErrorLoader />}
          path={EDIT_DBK + ":id"}
          element={<CreateUpdateDbk />}
        />

        <Route path="*" element={<ErrorLoader />} />

      </Route>
    )
  );

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Root>
    {/* <Footer /> */}
    <RouterProvider router={router} />
  </Root>
);
