import React, { useEffect, useState } from 'react'
import Master from '../../../../Layout/admin/Master/index'
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import { createReasonCodes, getFacilityDropdown, getPayerDropdown, getPayerDropdownV2, getSingleReasonCode, getX12CodeDropdown } from '../../../../Redux/user/action';
import { useParams, useNavigate, } from 'react-router-dom';
import { REASON_CODES } from '../../../../routeNames';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import AsyncSelect from 'react-select/async';
import CheckImageModal from '../../Payee/checkImageModal';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import styled from 'styled-components';


const CreateReasonCode = () => {
    const navigate = useNavigate();
    const [showCheckView, setShowCheckView] = useState(false)

    const { id, type } = useParams()
    const [payers, setPayers] = useState([])
    const [clients, setClients] = useState([])
    const [facilities, setFacilities] = useState([])
    const [x12codes, setX12Codes] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [data, setData] = useState([])

    const [selectedFacility, setSelectedFacility] = useState([])
    const [state, setState] = useState({
        error: false,
        error_message: "",
        removed_ids: []
    })
    const [toaster, setToaster] = useState(false)

    const [toast, setToast] = useState({
        type: "",
        message: "",
        toast: false,

    });



    const validationSchema = Yup.object().shape({
        // payer_id: Yup.string()
        //     // .oneOf([payers.value],"Required")
        //     .required('Required'),
        clients: Yup.array()

            .nullable(),
        // facility: Yup.string()
        reason_code: Yup.string()
            .required('Required'),
        data: Yup.array().of(
            Yup.object().shape({
                description: Yup.string().required('Required'),
                carc: Yup.string().max(10, 'Only 10 characters').required('Required').matches(/^[ A-Za-z0-9_@./#&+-]*$/, 'Invalid carc'),
                rarc: Yup.string().max(10, 'Only 10 characters').matches(/^[ A-Za-z0-9_@./#&+-]*$/, 'Invalid rarc'),
                group_code: Yup.string().required('Required'),
            }))
    });

    const reasonformik = useFormik({
        initialValues: {
            payer_id: '',
            payer: '',
            reason_code: '',
            clients: [],
            facility: [],
            data: [{
                description: "",
                carc: "",
                rarc: "",
                group_code: "",
            }]
        },
        validationSchema,
        validate: (values) => {
            let errors = {}
            if (values?.payer_id == "") {
                errors.payer_id = "Required"
            }
            return errors
        },
        onSubmit: (values) => {
            __submitForm(values)
        },
    });
    useEffect(() => {
        getAllClientDropdown()((response) => {
            if (response?.status) {


                setClients(response?.data)
            }
        })
    }, []);


    useEffect(() => {
        if (reasonformik?.values?.clients && reasonformik?.values?.clients.length > 0) {
            getFacilityDropdown(reasonformik?.values?.clients)((response) => {
                if (response && response.status) {
                    setFacilities(response.data.facilities?.map(result => { return { options: result?.facilities, label: result?.client } }))
                }
            });
        }

    }, [selectValue])

    const handleOnSelectChange = async (e) => {

        await reasonformik.setValues({
            ...reasonformik.values,
            payer_id: e?.value ? e?.value : "",
            payer: e ? e : ""
        })
    }

    const formatOptionLabel = ({ value, label, is_logged_in }) => (
        <div className='d-flex flex-row justify-content-between align-items-center'>
            <div>{label}</div>
            {"is_logged_in" ?
                <div>

                </div>

                :
                <div>

                </div>
            }
        </div>
    );

    const options = [
        // { value: "all", label: "All" },
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];
    useEffect(() => {
        if (id) {
            getSingleReasonCode(id)((response) => {

                if (response?.status && response?.data) {
                    const data = [];
                    const groupOption = group_options.map(i => i.value)
                    response.data?.x12code?.map((reason, index) => (
                        data.push({
                            id: reason.id,
                            description: reason.description,
                            carc: reason.carc,
                            rarc: reason.rarc,
                            group_code: groupOption?.includes(reason.group_code) ? reason.group_code : "",
                        })
                    ))
                    let payer = payers.filter((i) => i.label?.trim() == response?.data?.payer_id)
                    reasonformik.setValues({
                        ...reasonformik.values,
                        payer_id: response?.data?.payer_id?.value ? response?.data?.payer_id?.value : '',
                        payer: response?.data?.payer_id ? response?.data?.payer_id : '',
                        reason_code: response?.data?.reason_code,
                        data: data,
                        clients: response?.data?.client?.map((data) => data.value),
                        facility: response?.data?.facility?.map((data) => data?.value),

                    })
                    setSelectedFacility(response?.data?.facility);
                    setSelectValue(response?.data?.client)
                    setData(response?.data)
                }
            });

        }
    }, [id])

    const __toggleToast = (type, message, toast) => {
        setToast({
            type: type,
            message: message,
            toast: toast,
        })
        const timer = setTimeout(() => {
            setToast({
                type: false,
                message: '',
                toast: false,
            })
        }, 2000);
        return () => clearTimeout(timer);

    }
    const __submitForm = async (values) => {
        const RedirectId = getRedirectTabId()
        const data_from = 1;
        let formData = new FormData();
        formData.append('reason_code', values.reason_code.toUpperCase())
        formData.append('payer_id', values.payer_id)
        formData.append('data', JSON.stringify(values.data))
        formData.append('data_from', data_from)
        if (id) {
            var reasoncodes = id
        } else {
            var reasoncodes = ""

        }
        formData.append('removed_ids', state.removed_ids)
        if (type == 'approve') {

            var is_approved = true
        }
        else {
            var is_approved = false

        }
        await createReasonCodes(values.reason_code.toUpperCase(), values.payer_id, values.data, data_from, reasoncodes, state.removed_ids, is_approved, values.clients, values.facility)((response) => {
            if (response && response.status) {
                __toggleToast("success", response?.message, true);
                setTimeout(() => {

                    changeAllTitleFunction("Reason Codes", REASON_CODES, RedirectId)
                }, 2000);
            }
            else {
                __toggleToast("error", response?.response?.data?.errors?.description[0] ? response?.response?.data?.errors?.description[0] : "12123123", true)
                return () => clearTimeout(timer);
            }
        });
    }

    const handleRemoveItems = (index, arrayHelpers, value) => {

        let removed_ids = [...state.removed_ids]
        var data = reasonformik.values.data;
        data = data.filter((i) => i != value)
        reasonformik.setValues({ ...reasonformik.values, 'data': data })
        if (value?.id) {
            removed_ids.push(String(value?.id))
            setState({ ...state, removed_ids })
        }
    }

    const handleSelectChange = (selected) => {
        // console.log(options);
        let selectedIds = [];
        let selectedOptions = [];

        if (selected && selected?.length > 0) {

            selected?.map((option) => {
                selectedIds.push(option.value)
                selectedOptions.push(option)
            })
            if (selected?.length > 1) {
                setSelectedFacility([])
                setFacilities([])

            }
        }
        else {
            selectedOptions = [];
            selectedIds = [];
            setFacilities([])
            setSelectedFacility([])
        }

        reasonformik.setValues({ ...reasonformik.values, "clients": selectedIds ?? "", "facility": selected?.length > 1 ? "" : selectedFacility });



        setSelectValue(selectedOptions ?? "")
    };
    const handleFacilityChange = (selected) => {
        reasonformik.setFieldValue("facility", selected.map((option) => option?.value));
        setSelectedFacility(selected)


    };

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {reasonformik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}
            </components.DropdownIndicator>
        );
    };

    window.onkeyup = function (event) {
        if (event.keyCode === 27) {
            setShowCheckView(false)
        }
    }
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);


    // const loadOptions = async (inputValue, callback) => {
    //     try {
    //         // Replace with your API endpoint
    //         getPayerDropdown(1, inputValue)((response) => {
    //             if (response && response.status && response.data && response.data.results) {
    //                 setPayers(response.data.results)


    //                 callback(response.data.results);
    //             }
    //         });


    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // };

    let typingTimer;
    const loadOptions = inputValue => {
        return new Promise((resolve, reject) => {

            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if (inputValue.length >= 3) {
                    getPayerDropdownV2(1, inputValue.toUpperCase(), reasonformik.values?.clients)((response) => {
                        if (response && response.status && response.data && response.data.results) {
                            setPayers(response.data.results)

                            resolve(response.data.results);

                        }
                    });
                }
                else {
                    resolve([]);
                }

            }, 300);
        });
    };
    const changeDatavalue = (e) => {
        e.target.value = e.target.value.toUpperCase();
        reasonformik.handleChange(e)
    }

    const group_options = [
        { label: '-Select-', value: '' },
        { label: 'CO', value: 'CO' },
        { label: 'OA', value: 'OA' },
        { label: 'PI', value: 'PI' },
        { label: 'PR', value: 'PR' },
    ]
    return (

        <React.Fragment>
            <Master
            // ToastMessage={toast}
            />
            <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg="success" show={toast.type == 'success' ? true : false} animation={true} delay={2000} autohide>

                    <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                </Toast>
                <Toast bg="danger" show={toast.type == 'error' ? true : false} onClose={() => setToast({ toast: false })} animation={true} delay={2000} autohide>

                    <Toast.Body ><span className='text-white'>{toast.message ? toast.message : "Failed"}</span></Toast.Body>
                </Toast>
            </ToastContainer>
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1 className='text-uppercase'>{type == 'approve' && id ? "Approve Reason Code" : id ? 'Edit Reason Code' : 'Create Reason Code'}</h1>
                </div>
                <MainContainer className="section mt-5">
                    <div className="card card-flush py-4">
                        <div className="card-body pt-0 ">
                            <FormikProvider value={reasonformik}>
                                <Form onSubmit={reasonformik.handleSubmit}>
                                    <div className='d-flex justify-content-around'>
                                        <div className='col-lg-5'>
                                            <div className="mb-10">
                                                <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Clients</label>


                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isMulti
                                                    value={selectValue}
                                                    name="clients"
                                                    options={options}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                    onChange={handleSelectChange}
                                                    placeholder="Select Clients..."
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px", minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),
                                                        placeholder: (styles) => ({
                                                            ...styles,
                                                            textTransform: 'uppercase' // Change placeholder text to uppercase
                                                        })
                                                    }} />

                                                {!!reasonformik.errors?.clients &&
                                                    <span className='text-danger'>{reasonformik.errors?.clients}</span>
                                                }
                                            </div>
                                        </div>
                                        {reasonformik.values?.clients != "ALL" &&
                                            <div className='col-lg-6'>
                                                <div className="mb-10">
                                                    <Form.Group>
                                                        <Form.Label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Facility</Form.Label>
                                                        {/* <Form.Control
                                                            type="text"
                                                            name='facility'
                                                            required
                                                            isValid={reasonformik.touched.facility && !reasonformik?.errors?.facility}
                                                            isInvalid={!!reasonformik.errors?.facility}
                                                            onChange={reasonformik.handleChange}
                                                            className="form-control form-control-solid b-r-10"
                                                            placeholder="Reason Code"
                                                            value={reasonformik.values?.facility}
                                                        /> */}
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isMulti
                                                            value={selectedFacility}
                                                            name="facility"
                                                            options={facilities}
                                                            onChange={handleFacilityChange}
                                                            placeholder="Select Facility..."
                                                            isDisabled={selectValue.length > 1 ? true : false}
                                                            components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="facility" /> }}
                                                            onFocus={() => setIsFocusedFacility(true)}
                                                            onBlur={() => setIsFocusedFacility(false)}
                                                            styles={{
                                                                container: (styles, state) => ({
                                                                    ...styles,
                                                                    borderRadius: "9px",
                                                                }),
                                                                control: (styles, state) => ({
                                                                    ...styles,
                                                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                    borderStyle: "none", boxShadow: "none",
                                                                    borderRadius: "10px",
                                                                    maxHeight: state.isFocused ? "auto" : "30px",
                                                                }),
                                                                indicatorSeparator: styles => ({
                                                                    ...styles, width: "0px", minHeight: "20px"
                                                                }),

                                                                valueContainer: (styles, state) => ({
                                                                    ...styles,
                                                                    maxHeight: isFocusedFacility ? "auto" : "30px",
                                                                }),
                                                                placeholder: (styles) => ({
                                                                    ...styles,
                                                                    textTransform: 'uppercase' // Change placeholder text to uppercase
                                                                })


                                                            }} />
                                                        <Form.Control.Feedback type="invalid">{reasonformik?.errors?.facility}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='d-flex justify-content-around mt-2'>
                                        <div className='col-lg-5'>
                                            <div className="mb-10">
                                                <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Payer</label>
                                                {/* <Form.Control as="select"
                                                    className='form-control form-control-solid b-r-10'
                                                    name="payer_id"
                                                    isValid={reasonformik.touched.payer_id && !reasonformik?.errors?.payer_id}
                                                    isInvalid={!!reasonformik.errors?.payer_id}
                                                    onChange={(e) => handleOnSelectChange(e, 'payer_id')}
                                                    value={reasonformik.values?.payer_id}

                                                >
                                                    <option value="">Select an Option</option>
                                                    {payers?.map((payer, index) => (

                                                        <option key={index} value={payer.value}>{payer.label}</option>
                                                    ))}
                                                </Form.Control> */}
                                                <AsyncSelect
                                                    // cacheOptions
                                                    loadOptions={loadOptions}
                                                    defaultOptions={reasonformik.values?.payer}
                                                    className="basic-single"
                                                    placeholder="select payer..."
                                                    classNamePrefix="select"
                                                    isClearable={true}
                                                    onChange={(e) => handleOnSelectChange(e, 'payer_id')}
                                                    isDisabled={reasonformik.values?.clients?.length ? false : true}
                                                    value={reasonformik.values?.payer}
                                                    styles={{

                                                        placeholder: (styles) => ({
                                                            ...styles,
                                                            textTransform: 'uppercase' // Change placeholder text to uppercase
                                                        })


                                                    }}
                                                // debounceInterval={1000}
                                                />
                                                {!!reasonformik.errors?.payer_id &&
                                                    <span className='text-danger'>{reasonformik.errors?.payer_id}</span>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="mb-10">
                                                <Form.Group>
                                                    <Form.Label htmlFor="exampleFormControlInput1" className=" form-label">Reason Code</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name='reason_code'

                                                        isValid={reasonformik.touched.reason_code && !reasonformik?.errors?.reason_code}
                                                        isInvalid={!!reasonformik.errors?.reason_code}
                                                        onChange={reasonformik.handleChange}
                                                        className="form-control form-control-solid b-r-10 text-uppercase"
                                                        placeholder="Reason Code"
                                                        value={reasonformik.values?.reason_code}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{reasonformik?.errors?.reason_code}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    <FieldArray name="data"
                                        render={(arrayHelpers) => (
                                            <React.Fragment>
                                                {reasonformik?.values?.data.map((value, index) => (
                                                    <div key={index}>
                                                        <div className='d-flex mt-2 justify-content-around gap-2 align-items-center'>
                                                            <div className='col-lg-2'>
                                                                <div className="mb-10">
                                                                    <Form.Group>
                                                                        {index == 0 &&
                                                                            <Form.Label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Description</Form.Label>
                                                                        }
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            name={`data[${index}].description`}
                                                                            // isValid={reasonformik?.errors && reasonformik?.errors?.data && !reasonformik?.errors?.data[index]?.description}
                                                                            isInvalid={reasonformik?.errors && reasonformik?.errors?.data && !!reasonformik.errors?.data[index]?.description}
                                                                            onChange={(e) => changeDatavalue(e)}
                                                                            className="form-control form-control-solid b-r-10 text-uppercase"
                                                                            placeholder="Description"
                                                                            value={reasonformik.values?.data[index]?.description}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">{reasonformik?.errors && reasonformik?.errors?.data && reasonformik?.errors?.data[index]?.description}</Form.Control.Feedback>
                                                                    </Form.Group>
                                                                </div>
                                                            </div>

                                                            <div className='col-lg-2'>
                                                                <div className="mb-10">
                                                                    {index == 0 &&
                                                                        <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Group Code</label>
                                                                    }
                                                                    <Form.Control as="select"
                                                                        className='form-control form-control-solid b-r-10 text-uppercase'
                                                                        name={`data[${index}].group_code`}
                                                                        // isValid={reasonformik?.errors && reasonformik?.errors?.data && !reasonformik?.errors?.data[index]?.group_code}
                                                                        isInvalid={reasonformik?.errors && reasonformik?.errors?.data && !!reasonformik.errors?.data[index]?.group_code}
                                                                        value={reasonformik.values?.data[index]?.group_code}

                                                                        placeholder="select an option"
                                                                        onChange={(e) => changeDatavalue(e)}
                                                                    >
                                                                        {group_options.map(i =>
                                                                            <option value={i.value}>{i.label}</option>
                                                                        )}
                                                                        {/* <option value="">-Select-</option>
                                                                        <option value="CO">CO</option>
                                                                        <option value="OA">OA</option>
                                                                        <option value="PI">PI</option>
                                                                        <option value="PR">PR</option> */}
                                                                    </Form.Control>
                                                                    <Form.Control.Feedback type="invalid">{reasonformik?.errors && reasonformik?.errors?.data && reasonformik?.errors?.data[index]?.group_code}</Form.Control.Feedback>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <div className="mb-10">
                                                                    {index == 0 &&
                                                                        <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">CARC</label>
                                                                    }
                                                                    <Form.Control
                                                                        className="form-control form-control-solid b-r-10 text-uppercase"
                                                                        classNamePrefix="select"
                                                                        name={`data[${index}].carc`}
                                                                        // isValid={reasonformik?.errors && reasonformik?.errors?.data && !reasonformik?.errors?.data[index]?.carc}
                                                                        isInvalid={reasonformik?.errors && reasonformik?.errors?.data && !!reasonformik.errors?.data[index]?.carc}

                                                                        maxLength={10}
                                                                        placeholder="CARC"
                                                                        onChange={(e) => changeDatavalue(e)}
                                                                        value={reasonformik.values?.data[index]?.carc}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{reasonformik?.errors && reasonformik?.errors?.data && reasonformik?.errors?.data[index]?.carc}</Form.Control.Feedback>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-2'>
                                                                <div className="mb-10">
                                                                    {index == 0 &&
                                                                        <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">RARC</label>
                                                                    }
                                                                    <Form.Control
                                                                        className="form-control form-control-solid b-r-10 text-uppercase"
                                                                        classNamePrefix="select"
                                                                        name={`data[${index}].rarc`}
                                                                        // isValid={reasonformik?.errors && reasonformik?.errors?.data && !reasonformik?.errors?.data[index]?.rarc}
                                                                        isInvalid={reasonformik?.errors && reasonformik?.errors?.data && !!reasonformik.errors?.data[index]?.rarc}
                                                                        placeholder="RARC"
                                                                        maxLength={10}
                                                                        onChange={(e) => changeDatavalue(e)}
                                                                        value={reasonformik.values?.data[index]?.rarc}
                                                                    />
                                                                    <Form.Control.Feedback type="invalid">{reasonformik?.errors && reasonformik?.errors?.data && reasonformik?.errors?.data[index]?.rarc}</Form.Control.Feedback>
                                                                </div>
                                                            </div>

                                                            <div className='pt-3'>
                                                                <button
                                                                    className='btn btn-danger '
                                                                    type="button"
                                                                    disabled={reasonformik.values?.data?.length === 1}
                                                                    onClick={() => handleRemoveItems(index, arrayHelpers, value)}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className='pt-4'>
                                                    <button
                                                        className='text-uppercase btn btn-light btn-active-primary'
                                                        type="button"
                                                        onClick={() => arrayHelpers.push({ description: "", carc: '', rarc: "", group_code: "" })}
                                                    >
                                                        Add New
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        )} />
                                    {state.error &&
                                        <div className='text-center mt-3'>
                                            <span className='text-danger fw-bold '>{state.error_message}</span>
                                        </div>
                                    }
                                    <div className='row mt-2'>
                                        <div className='d-flex flex-row justify-content-end'>
                                            {data?.job_id &&
                                                <div className='me-2'>
                                                    <p
                                                        className='pt-2'
                                                        style={{ fontSize: '0.9375rem', color: '#6ba2b6' }}
                                                    >
                                                        {data?.job_id}
                                                    </p>
                                                </div>
                                            }

                                            {data.image_url &&
                                                <div className=''>
                                                    <button
                                                        type='button'
                                                        className='text-uppercase btn btn-secondary btn-active-primary me-2'
                                                        onClick={() => { setShowCheckView(true) }}
                                                    >
                                                        Image
                                                    </button>
                                                </div>
                                            }
                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='text-uppercase btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("Reason Codes", REASON_CODES) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <button
                                                type='submit'
                                                className='text-uppercase btn btn-primary btnHover new-window'
                                                disabled={!reasonformik.isValid}
                                            >
                                                {id ?
                                                    type == 'approve' ?
                                                        'Approve'
                                                        :
                                                        'Update'
                                                    :
                                                    'Create'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </FormikProvider>
                        </div>
                    </div>
                </MainContainer>
                {/* Check View Modal Start */}
                {showCheckView && data.image_url &&
                    <CheckImageModal fileUrl={data.image_url} setShowCheckView={setShowCheckView} showCheckView={showCheckView} />

                }

                {/* Check View Modal Start */}
            </main>
        </React.Fragment >
    )
}

export default CreateReasonCode;

const MainContainer = styled.div`
    label{
        text-transform:uppercase
    }
`