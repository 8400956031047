import React, { useEffect, useState } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap';
import Master from '../../../../Layout/admin/Master';
import { FieldArray, Formik, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { createCorrespondenceCategory, createX12Code, getFacilityDropdown, getX12CodeDropdown } from '../../../../Redux/user/action';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CORRESPONDENCE_CODES } from '../../../../routeNames';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';

const Create = () => {
    const navigate = useNavigate()
    const [toaster, setToaster] = useState(false)
    const [message, setMessage] = useState(false)
    const [x12Codes, setX12Codes] = useState([])
    const [state, setState] = useState({
        error: false,
        error_message: ""
    })
    // const CategorySchema = Yup.object().shape({
    //     categories: Yup.array().of(
    //         Yup.object().shape({
    //             category: Yup.string().matches(/^[ A-Za-z0-9_@./#&+-]*$/, 'Enter Valid Category Codes').required('Required')
    //         })
    //     )

    //         .required('Required'),



    // });
    const [clients, setClients] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [facilities, setFacilities] = useState([])
    const [selectedFacility, setSelectedFacility] = useState([])

    useEffect(() => {

        getAllClientDropdown()((response) => {
            if (response?.status) {


                setClients(response?.data)
            }
        })

    }, []);
    useEffect(() => {
        if (categoryformik?.values?.clients && categoryformik?.values?.clients.length > 0) {
            getFacilityDropdown(categoryformik?.values?.clients)((response) => {
                if (response && response.status) {
                    setFacilities(response.data.facilities?.map(result => { return { options: result?.facilities, label: result?.client } }))
                }
            });
        }

    }, [selectValue])
    const [facilitySelected, setFacilitySelected] = useState([])
    const handleSelectChange = (selected) => {

        let selectedIds = [];
        let selectedOptions = [];

        if (selected && selected?.length > 0) {

            selected?.map((option) => {
                selectedIds.push(option.value)
                selectedOptions.push(option)
            })
            if (selected?.length > 1) {
                setSelectedFacility([])
                setFacilities([])
                setFacilitySelected([])
            }
        }
        else {
            selectedOptions = [];
            selectedIds = [];
            setFacilities([])
            setSelectedFacility([])
        }
        categoryformik.setValues({ ...categoryformik.values, "clients": selectedIds ?? "", "facility": selected?.length > 1 ? "" : facilitySelected });
        setSelectValue(selectedOptions ?? "")
    };
    const handleFacilityChange = (selected) => {
        categoryformik.setFieldValue("facility", selected.map((option) => option?.value ?? ""));

        setSelectedFacility(selected ?? "")
        setFacilitySelected(selected.map((option) => option?.value ?? ""))


    };

    const options = [
        // { value: "all", label: "All" },
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];
    const categoryformik = useFormik({
        initialValues: {
            clients: "",
            facility: "",
            categories: [{
                category: ""
            }]
        },
        validationSchema: Yup.object().shape({

            categories: Yup.array().of(
                Yup.object().shape({
                    category: Yup.string().matches(/^[ A-Za-z0-9]*$/, 'Enter Valid Category Codes').required('Required')
                })
            ),

        }),
        onSubmit: (values) => {
            __submitForm(values)
        },
    });
    useEffect(() => {
        getX12CodeDropdown(1)((response) => {
            // console.log(response);
            setX12Codes(response?.data?.results)
        });
        // getReasonCodes();
    }, []);


    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);


    const __submitForm = (values) => {
        // console.log(values);
        const client = values?.clients ? values?.clients : [];
        // const facility = values?.facility ? values?.facility : [];
        const RedirectId = getRedirectTabId()
        createCorrespondenceCategory(client, facilitySelected, values.categories)((response) => {

            if (response && response.status) {
                setToaster('success')
                const timer = setTimeout(() => {
                    setToaster(false)
                    changeAllTitleFunction("Correspondence Category", CORRESPONDENCE_CODES, RedirectId)
                }, 2000);
                return () => clearTimeout(timer);
            }
            else {
                // console.log(response?.response?.data?.errors?.category[0]);
                if (response?.response?.data?.errors?.category && response?.response?.data?.errors?.category[0]) {
                    setMessage(response?.response?.data?.errors?.category[0])


                    // categoryformik.setErrors({

                    //     categories: [...categories, {
                    //         category: response?.response?.data?.errors?.category
                    //     }]
                    // })
                }

                setToaster('error')
                const timer = setTimeout(() => {
                    setToaster(false)
                    setMessage(false)
                }, 2000);
                return () => clearTimeout(timer);
            }

        });
    }

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {categoryformik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}
            </components.DropdownIndicator>
        );
    };

    const handleDataChange = (value, index) => {
        var data = categoryformik.values.categories;
        data[index] = { category: value };
        categoryformik.setValues({ ...categoryformik.values, 'categories': data })
    }
    // console.log(message)
    return (
        <React.Fragment>
            <Master ToastMessage={toaster} message={message} />
            <main id="main" className="main">
                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" onClose={() => setToaster(!toaster)} show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{message}</span></Toast.Body>
                    </Toast>
                </ToastContainer> */}

                <div className="pagetitle tab-fixed-header ">
                    <h1 id='page-title'>Create Correspondence Category</h1>
                </div>
                <section className="section mt-5">
                    <div className="row align-items-top py-3   gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-body py-3 ">

                                    <FormikProvider value={categoryformik}>
                                        <Form
                                            onSubmit={categoryformik.handleSubmit}
                                        >
                                            <div className="mb-3 " >

                                                <div className='row'>

                                                    <div className='col-lg-5'>
                                                        <div className="mb-10">
                                                            <Form.Label className='fw-bold'>Client</Form.Label>


                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                isMulti
                                                                value={selectValue}
                                                                name="clients"
                                                                options={options}
                                                                components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                                onChange={handleSelectChange}
                                                                placeholder="Select Clients..."
                                                                onFocus={() => setIsFocusedClient(true)}
                                                                onBlur={() => setIsFocusedClient(false)}
                                                                styles={{
                                                                    container: (styles, state) => ({
                                                                        ...styles,
                                                                        borderRadius: "9px",
                                                                    }),
                                                                    control: (styles, state) => ({
                                                                        ...styles,
                                                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                        borderStyle: "none", boxShadow: "none",
                                                                        borderRadius: "10px",
                                                                        maxHeight: state.isFocused ? "auto" : "30px",
                                                                    }),
                                                                    indicatorSeparator: styles => ({
                                                                        ...styles, width: "0px", minHeight: "20px"
                                                                    }),

                                                                    valueContainer: (styles, state) => ({
                                                                        ...styles,
                                                                        maxHeight: isFocusedClient ? "auto" : "30px",
                                                                    }),
                                                                }}
                                                            />

                                                            {!!categoryformik.errors?.clients &&
                                                                <span className='text-danger'>{categoryformik.errors?.clients}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <div className="mb-10">
                                                            <Form.Group>
                                                                <Form.Label className='fw-bold'>Facility</Form.Label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    isMulti
                                                                    value={selectedFacility}
                                                                    name="facility"
                                                                    options={facilities}
                                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="facility" /> }}
                                                                    onChange={handleFacilityChange}
                                                                    placeholder="Select Facility..."
                                                                    isDisabled={selectValue.length > 1 ? true : false}
                                                                    onFocus={() => setIsFocusedFacility(true)}
                                                                    onBlur={() => setIsFocusedFacility(false)}
                                                                    styles={{
                                                                        container: (styles, state) => ({
                                                                            ...styles,
                                                                            borderRadius: "9px",
                                                                        }),
                                                                        control: (styles, state) => ({
                                                                            ...styles,
                                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                            borderStyle: "none", boxShadow: "none",
                                                                            borderRadius: "10px",
                                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                                        }),
                                                                        indicatorSeparator: styles => ({
                                                                            ...styles, width: "0px", minHeight: "20px"
                                                                        }),

                                                                        valueContainer: (styles, state) => ({
                                                                            ...styles,
                                                                            maxHeight: isFocusedFacility ? "auto" : "30px",
                                                                        }),


                                                                    }}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{categoryformik?.errors?.facility}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='pt-3 '>
                                                    <Form.Label className='fw-bold'>Category</Form.Label>

                                                </div>
                                                <FieldArray name="categories"
                                                    render={(arrayHelpers) => (
                                                        <React.Fragment>
                                                            <div className='d-flex flex-row gap-2'>

                                                                <div className='d-flex flex-column justify-content-start  gap-2 flex-wrap' >

                                                                    {categoryformik?.values?.categories.map((value, index) => (
                                                                        <React.Fragment>
                                                                            <div key={index} >
                                                                                <div className='d-flex flex-row justify-content-start gap-3 '  >
                                                                                    <div className='' style={{ width: '38rem' }}>
                                                                                        <Form.Group>
                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                name={`categories[${index}].category`}
                                                                                                isValid={categoryformik?.errors?.categories && categoryformik?.errors?.categories[index] && categoryformik?.errors?.categories[index]?.category && !categoryformik?.errors?.categories[index]?.category && categoryformik?.touched?.categories && categoryformik?.touched?.categories[index]?.category}
                                                                                                isInvalid={categoryformik?.errors?.categories && categoryformik?.errors?.categories[index] && categoryformik?.errors?.categories[index]?.category && !!categoryformik.errors?.categories[index]?.category}
                                                                                                onChange={(e) => handleDataChange(e.target.value, index)}
                                                                                                className="form-control form-control-solid b-r-10"
                                                                                                value={value.category}
                                                                                                placeholder="Category"
                                                                                            />
                                                                                            <Form.Control.Feedback type="invalid">{categoryformik?.errors?.categories && categoryformik?.errors?.categories[index] && categoryformik?.errors?.categories[index]?.category && categoryformik.errors?.categories[index]?.category}</Form.Control.Feedback>
                                                                                        </Form.Group>
                                                                                    </div>
                                                                                    <div className=''>

                                                                                        <button
                                                                                            className='btn btn-danger'
                                                                                            type="button"
                                                                                            disabled={categoryformik?.values?.categories?.length == 1 ? true : false}
                                                                                            onClick={() => arrayHelpers.remove(index)}
                                                                                        >
                                                                                            <i className='bi bi-trash'></i>
                                                                                        </button>



                                                                                    </div>
                                                                                    {categoryformik?.values?.categories?.length - 1 == index ?
                                                                                        <div>
                                                                                            <button
                                                                                                className='btn btn-primary'
                                                                                                style={{ backgroundColor: '#6ba2b6', borderColor: '#6ba2b6', height: '36px' }}
                                                                                                type="button"
                                                                                                onClick={() => { arrayHelpers.push({ category: "" }); }}
                                                                                            >
                                                                                                +
                                                                                            </button>
                                                                                        </div>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                        </React.Fragment>
                                                    )}
                                                />
                                            </div>

                                            {/* {state.error &&
                                                <div className='text-center mt-3'>

                                                    <span className='text-danger fw-bold '>{state.error_message}</span>
                                                </div>
                                            } */}
                                            <div className='row mt-2'>
                                                <div className='d-flex flex-row justify-content-end'>
                                                    <div className=''>
                                                        <button
                                                            type='button'
                                                            className='btn btn-secondary btn-active-primary me-2'
                                                            onClick={() => { changeAllTitleFunction("Correspondence Category", CORRESPONDENCE_CODES) }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <button
                                                        disabled={!categoryformik.isValid}
                                                        type='submit'
                                                        className='btn btn-primary btnHover new-window'
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </FormikProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment >
    )
}

export default Create