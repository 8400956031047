import React, { useState } from 'react'
import Board, { moveCard } from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import { Button, Card, Form } from 'react-bootstrap';

const Drag = ({ type, values, errors, formik, handleDrag, handleSwitchChange, addReportFileFormat }) => {


    return (
        <>
            <div className='row pb-3 d-flex align-items-start' >


                <div className="col-lg-2 mt-2 ">
                    <Form.Check
                        as="input"
                        inline
                        type="switch"
                        reverse={true}
                        onChange={(e) => handleSwitchChange(e, type)}
                        checked={values?.is_snippet}
                        isInvalid={
                            errors?.is_snippet &&
                            !!errors?.is_snippet
                        }

                        feedbackType="invalid"

                        feedback={
                            errors?.is_snippet &&
                            errors?.is_snippet
                        }
                        id="is_snippet"
                        name="is_snippet"
                        label={`${values?.name} Snippet?`}
                    />
                </div>
                {values.is_snippet ?
                    <>
                        <div className='col-lg-4'>
                            <Form.Group className="" >
                                <div className='d-flex flex-row align-items-center'>
                                    <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[File Name]', 'file_naming_format', type)}>File Name</Button>{' '}
                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[Client ID]', 'file_naming_format', type)}>Client ID</Button>{' '}
                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[Client Name]', 'file_naming_format', type)}>Client Name</Button>{' '}
                                </div>

                                <Form.Control
                                    name={`${type}.file_naming_format`}
                                    type="text"
                                    autoComplete="false"
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    placeholder="File Naming Formats"
                                    isValid={errors?.file_naming_format && !!errors?.file_naming_format}
                                    isInvalid={!!errors?.file_naming_format}
                                    value={values?.file_naming_format}
                                    onChange={formik.handleChange}
                                />
                                <Form.Control.Feedback type="invalid">{errors?.file_naming_format}</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className='col-lg-2'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Folder Path</Form.Label>
                                <Form.Control
                                    name={`${type}.file_path`}
                                    className='b-r-10 form-control-solid form-control'
                                    maxLength="255"
                                    isValid={errors?.file_path && !!errors?.file_path}
                                    isInvalid={!!errors?.file_path}
                                    value={values?.file_path}
                                    onChange={formik.handleChange}
                                    placeholder="Folder Path"
                                />
                                <Form.Control.Feedback type="invalid">{errors?.file_path}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                        {!["MULTI_PAGE_PDF", "MULTI_PAGE_TIFF", "MULTI_FILE_PDF", "MULTI_FILE_TIFF"].includes(`${formik.values?.output_file_type}_${formik.values?.pdf_tiff_type}`)
                            &&
                            <div className="col-lg-1 mt-2">
                                <Form.Check
                                    as="input"
                                    inline
                                    type="switch"
                                    reverse={true}
                                    onChange={(e) => handleSwitchChange(e, type)}
                                    checked={values?.masking}
                                    isInvalid={
                                        errors?.masking &&
                                        !!errors?.masking
                                    }

                                    feedbackType="invalid"

                                    feedback={
                                        errors?.masking &&
                                        errors?.masking
                                    }
                                    id="masking"
                                    name="masking"
                                    label="Masking"
                                />
                            </div>
                        }

                        {values?.masking ?
                            <>
                                <div className="col-lg-3 mt-2 d-flex align-items-center">
                                    <span>Mask color</span>
                                    <input type="color" class="form-control form-control-color ms-2 custom-color-picker" id="color" name={`${type}.color`} onChange={formik.handleChange} value={values.color} />

                                    <Form.Check
                                        as="input"
                                        inline
                                        type="switch"
                                        reverse={true}
                                        onChange={(e) => handleSwitchChange(e, type)}
                                        checked={values?.inverse}
                                        isInvalid={
                                            errors?.inverse &&
                                            !!errors?.inverse
                                        }

                                        feedbackType="invalid"

                                        feedback={
                                            errors?.inverse &&
                                            errors?.inverse
                                        }
                                        id="inverse"
                                        name="inverse"
                                        label="Inverse"
                                    />
                                </div>
                            </>
                            :
                            <>
                                {type == 'eob835' ?
                                    <>
                                        <div className="col-lg-2 mt-2">
                                            <Form.Check
                                                as="input"
                                                inline
                                                type="switch"
                                                reverse={true}
                                                onChange={(e) => handleSwitchChange(e, type)}
                                                checked={values?.claim_wise}
                                                isInvalid={
                                                    errors?.claim_wise &&
                                                    !!errors?.claim_wise
                                                }

                                                feedbackType="invalid"

                                                feedback={
                                                    errors?.claim_wise &&
                                                    errors?.claim_wise
                                                }
                                                id="claim_wise"
                                                name="claim_wise"
                                                label="Claim Wise"
                                            />
                                        </div>
                                        {values?.claim_wise &&
                                            <div className="col-lg-2 mt-2">
                                                <Form.Check
                                                    as="input"
                                                    inline
                                                    type="switch"
                                                    reverse={true}
                                                    onChange={(e) => handleSwitchChange(e, type)}
                                                    checked={values?.group_static}
                                                    isInvalid={
                                                        errors?.group_static &&
                                                        !!errors?.group_static
                                                    }

                                                    feedbackType="invalid"

                                                    feedback={
                                                        errors?.group_static &&
                                                        errors?.group_static
                                                    }
                                                    id="group_static"
                                                    name="group_static"
                                                    label="Group Static"
                                                />
                                            </div>
                                        }
                                    </>

                                    :
                                    <>

                                    </>
                                }
                            </>
                        }



                    </>
                    :
                    <></>
                }
            </div>
            <div>
                {values.is_snippet && !values?.masking ?
                    <div className='col-lg-12'>
                        <Board
                            disableColumnDrag={true}
                            renderCard={({ content }, { removeCard, dragging }) => (
                                <Card className='custom-kanban-card' dragging={dragging}>
                                    {content}
                                </Card>
                            )}
                            onCardDragEnd={(card, source, destination) => handleDrag(card, source, destination, type)}
                        >
                            {values.board}
                        </Board>

                    </div>
                    :
                    <></>
                }
            </div>
            <hr className='custom-hr' />

        </>
    )
}

export default Drag
