import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components/macro';
import { getAllTimeZone } from '../../../Redux/header/action';
import Select from 'react-select';
import { DASHBOARD, IFRAME } from '../../../routeNames';
import { useNavigate } from 'react-router';
import Swal from "sweetalert2";
import "../../../assets/css/dashboard.css"
import DeleteSweetAlert from '../../SweetAlert/DeleteSweetAlert';
import { useSelector } from 'react-redux';

const ChangeTimeZone = ({ closeModal, setShowToastMessage, timeZone, formik, selectedOption, setSelectedOption, selectedTimezoneOptions }) => {
    const user = useSelector((state) => state?.auth?.user);
    //const [selectedTimezoneOptions, setSelectedTimezoneOptions] = useState([])

    //const [timeZoneList, setTimeZoneList] = useState();



    // const optionData = [
    //     { value: 1, label: "UTC" },
    //     { value: 2, label: "AMERICA/NEW YORK" },
    //     { value: 3, label: "AMERICA/LOS ANGELES" },
    //     { value: 4, label: "ASIA/KOLKATA" },
    // ];
    // const timezoneOptions = [
    //     ...optionData.map((timeZone) => ({
    //         label: timeZone.label,
    //         value: timeZone.value,
    //     })),
    // ];

    // useEffect(() => {
    //     const storedOption = localStorage.getItem('selectedOption');
    //     if (storedOption) {
    //         setSelectedOption(JSON.parse(storedOption));
    //     } else {

    //         const option_data = optionData.find((i) => i.value == timeZone)

    //         setSelectedOption(option_data)
    //         setSelectValue(option_data)

    //         localStorage.setItem('selectedOption', JSON.stringify(option_data));

    //         formik.setValues({
    //             ...formik,
    //             timezone: timezone
    //         })
    //     }
    // }, []);




    const handleSelectChange = (event) => {

        formik.setValues({
            ...formik,
            timezone: event.value
        })
        // localStorage.setItem('selectedOption', JSON.stringify({ value: event.value, label: event.label }));
        setSelectedOption({ value: event.value, label: event.label })
    }




    return (
        <section>
            <Form onSubmit={formik.handleSubmit}>

                <TopHeadPassword>
                    <div >
                        <Form.Label className='fw-bold clr-heading fs-5 ' >Change Timezone</Form.Label>
                    </div>
                    <div>
                        <span onClick={() => { closeModal() }} className='close-icon'><i className="bi bi-x-square"></i></span>
                    </div>


                </TopHeadPassword>


                <div>
                    <div className='col-lg-12'>
                        <div className="mb-3 d-flex flex-column justify-content-center align-items-center h-100"  >

                            <div className='input-group d-flex flex-row justify-content-center align-items-center pt-2'>
                                <Select
                                    className="basic-single select-search timeZone-select"
                                    classNamePrefix="select"
                                    value={selectedOption}
                                    name="timeZone"
                                    options={selectedTimezoneOptions}
                                    onChange={handleSelectChange}
                                    defaultValue={formik?.values?.timezone}
                                    placeholder={"Select Timezone"}
                                //menuPlacement="auto"
                                //maxMenuHeight={300}


                                //styles={{ control: styles => ({ ...styles, backgroundColor: "hsl(204deg 33.33% 97.06%)", borderStyle: "none", boxShadow: "none", borderRadius: "10px" }), indicatorSeparator: styles => ({ ...styles, width: "0px", minHeight: "20px" }), container: styles => ({ ...styles, borderRadius: "9px", }) }}
                                />
                            </div>
                            <div style={{ marginTop: "26px" }}>

                                {formik.errors.timezone &&
                                    <span className='text-danger' >{formik.errors.timezone}</span>
                                }
                            </div>

                        </div>


                        <div className='my-2'>
                            <div className='d-flex flex-row justify-content-center align-items-center pt-2'>
                                <button
                                    disabled={!formik.isValid}
                                    type='submit'
                                    className='btn btn-light btn-active-primary justify-content-center'
                                    style={{ width: '20%', fontSize: "13px" }}
                                //onClick={showAlert}
                                >
                                    Change
                                </button>

                            </div>
                        </div>
                    </div>
                </div>


            </Form>
        </section>


    )
}

export default ChangeTimeZone

const TopHeadPassword = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:'center';
    flex-direction: row;
    margin-bottom:10px;
    span.close-icon{
        cursor: pointer;
    }
`;