import React from 'react'
import AdminMaster from '../../../../Layout/admin/Master/index';
import { Formik, Field } from 'formik';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import * as Yup from 'yup';
import { createUser } from '../../../../Redux/user/action';
import { useState, useEffect } from 'react';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import DbkForm from '../dbk';
import { getUserlist } from '../../../../Redux/qms/action';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import { createUpdateUserWiseDbk, getSingleUserWiseDbk } from '../../../../Redux/userWiseDbk/action';
import { DBK_LIST } from '../../../../routeNames';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';


const CreateUpdateDbk = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { id } = params
    const [state, setState] = useState({
        showToast: false,
        error: '',
        error_message: "",
    })
    const [users, setUsers] = useState();
    const [clients, setClients] = useState();

    const [showPassword, setShowPassword] = useState(false)

    const [options, setOptions] = useState([]);
    const [userlist, setUserlist] = useState([]);
    const [isLoadinguser, setIsLoadinguser] = useState(true); // Add a loading state flag
    const [isLoadingclient, setIsLoadingclient] = useState(true); // Add a loading state flag
    const [isLoading, setIsLoading] = useState(true); // Add a loading state flag


    const [initialValues, setInitialValues] = useState({
        // dbk_id: "",
        comment: "",
        user: [],
        client: [],

        payment_check_number: false,
        payment_check_date: false,
        payment_check_amount: false,
        payment_payer_name: false,
        payment_payer_address: false,
        payment_payer_city: false,
        payment_payer_state: false,
        payment_payer_zip: false,
        payment_payee_name: false,
        payment_payee_npi: false,
        payment_payee_address: false,
        payment_payee_city: false,
        payment_payee_state: false,
        payment_payee_zip: false,
        payment_patient_acc_number: false,
        payment_payer_claim_number: false,
        payment_mrn_number: false,
        payment_statement_number: false,
        payment_statement_date: false,
        payment_patient_first_name: false,
        payment_patient_middle_name: false,
        payment_patient_last_name: false,
        payment_patient_identifier_code: false,
        payment_subscriber_first_name: false,
        payment_subscriber_middle_name: false,
        payment_subscriber_last_name: false,
        payment_subscriber_identifier_code: false,
        payment_rendering_provider_first_name: false,
        payment_rendering_provider_last_name: false,
        payment_rendering_provider_identifier: false,
        payment_serviceline_from_date: false,
        payment_serviceline_to_date: false,
        payment_serviceline_procedure: false,
        payment_serviceline_rev_code: false,
        payment_serviceline_units: false,
        payment_serviceline_billed: false,
        payment_serviceline_discount: false,
        payment_serviceline_allowed: false,
        payment_serviceline_deduct: false,
        payment_serviceline_coins: false,
        payment_serviceline_copay: false,
        payment_serviceline_patres: false,
        payment_service_adjust_amt: false,
        payment_service_adjust_pc: false,
        payment_service_adjust_rc: false,
        payment_service_adjust_rmk: false,
        payment_service_line_cob: false,
        payment_service_line_balance: false,

        paymentlite_check_number: false,
        paymentlite_check_date: false,
        paymentlite_check_amount: false,
        paymentlite_payer_name: false,
        paymentlite_payee_name: false,
        paymentlite_patient_acc_number: false,
        paymentlite_patient_first_name: false,
        paymentlite_patient_middle_name: false,
        paymentlite_patient_last_name: false,
        paymentlite_serviceline_period_start: false,
        paymentlite_serviceline_period_end: false,
        paymentlite_serviceline_billed: false,
        paymentlite_serviceline_paid: false,

        patientpay_check_number: false,
        patientpay_check_date: false,
        patientpay_check_amount: false,
        patientpay_patient_acc_number: false,
        patientpay_patient_first_name: false,
        patientpay_patient_middle_name: false,
        patientpay_patient_last_name: false,
        patientpay_payment_amt_due: false,
        patientpay_payment_statement_amount: false,
        patientpay_payment_statement_number: false,
        patientpay_payment_statement_date: false,

        correspondence_payer_name: false,
        correspondence_letter_date: false,
        correspondence_patient_acc_number: false,
        correspondence_period_start: false,
        correspondence_period_end: false,
        correspondence_billed_amount: false,
        correspondence_patient_first_name: false,
        correspondence_patient_middle_name: false,
        correspondence_patient_last_name: false,
    })

    const DbkSchema = Yup.object().shape({
        dbk_id: Yup.string(),
        comment: Yup.string().required('Enter your comment'),
        user: Yup.array().min(1, 'At least one value is required'),
        client: Yup.array().min(1, 'At least one value is required'),

        payment_check_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_check_date: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_check_amount: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payer_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payer_address: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payer_city: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payer_state: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payer_zip: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payee_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payee_npi: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payee_address: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payee_city: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payee_state: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payee_zip: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_patient_acc_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_payer_claim_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_mrn_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_statement_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_statement_date: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_patient_first_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_patient_middle_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_patient_last_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_patient_identifier_code: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_subscriber_first_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_subscriber_middle_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_subscriber_last_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_subscriber_identifier_code: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_rendering_provider_first_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_rendering_provider_last_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_rendering_provider_identifier: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_from_date: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_to_date: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_procedure: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_rev_code: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_units: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_billed: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_discount: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_allowed: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_deduct: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_coins: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_copay: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_serviceline_patres: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_service_adjust_amt: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_service_adjust_pc: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_service_adjust_rc: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_service_adjust_rmk: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_service_line_cob: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        payment_service_line_balance: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_check_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_check_date: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_check_amount: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_payer_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_payee_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_patient_acc_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_patient_first_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_patient_middle_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_patient_last_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_serviceline_period_start: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_serviceline_period_end: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_serviceline_billed: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        paymentlite_serviceline_paid: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_check_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_check_date: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_check_amount: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_patient_acc_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_patient_first_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_patient_middle_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_patient_last_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_payment_amt_due: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_payment_statement_amount: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_payment_statement_date: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        patientpay_payment_statement_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        correspondence_payer_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        correspondence_letter_date: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        correspondence_patient_acc_number: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        correspondence_period_start: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        correspondence_period_end: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        correspondence_billed_amount: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        correspondence_patient_first_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        correspondence_patient_middle_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
        correspondence_patient_last_name: Yup.bool().oneOf(
            [true, false],
            "Select either on of these"
        ),
    });

    // useEffect(() => {

    //     getUserlist(1)((response) => {
    //         const data = response?.data?.results
    //         setUserlist(data.map(({ is_logged_in, ...rest }) => rest))
    //     });
    //     getAllClientDropdown()((response) => {
    //         if (response?.status) {
    //             const data = response?.data
    //             setOptions(data.map(({ is_logged_in, ...rest }) => rest))

    //         }
    //     })


    // }, []);

    // useEffect(() => {
    //     if (id) {
    //         getSingleUserWiseDbk(id)((response) => {
    //             console.log(response?.data);
    //             const fullDict = {...response?.data,dbk_id:response?.data?.pk}
    //             const userData = fullDict?.user
    //             const clientData = fullDict?.client
    //             console.log(clientData,userData,userlist,options,"UVAIS T THACHANKATTIL (H) PO MKUTTIL KALPETTA");
    //             setUsers(userlist.filter((e)=>userData.includes(e.value)))
    //             setClients(options.filter((e)=>clientData.includes(e.value)))
    //             setInitialValues(fullDict)
    //         });
    //     }
    // }, [id])
    useEffect(() => {
        // First useEffect
        getUserlist(1)((response) => {
            const data = response?.data?.results;
            setUserlist(data.map(({ is_logged_in, ...rest }) => rest));
            setIsLoadinguser(false); // Set isLoading to false when the data is fetched
        });
        getAllClientDropdown()((response) => {
            if (response?.status) {
                const data = response?.data;
                setOptions(data.map(({ is_logged_in, ...rest }) => rest));
                setIsLoadingclient(false)
            }
        });
    }, []);

    useEffect(() => {
        // Second useEffect
        if (!isLoadinguser && !isLoadingclient && id) {
            getSingleUserWiseDbk(id)((response) => {
                const fullDict = { ...response?.data, dbk_id: response?.data?.pk };
                const userData = fullDict?.user;
                const clientData = fullDict?.client;

                setUsers(userlist.filter((e) => userData.includes(e.value)));
                setClients(options.filter((e) => clientData.includes(e.value)));
                setInitialValues(fullDict);
            });
        }
    }, [isLoadinguser, isLoadingclient, id]);

    const [toaster, setToaster] = useState('')

    const __handleCreateDbk = (values, actions) => {
        const { setSubmitting } = actions
        // setIsLoading(true)
        const RedirectId = getRedirectTabId()
        createUpdateUserWiseDbk(values)((response) => {
            if (!response?.data?.status && response?.status && response?.status_code === 200) {

                setToaster('success')
                setTimeout(() => {

                    setToaster('')

                    changeAllTitleFunction("Userwise DBK", DBK_LIST, RedirectId)
                }, 2000);

                // setState({ showToast: !state.showToast })
            } else {
                // setState({ error: true, error_message: response?.message });
                actions.setErrors({
                    dbk_id: response?.response?.data?.errors?.dbk_id,
                    comment: response?.response?.data?.errors?.comment,
                    clients: response?.response?.data?.errors?.client,
                    users: response?.response?.data?.errors?.user,
                })

                if (response?.errors && response?.errors?.comment && response?.errors?.comment[0]) {

                    setState({ showToast: !state.showToast, error: 'danger', error_message: response?.errors?.comment[0] })
                } else {
                    setState({ showToast: !state.showToast, error: 'danger', error_message: 'Failed' })

                }
                //setToaster('error')
                setTimeout(() => {

                    setToaster('')
                    setState({ showToast: false, error: '', error_message: '' })

                }, 2000);
                setSubmitting(false)

            }
        });
    }
    const __toggleToast = () => {
        setState({ showToast: !state.showToast })

    }
    const __toggleError = () => {
        setState({ showToast: !state.showToast })

    }
    // const formik = Formik
    const handleChangeSelect = (e, name) => {
        if (name == "users") {
            setUsers(e)
        } else if (name == "clients") {
            setClients(e)

        }


    }


    const DropdownIndicator = ({ state, ...props }) => {
        let data = []
        if (state == 'users') {
            data = users
        } else if (state == 'clients') {
            data = clients
        }
        return (
            <components.DropdownIndicator {...props}>

                {data?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };

    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedUser, setIsFocusedUser] = useState(false);
    return (
        <React.Fragment>

            <AdminMaster ToastMessage={toaster} />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={state.error} onClose={__toggleToast} show={state.showToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{state.error_message}</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header ">
                    <h1>{id ? "Edit" : "Create"} DBK</h1>
                </div>
                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">
                                    <Formik
                                        validationSchema={DbkSchema}
                                        initialValues={initialValues}
                                        enableReinitialize
                                        onSubmit={(initialValues, actions) => {
                                            __handleCreateDbk(initialValues, actions)
                                        }}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            setFieldValue,
                                            values,
                                            touched,
                                            isValid,
                                            errors,
                                            setValues,
                                            setErrors,
                                            isSubmitting,
                                        }) => (

                                            <Form
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="mb-3 row" >
                                                    <div className='col-lg-4'>
                                                        <div className="mb-10">
                                                            <Form.Label className='fw-bold'>Comment</Form.Label>
                                                            {/* <label htmlhtmlFor="exampleFormControlInput1" className="required form-label">Comment</label> */}
                                                            <Form.Control
                                                                type="text"
                                                                name='comment'
                                                                onChange={handleChange}
                                                                className="form-control form-control-solid b-r-10"
                                                                placeholder="Comment"
                                                                isValid={touched?.comment && !errors?.comment}
                                                                isInvalid={!!errors?.comment}
                                                                value={values?.comment}

                                                            />
                                                            {/* <Form.Control.Feedback style={{ fontSize: "14px" }} type="invalid">{errors?.comment}</Form.Control.Feedback> */}
                                                            {errors?.comment &&

                                                                <span className='text-danger'>{errors?.comment}</span>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-4'>

                                                        <Form.Label className='fw-bold'>Clients</Form.Label>
                                                        <Field
                                                            className="custom-select"
                                                            name="client"
                                                            options={options}
                                                            value={clients}
                                                            components={{ DropdownIndicator: (props) => <DropdownIndicator state={'clients'} {...props} /> }}
                                                            onChange={(e) => { handleChangeSelect(e, 'clients'); setFieldValue('client', e.map((el) => el.value)) }}
                                                            component={Select}
                                                            placeholder="Select multi Clients..."
                                                            isMulti={true}
                                                            onFocus={() => setIsFocusedClient(true)}
                                                            onBlur={() => setIsFocusedClient(false)}
                                                            styles={{
                                                                container: (styles, state) => ({
                                                                    ...styles,
                                                                    borderRadius: "9px",
                                                                }),
                                                                control: (styles, state) => ({
                                                                    ...styles,
                                                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                    borderStyle: "none", boxShadow: "none",
                                                                    borderRadius: "10px",
                                                                    maxHeight: state.isFocused ? "auto" : "30px",
                                                                }),
                                                                indicatorSeparator: styles => ({
                                                                    ...styles, width: "0px", minHeight: "20px"
                                                                }),

                                                                valueContainer: (styles, state) => ({
                                                                    ...styles,
                                                                    maxHeight: isFocusedClient ? "auto" : "30px",
                                                                }),
                                                            }}
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid">Select Groups</Form.Control.Feedback> */}
                                                        {errors?.client &&

                                                            <span className='text-danger'>{errors?.client}</span>
                                                        }
                                                    </div>
                                                    <div className='col-lg-4'>

                                                        <Form.Label className='fw-bold'>Users</Form.Label>
                                                        <Field
                                                            className="custom-select"
                                                            name="user"
                                                            options={userlist}
                                                            value={users}
                                                            components={{ DropdownIndicator: (props) => <DropdownIndicator state={'users'} {...props} /> }}
                                                            onChange={(e) => { handleChangeSelect(e, 'users'); setFieldValue('user', e.map((el) => el.value)) }}
                                                            component={Select}
                                                            placeholder="Select multi Users..."
                                                            isMulti={true}
                                                            onFocus={() => setIsFocusedUser(true)}
                                                            onBlur={() => setIsFocusedUser(false)}
                                                            styles={{
                                                                container: (styles, state) => ({
                                                                    ...styles,
                                                                    borderRadius: "9px",
                                                                }),
                                                                control: (styles, state) => ({
                                                                    ...styles,
                                                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                    borderStyle: "none", boxShadow: "none",
                                                                    borderRadius: "10px",
                                                                    maxHeight: state.isFocused ? "auto" : "30px",
                                                                }),
                                                                indicatorSeparator: styles => ({
                                                                    ...styles, width: "0px", minHeight: "20px"
                                                                }),

                                                                valueContainer: (styles, state) => ({
                                                                    ...styles,
                                                                    maxHeight: isFocusedUser ? "auto" : "30px",
                                                                }),
                                                            }}
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid">Select Groups</Form.Control.Feedback> */}
                                                        {errors?.user &&

                                                            <span className='text-danger'>{errors?.user}</span>
                                                        }
                                                    </div>
                                                    <div className='mt-4 col-lg-12'>

                                                        <DbkForm handleChange={handleChange}
                                                            setValues={setValues}
                                                            setErrors={setErrors}
                                                            values={values}
                                                            errors={errors} />
                                                    </div>

                                                </div>

                                                <div className='d-flex flex-row justify-content-end'>
                                                    <div className=''>
                                                        <button
                                                            type='button'
                                                            className='btn btn-secondary btn-active-primary me-2'
                                                            onClick={() => { changeAllTitleFunction("Userwise DBK", DBK_LIST) }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>

                                                    <div className=''>

                                                        {isSubmitting ?
                                                            <button
                                                                type='submit'
                                                                disabled
                                                                className='btn btn-primary btnHover'
                                                            >
                                                                Submit
                                                            </button>
                                                            :

                                                            <button
                                                                type='submit'
                                                                disabled={!isValid}
                                                                className='btn btn-primary btnHover'
                                                            >
                                                                Submit
                                                            </button>
                                                        }

                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </React.Fragment>
    )
}

export default CreateUpdateDbk