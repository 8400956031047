import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import { handleWheel, handlePreventEKeyPress, preventWhiteSpaceValidate } from "../gridFunctions";
import { DbkField } from "../Elements/DbkField";
import { useEffect } from "react";
import { isActiveDbkField } from "../../../Dashboard/functions";
import moment from "moment";
import { EDIT_WORK, QA_REVIEW, DATA_ENTRY } from "../../../../../routeNames";

const Claims = (props) => {
  const {
    index,
    formik,
    claim835Index,
    setClaim835Index,
    currentPage,
    setPatientFocus,
    patientFocus,
    setPatientValue,
    // DBK
    cleareEobFocus,
    eobFocus,
    setEobFocus,
    handleFocusEob,
    addingNewEobClaimDbk,
    removeEobClaimDbk,
    isDbkPermission,
    jobData,
    setDefaultValueAssignedWorkClaim,
    handleChangeIds,
    setClaim835Focus,
    qdxTransaction,
    handleClaimCalculation,
    handleRemainingBalanceCalculation,
    handleChange837ClaimData,
    isViewOnllyGrid,
    jumpToPage,
    handlePageNavigate,
    jumpRef,
    job_id,
    log_id,
    pdfLoaded,
    disableCorresField,
    isSubJob,
    isFirstJob
  } = props;

  useEffect(() => {
    setClaim835Index(index);
  }, [index]);

  const __removePatient = async (index) => {
    await formik.setValues((prevState) => ({
      ...prevState,
      claims: prevState.claims.map((claim, ind) => {
        if (claim835Index == ind) {
          return {
            ...claim,
            patient: false,
            patient_id: "",
            patient_first_name: "",
            patient_middle_name: "",
            patient_last_name: "",
            patient_identifier_code: "",
            dbk_patient_first_name: "",
            dbk_patient_middle_name: "",
            dbk_patient_last_name: "",
            dbk_patient_identifier_code: "",
          }
        }
        else {
          return claim;
        }
      })
    }));

  };
  const __removeSubscriber = async (index) => {
    await formik.setValues((prevState) => ({
      ...prevState,
      claims: prevState.claims.map((claim, ind) => {
        if (claim835Index == ind) {
          return {
            ...claim,
            subscriber: false,
            subscriber_id: "",
            subscriber_first_name: "",
            subscriber_middle_name: "",
            subscriber_last_name: "",
            subscriber_identifier_code: "",
            dbk_subscriber_first_name: "",
            dbk_subscriber_middle_name: "",
            dbk_subscriber_last_name: "",
            dbk_subscriber_identifier_code: "",
          }
        }
        else {
          return claim;
        }
      })
    }));
  };
  const __removeRenderingprovider = async (index) => {
    await formik.setValues((prevState) => ({
      ...prevState,
      claims: prevState.claims.map((claim, ind) => {
        if (claim835Index == ind) {
          return {
            ...claim,
            rendering_provider: false,
            rendering_provider_id: "",
            rendering_provider_first_name: "",
            rendering_provider_last_name: "",
            rendering_provider_identifier_code: "",
            dbk_rendering_provider_first_name: "",
            dbk_rendering_provider_last_name: "",
            dbk_rendering_provider_identifier_code: "",

          }
        }
        else {
          return claim;
        }
      })
    }));
  };

  const handleChangeFrom = (e) => {
    if (!e.target.disabled) {
      formik.setValues((prevState) => ({
        ...prevState,
        claims: prevState.claims.map((claim, i) => {
          if (i === index) {
            return {
              ...claim,
              page_from: currentPage + 1
            };
          }
          return claim;
        })
      }));
    }
  };

  const handleChangeTo = (e) => {
    formik.setValues((prevState) => ({
      ...prevState,
      claims: prevState.claims.map((claim, i) => {
        if (i === index) {
          return {
            ...claim,
            page_to: currentPage + 1
          };
        }
        return claim;
      })
    }));
  };
  const handleFloatValue = async (e) => {
    e.target.value = parseFloat(e.target.value).toFixed(2);
    e.target.type = "text"
    formik.handleChange(e)
    e.target.type = "number"
  }
  const changeClaimValue = async (event, type, adjIndex) => {
    const value = event.target.value;
    let claimTotal = 0;
    if (value.includes('.') && value.split('.')[1].length > 2) {
      return;
    }
    const oldDatas = formik.values.claims;
    await formik.setValues((prevState) => ({
      ...prevState,
      claims: prevState.claims.map((claim, ind) => {
        if (ind == index) {
          return {
            ...claim,
            [type]: value,
          };
        }
        return claim; // Return the unchanged claim for other indices
      }),
    }));
    if (type == 'claim_paid') {
      const remaining = await handleRemainingBalanceCalculation()
    }
    const claim = await handleClaimCalculation()
  }
  const handleRowFromClicked = (index) => {
    const claims = formik.values?.claims
    if (claims.length > 0) {
      jumpToPage(claims[index]?.page_from - 1)
    }
  }
  const handleRowToClicked = (index) => {
    const claims = formik.values?.claims
    if (claims.length > 0) {
      jumpToPage(claims[index]?.page_to - 1)
    }
  }
  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  const qaCorrectedData = gridData?.qa_corrected_data;

  const renderClaimClass = (name, type, defaultClass) => {
    if (formik.errors?.claims &&
      formik.errors?.claims[index] &&
      formik.errors?.claims[index]?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.claims &&
        qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
        qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
        moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
        formik.values?.claims[index]?.[name]?.toUpperCase() !=
        gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
        parseFloat(formik.values?.claims[index]?.[name] ? formik.values?.claims[index]?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name] ?
          gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }
      else if (formik.values?.claims[index]?.corrected_claim || !gridData?.claims || gridData?.transaction_type != "PAYMENT835") {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' && formik.values?.claims[index]?.color_data?.[name] && moment(formik.values?.claims[index]?.color_data?.[name]).format("MM/DD/YYYY") ==
        moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' data-837'
      }
      else if (type == 'TEXT' && formik.values?.claims[index]?.color_data?.[name] && formik.values?.claims[index]?.color_data?.[name]?.toUpperCase() == formik.values?.claims[index]?.[name]?.toUpperCase()) {
        return defaultClass + ' data-837'
      }
      else if (type == 'NUMBER' && formik.values?.claims[index]?.color_data?.[name] && formik.values?.claims[index]?.color_data?.[name] == formik.values?.claims[index]?.[name]) {
        return defaultClass + ' data-837'
      }


      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData &&
      qaCorrectedData?.claims &&
      qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
      qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]) {
      return defaultClass + ' qa-field'
    }
    else if (type == 'DATE' && formik.values?.claims[index]?.color_data?.[name] && moment(formik.values?.claims[index]?.color_data?.[name]).format("MM/DD/YYYY") ==
      moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY")) {
      return defaultClass + ' data-837'
    }
    else if (type == 'TEXT' && formik.values?.claims[index]?.color_data?.[name] && formik.values?.claims[index]?.color_data?.[name]?.toUpperCase() == formik.values?.claims[index]?.[name]?.toUpperCase()) {
      return defaultClass + ' data-837'
    }
    else if (type == 'NUMBER' && formik.values?.claims[index]?.color_data?.[name] && parseFloat(formik.values?.claims[index]?.color_data?.[name]).toFixed(2) == parseFloat(formik.values?.claims[index]?.[name]).toFixed(2)) {
      return defaultClass + ' data-837'
    }
    else {
      return defaultClass;
    }
  }
  return (
    <React.Fragment>
      <div
        className="bottom-wrapper claim_details accordion-collapse collapse show"
        id="collapseTwo"
        aria-labelledby="headingTwo"
        data-bs-parent="#claim"
      >
        <div className="sub-section">
          <div className="">
            <label className="btn btn-claim">Claim Details </label>
          </div>
          <form className="wrapper-patient">
            <InputGroup>
              <InputGroup.Text>Patient Account #</InputGroup.Text>
              <div className="dbk-main-container">
                <Form.Control

                  type="text"
                  name={`claims[${index}].patient_account_number`}
                  onChange={(val) => {
                    const e = preventWhiteSpaceValidate(val)
                    formik.handleChange(e);
                    setPatientValue(e.target.value);
                  }}
                  onFocus={(e) => {
                    handleFocusEob("patient_account_number", "claims", index, isDbkPermission?.payment?.payment_patient_acc_number);
                    setPatientFocus(true);
                    setPatientValue(e.target.value);
                    setClaim835Focus(true)
                  }}
                  onClick={(e) => { setPatientFocus(true); setPatientValue(e.target.value); }}
                  onDoubleClick={() => handlePageNavigate(index, '835')}
                  onBlur={() => { setPatientFocus(false); setClaim835Focus(false) }}
                  autoFocus={patientFocus}
                  onKeyDown={(e) => {
                    if (!(!isSubJob && isFirstJob || isSubJob && !isFirstJob) && index == 0 && !e.shiftKey && e.key == 'Tab' && pathname == DATA_ENTRY && !(isDbkPermission?.payment?.payment_patient_acc_number && formik.errors?.claims && formik.errors?.claims[index] && formik.errors?.claims[index].patient_account_number === "Dont Match" && eobFocus.claims[index]?.patient_account_number)) {
                      e.preventDefault();
                      const claimAccountNo = document.querySelector(`[name="payer_name"]`)
                      if (claimAccountNo) {
                        claimAccountNo.focus();
                      }
                    }
                  }}
                  onKeyUp={(e) => {
                    setDefaultValueAssignedWorkClaim(e, index, "patient_account_number");
                  }}
                  value={formik.values?.claims[index]?.patient_account_number}
                  autoComplete="new-password"
                  tabIndex={
                    formik.values?.claims[index]?.patient_account_number != '' && pathname == DATA_ENTRY ? -1 : 0
                  }
                  className={renderClaimClass('patient_account_number', 'TEXT', 'w-patient')}
                  disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_patient_acc_number) ? !eobFocus.claims[index]?.patient_account_number : false}
                />

                {isDbkPermission?.payment?.payment_patient_acc_number &&

                  formik.errors?.claims &&
                  formik.errors?.claims[index] &&
                  formik.errors?.claims[index].patient_account_number ===
                  "Dont Match" &&
                  eobFocus.claims[index]?.patient_account_number ? (
                  <DbkField
                    formik={formik}
                    index={index}
                    type="text"
                    fieldName="patient_account_number"
                    fieldNameDbk="dbk_patient_account_number"
                    fieldType="claims"
                    setCorresPondanceFocus={setEobFocus}
                    corresPondanceFocus={eobFocus}
                  />
                ) : null}
              </div>
            </InputGroup>
          </form>
          <div className="wrapper-patient-claim">
            <InputGroup>
              <InputGroup.Text>Payer Claim #</InputGroup.Text>
              <div className="dbk-main-container">
                <Form.Control
                  type="text"
                  name={`claims[${index}].payer_claim_number`}
                  onChange={(val) => {
                    const e = preventWhiteSpaceValidate(val)
                    formik.handleChange(e)
                  }}
                  onFocus={() => { handleFocusEob("payer_claim_number", "claims", index, isDbkPermission?.payment?.payment_payer_claim_number); setClaim835Focus(true); }}
                  onDoubleClick={() => handlePageNavigate(index, '835')}
                  onKeyUp={(e) => { setDefaultValueAssignedWorkClaim(e, index, "payer_claim_number"); }}
                  onBlur={() => { setClaim835Focus(false) }}
                  value={formik.values?.claims[index]?.payer_claim_number}
                  tabIndex={formik.values?.claims[index]?.payer_claim_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                  className={renderClaimClass('payer_claim_number', 'TEXT', 'w-patient')}
                  disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_payer_claim_number) ? !eobFocus.claims[index]?.payer_claim_number : false || disableCorresField('payer_claim_number')}
                  data-corress-disable={disableCorresField('payer_claim_number')}
                />
                {isDbkPermission?.payment?.payment_payer_claim_number &&
                  formik.errors?.claims &&
                  formik.errors?.claims[index] &&
                  formik.errors?.claims[index].payer_claim_number ===
                  "Dont Match" &&
                  eobFocus.claims[index]?.payer_claim_number ? (
                  <DbkField
                    formik={formik}
                    index={index}
                    type="text"
                    fieldName="payer_claim_number"
                    fieldNameDbk="dbk_payer_claim_number"
                    fieldType="claims"
                    setCorresPondanceFocus={setEobFocus}
                    corresPondanceFocus={eobFocus}
                  />
                ) : null}
              </div>
            </InputGroup>
          </div>
          <div className="wrapper">
            <InputGroup>
              <InputGroup.Text>Period Start</InputGroup.Text>
              <InputMask
                mask="99/99/9999"
                className={renderClaimClass('period_start', 'DATE', 'patient-date')}
                tabIndex={formik.values?.claims[index]?.period_start != '' && formik.values?.claims[index]?.period_start != "__/__/____" && pathname == DATA_ENTRY ? -1 : 0}
                onFocus={() => setClaim835Focus(true)}
                onDoubleClick={() => handlePageNavigate(index, '835')}
                onBlur={() => setClaim835Focus(false)}
                onChange={formik.handleChange}
                onKeyUp={(e) => { setDefaultValueAssignedWorkClaim(e, index, "period_start"); }}
                value={formik.values?.claims[index]?.period_start}
                name={`claims[${index}].period_start`}
                placeholder={"mm/dd/yyyy"}
              />
            </InputGroup>
          </div>
          <div className="wrapper">
            <InputGroup>
              <InputGroup.Text>Period End</InputGroup.Text>
              <InputMask
                mask="99/99/9999"
                className={renderClaimClass('period_end', 'DATE', 'patient-date')}
                tabIndex={formik.values?.claims[index]?.period_end != '' && formik.values?.claims[index]?.period_end != "__/__/____" && pathname == DATA_ENTRY ? -1 : 0}
                onFocus={() => setClaim835Focus(true)}
                onDoubleClick={() => handlePageNavigate(index, '835')}
                onBlur={() => setClaim835Focus(false)}
                onKeyUp={(e) => { setDefaultValueAssignedWorkClaim(e, index, "period_end"); }}
                onChange={formik.handleChange}
                value={formik.values?.claims[index]?.period_end}
                name={`claims[${index}].period_end`}
                placeholder={"mm/dd/yyyy"}
              />
            </InputGroup>
          </div>
          <div className="wrapper">
            <InputGroup>
              <InputGroup.Text>Status Code</InputGroup.Text>
              <Form.Control
                as="select"
                name={`claims[${index}].status_code`}
                onFocus={() => setClaim835Focus(true)}
                onDoubleClick={() => handlePageNavigate(index, '835')}
                onBlur={() => setClaim835Focus(false)}
                onChange={formik.handleChange}
                value={formik.values?.claims && formik.values?.claims[index]?.status_code}
                tabIndex={formik.values?.claims[index]?.status_code != '' && pathname == DATA_ENTRY ? -1 : 0}
                className={renderClaimClass('status_code', 'NUMBER', 'selc-bg-2')}
                aria-label="Default select example"
              >
                <option value="">Select an Option</option>
                <option value="1">1 Primary</option>
                <option value="2">2 Secondary</option>
                <option value="3">3 Tertiary</option>
                <option value="4">4 Denial</option>
                <option value="22">22 Reverse</option>
                <option value="19">19 Primary Forwarded</option>
                <option value="20">20 Secondary Forwarded</option>
                <option value="21">21 Tertiary Forwarded</option>
                <option value="23">23 Not Our Claim Forwarded</option>
                <option value="25">25 Predetermination - No Payment</option>
              </Form.Control>
            </InputGroup>
          </div>
          {qdxTransaction != "Correspondence" &&
            <React.Fragment>
              <div className="wrapper">
                <InputGroup>
                  <InputGroup.Text>MRN #</InputGroup.Text>
                  <div className="dbk-main-container">

                    <Form.Control
                      type="text"
                      name={`claims[${index}].mrn`}
                      onChange={formik.handleChange}
                      onDoubleClick={() => handlePageNavigate(index, '835')}
                      onFocus={() => { handleFocusEob("mrn", "claims", index, isDbkPermission?.payment?.payment_mrn_number); setClaim835Focus(true); }}
                      onBlur={() => { setClaim835Focus(false) }}
                      value={formik.values?.claims[index]?.mrn}
                      tabIndex={(qdxTransaction == 'PATIENT_PAY' && formik.values?.claims[index]?.mrn == '') || pathname == QA_REVIEW ? 0 : -1}
                      className={renderClaimClass('mrn', 'TEXT', 'w-mrn')}
                      disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_mrn_number) ? !eobFocus.claims[index]?.mrn : false || disableCorresField('mrn')}
                      data-corress-disable={disableCorresField('mrn')}
                    />

                    {isDbkPermission?.payment?.payment_mrn_number &&
                      formik.errors?.claims &&
                      formik.errors?.claims[index] &&
                      formik.errors?.claims[index].mrn ===
                      "Dont Match" &&
                      eobFocus.claims[index]?.mrn ? (
                      <DbkField
                        formik={formik}
                        index={index}
                        type="text"
                        fieldName="mrn"
                        fieldNameDbk="dbk_mrn"
                        fieldType="claims"
                        setCorresPondanceFocus={setEobFocus}
                        corresPondanceFocus={eobFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="wrapper">
                <InputGroup>
                  <InputGroup.Text>Statement #</InputGroup.Text>
                  <div className="dbk-main-container">

                    <Form.Control
                      type="text"
                      name={`claims[${index}].statement_number`}
                      onChange={formik.handleChange}
                      onDoubleClick={() => handlePageNavigate(index, '835')}
                      onFocus={() => { handleFocusEob("statement_number", "claims", index, isDbkPermission?.payment?.payment_statement_number); setClaim835Focus(true); }}
                      onBlur={() => { setClaim835Focus(false) }}
                      value={formik.values?.claims[index]?.statement_number}
                      tabIndex={(qdxTransaction == 'PATIENT_PAY' && formik.values?.claims[index]?.statement_number == '') || pathname == QA_REVIEW ? 0 : -1}
                      className={renderClaimClass('statement_number', 'TEXT', 'w-statement-number')}
                      disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_statement_number) ? !eobFocus.claims[index]?.statement_number : false || disableCorresField('statement_number')}
                      data-corress-disable={disableCorresField('statement_number')}
                    />
                    {isDbkPermission?.payment?.payment_statement_number &&
                      formik.errors?.claims &&
                      formik.errors?.claims[index] &&
                      formik.errors?.claims[index].statement_number ===
                      "Dont Match" &&
                      eobFocus.claims[index]?.statement_number ? (
                      <DbkField
                        formik={formik}
                        index={index}
                        type="text"
                        fieldName="statement_number"
                        fieldNameDbk="dbk_statement_number"
                        fieldType="claims"
                        setCorresPondanceFocus={setEobFocus}
                        corresPondanceFocus={eobFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
            </React.Fragment>
          }
          <div className="">
            <InputGroup>
              <InputGroup.Text>From</InputGroup.Text>
              <Form.Control
                type="number"
                min={1}
                name={`claims[${index}].page_from`}
                data-disabled={!pdfLoaded}
                onChange={formik.handleChange}
                onWheel={handleWheel}
                onBlur={() => setClaim835Focus(false)}
                onClick={(e) => { setClaim835Focus(true); handleChangeFrom(e); }}
                onFocus={(e) => { setClaim835Focus(true); handleChangeFrom(e); }}
                onKeyDown={(e) => { handlePreventEKeyPress(e); }}
                value={formik.values?.claims[index]?.page_from}
                className={renderClaimClass('page_from', 'NUMBER', 'pagefrom')}

              />
            </InputGroup>
          </div>
          <div className="">
            <InputGroup>
              <InputGroup.Text>To</InputGroup.Text>
              <Form.Control
                type="number"
                name={`claims[${index}].page_to`}
                min={1}
                data-disabled={!pdfLoaded}
                onBlur={() => setClaim835Focus(false)}
                onWheel={handleWheel}
                onChange={formik.handleChange}
                onKeyDown={(e) => { handlePreventEKeyPress(e); }}
                onClick={(e) => { setClaim835Focus(true); handleChangeTo(e); }}
                onFocus={(e) => { handleChangeTo(e); setClaim835Focus(true) }}
                value={formik.values?.claims[index]?.page_to}
                className={renderClaimClass('page_to', 'NUMBER', 'pageto')}
              />
            </InputGroup>
          </div>
        </div>
        {formik.values?.claims[index]?.patient ? (
          <div className="sub-section">
            <div className="">
              <div className="label-claim">
                <button
                  type="button"
                  tabIndex={"-1"}
                  className="btn btn-outline-danger"
                  onClick={__removePatient}
                >
                  ×
                </button>
                Patient{" "}
              </div>
            </div>
            <form className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    id="pan-focus"
                    type="text"
                    name={`claims[${index}].patient_first_name`}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    tabIndex={formik.values?.claims[index]?.patient_first_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                    onChange={(e) => { formik.handleChange(e); }}
                    onFocus={(e) => { handleFocusEob("patient_first_name", "claims", index, isDbkPermission?.payment?.payment_patient_first_name); setClaim835Focus(true); }}
                    onBlur={() => setClaim835Focus(false)}
                    value={formik.values?.claims && formik.values?.claims[index]?.patient_first_name}
                    className={renderClaimClass('patient_first_name', 'TEXT', 'w-fsname')}
                    onKeyUp={(e) => { setDefaultValueAssignedWorkClaim(e, index, "patient_first_name"); }}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_patient_first_name) ? !eobFocus.claims[index]?.patient_first_name : false}
                  />
                  {isDbkPermission?.payment?.payment_patient_first_name &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].patient_first_name &&
                    eobFocus.claims[index]?.patient_first_name ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="patient_first_name"
                      fieldNameDbk="dbk_patient_first_name"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </form>
            <div className="wrapper-miname">
              <InputGroup>
                <InputGroup.Text>Middle Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    name={`claims[${index}].patient_middle_name`}
                    tabIndex={formik.values?.claims[index]?.patient_middle_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                    onFocus={(e) => { handleFocusEob("patient_middle_name", "claims", index, isDbkPermission?.payment?.payment_patient_middle_name); setClaim835Focus(true); }}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onBlur={() => setClaim835Focus(false)}
                    onChange={(e) => { formik.handleChange(e); }}
                    value={formik.values?.claims && formik.values?.claims[index]?.patient_middle_name}
                    className={renderClaimClass('patient_middle_name', 'TEXT', 'w-miname')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_patient_middle_name) ? !eobFocus.claims[index]?.patient_middle_name : false || disableCorresField('patient_middle_name')}
                    data-corress-disable={disableCorresField('patient_middle_name')}
                  />
                  {isDbkPermission?.payment?.payment_patient_middle_name &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].patient_middle_name &&
                    eobFocus.claims[index]?.patient_middle_name ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="patient_middle_name"
                      fieldNameDbk="dbk_patient_middle_name"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].patient_last_name`}
                    tabIndex={formik.values?.claims[index]?.patient_last_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                    onChange={(e) => { formik.handleChange(e); }}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onBlur={() => { setClaim835Focus(false) }}
                    onKeyUp={(e) => { setDefaultValueAssignedWorkClaim(e, index, "patient_last_name"); }}
                    onFocus={(e) => { handleFocusEob("patient_last_name", "claims", index, isDbkPermission?.payment?.payment_patient_last_name); setClaim835Focus(true) }}
                    value={formik.values?.claims && formik.values?.claims[index]?.patient_last_name}
                    className={renderClaimClass('patient_last_name', 'TEXT', 'w-fsname')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_patient_last_name) ? !eobFocus.claims[index]?.patient_last_name : false}
                  />
                  {isDbkPermission?.payment?.payment_patient_last_name &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].patient_last_name &&
                    eobFocus.claims[index]?.patient_last_name ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="patient_last_name"
                      fieldNameDbk="dbk_patient_last_name"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Patient Identifier</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].patient_identifier_code`}
                    onChange={(e) => { formik.handleChange(e); }}
                    onFocus={(e) => { handleFocusEob("patient_identifier_code", "claims", index, isDbkPermission?.payment?.payment_patient_identifier_code); setClaim835Focus(true) }}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onBlur={() => setClaim835Focus(false)}
                    tabIndex={formik.values?.claims[index]?.patient_identifier_code != '' && pathname == DATA_ENTRY ? -1 : 0}
                    value={formik.values?.claims && formik.values?.claims[index]?.patient_identifier_code}
                    className={renderClaimClass('patient_identifier_code', 'TEXT', 'w-fsname')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_patient_identifier_code) ? !eobFocus.claims[index]?.patient_identifier_code : false}
                  />

                  {isDbkPermission?.payment?.payment_patient_identifier_code &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].patient_identifier_code ==
                    "Dont Match" &&
                    eobFocus.claims[index]?.patient_identifier_code ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="patient_identifier_code"
                      fieldNameDbk="dbk_patient_identifier_code"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
          </div>
        ) : (
          ""
        )}
        {formik.values?.claims[index]?.subscriber ? (
          <div className="sub-section">
            <div className="">
              <div className="label-claim">
                <button
                  type="button"
                  tabIndex={"-1"}
                  className="btn btn-outline-danger"
                  onClick={__removeSubscriber}
                >
                  ×
                </button>{" "}
                Subscriber{" "}
              </div>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].subscriber_first_name`}
                    onChange={(e) => { formik.handleChange(e); handleChangeIds(e, "subscriber"); }}
                    onFocus={(e) => { handleFocusEob("subscriber_first_name", "claims", index, isDbkPermission?.payment?.payment_subscriber_first_name); setClaim835Focus(true) }}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onBlur={() => setClaim835Focus(false)}
                    tabIndex={formik.values?.claims[index]?.subscriber_first_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                    value={formik.values?.claims && formik.values?.claims[index]?.subscriber_first_name}
                    className={renderClaimClass('subscriber_first_name', 'TEXT', 'w-fsname')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_subscriber_first_name) ? !eobFocus.claims[index]?.subscriber_first_name : false || disableCorresField('subscriber_first_name')}
                    data-corress-disable={disableCorresField('subscriber_first_name')}
                  />
                  {isDbkPermission?.payment?.payment_subscriber_first_name &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].subscriber_first_name ===
                    "Dont Match" &&
                    eobFocus.claims[index]?.subscriber_first_name ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="subscriber_first_name"
                      fieldNameDbk="dbk_subscriber_first_name"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-miname">
              <InputGroup>
                <InputGroup.Text>Middle Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].subscriber_middle_name`}
                    onFocus={(e) => { handleFocusEob("subscriber_middle_name", "claims", index, isDbkPermission?.payment?.payment_subscriber_middle_name); setClaim835Focus(true) }}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onBlur={() => setClaim835Focus(false)}
                    onChange={(e) => { formik.handleChange(e); handleChangeIds(e, "subscriber"); }}
                    tabIndex={formik.values?.claims[index]?.subscriber_middle_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                    value={formik.values?.claims && formik.values?.claims[index]?.subscriber_middle_name}
                    className={renderClaimClass('subscriber_middle_name', 'TEXT', 'w-miname')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_subscriber_middle_name) ? !eobFocus.claims[index]?.subscriber_middle_name : false || disableCorresField('subscriber_middle_name')}
                    data-corress-disable={disableCorresField('subscriber_middle_name')}
                  />
                  {isDbkPermission?.payment?.payment_subscriber_middle_name &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].subscriber_middle_name &&
                    eobFocus.claims[index]?.subscriber_middle_name ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="subscriber_middle_name"
                      fieldNameDbk="dbk_subscriber_middle_name"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].subscriber_last_name`}
                    tabIndex={formik.values?.claims[index]?.subscriber_last_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                    onChange={(e) => { formik.handleChange(e); handleChangeIds(e, "subscriber"); }}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onFocus={(e) => { handleFocusEob("subscriber_last_name", "claims", index, isDbkPermission?.payment?.payment_subscriber_last_name); setClaim835Focus(true) }}
                    onBlur={() => setClaim835Focus(false)}
                    value={formik.values?.claims && formik.values?.claims[index]?.subscriber_last_name}
                    className={renderClaimClass('subscriber_last_name', 'TEXT', 'w-fsname')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_subscriber_last_name) ? !eobFocus.claims[index]?.subscriber_last_name : false || disableCorresField('subscriber_last_name')}
                    data-corress-disable={disableCorresField('subscriber_last_name')}
                  />
                  {isDbkPermission?.payment?.payment_subscriber_last_name &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].subscriber_last_name &&
                    eobFocus.claims[index]?.subscriber_last_name ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="subscriber_last_name"
                      fieldNameDbk="dbk_subscriber_last_name"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Subscriber Identifier</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].subscriber_identifier_code`}
                    tabIndex={formik.values?.claims[index]?.subscriber_identifier_code != '' && pathname == DATA_ENTRY ? -1 : 0}
                    onFocus={(e) => { handleFocusEob("subscriber_identifier_code", "claims", index, isDbkPermission?.payment?.payment_subscriber_identifier_code); setClaim835Focus(true) }}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onBlur={() => setClaim835Focus(false)}
                    onChange={(e) => { formik.handleChange(e); handleChangeIds(e, "subscriber"); }}
                    value={formik.values?.claims && formik.values?.claims[index]?.subscriber_identifier_code}
                    className={renderClaimClass('subscriber_identifier_code', 'TEXT', 'w-fsname')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_subscriber_identifier_code) ? !eobFocus.claims[index]?.subscriber_identifier_code : false || disableCorresField('subscriber_identifier_code')}
                    data-corress-disable={disableCorresField('subscriber_identifier_code')}
                  />
                  {isDbkPermission?.payment
                    ?.payment_subscriber_identifier_code &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].subscriber_identifier_code &&
                    eobFocus.claims[index]?.subscriber_identifier_code ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="subscriber_identifier_code"
                      fieldNameDbk="dbk_subscriber_identifier_code"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
          </div>
        ) : (
          ""
        )}
        {formik.values?.claims[index]?.rendering_provider ? (
          <div className="sub-section">
            <div className="">
              <div className="label-claim">
                <button
                  tabIndex={"-1"}
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={__removeRenderingprovider}
                >
                  ×
                </button>{" "}
                Rendering Provider{" "}
              </div>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].rendering_provider_first_name`}
                    tabIndex={formik.values?.claims[index]?.rendering_provider_first_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                    onFocus={(e) => { handleFocusEob("rendering_provider_first_name", "claims", index, isDbkPermission?.payment?.payment_rendering_provider_first_name); setClaim835Focus(true) }}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onBlur={() => setClaim835Focus(false)}
                    onChange={(e) => { formik.handleChange(e); handleChangeIds(e, "rendering_provider"); }}
                    value={formik.values?.claims && formik.values?.claims[index]?.rendering_provider_first_name}
                    className={renderClaimClass('rendering_provider_first_name', 'TEXT', 'w-fsname')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_rendering_provider_first_name) ? !eobFocus.claims[index]?.rendering_provider_first_name : false || disableCorresField('rendering_provider_first_name')}
                    data-corress-disable={disableCorresField('rendering_provider_first_name')}
                  />
                  {isDbkPermission?.payment
                    ?.payment_rendering_provider_first_name &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].rendering_provider_first_name &&
                    eobFocus.claims[index]?.rendering_provider_first_name ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="rendering_provider_first_name"
                      fieldNameDbk="dbk_rendering_provider_first_name"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    name={`claims[${index}].rendering_provider_last_name`}
                    tabIndex={formik.values?.claims[index]?.rendering_provider_last_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onFocus={(e) => { handleFocusEob("rendering_provider_last_name", "claims", index, isDbkPermission?.payment?.payment_rendering_provider_last_name); setClaim835Focus(true) }}
                    onBlur={() => setClaim835Focus(false)}
                    onChange={(e) => { formik.handleChange(e); handleChangeIds(e, "rendering_provider"); }}
                    value={formik.values?.claims && formik.values?.claims[index]?.rendering_provider_last_name}
                    className={renderClaimClass('rendering_provider_last_name', 'TEXT', 'w-fsname')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_rendering_provider_last_name) ? !eobFocus.claims[index]?.rendering_provider_last_name : false || disableCorresField('rendering_provider_last_name')}
                    data-corress-disable={disableCorresField('rendering_provider_last_name')}
                  />
                  {isDbkPermission?.payment
                    ?.payment_rendering_provider_last_name &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].rendering_provider_last_name &&
                    eobFocus.claims[index]?.rendering_provider_last_name ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="rendering_provider_last_name"
                      fieldNameDbk="dbk_rendering_provider_last_name"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-rendering">
              <InputGroup>
                <InputGroup.Text>Rendering Provider Identifier</InputGroup.Text>
                <div className="dbk-main-container">
                  <Form.Control
                    type="text"
                    name={`claims[${index}].rendering_provider_identifier_code`}
                    tabIndex={formik.values?.claims[index]?.rendering_provider_identifier_code != '' && pathname == DATA_ENTRY ? -1 : 0}
                    maxLength={10}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onChange={(e) => { formik.handleChange(e); handleChangeIds(e, "rendering_provider"); }}
                    onFocus={(e) => { handleFocusEob("rendering_provider_identifier_code", "claims", index, isDbkPermission?.payment?.payment_rendering_provider_identifier); setClaim835Focus(true) }}
                    onBlur={() => setClaim835Focus(false)}
                    value={formik.values?.claims && formik.values?.claims[index]?.rendering_provider_identifier_code}
                    className={renderClaimClass('rendering_provider_identifier_code', 'TEXT', 'w-patient')}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_rendering_provider_identifier) ? !eobFocus.claims[index]?.rendering_provider_identifier_code : false || disableCorresField('rendering_provider_identifier_code')}
                    data-corress-disable={disableCorresField('rendering_provider_identifier_code')}
                  />

                  {isDbkPermission?.payment
                    ?.payment_rendering_provider_identifier &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index]
                      .rendering_provider_identifier_code == "Dont Match" &&
                    eobFocus.claims[index]?.rendering_provider_identifier_code ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="text"
                      fieldName="rendering_provider_identifier_code"
                      fieldNameDbk="dbk_rendering_provider_identifier_code"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>
          </div>
        ) : (
          ""
        )}
        {qdxTransaction == "PATIENT_PAY" &&
          <div className="sub-section">
            <div className="">
              <div className="label-claim">
                Payment Details{" "}
              </div>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Charge Amount</InputGroup.Text>
                <div className="dbk-main-container1">
                  <Form.Control
                    name={`claims[${index}].claim_billed`}
                    type="number"
                    onChange={(e) => changeClaimValue(e, 'claim_billed')}
                    onWheel={handleWheel}
                    onBlur={handleFloatValue}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onKeyDown={(e) => { handlePreventEKeyPress(e); }}
                    value={formik.values?.claims[index]?.claim_billed}
                    className={renderClaimClass('claim_billed', 'NUMBER', 'serviceline-btn text-end')}
                    tabIndex={formik.values?.claims[index]?.claim_billed != '' && pathname == DATA_ENTRY ? -1 : 0}
                  />
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-patient">
              <InputGroup>
                <InputGroup.Text>Paid Amount</InputGroup.Text>
                <div className="dbk-main-container1">
                  <Form.Control
                    type="number"
                    onWheel={handleWheel}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    name={`claims[${index}].claim_paid`}
                    onBlur={handleFloatValue}
                    onKeyDown={(e) => { handlePreventEKeyPress(e); }}
                    onChange={(e) => changeClaimValue(e, 'claim_paid')}
                    tabIndex={formik.values?.claims[index]?.claim_paid != '' && pathname == DATA_ENTRY ? -1 : 0}
                    value={formik.values?.claims[index]?.claim_paid}
                    className={renderClaimClass('claim_paid', 'NUMBER', 'serviceline-btn text-end')}
                  />
                </div>
              </InputGroup>
            </div>
            <div className="wrapper-statement-date">
              <InputGroup>
                <InputGroup.Text>Statement Date</InputGroup.Text>
                <div className="dbk-main-container">
                  <InputMask
                    mask="99/99/9999"
                    style={{ fontSize: "0.7em" }}
                    onFocus={() => { handleFocusEob("statement_date", "claims", index, isDbkPermission?.payment?.payment_statement_date); setClaim835Focus(true) }}
                    onDoubleClick={() => handlePageNavigate(index, '835')}
                    onBlur={() => setClaim835Focus(false)}
                    className={renderClaimClass('statement_date', 'DATE', 'patient-date')}
                    tabIndex={formik.values?.claims[index]?.statement_date != '' && formik.values?.claims[index]?.statement_date != "__/__/____" && pathname == DATA_ENTRY ? -1 : 0}
                    onChange={formik.handleChange}
                    value={formik.values?.claims[index]?.statement_date}
                    name={`claims[${index}].statement_date`}
                    placeholder={"mm/dd/yyyy"}
                    disabled={isActiveDbkField(eobFocus, formik, isDbkPermission?.payment?.payment_statement_date) ? !eobFocus.claims[index]?.statement_date : false}
                  />
                  {isDbkPermission?.payment?.payment_statement_date &&
                    formik.errors?.claims &&
                    formik.errors?.claims[index] &&
                    formik.errors?.claims[index].statement_date ===
                    "Dont Match" &&
                    eobFocus.claims[index]?.statement_date ? (
                    <DbkField
                      formik={formik}
                      index={index}
                      type="date"
                      fieldName="statement_date"
                      fieldNameDbk="dbk_statement_date"
                      fieldType="claims"
                      setCorresPondanceFocus={setEobFocus}
                      corresPondanceFocus={eobFocus}
                    />
                  ) : null}
                </div>
              </InputGroup>
            </div>

          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default Claims;
