import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import AdminMaster from '../../../Layout/admin/Master';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast, ToastContainer, OverlayTrigger } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import axiosInstance from './../../../axiosInstance';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BiFile, BiReset, BiSearch, BiTimer, BiUserPlus } from 'react-icons/bi';
import Modal from 'react-bootstrap/Modal';
import DateTimePicker from 'react-datetime-picker';
import Form from 'react-bootstrap/Form';
import { fetchAllLockboxFilesData, getChangeJobTat, getUserlist, getUserManualAssign } from '../../../Redux/qms/action';
import { GET_ALL_SFTP_FILES_URL, GET_ALL_SFTP_SPLITTED_FILES_URL, GET_QUEUE_LISTING_URL } from '../../../api';
import moment from 'moment/moment';
import { BsClockHistory } from 'react-icons/bs';
import { filterQueueDetails } from './../../../Redux/qms/action';
import { IoIosSwitch } from 'react-icons/io';
import { getAllClientDropdown } from '../../../Redux/client/action';
import { getRejectHistory } from '../../../Redux/sftpClient/action';
import queueDatatableCustomStyles from '../../../Layout/Elements/QueueDatatableCustomStyles';
// import { LOCKBOX_FILES_QMS, SINGLE_LOCKBOX_LOCKBOX_FILES } from '../../../routeNames';
import { LOCKBOXFILES, LOCKBOX_FILES_QMS } from '../../../routeNames';
import { rejectJob } from '../../../Redux/grid/action';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getFacilityDropdown } from '../../../Redux/user/action';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { changeAllTitleFunction } from '../Dashboard/functions';
import { Tooltip } from '@mui/material';

const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
var input = {
    client: '',
    file: ''
};

const Lockbox = () => {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)

    const [pageno, setPageNo] = useState(1);
    const [selectValue, setSelectValue] = useState([])
    const [selectedFacility, setSelectedFacility] = useState([])
    const [selectedUser, setSelectedUser] = useState([])

    const [facilities, setFacilities] = useState([])
    const [clients, setClients] = useState([])

    let { file_id } = useParams();
    const [historyData, setHistoryData] = useState([]);
    const [isSwal, setIsSwal] = useState({
        show: false,
        id: ''
    })
    const [toggleHistoryPopup, setToggleHistoryPopup] = useState({
        isShow: false,
        id: null,
    });

    //////////////////////////////////////////////////////////////////

    const [userlist, setUserlist] = useState();

    const [clientList, setClientList] = useState();

    useEffect(() => {
        getUserlist(1)((response) => {
            setUserlist(response?.data?.results)
        });

    }, []);

    const assignUser = () => {
        let form = new FormData();
        form.append('staff',)
        getUserManualAssign()((response) => {
            setUserassign(response?.data)
            formik.setValues({ ...formik.values, data: response?.data })
            setShowModal(false);
        });
    }

    //////////////////////////////////////////////////////////////////


    const [settings, setSettings] = useState();

    const [toaster, setToaster] = useState(null);

    /////////////////////////////////////////////////////////////////


    const changeTat = () => {
        getChangeJobTat(moment(value).format('Y-M-D[T]H:m'), [settings?.id])((response) => {
            setShow(false);
            if (response && response.status) {
                fetchQueueListing(pageno, limit, searchedData?.name, searchedData?.lock_box_file_name, searchedData?.clients, searchedData?.facility, searchedData?.user);
                setToaster('success')

            }
            else {
                setToaster('error')

                return () => clearTimeout(timer);
            }
        });
    }



    const formik = useFormik({
        initialValues: {
            assignedqueue: "",
            staff: "",
        },
        // validationSchema,
        onSubmit: (values) => {
            __formSubmit(values)
        },
    });
    const __formSubmit = (values) => {

        getUserManualAssign(values?.staff, values?.assignedqueue)((response) => {
            setShowModal(false);
            if (response && response.status) {
                setToaster('success')

            }
            else {
                setToaster('error')

            }

        });
    };
    // const searchTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Search
    //     </Tooltip>
    // );
    // const resetTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Reset
    //     </Tooltip>
    // );
    // const HeadingsTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Table Headings
    //     </Tooltip>
    // );
    // const assignTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Assign
    //     </Tooltip>
    // );
    // const tatTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Tat
    //     </Tooltip>
    // );


    /////////////////////////////////////////////////////////////

    const [value, onChange] = useState(new Date());

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);

    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);

    const handleShowModal = (id) => {

        formik.setValues({
            ...formik.values,
            assignedqueue: id,
        })
        setShowModal(true)
    };


    const [tatModal, setTatModal] = useState(false)
    const handleAssignTat = () => setTatModal(false);

    const handleShowTat = () => {
        setTatModal(true);
    }


    const [settingsTat, setSettingsTat] = useState();


    const tatformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            tat: ""
        },
        onSubmit: (values) => {

            getChangeJobTat(moment(value).format('Y-M-D[T]H:m'), selectedRows)((response) => {
                if (response && response.status) {
                    fetchQueueListing(pageno, limit, searchedData?.name, searchedData?.lock_box_file_name, searchedData?.clients, searchedData?.facility, searchedData?.user);
                    setTatModal(false);
                    setToaster('success')

                }
                else {
                    setToaster('error')

                    return () => clearTimeout(timer);
                }
            });
        },
    })

    const [state, setState] = useState({
        s_no: true,
        client: true,
        facility: true,
        file: true,
        internal_tat: true,
        split_type: true,
        payment_method: true,
        file_assigned: true,
        file_completed: true,
        index_file: true,
        index_file_name: true,
        available_file: true,
        file_received_on: true,
        lockbox_details: true,
        status: false,
        split_user: true,
        split_start_time: true,
        split_end_time: true,
        split_total_time: true,
        actions: true,
        page_count: true
    });

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [data, setData] = useState({})
    const [colorRow, setColorRow] = React.useState({});
    const [filterText, setFilterText] = React.useState('');
    const [filterClient, setFilterClient] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [serialNumber, setSerialNumber] = useState(false);


    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );

    const handleDblClickCopy = async (filename) => {
        navigator.clipboard.writeText(filename)

    };


    const columns = useMemo(
        () => [

            {
                id: 'S.NO',
                name: 'S.NO',
                selector: (row, index) => handleSerialNumber(index),
                // sortable: true,
                wrap: true,
                width: "60px",
                grow: 0,
                //omit: !state.s_no,
            },


            {
                id: 'CLIENT',
                name: 'CLIENT',
                selector: row => row?.client,
                //cell: (row) => <span className='text-truncate'> {row?.client}</span>,
                cell: (row) => <div>
                    {row?.client &&

                        <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                            <Tooltip disableInteractive title={row?.client} placement='top' arrow>
                                <span className='text-truncate' > {row?.client}</span>
                            </Tooltip>
                        </div>


                    }
                </div>
                ,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "160px",
                //grow: 0.1,
                omit: !state.client,
            },

            {
                id: 'FACILITY',
                name: 'FACILITY',
                selector: row => row?.facility,
                //cell: (row) => <span className='text-truncate'> {row?.facility}</span>,
                cell: (row) =>
                    <div>
                        {row?.facility &&

                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: '120px' }} >
                                <Tooltip disableInteractive title={row?.facility} placement='top' arrow>
                                    <span className='text-truncate' > {row?.facility}</span>
                                </Tooltip>
                            </div>

                        }
                    </div>
                ,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "160px",

                omit: !state.facility,
            },

            {
                id: 'JOB FILE NAME',
                name: 'JOB FILE NAME',
                selector: row => row?.file,
                cell: (row) => <div>
                    {row?.file &&
                        <div className='d-flex flex-row justify-content-between align-items-center gap-2' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onDoubleClick={() => handleDblClickCopy(row?.file)} >
                                <Tooltip disableInteractive title={row?.file} placement='top' arrow>
                                    <span className='text-truncate'> {row?.file}</span>
                                </Tooltip>
                            </div>
                            <div className='' onClick={() => onButtonClick(row?.converted_file)}>  <i className='bi bi-download' /></div>
                        </div>
                    }
                </div>
                ,
                sortable: true,
                width: '160px',
                reorder: true,
                left: true,
                wrap: true,
                // grow: 0.2,
                omit: !state.file,
            },
            {
                id: 'internal_tat',
                name: 'INTERNAL TAT',
                selector: row => row.internal_tat ? moment(row.internal_tat).format('M/DD/YY, H:mm') : "---",
                cell: (row) => <span onClick={() => handleRowClick(row)}>{row.internal_tat ? moment(row.internal_tat).format('M/DD/YY, H:mm') : "---"}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                //width: "90px",
                // grow: 0.1,
                omit: !state.internal_tat,
            },
            {
                id: 'split_type',
                name: 'SPLIT TYPE',
                selector: row => row?.split_type,
                cell: (row) => <span className='text-truncate ' style={{ width: "120px" }} onClick={() => handleRowClick(row)}> {row?.split_type}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                minWidth: "80px",
                grow: 0,
                omit: !state.split_type,
            },

            {
                id: 'FILE ASSIGNED',
                name: 'FILE ASSIGNED',
                cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}>{row.file_assigned}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                minWidth: "90px",
                grow: 0,
                omit: !state.file_assigned,
            },

            {
                id: 'file_completed',
                name: 'FILE COMPLETED ',
                selector: row => row.file_completed,
                cell: (row) => <span onClick={() => handleRowClick(row)}>{row.file_completed}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                minWidth: "100px",
                grow: 0,
                omit: !state.file_completed,
            },

            {
                id: 'INDEX FILE',
                name: 'INDEX FILE',
                selector: row => row?.index_file_name,
                cell: (row) => <React.Fragment>

                    {row?.index_file_name &&
                        <div className='d-flex flex-row justify-content-between align-items-center gap-2' onClick={() => handleRowClick(row)} style={{ width: "120px" }} >
                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' >
                                <Tooltip disableInteractive title={row?.index_file_name} placement='top' arrow>

                                    <span className='text-truncate' onDoubleClick={() => handleDblClickCopy(row?.file)} >{row?.index_file_name}</span>
                                </Tooltip>
                            </div>
                            <div className='' onClick={() => onButtonClick(row?.index_file)}>  <i className='bi bi-download' /></div>
                        </div>
                    }
                </React.Fragment>
                ,

                sortable: true,
                reorder: true,
                minWidth: "120px",
                left: true,
                wrap: true,
                grow: 0,
                omit: !state.index_file,
            },

            {
                id: 'AVAILABLE FILE',
                name: 'AVAILABLE FILE ',
                cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}> {row.available_file}</span>,
                sortable: true,
                reorder: true,
                right: true,
                //width: "90px",
                wrap: true,
                minWidth: "90px",

                grow: 0,
                omit: !state.available_file,
            },
            {
                id: 'page_count',
                name: 'Page Count ',
                cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}> {row.total_pages}</span>,
                sortable: true,
                reorder: true,
                right: true,
                //width: "90px",
                wrap: true,
                grow: 0.1,
                omit: !state.page_count,
            },
            {
                id: 'file_received_on',
                name: 'FILE RECEIVED DATE AND TIME ',
                selector: row => row.file_received_on,
                cell: (row) => <div onClick={() => handleRowClick(row)}>
                    {row.file_received_on ? moment(row.file_received_on).format('M/DD/YY, H:mm') : "---"}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                minWidth: "150px",
                wrap: true,
                // grow: 0.1,
                omit: !state.file_received_on,
            },

            {
                id: 'LOCKBOX DETAILS',
                name: 'LOCKBOX DETAILS',
                selector: row => row?.lockbox_details,

                cell: (row) => <div>
                    {row?.lockbox_details &&
                        <div className='d-flex flex-row justify-content-between align-items-center gap-2'>
                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                                <Tooltip disableInteractive title={row?.lockbox_details} placement='top' arrow>

                                    <span className=' text-truncate ' onDoubleClick={() => handleFilterJobmonitor(row.lockbox_details)}> {row.lockbox_details}</span>
                                </Tooltip>
                            </div>
                        </div>
                    }
                </div>
                ,
                sortable: true,
                wrap: true,
                // grow: 0.1,
                reorder: true,

                omit: !state.lockbox_details,
                minWidth: '100px',

            },

            {
                id: 'split_user',
                name: 'SPLIT USER ',
                selector: row => row.split_user,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                        <Tooltip disableInteractive title={row?.split_user} placement='top' arrow>
                            <span className='text-truncate'> {row?.split_user}</span>
                        </Tooltip>

                    </div>,

                sortable: true,
                reorder: true,
                left: true,
                width: "100px",
                wrap: true,
                //grow: 0.1,
                omit: !state.split_user,
            },

            {
                id: 'split_start_time',
                name: 'SPLIT START TIME ',
                selector: row => row.split_start_time,
                cell: (row) => <div onClick={() => handleRowClick(row)}>
                    {row.split_start_time ? moment(row.split_start_time).format('M/DD/YY, H:mm') : "---"}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                //width: "100px",
                wrap: true,
                //grow: 0.1,
                omit: !state.split_start_time,
            },

            {
                id: 'split_end_time',
                name: 'SPLIT END TIME ',
                selector: row => row.split_end_time,
                cell: (row) => <div onClick={() => handleRowClick(row)}>
                    {row.split_end_time ? moment(row.split_end_time).format('M/DD/YY, H:mm') : "---"}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                // width: "90px",
                wrap: true,
                //grow: 0.1,
                omit: !state.split_end_time,
            },

            {
                id: 'split_total_time',
                name: 'SPLIT TOTAL TIME ',
                selector: row => row.split_total_time,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.split_total_time}</span>, sortable: true,
                reorder: true,
                left: true,
                width: "100px",
                wrap: true,
                // grow: 0.1,
                omit: !state.split_total_time,
            },


            // {
            //     id: 'status',
            //     name: 'status',
            //     selector: row => row?.status,
            //     sortable: true,
            //     wrap: true,
            //     grow: 0.1,
            //     reorder: true,
            //     omit: !state.status,

            // },
            {
                id: 'ACTION',
                name: 'ACTION',
                omit: !state.actions,
                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0 ">
                        {row?.is_freeze ?
                            "" :
                            <React.Fragment>
                                <div onClick={() => handleRowClick(row)} style={{ width: "40px" }}>
                                    <div className="qms-btn me-1" >
                                        {!row?.is_completed ?
                                            <Button variant="btn fs-7 fw-bold btn-sm btn-outline-secondary" onClick={() => setIsSwal({ id: row?.file_id, show: true })}>
                                                Reject
                                            </Button>
                                            : ""
                                        }
                                    </div>
                                </div>

                            </React.Fragment>
                        }

                        <Tooltip disableInteractive title='History' placement='top' arrow>
                            <div className='' style={{ fontSize: '1.5em' }} onClick={() => handleRejectHistory(row.file_id)} >
                                <BsClockHistory color={"#000000"} />
                            </div>
                        </Tooltip>
                    </div>,
                // ignoreRowClick: false,
                // //allowOverflow: false,
                right: true,
                // wrap: true,
                width: '80px',
                button: false,
            },


            // {
            //     id: 'external_tat',
            //     name: 'External Tat',
            //     selector: row => row.external_tat ? moment(row.external_tat).format('M/DD/YY, H:mm') : "---",
            //     sortable: true,
            //     reorder: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.1,
            //     // omit: !state.external_tat,
            // },

            // {
            //     id: 'payment_methodtype',
            //     name: 'Payment Method ',
            //     selector: row => row.payment_method,
            //     sortable: true,
            //     reorder: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.1,
            // omit: !state.payment_methodtype,
            // },





            // {
            //     id: 'page_end_index',
            //     name: 'page_end_index ',
            //     selector: row => row.page_endindex,
            //     sortable: true,
            //     reorder: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.1,
            // omit: !state.page_end_index,
            // },
            // {
            //     id: 'page_start_index',
            //     name: 'page_start_index ',
            //     selector: row => row.page_startindex,
            //     sortable: true,
            //     reorder: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.1,
            // omit: !state.page_start_index,
            // },





        ],
        [state, pageno],
    );

    const lockboxformik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            name: "",
            lock_box_file_name: "",
            clients: [],
            facility: [],
            user: [],
            // status: "",

        },
        // validationSchema,
        onSubmit: (values) => {
            searchData(values)
            // alert('723')

            setSearchedData({
                name: values?.name ? values?.name : "",
                lock_box_file_name: values?.lock_box_file_name ? values?.lock_box_file_name : "",
                clients: values?.clients ? values?.clients : [],
                facility: values?.facility ? values?.facility : [],
                split_user: values?.user ? values?.user : [],
            })

            handleResetDataTable()
        },
    });



    useEffect(() => {
        if (file_id) {
            // console.log(file_id);
            // alert('600')

            lockboxformik.setValues({
                ...lockboxformik.values,
                lock_box_file_name: file_id,
                clients: [],
                facility: [],
            })
            setSearchedData({
                ...searchedData,
                lock_box_file_name: file_id,
            })
            fetchQueueListing(pageno, limit, null, file_id)
        }
        else {

            fetchQueueListing(pageno, limit)

        }
    }, []);

    useEffect(() => {
        getAllClientDropdown()((response) => {
            if (response?.status) {
                setClients(response?.data)
            }
        })
    }, [])

    useEffect(() => {
        if (selectValue && selectValue.length > 0) {
            getFacilityDropdown(selectValue?.map((value) => value?.value))((response) => {
                if (response && response.status) {
                    setFacilities(response.data.facilities?.map(result => { return { options: result?.facilities, label: result?.client } }))
                }
            });
        }

    }, [selectValue])

    const navigate = useNavigate();

    const handleFilterJobmonitor = (lockbox) => {
        // navigate(LOCKBOX_FILES_QMS + lockbox);
        changeAllTitleFunction("Job Monitor", `${LOCKBOX_FILES_QMS + lockbox}`)
    }

    // const handleFilterJobmonitor = (lockbox) => {
    //     navigate(LOCKBOX_FILES_QMS + lockbox);
    // }


    const fetchQueueListing = async (page, page_limit, name, lock_box_file_name, clients, facility, split_user) => {
        setLoading(true)
        await fetchAllLockboxFilesData({ page, page_limit, name, lock_box_file_name, clients, facility, split_user })((response) => {
            if (response?.data) {
                // alert('655')

                setData(response?.data);

                setLimit(page_limit);
                setPageNo(page)
                setTotalRows(response?.data?.count);
                setLoading(false);

            }
        })
    };


    const handlePageChange = page => {
        setPageNo(page)

        fetchQueueListing(page, limit, searchedData?.name, searchedData?.lock_box_file_name, searchedData?.clients, searchedData?.facility, searchedData?.user);

    };

    const handlePerRowsChange = async (newPerPage, page) => {

        fetchQueueListing(1, newPerPage, searchedData?.name, searchedData?.lock_box_file_name, searchedData?.clients, searchedData?.facility, searchedData?.user);


        setPageNo(1)
        setLimit(newPerPage);

        handleResetDataTable()

    };


    const onButtonClick = (file) => {
        // using Java Script method to get PDF file
        const fileURL = file;
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();
    }

    const [searchedData, setSearchedData] = useState({
        name: "",
        lock_box_file_name: "",
        clients: [],
        facility: [],
        user: [],
    })





    const searchData = async ({ name, lock_box_file_name, clients, facility }) => {
        fetchQueueListing(1, limit, name, lock_box_file_name, clientList, selectedFaci, selectedUse);
        // alert('743')


    }

    const handleRowSelected = React.useCallback(state => {
        var ids = [];

        state.selectedRows.map((row, index) => {
            ids.push(row.id);
        })

        setSelectedRows(ids);

    }, []);

    var i = 0;

    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.file_id == row.file_id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.file_id == row.file_id ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];

    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })

    };
    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })

    const handleRejectSplit = (pk) => {
        setIsSwal({ show: false })

        rejectJob(pk)(async (response) => {
            setIsSwal({ show: false })
            if (response.status) {
                setToaster('success')
                const timer = setTimeout(() => {
                    setToaster(false)
                }, 2000);
                fetchQueueListing(pageno, limit, searchedData?.name, searchedData?.lock_box_file_name, searchedData?.clients, searchedData?.facility, searchedData?.user);


                return () => clearTimeout(timer);
            }
            else {
                setToastMessage({
                    status: true,
                    message: response?.errors?.lockbox_file,
                    type: 'danger',
                })

                fetchQueueListing(pageno, limit, searchedData?.name, searchedData?.lock_box_file_name, searchedData?.clients, searchedData?.facility, searchedData?.user);


                const timer = setTimeout(() => {
                    setToastMessage(
                        {
                            status: false,
                            message: "",
                            type: "",
                        }

                    )
                }, 2000);

            }
        });
    }

    const options = [
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];

    const handleSelectChange = (selected) => {
        let selectedIds = [];
        let selectedOptions = [];


        if (selected && selected?.length > 0) {

            selected?.map((option) => {
                selectedIds.push(option.value)
                selectedOptions.push(option)
            })
            if (selected?.length > 1) {
                setSelectedFacility([])
                setFacilities([])
                setSearchedData({
                    ...searchedData,
                    facility: ""
                })
                setSetlectedFaci()

            }
        }
        else {
            selectedOptions = [];
            selectedIds = [];
            setFacilities([])
            setSelectedFacility([])
        }
        lockboxformik.setValues({ ...lockboxformik.values, "clients": selectedIds ?? [], "facility": selectedFacility });
        setSelectValue(selectedOptions ? selectedOptions : "")

        setClientList(selectedIds)
    };

    const [selectedFaci, setSetlectedFaci] = useState();
    const [selectedUse, setSetlectedUse] = useState();

    const handleFacilityChange = (selected) => {
        lockboxformik.setValues({ ...lockboxformik.values, "facility": selected.map((option) => option?.value) });

        // console.log(selected.map((option) => option?.value));
        setSelectedFacility(selected)
        setSetlectedFaci(selected.map((option) => option?.value))

    };
    const handleUserChange = (selected) => {
        lockboxformik.setValues({ ...lockboxformik.values, "user": selected.map((option) => option?.value) });

        // console.log(selected.map((option) => option?.value));
        setSelectedUser(selected)
        setSetlectedUse(selected.map((option) => option?.value))

    };

    const statuses = [
        { value: 'received', label: 'NEW' },
        { value: 'inprocess', label: 'INPROCESS' },
        { value: 'completed', label: 'COMPLETE' },
        { value: 'generated', label: 'GENERATED' },

    ]

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>
                {state && lockboxformik.values && lockboxformik.values[state] && lockboxformik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };

    const handleResetCallFetch = () => {

        const currentUrl = window.location.pathname; // Get the current URL
        const decodedUrl = decodeURI(currentUrl); // Decode the URL
        const newUrl = encodeURI(decodedUrl.replace(/\%20/g, '')); // Remove all occurrences of '%20' and encode the new URL
        // const currentUrl2 = SINGLE_LOCKBOX_LOCKBOX_FILES + file_id; // Get the current URL
        // const decodedUrl2 = decodeURI(currentUrl); // Decode the URL
        const newUrl2 = encodeURI(decodedUrl.replace(/\%20/g, '')); // Remove all occurrences of '%20' and encode the new URL

        if (newUrl == newUrl2) {
            navigate(LOCKBOXFILES)
            window.location.reload()

        } else {
            window.location.reload()

        }

    }
    const handleSerialNumber = (index) => {
        return pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1
    }

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);
    const [isFocusedUser, setIsFocusedUser] = useState(false);


    const handleRejectHistory = (id) => {
        getRejectHistory(id)((response) => {
            if (response && response.status) {
                setHistoryData(response?.data)
                setToggleHistoryPopup({
                    isShow: true,
                    id: id
                })
            } else {
                // setToastMessage({
                //     status: true,
                //     message: ,
                //     type: 'danger',
                // })

                setToggleHistoryPopup({
                    isShow: false,
                    id: id
                })

            }
        })
    }

    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} />

            <main id="main" className="main">

                {isSwal.show === true &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Ok!"
                        cancelBtnText="Cancel"
                        //confirmBtnBsStyle="danger"
                        confirmBtnCssClass="custom-confirm-button-class"
                        cancelBtnCssClass="custom-cancel-button-class"
                        title="Are you sure?"
                        onConfirm={() => { handleRejectSplit(isSwal.id) }}
                        onCancel={() => { setIsSwal({ ...isSwal, show: false }) }}
                        focusCancelBtn
                    >
                        You can't recover the Splited files & Job's and cannot revert it as well!
                    </SweetAlert>
                }
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                        {
                            status: false,
                            message: "",
                            type: "",
                        }

                    )}
                        show={ToastMessage?.status} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                    </Toast>

                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>LockBox Files</h1>
                </div>

                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8  py-2">
                                <Form onSubmit={lockboxformik.handleSubmit} onReset={() => {
                                    handleResetCallFetch()

                                }}>
                                    <div className=" ">
                                        <div className="px-4 py-4 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap ">
                                            <div className='lock-box-files-select'>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid fileManagePadding b-r-10"
                                                    name='name'
                                                    type="search"
                                                    style={{ width: "100%", fontSize: "17px" }}
                                                    placeholder="File name"
                                                    aria-label="Search Input"
                                                    value={lockboxformik.values?.name}
                                                    // value={input.file}
                                                    onChange={lockboxformik.handleChange}
                                                />
                                            </div>
                                            <div className='lock-box-files-select'>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid fileManagePadding b-r-10"
                                                    name='lock_box_file_name'
                                                    type="search"
                                                    style={{ width: "100%" }}
                                                    placeholder="Lockbox File name"
                                                    aria-label="Search Input"
                                                    value={lockboxformik.values?.lock_box_file_name}
                                                    // value={input.file}
                                                    onChange={lockboxformik.handleChange}
                                                />
                                            </div>
                                            <div className='lock-box-files-select'>
                                                <Select
                                                    className={"basic-single select-search fontSize-style"}
                                                    isMulti
                                                    value={selectValue}
                                                    name="clients"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                    options={options}
                                                    onChange={(selected) => handleSelectChange(selected)}
                                                    placeholder="Select Client"
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),
                                                    }}
                                                />

                                                {!!lockboxformik.errors?.clients &&
                                                    <span className='text-danger'>{lockboxformik.errors?.clients}</span>
                                                }
                                            </div>



                                            <div className='lock-box-files-select'>
                                                <Form.Group>
                                                    <Select
                                                        className="basic-single select-search-facility"
                                                        classNamePrefix="select"
                                                        isMulti
                                                        value={selectedFacility}
                                                        isDisabled={selectValue.length > 1 ? true : false}
                                                        name="facility"
                                                        components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="facility" /> }}
                                                        options={facilities}
                                                        onFocus={() => setIsFocusedFacility(true)}
                                                        onBlur={() => setIsFocusedFacility(false)}
                                                        onChange={(selected) => handleFacilityChange(selected)}
                                                        placeholder="Select Facility"
                                                        styles={{
                                                            container: (styles, state) => ({
                                                                ...styles,
                                                                borderRadius: "9px",
                                                            }),
                                                            placeholder: styles => ({
                                                                ...styles,
                                                                fontSize: '17px'
                                                            }),
                                                            control: (styles, state) => ({
                                                                ...styles,
                                                                backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                borderStyle: "none", boxShadow: "none",
                                                                borderRadius: "10px",
                                                                maxHeight: state.isFocused ? "auto" : "30px",
                                                            }),
                                                            indicatorSeparator: styles => ({
                                                                ...styles, width: "0px",
                                                                minHeight: "20px"
                                                            }),

                                                            valueContainer: (styles, state) => ({
                                                                ...styles,
                                                                maxHeight: isFocusedFacility ? "auto" : "30px",
                                                            }),


                                                        }} />
                                                    <Form.Control.Feedback type="invalid">{lockboxformik.errors?.facility}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className='lock-box-files-select'>
                                                <Form.Group>
                                                    <Select
                                                        className="basic-single select-search-facility"
                                                        classNamePrefix="select"
                                                        isMulti
                                                        value={selectedUser}
                                                        // isDisabled={selectValue.length > 1 ? true : false}
                                                        name="user"
                                                        components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="user" /> }}
                                                        options={userlist}
                                                        onFocus={() => setIsFocusedUser(true)}
                                                        onBlur={() => setIsFocusedUser(false)}
                                                        onChange={(selected) => handleUserChange(selected)}
                                                        placeholder="Select User"
                                                        styles={{
                                                            container: (styles, state) => ({
                                                                ...styles,
                                                                borderRadius: "9px",
                                                            }),
                                                            placeholder: styles => ({
                                                                ...styles,
                                                                fontSize: '17px'
                                                            }),
                                                            control: (styles, state) => ({
                                                                ...styles,
                                                                backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                borderStyle: "none", boxShadow: "none",
                                                                borderRadius: "10px",
                                                                maxHeight: state.isFocused ? "auto" : "30px",
                                                            }),
                                                            indicatorSeparator: styles => ({
                                                                ...styles, width: "0px",
                                                                minHeight: "20px"
                                                            }),

                                                            valueContainer: (styles, state) => ({
                                                                ...styles,
                                                                maxHeight: isFocusedUser ? "auto" : "30px",
                                                            }),


                                                        }} />
                                                    <Form.Control.Feedback type="invalid">{lockboxformik.errors?.user}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>



                                            {/* <div className='col-lg-3'>
                                                <div>
                                                    <Form.Group>
                                                        <Select
                                                            className="select-search-client"
                                                            classNamePrefix="select"
                                                            isMulti
                                                            value={selectedStatus}
                                                            name="status"
                                                            options={statuses}
                                                            onChange={(selected) => handleStatusesChange(selected)}
                                                            placeholder="Select Status..."
                                                            styles={{ control: styles => ({ ...styles, backgroundColor: "hsl(204deg 33.33% 97.06%)", borderStyle: "none", boxShadow: "none", borderRadius: "10px" }), indicatorSeparator: styles => ({ ...styles, width: "0px", minHeight: "20px" }), container: styles => ({ ...styles, borderRadius: "9px", }) }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{lockboxformik.errors?.status}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div> */}


                                            <div>
                                                <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                    {/* <Button className="btn btn-light btn-active-primary" type="submit">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiSearch />
                                                        </span>

                                                    </Button> */}
                                                    <div className="">
                                                        <button className="btn btn-light btn-active-primary jobMonitor-Buttons  btn btn-primary" type="submit" aria-expanded="false">
                                                            <span className="svg-icon svg-icon-3">
                                                                <BiSearch />
                                                            </span>
                                                        </button>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                    {/* <Button className="btn btn-light btn-active-primary" type="reset">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiReset />
                                                        </span>

                                                    </Button> */}
                                                    <div className="">
                                                        <button className="btn btn-light btn-active-primary jobMonitor-Buttons  btn btn-primary" type="reset" aria-expanded="false">
                                                            <span className="svg-icon svg-icon-3">
                                                                <BiReset />
                                                            </span>
                                                        </button>
                                                    </div>
                                                </Tooltip>
                                            </div>

                                            <div className="dropdown">
                                                <Tooltip disableInteractive title="Table Headings" placement='top' arrow>

                                                    <button className="btn btn-light btn-active-primary jobMonitor-Buttons  btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <IoIosSwitch />
                                                    </button>
                                                </Tooltip>
                                                <ul className="dropdown-menu hide-scroll-bar" style={{ height: '500px', overflowX: 'scroll' }} onClick={(e) => e.stopPropagation()}>
                                                    {/* <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.s_no} onChange={() => setState({ ...state, s_no: !state.s_no })} type="checkbox" id="s_no" />
                                                                        <label className="form-check-label" htmlFor="s_no">
                                                                            S.NO
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li> */}
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.client} onChange={() => setState({ ...state, client: !state.client })} type="checkbox" id="client" />
                                                                <label className="form-check-label" htmlFor="client">
                                                                    Client
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.facility} onChange={() => setState({ ...state, facility: !state.facility })} type="checkbox" id="facility" />
                                                                <label className="form-check-label" htmlFor="facility">
                                                                    Facility
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.file} onChange={() => setState({ ...state, file: !state.file })} type="checkbox" id="file" />
                                                                <label className="form-check-label" htmlFor="file">
                                                                    Job File Name
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {/* <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.amount} onChange={() => setState({ ...state, amount: !state.amount })} type="checkbox" id="amount" />
                                                                        <label className="form-check-label" htmlFor="amount">
                                                                            Amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li> */}
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.internal_tat} onChange={() => setState({ ...state, internal_tat: !state.internal_tat })} type="checkbox" id="internal_tat" />
                                                                <label className="form-check-label" htmlFor="internal_tat">
                                                                    Internal Tat
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.split_type} onChange={() => setState({ ...state, split_type: !state.split_type })} type="checkbox" id="split_type" />
                                                                <label className="form-check-label" htmlFor="split_type">
                                                                    Split Type
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {/* <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.payment_method} onChange={() => setState({ ...state, payment_method: !state.payment_method })} type="checkbox" id="payment_method" />
                                                                        <label className="form-check-label" htmlFor="payment_method">
                                                                            Payment Method
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li> */}
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.file_assigned} onChange={() => setState({ ...state, file_assigned: !state.file_assigned })} type="checkbox" id="file_assigned" />
                                                                <label className="form-check-label" htmlFor="file_assigned">
                                                                    File Assigned
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.file_completed} onChange={() => setState({ ...state, file_completed: !state.file_completed })} type="checkbox" id="file_completed" />
                                                                <label className="form-check-label" htmlFor="file_completed">
                                                                    File Completed
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.index_file} onChange={() => setState({ ...state, index_file: !state.index_file })} type="checkbox" id="index_file" />
                                                                <label className="form-check-label" htmlFor="index_file">
                                                                    Index File
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.available_file} onChange={() => setState({ ...state, available_file: !state.available_file })} type="checkbox" id="available_file" />
                                                                <label className="form-check-label" htmlFor="available_file">
                                                                    Available File
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.page_count} onChange={() => setState({ ...state, page_count: !state.page_count })} type="checkbox" id="page_count" />
                                                                <label className="form-check-label" htmlFor="page_count">
                                                                    Page Count
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.file_received_on} onChange={() => setState({ ...state, file_received_on: !state.file_received_on })} type="checkbox" id="file_received_on" />
                                                                <label className="form-check-label" htmlFor="file_received_on">
                                                                    File Received Date And Time
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.lockbox_details} onChange={() => setState({ ...state, lockbox_details: !state.lockbox_details })} type="checkbox" id="lockbox_details" />
                                                                <label className="form-check-label" htmlFor="lockbox_details">
                                                                    Lockbox Details
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.split_user} onChange={() => setState({ ...state, split_user: !state.split_user })} type="checkbox" id="split_user" />
                                                                <label className="form-check-label" htmlFor="split_user">
                                                                    Split User
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.split_start_time} onChange={() => setState({ ...state, split_start_time: !state.split_start_time })} type="checkbox" id="split_start_time" />
                                                                <label className="form-check-label" htmlFor="split_start_time">
                                                                    Split Start Time
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.split_end_time} onChange={() => setState({ ...state, split_end_time: !state.split_end_time })} type="checkbox" id="split_end_time" />
                                                                <label className="form-check-label" htmlFor="split_end_time">
                                                                    Split End Time
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.split_total_time} onChange={() => setState({ ...state, split_total_time: !state.split_total_time })} type="checkbox" id="split_total_time" />
                                                                <label className="form-check-label" htmlFor="split_total_time">
                                                                    Split Total TIme
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.actions} onChange={() => setState({ ...state, actions: !state.actions })} type="checkbox" id="actions" />
                                                                <label className="form-check-label" htmlFor="actions">
                                                                    Action
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    {/* <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.status} onChange={() => setState({ ...state: !state.status })} type="checkbox" id="status" />
                                                                        <label className="form-check-label" htmlFor="status">
                                                                            status
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li> */}
                                                </ul>

                                            </div>

                                        </div>

                                    </div>
                                </Form>

                                <div className="card-body" style={{ padding: "0px 2.25rem" }}>
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}

                                                    data={data?.results}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination

                                                    pointerOnHover={true}

                                                    paginationServer
                                                    onSelectedRowsChange={handleRowSelected}
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    // subHeader
                                                    // subHeaderComponent={subHeaderComponentMemo}
                                                    theme="solarized"
                                                    customStyles={queueDatatableCustomStyles}

                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Manual TAT  Modal Starts*/}


                <Modal show={show} onHide={handleClose} centered className='manual-tat'>
                    <Modal.Header closeButton>
                        <Modal.Title> Change TAT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className=''>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Current TAT</Form.Label>
                                    <Form.Control type="text" value={moment(settings?.tat).format('MMMM Do YYYY, h:mm a')} disabled />
                                </Form.Group>
                                <Form.Group className="mb-3 d-grid" controlId="formBasicEmail">
                                    <Form.Label>Change TAT</Form.Label>
                                    <DateTimePicker
                                        onChange={onChange}
                                        value={value}
                                        className='b-r-10' />
                                </Form.Group>
                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <Button type="submit" className='btn btn-light btn-active-primary' onClick={changeTat} variant="primary" >Submit</Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
                {/* Manual TAT  Modal Ends*/}

                {/* Manual TAT  Modal Starts*/}

                <Modal show={showModal} onHide={handleCloseModal} centered className='assign'>
                    <Modal.Header closeButton>
                        <Modal.Title>Assign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-4'>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className='row d-flex align-items-start'>
                                <Form.Control onChange={formik.handleChange} name="staff" as="select" aria-label="Default select example">
                                    <option>Choose User</option>
                                    {userlist?.map((results, index) =>
                                        <option key={index} value={results?.value}>{results?.label}</option>
                                    )}
                                </Form.Control>
                            </div>
                            <div className='d-flex flex-row justify-content-center mt-4'>
                                <Button type="submit" className='btn btn-light btn-active-primary' variant="primary">Submit</Button>
                            </div>
                        </Form>

                    </Modal.Body>

                </Modal>


                <Modal show={tatModal} onHide={handleAssignTat} centered >
                    <Modal.Header closeButton>
                        <Modal.Title>Change Tat</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-4'>
                        <Form onSubmit={tatformik.handleSubmit}>
                            <div className='row d-flex align-items-start'>
                                <Form.Group className="mb-3 d-grid" controlId="formBasicEmail">
                                    <Form.Label>Change TAT</Form.Label>
                                    <DateTimePicker
                                        onChange={onChange}
                                        value={value}
                                        className='b-r-10' />
                                </Form.Group>
                            </div>
                            <div className='d-flex flex-row justify-content-center mt-4'>
                                <Button type="submit" className='btn btn-light btn-active-primary' variant="primary">Submit</Button>
                            </div>
                        </Form>

                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer> */}
                </Modal>
                {/* Manual TAT  Modal Ends*/}


                <Modal size="xl" show={toggleHistoryPopup.isShow} onHide={() => { setToggleHistoryPopup({ isShow: false, job_id: null }), setHistoryData([]) }} centered className='manual-tat'>
                    <Modal.Header closeButton>
                        <Modal.Title>Logs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table history-log-table" >
                            <thead>
                                <tr >
                                    <th >Date</th>
                                    <th >Action</th>
                                    <th >Staff</th>
                                    <th >Split start time</th>
                                    <th >Split end time</th>
                                    <th >Split total time</th>
                                    <th ></th>
                                </tr>
                            </thead>
                            {historyData?.map((i, index) =>
                                <tbody key={index}>
                                    <tr >
                                        <td>{moment(i.created_date).format("M/DD/YY, H:mm")}</td>
                                        <td><div className='text-truncate' >{i.activity}</div></td>
                                        <td><div className='text-truncate' style={{ width: "150px" }}>{i.staff}</div></td>
                                        <td><div className='text-truncate' >{i.split_start_time}</div></td>
                                        <td><div className='text-truncate' >{i.split_end_time}</div></td>
                                        <td><div className='text-truncate' >{i.split_total_time}</div></td>
                                    </tr>
                                </tbody>
                            )}
                        </table>

                    </Modal.Body>

                </Modal>

            </main >

        </React.Fragment >
    )
}

export default Lockbox;