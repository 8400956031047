import React, { useRef, useState } from 'react'
import clear from "../../../../../assets/img/clear-icon.svg";
import deleteserviceline from "../../../../../assets/img/delete-icon-serviceline.svg";
import copyicon from "../../../../../assets/img/copy-icon.svg";
import newicon from "../../../../../assets/img/new-icon.svg";
import { Form, InputGroup, Overlay, Popover, Tooltip } from 'react-bootstrap';
import { handleWheel, handlePreventEKeyPress } from '../gridFunctions';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { BiChevronRight } from 'react-icons/bi';
import { FormikProvider } from 'formik';
import { isActiveDbkField } from '../../../Dashboard/functions';
import { EDIT_WORK, QA_REVIEW, DATA_ENTRY, IFRAME } from '../../../../../routeNames';
const Servicelines = (props) => {
    const [revCodeClaimOnFocus, setRevCodeClaimOnFocus] = useState(false)
    const {
        gridRef,
        propCodeRef,
        formik,
        handleDoubleClickClaimAdjustment,
        handleDoubleClickServiceLineAdjustment,
        eobFocus,
        isViewOnllyGrid,
        index,
        serviceIndex,
        setServiceIndex,
        handleNewServiceLine,
        newPoupUp,
        setNewPoupUp,
        slCount,
        setSlCount,
        setMoonji,
        disableCorresField,
        setIsDrag,

        x12ClaimCodes,
        setx12ClaimCodes,
        pcClaimPopup,
        setPcClaimPopup,
        formClaimRc,
        setPcClaimFormik,
        handleClaimPayerPcVerify,
        propCodeClaimData,
        handleMultipleClaimPropCode,
        setFormClaimRc,

        x12Codes,
        setx12Codes,
        pcPopup,
        setPcPopup,
        formRc,
        setPcFormik,
        handlePayerPcVerify,
        propCodeData,
        handleMultiplePropCode,
        setFormRc,


        handleRemainingBalanceCalculation,
        handleColumnCalculation,
        handleRowCalculation,
        setDefaultValueAssignedWorkServiceLine,

        handleClaimCalculation,
        handleSlCalculation,
        handlePatresCalculation,
        handleClaimPatresCalculation,
        handleSlAndClaimDifference,
        setDefaultValueAssignedWorkClaimAdj,
        handlePageNavigate,
        jobData,
        job_id,
        log_id
    } = props;

    const ref = useRef(null);
    const refPC = useRef([]);
    const claimRefPC = useRef([]);
    const [currentServiceLineRef, setCurrentServiceLineRef] = useState(null);
    const [currentClaimRef, setCurrentClaimRef] = useState(null);
    const [claimrevCodeFocus, setClaimrevCodeFocus] = useState(false);



    const handleChangeEndDate = async (e, serviceLineIndex) => {
        await formik.setValues((prevState) => ({
            ...prevState,
            claims: prevState.claims?.map((claim, claimIndex) => {
                if (index === claimIndex) {
                    return {
                        ...claim,
                        service_lines: claim.service_lines?.map((serviceLine, ind) => {
                            if (ind === serviceLineIndex) {
                                return {
                                    ...serviceLine,
                                    to_date: e.target.value,
                                };
                            }
                            return serviceLine;
                        }),
                    };
                }
                return claim;
            }),
        }));
    }

    const handleFloatValue = async (e) => {
        e.target.value = parseFloat(e.target.value).toFixed(2);
        e.target.type = "text"
        formik.handleChange(e)
        e.target.type = "number"
    }

    const handleAmountFloat = (event) => {

        const value = event.target.value;
        if (value.includes('.') && value.split('.')[1].length > 2) {
            return;
        }
        let e = {
            target: {}
        }
        e.target.type = "text"
        e.target.value = event.target.value
        e.target.name = event.target.name
        formik.handleChange(e);


    }

    const changeGroupCode = async (event, sfIndex, adjIndex) => {
        const oldval = formik.values.claims[index].service_lines[sfIndex].adjustments[adjIndex].group_code;
        const value = event.target.value;
        let patres = {
            target: {}
        }
        let clpatres = {
            target: {}
        }
        let fake_patres = {
            target: {}
        }
        if (value == 'PR') {
            patres.target.value = parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) + parseFloat(formik.values.claims[index].service_lines[sfIndex].adjustments[adjIndex].amount ? formik.values.claims[index].service_lines[sfIndex].adjustments[adjIndex].amount : 0)).toFixed(2);
            patres.target.name = `claims[${index}].sl_patres`
            patres.target.type = 'number'
            formik.handleChange(patres)


            clpatres.target.value = parseFloat(parseFloat(patres.target.value ? patres.target.value : 0) +
                parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) +
                parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0)) +
                parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))
            ).toFixed(2);
            clpatres.target.name = `claims[${index}].claim_patres`
            clpatres.target.type = 'text'
            formik.handleChange(clpatres)

            fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
            fake_patres.target.name = `claims[${index}].fake_sl_patres`
            fake_patres.target.type = 'text'
            formik.handleChange(fake_patres)
        }
        else if (oldval == 'PR') {
            patres.target.value = parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(formik.values.claims[index].service_lines[sfIndex].adjustments[adjIndex].amount ? formik.values.claims[index].service_lines[sfIndex].adjustments[adjIndex].amount : 0)).toFixed(2);
            patres.target.name = `claims[${index}].sl_patres`
            patres.target.type = 'number'
            formik.handleChange(patres)


            clpatres.target.value = parseFloat(parseFloat(patres.target.value ? patres.target.value : 0) +
                parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) +
                parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0)) +
                parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))
            ).toFixed(2);
            clpatres.target.name = `claims[${index}].claim_patres`
            clpatres.target.type = 'text'
            formik.handleChange(clpatres)

            fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
            fake_patres.target.name = `claims[${index}].fake_sl_patres`
            fake_patres.target.type = 'text'
            formik.handleChange(fake_patres)
        }
        formik.handleChange(event)


    }

    const changeClaimGroupCode = async (event, adjIndex) => {
        const value = event.target.value;
        let oldvalue = formik.values.claims[index].claim_adjustments[adjIndex].group_code
        let claim_amount_pr = {
            target: {}
        }
        let clpatres = {
            target: {}
        }
        let fake_patres = {
            target: {}
        }
        if (value.includes('.') && value.split('.')[1].length > 2) {
            return;
        }
        if (value == 'PR') {
            claim_amount_pr.target.value = parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) + parseFloat(formik.values.claims[index].claim_adjustments[adjIndex].amount ? formik.values.claims[index].claim_adjustments[adjIndex].amount : 0)).toFixed(2)
            claim_amount_pr.target.name = `claims[${index}].claim_adjustment_total_pr`
            claim_amount_pr.target.type = 'number'
            formik.handleChange(claim_amount_pr)

            clpatres.target.value = parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) +
                parseFloat(claim_amount_pr.target.value ? claim_amount_pr.target.value : 0) +
                parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0)) +
                parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))

            ).toFixed(2);
            clpatres.target.name = `claims[${index}].claim_patres`
            clpatres.target.type = 'text'
            formik.handleChange(clpatres)

            fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
            fake_patres.target.name = `claims[${index}].fake_sl_patres`
            fake_patres.target.type = 'text'
            formik.handleChange(fake_patres)


        }
        else if (oldvalue == 'PR') {
            claim_amount_pr.target.value = parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) - parseFloat(formik.values.claims[index].claim_adjustments[adjIndex].amount ? formik.values.claims[index].claim_adjustments[adjIndex].amount : 0)).toFixed(2)
            claim_amount_pr.target.name = `claims[${index}].claim_adjustment_total_pr`
            claim_amount_pr.target.type = 'number'
            formik.handleChange(claim_amount_pr)
            clpatres.target.value = parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) +
                parseFloat(claim_amount_pr.target.value ? claim_amount_pr.target.value : 0) +
                parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0)) +
                parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))

            ).toFixed(2);
            clpatres.target.name = `claims[${index}].claim_patres`
            clpatres.target.type = 'text'
            formik.handleChange(clpatres)

            fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
            fake_patres.target.name = `claims[${index}].fake_sl_patres`
            fake_patres.target.type = 'text'
            formik.handleChange(fake_patres)
        }


        formik.handleChange(event)

    }



    const changeClaimValue = async (event, type, adjIndex) => {
        const value = event.target.value;
        if (value.includes('.') && value.split('.')[1].length > 2) {
            return;
        }
        let oldvalue = 0
        if (type === 'amount') {
            oldvalue = formik.values.claims[index].claim_adjustments[adjIndex].amount;
        }
        else {
            oldvalue = formik.values.claims[index][type];
        }
        let claim_amount = {
            target: {}
        }
        let claim_amount_pr = {
            target: {}
        }
        let clpatres = {
            target: {}
        }
        let fake_patres = {
            target: {}
        }
        let cltotal = {
            target: {}
        }
        let remaining = {
            target: {}
        }



        switch (type) {
            case "claim_billed":
                cltotal.target.value = parseFloat((parseFloat(value ? value : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(formik.values.claims[index].claim_patres ? formik.values.claims[index].claim_patres : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cltotal.target.name = `claims[${index}].claim_balance`
                cltotal.target.type = 'text'
                formik.handleChange(cltotal)



                break;
            case "claim_discount":
                cltotal.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(value ? value : 0) +
                        parseFloat(formik.values.claims[index].claim_patres ? formik.values.claims[index].claim_patres : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cltotal.target.name = `claims[${index}].claim_balance`
                cltotal.target.type = 'text'
                formik.handleChange(cltotal)

                break;
            case "claim_deduct":
                clpatres.target.value = parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) +
                    parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(value ? value : 0) ? parseFloat(value ? value : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))
                ).toFixed(2);
                clpatres.target.name = `claims[${index}].claim_patres`
                clpatres.target.type = 'text'
                formik.handleChange(clpatres)

                fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
                fake_patres.target.name = `claims[${index}].fake_sl_patres`
                fake_patres.target.type = 'text'
                formik.handleChange(fake_patres)

                cltotal.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(clpatres.target.value ? clpatres.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cltotal.target.name = `claims[${index}].claim_balance`
                cltotal.target.type = 'text'
                formik.handleChange(cltotal)

                break;
            case "claim_coins":
                clpatres.target.value = parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) +
                    parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(value ? value : 0) ? parseFloat(value ? value : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))
                ).toFixed(2);
                clpatres.target.name = `claims[${index}].claim_patres`
                clpatres.target.type = 'text'
                formik.handleChange(clpatres)

                fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
                fake_patres.target.name = `claims[${index}].fake_sl_patres`
                fake_patres.target.type = 'text'
                formik.handleChange(fake_patres)

                cltotal.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(clpatres.target.value ? clpatres.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cltotal.target.name = `claims[${index}].claim_balance`
                cltotal.target.type = 'text'
                formik.handleChange(cltotal)

                break;
            case "claim_copay":
                clpatres.target.value = parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) +
                    parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(value ? value : 0) ? parseFloat(value ? value : 0) : parseFloat(0))
                ).toFixed(2);
                clpatres.target.name = `claims[${index}].claim_patres`
                clpatres.target.type = 'text'
                formik.handleChange(clpatres)

                fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
                fake_patres.target.name = `claims[${index}].fake_sl_patres`
                fake_patres.target.type = 'text'
                formik.handleChange(fake_patres)

                cltotal.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(clpatres.target.value ? clpatres.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cltotal.target.name = `claims[${index}].claim_balance`
                cltotal.target.type = 'text'
                formik.handleChange(cltotal)

                break;
            case "claim_cob":
                cltotal.target.value = parseFloat(parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(formik.values.claims[index].claim_patres ? formik.values.claims[index].claim_patres : 0) +
                        parseFloat(value ? value : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cltotal.target.name = `claims[${index}].claim_balance`
                cltotal.target.type = 'text'
                formik.handleChange(cltotal)

                break;
            case "claim_paid":
                cltotal.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(formik.values.claims[index].claim_patres ? formik.values.claims[index].claim_patres : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(value ? value : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cltotal.target.name = `claims[${index}].claim_balance`
                cltotal.target.type = 'text'
                formik.handleChange(cltotal)


                let paid_total = 0;
                let plb_total = 0;


                formik.values?.claims?.map((claim, ind) => {
                    paid_total =
                        parseFloat(paid_total ? paid_total : 0) +
                        parseFloat(claim.claim_paid ? claim.claim_paid : 0);
                });
                paid_total = parseFloat(paid_total ? paid_total : 0) - parseFloat(oldvalue ? oldvalue : 0) + parseFloat(value ? value : 0)
                formik.values?.plb_adjustments?.map((plb, ind) => {
                    plb_total =
                        parseFloat(plb_total ? plb_total : 0) +
                        parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
                });

                remaining.target.value = parseFloat(parseFloat(formik.values.check_amount ? formik.values.check_amount : 0) - parseFloat(paid_total ? paid_total : 0) + parseFloat(plb_total ? plb_total : 0)).toFixed(2);
                remaining.target.type = 'text';
                remaining.target.name = 'remaining_balance';
                formik.handleChange(remaining)

                break;

            case "amount":
                claim_amount.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(oldvalue ? oldvalue : 0)) + parseFloat(value ? value : 0)).toFixed(2)
                claim_amount.target.name = `claims[${index}].claim_adjustment_total`
                claim_amount.target.type = 'number'
                formik.handleChange(claim_amount)

                if (formik.values.claims[index].claim_adjustments[adjIndex].group_code == 'PR') {
                    claim_amount_pr.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) - parseFloat(oldvalue ? oldvalue : 0)) + parseFloat(value ? value : 0)).toFixed(2)
                    claim_amount_pr.target.name = `claims[${index}].claim_adjustment_total_pr`
                    claim_amount_pr.target.type = 'number'
                    formik.handleChange(claim_amount_pr)

                    clpatres.target.value = parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) +
                        parseFloat(claim_amount_pr.target.value ? claim_amount_pr.target.value : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0)) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))
                    ).toFixed(2);
                    clpatres.target.name = `claims[${index}].claim_patres`
                    clpatres.target.type = 'text'
                    formik.handleChange(clpatres)

                    fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
                    fake_patres.target.name = `claims[${index}].fake_sl_patres`
                    fake_patres.target.type = 'text'
                    formik.handleChange(fake_patres)


                    cltotal.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                        (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                            parseFloat(clpatres.target.value ? clpatres.target.value : 0) +
                            parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                            parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                            parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                            parseFloat(parseFloat(claim_amount.target.value ? claim_amount.target.value : 0) - parseFloat(claim_amount_pr.target.value ? claim_amount_pr.target.value : 0))
                        )).toFixed(2)
                    cltotal.target.name = `claims[${index}].claim_balance`
                    cltotal.target.type = 'text'
                    formik.handleChange(cltotal)

                }
                else {
                    cltotal.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                        (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                            parseFloat(formik.values.claims[index].claim_patres ? formik.values.claims[index].claim_patres : 0) +
                            parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                            parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                            parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                            parseFloat(parseFloat(claim_amount.target.value ? claim_amount.target.value : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                        )).toFixed(2)
                    cltotal.target.name = `claims[${index}].claim_balance`
                    cltotal.target.type = 'text'
                    formik.handleChange(cltotal)
                }
                break;


            default:
                break;
        }

        formik.handleChange(event)

    }




    const handleChangeFormik = (e, index, sfindex, type, adj) => {

        const value = e.target.value;
        if (value.includes('.')) {
            const decimalPart = value.split('.')[1];
            if (decimalPart.length > 2) {
                e.target.value = parseFloat(value).toFixed(2)
                return;
            }
        }
        let oldval = 0
        let old_claim = 0
        let old_balance = formik.values.claims[index].service_lines[sfindex].balance
        if (type == 'amount') {
            oldval = formik.values.claims[index].service_lines[sfindex].adjustments[adj].amount
        }
        else {
            oldval = formik.values.claims[index].service_lines[sfindex][type]
        }
        formik.handleChange(e)
        let claim = {
            target: {}
        }
        let sl = {
            target: {}
        }
        let row = {
            target: {}
        }
        let patres = {
            target: {}
        }
        let clpatres = {
            target: {}
        }
        let fake_patres = {
            target: {}
        }
        let sl_total = {
            target: {}
        }
        let cl_total = {
            target: {}
        }

        let remaining = {
            target: {}
        }


        switch (type) {
            case "billed":
                old_claim = formik.values.claims[index].claim_billed
                sl.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_billed ? formik.values.claims[index].sl_billed : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                sl.target.name = `claims[${index}].sl_billed`
                sl.target.type = 'text'
                formik.handleChange(sl)

                claim.target.value = parseFloat(sl.target.value).toFixed(2);
                claim.target.name = `claims[${index}].claim_billed`
                claim.target.type = 'text'
                formik.handleChange(claim)

                row.target.value = parseFloat((parseFloat(formik.values.claims[index].service_lines[sfindex].balance ? formik.values.claims[index].service_lines[sfindex].balance : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                row.target.name = `claims[${index}].service_lines[${sfindex}].balance`
                row.target.type = 'text'
                if (row.target.value == '-0.00') {
                    row.target.value = '0.00';
                }
                formik.handleChange(row)

                sl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_balance ? formik.values.claims[index].sl_balance : 0) - parseFloat(old_balance ? old_balance : 0)) + parseFloat(row.target.value ? row.target.value : 0)).toFixed(2);
                sl_total.target.name = `claims[${index}].sl_balance`
                sl_total.target.type = 'number'
                formik.handleChange(sl_total)

                cl_total.target.value = parseFloat((parseFloat(claim.target.value ? claim.target.value : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(formik.values.claims[index].claim_patres ? formik.values.claims[index].claim_patres : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cl_total.target.name = `claims[${index}].claim_balance`
                cl_total.target.type = 'number'
                formik.handleChange(cl_total)

                break;
            case "discount":
                old_claim = formik.values.claims[index].claim_discount

                sl.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_discount ? formik.values.claims[index].sl_discount : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                sl.target.name = `claims[${index}].sl_discount`
                sl.target.type = 'number'
                formik.handleChange(sl)

                claim.target.value = parseFloat(sl.target.value).toFixed(2);
                claim.target.name = `claims[${index}].claim_discount`
                claim.target.type = 'text'
                formik.handleChange(claim)

                row.target.value = parseFloat((parseFloat(formik.values.claims[index].service_lines[sfindex].balance ? formik.values.claims[index].service_lines[sfindex].balance : 0) + parseFloat(oldval ? oldval : 0)) - parseFloat(value ? value : 0)).toFixed(2);
                row.target.name = `claims[${index}].service_lines[${sfindex}].balance`
                row.target.type = 'text'
                if (row.target.value == '-0.00') {
                    row.target.value = '0.00';
                }
                formik.handleChange(row)

                sl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_balance ? formik.values.claims[index].sl_balance : 0) - parseFloat(old_balance ? old_balance : 0)) + parseFloat(row.target.value ? row.target.value : 0)).toFixed(2);
                sl_total.target.name = `claims[${index}].sl_balance`
                sl_total.target.type = 'number'
                formik.handleChange(sl_total)

                cl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(claim.target.value ? claim.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_patres ? formik.values.claims[index].claim_patres : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cl_total.target.name = `claims[${index}].claim_balance`
                cl_total.target.type = 'number'
                formik.handleChange(cl_total)

                break;
            case "allowed":

                sl.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_allowed ? formik.values.claims[index].sl_allowed : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                sl.target.name = `claims[${index}].sl_allowed`
                sl.target.type = 'number'
                formik.handleChange(sl)

                claim.target.value = parseFloat(sl.target.value).toFixed(2);
                claim.target.name = `claims[${index}].claim_allowed`
                claim.target.type = 'text'
                formik.handleChange(claim)

                break;
            case "deduct":
                old_claim = formik.values.claims[index].claim_deduct

                sl.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                sl.target.name = `claims[${index}].sl_deduct`
                sl.target.type = 'number'
                formik.handleChange(sl)

                claim.target.value = parseFloat(sl.target.value).toFixed(2);
                claim.target.name = `claims[${index}].claim_deduct`
                claim.target.type = 'text'
                formik.handleChange(claim)

                row.target.value = parseFloat((parseFloat(formik.values.claims[index].service_lines[sfindex].balance ? formik.values.claims[index].service_lines[sfindex].balance : 0) + parseFloat(oldval ? oldval : 0)) - parseFloat(value ? value : 0)).toFixed(2);
                row.target.name = `claims[${index}].service_lines[${sfindex}].balance`
                row.target.type = 'text'
                if (row.target.value == '-0.00') {
                    row.target.value = '0.00';
                }
                formik.handleChange(row)

                sl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_balance ? formik.values.claims[index].sl_balance : 0) - parseFloat(old_balance ? old_balance : 0)) + parseFloat(row.target.value ? row.target.value : 0)).toFixed(2);
                sl_total.target.name = `claims[${index}].sl_balance`
                sl_total.target.type = 'number'
                formik.handleChange(sl_total)


                patres.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                patres.target.name = `claims[${index}].sl_patres`
                patres.target.type = 'number'
                formik.handleChange(patres)


                clpatres.target.value = parseFloat((parseFloat(patres.target.value ? patres.target.value : 0) +
                    parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) +
                    parseFloat(parseFloat(sl.target.value ? sl.target.value : 0) != parseFloat(claim.target.value ? claim.target.value : 0) ? parseFloat(claim.target.value ? claim.target.value : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))

                )).toFixed(2);
                clpatres.target.name = `claims[${index}].claim_patres`
                clpatres.target.type = 'text'
                formik.handleChange(clpatres)

                fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
                fake_patres.target.name = `claims[${index}].fake_sl_patres`
                fake_patres.target.type = 'text'
                formik.handleChange(fake_patres)

                cl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(clpatres.target.value ? clpatres.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(patres.target.value ? patres.target.value : 0) - parseFloat(parseFloat(sl.target.value ? sl.target.value : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cl_total.target.name = `claims[${index}].claim_balance`
                cl_total.target.type = 'number'
                formik.handleChange(cl_total)

                break;
            case "coins":
                old_claim = formik.values.claims[index].claim_coins

                sl.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                sl.target.name = `claims[${index}].sl_coins`
                sl.target.type = 'number'
                formik.handleChange(sl)

                claim.target.value = parseFloat(sl.target.value).toFixed(2);
                claim.target.name = `claims[${index}].claim_coins`
                claim.target.type = 'text'
                formik.handleChange(claim)

                row.target.value = parseFloat((parseFloat(formik.values.claims[index].service_lines[sfindex].balance ? formik.values.claims[index].service_lines[sfindex].balance : 0) + parseFloat(oldval ? oldval : 0)) - parseFloat(value ? value : 0)).toFixed(2);
                row.target.name = `claims[${index}].service_lines[${sfindex}].balance`
                row.target.type = 'text'
                if (row.target.value == '-0.00') {
                    row.target.value = '0.00';
                }
                formik.handleChange(row)

                sl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_balance ? formik.values.claims[index].sl_balance : 0) - parseFloat(old_balance ? old_balance : 0)) + parseFloat(row.target.value ? row.target.value : 0)).toFixed(2);
                sl_total.target.name = `claims[${index}].sl_balance`
                sl_total.target.type = 'number'
                formik.handleChange(sl_total)

                patres.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                patres.target.name = `claims[${index}].sl_patres`
                patres.target.type = 'number'
                formik.handleChange(patres)

                clpatres.target.value = parseFloat((parseFloat(patres.target.value ? patres.target.value : 0) +
                    parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) +
                    parseFloat(parseFloat(sl.target.value ? sl.target.value : 0) != parseFloat(claim.target.value ? claim.target.value : 0) ? parseFloat(claim.target.value ? claim.target.value : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))

                )).toFixed(2);
                clpatres.target.name = `claims[${index}].claim_patres`
                clpatres.target.type = 'text'
                formik.handleChange(clpatres)

                fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
                fake_patres.target.name = `claims[${index}].fake_sl_patres`
                fake_patres.target.type = 'text'
                formik.handleChange(fake_patres)

                cl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(clpatres.target.value ? clpatres.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(patres.target.value ? patres.target.value : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(sl.target.value ? sl.target.value : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cl_total.target.name = `claims[${index}].claim_balance`
                cl_total.target.type = 'number'
                formik.handleChange(cl_total)

                break;
            case "copay":
                old_claim = formik.values.claims[index].claim_copay

                sl.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                sl.target.name = `claims[${index}].sl_copay`
                sl.target.type = 'number'
                formik.handleChange(sl)

                claim.target.value = parseFloat(sl.target.value).toFixed(2);
                claim.target.name = `claims[${index}].claim_copay`
                claim.target.type = 'text'
                formik.handleChange(claim)

                row.target.value = parseFloat((parseFloat(formik.values.claims[index].service_lines[sfindex].balance ? formik.values.claims[index].service_lines[sfindex].balance : 0) + parseFloat(oldval ? oldval : 0)) - parseFloat(value ? value : 0)).toFixed(2);
                row.target.name = `claims[${index}].service_lines[${sfindex}].balance`
                row.target.type = 'text'
                if (row.target.value == '-0.00') {
                    row.target.value = '0.00';
                }
                formik.handleChange(row)

                sl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_balance ? formik.values.claims[index].sl_balance : 0) - parseFloat(old_balance ? old_balance : 0)) + parseFloat(row.target.value ? row.target.value : 0)).toFixed(2);
                sl_total.target.name = `claims[${index}].sl_balance`
                sl_total.target.type = 'number'
                formik.handleChange(sl_total)

                patres.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                patres.target.name = `claims[${index}].sl_patres`
                patres.target.type = 'number'
                formik.handleChange(patres)

                clpatres.target.value = parseFloat((parseFloat(patres.target.value ? patres.target.value : 0) +
                    parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) +
                    parseFloat(parseFloat(sl.target.value ? sl.target.value : 0) != parseFloat(claim.target.value ? claim.target.value : 0) ? parseFloat(claim.target.value ? claim.target.value : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                    parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0))

                )).toFixed(2);
                clpatres.target.name = `claims[${index}].claim_patres`
                clpatres.target.type = 'text'
                formik.handleChange(clpatres)

                fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
                fake_patres.target.name = `claims[${index}].fake_sl_patres`
                fake_patres.target.type = 'text'
                formik.handleChange(fake_patres)

                cl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(clpatres.target.value ? clpatres.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(patres.target.value ? patres.target.value : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(sl.target.value ? sl.target.value : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cl_total.target.name = `claims[${index}].claim_balance`
                cl_total.target.type = 'number'
                formik.handleChange(cl_total)


                break;
            case "cob":
                old_claim = formik.values.claims[index].claim_cob

                sl.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_cob ? formik.values.claims[index].sl_cob : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                sl.target.name = `claims[${index}].sl_cob`
                sl.target.type = 'number'
                formik.handleChange(sl)

                claim.target.value = parseFloat(sl.target.value).toFixed(2);
                claim.target.name = `claims[${index}].claim_cob`
                claim.target.type = 'text'
                formik.handleChange(claim)

                row.target.value = parseFloat((parseFloat(formik.values.claims[index].service_lines[sfindex].balance ? formik.values.claims[index].service_lines[sfindex].balance : 0) + parseFloat(oldval ? oldval : 0)) - parseFloat(value ? value : 0)).toFixed(2);
                row.target.name = `claims[${index}].service_lines[${sfindex}].balance`
                row.target.type = 'text'
                if (row.target.value == '-0.00') {
                    row.target.value = '0.00';
                }
                formik.handleChange(row)

                sl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_balance ? formik.values.claims[index].sl_balance : 0) - parseFloat(old_balance ? old_balance : 0)) + parseFloat(row.target.value ? row.target.value : 0)).toFixed(2);
                sl_total.target.name = `claims[${index}].sl_balance`
                sl_total.target.type = 'number'
                formik.handleChange(sl_total)

                cl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(formik.values.claims[index].claim_patres ? formik.values.claims[index].claim_patres : 0) +
                        parseFloat(claim.target.value ? claim.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cl_total.target.name = `claims[${index}].claim_balance`
                cl_total.target.type = 'number'
                formik.handleChange(cl_total)

                break;
            case "paid":
                old_claim = formik.values.claims[index].claim_paid

                sl.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_paid ? formik.values.claims[index].sl_paid : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                sl.target.name = `claims[${index}].sl_paid`
                sl.target.type = 'number'
                formik.handleChange(sl)

                claim.target.value = parseFloat(sl.target.value).toFixed(2);
                claim.target.name = `claims[${index}].claim_paid`
                claim.target.type = 'text'
                formik.handleChange(claim)

                row.target.value = parseFloat((parseFloat(formik.values.claims[index].service_lines[sfindex].balance ? formik.values.claims[index].service_lines[sfindex].balance : 0) + parseFloat(oldval ? oldval : 0)) - parseFloat(value ? value : 0)).toFixed(2);
                row.target.name = `claims[${index}].service_lines[${sfindex}].balance`
                row.target.type = 'text'
                if (row.target.value == '-0.00') {
                    row.target.value = '0.00';
                }
                formik.handleChange(row)

                sl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_balance ? formik.values.claims[index].sl_balance : 0) - parseFloat(old_balance ? old_balance : 0)) + parseFloat(row.target.value ? row.target.value : 0)).toFixed(2);
                sl_total.target.name = `claims[${index}].sl_balance`
                sl_total.target.type = 'number'
                formik.handleChange(sl_total)

                cl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(formik.values.claims[index].claim_patres ? formik.values.claims[index].claim_patres : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(claim.target.value ? claim.target.value : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cl_total.target.name = `claims[${index}].claim_balance`
                cl_total.target.type = 'number'
                formik.handleChange(cl_total)

                let paid_total = 0;
                let plb_total = 0;


                formik.values?.claims?.map((claim, ind) => {
                    paid_total =
                        parseFloat(paid_total ? paid_total : 0) +
                        parseFloat(claim.claim_paid ? claim.claim_paid : 0);
                });
                paid_total = parseFloat(paid_total ? paid_total : 0) - parseFloat(old_claim ? old_claim : 0) + parseFloat(sl.target.value ? sl.target.value : 0)
                formik.values?.plb_adjustments?.map((plb, ind) => {
                    plb_total =
                        parseFloat(plb_total ? plb_total : 0) +
                        parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
                });

                remaining.target.value = parseFloat(parseFloat(formik.values.check_amount ? formik.values.check_amount : 0) - parseFloat(paid_total ? paid_total : 0) + parseFloat(plb_total ? plb_total : 0)).toFixed(2);
                remaining.target.type = 'text';
                remaining.target.name = 'remaining_balance';
                formik.handleChange(remaining)

                break;

            case "amount":
                old_claim = formik.values.claims[index].sl_amount
                sl.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_amount ? formik.values.claims[index].sl_amount : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                sl.target.name = `claims[${index}].sl_amount`
                sl.target.type = 'number'
                formik.handleChange(sl)

                row.target.value = parseFloat((parseFloat(formik.values.claims[index].service_lines[sfindex].balance ? formik.values.claims[index].service_lines[sfindex].balance : 0) + parseFloat(oldval ? oldval : 0)) - parseFloat(value ? value : 0)).toFixed(2);
                row.target.name = `claims[${index}].service_lines[${sfindex}].balance`
                row.target.type = 'text'
                if (row.target.value == '-0.00') {
                    row.target.value = '0.00';
                }
                formik.handleChange(row)

                sl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_balance ? formik.values.claims[index].sl_balance : 0) - parseFloat(old_balance ? old_balance : 0)) + parseFloat(row.target.value ? row.target.value : 0)).toFixed(2);
                sl_total.target.name = `claims[${index}].sl_balance`
                sl_total.target.type = 'number'
                formik.handleChange(sl_total)

                if (formik.values.claims[index].service_lines[sfindex].adjustments[adj].group_code == 'PR') {
                    patres.target.value = parseFloat((parseFloat(formik.values.claims[index].sl_patres ? formik.values.claims[index].sl_patres : 0) - parseFloat(oldval ? oldval : 0)) + parseFloat(value ? value : 0)).toFixed(2);
                    patres.target.name = `claims[${index}].sl_patres`
                    patres.target.type = 'number'
                    formik.handleChange(patres)

                    clpatres.target.value = parseFloat((parseFloat(patres.target.value ? patres.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) != parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) ? parseFloat(formik.values.claims[index].claim_coins ? formik.values.claims[index].claim_coins : 0) : parseFloat(0)) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) != parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) ? parseFloat(formik.values.claims[index].claim_deduct ? formik.values.claims[index].claim_deduct : 0) : parseFloat(0)) +
                        parseFloat(parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0) != parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) ? parseFloat(formik.values.claims[index].claim_copay ? formik.values.claims[index].claim_copay : 0) : parseFloat(0))

                    )).toFixed(2);
                    clpatres.target.name = `claims[${index}].claim_patres`
                    clpatres.target.type = 'text'
                    formik.handleChange(clpatres)

                    fake_patres.target.value = parseFloat(clpatres.target.value).toFixed(2);
                    fake_patres.target.name = `claims[${index}].fake_sl_patres`
                    fake_patres.target.type = 'text'
                    formik.handleChange(fake_patres)

                }

                cl_total.target.value = parseFloat((parseFloat(formik.values.claims[index].claim_billed ? formik.values.claims[index].claim_billed : 0)) -
                    (parseFloat(formik.values.claims[index].claim_discount ? formik.values.claims[index].claim_discount : 0) +
                        parseFloat(clpatres.target.value ? clpatres.target.value : 0) +
                        parseFloat(formik.values.claims[index].claim_cob ? formik.values.claims[index].claim_cob : 0) +
                        parseFloat(formik.values.claims[index].claim_paid ? formik.values.claims[index].claim_paid : 0) +
                        parseFloat(parseFloat(sl.target.value ? sl.target.value : 0) - parseFloat(parseFloat(patres.target.value ? patres.target.value : 0) - parseFloat(parseFloat(formik.values.claims[index].sl_deduct ? formik.values.claims[index].sl_deduct : 0) + parseFloat(formik.values.claims[index].sl_coins ? formik.values.claims[index].sl_coins : 0) + parseFloat(formik.values.claims[index].sl_copay ? formik.values.claims[index].sl_copay : 0)))) +
                        parseFloat(parseFloat(formik.values.claims[index].claim_adjustment_total ? formik.values.claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustment_total_pr ? formik.values.claims[index].claim_adjustment_total_pr : 0))
                    )).toFixed(2)
                cl_total.target.name = `claims[${index}].claim_balance`
                cl_total.target.type = 'number'
                formik.handleChange(cl_total)



                break;


            default:
                break;
        }


    }
    const changeServiceLineValue = async (event, type, sfindex, adjIndex) => {
        const value = event.target.value;
        event.target.value = parseFloat(event.target.value).toFixed(2);
        event.target.type = "text"
        formik.handleChange(event)
        event.target.type = "number"
    }





    const updatePeriodDate = async () => {


        await formik.setValues((prevState) => {



            return {
                ...prevState,
                claims: prevState.claims.map((claim, claimIndex) => {
                    if (claimIndex === index) {
                        // const datas = { ...claim };

                        if (claim.service_lines.length === 0) {
                            claim.period_end = "";
                            claim.period_start = "";
                        } else {
                            // console.log(claim.service_lines[0].from_date);

                            const from_index = claim.service_lines
                                .map((item, index) => {
                                    if (
                                        new Date(item.from_date) &&
                                        !isNaN(new Date(item.from_date).getDate())
                                    ) {
                                        // Check for non-null and non-empty values
                                        return index;
                                    }
                                    return null;
                                })
                                .filter((index) => index !== null);
                            const to_index = claim.service_lines
                                .map((item, index) => {
                                    if (
                                        new Date(item.to_date) &&
                                        !isNaN(new Date(item.to_date).getDate())
                                    ) {
                                        // Check for non-null and non-empty values
                                        return index;
                                    }
                                    return null;
                                })
                                .filter((index) => index !== null);

                            // console.log(validDateIndices[0]);
                            claim.period_start = claim.service_lines[from_index[0]].from_date;
                            claim.period_end = claim.service_lines[to_index[0]].to_date;

                            claim.service_lines?.forEach((service, sindex) => {
                                if (
                                    service &&
                                    service.from_date &&
                                    new Date(service.from_date) &&
                                    !isNaN(new Date(service.from_date).getDate())
                                ) {
                                    if (
                                        new Date(claim.period_start) &&
                                        new Date(service.from_date) <= new Date(claim.period_start)
                                    ) {
                                        claim.period_start = service.from_date;
                                    }
                                }
                                if (
                                    service &&
                                    service.to_date &&
                                    new Date(service.to_date) &&
                                    !isNaN(new Date(service.to_date).getDate())
                                ) {
                                    if (new Date(service.to_date) >= new Date(claim.period_end)) {
                                        claim.period_end = service.to_date;
                                    }
                                }
                                if (service.from_date == "__/__/____") {
                                    service.from_date = ""
                                }
                                if (service.to_date == "__/__/____") {
                                    service.to_date = ""
                                }
                            });
                        }
                        return claim;
                    }
                    return claim;
                }),
            };

        });


    }

    const __copyServiceLine = async () => {


        await formik.setValues(prevValues => {


            const _ = require('lodash');

            const oldValue = _.cloneDeep(prevValues.claims);

            const initialValues = oldValue[index]?.service_lines[serviceIndex];

            const new_service_line = {
                ...initialValues,
                corrected_serviceline: true,
                serviceline_old_data_index: "",
                order: "",
                from_date: initialValues.from_date ? initialValues.from_date : "",
                to_date: initialValues.to_date ? initialValues.to_date : "",
                code: initialValues.code ? initialValues.code : "",
                procedure_type: "HC",
                modifier1: initialValues.modifier1 ? initialValues.modifier1 : "",
                modifier2: initialValues.modifier2 ? initialValues.modifier2 : "",
                modifier3: initialValues.modifier3 ? initialValues.modifier3 : "",
                modifier4: initialValues.modifier4 ? initialValues.modifier4 : "",
                rev_code: initialValues.rev_code ? initialValues.rev_code : "",
                units: initialValues.units ? initialValues.units : "",
                patres: initialValues.patres ? initialValues.patres : "",
                billed: initialValues.billed ? initialValues.billed : "",
                discount: initialValues.discount ? initialValues.discount : "",
                allowed: initialValues.allowed ? initialValues.allowed : "",
                deduct: initialValues.deduct ? initialValues.deduct : "",
                coins: initialValues.coins ? initialValues.coins : "",
                copay: initialValues.copay ? initialValues.copay : "",
                cob: initialValues.cob ? initialValues.cob : "",
                paid: initialValues.paid ? initialValues.paid : "",
                balance: initialValues.balance ? initialValues.balance : "",
                service_line_id: "",
                color_index: "",
                color_data: [],
                adjustments: initialValues.adjustments.map(adjustment => ({
                    ...adjustment,
                    adjustment_id: "",
                })),
            };

            oldValue[index]?.service_lines.splice(serviceIndex + 1, 0, new_service_line);

            return {
                ...prevValues,
                claims: oldValue,
            };
        });



        const billed = await handleColumnCalculation(serviceIndex + 1, 'billed')
        const discount = await handleColumnCalculation(serviceIndex + 1, 'discount')
        const allowed = await handleColumnCalculation(serviceIndex + 1, 'allowed')
        const deduct = await handleColumnCalculation(serviceIndex + 1, 'deduct')
        const coins = await handleColumnCalculation(serviceIndex + 1, 'coins')
        const copay = await handleColumnCalculation(serviceIndex + 1, 'copay')
        const amount = await handleColumnCalculation(serviceIndex + 1, 'amount')
        const cob = await handleColumnCalculation(serviceIndex + 1, 'cob')
        const paid = await handleColumnCalculation(serviceIndex + 1, 'paid')
        const sl = await handleSlCalculation()
        const patres = await handlePatresCalculation();
        const claim_patres = await handleClaimPatresCalculation();
        const remaining = await handleRemainingBalanceCalculation()
        const claim = await handleClaimCalculation()
    }

    const __moveServiceLine = async (type) => {
        if (serviceIndex < 0) {
            return
        }
        else if (formik.values.claims[index].service_lines.length > 0) {
            await formik.setValues((prevValues) => {
                return {
                    ...prevValues,
                    claims: prevValues.claims.map((claim, idx) => {
                        if (idx === index) {
                            const newServiceLines = [...claim.service_lines];
                            if (type === 'UP') {
                                if (serviceIndex === 0) {
                                    return claim;
                                }
                                const temp = newServiceLines[serviceIndex];
                                newServiceLines[serviceIndex] = newServiceLines[serviceIndex - 1];
                                newServiceLines[serviceIndex - 1] = temp;
                                setServiceIndex(serviceIndex - 1);
                            } else {
                                if (serviceIndex === claim.service_lines.length - 1) {
                                    return claim;
                                }
                                const temp = newServiceLines[serviceIndex];
                                newServiceLines[serviceIndex] = newServiceLines[serviceIndex + 1];
                                newServiceLines[serviceIndex + 1] = temp;
                                setServiceIndex(serviceIndex + 1);
                            }
                            return {
                                ...claim,
                                service_lines: newServiceLines,
                            };
                        }
                        return claim;
                    }),
                };

            });

        }
    }

    const __clearServiceLine = async () => {
        return await formik.setValues((prevValues) => {
            var data = prevValues.claims;
            var count = data[index]?.service_lines?.length;

            return {

                ...prevValues,
                remaining_balance: parseFloat(parseFloat(prevValues?.remaining_balance) + parseFloat(prevValues?.claims[index].claim_paid)).toFixed(2),
                claims: prevValues.claims?.map((claim, ind) => {
                    if (index == ind) {

                        var count = data[index]?.service_lines?.length;

                        const updatedServicelines = claim.service_lines?.map((serviceline, sfindex) => {
                            const updatedServiceLine = { ...serviceline };
                            updatedServiceLine.color_index = "";
                            updatedServiceLine.order = "";
                            updatedServiceLine.service_line_id = "";
                            updatedServiceLine.from_date = "";
                            updatedServiceLine.to_date = "";
                            updatedServiceLine.code = "";
                            updatedServiceLine.procedure_type = "HC";
                            updatedServiceLine.modifier1 = "";
                            updatedServiceLine.modifier2 = "";
                            updatedServiceLine.modifier3 = "";
                            updatedServiceLine.modifier4 = "";
                            updatedServiceLine.rev_code = "";
                            updatedServiceLine.units = "";
                            updatedServiceLine.patres = "";
                            updatedServiceLine.billed = "";
                            updatedServiceLine.discount = "";
                            updatedServiceLine.allowed = "";
                            updatedServiceLine.deduct = "";
                            updatedServiceLine.coins = "";
                            updatedServiceLine.copay = "";
                            updatedServiceLine.cob = "";
                            updatedServiceLine.paid = "";
                            updatedServiceLine.color_data = [];
                            updatedServiceLine.balance = "";
                            updatedServiceLine.adjustments = [
                                {
                                    adjustment_id: "",
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                },
                            ];
                            return updatedServiceLine;
                        });

                        return {
                            ...claim,
                            rendering_provider_identifier_code: claim?.rendering_provider_identifier_code,
                            claim_billed: parseFloat(0).toFixed(2),
                            claim_discount: parseFloat(0).toFixed(2),
                            claim_allowed: parseFloat(0).toFixed(2),
                            claim_deduct: parseFloat(0).toFixed(2),
                            claim_coins: parseFloat(0).toFixed(2),
                            claim_copay: parseFloat(0).toFixed(2),
                            claim_cob: parseFloat(0).toFixed(2),
                            claim_patres: parseFloat(0).toFixed(2),
                            claim_paid: parseFloat(0).toFixed(2),
                            claim_balance: parseFloat(0).toFixed(2),
                            sl_billed: parseFloat(0).toFixed(2),
                            sl_discount: parseFloat(0).toFixed(2),
                            sl_allowed: parseFloat(0).toFixed(2),
                            sl_deduct: parseFloat(0).toFixed(2),
                            sl_coins: parseFloat(0).toFixed(2),
                            sl_copay: parseFloat(0).toFixed(2),
                            sl_cob: parseFloat(0).toFixed(2),
                            sl_patres: parseFloat(0).toFixed(2),
                            sl_amount: parseFloat(0).toFixed(2),
                            sl_paid: parseFloat(0).toFixed(2),
                            sl_balance: parseFloat(0).toFixed(2),
                            sl_total: parseFloat(0).toFixed(2),
                            claim_adjustment_total: parseFloat(0).toFixed(2),
                            claim_adjustment_total_pr: parseFloat(0).toFixed(2),
                            period_start: "",
                            period_end: "",
                            claim_adjustments: [
                                {
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                    claim_adjustment_id: "",
                                    description: "",
                                },
                            ],
                            service_lines: updatedServicelines,

                        }

                    }
                    else {
                        return claim;
                    }
                })
            };
        });


    }



    const handleServiceLineDelete = async (slIndex, serviceLine) => {
        var sIndex = slIndex;
        let event = {
            target: {}
        }
        let remaining = {
            target: {}
        }
        const claims = _.cloneDeep(formik.values.claims);
        const oldsl = _.cloneDeep(claims[index].service_lines[sIndex]);
        const oldclPaid = claims[index].claim_paid;
        let adjamount = 0;
        let adjamountpr = 0;
        oldsl?.adjustments?.map((adj, ind) => {
            adjamount = adjamount + parseFloat(adj?.amount);
            if (adj?.group_code == 'PR') {
                adjamountpr = adjamountpr + parseFloat(adj?.amount)
            }
        })



        claims[index].service_lines.splice(sIndex, 1);
        if (claims[index].service_lines?.length == 0) {
            claims[index].claim_adjustments = [
                {
                    pc: "",
                    group_code: "",
                    reason_code: "",
                    rmk: "",
                    amount: parseFloat(0).toFixed(2),
                    claim_adjustment_id: "",
                    description: "",
                },
            ];
            claims[index].claim_adjustment_total = parseFloat(0).toFixed(2)
            claims[index].claim_adjustment_total_pr = parseFloat(0).toFixed(2)

        }

        claims[index].sl_billed = parseFloat(parseFloat(claims[index].sl_billed ? claims[index].sl_billed : 0) - parseFloat(oldsl?.billed ? oldsl?.billed : 0)).toFixed(2)
        claims[index].claim_billed = parseFloat(claims[index].sl_billed ? claims[index].sl_billed : 0).toFixed(2)

        claims[index].sl_discount = parseFloat(parseFloat(claims[index].sl_discount ? claims[index].sl_discount : 0) - parseFloat(oldsl?.discount ? oldsl?.discount : 0)).toFixed(2)
        claims[index].claim_discount = parseFloat(claims[index].sl_discount ? claims[index].sl_discount : 0).toFixed(2)

        claims[index].sl_allowed = parseFloat(parseFloat(claims[index].sl_allowed ? claims[index].sl_allowed : 0) - parseFloat(oldsl?.allowed ? oldsl?.allowed : 0)).toFixed(2)
        claims[index].claim_allowed = parseFloat(claims[index].sl_allowed ? claims[index].sl_allowed : 0).toFixed(2)

        claims[index].sl_deduct = parseFloat(parseFloat(claims[index].sl_deduct ? claims[index].sl_deduct : 0) - parseFloat(oldsl?.deduct ? oldsl?.deduct : 0)).toFixed(2)
        claims[index].claim_deduct = parseFloat(claims[index].sl_deduct ? claims[index].sl_deduct : 0).toFixed(2)

        claims[index].sl_coins = parseFloat(parseFloat(claims[index].sl_coins ? claims[index].sl_coins : 0) - parseFloat(oldsl?.coins ? oldsl?.coins : 0)).toFixed(2)
        claims[index].claim_coins = parseFloat(claims[index].sl_coins ? claims[index].sl_coins : 0).toFixed(2)

        claims[index].sl_copay = parseFloat(parseFloat(claims[index].sl_copay ? claims[index].sl_copay : 0) - parseFloat(oldsl?.copay ? oldsl?.copay : 0)).toFixed(2)
        claims[index].claim_copay = parseFloat(claims[index].sl_copay ? claims[index].sl_copay : 0).toFixed(2)

        claims[index].sl_cob = parseFloat(parseFloat(claims[index].sl_cob ? claims[index].sl_cob : 0) - parseFloat(oldsl?.cob ? oldsl?.cob : 0)).toFixed(2)
        claims[index].claim_cob = parseFloat(claims[index].sl_cob ? claims[index].sl_cob : 0).toFixed(2)

        claims[index].sl_paid = parseFloat(parseFloat(claims[index].sl_paid ? claims[index].sl_paid : 0) - parseFloat(oldsl?.paid ? oldsl?.paid : 0)).toFixed(2)
        claims[index].claim_paid = parseFloat(claims[index].sl_paid ? claims[index].sl_paid : 0).toFixed(2)

        claims[index].sl_amount = parseFloat(parseFloat(claims[index].sl_amount ? claims[index].sl_amount : 0) - parseFloat(adjamount ? adjamount : 0)).toFixed(2)
        claims[index].sl_balance = parseFloat(parseFloat(claims[index].sl_balance ? claims[index].sl_balance : 0) - parseFloat(oldsl?.balance ? oldsl?.balance : 0)).toFixed(2)
        claims[index].claim_balance = parseFloat(parseFloat(claims[index].sl_balance ? claims[index].sl_balance : 0) - parseFloat(claims[index]?.claim_adjustment_total ? claims[index]?.claim_adjustment_total : 0)).toFixed(2)

        claims[index].sl_patres = parseFloat(parseFloat(claims[index].sl_patres ? claims[index].sl_patres : 0) - (parseFloat(oldsl?.deduct ? oldsl?.deduct : 0) + parseFloat(oldsl?.coins ? oldsl?.coins : 0) + parseFloat(oldsl?.copay ? oldsl?.copay : 0) + parseFloat(adjamountpr ? adjamountpr : 0))).toFixed(2)
        claims[index].claim_patres = parseFloat(parseFloat(claims[index].sl_patres ? claims[index].sl_patres : 0) + parseFloat(claims[index].claim_adjustment_total_pr ? claims[index].claim_adjustment_total_pr : 0)).toFixed(2)
        claims[index].fake_sl_patres = parseFloat(parseFloat(claims[index].sl_patres ? claims[index].sl_patres : 0) + parseFloat(claims[index].claim_adjustment_total_pr ? claims[index].claim_adjustment_total_pr : 0)).toFixed(2)


        event.target.value = claims;
        event.target.type = 'text';
        event.target.name = 'claims';
        formik.handleChange(event)


        remaining.target.value = parseFloat((parseFloat(formik.values.remaining_balance ? formik.values.remaining_balance : 0) + parseFloat(oldclPaid ? oldclPaid : 0)) - parseFloat(claims[index].claim_paid)).toFixed(2);
        remaining.target.type = 'text';
        remaining.target.name = 'remaining_balance';
        formik.handleChange(remaining)
        setTimeout(() => {

            formik.validateForm()
        }, 200);

    }

    const handleNewPoup = async () => {
        if (slCount && slCount > 0) {
            var new_service_line = {
                color_index: "",
                order: "",
                service_line_id: "",
                from_date: "",
                to_date: "",
                code: "",
                procedure_type: "HC",
                modifier1: "",
                corrected_serviceline: true,
                modifier2: "",
                modifier3: "",
                modifier4: "",
                rev_code: "",
                units: "",
                billed: "",
                patres: "",
                discount: "",
                allowed: "",
                deduct: "",
                coins: "",
                copay: "",
                cob: "",
                paid: "",
                color_data: [],

                balance: "",
                adjustments: [{
                    adjustment_id: "",
                    pc: "",
                    group_code: "",
                    reason_code: "",
                    rmk: "",
                    amount: "",
                }],
            };
            await formik.setValues((prevState) => {
                const _ = require('lodash');

                const claims = _.cloneDeep(prevState?.claims);
                if (claims[index]?.service_lines) {
                    for (let i = 0; i < slCount; i++) {
                        claims[index]?.service_lines.splice(serviceIndex + 1 + i, 0, { ...new_service_line });
                    }
                }
                return {
                    ...prevState,
                    claims: claims,
                }

            });


            setSlCount(0);
            setNewPoupUp(false)
        }

    }
    const handleClaimAdjustment = () => {
        const initialAdjustments = {
            pc: "",
            group_code: "",
            reason_code: "",
            rmk: "",
            amount: "",
            claim_adjustment_id: "",
        }
        formik.setValues((prevState) => {

            const updatedClaims = prevState?.claims.map((claim, ind) => {

                if (ind === index) {

                    return {
                        ...claim,
                        claim_adjustments: [...claim?.claim_adjustments, initialAdjustments],
                    };

                }
                return claim;
            });

            return {
                ...prevState,
                claims: updatedClaims,
            };
        });

    }

    const handleClaimAServiceLineAdjustment = (serviceIndex) => {
        const initialAdjustments = {
            pc: "",
            group_code: "",
            reason_code: "",
            new_adjustment: true,
            rmk: "",
            amount: "",
            adjustment_id: "",
        }
        formik.setValues((prevState) => {

            const updatedClaims = prevState?.claims.map((claim, ind) => {
                if (ind === index) {
                    const updatedServicelines = claim?.service_lines.map((serviceline, sfindex) => {
                        if (serviceIndex === sfindex) {
                            return {
                                ...serviceline,
                                adjustments: [...serviceline.adjustments, initialAdjustments],
                            };
                        }
                        return serviceline;
                    });
                    return {
                        ...claim,
                        service_lines: updatedServicelines,
                    };

                }
                return claim;
            });
            return {
                ...prevState,
                claims: updatedClaims,
            };

        });
    }

    const inputRefs = useRef([]);

    const handleKeyDown = (e, ind, type, claim_type) => {
        if (e.keyCode == 38 || e.keyCode == 40) {
            e.preventDefault();
            let el = null;
            if (e.keyCode == 38) {
                el = document.getElementsByName(`claims[${index}].service_lines[${ind - 1}].${type}`);
                if (el && el[0]) {
                    el[0].focus()
                    setTimeout(() => {
                        el[0].scrollIntoViewIfNeeded(false);
                    }, 10)
                }
            }
            else {
                el = document.getElementsByName(`claims[${index}].service_lines[${ind + 1}].${type}`);
                if (el && el[0]) {
                    el[0].focus()
                    setTimeout(() => {
                        el[0].scrollIntoViewIfNeeded(false);
                    }, 10)
                }
                else {
                    el = document.getElementsByName(`claims[${index}].${claim_type}`);
                    if (claim_type == 'claim_billed' || claim_type == 'claim_paid') {
                        if (el && el[1]) {
                            el[1].focus()
                        }
                    }
                    else {
                        if (el && el[0]) {
                            el[0].focus()
                        }
                    }
                }
            }
        }
        if (e.keyCode == 68 && e.shiftKey) {
            document.querySelector(`input[name='claims[${index}].service_lines[${ind}].discount']`).focus();
        }
        if (e.keyCode == 80 && e.shiftKey) {
            document.querySelector(`input[name='claims[${index}].service_lines[${ind}].paid']`).focus();
        }
    }

    const changeClaimFocus = (e, type) => {
        if (e.keyCode == 38 || e.keyCode == 40) {
            e.preventDefault();
            if (type != 'claim_patres')
                var length = formik.values?.claims[index].service_lines.length;
            let el = null;
            if (e.keyCode == 38)
                el = document.getElementsByName(`claims[${index}].service_lines[${length - 1}].${type}`);
            if (el && el[0]) {
                el[0].focus()
            }
        }
    }

    const changeFocusAdj = async (e, ind, adj, type) => {
        if (e.keyCode == 38 || e.keyCode == 40) {
            e.preventDefault();
            let el = null;
            if (e.keyCode == 38) {
                el = document.getElementsByName(`claims[${index}].service_lines[${ind}].adjustments[${adj - 1}].${type}`);
                if (el && el[0]) {

                    el[0].focus()
                    setTimeout(() => {
                        el[0].scrollIntoViewIfNeeded(false);
                    }, 10)
                }
                else {
                    el = document.getElementsByName(`claims[${index}].service_lines[${ind - 1}].adjustments[${formik.values?.claims[index]?.service_lines[ind - 1]?.adjustments?.length - 1}].${type}`);
                    if (el && el[0]) {
                        el[0].focus()
                        setTimeout(() => {
                            el[0].scrollIntoViewIfNeeded(false);
                        }, 10)
                    }
                }
            }
            else {
                el = document.getElementsByName(`claims[${index}].service_lines[${ind}].adjustments[${adj + 1}].${type}`);
                if (el && el[0]) {
                    el[0].focus()
                    setTimeout(() => {
                        el[0].scrollIntoViewIfNeeded(false);
                    }, 10)
                }
                else {
                    el = document.getElementsByName(`claims[${index}].service_lines[${ind + 1}].adjustments[0].${type}`);
                    if (el && el[0]) {
                        el[0].focus()
                        setTimeout(() => {
                            el[0].scrollIntoViewIfNeeded(false);
                        }, 10)
                    }
                    else {
                        el = document.getElementsByName(`claims[${index}].claim_adjustments[${0}].${type}`);
                        if (el && el[0]) {
                            el[0].focus()
                            setTimeout(() => {
                                el[0].scrollIntoViewIfNeeded(false);
                            }, 10)
                        }
                    }
                }
            }
        }
        else if (e.keyCode == 46 && e.shiftKey) {
            if (formik.values?.claims[index]?.service_lines[ind].adjustments.length > 1) {

                var claims = formik.values?.claims;
                claims[index].sl_amount = claims[index].sl_amount - claims[index].service_lines[ind].adjustments[adj].amount
                claims[index].service_lines[ind].adjustments?.splice(adj, 1);

                setTimeout(() => {
                    let copay = document.querySelector(`input[name='claims[${index}].service_lines[${ind}].copay']`);
                    copay.focus()
                }, 100);


                await formik.setValues({
                    ...formik.values,
                    claims: claims,
                })


                const row = await handleRowCalculation(ind)

                const sl = await handleSlCalculation()

                const patres = await handlePatresCalculation();

                const claimn = await handleClaimCalculation()
            }

        }
        if (e.keyCode == 80 && e.shiftKey) {
            document.querySelector(`input[name='claims[${index}].service_lines[${ind}].paid']`).focus();
        }
        if (e.keyCode == 68 && e.shiftKey) {
            document.querySelector(`input[name='claims[${index}].service_lines[${ind}].discount']`).focus();
        }
    }

    const changeClaimAdjFocus = async (e, ind, type) => {
        if (e.keyCode == 38 || e.keyCode == 40) {
            e.preventDefault();
            var length = formik.values?.claims[index].service_lines.length;
            var adjLength = formik.values?.claims[index].service_lines[length - 1].adjustments.length;
            let el = null;
            if (e.keyCode == 38) {
                el = document.getElementsByName(`claims[${index}].claim_adjustments[${ind - 1}].${type}`);
                if (el && el[0]) {
                    el[0].focus()
                }
                else {
                    el = document.getElementsByName(`claims[${index}].service_lines[${length - 1}].adjustments[${adjLength - 1}].${type}`);
                    if (el && el[0]) {
                        el[0].focus()
                    }
                }
            }
            else {
                el = document.getElementsByName(`claims[${index}].claim_adjustments[${ind + 1}].${type}`);
                if (el && el[0]) {
                    el[0].focus()
                }
            }
        }

        else if (e.keyCode == 46 && e.shiftKey) {

            if (formik.values?.claims[index]?.claim_adjustments.length > 1) {
                var claims = formik.values?.claims;
                claims[index].claim_adjustment_total = parseFloat(parseFloat(claims[index].claim_adjustment_total ? claims[index].claim_adjustment_total : 0) - parseFloat(formik.values.claims[index].claim_adjustments[ind].amount ? formik.values.claims[index].claim_adjustments[ind].amount : 0)).toFixed(2)
                claims[index].claim_balance = parseFloat(parseFloat(claims[index].claim_balance ? claims[index].claim_balance : 0) + parseFloat(formik.values.claims[index].claim_adjustments[ind].amount ? formik.values.claims[index].claim_adjustments[ind].amount : 0)).toFixed(2)
                if (formik.values.claims[index].claim_adjustments[ind].group_code == 'PR') {
                    claims[index].claim_adjustment_total_pr = parseFloat(parseFloat(claims[index].claim_adjustment_total_pr ? claims[index].claim_adjustment_total_pr : 0) - parseFloat(formik.values.claims[index].claim_adjustments[ind].amount ? formik.values.claims[index].claim_adjustments[ind].amount : 0)).toFixed(2)
                    claims[index].claim_patres = parseFloat(parseFloat(claims[index].claim_patres ? claims[index].claim_patres : 0) - parseFloat(formik.values.claims[index].claim_adjustments[ind].amount ? formik.values.claims[index].claim_adjustments[ind].amount : 0)).toFixed(2)
                    claims[index].fake_sl_patres = parseFloat(parseFloat(claims[index].fake_sl_patres ? claims[index].fake_sl_patres : 0) - parseFloat(formik.values.claims[index].claim_adjustments[ind].amount ? formik.values.claims[index].claim_adjustments[ind].amount : 0)).toFixed(2)
                }

                claims[index].claim_adjustments?.splice(ind, 1);

                let copay = document.querySelector(`input[name='claims[${index}].claim_copay`);
                copay.focus()

                await formik.setValues({
                    ...formik.values,
                    claims: claims,
                })

            }
        }
    }

    const __handleSLAssignBalanceValue = async (e, claimIndex, serviceIndex, adjustmentIndex, field_type, type) => {
        if (!e.target.disabled) {

            const _ = require('lodash');
            const balance = formik.values?.claims[claimIndex]?.service_lines[serviceIndex]?.balance;
            const claim_balance = formik.values?.claims[claimIndex]?.claim_balance;

            if (parseFloat(balance).toFixed(2) !== parseFloat(0).toFixed(2)) {
                if (field_type === "amount") {
                    e.target.value = parseFloat(balance).toFixed(2);
                    handleChangeFormik(e, claimIndex, serviceIndex, type)
                } else if (field_type === "adjustments") {
                    e.target.value = parseFloat(balance).toFixed(2);
                    handleChangeFormik(e, claimIndex, serviceIndex, type, adjustmentIndex)
                }
            }
            if (parseFloat(claim_balance).toFixed(2) !== parseFloat(0).toFixed(2)) {
                if (field_type === "claim") {
                    e.target.value = parseFloat(claim_balance).toFixed(2);
                    changeClaimValue(e, type)
                } else if (field_type === "claim_adjustments") {
                    e.target.value = parseFloat(claim_balance).toFixed(2);
                    changeClaimValue(e, type, adjustmentIndex)
                }
            }


        }

    }
    const pcReasonRef = useRef(null)
    const pcSaveRef = useRef(null)
    const handleKeyDownPc = (e, name) => {
        if (e.shiftKey === true && e.key === 'Tab') {
            if (name === 'reasonCode') {
                e.preventDefault();
                pcSaveRef.current.focus()
            }
        }
        else if (e.key === 'Tab') {
            if (name === 'okButton') {
                e.preventDefault();
                pcReasonRef.current.focus()
            }
        }
        if (e.key == 'Enter' && name === 'okButton') {
            formRc.handleSubmit()
        }
    }


    const pathname = window.location.pathname;
    const gridData = jobData?.grid_data;
    const qaCorrectedData = gridData?.qa_corrected_data;

    const renderClass = (name, type, defaultClass, sfindex) => {
        // console.log(formik.values?.claims[index]?.service_lines[sfindex]?.[name])
        if (formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.service_lines &&
            formik.errors?.claims[index]?.service_lines[sfindex] &&
            formik.errors?.claims[index]?.service_lines[sfindex]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData?.claims &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name]) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'DATE' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                moment(formik.values?.claims[index]?.service_lines[sfindex]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name]).format("MM/DD/YYYY")) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                formik.values?.claims[index]?.service_lines[sfindex]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                parseFloat(formik.values?.claims[index]?.service_lines[sfindex]?.[name] ? formik.values?.claims[index]?.service_lines[sfindex]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name] ?
                    gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }
            else if (gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]) {
                return defaultClass + ' qa-field'
            }

            else if (formik.values?.claims[index]?.corrected_claim || formik.values?.claims[index]?.service_lines[sfindex]?.corrected_serviceline || !gridData?.claims || gridData?.transaction_type != "PAYMENT835") {
                return defaultClass + ' qa-field'
            }
            else if (type == 'DATE' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && moment(formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name]).format("MM/DD/YYYY") ==
                moment(formik.values?.claims[index]?.service_lines[sfindex]?.[name]).format("MM/DD/YYYY")) {
                return defaultClass + ' data-837'
            }
            else if (type == 'TEXT' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name]?.toUpperCase() == formik.values?.claims[index]?.service_lines[sfindex]?.[name]?.toUpperCase()) {
                return defaultClass + ' data-837'
            }
            else if (type == 'NUMBER' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] == formik.values?.claims[index]?.service_lines[sfindex]?.[name]) {
                return defaultClass + ' data-837'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData?.claims &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else if (type == 'DATE' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && moment(formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name]).format("MM/DD/YYYY") ==
            moment(formik.values?.claims[index]?.service_lines[sfindex]?.[name]).format("MM/DD/YYYY")) {
            return defaultClass + ' data-837'
        }
        else if (type == 'TEXT' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name]?.toUpperCase() == formik.values?.claims[index]?.service_lines[sfindex]?.[name]?.toUpperCase()) {
            return defaultClass + ' data-837'
        }
        else if (type == 'NUMBER' && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] && formik.values?.claims[index]?.service_lines[sfindex]?.color_data?.[name] == formik.values?.claims[index]?.service_lines[sfindex]?.[name]) {
            return defaultClass + ' data-837'
        }
        else {
            return defaultClass;
        }
    }
    const renderClaimClass = (name, type, defaultClass) => {
        if (formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData &&
                qaCorrectedData?.claims &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'DATE' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]).format("MM/DD/YYYY")) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                formik.values?.claims[index]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                parseFloat(formik.values?.claims[index]?.[name] ? formik.values?.claims[index]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name] ?
                    gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }

            else if (formik.values?.claims[index]?.corrected_claim || !gridData?.claims || gridData?.transaction_type != "PAYMENT835") {
                return defaultClass + ' qa-field'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData &&
            qaCorrectedData?.claims &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else {
            return defaultClass;
        }
    }
    const renderAdjustmentClass = (name, type, defaultClass, sfindex, adjIndex) => {
        if (formik.errors &&
            formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.service_lines &&
            formik.errors?.claims[index]?.service_lines[sfindex] &&
            formik.errors?.claims[index]?.service_lines[sfindex]?.adjustments &&
            formik.errors?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex] &&
            formik.errors?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData &&
                qaCorrectedData?.claims &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex]?.[name]) {
                return defaultClass + ' qa-field'
            }

            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex] &&
                formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex] &&
                parseFloat(formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.[name] ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex]?.[name] ?
                    gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }
            else if (gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]) {
                return defaultClass + ' qa-field'
            }

            else if (formik.values?.claims[index]?.corrected_claim ||
                formik.values?.claims[index]?.service_lines[sfindex]?.corrected_serviceline ||
                !gridData?.claims ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex] ||
                gridData?.transaction_type != "PAYMENT835") {
                return defaultClass + ' qa-field'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData &&
            qaCorrectedData?.claims &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.service_lines[formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.serviceline_old_data_index : sfindex]?.adjustments[formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index == 0 ? formik.values?.claims[index]?.service_lines[sfindex]?.adjustments[adjIndex]?.old_index : adjIndex]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else {
            return defaultClass;
        }
    }
    const renderClaimAdjustmentClass = (name, type, defaultClass, clIndex) => {
        if (formik.errors &&
            formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.claim_adjustments &&
            formik.errors?.claims[index]?.claim_adjustments[clIndex] &&
            formik.errors?.claims[index]?.claim_adjustments[clIndex]?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData &&
                qaCorrectedData?.claims &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] &&
                qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name]) {
                return defaultClass + ' qa-field'
            }

            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] &&
                // gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name] &&
                formik.values?.claims[index]?.claim_adjustments[clIndex]?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments &&
                gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] &&
                // gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name] &&
                parseFloat(formik.values?.claims[index]?.claim_adjustments[clIndex]?.[name] ? formik.values?.claims[index]?.claim_adjustments[clIndex]?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name] ?
                    gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name] : 0).toFixed(2)) {
                return defaultClass + ' qa-field'
            }

            else if (formik.values?.claims[index]?.corrected_claim ||
                !gridData?.claims ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments ||
                !gridData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] ||
                gridData?.transaction_type != "PAYMENT835"
            ) {
                return defaultClass + ' qa-field'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) && qaCorrectedData &&
            qaCorrectedData?.claims &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex] &&
            qaCorrectedData?.claims[formik.values?.claims[index].claim_corrected_index ? formik.values?.claims[index].claim_corrected_index : formik.values?.claims[index].claim_corrected_index == 0 ? formik.values?.claims[index].claim_corrected_index : index]?.claim_adjustments[clIndex]?.[name]) {
            return defaultClass + ' qa-field'
        }
        else {
            return defaultClass;
        }
    }
    console.log(formik.values)
    return (
        <React.Fragment>
            <div className="serviceline-mt">
                <div className="serviceline">
                    <div className="serviceline-top">
                        <div className="leftside-block">
                            <div className="accordion" id="serviceline835">
                                <button tabIndex={-1} type="button" className="btn btn-healthcare accordion-icon" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">serviceline835</button>
                            </div>
                            <div className="">
                                <button type="button" tabIndex={"-1"} className="btn btn-healthcare" id='tooltip-button'
                                    ref={ref}
                                    onClick={(e) => handleNewServiceLine(e)}
                                > <img src={newicon} alt="" /> New</button>
                            </div>
                            <Overlay rootClose={true}
                                target={ref.current} show={newPoupUp}
                                onHide={() => { setNewPoupUp(false); setIsDrag(true) }}
                                placement="top">
                                {(props) => (
                                    <Tooltip id="overlay-example" {...props}>
                                        <Form.Control type="text" style={{ inlineSize: '30px' }} onChange={(e) => { setSlCount(e.target.value) }} onBlur={() => { handleNewPoup(); setIsDrag(true) }} />
                                    </Tooltip>
                                )}
                            </Overlay>
                            <div className="">
                                <button type="button" tabIndex={"-1"} disabled={formik.values?.claims[index]?.service_lines?.length > 0 ? false : true} className="btn btn-healthcare" onClick={() => { __copyServiceLine(); }}> <img src={copyicon} alt="" /> Copy</button>
                            </div>
                            <div className="">
                                <button type="button" tabIndex={"-1"} disabled={formik.values?.claims[index]?.service_lines?.length > 0 ? false : true} className="btn btn-healthcare" onClick={() => { __moveServiceLine('UP') }}> <i className="bi bi-chevron-up"></i> Move up</button>
                            </div>
                            <div className="">
                                <button type="button" tabIndex={"-1"} disabled={formik.values?.claims[index]?.service_lines?.length > 0 ? false : true} className="btn btn-healthcare" onClick={() => { __moveServiceLine('DOWN') }}> <i className="bi bi-chevron-down"></i> Move down</button>
                            </div>
                            <div className="">
                                <button type="button" tabIndex={"-1"} className="btn btn-healthcare" onClick={() => { __clearServiceLine() }}> <img src={clear} alt="" className="clear" /> Clear</button>
                            </div>
                        </div>
                        <div className="rightside-block">
                            <InputGroup className=' mt-1' style={{ flex: '0' }}>
                                <InputGroup.Text className="btn btn-healthcare">Billed</InputGroup.Text>
                                <Form.Control
                                    disabled
                                    name={`claims[${index}].claim_billed`}
                                    type="number"
                                    tabIndex={-1}
                                    onDoubleClick={() => handlePageNavigate(index, '835')}
                                    onChange={(e) => changeClaimValue(e, 'claim_billed')}
                                    onWheel={handleWheel}
                                    value={formik.values?.claims[index]?.claim_billed}
                                    onKeyDown={(e) => {
                                        handlePreventEKeyPress(e);
                                    }}
                                    className={renderClaimClass('claim_billed', 'NUMBER', 'serviceline-btn text-end')}
                                />
                            </InputGroup>
                            <InputGroup className=' mt-1 ' style={{ flex: '0' }}>
                                <InputGroup.Text className="btn btn-healthcare">Patres</InputGroup.Text>
                                <Form.Control
                                    name={`claims[${index}].claim_patres`}
                                    type="number"
                                    tabIndex={-1}
                                    onDoubleClick={() => handlePageNavigate(index, '835')}
                                    onWheel={handleWheel}
                                    value={formik.values?.claims[index]?.claim_patres}
                                    onKeyDown={(e) => {
                                        handlePreventEKeyPress(e);
                                    }}
                                    className={renderClaimClass('claim_patres', 'NUMBER', 'serviceline-btn text-end w-blnc prevent-shift')}
                                />
                            </InputGroup>
                            <InputGroup className=' mt-1' style={{ flex: '0' }}>
                                <InputGroup.Text className="btn btn-healthcare">Paid</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    onWheel={handleWheel}
                                    tabIndex={-1}
                                    name={`claims[${index}].claim_paid`}
                                    onDoubleClick={() => handlePageNavigate(index, '835')}
                                    onBlur={handleFloatValue}
                                    onChange={(e) => changeClaimValue(e, 'claim_paid')}
                                    value={formik.values?.claims[index]?.claim_paid}
                                    onKeyDown={(e) => {
                                        handlePreventEKeyPress(e);
                                    }}
                                    className={renderClaimClass('claim_paid', 'NUMBER', 'serviceline-btn text-end')}
                                />
                            </InputGroup>
                        </div>
                    </div>
                </div>
                <div className="bottom-wrapper serviceline_details accordion-collapse collapse show" id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#serviceline835">
                    <div className="sub-section ">
                        <div className="table-responsive servicelinetab">
                            <table className="table table-bordered align-middle" >
                                <thead>
                                    <tr >
                                        <th className="serialno-th">#</th>
                                        <th className="date-th">Date</th>
                                        <th className="procedure-th">Procedure</th>
                                        <th className="revcode-th">Rev code</th>
                                        <th className="units-th">Units</th>
                                        <th className="billed-th">Billed</th>
                                        <th className="discount-th">Discount</th>
                                        <th className="allowed-th">Allowed</th>
                                        <th className="deduct-th">Deduct</th>
                                        <th className="coins-th">Coins</th>
                                        <th className="copay-th">Copay</th>
                                        <th className="patres-th">PatRes</th>
                                        <th className="adjustments-th">Adjustments</th>
                                        <th className="cob-th">COB</th>
                                        <th className="paid-th">Paid</th>
                                        <th className="balance-th">Balance</th>
                                        <th className="em-th">  </th>
                                    </tr>
                                </thead>

                                <tbody className="totalserviceline835">

                                    {formik.values?.claims && formik.values?.claims[index] && formik.values?.claims[index]?.service_lines &&
                                        <tr className='servicelinetd' style={{ outline: 'none', border: 'none', borderWidth: '0px' }}>
                                            <td className="servicelinetd" colSpan="17">
                                                <div className="table-responsive">
                                                    <table className="table table-bordered sub-table" >
                                                        <tbody className=''>
                                                            {formik.values?.claims[index]?.service_lines?.map((servicefield, sfindex) => (
                                                                <tr key={sfindex} id={"serviceline" + sfindex} onClick={() => setServiceIndex(sfindex)}>
                                                                    <td className="serialno">{sfindex + 1}</td>
                                                                    <td className="date">

                                                                        <div className="dflex">
                                                                            <InputMask
                                                                                disabled={isViewOnllyGrid}
                                                                                onChange={formik.handleChange}
                                                                                mask="99/99/9999"
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                className={renderClass('from_date', 'DATE', 'serviceline-date fw-normal', sfindex)}
                                                                                onKeyDown={(e) => { setDefaultValueAssignedWorkServiceLine(e, index, sfindex, '', 'from_date'), handleKeyDown(e, sfindex, 'from_date') }}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onBlur={() => { updatePeriodDate() }}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.from_date}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.from_date != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                name={`claims[${index}].service_lines[${sfindex}].from_date`}
                                                                                placeholder={"mm/dd/yyyy"}
                                                                            />

                                                                            <InputMask
                                                                                disabled={isViewOnllyGrid}
                                                                                onKeyUp={(e) => { setDefaultValueAssignedWorkServiceLine(e, index, sfindex, '', 'to_date') }}
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                mask="99/99/9999"
                                                                                className={renderClass('to_date', 'DATE', 'serviceline-date fw-normal', sfindex)}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onChange={(e) => { handleChangeEndDate(e, sfindex) }}
                                                                                onBlur={() => { updatePeriodDate() }}
                                                                                onKeyDown={(e) => handleKeyDown(e, sfindex, 'to_date')}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.to_date}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.to_date != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                name={`claims[${index}].service_lines[${sfindex}].to_date`}
                                                                                placeholder={"mm/dd/yyyy"}
                                                                            />

                                                                        </div>
                                                                    </td>

                                                                    <td className="procedure">
                                                                        <div className="dflex">

                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('code')}
                                                                                data-corress-disable={disableCorresField('code')}
                                                                                type="text"
                                                                                placeholder="code"
                                                                                name={`claims[${index}].service_lines[${sfindex}].code`}
                                                                                onChange={(e) => {
                                                                                    const { value } = e.target; // Get the current value
                                                                                    e.target.value = value.trim()
                                                                                    handleAmountFloat(e)
                                                                                }}
                                                                                maxLength={5}
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onKeyDown={(e) => { setDefaultValueAssignedWorkServiceLine(e, index, sfindex, '', 'code'), handleKeyDown(e, sfindex, 'code') }}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.code}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.code != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('code', 'TEXT', 'w-40 fw-normal', sfindex)}
                                                                            />

                                                                            <Form.Control as="select"
                                                                                disabled={isViewOnllyGrid || disableCorresField('procedure_type')}
                                                                                data-corress-disable={disableCorresField('procedure_type')}
                                                                                placeholder="procedure_type"
                                                                                name={`claims[${index}].service_lines[${sfindex}].procedure_type`}
                                                                                onChange={formik.handleChange}
                                                                                onKeyDown={(e) => handleKeyDown(e, sfindex, 'procedure_type')}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.procedure_type != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.procedure_type}
                                                                                className={renderClass('procedure_type', 'TEXT', 'selc-bg-1 fw-normal', sfindex)}
                                                                            >
                                                                                <option value="" >---select---</option>
                                                                                <option value="HC" >HC</option>
                                                                                <option value="NU" >NU</option>
                                                                                <option value="AD" >AD</option>

                                                                            </Form.Control>

                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('modifier1')}
                                                                                data-corress-disable={disableCorresField('modifier1')}
                                                                                onChange={(e) => handleAmountFloat(e)}
                                                                                name={`claims[${index}].service_lines[${sfindex}].modifier1`}
                                                                                type="text"
                                                                                maxLength={2}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.modifier1}
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onKeyDown={(e) => handleKeyDown(e, sfindex, 'modifier1')}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.modifier1 != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('modifier1', 'TEXT', 'w-30', sfindex)}
                                                                                placeholder="mo1"
                                                                            />
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('modifier2')}
                                                                                data-corress-disable={disableCorresField('modifier2')}
                                                                                onChange={(e) => handleAmountFloat(e)}
                                                                                name={`claims[${index}].service_lines[${sfindex}].modifier2`}
                                                                                type="text"
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                maxLength={2}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.modifier2}
                                                                                onKeyDown={(e) => handleKeyDown(e, sfindex, 'modifier2')}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.modifier2 != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('modifier2', 'TEXT', 'w-30', sfindex)}
                                                                                placeholder="mo2"
                                                                            />
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('modifier3')}
                                                                                data-corress-disable={disableCorresField('modifier3')}
                                                                                onChange={(e) => handleAmountFloat(e)}
                                                                                name={`claims[${index}].service_lines[${sfindex}].modifier3`}
                                                                                type="text"
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                maxLength={2}
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                onKeyDown={(e) => handleKeyDown(e, sfindex, 'modifier3')}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.modifier3}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.modifier3 != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('modifier3', 'TEXT', 'w-30', sfindex)}
                                                                                placeholder="mo3"
                                                                            />
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('modifier4')}
                                                                                data-corress-disable={disableCorresField('modifier4')}
                                                                                onChange={(e) => handleAmountFloat(e)}
                                                                                name={`claims[${index}].service_lines[${sfindex}].modifier4`}
                                                                                type="text"
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                maxLength={2}
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                onKeyDown={(e) => handleKeyDown(e, sfindex, 'modifier4')}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.modifier4}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.modifier4 != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('modifier4', 'TEXT', 'w-30', sfindex)}
                                                                                placeholder="mo4"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="revcode">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('rev_code')}
                                                                                data-corress-disable={disableCorresField('rev_code')}
                                                                                onChange={(e) => handleAmountFloat(e)}
                                                                                name={`claims[${index}].service_lines[${sfindex}].rev_code`}
                                                                                type="text"
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                maxLength={4}
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                onKeyDown={(e) => handleKeyDown(e, sfindex, 'rev_code')}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.rev_code}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.rev_code != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('rev_code', 'TEXT', 'w-82', sfindex)}
                                                                                placeholder="rev code"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="units">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('units')}
                                                                                data-corress-disable={disableCorresField('units')}
                                                                                onChange={(e) => handleAmountFloat(e)}
                                                                                name={`claims[${index}].service_lines[${sfindex}].units`}
                                                                                type="number"
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e); handleKeyDown(e, sfindex, 'units') }}
                                                                                onDoubleClick={() => handlePageNavigate(index, '835')}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.units}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.units != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('units', 'TEXT', 'w-units', sfindex)}
                                                                                placeholder="units"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="billed">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid}
                                                                                onBlur={(e) => { changeServiceLineValue(e, 'billed', sfindex) }}
                                                                                onChange={(e) => { handleChangeFormik(e, index, sfindex, 'billed') }}
                                                                                name={`claims[${index}].service_lines[${sfindex}].billed`}
                                                                                onWheel={handleWheel}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e); setDefaultValueAssignedWorkServiceLine(e, index, sfindex, '', 'billed'), handleKeyDown(e, sfindex, 'billed', 'claim_billed') }}
                                                                                type="number"
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.billed}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.billed != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('billed', 'NUMBER', 'w-billed billed trigger-billed text-end', sfindex)}
                                                                                placeholder="billed"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="discount">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('discount')}
                                                                                data-corress-disable={disableCorresField('discount')}
                                                                                onBlur={(e) => { changeServiceLineValue(e, 'discount', sfindex) }}
                                                                                onChange={(e) => { handleChangeFormik(e, index, sfindex, 'discount') }}
                                                                                name={`claims[${index}].service_lines[${sfindex}].discount`}
                                                                                type="number"
                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e); handleKeyDown(e, sfindex, 'discount', 'claim_discount') }}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                ref={(el) => (inputRefs.current[index] = el)}
                                                                                onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, sfindex, null, 'amount', 'discount') }}
                                                                                onWheel={handleWheel}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.discount}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.discount != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('discount', 'NUMBER', 'w-82 discount trigger-discount text-end', sfindex)}
                                                                                placeholder="discount"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="allowed">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('allowed')}
                                                                                data-corress-disable={disableCorresField('allowed')}
                                                                                onChange={(e) => { handleChangeFormik(e, index, sfindex, 'allowed') }}
                                                                                onBlur={(e) => { changeServiceLineValue(e, 'allowed', sfindex) }}
                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e); setDefaultValueAssignedWorkServiceLine(e, index, sfindex, '', 'allowed'), handleKeyDown(e, sfindex, 'allowed', 'claim_allowed') }}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, sfindex, null, 'amount', 'allowed') }}
                                                                                type="number"
                                                                                name={`claims[${index}].service_lines[${sfindex}].allowed`}
                                                                                onWheel={handleWheel}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.allowed}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.allowed != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('allowed', 'NUMBER', 'w-82 allowed trigger-allowed text-end', sfindex)}
                                                                                placeholder="allowed"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="deduct">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('deduct')}
                                                                                data-corress-disable={disableCorresField('deduct')}
                                                                                onBlur={(e) => { changeServiceLineValue(e, 'deduct', sfindex) }}
                                                                                onChange={(e) => { handleChangeFormik(e, index, sfindex, 'deduct') }}
                                                                                name={`claims[${index}].service_lines[${sfindex}].deduct`}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                type="number"
                                                                                onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, sfindex, null, 'amount', 'deduct') }}
                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e); handleKeyDown(e, sfindex, 'deduct', 'claim_deduct') }}
                                                                                onWheel={handleWheel}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.deduct}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.deduct != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('deduct', 'NUMBER', 'w-82 deduct trigger-deduct text-end', sfindex)}
                                                                                placeholder="deduct"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="coins">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('coins')}
                                                                                data-corress-disable={disableCorresField('coins')}
                                                                                onBlur={(e) => { changeServiceLineValue(e, 'coins', sfindex) }}
                                                                                onChange={(e) => { handleChangeFormik(e, index, sfindex, 'coins') }}
                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e); handleKeyDown(e, sfindex, 'coins', 'claim_coins') }}
                                                                                onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, sfindex, null, 'amount', 'coins') }}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                name={`claims[${index}].service_lines[${sfindex}].coins`}
                                                                                type="number"
                                                                                onWheel={handleWheel}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.coins}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.coins != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('coins', 'NUMBER', 'w-82 coins trigger-coins text-end', sfindex)}
                                                                                placeholder="coins"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="copay">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('copay')}
                                                                                data-corress-disable={disableCorresField('copay')}
                                                                                onBlur={(e) => { changeServiceLineValue(e, 'copay', sfindex) }}
                                                                                onChange={(e) => { handleChangeFormik(e, index, sfindex, 'copay') }}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, sfindex, null, 'amount', 'copay') }}
                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e); handleKeyDown(e, sfindex, 'copay') }}
                                                                                name={`claims[${index}].service_lines[${sfindex}].copay`}
                                                                                type="number"
                                                                                onWheel={handleWheel}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.copay != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.copay}
                                                                                className={renderClass('copay', 'NUMBER', 'w-82 copay trigger-copay text-end', sfindex)}
                                                                                placeholder="copay"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="patres">

                                                                    </td>
                                                                    <td className="adjustments">
                                                                        <React.Fragment>
                                                                            <div className='d-flex flex-row justify-content-center'>
                                                                                <div className=' text-end'>
                                                                                    {formik.values?.claims[index]?.service_lines[sfindex]?.adjustments && formik.values?.claims[index]?.service_lines[sfindex]?.adjustments?.map((adjust, adjIndex) => (
                                                                                        <div className="d-flex" key={adjIndex}>

                                                                                            <Form.Control
                                                                                                disabled={isViewOnllyGrid}
                                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e); changeFocusAdj(e, sfindex, adjIndex, 'amount') }}
                                                                                                onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, sfindex, adjIndex, 'adjustments', 'amount') }}
                                                                                                onBlur={(e) => { changeServiceLineValue(e, 'amount', sfindex) }}
                                                                                                onChange={(e) => { handleChangeFormik(e, index, sfindex, 'amount', adjIndex) }}
                                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                                type="number"
                                                                                                onWheel={handleWheel}
                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].amount`}
                                                                                                placeholder="amt"
                                                                                                value={adjust.amount}
                                                                                                tabIndex={adjust.amount != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                                className={renderAdjustmentClass('amount', 'NUMBER', 'w-40 amount trigger-amount text-end', sfindex, adjIndex)}
                                                                                            />
                                                                                            <Overlay
                                                                                                container={gridRef.current}
                                                                                                rootClose={true}
                                                                                                onHide={() => { setPcPopup(false); setIsDrag(true); formRc.handleReset() }}
                                                                                                show={pcPopup && propCodeData?.adjustmentIndex == adjIndex && propCodeData?.serviceIndex == sfindex}
                                                                                                target={currentServiceLineRef}
                                                                                                containerPadding={100} placement="top"
                                                                                            >
                                                                                                {
                                                                                                    (props) => (
                                                                                                        <Popover className='pc-popover-popup' id="pc-popover-popup"  {...props}>
                                                                                                            <Popover.Header className='p-1 ' style={{ fontSize: '1em', backgroundColor: "#6ba2b6" }} >
                                                                                                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                                                                    <span className='text-white fw-bold'> Create Prop Code </span>
                                                                                                                    <button className='btn text-white fw-bold' onClick={() => { setPcPopup(false); setIsDrag(true); formRc.handleReset() }}>&times;</button>
                                                                                                                </div>
                                                                                                            </Popover.Header>

                                                                                                            <Popover.Body>
                                                                                                                <FormikProvider value={formRc}>
                                                                                                                    <Form
                                                                                                                        onSubmit={formRc.handleSubmit}
                                                                                                                    >
                                                                                                                        <div className='row  align-items-center'>

                                                                                                                            <div className='col-lg-3'>
                                                                                                                                <div className="mb-10">
                                                                                                                                    <Form.Group>

                                                                                                                                        <Form.Label htmlFor="exampleFormControlInput1" className=" form-label">Reason Code</Form.Label>
                                                                                                                                        <Form.Control
                                                                                                                                            ref={pcReasonRef}
                                                                                                                                            onKeyDown={(e) => { handleKeyDownPc(e, 'reasonCode') }}
                                                                                                                                            disabled={isViewOnllyGrid}
                                                                                                                                            autoFocus
                                                                                                                                            type="text"
                                                                                                                                            name='reason_code'
                                                                                                                                            required
                                                                                                                                            onChange={(e) => { formRc.handleChange(e); setPcFormik(e.target.value, index, serviceIndex, adjIndex) }}
                                                                                                                                            value={formRc.values?.reason_code}
                                                                                                                                            isInvalid={!!formRc.errors?.reason_code}
                                                                                                                                            className="form-control-sm text-uppercase b-r-10 "
                                                                                                                                            placeholder="Reason Code"
                                                                                                                                        />

                                                                                                                                        <Form.Control.Feedback type="invalid" >
                                                                                                                                            {formRc.errors && formRc.errors?.reason_code &&
                                                                                                                                                formRc?.errors?.reason_code
                                                                                                                                            }
                                                                                                                                        </Form.Control.Feedback>

                                                                                                                                    </Form.Group>
                                                                                                                                </div>
                                                                                                                            </div>


                                                                                                                            <div className="mb-10 col-lg-3">
                                                                                                                                <Form.Group>

                                                                                                                                    <Form.Label htmlFor="exampleFormControlInput1" className=" form-label">Description</Form.Label>

                                                                                                                                    <Form.Control
                                                                                                                                        disabled={isViewOnllyGrid}

                                                                                                                                        type="text"
                                                                                                                                        name="description"
                                                                                                                                        required
                                                                                                                                        onChange={formRc.handleChange}
                                                                                                                                        isInvalid={!!formRc.errors?.description}
                                                                                                                                        value={formRc.values?.description}
                                                                                                                                        className="form-control-sm text-uppercase  b-r-10 "
                                                                                                                                        placeholder="Description"
                                                                                                                                    />
                                                                                                                                    <Form.Control.Feedback type="invalid" >
                                                                                                                                        {formRc.errors && formRc.errors?.description &&
                                                                                                                                            formRc?.errors?.description
                                                                                                                                        }
                                                                                                                                    </Form.Control.Feedback>
                                                                                                                                </Form.Group>
                                                                                                                            </div>

                                                                                                                            <div className="mb-10 col-lg-2">
                                                                                                                                <label htmlFor="exampleFormControlInput1" className=" form-label">CARC</label>

                                                                                                                                <Form.Control
                                                                                                                                    disabled={isViewOnllyGrid}

                                                                                                                                    className="form-control-sm text-uppercase b-r-10"
                                                                                                                                    name="carc"
                                                                                                                                    value={formRc.values?.carc}
                                                                                                                                    required
                                                                                                                                    maxLength={10}
                                                                                                                                    isInvalid={!!formRc.errors?.carc}
                                                                                                                                    // options={x12codes}
                                                                                                                                    placeholder="CARC"
                                                                                                                                    onChange={formRc.handleChange}
                                                                                                                                />
                                                                                                                                <Form.Control.Feedback type="invalid" >
                                                                                                                                    {formRc.errors && formRc.errors?.carc &&
                                                                                                                                        formRc?.errors?.carc
                                                                                                                                    }
                                                                                                                                </Form.Control.Feedback>

                                                                                                                            </div>
                                                                                                                            <div className="mb-10 col-lg-2">
                                                                                                                                <label htmlFor="exampleFormControlInput1" className=" form-label">RARC</label>

                                                                                                                                <Form.Control
                                                                                                                                    disabled={isViewOnllyGrid}
                                                                                                                                    className="form-control-sm text-uppercase b-r-10 "
                                                                                                                                    name="rarc"
                                                                                                                                    value={formRc.values?.rarc}
                                                                                                                                    isInvalid={formRc.errors?.rarc}
                                                                                                                                    placeholder="RARC"
                                                                                                                                    maxLength={10}
                                                                                                                                    onChange={formRc.handleChange}
                                                                                                                                />
                                                                                                                                <Form.Control.Feedback type="invalid" >
                                                                                                                                    {formRc.errors && formRc.errors?.rarc &&
                                                                                                                                        formRc?.errors?.rarc
                                                                                                                                    }
                                                                                                                                </Form.Control.Feedback>
                                                                                                                            </div>
                                                                                                                            <div className="mb-10 col-lg-2">
                                                                                                                                <label htmlFor="exampleFormControlInput1" className=" form-label">GC</label>

                                                                                                                                <Form.Control as="select"
                                                                                                                                    disabled={isViewOnllyGrid}
                                                                                                                                    className="form-control-sm  b-r-10 "
                                                                                                                                    name="group_code"
                                                                                                                                    value={formRc.values?.group_code}
                                                                                                                                    isInvalid={formRc.errors?.group_code}

                                                                                                                                    placeholder="GC"
                                                                                                                                    onChange={formRc.handleChange}
                                                                                                                                >
                                                                                                                                    <option value="0" >-Select-</option>
                                                                                                                                    <option value="CO">CO</option>
                                                                                                                                    <option value="OA">OA</option>
                                                                                                                                    <option value="PI">PI</option>
                                                                                                                                    <option value="PR">PR</option>
                                                                                                                                </Form.Control>
                                                                                                                                <Form.Control.Feedback type="invalid" >
                                                                                                                                    {formRc.errors && formRc.errors?.group_code &&
                                                                                                                                        formRc?.errors?.group_code
                                                                                                                                    }
                                                                                                                                </Form.Control.Feedback>
                                                                                                                            </div>


                                                                                                                        </div>
                                                                                                                        <div className='d-flex flex-row justify-content-end'>


                                                                                                                            <div className='col-lg-2 pt-4 text-end'>
                                                                                                                                <button
                                                                                                                                    ref={pcSaveRef}
                                                                                                                                    onKeyDown={(e) => { handleKeyDownPc(e, 'okButton') }}
                                                                                                                                    disabled={formRc.isSubmitting}
                                                                                                                                    type='submit'
                                                                                                                                    className='btn btn-light btn-active-primary new-window btn-focus-shadow'
                                                                                                                                >
                                                                                                                                    Save
                                                                                                                                </button>
                                                                                                                            </div>
                                                                                                                        </div>


                                                                                                                    </Form>
                                                                                                                </FormikProvider>
                                                                                                            </Popover.Body>
                                                                                                        </Popover>
                                                                                                    )
                                                                                                }
                                                                                            </Overlay>

                                                                                            <Overlay
                                                                                                container={gridRef.current}
                                                                                                rootClose={true}
                                                                                                show={x12Codes.open && propCodeData?.serviceIndex == sfindex && adjIndex == x12Codes.adjustmentIndex}
                                                                                                onHide={() => { setx12Codes({ open: false, data: "" }); setIsDrag(true) }}
                                                                                                target={currentServiceLineRef}
                                                                                                placement="top"
                                                                                            >
                                                                                                {
                                                                                                    (props) => (
                                                                                                        <Popover className='pc-popover-pc' id="pc-popover-pc"  {...props}>
                                                                                                            <Popover.Header className='p-1 ' style={{ fontSize: '1em', backgroundColor: "#6ba2b6" }} >
                                                                                                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                                                                    <span className='text-white fw-bold'> X12 Codes </span>
                                                                                                                    <button className='btn text-white fw-bold' onClick={() => { setx12Codes({ open: false, data: "" }); setIsDrag(true) }}>&times;</button>
                                                                                                                </div>
                                                                                                            </Popover.Header>

                                                                                                            <Popover.Body style={{ padding: "0px", }}>
                                                                                                                <div className='pc-height'>

                                                                                                                    <ul className="list-group list-group-horizontal">
                                                                                                                        <li className="list-group-item item-width-full-pc w-110px py-1">CARC</li>
                                                                                                                        <li className="list-group-item item-width-full-pc w-110px py-1">RARC</li>
                                                                                                                        <li className="list-group-item item-width-full-pc w-55px py-1" >GC</li>
                                                                                                                        <li className="list-group-item item-width-full-pc w-desc py-1" > DESC</li>
                                                                                                                        <li className="list-group-item item-width-full-pc w-5 py-1" style={{ width: "5%" }}> </li>
                                                                                                                    </ul>
                                                                                                                    {x12Codes.data &&
                                                                                                                        <React.Fragment>
                                                                                                                            {x12Codes?.data?.length > 0 && x12Codes?.data?.map((code, xindex) => (
                                                                                                                                <ul

                                                                                                                                    key={xindex} className={"list-group list-group-horizontal"}>
                                                                                                                                    <li className="list-group-item option-item-pc w-110px py-1 " >
                                                                                                                                        {code?.carc}
                                                                                                                                    </li>
                                                                                                                                    <li className="list-group-item option-item-pc w-110px py-1 " >
                                                                                                                                        {code?.rarc}
                                                                                                                                    </li>
                                                                                                                                    <li className="list-group-item option-item-pc w-55px  py-1 " >
                                                                                                                                        {code?.group_code}
                                                                                                                                    </li>

                                                                                                                                    <li className="list-group-item option-item-pc w-desc py-1 " >
                                                                                                                                        {code?.description}

                                                                                                                                    </li>

                                                                                                                                    <li className="list-group-item option-item-pc w-5 d-flex align-items-center " >
                                                                                                                                        <button onClick={() => { handleMultiplePropCode(code, xindex, index, sfindex, adjIndex); }} className='btn btn-light btn-sm btn-icon btn-active-primary p-0 me-2'>

                                                                                                                                            <BiChevronRight style={{ color: '#fff' }} />
                                                                                                                                        </button>

                                                                                                                                    </li>



                                                                                                                                </ul>
                                                                                                                            ))}
                                                                                                                        </React.Fragment>
                                                                                                                    }
                                                                                                                </div>

                                                                                                            </Popover.Body>
                                                                                                        </Popover>
                                                                                                    )
                                                                                                }
                                                                                            </Overlay>



                                                                                            <Form.Control
                                                                                                disabled={isViewOnllyGrid || disableCorresField('pc')}
                                                                                                data-corress-disable={disableCorresField('pc')}
                                                                                                type="text"
                                                                                                className={renderAdjustmentClass('pc', 'TEXT', 'w-30', sfindex, adjIndex)}
                                                                                                value={adjust.pc}
                                                                                                tabIndex={adjust.pc != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                                ref={(el) => refPC.current[adjIndex] = el}
                                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                                onChange={(e) => { setFormRc(e.target.value); formik.handleChange(e) }}
                                                                                                onKeyDown={(e) => {
                                                                                                    handlePayerPcVerify(e, index, sfindex, adjIndex);
                                                                                                    setCurrentServiceLineRef(e.target);
                                                                                                    setDefaultValueAssignedWorkServiceLine(e, index, sfindex, adjIndex, 'pc');
                                                                                                    changeFocusAdj(e, sfindex, adjIndex, 'pc');
                                                                                                }}
                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].pc`}
                                                                                                placeholder="pc"
                                                                                                onDoubleClick={(e) => {
                                                                                                    handleDoubleClickServiceLineAdjustment(adjust, e, index, sfindex, adjIndex)
                                                                                                    setCurrentServiceLineRef(e.target)
                                                                                                }}
                                                                                            />
                                                                                            <Form.Control as="select"
                                                                                                disabled={isViewOnllyGrid || disableCorresField('group_code')}
                                                                                                data-corress-disable={disableCorresField('group_code')}
                                                                                                onChange={(e) => changeGroupCode(e, sfindex, adjIndex)}
                                                                                                onKeyDown={(e) => changeFocusAdj(e, sfindex, adjIndex, 'group_code')}
                                                                                                className={renderAdjustmentClass('group_code', 'TEXT', 'w-select-60', sfindex, adjIndex)}
                                                                                                value={adjust.group_code}
                                                                                                tabIndex={adjust.group_code != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].group_code`}
                                                                                                aria-label="Default select example"
                                                                                            >
                                                                                                <option value="" >-Select-</option>
                                                                                                <option value="CO">CO</option>
                                                                                                <option value="OA">OA</option>
                                                                                                <option value="PI">PI</option>
                                                                                                <option value="PR">PR</option>
                                                                                            </Form.Control>
                                                                                            <Form.Control
                                                                                                disabled={isViewOnllyGrid || disableCorresField('reason_code')}
                                                                                                data-corress-disable={disableCorresField('reason_code')}
                                                                                                type="text"
                                                                                                className={renderAdjustmentClass('reason_code', 'TEXT', 'w-30', sfindex, adjIndex)}
                                                                                                onChange={(e) => handleAmountFloat(e)}
                                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                                value={adjust.reason_code}
                                                                                                tabIndex={adjust.reason_code != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                                onKeyDown={(e) => changeFocusAdj(e, sfindex, adjIndex, 'reason_code')}
                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].reason_code`}
                                                                                                placeholder="rc"
                                                                                            />
                                                                                            <Form.Control
                                                                                                disabled={isViewOnllyGrid || disableCorresField('rmk')}
                                                                                                data-corress-disable={disableCorresField('rmk')}
                                                                                                type="text"
                                                                                                className={renderAdjustmentClass('rmk', 'TEXT', 'w-30-rmk', sfindex, adjIndex)}
                                                                                                onChange={(e) => handleAmountFloat(e)}
                                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                                onKeyDown={(e) => changeFocusAdj(e, sfindex, adjIndex, 'rmk')}
                                                                                                value={adjust.rmk}
                                                                                                tabIndex={adjust.rmk != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                                name={`claims[${index}].service_lines[${sfindex}].adjustments[${adjIndex}].rmk`}
                                                                                                placeholder="rmk"
                                                                                            />

                                                                                        </div>
                                                                                    ))}


                                                                                </div>
                                                                                <div className='' role="button" >
                                                                                    <button tabIndex={-1} type="button" className='btn btn-hidden-add h-100' onDoubleClick={() => handleClaimAServiceLineAdjustment(sfindex)}></button>
                                                                                </div>
                                                                            </div>
                                                                        </React.Fragment>


                                                                    </td>
                                                                    <td className="cob">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid || disableCorresField('cob')}
                                                                                data-corress-disable={disableCorresField('cob')}
                                                                                onBlur={(e) => { changeServiceLineValue(e, 'cob', sfindex) }}
                                                                                onChange={(e) => { handleChangeFormik(e, index, sfindex, 'cob') }}
                                                                                onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, sfindex, null, 'amount', 'cob') }}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e); handleKeyDown(e, sfindex, 'cob', 'claim_cob') }}
                                                                                name={`claims[${index}].service_lines[${sfindex}].cob`}
                                                                                type="number"
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.cob}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.cob != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('cob', 'NUMBER', 'w-82 cob trigger-cob text-end', sfindex)}
                                                                                placeholder="cob"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="paid">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                disabled={isViewOnllyGrid}
                                                                                onBlur={(e) => { changeServiceLineValue(e, 'paid', sfindex) }}
                                                                                onChange={(e) => { handleChangeFormik(e, index, sfindex, 'paid') }}
                                                                                onWheel={handleWheel}
                                                                                name={`claims[${index}].service_lines[${sfindex}].paid`}
                                                                                onFocus={() => setServiceIndex(sfindex)}
                                                                                type="number"
                                                                                onKeyDown={(e) => {
                                                                                    if (!e.shiftKey && e.key === 'Tab' && formik.values?.claims[index]?.service_lines.length - 1 == sfindex && pathname == DATA_ENTRY) {
                                                                                        e.preventDefault()
                                                                                        const aditionalInfoButton = document.getElementById("additional-btn");
                                                                                        aditionalInfoButton.focus()
                                                                                    }
                                                                                    handlePreventEKeyPress(e); handleKeyDown(e, sfindex, 'paid', 'claim_paid')
                                                                                }}
                                                                                onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, sfindex, null, 'amount', 'paid') }}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.paid}
                                                                                tabIndex={formik.values?.claims[index]?.service_lines[sfindex]?.paid != '' && pathname == DATA_ENTRY ? -1 : 0}
                                                                                className={renderClass('paid', 'NUMBER', 'w-82 paid trigger-paid text-end', sfindex)}
                                                                                placeholder="paid"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="balance">
                                                                        <div className="">
                                                                            <Form.Control
                                                                                name={`claims[${index}].service_lines[${sfindex}].balance`}
                                                                                type="number"
                                                                                disabled
                                                                                data-disabled={true}
                                                                                tabIndex={-1}
                                                                                onWheel={handleWheel}
                                                                                onKeyDown={(e) => { handlePreventEKeyPress(e) }}
                                                                                value={formik.values?.claims[index]?.service_lines[sfindex]?.balance}
                                                                                className={formik.errors?.claims &&
                                                                                    formik.errors?.claims[index] &&
                                                                                    formik.errors?.claims[index]?.service_lines &&
                                                                                    formik.errors?.claims[index]?.service_lines[sfindex]?.balance ? 'w-blnc error-field balance text-end prevent-shift' : 'w-blnc balance text-end prevent-shift'}
                                                                                placeholder="balance"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td className="em-td">
                                                                        <button
                                                                            disabled={isViewOnllyGrid || isActiveDbkField(eobFocus, formik) ? true : false}
                                                                            tabIndex={-1}
                                                                            type='button' style={{ cursor: 'pointer', background: 'transparent', outline: 'none', border: 'none' }} onClick={() => {
                                                                                handleServiceLineDelete(sfindex, formik.values?.claims[index]?.service_lines[sfindex])
                                                                            }}>
                                                                            <img src={deleteserviceline} alt="" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td></td>
                                        <td className="">
                                            <div className="ms-2 text-end">
                                                SL Totals
                                            </div>
                                        </td>
                                        <td >
                                            <div className="text-end">
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-end">
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className="px-2 text-end">
                                                {parseFloat(formik?.values?.claims[index]?.sl_billed ? formik?.values?.claims[index]?.sl_billed : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_discount ? formik?.values?.claims[index]?.sl_discount : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_allowed ? formik?.values?.claims[index]?.sl_allowed : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_deduct ? formik?.values?.claims[index]?.sl_deduct : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_coins ? formik?.values?.claims[index]?.sl_coins : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_copay ? formik?.values?.claims[index]?.sl_copay : 0).toFixed(2)}
                                            </div>
                                        </td>

                                        <td className='px-2  text-end'>
                                            <div className='px-2'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_patres ? formik?.values?.claims[index]?.sl_patres : 0).toFixed(2)}
                                            </div>

                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_amount ? formik?.values?.claims[index]?.sl_amount : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_cob ? formik?.values?.claims[index]?.sl_cob : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='px-2 text-end'>
                                                {parseFloat(formik?.values?.claims[index]?.sl_paid ? formik?.values?.claims[index]?.sl_paid : 0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td className=''>
                                            <div className='me-2 text-end'>
                                                <div className="text-end">
                                                    {parseFloat(formik?.values?.claims[index]?.sl_balance ? formik?.values?.claims[index]?.sl_balance : 0).toFixed(2)}
                                                </div>
                                            </div>
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td className="">
                                            <div className=" ms-2">
                                                Claim
                                            </div>
                                        </td>
                                        <td >
                                            <div className="">
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control
                                                    onBlur={handleFloatValue}
                                                    onChange={(e) => changeClaimValue(e, 'claim_billed')}
                                                    name={`claims[${index}].claim_billed`}
                                                    type="number"
                                                    onWheel={handleWheel}
                                                    onKeyDown={(e) => { handlePreventEKeyPress(e); changeClaimFocus(e, 'billed') }}
                                                    tabIndex={pathname == DATA_ENTRY ? -1 : 0}
                                                    value={formik.values?.claims[index]?.claim_billed}
                                                    className={renderClaimClass('claim_billed', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="billed"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control
                                                    onBlur={handleFloatValue}
                                                    onChange={(e) => changeClaimValue(e, 'claim_discount')}
                                                    name={`claims[${index}].claim_discount`}
                                                    type="number"
                                                    onWheel={handleWheel}
                                                    onKeyDown={(e) => { handlePreventEKeyPress(e); changeClaimFocus(e, 'discount') }}
                                                    disabled={disableCorresField('claim_discount')}
                                                    data-corress-disable={disableCorresField('claim_discount')}
                                                    onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, null, null, 'claim', 'claim_discount') }}
                                                    value={formik.values?.claims[index]?.claim_discount}
                                                    tabIndex={pathname == DATA_ENTRY ? -1 : 0}
                                                    className={renderClaimClass('claim_discount', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="discount"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control
                                                    onBlur={handleFloatValue}
                                                    onChange={(e) => changeClaimValue(e, 'claim_allowed')}
                                                    onKeyDown={(e) => { handlePreventEKeyPress(e); changeClaimFocus(e, 'allowed') }}
                                                    onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, null, null, 'claim', 'claim_allowed') }}
                                                    disabled={disableCorresField('claim_allowed')}
                                                    data-corress-disable={disableCorresField('claim_allowed')}
                                                    onWheel={handleWheel}
                                                    name={`claims[${index}].claim_allowed`}
                                                    type="number"
                                                    value={formik.values?.claims[index]?.claim_allowed}
                                                    tabIndex={pathname == DATA_ENTRY ? -1 : 0}
                                                    className={renderClaimClass('claim_allowed', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="allowed"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control
                                                    onBlur={handleFloatValue}
                                                    onKeyDown={(e) => { handlePreventEKeyPress(e); changeClaimFocus(e, 'deduct') }}
                                                    onChange={(e) => changeClaimValue(e, 'claim_deduct')}
                                                    onWheel={handleWheel}
                                                    name={`claims[${index}].claim_deduct`}
                                                    type="number"
                                                    disabled={disableCorresField('claim_deduct')}
                                                    data-corress-disable={disableCorresField('claim_deduct')}
                                                    onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, null, null, 'claim', 'claim_deduct') }}
                                                    value={formik.values?.claims[index]?.claim_deduct}
                                                    tabIndex={pathname == DATA_ENTRY ? -1 : 0}
                                                    className={renderClaimClass('claim_deduct', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="deduct"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control
                                                    onBlur={handleFloatValue}
                                                    onKeyDown={(e) => { handlePreventEKeyPress(e); changeClaimFocus(e, 'coins') }}
                                                    onWheel={handleWheel}
                                                    onChange={(e) => changeClaimValue(e, 'claim_coins')}
                                                    name={`claims[${index}].claim_coins`}
                                                    type="number"
                                                    onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, null, null, 'claim', 'claim_coins') }}
                                                    disabled={disableCorresField('claim_coins')}
                                                    data-corress-disable={disableCorresField('claim_coins')}
                                                    value={formik.values?.claims[index]?.claim_coins}
                                                    tabIndex={pathname == DATA_ENTRY ? -1 : 0}
                                                    className={renderClaimClass('claim_coins', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="coins"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control
                                                    onBlur={handleFloatValue}
                                                    onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, null, null, 'claim', 'claim_copay') }}
                                                    disabled={disableCorresField('claim_copay')}
                                                    data-corress-disable={disableCorresField('claim_copay')}
                                                    onKeyDown={(e) => { handlePreventEKeyPress(e); changeClaimFocus(e, 'copay') }}
                                                    onWheel={handleWheel}
                                                    onChange={(e) => changeClaimValue(e, 'claim_copay')}
                                                    name={`claims[${index}].claim_copay`}
                                                    type="number"
                                                    value={formik.values?.claims[index]?.claim_copay}
                                                    tabIndex={pathname == DATA_ENTRY ? -1 : 0}
                                                    className={renderClaimClass('claim_copay', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="copay"
                                                />
                                            </div>
                                        </td>
                                        <td className='text-center'>
                                            <div className="">
                                                <Form.Control
                                                    onBlur={handleFloatValue}
                                                    onKeyDown={(e) => { handlePreventEKeyPress(e); changeClaimFocus(e, 'claim_patres') }}
                                                    onWheel={handleWheel}
                                                    name={`claims[${index}].claim_patres`}
                                                    type="number"
                                                    disabled
                                                    value={formik.values?.claims[index]?.claim_patres}
                                                    tabIndex={pathname == DATA_ENTRY ? -1 : 0}
                                                    className={renderClaimClass('claim_patres', 'NUMBER', 'w-82 paid text-end w-blnc prevent-shift')}
                                                    placeholder="patres"
                                                />
                                            </div>
                                        </td>

                                        <React.Fragment>
                                            <td className='text-center'>
                                                <React.Fragment>
                                                    <div className='d-flex flex-row justify-content-center'>
                                                        <div className=' text-end'>

                                                            {formik.values?.claims[index]?.claim_adjustments?.map((claim_adjustment, clIndex) => (
                                                                <div className="custome-claim-adjustment d-flex" data-index={clIndex} key={clIndex}>
                                                                    <Form.Control
                                                                        onBlur={(e) => { setClaimrevCodeFocus(false); handleFloatValue(e) }}
                                                                        onWheel={handleWheel}
                                                                        onChange={(e) => changeClaimValue(e, 'amount', clIndex)}
                                                                        onKeyDown={(e) => { handlePreventEKeyPress(e); changeClaimAdjFocus(e, clIndex, 'amount', claim_adjustment) }}
                                                                        onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, null, clIndex, 'claim_adjustments', 'amount') }}
                                                                        type="number"
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].amount`}
                                                                        placeholder="amt"
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.amount}
                                                                        onFocus={() => setClaimrevCodeFocus(true)}
                                                                        tabIndex={claimrevCodeFocus && formik.values?.claims[index]?.claim_adjustments[clIndex]?.amount == '' && pathname == DATA_ENTRY ? 0 : pathname == QA_REVIEW ? 0 : -1}
                                                                        className={renderClaimAdjustmentClass('amount', 'NUMBER', 'w-40 amount trigger-amount text-end', clIndex)}
                                                                    />

                                                                    <Overlay
                                                                        container={gridRef.current}
                                                                        rootClose={true}
                                                                        onHide={() => { setPcClaimPopup(false); setIsDrag(true) }}
                                                                        show={pcClaimPopup && propCodeClaimData?.adjustmentIndex == clIndex}
                                                                        target={currentClaimRef}
                                                                        containerPadding={100} placement="top"
                                                                    >
                                                                        {
                                                                            (props) => (
                                                                                <Popover className='pc-popover-popup' id="pc-popover-popup"  {...props}>
                                                                                    <Popover.Header className='p-1 ' style={{ fontSize: '1em', backgroundColor: "#6ba2b6" }} >
                                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                                            <span className='text-white fw-bold'> Create Prop Code</span>
                                                                                            <button className='btn text-white fw-bold' onClick={() => { setPcClaimPopup(false); setIsDrag(true) }}>&times;</button>
                                                                                        </div>
                                                                                    </Popover.Header>

                                                                                    <Popover.Body>
                                                                                        <FormikProvider value={formClaimRc}>
                                                                                            <Form
                                                                                                onSubmit={formClaimRc.handleSubmit}
                                                                                            >
                                                                                                <div className='row align-items-center'>

                                                                                                    <div className='col-lg-3'>
                                                                                                        <div className="mb-10">
                                                                                                            <Form.Group>

                                                                                                                <Form.Label htmlFor="exampleFormControlInput1" className=" form-label">Reason Code</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    type="text"
                                                                                                                    name='reason_code'
                                                                                                                    autoFocus
                                                                                                                    required
                                                                                                                    onChange={(e) => { formClaimRc.handleChange(e); setPcClaimFormik(e.target.value, index, clIndex) }}
                                                                                                                    value={formClaimRc.values?.reason_code}
                                                                                                                    isInvalid={!!formClaimRc.errors?.reason_code}
                                                                                                                    className="form-control-sm text-uppercase b-r-10 "
                                                                                                                    placeholder="Reason Code"
                                                                                                                />

                                                                                                                <Form.Control.Feedback type="invalid" >
                                                                                                                    {formClaimRc.errors && formClaimRc.errors?.reason_code &&
                                                                                                                        formClaimRc?.errors?.reason_code
                                                                                                                    }
                                                                                                                </Form.Control.Feedback>

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                    </div>


                                                                                                    <div className="mb-10 col-lg-3">
                                                                                                        <Form.Group>

                                                                                                            <Form.Label htmlFor="exampleFormControlInput1" className=" form-label">Description</Form.Label>

                                                                                                            <Form.Control
                                                                                                                type="text"
                                                                                                                name="description"
                                                                                                                required
                                                                                                                onChange={formClaimRc.handleChange}
                                                                                                                isInvalid={!!formClaimRc.errors?.description}
                                                                                                                value={formClaimRc.values?.description}
                                                                                                                className="form-control-sm text-uppercase b-r-10 "
                                                                                                                placeholder="Description"
                                                                                                            />
                                                                                                            <Form.Control.Feedback type="invalid" >
                                                                                                                {formClaimRc.errors && formClaimRc.errors?.description &&
                                                                                                                    formClaimRc?.errors?.description
                                                                                                                }
                                                                                                            </Form.Control.Feedback>
                                                                                                        </Form.Group>
                                                                                                    </div>

                                                                                                    <div className="mb-10 col-lg-2">
                                                                                                        <label htmlFor="exampleFormControlInput1" className=" form-label">CARC</label>

                                                                                                        <Form.Control
                                                                                                            className="form-control-sm text-uppercase b-r-10 "
                                                                                                            name="carc"
                                                                                                            value={formClaimRc.values?.carc}
                                                                                                            required
                                                                                                            isInvalid={!!formClaimRc.errors?.carc}
                                                                                                            // options={x12codes}
                                                                                                            placeholder="CARC"
                                                                                                            onChange={formClaimRc.handleChange}
                                                                                                        />
                                                                                                        <Form.Control.Feedback type="invalid" >
                                                                                                            {formClaimRc.errors && formClaimRc.errors?.carc &&
                                                                                                                formClaimRc?.errors?.carc
                                                                                                            }
                                                                                                        </Form.Control.Feedback>

                                                                                                    </div>
                                                                                                    <div className="mb-10 col-lg-2">
                                                                                                        <label htmlFor="exampleFormControlInput1" className=" form-label">RARC</label>

                                                                                                        <Form.Control
                                                                                                            className="form-control-sm text-uppercase b-r-10 "
                                                                                                            name="rarc"
                                                                                                            value={formClaimRc.values?.rarc}
                                                                                                            isInvalid={formClaimRc.errors?.rarc}
                                                                                                            placeholder="RARC"
                                                                                                            onChange={formClaimRc.handleChange}
                                                                                                        />
                                                                                                        <Form.Control.Feedback type="invalid" >
                                                                                                            {formClaimRc.errors && formClaimRc.errors?.rarc &&
                                                                                                                formClaimRc?.errors?.rarc
                                                                                                            }
                                                                                                        </Form.Control.Feedback>
                                                                                                    </div>
                                                                                                    <div className="mb-10 col-lg-2">
                                                                                                        <label htmlFor="exampleFormControlInput1" className=" form-label">GC</label>

                                                                                                        <Form.Control as="select"
                                                                                                            className="form-control-sm  b-r-10 "
                                                                                                            name="group_code"
                                                                                                            value={formClaimRc.values?.group_code}
                                                                                                            isInvalid={formClaimRc.errors?.group_code}
                                                                                                            placeholder="GC"
                                                                                                            onChange={formClaimRc.handleChange}
                                                                                                        >
                                                                                                            <option value="" >-Select-</option>
                                                                                                            <option value="CO">CO</option>
                                                                                                            <option value="OA">OA</option>
                                                                                                            <option value="PI">PI</option>
                                                                                                            <option value="PR">PR</option>
                                                                                                        </Form.Control>
                                                                                                        <Form.Control.Feedback type="invalid" >
                                                                                                            {formClaimRc.errors && formClaimRc.errors?.group_code &&
                                                                                                                formClaimRc?.errors?.group_code
                                                                                                            }
                                                                                                        </Form.Control.Feedback>
                                                                                                    </div>


                                                                                                </div>
                                                                                                <div className='d-flex flex-row justify-content-end'>


                                                                                                    <div className='col-lg-2 pt-4 text-end'>
                                                                                                        <button
                                                                                                            disabled={formClaimRc.isSubmitting}
                                                                                                            type='submit'
                                                                                                            className='btn btn-light btn-active-primary new-window'
                                                                                                        >
                                                                                                            Save
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>


                                                                                            </Form>
                                                                                        </FormikProvider>
                                                                                    </Popover.Body>
                                                                                </Popover>
                                                                            )
                                                                        }
                                                                    </Overlay>

                                                                    <Overlay
                                                                        container={gridRef.current}
                                                                        rootClose={true}
                                                                        show={x12ClaimCodes.open && clIndex == x12ClaimCodes.adjustmentIndex}
                                                                        onHide={() => { setx12ClaimCodes({ open: false, data: "" }); setIsDrag(true) }}
                                                                        target={currentClaimRef}
                                                                        placement="top"
                                                                    >
                                                                        {
                                                                            (props) => (
                                                                                <Popover className='pc-popover-pc' id="pc-popover-pc"  {...props}>
                                                                                    <Popover.Header className='p-1 ' style={{ fontSize: '1em', backgroundColor: "#6ba2b6" }} >
                                                                                        <div className='d-flex flex-row justify-content-between align-items-center'>
                                                                                            <span className='text-white fw-bold'> X12 Codes </span>
                                                                                            <button className='btn text-white fw-bold' onClick={() => { setx12ClaimCodes({ open: false, data: "" }); setIsDrag(true) }}>&times;</button>
                                                                                        </div>
                                                                                    </Popover.Header>

                                                                                    <Popover.Body style={{ padding: "0px", }}>
                                                                                        <div className='pc-height'>

                                                                                            <ul className="list-group list-group-horizontal">
                                                                                                <li className="list-group-item item-width-full-pc w-110px py-1">CARC</li>
                                                                                                <li className="list-group-item item-width-full-pc w-110px py-1">RARC</li>
                                                                                                <li className="list-group-item item-width-full-pc w-55px py-1" >GC</li>
                                                                                                <li className="list-group-item item-width-full-pc w-desc py-1" > DESC</li>
                                                                                                <li className="list-group-item item-width-full-pc w-5 py-1" style={{ width: "5%" }}> </li>
                                                                                            </ul>
                                                                                            {x12ClaimCodes.data &&
                                                                                                <React.Fragment>
                                                                                                    {x12ClaimCodes?.data?.length > 0 && x12ClaimCodes?.data?.map((code, xindex) => (
                                                                                                        <ul

                                                                                                            key={xindex} className={"list-group list-group-horizontal"}>
                                                                                                            <li className="list-group-item option-item-pc w-110px py-1 " >
                                                                                                                {code?.carc}
                                                                                                            </li>
                                                                                                            <li className="list-group-item option-item-pc w-110px py-1 " >
                                                                                                                {code?.rarc}
                                                                                                            </li>
                                                                                                            <li className="list-group-item option-item-pc w-55px  py-1 " >
                                                                                                                {code?.group_code}
                                                                                                            </li>

                                                                                                            <li className="list-group-item option-item-pc w-desc py-1 " >
                                                                                                                {code?.description}

                                                                                                            </li>

                                                                                                            <li className="list-group-item option-item-pc w-5 d-flex align-items-center " >
                                                                                                                <button onClick={() => { handleMultipleClaimPropCode(code, xindex, index, clIndex); }} className='btn btn-light btn-sm btn-icon btn-active-primary p-0 me-2'>

                                                                                                                    <BiChevronRight style={{ color: '#fff' }} />
                                                                                                                </button>

                                                                                                            </li>



                                                                                                        </ul>
                                                                                                    ))}
                                                                                                </React.Fragment>
                                                                                            }
                                                                                        </div>

                                                                                    </Popover.Body>
                                                                                </Popover>
                                                                            )
                                                                        }
                                                                    </Overlay>

                                                                    <Form.Control
                                                                        onChange={(e) => {
                                                                            setFormClaimRc(e.target.value);
                                                                            formik.handleChange(e)
                                                                        }}
                                                                        type="text"
                                                                        disabled={disableCorresField('claim_pc')}
                                                                        data-corress-disable={disableCorresField('claim_pc')}
                                                                        className={renderClaimAdjustmentClass('pc', 'TEXT', 'w-30', clIndex)}
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.pc}
                                                                        tabIndex={claimrevCodeFocus && formik.values?.claims[index]?.claim_adjustments[clIndex]?.pc == '' && pathname == DATA_ENTRY ? 0 : pathname == QA_REVIEW ? 0 : -1}
                                                                        onFocus={() => setClaimrevCodeFocus(true)}
                                                                        onBlur={() => setClaimrevCodeFocus(false)}
                                                                        ref={(el) => (claimRefPC.current[clIndex] = el)}
                                                                        onKeyDown={(e) => {
                                                                            handleClaimPayerPcVerify(e, index, clIndex)
                                                                            setCurrentClaimRef(e.target)
                                                                            changeClaimAdjFocus(e, clIndex, 'pc', claim_adjustment)
                                                                            setDefaultValueAssignedWorkClaimAdj(e, index, clIndex, 'pc')
                                                                        }}
                                                                        onDoubleClick={(e) => {
                                                                            handleDoubleClickClaimAdjustment(formik.values?.claims[index]?.claim_adjustments[clIndex], e, index, clIndex);
                                                                            setCurrentClaimRef(e.target)
                                                                        }}
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].pc`
                                                                        }
                                                                        placeholder="pc"
                                                                    />

                                                                    <Form.Control as="select"
                                                                        className={renderClaimAdjustmentClass('group_code', 'TEXT', 'w-select-60', clIndex)}
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.group_code}
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].group_code`}
                                                                        onChange={(e) => changeClaimGroupCode(e, clIndex)}
                                                                        onKeyDown={(e) => changeClaimAdjFocus(e, clIndex, 'group_code', claim_adjustment)}
                                                                        aria-label="Default select example"
                                                                        disabled={disableCorresField('claim_group_code')}
                                                                        data-corress-disable={disableCorresField('claim_group_code')}
                                                                        onFocus={() => setClaimrevCodeFocus(true)}
                                                                        onBlur={() => setClaimrevCodeFocus(false)}
                                                                        tabIndex={claimrevCodeFocus && formik.values?.claims[index]?.claim_adjustments[clIndex]?.group_code == '' && pathname == DATA_ENTRY ? 0 : pathname == QA_REVIEW ? 0 : -1}
                                                                    >
                                                                        <option value="" >-Select-</option>
                                                                        <option value="CO">CO</option>
                                                                        <option value="OA">OA</option>
                                                                        <option value="PI">PI</option>
                                                                        <option value="PR">PR</option>
                                                                    </Form.Control>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className={renderClaimAdjustmentClass('reason_code', 'TEXT', 'w-30', clIndex)}
                                                                        onChange={(e) => handleAmountFloat(e)}
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.reason_code}
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].reason_code`}
                                                                        onKeyDown={(e) => changeClaimAdjFocus(e, clIndex, 'reason_code', claim_adjustment)}
                                                                        placeholder="rc"
                                                                        disabled={disableCorresField('claim_reason_code')}
                                                                        data-corress-disable={disableCorresField('claim_reason_code')}
                                                                        onFocus={() => setClaimrevCodeFocus(true)}
                                                                        onBlur={() => setClaimrevCodeFocus(false)}
                                                                        tabIndex={claimrevCodeFocus && formik.values?.claims[index]?.claim_adjustments[clIndex]?.reason_code == '' && pathname == DATA_ENTRY ? 0 : pathname == QA_REVIEW ? 0 : -1}
                                                                    />

                                                                    <Form.Control
                                                                        type="text"
                                                                        className={renderClaimAdjustmentClass('rmk', 'TEXT', 'w-30-rmk', clIndex)}
                                                                        onChange={(e) => handleAmountFloat(e)}
                                                                        onKeyDown={(e) => changeClaimAdjFocus(e, clIndex, 'rmk', claim_adjustment)}
                                                                        value={formik.values?.claims[index]?.claim_adjustments[clIndex]?.rmk}
                                                                        name={`claims[${index}].claim_adjustments[${clIndex}].rmk`}
                                                                        placeholder="rmk"
                                                                        disabled={disableCorresField('claim_rmk')}
                                                                        data-corress-disable={disableCorresField('claim_rmk')}
                                                                        onFocus={() => setClaimrevCodeFocus(true)}
                                                                        onBlur={() => setClaimrevCodeFocus(false)}
                                                                        tabIndex={claimrevCodeFocus && formik.values?.claims[index]?.claim_adjustments[clIndex]?.rmk == '' && pathname == DATA_ENTRY ? 0 : pathname == QA_REVIEW ? 0 : -1}
                                                                    />

                                                                </div>
                                                            ))}


                                                        </div>
                                                        <div className='' role="button" >
                                                            <button tabIndex={pathname == DATA_ENTRY ? -1 : 0} type="button" className='btn btn-hidden-add h-100'
                                                                onDoubleClick={() => handleClaimAdjustment()}></button>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            </td>
                                        </React.Fragment>
                                        <td>
                                            <div className="text-center">
                                                <Form.Control
                                                    name={`claims[${index}].claim_cob`}
                                                    type="number"
                                                    onWheel={handleWheel}
                                                    onKeyDown={(e) => {
                                                        handlePreventEKeyPress(e)
                                                        changeClaimFocus(e, 'cob');
                                                    }}
                                                    onBlur={handleFloatValue}
                                                    onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, null, null, 'claim', 'claim_cob') }}
                                                    disabled={disableCorresField('claim_cob')}
                                                    data-corress-disable={disableCorresField('claim_cob')}
                                                    onChange={(e) => changeClaimValue(e, 'claim_cob')}
                                                    value={formik.values?.claims[index]?.claim_cob}
                                                    tabIndex={pathname == DATA_ENTRY ? -1 : 0}
                                                    className={renderClaimClass('claim_cob', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="cob"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-end">
                                                <Form.Control
                                                    onBlur={handleFloatValue}
                                                    onChange={(e) => changeClaimValue(e, 'claim_paid')}
                                                    name={`claims[${index}].claim_paid`}
                                                    type="number"
                                                    onDoubleClick={(e) => { __handleSLAssignBalanceValue(e, index, null, null, 'claim', 'claim_paid') }}
                                                    onWheel={handleWheel}
                                                    onKeyDown={(e) => { handlePreventEKeyPress(e); changeClaimFocus(e, 'paid') }}
                                                    value={formik.values?.claims[index]?.claim_paid}
                                                    tabIndex={pathname == DATA_ENTRY ? -1 : 0}
                                                    className={renderClaimClass('claim_paid', 'NUMBER', 'w-82 paid text-end')}
                                                    placeholder="paid"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-end me-2">
                                                {formik?.values?.claims[index]?.claim_balance ? parseFloat(formik?.values?.claims[index]?.claim_balance ? formik?.values?.claims[index]?.claim_balance : 0).toFixed(2) : parseFloat(0).toFixed(2)}
                                            </div>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Servicelines