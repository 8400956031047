import React from 'react'
import { useNavigate } from 'react-router'

const Njan = () => {

    const navigate = useNavigate()

    return (
        <div>
            {/* <button type onClick={() => navigate("/xtract ")}>asfdafsd</button> */}
        </div>
    )
}

export default Njan
