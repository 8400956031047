import { useFormik, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { createOrUpdatePayer, getPayerDropdown } from '../../../../../Redux/client/action';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { changeAllTitleFunction } from '../../../Dashboard/functions';
import { CLIENT } from '../../../../../routeNames';



const Payer = (props) => {
    // let { is_payer_lockbox } = useParams();
    const [data, setData] = useState('');
    const [state, setState] = useState({
        error: false,
        error_message: "",

    });
    const navigate = useNavigate()
    const { client_data, client, __toggleToast, setLoading, excluded_payers, fetchClientDetails } = props;

    const [selectedValues, SetSelectedValues] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)

    const payerformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            is_payer_lockbox: "",
            block_id: "",
            blocked_payer: [],
        },
        PayerSchema,
        // validate: (values) => {
        //     let errors = {}
        //     if(values.blocked_payer.length === 0 && values.blocked_payer.length < 1) {
        //         errors.blocked_payer = "Required"
        //     }
        //     return errors
        // },
        onSubmit: (values) => {
            setIsSubmit(true)
            setLoading(true)
            // console.log(props.client_id);
            let formData = new FormData(); //formdata object

            const blocked_payers = [];

            values?.blocked_payer?.map((val) => {
                blocked_payers.push(
                    val.value
                )
            })

            const block_id = values?.block_id ? values?.block_id : "";

            formData.append('blocked_by', client);
            formData.append('block_id', block_id);
            formData.append('blocked_payer', blocked_payers);
            // formData.append('blocked_payer', [values.blocked_payer?.value]);
            createOrUpdatePayer(client, block_id, blocked_payers)((response) => {
                // console.log(response, 'data');
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    // setState({ clientValidated: true })
                    // props.changeState(response?.data);
                    __toggleToast("success", response?.message, true)
                    setLoading(false)
                    setIsSubmit(false)
                    fetchClientDetails()

                } else {
                    const errors = response.response?.data?.errors;
                    payerformik.setErrors({ errors })
                    // props.changeState(response);
                    setLoading(false)
                    setIsSubmit(false)
                    __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                }
            });
        },
    });
    useEffect(() => {
        if (client_data?.excluded_payers) {

            payerformik.setValues({
                block_id: client_data?.excluded_payers?.block_id,
                blocked_Payer: client_data?.excluded_payers?.blocked_Payer
            })
        }
    }, [client_data?.excluded_payers])

    useEffect(() => {
        const selectedpayer = []
        if (excluded_payers) {
            excluded_payers?.blocked_Payer?.map((payer, index) => {
                selectedpayer.push(
                    payer,
                )
            })
            // console.log(selectedpayer, 'seeeeeeeeellllllllll');
            payerformik.setValues({
                blocked_payer: selectedpayer,
                block_id: excluded_payers?.block_id,
            })
        }

    }, [excluded_payers, client]);

    const PayerSchema = Yup.object().shape({
        // is_payer_lockbox: Yup.string()
        //     .required('Required'),
        // // blocked_payer: Yup.array()
        // //     .required('Required'),

    });

    const handleOnSelectChange = ({ name, value }) => {
        payerformik.setValues({ ...payerformik?.values, [name]: value.value })
        // setRoles(value.map((val) => val.value))
    }
    // const handleOnSelectMultiChange = ({ name, values }) => {
    //     payerformik.setValues({ ...payerformik?.values, [name]: value.value })
    //     setRoles(value.map((val) => val.value))
    // }
    const handleOnSelectMultiChange = (name, value) => {
        // console.log('====================================');
        // console.log(name.name, name.value.map((val) => val.value));
        // console.log('====================================');
        payerformik.setValues({ ...payerformik?.values, [name.name]: name.value.map((val) => val) })

        // SetSelectedValues(value.map((val) => val.value))
        // // console.log()
    }
    // const handleOnSelectMultiChange = (name, value) => {
    //     // console.log('====================================');
    //     // console.log(name.name, name.value.map((val) => val.value));
    //     // console.log('====================================');
    //     payerformik.setValues({ ...payerformik?.values, [name.name]: name.value.map((val) => val.value) })

    //     // SetSelectedValues(value.map((val) => val.value))
    //     // // console.log()
    // }
    const __handlePayerSubmit = (values) => {
        setIsSubmit(true)
        setLoading(true)
        // console.log(props.client_id);
        let formData = new FormData(); //formdata object

        const blocked_payers = [];

        values?.blocked_payer?.map((val) => {
            blocked_payers.push(
                val.value
            )
        })

        const block_id = values?.block_id ? values?.block_id : "";

        formData.append('blocked_by', client);
        formData.append('block_id', block_id);
        formData.append('blocked_payer', blocked_payers);
        // formData.append('blocked_payer', [values.blocked_payer?.value]);
        createOrUpdatePayer(client, block_id, blocked_payers)((response) => {
            // console.log(response, 'data');
            if (!response?.data?.status && response?.status && response?.status_code === 201) {
                // setState({ clientValidated: true })
                // props.changeState(response?.data);
                __toggleToast("success", response?.message, true)
                setLoading(false)
                setIsSubmit(true)
                fetchClientDetails()

            } else {
                const errors = response.response?.data?.errors;
                payerformik.setErrors({ errors })
                // props.changeState(response);
                setLoading(false)
                setIsSubmit(false)
                __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
            }
        });
    }

    //options
    const [payerFormatOptions, SetPayerFormatOptions] = useState([
        { value: "1", label: 'Yes' },
        { value: "0", label: 'No' },
    ]);
    const [payerIdOptions, SetpayerIdOptions] = useState([]);

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {payerformik.values?.blocked_payer?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}

            </components.DropdownIndicator>
        );
    };

    useEffect(() => {
        getPayerDropdown()((response) => {
            if (response?.status && response?.data && response?.data?.results) {
                SetpayerIdOptions(response?.data?.results)
                // payerformik.setValues({
                //     blocked_payer: response?.data?.results,
                // })
            }
        });

    }, []);
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    return (
        <React.Fragment>
            <Form
                onSubmit={payerformik.handleSubmit}
            >
                <div className="mb-3 row" >



                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Payer </Form.Label>
                            <Select
                                placeholder="Select an Option"

                                className="basic-single"
                                classNamePrefix="select"
                                name="blocked_payer"
                                value={payerformik.values?.blocked_payer}
                                //isValid={payerformik.touched?.blocked_payer && !payerformik?.errors?.errors?.blocked_payer}
                                //isInvalid={!!payerformik.errors?.errors?.blocked_payer}
                                //required
                                onFocus={() => setIsFocusedClient(true)}
                                onBlur={() => setIsFocusedClient(false)}
                                isMulti
                                components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client" /> }}
                                onChange={(e) => { handleOnSelectMultiChange({ name: 'blocked_payer', value: e }) }}
                                options={payerIdOptions}
                                styles={{
                                    container: (styles, state) => ({
                                        ...styles,
                                        borderRadius: "9px",
                                    }),
                                    control: (styles, state) => ({
                                        ...styles,
                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                        borderStyle: "none", boxShadow: "none",
                                        borderRadius: "10px",
                                        maxHeight: state.isFocused ? "auto" : "30px",
                                    }),
                                    indicatorSeparator: styles => ({
                                        ...styles, width: "0px", minHeight: "20px"
                                    }),

                                    valueContainer: (styles, state) => ({
                                        ...styles,
                                        maxHeight: isFocusedClient ? "auto" : "30px",
                                    }),
                                }}
                            />
                            {/* {!!payerformik.errors?.blocked_payer &&
                                                    <span className='text-danger'>{payerformik.errors?.blocked_payer}</span>
                                                } */}
                            {/* <Field
                                    className="custom-select"
                                    name="blocked_payer"
                                    options={payerIdOptions}
                                    isValid={payerformik.touched?.blocked_payer && !payerformik?.errors?.errors?.blocked_payer}
                                    isInvalid={!!payerformik.errors?.errors?.blocked_payer}
                                    required

                                    onChange={payerformik.handleChangeSelect}
                                    component={Select}
                                    placeholder="Select multi blocked_payer..."
                                    isMulti={true}
                                /> */}

                        </Form.Group>
                    </div>
                    {/* } */}




                </div>
                {/* <div>
                    {state.error &&
                        <span className='text-danger'>{state.error_message}</span>}
                </div> */}
                <div className='d-flex flex-row justify-content-end'>
                    <div className=''>
                        <button
                            type='button'
                            className='btn btn-secondary btn-active-primary me-2'
                            onClick={() => { changeAllTitleFunction('Client Setup', CLIENT) }}
                        >
                            Cancel
                        </button>
                    </div>
                    {client_data ? (checkUserPermission('edit_client') &&
                        <div className=''>
                            <button
                                disabled={isSubmit || !payerformik.isValid}
                                type='submit'
                                className='btn btn-primary btnHover'
                            >
                                {isSubmit ? "Updating" : 'Update'}
                            </button>
                        </div>
                    ) : (
                        <div className=''>
                            <button
                                disabled={isSubmit || !payerformik.isValid}
                                type='submit'
                                className='btn btn-primary btnHover'
                            >
                                {isSubmit ? "Saving" : 'Save'}
                            </button>
                        </div>
                    )
                    }
                </div>
            </Form>
        </React.Fragment>
    )
}

export default Payer;