import React from "react";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Badge from 'react-bootstrap/Badge';
import moment from "moment";


const MyWorkDataEntryReportModal = ({ toggleReportPopup, setToggleReportPopup, reportData, setReportData }) => {
    return (
        <Modal size="lg" show={toggleReportPopup} onHide={() => { setToggleReportPopup(false), setReportData([]) }} centered className='manual-tat'>
            <Modal.Header closeButton>
                <Modal.Title className="table-report-modelTitle" >Change Logs</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <Table >
                    <tbody>

                        <tr>

                            <th className="d-flex flex-row align-items-center  justify-content-around " >
                                <div className="flex-shrink-1">
                                    <span className="my-2 badge table-report-badge " >Previous Updated User</span>
                                </div>
                                <div className="flex-grow-1 ms-3">

                                    <span className="table-report-tr  " >{reportData?.created_user?.old}</span>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="d-flex flex-row align-items-center  justify-content-around ">
                                <div className="flex-shrink-1">
                                    <span className="my-2 badge table-report-badge">Previous Date</span>
                                </div>
                                <div className="flex-grow-1 ms-3">

                                    <span className="table-report-tr">{moment(reportData?.time?.old).format("MM-DD-YYYY HH:MM ss")}</span>
                                </div>

                            </th>
                        </tr>
                        <tr>
                            <th className="d-flex flex-row align-items-center  justify-content-around ">
                                <div className="flex-shrink-1">

                                    <span className="my-2 badge table-report-badge">Last Updated User</span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <span className="table-report-tr">{reportData?.created_user?.new}</span>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="d-flex flex-row align-items-center  justify-content-around ">
                                <div className="flex-shrink-1">

                                    <span className="my-2 badge table-report-badge">Last Date</span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <span className="table-report-tr ">{moment(reportData?.time?.new).format("MM-DD-YYYY HH:MM ss")}</span>
                                </div>
                            </th>
                        </tr>
                    </tbody>

                </Table>
                <table className="table history-log-table" >

                    <thead>
                        <tr >
                            <th >Field</th>
                            <th >Previous</th>
                            <th >Updated</th>
                        </tr>
                    </thead>
                    {reportData?.data?.map((report_data, index) =>
                        <tbody key={index}>
                            {report_data?.key &&
                                <tr>
                                    <td>{report_data?.key}</td>
                                    <td>{report_data?.old_value}</td>
                                    <td>{report_data?.new_value}</td>
                                </tr>
                            }
                            {report_data?.claims?.map((claim_data, index) =>
                                <>
                                    {claim_data?.patient_acc &&
                                        <tr>
                                            <th colSpan="3"><span className=" badge table-report-badge">Patient Account</span><p className="table-report-patient-account-text">{claim_data?.patient_acc} </p></th>
                                        </tr>
                                    }
                                    {claim_data?.patient_data && claim_data?.patient_data?.map((patient) =>
                                        <>
                                            {patient?.key && patient?.new_value && patient?.new_value != "" && patient?.old_value && patient?.old_value != "" || patient?.old_value == 0 &&
                                                <tr>
                                                    <td>{patient?.key}</td>
                                                    <td>{patient?.new_value}</td>
                                                    <td>{patient?.old_value}</td>
                                                </tr>
                                            }
                                            {patient?.serviceline && patient?.serviceline?.map((i) =>
                                                <>
                                                    <tr>
                                                        <th colSpan="3"><span className=" badge table-report-badge">Service Line</span> <p className="table-report-patient-account-text"> {i?.procedure_code}</p> </th>
                                                    </tr>
                                                    {i?.service_data && i?.service_data?.map((j) =>
                                                        <>
                                                            {j?.key && j?.new_value && j?.old_value &&

                                                                <tr>
                                                                    <td>{j?.key}</td>
                                                                    <td>{j?.new_value}</td>
                                                                    <td>{j?.old_value}</td>
                                                                </tr>
                                                            }
                                                            {j?.service_adjustment?.map((aduj_data) =>
                                                                <>
                                                                    {aduj_data?.key && aduj_data?.new_value && aduj_data?.old_value &&
                                                                        <tr>
                                                                            <td>{aduj_data?.key}</td>
                                                                            <td>{aduj_data?.new_value}</td>
                                                                            <td>{aduj_data?.old_value}</td>
                                                                        </tr>
                                                                    }
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}


                            {report_data?.claim_adjustment?.map((claim_aduj, index) =>
                                <tr>
                                    <td>{claim_aduj?.key}</td>
                                    <td>{claim_aduj?.new_value}</td>
                                    <td>{claim_aduj?.old_value}</td>
                                </tr>
                            )}

                            {report_data?.plb_adjustment?.map((plb_aduj, index) =>
                                <tr>
                                    <td>{plb_aduj?.key}</td>
                                    <td>{plb_aduj?.new_value}</td>
                                    <td>{plb_aduj?.old_value}</td>
                                </tr>
                            )}

                        </tbody>
                    )}

                </table>

            </Modal.Body>
        </Modal>

        // <Modal size="lg" show={toggleReportPopup} onHide={() => { setToggleReportPopup(false), setReportData([]) }} centered className='manual-tat'>
        //     <Modal.Header closeButton>
        //         <Modal.Title className="table-report-modelTitle">Change Logs</Modal.Title>
        //     </Modal.Header>
        //     <Modal.Body className="modal-body">
        //         <Table  >
        //             <tbody>
        //                 <tr className="table-report-tbody1" >
        //                     <th ><span className=" badge table-report-badge">Previous Updated User</span></th>
        //                     <td className="table-report-tr">Sajith</td>
        //                 </tr>
        //                 <tr>
        //                     <th><span className=" badge table-report-badge">Previous Date</span></th>
        //                     <td className="table-report-tr" >26-03-2023</td>
        //                 </tr>
        //                 <tr>
        //                     <th><span className=" badge table-report-badge">Last Updated User</span></th>
        //                     <td className="table-report-tr">raihan</td>
        //                 </tr>
        //                 <tr>
        //                     <th><span className=" badge table-report-badge">Last Date</span></th>
        //                     <td className="table-report-tr">29-03-2023</td>
        //                 </tr>
        //             </tbody>

        //         </Table>
        //         <table className="table history-log-table" >

        //             <thead >
        //                 <tr >

        //                     <th >Field</th>
        //                     <th >Previous</th>
        //                     <th >Updated</th>



        //                 </tr>
        //             </thead>

        //             <tbody >

        //                 <tr>
        //                     <td>sajith</td>
        //                     <td>kumar</td>
        //                     <td>age</td>
        //                 </tr>


        //                 <>

        //                     <tr>
        //                         <th colSpan="3"><span className=" badge table-report-badge">Patient Account</span><p className="table-report-patient-account-text">02547858</p></th>
        //                     </tr>


        //                     <>

        //                         <tr>
        //                             <td>Patient First Name</td>
        //                             <td>Santhosh</td>
        //                             <td>Ruben</td>
        //                         </tr>


        //                         <>
        //                             <tr>
        //                                 <th colSpan="3"><span className=" badge table-report-badge">Service Line</span><p className="table-report-patient-account-text"> 22568562</p></th>
        //                             </tr>

        //                             <>


        //                                 <tr>
        //                                     <td>Billed</td>
        //                                     <td>1250</td>
        //                                     <td>1520</td>
        //                                 </tr>


        //                                 <>

        //                                     <tr>
        //                                         <td>Discount</td>
        //                                         <td>500</td>
        //                                         <td>700</td>
        //                                     </tr>

        //                                 </>

        //                             </>

        //                         </>

        //                     </>

        //                 </>




        //                 <tr>
        //                     <td>Allowed</td>
        //                     <td>500</td>
        //                     <td>300</td>
        //                 </tr>



        //                 <tr>
        //                     <td>Deduct</td>
        //                     <td>1020</td>
        //                     <td>1125</td>
        //                 </tr>


        //             </tbody>


        //         </table>

        //     </Modal.Body>
        // </Modal>
    )


}
export default MyWorkDataEntryReportModal;




