import React, { useEffect, useState } from "react";
import CorresClaim from "./CorresClaim/corresClaim";
import { InputGroup, Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import { useFormik, FormikProvider, FieldArray } from "formik";
import Select from "react-select";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { handleWheel, handlePreventEKeyPress, preventWhiteSpaceValidate } from "../gridFunctions";
import { DbkField } from "../Elements/DbkField";
import { isActiveDbkField } from "../../../Dashboard/functions";
import { EDIT_WORK, QA_REVIEW, DATA_ENTRY } from "../../../../../routeNames";

const Correspondence = (props) => {
  const {
    job_id,
    log_id,
    jobData,
    formik,
    resetFormikData,
    setClaim835Focus,
    handleCorresspondenceData,
    currentPage,
    setClaimCorresIndex,
    claimCorresIndex,
    corresPondanceFocus,
    setCorresPondanceFocus,
    handleFocusCorresPondance,
    isDbkPermission,
    isPayerPage,
    pdfLoaded

  } = props;

  const [options, setOptions] = useState(
    jobData?.correspondence_categories ? jobData?.correspondence_categories : []
  );

  const handleClaimPagination = (value, e) => {

    if (value < 1 || value == 0) {
      setClaimCorresIndex(0);
    } else if (value <= formik.values?.claims?.length) {
      setClaimCorresIndex(value - 1);
    } else if (value > formik.values?.claims?.length) {
    }

  };
  const handleBackspace = (e) => {
    if (e.keyCode == 8) {
      e.preventDefault();
      e.target.select();
    }
  }

  const setDefaultValueAssignedWorkClaim = async (event, index) => {
    if (event.ctrlKey == true && event.keyCode == 32) {
      const claims = [...formik.values?.claims]
      if (
        event.target.name === `claims[${index}].patient_account_number` &&
        jobData?.default?.account_number
      ) {
        claims[index] = {
          ...claims[index],
          patient_account_number: jobData?.default?.account_number,
          dbk_patient_account_number: jobData?.default?.account_number,
        };
      }
      if (
        event.target.name === `claims[${index}].patient_first_name` &&
        jobData?.default?.first_name
      ) {
        claims[index] = {
          ...claims[index],
          patient_first_name: jobData?.default?.first_name,
          dbk_patient_first_name: jobData?.default?.first_name,
        };
      }
      if (
        event.target.name === `claims[${index}].patient_last_name` &&
        jobData?.default?.last_name
      ) {
        claims[index] = {
          ...claims[index],
          patient_last_name: jobData?.default?.last_name,
          dbk_patient_last_name: jobData?.default?.last_name,
        };
      }
      if (
        event.target.name === `claims[${index}].period_start` &&
        jobData?.default?.date_of_service
      ) {
        let date = moment(jobData?.default?.date_of_service).format(
          "MM/DD/YYYY"
        );

        claims[index] = {
          ...claims[index],
          period_start: date,
          dbk_period_start: date,
        };
      }
      if (
        event.target.name === `claims[${index}].period_end` &&
        jobData?.default?.date_of_service
      ) {
        let date = moment(jobData?.default?.date_of_service).format(
          "MM/DD/YYYY"
        );

        claims[index] = {
          ...claims[index],
          period_end: date,
          dbk_period_end: date,
        };
      }
      if (
        event.target.name === `claims[${index}].billed` &&
        jobData?.default?.charge
      ) {
        claims[index] = {
          ...claims[index],
          billed: parseFloat(jobData?.default?.charge).toFixed(2),
          dbk_billed: parseFloat(jobData?.default?.charge).toFixed(2),
        };
      }

      formik.setValues({
        ...formik.values,
        claims: claims,
      });
    }
  };
  useEffect(() => {
    handleCorresspondenceData();
  }, [jobData]);
  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  const qaCorrectedData = gridData?.qa_corrected_data;

  const handleChangeFromTo = async (e) => {
    if (!e.target.disabled) {
      await formik.setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: currentPage + 1,
        }
      })
    }
  }
  const renderClass = (name, type, defaultClass) => {
    if (formik.errors?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' && moment(formik.values?.[name]).format("MM/DD/YYYY") != moment(gridData?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' && formik.values?.[name]?.toUpperCase() !=
        gridData?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'STRING' && formik.values?.[name] !=
        gridData?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' && parseFloat(formik.values?.[name] ? formik.values?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.[name] ?
          gridData?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }

      else if (gridData?.transaction_type != "CORRESPONDENCE") {
        return defaultClass + ' qa-field'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.[name]) {
      return defaultClass + ' qa-field'
    }
    else {
      return defaultClass;
    }
  }

  return (
    <React.Fragment>
      <FormikProvider value={formik}>
        <Form autoComplete={"off"} noValidate onReset={resetFormikData}>
          <div
            className="topblock-wrapper correspondence_details accordion-collapse collapse show"
            id="collapseOne"
            aria-labelledby="headingOne"
            data-bs-parent="#payment835"
          >
            <div className="sub-section sub-job-eob">
              <div className="">
                <Form.Label
                  tabIndex="-1"
                  type="button"
                  className="btn btn-payment835"
                >
                  Correspondence
                </Form.Label>
              </div>
              <div className="payer-sender-wrapper">
                <InputGroup>
                  <InputGroup.Text>PayerName/Sender</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      data-prevent-shift-tab="true"
                      type="text"
                      onChange={(event) => {
                        const e = preventWhiteSpaceValidate(event)
                        formik.handleChange(e)
                      }}
                      className={renderClass('payer_name', 'TEXT', 'w-78')}
                      value={formik.values?.payer_name}
                      tabIndex={formik.values?.payer_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                      onFocus={() => {
                        handleFocusCorresPondance("payer_name", "main", null, isDbkPermission?.correspondence?.correspondence_payer_name),
                          setClaim835Focus(true);
                      }}
                      name="payer_name"
                      disabled={isActiveDbkField(corresPondanceFocus, formik, isDbkPermission?.correspondence?.correspondence_payer_name) ? !corresPondanceFocus.payer_name : false}
                    />

                    {isDbkPermission?.correspondence
                      ?.correspondence_payer_name &&
                      formik.errors?.payer_name === "Dont Match" &&
                      corresPondanceFocus.payer_name ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="text"
                        fieldName="payer_name"
                        fieldNameDbk="dbk_payer_name"
                        setCorresPondanceFocus={setCorresPondanceFocus}
                        corresPondanceFocus={corresPondanceFocus}
                      />

                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="checkdate-wi">
                <InputGroup>
                  <InputGroup.Text className="input-group-text">
                    Letter Date
                  </InputGroup.Text>
                  {/* <Form.Control type="text" className="w-48" name="email" placeholder="mm/dd/yyyy" /> */}
                  <div className="dbk-main-container">
                    <InputMask
                      mask="99/99/9999"
                      className={renderClass('letter_date', 'DATE', 'dbk-date w-48')}
                      onChange={formik.handleChange}
                      onFocus={() => {
                        handleFocusCorresPondance("letter_date", "main", null, isDbkPermission?.correspondence?.correspondence_letter_date),
                          setClaim835Focus(true);
                      }}
                      value={formik.values?.letter_date}
                      tabIndex={formik.values?.letter_date != '' && formik.values?.letter_date != '__/__/____' && pathname == DATA_ENTRY ? -1 : 0}
                      name="letter_date"
                      placeholder={"mm/dd/yyyy"}
                      disabled={isActiveDbkField(corresPondanceFocus, formik, isDbkPermission?.correspondence?.correspondence_letter_date) ? !corresPondanceFocus.letter_date : false}

                    />
                    {formik?.values?.letter_date !=
                      jobData?.grid_data?.letter_date &&
                      isDbkPermission?.correspondence
                        ?.correspondence_letter_date &&
                      formik.errors?.letter_date === "Dont Match" &&
                      corresPondanceFocus.letter_date ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="date"
                        fieldName="letter_date"
                        fieldNameDbk="dbk_letter_date"
                        setCorresPondanceFocus={setCorresPondanceFocus}
                        corresPondanceFocus={corresPondanceFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="paymentmethod-wi">
                <div className="input-group">
                  <label className="input-group-text">
                    Correspondence Category
                  </label>
                  <Form.Control
                    as="select"
                    onChange={formik.handleChange}
                    className={renderClass('correspondance', 'STRING', 'selc-bg-1')}
                    name="correspondance"
                    value={formik.values?.correspondance}
                    tabIndex={formik.values?.correspondance != '' && pathname == DATA_ENTRY ? -1 : 0}
                    onFocus={() => setClaim835Focus(true)}
                    aria-label="Default select example"
                  >
                    <option value="">---select---</option>
                    {options &&
                      options.map((option, index) => (
                        <option key={index} value={option?.value}>
                          {option?.label}
                        </option>
                      ))}
                  </Form.Control>
                </div>



              </div>
              {isPayerPage &&
                <div className='pagefrom-corress'>
                  <div className="input-group">
                    <label className="input-group-text">
                      Page From
                    </label>
                    <Form.Control
                      data-disabled={!pdfLoaded}
                      className={renderClass('payer_page_from', 'NUMBER', 'pagefrom')}
                      onChange={formik.handleChange}
                      min={1}
                      type="number"
                      onClick={(e) => {
                        handleChangeFromTo(e);
                      }}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                      }}
                      onFocus={(e) => {
                        handleChangeFromTo(e);
                      }}
                      value={formik.values?.payer_page_from}
                      name={`payer_page_from`}
                    />
                  </div>
                </div>
              }
              {isPayerPage &&
                <div className='pageto-corress'>
                  <div className="input-group">
                    <label className="input-group-text">
                      Page To
                    </label>
                    <Form.Control
                      data-disabled={!pdfLoaded}
                      className={renderClass('payer_page_to', 'NUMBER', 'pageto')}
                      onChange={formik.handleChange}
                      min={1}
                      type="number"
                      onClick={(e) => {
                        handleChangeFromTo(e);
                      }}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                      }}
                      onFocus={(e) => {
                        handleChangeFromTo(e);
                      }}
                      value={formik.values?.payer_page_to}
                      name={`payer_page_to`}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
          <FieldArray
            name="claims"
            render={(arrayHelpers) => (
              <React.Fragment>
                <CorresClaim
                  claimCorresIndex={claimCorresIndex}
                  setClaimCorresIndex={setClaimCorresIndex}
                  handleClaimPagination={handleClaimPagination}
                  handleBackspace={handleBackspace}
                  handleWheel={handleWheel}
                  arrayHelpers={arrayHelpers}
                  setDefaultValueAssignedWorkClaim={setDefaultValueAssignedWorkClaim}
                  {...props}
                />
              </React.Fragment>
            )}
          />

          <button
            type="reset"
            tabIndex="-1"
            className="d-none"
            id="submit-correspondance-reset"
          ></button>
        </Form>
      </FormikProvider>
    </React.Fragment>
  );
};

export default Correspondence;
