import React, { useState } from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { Formik, useFormik } from 'formik';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import * as Yup from 'yup';
import { createPayee, getFacilityDropdown, getPayerPayeeDefaultvalue } from '../../../../Redux/user/action';
import { useNavigate } from 'react-router-dom';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import { useEffect } from 'react';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import { PAYEE } from '../../../../routeNames';
import styled from 'styled-components';

const CreatePayee = () => {
    const navigate = useNavigate()
    const [defaultData, setDefaultData] = useState(null)
    const [clients, setClients] = useState([])
    const [facilities, setFacilities] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [selectedFacility, setSelectedFacility] = useState([])
    const [state, setState] = useState({
        showToast: false,
        error: '',
        error_message: "",
    })
    const [toaster, setToaster] = useState(null)
    const fetchDefaultvalue = (clientId) => {
        getPayerPayeeDefaultvalue(clientId)((response) => {
            if (response?.status) {
                setDefaultData(response?.data)
            }
        })
    }
    useEffect(() => {

        getAllClientDropdown()((response) => {
            if (response?.status) {


                setClients(response?.data)
            }
        })

    }, []);
    useEffect(() => {
        if (formik?.values?.clients) {
            getFacilityDropdown([formik?.values?.clients])((response) => {
                if (response && response.status) {
                    setFacilities(response.data.facilities?.map(result => { return { options: result?.facilities, label: result?.client } }))
                }
            });
        }

    }, [selectValue])
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            payee_id: "",
            entity_identifier_code: "",
            id_code_qualifier: "",
            payee_identification: "",
            name: "",
            address: "",
            city: "",
            state: "",
            zip_code: "",
            country: "",
            country_subdivision_code: "",
            clients: "",
            facility: ""
        },
        validationSchema: Yup.object().shape({
            clients: Yup.string()
                .required('Required'),
            payee_id: Yup.string()
                .min(1, ' At least 1 character')
                .required('Required'),
            entity_identifier_code: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            id_code_qualifier: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            payee_identification: Yup.string()
                .matches(/^[0-9]+$/, "Only digits allowed")
                .min(10, 'Only 10 digits')
                .max(10, 'Only 10 digits')
                .required('Required'),
            name: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            address: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            entity_identifier_code: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            city: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            state: Yup.string()
                .matches(/^[A-Za-z]+$/, 'Invalid State Abbrevation')
                .min(2, 'Only two characters')
                .max(2, 'Only two characters')
                .required('Required'),
            zip_code: Yup.string()
                .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{5}\d{4}$)/, 'Invalid Zip Code')
                .required('Required'),
            country: Yup.string()
                .matches(/^[a-zA-Z ]*$/, 'Invalid Country Name')
                .min(1, 'At least 1 character'),
            country_subdivision_code: Yup.string()
                .min(1, 'At least 1 character'),

        }),
        onSubmit: (values) => {
            const RedirectId = getRedirectTabId()
            // __handleCreatePayee(initialValues)
            let formData = new FormData();    //formdata object

            formData.append('payee_id', values.payee_id.toUpperCase());   //append the values with key, value pair
            formData.append('data_from', 1);
            formData.append('entity_identifier_code', values.entity_identifier_code.toUpperCase());
            formData.append('id_code_qualifier', values.id_code_qualifier.toUpperCase());
            formData.append('payee_identification', values.payee_identification);
            formData.append('name', values.name.toUpperCase());
            formData.append('address', values.address.toUpperCase());
            formData.append('city', values.city.toUpperCase());
            formData.append('state', values.state.toUpperCase());
            formData.append('zip_code', values.zip_code.toUpperCase());
            formData.append('country', values.country.toUpperCase());
            formData.append('country_subdivision_code', values.country_subdivision_code.toUpperCase());
            formData.append('client', values.clients);
            // if (values?.clients && values?.clients?.length > 0) {

            //     values?.clients?.map((client) => {
            //         formData.append('client', client);
            //     })
            // }

            if (values?.facility && values?.facility?.length > 0) {

                values?.facility?.map((data) => {
                    formData.append('facility', data);
                })
            }

            createPayee({ formData })((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    //setState({ showToast: !state.showToast })
                    setToaster('success')
                    setTimeout(() => {
                        changeAllTitleFunction("Payee", PAYEE, RedirectId)
                    }, 3000);
                } else {
                    formik.setErrors({
                        payee_id: response?.response?.data?.errors?.payee_id,
                        entity_identifier_code: response?.response?.data?.errors?.entity_identifier_code,
                        id_code_qualifier: response?.response?.data?.errors?.id_code_qualifier,
                        payee_identification: response?.response?.data?.errors?.payee_identification,
                        name: response?.response?.data?.errors?.payee,
                        address: response?.response?.data?.errors?.address,
                        city: response?.response?.data?.errors?.city,
                        state: response?.response?.data?.errors?.state,
                        zip_code: response?.response?.data?.errors?.zip_code,
                        country: response?.response?.data?.errors?.country,
                        country_subdivision_code: response?.response?.data?.errors?.country_subdivision_code,
                    })
                    // // console.log(error)
                    setState({ showToast: !state.showToast, error: 'danger', error_message: 'Duplicate Entry!' })
                    // setToaster('error')
                    setTimeout(() => {
                        setToaster('')

                    }, 2000);
                }
            });

        },
    });


    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {formik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };

    const options = [
        // { value: "all", label: "All" },
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];

    const __toggleToast = () => {
        setState({ showToast: !state.showToast })

    }
    const __toggleError = () => {
        setState({ showToast: !state.showToast })

    }
    const handleSelectChange = (selected) => {

        // let selectedIds = [];
        // let selectedOptions = [];

        // if (selected && selected?.length > 0) {

        //     selected?.map((option) => {
        //         selectedIds.push(option.value)
        //         selectedOptions.push(option)
        //     })
        //     if (selected?.length > 1) {
        //         setSelectedFacility([])
        //         setFacilities([])

        //     }
        // }
        // else {
        //     selectedOptions = [];
        //     selectedIds = [];
        //     setFacilities([])
        //     setSelectedFacility([])
        // }
        formik.setValues({
            ...formik.values, "clients": selected.value, "facility": []
        });
        setSelectValue(selected ?? "")
        setSelectedFacility([])
        fetchDefaultvalue(selected.value)

    }

    const handleFacilityChange = (selected) => {
        formik.setFieldValue("facility", selected.map((option) => option?.value));
        setSelectedFacility(selected)


    };

    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);





    return (
        <React.Fragment>

            <AdminMaster ToastMessage={toaster} />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={state.error} onClose={__toggleToast} show={state.showToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{state.error_message}</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1 className='text-uppercase'>Create Payee</h1>

                </div>
                <MainContainer className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">


                                    <Form
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="mb-3 row" >

                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold' >Payee ID</Form.Label>

                                                    <Form.Control
                                                        name='payee_id'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.payee_id && !formik.errors.payee_id}
                                                        isInvalid={!!formik.errors.payee_id}
                                                        value={formik.values.payee_id}
                                                        className='b-r-10 form-control-solid  text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Payee Id"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.payee_id}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold' >Entity Identifier Code</Form.Label>

                                                    <Form.Control
                                                        name='entity_identifier_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.entity_identifier_code && !formik.errors.entity_identifier_code}
                                                        isInvalid={!!formik.errors.entity_identifier_code}
                                                        value={formik.values.entity_identifier_code}
                                                        className='b-r-10 form-control-solid  text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Entity Identifier Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.entity_identifier_code}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>ID Code Qualifier</Form.Label>
                                                    <Form.Control
                                                        name='id_code_qualifier'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.id_code_qualifier && !formik.errors.id_code_qualifier}
                                                        isInvalid={!!formik.errors.id_code_qualifier}
                                                        value={formik.values.id_code_qualifier}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="ID Code Qualifier"
                                                        onKeyUp={(e) => {
                                                            if (e.ctrlKey && e.key === " ") {
                                                                defaultData?.default_payee_code_qualifier && formik?.values?.clients && formik.setValues({
                                                                    ...formik.values,
                                                                    id_code_qualifier: defaultData?.default_payee_code_qualifier,
                                                                })
                                                            }
                                                        }}
                                                    />

                                                    <Form.Control.Feedback type="invalid">{formik.errors.id_code_qualifier}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Payee Identification</Form.Label>
                                                    <Form.Control
                                                        name='payee_identification'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.payee_identification && !formik.errors.payee_identification}
                                                        isInvalid={!!formik.errors.payee_identification}
                                                        value={formik.values.payee_identification}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength={10}
                                                        placeholder="Payee Identification"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.payee_identification}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Name</Form.Label>
                                                    <Form.Control
                                                        name='name'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.name && !formik.errors.name}
                                                        isInvalid={!!formik.errors.name}
                                                        value={formik.values.name}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Name"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Address</Form.Label>
                                                    <Form.Control
                                                        name='address'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.address && !formik.errors.address}
                                                        isInvalid={!!formik.errors.address}
                                                        value={formik.values.address}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Address"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.address}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>City</Form.Label>
                                                    <Form.Control
                                                        name='city'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.city && !formik.errors.city}
                                                        isInvalid={!!formik.errors.city}
                                                        value={formik.values.city}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="City"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>State</Form.Label>
                                                    <Form.Control
                                                        name='state'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.state && !formik.errors.state}
                                                        isInvalid={!!formik.errors.state}
                                                        value={formik.values.state}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength={2}
                                                        placeholder="State"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.state}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Zip Code</Form.Label>
                                                    <Form.Control
                                                        name='zip_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.zip_code && !formik.errors.zip_code}
                                                        isInvalid={!!formik.errors.zip_code}
                                                        value={formik.values.zip_code}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength={10}
                                                        placeholder="Zip Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.zip_code}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Country</Form.Label>
                                                    <Form.Control
                                                        name='country'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.country && !formik.errors.country}
                                                        isInvalid={!!formik.errors.country}
                                                        value={formik.values?.country}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Country"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.country}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Country Subdivision Code</Form.Label>
                                                    <Form.Control
                                                        name='country_subdivision_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.country_subdivision_code && !formik.errors.country_subdivision_code}
                                                        isInvalid={!!formik.errors.country_subdivision_code}
                                                        value={formik.values.country_subdivision_code}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder="Country Subdivision Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.country_subdivision_code}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-5'>
                                                <div className="mb-10">

                                                    <Form.Label className='fw-bold' >Clients</Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        // isMulti
                                                        value={selectValue}
                                                        name="clients"
                                                        options={options}
                                                        components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select Clients..."
                                                        onFocus={() => setIsFocusedClient(true)}
                                                        onBlur={() => setIsFocusedClient(false)}
                                                        styles={{
                                                            container: (styles, state) => ({
                                                                ...styles,
                                                                borderRadius: "9px",
                                                            }),
                                                            control: (styles, state) => ({
                                                                ...styles,
                                                                backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                borderStyle: "none", boxShadow: "none",
                                                                borderRadius: "10px",
                                                                maxHeight: state.isFocused ? "auto" : "30px",
                                                            }),
                                                            indicatorSeparator: styles => ({
                                                                ...styles, width: "0px", minHeight: "20px"
                                                            }),

                                                            valueContainer: (styles, state) => ({
                                                                ...styles,
                                                                maxHeight: isFocusedClient ? "auto" : "30px",
                                                            }),
                                                            placeholder: (styles) => ({
                                                                ...styles,
                                                                textTransform: 'uppercase' // Change placeholder text to uppercase
                                                            })
                                                        }}
                                                    />

                                                    {!!formik.errors?.clients &&
                                                        <span className='text-danger'>{formik.errors?.clients}</span>
                                                    }
                                                </div>
                                            </div>

                                            <div className='col-lg-6'>
                                                <FacilityDiv className="mb-10">
                                                    <Form.Group>
                                                        <Form.Label className='fw-bold' >Facility</Form.Label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isMulti
                                                            value={selectedFacility}
                                                            name="facility"
                                                            options={facilities}
                                                            components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="facility" /> }}
                                                            onChange={handleFacilityChange}
                                                            placeholder="Select Facility..."
                                                            isDisabled={selectValue.length > 1 ? true : false}
                                                            onFocus={() => setIsFocusedFacility(true)}
                                                            onBlur={() => setIsFocusedFacility(false)}
                                                            styles={{
                                                                container: (styles, state) => ({
                                                                    ...styles,
                                                                    borderRadius: "9px",
                                                                }),
                                                                control: (styles, state) => ({
                                                                    ...styles,
                                                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                    borderStyle: "none", boxShadow: "none",
                                                                    borderRadius: "10px",
                                                                    maxHeight: state.isFocused ? "auto" : "30px",
                                                                }),
                                                                indicatorSeparator: styles => ({
                                                                    ...styles, width: "0px", minHeight: "20px"
                                                                }),

                                                                valueContainer: (styles, state) => ({
                                                                    ...styles,
                                                                    maxHeight: isFocusedFacility ? "auto" : "30px",
                                                                }),
                                                                placeholder: (styles) => ({
                                                                    ...styles,
                                                                    textTransform: 'uppercase' // Change placeholder text to uppercase
                                                                })


                                                            }}
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facility}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </FacilityDiv>
                                            </div>

                                        </div>
                                        {/* <div>
                                            {state.error &&
                                                <span className='text-danger'>{state.error_message}</span>}
                                        </div> */}
                                        <div className='d-flex flex-row justify-content-end'>

                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='text-uppercase btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("Payee", PAYEE) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className=''>
                                                <button
                                                    type='submit'
                                                    className='text-uppercase btn btn-primary btnHover'
                                                    disabled={!formik.isValid}
                                                >
                                                    Create Payee
                                                </button>
                                            </div>
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </MainContainer>


            </main>
        </React.Fragment >
    )
}

export default CreatePayee;


const MainContainer = styled.div`
    label{
        text-transform:uppercase
    }
`
const FacilityDiv = styled.div`

`