import React from 'react'
import Modal from "react-modal";
import Select, { components } from 'react-select';
import { useState } from 'react';
import { TbGridDots, TbHandGrab } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import { Form } from 'react-bootstrap'
import styled from 'styled-components/macro';

const QaErrorModal = ({ options, setQaErrorModal, setIsDrag, formik, closeModal }) => {

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {filterState && filterState[state] && filterState[state].length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    const [isFocusedAllowedValues, setIsFocusedAllowedValues] = useState(false);
    const [filterState, setFilterState] = useState([{
        qa_error_type: [],
    }])

    return (
        <section>

            <TopHeadPassword>
                <div >
                    <Form.Label className='fw-bold clr-heading fs-5 ' >Error Description</Form.Label>
                </div>
                <div>
                    <span onClick={() => { closeModal() }} className='close-icon'><i className="bi bi-x-square"></i></span>
                </div>


            </TopHeadPassword>
            <Form onSubmit={formik.handleSubmit}>
                <div className='row'>
                    <div className='col-lg-12 mb-3'>
                        <Select
                            isMulti
                            autoFocus={true}
                            tabSelectsValue={false}
                            closeMenuOnSelect={false} // Keep the menu open after selecting an option
                            options={options?.length > 0 ? options : []}
                            className="select-search input-focus-shadow"
                            components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="qa_error_type" /> }}
                            classNamePrefix="select"
                            placeholder="Error Type"
                            menuPlacement={'top'}
                            name="qa_error_type"
                            onFocus={() => setIsFocusedAllowedValues(true)}
                            onBlur={() => { setIsFocusedAllowedValues(false) }}
                            onChange={(e) => {
                                setFilterState({ qa_error_type: e })
                                formik.setValues({
                                    ...formik.values,
                                    qa_error_type: e?.map(i => i.value)
                                })
                            }}
                            value={options?.filter(i => formik?.values?.qa_error_type.includes(i.value))}
                            styles={{
                                container: (styles, state) => ({
                                    ...styles,
                                    borderRadius: "9px",
                                }),
                                control: (styles, state) => ({
                                    ...styles,
                                    backgroundColor: '#f5f8fa',
                                    borderStyle: "none", boxShadow: "none",
                                    borderRadius: "10px",
                                    maxHeight: state.isFocused ? "auto" : "30px",
                                }),
                                indicatorSeparator: styles => ({
                                    ...styles, width: "0px",
                                    minHeight: "20px"
                                }),
                                placeholder: (styles) => ({
                                    ...styles,
                                    color: 'black', // Change the color to your desired color
                                    fontSize: '1rem'

                                }),
                                valueContainer: (styles, state) => ({
                                    ...styles,
                                    maxHeight: isFocusedAllowedValues ? "auto" : "30px",
                                }),
                            }} />
                        {/* {formik.errors && formik.errors?.qa_error_type &&
                            <span className='text-danger'>  {formik?.errors?.qa_error_type}</span>
                        } */}
                    </div>
                    <div className='col-lg-12'>
                        <div>
                            <Form.Group>
                                <Form.Control
                                    as="textarea"
                                    name={`comment`}
                                    onChange={(e) => {
                                        formik.setValues({
                                            ...formik.values,
                                            qa_error_type_comment: e.target.value
                                        })
                                    }}
                                    className="form-control form-control-solid b-r-10"
                                    placeholder="Comments"
                                    style={{ height: '100px' }}

                                />
                            </Form.Group>
                        </div>
                    </div>

                </div>
                <div className='d-flex justify-content-end text-right mt-2'>
                    <button
                        type='submit'
                        className='qa-error-modal-btnHover btn btn-primary btnHover new-window col-lg-2'
                    >
                        Submit
                    </button>

                </div>
            </Form>
        </section>


    )
}

export default QaErrorModal

const TopHeadPassword = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:'center';
    flex-direction: row;
    margin-bottom:10px;
    span.close-icon{
        cursor: pointer;
    }
`;