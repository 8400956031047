import React, { useMemo, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { GET_MANUAL_FILE_SPLIT_URL } from '../../../api';
import AdminMaster from '../../../Layout/admin/Master';
import datatableCustomStyles from '../../../Layout/Elements/DatatableCustomStyles';
import PdfViewer from './PdfViewer/index';
import axiosInstance from './../../../axiosInstance';
import { Button, OverlayTrigger } from 'react-bootstrap';
import { BiReset, BiSearch } from 'react-icons/bi';
import { IoIosSwitch } from 'react-icons/io';
import { getFileSplitFetchData } from '../../../Redux/fileSplit/action';
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { SINGLE_SPLIT } from '../../../routeNames';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { getAllClientDropdown } from '../../../Redux/client/action';
import { getFacilityDropdown } from '../../../Redux/user/action';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import { Tooltip } from '@mui/material';
import { changeAllTitleFunction } from '../Dashboard/functions';


const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
var input = {
    client: '',
    file: ''
};


// const showTooltip = (text, props) => (
//     <Tooltip id="button-tooltip" {...props}>
//         {text}
//     </Tooltip>
// );
const FileSplit = () => {
    const [state, setState] = useState({
        s_no: true,
        client: true,
        facility: true,
        file: true,
        external_tat: true,
        internal_tat: true,
        file_size: true,
        status: true,
        comment: true,
        file_received: true,
        actions: true,
        type: true,

    });
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [pageno, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [filterText, setFilterText] = React.useState('');
    const [colorRow, setColorRow] = React.useState({});
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [data, setData] = useState({})
    const [limit, setLimit] = useState(100)
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [selectValue, setSelectValue] = useState([])
    const [selectedFacility, setSelectedFacility] = useState([])
    const [selectedStatus, setSelectedStatus] = useState([])
    const [selectedType, setSelectedType] = useState([])
    const [facilities, setFacilities] = useState([])
    const [clients, setClients] = useState([])
    const [defaultValues, setDefaultValues] = useState({})
    const [checking, setChecking] = useState(true)
    const [searchedData, setSearchedData] = useState({

        name: "",
        clients: "",
        status: "",
        facility: "",
        type: "",
    })

    const statuses = [
        { value: 'received', label: 'NEW' },
        { value: 'inprocess', label: 'IN PROCESS' },


    ]
    const typeOptions = [
        { value: 'FILE', label: 'Files' },
        { value: 'JOB', label: 'Jobs' },


    ]

    const handleDblClickCopy = async (filename) => {
        navigator.clipboard.writeText(filename)

    };

    useEffect(() => {
        fileSplitFetchData(1, limit)
    }, []);
    const handleNewSingleTab = async (name, url) => {
        let new_tab = {
            id: 0,
            route: `${window.location.origin}` + url,
            // route: `${window.location.origin}` + EDIT_WORK + 1278 +'?log_id='+20208,
            label: name,
            prevTabs: [],
            isNewTab: false,
            isCurrentTab: true,
            isSplitTab: true,
            title: name
        }
        // window.parent.localStorage.setItem('bbtabs', JSON.stringify(data))
        window.parent.localStorage.setItem('newTab', JSON.stringify(new_tab))

        parent.document.getElementById('bottom-tab-add').click()
    }
    const [disabled, setDisabled] = useState(false);

    const columns = useMemo(
        () => [


            {
                id: 'id',
                name: 'S.NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                grow: 0,
                width: "60px"
                //omit: !state.s_no,

            },
            {
                id: 'name',
                name: 'Client',
                selector: row => row?.name,
                cell: (row) => <div>
                    {row?.name &&
                        <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                            <Tooltip disableInteractive title={row?.name} placement='top' arrow>

                                <span className='text-truncate' onDoubleClick={() => handleDblClickCopy(row?.name)}>{row?.name}</span>
                            </Tooltip>
                        </div>
                    }
                </div>
                ,
                sortable: true,
                wrap: true,
                width: "160px",
                grow: 0.1,
                reorder: true,
                omit: !state.client,
                ignoreRowClick: false,

            },
            {
                id: 'facility',
                name: 'Facility',
                selector: row => row?.facility,
                cell: (row, index) =>
                    <div>
                        {row?.facility &&
                            <div onClick={() => handleRowClick(row)} className='d-flex flex-row justify-content-start flex-wrap text-truncate ' style={{ width: '120px' }}>
                                <Tooltip disableInteractive title={row?.facility} placement='top' arrow>
                                    {row?.facility &&
                                        <span key={index} className='text-truncate' onDoubleClick={() => handleDblClickCopy(row?.facility)}>{row?.facility}</span>
                                    }
                                </Tooltip>
                            </div>
                        }
                    </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "160px",
                grow: 0.1,
                omit: !state.facility,
            },

            {
                id: 'file',
                name: 'Job File Name',
                cell: (row) =>
                    <React.Fragment>
                        {row?.file &&
                            <div
                                className='d-flex flex-row  flex-wrap text-truncate '
                                style={{ maxWidth: "120px" }}
                                onDoubleClick={() => handleDblClickCopy(row?.file)}>
                                <Tooltip disableInteractive title={row?.file} placement='top' arrow>
                                    <span className='text-truncate' style={{ color: '#6ba2b6 ' }} onClick={() => handleRowClick(row)}>{row?.file}</span>
                                </Tooltip>
                            </div>
                        }
                    </React.Fragment>,
                selector: row => row?.file,
                sortable: true,
                left: true,
                wrap: true,
                width: "160px",
                reorder: true,
                grow: 0.2,
                omit: !state.file,

            },

            {
                id: 'internal_tat',
                name: 'Internal TAT',
                cell: (row) => moment(row.internal_tat).format('M/DD/YY, H:mm'),
                selector: row => row.internal_tat,
                sortable: true,
                left: true,
                wrap: true,
                //width: "100px",
                reorder: true,
                // grow: 0.1,
                omit: !state.internal_tat,

            },
            {
                id: 'file_size',
                name: 'File Size',
                selector: row => row?.file_size,
                sortable: true,
                left: true,
                wrap: true,
                // width: "60px",
                //grow: 0.1,
                reorder: true,
                omit: !state.file_size,

            },
            {
                id: 'status',
                name: 'Status',
                selector: row => row?.status,
                cell: (row) => <span className='text-truncate ' style={{ width: "120px" }} onClick={() => handleRowClick(row)}> {row?.status}</span>,
                sortable: true,
                left: true,
                wrap: true,
                //width: "120px",
                reorder: true,
                //grow: 0.1,
                omit: !state.status,

            },
            {
                id: 'comment',
                name: 'Comment',
                selector: row => row?.comment,
                cell: (row) =>
                    <React.Fragment>
                        {row?.comment &&
                            <div
                                className='d-flex flex-row  flex-wrap text-truncate '
                                style={{ maxWidth: "120px" }}
                            >
                                <Tooltip disableInteractive title={row?.comment} placement='top' arrow>
                                    <span className='text-truncate text-uppercase' style={{ color: '#6ba2b6 ' }}>{row?.comment}</span>
                                </Tooltip>
                            </div>
                        }
                    </React.Fragment>,
                sortable: true,
                left: true,
                wrap: true,
                width: "160px",
                reorder: true,
                //grow: 0.1,
                omit: !state.comment,

            },
            {
                id: 'file_received',
                name: 'FILE RECEIVED DATE & TIME',
                cell: (row) => moment(row.file_received).format('M/DD/YY, H:mm'),
                selector: row => row.file_received,
                sortable: true,
                left: true,
                wrap: true,
                //grow: 0.1,
                width: '150px',
                reorder: true,
                omit: !state.file_received,

            },
            {
                id: 'type',
                name: 'Type',
                cell: (row) => row?.type,
                selector: row => row?.type,
                sortable: true,
                left: true,
                wrap: true,
                //grow: 0.1,
                width: '100px',
                reorder: true,
                omit: !state.type,

            },
            {
                id: 'action',
                name: 'Action',
                omit: !state.actions,
                cell: (row) =>
                    <div className=" " >

                        {/* {row?.status == "Assigned" ? (
                            <React.Fragment>
                                <span className='badge badge-light-danger'>

                                    {row?.staff?.username}
                                </span>
                            </React.Fragment>
                        ) : (*/}
                        <React.Fragment>

                            {/* {row?.status != "In Process" && */}
                            <div className="" >
                                {checkUserPermission('allow_split') &&
                                    <div
                                        onClick={(e) => {
                                            if (e.ctrlKey) {
                                                handleNewSingleTab("File Split", SINGLE_SPLIT + row.file_id)
                                            } else {
                                                if (!disabled) {
                                                    // Disable the button
                                                    setDisabled(true);
                                                    changeAllTitleFunction("File Split", SINGLE_SPLIT + row.file_id)
                                                    setTimeout(() => {
                                                        setDisabled(false);
                                                    }, 1000); // Adjust the delay as needed
                                                }


                                            }
                                        }}

                                        className="btn btn-sm fs-7 text-white" style={{ backgroundColor: "#6ba2b6" }}
                                    >
                                        Split
                                    </div>

                                }

                            </div>
                            {/* } */}

                        </React.Fragment>
                        {/* // )} */}
                    </div>,

                button: false,
                right: true,
            },

        ],
        [state, pageno, disabled],
    );

    // const searchTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Search
    //     </Tooltip>
    // );
    // const resetTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Reset
    //     </Tooltip>
    // );
    // const HeadingsTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Table Headings
    //     </Tooltip>
    // );
    useEffect(() => {
        //getManualSplits(1, limit, sortColumn, sortOrder);
        // get client data
        getAllClientDropdown()((response) => {
            if (response?.status) {
                setClients(response?.data)
            }
        })
    }, []);

    useEffect(() => {
        if (selectValue && selectValue.length > 0) {
            getFacilityDropdown(selectValue?.map((value) => value))((response) => {
                if (response && response.status) {
                    setFacilities(response.data.facilities?.map((result) => { return { options: result?.facilities, label: result?.client } }))
                }
            });
        }

    }, [selectValue])

    useEffect(() => {

        if (!!checking && defaultValues?.client) {

            setState({
                client: defaultValues?.client,
                comment: defaultValues?.comment,
                facility: defaultValues?.facility,
                file: defaultValues?.file,
                file_size: defaultValues?.file_size,
                file_received: defaultValues?.file_received,
                internal_tat: defaultValues?.internal_tat,
                status: defaultValues?.status,
                actions: defaultValues?.action,
                type: defaultValues?.type,


            })
            setChecking(false)
        }

    }, [defaultValues]);

    const handleSort = async (column, order) => {
        setSortColumn(column.id);
        setSortOrder(order);
        getManualSplits(1, limit, column, order);


    };
    const fileSplitFetchData = (page, newPerPage, name, clients, facility, status, type) => {
        setLoading(true)
        getFileSplitFetchData({ page, newPerPage, name, clients, facility, status, type })((response) => {
            setData(response?.data);
            setLimit(newPerPage)
            setPageNo(page)
            setDefaultValues(response?.data?.results?.default_values);
            setTotalRows(response?.data?.count);
            setLoading(false);
        })
    }

    // const getManualSplits = async (page, page_limit, order, column) => {
    //     setLoading(true);

    //     setLimit(limit);

    //     const response = await axiosInstance.get(GET_MANUAL_FILE_SPLIT_URL + "?page=" + page + "&limit=" + page_limit + "&sort_order=" + order + "&sort_key=" + column + "&search=" + filesplitformik.values.name + "&client=" + filesplitformik.values.clients + "&facility=" + filesplitformik.values.facility + "&status=" + filesplitformik.values.status);
    //     setData(response?.data);
    //     setLimit(limit);
    //     setTotalRows(response?.data?.data?.count);
    //     setLoading(false);
    // };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        fileSplitFetchData(1, newPerPage, searchedData?.name, searchedData?.clients, searchedData?.facility, searchedData?.status, searchedData?.type)
        setPageNo(1)
        setLimit(newPerPage)
        handleResetDataTable()
        setLoading(false);


    };

    const handlePageChange = (page) => {
        setPageNo(page)
        //getManualSplits(page, limit, sortColumn, sortOrder);
        fileSplitFetchData(page, limit, searchedData?.name, searchedData?.clients, searchedData?.facility, searchedData?.status, searchedData?.type)
    };

    const searchData = async ({ name, clients, facility, status, type }) => {
        // console.log(values);
        // const response = await axiosInstance.get(GET_MANUAL_FILE_SPLIT_URL + "?limit=" + limit + "&search=" + values.name + "&client=" + values.clients + "&facility=" + values.facility + "&status=" + values.status);
        // setData(response?.data);
        // setTotalRows(response?.data?.data?.count);
        fileSplitFetchData(1, limit, name, selectValue, selectedFaci, selectedStatus, selectedType)
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;

        }
    }

    var i = 0;


    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.file_id == row.file_id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.file_id == row.file_id ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];

    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);

    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.data.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
        // // console.log(row);
    };
    const filesplitformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            clients: "",
            status: "",
            facility: "",
            type: "",

        },
        // validationSchema,
        onSubmit: (values) => {
            searchData(values)

            setSearchedData({
                name: values?.name ? values?.name : "",
                clients: values?.clients ? values?.clients : "",
                status: values?.status ? values?.status : "",
                facility: values?.facility ? values?.facility : "",
                type: values?.type ? values?.type : "",
            })
            handleResetDataTable()

        },
    });

    const options = [
        // { value: "all", label: "All" },
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];
    const [selectedFaci, setSetlectedFaci] = useState();
    const handleSelectChange = (selected, name) => {
        let selectedIds = [];
        let selectedOptions = [];

        if (selected && selected?.length > 0) {

            selected.map((option) => {
                // if (option.value !== 'all') {
                selectedIds.push(option.value)
                selectedOptions.push(option)
                // }
            })
            if (selected?.length > 1) {
                setSelectedFacility([])
                setSetlectedFaci()
                setSearchedData({
                    ...searchedData,
                    facility: ""
                })
            }
        }
        else {
            selectedOptions = [];
            selectedIds = [];
            setFacilities([])
            setSelectedFacility([])
        }
        filesplitformik.setValues({ ...filesplitformik.values, "clients": selectedIds, "facility": selected?.length > 1 ? "" : selectedFaci });
        setSelectValue(selectedIds)

    };



    const handleFacilitySelectChange = (selected, name) => {
        filesplitformik.setValues({ ...filesplitformik.values, "facility": selected.map((option) => option?.value) });


        setSelectedFacility(selected)
        setSetlectedFaci(selected.map((option) => option?.value))

    };
    const handleStatusSelectChange = async (selected, name) => {
        let selectedIds = [];
        let selectedOptions = [];
        // if (selected.some((option) => option.value === 'all')) {
        if (selected && selected?.length > 0) {
            selected.map((option) => {
                // if (option.value !== 'all') {
                selectedIds.push(option.value)
                selectedOptions.push(option)
                // }
            })
        }

        filesplitformik.setValues({ ...filesplitformik.values, [name]: selectedIds });

        setSelectedStatus(selectedIds)

    };
    const handleTypeSelectChange = async (selected, name) => {
        filesplitformik.setValues({ ...filesplitformik.values, [name]: selected?.value });
        setSelectedType(selected?.value)
    };

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {filesplitformik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };


    // const handleFacilityChange = (selected) => {
    //     filesplitformik.setValues({ ...filesplitformik.values, "facility": selected.map((option) => option?.value) });
    //     setSelectedFacility(selected)


    // };
    // const handleStatusesChange = (selected) => {
    //     filesplitformik.setValues({ ...filesplitformik.values, "status": selected.map((option) => option?.value) });
    //     setSelectedStatus(selected)


    // };
    return (
        <React.Fragment>
            <AdminMaster />
            <main id="main" className="main">


                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>File Split</h1>
                </div>
                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                {/* <div className='card-header pt-3'>
                                    <div className="d-flex align-items-center  position-relative ">
                                        <FilterComponent onFilter={(e) => handleSearchSplits(e.target.value)} onClear={handleClear} filterText={filterText} />
                                    </div>
                                </div> */}
                                <Form onSubmit={filesplitformik.handleSubmit} onReset={() => {
                                    // filesplitformik.setValues({
                                    //     name: "",
                                    //     client: "",
                                    //     status: "",
                                    // });
                                    window.location.reload()
                                    // fetchQueueListing(pageno, limit)
                                }}>
                                    <div className=" ">
                                        <div className="d-flex flex-row justify-content-start align-items-center flex-wrap gap-2 py-4 px-4">
                                            <div className='jobmonitor-select'>
                                                <Form.Control
                                                    id="search"
                                                    className="form-control form-control-solid fileManagePadding b-r-10"
                                                    onChange={filesplitformik.handleChange}
                                                    name="name"
                                                    type="search"
                                                    //style={{ width: "250px" }}
                                                    //style={{ width: '220px', fontSize: "14px",borderRadius: "9px!important", height: "38px", paddingTop: "8px !important"}}
                                                    placeholder="File Name "
                                                    value={filesplitformik.values?.name}
                                                    style={{ fontSize: "17px" }}
                                                />
                                            </div>
                                            <div className='jobmonitor-select'>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isMulti
                                                    values={selectValue}
                                                    name="clients"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                    options={options}
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    onChange={(e) => handleSelectChange(e, "clients")}
                                                    defaultValue={filesplitformik?.values?.clients}
                                                    placeholder="Select Client"
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),
                                                    }} />

                                                {!!filesplitformik.errors?.clients &&
                                                    <span className='text-danger'>{filesplitformik.errors?.clients}</span>
                                                }
                                            </div>
                                            <div className='jobmonitor-select'>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isMulti
                                                    value={selectedFacility}
                                                    isDisabled={selectValue.length > 1 ? true : false}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="facility" /> }}
                                                    name="facility"
                                                    options={facilities}
                                                    onFocus={() => setIsFocusedFacility(true)}
                                                    onBlur={() => setIsFocusedFacility(false)}
                                                    onChange={(selected) => handleFacilitySelectChange(selected, "facility")}
                                                    placeholder="Select Facility"
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedFacility ? "auto" : "30px",
                                                        }),
                                                    }} />
                                            </div>
                                            <div className='jobmonitor-select'>

                                                <Select
                                                    className=" select-search-client"
                                                    classNamePrefix="select"
                                                    isMulti
                                                    values={selectedStatus}
                                                    name="status"
                                                    options={statuses}
                                                    onChange={(selected) => handleStatusSelectChange(selected, "status")}
                                                    placeholder="Select Status"
                                                    styles={{
                                                        control: styles => ({ ...styles, backgroundColor: "hsl(204deg 33.33% 97.06%)", borderStyle: "none", boxShadow: "none", borderRadius: "10px" }), placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }), indicatorSeparator: styles => ({ ...styles, width: "0px", minHeight: "20px" }), container: styles => ({ ...styles, borderRadius: "9px" })
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">{filesplitformik.errors?.status}</Form.Control.Feedback>

                                            </div>
                                            <div className='jobmonitor-select'>

                                                <Select
                                                    className=" select-search-client"
                                                    classNamePrefix="select"
                                                    // isMulti
                                                    isClearable={true}
                                                    values={selectedType}
                                                    name="type"
                                                    options={typeOptions}
                                                    onChange={(selected) => handleTypeSelectChange(selected, "type")}
                                                    placeholder="Select Type"
                                                    styles={{
                                                        control: styles => ({ ...styles, backgroundColor: "hsl(204deg 33.33% 97.06%)", borderStyle: "none", boxShadow: "none", borderRadius: "10px" }), placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }), indicatorSeparator: styles => ({ ...styles, width: "0px", minHeight: "20px" }), container: styles => ({ ...styles, borderRadius: "9px" })
                                                    }}
                                                />
                                                <Form.Control.Feedback type="invalid">{filesplitformik.errors?.type}</Form.Control.Feedback>

                                            </div>


                                            <div className='d-flex justify-content-start gap-2'>

                                                <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                    {/* <Button className="btn btn-light btn-active-primary" type="submit">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiSearch />
                                                        </span>

                                                    </Button> */}
                                                    <div className="">
                                                        <button className="btn btn-light btn-active-primary pb-2 " type="submit" aria-expanded="false">
                                                            <span className="svg-icon svg-icon-3">
                                                                <BiSearch />
                                                            </span>
                                                        </button>
                                                    </div>
                                                </Tooltip>
                                                <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                    {/* <Button className="btn btn-light btn-active-primary" type="reset">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiReset />
                                                        </span>

                                                    </Button> */}
                                                    <div className="">
                                                        <button className="btn btn-light btn-active-primary pb-2 " type="reset" aria-expanded="false">
                                                            <span className="svg-icon svg-icon-3">
                                                                <BiReset />
                                                            </span>
                                                        </button>
                                                    </div>
                                                </Tooltip>

                                                <Tooltip disableInteractive title="Table Headings" placement='top' arrow>
                                                    <div className="dropdown ">
                                                        <button className="btn btn-light btn-active-primary pb-2 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <IoIosSwitch />
                                                        </button>
                                                        <ul className="dropdown-menu hide-scroll-bar" style={{ height: '350px', overflowX: 'scroll' }} onClick={(e) => e.stopPropagation()}>
                                                            {/* <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.s_no} onChange={() => setState({ ...state, s_no: !state.s_no })} type="checkbox" id="client" />
                                                                        <label className="form-check-label" htmlFor="client">
                                                                            S.NO
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li> */}
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.client} onChange={() => setState({ ...state, client: !state.client })} type="checkbox" id="client" />
                                                                        <label className="form-check-label" htmlFor="client">
                                                                            Client
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.facility} onChange={() => setState({ ...state, facility: !state.facility })} type="checkbox" id="facility" />
                                                                        <label className="form-check-label" htmlFor="facility">
                                                                            Facility
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.file} onChange={() => setState({ ...state, file: !state.file })} type="checkbox" id="file" />
                                                                        <label className="form-check-label" htmlFor="file">
                                                                            Job File Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.internal_tat} onChange={() => setState({ ...state, internal_tat: !state.internal_tat })} type="checkbox" id="internal_tat" />
                                                                        <label className="form-check-label" htmlFor="internal_tat">
                                                                            Internal Tat
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.file_size} onChange={() => setState({ ...state, file_size: !state.file_size })} type="checkbox" id="file_size" />
                                                                        <label className="form-check-label" htmlFor="file_size">
                                                                            file size
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.status} onChange={() => setState({ ...state, status: !state.status })} type="checkbox" id="status" />
                                                                        <label className="form-check-label" htmlFor="status">
                                                                            Status
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.comment} onChange={() => setState({ ...state, comment: !state.comment })} type="checkbox" id="comment" />
                                                                        <label className="form-check-label" htmlFor="comment">
                                                                            Comment
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.file_received} onChange={() => setState({ ...state, file_received: !state.file_received })} type="checkbox" id="file_received" />
                                                                        <label className="form-check-label" htmlFor="file_received">
                                                                            File Received Date & Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.type} onChange={() => setState({ ...state, type: !state.type })} type="checkbox" id="type" />
                                                                        <label className="form-check-label" htmlFor="type">
                                                                            Type
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.actions} onChange={() => setState({ ...state, actions: !state.actions })} type="checkbox" id="actions" />
                                                                        <label className="form-check-label" htmlFor="actions">
                                                                            Actions
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </Tooltip>




                                            </div>

                                        </div>

                                    </div>
                                </Form>

                                <div className="card-body" style={{ padding: "0px 2.25rem" }}>
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}
                                                    data={data?.results?.data}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    pointerOnHover={true}
                                                    responsive
                                                    // subHeader
                                                    // subHeaderComponent={subHeaderComponentMemo}
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}

                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </main>
        </React.Fragment>
    );
}

export default FileSplit;
