
import { Provider } from "react-redux";
import React, { createContext, useEffect, useState } from "react";
import store from "./Redux/store.js";
export const ContextData = createContext();

// const MyContext = React.createContext(null)

// Export your custom hooks if you wish to use them in other files.
// export const useStore = createStoreHook(MyContext)
// export const useDispatch = createDispatchHook(MyContext)
// export const useSelector = createSelectorHook(MyContext)

// const myStore = createStore(rootReducer)
// app.use(cors()) // Use this after the variable declaration



const Root = ({ children, initialState = {} }) => (

    <React.Fragment>

        <Provider store={store(initialState)}>
            {children}
        </Provider>
    </React.Fragment>
);
export default Root;

// function Root({ children, initialState = {} }) {
//     const currentPath = String(window.location.pathname).split('/')


//     const defaultLink = `${window.location.origin}/admin/dashboard`

//     const [constextState, setStateContext] = useState({
//         noOfTabs: 5,
//         bottomTabs:[{id:1,route:defaultLink,label:currentPath.pop()}],
//         currentTab:{id:1,route:defaultLink,label:currentPath.pop()}
//         // i: 0,
//         // tabCount: 0,
//     })  
    
//     return (
//         <ContextData.Provider
//             value={{
//             context: [constextState, setStateContext],
//             }}
//         >
//             <Provider store={store(initialState)}>
//                 {children}
//             </Provider>
//         </ContextData.Provider>
//     );
//   }
  
// export default Root;