import React, { useEffect, useState } from 'react'
import Master from '../../../../Layout/admin/Master/index'
import { Toast, ToastContainer } from 'react-bootstrap';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { createReasonCodes, getPayerDropdown, getRoles, getRolesDropdown, getX12CodeDropdown } from '../../../../Redux/user/action';
import { createRejectReason, getRejectReasonDetails } from '../../../../Redux/fileSplit/action';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { EDIT_REJECT_REASONS, REJECT_REASONS } from './../../../../routeNames';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';


const CreateRejectReason = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [roles, setRoles] = useState([])
    const [data, setData] = useState([]);
    const [toaster, setToaster] = useState(false)
    const [state, setState] = useState({
        error: false,
        error_message: ""
    })
    const [options, setOptions] = useState([]);
    const validationSchema = Yup.object().shape({
        rejection_reason: Yup.string()
            .required().matches(/([a-zA-Z])/, 'Enter a valid Reason'),
        reason_level: Yup.string()
            .required(),
        // roles: Yup.array()
        //     .min(1, 'Please select at least one tag')
        //     .of(Yup.number().min(1).max(100))
        //     .required('Required'),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            rejection: null,
            rejection_reason: '',
            reason_level: '',
            roles: [],

        },
        validationSchema,
        onSubmit: (values) => {
            __submitForm(values)
        },
    });
    // debugger
    console.log(useParams())
    useEffect(() => {
        getRolesDropdown()((response) => {
            if (response?.status) {

                // setOptions(response?.data);
                response?.data?.forEach((item) => {
                    options.push({ value: item.id, label: item.name })
                })
            }
        });

        if (id && id >= 0) {
            getRejectReasonDetails(id)((response) => {
                if (response?.status && response?.data) {
                    setData(response?.data);
                    // setRoles(response?.data?.group?.roles);
                    setRoles(response?.data?.staff_roles?.map((val) => val.value))
                    formik.setValues({
                        ...formik.values,
                        rejection_reason: response?.data?.rejection_reason,
                        rejection: response?.data?.id,
                        reason_level: response?.data?.rejection_reason_level,
                        roles: response?.data?.staff_roles,

                    })
                }
            });
        }

    }, [id]);
    const __submitForm = (values) => {
        let formData = new FormData();
        // formData.append('rejection_reason', values.rejection_reason)
        // formData.append('reason_level', values.reason_level)
        // formData.append('roles', roles)
        // if (values?.rejection)
        //     formData.append('rejection', values.rejection)
        if (roles.length) {
            const RedirectId = getRedirectTabId()
            createRejectReason(values?.rejection, values?.rejection_reason, values?.reason_level, roles)((response) => {
                // // console.log(response?.response?.data?.errors?.name, '===========================>');
                if (response?.status) {
                    setToaster('success')
                    const timer = setTimeout(() => {
                        setToaster(false)
                        changeAllTitleFunction("Rejection Reasons", REJECT_REASONS, RedirectId)

                    }, 2000);
                    return () => clearTimeout(timer);
                }
                else {
                    formik.setErrors({
                        rejection_reason: response?.response?.data?.errors?.rejection_reason,
                        // rejection: response?.data?.id,
                        // reason_level: response?.data?.rejection_reason_level,
                        // roles: response?.data?.staff_roles,
                    })
                    // setToaster('error')
                    const timer = setTimeout(() => {
                        setToaster(false)
                    }, 2000);
                    return () => clearTimeout(timer);

                }
            });
        }
        else {
            formik.setErrors({ ...formik.errors, roles: 'required', })
        }
    }
    const handleOnSelectChange = ({ name, value }) => {
        formik.setValues({ ...formik.values, [name]: value.value })
        setRoles(value.map((val) => val.value))
    }


    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {roles?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}
            </components.DropdownIndicator>
        );
    };

    const [isFocusedRoles, setIsFocusedRoles] = useState(false);

    return (
        <React.Fragment>
            <Master />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Failed</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1>{id ? 'Edit Rejection Reason' : 'Create Rejection Reason'}</h1>

                </div>

                <section className="section mt-5">
                    <div className="card card-flush py-4">

                        <div className="card-body pt-0 ">


                            <Form
                                onSubmit={formik.handleSubmit}
                            >
                                <div className='row'>

                                    <div className='col-lg-5'>
                                        <div className="mb-10">
                                            <Form.Group>

                                                <Form.Label htmlhtmlFor="exampleFormControlInput1" className="required form-label">Rejection Reason</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name='rejection_reason'
                                                    required
                                                    value={formik.values?.rejection_reason}
                                                    isValid={formik.touched.rejection_reason && !formik?.errors?.rejection_reason}
                                                    isInvalid={!!formik.errors?.rejection_reason} onChange={formik.handleChange}
                                                    className="form-control form-control-solid b-r-10"
                                                    placeholder="Rejection Reason"
                                                />
                                                <Form.Control.Feedback type="invalid">{formik?.errors?.rejection_reason}</Form.Control.Feedback>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <Form.Group className="mb-3" >
                                            <Form.Label className='fw-bold'>Reason level</Form.Label>
                                            <Form.Select
                                                name='reason_level'
                                                onChange={formik.handleChange}
                                                isValid={formik.touched.reason_level && !formik.touched.reason_level}
                                                isInvalid={!!formik.errors?.reason_level}
                                                value={formik.values?.reason_level}
                                                className='b-r-10 form-control-solid form-control'
                                                maxLength="255"
                                                required
                                                placeholder="Select Reason level"
                                            >
                                                <option >Select an Option</option>
                                                <option value="file_level">File Level</option>
                                                <option value="Job_level">Job Level</option>

                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">{formik.errors?.reason_level}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-5'>
                                        <div className="mb-10">
                                            <label htmlhtmlFor="exampleFormControlInput1" className="required form-label">Roles</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isMulti
                                                value={formik.values.roles}
                                                name="roles"
                                                options={options}
                                                onFocus={() => setIsFocusedRoles(true)}
                                                onBlur={() => setIsFocusedRoles(false)}
                                                components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="roles" /> }}
                                                onChange={(e) => { handleOnSelectChange({ name: 'roles', value: e }) }}
                                                placeholder="Select Roles..."
                                                styles={{
                                                    container: (styles, state) => ({
                                                        ...styles,
                                                        borderRadius: "9px",
                                                    }),
                                                    control: (styles, state) => ({
                                                        ...styles,
                                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                        borderStyle: "none", boxShadow: "none",
                                                        borderRadius: "10px",
                                                        maxHeight: state.isFocused ? "auto" : "30px",
                                                    }),
                                                    indicatorSeparator: styles => ({
                                                        ...styles, width: "0px", minHeight: "20px"
                                                    }),

                                                    valueContainer: (styles, state) => ({
                                                        ...styles,
                                                        maxHeight: isFocusedRoles ? "auto" : "30px",
                                                    }),
                                                }}
                                            />
                                            {formik.errors?.roles &&
                                                <span className='text-danger'>
                                                    {formik.errors?.roles}
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {state.error &&
                                    <div className='text-center mt-3'>

                                        <span className='text-danger fw-bold '>{state.error_message}</span>
                                    </div>
                                }
                                <div className=' mt-2 d-flex flex-row justify-content-end'>
                                    <div className=''>
                                        <button
                                            type='button'
                                            className='btn btn-secondary btn-active-primary me-2'
                                            onClick={() => { changeAllTitleFunction("Rejection Reasons", REJECT_REASONS) }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    {checkUserPermission('edit_reject_reasons') &&
                                        <div className=''>

                                            {window.location.pathname == EDIT_REJECT_REASONS + id ? (
                                                <React.Fragment>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-light btn-active-primary new-window'
                                                    >
                                                        Update
                                                    </button>
                                                </React.Fragment>

                                            ) : (
                                                <React.Fragment>
                                                    <button
                                                        type='submit'
                                                        className='btn btn-primary btnHover new-window'
                                                        disabled={!formik.isValid}
                                                    >
                                                        Submit
                                                    </button>
                                                </React.Fragment>
                                            )}

                                        </div>
                                    }
                                </div>
                            </Form>

                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default CreateRejectReason;