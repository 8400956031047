import React, { useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { handleKeyPress, handleWheel, handlePreventEKeyPress, preventWhiteSpaceValidate } from "../gridFunctions";
import InputMask from "react-input-mask";
import { DbkField } from "../Elements/DbkField";
import { isActiveDbkField } from "../../../Dashboard/functions";
import { EDIT_WORK, QA_REVIEW, DATA_ENTRY } from "../../../../../routeNames";
import moment from "moment";

const PatientPay = (props) => {
  const {
    formik,
    jobData,
    patientPayFocus,
    setPatientPayFocus,
    handleFocusPatientPay,
    isDbkPermission,
    handlePatientPayData,
    log_id,
    job_id,
    isPatientPageFromTo,
    pdfLoaded,
    isViewOnllyGrid,
    currentPage,
    setDefaultValueMainGrid
  } = props;

  useEffect(() => {
    handlePatientPayData();
  }, [jobData]);

  const handleAmountFloat = (event) => {
    const value = event.target.value;

    if (value.includes(".") && value.split(".")[1].length > 2) {
      return;
    }

    formik.handleChange(event);
  };
  const setDefaultValueAssignedWork = (event) => {
    if (event.ctrlKey == true && event.keyCode == 32) {
      if (
        event.target.name == "patient_account_number" &&
        jobData?.default?.account_number
      ) {
        formik.setValues({
          ...formik.values,
          patient_account_number: jobData?.default?.account_number,
          dbk_patient_account_number: jobData?.default?.account_number,
        });
      }

      if (
        event.target.name == "patient_first_name" &&
        jobData?.default?.first_name
      ) {
        formik.setValues({
          ...formik.values,
          patient_first_name: jobData?.default?.first_name,
          dbk_patient_first_name: jobData?.default?.first_name,
        });
      }

      if (
        event.target.name == "patient_last_name" &&
        jobData?.default?.last_name
      ) {
        formik.setValues({
          ...formik.values,
          patient_last_name: jobData?.default?.last_name,
          dbk_patient_last_name: jobData?.default?.last_name,
        });
      }
    }
  };
  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  const qaCorrectedData = gridData?.qa_corrected_data;
  const handleChangeFromTo = async (e) => {
    if (!e.target.disabled) {
      await formik.setValues((prevState) => {
        return {
          ...prevState,
          [e.target.name]: currentPage + 1,
        }
      })
    }
  }

  const renderClass = (name, type, defaultClass) => {
    if (formik.errors?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' && moment(formik.values?.[name]).format("MM/DD/YYYY") != moment(gridData?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' && formik.values?.[name]?.toUpperCase() !=
        gridData?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' && parseFloat(formik.values?.[name] ? formik.values?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.[name] ?
          gridData?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }

      else if (gridData?.transaction_type != "PATIENT_PAY") {
        return defaultClass + ' qa-field'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.[name]) {
      return defaultClass + ' qa-field'
    }
    else {
      return defaultClass;
    }
  }

  return (
    <React.Fragment>
      <div>
        <div
          className="topblock-wrapper patient_pay_block accordion-collapse collapse show"
          id="collapseOne"
          aria-labelledby="headingOne"
          data-bs-parent="#payment835"
        >
          <Form
            autoComplete={"off"}
            noValidate
            id="payment-835-form"
            onSubmit={formik.handleSubmit}
            onReset={formik.handleReset}
          >
            <div className="sub-section">
              <div className="">
                <Form.Label
                  tabIndex="-1"
                  className="btn btn-payment835"
                >
                  Patient Pay
                </Form.Label>
              </div>
              <div className="checknum-wi">
                <InputGroup>
                  <InputGroup.Text>Check Number</InputGroup.Text>

                  <div className="dbk-main-container">
                    <Form.Control
                      data-prevent-shift-tab="true"
                      type="text"
                      className={renderClass('check_number', 'TEXT', 'w-78')}
                      onFocus={() => {
                        handleFocusPatientPay("check_number", isDbkPermission?.patient_pay?.patientpay_check_number);
                      }}
                      onKeyUp={(e) => { setDefaultValueMainGrid(e, 'check_number', formik) }}
                      onWheel={handleWheel}
                      name="check_number"
                      onChange={formik.handleChange}
                      value={formik.values.check_number}
                      tabIndex={!formik?.values?.check_number?.includes("CUSTOM_CHECK_NUMBER") && formik.values?.check_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_check_number) ? !patientPayFocus.check_number : false}
                    />
                    {isDbkPermission?.patient_pay?.patientpay_check_number &&
                      formik.errors?.check_number === "Dont Match" &&
                      patientPayFocus.check_number ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="text"
                        fieldName="check_number"
                        fieldNameDbk="dbk_check_number"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="checkdate-wi">
                <InputGroup>
                  <InputGroup.Text>Check Date</InputGroup.Text>
                  <div className="dbk-main-container">
                    <InputMask
                      mask="99/99/9999"
                      className={renderClass('check_date', 'DATE', 'w-48')}
                      style={{ fontSize: "0.7em" }}
                      onChange={formik.handleChange}
                      onFocus={() => {
                        handleFocusPatientPay("check_date", isDbkPermission?.patient_pay?.patientpay_check_date);
                      }}
                      value={formik.values?.check_date}
                      tabIndex={formik.values?.check_date != '' && formik.values?.check_date != '__/__/____' && pathname == DATA_ENTRY ? -1 : 0}
                      name="check_date"
                      placeholder={"mm/dd/yyyy"}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_check_date) ? !patientPayFocus.check_date : false}
                    />
                    {isDbkPermission?.patient_pay?.patientpay_check_date &&
                      formik.errors?.check_date === "Dont Match" &&
                      patientPayFocus.check_date ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="date"
                        fieldName="check_date"
                        fieldNameDbk="dbk_check_date"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="checkamt-wi">
                <InputGroup>
                  <InputGroup.Text>Check Amount</InputGroup.Text>
                  <div className="dbk-main-container1">
                    <Form.Control
                      type="number"
                      onChange={(e) => handleAmountFloat(e)}
                      onFocus={() => {
                        handleFocusPatientPay("check_amount");
                      }}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                        handleKeyPress(e)
                      }}
                      onBlur={(e) =>
                        formik.setValues({
                          ...formik.values,
                          check_amount: e.target.value ? parseFloat(e.target.value).toFixed(2) : "",
                        })
                      }
                      onWheel={handleWheel}
                      value={formik.values?.check_amount}
                      tabIndex={formik.values?.check_amount != '' && pathname == DATA_ENTRY ? -1 : 0}
                      className={renderClass('check_amount', 'NUMBER', 'w-78 text-end')}
                      name="check_amount"
                    />
                  </div>
                </InputGroup>
              </div>
            </div>
            <div className="sub-section">
              <div className="">
                <Form.Label
                  tabIndex="-1"
                  className="btn btn-payment835"
                >
                  Patient{" "}
                </Form.Label>
              </div>
              <div className="wrapper-patient">
                <InputGroup>
                  <InputGroup.Text>Patient Account Number</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      name={`patient_account_number`}
                      onChange={(val) => {
                        const e = preventWhiteSpaceValidate(val)
                        formik.handleChange(e)
                      }}
                      onKeyUp={(e) => {
                        setDefaultValueAssignedWork(e);
                      }}
                      onFocus={() => {
                        handleFocusPatientPay("patient_account_number", isDbkPermission?.patient_pay?.patientpay_patient_acc_number);
                      }}
                      value={formik.values?.patient_account_number}
                      tabIndex={formik.values?.patient_account_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                      className={renderClass('patient_account_number', 'TEXT', 'w-patient')}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_patient_acc_number) ? !patientPayFocus.patient_account_number : false}

                    />
                    {isDbkPermission?.patient_pay
                      ?.patientpay_patient_acc_number &&
                      formik.errors?.patient_account_number === "Dont Match" &&
                      patientPayFocus.patient_account_number ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="text"
                        fieldName="patient_account_number"
                        fieldNameDbk="dbk_patient_account_number"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-patient">
                <InputGroup>
                  <InputGroup.Text>First Name</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      name={`patient_first_name`}
                      onFocus={() => {
                        handleFocusPatientPay("patient_first_name", isDbkPermission?.patient_pay?.patientpay_patient_first_name);
                      }}
                      onChange={formik.handleChange}
                      value={formik.values?.patient_first_name}
                      tabIndex={formik.values?.patient_first_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                      onKeyUp={(e) => {
                        setDefaultValueAssignedWork(e);
                      }}
                      className={renderClass('patient_first_name', 'TEXT', 'w-fsname')}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_patient_first_name) ? !patientPayFocus.patient_first_name : false}

                    />

                    {isDbkPermission?.patient_pay
                      ?.patientpay_patient_first_name &&
                      formik.errors?.patient_first_name === "Dont Match" &&
                      patientPayFocus.patient_first_name ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="text"
                        fieldName="patient_first_name"
                        fieldNameDbk="dbk_patient_first_name"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-miname">
                <InputGroup>
                  <InputGroup.Text>Middle Name</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      name={`patient_middle_name`}
                      onFocus={() => {
                        handleFocusPatientPay("patient_middle_name", isDbkPermission?.patient_pay?.patientpay_patient_middle_name);
                      }}
                      onChange={formik.handleChange}
                      value={formik.values?.patient_middle_name}
                      tabIndex={formik.values?.patient_middle_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                      className={renderClass('patient_middle_name', 'TEXT', 'w-fsname')}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_patient_middle_name) ? !patientPayFocus.patient_middle_name : false}

                    />
                    {isDbkPermission?.patient_pay
                      ?.patientpay_patient_middle_name &&
                      formik.errors?.patient_middle_name === "Dont Match" &&
                      patientPayFocus.patient_middle_name ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="text"
                        fieldName="patient_middle_name"
                        fieldNameDbk="dbk_patient_middle_name"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-patient">
                <InputGroup>
                  <InputGroup.Text>Last Name</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      name={`patient_last_name`}
                      onFocus={() => {
                        handleFocusPatientPay("patient_last_name", isDbkPermission?.patient_pay?.patientpay_patient_last_name);
                      }}
                      onChange={formik.handleChange}
                      value={formik.values?.patient_last_name}
                      tabIndex={formik.values?.patient_last_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                      onKeyUp={(e) => {
                        setDefaultValueAssignedWork(e);
                      }}
                      className={renderClass('patient_last_name', 'TEXT', 'w-fsname')}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_patient_last_name) ? !patientPayFocus.patient_last_name : false}

                    />
                    {isDbkPermission?.patient_pay
                      ?.patientpay_patient_last_name &&
                      formik.errors?.patient_last_name === "Dont Match" &&
                      patientPayFocus.patient_last_name ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="text"
                        fieldName="patient_last_name"
                        fieldNameDbk="dbk_patient_last_name"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              {isPatientPageFromTo &&
                <div className='pagefrom-corress'>
                  <div className="input-group">
                    <label className="input-group-text">
                      Page From
                    </label>
                    <Form.Control
                      data-disabled={!pdfLoaded}
                      className={renderClass('patient_page_from', 'NUMBER', 'pagefrom')}
                      onChange={formik.handleChange}
                      min={1}
                      type="number"
                      onClick={(e) => {
                        handleChangeFromTo(e);
                      }}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                      }}
                      onFocus={(e) => {
                        handleChangeFromTo(e);
                      }}
                      value={formik.values?.patient_page_from}
                      name={`patient_page_from`}
                    />
                  </div>
                </div>
              }
              {isPatientPageFromTo &&
                <div className='pageto-corress'>
                  <div className="input-group">
                    <label className="input-group-text">
                      Page To
                    </label>
                    <Form.Control
                      data-disabled={!pdfLoaded}
                      className={renderClass('patient_page_to', 'NUMBER', 'pagefrom')}
                      onChange={formik.handleChange}
                      min={1}
                      type="number"
                      onClick={(e) => {
                        handleChangeFromTo(e);
                      }}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                      }}
                      onFocus={(e) => {
                        handleChangeFromTo(e);
                      }}
                      value={formik.values?.patient_page_to}
                      name={`patient_page_to`}
                    />
                  </div>
                </div>
              }

            </div>
            <div className="sub-section">
              <div className="">
                <Form.Label
                  tabIndex="-1"
                  className="btn btn-payment835"
                >
                  Payment Details{" "}
                </Form.Label>
              </div>
              <div className="wrapper-patient">
                <InputGroup>
                  <InputGroup.Text>Amount Due</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="number"
                      name={`amount_due`}
                      onWheel={handleWheel}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                        handleKeyPress(e)
                      }}
                      onBlur={(e) =>
                        formik.setValues({
                          ...formik.values,
                          amount_due: e.target.value ? parseFloat(e.target.value).toFixed(2) : "",
                        })
                      }
                      onChange={(e) => handleAmountFloat(e)}
                      value={formik.values?.amount_due}
                      tabIndex={formik.values?.amount_due != '' && pathname == DATA_ENTRY ? -1 : 0}
                      onFocus={() => {
                        handleFocusPatientPay("amount_due", isDbkPermission?.patient_pay?.patientpay_payment_amt_due);
                      }}
                      className={renderClass('amount_due', 'NUMBER', 'w-fsname ps-1 text-end')}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_payment_amt_due) ? !patientPayFocus.amount_due : false}

                    />
                    {isDbkPermission?.patient_pay?.patientpay_payment_amt_due &&
                      formik.errors?.amount_due === "Dont Match" &&
                      patientPayFocus.amount_due ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="number"
                        fieldName="amount_due"
                        fieldNameDbk="dbk_amount_due"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-miname">
                <InputGroup>
                  <InputGroup.Text>Statement Number</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="text"
                      onFocus={() => {
                        handleFocusPatientPay("statement_number", isDbkPermission?.patient_pay?.patientpay_payment_statement_number)
                      }}
                      name={`statement_number`}
                      onWheel={handleWheel}
                      onChange={(e) => handleAmountFloat(e)}
                      onKeyDown={handleKeyPress}
                      value={formik.values?.statement_number}
                      tabIndex={formik.values?.statement_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_payment_statement_number) ? !patientPayFocus.statement_number : false}
                      className={renderClass('statement_number', 'TEXT', 'w-fsname')}
                    />
                    {isDbkPermission?.patient_pay?.patientpay_payment_statement_number &&
                      formik.errors?.statement_number === "Dont Match" &&
                      patientPayFocus.statement_number ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="text"
                        fieldName="statement_number"
                        fieldNameDbk="dbk_statement_number"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="wrapper-miname">
                <InputGroup>
                  <InputGroup.Text>Statement Amount</InputGroup.Text>
                  <div className="dbk-main-container">
                    <Form.Control
                      type="number"
                      onFocus={() => {
                        handleFocusPatientPay("statement_amount", isDbkPermission?.patient_pay?.patientpay_payment_statement_amount);
                      }}
                      name={`statement_amount`}
                      onWheel={handleWheel}
                      onChange={(e) => handleAmountFloat(e)}
                      onKeyDown={(e) => {
                        handlePreventEKeyPress(e);
                        handleKeyPress(e)
                      }}
                      value={formik.values?.statement_amount}
                      tabIndex={formik.values?.statement_amount != '' && pathname == DATA_ENTRY ? -1 : 0}
                      onBlur={(e) =>
                        formik.setValues({
                          ...formik.values,
                          statement_amount: e.target.value ? parseFloat(e.target.value).toFixed(2) : "",
                        })
                      }
                      className={renderClass('statement_amount', 'NUMBER', 'w-fsname ps-1 text-end')}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_payment_statement_amount) ? !patientPayFocus.statement_amount : false}

                    />
                    {isDbkPermission?.patient_pay?.patientpay_payment_statement_amount &&
                      formik.errors?.statement_amount === "Dont Match" &&
                      patientPayFocus.statement_amount ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="number"
                        fieldName="statement_amount"
                        fieldNameDbk="dbk_statement_amount"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
              <div className="wrapper">
                <InputGroup>
                  <InputGroup.Text>Statement Date</InputGroup.Text>
                  <div className="dbk-main-container">
                    <InputMask
                      mask="99/99/9999"
                      onFocus={() => {
                        handleFocusPatientPay("statement_date", isDbkPermission?.patient_pay?.patientpay_payment_statement_date);
                      }}
                      className={renderClass('statement_date', 'DATE', 'w-30')}
                      style={{ fontSize: "0.7em" }}
                      onChange={formik.handleChange}
                      value={formik.values?.statement_date}
                      tabIndex={formik.values?.statement_date != '' && formik.values?.statement_date != '__/__/____' && pathname == DATA_ENTRY ? -1 : 0}
                      name={`statement_date`}
                      placeholder={"mm/dd/yyyy"}
                      disabled={isActiveDbkField(patientPayFocus, formik, isDbkPermission?.patient_pay?.patientpay_payment_statement_date) ? !patientPayFocus.statement_date : false}

                    />

                    {isDbkPermission?.patient_pay
                      ?.patientpay_payment_statement_date &&
                      formik.errors?.statement_date === "Dont Match" &&
                      patientPayFocus.statement_date ? (
                      <DbkField
                        formik={formik}
                        index=""
                        fieldType="main"
                        type="date"
                        fieldName="statement_date"
                        fieldNameDbk="dbk_statement_date"
                        setCorresPondanceFocus={setPatientPayFocus}
                        corresPondanceFocus={patientPayFocus}
                      />
                    ) : null}
                  </div>
                </InputGroup>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PatientPay;
