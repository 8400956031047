import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import AdminMaster from '../../../Layout/admin/Master/index';
import { OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from '../../../Layout/Elements/DatatableCustomStyles';
import axiosInstance, { BACKEND_URL } from '../../../axiosInstance';
import { GET_ALL_CLIENT_GROUP_URL, GET_CLIENTS_PDF_URL, GET_CLIENT_URL } from '../../../api';

import { CLIENT_GROUP, CREATE_CLIENT_GROUP } from '../../../routeNames';
import { deleteClientGroup, getAllClientGroups } from '../../../Redux/clientGroup/action';
import DeleteSweetAlert from '../../SweetAlert/DeleteSweetAlert';
import { changeAllTitleFunction } from '../Dashboard/functions';
import { Tooltip } from '@mui/material';


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Client ID/Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;

const ClientGroup = () => {
    const [isSwal, setIsSwal] = useState({
        show: false,
        id: ''
    })
    const [pageno, setPageNo] = useState(1);
    const [toaster, setToaster] = useState(false)
    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
    });

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [data, setData] = useState({})
    const [colorRow, setColorRow] = useState({});

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                width: "40px",
                //grow: 0,

            },
            {
                id: 'group_name',
                name: 'Group Name',
                selector: row => row.group_name,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.group_name}</span>,
                sortable: true,
                left: true,
                wrap: true,
                //grow: 0.9,


            },

            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0">
                        <Tooltip disableInteractive title="Edit" placement='top' arrow>
                            <Link onClick={() => changeAllTitleFunction("Edit Client Group", '/client-groups/edit/' + row?.id)}  >
                                <button className="btn  py-0 px-2 fs-7 btn-sm me-1">

                                    <span className='icon-style'>

                                        <i className='bi bi-pencil '></i>
                                    </span>
                                </button>

                            </Link>
                        </Tooltip>
                        {/* {checkUserPermission('delete_group') &&
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 50, hide: 100 }}
                                overlay={DeleteTooltip}
                            >
                             
                                <Link className="btn btn-icon  btn-outline-secondary py-0 px-2  btn-sm me-1" onClick={() => setIsSwal({ ...isSwal, show: true, id: row.id })}  >
                                    <i className='bi bi-trash fs-7'></i>
                                </Link>
                            </OverlayTrigger>
                        } */}
                        {checkUserPermission('assigned_user_to_group') &&
                            <Tooltip disableInteractive title="Assign User" placement='top' arrow>
                                <div onClick={() => { changeAllTitleFunction('Assign User', '/assign-users/create/' + row?.id) }} to={'/assign-users/create/' + row?.id} >
                                    <button className="btn  py-0 px-2 fs-7 btn-sm me-1">

                                        <span className='icon-style'>

                                            <i className='bi bi-person-plus-fill'></i>
                                        </span>
                                    </button>

                                </div>
                            </Tooltip>
                        }


                    </div>,
                // ignoreRowClick: false,
                // allowOverflow: true,
                // width: "100px",
                right: true,
                button: false,
            },

        ],
        [pageno],
    );
    const fetchRoles = async (page, limit, search) => {
        setLoading(true);

        //setLimit(limit);
        getAllClientGroups(page, limit, search)((response) => {
            if (response?.status && response?.status_code == 200) {
                setData(response?.data);
                setLimit(limit);
                setTotalRows(response?.data?.count);
                setLoading(false);


            }
        })

        // const response = await axiosInstance.get(GET_ALL_CLIENT_GROUP_URL + "?page=" + page + "&limit=" + limit);
        // setData(response?.data);
        // setLimit(response?.data?.data?.count);
        // setTotalRows(response?.data?.data?.count);
        // setLoading(false);
        // console.log(response?.data?.data?.count)
    };
    const handlePageChange = page => {
        setPageNo(page)
        fetchRoles(page, limit, filterText);
    };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        fetchRoles(page, newPerPage, filterText)
        setLoading(false);

    };
    useEffect(() => {

        // getRoles({ limit })((response) => {
        //     setData(response);
        //     setLimit(limit);
        //     setTotalRows(response?.data?.count)
        // });


        fetchRoles(1, limit);
    }, []);


    const __deleteClientGroup = (client) => {
        let formData = new FormData();    //formdata object
        formData.append('client_group_id', client);

        deleteClientGroup(client)((response) => {
            if (response?.status && response?.status_code === 200) {
                getAllClientGroups(1, limit)((response) => {
                    setData(response?.data);
                    setToaster('success')
                });
            } else {
                setState({ error: true, error_message: response?.message });
                setToaster('error')
            }
        });
    }

    const searchGroups = async (e) => {
        setFilterText(e.target.value)
        let data = e.target.value ? e.target.value : '';
        fetchRoles(1, limit, data)
    }

    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, resetPaginationToggle]);
    const handleClear = async () => {
        if (filterText) {
            //setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            // let data = '';
            // const response = await axiosInstance.get(GET_ALL_CLIENT_GROUP_URL + "?limit=" + data?.data?.count);
            // setData(response?.data);
            fetchRoles(1, limit)
        }
    };
    // const editTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Edit
    //     </Tooltip>
    // );
    // const DeleteTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Delete
    //     </Tooltip>
    // );
    // const assignUserTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Assign user
    //     </Tooltip>
    // );
    const handleDelete = (pk) => {
        setIsSwal({ ...isSwal, show: 2 })
        __deleteClientGroup(pk)
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
        // // console.log(row);
    };
    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} />
            <main id="main" className="main">
                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" onClose={() => setState({ showToast: false })} show={state.showToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Group Deleted</span></Toast.Body>
                    </Toast>
                </ToastContainer> */}
                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Client Group</h1>

                </div>


                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0 px-3">
                                    <div className="d-flex align-items-center position-relative my-1">
                                        <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
                                    </div>
                                    {checkUserPermission('create_group') &&
                                        <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                            <Link onClick={() => changeAllTitleFunction('Create Client Group', CREATE_CLIENT_GROUP)} className="btn btn-light btn-active-primary">
                                                <span className="svg-icon svg-icon-3">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                    </svg>
                                                </span>
                                                Create Group
                                            </Link>
                                        </div>
                                    }
                                </div>
                                <div className="card-body py-3">
                                    <div className="table-responsive">
                                        <div className='dashboardtable'>
                                            <DataTable
                                                className="custom-datatable"
                                                fixedHeader={true}

                                                data={data?.results}
                                                columns={columns}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationPerPage={limit}
                                                paginationRowsPerPageOptions={[25, 50, 100]}
                                                paginationTotalRows={totalRows}
                                                onChangePage={handlePageChange}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                conditionalRowStyles={conditionalRowStyles}
                                                onRowClicked={handleRowCheckClick}
                                                theme="solarized"
                                                customStyles={datatableCustomStyles}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                        </div>
                    </div>
                </section>
                <DeleteSweetAlert handleDelete={handleDelete} isSwal={isSwal} setIsSwal={setIsSwal} />

            </main>
        </React.Fragment>
    )
}

export default ClientGroup;