import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, Modal, OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import { GET_X12_CODES_URL } from '../../../api'
import axiosInstance from '../../../axiosInstance'
import Master from '../../../Layout/admin/Master'
import { getX12Codes, toggleX12Code } from '../../../Redux/user/action'
import { CREATE_X12_CODES, EDIT_X12_CODES } from '../../../routeNames'
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import styled from 'styled-components'
import x12codeSample from "../../../assets/sample/sample-x12code.csv"
import { useFormik } from 'formik'
import { importCsvX12Code } from '../../../Redux/user/action'
import { changeAllTitleFunction } from '../Dashboard/functions'
import { BiReset, BiSearch } from 'react-icons/bi'
import { Tooltip } from '@mui/material';



const FilterComponent = ({ filterText, onFilter, onClear, placeholder }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid left-b-r-10 filter-btn-custom"
            type="text"
            placeholder={placeholder}
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
border-top-left-radius: 0;
border-bottom-left-radius: 0;
border-top-right-radius: 5px;
border-left: 0;
color: #1ba1a7;
border-bottom-right-radius: 5px;
height: 35px;
width: 32px;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
background-color: #f5f8fa;
border-color: #d9d9d9;
&:hover {
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    color: #1ba1a7;
}
&:focus {
    background-color: #f5f8fa !important;
    border-color: #d9d9d9 !important;
    color: #1ba1a7;
}`;

const X12Code = () => {
    const [pageno, setPageNo] = useState(1);

    const [toaster, setToaster] = useState(false)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [filterText, setFilterText] = React.useState({
        description: "",
        code: "",
    });
    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
    });
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [modalUpload, setModalUpload] = useState(false);
    const [colorRow, setColorRow] = useState({});

    useEffect(() => {
        // getX12Codes(1, 10)((response) => {
        //     setData(response)
        //     setTotalRows(response?.data?.count);
        // });
        // getReasonCodes();
        fetchX12Codes(1, limit, searchData.description, searchData.code)

    }, []);

    const fetchX12Codes = async (page, limit, description, code) => {
        setLoading(true);

        setLimit(limit);

        getX12Codes(page, limit, description, code)((response) => {
            setData(response?.data);
            setLimit(limit);
            setTotalRows(response?.data?.count);
            setLoading(false);
        });
    };

    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        // getX12Codes(page, limit)((response) => {
        //     setData(response);
        //     setLimit(limit);
        //     setTotalRows(response?.data?.count);
        //     setLoading(false);
        // });
        fetchX12Codes(page, newPerPage, searchData.description, searchData.code)
        setLoading(false);
    };

    const handlePageChange = page => {
        setPageNo(page)
        fetchX12Codes(page, limit, searchData.description, searchData.code);
    };
    const __toggleCode = (id) => {
        let formData = new FormData();    //formdata object

        formData.append('user_id', id);

        toggleX12Code({ formData })((response) => {
            if (response?.status && response?.status_code === 201) {
                setState({ showToast: !state.showToast })
                setToaster('success')
                // getX12Codes(1, 10)((response) => {
                //     setData(response)
                // });
            } else {
                setToaster('error')
                setState({ error: true, error_message: response?.message });
            }
        });
    }

    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster(false)
            }, 2000);
            return () => clearTimeout(timer)
        }
    }, [toaster])

    const searchGroups = async (e, name) => {
        setFilterText({
            ...filterText,
            [name]: e.target.value
        })
        let data = e.target.value ? e.target.value : '';
        // data?.data?.count
        //  const response = await axiosInstance.get(GET_X12_CODES_URL + "?limit=" + limit + "&description=" + filterText.description + "&rarc=" + filterText.rarc + "&carc=" + filterText.carc);
        //  setData(response?.data?.data);
    }

    const [searchData, setSearchedData] = useState({
        code: '',
        description: "",
    })
    const addFilter = async () => {


        fetchX12Codes(1, limit, filterText.description, filterText.code)
        setSearchedData({
            code: filterText.code,
            description: filterText.description,
        })
        //    const response = await axiosInstance.get(GET_X12_CODES_URL + '?page=' + page + "?limit=" + 1 + "&description=" + filterText.description + "&rarc=" + filterText.rarc + "&carc=" + filterText.carc);
        // setData(response?.data);
    }
    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, resetPaginationToggle]);
    const handleClear = (name) => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            if (name == 'reset') {
                setFilterText({
                    ...filterText,
                    description: "",
                    carc: "",
                    rarc: "",
                })
                fetchX12Codes(1, limit, "", "", "");
            } else {

                setFilterText({
                    ...filterText,
                    [name]: "",
                })
            }
        }
    };
    // const renderEditTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Edit
    //     </Tooltip>
    // );
    // const toggleTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Status
    //     </Tooltip>
    // );
    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );

    const handleRowClickCheck = (row) => {

        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const columns = useMemo(
        () => [

            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                // grow: 0,

            },
            {
                id: 'code',
                name: 'code',
                selector: row => row.code,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.code}</span>,
                sortable: true,
                left: true,
                wrap: true,
                //grow: 0.5,


            },
            {
                id: 'type',
                name: 'type',
                selector: row => row.type,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.type}</span>,
                sortable: true,
                left: true,
                wrap: true,
                //grow: 0.5,


            },
            {
                id: 'description',
                name: 'description',
                selector: row => row.description,
                cell: (row) => <div className="" >
                    {row?.description &&

                        <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: "340px" }}>
                            <Tooltip disableInteractive title={row?.description} placement='top' arrow>
                                <span className='text-truncate' > {row?.description}</span>
                            </Tooltip>
                        </div>

                    }

                </div>,
                sortable: true,
                left: true,
                fixed: true,
                width: "400px",
                wrap: true,
                //grow: 0.5,

            },


            {
                id: 'start_date',
                name: 'start date',
                selector: row => row.start_date,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.start_date}</span>,
                sortable: true,
                left: true,
                wrap: true,
                grow: 0.5,


            },

            {
                id: 'stop_date',
                name: 'stop date',
                selector: row => row.stop_date,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.stop_date}</span>,
                sortable: true,
                left: true,
                wrap: true,
                grow: 0.5,


            },



            {
                id: 'edit',
                name: 'Actions',
                //grow: 0.5,

                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0 gap-3">

                        {checkUserPermission('edit_x12_code') &&
                            <>
                                <Tooltip disableInteractive title="Edit" placement='top' arrow>

                                    <Link onClick={() => changeAllTitleFunction('Edit X12 Code', EDIT_X12_CODES + row.pk)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                        <span className="svg-icon svg-icon-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </Link>
                                </Tooltip>
                                <Tooltip disableInteractive title="Status" placement='top' arrow>
                                    <div className="form-check form-switch form-switch-custom " onClick={() => __toggleCode(row.pk)}>
                                        <input className="form-check-input" type="checkbox" defaultChecked={row.is_active} role="switch" id="flexSwitchCheckDefault" />
                                    </div>
                                </Tooltip>
                            </>
                        }

                    </div>,
                ignoreRowClick: true,
                allowOverflow: true,
                right: true,
                button: false,
                //width: '110px'
            },

        ],
        [pageno],
    );
    // const downloadTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Sample Csv
    //     </Tooltip>
    // );

    const csvFormik = useFormik({
        initialValues: {
            csv_file: ""
        },
        // validationSchema: Yup.object().shape({
        //     csv_file: Yup.mixed()
        //         .required('Required'),
        // }),
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append('csv_file', values?.csv_file[0]);
            importCsvX12Code({ formData })((response) => {
                if (response && response?.status) {
                    setToaster('success')
                    setModalUpload(!modalUpload)
                }
                else {
                    setToaster('error')

                }
            })
        }
    })
    const handleFileChange = (event) => {

        let selectedFile = event.target.files;

        csvFormik.setValues({
            ...csvFormik.values,
            csv_file: selectedFile
        })

    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.pk == row.pk ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleKeyUpEnter = (e) => {
        if (e.keyCode === 13) {


            addFilter()
        }
    }
    return (
        <React.Fragment>
            <Master />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>X12 Codes</h1>
                </div>
                <Modal show={modalUpload} onHide={() => setModalUpload(!modalUpload)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className='d-flex flex-row justify-content-around gap-4'>
                                <span>X12 CSV Upload</span>
                                <a className='' style={{ cursor: 'pointer' }} target="_blank" download href={x12codeSample}>
                                    <Tooltip disableInteractive title="Sample Csv" placement='top' arrow>

                                        <span className=''><i className='bi bi-download'></i></span>
                                    </Tooltip>
                                </a>
                            </div>
                        </Modal.Title>
                    </Modal.Header>

                    <Form onSubmit={csvFormik.handleSubmit}>

                        <Modal.Body>
                            <Form.Control
                                type="file"
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className='form-control form-control-solid b-r-10'
                                required
                                onChange={(e) => handleFileChange(e)}
                                name="csv_file"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setModalUpload(!modalUpload)}>
                                Close
                            </Button>
                            <Button className='btn btn-light btn-active-primary' variant="primary" type="submit" >
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>

                <section className="section mt-5" >
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">



                            <div className="card mb-5 mb-xl-8">
                                {/* <Form

                                    onSubmit={() => { addFilter() }}
                                // onSubmit={(e) => e.preventDefault()}
                                > */}
                                <div className="card-header border-0 ">

                                    <div className='d-flex align-items-center ms-3'>
                                        <Form.Control type='search' placeholder="Code" className='me-2 form-control form-control-solid b-r-10' onKeyDown={(e) => handleKeyUpEnter(e)} onChange={(e) => searchGroups(e, 'code')} onClear={() => handleClear('code')} filterText={filterText.code} />
                                    </div>
                                    <div className="d-flex me-1 p-3  align-items-center">
                                        <Form.Control type='search' placeholder="Description" className='me-2 form-control form-control-solid b-r-10' onKeyDown={(e) => handleKeyUpEnter(e)} onChange={(e) => searchGroups(e, 'description')} onClear={() => handleClear('description')} filterText={filterText.description} />
                                    </div>

                                    <div className='d-flex me-auto p-2 align-items-center gap-2'>
                                        <Tooltip disableInteractive title="Search" placement='top' arrow>
                                            <button type='button'
                                                onClick={() => { addFilter() }}
                                                className="btn btn-light btn-active-primary">
                                                <span className="svg-icon svg-icon-3 ">
                                                    <BiSearch className='jobMonitor-svg' />
                                                </span>
                                                {/* Search */}
                                            </button>
                                        </Tooltip>

                                        <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                            <button style={{ height: "38px" }} onClick={() => { window.location.reload() }} className="btn btn-light btn-active-primary">
                                                <span className="svg-icon svg-icon-3">
                                                    <BiReset className='jobMonitor-svg' />
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </div>


                                    {/* </Form> */}
                                    <div className="card-toolbar gap-2 me-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                        {/* <button className='btn btn-light btn-active-primary' onClick={() => setModalUpload(true)}>
                                    File Upload
                                    </button> */}
                                        {checkUserPermission('create_x12_code') &&

                                            <Link onClick={() => changeAllTitleFunction('Create X12 Code', CREATE_X12_CODES)} className="btn btn-light btn-active-primary">
                                                <span className="svg-icon svg-icon-3">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                    </svg>
                                                </span>
                                                Create
                                            </Link>
                                        }
                                    </div>
                                </div>



                                <div className="card-body py-3">
                                    <div className="table-responsive">
                                        <div className='dashboardtable'>
                                            <DataTable
                                                className="custom-datatable"
                                                data={data?.results}
                                                columns={columns}
                                                progressPending={loading}
                                                pagination
                                                fixedHeader={true}
                                                paginationServer
                                                paginationPerPage={limit}
                                                paginationTotalRows={totalRows}
                                                paginationRowsPerPageOptions={[25, 50, 100]}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                // subHeader
                                                // subHeaderComponent={subHeaderComponentMemo}
                                                theme="solarized"
                                                customStyles={datatableCustomStyles}
                                                conditionalRowStyles={conditionalRowStyles}
                                                onRowClicked={handleRowClickCheck}

                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default X12Code