
import axiosInstance from './../../axiosInstance';
import {
    CREATE_ASSIGN_USERS_URL,
    CREATE_CLIENT_GROUP_URL, DESTROY_CLIENT_GROUP_URL, GET_ALL_CLIENT_GROUP_URL, GET_ALL_STAFF_URL, GET_CLIENTS_GROUPS_CHOICE_URL, GET_CLIENT_GROUP_DETAILS_URL
} from './../../api/index';
import { SINGLE_ASSIGN_GROUP_USERS } from '../../routeNames';

// client 
export const createClientGroup = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_CLIENT_GROUP_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getAllClientGroups = (page, limit, search) => onResponse => {
    try {
        let data = search ? search : ""
        axiosInstance.get(GET_ALL_CLIENT_GROUP_URL + "?page=" + page + "&limit=" + limit + "&search=" + data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getClientGroupDetails = (id) => onResponse => {
    try {
        axiosInstance.get(GET_CLIENT_GROUP_DETAILS_URL + '?client_group_id=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const deleteClientGroup = (id) => onResponse => {
    try {
        axiosInstance.delete(DESTROY_CLIENT_GROUP_URL, {
            data: { client_group_id: id }
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getAllStaffs = () => onResponse => {
    try {
        axiosInstance.get(GET_ALL_STAFF_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createAssignUsers = (values, id, staff) => onResponse => {
    try {
        axiosInstance.post(CREATE_ASSIGN_USERS_URL, {
            staff: values,
            client_group: id,
            staff_in_group: staff
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getGroupsForClient = () => onResponse => {
    try {
        axiosInstance.get(GET_CLIENTS_GROUPS_CHOICE_URL)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getSingleAssignGroupUser = (id) => onResponse => {
    try {
        axiosInstance.get(SINGLE_ASSIGN_GROUP_USERS + '?client_group_id=' + id)

            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};