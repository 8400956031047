import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import Table from "react-bootstrap/Table"


const UserDiffrenceReportModal = ({ toggleReportPopup, setToggleReportPopup, reportData, setReportData }) => {
    const removeUnderScore = (str) => {
        const newStr = str?.replace(/_/g, " ");
        return newStr

    }
    function displayBooleanOrValue(value) {
        let curentValue = value
        if (typeof value === 'boolean') {
            curentValue = value ? 'true' : 'false';
        }
        return curentValue
    }
    return (
        <Modal size="lg" show={toggleReportPopup} onHide={() => { setToggleReportPopup(false), setReportData([]) }} centered className='manual-tat'>
            <Modal.Header closeButton>
                <Modal.Title className="table-report-modelTitle">Log</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-body">
                <Table >
                    <tbody>
                        <tr>

                            <th className="d-flex flex-row align-items-center  justify-content-around " >
                                <div className="flex-shrink-1">
                                    <span className="my-2 badge table-report-badge " >Previous Updated User</span>
                                </div>
                                <div className="flex-grow-1 ms-3">

                                    <span className="table-report-tr  " >{reportData?.created_user?.old}</span>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="d-flex flex-row align-items-center  justify-content-around ">
                                <div className="flex-shrink-1">
                                    <span className="my-2 badge table-report-badge">Previous Date</span>
                                </div>
                                <div className="flex-grow-1 ms-3">

                                    <span className="table-report-tr">{reportData?.time?.old ? moment(reportData?.time?.old).format('MM/DD/YYYY HH:mm') : "--------"}</span>
                                </div>

                            </th>
                        </tr>
                        <tr>
                            <th className="d-flex flex-row align-items-center  justify-content-around ">
                                <div className="flex-shrink-1">

                                    <span className="my-2 badge table-report-badge">Last Updated User</span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <span className="table-report-tr">{reportData?.created_user?.new}</span>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th className="d-flex flex-row align-items-center  justify-content-around ">
                                <div className="flex-shrink-1">

                                    <span className="my-2 badge table-report-badge">Last Date</span>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                    <span className="table-report-tr ">{reportData?.time?.new ? moment(reportData?.time?.new).format('MM/DD/YYYY HH:mm') : "--------"}</span>
                                </div>
                            </th>
                        </tr>
                    </tbody>

                </Table>
                <table className="table history-log-table" >
                    {/* <tbody>
                    |<tr>
                        <th>Last User</th>
                        <td>Uvais</td>
                    </tr>
                </tbody> */}
                    <thead>
                        <tr >
                            <th >Field</th>
                            <th >Previous</th>
                            <th >Updated</th>
                        </tr>
                    </thead>
                    {reportData?.userdata?.map((i, index) =>
                        <tbody key={index}>
                            <tr >
                                <td> <div style={{ width: '240px', wordWrap: 'break-word' }}>{removeUnderScore(i?.key)}</div></td>
                                <td> <div style={{ width: '240px', wordWrap: 'break-word' }}>{displayBooleanOrValue(i?.old)}</div></td>
                                <td><div style={{ width: '240px', wordWrap: 'break-word' }}>{displayBooleanOrValue(i?.new)}</div></td>
                            </tr>

                        </tbody>
                    )}
                </table>

            </Modal.Body>
        </Modal>
    )


}
export default UserDiffrenceReportModal;




