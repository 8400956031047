


import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components/macro";

const DashBoardSkeleton = () => {
  return (
    <div>
      {/* Header */}
      {/* <Skeleton height={50} /> */}

      {/* Main Content */}
      <div style={{ display: "flex", marginTop: "20px" }}>

        {/* Main Content Area */}
        <div style={{ width: "80%" }}>

          {/* Canvas Display */}
          <MainListContainer >
            {/* Placeholder for multiple canvas elements */}

            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            <div>
              <Skeleton style={{ borderRadius: '16px' }} width={300} height={110} />

            </div>
            {/* Add more placeholders as needed */}
          </MainListContainer>
        </div>
      </div>

      {/* SingleCanvas Overlay */}
      {/*
        The overlay is displayed when signleView.isOpen is true.
        You can add a skeleton or loading indicator here.
      */}
      {/*
        <BlurBackground>
          <SkeletonLoadingOverlay />
        </BlurBackground>
      */}
    </div>
  );
};

export default DashBoardSkeleton;


const ButtonIconConatiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 20%;
  transition: all 0.4s ease-in-out;
  .save-submit{
    width: 36px;
    height: 36px;
  }
  div {
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    svg {
      font-size: 16px;
    }
  }
`;
const MainListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    /* div{
      width: 200px;
      height: 150px;
      margin-right: 90px;
      margin-bottom: 60px;
      
    } */
`