import { GET_PARTNERS_LIST_URL, GET_PARTNER_DROPDOWN_URL, GET_PARTNER_SINGLE_DETAIL_URL, POST_CREATE_OR_UPDATE_URL } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getPartnerList = (page, limit, search) => onResponse => {
    try {

        let data = search ? search : "";
        axiosInstance.get(GET_PARTNERS_LIST_URL + "?page=" + page + "&limit=" + limit + "&search=" + data)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdatePartner = (props) => onResponse => {
    try {

        axiosInstance.post(POST_CREATE_OR_UPDATE_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSinglePartnerDetail = (partner) => onResponse => {
    try {

        axiosInstance.get(GET_PARTNER_SINGLE_DETAIL_URL + "?partner=" + partner)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getPartnersDropdown = () => onResponse => {
    try {

        axiosInstance.get(GET_PARTNER_DROPDOWN_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
