import { CREATE_UPDATE_EMAIL_TEMPLATE_URL, EMAIL_TEMPLATE_STATUS, GET_ALL_EMAIL_TEMPLATE_URL, GET_SINGLE_EMAIL_TEMPLATE_URL } from "../../api";
import axiosInstance from "../../axiosInstance";


export const getAllEmailTemplates = (page, limit, search) => onResponse => {
    try {

        let data = search ? search : "";
        axiosInstance.get(GET_ALL_EMAIL_TEMPLATE_URL + "?page=" + page + "&limit=" + limit + "&search=" + data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const createOrUpdateEmailTemplate = (props) => onResponse => {
    const template = props.template ? props.template : undefined
    const email_type = props.email_type ? props.email_type : ""
    const contents = props.contents ? props.contents : ""
    const subject = props.subject ? props.subject : ""
    try {

        axiosInstance.post(CREATE_UPDATE_EMAIL_TEMPLATE_URL, { template, email_type, contents, subject })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }
};

export const getSingleEmailTemplate = (id) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_EMAIL_TEMPLATE_URL + '?template=' + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};



export const setEmailTemplateStatus = (id) => onResponse => {
    try {
        axiosInstance.post(EMAIL_TEMPLATE_STATUS, {
            template: id

        })
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {

    }
} 