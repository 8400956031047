import styled from 'styled-components';
import AdminMaster from '../../../Layout/admin/Master';
import { Link, useParams, useNavigate } from 'react-router-dom';
import queueDatatableCustomStyles from '../../../Layout/Elements/QueueDatatableCustomStyles';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap';
import "../../../../src/assets/css/dashboard.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import { changeQueueReleaseStatus, clientJobHistory, exportJobMonitor, filterTotalQueueDetails, generateOutputFileSchema, generateOutputFileSchema835, generateOutputXmlFileSchema, getChangeJobTat, getUserlist, getUserManualAssign, resentOutputFileSchema, rejectJobApi } from '../../../Redux/qms/action';
import { QMS } from '../../../routeNames';
import moment from 'moment';
import { filterQueueDetails, queueHoldUser } from '../../../Redux/qms/action';
import { BiHide, BiShow, BiExport, BiFile, BiReset, BiSearch, BiTimer, BiUserPlus, BiXCircle, BiDownload } from 'react-icons/bi';
import { TbBoxMultiple } from 'react-icons/tb';
import { BsAlarm, BsChevronDown, BsClockHistory, BsEye, BsLayoutTextSidebarReverse } from 'react-icons/bs';
import { HiChevronDown } from 'react-icons/hi';
import { IoIosSwitch, IoMdCheckbox } from 'react-icons/io';
import { TbGridDots, TbHandGrab } from 'react-icons/tb';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import DateRangePicker from 'rsuite/DateRangePicker';
import * as Yup from 'yup';
import { getAllClientDropdown } from '../../../Redux/client/action';
import { getAllStaffs, getGroupsForClient } from '../../../Redux/clientGroup/action';
import { myWorkDataEntryReport } from '../../../Redux/reports/action';
import axiosInstance from '../../../axiosInstance';
import { EDIT_WORK } from '../../../routeNames';
import MyWorkDataEntryReportModal from '../../BootstrapModal/MyWorkDataEntryReportModal';
import { changeAllTitleFunction, setfiltrationHistoryBottomTab, downloadFile } from '../Dashboard/functions';
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { setHours, setMinutes, setSeconds, startOfYear, endOfYear } from 'date-fns';

import addMonths from 'date-fns/addMonths';
import { Tooltip } from '@mui/material';
import Swal from 'sweetalert2';

import { MdCheckBox, MdCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import DateTimePicker from './DateTimePicker';

const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const TH = styled.th`
    :first-child{
        padding-left:10px !important;
    }
    padding-top:10px!important;
    padding-bottom:10px!important;
   
    `;
const TR = styled.tr`
   
    `;
var input = {
    client: '',
    file: ''
};



const QueueManagementSystem = () => {

    const [grandTotal, setGrandTotal] = useState({
        isActive: false,
    });
    const [grandTotalData, setGrandTotalData] = useState({
        data: {}
    });
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [userlist, setUserlist] = useState();
    const [staffList, setStaffList] = useState();
    const [clientGroupList, setClientGroupList] = useState();
    const [pageno, setPageNo] = useState(1);
    const [settings, setSettings] = useState();
    const [toaster, setToaster] = useState(null);
    const [value, onChange] = useState(new Date());
    const [tatDate, setTatDate] = useState('')
    const [tatTime, setTatTime] = useState('')
    const [sortColumn, setSortColumn] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    // const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [exportLoader, setExportLoader] = useState(false);
    //const [is_multiple, setIs_multiple] = useState(true);

    let { lockbox_file } = useParams();


    useEffect(() => {
        getUserlist(1)((response) => {
            setUserlist(response?.data?.results)
        });

        getAllStaffs()((response) => {
            setStaffList(response?.data?.results)
        });
        getGroupsForClient()((response) => {
            setClientGroupList(response?.data)
        });

    }, []);


    const navigate = useNavigate();
    const handleSort = async (column, order) => {

        setSortColumn(column?.sort_key);
        setSortOrder(order);
        fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, order, column.sort_key, searchedData?.tat_status, searchedData?.station, "", searchedData?.processed_date_range);

    };



    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 59)],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date().setHours(0, 0, 0, 0), -1), addDays(new Date().setHours(23, 59, 59, 59), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date().setHours(0, 0, 0, 0)), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date().setHours(0, 0, 0, 0), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date().setHours(0, 0, 0, 0), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date().setHours(0, 0, 0, 0)), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date().setHours(0, 0, 0, 0), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [setSeconds(setMinutes(setHours(startOfYear(new Date(new Date().getFullYear() - 1, 0, 1)), 0), 0), 0), setSeconds(setMinutes(setHours(endOfYear(new Date(new Date().getFullYear(), 0, 0)), 23), 59), 59)],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date().setHours(0, 0, 0, 0)] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];
    /////////////////////////////////////////////////////////////////




    const releaseFormik = useFormik({
        initialValues: {
            reason: "",
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string()
                .required('Required'),

        }),
        onSubmit: (values, { setSubmitting }) => {
            if (values.reason.replace(/\s/g, "") == '') {
                releaseFormik.setErrors({ ...releaseFormik.errors, 'reason': 'Required' })
                return
            }
            setSubmitting(true)
            setGrandTotal({ ...grandTotal, isActive: false })

            changeQueueReleaseStatus(selectedRows, values.reason)((response) => {
                if (response && response.status) {
                    fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);
                    setTogglePopup(false)
                    setSelectedRows([])
                    releaseFormik.setValues({ reason: "" })
                    setSubmitting(false)
                    setToaster('success')
                    handleResetCheckBox()
                }
                else {
                    setTogglePopup(false)
                    setSelectedRows([])
                    setSubmitting(true)
                    setToaster('error')
                    handleResetCheckBox()


                }
            });
        },
    });


    const handleResetCallFetch = async () => {
        setfiltrationHistoryBottomTab("")

        const currentUrl = window.location.pathname; // Get the current URL
        const decodedUrl = decodeURI(currentUrl); // Decode the URL
        const newUrl = encodeURI(decodedUrl.replace(/\%20/g, '')); // Remove all occurrences of '%20' and encode the new URL
        // const currentUrl2 = SINGLE_LOCKBOX_LOCKBOX_FILES + file_id; // Get the current URL
        // const decodedUrl2 = decodeURI(currentUrl); // Decode the URL
        const newUrl2 = encodeURI(decodedUrl.replace(/\%20/g, '')); // Remove all occurrences of '%20' and encode the new URL

        if (newUrl == newUrl2) {
            navigate(QMS)
            window.location.reload()

        } else {
            window.location.reload()

        }

    }

    const [disableTotal, setDisableTotal] = useState(false)

    const handleExportJobMonitor = async (page, limit, staff, client, client_group, status, filename, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station, list_type, processed_date_range) => {
        if (!exportLoader) {
            setGrandTotal({ ...grandTotal, isActive: false })

            setExportLoader(true)
            setDisableTotal(true)
            let header_names = [];

            const headerMap = {
                deposit_date: 'DEPOSIT DATE',
                processed_date: 'PROCESSED DATE',
                client: 'CLIENT',
                facility: 'FACILITY',
                job_file_name: "JOB FILE NAME",
                job_id: "JOB ID",
                job_status: "JOB STATUS",
                lockbox_file_name: "LOCKBOX FILE NAME",
                batch_id: "BATCH ID",
                document_type: "DOCUMENT TYPE",
                internal_tat: "INTERNAL TAT",
                external_tat: "EXTERNAL TAT",
                check_number: "CHECK NUMBER",
                index_amount: "INDEX AMOUNT",
                check_amount: "CHECK AMOUNT",
                eft_amount: "EFT AMOUNT",
                payer: "PAYER",
                no_of_pages: "NO OF PAGES",
                no_of_claims: "NO OF CLAIMS",
                no_of_service_lines: "NO OF SERVICE LINES",
                no_of_fields: "NO OF FIELDS",
                no_of_corrected_fields: "NO OF CORRECTED FIELDS",
                no_of_autofilled_fields: "NO OF AUTOFILLED FIELDS",
                no_of_key_fields: "NO OF KEYED FIELDS",
                data_grid_user: "USER NAME",
                user_start: "USER START TIME",
                user_end: "USER END TIME",
                user_total_time: "USER TOTAL TIME",
                qa_user: "QA NAME",
                qa_start_time: "QA START TIME",
                qa_end_time: "QA END TIME",
                qa_total_time: "QA TOTAL TIME",

                snipping_staff: "SNIPPING NAME",
                snipping_start_time: "SNIPPING START TIME",
                snipping_end_time: "SNIPPING END TIME",
                snipping_total_time: "SNIPPING TOTAL TIME",

                additional_info_status: "ADDITIONAL INFO STATUS",
                comment: "COMMENT",
            };


            for (let key in state) {
                if (state && state[key]) {
                    const headerName = headerMap[key];
                    if (headerName) {
                        header_names.push(headerName);
                    }
                }
            }

            let process_date = ""
            if (processed_date_range?.length > 0) {
                var processed_date_from = moment(processed_date_range[0]).format('MM/DD/YYYY HH:mm:ss');
                var processed_date_to = moment(processed_date_range[1]).format('MM/DD/YYYY HH:mm:ss');

                process_date = processed_date_from + "-" + processed_date_to
            }
            exportJobMonitor({ page, limit, staff, client, client_group, status, filename, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, header_names, station, list_type, process_date })((response) => {

                if (!response?.data?.status && response?.status) {
                    setExportLoader(false)
                    handleResetCheckBox()
                    setDisableTotal(false)

                    // console.log(response?.data, "SUCCESSS");
                    downloadFile(response?.data, 'job-monitor')

                    // let url = "https://xtract-s3-local.s3.amazonaws.com/media/export/job-monitor/2023-04-14/09-49/job_monitor-4ccf4e4b-ae5d-48b1-b6f6-c370bf8e8008.csv?AWSAccessKeyId=AKIAZLO3CEUCADU6MMBT&Signature=A%2FYGissaiqxW9zYZZca62uJkAq4%3D&Expires=1681469388"
                } else {
                    setExportLoader(false)
                    handleResetCheckBox()
                    setDisableTotal(false)

                    console.log("ERROR");
                }
            });
        }
    }

    const holdFormik = useFormik({
        initialValues: {
            reason: "",
        },
        // validationSchema,
        validationSchema: Yup.object().shape({
            reason: Yup.string()
                .required('Required'),

        }),
        onSubmit: (values, { setSubmitting }) => {
            if (values.reason.replace(/\s/g, "") == '') {
                holdFormik.setErrors({ ...holdFormik.errors, 'reason': 'Required' })
                return
            }
            setSubmitting(true)
            queueHoldUser(selectedRows, values.reason)((response) => {
                setLoading(true)
                if (response && response.status) {
                    fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);
                    setToggleHoldPopup(false)
                    setSubmitting(false)
                    setToaster('success')
                    holdFormik.setValues({ reason: "" })
                    setSelectedRows([])
                    setSelectedData([])

                    handleResetCheckBox()
                }
                else {
                    setSubmitting(false)
                    setToaster('error')
                    setTogglePopup(false)
                    setSelectedRows([])
                    setSelectedData([])

                    handleResetCheckBox()




                }
                setLoading(false)
            });
        },
    });


    const formik = useFormik({
        initialValues: {
            assignedqueue: "",
            staff: "",
            qa_or_snipping: "3",

        },


        validationSchema: Yup.object().shape({
            staff: Yup.string()
                .required('Required'),


        }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            setGrandTotal({ ...grandTotal, isActive: false })

            __formSubmit(values, { setSubmitting })
        },
    });

    console.log(formik.errors, 'formik.errors', formik.values)
    const __formSubmit = (values, { setSubmitting }) => {
        getUserManualAssign(values?.staff, values?.assignedqueue, values?.qa_or_snipping)((response) => {
            setShowModal(false);
            if (response && response.status) {
                setSubmitting(false)
                setToaster('success')
                fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);
                setSelectedRows([])
                formik.setValues({
                    qa_or_snipping: "3",
                    assignedqueue: "",
                    staff: "",
                })
                // const timer = setTimeout(() => {
                //     setToaster(false)
                // }, 2000);
                // return () => clearTimeout(timer);
                handleResetCheckBox()
            }
            else {
                setSelectedRows([])
                setToaster('error')
                setSubmitting(false)
                // const timer = setTimeout(() => {
                //     setToaster(false)
                // }, 2000);
                // return () => clearTimeout(timer);
                handleResetCheckBox()

            }

        });
    };


    /////////////////////////////////////////////////////////////


    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);

    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        formik.setValues({
            ...formik.values,
            staff: "",
            qa_or_snipping: "3",
            assignedqueue: "",
        })

        setShowModal(false)
    };

    const handleShowModal = (id) => {

        formik.setValues({
            ...formik.values,
            assignedqueue: id,
        })
        setShowModal(true)
    };


    const [tatModal, setTatModal] = useState(false)
    const handleAssignTat = () => setTatModal(false);

    const handleShowTat = () => {
        setTatModal(true);
    }

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }

    const [settingsTat, setSettingsTat] = useState();



    const tatformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            tat: "",
            tat_time: "",
            date_of_service: ""
        },

        validationSchema: Yup.object().shape({
            date_of_service: Yup.date().required('Required'),
            tat_time: Yup.string().matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                'Invalid Time'),
        }),

        validate: (values) => {
            let errors = {};
            if (values.date_of_service == "") {
                errors.date_of_service = "Required"
            }

            if (!moment(values.date_of_service, "MM/DD/YYYY", true).isValid()) {
                errors.date_of_service = "Invalid Date"
            }

            if (values.date_of_service == "__/__/____") {
                errors.date_of_service = "Required"
            }

            if (values?.tat_time == "") {
                errors.tat_time = "Required"

            }

            return errors;
        },
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            // let formData = new FormData();    //formdata object
            // if (client_data?.id)
            //     formData.append('client', client_data?.id);
            // formData.append('client_id', values.client_id);
            // formData.append('name', values.name);
            // formData.append('address', values.address);
            // formData.append('city', values.city);
            // // console.log(dateTime)
            var dateTime = moment(tatDate).format('YYYY-MM-DD') + '-' + tatTime;

            // // console.log(new Date(dateTime))
            setTatModal(false);
            setGrandTotal({ ...grandTotal, isActive: false })

            getChangeJobTat(dateTime, selectedRows)((response) => {
                if (response && response.status) {
                    fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);
                    setToaster('success')
                    setSelectedRows([])
                    setSubmitting(false)
                    // const timer = setTimeout(() => {
                    //     setToaster(false)
                    // }, 2000);
                    // return () => clearTimeout(timer);
                    handleResetCheckBox()

                    tatformik.setValues({
                        ...tatformik.values,
                        date_of_service: "",
                        tat_time: "",
                    })

                    setTatDate("")
                    setTatTime("")

                }
                else {
                    setToaster('error')
                    setSelectedRows([])
                    setSubmitting(false)
                    tatformik.setValues({
                        ...tatformik.values,
                        date_of_service: "",
                        tat_time: "",
                    })

                    setTatDate("")
                    setTatTime("")

                    // const timer = setTimeout(() => {
                    //     setToaster(false)
                    // }, 2000);
                    return () => clearTimeout(timer);
                }
            });


        },
    })

    const [state, setState] = useState({
        deposit_date: true,
        processed_date: true,
        client: true,
        facility: true,
        job_file_name: true,
        job_id: true,
        job_status: true,
        lockbox_file_name: true,
        batch_id: true,
        document_type: true,
        internal_tat: true,
        external_tat: true,
        check_number: true,
        index_amount: true,
        check_amount: true,
        eft_amount: true,
        payer: true,
        no_of_pages: true,
        no_of_claims: true,
        no_of_service_lines: true,
        no_of_fields: true,
        no_of_corrected_fields: true,
        no_of_autofilled_fields: true,
        no_of_key_fields: true,
        data_grid_user: true,
        user_start: true,
        user_end: true,
        user_total_time: true,
        qa_user: true,
        qa_start_time: true,
        qa_end_time: true,
        qa_total_time: true,

        snipping_staff: true,
        snipping_start_time: true,
        snipping_end_time: true,
        snipping_total_time: true,

        additional_info_status: true,
        comment: true,
        action: true

        // reason_rejection: true,
        // lockbox_payee_name: true,
        // lockbox: true,

        // file_received_on: true,
        // status: true,


        // file_status: true,

    });
    const [loading, setLoading] = useState(false);

    const [releaseid, setReleaseId] = useState(null);
    const [togglePopup, setTogglePopup] = useState(false);

    const [toggleHistoryPopup, setToggleHistoryPopup] = useState({
        isShow: false,
        job_id: null,
    });
    const [historyData, setHistoryData] = useState([]);

    const [toggleReportPopup, setToggleReportPopup] = useState(false);
    const [reportData, setReportData] = useState([]);

    const [toggleHoldPopup, setToggleHoldPopup] = useState(false);

    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [selectedRows, setSelectedRows] = useState([]);
    const [colorRow, setColorRow] = React.useState({});
    const [data, setData] = useState({})

    const [filterText, setFilterText] = React.useState('');
    const [filterClient, setFilterClient] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const [isFocusedStaff, setIsFocusedStaff] = useState(false);
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedGroup, setIsFocusedGroup] = useState(false);
    const [isFocusedTat, setIsFocusedTat] = useState(false);
    const [isFocusedStatus, setIsFocusedStatus] = useState(false);

    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster(false)
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [toaster])

    const handleClientJobHistory = (id) => {
        clientJobHistory(id)((response) => {
            if (response && response.status) {
                setHistoryData(response?.data)
                setToggleHistoryPopup({
                    isShow: true,
                    job_id: id
                })
            } else {
                setToaster("error")
                setToggleHistoryPopup({
                    isShow: false,
                    job_id: id
                })

            }
        })
    }

    const handleMyWorkDataEntryReport = (id) => {
        myWorkDataEntryReport(id)((response) => {
            if (response && response.status) {
                setReportData(response?.data)
                setToggleReportPopup(true)
            } else {
                setToggleReportPopup(false)
                setToaster("error")
            }
        })
    }

    const tabs1 = useSelector((state) => state?.bottomTab);
    const tabs = window.parent.localStorage.getItem('bottomTab') ? JSON.parse(localStorage.getItem('bottomTab')) : [];
    const handleSingleReportDetails = async (log_id, status, isView = false) => {
        const name = status == 'Data Capture Completed' || status == 'Partial Save' ? 'View Work' : "View QA"
        const url = !isView ? toggleHistoryPopup.job_id + '?log_id=' + log_id : log_id
        let new_tab = {
            id: 0,
            route: `${window.location.origin}` + EDIT_WORK + url,
            // route: `${window.location.origin}` + EDIT_WORK + 1278 +'?log_id='+20208,
            label: name,
            prevTabs: [],
            isNewTab: false,
            title: name
        }
        // window.parent.localStorage.setItem('bbtabs', JSON.stringify(data))
        window.parent.localStorage.setItem('newTab', JSON.stringify(new_tab))

        parent.document.getElementById('bottom-tab-add').click()
    }

    const handleGenerateOutputFileSchema835 = (id) => {
        setGrandTotal({ ...grandTotal, isActive: false })

        generateOutputFileSchema835(id)((response) => {
            if (response && response.status) {
                // console.log(response);
                setToaster("success")
                fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);
                handleResetCheckBox()

            } else {

                setToastMessage({
                    status: true,
                    message: response?.message,
                    type: 'danger',
                })
                // setToaster("error")
            }
        })
    }

    const selectAllText = (e) => {
        // e.target.select()

        console.log(e.target.textContent);
    }

    const handleOutputXmlFileSchema = (id) => {
        generateOutputXmlFileSchema(id)((response) => {
            if (response && response.status) {
                // console.log(response);
                setToaster("success")
                fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);
            } else {
                setToaster("error")
            }
        })
    }

    const handleGenerateOutputFileSchema = (id) => {
        generateOutputFileSchema(id)((response) => {
            if (response && response.status) {
                setToaster("success")
                fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);
            } else {
                setToaster("error")
            }
        })
    }
    const handleResentOutputFileSchema = (id, type) => {
        if (type == 'PAYMENT835 lITE') {
            handleOutputXmlFileSchema(id)
        }
        else {
            resentOutputFileSchema(id)((response) => {
                if (response && response.status) {
                    setToaster("success")
                    fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);

                } else {
                    setToaster("error")
                }

            })
        }
    }

    const handleSerialNumber = (index) => {
        return pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1
    }

    const columns = useMemo(
        () => [
            {
                id: 'asdf',
                name: '',
                cell: (row) => <span style={{ position: 'absolute', right: '40px' }} > {row?.is_large_batch_job && !row?.is_sub_job && <TbBoxMultiple />}</span>
                ,
                width: "5px",
                compact: true,
                wrap: true,
                reorder: true,
                ignoreRowClick: false,

            },
            {
                id: 'id',
                name: 'S.NO',
                selector: (row, index) => handleSerialNumber(index),
                width: "30px",
                compact: true,
                wrap: true,
                reorder: true,
                ignoreRowClick: false,

            },
            {
                id: 'DEPOSIT DATE',
                name: 'DEPOSIT DATE',
                sort_key: "deposit_date",
                selector: row => moment(row?.deposit_date).format('M/DD/YY'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.deposit_date ? moment(row?.deposit_date).format('M/DD/YY') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.deposit_date,
            },
            {
                id: 'PROCESSED DATE',
                name: 'PROCESSED DATE',
                sort_key: "processed_date",
                selector: row => moment(row?.processed_date).format('M/DD/YY,HH:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.processed_date ? moment(row?.processed_date).format('M/DD/YY,HH:mm') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.processed_date,
            },

            {
                id: 'CLIENT',
                name: 'CLIENT',
                sort_key: "client",
                selector: row => row?.client,
                cell: (row) => <Tooltip disableInteractive title={row?.client} placement='top' arrow>
                    <span className='text-truncate' onClick={() => handleRowClick(row)} onDoubleClick={(e) => { handleDblClickCopy(row?.client); }}>{row?.client}</span>
                </Tooltip>,
                sortable: true,
                wrap: true,
                grow: 0.1,
                reorder: true,
                omit: !state.client,
                ignoreRowClick: false,

            },
            {
                id: 'FACILITY',
                name: 'FACILITY',
                sort_key: "facility",
                selector: row => row?.facility,
                cell: (row, index) => <div>
                    {row?.facility &&
                        <div onClick={() => handleRowClick(row)} className='overflow-hidden d-flex flex-row  flex-wrap  text-truncate ' style={{ width: "120px" }}>
                            <Tooltip disableInteractive title={row?.facility} placement='top' arrow>
                                {row?.facility &&
                                    <span key={index} className='text-truncate ' onDoubleClick={() => handleDblClickCopy(row?.facility)}>{row?.facility}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>
                ,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "140px",
                grow: 0.1,
                omit: !state.facility,
            },
            {
                id: 'JOB FILE NAME',
                name: 'JOB FILE NAME',
                sort_key: "job_file_name",
                sortable: true,
                selector: row => row?.job_file_name,
                cell: (row) => <div className='d-flex flex-row align-items-center gap-2' onClick={() => handleRowClick(row)}>
                    {row?.job_file_name &&
                        <React.Fragment>
                            <div style={{ width: '120px' }}
                                onDoubleClick={(e) => { handleDblClickCopy(row?.job_file_name); }}
                                className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' >
                                <Tooltip disableInteractive title={row?.job_file_name} placement='top' arrow>
                                    <span className='text-truncate' > {row?.job_file_name}</span>
                                </Tooltip>
                            </div>
                            <div
                                className='' >
                                <div role="button" onClick={() => onButtonClick(row?.splited_file)} className=''>
                                    <i className={'bi bi-download text-black '} style={{ fontSize: '1.3em' }} />
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>,
                width: '160px',
                left: true,
                wrap: true,
                reorder: true,
                grow: 1.3,
                omit: !state.job_file_name,
            },
            {
                id: 'JOB ID',
                name: 'JOB ID',
                sort_key: "job_id",
                selector: row => row?.job_id,
                cell: (row) =>
                    <span onClick={() => handleRowClick(row)} onDoubleClick={() => handleDblClickCopy(row?.job_id)}>
                        {row?.job_id}
                    </span>,
                sortable: true,
                wrap: true,
                width: "120px",
                reorder: true,
                omit: !state.job_id,


            },
            {
                id: 'JOB STATUS',
                name: 'JOB STATUS',
                sort_key: 'job_status',
                selector: row => row?.job_status,
                cell: (row) => <Tooltip disableInteractive title={row?.job_status} placement='top' arrow>
                    <span className='text-truncate' onClick={() => handleRowClick(row)}> {row?.job_status}</span>
                </Tooltip>,
                reorder: true,
                left: true,
                wrap: true,
                width: "130px",
                omit: !state.job_status,
            },

            {
                id: 'LOCKBOX FILE NAME',
                name: 'LOCKBOX FILE NAME',
                sort_key: "lockbox_file_name",
                sortable: true,
                selector: row => row?.lockbox_file_name,
                cell: (row) => <div className='d-flex flex-row gap-2' onClick={() => handleRowClick(row)}>
                    {row?.lockbox_file_name &&
                        <React.Fragment>

                            <div style={{ width: '120px' }} className='overflow-hidden d-flex flex-row  flex-wrap text-truncate'
                                onDoubleClick={() => handleDblClickCopy(row?.lockbox_file_name)}
                            >
                                <Tooltip disableInteractive title={row?.lockbox_file_name} placement='top' arrow>
                                    <span className='text-truncate'> {row?.lockbox_file_name}</span>
                                </Tooltip>
                            </div>
                            <div
                                className='' >

                            </div>
                        </React.Fragment>
                    }
                </div>,
                width: '140px',
                left: true,
                wrap: true,
                reorder: true,
                grow: 1.5,
                omit: !state.lockbox_file_name,
            },
            {
                id: 'BATCH ID',
                name: 'BATCH ID',
                sort_key: "batch_id",
                selector: row => row?.batch_id,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.batch_id}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.3,
                omit: !state.batch_id,
            },
            {
                id: 'DOCUMENT TYPE',
                name: 'DOCUMENT TYPE',
                sort_key: "document_type",
                selector: row => row?.document_type,
                cell: (row, index) => <div>
                    {row?.document_type &&
                        <div className='text-truncate d-flex flex-row  flex-wrap' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                            <Tooltip
                                disableInteractive
                                title={row?.document_type} placement='top' arrow>
                                {row?.document_type &&
                                    <span className='text-truncate' key={index} onDoubleClick={() => handleDblClickCopy(row?.document_type)}>{row?.document_type}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                width: "140px",
                omit: !state.document_type,
            },
            {
                id: 'INTERNAL TAT',
                name: 'INTERNAL TAT',
                sort_key: "internal_tat",
                selector: row => row?.internal_tat,
                cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}>{row.internal_tat ? moment(row.internal_tat).format('M/DD/YY, H:mm') : "---"}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.5,
                omit: !state.internal_tat,
            },

            {      // sachu told to remove this on 26-04-2023
                id: 'external_tat',
                name: 'External Tat',
                selector: row => row?.external_tat,
                cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}>{row.external_tat ? moment(row.external_tat).format('M/DD/YY, H:mm') : "---"}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.5,
                omit: !state.external_tat,
            },

            {
                id: 'Check Number',
                name: 'Check Number',
                sort_key: "check_number",
                selector: row => parseInt(row?.check_number ? row?.check_number : 0),
                cell: (row) => <div className=' '

                    onDoubleClick={() => handleDblClickCopy(row?.check_number)}
                    onClick={() => handleRowClick(row)}>
                    <span>

                        {row?.check_number}
                    </span>
                </div>,
                width: "110px",
                reorder: true,
                left: true,
                grow: 1.1,
                omit: !state.check_number,
            },

            {
                id: 'INDEX AMOUNT',
                name: 'INDEX AMOUNT',
                sort_key: "index_amount",
                sortable: true,
                selector: row => parseInt(row?.index_amount ? row?.index_amount : 0),
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap' onClick={() => handleRowClick(row)}>
                    {parseFloat(row?.index_amount ? row?.index_amount : 0).toFixed(2)}
                </div>,
                reorder: true,
                left: false,
                right: true,
                wrap: true,
                grow: 0.1,
                omit: !state.index_amount,
            },

            {
                id: 'CHECK AMOUNT',
                name: 'CHECK AMOUNT',
                sort_key: "check_amount",
                selector: row => parseInt(row?.check_amount ? row?.check_amount : 0),
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap' onClick={() => handleRowClick(row)}>
                    {parseFloat(row?.check_amount ? row?.check_amount : 0).toFixed(2)}
                </div>,
                reorder: true,
                left: false,
                right: true,
                wrap: true,
                grow: 0.1,
                omit: !state.check_amount,
            },

            {
                id: 'EFT AMOUNT',
                name: 'EFT AMOUNT',
                sort_key: "eft_amount",
                selector: row => parseInt(row?.eft_amount ? row?.eft_amount : 0),
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap' onClick={() => handleRowClick(row)}>
                    {parseFloat(row?.eft_amount ? row?.eft_amount : 0).toFixed(2)}
                </div>,
                reorder: true,
                left: false,
                right: true,
                wrap: true,
                grow: 0.2,
                omit: !state.eft_amount,
            },

            {
                id: 'PAYER',
                name: 'PAYER',
                sort_key: "payer",
                selector: row => row?.payer,
                cell: (row) =>
                    <div className=' d-flex flex-row flex-wrap text-truncate justify-content-start ' style={{ width: '120px' }} onClick={() => handleRowClick(row)}>
                        <Tooltip disableInteractive title={row?.payer} placement='top' arrow>
                            <span className='text-truncate'>{row?.payer}</span>

                        </Tooltip>
                    </div>,
                reorder: true,
                left: true,
                wrap: true,
                width: "140px",
                grow: 0.2,
                omit: !state.payer,
            },

            {
                id: '# PAGES',
                name: '# PAGES',
                sort_key: "no_of_pages",
                selector: row => row?.no_of_pages,
                cell: (row) => <div className='text-end'> <span onClick={() => handleRowClick(row)}> {row?.no_of_pages}</span></div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_pages,
            },

            {
                id: '# CLAIMS',
                name: '# CLAIMS',
                sort_key: "no_of_claims",
                selector: row => row?.no_of_claims,
                cell: (row) => <div className='text-end'> <span onClick={() => handleRowClick(row)}> {row?.no_of_claims}</span></div>,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_claims,
            },

            {
                id: 'SERVICE LINES',
                name: '# SERVICE LINES',
                sort_key: "no_of_service_lines",
                selector: row => row?.no_of_service_lines,
                cell: (row) => <div className='text-end'> <span onClick={() => handleRowClick(row)}> {row?.no_of_service_lines}</span></div>,
                sortable: true,
                width: '120px',
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_service_lines,
            },
            {
                id: 'FIELDS',
                name: '# FIELDS',
                sort_key: "no_of_fields",
                selector: row => row?.no_of_fields,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_fields}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_fields,
            },

            {
                id: 'CORRECTED FIELDS',
                name: '# CORRECTED FIELDS',
                sort_key: "no_of_corrected_fields",
                selector: row => row?.no_of_corrected_fields,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_corrected_fields}</span>,
                sortable: true,
                reorder: true,
                width: '120px',
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.no_of_corrected_fields,
            },
            {
                id: 'AUTOFILLED FIELDS',
                name: '# AUTOFILLED FIELDS',
                sort_key: "no_of_autofilled_fields",
                selector: row => row?.no_of_autofilled_fields,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_autofilled_fields}</span>,
                sortable: true,
                width: '120px',
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.no_of_autofilled_fields,
            },

            {
                id: 'KEYED FIELDS',
                name: '# KEYED FIELDS',
                sort_key: "no_of_key_fields",
                selector: row => row?.no_of_key_fields,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_key_fields}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.no_of_key_fields,
            },

            {
                id: 'USER NAME',
                name: 'USER NAME',
                sort_key: "user_name",
                reorder: true,
                sortable: true,
                omit: !state.data_grid_user,
                width: "140px",
                selector: row => row?.user_name,
                cell: (row) =>
                    <div className="d-flex justify-content-end text-truncate" onClick={() => handleRowClick(row)}>

                        {row?.status == "partial_save" ? (
                            <React.Fragment>
                                <Tooltip disableInteractive title={row?.user_name} placement='top' arrow>
                                    <span onClick={() => handleRowClick(row)} style={!row?.user_name ? { visibility: 'hidden' } : { visibility: 'show' }} className=' text-truncate '>

                                        {row?.user_name ? row?.user_name : "nodata"}
                                    </span>
                                </Tooltip>

                            </React.Fragment>
                        ) :
                            <React.Fragment>
                                <Tooltip disableInteractive title={row?.user_name} placement='top' arrow>
                                    <span className='text-truncate' onClick={() => handleRowClick(row)} style={!row?.user_name ? { visibility: 'hidden' } : { visibility: 'show' }}>

                                        {row?.user_name ? row?.user_name : "nodata"}

                                    </span>
                                </Tooltip>
                            </React.Fragment>
                        }

                        {row?.is_user_multiple &&
                            <div style={{ paddingLeft: "10px" }}>

                                <TbBoxMultiple />
                            </div>
                        }
                    </div>,

            },

            {
                id: 'USER START TIME',
                name: 'USER START TIME',
                sort_key: "user_start_time",
                selector: row => moment(row?.user_start_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.user_start_time ? moment(row?.user_start_time).format('M/DD/YY, H:mm') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.user_start,
            },
            {
                id: 'USER END TIME',
                name: 'USER END TIME',
                sort_key: "user_end_time",
                selector: row => moment(row?.user_end_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.user_end_time ? moment(row?.user_end_time).format('M/DD/YY, H:mm') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.user_end,
            },

            {
                id: 'USER TOTAL TIME',
                name: 'USER TOTAL TIME',
                sort_key: "user_total_time",
                selector: row => row?.user_total_time,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.user_total_time}</span>,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.user_total_time,
            },

            {
                id: 'QA NAME',
                name: 'QA NAME',
                sort_key: "qa_name",
                selector: row => row?.qa_name,
                cell: (row) =>
                    <div className="d-flex justify-content-between text-truncate" onClick={() => handleRowClick(row)}>


                        <Tooltip disableInteractive title={row?.qa_name} placement='top' arrow>
                            <span className='text-truncate' onClick={() => handleRowClick(row)} onDoubleClick={(e) => { handleDblClickCopy(row?.qa_name); }}>{row?.qa_name}</span>
                        </Tooltip>

                        {row?.is_multiple_qa &&
                            <div style={{ paddingLeft: "10px" }}>

                                <TbBoxMultiple />
                            </div>
                        }
                    </div>

                ,

                grow: 0.1,
                reorder: true,
                omit: !state.qa_user,
                allowOverflow: false,
                width: "140px",
            },
            {
                id: 'QA START TIME',
                name: 'QA START TIME',
                sort_key: "qa_start_time",
                selector: row => moment(row?.qa_start_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_start_time ? moment(row?.qa_start_time).format('M/DD/YY, H:mm') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.qa_start_time,
            },
            {
                id: 'QA END TIME',
                name: 'QA END TIME',
                sort_key: "qa_end_time",
                selector: row => moment(row?.qa_end_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_end_time ? moment(row?.qa_end_time).format('M/DD/YY, H:mm') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.qa_end_time,
            },
            {
                id: 'QA TOTAL TIME',
                name: 'QA TOTAL TIME',
                sort_key: "qa_total_time",
                selector: row => row?.qa_total_time,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.qa_total_time}</span>,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.qa_total_time,
            },


            {
                id: 'SNIPPING NAME',
                name: 'SNIPPING NAME',
                sort_key: "snipping_staff",
                selector: row => row?.snipping_staff,
                cell: (row) =>
                    <div className="d-flex justify-content-between text-truncate" onClick={() => handleRowClick(row)}>


                        <Tooltip disableInteractive title={row?.snipping_staff} placement='top' arrow>
                            <span className='text-truncate' onClick={() => handleRowClick(row)} onDoubleClick={(e) => { handleDblClickCopy(row?.snipping_staff); }}>{row?.snipping_staff}</span>
                        </Tooltip>

                        {/* {row?.is_multiple_snipping &&
                            <div style={{ paddingLeft: "10px" }}>

                                <TbBoxMultiple />
                            </div>
                        } */}
                    </div>

                ,

                grow: 0.1,
                reorder: true,
                omit: !state.snipping_staff,
                allowOverflow: false,
                width: "140px",
            },
            {
                id: 'SNIPPING START TIME',
                name: 'SNIPPING START TIME',
                sort_key: "snipping_start_time",
                selector: row => moment(row?.snipping_start_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.snipping_start_time ? moment(row?.snipping_start_time).format('M/DD/YY, H:mm') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.2,
                omit: !state.snipping_start_time,
            },
            {
                id: 'SNIPPING END TIME',
                name: 'SNIPPING END TIME',
                sort_key: "snipping_end_time",
                selector: row => moment(row?.snipping_end_time).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.snipping_end_time ? moment(row?.snipping_end_time).format('M/DD/YY, H:mm') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.snipping_end_time,
            },
            {
                id: 'SNIPPING TOTAL TIME',
                name: 'SNIPPING TOTAL TIME',
                sort_key: "snipping_total_time",
                selector: row => row?.snipping_total_time,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.snipping_total_time}</span>,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.snipping_total_time,
            },
            {
                id: 'ADDITIONAL INFO STATUS',
                name: 'ADDITIONAL INFO STATUS',
                sort_key: "additional_info_status",
                selector: row => row?.additional_info_status,
                cell: (row) => <span className='text-truncate' onClick={() => handleRowClick(row)}>{row.additional_info_status}</span>,
                reorder: true,
                left: true,
                width: '150px',
                wrap: true,
                omit: !state.additional_info_status,
            },

            {
                id: 'COMMENT',
                name: 'COMMENT',
                sort_key: "comment",
                selector: row => row?.comment,
                cell: (row, index) => <div style={{ cursor: 'pointer' }}>
                    {row?.comment &&
                        <div onClick={() => handleRowClick(row)} className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' style={{ width: '120px' }}>
                            <Tooltip disableInteractive title={row?.comment.toUpperCase()} placement='top' arrow>
                                {row?.comment &&
                                    <span style={{ cursor: 'pointer' }} key={index} className='text-truncate  text-uppercase' onDoubleClick={() => handleDblClickCopy(row?.comment)}>{row?.comment}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>,
                style: {
                    cursor: "pointer",
                },
                reorder: true,
                width: '140px',
                left: true,
                wrap: true,
                omit: !state.comment,
            },
            {
                id: 'action',
                name: 'Actions',
                width: '50px',
                reorder: true,
                cell: (row) =>
                    <React.Fragment>
                        {!row?.is_freeze &&
                            <div className="d-flex justify-content-center align-items-center gap-2 job-monitor-table-wrapper">
                                {row?.snippet_image && row?.snippet_image != '' ?
                                    <React.Fragment>
                                        <Tooltip disableInteractive title="Snippet Image" placement='top' arrow>
                                            <div className={''} style={{ fontSize: '1.5em' }}

                                                onClick={() => { handleFileDownload(row?.snippet_image) }}

                                            >
                                                <BiDownload color={"#000000"} />
                                            </div>
                                        </Tooltip>
                                    </React.Fragment>
                                    :
                                    <></>
                                }
                                {!["imported", "assigned", "inprocess", 'hold', 'rejected'].includes(row?.status) ? (
                                    <React.Fragment>
                                        <Tooltip disableInteractive title='View' placement='top' arrow>
                                            <div className={''} style={{ fontSize: '1.5em' }}

                                                onClick={() => { handleSingleReportDetails(row.id, 'Partial Save', true) }}

                                            >
                                                <BsEye color={"#000000"} />
                                            </div>
                                        </Tooltip>
                                    </React.Fragment>
                                )

                                    : null
                                }


                                <React.Fragment>

                                    <Tooltip disableInteractive title='History' placement='top' arrow>
                                        <Link className='' style={{ fontSize: '1.5em' }} onClick={() => handleClientJobHistory(row.id)} >
                                            <BsClockHistory color={"#000000"} />
                                        </Link>
                                    </Tooltip>
                                </React.Fragment>

                            </div>
                        }
                    </React.Fragment>
                ,
                right: true,
                omit: !state.action
            },

        ],

        [state, pageno, selectedRows, kunjani],
    );

    const handleFileDownload = (file) => {
        const fileURL = file;
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();


    }

    //         {console.log(selectedRows, row)}
    //         <span style={{ marginRight: '8px', marginLeft: "8px" }}><TbBoxMultiple /></span>
    //         {/* Replace the default checkbox with custom icon */}
    //         {selectedRows?.includes(row) ? (
    //             <BsAlarm size={20} />
    //         ) : (

    //             <MdOutlineCheckBox size={20} />
    //         )}

    //         {/* Add additional icon or text */}

    //     </div>
    // );

    const rowDisabledCriteria = (row) => {

        if (row.status == "rejected" && row.is_freeze) {
            return true
        }
        else if (row.is_large_batch_job && !row.is_sub_job && ['imported', 'assigned', 'inprocess', 'partial_save', 'output_generated', 'output_completed'].includes(row.status)) {
            return true
        }
        else if (row.is_large_batch_job && row.is_sub_job && ['qa_review', 'qa_complete', 'output_generated', 'output_completed', 'snipping', 'snipping_assigned', 'snipping_inprocess', 'snipping_complete'].includes(row.status)) {
            return true
        }
        else if (row.status == 'imported'
            || row.status == 'assigned'
            || row.status == 'inprocess'
            || row.status == 'datacaptured'
            || row.status == "partial_save"
            || row.status == 'qa_review'
            || row.status == 'qa_inprocess'
            || row.status == "qa_complete"
            || row.status == "ready_to_output_generate"
            || row.status == "output_generated"
            || row.status == "hold"
            || row.status == "rejected") {

            return false;
        }

    }




    const fetchQueueListing = async (page, limit, staff, client, client_group, status, filename, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station, deposit_date_range, processed_date_range) => {
        setLoading(true);
        let process_date = ""
        if (processed_date_range?.length > 0) {
            var processed_date_from = moment(processed_date_range[0]).format('MM/DD/YYYY HH:mm:ss');
            var processed_date_to = moment(processed_date_range[1]).format('MM/DD/YYYY HH:mm:ss');

            process_date = processed_date_from + "-" + processed_date_to
        }

        filterQueueDetails({ page, limit, staff, client, client_group, status, filename, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station, process_date })((response) => {
            setLoading(true)
            if (!response?.data?.status && response?.status) {

                setData(response);
                setPageNo(page)
                setDefaultValues(response?.data?.results?.default_values);
                setTotalRows(response?.data?.count);
                setLoading(false);

            } else {
                queueformik.setErrors({
                    ...queueformik.values,
                    filename: response?.response?.data?.errors?.username,
                    client: response?.response?.data?.errors?.client_id,
                    status: response?.response?.data?.errors?.name,

                })
            }
        });

    };

    const [kunjani, setKunjani] = useState(false)
    const fetchTotalQueueListing = async (page, limit, staff, client, client_group, status, filename, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station, element, processed_date_range) => {
        let process_date = ""
        if (processed_date_range?.length > 0) {
            var processed_date_from = moment(processed_date_range[0]).format('MM/DD/YYYY HH:mm:ss');
            var processed_date_to = moment(processed_date_range[1]).format('MM/DD/YYYY HH:mm:ss');

            process_date = processed_date_from + "-" + processed_date_to
        }
        setLoading(true);
        filterTotalQueueDetails({ page, limit, staff, client, client_group, status, filename, check_number, batch_id, start_date, end_date, sort_order, sort_key, tat_status, station, process_date })((response) => {

            if (response?.status) {

                setGrandTotalData({ ...grandTotalData, data: response.data })

                setTimeout(() => {
                    setKunjani(true)
                    setLoading(false);
                }, 200);


            }
        });

    };

    const handlePageChange = page => {

        setPageNo(page)
        fetchQueueListing(page, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);

    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setGrandTotal({ ...grandTotal, isActive: false })


        fetchQueueListing(1, newPerPage, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);

        setPageNo(1)

        setLimit(newPerPage);


        handleResetCheckBox()


    };


    const [clientList, setClientList] = useState();
    const [checking, setChecking] = useState(true)
    const [defaultValues, setDefaultValues] = useState({})

    useEffect(() => {
        const tabs_json = localStorage.getItem("bottomTab")
        const tabs = JSON.parse(tabs_json);
        const filterData = tabs?.currentTab?.filterData
        let deposit_date_range = ""

        if (lockbox_file) {
            fetchQueueListing(1, limit, null, null, null, null, lockbox_file, null, null, null, null, null, null, searchedData?.processed_date_range);

            queueformik.setValues({
                ...queueformik.values,
                filename: lockbox_file,
            })

            setSearchedData({
                ...searchedData,
                filename: lockbox_file,
            })
        }
        else if (filterData && Object.keys(filterData).length !== 0) {
            setSearchedData(filterData)
            if (filterData.start_date && filterData.end_date) {
                deposit_date_range = [new Date(filterData.start_date), new Date(filterData.end_date)]
            }
            queueformik.setValues({
                ...filterData,
                deposit_date_range
            })
            fetchQueueListing(1, limit, filterData?.staff, filterData?.client, filterData?.client_group, filterData?.status, filterData?.filename, filterData?.check_number, filterData?.batch_id, filterData?.start_date, filterData?.end_date, sortOrder, sortColumn, filterData?.tat_status, filterData?.station, null, filterData?.processed_date_range)

        }
        else {
            fetchQueueListing(1, limit, null, null, null, null, null, null, null, null, null, null, null, null, null, null, searchedData?.processed_date_range);
        }

        getAllClientDropdown()((response) => {

            setClientList(response?.data)
        })
    }, []);

    useEffect(() => {

        if (!!checking && defaultValues?.client) {

            setState({
                deposit_date: defaultValues?.deposit_date,
                processed_date: defaultValues?.processed_date,
                client: defaultValues?.client,
                facility: defaultValues?.facility,
                job_file_name: defaultValues?.job_file_name,
                job_id: defaultValues?.job_id,
                job_status: defaultValues?.job_status,
                lockbox_file_name: defaultValues?.lockbox_file_name,
                batch_id: defaultValues?.batch_id,
                document_type: defaultValues?.document_type,
                internal_tat: defaultValues?.internal_tat,
                external_tat: defaultValues?.external_tat,
                check_number: defaultValues?.check_number,
                index_amount: defaultValues?.index_amount,
                check_amount: defaultValues?.check_amount,
                eft_amount: defaultValues?.eft_amount,
                payer: defaultValues?.payer,
                no_of_pages: defaultValues?.no_of_pages,
                no_of_claims: defaultValues?.no_of_claims,
                no_of_service_lines: defaultValues?.no_of_service_lines,
                no_of_fields: defaultValues?.no_of_fields,
                no_of_corrected_fields: defaultValues?.no_of_corrected_fields,
                no_of_autofilled_fields: defaultValues?.no_of_autofilled_fields,
                no_of_key_fields: defaultValues?.no_of_key_fields,
                data_grid_user: defaultValues?.data_grid_user,
                user_start: defaultValues?.user_start,
                user_end: defaultValues?.user_end,
                user_total_time: defaultValues?.user_total_time,

                qa_user: defaultValues?.qa_user,
                qa_start_time: defaultValues?.qa_start_time,
                qa_end_time: defaultValues?.qa_end_time,
                qa_total_time: defaultValues?.qa_total_time,

                snipping_staff: defaultValues?.snipping_staff,
                snipping_start_time: defaultValues?.snipping_start_time,
                snipping_end_time: defaultValues?.snipping_end_time,
                snipping_total_time: defaultValues?.snipping_total_time,

                additional_info_status: defaultValues?.additional_info_status,
                comment: defaultValues?.comment,
                action: true,

            })
            setChecking(false)
        }

    }, [defaultValues]);

    const handleDblClickCopy = async (filename) => {
        navigator.clipboard.writeText(filename)

    };

    const handleResetCheckBox = () => {
        setUnselectCheck(true)
        setTimeout(() => {
            setUnselectCheck(false)
        }, 200);
    }

    const onButtonClick = (file) => {
        const fileURL = file;
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();
    }

    const [searchedData, setSearchedData] = useState({

        filename: "",
        client: "",
        client_group: "",
        staff: "",
        status: "",
        tat_status: "",
        station: "",
        check_number: "",
        batch_id: "",
        deposit_date_range: [],
        processed_date_range: [],
        start_date: "",
        end_date: "",
    });


    const queueformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            filename: "",
            client: "",
            client_group: "",
            staff: "",
            status: "",
            tat_status: "",
            station: "",
            check_number: "",
            batch_id: "",
            deposit_date_range: [],
            start_date: "",
            end_date: "",
            processed_date_range: []

        },
        onSubmit: (values) => {

            setGrandTotal({ ...grandTotal, isActive: false })

            fetchQueueListing(1, limit, values?.staff, values.client, values.client_group, values.status, values?.filename, values?.check_number, values?.batch_id, values?.start_date, values?.end_date, sortOrder, sortColumn, values?.tat_status, values?.station, values?.deposit_date_range, values?.processed_date_range)
            const filter = {
                filename: values?.filename ? values?.filename : "",
                client: values?.client ? values?.client : "",
                client_group: values?.client_group ? values?.client_group : "",
                staff: values?.staff ? values?.staff : "",
                status: values?.status ? values?.status : "",
                tat_status: values?.tat_status ? values?.tat_status : "",
                station: values?.station ? values?.station : "",
                check_number: values?.check_number ? values?.check_number : "",
                batch_id: values?.batch_id ? values?.batch_id : "",
                deposit_date_range: [],
                start_date: values?.start_date ? values?.start_date : "",
                end_date: values?.end_date ? values?.end_date : "",
                processed_date_range: values?.processed_date_range
            }
            let filterData = JSON.stringify(filter);
            setfiltrationHistoryBottomTab(filterData)

            setSearchedData(filter)
            handleResetCheckBox()
            setSelectedRows([])

        },
    });

    const [filterState, setFilterState] = useState([{
        staff: [],
        client: [],
        client_group: [],
    }])

    const handleOnDateTimeChange = ({ name, value }) => {
        let start_date = ''
        let end_date = ''
        if (value && value != "") {

            start_date = moment(value['0']).format('MM/DD/YYYY')
            end_date = moment(value['1']).format('MM/DD/YYYY')

        }

        queueformik.setValues({
            ...queueformik.values,
            deposit_date_range: value ?? "",
            start_date,
            end_date,
        })
    }
    const handleOnMultySelectChange = async (selected, name) => {
        let selectedIds = [];

        selected.map((option) => {
            selectedIds.push(option.value)
        })

        queueformik.setValues({ ...queueformik.values, [name]: selectedIds });


        setFilterState({
            ...filterState,
            [name]: selected
        })

    }

    const handleTatStatusChange = async (selected, name) => {
        queueformik.setValues({ ...queueformik.values, [name]: selected?.value ?? "" });
    }

    const handleStationChange = (selected, name) => {
        queueformik.setValues({ ...queueformik.values, [name]: selected?.value ?? "" })
    }

    const tat_Statuses = [
        { value: "1", label: 'TAT Nearby' },
        { value: "2", label: 'TAT Missed' },
    ];

    const station_option = [
        { value: "1", label: "My Work" },
        { value: "2", label: "QA Review" },
        { value: "3", label: "Snipping" },

    ]

    const status_options = [
        { value: "imported", label: 'Imported' },
        { value: "assigned", label: 'Assigned' },
        { value: "inprocess", label: 'In Process' },
        { value: "datacaptured", label: 'Data Capture Completed' },
        { value: "partial_save", label: 'Partial Save' },
        { value: "re_assigned", label: 'Re Assigned' },
        { value: "qa_review", label: 'QA Review' },
        { value: "qa_assigned", label: 'QA Assigned' },
        { value: "qa_inprocess", label: 'QA In Process' },
        { value: "qa_complete", label: 'QA Completed' },
        { value: "ready_to_output_generate", label: 'Ready To Generate' },
        { value: "output_generated", label: 'Output Generated' },
        // { value: "output_completed", label: 'Output Completed' },
        // { value: "archived", label: 'Archived' },
        { value: "rejected", label: 'Rejected' },
        { value: "hold", label: 'On Hold' },

        { value: "snipping", label: 'Snipping' },
        { value: "snipping_assigned", label: 'Snipping Assigned' },
        { value: "snipping_inprocess", label: 'Snipping In Process' },
        { value: "snipping_complete", label: 'Snipping Completed' },

    ];
    const handleUserSelectChange = async (e) => {

        await formik.setValues({
            ...formik.values,
            staff: e?.value ?? "",
        })

    }



    const formatOptionLabel = ({ value, label, is_logged_in }) => (
        <div className='d-flex flex-row justify-content-between align-items-center'>
            <div>{label}</div>
            {is_logged_in ?
                <div className='ms-2 dot' />

                :
                <div></div>
            }
        </div>
    );


    const [selectedData, setSelectedData] = useState([])
    const [unselectCheck, setUnselectCheck] = useState(false)
    const handleRowSelected = React.useCallback(state => {
        console.log(state);
        var ids = [];
        var datas = [];
        state.selectedRows.map((row, index) => {
            ids.push(row.id);
            datas.push(row);
        })
        setSelectedRows(ids);
        setSelectedData(datas)
    }, []);


    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }


    const handleRowClick = (row) => {
        setColorRow(row);
    };

    const handleRowClickCheck = (row) => {

        data?.data?.results?.data.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
    }

    const handleRowDoubleClick = (row) => {
        setSelectedRows(row);
    };

    var i = 0;

    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            }),
        },

        {
            when: row => row?.internal_tat_diff < 0 && row?.status !== "output_generated" && row?.status !== "output_completed",
            style: {
                backgroundColor: '#e89d9d',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            },
        },

        {
            when: row => colorRow.id == row.id && row?.internal_tat_diff < 0 && row?.status !== "output_generated" && row?.status !== "output_completed",
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id && row?.internal_tat_diff < 0 && row?.status !== "output_generated" && row?.status !== "output_completed" ? '#dbf4f5' : '#f2f2f2',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            }),
        },

        {
            when: row => row?.internal_tat_diff >= 0 && row?.internal_tat_diff <= 2 && row?.status !== "output_generated" && row?.status !== "output_completed",
            style: {
                backgroundColor: '#f4f475',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            },
        },

        {
            when: row => colorRow.id == row.id && row?.internal_tat_diff > 0 && row?.internal_tat_diff <= 2 && row?.status !== "output_generated" && row?.status !== "output_completed",
            style: (row, index) => ({
                backgroundColor: colorRow.id == row.id && row?.internal_tat_diff > 0 && row?.internal_tat_diff <= 2 ? '#dbf4f5' : '#f2f2f2',
                color: 'black',
                fontWeight: '550',
                position: 'relative'
            }),
        },
    ];

    const handleTotalJobMonitor = async () => {
        const duplicateRdtTable = secondDiv?.querySelector(".custome-rdt_TableHead");
        if (duplicateRdtTable) {
            if (duplicateRdtTable.classList.contains('none-div-class')) {
                // if the element has the "none-div-class" class, remove it and add the "display-div-class" class
                duplicateRdtTable.classList.remove('none-div-class');
                duplicateRdtTable.classList.add('display-div-class');
                setGrandTotal({ ...grandTotal, isActive: true })

                // fetch the data and update the table
                fetchTotalQueueListing(pageno, limit, searchedData?.staff, searchedData?.client, searchedData?.client_group, searchedData?.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, duplicateRdtTable, searchedData?.processed_date_range);

            } else {
                // if the element does not have the "none-div-class" class, remove the "display-div-class" class and add the "none-div-class" class
                setGrandTotal({ ...grandTotal, isActive: false })
                duplicateRdtTable.classList.remove('display-div-class');
                duplicateRdtTable.classList.add('none-div-class');

            }
        } else {
            if (grandTotal.isActive) {
                setGrandTotal({ ...grandTotal, isActive: false })
            } else {
                setGrandTotal({ ...grandTotal, isActive: true })

            }
            fetchTotalQueueListing(pageno, limit, searchedData?.staff, searchedData?.client, searchedData?.client_group, searchedData?.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);

        }
        // check if the element has the "none-div-class" class

    }

    // adding total row in the bottom of the data table START
    const secondDiv = document.querySelector(".custom-datatable");

    useEffect(() => {
        if (!secondDiv) return;

        const observer = new MutationObserver(mutations => {
            const duplicateRdtTable = secondDiv.querySelector(".custome-rdt_TableHead");
            if (duplicateRdtTable) duplicateRdtTable.remove();

            const rdtTable = secondDiv.querySelector(".rdt_TableHead");
            if (!rdtTable) return;

            const clonedTable = rdtTable.cloneNode(true);
            clonedTable.classList.add("custome-rdt_TableHead");
            if (grandTotal.isActive) {
                clonedTable.classList.remove("none-div-class");
                clonedTable.classList.add("display-div-class");
            }
            else {

                clonedTable.classList.add("none-div-class");
                clonedTable.classList.remove("display-div-class");
                // setGrandTotal({ ...grandTotal, isActive: false })


            }

            clonedTable.querySelectorAll(".rdt_TableCol").forEach(cell => {
                const inputElement = cell.querySelector("input");
                const spanElement = cell.querySelector("span");
                if (spanElement) spanElement.remove();
                if (inputElement) inputElement.remove();

                const divElement = cell.querySelector("div");
                const contentDiv = divElement?.querySelector("div");
                if (contentDiv) {
                    switch (contentDiv.textContent) {
                        case "USER TOTAL TIME":
                            divElement.setAttribute("id", "id_user_total_time")
                            divElement.innerHTML = grandTotalData.data.user_total_time ?? "";
                            break;
                        case "QA TOTAL TIME":
                            divElement.classList.add("qa_total_time")

                            divElement.innerHTML = grandTotalData.data.qa_total_time ?? "";
                            break;
                        case "SNIPPING TOTAL TIME":
                            divElement.classList.add("snipping_total_time")

                            divElement.innerHTML = grandTotalData.data.snipping_total_time ?? "";
                            break;
                        case "INDEX AMOUNT":
                            divElement.classList.add("index_amount")

                            divElement.innerHTML = grandTotalData.data.index_amount ? grandTotalData.data.index_amount.toFixed(2) : 0;
                            break;
                        case "CHECK AMOUNT":
                            divElement.classList.add("check_amount")

                            divElement.innerHTML = grandTotalData.data.check_amount ? grandTotalData.data.check_amount.toFixed(2) : 0;
                            break;
                        case "EFT AMOUNT":
                            divElement.classList.add("eft_amount")

                            divElement.innerHTML = grandTotalData.data.eft_amount ? grandTotalData.data.eft_amount.toFixed(2) : 0;

                            break;
                        case "# PAGES":
                            divElement.classList.add("pages")

                            divElement.innerHTML = grandTotalData.data.no_of_pages ?? "";
                            break;
                        case "# CLAIMS":
                            divElement.classList.add("claims")

                            divElement.innerHTML = grandTotalData.data.no_of_claims ?? "";
                            break;
                        case "# SERVICE LINES":
                            divElement.classList.add("service_lines")

                            divElement.innerHTML = grandTotalData.data.no_of_service_lines ?? "";
                            break;
                        case "# FIELDS":
                            divElement.classList.add("fields")

                            divElement.innerHTML = grandTotalData.data.no_of_fields ?? "";
                            break;
                        case "# CORRECTED FIELDS":
                            divElement.classList.add("corrected_fields")

                            divElement.innerHTML = grandTotalData.data.no_of_corrected_fields ?? "";
                            break;
                        case "# KEYED FIELDS":
                            divElement.innerHTML = grandTotalData.data.no_of_key_fields ?? "";
                            break;
                        case "# AUTO FILLED FIELDS":
                            divElement.innerHTML = grandTotalData.data.no_of_autofilled_fields ?? "";
                            break;
                        default:
                            divElement.innerHTML = "";
                    }
                }
            });

            const customeRdtTable = secondDiv.querySelector(".rdt_Table");
            customeRdtTable.appendChild(clonedTable);

            observer.disconnect();
        });

        observer.observe(secondDiv, { childList: true, subtree: true });
    }, [secondDiv, loading, state, grandTotal, grandTotalData.data]);
    // adding total row in the bottom of the data table END

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {filterState && filterState[state] && filterState[state].length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };



    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })
    useEffect(() => {
        if (Object.keys(grandTotalData.data).length !== 0) {
        }
    }, [grandTotalData.data])


    const handleRejectJob = () => {
        Swal.fire({
            icon: 'warning',
            confirmButtonColor: '#6ba2b6',
            confirmButtonText: 'Yes',
            showCloseButton: true,
            cancelButtonText: 'No',
            showCancelButton: true,
            title: 'Do you want to reject the jobs?',
            reverseButtons: true,
            customClass: {
                title: 'fs-4'
            }
            // text: 'Session',
        }).then((result) => {

            if (result.isConfirmed) {
                rejectJobApi(selectedRows)((response) => {

                    if (response?.status) {
                        handleResetCheckBox()
                        fetchQueueListing(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, null, searchedData?.processed_date_range);
                        setSelectedRows([])

                    }
                });
            }

        })

    }
    const getSelectedValues = (list, item) => {
        return list?.filter((i) => item?.includes(i?.value))
    }


    const handleDatePickerOpen = (e) => {
        // Set initial display time values when the picker is opened
        // Assuming you have a reference to the button element
        const timeButton = document.querySelector('.rs-calendar-header-title.rs-calendar-header-title-time.rs-btn.rs-btn-subtle.rs-btn-xs');

        // Set default time
        const defaultTime = '00:00';
        timeButton.textContent = defaultTime;
    };
    const [defaultCalendarValue, setDefaultCalendarValue] = useState(new Date());
    const [hoverRange, setHoverRange] = useState([
        new Date(new Date().setHours(0, 0, 0, 0)), // Start of the day
        new Date(new Date().setHours(23, 59, 59, 999)), // End of the day
    ]);
    const changeTimeZone = (e) => {
        console.log(e)
        let timeview = document.getElementsByClassName('rs-calendar-header-title-time')
        let items = document.querySelectorAll('ul[data-type="hours"]')
        let first = timeview[0];
        let second = timeview[1];
        if (first) {
            first.textContent = '00:00'
            let lists = items[0].querySelectorAll('li')
            Array.from(lists).map((list, ind) => {
                let aval = list.querySelector('a');
                if (aval.classList.contains('rs-calendar-time-dropdown-cell-active')) {
                    aval.classList.remove('rs-calendar-time-dropdown-cell-active')
                }
                if (ind == 0) {
                    aval.classList.add('rs-calendar-time-dropdown-cell-active')
                }
            })

        }
        if (second) {
            second.textContent = '23:59'
        }
    }

    const checkAssignDisabled = (data) => {
        console.log(data);
        // disabled={!selectedData?.every((el) => el?.status === "imported" || el?.status === "qa_review" || el?.status === "inprocess" || el?.status === "datacaptured" || el?.status === "partial_save" || el?.status === "re_assigned" || el?.status === "qa_inprocess" || el?.status === "qa_complete" || el?.status === "ready_to_output_generate" || el?.status === "rejected" || el?.status === "qa_assigned" || el?.status === "snipping" || el?.status === "snipping_assigned" || el?.status === "snipping_inprocess" || el?.status === "snipping_complete")}
        if (data.find((el) => el?.status === "snipping_complete" || el?.status === "snipping_inprocess" || el?.status === "snipping_assigned" || el?.status === "snipping")) {
            if (data.find((el) => el?.status != "snipping_complete" && el?.status != "snipping_inprocess" && el?.status != "snipping_assigned" && el?.status != "snipping")) {
                return true
            }
            else {
                return false
            }
        }
        else if (data?.every((el) => el?.status === "imported" || el?.status === "qa_review" || el?.status === "inprocess" || el?.status === "datacaptured" || el?.status === "partial_save" || el?.status === "re_assigned" || el?.status === "qa_inprocess" || el?.status === "qa_complete" || el?.status === "ready_to_output_generate" || el?.status === "rejected" || el?.status === "qa_assigned")) {
            return false
        }
        else {
            return true
        }
    }
    return (
        <React.Fragment>

            <AdminMaster ToastMessage={toaster} />




            <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                    {
                        status: false,
                        message: "",
                        type: "",
                    }

                )}
                    show={ToastMessage?.status} animation={true} delay={2000} autohide>
                    <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                </Toast>

            </ToastContainer>


            <main id="main" className="main">


                <div className="pagetitle tab-fixed-header ">
                    <h1 id='page-title'>Job Monitor</h1>

                </div>
                <section className="section mt-5">



                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card  mb-xl-8 py-2">
                                <Form onSubmit={queueformik.handleSubmit}
                                    onReset={() => {
                                        handleResetCallFetch()
                                    }}
                                >
                                    <div className=" ">
                                        <div className=" px-2  grid-job-monitor-select">

                                            <div className='' >

                                                <Select
                                                    tabSelectsValue={false}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    options={staffList}
                                                    isClearable
                                                    formatOptionLabel={formatOptionLabel}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="staff" /> }}
                                                    className="select-search-job-monitor"
                                                    classNamePrefix="select"
                                                    placeholder=" User"
                                                    name="staff"
                                                    onFocus={() => setIsFocusedStaff(true)}
                                                    onBlur={() => setIsFocusedStaff(false)}
                                                    onChange={(e) => handleOnMultySelectChange(e, 'staff')}
                                                    value={getSelectedValues(staffList, queueformik?.values?.staff)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px",
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedStaff ? "auto" : "30px",
                                                        }),
                                                    }}
                                                />

                                            </div>
                                            <div className=''>
                                                <Select
                                                    tabSelectsValue={false}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    options={clientList}
                                                    className="select-search"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client" /> }}
                                                    classNamePrefix="select"
                                                    placeholder="Clients"
                                                    isClearable
                                                    // hideSelectedOptions={false}
                                                    name="client"
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    onChange={(e) => handleOnMultySelectChange(e, 'client')}
                                                    value={getSelectedValues(clientList, queueformik?.values?.client)}

                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px",
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),
                                                    }} />
                                            </div>
                                            <div className=''>
                                                <Select
                                                    tabSelectsValue={false}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    options={clientGroupList}
                                                    className="select-search"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client_group" /> }}
                                                    classNamePrefix="select"
                                                    placeholder=" Client Group"
                                                    isClearable
                                                    // hideSelectedOptions={false}
                                                    name="client_group"
                                                    onFocus={() => setIsFocusedGroup(true)}
                                                    onBlur={() => setIsFocusedGroup(false)}
                                                    onChange={(e) => handleOnMultySelectChange(e, 'client_group')}
                                                    value={getSelectedValues(clientGroupList, queueformik?.values?.client_group)}

                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px",
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedGroup ? "auto" : "30px",
                                                        }),
                                                    }} />
                                            </div>
                                            <div className='-tat'>
                                                <Select
                                                    tabSelectsValue={false}
                                                    options={tat_Statuses}
                                                    className="select-search"
                                                    classNamePrefix="select"
                                                    placeholder="TAT Status"
                                                    isClearable
                                                    name="tat_status"
                                                    onFocus={() => setIsFocusedTat(true)}
                                                    onBlur={() => setIsFocusedTat(false)}
                                                    onChange={(e) => handleTatStatusChange(e, 'tat_status')}
                                                    value={getSelectedValues(tat_Statuses, queueformik?.values?.tat_status)}

                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            //  minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedTat ? "auto" : "30px",
                                                        }),
                                                    }}
                                                />
                                            </div>
                                            <div className=''>

                                                <Select
                                                    tabSelectsValue={false}
                                                    options={station_option}
                                                    className="select-search"
                                                    classNamePrefix="select"
                                                    //components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="status" /> }}
                                                    placeholder="Station"
                                                    isClearable
                                                    name="station"
                                                    //onFocus={() => setIsFocusedStatus(true)}
                                                    //onBlur={() => setIsFocusedStatus(false)}
                                                    onChange={(e) => handleStationChange(e, 'station')}
                                                    value={getSelectedValues(station_option, queueformik?.values?.station)}

                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px", minHeight: "20px"
                                                        }),

                                                        // valueContainer: (styles, state) => ({
                                                        //     ...styles,
                                                        //     maxHeight: isFocusedStatus ? "auto" : "30px",
                                                        // }),
                                                    }}
                                                />
                                            </div>
                                            <div className=''>

                                                <Select
                                                    tabSelectsValue={false}
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    options={status_options}
                                                    className="select-search"
                                                    classNamePrefix="select"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="status" /> }}
                                                    placeholder="Status"
                                                    isClearable
                                                    // hideSelectedOptions={false}
                                                    name="status"
                                                    onFocus={() => setIsFocusedStatus(true)}
                                                    onBlur={() => setIsFocusedStatus(false)}
                                                    onChange={(e) => handleOnMultySelectChange(e, 'status')}
                                                    value={getSelectedValues(status_options, queueformik?.values?.status)}

                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px",
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedStatus ? "auto" : "30px",
                                                        }),
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className=" px-2  grid-job-monitor-input">

                                            <div className=''>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid  b-r-10"
                                                    name='filename'
                                                    type="search"
                                                    style={{ width: "100%" }}
                                                    placeholder="Filename / Job Id"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.filename}
                                                    // value={input.file}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>
                                            <div className=''>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid  b-r-10"
                                                    name='check_number'
                                                    type="search"
                                                    style={{ width: "100%" }}
                                                    placeholder="Check Number"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.check_number}
                                                    // value={input.file}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>
                                            <div className=''>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid  b-r-10"
                                                    name='batch_id'
                                                    type="search"
                                                    style={{ width: "100%" }}
                                                    placeholder="Batch ID"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.batch_id}
                                                    // value={input.file}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>
                                            <Tooltip disableInteractive title={"Deposit Date"} placement="top" arrow>
                                                <div className=''>
                                                    <Stack direction="column" spacing={8} alignItems="flex-start">
                                                        <DateRangePicker
                                                            ranges={predefinedBottomRanges}
                                                            cleanable={true}
                                                            appearance="default"
                                                            format={"MM/dd/yyyy"}
                                                            placement='auto'
                                                            placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                                                            className="form-date-control form-control-solid b-r-1 text-dark jobmonitor-datepicker"
                                                            name="deposit_date_range"
                                                            onChange={(e) => { handleOnDateTimeChange({ name: 'deposit_date_range', value: e }) }}
                                                            value={queueformik?.values?.deposit_date_range}
                                                        />
                                                    </Stack>

                                                </div>
                                            </Tooltip>
                                            <Tooltip disableInteractive title={"Processed Date"} placement="top" arrow>

                                                <div className=''>
                                                    <Stack direction="column" spacing={8} alignItems="flex-start">
                                                        <DateRangePicker
                                                            ranges={predefinedBottomRanges}
                                                            cleanable={true}
                                                            defaultCalendarValue={[new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 59)]}
                                                            appearance="default"
                                                            format={"MM/dd/yyyy HH:mm"}
                                                            // format="yyyy-MM-dd HH:mm:ss"
                                                            placement='auto'
                                                            placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                                                            className="form-date-control form-control-solid b-r-1 text-dark jobmonitor-datepicker"
                                                            name="processed_date_range"
                                                            // onOpen={() => changeTimeZone()}
                                                            onChange={(e) => {
                                                                let processed_date_range = []
                                                                queueformik.setValues({
                                                                    ...queueformik.values,
                                                                    processed_date_range: e
                                                                })
                                                            }}
                                                        />
                                                    </Stack>

                                                </div>
                                            </Tooltip>
                                            {/* <DateTimePicker queueformik={queueformik} predefinedBottomRanges={predefinedBottomRanges} /> */}
                                            <div>
                                                <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary jobMonitor-Buttons " type="submit">
                                                        <span className="svg-icon svg-icon-3 ">
                                                            <BiSearch className='jobMonitor-svg' />
                                                        </span>

                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary  jobMonitor-Buttons"
                                                        //onClick={handleResetCallFetch}
                                                        type="reset"
                                                    >
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiReset className='jobMonitor-svg' />
                                                        </span>

                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Table Headings" placement='top' arrow>
                                                    <div className="dropdown">
                                                        <button className="btn btn-light btn-active-primary pb-2 dropdown-toggle  jobMonitor-Buttons" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <IoIosSwitch className='jobMonitor-svg' />
                                                        </button>
                                                        <ul className="dropdown-menu hide-scroll-bar" style={{ height: '500px', overflowX: 'scroll' }} onClick={(e) => e.stopPropagation()}>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.deposit_date} onChange={() => setState({ ...state, deposit_date: !state.deposit_date })} type="checkbox" id="deposit_date" />
                                                                        <label className="form-check-label" htmlFor="deposit_date">
                                                                            Deposit Date
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.processed_date} onChange={() => setState({ ...state, processed_date: !state.processed_date })} type="checkbox" id="processed_date" />
                                                                        <label className="form-check-label" htmlFor="processed_date">
                                                                            Processed Date
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.client} onChange={() => setState({ ...state, client: !state.client })} type="checkbox" id="client" />
                                                                        <label className="form-check-label" htmlFor="client">
                                                                            Client
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.facility} onChange={() => setState({ ...state, facility: !state.facility })} type="checkbox" id="facility" />
                                                                        <label className="form-check-label" htmlFor="facility">
                                                                            Facility
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.job_file_name} onChange={() => setState({ ...state, job_file_name: !state.job_file_name })} type="checkbox" id="job_file_name" />
                                                                        <label className="form-check-label" htmlFor="job_file_name">
                                                                            Job File Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.job_id} onChange={() => setState({ ...state, job_id: !state.job_id })} type="checkbox" id="job" />
                                                                        <label className="form-check-label" htmlFor="job">
                                                                            Job id
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.job_status} onChange={() => setState({ ...state, job_status: !state.job_status })} type="checkbox" id="job_status" />
                                                                        <label className="form-check-label" htmlFor="job_status">
                                                                            Job Status
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.lockbox_file_name} onChange={() => setState({ ...state, lockbox_file_name: !state.lockbox_file_name })} type="checkbox" id="lockbox_file_name" />
                                                                        <label className="form-check-label" htmlFor="lockbox_file_name">
                                                                            Lockbox File Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.batch_id} onChange={() => setState({ ...state, batch_id: !state.batch_id })} type="checkbox" id="batch_id" />
                                                                        <label className="form-check-label" htmlFor="batch_id">
                                                                            Batch Id
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.document_type} onChange={() => setState({ ...state, document_type: !state.document_type })} type="checkbox" id="document_type" />
                                                                        <label className="form-check-label" htmlFor="document_type">
                                                                            Document Type
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.internal_tat} onChange={() => setState({ ...state, internal_tat: !state.internal_tat })} type="checkbox" id="internal_tat" />
                                                                        <label className="form-check-label" htmlFor="internal_tat">
                                                                            Internal Tat
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            {/*    // sachu told to remove this  26-04-2023 */}
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.external_tat} onChange={() => setState({ ...state, external_tat: !state.external_tat })} type="checkbox" id="external_tat" />
                                                                        <label className="form-check-label" htmlFor="external_tat">
                                                                            External Tat
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.check_number} onChange={() => setState({ ...state, check_number: !state.check_number })} type="checkbox" id="check_number" />
                                                                        <label className="form-check-label" htmlFor="check_number">
                                                                            Check Number
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.index_amount} onChange={() => setState({ ...state, index_amount: !state.index_amount })} type="checkbox" id="index_amount" />
                                                                        <label className="form-check-label" htmlFor="index_amount">
                                                                            index amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.check_amount} onChange={() => setState({ ...state, check_amount: !state.check_amount })} type="checkbox" id="check_amount" />
                                                                        <label className="form-check-label" htmlFor="check_amount">
                                                                            check amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.eft_amount} onChange={() => setState({ ...state, eft_amount: !state.eft_amount })} type="checkbox" id="eft_amount" />
                                                                        <label className="form-check-label" htmlFor="eft_amount">
                                                                            EFT amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.payer} onChange={() => setState({ ...state, payer: !state.payer })} type="checkbox" id="payer" />
                                                                        <label className="form-check-label" htmlFor="payer">
                                                                            Payer
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>



                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_pages} onChange={() => setState({ ...state, no_of_pages: !state.no_of_pages })} type="checkbox" id="no_of_pages" />
                                                                        <label className="form-check-label" htmlFor="no_of_pages">
                                                                            # Pages
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_claims} onChange={() => setState({ ...state, no_of_claims: !state.no_of_claims })} type="checkbox" id="no_of_claims" />
                                                                        <label className="form-check-label" htmlFor="no_of_claims">
                                                                            # claims
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_service_lines} onChange={() => setState({ ...state, no_of_service_lines: !state.no_of_service_lines })} type="checkbox" id="no_of_service_lines" />
                                                                        <label className="form-check-label" htmlFor="no_of_service_lines">
                                                                            # service lines
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_fields} onChange={() => setState({ ...state, no_of_fields: !state.no_of_fields })} type="checkbox" id="no_of_fields" />
                                                                        <label className="form-check-label" htmlFor="no_of_fields">
                                                                            # Fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_corrected_fields} onChange={() => setState({ ...state, no_of_corrected_fields: !state.no_of_corrected_fields })} type="checkbox" id="no_of_corrected_fields" />
                                                                        <label className="form-check-label" htmlFor="no_of_corrected_fields">
                                                                            # Corrected Fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_autofilled_fields} onChange={() => setState({ ...state, no_of_autofilled_fields: !state.no_of_autofilled_fields })} type="checkbox" id="no_of_autofilled_fields" />
                                                                        <label className="form-check-label" htmlFor="no_of_autofilled_fields">
                                                                            # Autofilled Fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_key_fields} onChange={() => setState({ ...state, no_of_key_fields: !state.no_of_key_fields })} type="checkbox" id="no_of_key_fields" />
                                                                        <label className="form-check-label" htmlFor="no_of_key_fields">
                                                                            # Keyed Fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.data_grid_user} onChange={() => setState({ ...state, data_grid_user: !state.data_grid_user })} type="checkbox" id="data_grid_user" />
                                                                        <label className="form-check-label" htmlFor="data_grid_user">
                                                                            User Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.user_start} onChange={() => setState({ ...state, user_start: !state.user_start })} type="checkbox" id="user_start" />
                                                                        <label className="form-check-label" htmlFor="user_start">
                                                                            User Start Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.user_end} onChange={() => setState({ ...state, user_end: !state.user_end })} type="checkbox" id="user_end" />
                                                                        <label className="form-check-label" htmlFor="user_end">
                                                                            User End Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.user_total_time} onChange={() => setState({ ...state, user_total_time: !state.user_total_time })} type="checkbox" id="user_total_time" />
                                                                        <label className="form-check-label" htmlFor="user_total_time">
                                                                            User Total Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.qa_user} onChange={() => setState({ ...state, qa_user: !state.qa_user })} type="checkbox" id="qa_user" />
                                                                        <label className="form-check-label" htmlFor="qa_user">
                                                                            QA Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.qa_start_time} onChange={() => setState({ ...state, qa_start_time: !state.qa_start_time })} type="checkbox" id="qa_start_time" />
                                                                        <label className="form-check-label" htmlFor="qa_start_time">
                                                                            QA Start Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.qa_end_time} onChange={() => setState({ ...state, qa_end_time: !state.qa_end_time })} type="checkbox" id="qa_end_time" />
                                                                        <label className="form-check-label" htmlFor="qa_end_time">
                                                                            QA End Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.qa_total_time} onChange={() => setState({ ...state, qa_total_time: !state.qa_total_time })} type="checkbox" id="qa_total_time" />
                                                                        <label className="form-check-label" htmlFor="qa_total_time">
                                                                            QA Total Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.snipping_staff} onChange={() => setState({ ...state, snipping_staff: !state.snipping_staff })} type="checkbox" id="snipping_staff" />
                                                                        <label className="form-check-label" htmlFor="snipping_staff">
                                                                            Snipping Name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.snipping_start_time} onChange={() => setState({ ...state, snipping_start_time: !state.snipping_start_time })} type="checkbox" id="snipping_start_time" />
                                                                        <label className="form-check-label" htmlFor="snipping_start_time">
                                                                            Snipping Start Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.snipping_end_time} onChange={() => setState({ ...state, snipping_end_time: !state.snipping_end_time })} type="checkbox" id="snipping_end_time" />
                                                                        <label className="form-check-label" htmlFor="snipping_end_time">
                                                                            Snipping End Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.snipping_total_time} onChange={() => setState({ ...state, snipping_total_time: !state.snipping_total_time })} type="checkbox" id="snipping_total_time" />
                                                                        <label className="form-check-label" htmlFor="snipping_total_time">
                                                                            Snipping Total Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.additional_info_status} onChange={() => setState({ ...state, additional_info_status: !state.additional_info_status })} type="checkbox" id="additional_info_status" />
                                                                        <label className="form-check-label" htmlFor="additional_info_status">
                                                                            Additional info Status
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>


                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.comment} onChange={() => setState({ ...state, comment: !state.comment })} type="checkbox" id="comment" />
                                                                        <label className="form-check-label" htmlFor="comment">
                                                                            Comment
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.action} onChange={() => setState({ ...state, action: !state.action })} type="checkbox" id="action" />
                                                                        <label className="form-check-label" htmlFor="comment">
                                                                            Actions
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>


                                                        </ul>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            {/* <div>
                                                <Tooltip disableInteractive title="Job Monitor Export" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary  jobMonitor-Buttons"
                                                        onClick={() => handleExportJobMonitor(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station)}
                                                        type="button">
                                                        <span className="svg-icon svg-icon-3">
                                                            {!exportLoader ?
                                                                <BiExport className='jobMonitor-svg' /> :
                                                                <div className="spinner-border spinner-border-sm text-light" role="status">
                                                                </div>
                                                            }
                                                        </span>


                                                    </Button>
                                                </Tooltip>
                                            </div> */}
                                            <div className="dropdown">
                                                <button className="btn btn-light btn-active-primary pb-2 dropdown-toggle  jobMonitor-Buttons" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    {!exportLoader ?
                                                        <BiExport className='jobMonitor-svg' /> :
                                                        <div className="spinner-border spinner-border-sm text-light" role="status">
                                                        </div>
                                                    }
                                                </button>
                                                <ul className="dropdown-menu hide-scroll-bar" style={{ height: '80px', overflowX: 'scroll', cursor: 'pointer' }}>
                                                    <li>
                                                        <div className='dropdown-item' onClick={() => handleExportJobMonitor(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, 2, searchedData?.processed_date_range)}>

                                                            <div className="">
                                                                Full view
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item' onClick={() => handleExportJobMonitor(pageno, limit, searchedData?.staff, searchedData.client, searchedData.client_group, searchedData.status, searchedData?.filename, searchedData?.check_number, searchedData?.batch_id, searchedData?.start_date, searchedData?.end_date, sortOrder, sortColumn, searchedData?.tat_status, searchedData?.station, 1, searchedData?.processed_date_range)}>

                                                            <div className="">
                                                                Summarized view
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Job Monitor Total" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary  jobMonitor-Buttons"
                                                        onClick={handleTotalJobMonitor}
                                                        disabled={disableTotal}
                                                        type="button">
                                                        <span className="svg-icon svg-icon-3 jobMonitor-Release-span">
                                                            {grandTotal.isActive ?
                                                                <BiShow /> :
                                                                <BiHide />
                                                            }

                                                            {/* Total */}
                                                            {/* <Form.Check
                                                                type="switch"
                                                                reverse={true}
                                                                checked={
                                                                grandTotal.isActive
                                                                }
                                                                feedbackType="invalid"
                                                                id="validationFormik20"
                                                                name="total_row"
                                                            /> */}
                                                        </span>


                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            {/* {console.log(!!selectedData?.includes((el) => el?.status != "qa_review" || el?.status != "imported"))} */}
                                            {selectedRows?.length > 0 &&
                                                <React.Fragment>
                                                    {checkUserPermission('assign_work_to_user') &&
                                                        <div>
                                                            <Tooltip disableInteractive title="Assign" placement='top' arrow>

                                                                {/* <div className='col-lg-1 d-flex justify-content-between'> */}
                                                                <Button
                                                                    // disabled={!selectedData?.every((el) => el?.status === "imported" || el?.status === "qa_review" || el?.status === "inprocess" || el?.status === "datacaptured" || el?.status === "partial_save" || el?.status === "re_assigned" || el?.status === "qa_inprocess" || el?.status === "qa_complete" || el?.status === "ready_to_output_generate" || el?.status === "rejected" || el?.status === "qa_assigned" || el?.status === "snipping" || el?.status === "snipping_assigned" || el?.status === "snipping_inprocess" || el?.status === "snipping_complete")}
                                                                    disabled={checkAssignDisabled(selectedData)}
                                                                    className="btn btn-light btn-active-primary  jobMonitor-Buttons"
                                                                    type="button"
                                                                    onClick={() => handleShowModal(selectedRows)}>
                                                                    <span className="svg-icon svg-icon-3">
                                                                        <BiUserPlus className='jobMonitor-svg' />
                                                                    </span>
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    }
                                                    <div>
                                                        <Tooltip disableInteractive title="Tat" placement='top' arrow>
                                                            {/* el?.status != "output_completed" || el?.status != "output_generated" || el?.status != "ready_to_output_generate" || el?.status != "rejected" */}
                                                            <Button disabled={!selectedData?.every((el) => el?.status != "output_completed" && el?.status != "output_generated" && el?.status != "ready_to_output_generate" && el?.status != "rejected")} className="btn btn-light btn-active-primary  jobMonitor-Buttons " type="button" onClick={() => {
                                                                handleShowTat(); setSettingsTat(selectedRows); setSettings()
                                                            }}>
                                                                <span className="svg-icon svg-icon-3">
                                                                    <BiTimer className='jobMonitor-svg' />
                                                                </span>

                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        <Tooltip disableInteractive title="Hold" placement='top' arrow>
                                                            {/* && el?.status != "archived" && el?.status != "output_completed" && el?.status != "output_generated" */}
                                                            <Button disabled={!!selectedData?.find((el) => el?.status == "hold" || el?.status == "archived" || el?.status == "output_completed" || el?.status == "output_generated")} className='btn btn-light btn-active-primary  jobMonitor-Buttons'
                                                                onClick={() => { setToggleHoldPopup(true); }}
                                                            >
                                                                <TbHandGrab className='jobMonitor-svg' />
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                    <div>

                                                        <Tooltip disableInteractive title="Release" placement='top' arrow>
                                                            <Button disabled={!selectedData?.every((el) => el?.status == "hold" || el?.status === "re_assigned" || el?.status == "inprocess" || el?.status == "qa_inprocess" || el?.status == "assigned" || el?.status == "rejected" || el?.status == "qa_assigned" || el?.status == "snipping_assigned" || el?.status == "snipping_inprocess")} className='btn btn-light btn-active-primary  jobMonitor-Release-Buttons'
                                                                onClick={() => { setTogglePopup(true); }}
                                                            >
                                                                <span className="svg-icon svg-icon-3 jobMonitor-Release-span">
                                                                    Release
                                                                </span>
                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                    <div>
                                                        <Tooltip disableInteractive title="Generate Output" placement='top' arrow>

                                                            <Button className="btn btn-light btn-active-primary  jobMonitor-Buttons"
                                                                disabled={!selectedData?.every((el) => el?.status === "qa_complete" || el?.status === "ready_to_output_generate" || el?.status === "output_generated" || el?.status === "snipping_complete")}
                                                                onClick={() => { handleGenerateOutputFileSchema835(selectedRows); handleResetCheckBox() }}
                                                                type="button">
                                                                <span className="svg-icon svg-icon-3">
                                                                    <BiFile className='jobMonitor-svg' />
                                                                </span>


                                                            </Button>
                                                        </Tooltip>
                                                    </div>
                                                    <div>

                                                        <Tooltip disableInteractive title="Reject" placement='top' arrow>
                                                            <Button
                                                                disabled={!selectedData?.every((el) => el?.status == "snipping_complete" || el?.status === "snipping_inprocess" || el?.status === "snipping_assigned" || el?.status === "snipping" || el?.status == "imported" || el?.status === "assigned" || el?.status == "inprocess" || el?.status == "datacaptured" || el?.status == "partial_save" || el?.status == "re_assigned" || el?.status == "qa_review" || el?.status == "qa_inprocess" || el?.status == "qa_complete" || el?.status == "ready_to_output_generate" || el?.status == "archived" || el?.status == "rejected" || el?.status == "hold") || !selectedData?.every((el) => !el?.is_sub_job && !el?.is_large_batch_job)}
                                                                className='btn btn-light btn-active-primary  jobMonitor-Buttons'
                                                                onClick={() => { handleRejectJob() }}
                                                            >
                                                                <span className="svg-icon svg-icon-3">
                                                                    <BiXCircle className='jobMonitor-svg' />
                                                                </span>

                                                            </Button>
                                                        </Tooltip>
                                                    </div>

                                                </React.Fragment>
                                            }


                                        </div>
                                    </div>
                                </Form>

                                <div className="p-2">
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!unselectCheck &&
                                                <DataTable
                                                    data={data?.data?.results?.data}
                                                    className="custom-datatable"
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination

                                                    paginationServer
                                                    // highlightOnHover={true}
                                                    pointerOnHover={true}
                                                    fixedHeader={true}
                                                    selectableRows
                                                    // selectableRowsComponent={CustomSelectableRow}
                                                    onSelectedRowsChange={handleRowSelected}
                                                    // clearSelectedRows={true}
                                                    // sort={{ column: sortColumn, order: sortOrder }}
                                                    // onSort={handleSort}

                                                    // striped={true}
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    selectableRowDisabled={rowDisabledCriteria}
                                                    // subHeaderComponent={subHeaderComponentMemo}
                                                    theme="solarized"
                                                    customStyles={queueDatatableCustomStyles}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    // striped={true}
                                                    onRowClicked={handleRowClickCheck}
                                                    //onRowDoubleClicked={handleRowDoubleClick}
                                                    sortServer
                                                    onSort={handleSort}
                                                    // selectableRows 
                                                    responsive={true}
                                                    customFooter

                                                />
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Manual Release  Modal Starts*/}


                <Modal show={togglePopup} onHide={() => { setTogglePopup(false) }} centered className='manual-tat'>
                    <Modal.Header closeButton>
                        <Modal.Title> Please Provide The Reason</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={releaseFormik.handleSubmit}>
                            <div className=''>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Reason</Form.Label>
                                    <Form.Control
                                        name='reason'
                                        type="text"
                                        autoComplete="false"
                                        // isValid={releaseFormik.touched?.reason && !releaseFormik.errors?.reason}
                                        isInvalid={!!releaseFormik.errors?.reason}
                                        value={releaseFormik.values?.reason}
                                        className='b-r-10 form-control-solid'
                                        onChange={releaseFormik.handleChange}
                                        // onBlur={releaseFormik.handleBlur}
                                        // 
                                        placeholder="Reason"
                                    />
                                    <Form.Control.Feedback type="invalid">{releaseFormik.errors?.reason}</Form.Control.Feedback>
                                </Form.Group>

                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <Button disabled={releaseFormik.isSubmitting} type="submit" className='btn btn-light btn-active-primary' variant="primary" >Submit</Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
                {/* Manual Modal  Modal Ends*/}
                {/* Manual History  Modal Starts*/}

                <Modal size="lg" show={toggleHistoryPopup.isShow} onHide={() => { setToggleHistoryPopup({ isShow: false, job_id: null }), setHistoryData([]) }} centered className='manual-tat'>
                    <Modal.Header closeButton>
                        <Modal.Title>Logs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table history-log-table" >
                            <thead>
                                <tr >
                                    <th >Date</th>
                                    <th >Created By</th>
                                    <th >Action</th>
                                    <th >Staff</th>
                                    <th >Comment</th>
                                    <th ></th>
                                </tr>
                            </thead>
                            {historyData?.map((i, index) =>
                                <tbody key={index}>
                                    <tr >
                                        <td>{i.created_date ? i.created_date : ""}</td>
                                        <td><div className='text-truncate' style={{ width: "150px" }}>{i.created_by}</div></td>
                                        <td>{i.job_status}</td>
                                        <td><div className='text-truncate' style={{ width: "150px" }}>{i.staff}</div></td>
                                        <td className='text-break text-uppercase'>{i.comment}</td>
                                        {i.job_status == "Partial Save" || i.job_status == "QA Completed" || i.job_status == "Data Capture Completed" ?
                                            <td className='text-break'>
                                                <button type="button" onClick={() => {
                                                    handleSingleReportDetails(i.log_id, i.job_status)
                                                }
                                                } className='btn btn-light btn-active-primary btn-sm btn-icon'>
                                                    <i className='bi bi-eye fs-7'></i>
                                                </button>
                                            </td>
                                            :
                                            <td ></td>
                                        }
                                    </tr>
                                </tbody>
                            )}
                        </table>

                    </Modal.Body>

                </Modal>

                {/* Manual Modal  Modal Ends*/}

                {/* Hold  Modal Starts*/}


                <Modal show={toggleHoldPopup} onHide={() => { setToggleHoldPopup(false) }} centered className='manual-tat'>
                    <Modal.Header closeButton>
                        <Modal.Title> Please Provide The Reason</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={holdFormik.handleSubmit}>
                            <div className=''>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Reason</Form.Label>
                                    <Form.Control
                                        name='reason'
                                        type="text"
                                        autoComplete="false"
                                        // isValid={holdFormik.touched?.reason && !holdFormik.errors?.reason}
                                        isInvalid={!!holdFormik.errors?.reason}
                                        value={holdFormik.values?.reason}
                                        className='b-r-10 form-control-solid'
                                        onChange={holdFormik.handleChange}
                                        // onBlur={holdFormik.handleBlur}
                                        // 
                                        placeholder="Reason"
                                    />
                                    <Form.Control.Feedback type="invalid">{holdFormik.errors?.reason}</Form.Control.Feedback>
                                </Form.Group>

                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <Button disabled={holdFormik.isSubmitting} type="submit" className='btn btn-light btn-active-primary' variant="primary" >Submit</Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
                {/* Hold Modal Ends*/}

                {/* Manual TAT  Modal Starts*/}


                {/* <Modal show={show} onHide={handleClose} centered className='manual-tat'>
                    <Modal.Header closeButton>
                        <Modal.Title> Change TAT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className=''>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Current TAT</Form.Label>
                                    <Form.Control type="text" value={moment(settings?.tat).format('MMMM Do YYYY, h:mm a')} disabled />
                                </Form.Group>
                                <Form.Group className="mb-3 d-grid" controlId="formBasicEmail">
                                    <DateTimePicker
                                        onChange={onChange}
                                        value={value}
                                        className='b-r-10' />
                                </Form.Group>
                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <Button type="submit" className='btn btn-light btn-active-primary' onClick={changeTat} variant="primary" >Submit</Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal> */}
                {/* Manual TAT  Modal Ends*/}

                {/* Manual TAT  Modal Starts*/}

                <Modal show={showModal} onHide={handleCloseModal} centered className='assign'>
                    <Modal.Header closeButton>
                        <Modal.Title>Assign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-4'>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className='d-flex justify-content-between'>
                                <div style={{ width: selectedData.every((el) => el?.status === "snipping_complete" || el?.status === "snipping_inprocess" || el?.status === "snipping_assigned" || el?.status === "snipping") ? "65%" : "100%" }} className='row d-flex align-items-start'>

                                    <Select
                                        options={userlist}
                                        formatOptionLabel={formatOptionLabel}
                                        className="select-search"
                                        classNamePrefix="select"
                                        placeholder="Select User"
                                        name="staff"
                                        isClearable  // onChange={formik.handleChange}
                                        onChange={(e) => handleUserSelectChange(e, 'staff')}
                                        defaultValue={formik?.values?.staff}
                                        styles={{ control: styles => ({ ...styles, backgroundColor: "hsl(204deg 33.33% 97.06%)", borderStyle: "none", boxShadow: "none", borderRadius: "10px" }), indicatorSeparator: styles => ({ ...styles, width: "0px", minHeight: "20px" }), container: styles => ({ ...styles, borderRadius: "9px", marginLeft: "0px" }) }}
                                    />


                                    {formik.errors && formik.errors?.staff &&
                                        <span className='text-danger'>{formik.errors?.staff}</span>
                                    }
                                </div>
                                {selectedData.every((el) => el?.status === "snipping_complete" || el?.status === "snipping_inprocess" || el?.status === "snipping_assigned" || el?.status === "snipping") ?
                                    <div style={{ width: "38%" }} className='row d-flex align-items-start'>
                                        <Select
                                            options={[{
                                                label: "QA",
                                                value: "2"
                                            },
                                            {
                                                label: "SNIPPING",
                                                value: "3"
                                            }

                                            ]}
                                            formatOptionLabel={formatOptionLabel}
                                            className="select-search"
                                            classNamePrefix="select"
                                            placeholder="Type"
                                            name="qa_or_snipping"
                                            // isClearable  // onChange={formik.handleChange}
                                            onChange={(e) => formik.setFieldValue("qa_or_snipping", e.value)}
                                            defaultValue={[{ label: "SNIPPING", value: "3" }]}
                                            styles={{ control: styles => ({ ...styles, backgroundColor: "hsl(204deg 33.33% 97.06%)", borderStyle: "none", boxShadow: "none", borderRadius: "10px" }), indicatorSeparator: styles => ({ ...styles, width: "0px", minHeight: "20px" }), container: styles => ({ ...styles, borderRadius: "9px", marginLeft: "0px" }) }}
                                        />

                                        {formik.errors && formik.errors?.qa_or_snipping &&
                                            <span className='text-danger'>{formik.errors?.qa_or_snipping}</span>
                                        }
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className='d-flex flex-row justify-content-center mt-4'>
                                <Button disabled={formik.isSubmitting} type="submit" className='btn btn-light btn-active-primary' variant="primary">Submit</Button>
                            </div>
                        </Form>

                    </Modal.Body>

                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer> */}

                </Modal>

                <Modal show={tatModal} onHide={handleAssignTat} centered >
                    <Modal.Header closeButton>
                        <Modal.Title>Change TAT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-4'>
                        <Form onSubmit={tatformik.handleSubmit}>
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <Form.Group className="  " controlId="formBasicEmail">
                                    <div className='d-flex flex-column '>

                                        <InputMask
                                            className='b-r-10 ps-3'
                                            mask="99/99/9999"

                                            onChange={(e) => { setTatDate(e.target.value); tatformik.handleChange(e) }}
                                            // onChange={(e) => { handleOnDateChange({ name: 'date_of_service', value: e }) }}

                                            value={tatDate}

                                            name="date_of_service"

                                            placeholder={"mm/dd/yyyy"}
                                        />
                                        {tatformik.errors && tatformik.errors?.date_of_service &&
                                            <span className='text-danger ms-2'>{tatformik.errors?.date_of_service}</span>
                                        }
                                    </div>
                                </Form.Group>
                                <Form.Group className="  " controlId="formBasicEmail">

                                    <div className=''>

                                        <input type="time" name='tat_time' onChange={(e) => { setTatTime(e.target.value); tatformik.handleChange(e) }} className='b-r-10 fs-6 ms-2 ps-2' value={tatTime} />
                                    </div>
                                    {tatformik.errors && tatformik.errors?.tat_time &&
                                        <span className='text-danger ms-2'>{tatformik.errors?.tat_time}</span>
                                    }
                                </Form.Group>


                            </div>
                            <div className='d-flex flex-row justify-content-center mt-4'>
                                <Button disabled={tatformik.isSubmitting} type="submit" className='btn btn-light btn-active-primary' variant="primary">Submit</Button>
                            </div>
                        </Form>

                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer> */}
                </Modal>
                {/* Manual TAT  Modal Ends*/}

                {/* Report History  Modal Starts*/}
                <MyWorkDataEntryReportModal
                    toggleReportPopup={toggleReportPopup}
                    setToggleReportPopup={setToggleReportPopup}
                    reportData={reportData}
                    setReportData={setReportData}
                />

                {/* Report Modal  Modal Ends*/}

            </main>

        </React.Fragment>
    )
}

export default QueueManagementSystem;