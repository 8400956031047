import React, { useState, useEffect } from 'react'
import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import AdminMaster from '../../../Layout/admin/Master'
import { createOrUpdatePartner, getSinglePartnerDetail } from '../../../Redux/partner/action';
import { PARTNER_CREATE, PARTNER_LIST } from '../../../routeNames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { changeAllTitleFunction, getRedirectTabId } from '../Dashboard/functions';

const CreateUpdate = (props) => {

    let { partner_id } = useParams();
    const [showPassword, setShowPassword] = useState(false)
    const navigate = useNavigate()
    const [state, setState] = useState({
        showToast: false,
        error: false,
        error_message: "",
    })

    const [toaster, setToaster] = useState(null);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "",
            name: "",
            address: "",
            email: "",
            password: "",
            city: "",
            state: "",
            zip_code: "",
            country: "",
        },

        validationSchema: Yup.object().shape({

            name: Yup.string()
                .min(1, 'at least 1 character')
                .required('Required'),
            address: Yup.string()
                .min(1, 'at least 1 character')
                .required('Required'),

            city: Yup.string()
                .min(1, 'at least 1 character')
                .required('Required'),

            email: Yup.string()
                .matches(/^[a-zA-Z0-9@._]+$/, 'Invalid Email')
                .email('Invalid email')
                .required('Required'),

            state: Yup.string()
                .matches(/^[A-Za-z]+$/, 'Enter a valid State Abbrevation')
                .min(2, 'Only two characters')
                .max(2, 'Only two characters')
                .required('Required'),
            zip_code: Yup.string()
                .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{5}\d{4}$)/, 'Enter a valid Zip code')
                .required('Required'),
            country: Yup.string()
                .matches(/^[a-zA-Z ]*$/, 'Enter a valid Country Name')
                .min(1, 'at least 1 character')
                .required('Required'),
            password: !partner_id ? Yup.string().required('Please Enter your password')
                .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                ) : Yup.string()
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
                    ),


        }),
        onSubmit: (values) => {
            // __handleCreatePayee(initialValues)
            let formData = new FormData();    //formdata object

            formData.append('pk', values?.id ? values?.id : "");   //append the values with key, value pair
            formData.append('data_from', 1);
            formData.append('name', values?.name);
            formData.append('email', values?.email);
            formData.append('password', values?.password);
            formData.append('address', values?.address);
            formData.append('city', values?.city);
            formData.append('state', values?.state);
            formData.append('zip_code', values?.zip_code);
            formData.append('country', values?.country);

            const RedirectId = getRedirectTabId()
            createOrUpdatePartner({ formData })((response) => {
                // console.log(response?.response?.data?.errors, 'data');
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    setToaster('success')
                    setTimeout(() => {
                        changeAllTitleFunction('Partner', PARTNER_LIST, RedirectId)

                    }, 3000);
                } else {
                    formik.setErrors({
                        // payee_id: response?.response?.data?.errors?.payee_id,
                        name: response?.response?.data?.errors?.name,
                        address: response?.response?.data?.errors?.address,
                        email: response?.response?.data?.errors?.email,
                        password: response?.response?.data?.errors?.password,
                        city: response?.response?.data?.errors?.city,
                        state: response?.response?.data?.errors?.state,
                        zip_code: response?.response?.data?.errors?.zip_code,
                        country: response?.response?.data?.errors?.country,
                    })
                    // // console.log(error)


                    setToaster('error')

                    setTimeout(() => {
                        setToaster('')

                    }, 2000);

                }
            });

        },
    });

    const passwordValidationMsg = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {formik.errors.password}
            {/* Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character */}
        </Tooltip>
    );
    useEffect(() => {
        if (partner_id) {
            getSinglePartnerDetail(partner_id)((response) => {
                if (response?.status) {
                    formik.setValues({
                        ...formik.values,
                        id: partner_id,
                        name: response?.data?.partner?.name ? response?.data?.partner?.name : "",
                        address: response?.data?.partner?.address ? response?.data?.partner?.address : "",
                        email: response?.data?.partner?.email ? response?.data?.partner?.email : "",
                        password: response?.data?.partner?.password ? response?.data?.partner?.password : "",
                        city: response?.data?.partner?.city ? response?.data?.partner?.city : "",
                        state: response?.data?.partner?.state ? response?.data?.partner?.state : "",
                        zip_code: response?.data?.partner?.zip_code ? response?.data?.partner?.zip_code : "",
                        country: response?.data?.partner?.country ? response?.data?.partner?.country : "",
                    })
                }
            })
        }
    }, []);


    console.log(formik.errors)
    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} />
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1>{window.location.pathname == PARTNER_CREATE ? 'Create Partner' : 'Edit Partner'}</h1>

                </div>

                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">


                                    <Form
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="mb-3 row" >





                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Name</Form.Label>
                                                    <Form.Control
                                                        name='name'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.name && !formik.errors.name}
                                                        isInvalid={!!formik.errors.name}
                                                        value={formik.values.name}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength="255"
                                                        placeholder="Name"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Email</Form.Label>
                                                    <Form.Control
                                                        name='email'
                                                        type="email"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.email && !formik.errors.email}
                                                        isInvalid={!!formik.errors.email}
                                                        value={formik.values.email}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength="255"
                                                        placeholder="Email"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Password</Form.Label>
                                                    {formik.errors?.password &&
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 50, hide: 100 }}
                                                            overlay={passwordValidationMsg}
                                                        >
                                                            <span className='ms-2'><i className="bi bi-info-circle text-danger"></i></span>
                                                        </OverlayTrigger>}
                                                    <InputGroup className='p-0 m-0'>
                                                        <Form.Control
                                                            name='password'
                                                            type={showPassword ? "text" : "password"}
                                                            onChange={formik.handleChange}
                                                            autoComplete="false"
                                                            isValid={formik.touched.password && !formik.errors.password}
                                                            isInvalid={!!formik.errors.password}
                                                            value={formik.values.password}
                                                            className='form-control-solid password-padding'
                                                            maxLength="255"
                                                            placeholder="Password"
                                                        />
                                                        <InputGroup.Text className='eye-input-group pe-3' onClick={() => setShowPassword(!showPassword)}  >
                                                            <i className={showPassword ? 'bi bi-eye' : "bi bi-eye-slash"}></i>
                                                        </InputGroup.Text>
                                                    </InputGroup>
                                                    <Form.Control.Feedback type="invalid">{formik.errors.password}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Address</Form.Label>
                                                    <Form.Control
                                                        name='address'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.address && !formik.errors.address}
                                                        isInvalid={!!formik.errors.address}
                                                        value={formik.values.address}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength="255"
                                                        placeholder="Address"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.address}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>City</Form.Label>
                                                    <Form.Control
                                                        name='city'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.city && !formik.errors.city}
                                                        isInvalid={!!formik.errors.city}
                                                        value={formik.values.city}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength="255"
                                                        placeholder="City"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.city}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>State</Form.Label>
                                                    <Form.Control
                                                        name='state'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.state && !formik.errors.state}
                                                        isInvalid={!!formik.errors.state}
                                                        value={formik.values.state}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength={2}
                                                        placeholder="State"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.state}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Zip Code</Form.Label>
                                                    <Form.Control
                                                        name='zip_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.zip_code && !formik.errors.zip_code}
                                                        isInvalid={!!formik.errors.zip_code}
                                                        value={formik.values.zip_code}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength={10}
                                                        placeholder="Zip Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.zip_code}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Country</Form.Label>
                                                    <Form.Control
                                                        name='country'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.country && !formik.errors.country}
                                                        isInvalid={!!formik.errors.country}
                                                        value={formik.values.country}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength="255"
                                                        placeholder="Country"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.country}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>


                                        </div>

                                        <div className='d-flex flex-row justify-content-end'>

                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("Partner", PARTNER_LIST) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div className=''>
                                                <button
                                                    type='submit'
                                                    className='btn btn-light btn-active-primary'
                                                >
                                                    {window.location.pathname == PARTNER_CREATE ? 'Save' : 'Update'}
                                                </button>
                                            </div>
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default CreateUpdate
