import React, { useRef } from 'react'
import { Form, InputGroup } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { handleKeyPress, handleWheel, handlePreventEKeyPress } from '../gridFunctions';
import { EDIT_WORK, QA_REVIEW, DATA_ENTRY } from '../../../../../routeNames';
import moment from 'moment';

const ServiceLine = (props) => {
    const { formik, index, setDefaultValueAssignedWork, handleLiteRemainingBalanceCalculation, handlePageNavigate, jobData, job_id, log_id } = props;
    const inputRef = useRef([]);

    const handleFloatValue = async (e) => {

        e.target.value = parseFloat(e.target.value).toFixed(2);
        e.target.type = 'text';
        await formik.handleChange(e)
        e.target.type = 'number';
    }



    const handleCalculation = async (event) => {
        const value = event.target.value;
        if (value.includes('.') && value.split('.')[1].length > 2) {
            return;
        }
        await formik.setValues((prevState) => ({
            ...prevState,
            claims: prevState?.claims.map((claim, ind) => {
                if (ind === index) {

                    return {
                        ...claim,
                        service_lines: {
                            ...claim.service_lines,
                            paid: value,
                        },
                    };
                }
                return claim;
            }),
        }));

        const remaining = await handleLiteRemainingBalanceCalculation()
    }
    const pathname = window.location.pathname;
    const gridData = jobData?.grid_data;
    const qaCorrectedData = gridData?.qa_corrected_data;
    const renderClass = (name, type, defaultClass) => {
        if (formik.errors &&
            formik.errors?.claims &&
            formik.errors?.claims[index] &&
            formik.errors?.claims[index]?.service_lines &&
            formik.errors?.claims[index]?.service_lines?.[name]) {
            return defaultClass + ' error-field'
        }
        if (pathname == QA_REVIEW) {

            if (qaCorrectedData &&
                qaCorrectedData?.claims &&
                qaCorrectedData?.claims[index] &&
                qaCorrectedData?.claims[index]?.service_lines &&
                qaCorrectedData?.claims[index]?.service_lines?.[name]) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'DATE' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.service_lines &&
                moment(formik.values?.claims[index]?.service_lines?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.service_lines?.[name]).format("MM/DD/YYYY")) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'TEXT' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.service_lines &&
                formik.values?.claims[index]?.service_lines?.[name]?.toUpperCase() !=
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.service_lines?.[name]?.toUpperCase()) {
                return defaultClass + ' qa-field'
            }
            else if (type == 'NUMBER' &&
                gridData?.claims &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
                gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.service_lines &&
                parseFloat(formik.values?.claims[index]?.service_lines?.[name] ? formik.values?.claims[index]?.service_lines?.[name] : 0).toFixed(2) !=
                parseFloat(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.service_lines?.[name] ?
                    gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.service_lines?.[name] : 0).toFixed(2)) {
                console.log(name)
                return defaultClass + ' qa-field'
            }

            else if (gridData?.transaction_type != "PAYMENT835_LITE" || formik.values?.claims[index]?.corrected_claim) {
                return defaultClass + ' qa-field'
            }
            else {
                return defaultClass;
            }


        }
        else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
            qaCorrectedData &&
            qaCorrectedData?.claims &&
            qaCorrectedData?.claims[index] &&
            qaCorrectedData?.claims[index]?.service_lines &&
            qaCorrectedData?.claims[index]?.service_lines?.[name]) {
            return defaultClass + ' qa-field'
        }
        else {
            return defaultClass;
        }
    }
    return (
        <div className="serviceline-mt">
            <div className="serviceline">
                <div className="serviceline-top">
                    <div className="accordion" id="serviceline835">
                        <button tabIndex="-1" type="button" className="btn btn-healthcare accordion-icon" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">serviceline835</button>
                    </div>
                </div>
            </div>
            <div className="bottom-wrapper serviceline_details accordion-collapse collapse show" id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#serviceline835">
                <div className="serviceline-sub-section " >
                    <div className="period-start-wrap">

                        <InputGroup>
                            <InputGroup.Text>Period Start</InputGroup.Text>
                            <InputMask
                                mask="99/99/9999"
                                className={renderClass('period_start', 'DATE', 'patient-date')}
                                style={{ fontSize: '0.7em' }}
                                onChange={formik.handleChange}
                                onDoubleClick={() => handlePageNavigate(index, 'LITE')}
                                onKeyUp={(e) => setDefaultValueAssignedWork(e, 'date_of_service', 'period_start', 'dbk_period_start', index, 'service_line')}
                                value={formik.values?.claims[index]?.service_lines?.period_start}
                                tabIndex={formik.values?.claims[index]?.service_lines?.period_start != '' && formik.values?.claims[index]?.service_lines?.period_start != '__/__/____' && pathname == DATA_ENTRY ? -1 : 0}
                                name={`claims[${index}].service_lines.period_start`}
                                placeholder={"mm/dd/yyyy"}
                            />
                        </InputGroup>
                    </div>
                    <div className="period-end-wrap">
                        <InputGroup>
                            <InputGroup.Text>Period End</InputGroup.Text>
                            <InputMask mask="99/99/9999"
                                className={renderClass('period_end', 'DATE', 'patient-date')}
                                style={{ fontSize: '0.7em' }}
                                ref={inputRef}
                                onDoubleClick={() => handlePageNavigate(index, 'LITE')}
                                onChange={formik.handleChange}
                                onKeyUp={(e) => setDefaultValueAssignedWork(e, 'date_of_service', 'period_end', 'dbk_period_end', index, 'service_line')}
                                value={formik.values?.claims[index]?.service_lines?.period_end}
                                tabIndex={formik.values?.claims[index]?.service_lines?.period_end != '' && formik.values?.claims[index]?.service_lines?.period_end != '__/__/____' && pathname == DATA_ENTRY ? -1 : 0}
                                name={`claims[${index}].service_lines.period_end`}
                                placeholder={"mm/dd/yyyy"} />
                        </InputGroup>
                    </div>
                    <div className="billed-wrapper">
                        <InputGroup>
                            <InputGroup.Text>Billed</InputGroup.Text>
                            <Form.Control
                                type="number"
                                name={`claims[${index}].service_lines.billed`}
                                onChange={formik.handleChange}
                                onDoubleClick={() => handlePageNavigate(index, 'LITE')}
                                onBlur={(e) => handleFloatValue(e)}
                                onKeyDown={(e) => {
                                    handlePreventEKeyPress(e);
                                    handleKeyPress(e)
                                }}
                                onKeyUp={(e) => setDefaultValueAssignedWork(e, 'amounts', 'billed', 'dbk_billed', index, 'service_line')}
                                onWheel={handleWheel}
                                value={formik.values?.claims[index]?.service_lines?.billed}
                                tabIndex={formik.values?.claims[index]?.service_lines?.billed != '' && pathname == DATA_ENTRY ? -1 : 0}
                                className={renderClass('billed', 'NUMBER', 'w-78 text-end')}
                            />
                        </InputGroup>
                    </div>
                    <div className="paid-wrapper">
                        <InputGroup>
                            <InputGroup.Text>Paid</InputGroup.Text>
                            <Form.Control
                                type="number"
                                name={`claims[${index}].service_lines.paid`}
                                onChange={(e) => handleCalculation(e)}
                                onKeyUp={(e) => setDefaultValueAssignedWork(e, 'charge', 'paid', 'dbk_paid', index, 'service_line')}
                                onDoubleClick={() => handlePageNavigate(index, 'LITE')}
                                onKeyDown={(e) => {
                                    handlePreventEKeyPress(e);
                                    handleKeyPress(e)
                                }}
                                onWheel={handleWheel}
                                onBlur={handleFloatValue}
                                value={formik.values?.claims[index]?.service_lines?.paid}
                                tabIndex={formik.values?.claims[index]?.service_lines?.paid != '' && pathname == DATA_ENTRY ? -1 : 0}
                                className={renderClass('paid', 'NUMBER', 'w-78 text-end fw-normal')}
                            />
                        </InputGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceLine