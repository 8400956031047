import React, { useEffect, useState } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import Master from '../../../../Layout/admin/Master';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { getPermissions, createRoles } from '../../../../Redux/user/action';
import { Toast, ToastContainer, Form } from 'react-bootstrap';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { ROLES } from '../../../../routeNames';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';



const Create = () => {
    const navigate = useNavigate()
    const [permission, setPermission] = useState([]);
    const [nodes, setNodes] = useState([]);
    useEffect(() => {
        getPermissions()((response) => {
            // console.log(response);
            if (response && response.data && response.data.permissions) {
                setNodes(response.data.permissions)
            }

        });
    }, []);

    // console.log(checked?.length > 0)
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [toaster, setToaster] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)

    useEffect(() => {
        if (checked?.length > 0) {
            setIsSubmit(true)
        }
        else {
            setIsSubmit(false)

        }
    }, [checked]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "", roles: []
            // confirm_password: "",
        },
        validationSchema: Yup.object().shape({

            name: Yup.string()
                .required('Required'),

        }),
        onSubmit: (values) => {
            const RedirectId = getRedirectTabId()
            createRoles(values.name, checked)((response) => {
                // console.log(response);
                // e.target.reset(); 
                if (response && response.status) {
                    setToaster('success')
                    const timer = setTimeout(() => {
                        setToaster(false)
                        changeAllTitleFunction("Roles", ROLES, RedirectId)
                    }, 2000);
                    return () => clearTimeout(timer);
                }
                else {
                    // const errors = response?.response?.data?.errors?.name[0];
                    formik.setErrors({
                        ...formik,
                        name: response?.response?.data?.errors?.name,

                    })
                    // setToaster('error')
                    // const timer = setTimeout(() => {
                    //     setToaster(false)
                    //     // console.log(response);
                    //     message = response.message
                    // }, 2000);
                    // return () => clearTimeout(timer);
                }

            });
        },
    });

    // console.log('====================================');
    // console.log(checked);
    // console.log('====================================');
    const __showDataEntry = (values) => {
        //append the values with key, value pair

        createRoles(values.name, checked)((response) => {
            // console.log(response);
            // e.target.reset(); 
            if (response && response.status) {
                setToaster(true)
                const timer = setTimeout(() => {
                    setToaster(false)
                    navigate('/view/roles')
                }, 2000);
                return () => clearTimeout(timer);
            }
            else {
                const errors = response?.response?.data?.errors?.name[0];
                Formik.setErrors({
                    ...Formik,
                    current_password: errors,

                })
                // setToaster('error')
                // const timer = setTimeout(() => {
                //     setToaster(false)
                //     // console.log(response);
                //     message = response.message
                // }, 2000);
                // return () => clearTimeout(timer);
            }

        });
    }
    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .required('Required'),

    });
    return (
        <React.Fragment>
            <Master />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Failed</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1> Create Role</h1>

                </div>

                <section className="section mt-5">
                    {/*   <Formik
                        initialValues={{ name: "", roles: [] }}
                        // onSubmit={(e) => __showDataEntry(e)}
                        onSubmit={(initialValues) => {
                            // // console.log(initialValues);
                            __showDataEntry(initialValues)
                        }}
                        validationSchema={SignupSchema}

                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            isValid,
                            errors,
                        }) => ( */}
                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-body py-3 ">
                                    <Form onSubmit={formik.handleSubmit}>

                                        <div className="mb-3 row">
                                            <div className='col-lg-6'>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className='fw-bold'>Role Name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        onChange={formik.handleChange}
                                                        isValid={formik.touched?.name && !formik.errors?.name}
                                                        isInvalid={!!formik.errors?.name}
                                                        value={formik.values?.name}
                                                        className="form-control form-control-solid b-r-10"
                                                        placeholder="Enter Role Name"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.name}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-6'>
                                                <Form.Group>
                                                    <Form.Label className='fw-bold'>Permissions</Form.Label>
                                                    <CheckboxTree
                                                        nodes={nodes}
                                                        checked={checked}
                                                        expanded={expanded}
                                                        onCheck={checked => setChecked(checked)}
                                                        onExpand={expanded => setExpanded(expanded)}
                                                        icons={{
                                                            check: <i className="bi bi-check2-square" />,
                                                            uncheck: <span className="bi bi-square" />,
                                                            halfCheck: <span className="bi bi-dash-square" />,
                                                            expandClose: <span className="bi bi-chevron-right" />,
                                                            expandOpen: <span className="bi bi-chevron-down" />,
                                                            expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                            collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                            parentClose: <span className="bi bi-folder" />,
                                                            parentOpen: <span className="bi-folder2-open" />,
                                                            leaf: <span className="bi bi-file-earmark" />,
                                                        }}
                                                    />
                                                </Form.Group>

                                                {!isSubmit &&
                                                    <span className='text-danger '>
                                                        Required
                                                    </span>}
                                            </div>
                                        </div>

                                        <div className='d-flex flex-row justify-content-end'>
                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("Roles", ROLES) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>

                                            <div className=''>
                                                <button
                                                    type='submit'
                                                    disabled={!isSubmit}
                                                    className='btn btn-primary btnHover'
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* )} */}
                    {/* </Formik> */}
                </section>
            </main>
        </React.Fragment>
    )
}

export default Create;