
import Axios from 'axios';
import { GET_DASHBOARD_GRID_DATA_URL, GET_DASHBOARD_REPORT_DATA_URL, GET_DASHBOARD_USER_DATA_URL, GET_DEVICE_DETAILS, LOGIN_URL, PERMISSION_URL, REFRESH_TOKEN_URL, SESSION_LOGOUT } from './../../api/index';
import axiosInstance from '../../axiosInstance';
import { BACKEND_URL } from './../../axiosInstance';



export const loginUser = (props) => onResponse => {
    try {
        axiosInstance.post(LOGIN_URL, props.formData)
            .then((response) => {
                onResponse(response?.data ? response?.data : response);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getDashboardUserData = () => onResponse => {
    try {
        axiosInstance.get(GET_DASHBOARD_USER_DATA_URL)
            .then((response) => {
                onResponse(response?.data ? response?.data : response);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getDashboardGridData = (props) => onResponse => {
    let base_url= GET_DASHBOARD_GRID_DATA_URL;
    if(props?.type && props?.type != '')
    base_url = base_url + '?shift='+ props?.type
    try {
        axiosInstance.get(base_url)
            .then((response) => {
                onResponse(response?.data ? response?.data : response);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getDashboardReportData = () => onResponse => {
    try {
        axiosInstance.get(GET_DASHBOARD_REPORT_DATA_URL)
            .then((response) => {
                onResponse(response?.data ? response?.data : response);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getDeviceDetails = (firebaseToken, device, device_details) => onResponse => {
    try {
        axiosInstance.post(GET_DEVICE_DETAILS, { firebaseToken, device, device_details })
            .then((response) => {
                onResponse(response?.data ? response?.data : response);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const refreshToken = (refresh_token) => onResponse => {
    try {
        Axios.post(BACKEND_URL + REFRESH_TOKEN_URL, { refresh: refresh_token })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getAllPermissions = (id = "") => onResponse => {
    try {
        axiosInstance.post(PERMISSION_URL, { group: id })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const logooutSession = (refresh) => onResponse => {
    try {
        axiosInstance.post(SESSION_LOGOUT, { refresh })
            .then((response) => {
                onResponse(response?.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};