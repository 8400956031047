import React from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import * as Yup from 'yup';
import { createPayer, getFacilityDropdown, getPayerPayeeDefaultvalue } from '../../../../Redux/user/action';
import { useState } from 'react';
import { useEffect } from 'react';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import { PAYER } from '../../../../routeNames';
import styled from 'styled-components';



const CreatePayer = () => {
    const navigate = useNavigate()
    const [selectValue, setSelectValue] = useState([])
    const [defaultData, setDefaultData] = useState(null)
    const [selectedFacility, setSelectedFacility] = useState([])
    const [facilities, setFacilities] = useState([])
    const [clients, setClients] = useState([])
    const [state, setState] = useState({
        showToast: false,
        error: '',
        error_message: "",
    })

    const [toaster, setToaster] = useState(null)
    const SignupSchema = Yup.object().shape({
        clients: Yup.string()
            .required('Required'),
        // payer_id: Yup.string()
        //     .min(1, ' At least 1 character')
        //     .required('Required'),
        entity_identifier_code: Yup.string()
            .min(1, 'At least 1 character')
            .required('Required'),
        id_code_qualifier: Yup.string()
            .min(1, 'At least 1 character')
            .required('Required'),
        payer_identification: Yup.string()
            // .matches(/^[0-9]+$/, "Must be only digits")
            // .min(10, 'at least 10 character')
            // .max(10, 'at least 10 character')
            .required('Required'),
        name: Yup.string()
            .min(1, 'At least 1 character')
            .required('Required'),
        address: Yup.string()
            .min(1, 'At least 1 character')
            .required('Required'),
        entity_identifier_code: Yup.string()
            .min(1, 'At least 1 character')
            .required('Required'),
        city: Yup.string()
            .min(1, 'At least 1 character')
            .required('Required'),
        state: Yup.string()
            .matches(/^[A-Z]+$/i, 'Invalid State Abbrevation')
            .min(2, 'Only two characters')
            .max(2, 'Only two characters')
            .required('Required'),
        zip_code: Yup.string()
            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{5}\d{4}$)/, 'Invalid Zip Code')
            .required('Required'),
        country: Yup.string()
            .matches(/^[a-zA-Z ]*$/, 'Invalid Country '),
        country_subdivision_code: Yup.string()
            .min(1, 'At least 1 character'),
        is_default_industry_standard_code_available: Yup.bool().oneOf([true, false], 'Select either on of these'),



    });
    const __handleCreatePayer = (values, actions) => {
        const RedirectId = getRedirectTabId()
        let formData = new FormData();    //formdata object

        formData.append('payer_id', values.payer_id.toUpperCase());   //append the values with key, value pair
        formData.append('entity_identifier_code', values.entity_identifier_code.toUpperCase());
        formData.append('id_code_qualifier', values.id_code_qualifier.toUpperCase());
        formData.append('payer_identification', values.payer_identification.toUpperCase());
        formData.append('name', values.name.toUpperCase());
        formData.append('address', values.address.toUpperCase());
        formData.append('data_from', 1);
        formData.append('city', values.city.toUpperCase());
        formData.append('state', values.state.toUpperCase());
        formData.append('zip_code', values.zip_code.toUpperCase());
        formData.append('country', values.country.toUpperCase());
        formData.append('country_subdivision_code', values.country_subdivision_code.toUpperCase());
        formData.append('client', values?.clients);
        formData.append('is_default_industry_standard_code_available', values?.is_default_industry_standard_code_available);
        formData.append('line_of_business', values.line_of_business);

        // if (values?.clients && values?.clients?.length > 0) {

        //     values?.clients?.map((client) => {
        //     })
        // }
        if (values?.facility && values?.facility?.length > 0) {

            values?.facility?.map((data) => {
                formData.append('facility', data);
            })
        }

        createPayer({ formData })((response) => {
            // console.log(response, 'data');
            if (!response?.data?.status && response?.status && response?.status_code === 201) {
                //  setState({ showToast: !state.showToast })
                setToaster('success')
                setTimeout(() => {
                    changeAllTitleFunction("Payer", PAYER, RedirectId)
                }, 3000);
            } else {
                setState({ error: true, error_message: response?.message });
                actions.setErrors({
                    payer_id: response?.response?.data?.errors?.payer_id,
                    entity_identifier_code: response?.response?.data?.errors?.entity_identifier_code,
                    id_code_qualifier: response?.response?.data?.errors?.id_code_qualifier,
                    payer_identification: response?.response?.data?.errors?.payer_identification,
                    name: response?.response?.data?.errors?.payer,
                    address: response?.response?.data?.errors?.address,
                    city: response?.response?.data?.errors?.city,
                    state: response?.response?.data?.errors?.state,
                    zip_code: response?.response?.data?.errors?.zip_code,
                    country: response?.response?.data?.errors?.country,
                    country_subdivision_code: response?.response?.data?.errors?.country_subdivision_code,
                    is_default_industry_standard_code_available: response?.response?.data?.errors?.is_default_industry_standard_code_available,

                })
                setState({ showToast: !state.showToast, error: 'danger', error_message: 'Duplicate Entry!' })

                // setToaster('error')
                setTimeout(() => {
                    setToaster('')

                }, 2000);
            }
        });
    }
    const __toggleToast = () => {
        setState({ showToast: !state.showToast })

    }
    const fetchDefaultvalue = (clientId) => {
        getPayerPayeeDefaultvalue(clientId)((response) => {
            if (response?.status) {
                setDefaultData(response?.data)
            }
        })
    }
    useEffect(() => {
        getAllClientDropdown()((response) => {
            if (response?.status) {


                setClients(response?.data)
            }
        })
    }, [])
    useEffect(() => {
        if (selectValue) {
            getFacilityDropdown([selectValue.value])((response) => {
                if (response && response.status) {
                    setFacilities(response.data.facilities?.map(result => { return { options: result?.facilities, label: result?.client } }))
                }
            });
        }

    }, [selectValue])

    useEffect(() => {
        if (state.showToast == true) {
            const timer = setTimeout(() => {
                setState({ ...state, showToast: false })
            }, [1000]);
            return () => clearTimeout(timer);
        }
    }, [state.showToast]);
    const options = [
        // { value: "all", label: "All" },
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];
    const handleSelectChange = (selected, setValues, values) => {
        fetchDefaultvalue(selected?.value)
        setValues({
            ...values, "clients": selected.value, "facility": []
        });
        setSelectValue(selected ?? "")
        setSelectedFacility([])
    };

    const handleFacilityChange = (selected, setValues, values) => {
        setValues({ ...values, "facility": selected.map((option) => option?.value) });
        setSelectedFacility(selected)


    };

    const DropdownIndicator = ({ state, data, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {data[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}
            </components.DropdownIndicator>
        );
    };
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);


    return (
        <React.Fragment>

            <AdminMaster ToastMessage={toaster} />

            <main id="main" className="main">

                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={state.error} onClose={__toggleToast} show={state.showToast} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>{state.error_message}</span></Toast.Body>
                    </Toast>
                </ToastContainer>

                <div className="pagetitle tab-fixed-header">
                    <h1 className='text-uppercase'>Create Payer</h1>

                </div>

                <MainContainer className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className=" p-3 ">
                                    <Formik
                                        initialValues={{
                                            payer_id: "",
                                            entity_identifier_code: "",
                                            id_code_qualifier: "",
                                            payer_identification: "",
                                            name: "",
                                            address: "",
                                            city: "",
                                            state: "",
                                            zip_code: "",
                                            country: "",
                                            country_subdivision_code: "",
                                            is_default_industry_standard_code_available: false,
                                            clients: "",
                                            facility: [],
                                            line_of_business: ''
                                        }}
                                        onSubmit={(initialValues, actions) => {
                                            __handleCreatePayer(initialValues, actions)
                                        }}
                                        validationSchema={SignupSchema}

                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            setValues,
                                            values,
                                            touched,
                                            isValid,
                                            setFieldValue,
                                            errors,
                                        }) => (

                                            <Form
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="mb-3 row" >

                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold' >Payer ID</Form.Label>

                                                            <Form.Control
                                                                name='payer_id'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                // isValid={touched.payer_id && !errors.payer_id}
                                                                // isInvalid={!!errors.payer_id}
                                                                value={values.payer_id}
                                                                className='b-r-10 form-control-solid  text-uppercase'
                                                                maxLength="255"
                                                                placeholder="Payer Id"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.payer_id}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold' >Entity Identifier Code</Form.Label>

                                                            <Form.Control
                                                                name='entity_identifier_code'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.entity_identifier_code && !errors.entity_identifier_code}
                                                                isInvalid={!!errors.entity_identifier_code}
                                                                value={values.entity_identifier_code}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength="255"
                                                                placeholder="Entity Identifier Code"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.entity_identifier_code}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>ID Code Qualifier</Form.Label>
                                                            <Form.Control
                                                                name='id_code_qualifier'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.id_code_qualifier && !errors.id_code_qualifier}
                                                                isInvalid={!!errors.id_code_qualifier}
                                                                value={values.id_code_qualifier}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength="255"
                                                                placeholder="ID Code Qualifier"
                                                                onKeyUp={(e) => {
                                                                    if (e.ctrlKey && e.key === " ") {
                                                                        values.id_code_qualifier == '' && values?.clients && defaultData?.default_payer_code_qualifier &&
                                                                            setValues({
                                                                                ...values,
                                                                                id_code_qualifier: defaultData?.default_payer_code_qualifier,
                                                                            })
                                                                    }
                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.id_code_qualifier}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Payer Identification</Form.Label>
                                                            <Form.Control
                                                                name='payer_identification'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.payer_identification && !errors.payer_identification}
                                                                isInvalid={!!errors.payer_identification}
                                                                value={values.payer_identification}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                // maxLength={10}
                                                                placeholder="Payer Identification"
                                                                onKeyUp={(e) => {
                                                                    if (e.ctrlKey && e.key === " ") {
                                                                        values.payer_identification == '' && values?.clients && defaultData?.default_payer_identification && setValues({
                                                                            ...values,
                                                                            payer_identification: defaultData?.default_payer_identification,
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.payer_identification}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Default Industry Standard Code</Form.Label>
                                                            <Form.Check
                                                                type="switch"
                                                                // reverse
                                                                onChange={() => setFieldValue('is_default_industry_standard_code_available', !values?.is_default_industry_standard_code_available)}
                                                                checked={values?.is_default_industry_standard_code_available}
                                                                isInvalid={
                                                                    errors?.is_default_industry_standard_code_available &&
                                                                    !!errors?.is_default_industry_standard_code_available
                                                                }
                                                                feedbackType="invalid"
                                                                feedback={
                                                                    errors?.is_default_industry_standard_code_available &&
                                                                    errors?.is_default_industry_standard_code_available
                                                                }

                                                                name="is_default_industry_standard_code_available"
                                                            // label='Is supervisor dashboard'
                                                            />

                                                        </Form.Group>
                                                    </div>


                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Name</Form.Label>
                                                            <Form.Control
                                                                name='name'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.name && !errors.name}
                                                                isInvalid={!!errors.name}
                                                                value={values.name}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength="255"
                                                                placeholder="Name"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Address</Form.Label>
                                                            <Form.Control
                                                                name='address'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.address && !errors.address}
                                                                isInvalid={!!errors.address}
                                                                value={values.address}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength="255"
                                                                placeholder="Address"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>City</Form.Label>
                                                            <Form.Control
                                                                name='city'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.city && !errors.city}
                                                                isInvalid={!!errors.city}
                                                                value={values.city}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength="255"
                                                                placeholder="City"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>State</Form.Label>
                                                            <Form.Control
                                                                name='state'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.state && !errors.state}
                                                                isInvalid={!!errors.state}
                                                                value={values.state}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength={2}
                                                                placeholder="State"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Zip Code</Form.Label>
                                                            <Form.Control
                                                                name='zip_code'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.zip_code && !errors.zip_code}
                                                                isInvalid={!!errors.zip_code}
                                                                value={values.zip_code}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength={10}
                                                                placeholder="Zip Code"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.zip_code}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>

                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Country</Form.Label>
                                                            <Form.Control
                                                                name='country'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.country && !errors.country}
                                                                isInvalid={!!errors.country}
                                                                value={values.country}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength="255"
                                                                placeholder="Country"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.country}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Country Subdivision Code</Form.Label>
                                                            <Form.Control
                                                                name='country_subdivision_code'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.country_subdivision_code && !errors.country_subdivision_code}
                                                                isInvalid={!!errors.country_subdivision_code}
                                                                value={values.country_subdivision_code}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength="255"
                                                                placeholder="Country Subdivision Code"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.country_subdivision_code}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-5'>
                                                        <div className="mb-3 ">
                                                            <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Clients</label>


                                                            <Select
                                                                className="basic-single"
                                                                classNamePrefix="select"
                                                                // isMulti
                                                                value={selectValue}
                                                                name="clients"
                                                                options={options}
                                                                components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" data={values} /> }}
                                                                onChange={(selected) => handleSelectChange(selected, setValues, values)}
                                                                //onChange={(selected) => console.log(selected)}
                                                                placeholder="Select Clients..."
                                                                onFocus={() => setIsFocusedClient(true)}
                                                                onBlur={() => setIsFocusedClient(false)}
                                                                styles={{
                                                                    container: (styles, state) => ({
                                                                        ...styles,
                                                                        borderRadius: "9px",
                                                                    }),
                                                                    control: (styles, state) => ({
                                                                        ...styles,
                                                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                        borderStyle: "none", boxShadow: "none",
                                                                        borderRadius: "10px",
                                                                        maxHeight: state.isFocused ? "auto" : "30px",
                                                                    }),
                                                                    indicatorSeparator: styles => ({
                                                                        ...styles, width: "0px", minHeight: "20px"
                                                                    }),

                                                                    valueContainer: (styles, state) => ({
                                                                        ...styles,
                                                                        maxHeight: isFocusedClient ? "auto" : "30px",
                                                                    }),
                                                                    placeholder: (styles) => ({
                                                                        ...styles,
                                                                        textTransform: 'uppercase' // Change placeholder text to uppercase
                                                                    })
                                                                }}

                                                            />

                                                            {!!errors?.clients &&
                                                                <span className='text-danger'>{errors?.clients}</span>
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <div className="">
                                                            <Form.Group className="mb-3">
                                                                <Form.Label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Facility</Form.Label>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    isMulti
                                                                    value={selectedFacility}
                                                                    name="facility"
                                                                    options={facilities}
                                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="facility" data={values} /> }}
                                                                    onChange={(selected) => handleFacilityChange(selected, setValues, values)}
                                                                    placeholder="Select Facility..."
                                                                    isDisabled={selectValue.length > 1 ? true : false}
                                                                    onFocus={() => setIsFocusedFacility(true)}
                                                                    onBlur={() => setIsFocusedFacility(false)}
                                                                    styles={{
                                                                        container: (styles, state) => ({
                                                                            ...styles,
                                                                            borderRadius: "9px",
                                                                        }),
                                                                        control: (styles, state) => ({
                                                                            ...styles,
                                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                            borderStyle: "none", boxShadow: "none",
                                                                            borderRadius: "10px",
                                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                                        }),
                                                                        indicatorSeparator: styles => ({
                                                                            ...styles, width: "0px", minHeight: "20px"
                                                                        }),

                                                                        valueContainer: (styles, state) => ({
                                                                            ...styles,
                                                                            maxHeight: isFocusedFacility ? "auto" : "30px",
                                                                        }),
                                                                        placeholder: (styles) => ({
                                                                            ...styles,
                                                                            textTransform: 'uppercase' // Change placeholder text to uppercase
                                                                        })


                                                                    }}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors?.facility}</Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Line of Business</Form.Label>
                                                            <Form.Control
                                                                name='line_of_business'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                // isValid={touched.line_of_business && !errors.line_of_business}
                                                                // isInvalid={!!errors.line_of_business}
                                                                value={values.line_of_business}
                                                                className='b-r-10 form-control-solid text-uppercase'
                                                                maxLength="255"
                                                                placeholder="Line of Business"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.line_of_business}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    {state.error &&
                                                        <span className='text-danger'>{state.error_message}</span>}
                                                </div> */}
                                                <div className='d-flex flex-row justify-content-end'>
                                                    <div className=''>
                                                        <button
                                                            type='button'
                                                            className='text-uppercase btn btn-secondary btn-active-primary me-2'
                                                            onClick={() => { changeAllTitleFunction("Payer", PAYER) }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className=''>
                                                        <button
                                                            type='submit'
                                                            className='text-uppercase btn btn-primary btnHover'
                                                            disabled={!isValid}
                                                        >
                                                            Create Payer
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </MainContainer>


            </main>
        </React.Fragment>
    )
}

export default CreatePayer

const MainContainer = styled.div`
    label{
        text-transform:uppercase
    }
`