import React, { useState } from "react";
import DataTable from "react-data-table-component";
import datatableCustomStyles from "../../../Layout/Elements/DatatableCustomStyles";
import { GET_CORRESPONDANCE } from "../../../routeNames";
import styled from 'styled-components/macro';
import AdminMaster from "../../../Layout/admin/Master";
import Button from 'react-bootstrap/Button';
import { useMemo } from "react";
import { getEncounterData } from "../../../Redux/outputSchema/action";
import { IoIosSwitch } from 'react-icons/io';
import { useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
import { Form, OverlayTrigger } from "react-bootstrap";
import { GET_ENCOUNTER_LIST_URL } from "../../../api";
import moment from "moment";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import DateRangePicker from 'rsuite/DateRangePicker';
import { useFormik } from "formik";
import { getAllClientDropdown } from "../../../Redux/client/action";
import { BiReset, BiSearch } from "react-icons/bi";
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import { Tooltip } from '@mui/material';

const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;
function EncounterList(params) {
    const [state, setState] = useState({
        s_no: true,
        received_date: true,
        client_name: true,
        file_name: true,
        zip_file_name: true,
        source: true,
        no_of_claims: true,
        version: true,
        actions: true,
    });

    const [pageno, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [filterText, setFilterText] = useState('');
    const [colorRow, setColorRow] = React.useState({});
    const [data, setData] = useState({})
    const [limit, setLimit] = useState(100)
    const [sortColumn, setSortColumn] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [defaultValues, setDefaultValues] = useState({})

    // const HeadingsTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Table Headings
    //     </Tooltip>
    // );

    // const handleSort = async (column, order) => {
    //     console.log(column);
    //     fetc
    //     setSortColumn(column.id);
    //     setSortOrder(order);

    //     const response = await axiosInstance.get(GET_ENCOUNTER_LIST_URL + "?page=" + pageno + "&limit=" + limit + "&search=" + "" + "&sort_order=" + order + "&sort_key=" + column.id)

    //     console.log(response?.data);
    //     setData(response?.data?.data);
    // };
    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        fetchData(page, newPerPage, searchFormik.values?.search, searchFormik.values.client, datetime, searchFormik.values?.patient_account_number);
        // setData(response?.data);
        setPageNo(page)
        setLimit(newPerPage);
        // setTotalRows(response?.data?.data?.count);
        // setLoading(false);

        setLoading(false);
    };

    const handlePageChange = page => {
        setPageNo(page)
        fetchData(page, limit, searchFormik.values?.search, searchFormik.values.client, datetime, searchFormik.values?.patient_account_number);
    };

    const fetchData = (page, pageLimit, search, client, recieved_date_range, patient_account_number) => {
        setLoading(true);
        getEncounterData({ page, pageLimit, search, client, recieved_date_range, patient_account_number })((response) => {
            setData(response?.data);
            setPageNo(page)
            setDefaultValues(response?.data?.results?.defaultValues)
            setTotalRows(response?.data.count);
            setLimit(pageLimit);
            setLoading(false);
        });

    }
    const [clientList, setClientList] = useState();
    const [selectValue, setSelectValue] = useState([])

    const searchFormik = useFormik({
        initialValues: {
            search: "",
            client: "",
            recieved_date_range: "",
            created_date: "",
            patient_account_number: "",
        },
        onSubmit: (values) => {
            fetchData(1, limit, values?.search, values?.client, values?.created_date, values?.patient_account_number);
        }
    })

    const [datetime, setDateTime] = useState([]);

    const handleOnDateTimeChange = ({ name, value }) => {
        let created_date = ""
        if (value !== null) {
            const datefrom = moment(value['0']).format('MM/DD/YYYY')
            const dateto = moment(value['1']).format('MM/DD/YYYY')

            created_date = datefrom + " - " + dateto
        }
        setDateTime(created_date)
        searchFormik.setValues({ ...searchFormik.values, created_date, deposit_date_range: value ?? "" })


    }

    const handleOnSelectClientChange = async (e, name) => {
        let selectedId = []
        let selectedOptons = []

        e.map((option) => {
            selectedId.push(option.value)
            selectedOptons.push({ option })
        })

        searchFormik.setValues({ ...searchFormik.values, [name]: selectedId })
        setSelectValue(selectedOptons)
    }

    useEffect(() => {
        fetchData(1, limit,)

        getAllClientDropdown()((response) => {

            setClientList(response?.data)
        });
    }, []);
    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );
    const handleDblClickCopy = async (filename) => {
        navigator.clipboard.writeText(filename)

    };
    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S. NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                grow: 0,
                width: "60px",
                //omit: !state.s_no,
            },
            {
                id: 'client_name',
                name: 'ClIENT',
                selector: row => row?.client_name,
                cell: (row, index) =>
                    <div>
                        {row?.client_name &&

                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: "100px" }}>
                                <Tooltip disableInteractive title={row?.client_name} placement='top' arrow>
                                    {row?.client_name &&
                                        <span key={index} className='text-truncate' >{row?.client_name}</span>
                                    }
                                </Tooltip>
                            </div>
                        }
                    </div>
                ,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                grow: 0.2,
                omit: !state.client_name,
            },
            {
                id: 'FILE RECEIVED DATE & TIME',
                name: 'FILE RECEIVED DATE & TIME',
                selector: row => moment(row?.received_date).format('M/DD/YY, H:mm'),

                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.received_date ? moment(row?.received_date).format('M/DD/YY, H:mm') : "-----"}</span>,

                sortable: true,
                left: true,
                wrap: true,
                grow: 0.5,
                width: '150px',
                reorder: true,
                omit: !state.received_date,
            },
            {
                id: '837 FILE NAME',
                name: '837 FILE NAME',
                selector: row => row?.file_name,
                cell: (row, index) => <div>
                    {row?.file_name &&

                        <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' style={{ width: "200px" }}>
                            <Tooltip disableInteractive title={row?.file_name} placement='top' arrow>
                                {row?.file_name &&
                                    <span key={index} className='text-truncate' onClick={() => handleRowClick(row)}>{row?.file_name}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                grow: 0.5,
                omit: !state.file_name
            },
            {
                id: 'ZIP FILE NAME',
                name: 'ZIP FILE NAME',
                selector: row => row?.zip_file_name,
                cell: (row, index) => <div>
                    {row?.zip_file_name &&

                        <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: "200px" }}>
                            <Tooltip disableInteractive title={row?.zip_file_name} placement='top' arrow>
                                {row?.zip_file_name &&
                                    <span key={index} className='text-truncate' >{row?.zip_file_name}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                grow: 0.5,
                omit: !state.zip_file_name
            },
            {
                id: 'no_of_claims',
                name: '# CLAIMS',
                selector: row => row?.number_of_claims,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.number_of_claims}</span>,
                // sortable: true,
                left: true,
                wrap: true,
                grow: 0.1,
                reorder: true,
                omit: !state.no_of_claims,
                width: '108px',

            },
            // {
            //     id: 'source',
            //     name: 'SOURCE',
            //     selector: row => row?.source,
            //     // sortable: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.1,
            //     omit: !state.source
            // },
            // {
            //     id: 'version',
            //     name: 'VERSION',
            //     selector: row => row?.version,
            //     // sortable: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.1,
            //     omit: !state.version
            // },
            // {
            //     id: 'status',
            //     name: 'status',
            //     selector: row => row?.status,
            //     // sortable: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.1,
            //     omit: !state.status
            // },

            {
                id: 'action',
                name: 'ACTION',
                omit: !state.actions,
                selector: row => <div className="d-flex justify-content-end flex-shrink-0 ">
                    <React.Fragment>
                       

                            <div className="ms-1" role="button" style={{ width: "50px" }} onClick={() => handleRowClick(row)}>
                                <i className='bi bi-download' onClick={() => downloadFile(row?.file, row?.file_name)} />
                            </div>

                       

                    </React.Fragment>
                </div>,
                // sortable: true,
                //left: true,
                wrap: true,
                grow: 0.1,
                width: '60px',
                //button: true
            },

            // {
            //     id: 'status',
            //     name: (row) => row?.status == "Assigned" ? 'Assignee' : 'Status',
            //     grow: 1,

            //     cell: (row) =>
            //         <div className="d-flex justify-content-end flex-shrink-0 ">

            //             <React.Fragment>
            //                 <span className='badge badge-light-danger'>

            //                     {row?.staff?.username}
            //                 </span>
            //             </React.Fragment>
            //         </div>,
            //     ignoreRowClick: true,
            //     allowOverflow: true,
            //     button: true,
            // },

        ],
        [state, pageno],
    );
    const downloadFile = (file, filename) => {
        // // console.log(file);
        // var fileURL = file;
        const fileURL = file.replace('http://', 'https://')
        // console.log(fileURL);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = filename;
        alink.target = '_blank';
        alink.click();
    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];

    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
    };
    // const { data } = data?.results;
    // const sortedData = data?.results?.sort((a, b) => {
    //     if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
    //     if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
    //     return 0;
    // })
    //     .slice((pageno - 1) * totalRows, limit * totalRows);
    const handleClear = () => {
        if (filterText) {
            setFilterText('');
            fetchData(1, limit);
        }
    };

    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },
        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];
    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {searchFormik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);


    return (
        <React.Fragment>
            <AdminMaster />
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>837 List</h1>
                </div>


                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8 py-2">

                                <Form onSubmit={searchFormik.handleSubmit} onReset={() => window.location.reload()}>
                                    <div>

                                        <div className=" px-4 py-4 d-flex flex-row justify-content-start align-items-center gap-2 flex-wrap">
                                            <div className='jobmonitor-select'>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid fileManagePadding b-r-10"
                                                    name='search'
                                                    type="search"
                                                    style={{ width: "100%", fontSize: "17px" }}
                                                    placeholder=" File Name / Client Code"
                                                    aria-label="Search Input"
                                                    value={searchFormik.values?.search}
                                                    onChange={searchFormik.handleChange}
                                                />
                                            </div>
                                            <div className='jobmonitor-select'>
                                                <Select
                                                    isMulti
                                                    options={clientList}
                                                    className="select-search-client"
                                                    classNamePrefix="select"
                                                    placeholder="Select Client"
                                                    values={selectValue}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={300}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client" /> }}
                                                    isClearable
                                                    name="client"
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    onChange={(e) => handleOnSelectClientChange(e, 'client')}
                                                    defaultValue={searchFormik?.values?.client}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px", minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),
                                                    }} />
                                            </div>
                                            <div className=''>
                                                <Stack direction="column" spacing={8} alignItems="flex-start">

                                                    <DateRangePicker
                                                        ranges={predefinedBottomRanges}
                                                        // style={{ backgroundColor: "#000" }}
                                                        placement="auto"
                                                        cleanable={true}
                                                        appearance="default" format={"MM/dd/yyyy"}
                                                        placeholder="MM/DD/YYYY - MM/DD/YYYY"
                                                        className="form-date-control form-control-solid b-r-1 text-dark"
                                                        name="deposit_date_range"
                                                        onChange={(e) => { handleOnDateTimeChange({ name: 'deposit_date_range', value: e }) }} value={searchFormik?.values?.deposit_date_range} />
                                                </Stack>

                                            </div>

                                            <div className="jobmonitor-select">
                                                <TextField
                                                    className="form-control form-control-solid b-r-10 fileManagePadding"
                                                    onChange={searchFormik.handleChange}
                                                    type="search"
                                                    name="patient_account_number"
                                                    placeholder="Patient Account Number"
                                                    style={{ width: "100%", fontSize: '17px' }}
                                                    value={searchFormik.values?.patient_account_number}
                                                //style={{ fontSize: "17px" }}
                                                />
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary  " type="submit">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiSearch />
                                                        </span>

                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary " type='reset' >
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiReset />
                                                        </span>

                                                    </Button>
                                                </Tooltip>
                                            </div>

                                            <div className="dropdown ">
                                                <Tooltip disableInteractive title="Table Headings" placement='top' arrow>
                                                    <button className="btn btn-light btn-active-primary pb-2 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <IoIosSwitch />
                                                    </button>
                                                </Tooltip>

                                                <ul className="dropdown-menu" onClick={(e) => e.stopPropagation()}>
                                                    {/* <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.s_no} onChange={() => setState({ ...state, s_no: !state.s_no })} type="checkbox" id="s_no" />
                                                                <label className="form-check-label" htmlFor="s_no">
                                                                    S.NO
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li> */}
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.client_name} onChange={() => setState({ ...state, client_name: !state.client_name })} type="checkbox" id="client_name" />
                                                                <label className="form-check-label" htmlFor="client_name">
                                                                    Client
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.received_date} onChange={() => setState({ ...state, received_date: !state.received_date })} type="checkbox" id="received_date" />
                                                                <label className="form-check-label" htmlFor="received_date">
                                                                    File Recieved Date & Time
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.file_name} onChange={() => setState({ ...state, file_name: !state.file_name })} type="checkbox" id="file_name" />
                                                                <label className="form-check-label" htmlFor="file_name">
                                                                    837 File name
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.zip_file_name} onChange={() => setState({ ...state, zip_file_name: !state.zip_file_name })} type="checkbox" id="zip_file_name" />
                                                                <label className="form-check-label" htmlFor="zip_file_name">
                                                                    Zip File name
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.no_of_claims} onChange={() => setState({ ...state, no_of_claims: !state.no_of_claims })} type="checkbox" id="no_of_claims" />
                                                                <label className="form-check-label" htmlFor="no_of_claims">
                                                                    # Claims
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    {/* <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.source} onChange={() => setState({ ...state, source: !state.source })} type="checkbox" id="source" />
                                                                <label className="form-check-label" htmlFor="source">
                                                                    Source
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.version} onChange={() => setState({ ...state, version: !state.version })} type="checkbox" id="version" />
                                                                <label className="form-check-label" htmlFor="version">
                                                                    Version                                                                        </label>
                                                            </div>
                                                        </div>
                                                    </li> */}

                                                    <li>
                                                        <div className='dropdown-item'>

                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={state.actions} onChange={() => setState({ ...state, actions: !state.actions })} type="checkbox" id="actions" />
                                                                <label className="form-check-label" htmlFor="actions">
                                                                    Action                                                                       </label>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                </Form>




                                <div className="card-body" style={{ padding: "0px 2.25rem" }}>
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            <DataTable
                                                data={data?.results}
                                                className="custom-datatable"
                                                columns={columns}
                                                fixedHeader={true}
                                                sort={{ column: sortColumn, order: sortOrder }}
                                                progressPending={loading}
                                                pagination
                                                highlightOnHover
                                                paginationServer
                                                // sortServer
                                                paginationTotalRows={totalRows}
                                                paginationPerPage={limit}
                                                paginationRowsPerPageOptions={[25, 50, 100]}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                conditionalRowStyles={conditionalRowStyles}
                                                onChangePage={handlePageChange}
                                                onRowClicked={handleRowCheckClick}
                                                // onSort={handleSort}
                                                // subHeader
                                                // subHeaderComponent={subHeaderComponentMemo}
                                                theme="solarized"
                                                customStyles={datatableCustomStyles}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </main>
        </React.Fragment>
    )
}
export default EncounterList




