import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AdminMaster from '../../../Layout/admin/Master'
import Dashboard from './../Dashboard/index';
import 'react-tabs/style/react-tabs.css';
import Roles from './../Roles/index';

function MainIndex() {
    return (
        <React.Fragment>
            <div className='d-flex ' >

                {/* <Tabs style={{ marginLeft: '310px', }}>

                    <TabList>
                        <Tab>Tab 1</Tab>
                        <Tab>Tab 2</Tab>
                    </TabList>
                    <TabPanel>
                        <Dashboard />
                    </TabPanel>
                    <TabPanel>
                        <Roles />
                    </TabPanel>

                </Tabs> */}
            </div>
        </React.Fragment>
    )
}

export default MainIndex;