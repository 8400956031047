import { createTheme } from 'react-data-table-component';

const queueDatatableCustomStyles = {
    rows: {
        style: {
            minHeight: '20px', // override the row height
            fontSize: '0.7em',
            fontColor: '#000000',
            cursor: 'pointer',
            // width: '100%'

        },
    },

    headCells: {
        style: {
            paddingLeft: '5px', // override the cell padding for head cells
            paddingRight: '5px',
            textTransform: "uppercase",
            fontSize: '0.7em',
            fontWeight: '800',
            fontColor: '#6c757d !important',
            opacity: 1,
            minHeight: "10px",
            // display : 'flex',
            // flexDirection: 'row',
            // justifyContent:'center'
        },
        draggingStyle: {
            cursor: 'move',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px', // override the cell padding for data cells
            paddingRight: '8px',
            width: "100%",
            fontColor: '#000000',
            fontWeight: "550",
            cursor: 'pointer',
        },
    },
    pagination: {
        style: {
            color: '#000',
            fontSize: '0.8em',
            minHeight: '56px',
            backgroundColor: '#fff',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            borderTopColor: '#f2f2f2',
        },
        pageButtonsStyle: {
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            padding: '8px',
            margin: '0px',
            cursor: 'pointer',
            transition: '0.4s',
            color: '#000',
            fill: '#000',
            backgroundColor: 'transparent',
            '&:disabled': {
                cursor: 'unset',
                color: '#e2e2e2',
                fill: '#e2e2e2',
            },
            '&:hover:not(:disabled)': {
                backgroundColor: '#f2f2f2',
            },
            '&:focus': {
                outline: 'none',
                backgroundColor: '#f5f5f5',
            },
        },
    },
};
createTheme(
    'solarized',
    {
        text: {
            primary: '#000000',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
        },
        background: {
            default: '#FFFFFF',
        },
        context: {
            background: '#e3f2fd',
            text: 'rgba(0, 0, 0, 0.87)',
        },
        divider: {
            default: '#f4f4f4',
        },
        button: {
            default: 'rgba(0,0,0,.54)',
            focus: 'rgba(0,0,0,.12)',
            hover: 'rgba(0,0,0,.12)',
            disabled: 'rgba(0, 0, 0, .18)',
        },
        selected: {
            default: '#e3f2fd',
            text: 'rgba(0, 0, 0, 0.87)',
        },
        highlightOnHover: {
            default: '#EEEEEE',
            text: 'rgba(0, 0, 0, 0.87)',
        },
        striped: {
            default: '#FAFAFA',
            text: 'rgba(0, 0, 0, 0.87)',
        },
    }
);
export default queueDatatableCustomStyles;
