import { EMAIL_LOG_LIST_URL, GET_OFFLINE_JOBS_LOG_HISTORY_URL, GET_OFFLINE_STATUS_JOBS_URL, GET_TOGGLE_OFFLINE_STATUS_URL, PULL_SFTP_FILE_MANUALLY_V2_URL, SFTP_CLIENT_LIST_URL, SFTP_LOG_LIST_URL, GET_REJECT_HISTORY_URL, HOUSE_KEEPING_LOG_LIST_URL, PULL_SFTP_FILE_MANUALLY_V3_URL } from "../../api";
import axiosInstance from "../../axiosInstance";


export const getSFTPLogListData = (props) => onResponse => {

    try {
        const search = props?.search ? props?.search : "";
        // const filename = filename ? filename : "";
        const client = props?.client ? props?.client : "";
        const deposit_date_range = props?.deposit_date_range ? props?.deposit_date_range : "";
        const status = props?.status ? props?.status : "";

        axiosInstance.get(SFTP_LOG_LIST_URL + "?page=" + props?.page + "&limit=" + props?.page_limit
            + "&search=" + search + "&client=" + client + "&deposit_date_range=" + deposit_date_range + "&status=" + status
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getHouseKeepingLogListData = (props) => onResponse => {

    try {
        console.log(props);
        const search = props?.search ? props?.search : "";
        const status = props?.status ? props?.status : "";
        const deposit_date_range = props?.deposit_date_range ? props?.deposit_date_range : "";

        axiosInstance.get(HOUSE_KEEPING_LOG_LIST_URL + "?page=" + props?.page + "&limit=" + props?.page_limit
            + "&search=" + search + "&deposit_date_range=" + deposit_date_range + "&log_type=" + status
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getEmailLogListData = (props) => onResponse => {

    try {
        console.log(props);
        const search = props?.search ? props?.search : "";
        // const filename = filename ? filename : "";
        const client = props?.client ? props?.client : "";
        const email_type = props?.email_type ? props?.email_type : "";
        const sent_status = props?.status && props?.status == 'sent' ? true : props?.status && props?.status == 'failed' ? false : "";
        const deposit_date_range = props?.deposit_date_range ? props?.deposit_date_range : "";

        axiosInstance.get(EMAIL_LOG_LIST_URL + "?page=" + props?.page + "&limit=" + props?.page_limit
            + "&client=" + client + "&email_type=" + email_type + "&sent_status=" + sent_status + "&deposit_date_range=" + deposit_date_range
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};




// encounter list 835
export const getSFTPClientListData = (props) => onResponse => {
    try {
        // let base_uri = `${SFTP_CLIENT_LIST_URL}?`
        let order = ""
        let column = ""

        if (props?.order !== undefined) {
            order = props?.order
        }
        if (props?.column !== undefined) {
            column = props?.column
        }

        axiosInstance.get(SFTP_CLIENT_LIST_URL + "?page=" + props.page + "&limit=" + props.pageLimit + "&search=" + props.search + "&sort_order=" + order + "&sort_key=" + column)
            // axiosInstance.get(SFTP_CLIENT_LIST_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};

// offline status list
export const getOfflineStatusData = (props) => onResponse => {
    try {
        // let base_uri = `${SFTP_CLIENT_LIST_URL}?`

        axiosInstance.get(GET_OFFLINE_STATUS_JOBS_URL + "?page=" + props.page + "&limit=" + props.pageLimit + "&search=" + props.search)
            // axiosInstance.get(SFTP_CLIENT_LIST_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};

export const getOfflineJobLogHistory = (periodic_task) => onResponse => {
    try {
        axiosInstance.get(GET_OFFLINE_JOBS_LOG_HISTORY_URL + "?periodic_task=" + periodic_task)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getRejectHistory = (sftp_id) => onResponse => {
    try {
        axiosInstance.get(GET_REJECT_HISTORY_URL + "?sftp_id=" + sftp_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const toggleOfflineStatus = (props) => onResponse => {
    try {
        axiosInstance.post(GET_TOGGLE_OFFLINE_STATUS_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

// encounter list 835
export const pullSFTPFileManually = (props) => onResponse => {
    try {
        axiosInstance.post(PULL_SFTP_FILE_MANUALLY_V3_URL, props)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};