import React from 'react'
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DeleteSweetAlert from '../../SweetAlert/DeleteSweetAlert';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { OverlayTrigger } from 'react-bootstrap';
import AdminMaster from '../../../Layout/admin/Master';
import { getClaimsFiling, getDeleteClaimsFiling } from '../../../Redux/claimsFiling/action';
import { CREATE_EMAIL_TEMPLATE, EDIT_EMAIL_TEMPLATE } from '../../../routeNames';
import datatableCustomStyles from '../../../Layout/Elements/DatatableCustomStyles';
import { getAllEmailTemplates, setEmailTemplateStatus } from '../../../Redux/emailTemplate/action';
import { changeAllTitleFunction } from '../Dashboard/functions';
import { Tooltip } from '@mui/material';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Template"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }
    


`;

const ViewEmailTemplate = () => {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [isSwal, setIsSwal] = useState({
        show: false,
        id: ''
    })
    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
    });
    const [pageno, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(30);
    const [limit, setLimit] = useState(30);
    const [toaster, setToaster] = useState(false)
    const [data, setData] = useState({})
    const [filterText, setFilterText] = useState('');
    const [colorRow, setColorRow] = React.useState({});
    const [message, setMessage] = useState(false)

    const handleClear = () => {
        if (filterText) {
            setFilterText('');
            fetchEmailTemplate(1, limit)
        }
    };

    const fetchEmailTemplate = (pageno, limit, search) => {
        setLoading(true)

        getAllEmailTemplates(pageno, limit, search)((response) => {
            if (response?.status && response?.status_code == 200) {

                setData(response?.data);
                setPageNo(pageno)
                setLimit(limit);
                setTotalRows(response?.data?.count)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchEmailTemplate(1, limit)
    }, []);

    const handlePageChange = page => {
        setPageNo(page)
        fetchEmailTemplate(page, limit, filterText);
    };
    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );
    // const toggleTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Status
    //     </Tooltip>
    // );

    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        fetchEmailTemplate(1, newPerPage, filterText)
        setLimit(newPerPage)
        setLoading(false);
        handleResetDataTable()
    };



    const __toggleEmailStatus = (id) => {

        setEmailTemplateStatus(id)((response) => {
            if (response?.status && response?.status_code === 201) {
                setState({ showToast: !state.showToast })
                setToaster('success')
                // fetchClaimsFiling(pageno, limit, filterText)


            } else {
                if (response?.response?.data?.errors?.email_type[0] && response?.response?.data?.errors?.email_type[0]) {
                    setMessage(response?.response?.data?.errors?.email_type[0])
                }
                setToaster('error')
                setState({ error: true, error_message: response?.message });
                window.location.reload()
            }
        });
    }

    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster(false)
            }, 2000);
            return () => clearTimeout(timer)
        }
    }, [toaster])

    const handleDelete = (pk) => {
        // console.log(pk);
        setIsSwal({ ...isSwal, show: 2 })
        onClickDeleteClaimsFiling(pk)
        // console.log('******');
    }

    const handleSearchSplits = async (e) => {
        setFilterText(e)
        let data = e ? e : '';
        fetchEmailTemplate(1, limit, data)
        handleResetDataTable()
    }

    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
        // // console.log(row);
    };

    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
            }),
        },
    ];

    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S.NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                sortable: true,
                width: '50px',
                //sortable: true,

            },
            {
                id: 'email_type',
                name: 'Email Type',
                selector: row => row?.email_type,
                cell: (row) => <div>
                    {row?.email_type &&

                        <div className='d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: '260px' }}>
                            <Tooltip disableInteractive title={row?.email_type} placement='top' arrow>
                                {row?.email_type &&
                                    <span className='text-truncate ' >{row?.email_type}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>,
                sortable: true,
                //width: "180px"
            },
            {
                id: 'subject',
                name: 'subject',
                selector: row => row?.subject,
                cell: (row) => <div>
                    {row?.subject &&

                        <div className='d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: '400px' }}>
                            <Tooltip disableInteractive title={row?.subject} placement='top' arrow>
                                {row?.subject &&
                                    <span className='text-truncate ' >{row?.subject}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>,
                sortable: true,
                //width: "140px"
            },



            {
                id: 'actions',
                name: 'Actions',
                cell: (row) =>
                    <div className='d-flex align-items-center gap-2' onClick={() => handleRowClick(row)}>
                        <React.Fragment>
                            {checkUserPermission('edit_email_templates') &&

                                <Tooltip disableInteractive title="Edit" placement='top' arrow>
                                    <Link onClick={() => changeAllTitleFunction('Edit Email Template', EDIT_EMAIL_TEMPLATE + row.id)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                        <span className="svg-icon svg-icon-3">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                    </Link>

                                </Tooltip>
                            }
                            <Tooltip disableInteractive title="Status" placement='top' arrow>

                                <div className=" ms-2" >

                                    <Form.Check
                                        onClick={() => __toggleEmailStatus(row.id)}
                                        type="switch"
                                        role="switch" defaultChecked={row.is_active} id="flexSwitchCheckDefault"
                                    />


                                </div>
                            </Tooltip>
                        </React.Fragment>

                    </div>,
                //sortable: true,
                // ignoreRowClick: false,
                // allowOverflow: true,
                right: true,
                button: false,
                //width: "100px",

            },



        ],
        [pageno],
    );

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }

    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} message={message} />
            <main id="main" className="main">

                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Email Templates</h1>
                </div>

                <section className="section mt-5 ">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8 ">
                                <div className='card-header pt-3 px-4'>
                                    <div className="d-flex align-items-center  position-relative ">
                                        <FilterComponent onFilter={(e) => handleSearchSplits(e.target.value)} onClear={handleClear} filterText={filterText} />
                                    </div>
                                    <div className="card-toolbar gap-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                        {checkUserPermission('create_email_templates') &&

                                            <Link onClick={() => changeAllTitleFunction('Create Email Template', CREATE_EMAIL_TEMPLATE)} className="btn btn-light btn-active-primary">
                                                <span className="svg-icon svg-icon-3">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                    </svg>
                                                </span>
                                                Create
                                            </Link>
                                        }
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}
                                                    data={data?.results}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    highlightOnHover
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}

                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <DeleteSweetAlert handleDelete={handleDelete} isSwal={isSwal} setIsSwal={setIsSwal} />
        </React.Fragment>
    )
}

export default ViewEmailTemplate