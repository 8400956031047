import React from 'react'
import Master from '../../../../Layout/admin/Master'
import * as Yup from 'yup';
import { FloatingLabel, Form } from 'react-bootstrap';
import { Formik, Field, useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { Link, useHistory, useNavigate } from "react-router-dom";
import { createGroup, getRoles, getRolesDropdown } from '../../../../Redux/user/action';
import { Toast, ToastContainer } from 'react-bootstrap';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import { GROUPS } from '../../../../routeNames';

// const history = useHistory();

const message = "";
const CreateGroup = () => {
    const navigate = useNavigate()
    const [toaster, setToaster] = useState(false)
    const [options, setOptions] = useState([])
    const [state, setState] = useState({
        error: false,
        error_message: ""
    })
    const [roles, setRoles] = useState()
    useEffect(() => {
        getRolesDropdown()((response) => {
            if (response && response.status && response.data && response.data)
            // if(response  && response.results)
            {
                const data = []
                response.data.forEach((item) => {
                    data.push({ value: item.id, label: item.name })
                })
                setOptions(data)
            }
        });
    }, []);
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            groupName: "", roles: []
            // confirm_password: "",
        },
        validationSchema: Yup.object().shape({

            groupName: Yup.string()
                .required('Required'),

        }),
        onSubmit: (values, actions) => {
            const RedirectId = getRedirectTabId()
            createGroup(values.groupName, roles)((response) => {
                // console.log(response);
                // e.target.reset(); 
                if (response?.status) {
                    setToaster('success')
                    const timer = setTimeout(() => {
                        setToaster(false)
                        changeAllTitleFunction("Groups", GROUPS, RedirectId)
                    }, 2000);
                    return () => clearTimeout(timer);
                }
                else {

                    actions.setErrors({

                        groupName: response?.response?.data?.errors?.name,

                    })
                    // setToaster('error')
                    // const timer = setTimeout(() => {
                    //     setToaster(false)
                    //     // console.log(response);
                    //     message = response.message
                    // }, 2000);
                    // return () => clearTimeout(timer);
                }

            });
        },
    });
    const __showDataEntry = (values, actions) => {

        createGroup(values.groupName, roles)((response) => {
            // console.log(response);
            // e.target.reset(); 
            if (response && response.status) {
                setToaster('success')
                const timer = setTimeout(() => {
                    setToaster(false)
                    navigate('/view/groups')
                }, 2000);
                return () => clearTimeout(timer);
            }
            else {
                setToaster('error')
                actions.setErrors({

                    groupName: response?.response?.data?.errors?.name,
                    roles: response?.response?.data?.errors?.roles,

                })
                const timer = setTimeout(() => {
                    setToaster(false)
                    // console.log(response);
                    message = response.message
                }, 2000);
                return () => clearTimeout(timer);
            }

        });
    }
    const handleChangeSelect = (e) => {
        setRoles(e.map((value) => value.value))
        // // console.log(roles)



    }
    const SignupSchema = Yup.object().shape({
        groupName: Yup.string()
            .required('Required'),
        roles: Yup.array().min(1, 'At least one value is required')
            .of(
                Yup.object().shape({
                    value: Yup.number().required(),
                    label: Yup.string().required()
                })
            ),
    });


    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {roles?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };

    const [isFocusedClient, setIsFocusedClient] = useState(false);



    return (
        <React.Fragment>
            <Master />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Failed</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1>Create Group</h1>

                </div>

                <section className="section mt-5">
                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-body py-3 ">
                                    <Formik
                                        initialValues={{ groupName: "", roles: [] }}
                                        // onSubmit={(e) => __showDataEntry(e)}
                                        onSubmit={(values, actions) => {
                                            // console.log(values, "*********************");
                                            // __showDataEntry(initialValues, actions);
                                            const RedirectId = getRedirectTabId()
                                            createGroup(values.groupName, roles)((response) => {
                                                // console.log(response);
                                                // e.target.reset(); 
                                                if (response?.status) {

                                                    setToaster('success')
                                                    const timer = setTimeout(() => {
                                                        setToaster(false)
                                                        changeAllTitleFunction("Groups", GROUPS, RedirectId)
                                                    }, 2000);
                                                    return () => clearTimeout(timer);
                                                }
                                                else {
                                                    actions.setErrors({

                                                        groupName: response?.response?.data?.errors?.name,
                                                        roles: response?.response?.data?.errors?.roles,

                                                    })
                                                    // setToaster('error')
                                                    // const timer = setTimeout(() => {
                                                    //     setToaster(false)
                                                    //     // console.log(response);
                                                    //     message = response.message
                                                    // }, 2000);
                                                    // return () => clearTimeout(timer);
                                                }

                                            });
                                        }}
                                        validationSchema={SignupSchema}

                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            setFieldValue,
                                            values,
                                            touched,
                                            isValid,
                                            errors,
                                        }) => (

                                            <Form
                                                onSubmit={handleSubmit}
                                            >

                                                <div className="mb-3 row">
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label className='fw-bold'>Group Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name='groupName'
                                                                onChange={handleChange}
                                                                className="form-control form-control-solid b-r-10"
                                                                placeholder="Enter Group Name"
                                                                isValid={touched?.groupName && !errors?.groupName}
                                                                isInvalid={!!errors?.groupName}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors?.groupName}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label className='fw-bold'>Roles</Form.Label>
                                                            <Field
                                                                className="custom-select"
                                                                name="roles"
                                                                options={options}
                                                                onChange={(e) => {
                                                                    handleChangeSelect(e); setFieldValue('roles', e)
                                                                }}
                                                                component={Select}
                                                                placeholder="Select Roles"
                                                                isMulti={true}
                                                                components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} /> }}
                                                                onFocus={() => setIsFocusedClient(true)}
                                                                onBlur={() => setIsFocusedClient(false)}
                                                                styles={{
                                                                    container: (styles, state) => ({
                                                                        ...styles,
                                                                        borderRadius: "9px",
                                                                    }),
                                                                    control: (styles, state) => ({
                                                                        ...styles,
                                                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                        borderStyle: "none", boxShadow: "none",
                                                                        borderRadius: "10px",
                                                                        maxHeight: state.isFocused ? "auto" : "30px",
                                                                    }),
                                                                    indicatorSeparator: styles => ({
                                                                        ...styles, width: "0px", minHeight: "20px"
                                                                    }),

                                                                    valueContainer: (styles, state) => ({
                                                                        ...styles,
                                                                        maxHeight: isFocusedClient ? "auto" : "30px",
                                                                    }),
                                                                }} />
                                                            {errors?.roles && touched?.roles &&

                                                                <span className='text-danger'>{errors?.roles}</span>
                                                            }
                                                        </Form.Group>
                                                    </div>


                                                </div>
                                                {state.error &&
                                                    <div className='text-center mt-3'>

                                                        <span className='text-danger fw-bold '>{state.error_message}</span>
                                                    </div>
                                                }
                                                <div className='row mt-2'>
                                                    <div className='col-lg-12 text-end'>
                                                        <button
                                                            onClick={() => { changeAllTitleFunction("Groups", GROUPS) }}
                                                            type='button'
                                                            className='btn me-1 btn-secondary new-window'
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            disabled={!isValid}
                                                            type='submit'
                                                            className='btn btn-primary btnHover new-window'
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default CreateGroup