

import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    data837: localStorage.getItem("data837") ? JSON.parse(localStorage.getItem("data837")) : [],
};



const data837Slice = createSlice({
    name: "data837",
    initialState,
    reducers: {
        setPatient837: (state, action) => {

            state.claimIndex = action.payload.claimIndex;

            localStorage.setItem("data837", JSON.stringify(action.payload));
        },
    },
});

export const { setPatient837 } = data837Slice.actions;


export default data837Slice.reducer;
