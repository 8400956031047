
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    firebase: localStorage.getItem("firebase") ? JSON.parse(localStorage.getItem("firebase")) : [],
};

// export default function (state = initialState, action) {
//     switch (action.type) {
//         case LOGIN:
//             return { ...state, user: action.payload };
//         default:
//             return state;
//     }
// }

const fireBaseSlice = createSlice({
    name: "firebase",
    initialState,
    reducers: {
        firebaseSuccess: (state, action) => {
            firebase = action.payload;
            state.firebase = action.payload;
            localStorage.setItem("firebase", JSON.stringify(action.payload));
        },

    },
});

export const { firebaseSuccess } = fireBaseSlice.actions;



export default fireBaseSlice.reducer;
