import React, { useRef } from 'react';
import { FloatingLabel, Form, Toast, ToastContainer } from 'react-bootstrap';
import logo from '../../assets/img/Xtract-Login.png';
import { useState, useEffect } from 'react';
import { getAllPermissions, loginUser, logooutSession } from '../../Redux/auth/action';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { loginSuccess } from '../../Redux/auth/reducer';
import { requestForToken } from '../../firebase';
import moment from 'moment';




const Login = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        localStorage.clear();
    }, []);

    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const [showLogout, setShowLogout] = useState(false);
    const [showRefresh, setShowRefresh] = useState(false);
    const [usenameFocus, setUsenameFocus] = useState(false);
    const [passwordFocus, setPasswordFocus] = useState(false);
    const [show, setShow] = useState(false);

    const [state, setState] = useState({
        error: false,
        error_message: ""
    })

    const [loginSubmit, setLoginSubmit] = useState(false);
    const [isTokenFound, setTokenFound] = useState(false);
    const [notification, setNotification] = useState({ title: '', body: '' });




    const SignupSchema = Yup.object().shape({
        username: Yup.string()
            .required('Required')
            .min(3, 'Too Short! Enter Valid Username'),
        password: Yup.string()
            .min(3, 'Password must be at least 6 characters')
            .required('Required'),
    });


    const logOut = async (setErrors) => {
        setLoginSubmit(true)
        await logooutSession(showRefresh)((response) => {
            if (response?.status) {
                setLoginSubmit(false)

                setShowLogout(false)
                localStorage.clear();
                setErrors({
                    username: "",
                    password: ""
                })
                // window.location.reload()
            } else {
                setLoginSubmit(false)
            }
        })
    }


    const [showPassword, setShowPassword] = useState(false);





    const handlePasswordKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            passwordRef.current.focus();
        }
    };




    const setLoginTitle = () => {
        const domain = window.location.origin;
        let value = ""
        switch (domain) {
            case "https://uat.db-xtract.com":
                value = "Sign in - UAT"
                break;
            case "https://training.db-xtract.com":
                value = "Sign in - TRAINING"
                break
            case "https://demo.db-xtract.com":
                value = "Sign in - DEMO"
                break
            default:
                value = "Sign in"
        }
        return value
    }
    return (
        <React.Fragment>
            <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg="danger" show={state.error ? true : false} onClose={() => setState({ error: false, error_message: '' })} animation={true} delay={2000} autohide>
                    <Toast.Body ><span className='text-white'>{state.error_message}</span></Toast.Body>
                </Toast>

            </ToastContainer>
            <Toast bg='success' onClose={() => setShow(false)} show={show} delay={3000} autohide animation style={{
                position: 'fixed',
                top: 70,
                right: 20,
                minWidth: 200
            }}>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto ">{notification.title}</strong>
                    <small>just now</small>
                </Toast.Header>
                <Toast.Body className='text-white'>{notification.body}</Toast.Body>
            </Toast>

            <section className='loginForm'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 text-center'>
                            <img src={logo} className='img-fluid' alt="xtract" />
                        </div>
                        <div className='col-lg-6'>
                            <div className='logFont'>

                                <h1 className='loginLabel'>{setLoginTitle()}</h1>
                            </div>
                            <div className='d-flex flex-column align-items-center mt-3' >

                                <div className='w-60'>
                                    <Formik
                                        initialValues={{ username: "", password: "" }}
                                        onSubmit={(values, actions) => {
                                            let formData = new FormData();
                                            setLoginSubmit(true)
                                            formData.append('username', values.username);
                                            formData.append('password', values.password);


                                            loginUser({ formData })((response) => {

                                                if (response?.status && response?.status_code === 200) {
                                                    setLoginSubmit(false)
                                                    const data = response?.data;
                                                    localStorage.setItem('token', data.token);
                                                    localStorage.setItem('refresh_token', data.refresh);
                                                    const user = {
                                                        id: data?.user?.id,
                                                        username: data?.user?.username,
                                                        email: data?.user?.email,
                                                        is_admin: data?.user?.is_admin,
                                                        is_staff: data?.user?.is_staff,
                                                        is_superuser: data?.user?.is_superuser,
                                                        is_verified: data?.user?.is_verified,
                                                        is_active: data?.user?.is_active,
                                                        settings: data?.user?.settings,
                                                    };
                                                    localStorage.setItem('timezone', data?.user.timezone)


                                                    if (data && data.user) {
                                                        requestForToken(setTokenFound);

                                                    }
                                                    if (data?.user?.groups.length > 0) {
                                                        const group_id = data?.user?.groups[0].id
                                                        getAllPermissions(group_id)((response) => {
                                                            if (response) {
                                                                const updatedObject = {
                                                                    ...data?.user,
                                                                    permissions: response?.codename,
                                                                };


                                                                dispatch(loginSuccess(updatedObject));

                                                                localStorage.setItem('permissions', JSON.stringify(response?.codename))
                                                                window.open(window.location.origin + "/xtract", "xtract", "width=" + screen.availWidth + ",height=" + screen.availHeight)
                                                                localStorage.setItem('popup', true)

                                                            }
                                                        });
                                                    } else if (data && data.user) {
                                                        dispatch(loginSuccess(data?.user));
                                                        window.open(window.location.origin + "/xtract", "xtract", "width=" + screen.availWidth + ",height=" + screen.availHeight)
                                                        localStorage.setItem('popup', true)


                                                    }
                                                    actions.resetForm();

                                                }
                                                else if (response?.status_code === 207) {
                                                    setLoginSubmit(false)
                                                    setShowLogout(true)
                                                    localStorage.setItem('token', response?.data?.token)
                                                    setShowRefresh(response?.data?.refresh)
                                                    actions.setErrors({
                                                        username: response?.message,
                                                        password: response?.message
                                                    })
                                                }
                                                else if (response?.status_code === 401) {
                                                    setLoginSubmit(false)
                                                    setState({ error: true, error_message: response?.message });
                                                    localStorage.clear();
                                                    actions.setErrors({
                                                        username: response?.message,
                                                        password: response?.message
                                                    })
                                                }

                                                else {
                                                    setLoginSubmit(false)
                                                    setState({ error: true, error_message: response?.message });
                                                    localStorage.clear();
                                                    actions.setErrors({
                                                        username: response?.message,
                                                        password: response?.message,
                                                    })

                                                }
                                            });
                                        }}
                                        validationSchema={SignupSchema}

                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            touched,
                                            isValid,
                                            errors,
                                            setErrors,
                                            isSubmitting
                                        }) => (

                                            <Form
                                                onSubmit={handleSubmit}
                                            >

                                                <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Username"
                                                    className="input-padding username-color-active"
                                                    style={usenameFocus ? { zIndex: '1' } : { zIndex: '0' }}
                                                >
                                                    <Form.Control
                                                        autoComplete='one-time-code'
                                                        name='username'
                                                        type="text"
                                                        onFocus={() => { setUsenameFocus(true) }}
                                                        onBlur={() => { setUsenameFocus(false) }}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                            setShowLogout(false)

                                                        }}
                                                        ref={usernameRef}
                                                        isValid={touched.username && !errors.username}
                                                        isInvalid={!!errors.username}
                                                        value={values.username}
                                                        className='b-r-t'
                                                        required
                                                        onKeyDown={handlePasswordKeyDown}
                                                        onkey
                                                    />

                                                </FloatingLabel>
                                                <FloatingLabel
                                                    controlId="floatingPassword"
                                                    label="Password"
                                                    className='common-color-active'
                                                    style={passwordFocus ? { zIndex: '1' } : { zIndex: '0' }}

                                                >

                                                    <Form.Control
                                                        autoComplete='one-time-code'
                                                        name='password'
                                                        type={showPassword ? "text" : "login-password"}
                                                        onFocus={() => { setPasswordFocus(true) }}
                                                        onBlur={() => { setPasswordFocus(false) }}
                                                        onChange={(e) => {
                                                            setShowLogout(false)
                                                            handleChange(e)
                                                        }}
                                                        required
                                                        ref={passwordRef}
                                                        isValid={touched.password && !errors.password}
                                                        isInvalid={!!errors.password}
                                                        value={values.password}
                                                        minLength="3"
                                                        className='b-r-b'
                                                    />
                                                    <div className='' style={{ position: 'absolute', top: '20px', right: '35px', cursor: 'pointer' }} onClick={() => setShowPassword(!showPassword)}  >
                                                        <i className={showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'}></i>
                                                    </div>

                                                    {errors.password && showLogout &&
                                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                                    }
                                                    {errors.password && showLogout &&
                                                        <div style={{ display: 'flex' }} >
                                                            <div className='custome-invalid-feedback'>Do you want to logout from the system? &nbsp; </div>

                                                            <button style={{ background: '#176c89', color: 'white' }} onClick={() => { !loginSubmit ? logOut(setErrors) : console.log("Loading..") }} className="btn" type="button">
                                                                Logout
                                                            </button>

                                                        </div>
                                                    }

                                                </FloatingLabel>
                                                {state.error &&
                                                    <div className='text-center mt-3'>

                                                        <span className='text-danger fw-bold '>{state.error_message}</span>
                                                    </div>
                                                }
                                                {!showLogout &&
                                                    <div className='buttonLogin mt-4'>
                                                        {loginSubmit ?
                                                            <button
                                                                id='myButton'
                                                                type='button'
                                                                className='btn btn-primary new-window'
                                                            >
                                                                Login
                                                            </button>
                                                            :
                                                            <button
                                                                id='myButton'
                                                                type='submit'
                                                                className='btn btn-primary new-window'
                                                            >
                                                                Login
                                                            </button>
                                                        }
                                                    </div>
                                                }
                                            </Form>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='login-footer-position'>
                    <span className='' style={{ color: '#176c89', fontSize: "0.8em" }}>
                        Copyright <i className="bi bi-c-circle  fw-bolder"></i> {moment().year()} <a href='http://www.zaparetech.com/' target="_blank" style={{ textDecoration: 'none', color: '#176c89' }} className='' >DataBlocks Inc dba Zapare Technologies.</a> All rights reserved.
                    </span>
                </div>
            </section>

        </React.Fragment>
    );
}

export default Login;
