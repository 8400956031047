import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Toast, ToastContainer } from 'react-bootstrap';
import { createClient } from './../../../../../Redux/client/action';
import * as Yup from 'yup';
import { useFormik, Field, Formik } from 'formik';

import { getGroupsForClient, getSingleAssignGroupUser } from '../../../../../Redux/clientGroup/action';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import { CLIENT, EDIT_CLIENT } from '../../../../../routeNames';
import { getPartnersDropdown } from '../../../../../Redux/partner/action';
import { changeAllTitleFunction } from '../../../Dashboard/functions';
import { handleWheel } from '../../../DataEntry/Grid/gridFunctions';

function ClientForm(props) {
    const { client_data, __toggleToast, setLoading, copy_id } = props;
    const [isSubmit, setIsSubmit] = useState(false)
    const [options, setOptions] = useState({});
    const [partners, setPartners] = useState({});
    const [togglePassword, setTogglePassword] = useState(false);
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (props?.client_data) {

            clientformik.setValues({
                client_id: client_data?.client_id,
                client_group: client_data?.client_group_name?.id,
                partner: client_data?.partner?.value,
                name: client_data?.name,
                address: client_data?.address,
                city: client_data?.city,
                state: client_data?.state,
                country: client_data?.country,
                zip_code: client_data?.zip_code,
                npi: client_data?.npi,
                tin: client_data?.tin,
                username: client_data?.username,
                password: client_data?.password,
                Eob: client_data?.eob,
                patient_pay: client_data?.patient_pay,
                correspondence: client_data?.correspondence,
                Eob_lite: client_data?.eob_lite,
                tat_for_client: client_data?.tat_for_client,
                internal_tat: client_data?.internal_tat,
                qa: client_data?.qa,
                is_single_transaction_tiff: client_data?.is_single_transaction_tiff,
                Facility: client_data?.is_facility_available,
                Claim: client_data?.is_claim_available,
                is_revarc_available: client_data?.is_revarc_available,
                is_dbk_available: client_data?.is_dbk_available,
                is_snippet_available: client_data?.is_snippet_available,
            })
        }

    }, [client_data]);

    useEffect(() => {
        getGroupsForClient()((response) => {
            setOptions(response?.data);
        });
        getPartnersDropdown()((response) => {
            setPartners(response?.data?.results);
        });

    }, []);
    const clientformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            client_group: "",
            partner: "",
            client_id: "",
            name: "",
            address: "",
            city: "",
            state: "",
            country: "",
            zip_code: "",
            npi: "",
            tin: "",
            username: "",
            password: "",
            Eob: false,
            patient_pay: false,
            correspondence: false,
            Eob_lite: false,
            tat_for_client: "",
            internal_tat: "",
            qa: "",
            is_single_transaction_tiff: false,
            Facility: "",
            Claim: "",
            is_dbk_available: "",
            is_revarc_available: "",
            is_snippet_available: "",
        },
        validationSchema: Yup.object().shape({
            client_id: Yup.string()
                .min(1, ' at least 1')
                .required('Required'),
            name: Yup.string()
                .min(3, ' at least 3')
                .required('Required'),
            address: Yup.string()
                .min(3, ' at least 3')
                .required('Required'),
            city: Yup.string()
                .min(3, ' at least 3')
                .required('Required'),
            state: Yup.string()
                .matches(/^[A-Z]+$/i, 'Enter a valid State Abbrevation')
                .min(2, 'Only 2 characters')
                .max(2, 'Only 2 characters')
                .required('Required'),
            country: Yup.string()
                .min(2, ' at least 2')
                .required('Required'),
            zip_code: Yup.string()
                .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Enter a valid Zip code')
                .required('Required'),
            npi: Yup.string()
                .matches(/^[0-9]+$/, "Only digits allowed")
                .min(10, 'Only 10 digits')
                .max(10, 'Only 10 digits')
                .required('Required'),
            tin: Yup.string()
                .matches(/^[0-9]+$/, "Only digits allowed")
                .min(9, 'Only 9 digits')
                .max(9, 'Only 9 digits')
                .required('Required'),
            username: Yup.string()
                .min(3, ' at least 3')
                .required('Required'),
            password: client_data?.client_id ? Yup.string() : Yup.string().required('Required'),
            // .min(3, 'Password is too short - should be 8 chars minimum.'),
            Eob: Yup.bool().oneOf([true, false], 'Select either on of these'),
            patient_pay: Yup.bool().oneOf([true, false], 'Select either on of these'),
            correspondence: Yup.bool().oneOf([true, false], 'Select either on of these'),
            Eob_lite: Yup.bool().oneOf([true, false], 'Select either on of these'),
            tat_for_client: Yup.number()
                .min(1, ' at least 1 Hr')
                .required('Required'),
            internal_tat: Yup.number()
                .min(1, ' at least 1 Hr')
                .lessThan(Yup.ref("tat_for_client"), 'Internal TAT must be less than External TAT (Client Tat) ')
                .required('Required'),
            qa: Yup.number()
                .min(1, ' at least 1 %')
                .max(100, ' Max 100 %')
                .required('Required'),
            is_single_transaction_tiff: Yup.bool().oneOf([true, false], 'Select this field is required'),
            Facility: Yup.bool().oneOf([true, false], 'Selecting the Facility field is required'),
            Claim: Yup.bool().oneOf([true, false], 'Selecting the Claims field is required'),
            is_revarc_available: Yup.bool().oneOf([true, false], 'Selecting the Revarc field is required'),
            is_dbk_available: Yup.bool().oneOf([true, false], 'Selecting the Dbk field is required'),
            is_snippet_available: Yup.bool().oneOf([true, false], 'Selecting the Snippet field is required'),
            client_group: Yup.string().required('Required'),
            partner: Yup.string().required('Required')

        }),
        onSubmit: (values) => {
            if (!isSubmit) {
                setLoading(true)
                setIsSubmit(true)

                let formData = new FormData();    //formdata object
                if (copy_id) {

                    formData.append('cloned_client_id', copy_id);
                    formData.append('client_id', values.client_id);
                    formData.append('client_group_name', values.client_group);
                    formData.append('partner', values.partner);
                    formData.append('name', values.name);
                    formData.append('address', values.address);
                    formData.append('city', values.city);
                    formData.append('state', values.state);
                    formData.append('country', values.country);
                    formData.append('zip_code', values.zip_code);
                    formData.append('npi', values.npi);
                    formData.append('tin', values.tin);
                    formData.append('username', values.username);
                    formData.append('password', values.password);
                    formData.append('eob', values.Eob && values.Eob ? values.Eob : false);
                    formData.append('patient_pay', values.patient_pay && values.patient_pay ? values.patient_pay : false);
                    formData.append('correspondence', values.correspondence && values.correspondence ? values.correspondence : false);
                    formData.append('eob_lite', values.Eob_lite && values.Eob_lite ? values.Eob_lite : false);
                    formData.append('tat_for_client', values.tat_for_client);
                    formData.append('internal_tat', values.internal_tat);
                    formData.append('qa', values.qa);
                    formData.append('is_single_transaction_tiff', values.is_single_transaction_tiff ? values.is_single_transaction_tiff : false);
                    formData.append('is_facility_available', values.Facility ? values.Facility : false);
                    formData.append('is_claim_available', values.Claim ? values.Claim : false);
                    formData.append('is_revarc_available', values.is_revarc_available ? values.is_revarc_available : false);
                    formData.append('is_dbk_available', values.is_dbk_available ? values.is_dbk_available : false);
                    formData.append('is_snippet_available', values.is_snippet_available ? values.is_snippet_available : false);
                }
                else if (client_data?.id) {

                    formData.append('client', client_data?.id);
                    formData.append('client_id', values.client_id);
                    formData.append('client_group_name', values.client_group);
                    formData.append('partner', values.partner);
                    formData.append('name', values.name);
                    formData.append('address', values.address);
                    formData.append('city', values.city);
                    formData.append('state', values.state);
                    formData.append('country', values.country);
                    formData.append('zip_code', values.zip_code);
                    formData.append('npi', values.npi);
                    formData.append('tin', values.tin);
                    formData.append('username', values.username);
                    formData.append('password', values.password);
                    formData.append('eob', values.Eob && values.Eob ? values.Eob : false);
                    formData.append('patient_pay', values.patient_pay && values.patient_pay ? values.patient_pay : false);
                    formData.append('correspondence', values.correspondence && values.correspondence ? values.correspondence : false);
                    formData.append('eob_lite', values.Eob_lite && values.Eob_lite ? values.Eob_lite : false);
                    formData.append('tat_for_client', values.tat_for_client);
                    formData.append('internal_tat', values.internal_tat);
                    formData.append('qa', values.qa);
                    formData.append('is_single_transaction_tiff', values.is_single_transaction_tiff ? values.is_single_transaction_tiff : false);
                    formData.append('is_facility_available', values.Facility ? values.Facility : false);
                    formData.append('is_claim_available', values.Claim ? values.Claim : false);
                    formData.append('is_revarc_available', values.is_revarc_available ? values.is_revarc_available : false);
                    formData.append('is_dbk_available', values.is_dbk_available ? values.is_dbk_available : false);
                    formData.append('is_snippet_available', values.is_snippet_available ? values.is_snippet_available : false);
                }
                else {
                    formData.append('client_id', values.client_id);
                    formData.append('client_group_name', values.client_group);
                    formData.append('partner', values.partner);
                    formData.append('name', values.name);
                    formData.append('address', values.address);
                    formData.append('city', values.city);
                    formData.append('state', values.state);
                    formData.append('country', values.country);
                    formData.append('zip_code', values.zip_code);
                    formData.append('npi', values.npi);
                    formData.append('tin', values.tin);
                    formData.append('username', values.username);
                    formData.append('password', values.password);
                    formData.append('eob', values.Eob && values.Eob ? values.Eob : false);
                    formData.append('patient_pay', values.patient_pay && values.patient_pay ? values.patient_pay : false);
                    formData.append('correspondence', values.correspondence && values.correspondence ? values.correspondence : false);
                    formData.append('eob_lite', values.Eob_lite && values.Eob_lite ? values.Eob_lite : false);
                    formData.append('tat_for_client', values.tat_for_client);
                    formData.append('internal_tat', values.internal_tat);
                    formData.append('qa', values.qa);
                    formData.append('is_single_transaction_tiff', values.is_single_transaction_tiff ? values.is_single_transaction_tiff : false);
                    formData.append('is_facility_available', values.Facility ? values.Facility : false);
                    formData.append('is_claim_available', values.Claim ? values.Claim : false);
                    formData.append('is_revarc_available', values.is_revarc_available ? values.is_revarc_available : false);
                    formData.append('is_dbk_available', values.is_dbk_available ? values.is_dbk_available : false);
                    formData.append('is_snippet_available', values.is_snippet_available ? values.is_snippet_available : false);

                }
                createClient({ formData })((response) => {
                    if (!response?.data?.status && response?.status && response?.status_code === 201) {
                        const client_id = response?.data?.data?.id;
                        props.changeState(response?.data);

                        __toggleToast("success", response?.message, true)
                        setIsSubmit(false)
                        setLoading(false)
                        // navigate(EDIT_CLIENT + client_id)
                        setTimeout(() => {

                            window.location.replace(EDIT_CLIENT + client_id)
                        }, 2000);

                    } else {
                        // alert('error')

                        // const errors = response?.response?.data?.errors;
                        clientformik.setErrors({
                            ...clientformik.errors,
                            username: response?.response?.data?.errors?.username,
                            client_id: response?.response?.data?.errors?.client_id,
                            client_group: response?.response?.data?.errors?.client_group,
                            name: response?.response?.data?.errors?.name,
                            password: response?.response?.data?.errors?.password,
                            city: response?.response?.data?.errors?.city,
                            state: response?.response?.data?.errors?.state,
                            country: response?.response?.data?.errors?.country,
                            zip_code: response?.response?.data?.errors?.zip_code,
                            npi: response?.response?.data?.errors?.npi,
                            tin: response?.response?.data?.errors?.tin,
                            Facility: response?.response?.data?.errors?.Facility,
                            Claim: response?.response?.data?.errors?.Claim,
                            Eob: response?.response?.data?.errors?.Eob,
                            patient_pay: response?.response?.data?.errors?.patient_pay,
                            correspondence: response?.response?.data?.errors?.correspondence,
                            Eob_lite: response?.response?.data?.errors?.Eob_lite,
                            client_group: response?.response?.data?.errors?.client_group_name,
                            partner: response?.response?.data?.errors?.partner,
                        })
                        setLoading(false)
                        props.changeState(response);
                        __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                        setIsSubmit(false)

                    }
                });
            }
        },
    });


    // const __toggleToast = () => {
    //     setState({ showToast: !state.showToast })

    // }
    const handleOnSelectChange = ({ name, value }) => {
        clientformik.setValues({ ...clientformik.values, [name]: value })
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }


    return (
        <React.Fragment>

            <div className=''>
                <Form
                    onSubmit={clientformik.handleSubmit}
                >
                    <Form.Label className='fw-bold clr-heading' >Client Details</Form.Label>
                    <div className="mb-3 row border-bottom-dashed" >
                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold ' >Client ID/Name</Form.Label>

                                <Form.Control
                                    name='client_id'
                                    type="text"
                                    autoComplete="false"
                                    isValid={clientformik.touched?.client_id && !clientformik.errors?.client_id}
                                    isInvalid={!!clientformik.errors?.client_id}
                                    value={clientformik.values?.client_id}
                                    className='b-r-10 form-control-solid'
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    // 
                                    placeholder="Client Id"
                                />
                                <Form.Control.Feedback type="invalid">{clientformik.errors?.client_id}</Form.Control.Feedback>

                            </Form.Group>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Name</Form.Label>
                                <Form.Control
                                    name='name'
                                    type="text"
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    autoComplete="false"
                                    isValid={clientformik.touched?.name && !clientformik.errors?.name}
                                    isInvalid={!!clientformik.errors?.name}
                                    value={clientformik.values?.name}
                                    className='b-r-10 form-control-solid'
                                    // 
                                    placeholder=" Name"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.name}</Form.Control.Feedback>

                            </Form.Group>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Address</Form.Label>
                                <Form.Control
                                    name='address'
                                    type="text"
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    autoComplete="false"
                                    isValid={clientformik.touched?.address && !clientformik.errors?.address}
                                    isInvalid={!!clientformik.errors?.address}
                                    value={clientformik.values?.address}
                                    className='b-r-10 form-control-solid'
                                    // 
                                    placeholder=" Address"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.address}</Form.Control.Feedback>

                            </Form.Group>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>City</Form.Label>
                                <Form.Control
                                    name='city'
                                    type="text"
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    autoComplete="false"
                                    isValid={clientformik.touched?.city && !clientformik.errors?.city}
                                    isInvalid={!!clientformik.errors?.city}
                                    value={clientformik.values?.city}
                                    className='b-r-10 form-control-solid'
                                    // 
                                    placeholder=" City"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.city}</Form.Control.Feedback>

                            </Form.Group>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>State</Form.Label>
                                <Form.Control
                                    name='state'
                                    type="text"
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    autoComplete="false"
                                    isValid={clientformik.touched?.state && !clientformik.errors?.state}
                                    isInvalid={!!clientformik.errors?.state}
                                    value={clientformik.values?.state}
                                    className='b-r-10 form-control-solid'
                                    maxLength={2}
                                    placeholder=" State"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.state}</Form.Control.Feedback>

                            </Form.Group>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Country</Form.Label>
                                <Form.Control
                                    name='country'
                                    type="text"
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    autoComplete="false"
                                    isValid={clientformik.touched?.country && !clientformik.errors?.country}
                                    isInvalid={!!clientformik.errors?.country}
                                    value={clientformik.values?.country}
                                    className='b-r-10 form-control-solid'
                                    // 
                                    placeholder=" Country"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.country}</Form.Control.Feedback>

                            </Form.Group>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Zip Code</Form.Label>
                                <Form.Control
                                    name='zip_code'
                                    type="text"
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    autoComplete="false"
                                    isValid={clientformik.touched?.zip_code && !clientformik.errors?.zip_code}
                                    isInvalid={!!clientformik.errors?.zip_code}
                                    value={clientformik.values?.zip_code}
                                    className='b-r-10 form-control-solid'
                                    maxLength={10}
                                    // 
                                    placeholder=" Zip Code"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.zip_code} </Form.Control.Feedback>

                            </Form.Group>
                        </div>
                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>NPI</Form.Label>
                                <Form.Control
                                    name='npi'
                                    type="text"
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    autoComplete="false"
                                    isValid={clientformik.touched?.npi && !clientformik.errors?.npi}
                                    isInvalid={!!clientformik.errors?.npi}
                                    value={clientformik.values?.npi}
                                    className='b-r-10 form-control-solid'
                                    maxLength={10}
                                    placeholder="NPI"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.npi}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>TIN</Form.Label>
                                <Form.Control
                                    name='tin'
                                    type="text"
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    autoComplete="false"
                                    isValid={clientformik.touched?.tin && !clientformik.errors?.tin}
                                    isInvalid={!!clientformik.errors?.tin}
                                    value={clientformik.values?.tin}
                                    className='b-r-10 form-control-solid'
                                    maxLength={9}
                                    placeholder="TIN"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.tin}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                    </div>
                    <Form.Label className='fw-bold clr-heading'>Client Login Credentials</Form.Label>
                    <div className='mb-3 pb-4 row border-bottom-dashed'>
                        <div className='col-lg-4'>
                            <Form.Group className="" >

                                <Form.Label className='fw-bold'>User Email</Form.Label>
                                <Form.Control
                                    name='username'
                                    type="text"
                                    onChange={clientformik.handleChange}
                                    // onBlur={clientformik.handleBlur}
                                    autoComplete="false"
                                    isValid={clientformik.touched?.username && !clientformik.errors?.username}
                                    isInvalid={!!clientformik.errors?.username}
                                    value={clientformik.values?.username}
                                    className='b-r-10 form-control-solid'
                                    // 
                                    placeholder=" User Email"
                                />
                                <Form.Control.Feedback type="invalid">{clientformik.errors?.username}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                        <div className='col-lg-4'>
                            <Form.Group className="">
                                <Form.Label className='fw-bold'>Password</Form.Label>
                                {/* <div className='input-group mt-0'> */}
                                <InputGroup className='input-group-client-password' >
                                    <Form.Control
                                        name='password'
                                        type={togglePassword ? "text" : "password"}
                                        onChange={clientformik.handleChange}
                                        // onBlur={clientformik.handleBlur}
                                        autoComplete="false"
                                        isValid={clientformik.touched?.password && !clientformik.errors?.password}
                                        isInvalid={!!clientformik.errors?.password}
                                        value={clientformik.values?.password}
                                        className='form-control-solid border-radius-password'
                                        // style={{ borderRadius: '0px 0px 0px 0px !important' }}

                                        //   ={client_data ? false : true}
                                        placeholder="Password"
                                    />
                                    {/* <div className=''><button
                                        // style={{ borderRadius: '0px 10px 10px 0px !important' }}
                                        className='btn btn-secondary border-radius-eye' type="button" onClick={() => setTogglePassword(!togglePassword)}><i className={togglePassword ? 'bi bi-eye' : 'bi bi-eye-slash'}></i></button></div> */}
                                    {/* </div> */}
                                    <InputGroup.Text className='btn btn-secondary border-radius-eye text-white input-group-text-password-client' onClick={() => setTogglePassword(!togglePassword)} ><i className={togglePassword ? 'bi bi-eye text-whtie' : 'bi bi-eye-slash text-whtie'}></i></InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">{clientformik.errors?.password}</Form.Control.Feedback>

                                </InputGroup>

                            </Form.Group>
                        </div>
                    </div>
                    <Form.Label className='fw-bold clr-heading'>Document Types</Form.Label>
                    <Form.Group>
                        <div className='mb-3 pb-3 row border-bottom-dashed'>
                            <div className='col-lg-3'>
                                <Form.Check
                                    type="checkbox"
                                    onChange={clientformik.handleChange}
                                    checked={clientformik?.values?.Eob}
                                    isInvalid={clientformik.errors?.Eob && !!clientformik.errors?.Eob}
                                    feedbackType="invalid"
                                    feedback={clientformik.errors?.Eob && clientformik.errors?.Eob}
                                    id="validationFormik0"
                                    name="Eob"
                                    label="EOB"
                                />
                            </div>
                            <div className='col-lg-3'>

                                <Form.Check
                                    type="checkbox"
                                    checked={clientformik?.values?.patient_pay}
                                    onChange={clientformik.handleChange}
                                    isInvalid={clientformik.errors?.patient_pay && !!clientformik.errors?.patient_pay}
                                    feedbackType="invalid"
                                    feedback={clientformik.errors?.patient_pay && clientformik.errors?.patient_pay}
                                    id="validationFormik01"
                                    name="patient_pay"
                                    label="Patient Pay"
                                />
                            </div>
                            <div className='col-lg-3'>
                                <Form.Check
                                    type="checkbox"
                                    onChange={clientformik.handleChange}
                                    checked={clientformik?.values?.correspondence}
                                    isInvalid={clientformik.errors?.correspondence && !!clientformik.errors?.correspondence}
                                    feedbackType="invalid"
                                    feedback={clientformik.errors?.correspondence && clientformik.errors?.correspondence}
                                    id="validationFormik20"
                                    name="correspondence"
                                    label="Correspondence"
                                />
                            </div>
                            <div className='col-lg-3'>
                                <Form.Check
                                    type='checkbox'
                                    id="validationFormik03"
                                    checked={clientformik?.values?.Eob_lite}
                                    label="EOB Lite"
                                    onChange={clientformik.handleChange}
                                    isInvalid={clientformik.errors?.Eob_lite && !!clientformik.errors?.Eob_lite}
                                    feedbackType="invalid"
                                    feedback={clientformik.errors?.Eob_lite && clientformik.errors?.Eob_lite}
                                    name="Eob_lite"
                                />
                            </div>
                        </div>
                    </Form.Group>


                    <div className="mb-3 row border-bottom-dashed" >
                        <Form.Label className='fw-bold clr-heading' >TAT Details</Form.Label>

                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold' >TAT for the Client (Hrs)</Form.Label>

                                <Form.Control
                                    name='tat_for_client'
                                    type="number"
                                    autoComplete="false"
                                    isValid={clientformik.touched?.tat_for_client && !clientformik.errors?.tat_for_client}
                                    isInvalid={!!clientformik.errors?.tat_for_client}
                                    value={clientformik.values?.tat_for_client}
                                    className='b-r-10 form-control-solid'
                                    onChange={clientformik.handleChange}
                                    onWheel={handleWheel}
                                    maxLength="255"
                                    // 
                                    placeholder=" TAT In (Hrs)"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.tat_for_client} </Form.Control.Feedback>

                            </Form.Group>
                        </div>




                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold' >Internal TAT</Form.Label>

                                <Form.Control
                                    name='internal_tat'
                                    type="number"
                                    autoComplete="false"
                                    isValid={clientformik.touched?.internal_tat}
                                    isInvalid={!!clientformik.errors?.internal_tat}
                                    value={clientformik.values?.internal_tat}
                                    className='b-r-10 form-control-solid'
                                    onChange={clientformik.handleChange}
                                    onWheel={handleWheel}
                                    maxLength="24"
                                    // 
                                    placeholder="Internal TAT"
                                />
                                <Form.Control.Feedback type="invalid">{clientformik.errors?.internal_tat}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                        <div className='col-lg-4'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold' >QA %</Form.Label>

                                <Form.Control
                                    name='qa'
                                    type="number"
                                    autoComplete="false"
                                    isValid={clientformik.touched?.qa && !clientformik.errors?.qa}
                                    isInvalid={!!clientformik.errors?.qa}
                                    value={clientformik.values?.qa}
                                    className='b-r-10 form-control-solid'
                                    onChange={clientformik.handleChange}
                                    maxLength="100"
                                    //style={{ overflow: 'hidden' }}
                                    onWheel={handleWheel}
                                    // 
                                    placeholder=" QA %"
                                />
                                <Form.Control.Feedback type="invalid"> {clientformik.errors?.qa}</Form.Control.Feedback>

                            </Form.Group>
                        </div>



                    </div>
                    <div className='mb-3 row border-bottom-dashed'>
                        <div className='col-lg-4'>
                            <Form.Group className='mb-3 row'>
                                <Form.Label className='fw-bolder '>Single Transaction TIFF?</Form.Label>
                                <Form.Check
                                    className=' form-control-solid col-lg-5 ms-3'
                                    type="radio"
                                    name="is_single_transaction_tiff"
                                    label="Yes"
                                    value={true}
                                    onChange={clientformik.handleChange}
                                    checked={clientformik.values?.is_single_transaction_tiff == true || clientformik.values?.is_single_transaction_tiff == "true"}
                                    isInvalid={!!clientformik.errors?.is_single_transaction_tiff}
                                    feedback={clientformik.errors?.is_single_transaction_tiff}
                                    feedbackType="invalid"
                                />


                                <Form.Check
                                    type="radio"
                                    className=' form-control-solid col-lg-6 '
                                    name="is_single_transaction_tiff"
                                    label="No"
                                    value={false}
                                    checked={clientformik.values?.is_single_transaction_tiff == false || clientformik.values?.is_single_transaction_tiff == "false"}
                                    onChange={clientformik.handleChange}
                                    isInvalid={!!clientformik.errors?.is_single_transaction_tiff}
                                    feedbackType="invalid"
                                />

                            </Form.Group>
                        </div>
                        <div className='col-lg-4'>
                            <Form.Group className='mb-3 row'>
                                <Form.Label className='fw-bolder '>Is Multiple Facility Available?</Form.Label>
                                <Form.Check
                                    className=' form-control-solid col-lg-5 ms-3'
                                    type="radio"
                                    name="Facility"
                                    label="Yes"
                                    value={true}
                                    onChange={clientformik.handleChange}
                                    checked={clientformik.values?.Facility == true || clientformik.values?.Facility == "true"}
                                    isInvalid={!!clientformik.errors?.Facility}
                                    feedback={clientformik.errors?.Facility}
                                    feedbackType="invalid"
                                />


                                <Form.Check
                                    type="radio"
                                    className=' form-control-solid col-lg-6 '
                                    name="Facility"
                                    label="No"
                                    value={false}
                                    checked={clientformik.values?.Facility == false || clientformik.values?.Facility == "false"}
                                    onChange={clientformik.handleChange}
                                    isInvalid={!!clientformik.errors?.Facility}
                                    feedbackType="invalid"
                                />

                            </Form.Group>
                        </div>
                        <div className='col-lg-4'>
                            <Form.Group className='mb-3 row'>
                                <Form.Label className='fw-bolder '>Is Claims Available?</Form.Label>
                                <Form.Check
                                    className=' form-control-solid col-lg-5 ms-3'
                                    type="radio"
                                    name="Claim"
                                    label="Yes"
                                    value={true}
                                    onChange={clientformik.handleChange}
                                    checked={clientformik.values?.Claim === true || clientformik.values?.Claim === "true"}
                                    isInvalid={!!clientformik.errors?.Claim}
                                    feedback={clientformik.errors?.Claim}
                                    feedbackType="invalid"
                                />


                                <Form.Check
                                    className=' form-control-solid col-lg-6'
                                    type="radio"
                                    name="Claim"
                                    label="No"
                                    value={false}
                                    checked={clientformik.values?.Claim === false || clientformik.values?.Claim === "false"}
                                    onChange={clientformik.handleChange}
                                    isInvalid={!!clientformik.errors?.Claim}
                                    feedbackType="invalid"
                                />

                            </Form.Group>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Group className='mb-3 row'>
                                <Form.Label className='fw-bolder '>Is DBK Available?</Form.Label>
                                <Form.Check
                                    className=' form-control-solid col-lg-5 ms-3'
                                    type="radio"
                                    name="is_dbk_available"
                                    label="Yes"
                                    value={true}
                                    onChange={clientformik.handleChange}
                                    checked={clientformik.values?.is_dbk_available === true || clientformik.values?.is_dbk_available === "true"}
                                    isInvalid={!!clientformik.errors?.is_dbk_available}
                                    feedback={clientformik.errors?.is_dbk_available}
                                    feedbackType="invalid"
                                />


                                <Form.Check
                                    className=' form-control-solid col-lg-6'
                                    type="radio"
                                    name="is_dbk_available"
                                    label="No"
                                    value={false}
                                    checked={clientformik.values?.is_dbk_available === false || clientformik.values?.is_dbk_available === "false"}
                                    onChange={clientformik.handleChange}
                                    isInvalid={!!clientformik.errors?.is_dbk_available}
                                    feedbackType="invalid"
                                />

                            </Form.Group>
                        </div>

                        <div className='col-lg-4'>
                            <Form.Group className='mb-3 row'>
                                <Form.Label className='fw-bolder '>Is Revarc Available?</Form.Label>
                                <Form.Check
                                    className=' form-control-solid col-lg-5 ms-3'
                                    type="radio"
                                    name="is_revarc_available"
                                    label="Yes"
                                    value={true}
                                    onChange={clientformik.handleChange}
                                    checked={clientformik.values?.is_revarc_available === true || clientformik.values?.is_revarc_available === "true"}
                                    isInvalid={!!clientformik.errors?.is_revarc_available}
                                    feedback={clientformik.errors?.is_revarc_available}
                                    feedbackType="invalid"
                                />


                                <Form.Check
                                    className=' form-control-solid col-lg-6'
                                    type="radio"
                                    name="is_revarc_available"
                                    label="No"
                                    value={false}
                                    checked={clientformik.values?.is_revarc_available === false || clientformik.values?.is_revarc_available === "false"}
                                    onChange={clientformik.handleChange}
                                    isInvalid={!!clientformik.errors?.is_revarc_available}
                                    feedbackType="invalid"
                                />

                            </Form.Group>
                        </div>
                        <div className='col-lg-4'>
                            <Form.Group className='mb-3 row'>
                                <Form.Label className='fw-bolder '>Is Snippet Available?</Form.Label>
                                <Form.Check
                                    className=' form-control-solid col-lg-5 ms-3'
                                    type="radio"
                                    name="is_snippet_available"
                                    label="Yes"
                                    value={true}
                                    onChange={clientformik.handleChange}
                                    checked={clientformik.values?.is_snippet_available === true || clientformik.values?.is_snippet_available === "true"}
                                    isInvalid={!!clientformik.errors?.is_snippet_available}
                                    feedback={clientformik.errors?.is_snippet_available}
                                    feedbackType="invalid"
                                />


                                <Form.Check
                                    className=' form-control-solid col-lg-6'
                                    type="radio"
                                    name="is_snippet_available"
                                    label="No"
                                    value={false}
                                    checked={clientformik.values?.is_snippet_available === false || clientformik.values?.is_snippet_available === "false"}
                                    onChange={clientformik.handleChange}
                                    isInvalid={!!clientformik.errors?.is_snippet_available}
                                    feedbackType="invalid"
                                />

                            </Form.Group>
                        </div>



                    </div>
                    <div className='mb-3 row border-bottom-dashed'>
                        <div className='col-lg-4'>
                            <Form.Group className='mb-3 '>
                                <Form.Label className='fw-bolder clr-heading'>Client Group</Form.Label>

                                {/* <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="client_group"
                                value={clientformik.values?.client_group}
                                // isValid={clientformik.touched.client_group && !clientformik?.errors?.client_group}
                                // isInvalid={!!clientformik.errors?.client_group}
                                required
                                placeholder="select an option"
                                options={options}
                                onChange={(e) => { handleOnSelectChange({ name: 'client_group', value: e }) }}
                            /> */}
                                <Form.Control as="select"
                                    name="client_group"
                                    className='form-control form-control-solid b-r-10'
                                    value={clientformik.values?.client_group}
                                    isValid={clientformik.touched?.client_group && !clientformik?.errors?.client_group}
                                    isInvalid={!!clientformik.errors?.client_group}
                                    placeholder="select an option"
                                    onChange={clientformik.handleChange}
                                >
                                    <option value=''>Select an Option</option>
                                    {options && options?.length > 0 && options?.map((option, index) => (
                                        <option key={index} value={option?.value}>{option?.label}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            {clientformik.errors?.client_group &&
                                <div>
                                    <span className='text-danger'>{clientformik.errors?.client_group}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='mb-3 row border-bottom-dashed'>
                        <div className='col-lg-4'>
                            <Form.Group className='mb-3 '>
                                <Form.Label className='fw-bolder clr-heading'>Partners</Form.Label>

                                {/* <Select
                                className="basic-single"
                                classNamePrefix="select"
                                name="client_group"
                                value={clientformik.values?.client_group}
                                // isValid={clientformik.touched.client_group && !clientformik?.errors?.client_group}
                                // isInvalid={!!clientformik.errors?.client_group}
                                required
                                placeholder="select an option"
                                options={options}
                                onChange={(e) => { handleOnSelectChange({ name: 'client_group', value: e }) }}
                            /> */}
                                <Form.Control as="select"
                                    name="partner"
                                    className='form-control form-control-solid b-r-10'
                                    value={clientformik.values?.partner}
                                    isValid={clientformik.touched?.partner && !clientformik?.errors?.partner}
                                    isInvalid={!!clientformik.errors?.partner}
                                    placeholder="select an option"
                                    onChange={clientformik.handleChange}
                                >
                                    <option value=''>Select an Option</option>
                                    {partners && partners?.length > 0 && partners?.map((option, index) => (
                                        <option key={index} value={option?.value}>{option?.label}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            {clientformik.errors?.partner &&
                                <div>
                                    <span className='text-danger'>{clientformik.errors?.partner}</span>
                                </div>
                            }
                        </div>
                    </div>
                    <div>

                    </div>
                    <div className='d-flex flex-row justify-content-end'>
                        <div className=''>
                            <button
                                type='button'
                                className='btn btn-secondary btn-active-primary me-2'
                                onClick={() => { changeAllTitleFunction('Client Setup', CLIENT) }}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className=''>
                            {!client_data ? (
                                <button
                                    disabled={!clientformik.isValid || isSubmit}
                                    type='submit'
                                    className={isSubmit ? 'btn btn-light btn-active-primary' : 'btn btn-primary btnHover'}
                                >
                                    {isSubmit ? 'Saving' : 'Create Client'}
                                </button>
                            ) : !copy_id ? (
                                checkUserPermission('edit_client') &&
                                <button
                                    disabled={!clientformik.isValid || isSubmit}
                                    type='submit'
                                    className={isSubmit ? 'btn btn-light btn-active-primary' : 'btn btn-primary btnHover'}
                                >
                                    {isSubmit ? 'Updating' : 'Update Client'}
                                </button>
                            ) : (
                                <button
                                    disabled={isSubmit}
                                    type='submit'
                                    className={isSubmit ? 'btn btn-light btn-active-primary' : 'btn btn-primary btnHover'}
                                >
                                    {isSubmit ? 'Saving' : 'Create Client'}
                                </button>
                            )
                            }
                        </div>
                    </div>
                </Form>

            </div >
        </React.Fragment >
    )
}

export default ClientForm