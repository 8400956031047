import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AdminMaster from '../../../Layout/admin/Master';
import { getNotificationSingleDetail, markAllAsRead } from '../../../Redux/notification/action';

const EditNotification = () => {
    const navigate = useNavigate()
    let { notification_id } = useParams();
    const [notification, setNotification] = useState({})
    useEffect(() => {
        fetchNotification();
        markAsRead()
    }, [])

    const fetchNotification = () => {
        getNotificationSingleDetail(notification_id)((response) => {
            if (response?.status) {
                setNotification(response?.data?.user)
            }
        })
    }

    const markAsRead = () => {
        markAllAsRead([notification_id])((response) => {

        })
    }

    return (
        <React.Fragment>
            <AdminMaster />
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header ">
                    <h1>Notification</h1>
                </div>
                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" onClose={__toggleToast} show={state.showToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                </ToastContainer> */}

                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3">

                                    <div className='form-group row mb-3'>
                                        <div className='col-lg-4'>

                                            <div className='form-label fw-bold fs-5'>Title</div>
                                            <input className='form-control-solid form-control b-r-10 ps-3' disabled value={notification?.title} />
                                        </div>
                                    </div>
                                    <div className='form-group row'>
                                        <div className='col-lg-4'>

                                            <div className='form-label fw-bold fs-5'>Message</div>
                                            <Form.Control
                                                as="textarea"
                                                disabled
                                                className='form-control form-control-solid b-r-10 py-2 px-3'
                                                placeholder="Leave a comment here"
                                                value={notification?.message}
                                                style={{ height: '100px', resize: 'none' }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </React.Fragment>
    )
}

export default EditNotification
