import React, { Fragment, useEffect, useState } from 'react'
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { Button, Form, InputGroup, OverlayTrigger, Modal, Tooltip } from 'react-bootstrap'
import * as Yup from 'yup';
import { createLockBox, create, createOrUpdateFacility } from '../../../../../Redux/client/action';
import Select from 'react-select';
import TimePicker from 'react-time-picker';
import { useNavigate } from 'react-router-dom';
import { changeAllTitleFunction } from '../../../Dashboard/functions';
import { CLIENT } from '../../../../../routeNames';

var selectedFileType = {};
var files = [];

function FacilityForm(props) {

    const { client_data, client, facilities, __toggleToast, setLoading, fetchClientDetails } = props;

    const [state, setState] = useState({
        error: false,
        error_message: "",
        lockboxindex: 0,
        viewlockboxindex: "",

    });

    const [togglePassword, setTogglePassword] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false)
    const [facilityIndex, setFacilityIndex] = useState(0)
    const [mainIndex, setMainIndex] = useState(0)
    const [imageIndex, setImageIndex] = useState(0)
    const navigate = useNavigate()
    const [indexFileStructureOptions, SetIndexFileStructureOptions] = useState([
        { value: "1", label: 'Yes' },
        { value: "2", label: 'No' },

    ]);
    const [fileTransferPeriodOptions, SetFileTransferPeriodOptions] = useState([
        { value: "1", label: 'Fixed Time' },
        { value: "2", label: 'Any Time' },
    ]);
    const [indexFileOptions, SetIndexFileOptions] = useState([
        { value: "1", label: 'CSV' },
        { value: "2", label: 'Text' },
        { value: "3", label: 'XML' },
        { value: "4", label: 'JSON' },
        { value: "5", label: 'PDF' },

    ]);
    const [sftpServerOptions, SetSftpServerOptions] = useState([
        { value: "1", label: 'Client' },
        { value: "2", label: 'Our Own' },
    ]);
    const [modalOpen, setModalOpen] = useState(false);
    const [imageEditOpen, setImageEditOpen] = useState(false);
    const [modalViewOpen, setModalViewOpen] = useState(false);
    const [jsonDownload, setJsonDownload] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false)

    const deleteTooltip = (text, props) => {
        <Tooltip id="button-tooltip" {...props}>
            {text}
        </Tooltip>
    }

    const handleFileChange = (event) => {

        let selectedFile = event.target.files;
        let file = null;
        let fileName = "";
        //Check File is not Empty
        if (selectedFile.length > 0) {
            // Select the very first file from list
            let fileToLoad = selectedFile[0];

            if (acceptAttribute == fileToLoad.type)
                fileName = fileToLoad.name;
            // FileReader function for read the file.
            let fileReader = new FileReader();
            // Onload of file read the file content
            fileReader.onload = function (fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                // Print data in // console
                lockboxFormik.setValues({
                    ...lockboxFormik.values,
                    index_file_schema: file ?? "",
                    index_file_schema_url: file ?? ""
                })
            };
            // Convert data to base64
            fileReader.readAsDataURL(fileToLoad);
        }
    }
    const onButtonClick = (file) => {
        // using Java Script method to get PDF file
        const fileURL = file;
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Sample';
        alink.target = '_blank';
        alink.click();
    }


    // const handleDownloadClick = async () => {
    //     const response = await fetch("https://xtractapi.aventusinformatics.com/static/output_file_schema/sample_input_file_schema.json");
    //     const file = await response.blob();
    //     const url = URL.createObjectURL(file);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'file.pdf';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(url);
    // };
    // const handleDownloadClick = () => {
    //     const url = process.env.REACT_APP_WEB_PROD_SERVER_URL + "/static/output_file_schema/sample_input_file_schema.json";
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'sample_input_file_schema.json';
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };
    const lockboxFormik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            add_image_id: "",
            bank_name: "",
            lockbox_no: "",
            lockbox_file_name_split_key: "",
            lockbox_number_position: "",
            // single_page: false,
            // multiple_page: false,
            // PDF: false,
            inbound_image_file_format: "",
            index_file_included: "",
            index_file_name: "",
            index_file_type: "",
            index_file_schema: "",
            index_file_schema_url: "",
            // file_format: "",
            file_transfer_period: "",
            lockbox_transfer_time: "",
            transfer_time: "",
            sftp_server: "",
            sftp_address: "",
            sftp_port: "",
            sftp_username: "",
            sftp_password: "",
            eob_file_path: "",
            eob_lite_file_path: "",
            patient_pay_file_path: "",
            correspondence_file_path: "",
            default_npi: "",
            default_tin: ""
        },

        validate: values => {
            let errors = {};
            // if(values?.single_page && values?.multiple_page && values?.PDF == false) {
            //     errors.single_page == "select An Option"
            // }
            if (values?.index_file_included == "1") {
                if (values?.index_file_name == "") {
                    errors.index_file_name = "Required"
                }
                if (values?.index_file_type == "") {
                    errors.index_file_type = "Select An Option";
                }
                if (!values?.index_file_schema && values.add_image_id == '') {

                    errors.index_file_schema = "Required";
                }
            }


            // if (values?.inbound_image_file_format == "") {
            //     errors.inbound_image_file_format = "required";
            // }

            if (values?.index_file_included == "0") {
                errors.index_file_included = "Select An Option";
            }
            if (values?.file_transfer_period == "1" && values?.transfer_time == "") {
                errors.transfer_time = "This Field Is Required And Choose a Valid Time";
            }
            if (client_data?.eob && values?.eob_file_path == "") {
                errors.eob_file_path = "This Field Is Required And Choose a Valid Path";
            }
            if (client_data?.eob_lite && values?.eob_lite_file_path == "") {
                errors.eob_lite_file_path = "This Field Is Required And Choose a Valid Path"
            }
            if (client_data?.patient_pay && values?.patient_pay_file_path == "") {
                errors.patient_pay_file_path = "This Field Is Required And Choose a Valid Path"
            }
            if (client_data?.correspondence && values?.correspondence_file_path == "") {
                errors.correspondence_file_path = "This Field Is Required And Choose a Valid Path"
            }
            if (!values.add_image_id && !values.sftp_password) {
                errors.sftp_password = "No password provided."
            }
            return errors;
        },
        validationSchema: Yup.object().shape({

            bank_name: Yup.string().required("Bank Required"),
            lockbox_no: Yup.string().required("Lockbox No Required"),
            lockbox_number_position: Yup.string()
                .matches(/^[1-9][0-9,]*$/, "Only digits 1 and above allowed"),

            default_tin: Yup.string().required("Required").matches(/^[0-9]+$/, "Only Digits Allowed").min(9, 'Only 9 digits').max(9, 'Only 9 digits'),
            default_npi: Yup.string().required("Required").matches(/^[0-9]+$/, "Only Digits Allowed").min(10, 'Only 10 digits').max(10, 'Only 10 digits'),
            // single_page: Yup.string().required("Required"),
            // multiple_page: Yup.string().required("Required"),
            // PDF: Yup.string().required("Required"),
            inbound_image_file_format: Yup.string().required("Required"),
            index_file_included: Yup.string().required('Required'),
            // index_file_name: Yup.string().required('Required'),
            file_transfer_period: Yup.string().required('Required'),
            sftp_server: Yup.string().required('Required'),
            sftp_address: Yup.string().required('Required'),
            sftp_port: Yup.string().required('Required'),
            sftp_username: Yup.string().required('Required'),
            // sftp_password: Yup.string()
            //     .required('No password provided.'),

        }),
        onSubmit: (values, actions) => {


            var inputs = formik.values.facilities;
            if (!inputs[facilityIndex]?.image_file[0]) {
                inputs[facilityIndex].image_file = [];
            }
            // if (lockboxFormik.values?.index_file_included == '1') {
            inputs[facilityIndex]?.image_file?.push({
                bank_name: values?.bank_name,
                lockbox_no: values?.lockbox_no,
                lockbox_file_name_split_key: values?.lockbox_file_name_split_key,
                lockbox_number_position: values?.lockbox_number_position,
                default_npi: values?.default_npi,
                default_tin: values?.default_tin,
                // single_page: values?.single_page,
                // multiple_page: values?.multiple_page,
                // PDF: values?.PDF,
                inbound_image_file_format: lockboxFormik.values?.inbound_image_file_format,
                index_file_included: values?.index_file_included,
                index_file_name: values?.index_file_included == "1" ? values?.index_file_name : "",
                index_file_type: values?.index_file_included == "1" ? values?.index_file_type : "",
                index_file_schema: values?.index_file_included == "1" ? values?.index_file_schema : "",
                // file_format: values?.file_format,
                file_transfer_period: values?.file_transfer_period,
                // lockbox_transfer_time: values?.lockbox_transfer_time,
                transfer_time: values?.file_transfer_period ? values?.transfer_time : "",
                sftp_server: values?.sftp_server,
                sftp_address: values?.sftp_address,
                sftp_port: values?.sftp_port,
                sftp_username: values?.sftp_username,
                sftp_password: values?.sftp_password,
                eob_file_path: values?.eob_file_path,
                eob_lite_file_path: values?.eob_lite_file_path,
                patient_pay_file_path: values?.patient_pay_file_path,
                correspondence_file_path: values?.correspondence_file_path,
            })
            // }
            // else {
            //     inputs[facilityIndex]?.image_file?.push({
            //         bank_name: values?.bank_name,
            //         lockbox_no: values?.lockbox_no,
            //         single_page: values?.single_page,
            //         multiple_page: values?.multiple_page,
            //         PDF: values?.PDF,
            //         index_file_included: values?.index_file_included,
            //         // index_file_type: values?.index_file_type,
            //         // index_file_schema: values?.index_file_schema,
            //         // file_format: values?.file_format,
            //         file_transfer_period: values?.file_transfer_period,
            //         lockbox_transfer_time: values?.lockbox_transfer_time,
            //         transfer_time: values?.transfer_time,
            //         sftp_server: values?.sftp_server,
            //         sftp_address: values?.sftp_address,
            //         sftp_port: values?.sftp_port,
            //         sftp_username: values?.sftp_username,
            //         sftp_password: values?.sftp_password,
            //         eob_file_path: values?.eob_file_path,
            //         eob_lite_file_path: values?.eob_lite_file_path,
            //         patient_pay_file_path: values?.patient_pay_file_path,
            //         correspondence_file_path: values?.correspondence_file_path,
            //     })
            // }
            formik.setValues({
                ...formik.values,
                facilities: inputs
            })
            setTimeout(() => {

                lockboxFormik.setValues({
                    bank_name: "",
                    lockbox_no: "",
                    lockbox_file_name_split_key: "",
                    lockbox_number_position: "",

                    default_npi: "",
                    default_tin: "",
                    // single_page: false,
                    // multiple_page: false,
                    // PDF: false,
                    inbound_image_file_format: "",
                    index_file_included: "",
                    index_file_name: "",
                    index_file_type: "",
                    index_file_schema: "",
                    // file_format: "",
                    file_transfer_period: "",
                    lockbox_transfer_time: "",
                    transfer_time: "",
                    sftp_server: "",
                    sftp_address: "",
                    sftp_port: "",
                    sftp_username: "",
                    sftp_password: "",
                    eob_file_path: "",
                    eob_lite_file_path: "",
                    patient_pay_file_path: "",
                    correspondence_file_path: "",
                })
                setImageEditOpen(false)
                setModalOpen(false)
            }, 100);
        },
    })

    const formik = useFormik({
        enableReinitialize: true,



        initialValues: facilities?.length > 0 ? facilities : {
            facilities: [{
                facility: {
                    client: localStorage.getItem('client_id'),
                    name: "",
                    address: "",
                    state: "",
                    country: "",
                    city: "",
                    zip_code: "",
                    patient_account_system: "",
                },
                tin_array: [{
                    tin: ""
                }],

                npi_array: [{
                    npi: ""
                }],
                image_file: []
            }],
        },

        validationSchema: Yup.object().shape({
            facilities: Yup.array().of(
                Yup.object().shape({
                    facility: Yup.object().shape({
                        name: Yup.string().required("Required"),
                        address: Yup.string().required("Required"),
                        state: Yup.string()
                            .matches(/^[A-Z]+$/i, 'Enter a valid State Abbrevation')
                            .min(2, 'only two characters')
                            .max(2, 'only two characters')
                            .required('Required'),
                        country: Yup.string().required(" Required"),
                        city: Yup.string().required("Required"),
                        zip_code: Yup.string().required(" Required")
                            .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)/, 'Enter a valid Zip code'),
                        patient_account_system: Yup.string().required("Required"),
                    }),

                    tin_array: Yup.array().of(
                        Yup.object().shape({
                            tin: Yup.string().required("Required")
                                .matches(/^[0-9]+$/, "Must be only digits")
                                .min(9, 'only 9 Digits')
                                .max(9, 'only 9 Digits'),
                        })
                    ),
                    npi_array: Yup.array().of(
                        Yup.object().shape({
                            npi: Yup.string().required("Required")
                                .matches(/^[0-9]+$/, "Must be only digits")
                                .min(10, 'only 10 Digits')
                                .max(10, 'only 10 Digits'),
                        })
                    ),

                    // image_file: Yup.array().of(
                    //     Yup.object().shape({
                    //         bank_name: Yup.string().required(" Required"),
                    //         lockbox_no: Yup.string().required(" Required"),
                    //         single_page: Yup.bool().oneOf([true, false], 'Select either on of these'),
                    //         transfer_time: Yup.string(),
                    //         sftp_server: Yup.string().required(" Required"),
                    //         sftp_address: Yup.string().required(" Required"),
                    //         multiple_page: Yup.bool().oneOf([true, false], 'Select either on of these'),
                    //         PDF: Yup.bool().oneOf([true, false], 'Select either on of these'),
                    //         index_file_included: Yup.string().required(" Required"),
                    //         index_file_type: Yup.string(),
                    //         index_file_schema: Yup.string(),
                    //         file_transfer_period: Yup.string().required(" Required"),
                    //         sftp_port: Yup.string().required(" Required"),
                    //         sftp_username: Yup.string().required(" Required"),
                    //         sftp_password: Yup.string().required(" Required"),
                    // eob_file_path: Yup.string(),
                    // eob_lite_file_path: Yup.string(),
                    // patient_pay_file_path: Yup.string(),
                    // correspondence_file_path: Yup.string()
                    // })
                    // )
                })
            ),
        }),
        onSubmit: (values, { setSubmitting }) => {
            setLoading(true)
            setSubmitting(true)
            var datas = values.facilities;

            if (client)

                datas.map(data => {
                    data.facility.client = client;
                })

            formik.setValues({
                facilities: datas,
                client: client,
            })

            // console.log(values);
            // let formData = new FormData();
            // formData.append("facilities", values)
            // formData.append("enctype", "multipart/form-data")
            createOrUpdateFacility(values)((response) => {
                if (response?.data && response?.status) {
                    // setState({ clientValidated: true })
                    // props.changeState(response?.data);
                    __toggleToast("success", response?.message, true)
                    setLoading(false)
                    // if (client && client_data) {

                    fetchClientDetails();
                    // }
                    setSubmitting(false)

                } else {
                    const errors = response.response?.data?.errors;
                    if (response?.message === "Invalid Index file schema") {

                        lockboxFormik.setValues({
                            ...lockboxFormik.values,
                            index_file_schema_url: ""
                        })

                    }
                    formik.setErrors({ errors })
                    __toggleToast("danger", response?.message ? response?.message : response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                    setLoading(false)
                    setSubmitting(false)

                    // props.changeState(response);
                }
            });
        },

    });


    // const dataSample = 
    const __handleSubmit = (values) => {
        setLoading(true)
        setIsSubmit(true)
        var datas = values.facilities;

        if (client)

            datas.map(data => {
                data.facility.client = client;
            })

        formik.setValues({
            facilities: datas,
            client: client,
        })


        createOrUpdateFacility(values)((response) => {
            if (response?.data && response?.status) {

                __toggleToast("success", response?.message, true)
                setLoading(false)
                setIsSubmit(true)
                // if (client && client_data) {

                // fetchClientDetails();
                // }

            } else {
                const errors = response.response?.data?.errors;
                formik.setErrors({ errors })
                __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                setLoading(false)
                setIsSubmit(false)

                // props.changeState(response);
            }
        });
    }
    useEffect(() => {
        if (facilities && facilities.length > 0) {

            const dup_fes = props.facilities;
            dup_fes.map((fes, index) => {
                fes?.image_file?.map((image, ind) => {
                    if (image && image?.index_file_schema) {
                        image.index_file_schema_url = image.index_file_schema;
                        image.index_file_schema = "";
                    }
                    else {
                        image.index_file_schema_url = "";

                    }
                })
            })
            formik.setValues({
                facilities: dup_fes
            })
            formik.setErrors({
                facilities: [{
                    facility: {
                        name: "",
                        address: "",
                        state: "",
                        country: "",
                        city: "",
                        zip_code: "",
                    },
                    tin_array: [{
                        tin: ""
                    }],

                    npi_array: [{
                        npi: ""
                    }],
                    image_file: []
                }],
            })
        }

    }, [facilities]);

    // alert(client)
    // // console.log(client_data);
    // const validationSchema = Yup.object().shape({
    //     facilities: Yup.array().of(
    //         Yup.object().shape({
    //             name: Yup.string().required("Name required"),
    //             address: Yup.string().required("Address required"),
    //             tin_data: Yup.array().of(
    //                 Yup.object().shape({
    //                     tin: Yup.string().required("Tin required"),
    //                 })
    //             ),
    //             npi_data: Yup.array().of(
    //                 Yup.object().shape({
    //                     npi: Yup.string().required("Npi required"),
    //                 })
    //             )
    //         })
    //     ),
    // });
    // const lockboxSchema = Yup.object().shape({

    //     bank_name: Yup.string().required("Bank required"),
    //     lockbox_no: Yup.string().required("lockbox No required"),

    // });


    const handleModal = (index) => {
        setState({ lockboxindex: index })
        setModalOpen(!modalOpen)
        lockboxFormik.setValues({
            ...lockboxFormik.values,
            inbound_image_file_format: ""
        })
    }

    const handleViewModal = (index) => {
        setState({ ...state, viewlockboxindex: index })
        setModalViewOpen(true);
    }

    const handleOnSelectChange = ({ name, value, index }) => {
        // console.log(value, "sajith vavavavavav")
        lockboxFormik.setValues({ ...lockboxFormik.values, [name]: value.value })
        // setRoles(value.map((val) => val.value))
    }

    const handleOnTimeChange = ({ name, value }) => {
        // console.log(value, "sajith vavavavavav")
        lockboxFormik.setValues({ ...lockboxFormik.values, [name]: value })
        // setRoles(value.map((val) => val.value))
    }

    // const timeClear = () => {
    //     lockboxFormik.setValues({
    //         ...lockboxFormik.values,
    //         bank_name: "",
    //         lockbox_no: "",
    //         single_page: false,
    //         multiple_page: false,
    //         PDF: false,
    //         index_file_included: "",
    //         index_file_type: "",
    //         index_file_schema: "",
    //         // file_format: "",
    //         file_transfer_period: "",
    //         lockbox_transfer_time: "",
    //         transfer_time: "",
    //         sftp_server: "",
    //         sftp_address: "",
    //         sftp_port: "",
    //         sftp_username: "",
    //         sftp_password: "",
    //         eob_file_path: "",
    //         eob_lite_file_path: "",
    //         patient_pay_file_path: "",
    //         correspondence_file_path: "",

    //     })
    // }

    const [clickedFacilityIndex, setClickedFacilityIndex] = useState("")
    const [clickedImageFileIndex, setClickedImageFileIndex] = useState("")

    const openImageEditModal = (mainIndex, imgIndex) => {
        lockboxFormik.setValues(formik?.values?.facilities[mainIndex]?.image_file[imgIndex]);
        setMainIndex(mainIndex);
        setImageIndex(imgIndex);
        selectedFileType = indexFileOptions.find((element) => {
            return element.value == formik?.values?.facilities[mainIndex]?.image_file[imgIndex]?.index_file_included;
        })
        // fetch(formik?.values?.facilities[mainIndex]?.image_file[imgIndex].index_file_schema
        //     , {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Accept': 'application/json'
        //         }
        //     }
        // )
        //     .then(function (response) {
        //         // console.log(response)
        //         return response.json();
        //     })
        //     .then(function (myJson) {
        //         // console.log(myJson);
        //         setJsonDownload(myJson)
        //     });
        setFacilityIndex(mainIndex)
        setImageEditOpen(true);
    }

    const editImageFile = () => {
        if (lockboxFormik.isValid) {
            var inputs = formik.values.facilities;
            if (inputs && inputs[facilityIndex] && inputs[facilityIndex]?.image_file && inputs[facilityIndex]?.image_file[imageIndex]) {
                inputs[facilityIndex].image_file[imageIndex] = {
                    // add_image_id:

                    add_image_id: lockboxFormik.values?.add_image_id,
                    bank_name: lockboxFormik.values?.bank_name,
                    lockbox_no: lockboxFormik.values?.lockbox_no,
                    lockbox_file_name_split_key: lockboxFormik.values?.lockbox_file_name_split_key,
                    lockbox_number_position: lockboxFormik.values?.lockbox_number_position,
                    default_npi: lockboxFormik.values?.default_npi,
                    default_tin: lockboxFormik.values?.default_tin,
                    // single_page: lockboxFormik.values?.single_page,
                    // multiple_page: lockboxFormik.values?.multiple_page,
                    // PDF: lockboxFormik.values?.PDF,
                    inbound_image_file_format: lockboxFormik.values?.inbound_image_file_format,
                    index_file_included: lockboxFormik.values?.index_file_included,
                    index_file_name: lockboxFormik.values?.index_file_name,
                    index_file_type: lockboxFormik.values?.index_file_type,
                    index_file_schema: lockboxFormik.values?.index_file_schema,
                    index_file_schema_url: lockboxFormik.values?.index_file_schema_url,
                    // file_format: lockboxFormik.values?.file_format,
                    file_transfer_period: lockboxFormik.values?.file_transfer_period,
                    lockbox_transfer_time: lockboxFormik.values?.lockbox_transfer_time,
                    transfer_time: lockboxFormik.values?.transfer_time,
                    sftp_server: lockboxFormik.values?.sftp_server,
                    sftp_address: lockboxFormik.values?.sftp_address,
                    sftp_port: lockboxFormik.values?.sftp_port,
                    sftp_username: lockboxFormik.values?.sftp_username,
                    sftp_password: lockboxFormik.values?.sftp_password,
                    eob_file_path: lockboxFormik.values?.eob_file_path,
                    eob_lite_file_path: lockboxFormik.values?.eob_lite_file_path,
                    patient_pay_file_path: lockboxFormik.values?.patient_pay_file_path,
                    correspondence_file_path: lockboxFormik.values?.correspondence_file_path,
                };
                if (lockboxFormik.values?.index_file_included == '1') {
                    inputs[facilityIndex].image_file[imageIndex] = {
                        ...inputs[facilityIndex].image_file[imageIndex],
                        index_file_name: lockboxFormik.values?.index_file_name,
                        index_file_type: lockboxFormik.values?.index_file_type,
                        index_file_schema: lockboxFormik.values?.index_file_schema,

                    };
                }



            }
            formik.setValues({
                ...formik.values,
                facilities: inputs
            })

            setTimeout(() => {

                lockboxFormik.setValues({
                    add_image_id: "",
                    bank_name: "",
                    lockbox_no: "",
                    lockbox_file_name_split_key: "",
                    lockbox_number_position: "",
                    // single_page: false,
                    // multiple_page: false,
                    // PDF: false,
                    inbound_image_file_format: "",
                    index_file_included: "",
                    index_file_name: "",
                    index_file_type: "",
                    index_file_schema: "",
                    // file_format: "",
                    file_transfer_period: "",
                    lockbox_transfer_time: "",
                    transfer_time: "",
                    sftp_server: "",
                    sftp_address: "",
                    sftp_port: "",
                    sftp_username: "",
                    sftp_password: "",
                    eob_file_path: "",
                    eob_lite_file_path: "",
                    patient_pay_file_path: "",
                    correspondence_file_path: "",
                })
                // actions.resetForm();
                setImageEditOpen(false)
                setModalOpen(false)
            }, 100);
        }
    }

    let acceptAttribute = '';
    if (lockboxFormik.values?.index_file_type == "1") {
        acceptAttribute = 'text/plain';
    } else if (lockboxFormik.values?.index_file_type == "2") {
        acceptAttribute = 'application/xml,text/xml';
    } else if (lockboxFormik.values?.index_file_type == "3") {
        acceptAttribute = 'application/json';
    }
    //  else if (lockboxFormik.values?.index_file_type == "5") {
    //     acceptAttribute = 'application/pdf';
    // }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    const onHideEditModal = () => {
        lockboxFormik.setValues({
            add_image_id: "",
            bank_name: "",
            lockbox_no: "",
            lockbox_file_name_split_key: "",
            lockbox_number_position: "",

            // single_page: false,
            // multiple_page: false,
            // PDF: false,
            index_file_included: "",
            index_file_name: "",
            index_file_type: "",
            index_file_schema: "",
            // file_format: "",
            file_transfer_period: "",
            lockbox_transfer_time: "",
            transfer_time: "",
            sftp_server: "",
            sftp_address: "",
            sftp_port: "",
            sftp_username: "",
            sftp_password: "",
            eob_file_path: "",
            eob_lite_file_path: "",
            patient_pay_file_path: "",
            correspondence_file_path: "",
        })
        setImageEditOpen(false)
    }



    const handleRadioChange = (e) => {
        lockboxFormik.setValues({
            ...lockboxFormik.values,
            inbound_image_file_format: e.target.id
        })
    }
    console.log(lockboxFormik.errors, 'lockboxFormik');
    return (
        <React.Fragment >
            <FormikProvider value={formik}>
                <Form
                    onSubmit={formik.handleSubmit}
                >
                    <FieldArray name="facilities"
                        render={(arrayHelpers) => (
                            <React.Fragment>
                                {formik?.values?.facilities?.map((facility, index) => (
                                    <div className='mb-3 border-bottom-dashed row' key={index}>

                                        <div className="col-lg-12 py-4"  >
                                            <div className='row'>
                                                {/* name field */}
                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" >
                                                        <Form.Label className='fw-bold' >Name</Form.Label>
                                                        <Form.Control
                                                            name={`facilities[${index}].facility.name`}
                                                            type="text"
                                                            autoComplete="false"
                                                            isValid={formik.touched?.facilities && formik.touched?.facilities[index]?.facility?.name && formik.errors?.facilities && !!formik.errors?.facilities[index]?.facility?.name}
                                                            isInvalid={formik?.errors?.facilities && !!formik?.errors?.facilities[index]?.facility?.name}
                                                            value={formik.values?.facilities[index]?.facility?.name}
                                                            className='b-r-10 form-control-solid'
                                                            onChange={formik.handleChange}
                                                            maxLength="255"

                                                            placeholder="Name"
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facilities && formik?.errors?.facilities[index]?.facility?.name}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>

                                                {/* Address field */}
                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold' >Address</Form.Label>

                                                        <Form.Control
                                                            name={`facilities[${index}].facility.address`}
                                                            type="address"
                                                            autoComplete="false"
                                                            isValid={formik.touched?.facilities && formik.touched?.facilities[index]?.facility?.address && formik.errors?.facilities && !!formik.errors?.facilities[index]?.facility?.address}
                                                            isInvalid={formik?.errors?.facilities && !!formik?.errors?.facilities[index]?.facility?.address}
                                                            value={formik.values?.facilities[index]?.facility?.address}
                                                            className='b-r-10 form-control-solid'
                                                            onChange={formik.handleChange}

                                                            placeholder="Address"
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facilities && formik?.errors?.facilities[index]?.facility?.address}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>

                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold'>City</Form.Label>
                                                        <Form.Control
                                                            name={`facilities[${index}].facility.city`}
                                                            type="text"
                                                            onChange={formik?.handleChange}
                                                            // onBlur={formik?.handleBlur}
                                                            autoComplete="false"
                                                            isValid={formik.touched?.facilities && formik.touched?.facilities[index]?.facility?.city && formik.errors?.facilities && !!formik.errors?.facilities[index]?.facility?.city}
                                                            isInvalid={formik?.errors?.facilities && !!formik?.errors?.facilities[index]?.facility?.city}
                                                            value={formik.values?.facilities[index]?.facility?.city}
                                                            className='b-r-10 form-control-solid'

                                                            placeholder=" City"
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facilities && formik?.errors?.facilities[index]?.facility?.city}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>

                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold'>State</Form.Label>
                                                        <Form.Control
                                                            name={`facilities[${index}].facility.state`}
                                                            type="text"
                                                            onChange={formik?.handleChange}
                                                            // onBlur={formik?.handleBlur}
                                                            isValid={formik.touched?.facilities && formik.touched?.facilities[index]?.facility?.state && formik.errors?.facilities && !!formik.errors?.facilities[index]?.facility?.state}
                                                            isInvalid={formik?.errors?.facilities && !!formik?.errors?.facilities[index]?.facility?.state}
                                                            value={formik.values?.facilities[index]?.facility?.state}
                                                            className='b-r-10 form-control-solid'
                                                            maxLength={2}
                                                            placeholder=" State"
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facilities && formik?.errors?.facilities[index]?.facility?.state}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>

                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold'>Country</Form.Label>
                                                        <Form.Control
                                                            name={`facilities[${index}].facility.country`}
                                                            type="text"
                                                            onChange={formik?.handleChange}
                                                            // onBlur={formik?.handleBlur}
                                                            autoComplete="false"
                                                            isValid={formik.touched?.facilities && formik.touched?.facilities[index]?.facility?.country && formik.errors?.facilities && !!formik.errors?.facilities[index]?.facility?.country}
                                                            isInvalid={formik?.errors?.facilities && !!formik?.errors?.facilities[index]?.facility?.country}
                                                            value={formik.values?.facilities[index]?.facility?.country}
                                                            className='b-r-10 form-control-solid'

                                                            placeholder=" Country"
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facilities && formik?.errors?.facilities[index]?.facility?.country}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>

                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold'>Zip Code</Form.Label>
                                                        <Form.Control
                                                            name={`facilities[${index}].facility.zip_code`}
                                                            type="text"
                                                            onChange={formik?.handleChange}
                                                            // onBlur={formik?.handleBlur}
                                                            autoComplete="false"
                                                            isValid={formik.touched?.facilities && formik.touched?.facilities[index]?.facility?.zip_code && formik.errors?.facilities && !!formik.errors?.facilities[index]?.facility?.zip_code}
                                                            isInvalid={formik?.errors?.facilities && !!formik?.errors?.facilities[index]?.facility?.zip_code}
                                                            value={formik.values?.facilities[index]?.facility?.zip_code}
                                                            className='b-r-10 form-control-solid'
                                                            maxLength={10}
                                                            placeholder=" Zip Code"
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facilities && formik?.errors?.facilities[index]?.facility?.zip_code}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>

                                                {/* tin */}
                                                <div className='col-lg-4'>
                                                    <Form.Label className='fw-bold' >TIN</Form.Label>

                                                    <FieldArray name={`facilities[${index}].tin_array`}
                                                        render={(roufan) => (
                                                            <React.Fragment>
                                                                <div className='d-flex flex-row gap-2'>
                                                                    <div className='d-flex flex-column justify-content-start  gap-2 flex-wrap'>
                                                                        {formik?.values?.facilities[index]?.tin_array?.map((facility, idxtin) => (
                                                                            <React.Fragment>

                                                                                <div key={idxtin} className="d-flex flex-row justify-content-start gap-3"  >
                                                                                    <div style={{ width: "200px" }}>
                                                                                        <Form.Group className="" >


                                                                                            <Form.Control
                                                                                                name={`facilities[${index}].tin_array[${idxtin}].tin`}
                                                                                                type="text"
                                                                                                autoComplete="false"
                                                                                                isValid={formik?.touched?.facilities &&
                                                                                                    formik?.errors?.facilities &&
                                                                                                    formik?.touched?.facilities[index]?.tin_array &&
                                                                                                    formik?.errors?.facilities[index]?.tin_array &&
                                                                                                    formik?.touched?.facilities[index]?.tin_array[idxtin]?.tin &&
                                                                                                    !!formik?.errors?.facilities[index]?.tin_array[idxtin]?.tin
                                                                                                }
                                                                                                isInvalid={formik?.errors?.facilities &&
                                                                                                    formik?.errors?.facilities[index]?.tin_array &&
                                                                                                    !!formik?.errors?.facilities[index]?.tin_array[idxtin]?.tin}
                                                                                                value={formik?.values?.facilities[index]?.tin_array[idxtin]?.tin}
                                                                                                className='b-r-10 form-control-solid'
                                                                                                onChange={formik.handleChange}
                                                                                                maxLength="9"

                                                                                                placeholder="TIN"
                                                                                            />
                                                                                            <Form.Control.Feedback type="invalid">{formik?.errors?.facilities && formik?.errors?.facilities[index]?.tin_array && formik?.errors?.facilities[index]?.tin_array[idxtin]?.tin}</Form.Control.Feedback>

                                                                                        </Form.Group>
                                                                                    </div>
                                                                                    <div className=''>
                                                                                        {/* <OverlayTrigger
                                                                                            placement='top'
                                                                                            
                                                                                            overlay={(props) => deleteTooltip("delete", props)}
                                                                                        >
                                                                                            <Button
                                                                                                className='btn btn-danger'
                                                                                                type="button"
                                                                                                style={{ pointerEvents: 'none' }}
                                                                                                disabled={formik?.values?.facilities[index]?.tin_array?.length == 1 ? true : false}
                                                                                                onClick={() => roufan.remove(idxtin)}
                                                                                            >
                                                                                                <i className='bi bi-trash'></i>
                                                                                            </Button>
                                                                                        </OverlayTrigger> */}
                                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                                                            <span className="d-inline-block">
                                                                                                <Button className='btn btn-danger' onClick={() => roufan.remove(idxtin)} disabled={formik?.values?.facilities[index]?.tin_array?.length == 1 ? true : false} >
                                                                                                    <i className='bi bi-trash'></i>
                                                                                                </Button>
                                                                                            </span>
                                                                                        </OverlayTrigger>


                                                                                    </div>

                                                                                </div>

                                                                            </React.Fragment>

                                                                        ))}
                                                                    </div>
                                                                    <div className=''>



                                                                        {/* <button
                                                                                className='btn  btn-primary'
                                                                                type="button"
                                                                                onClick={() => roufan.push({
                                                                                    tin: ""

                                                                                })}
                                                                            > */}
                                                                        {/* <i className='bi bi-plus'></i> */}

                                                                        <OverlayTrigger overlay={<Tooltip id="button-tooltip">Add</Tooltip>}>
                                                                            <span className="d-inline-block">
                                                                                <Button className='btn btn-primary' onClick={() => roufan.push({ tin: "" })}>
                                                                                    <i className='bi bi-plus'></i>
                                                                                </Button>
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                        {/* </button> */}

                                                                    </div>
                                                                </div>

                                                            </React.Fragment>

                                                        )}
                                                    />

                                                </div>

                                                {/* NPi */}
                                                <div className='col-lg-4'>
                                                    <Form.Label className='fw-bold' >NPI</Form.Label>

                                                    <FieldArray name={`facilities[${index}].npi_array`}
                                                        render={(arrayHelpers) => (
                                                            <React.Fragment>
                                                                <div className='d-flex flex-row gap-2'>
                                                                    <div className='d-flex flex-column justify-content-start  gap-2 flex-wrap'>


                                                                        {formik?.values?.facilities[index]?.npi_array?.map((facility, idxnpi) => (
                                                                            <React.Fragment  >

                                                                                <div key={idxnpi} className=" d-flex flex-row justify-content-start gap-3 "  >
                                                                                    <div style={{ width: "200px" }}>
                                                                                        <div>
                                                                                            <Form.Group className="" >


                                                                                                <Form.Control
                                                                                                    name={`facilities[${index}].npi_array[${idxnpi}].npi`}
                                                                                                    type="text"
                                                                                                    autoComplete="false"
                                                                                                    isValid={formik?.touched?.facilities &&
                                                                                                        formik?.touched?.facilities[index]?.npi_array &&
                                                                                                        formik?.touched?.facilities[index]?.npi_array[idxnpi]?.npi &&
                                                                                                        formik?.errors?.facilities &&
                                                                                                        formik?.errors?.facilities[index]?.npi_array &&
                                                                                                        !!formik?.errors?.facilities[index]?.npi_array[idxnpi]?.npi}
                                                                                                    isInvalid={formik?.errors?.facilities && formik?.errors?.facilities[index]?.npi_array &&
                                                                                                        formik?.errors?.facilities[index]?.npi_array[idxnpi]?.npi}
                                                                                                    value={formik?.values?.facilities[index]?.npi_array[idxnpi]?.npi}
                                                                                                    className='b-r-10 form-control-solid'
                                                                                                    onChange={formik.handleChange}
                                                                                                    maxLength="10"

                                                                                                    placeholder="NPI"
                                                                                                />
                                                                                                <Form.Control.Feedback type="invalid">{formik?.errors?.facilities &&
                                                                                                    formik?.errors?.facilities[index]?.npi_array && formik?.errors?.facilities[index]?.npi_array[idxnpi]?.npi}</Form.Control.Feedback>

                                                                                            </Form.Group>
                                                                                        </div>

                                                                                    </div>
                                                                                    {/* {idxnpi !==0&&
                                                                                    } */}
                                                                                    <div className=''>

                                                                                        {/* <button
                                                                                            className='btn btn-danger'
                                                                                            type="button"
                                                                                            disabled={formik?.values?.facilities[index]?.npi_array.length == 1 ? true : false}
                                                                                            onClick={() => arrayHelpers.remove(idxnpi)}
                                                                                        >
                                                                                            <i className='bi bi-trash'></i>
                                                                                        </button> */}

                                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Delete</Tooltip>}>
                                                                                            <span className="d-inline-block">
                                                                                                <Button className='btn btn-danger' onClick={() => arrayHelpers.remove(idxnpi)} disabled={formik?.values?.facilities[index]?.npi_array?.length == 1 ? true : false} >
                                                                                                    <i className='bi bi-trash'></i>
                                                                                                </Button>
                                                                                            </span>
                                                                                        </OverlayTrigger>
                                                                                    </div>

                                                                                </div>

                                                                            </React.Fragment>

                                                                        ))}
                                                                    </div>

                                                                    <div className=''>



                                                                        {/* <button
                                                                                className='btn  btn-primary'
                                                                                type="button"
                                                                                onClick={() => arrayHelpers.push({
                                                                                    npi: ""
                                                                                })}
                                                                            >
                                                                                <i className='bi bi-plus'></i>
                                                                            </button> */}

                                                                        <OverlayTrigger overlay={<Tooltip id="button-tooltip">Add</Tooltip>}>
                                                                            <span className="d-inline-block">
                                                                                <Button className='btn btn-primary' onClick={() => arrayHelpers.push({ npi: "" })}>
                                                                                    <i className='bi bi-plus'></i>
                                                                                </Button>
                                                                            </span>
                                                                        </OverlayTrigger>

                                                                    </div>
                                                                </div>
                                                            </React.Fragment>

                                                        )}
                                                    />

                                                </div>
                                                <div className='col-lg-4'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold'>Patient Account System Name</Form.Label>
                                                        <Form.Control
                                                            name={`facilities[${index}].facility.patient_account_system`}
                                                            type="text"
                                                            onChange={formik?.handleChange}
                                                            // onBlur={formik?.handleBlur}
                                                            autoComplete="false"
                                                            isValid={formik.touched?.facilities && formik.touched?.facilities[index]?.facility?.patient_account_system && formik.errors?.facilities && !!formik.errors?.facilities[index]?.facility?.patient_account_system}
                                                            isInvalid={formik?.errors?.facilities && !!formik?.errors?.facilities[index]?.facility?.patient_account_system}
                                                            value={formik.values?.facilities[index]?.facility?.patient_account_system}
                                                            className='b-r-10 form-control-solid'
                                                            placeholder=" Patient Account System"
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facilities && formik?.errors?.facilities[index]?.facility?.patient_account_system}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 border-top-dashed py-4'>
                                            <Form.Label className='fw-bolder my-2 clr-heading'>Image Files</Form.Label>
                                            <FieldArray name={`facilities[${index}].image_file`}
                                                render={(arrary) => (
                                                    <React.Fragment>
                                                        <div className='row align-items-center g-4' >
                                                            {formik?.values?.facilities[index]?.image_file && formik?.values?.facilities[index]?.image_file?.map((lockbox, lockindex) => (
                                                                <React.Fragment key={lockindex}>
                                                                    {/* {formik?.values?.facilities[index]?.image_file[lockindex]?.bank_name && */}

                                                                    <div className='col-lg-4 ' >
                                                                        <div className='card mb-5 mb-xl-8'>
                                                                            <div className='p-4 '>
                                                                                <div className='d-flex flex-row gap-4 justify-content-between align-items-center'>

                                                                                    <div className='d-flex flex-column ' style={{ width: '100%' }}>

                                                                                        <div className='d-flex flex-row justify-content-start align-items-center'>
                                                                                            <div className='flex-shrink-1 '>
                                                                                                <span className='fw-normal fs-6'>
                                                                                                    Bank Name:
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='flex-grow-1 ms-3'>
                                                                                                <Form.Control disabled className='form-control-solid form-control b-r-10' value={formik?.values?.facilities[index]?.image_file[lockindex]?.bank_name} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='d-flex flex-row justify-content-start align-items-center mt-2'>
                                                                                            <div className='flex-shrink-1'>
                                                                                                <span className='fw-normal fs-6'>
                                                                                                    Lockbox No:
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='flex-grow-1 ms-3'>
                                                                                                <Form.Control disabled className='form-control-solid form-control b-r-10' value={formik?.values?.facilities[index]?.image_file[lockindex]?.lockbox_no} />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='d-flex flex-row justify-content-start  mt-2'>
                                                                                            <div className='flex-shrink-1'>
                                                                                                <span className='fw-normal fs-6'>
                                                                                                    File Format:
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='flex-grow-1 ms-3'>
                                                                                                {formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "single_page" &&
                                                                                                    <span className='badge badge-light-danger'>Single Page </span>
                                                                                                }
                                                                                                {formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "multiple_page" &&
                                                                                                    <span className='badge badge-light-danger'>Multiple Page </span>
                                                                                                }
                                                                                                {formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "PDF" &&
                                                                                                    <span className='badge badge-light-danger'>PDF </span>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='d-flex flex-row justify-content-start align-items-center mt-2'>
                                                                                            <div className='flex-shrink-1'>
                                                                                                <span className='fw-normal fs-6'>
                                                                                                    Index File Included:
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='flex-grow-1 ms-3'>
                                                                                                <Form.Control disabled className='form-control-solid form-control b-r-10' value={formik?.values?.facilities[index]?.image_file[lockindex]?.index_file_included == '1' ? "Yes" : "No"} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className='d-flex flex-column h-100 gap-2 '>

                                                                                        {/* <button type="button" onClick={(lockindex) => handleViewModal(lockindex)} className='btn  btn-sm btn-success'>
                                                                                            <i className='bi bi-eye'></i>
                                                                                        </button> */}


                                                                                        <OverlayTrigger overlay={<Tooltip id="button-tooltip">View</Tooltip>}>
                                                                                            <span className="d-inline-block">
                                                                                                <Button className='btn  btn-sm btn-success' onClick={() => { handleViewModal(lockindex); setClickedFacilityIndex(index); setClickedImageFileIndex(lockindex) }}>
                                                                                                    <i className='bi bi-eye'></i>
                                                                                                </Button>
                                                                                            </span>
                                                                                        </OverlayTrigger>

                                                                                        {/* <button className='btn  btn-sm btn-primary' onClick={() => openImageEditModal(index, lockindex)} type='button'>
                                                                                            <i className='bi bi-pencil'></i>
                                                                                        </button> */}

                                                                                        <OverlayTrigger overlay={<Tooltip id="button-tooltip">Edit</Tooltip>}>
                                                                                            <span className="d-inline-block">
                                                                                                <Button className='btn  btn-sm btn-primary' onClick={() => { openImageEditModal(index, lockindex); setClickedFacilityIndex(index); setClickedImageFileIndex(lockindex) }}>
                                                                                                    <i className='bi bi-pencil'></i>
                                                                                                </Button>
                                                                                            </span>
                                                                                        </OverlayTrigger>
                                                                                        {/* <button
                                                                                            className='btn btn-sm btn-danger'
                                                                                            type="button"
                                                                                            onClick={() => { arrary.remove(lockindex) }}
                                                                                        >
                                                                                            <i className='bi bi-trash'></i>
                                                                                        </button> */}

                                                                                        <OverlayTrigger overlay={<Tooltip id="button-tooltip">Delete</Tooltip>}>
                                                                                            <span className="d-inline-block">
                                                                                                <Button className='btn btn-sm btn-danger' onClick={() => { arrary.remove(lockindex) }}>
                                                                                                    <i className='bi bi-trash'></i>
                                                                                                </Button>
                                                                                            </span>
                                                                                        </OverlayTrigger>
                                                                                    </div>

                                                                                    {/* edit modal */}

                                                                                    <Modal size='xl' show={imageEditOpen && clickedFacilityIndex == index && clickedImageFileIndex == lockindex} keyboard={true} backdrop="static"
                                                                                        onHide={() => onHideEditModal()}>
                                                                                        <Modal.Header closeButton>
                                                                                            <Modal.Title className='fs-6 fw-bolder clr-heading'>Edit Image File</Modal.Title>
                                                                                        </Modal.Header>
                                                                                        <Form
                                                                                        // onSubmit={lockboxFormik.handleSubmit}
                                                                                        >
                                                                                            <Modal.Body>
                                                                                                <div className='row border-bottom-dashed mb-3'>
                                                                                                    <div className='col-lg-4'>
                                                                                                        <Form.Group className='mb-3 ' >
                                                                                                            <Form.Label className='fw-bolder '>Bank Name</Form.Label>
                                                                                                            <Form.Control
                                                                                                                type="text"
                                                                                                                name='bank_name'
                                                                                                                className='form-control form-control-solid b-r-10 '
                                                                                                                placeholder='Bank Name'
                                                                                                                isValid={lockboxFormik?.touched?.bank_name && !!lockboxFormik?.errors?.bank_name}
                                                                                                                isInvalid={!!lockboxFormik?.errors?.bank_name}
                                                                                                                value={lockboxFormik?.values?.bank_name}

                                                                                                                onChange={lockboxFormik?.handleChange}
                                                                                                            />
                                                                                                            {lockboxFormik.errors?.bank_name &&
                                                                                                                <span className='text-danger '>{lockboxFormik?.errors?.bank_name}</span>
                                                                                                            }

                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4'>
                                                                                                        <Form.Group className='mb-3 ' >
                                                                                                            <Form.Label className='fw-bolder '>Lockbox No</Form.Label>
                                                                                                            <Form.Control
                                                                                                                type="text"
                                                                                                                name='lockbox_no'
                                                                                                                className='form-control form-control-solid b-r-10 '
                                                                                                                placeholder='Lockbox No'
                                                                                                                isValid={lockboxFormik?.touched?.lockbox_no && !!lockboxFormik?.errors?.lockbox_no}
                                                                                                                isInvalid={!!lockboxFormik?.errors?.lockbox_no}
                                                                                                                value={lockboxFormik?.values?.lockbox_no}

                                                                                                                onChange={lockboxFormik?.handleChange}
                                                                                                            />
                                                                                                            {lockboxFormik.errors?.lockbox_no &&
                                                                                                                <span className='text-danger '>{lockboxFormik?.errors?.lockbox_no}</span>
                                                                                                            }

                                                                                                        </Form.Group>
                                                                                                    </div>

                                                                                                    <div className='col-lg-4'>
                                                                                                        <Form.Group className='mb-3 ' >
                                                                                                            <Form.Label className='fw-bolder '>Default TIN</Form.Label>
                                                                                                            <Form.Control
                                                                                                                type="text"
                                                                                                                name='default_tin'
                                                                                                                className='form-control form-control-solid b-r-10 '
                                                                                                                placeholder='Default TIN'
                                                                                                                isValid={lockboxFormik?.touched?.default_tin && !!lockboxFormik?.errors?.default_tin}
                                                                                                                isInvalid={!!lockboxFormik?.errors?.default_tin}
                                                                                                                value={lockboxFormik?.values?.default_tin}
                                                                                                                maxLength="9"
                                                                                                                onChange={lockboxFormik?.handleChange}
                                                                                                            />
                                                                                                            {lockboxFormik.errors?.default_tin &&
                                                                                                                <span className='text-danger '>{lockboxFormik?.errors?.default_tin}</span>
                                                                                                            }
                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4'>
                                                                                                        <Form.Group className='mb-3 ' >
                                                                                                            <Form.Label className='fw-bolder '>Default NPI</Form.Label>
                                                                                                            <Form.Control
                                                                                                                type="text"
                                                                                                                name='default_npi'
                                                                                                                className='form-control form-control-solid b-r-10 '
                                                                                                                placeholder='Default NPI'
                                                                                                                isValid={lockboxFormik?.touched?.default_npi && !!lockboxFormik?.errors?.default_npi}
                                                                                                                isInvalid={!!lockboxFormik?.errors?.default_npi}
                                                                                                                value={lockboxFormik?.values?.default_npi}
                                                                                                                maxLength="10"
                                                                                                                onChange={lockboxFormik?.handleChange}
                                                                                                            />
                                                                                                            {lockboxFormik.errors?.default_npi &&
                                                                                                                <span className='text-danger '>{lockboxFormik?.errors?.default_npi}</span>
                                                                                                            }
                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* {console.log(formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format)} */}
                                                                                                <div className='row border-bottom-dashed'>
                                                                                                    <div className='col-lg-4'>
                                                                                                        <Form.Group className="mb-3" >

                                                                                                            <Form.Label className='fw-bold' >Inbound Image File Formats</Form.Label>

                                                                                                            <div>
                                                                                                                <Form.Check
                                                                                                                    inline
                                                                                                                    label="Single Page"
                                                                                                                    // checked={!!lockboxFormik?.values?.single_page}
                                                                                                                    name="inbound_image_file_format"
                                                                                                                    type='radio'
                                                                                                                    // value={true}
                                                                                                                    defaultChecked={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "single_page" ? true : false}
                                                                                                                    // value={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format}
                                                                                                                    id="single_page"
                                                                                                                    // checked={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "single_page" ? true : false}

                                                                                                                    onChange={(e) => handleRadioChange(e)}
                                                                                                                    //isInvalid={!!lockboxFormik.errors?.single_page}
                                                                                                                    // feedback={lockboxFormik.errors?.single_page}
                                                                                                                    feedbackType="invalid"

                                                                                                                />
                                                                                                                <Form.Check
                                                                                                                    inline
                                                                                                                    label="Multiple Page"
                                                                                                                    name="inbound_image_file_format"
                                                                                                                    id="multiple_page"
                                                                                                                    type='radio'
                                                                                                                    defaultChecked={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "multiple_page" ? true : false}
                                                                                                                    // checked={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format}
                                                                                                                    // value={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "multiple_page" ? true : false}
                                                                                                                    // value="multiple_page"
                                                                                                                    //checked={!!lockboxFormik?.values?.multiple_page}
                                                                                                                    onChange={(e) => handleRadioChange(e)}
                                                                                                                    //isInvalid={!!lockboxFormik.errors?.multiple_page}
                                                                                                                    // feedback={lockboxFormik.errors?.multiple_page}
                                                                                                                    feedbackType="invalid"
                                                                                                                //isValid={lockboxFormik.errors?.multiple_page}
                                                                                                                />


                                                                                                                <Form.Check
                                                                                                                    inline
                                                                                                                    label="PDF"
                                                                                                                    id="PDF"
                                                                                                                    name="inbound_image_file_format"
                                                                                                                    type='radio'
                                                                                                                    defaultChecked={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "PDF" ? true : false}
                                                                                                                    // checked={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format}
                                                                                                                    // value={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_formats == "PDF" ? true : false}
                                                                                                                    // value="PDF"
                                                                                                                    onChange={(e) => handleRadioChange(e)}
                                                                                                                    // checked={!!lockboxFormik?.values?.PDF}
                                                                                                                    //isInvalid={!!lockboxFormik.errors?.PDF}
                                                                                                                    // feedback={lockboxFormik.errors?.PDF}
                                                                                                                    feedbackType="invalid"
                                                                                                                //isValid={lockboxFormik.errors?.PDF}
                                                                                                                />


                                                                                                            </div>

                                                                                                            {/* {lockboxFormik.errors?.inbound_image_file_format &&
                                                                                                                <span className='text-danger ms-2'>
                                                                                                                    {lockboxFormik.errors?.inbound_image_file_format}
                                                                                                                </span>
                                                                                                            } */}
                                                                                                        </Form.Group>


                                                                                                    </div>




                                                                                                    <div className='col-lg-4'>
                                                                                                        <Form.Group className='mb-3 ' >
                                                                                                            <Form.Label className='fw-bolder '>Lockbox Filename Separator</Form.Label>
                                                                                                            <Form.Control
                                                                                                                type="text"
                                                                                                                name='lockbox_file_name_split_key'
                                                                                                                className='form-control form-control-solid b-r-10 '
                                                                                                                placeholder='Lockbox Filename Separator'
                                                                                                                isValid={lockboxFormik?.touched?.lockbox_file_name_split_key && !!lockboxFormik?.errors?.lockbox_file_name_split_key}
                                                                                                                isInvalid={!!lockboxFormik?.errors?.lockbox_file_name_split_key}
                                                                                                                value={lockboxFormik?.values?.lockbox_file_name_split_key}

                                                                                                                onChange={lockboxFormik?.handleChange}
                                                                                                            />
                                                                                                            {lockboxFormik.errors?.lockbox_file_name_split_key &&
                                                                                                                <span className='text-danger '>{lockboxFormik?.errors?.lockbox_file_name_split_key}</span>
                                                                                                            }

                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4'>
                                                                                                        <Form.Group className='mb-3 ' >
                                                                                                            <Form.Label className='fw-bolder '>Lockbox ID Position</Form.Label>
                                                                                                            <Form.Control
                                                                                                                type="text"
                                                                                                                name='lockbox_number_position'
                                                                                                                className='form-control form-control-solid b-r-10 '
                                                                                                                placeholder='Lockbox ID Position'
                                                                                                                isValid={lockboxFormik?.touched?.lockbox_number_position && !!lockboxFormik?.errors?.lockbox_number_position}
                                                                                                                isInvalid={!!lockboxFormik?.errors?.lockbox_number_position}
                                                                                                                value={lockboxFormik?.values?.lockbox_number_position}

                                                                                                                onChange={lockboxFormik?.handleChange}
                                                                                                            />
                                                                                                            {lockboxFormik.errors?.lockbox_number_position &&
                                                                                                                <span className='text-danger '>{lockboxFormik?.errors?.lockbox_number_position}</span>
                                                                                                            }

                                                                                                        </Form.Group>
                                                                                                    </div>



                                                                                                </div>
                                                                                                <div className='row border-bottom-dashed pt-3'>

                                                                                                    <div className='col-lg-3'>
                                                                                                        {/* {client_data && client_data['0'] && client_data['0']?.facility && client_data['0']?.facility['0']?.image_file && client_data['0']?.facility['0']?.image_file['0']?.sample_inbound_file_schema &&

                                                                                <Button download href={client_data['0']?.facility['0']?.image_file['0']?.sample_inbound_file_schema} variant='success' size='sm' className='ms-2' ><i className='bi bi-download fs-7'></i></Button>
                                                                            } */}
                                                                                                        {/* <Button download={"sample_input_file_schema.json"} variant='success' size='sm' className='ms-2' href={process.env.REACT_APP_WEB_PROD_SERVER_URL + "/static/output_file_schema/sample_input_file_schema.json"} ><i className='bi bi-download fs-7'></i></Button> */}

                                                                                                        <Form.Group className="mb-3" >
                                                                                                            <Form.Label className='fw-bold'>Index File Included</Form.Label>
                                                                                                            {/* <Button variant='primary' size='sm' className='ms-2'  > */}
                                                                                                            <a
                                                                                                                label={<i className='bi bi-download ms-2'></i>}
                                                                                                                // label={<i className='bi bi-download text-white'></i>}
                                                                                                                target='_blank'
                                                                                                                href={lockboxFormik?.values?.sample_inbound_file_schema}
                                                                                                            // filename="sample_input_file_schema.json"
                                                                                                            // mimeType="application/json" buttonProps={{
                                                                                                            //     variant: 'contained',
                                                                                                            //     color: 'primary'
                                                                                                            // }}
                                                                                                            // exportFile={() => JSON.stringify(dataSample)}
                                                                                                            ><i className='bi bi-download ms-2'></i></a>
                                                                                                            {/* </Button> */}
                                                                                                            {/* <Select
                                                                                    placeholder="Select an Option"

                                                                                    className="basic-single"
                                                                                    classNamePrefix="select"
                                                                                    name="index_file_included"
                                                                                    value={lockboxFormik.values?.index_file_included == '1' ? { value: "1", label: 'Yes' } : { value: "0", label: 'No' }}
                                                                                    isValid={lockboxFormik.touched.index_file_included && !!lockboxFormik?.errors?.index_file_included}
                                                                                    isInvalid={!!lockboxFormik.errors?.index_file_included}

                                                                                    options={indexFileStructureOptions}
                                                                                    onChange={(e) => { handleOnSelectChange({ name: 'index_file_included', value: e }) }}
                                                                                /> */}
                                                                                                            <Form.Control name="index_file_included"
                                                                                                                isInvalid={!!lockboxFormik.errors?.index_file_included}
                                                                                                                isValid={lockboxFormik.touched.index_file_included && !!lockboxFormik?.errors?.index_file_included}
                                                                                                                as="select"
                                                                                                                value={lockboxFormik.values?.index_file_included}
                                                                                                                className='form-control form-control-solid b-r-10'
                                                                                                                onChange={lockboxFormik.handleChange}
                                                                                                            >
                                                                                                                <option value='0'>Select an option</option>
                                                                                                                {indexFileStructureOptions?.map((option, index) => (
                                                                                                                    <option value={option.value}>{option.label}</option>
                                                                                                                ))}
                                                                                                            </Form.Control>

                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    {lockboxFormik.values?.index_file_included == "1" &&
                                                                                                        <React.Fragment>
                                                                                                            <div className='col-lg-3'>
                                                                                                                <Form.Group className='mb-3 ' >
                                                                                                                    <Form.Label className='fw-bold '>Index File Name</Form.Label>
                                                                                                                    <Form.Control
                                                                                                                        type="text"
                                                                                                                        name='index_file_name'
                                                                                                                        className='form-control form-control-solid b-r-10 '
                                                                                                                        placeholder='Index File Name'
                                                                                                                        isValid={lockboxFormik?.touched?.index_file_name && !!lockboxFormik?.errors?.index_file_name}
                                                                                                                        isInvalid={!!lockboxFormik?.errors?.index_file_name}
                                                                                                                        value={lockboxFormik?.values?.index_file_name}

                                                                                                                        onChange={lockboxFormik?.handleChange}
                                                                                                                    />

                                                                                                                    {lockboxFormik.errors?.index_file_name &&
                                                                                                                        <span className='text-danger '>{lockboxFormik?.errors?.index_file_name}</span>
                                                                                                                    }

                                                                                                                </Form.Group>
                                                                                                            </div>

                                                                                                            <div className='col-lg-3'>
                                                                                                                <Form.Group className="mb-3" >

                                                                                                                    <Form.Label className='fw-bold'>Index File Type</Form.Label>
                                                                                                                    {/* <Select
                                                                                            placeholder="Select an Option"
                                                                                            value={selectedFileType}
                                                                                            className="basic-single"
                                                                                            classNamePrefix="select"
                                                                                            name="index_file_type"
                                                                                            // value={lockboxFormik.values?.index_file_type}
                                                                                            isValid={lockboxFormik.touched.index_file_type && !!lockboxFormik?.errors?.index_file_type}
                                                                                            isInvalid={!!lockboxFormik.errors?.index_file_type}

                                                                                            options={indexFileOptions}
                                                                                            onChange={(e) => { handleOnSelectChange({ name: 'index_file_type', value: e }) }}
                                                                                        /> */}
                                                                                                                    <Form.Control name="index_file_type"
                                                                                                                        isInvalid={!!lockboxFormik.errors?.index_file_type}
                                                                                                                        isValid={lockboxFormik.touched.index_file_type && !!lockboxFormik?.errors?.index_file_type}
                                                                                                                        as="select"
                                                                                                                        value={lockboxFormik.values?.index_file_type}
                                                                                                                        className='form-control form-control-solid b-r-10'
                                                                                                                        onChange={lockboxFormik.handleChange}
                                                                                                                    >
                                                                                                                        {/* {indexFileOptions?.map((option, index) => (
                                                                                                <option value={option.value}>{option.label}</option>
                                                                                            ))} */}
                                                                                                                        <option value="">Select An Option</option>
                                                                                                                        <option value="1" >Text</option>
                                                                                                                        <option value="2" >XML</option>
                                                                                                                        <option value="3" >JSON</option>

                                                                                                                    </Form.Control>

                                                                                                                </Form.Group>
                                                                                                            </div>


                                                                                                            <div className='col-lg-3'>
                                                                                                                <Form.Group className="mb-3" >

                                                                                                                    <Form.Label className='fw-bold'>Index File Schema</Form.Label>
                                                                                                                    <Form.Control
                                                                                                                        name='index_file_schema'
                                                                                                                        type="file"
                                                                                                                        // onChange={lockboxFormik.handleChange}
                                                                                                                        onChange={(e) => handleFileChange(e)}
                                                                                                                        // accept={acceptAttribute}
                                                                                                                        autoComplete="false"
                                                                                                                        isValid={lockboxFormik.touched.index_file_schema && !!lockboxFormik?.errors?.index_file_schema}
                                                                                                                        isInvalid={!!lockboxFormik?.errors?.index_file_schema}
                                                                                                                        // value={lockboxFormik?.values?.index_file_schema}
                                                                                                                        className='b-r-10 form-control-solid browse-file-padd'
                                                                                                                    />
                                                                                                                    <span className='text-danger'>{lockboxFormik?.errors?.index_file_schema}</span>

                                                                                                                </Form.Group>
                                                                                                            </div>
                                                                                                            {lockboxFormik.values.index_file_schema_url &&
                                                                                                                <div className='col-lg-2'>
                                                                                                                    <Form.Group className="mb-3" >

                                                                                                                        <Form.Label className='fw-bold'>Current Index File  : </Form.Label>

                                                                                                                        <div className='overflow-hidden'>
                                                                                                                            <div>

                                                                                                                                <button className='btn btn-dark' onClick={() => { onButtonClick(lockboxFormik.values.index_file_schema_url) }} type="button"  ><i className='bi bi-download text-white'></i></button>
                                                                                                                            </div>
                                                                                                                            {/* <span className='fw-bold'>{formik?.values?.facilities[index]?.image_file[state.lockboxindex]?.index_file_schema}</span> */}
                                                                                                                        </div>

                                                                                                                    </Form.Group>
                                                                                                                </div>
                                                                                                            }

                                                                                                        </React.Fragment>
                                                                                                    }
                                                                                                </div>

                                                                                                <div className='row border-bottom-dashed pt-3'>
                                                                                                    <div className='col-lg-4'>
                                                                                                        <Form.Group className="mb-3" >

                                                                                                            <Form.Label className='fw-bold'>File Transfer Period</Form.Label>
                                                                                                            <Select
                                                                                                                placeholder="Select an Option"

                                                                                                                className="basic-single"
                                                                                                                classNamePrefix="select"
                                                                                                                name="file_transfer_period"
                                                                                                                value={lockboxFormik.values?.file_transfer_period == "1" ? { value: "1", label: 'Fixed Time' } : lockboxFormik.values?.file_transfer_period == "2" ? { value: "2", label: 'Any Time' } : ""}
                                                                                                                isValid={lockboxFormik.touched.file_transfer_period && !!lockboxFormik?.errors?.file_transfer_period}
                                                                                                                isInvalid={!!lockboxFormik.errors?.file_transfer_period}

                                                                                                                options={fileTransferPeriodOptions}
                                                                                                                onChange={(e) => { handleOnSelectChange({ name: 'file_transfer_period', value: e }) }}
                                                                                                                styles={{
                                                                                                                    container: (styles, state) => ({
                                                                                                                        ...styles,
                                                                                                                        borderRadius: "9px",
                                                                                                                    }),
                                                                                                                    control: (styles, state) => ({
                                                                                                                        ...styles,
                                                                                                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                                                                        borderStyle: "none", boxShadow: "none",
                                                                                                                        borderRadius: "10px",
                                                                                                                        maxHeight: state.isFocused ? "auto" : "30px",
                                                                                                                    }),
                                                                                                                    indicatorSeparator: styles => ({
                                                                                                                        ...styles, width: "0px", minHeight: "20px"
                                                                                                                    }),


                                                                                                                }}
                                                                                                            />
                                                                                                            {/* <Form.Control.Feedback type="invalid">File Transfer Period</Form.Control.Feedback> */}
                                                                                                            {lockboxFormik.errors?.file_transfer_period &&
                                                                                                                <span className='text-danger '>{lockboxFormik?.errors?.file_transfer_period}</span>
                                                                                                            }
                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    {lockboxFormik.values?.file_transfer_period == "1" &&
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>Transfer Time (UTC)</Form.Label>

                                                                                                                <div>
                                                                                                                    <TimePicker
                                                                                                                        className="b-r-10 "
                                                                                                                        onChange={(e) => { handleOnTimeChange({ name: 'transfer_time', value: e }) }}
                                                                                                                        disableClock={true}
                                                                                                                        value={lockboxFormik.values?.transfer_time} />
                                                                                                                </div>

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className='row pt-3'>
                                                                                                    <Form.Label className='fw-bold clr-heading'>SFTP Credentials</Form.Label>
                                                                                                    <div className='col-lg-4 '>
                                                                                                        <Form.Group className="mb-3" >

                                                                                                            <Form.Label className='fw-bold'>SFTP Server</Form.Label>
                                                                                                            <Select
                                                                                                                placeholder="Select an Option"

                                                                                                                className="basic-single"
                                                                                                                classNamePrefix="select"
                                                                                                                name="sftp_server"
                                                                                                                value={lockboxFormik.values?.sftp_server == "1" ? { value: "1", label: 'Client' } : lockboxFormik.values?.sftp_server == "2" ? { value: "2", label: 'Our Own' } : ""}
                                                                                                                isValid={lockboxFormik.touched.sftp_server && !!lockboxFormik?.errors?.sftp_server}
                                                                                                                isInvalid={!!lockboxFormik.errors?.sftp_server}

                                                                                                                options={sftpServerOptions}
                                                                                                                onChange={(e) => { handleOnSelectChange({ name: 'sftp_server', value: e }) }}
                                                                                                                styles={{
                                                                                                                    container: (styles, state) => ({
                                                                                                                        ...styles,
                                                                                                                        borderRadius: "9px",
                                                                                                                    }),
                                                                                                                    control: (styles, state) => ({
                                                                                                                        ...styles,
                                                                                                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                                                                        borderStyle: "none", boxShadow: "none",
                                                                                                                        borderRadius: "10px",
                                                                                                                        maxHeight: state.isFocused ? "auto" : "30px",
                                                                                                                    }),
                                                                                                                    indicatorSeparator: styles => ({
                                                                                                                        ...styles, width: "0px", minHeight: "20px"
                                                                                                                    }),


                                                                                                                }} />
                                                                                                            {lockboxFormik.errors?.sftp_server &&
                                                                                                                <span className='text-danger '>{lockboxFormik?.errors?.sftp_server}</span>
                                                                                                            }

                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4 '>
                                                                                                        <Form.Group className="mb-3" >

                                                                                                            <Form.Label className='fw-bold'>Address</Form.Label>
                                                                                                            <Form.Control
                                                                                                                name='sftp_address'
                                                                                                                type="text"
                                                                                                                onChange={lockboxFormik.handleChange}
                                                                                                                autoComplete="false"
                                                                                                                isValid={lockboxFormik.touched.sftp_address && !!lockboxFormik?.errors.sftp_address}
                                                                                                                isInvalid={!!lockboxFormik?.errors.sftp_address}
                                                                                                                value={lockboxFormik.values?.sftp_address}
                                                                                                                className='b-r-10 form-control-solid'
                                                                                                                maxLength="255"

                                                                                                                placeholder="Enter Address"
                                                                                                            />
                                                                                                            <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors.sftp_address}</Form.Control.Feedback>

                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4 '>
                                                                                                        <Form.Group className="mb-3" >

                                                                                                            <Form.Label className='fw-bold'>Port</Form.Label>
                                                                                                            <Form.Control
                                                                                                                name='sftp_port'
                                                                                                                type="number"
                                                                                                                onChange={lockboxFormik.handleChange}
                                                                                                                autoComplete="false"
                                                                                                                isValid={lockboxFormik.touched.sftp_port && !!lockboxFormik?.errors.sftp_port}
                                                                                                                isInvalid={!!lockboxFormik?.errors.sftp_port}
                                                                                                                value={lockboxFormik.values?.sftp_port}
                                                                                                                className='b-r-10 form-control-solid'
                                                                                                                maxLength="255"

                                                                                                                placeholder="Enter Port"
                                                                                                            />
                                                                                                            <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors.sftp_port}</Form.Control.Feedback>
                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4 '>
                                                                                                        <Form.Group className="mb-4" >

                                                                                                            <Form.Label className='fw-bold'>Username</Form.Label>
                                                                                                            <Form.Control
                                                                                                                name='sftp_username'
                                                                                                                type="text"
                                                                                                                onChange={lockboxFormik.handleChange}
                                                                                                                autoComplete="false"
                                                                                                                isValid={lockboxFormik.touched.sftp_username && !!lockboxFormik?.errors.sftp_username}
                                                                                                                isInvalid={!!lockboxFormik?.errors.sftp_username}
                                                                                                                value={lockboxFormik.values?.sftp_username}
                                                                                                                className='b-r-10 form-control-solid'
                                                                                                                maxLength="255"

                                                                                                                placeholder="Enter Username"
                                                                                                            />
                                                                                                            <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors.sftp_username}</Form.Control.Feedback>

                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4  '>
                                                                                                        <Form.Group className="mb-4" >

                                                                                                            <Form.Label className='fw-bold'>Password</Form.Label>
                                                                                                            <InputGroup className='input-group-client-password' >
                                                                                                                <Form.Control
                                                                                                                    name='sftp_password'
                                                                                                                    type={togglePassword ? "text" : "password"}
                                                                                                                    onChange={lockboxFormik.handleChange}
                                                                                                                    autoComplete="false"
                                                                                                                    isValid={lockboxFormik.touched.sftp_password && !!lockboxFormik?.errors.sftp_password}
                                                                                                                    isInvalid={!!lockboxFormik?.errors.sftp_password}
                                                                                                                    value={lockboxFormik.values?.sftp_password}
                                                                                                                    className='form-control-solid border-radius-password'
                                                                                                                    maxLength="255"

                                                                                                                    placeholder="Enter Password"
                                                                                                                />
                                                                                                                <InputGroup.Text className='btn btn-secondary border-radius-eye text-white input-group-text-password-client' onClick={() => setTogglePassword(!togglePassword)} ><i className={togglePassword ? 'bi bi-eye text-whtie' : 'bi bi-eye-slash text-whtie'}></i></InputGroup.Text>
                                                                                                                <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors.sftp_password}</Form.Control.Feedback>
                                                                                                            </InputGroup>

                                                                                                        </Form.Group>
                                                                                                    </div>
                                                                                                    {client_data && client_data?.eob &&
                                                                                                        <div className='col-lg-4 '>
                                                                                                            <Form.Group className="mb-4" >

                                                                                                                <Form.Label className='fw-bold'>Eob Folder</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    name="eob_file_path"
                                                                                                                    type="text"
                                                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                                                    // onBlur={formik?.handleBlur}
                                                                                                                    autoComplete="false"
                                                                                                                    isValid={lockboxFormik.touched?.eob_file_path && !!lockboxFormik.errors?.eob_file_path}
                                                                                                                    isInvalid={lockboxFormik?.errors?.eob_file_path && !!lockboxFormik?.errors?.eob_file_path}
                                                                                                                    value={lockboxFormik.values?.eob_file_path}
                                                                                                                    className='b-r-10 form-control-solid'

                                                                                                                />
                                                                                                                <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors?.eob_file_path && lockboxFormik?.errors?.eob_file_path}</Form.Control.Feedback>

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {client_data && client_data?.eob_lite &&
                                                                                                        <div className='col-lg-4 '>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>Eob Lite Folder</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    name="eob_lite_file_path"
                                                                                                                    type="text"
                                                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                                                    // onBlur={formik?.handleBlur}
                                                                                                                    autoComplete="false"
                                                                                                                    isValid={formik.touched?.facilities && formik.touched?.facilities[index]?.eob_lite_file_path && formik.errors?.facilities && !!formik.errors?.facilities[index]?.facility?.eob_lite_file_path}
                                                                                                                    isInvalid={lockboxFormik?.errors?.eob_lite_file_path && !!lockboxFormik?.errors?.eob_lite_file_path}
                                                                                                                    value={lockboxFormik.values?.eob_lite_file_path}
                                                                                                                    className='b-r-10 form-control-solid'

                                                                                                                />
                                                                                                                <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors?.eob_lite_file_path}</Form.Control.Feedback>

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {client_data && client_data?.patient_pay &&
                                                                                                        <div className='col-lg-4 '>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>Patient Pay Folder</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    name="patient_pay_file_path"
                                                                                                                    type="text"
                                                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                                                    // onBlur={formik?.handleBlur}
                                                                                                                    autoComplete="false"
                                                                                                                    isValid={formik.errors?.facilities && formik.errors?.facilities[index] && !!formik.errors?.facilities[index].patient_pay_file_path}
                                                                                                                    isInvalid={lockboxFormik?.errors?.patient_pay_file_path && !!lockboxFormik?.errors?.patient_pay_file_path}
                                                                                                                    value={lockboxFormik.values?.patient_pay_file_path}
                                                                                                                    className='b-r-10 form-control-solid'

                                                                                                                />
                                                                                                                <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors?.patient_pay_file_path}</Form.Control.Feedback>

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {client_data && client_data?.correspondence &&
                                                                                                        <div className='col-lg-4 '>
                                                                                                            <Form.Group className="mb-3">

                                                                                                                <Form.Label className='fw-bold'>Correspondence Folder</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    name="correspondence_file_path"
                                                                                                                    type="text"
                                                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                                                    // onBlur={formik?.handleBlur}
                                                                                                                    autoComplete="false"
                                                                                                                    // isValid={formik.touched?.facilities && formik.touched?.facilities[index].correspondence_file_path && formik.errors?.facilities && !!formik.errors?.facilities[index].correspondence_file_path}
                                                                                                                    isInvalid={lockboxFormik?.errors?.correspondence_file_path}
                                                                                                                    value={lockboxFormik.values?.correspondence_file_path}
                                                                                                                    className='b-r-10 form-control-solid'

                                                                                                                />
                                                                                                                <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors?.correspondence_file_path}</Form.Control.Feedback>

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>


                                                                                            </Modal.Body>
                                                                                            <Modal.Footer>
                                                                                                <Button variant="secondary" onClick={() => { onHideEditModal(); setTogglePassword(false) }}>
                                                                                                    Close
                                                                                                </Button>
                                                                                                <Button type="button"
                                                                                                    // onClick={() => raihan.push()}
                                                                                                    onClick={() => { editImageFile(); setTogglePassword(false) }}
                                                                                                    variant="success" >
                                                                                                    Save
                                                                                                </Button>
                                                                                            </Modal.Footer>
                                                                                        </Form>
                                                                                    </Modal>

                                                                                    {/* edit modal */}

                                                                                    {/* view modal  */}
                                                                                    {state.viewlockboxindex >= 0 &&
                                                                                        <Modal size='xl' show={modalViewOpen && clickedFacilityIndex == index && clickedImageFileIndex == lockindex} backdrop="static"
                                                                                            onHide={() => setModalViewOpen(false)}
                                                                                        >
                                                                                            <Modal.Header closeButton>
                                                                                                <Modal.Title className='fs-6 fw-bolder clr-heading'> Image File</Modal.Title>
                                                                                            </Modal.Header>
                                                                                            <Form>
                                                                                                <Modal.Body>
                                                                                                    <div className='row border-bottom-dashed mb-3'>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className='mb-3 ' >
                                                                                                                <Form.Label className='fw-bolder '>Bank Name</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    type="text"
                                                                                                                    name='bank_name'
                                                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                                                    placeholder='Bank Name'
                                                                                                                    disabled
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.bank_name}
                                                                                                                />

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className='mb-3 ' >
                                                                                                                <Form.Label className='fw-bolder '>Lockbox No</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    type="text"
                                                                                                                    name='lockbox_no'
                                                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                                                    placeholder='Lockbox No'
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.lockbox_no}

                                                                                                                />

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className='mb-3 ' >
                                                                                                                <Form.Label className='fw-bolder '>Default TIN</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    type="text"
                                                                                                                    name='default_tin'
                                                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                                                    placeholder='Default TIN'
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.default_tin}
                                                                                                                    disabled
                                                                                                                />

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className='mb-3 ' >
                                                                                                                <Form.Label className='fw-bolder '>Default NPI</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    type="text"
                                                                                                                    name='default_npi'
                                                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                                                    placeholder='Default NPI'
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.default_npi}
                                                                                                                    disabled
                                                                                                                />

                                                                                                            </Form.Group>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div className='row border-bottom-dashed'>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold ' >Inbound Image File Formats</Form.Label>

                                                                                                                <div>
                                                                                                                    <Form.Check
                                                                                                                        inline
                                                                                                                        label="Single Page"
                                                                                                                        name="inbound_image_file_format"
                                                                                                                        type='radio'
                                                                                                                        value="single_page"
                                                                                                                        id="single_page"
                                                                                                                        disabled
                                                                                                                        // checked={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.single_page} --sajith
                                                                                                                        defaultChecked={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "single_page" ? true : false}
                                                                                                                    />
                                                                                                                    <Form.Check
                                                                                                                        inline
                                                                                                                        label="Multiple Page"
                                                                                                                        name="inbound_image_file_format"
                                                                                                                        id="multiple_page"
                                                                                                                        type='radio'
                                                                                                                        value="multiple_page"
                                                                                                                        disabled
                                                                                                                        // checked={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.multiple_page} --sajith
                                                                                                                        defaultChecked={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "multiple_page" ? true : false} />


                                                                                                                    <Form.Check
                                                                                                                        inline
                                                                                                                        label="PDF"
                                                                                                                        id="PDF"
                                                                                                                        name="inbound_image_file_format"
                                                                                                                        type='radio'
                                                                                                                        value="PDF"
                                                                                                                        disabled
                                                                                                                        // checked={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.PDF} --sajith
                                                                                                                        defaultChecked={formik?.values?.facilities[index]?.image_file[lockindex]?.inbound_image_file_format == "PDF" ? true : false} />

                                                                                                                </div>

                                                                                                            </Form.Group>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='row border-bottom-dashed pt-3'>

                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>Index File Included</Form.Label>
                                                                                                                <Form.Control

                                                                                                                    className="form-control form-control-solid b-r-10"
                                                                                                                    name="index_file_included"
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.index_file_included == "1" ? "Yes" : "No"}

                                                                                                                />

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        {formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.index_file_included == "1" &&
                                                                                                            <React.Fragment>
                                                                                                                <div className='col-lg-2'>
                                                                                                                    <Form.Group className='mb-3 ' >
                                                                                                                        <Form.Label className='fw-bold '>Index File Name</Form.Label>
                                                                                                                        <Form.Control
                                                                                                                            type="text"
                                                                                                                            name='index_file_name'
                                                                                                                            className='form-control form-control-solid b-r-10 '

                                                                                                                            value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.index_file_name}
                                                                                                                            disabled
                                                                                                                        />

                                                                                                                    </Form.Group>
                                                                                                                </div>

                                                                                                                <div className='col-lg-2'>
                                                                                                                    <Form.Group className="mb-3" >

                                                                                                                        <Form.Label className='fw-bold'>Index File Type</Form.Label>
                                                                                                                        <Form.Control
                                                                                                                            // placeholder="Select an Option"
                                                                                                                            disabled
                                                                                                                            className="form-control form-control-solid b-r-10"
                                                                                                                            name="index_file_type"
                                                                                                                            value=
                                                                                                                            {formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.index_file_type == "1" ?
                                                                                                                                "Text" : formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.index_file_type == "2" ?
                                                                                                                                    "XML" : formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.index_file_type == "3" ?
                                                                                                                                        "JSON" : "null"
                                                                                                                            }

                                                                                                                        />

                                                                                                                    </Form.Group>
                                                                                                                </div>
                                                                                                                <div className='col-lg-3'>
                                                                                                                    <Form.Group className="mb-2" >

                                                                                                                        <Form.Label className='fw-bold'>Index File Schema : </Form.Label>

                                                                                                                        <div className='overflow-hidden'>
                                                                                                                            <div>
                                                                                                                                <button className='btn btn-dark' onClick={() => onButtonClick(formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.index_file_schema_url)} type="button"  ><i className='bi bi-download text-white'></i></button>
                                                                                                                            </div>
                                                                                                                            {/* <span className='fw-bold'>{formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.index_file_schema}</span> */}
                                                                                                                        </div>

                                                                                                                    </Form.Group>
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        }
                                                                                                    </div>

                                                                                                    <div className='row border-bottom-dashed pt-3'>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>File Transfer Period</Form.Label>
                                                                                                                <Form.Control

                                                                                                                    className="b-r-10 form-control-solid form-control"
                                                                                                                    classNamePrefix="select"
                                                                                                                    name="file_transfer_period"
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.file_transfer_period == "1" ? "Fixed Time" : "Any Time"}
                                                                                                                    disabled
                                                                                                                />



                                                                                                                {/* <Form.Control.Feedback type="invalid">File Transfer Period</Form.Control.Feedback> */}

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        {lockboxFormik.values?.file_transfer_period == "1" || formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.file_transfer_period == 1 &&
                                                                                                            <div className='col-lg-4'>
                                                                                                                <Form.Group className="mb-3" >

                                                                                                                    <Form.Label className='fw-bold'>Transfer Time (UTC)</Form.Label>

                                                                                                                    <div>
                                                                                                                        <TimePicker
                                                                                                                            className="b-r-10 "
                                                                                                                            onChange={(e) => { handleOnTimeChange({ name: 'transfer_time', value: e }) }}
                                                                                                                            disableClock={true}
                                                                                                                            disabled
                                                                                                                            value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.transfer_time} />
                                                                                                                    </div>

                                                                                                                </Form.Group>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className='row pt-3'>
                                                                                                        <Form.Label className='fw-bold clr-heading'>SFTP Credentials</Form.Label>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>SFTP Server</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    placeholder="Select an Option"

                                                                                                                    className="b-r-10 form-control form-control-solid"
                                                                                                                    classNamePrefix="select"
                                                                                                                    name="sftp_server"
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.sftp_server == "1" ? "Client" : "Our Own"}
                                                                                                                    disabled
                                                                                                                />





                                                                                                                {/* {lockboxFormik.errors?.sftp_server &&
                                                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.sftp_server}</span>
                                                                                                                } */}
                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>Address</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    name='sftp_address'
                                                                                                                    type="text"
                                                                                                                    autoComplete="false"
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.sftp_address}
                                                                                                                    className='b-r-10 form-control form-control-solid'
                                                                                                                    disabled
                                                                                                                />

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>Port</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    name='sftp_port'
                                                                                                                    type="number"
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.sftp_port}
                                                                                                                    disabled
                                                                                                                    className='b-r-10 form-control form-control-solid'
                                                                                                                />

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>Username</Form.Label>
                                                                                                                <Form.Control
                                                                                                                    name='sftp_username'
                                                                                                                    type="text"
                                                                                                                    value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.sftp_username}
                                                                                                                    disabled
                                                                                                                    className='b-r-10 form-control form-control-solid'
                                                                                                                />

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        <div className='col-lg-4'>
                                                                                                            <Form.Group className="mb-3" >

                                                                                                                <Form.Label className='fw-bold'>Password</Form.Label>
                                                                                                                <InputGroup className='input-group-client-password' >
                                                                                                                    <Form.Control
                                                                                                                        name='sftp_password'
                                                                                                                        type={togglePassword ? "text" : "password"}
                                                                                                                        value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.sftp_password}
                                                                                                                        disabled
                                                                                                                        className='  form-control-solid border-radius-password'
                                                                                                                    />
                                                                                                                    <InputGroup.Text className='btn btn-secondary border-radius-eye text-white input-group-text-password-client' onClick={() => setTogglePassword(!togglePassword)} ><i className={togglePassword ? 'bi bi-eye text-whtie' : 'bi bi-eye-slash text-whtie'}></i></InputGroup.Text>
                                                                                                                </InputGroup>

                                                                                                            </Form.Group>
                                                                                                        </div>
                                                                                                        {client_data && client_data?.eob &&
                                                                                                            <div className='col-lg-4'>
                                                                                                                <Form.Group className="mb-3" >

                                                                                                                    <Form.Label className='fw-bold'>Eob Folder</Form.Label>
                                                                                                                    <Form.Control
                                                                                                                        name={`eob_file_path`}
                                                                                                                        type="text"
                                                                                                                        // onBlur={formik?.handleBlur}
                                                                                                                        autoComplete="false"
                                                                                                                        value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.eob_file_path}
                                                                                                                        disabled
                                                                                                                        className='b-r-10 form-control-solid'

                                                                                                                    />

                                                                                                                </Form.Group>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {client_data && client_data?.eob_lite &&
                                                                                                            <div className='col-lg-4'>
                                                                                                                <Form.Group className="mb-3" >

                                                                                                                    <Form.Label className='fw-bold'>Eob Lite Folder</Form.Label>
                                                                                                                    <Form.Control
                                                                                                                        name={`eob_lite_file_path`}
                                                                                                                        type="text"
                                                                                                                        // onBlur={formik?.handleBlur}
                                                                                                                        disabled
                                                                                                                        autoComplete="false"
                                                                                                                        value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.eob_lite_file_path}
                                                                                                                        className='b-r-10 form-control-solid'

                                                                                                                    />

                                                                                                                </Form.Group>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {client_data && client_data?.patient_pay &&
                                                                                                            <div className='col-lg-4'>
                                                                                                                <Form.Group className="mb-3" >

                                                                                                                    <Form.Label className='fw-bold'>Patient Pay File  Path</Form.Label>
                                                                                                                    <Form.Control
                                                                                                                        name={`patient_pay_file_path`}
                                                                                                                        type="text"
                                                                                                                        disabled
                                                                                                                        // onBlur={formik?.handleBlur}
                                                                                                                        autoComplete="false"
                                                                                                                        value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.patient_pay_file_path}
                                                                                                                        className='b-r-10 form-control-solid'

                                                                                                                    />

                                                                                                                </Form.Group>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {client_data && client_data?.correspondence &&
                                                                                                            <div className='col-lg-4'>
                                                                                                                <Form.Group className="mb-3" >

                                                                                                                    <Form.Label className='fw-bold'>Correspondence Folder</Form.Label>
                                                                                                                    <Form.Control
                                                                                                                        name={`correspondence_file_path`}
                                                                                                                        type="text"
                                                                                                                        disabled
                                                                                                                        // onBlur={formik?.handleBlur}
                                                                                                                        autoComplete="false"
                                                                                                                        value={formik?.values?.facilities[index]?.image_file[state.viewlockboxindex]?.correspondence_file_path}
                                                                                                                        className='b-r-10 form-control-solid'

                                                                                                                    />

                                                                                                                </Form.Group>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </div>


                                                                                                </Modal.Body>
                                                                                                <Modal.Footer>
                                                                                                    <Button variant="secondary" onClick={() => { setModalViewOpen(false) }} >
                                                                                                        Close
                                                                                                    </Button>

                                                                                                </Modal.Footer>
                                                                                            </Form>
                                                                                        </Modal>

                                                                                    }
                                                                                    {/* view modal  */}

                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    {/* } */}


                                                                </React.Fragment>
                                                            ))}

                                                        </div>
                                                        <div className='my-4 d-flex flex-row justify-content-start'>
                                                            {checkUserPermission('edit_client') &&
                                                                <button
                                                                    className='btn  btn-success'
                                                                    type="button"
                                                                    onClick={() => { handleModal(index); setFacilityIndex(index); setClickedFacilityIndex(index) }}
                                                                // onClick={() => arrayHelpers.push()}
                                                                >
                                                                    + Add Image File
                                                                </button>
                                                            }
                                                        </div>

                                                        <Modal size='xl' show={modalOpen && clickedFacilityIndex == index} keyboard={true} style={{ zIndex: "999999 !important" }} backdrop="static"
                                                            onHide={handleModal}>
                                                            <Modal.Header closeButton>
                                                                <Modal.Title className='fs-6 fw-bolder clr-heading'>Add New Image File</Modal.Title>
                                                            </Modal.Header>
                                                            <Form
                                                            >
                                                                <Modal.Body>
                                                                    <div className='row border-bottom-dashed mb-3'>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className='mb-3 ' >
                                                                                <Form.Label className='fw-bolder '>Bank Name</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name='bank_name'
                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                    placeholder='Bank Name'
                                                                                    isValid={lockboxFormik?.touched?.bank_name && !!lockboxFormik?.errors?.bank_name}
                                                                                    isInvalid={!!lockboxFormik?.errors?.bank_name}
                                                                                    value={lockboxFormik?.values?.bank_name}

                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                />
                                                                                {lockboxFormik.errors?.bank_name &&
                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.bank_name}</span>
                                                                                }


                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className='mb-3 ' >
                                                                                <Form.Label className='fw-bolder '>Lockbox No</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name='lockbox_no'
                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                    placeholder='Lockbox No'
                                                                                    isValid={lockboxFormik?.touched?.lockbox_no && !!lockboxFormik?.errors?.lockbox_no}
                                                                                    isInvalid={!!lockboxFormik?.errors?.lockbox_no}
                                                                                    value={lockboxFormik?.values?.lockbox_no}

                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                />
                                                                                {lockboxFormik.errors?.lockbox_no &&
                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.lockbox_no}</span>
                                                                                }
                                                                            </Form.Group>
                                                                        </div>


                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className='mb-3 ' >
                                                                                <Form.Label className='fw-bolder '>Default TIN</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name='default_tin'
                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                    placeholder='Default TIN'
                                                                                    isValid={lockboxFormik?.touched?.default_tin && !!lockboxFormik?.errors?.default_tin}
                                                                                    isInvalid={!!lockboxFormik?.errors?.default_tin}
                                                                                    value={lockboxFormik?.values?.default_tin}
                                                                                    maxLength="9"
                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                />
                                                                                {lockboxFormik.errors?.default_tin &&
                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.default_tin}</span>
                                                                                }
                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className='mb-3 ' >
                                                                                <Form.Label className='fw-bolder '>Default NPI</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name='default_npi'
                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                    placeholder='Default NPI'
                                                                                    isValid={lockboxFormik?.touched?.default_npi && !!lockboxFormik?.errors?.default_npi}
                                                                                    isInvalid={!!lockboxFormik?.errors?.default_npi}
                                                                                    value={lockboxFormik?.values?.default_npi}
                                                                                    maxLength="10"
                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                />
                                                                                {lockboxFormik.errors?.default_npi &&
                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.default_npi}</span>
                                                                                }
                                                                            </Form.Group>
                                                                        </div>

                                                                    </div>
                                                                    <div className='row border-bottom-dashed'>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className="mb-3" >

                                                                                <Form.Label className='fw-bold ' >Inbound Image File Formats</Form.Label>

                                                                                <div>
                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Single Page"
                                                                                        name="inbound_image_file_format"
                                                                                        type='radio'
                                                                                        value="single_page"
                                                                                        id="single_page"
                                                                                        // defaultValue={lockboxFormik.values?.inbound_image_file_format == "single_page" ? true : false}
                                                                                        onChange={(e) => handleRadioChange(e)}
                                                                                        //isInvalid={!!lockboxFormik.errors?.single_page}
                                                                                        //feedback={lockboxFormik.errors?.single_page}
                                                                                        feedbackType="invalid"

                                                                                    />

                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="Multiple Page"
                                                                                        name="inbound_image_file_format"
                                                                                        id="multiple_page"
                                                                                        type='radio'
                                                                                        // value={lockboxFormik.values?.inbound_image_file_format == "multiple_page" ? true : false}
                                                                                        value="multiple_page"
                                                                                        onChange={(e) => handleRadioChange(e)}
                                                                                        //isInvalid={!!lockboxFormik.errors?.multiple_page}
                                                                                        //feedback={lockboxFormik.errors?.multiple_page}
                                                                                        feedbackType="invalid"
                                                                                    //isValid={lockboxFormik.errors?.multiple_page}
                                                                                    />


                                                                                    <Form.Check
                                                                                        inline
                                                                                        label="PDF"
                                                                                        id="PDF"
                                                                                        name="inbound_image_file_format"
                                                                                        value="PDF"
                                                                                        type='radio'
                                                                                        onChange={(e) => handleRadioChange(e)}
                                                                                        // value={lockboxFormik.values?.inbound_image_file_format == "PDF" ? true : false}
                                                                                        //isInvalid={!!lockboxFormik.errors?.PDF}
                                                                                        //feedback={lockboxFormik.errors?.PDF}
                                                                                        feedbackType="invalid"
                                                                                    //isValid={lockboxFormik.errors?.PDF}
                                                                                    />
                                                                                </div>
                                                                                {lockboxFormik.errors?.inbound_image_file_format &&
                                                                                    <span className='text-danger'>
                                                                                        {lockboxFormik.errors?.inbound_image_file_format}
                                                                                    </span>
                                                                                }
                                                                            </Form.Group>

                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className='mb-3 ' >
                                                                                <Form.Label className='fw-bolder '>Lockbox Filename Separator</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name='lockbox_file_name_split_key'
                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                    placeholder='Lockbox Filename Separator'
                                                                                    isValid={lockboxFormik?.touched?.lockbox_file_name_split_key && !!lockboxFormik?.errors?.lockbox_file_name_split_key}
                                                                                    isInvalid={!!lockboxFormik?.errors?.lockbox_file_name_split_key}
                                                                                    value={lockboxFormik?.values?.lockbox_file_name_split_key}

                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                />
                                                                                {lockboxFormik.errors?.lockbox_file_name_split_key &&
                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.lockbox_file_name_split_key}</span>
                                                                                }

                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className='mb-3 ' >
                                                                                <Form.Label className='fw-bolder '>Lockbox ID Position</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name='lockbox_number_position'
                                                                                    className='form-control form-control-solid b-r-10 '
                                                                                    placeholder='Lockbox ID Position'
                                                                                    isValid={lockboxFormik?.touched?.lockbox_number_position && !!lockboxFormik?.errors?.lockbox_number_position}
                                                                                    isInvalid={!!lockboxFormik?.errors?.lockbox_number_position}
                                                                                    value={lockboxFormik?.values?.lockbox_number_position}

                                                                                    onChange={lockboxFormik?.handleChange}
                                                                                />
                                                                                {lockboxFormik.errors?.lockbox_number_position &&
                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.lockbox_number_position}</span>
                                                                                }

                                                                            </Form.Group>
                                                                        </div>
                                                                    </div>
                                                                    <div className='row border-bottom-dashed pt-3'>

                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className="mb-3" >

                                                                                <Form.Label className='fw-bold'>Index File Included</Form.Label>

                                                                                <a
                                                                                    label={<i className='bi bi-download ms-2'></i>}
                                                                                    target='_blank'
                                                                                    href="https://xtractapi.aventusinformatics.com/static/output_file_schema/sample_input_file_schema.json"
                                                                                >
                                                                                    <i className='bi bi-download ms-2'></i>

                                                                                </a>

                                                                                <Form.Control name="index_file_included"
                                                                                    isInvalid={!!lockboxFormik.errors?.index_file_included}
                                                                                    isValid={lockboxFormik.touched.index_file_included && !!lockboxFormik?.errors?.index_file_included}
                                                                                    as="select"
                                                                                    value={lockboxFormik.values?.index_file_included}
                                                                                    className='form-control form-control-solid b-r-10'
                                                                                    onChange={lockboxFormik.handleChange}
                                                                                >
                                                                                    <option value="0">Select an option</option>
                                                                                    {indexFileStructureOptions?.map((option, index) => (
                                                                                        <option value={option.value}>{option.label}</option>
                                                                                    ))}
                                                                                </Form.Control>
                                                                                {lockboxFormik.errors?.index_file_included &&
                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.index_file_included}</span>
                                                                                }

                                                                            </Form.Group>
                                                                        </div>

                                                                        {lockboxFormik.values?.index_file_included == "1" &&
                                                                            <React.Fragment>
                                                                                <div className='col-lg-4'>
                                                                                    <Form.Group className='mb-3 ' >
                                                                                        <Form.Label className='fw-bolder '>Index File Name</Form.Label>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            name='index_file_name'
                                                                                            className='form-control form-control-solid b-r-10 '
                                                                                            placeholder='Index File Name'
                                                                                            isValid={lockboxFormik?.touched?.index_file_name && !!lockboxFormik?.errors?.index_file_name}
                                                                                            isInvalid={!!lockboxFormik?.errors?.index_file_name}
                                                                                            value={lockboxFormik?.values?.index_file_name}

                                                                                            onChange={lockboxFormik.handleChange}
                                                                                        />
                                                                                        {lockboxFormik.errors?.index_file_name &&
                                                                                            <span className='text-danger '>{lockboxFormik?.errors?.index_file_name}</span>
                                                                                        }

                                                                                    </Form.Group>
                                                                                </div>

                                                                                <div className='col-lg-4'>
                                                                                    <Form.Group className="mb-3" >

                                                                                        <Form.Label className='fw-bold'>Index File Type</Form.Label>
                                                                                        {/* <Select
                                                                                            placeholder="Select an Option"

                                                                                            className="basic-single"
                                                                                            classNamePrefix="select"
                                                                                            name="index_file_type"
                                                                                            value={lockboxFormik.values?.index_file_type}
                                                                                            isValid={lockboxFormik.touched.index_file_type && !!lockboxFormik?.errors?.index_file_type}
                                                                                            isInvalid={!!lockboxFormik.errors?.index_file_type}
                                                                                            options={indexFileOptions}
                                                                                            onChange={(e) => { handleOnSelectChange({ name: 'index_file_type', value: e }) }}
                                                                                        /> */}
                                                                                        <Form.Control name="index_file_type"
                                                                                            isInvalid={!!lockboxFormik.errors?.index_file_type}
                                                                                            isValid={lockboxFormik.touched.index_file_type && !!lockboxFormik?.errors?.index_file_type}
                                                                                            as="select"
                                                                                            value={lockboxFormik.values?.index_file_type}
                                                                                            className='form-control form-control-solid b-r-10'
                                                                                            onChange={lockboxFormik.handleChange}
                                                                                        >
                                                                                            {/* {indexFileOptions?.map((option, index) => (
                                                                                                <option value={option.value}>{option.label}</option>
                                                                                            ))} */}
                                                                                            <option value="">Select An Option</option>
                                                                                            <option value="1" >Text</option>
                                                                                            <option value="2" >XML</option>
                                                                                            <option value="3"  >JSON</option>
                                                                                            {/* <option value="5" disabled>PDF</option> */}

                                                                                        </Form.Control>
                                                                                        {lockboxFormik.errors?.index_file_type &&
                                                                                            <span className='text-danger '>{lockboxFormik?.errors?.index_file_type}</span>
                                                                                        }
                                                                                    </Form.Group>
                                                                                </div>


                                                                                <div className='col-lg-4'>
                                                                                    <Form.Group className="mb-3" >
                                                                                        <Form.Label className='fw-bold'>Index File Schema</Form.Label>
                                                                                        <Form.Control
                                                                                            name='index_file_schema'
                                                                                            type="file"
                                                                                            // onChange={lockboxFormik.handleChange}
                                                                                            onChange={(e) => handleFileChange(e)}
                                                                                            accept={acceptAttribute}
                                                                                            // accept={lockboxFormik.values?.index_file_included == "1" ? "text/csv" : lockboxFormik.values?.index_file_included == "2" ? "text/plain" : lockboxFormik.values?.index_file_included == "3" ? "application/xml,text/xml,application/xhtml+xml" : lockboxFormik.values?.index_file_included == "4" ? "application/json" : lockboxFormik.values?.index_file_included == "5" && "application/pdf,application/x-pdf"}
                                                                                            autoComplete="false"
                                                                                            isValid={lockboxFormik.touched.index_file_schema && !!lockboxFormik?.errors?.index_file_schema}
                                                                                            isInvalid={!!lockboxFormik?.errors?.index_file_schema}
                                                                                            // value={lockboxFormik?.values?.index_file_schema}
                                                                                            className='b-r-10 form-control-solid form-control browse-file-padd'
                                                                                        />
                                                                                        {lockboxFormik.errors?.index_file_schema &&
                                                                                            <span className='text-danger '>{lockboxFormik?.errors?.index_file_schema}</span>
                                                                                        }

                                                                                    </Form.Group>
                                                                                </div>
                                                                                {/* <div className='col-lg-2'>
                                                                                    <Form.Group className="mb-3" >

                                                                                        <Form.Label className='fw-bold'>Index File Schema : </Form.Label>

                                                                                        <div className='overflow-hidden'>
                                                                                            <div>
                                                                                                <button className='btn btn-primary' onClick={() => onButtonClick(lockboxFormik?.values?.index_file_schema)} type="button"  ><i className='bi bi-download'></i></button>
                                                                                            </div>
                                                                                        </div>

                                                                                    </Form.Group>
                                                                                </div> */}
                                                                            </React.Fragment>
                                                                        }
                                                                    </div>

                                                                    <div className='row border-bottom-dashed pt-3'>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className="mb-3" >

                                                                                <Form.Label className='fw-bold'>File Transfer Period</Form.Label>
                                                                                <Select
                                                                                    placeholder="Select an Option"

                                                                                    className="basic-single"
                                                                                    classNamePrefix="select"
                                                                                    name="file_transfer_period"
                                                                                    defaultValue={lockboxFormik.values?.file_transfer_period}
                                                                                    isValid={lockboxFormik.touched.file_transfer_period && !!lockboxFormik?.errors?.file_transfer_period}
                                                                                    isInvalid={!!lockboxFormik.errors?.file_transfer_period}

                                                                                    options={fileTransferPeriodOptions}
                                                                                    onChange={(e) => { handleOnSelectChange({ name: 'file_transfer_period', value: e }) }}
                                                                                    styles={{
                                                                                        container: (styles, state) => ({
                                                                                            ...styles,
                                                                                            borderRadius: "9px",
                                                                                        }),
                                                                                        control: (styles, state) => ({
                                                                                            ...styles,
                                                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                                            borderStyle: "none", boxShadow: "none",
                                                                                            borderRadius: "10px",
                                                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                                                        }),
                                                                                        indicatorSeparator: styles => ({
                                                                                            ...styles, width: "0px", minHeight: "20px"
                                                                                        }),


                                                                                    }}
                                                                                />
                                                                                {/* <Form.Control.Feedback type="invalid">File Transfer Period</Form.Control.Feedback> */}
                                                                                {lockboxFormik.errors?.file_transfer_period &&
                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.file_transfer_period}</span>
                                                                                }
                                                                            </Form.Group>
                                                                        </div>
                                                                        {lockboxFormik.values?.file_transfer_period == "1" &&
                                                                            <div className='col-lg-4'>
                                                                                <Form.Group className="mb-3" >

                                                                                    <Form.Label className='fw-bold'>Transfer Time (UTC)</Form.Label>

                                                                                    <div>
                                                                                        <TimePicker
                                                                                            className="b-r-10 form-control-solid form-control-sm"
                                                                                            onChange={(e) => { handleOnTimeChange({ name: 'transfer_time', value: e }) }}
                                                                                            disableClock={true}
                                                                                            value={lockboxFormik.values?.transfer_time} />
                                                                                    </div>
                                                                                    {lockboxFormik.errors?.transfer_time &&
                                                                                        <span className='text-danger '>{lockboxFormik?.errors?.transfer_time}</span>
                                                                                    }
                                                                                </Form.Group>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    <div className='row pt-3'>
                                                                        <Form.Label className='fw-bold clr-heading'>SFTP Credentials</Form.Label>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className="mb-3" >

                                                                                <Form.Label className='fw-bold'>SFTP Server</Form.Label>
                                                                                <Select
                                                                                    placeholder="Select an Option"

                                                                                    className="basic-single"
                                                                                    classNamePrefix="select"
                                                                                    name="sftp_server"
                                                                                    defaultValue={lockboxFormik.values?.sftp_server}
                                                                                    isValid={lockboxFormik.touched.sftp_server && !!lockboxFormik?.errors?.sftp_server}
                                                                                    isInvalid={!!lockboxFormik.errors?.sftp_server}

                                                                                    options={sftpServerOptions}
                                                                                    onChange={(e) => { handleOnSelectChange({ name: 'sftp_server', value: e }) }}
                                                                                    styles={{
                                                                                        container: (styles, state) => ({
                                                                                            ...styles,
                                                                                            borderRadius: "9px",
                                                                                        }),
                                                                                        control: (styles, state) => ({
                                                                                            ...styles,
                                                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                                            borderStyle: "none", boxShadow: "none",
                                                                                            borderRadius: "10px",
                                                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                                                        }),
                                                                                        indicatorSeparator: styles => ({
                                                                                            ...styles, width: "0px", minHeight: "20px"
                                                                                        }),


                                                                                    }} />
                                                                                {lockboxFormik.errors?.sftp_server &&
                                                                                    <span className='text-danger '>{lockboxFormik?.errors?.sftp_server}</span>
                                                                                }

                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className="mb-3" >

                                                                                <Form.Label className='fw-bold'>Address</Form.Label>
                                                                                <Form.Control
                                                                                    name='sftp_address'
                                                                                    type="text"
                                                                                    onChange={lockboxFormik.handleChange}
                                                                                    autoComplete="false"
                                                                                    isValid={lockboxFormik.touched.sftp_address && !!lockboxFormik?.errors.sftp_address}
                                                                                    isInvalid={!!lockboxFormik?.errors.sftp_address}
                                                                                    value={lockboxFormik.values?.sftp_address}
                                                                                    className='b-r-10 form-control-solid'
                                                                                    maxLength="255"

                                                                                    placeholder="Enter Address"
                                                                                />
                                                                                <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors.sftp_address}</Form.Control.Feedback>

                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className="mb-3" >

                                                                                <Form.Label className='fw-bold'>Port</Form.Label>
                                                                                <Form.Control
                                                                                    name='sftp_port'
                                                                                    type="number"
                                                                                    onChange={lockboxFormik.handleChange}
                                                                                    autoComplete="false"
                                                                                    isValid={lockboxFormik.touched.sftp_port && !!lockboxFormik?.errors.sftp_port}
                                                                                    isInvalid={!!lockboxFormik?.errors.sftp_port}
                                                                                    value={lockboxFormik.values?.sftp_port}
                                                                                    className='b-r-10 form-control-solid'
                                                                                    maxLength="255"
                                                                                    placeholder="Enter Port"
                                                                                />
                                                                                <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors.sftp_port}</Form.Control.Feedback>

                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className="mb-4" >

                                                                                <Form.Label className='fw-bold'>Username</Form.Label>
                                                                                <Form.Control
                                                                                    name='sftp_username'
                                                                                    type="text"
                                                                                    onChange={lockboxFormik.handleChange}
                                                                                    autoComplete="false"
                                                                                    isValid={lockboxFormik.touched.sftp_username && !!lockboxFormik?.errors.sftp_username}
                                                                                    isInvalid={!!lockboxFormik?.errors.sftp_username}
                                                                                    value={lockboxFormik.values?.sftp_username}
                                                                                    className='b-r-10 form-control-solid'
                                                                                    maxLength="255"

                                                                                    placeholder="Enter Username"
                                                                                />
                                                                                <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors.sftp_username}</Form.Control.Feedback>

                                                                            </Form.Group>
                                                                        </div>
                                                                        <div className='col-lg-4'>
                                                                            <Form.Group className="mb-4" >

                                                                                <Form.Label className='fw-bold'>Password</Form.Label>
                                                                                <InputGroup className='input-group-client-password' >
                                                                                    <Form.Control
                                                                                        name='sftp_password'
                                                                                        type={togglePassword ? "text" : "password"}
                                                                                        onChange={lockboxFormik.handleChange}
                                                                                        autoComplete="false"
                                                                                        isValid={lockboxFormik.touched.sftp_password && !!lockboxFormik?.errors.sftp_password}
                                                                                        isInvalid={!!lockboxFormik?.errors.sftp_password}
                                                                                        value={lockboxFormik.values?.sftp_password}
                                                                                        className='form-control-solid border-radius-password'
                                                                                        maxLength="255"

                                                                                        placeholder="Enter Password"
                                                                                    />
                                                                                    <InputGroup.Text className='btn btn-secondary border-radius-eye text-white input-group-text-password-client' onClick={() => setTogglePassword(!togglePassword)} ><i className={togglePassword ? 'bi bi-eye text-whtie' : 'bi bi-eye-slash text-whtie'}></i></InputGroup.Text>
                                                                                    <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors.sftp_password}</Form.Control.Feedback>
                                                                                </InputGroup>

                                                                            </Form.Group>
                                                                        </div>
                                                                        {client_data && client_data?.eob &&
                                                                            <div className='col-lg-4'>
                                                                                <Form.Group className="mb-4" >

                                                                                    <Form.Label className='fw-bold'>EOB Folder</Form.Label>
                                                                                    <Form.Control
                                                                                        name="eob_file_path"
                                                                                        type="text"
                                                                                        onChange={lockboxFormik?.handleChange}
                                                                                        // onBlur={formik?.handleBlur}
                                                                                        autoComplete="false"
                                                                                        // isValid={lockboxFormik.touched?.eob_file_path && !!lockboxFormik.errors?.eob_file_path}
                                                                                        isInvalid={lockboxFormik?.errors?.eob_file_path && !!lockboxFormik?.errors?.eob_file_path}
                                                                                        value={lockboxFormik.values?.eob_file_path}
                                                                                        className='b-r-10 form-control-solid'
                                                                                        placeholder="Eob Folder"
                                                                                    />
                                                                                    <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors?.eob_file_path && lockboxFormik?.errors?.eob_file_path}</Form.Control.Feedback>

                                                                                </Form.Group>
                                                                            </div>
                                                                        }
                                                                        {client_data && client_data?.eob_lite &&
                                                                            <div className='col-lg-4'>
                                                                                <Form.Group className="mb-3" >

                                                                                    <Form.Label className='fw-bold'>EOB Lite Folder</Form.Label>
                                                                                    <Form.Control
                                                                                        name={`eob_lite_file_path`}
                                                                                        type="text"
                                                                                        onChange={lockboxFormik?.handleChange}
                                                                                        // onBlur={formik?.handleBlur}
                                                                                        autoComplete="false"
                                                                                        // isValid={formik.touched?.facilities && formik.touched?.facilities[index]?.eob_lite_file_path && formik.errors?.facilities && !!formik.errors?.facilities[index]?.facility?.eob_lite_file_path}
                                                                                        isInvalid={lockboxFormik?.errors?.eob_lite_file_path && !!lockboxFormik?.errors?.eob_lite_file_path}
                                                                                        value={lockboxFormik.values?.eob_lite_file_path}
                                                                                        className='b-r-10 form-control-solid'
                                                                                        placeholder="Eob Lite Folder"
                                                                                    />
                                                                                    <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors?.eob_lite_file_path}</Form.Control.Feedback>

                                                                                </Form.Group>
                                                                            </div>
                                                                        }
                                                                        {client_data && client_data?.patient_pay &&
                                                                            <div className='col-lg-4'>
                                                                                <Form.Group className="mb-3" >

                                                                                    <Form.Label className='fw-bold'>Patient Pay Folder</Form.Label>
                                                                                    <Form.Control
                                                                                        name={`patient_pay_file_path`}
                                                                                        type="text"
                                                                                        onChange={lockboxFormik?.handleChange}
                                                                                        // onBlur={formik?.handleBlur}
                                                                                        autoComplete="false"
                                                                                        // isValid={formik.touched?.facilities && formik.touched?.facilities[index].patient_pay_file_path && formik.errors?.facilities && !!formik.errors?.facilities[index].patient_pay_file_path}
                                                                                        isInvalid={lockboxFormik?.errors?.patient_pay_file_path && !!lockboxFormik?.errors?.patient_pay_file_path}
                                                                                        value={lockboxFormik.values?.patient_pay_file_path}
                                                                                        className='b-r-10 form-control-solid'
                                                                                        placeholder="Patient Pay Folder"
                                                                                    />
                                                                                    <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors?.patient_pay_file_path}</Form.Control.Feedback>

                                                                                </Form.Group>
                                                                            </div>
                                                                        }
                                                                        {client_data && client_data?.correspondence &&
                                                                            <div className='col-lg-4'>
                                                                                <Form.Group className="mb-3" >

                                                                                    <Form.Label className='fw-bold'>Correspondence Folder</Form.Label>
                                                                                    <Form.Control
                                                                                        name={`correspondence_file_path`}
                                                                                        type="text"
                                                                                        onChange={lockboxFormik?.handleChange}
                                                                                        // onBlur={formik?.handleBlur}
                                                                                        autoComplete="false"
                                                                                        // isValid={formik.touched?.facilities && formik.touched?.facilities[index].correspondence_file_path && formik.errors?.facilities && !!formik.errors?.facilities[index].correspondence_file_path}
                                                                                        isInvalid={lockboxFormik?.errors?.correspondence_file_path}
                                                                                        value={lockboxFormik.values?.correspondence_file_path}
                                                                                        className='b-r-10 form-control-solid'
                                                                                        placeholder="Correspondence Folder"
                                                                                    />
                                                                                    <Form.Control.Feedback style={{ fontSize: "14.4px" }} type="invalid">{lockboxFormik?.errors?.correspondence_file_path}</Form.Control.Feedback>

                                                                                </Form.Group>
                                                                            </div>
                                                                        }
                                                                    </div>

                                                                </Modal.Body>

                                                                <Modal.Footer>
                                                                    <Button variant="secondary" onClick={() => { handleModal(index) }}>
                                                                        Close
                                                                    </Button>
                                                                    <Button type="button"
                                                                        // onClick={() => raihan.push()}
                                                                        disabled={!lockboxFormik.isValid}
                                                                        onClick={() => { lockboxFormik.handleSubmit() }}
                                                                        variant="success" >
                                                                        Save
                                                                    </Button>
                                                                </Modal.Footer>
                                                            </Form>
                                                        </Modal>



                                                    </React.Fragment>

                                                )}
                                            />

                                        </div>
                                        <div className='my-2 d-flex flex-row justify-content-end'>

                                            <button
                                                className='btn btn-danger'
                                                type="button"
                                                disabled={formik?.values?.facilities.length == "1"}
                                                onClick={() => arrayHelpers.remove(index)}
                                            >
                                                <i className='bi bi-trash'> </i>  Remove
                                            </button>
                                        </div>
                                    </div>

                                ))}


                                <div className='my-2 d-flex flex-row justify-content-start'>
                                    {checkUserPermission('edit_client') &&
                                        <button
                                            className='btn  btn-primary'
                                            disabled={!client_data?.is_facility_available}
                                            type="button"
                                            onClick={() => arrayHelpers.push({
                                                facility: {
                                                    client: localStorage.getItem('client_id'),
                                                    name: "",
                                                    address: "",
                                                    state: "",
                                                    country: "",
                                                    city: "",
                                                    zip_code: "",
                                                },
                                                tin_array: [{
                                                    tin: ""
                                                }],

                                                npi_array: [{
                                                    npi: ""
                                                }],
                                                image_file: []
                                            })}
                                        >
                                            + Add Facility

                                        </button>
                                    }
                                </div>
                            </React.Fragment>

                        )
                        }
                    />
                    <div className='d-flex flex-row justify-content-end'>
                        <div className=''>
                            <button
                                type='button'
                                className='btn btn-secondary btn-active-primary me-2'
                                onClick={() => { changeAllTitleFunction('Client Setup', CLIENT) }}
                            >
                                Cancel
                            </button>
                        </div>
                        {client_data ? (
                            checkUserPermission('edit_client') &&
                            <div className=''>
                                <button
                                    disabled={formik.isSubmitting || !formik.isValid}
                                    type='submit'
                                    className='btn btn-primary btnHover'
                                >
                                    {formik.isSubmitting ? 'Updating' : 'Update'}
                                </button>
                            </div>
                        ) : (
                            <div className=''>
                                <button
                                    disabled={formik.isSubmitting || !formik.isValid}
                                    type='submit'
                                    className='btn btn-primary btnHover'
                                >
                                    {formik.isSubmitting ? 'Saving' : 'Save'}
                                </button>
                            </div>
                        )}
                    </div>
                </Form>
            </FormikProvider>
        </React.Fragment>
    )
}

export default FacilityForm;