import { GET_TIMEZONE_URL } from "../../api";
import axiosInstance from "../../axiosInstance";

export const getAllTimeZone = (id) => onResponse => {
    try {
        axiosInstance.post(GET_TIMEZONE_URL, {
            timezone: id
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};