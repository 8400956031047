import React, { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { FaCircleCheck } from "react-icons/fa6";
import { FiZoomOut, FiZoomIn } from "react-icons/fi";
import { FaArrowRotateLeft } from "react-icons/fa6";
import { FaArrowRotateRight } from "react-icons/fa6";
import { MdDelete } from "react-icons/md";
import { RiDragDropFill } from "react-icons/ri";
import { FaDotCircle, FaSave } from "react-icons/fa";
import { Tooltip } from '@mui/material';
import Header from '../../../Elements/Header';
import styled from "styled-components/macro";
import { combineMultipleBase64Images, getMenuColorDetails, removeCurentElement } from "./functions";
import { getSnippetView, saveSnippet } from "../../../Redux/snippet/action";
import SnippetViewSkeleton from "./Component/SnippetViewSkeleton";
import { reassignSnippet } from "../../../Redux/snippet/action";
import { useFormik } from "formik";
import { Form, Popover, Overlay } from 'react-bootstrap';
import * as Yup from "yup";
import { updateJobUserStartTime, verificationJobUser } from "../../../Redux/client/action";
import NoJobPage from "./Component/NoJobPage";
import ShortCutPage from "./Component/ShortCutPage";
import SweetAlert from 'react-bootstrap-sweetalert';
import FileInfoModal from "./Component/FileInfoModal";
import { IoIosInformationCircle } from "react-icons/io";
import { changeAllTitleFunction, getRedirectTabId } from "../Dashboard/functions";
import { DASHBOARD } from "../../../routeNames";
import { exitGrid } from "../../../Redux/grid/action";
import { Toast, ToastContainer } from 'react-bootstrap';
import leftArrow from "../../../assets/img/arrow-left.svg";
import rightArrow from "../../../assets/img/arrow-right.svg";

const handleRectangleDraw = async (
  startX,
  startY,
  width,
  height,
  rectangleArr,
  setRectangleArr,
  colorState,
  pageId,
  singleClickData,
  resizeDict = { isResize: false, resizeType: null, resizeElement: null },
  jobData = null,
  setActiveClaim,
  activeClaim,
  signleView,
  canvasDetails,
  maskingDetails

) => {
  // Define the canvas and its context
  const { isResize, resizeType, resizeElement } = resizeDict
  if (isResize && resizeElement) {
    const uniqueID = resizeElement.getAttribute("data-unique-id");
    if (uniqueID) {
      const curentKey = uniqueID.split('-').pop();
      colorState = { ...Object.fromEntries(Object.keys(colorState).map(key => [key, false])), [curentKey]: true };
    }
  }
  const { color, className, colorKey } = getMenuColorDetails(colorState, maskingDetails)

  // let c = null
  let rectangleBoxDiv = null

  let style_startX = startX
  let style_startY = startY
  let style_width = width
  let style_height = height

  let canvasConatiner = null
  const canvasWidthHeight = canvasDetails.find((i) => i.id == pageId)
  // if (signleView.isOpen) {

  //   canvasConatiner = document.getElementById("pdfContainer")
  //   // c = document.getElementById(`capturedCanvas`);
  //   rectangleBoxDiv = document.querySelector(".single-canvas-container");

  // } else {
  canvasConatiner = document.getElementById(`${pageId}-rectangle-box`);
  // c = canvasConatiner.querySelector('canvas');
  // c = canvasWidthHeight.canvas;
  rectangleBoxDiv = canvasConatiner

  // style_startX = startX * (c.width / canvasConatiner.getBoundingClientRect().width);
  // style_startY = startY * (c.height / canvasConatiner.getBoundingClientRect().height);
  // style_width = width * (c.width / canvasConatiner.getBoundingClientRect().width);
  // style_height = height * (c.height / canvasConatiner.getBoundingClientRect().height);

  style_startX = startX * (canvasWidthHeight?.imageSize?.width / canvasConatiner.getBoundingClientRect().width);
  style_startY = startY * (canvasWidthHeight.imageSize.height / canvasConatiner.getBoundingClientRect().height);
  style_width = width * (canvasWidthHeight?.imageSize?.width / canvasConatiner.getBoundingClientRect().width);
  style_height = height * (canvasWidthHeight.imageSize.height / canvasConatiner.getBoundingClientRect().height);
  // }

  const isDottedClickAndIsCLaim = singleClickData?.isDottedClick && colorState.isCLaim
  const isClickAbove = singleClickData?.isClickedAbove

  // const c = document.createElement("canvas")
  // const ctx = c.getContext("2d");
  // const img = new Image();
  // img.src = canvasWidthHeight.image;

  // img.onload = () => {
  //   ctx.clearRect(0, 0, c.width, c.height);
  //   ctx.drawImage(img, 0, 0, canvasWidthHeight?.imageSize?.width, canvasWidthHeight.imageSize.height);
  // };

  // console.log(style_startX, style_startY, style_width, style_height)
  // const imgData = ctx?.getImageData(style_startX, style_startY, style_width, style_height);

  // // Create a new canvas to copy the portion
  // const newCanvas = document.createElement("canvas");
  // newCanvas.width = imgData.width;
  // newCanvas.height = imgData.height;
  // const newCtx = newCanvas.getContext("2d");

  // // Draw the copied portion onto the new canvas
  // newCtx.putImageData(imgData, 0, 0);

  // // Log the data URL of the new canvas
  // const dataURL = newCanvas.toDataURL();
  const dataURL = ""
  const curentDeatils = rectangleArr.filter(item => item[colorKey]);


  let unique_id = (curentDeatils.length + 1) + String(`-${colorKey}`)
  let order = 1
  let span_order = 1

  if (colorState.isBlackOut) {
    if (isResize) {
      const editID = resizeElement.getAttribute('data-unique-id')
      setRectangleArr(prevState => prevState.map((item) => item.unique_id == editID ? { ...item, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] } : item))

    } else {
      const maxRandomOrder = Math.max(...curentDeatils?.map(item => item?.order), 0);
      order = maxRandomOrder + 1
      unique_id = `${order}-${colorKey}`
      setRectangleArr(prevState => [...prevState, { order, claim_id: null, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }]);
    }
  }
  else if (maskingDetails.isMasking) {

    if (isResize) {
      const editID = resizeElement.getAttribute('data-unique-id')
      setRectangleArr(prevState => prevState.map((item) => item.unique_id == editID ? { ...item, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] } : item))

    } else {
      const maxRandomOrder = Math.max(...curentDeatils?.map(item => item?.order), 0);
      order = maxRandomOrder + 1
      unique_id = `${order}-${colorKey}`
      setRectangleArr(prevState => [...prevState, { order, claim_id: null, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }]);
    }

  }
  else if (colorState.isCLaim) {
    const claimId_list = jobData?.grid_data?.claims.map((i) => i.id)
    const selected_claim_id_list = curentDeatils.map(i => i.claim_id)
    const diff_array = claimId_list.filter(i => !selected_claim_id_list.includes(i))
    const isClaimCompleted = selected_claim_id_list.includes(activeClaim.curentClaimId)
    const curentIndex = claimId_list.indexOf(activeClaim.curentClaimId)

    const nextIndex = (curentIndex + 1) % claimId_list.length;
    const nextId = claimId_list[nextIndex];

    order = curentIndex + 1

    const claim_id = activeClaim.curentClaimId
    if (isResize) {
      const editID = resizeElement.getAttribute('data-unique-id')
      setRectangleArr(prevState => prevState.map((item) => item.unique_id == editID ? { ...item, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] } : item))

    }
    else if (colorState.isSpan) {

      const span_claimDetails = curentDeatils.filter(item => item.claim_id == claim_id);
      span_order = span_claimDetails?.length + 1
      if (curentDeatils?.some((i) => i?.claim_id == claim_id)) {
        const order_item = curentDeatils?.find((i) => i?.claim_id == claim_id)
        order = order_item?.order
        unique_id = `${order}-${span_order}-${colorKey}`
        setRectangleArr(prevState => [...prevState, { span_order, order: order, claim_id: claim_id, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }]);
      } else {
        unique_id = `${order}-${span_order}-${colorKey}`
        // const maxRandomOrder = Math.max(...curentDeatils?.map(item => item?.order), 0);
        // order = maxRandomOrder + 1
        setRectangleArr(prevState => [...prevState, { span_order, order: order, claim_id: claim_id, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }]);

      }
    }
    else {

      if (isClaimCompleted) {
        const selectedDetails = rectangleArr?.filter(i => i.claim_id == claim_id)
        const removed_data = rectangleArr?.filter(i => i.claim_id != claim_id)
        unique_id = order + String(`-${colorKey}`)
        // order = curentIndex + 1;
        selectedDetails.map((i) => {
          i.unique_id && removeCurentElement(`.rectangle-box-${i.unique_id}.${className}`, canvasConatiner)
        })
        setRectangleArr([...removed_data, { order, claim_id: claim_id, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }])
      } else {
        unique_id = order + String(`-${colorKey}`)
        // rectangleArr.some(i => i.unique_id == unique_id) ?
        //   setRectangleArr(prevState => prevState.map((item) => item.unique_id == unique_id ? { ...item, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] } : item))
        //   : setRectangleArr(prevState => [...prevState, { order, claim_id: claim_id, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }]);
        setRectangleArr(prevState => {
          if (prevState.some(i => i.unique_id == unique_id)) {
            // removeCurentElement(`.rectangle-box-${unique_id}.${className}`, canvasConatiner)
            return prevState.map((item) => item.unique_id == unique_id ? { ...item, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] } : item);
          } else {
            return [...prevState, { order, claim_id: claim_id, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }];
          }
        });
      }
      const nextClaimExist = rectangleArr?.some((i) => i?.claim_id == nextId)
      !nextClaimExist ? setActiveClaim({
        ...activeClaim,
        curentClaimId: nextId,
        previousClaimId: activeClaim.curentClaimId,
        nextClaimId: null,
        isClicked: false,
      }) :
        setActiveClaim({
          ...activeClaim,
          curentClaimId: activeClaim.curentClaimId,
          previousClaimId: activeClaim.curentClaimId,
          nextClaimId: activeClaim.curentClaimId,
          isClicked: false,
        })

    }




  }
  else if ((colorState[colorKey] && curentDeatils?.length > 0) || isResize) {
    if (isResize) {
      const editID = resizeElement.getAttribute('data-unique-id')
      setRectangleArr(prevState => prevState.map((item) => item.unique_id == editID ? { ...item, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] } : item))
    }
    else if (colorState.isSpan) {
      const maxRandomOrder = Math.max(...curentDeatils?.map(item => item?.order), 0);
      order = maxRandomOrder + 1
      setRectangleArr(prevState => [...prevState, { order: order, unique_id: unique_id, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }]);

    } else {
      unique_id = 1 + String(`-${colorKey}`)
      order = 1
      // removeCurentElement(`.rectangle-box-${unique_id}.${className}`, canvasConatiner)
      rectangleArr.map((item => {
        if (item[colorKey]) {
          removeCurentElement(`.rectangle-box-${item.unique_id}.${className}`, canvasConatiner)
        }
      }))
      const newReactArr = rectangleArr.filter(item => !item[colorKey])
      setRectangleArr(
        [...newReactArr, { order, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }]
      );
      // setRectangleArr(prevState => prevState.map(item =>
      //   item[colorKey] ? { ...item, order, unique_id: unique_id, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] } : item
      // ));
    }
  }
  else {
    order = 1
    unique_id = 1 + String(`-${colorKey}`)
    setRectangleArr(prevState => [...prevState, { order, unique_id: unique_id, [colorKey]: true, startX: style_startX, startY: style_startY, width: style_width, height: style_height, image: dataURL, isDottedClickAndIsCLaim, isClickAbove, pageId, mainParentRect: null, span_images: [] }]);
  }

  // =========================START====================
  // Create the currentBox element\
  if ((isResize && resizeElement)) {
    resizeElement.style.left = `${startX}px`;
    resizeElement.style.top = `${startY}px`;
    resizeElement.style.width = `${width}px`;
    resizeElement.style.height = `${height}px`;
    resizeElement.style.position = `absolute`;
    if (!maskingDetails.isMasking && !colorState.isBlackOut) {
      const orderBox = resizeElement.querySelector('.order-item-box')
      if (height < 25) {
        orderBox.style.height = `${height}px`;
        orderBox.style.width = `${height}px`;
      } else {
        orderBox.style.height = `${25}px`;
        orderBox.style.width = `${25}px`;
      }
    }
  } else {

    // removeCurentElement(`.rectangle-box-${unique_id}.${className}`, canvasConatiner)
    const currentBox = document.createElement("div");
    currentBox.className = `rectangle-box-${unique_id} ${className}`;
    currentBox.setAttribute('data-unique-id', unique_id);
    // append order box
    if (!maskingDetails.isMasking && !colorState.isBlackOut) {

      const orderBox = document.createElement("div");
      orderBox.className = `order-item-box`;
      if (height < 25) {
        orderBox.style.width = `${height}px`;
        orderBox.style.height = `${height}px`;
      } else {
        orderBox.style.width = `25px`;
        orderBox.style.height = `25px`;
      }
      if (colorState.isCLaim && colorState.isSpan) {
        if (span_order == 1) {
          currentBox.setAttribute('data-order', `${order}`);
          orderBox.textContent = `${order}`
        } else {
          currentBox.setAttribute('data-order', `${order}.${span_order}`);
          orderBox.textContent = `${order}.${span_order}`
        }
      } else {
        orderBox.textContent = order
        currentBox.setAttribute('data-order', order);
      }

      currentBox.appendChild(orderBox);
    }

    // Create and append the four resize handles
    for (let i = 0; i < 4; i++) {
      const resizeHandle = document.createElement("div");
      resizeHandle.className = "resize-handle";

      // Add specific classes based on the position
      if (i === 0) {
        resizeHandle.classList.add("top-left");
      } else if (i === 1) {
        resizeHandle.classList.add("top-right");
      } else if (i === 2) {
        resizeHandle.classList.add("bottom-left");
      } else if (i === 3) {
        resizeHandle.classList.add("bottom-right");
      }

      currentBox.appendChild(resizeHandle);
    }


    // Set the position and dimensions of the div
    currentBox.style.left = `${startX}px`;
    currentBox.style.top = `${startY}px`;
    currentBox.style.width = `${width}px`;
    currentBox.style.height = `${height}px`;
    currentBox.style.position = `absolute`;

    // Set styles for the rectangle
    if (color !== null) {
      if (maskingDetails.isMasking) {
        currentBox.style.background = `rgb(219 143 143 / 64%)`
      }
      else if (colorState.isBlackOut) {
        currentBox.style.background = color

      }
      else if (colorState.isSpan) {
        currentBox.style.background = `linear-gradient(rgb(101 ,161 ,180,0.5), rgb(101, 161, 180,0.5))`;
      }
      else {
        currentBox.style.background = `linear-gradient(to bottom, ${color})`;
      }

      if (isDottedClickAndIsCLaim) {
        if (isClickAbove === true) {
          currentBox.style.borderTop = "2px groove white";
        } else {
          currentBox.style.borderBottom = "2px groove white";
        }
      }
    }
    // ===========================END============================

    // Append the currentBox to the container

    rectangleBoxDiv.appendChild(currentBox);


  }
  const dottedRectangleBoxDiv = canvasConatiner.querySelector(".rectangle-box");
  dottedRectangleBoxDiv.style.border = "none";


};

export default function SnippetView(props) {
  const [colorState, setColorState] = useState({
    isCheck: false,
    isPayer: false,
    isPayee: false,
    isClaimHeader: false,
    isCLaim: false,
    isFooter: false,
    isBlackOut: false,
    isDraging: false,
    isSpan: false,
  });
  const [activeJob, setActiveJob] = useState(1)
  const [activePage, setActivePage] = useState([])
  const [pdfLoaded, setPdfLoaded] = useState(true)
  const [pdfLoaded2, setPdfLoaded2] = useState(false)
  const [activeClaim, setActiveClaim] = useState({
    curentClaimId: null,
    previousClaimId: null,
    nextClaimId: null,
    isClicked: false,
  })
  const [jobData, setJobData] = useState([])
  const [secondData, setSecondData] = useState([])
  const [rectangleArr, setRectangleArr] = useState([]);
  const [canvasOrginalDetails, setOriginalCanvasDetails] = useState([]);
  const [canvasDetails, setCanvasDetails] = useState([]);
  const [canvasDetails2, setCanvasDetails2] = useState([]);
  const [scale, setScale] = useState(1);
  const [zoomValue, setZoomValue] = useState(30);
  const [singleZoomValue, setSingleZoomValue] = useState(100);
  const [rotation, setRotation] = useState(0);
  const [dragEnabled, setDragEnabled] = useState(false);
  const [signleView, setSingleView] = useState({
    isOpen: false,
    id: null,
    index: null,
    curentCanvas: null,
  });
  const [isDraging, setIsDraging] = useState(false);
  const [baseImage, setBaseImage] = useState(null);
  const [dottedState, setDottedState] = useState({
    isFirstClicked: false,
    topVal: 0,
    dottedElement: null
  });
  const reAssignRef = useRef(null)
  const [assignTooltip, setAssignTooltip] = useState(false)
  const [maskingDetails, setMaskingDetails] = useState({
    isMasking: false,
    color: "",
    isInvert: false,
    order: []
  })
  const [contextMenuVisible, setContextMenuVisible] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  const [loadData, setLoadData] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [snippetLoading, setSnippetLoading] = useState(false);
  const [currentJobVerified, setCurrentJobVerified] = useState(true);
  const [noJob, setNoJOb] = useState(false);
  const [noJob2, setNoJOb2] = useState(false);
  const [showShortCut, setShowShortCut] = useState(false)
  const [errorAlert, setErrorAlert] = useState({
    status: false,
    message: null,
    type: null,
    is_exit: false

  });
  const [infoModal, setInfoModal] = useState(false)
  const [showToster, setShowToster] = useState({
    status: false,
    message: null,
    type: null
  })
  const [singleImageLoader, setSingleImageLoader] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [dragHoverId, setDragHoverId] = useState(null)

  const cleareDottedState = () => {
    const dottedRectangleBoxDiv = document.querySelector(
      ".dotted-stright-line"
    );
    dottedRectangleBoxDiv && dottedRectangleBoxDiv.remove();
    setDottedState({
      isFirstClicked: false,
      topVal: 0,
      dottedElement: null
    })
  }
  const handleSetLatestData = (data) => {
    setZoomValue(30)
    setJobData(data)
    setActiveClaim({
      curentClaimId: data?.grid_data?.claims[0]?.id,
      previousClaimId: null,
      nextClaimId: null,
      isClicked: false,
    })
    setSingleView({
      id: null,
      isOpen: false,
      index: null,
      canvas: null,
    });
    if (data?.grid_data?.transaction_type == "PAYMENT835") {
      const dataOrder = data.client_level_customization?.eob_draggable_menu_order
      setMaskingDetails({
        isMasking: data.client_level_customization?.eob_masking,
        color: data.client_level_customization?.eob_gray_out_color,
        isInvert: data.client_level_customization?.eob_gray_out_invert,
        order: dataOrder
      })
      // if (dataOrder?.length > 0) {
      //   const sortedData = dataOrder?.sort((a, b) => a.id - b.id);

      //   const keyName = getKeyNameId(sortedData[0]?.id)
      //   setColorState((prevState) => {
      //     // If the key is already true, don't update the state
      //     const newState = Object.keys(prevState).reduce((acc, currentStateKey) => {
      //       acc[currentStateKey] = false;
      //       return acc;
      //     }, {});
      //     newState[keyName.name] = true;
      //     return newState;
      //   });
      // }
    }
    else if (data?.grid_data?.transaction_type == "PAYMENT835_LITE") {
      const dataOrder = data.client_level_customization?.eob_lite_draggable_menu_order
      setMaskingDetails({
        isMasking: data.client_level_customization?.eob_lite_masking,
        color: data.client_level_customization?.eob_lite_gray_out_color,
        isInvert: data.client_level_customization?.eob_lite_gray_out_invert,
        order: dataOrder
      })

    }
    else if (data?.grid_data?.transaction_type == "CORRESPONDENCE") {
      const dataOrder = data.client_level_customization?.correspondance_draggable_menu_order
      setMaskingDetails({
        isMasking: data.client_level_customization?.correspondance_masking,
        color: data.client_level_customization?.correspondance_gray_out_color,
        isInvert: data.client_level_customization?.correspondance_gray_out_invert,
        order: dataOrder
      })

    }
    else if (data?.grid_data?.transaction_type == "PATIENT_PAY") {
      const dataOrder = data.client_level_customization?.patient_pay_draggable_menu_order
      setMaskingDetails({
        isMasking: data.client_level_customization?.patient_pay_masking,
        color: data.client_level_customization?.patient_pay_gray_out_color,
        isInvert: data.client_level_customization?.patient_pay_gray_out_invert,
        order: dataOrder
      })

    }
  }
  const handleCallNextJob = (job) => {
    verificationJobUser(3, job)((response) => {
      if (response.data == false) {
        // setErrorAlert({
        //     status: true,
        //     is_recently_loaded_job: true,
        //     message: 'You have no permission on the recently loaded job',
        //     type: '',
        // });
      }
      else {
        updateJobUserStartTime(3, job)((response) => {
          setCurrentJobVerified(true)
          setSaveSuccess(false)
          getSnippetView({ queue: true, ignore_jobs: [] })((response) => {
            setSecondData(response.data)
          })
        })
      }
    })
  }

  useEffect(() => {
    getSnippetView({ ignore_jobs: [], queue: false })((response) => {
      if (response.status) {
        const data = response.data
        handleSetLatestData(data)
        setPdfLoaded(true)
        loadPdfImages(data.file_url, setCanvasDetails, setPdfLoaded)
        getSnippetView({ ignore_jobs: [], queue: true })((response) => {
          if (response && response?.status_code == 400 && response?.errors == "No job remaining") {
            setNoJOb2(true)
          } else if (response && response?.response && response?.response?.data?.status_code == 500) {
            alert("ERROR")
          } else {
            setSecondData(response.data)
            loadPdfImages(response.data.file_url, setCanvasDetails2, setPdfLoaded2)
          }

        })

      } else if (response && response?.status_code == 400 && response?.errors == "No job remaining") {
        setNoJOb(true)
        setSaveSuccess(true)
      }
    })
  }, [])
  // const removeSelectedRectangleElement = () => {
  //   // Select all elements with the specified classes
  //   const checkElements = document.querySelectorAll('.rectangle-check-container');
  //   const payerElements = document.querySelectorAll('.rectangle-payer-container');
  //   const payeeElements = document.querySelectorAll('.rectangle-payee-container');
  //   const claimHeaderElements = document.querySelectorAll('.rectangle-claim-header-container');
  //   const claimElements = document.querySelectorAll('.rectangle-claim-container');
  //   const footerElements = document.querySelectorAll('.rectangle-footer-container');
  //   const blackOutElements = document.querySelectorAll('.rectangle-blackout-container');

  //   setTimeout(() => {
  //     // Loop through each NodeList and remove each element
  //     checkElements?.forEach(element => element.remove());
  //     payerElements?.forEach(element => element.remove());
  //     payeeElements?.forEach(element => element.remove());
  //     claimHeaderElements?.forEach(element => element.remove());
  //     claimElements?.forEach(element => element.remove());
  //     footerElements?.forEach(element => element.remove());
  //     blackOutElements?.forEach(element => element.remove());
  //   }, 0)
  // };
  const nextWorkAllocation = (jobId, saveJobId) => {
    if (jobId) {
      verificationJobUser(3, jobId)((response) => {
        if (response.data == false) {
          ({
            status: true,
            message: 'You have no permission on the recently loaded job',
            type: '',
            is_exit: false

          });
        }
        else {
          updateJobUserStartTime(3, jobId)((response) => {
            setCurrentJobVerified(true)
            setSaveSuccess(false)
            getSnippetView({ queue: true, ignore_jobs: [jobId, saveJobId] })((response) => {
              if (response && response?.status_code == 400 && response?.errors == "No job remaining") {
                setNoJOb2(true)
              } else if (response && response?.response && response?.response?.data?.status_code == 500) {
                alert("ERROR")
              } else {
                setSecondData(response.data)
                if (activeJob == 1) {
                  loadPdfImages(response.data.file_url, setCanvasDetails2, setPdfLoaded2)
                } else {
                  loadPdfImages(response.data.file_url, setCanvasDetails2, setPdfLoaded)
                }
              }
            })
          })
        }
      })
    }
  }
  useEffect(() => {
    if (noJob2 == true) {
      setNoJOb(true)
    }
    else if (loadData && secondData.job != jobData?.job && canvasDetails2 != null) {
      if (activeJob === 1) {
        nextWorkAllocation(secondData?.job, jobData?.job)
        setActivePage([])
        setRectangleArr([])
        setActiveJob(2)
        handleSetLatestData(secondData)
        setCanvasDetails(canvasDetails2)
        setOriginalCanvasDetails([])
        setCanvasDetails2(null)
        setLoadData(false)
        setCurrentJobVerified(false)
        setAssignTooltip(false)
        setColorState({
          isCheck: false,
          isPayer: false,
          isPayee: false,
          isClaimHeader: false,
          isCLaim: false,
          isFooter: false,
          isBlackOut: false,
          isDraging: false,
          isSpan: false,
        })
      } else if (activeJob === 2) {
        nextWorkAllocation(secondData?.job, jobData?.job)
        setActivePage([])
        setRectangleArr([])
        setActiveJob(1)
        handleSetLatestData(secondData)
        setCanvasDetails(canvasDetails2)
        setOriginalCanvasDetails([])
        setCanvasDetails2(null)
        setLoadData(false)
        setCurrentJobVerified(false)
        setAssignTooltip(false)
        setColorState({
          isCheck: false,
          isPayer: false,
          isPayee: false,
          isClaimHeader: false,
          isCLaim: false,
          isFooter: false,
          isBlackOut: false,
          isDraging: false,
          isSpan: false,
        })
      }
    }
  }, [loadData, secondData, canvasDetails2])

  // useEffect(() => {
  //   if (!loadData && saveSuccess && secondData.job == jobData?.job) {
  //     if (jobData?.job) {
  //       verificationJobUser(3, jobData?.job)((response) => {
  //         if (response.data == false) {
  //           ({
  //             status: true,
  //             message: 'You have no permission on the recently loaded job',
  //             type: '',
  //             is_exit: false

  //           });
  //         }
  //         else {
  //           updateJobUserStartTime(3, jobData?.job)((response) => {
  //             setCurrentJobVerified(true)
  //             setSaveSuccess(false)
  //             getSnippetView({ queue: true })((response) => {
  //               if (response && response?.status_code == 400 && response?.errors == "No job remaining") {
  //                 setNoJOb2(true)
  //               } else if (response && response?.response && response?.response?.data?.status_code == 500) {
  //                 alert("ERROR")
  //               } else {
  //                 setSecondData(response.data)
  //                 if (activeJob == 1) {
  //                   loadPdfImages(response.data.file_url, setCanvasDetails2, setPdfLoaded2)
  //                 } else {
  //                   loadPdfImages(response.data.file_url, setCanvasDetails2, setPdfLoaded)
  //                 }
  //               }
  //             })
  //           })
  //         }
  //       })
  //     }
  //   }
  // }, [saveSuccess, jobData?.job, secondData?.job, loadData])

  const loadPdfImages = (file_url, argState, setLoading) => {
    let curentFileUrl = file_url
    // if (window.location.origin === "http://localhost:3000") {
    //   curentFileUrl = testDocument
    // }
    // if (file_url) {
    //   function convertPDFtoImages() {

    //     return pdfjsLib.getDocument(file_url).promise.then((pdf) => {
    //       const promises = [];

    //       for (let i = 1; i <= pdf.numPages; i++) {
    //         promises.push(
    //           pdf.getPage(i).then((page) => {
    //             const viewport = page.getViewport({ scale: 0.7 });
    //             const canvas = document.createElement("canvas");
    //             canvas.id = `capturedCanvas-${i}`;
    //             canvas.setAttribute("data-id", i);
    //             canvas.height = viewport.height;
    //             canvas.width = viewport.width;
    //             const context = canvas.getContext("2d");

    //             const renderContext = {
    //               canvasContext: context,
    //               viewport: viewport,
    //             };

    //             return page.render(renderContext).promise.then(() => {
    //               const dataURL = canvas.toDataURL();
    //               return {
    //                 rotate: 0,
    //                 id: i,
    //                 canvas,
    //                 image: dataURL,
    //                 imageSize: {
    //                   width: canvas.width,
    //                   height: canvas.height,
    //                 },
    //               };
    //             });
    //           })
    //         );
    //       }

    //       return Promise.all(promises);
    //     });
    //   }

    //   convertPDFtoImages()
    //     .then((canvasDetailsArray) => {
    //       argState(canvasDetailsArray);
    //       setLoading(false)
    //     })
    //     .catch((error) => {
    //       console.error("Error during PDF conversion:", error);
    //     });
    // }
    if (curentFileUrl) {
      function convertPDFtoImages() {

        return pdfjsLib.getDocument(curentFileUrl).promise.then((pdf) => {
          const promises = [];

          // Calculate scaling factor based on window dimensions and PDF dimensions
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
          const fixedScale = 2.0; // Adjust this value based on your requirements
          // for (let i = 1; i <= pdf.numPages; i++) {
          //   promises.push(
          //     pdf.getPage(i).then((page) => {
          //       // Get the bounding box of the content on the page
          //       const contentBox = page.getViewport({ scale: 2.0 }).viewBox;

          //       // Calculate scaling factors for width and height
          //       const widthScale = windowWidth / contentBox[2];
          //       const heightScale = windowHeight / contentBox[3];

          //       // Choose the minimum scaling factor to fit the content within the window
          //       const scale = Math.min(widthScale, heightScale);

          //       // Apply the new scale and adjust the viewport based on the content box
          //       const viewport = page.getViewport({ scale });
          //       viewport.width = contentBox[2] * scale;
          //       viewport.height = contentBox[3] * scale;

          //       const canvas = document.createElement("canvas");
          //       canvas.id = `capturedCanvas-${i}`;
          //       canvas.setAttribute("data-id", i);
          //       canvas.height = viewport.height;
          //       canvas.width = viewport.width;
          //       const context = canvas.getContext("2d");

          //       const renderContext = {
          //         canvasContext: context,
          //         viewport: viewport,
          //       };

          //       return page.render(renderContext).promise.then(() => {
          //         const dataURL = canvas.toDataURL();
          //         return {
          //           rotate: 0,
          //           id: i,
          //           canvas,
          //           image: dataURL,
          //           imageSize: {
          //             width: viewport.width,
          //             height: viewport.height,
          //           },
          //         };
          //       });
          //     })
          //   );
          // }


          // for (let i = 1; i <= pdf.numPages; i++) {
          //   promises.push(
          //     pdf.getPage(i).then((page) => {
          //       const viewport = page.getViewport({ scale: fixedScale });

          //       const canvas = document.createElement("canvas");
          //       canvas.id = `capturedCanvas-${i}`;
          //       canvas.setAttribute("data-id", i);
          //       canvas.height = viewport.height;
          //       canvas.width = viewport.width;
          //       const context = canvas.getContext("2d");

          //       const renderContext = {
          //         canvasContext: context,
          //         viewport: viewport,
          //       };

          //       return page.render(renderContext).promise.then(() => {
          //         const dataURL = canvas.toDataURL();
          //         if (i == 2) {

          //         }
          //         return {
          //           rotate: 0,
          //           id: i,
          //           canvas,
          //           image: dataURL,
          //           imageSize: {
          //             width: viewport.width,
          //             height: viewport.height,
          //           },
          //         };
          //       });
          //     })
          //   );
          // }

          for (let i = 1; i <= pdf.numPages; i++) {
            promises.push(
              pdf.getPage(i).then((page) => {
                const viewport = page.getViewport({ scale: fixedScale });

                const canvas = document.createElement("canvas");
                canvas.id = `capturedCanvas-${i}`;
                canvas.setAttribute("data-id", i);
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const context = canvas.getContext("2d");

                const renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                };

                return page.render(renderContext).promise.then(() => {
                  return new Promise((resolve) => {
                    canvas.toBlob((blob) => {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const base64data = reader.result;
                        let isPageSizeSmall = false;
                        const cal_width = viewport.width / 2
                        // if ((cal_width + 100) >= viewport.height) {
                        if (viewport.width >= (viewport.height + 200)) {
                          isPageSizeSmall = true
                        }
                        resolve({
                          page_size: isPageSizeSmall,
                          rotate: 0,
                          id: i,
                          canvas,
                          image: base64data,
                          original_image: base64data,
                          imageSize: {
                            width: viewport.width,
                            height: viewport.height,
                          },
                        });
                      };
                      reader.readAsDataURL(blob);
                    }, 'image/png'); // You can specify the image format here, e.g., 'image/jpeg'
                  });
                });
              })
            );
          }
          return Promise.all(promises);
        });
      }

      convertPDFtoImages()
        .then((canvasDetailsArray) => {
          argState(canvasDetailsArray);
          setLoading(false)
        })
        .catch((error) => {
          console.error("Error during PDF conversion:", error);
        });
    }

  }

  // useEffect(() => {
  //   if (jobData.file_url) {
  //     function convertPDFtoImages() {
  //       setPdfLoaded(false)
  //       return pdfjsLib.getDocument(jobData.file_url).promise.then((pdf) => {
  //         const promises = [];

  //         for (let i = 1; i <= pdf.numPages; i++) {
  //           promises.push(
  //             pdf.getPage(i).then((page) => {
  //               const viewport = page.getViewport({ scale: 0.7 });
  //               const canvas = document.createElement("canvas");
  //               canvas.id = `capturedCanvas-${i}`;
  //               canvas.setAttribute("data-id", i);
  //               canvas.height = viewport.height;
  //               canvas.width = viewport.width;
  //               const context = canvas.getContext("2d");

  //               const renderContext = {
  //                 canvasContext: context,
  //                 viewport: viewport,
  //               };

  //               return page.render(renderContext).promise.then(() => {
  //                 const dataURL = canvas.toDataURL();
  //                 return {
  //                   rotate: 0,
  //                   id: i,
  //                   canvas,
  //                   image: dataURL,
  //                   imageSize: {
  //                     width: canvas.width,
  //                     height: canvas.height,
  //                   },
  //                 };
  //               });
  //             })
  //           );
  //         }

  //         return Promise.all(promises);
  //       });
  //     }

  //     convertPDFtoImages()
  //       .then((canvasDetailsArray) => {
  //         setCanvasDetails(canvasDetailsArray);
  //         setPdfLoaded(true)
  //       })
  //       .catch((error) => {
  //       });
  //   }
  // }, [jobData]);
  // Run only once on component mount
  // useEffect(() => {
  //   if (jobData.file_url) {
  //     function convertPDFtoImages() {
  //       setPdfLoaded(false);
  //       return pdfjsLib.getDocument(jobData.file_url).promise.then((pdf) => {
  //         const promises = [];

  //         // Calculate scaling factor based on window dimensions and PDF dimensions
  //         const windowWidth = window.innerWidth;
  //         const windowHeight = window.innerHeight;

  //         for (let i = 1; i <= pdf.numPages; i++) {
  //           promises.push(
  //             pdf.getPage(i).then((page) => {
  //               // Get the bounding box of the content on the page
  //               const contentBox = page.getViewport({ scale: 1.0 }).viewBox;

  //               // Calculate scaling factors for width and height
  //               const widthScale = windowWidth / contentBox[2];
  //               const heightScale = windowHeight / contentBox[3];

  //               // Choose the minimum scaling factor to fit the content within the window
  //               const scale = Math.min(widthScale, heightScale);

  //               // Apply the new scale and adjust the viewport based on the content box
  //               const viewport = page.getViewport({ scale });
  //               viewport.width = contentBox[2] * scale;
  //               viewport.height = contentBox[3] * scale;

  //               const canvas = document.createElement("canvas");
  //               canvas.id = `capturedCanvas-${i}`;
  //               canvas.setAttribute("data-id", i);
  //               canvas.height = viewport.height;
  //               canvas.width = viewport.width;
  //               const context = canvas.getContext("2d");

  //               const renderContext = {
  //                 canvasContext: context,
  //                 viewport: viewport,
  //               };

  //               return page.render(renderContext).promise.then(() => {
  //                 const dataURL = canvas.toDataURL();
  //                 return {
  //                   rotate: 0,
  //                   id: i,
  //                   canvas,
  //                   image: dataURL,
  //                   imageSize: {
  //                     width: viewport.width,
  //                     height: viewport.height,
  //                   },
  //                 };
  //               });
  //             })
  //           );
  //         }

  //         return Promise.all(promises);
  //       });
  //     }

  //     convertPDFtoImages()
  //       .then((canvasDetailsArray) => {
  //         setCanvasDetails(canvasDetailsArray);
  //         setPdfLoaded(true);
  //       })
  //       .catch((error) => {
  //         console.error("Error during PDF conversion:", error);
  //       });
  //   }
  // }, [jobData]);



  const handleZoomIn = useCallback(() => {
    // setScale((prevScale) => prevScale * 1.2);
    if (!isDraging) {
      if (signleView.isOpen) {
        setSingleZoomValue((prevScale) => prevScale > 10 ? prevScale - 10 : 10);
      } else {
        setZoomValue((prevScale) => prevScale > 10 ? prevScale - 10 : 10);
      }
    }
  }, [zoomValue, singleZoomValue, signleView, isDraging]);

  const handleZoomOut = useCallback(() => {
    // setScale((prevScale) => prevScale / 1.2);
    if (!isDraging) {
      if (signleView.isOpen) {
        if (singleZoomValue < 100) {
          setSingleZoomValue((prevScale) => prevScale + 10);
        }
      } else {
        if (zoomValue < 100) {
          setZoomValue((prevScale) => prevScale + 10);
        }
      }
    }

  }, [zoomValue, singleZoomValue, signleView, isDraging]);
  // function rotateBase64Image90deg(items, type) {
  //   let rotateValue = (items.rotate - 90) % 360;
  //   if (type == "right") {
  //     rotateValue = (items.rotate + 90) % 360
  //   }

  //   // Normalize negative values
  //   if (rotateValue < 0) {
  //     rotateValue += 360;
  //   }

  //   // create an off-screen canvas
  //   var offScreenCanvas = document.createElement('canvas');
  //   let offScreenCanvasCtx = offScreenCanvas.getContext('2d');

  //   // create Image
  //   var img = new Image();
  //   img.src = items.original_image;

  //   // set its dimension to rotated size
  //   if (Math.abs(rotateValue) === 90 || Math.abs(rotateValue) === 270) {
  //     offScreenCanvas.width = img.height;
  //     offScreenCanvas.height = img.width;
  //   } else {
  //     offScreenCanvas.width = img.width;
  //     offScreenCanvas.height = img.height;
  //   }
  //   console.log(offScreenCanvas, rotateValue, img, "****************************************offScreenCanvas***********************************")

  //   // rotate and draw source image into the off-screen canvas:
  //   if (Math.abs(rotateValue) === 90) {
  //     offScreenCanvasCtx.rotate(90 * Math.PI / 180);
  //     offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
  //   } else if (Math.abs(rotateValue) === -90 || rotateValue === 270) {
  //     offScreenCanvasCtx.rotate(-90 * Math.PI / 180);
  //     offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
  //   } else if (Math.abs(rotateValue) === 180) {
  //     offScreenCanvasCtx.rotate(180 * Math.PI / 180);
  //     offScreenCanvasCtx.translate(-offScreenCanvas.width, -offScreenCanvas.height);
  //   }

  //   // Draw the image onto the off-screen canvas
  //   offScreenCanvasCtx.drawImage(img, 0, 0);

  //   offScreenCanvas.setAttribute("data-id", items.id);

  //   // encode image to data-uri with base64
  //   const imageSize = {
  //     width: offScreenCanvas.width,
  //     height: offScreenCanvas.height,
  //   }

  //   return { ...items, image: offScreenCanvas.toDataURL("image/png", 1.0), canvas: offScreenCanvas, rotate: rotateValue, imageSize };
  // }

  function rotateBase64Image90deg(items, type) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.id = `capturedCanvas-${items.id}`;
        canvas.setAttribute("data-id", items.id);

        let rotateValue = (items.rotate - 90) % 360;
        if (type == "right") {
          rotateValue = (items.rotate + 90) % 360;
        }

        if (rotateValue < 0) {
          rotateValue += 360;
        }

        let width, height;
        if (Math.abs(rotateValue) === 90 || Math.abs(rotateValue) === 270) {
          width = img.height;
          height = img.width;
        } else {
          width = img.width;
          height = img.height;
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');

        ctx.translate(width / 2, height / 2);
        ctx.rotate(rotateValue * Math.PI / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        const rotatedImageData = {
          ...items,
          image: canvas.toDataURL("image/png", 1.0),
          canvas: canvas,
          rotate: rotateValue,
          imageSize: {
            width: canvas.width,
            height: canvas.height
          }
        };

        resolve(rotatedImageData);
      };

      img.src = items.original_image;
      img.onerror = (error) => reject(error);
    });
  }


  const handleRotate = async (type) => {
    if (activePage.length > 0) {
      cleareDottedState()
      setColorState({
        isCheck: false,
        isPayer: false,
        isPayee: false,
        isClaimHeader: false,
        isCLaim: false,
        isFooter: false,
        isBlackOut: false,
        isDraging: false,
        isSpan: false,
      })
      setSingleImageLoader(true);
      const copyCanvasDetail = [...canvasDetails];
      try {
        const updatedCanvas = await Promise.all(copyCanvasDetail.map(async (canvasItem) => {
          if (activePage?.some((i) => i == canvasItem?.id)) {
            const rotatedImageData = await rotateBase64Image90deg(canvasItem, type);
            return rotatedImageData;
          } else {
            return canvasItem;
          }
        }));
        setCanvasDetails(updatedCanvas);
      } catch (error) {
        console.error("Error rotating images:", error);
        // Handle error as needed, e.g., show error message to the user
      } finally {
        setSingleImageLoader(false);
      }
    }
  };

  const moveCanvas1 = (dragIndex, hoverIndex) => {
    const newCanvasDetails = [...canvasDetails];
    const draggedCanvas = newCanvasDetails[dragIndex];

    newCanvasDetails.splice(dragIndex, 1);
    newCanvasDetails.splice(hoverIndex, 0, draggedCanvas);

    setCanvasDetails(newCanvasDetails);
  };

  const moveCanvas = (dragIds, hoverId) => {
    const newCanvasDetails = [...canvasDetails];
    let list = newCanvasDetails
    if (canvasOrginalDetails.length == 0) {
      setOriginalCanvasDetails([...canvasDetails])
    } else {
      list = canvasOrginalDetails
    }
    const listDragIds = newCanvasDetails.filter(i => activePage.includes(String(i.id)))
    const finalData = newCanvasDetails.filter(i => !activePage.includes(String(i.id)))
    let hoverIndex = finalData.findIndex(obj => obj.id == hoverId)
    let listDragIndex = newCanvasDetails.findIndex(obj => obj.id == listDragIds[0]?.id)
    let listIds = listDragIds.map(obj => String(obj.id))
    if (!listIds.includes(hoverId)) {
      if (hoverIndex >= listDragIndex) {
        hoverIndex = hoverIndex + 1
      }
      finalData.splice(hoverIndex, 0, ...listDragIds);
      setCanvasDetails(finalData);
    }
    // Loop through each drag index
    // listDragIds.forEach(item => {
    //   const dragIndex = newCanvasDetails.findIndex(obj => obj.id == item.id)
    //   const draggedCanvas = newCanvasDetails[dragIndex];

    //   // Remove the dragged canvas from its original position
    //   newCanvasDetails.splice(dragIndex, 1);

    //   // Insert the dragged canvas at the hover index
    //   newCanvasDetails.splice(hoverIndex, 0, draggedCanvas);
    //   // Adjust hover index for the next insertion
    //   // hoverIndex++;
    // });

  };
  const hoverCanvas = (dragIds, hoverId) => {
    const newCanvasDetails = [...canvasDetails];
    let list = newCanvasDetails
    if (canvasOrginalDetails.length == 0) {
      setOriginalCanvasDetails([...canvasDetails])
    } else {
      list = canvasOrginalDetails
    }
    const listDragIds = newCanvasDetails.filter(i => activePage.includes(String(i.id)))
    const finalData = newCanvasDetails.filter(i => !activePage.includes(String(i.id)))
    let hoverIndex = finalData.findIndex(obj => obj.id == hoverId)
    let listDragIndex = newCanvasDetails.findIndex(obj => obj.id == listDragIds[0]?.id)
    let listIds = listDragIds.map(obj => String(obj.id))
    const id = finalData.find((i, ind) => ind == hoverIndex)?.id
    const hoverContainer = document.getElementById(`${Number(id)}-rectangle-box`)
    console.log(hoverContainer, finalData, hoverIndex, hoverId)
    const width = hoverContainer?.getBoundingClientRect()?.width
    const hoverDragContainer = hoverContainer?.querySelector('.hover-drag-line')
    if (!listIds.includes(hoverId)) {
      if (hoverIndex >= listDragIndex) {
        const rightIcon = hoverDragContainer?.querySelector('.right-arrow')
        const leftIcon = hoverDragContainer?.querySelector('.left-arrow')
        rightIcon.style.display = 'block'
        leftIcon.style.display = 'none'
        hoverDragContainer.style.left = `${width}px`
        hoverDragContainer.style.right = 'unset'

        console.log(hoverContainer, width, "right")
      } else {
        const leftIcon = hoverDragContainer?.querySelector('.left-arrow')
        const rightIcon = hoverDragContainer?.querySelector('.right-arrow')
        leftIcon.style.display = 'block'
        rightIcon.style.display = 'none'
        hoverDragContainer.style.right = `${width}px`
        hoverDragContainer.style.left = 'unset'


        console.log(hoverContainer, width, "left")
      }
    }
  };
  const clearHoverSideLine = () => {
    canvasDetails.forEach((item) => {
      const hoverContainer = document.getElementById(`${item.id}-rectangle-box`)
      const hoverDragContainer = hoverContainer?.querySelector('.hover-drag-line')
      const leftIcon = hoverDragContainer?.querySelector('.left-arrow')
      const rightIcon = hoverDragContainer?.querySelector('.right-arrow')
      leftIcon.style.display = 'none'
      rightIcon.style.display = 'none'
    })
  }
  const handleDeleteSingleBox = (event) => {
    // const deletedElement = event?.target?.closest(`.${"common-rectangle-box"}`);
    const deletedElement = event?.target;
    // const deletedElement = event?.target;
    const uniqueID = deletedElement?.getAttribute("data-unique-id")
    if (uniqueID) {

      const itemsArr = uniqueID && uniqueID?.split('-')
      const curentKey = itemsArr[itemsArr.length - 1];


      if (maskingDetails.isMasking || curentKey == "isBlackOut") {
        setRectangleArr(rectangleArr.filter((i) => i.unique_id != uniqueID))
        deletedElement.remove()

      } else {
        if (curentKey != 'isCLaim') {
          const deletedList = rectangleArr.filter((i) => i[curentKey])
          deletedList.forEach((item) => {
            removeCurentElement(`.rectangle-box-${item.unique_id}`)
          })
          setRectangleArr(rectangleArr.filter((i) => !i[curentKey]))

        }

        else {
          cleareDottedState()
          const claimID = rectangleArr.find((i) => i.unique_id == uniqueID)?.claim_id
          const deletedList = rectangleArr.filter((i) => i.claim_id == claimID)
          deletedList.forEach((item) => {
            removeCurentElement(`.rectangle-box-${item.unique_id}`)
          })
          setRectangleArr(prevState => prevState.filter((i) => i.claim_id != claimID))
          setActiveClaim({
            curentClaimId: claimID,
            previousClaimId: claimID,
            nextClaimId: claimID,
            isClicked: false,
          })
        }

      }
      setColorState((prevState) => {
        const newState = Object.keys(prevState).reduce((acc, currentStateKey) => {
          acc[currentStateKey] = false;
          return acc;
        }, {});
        newState[maskingDetails.isMasking || curentKey == "isBlackOut" ? "isBlackOut" : curentKey] = true;
        return newState;
      });
    }
  }

  const getKeyNameId = (keyName) => {
    if (typeof keyName === "number") {
      switch (keyName) {
        case 1:
          return { id: 1, name: "isCheck" }
        case 2:
          return { id: 2, name: "isPayer" }
        case 3:
          return { id: 3, name: "isPayee" }
        case 4:
          return { id: 4, name: "isClaimHeader" }
        case 5:
          return { id: 5, name: "isCLaim" }
        case 6:
          return { id: 6, name: "isFooter" }
        case 7:
          return { id: 6, name: "isBlackOut" }
        case 8:
          return { id: 8, name: "isDraging" }
      }
    }
    else {
      switch (keyName) {
        case "isCheck":
          return { id: 1, name: "isCheck" }
        case "isPayer":
          return { id: 2, name: "isPayer" }
        case "isPayee":
          return { id: 3, name: "isPayee" }
        case "isClaimHeader":
          return { id: 4, name: "isClaimHeader" }
        case "isCLaim":
          return { id: 5, name: "isCLaim" }
        case "isFooter":
          return { id: 6, name: "isFooter" }
        case "isBlackOut":
          return { id: 7, name: "isBlackOut" }
        case "isDraging":
          return { id: 8, name: "isDraging" }
      }
    }
  }
  const setSideMenuActive = useCallback((arg) => {
    const keyItem = getKeyNameId(arg);
    const keyName = keyItem.name
    const isActive = maskingDetails?.order.length > 0 && maskingDetails?.order?.some((i) => i.id == keyItem.id) || keyName == "isSpan" || keyName == "isBlackOut" || keyName == "isDraging"
    setContextMenuVisible(false)
    if (isActive) {
      // setSideMenuActive(false)
      if (keyName !== "isClaim") {
        setDottedState({
          isFirstClicked: false,
          topVal: 0,
          dottedElement: null
        });
      }

      setColorState((prevState) => {
        // If the key is already true, don't update the state
        // if (prevState[keyName]) {
        //   return prevState;
        // }
        const newState = Object.keys(prevState).reduce((acc, currentStateKey) => {
          acc[currentStateKey] = false;
          return acc;
        }, {});
        newState[keyName] = !colorState[keyName];
        return newState;
      });
    }
  }, [setColorState, maskingDetails, colorState]);

  const handleContextMenu = (event) => {
    event.preventDefault();

    const x = event.clientX;
    const y = event.clientY;

    setContextMenuPosition({ x, y });
    setContextMenuVisible(true);
  };

  const SidebarItem = ({ order, color, Icon, name, stateKey, hasTrueValueExceptSpanBlackout }) => (
    <SideMenuItem
      disabled={maskingDetails?.order?.length > 0 && name == "Span" ? true : jobData.grid_data.claims.length == 0 && name == "Claim" ? false : maskingDetails?.order?.length > 0 && maskingDetails?.order?.some((i) => i.id == getKeyNameId(stateKey)?.id)}
      onClick={() => {
        if (name !== "Span" && name != "Claim") {
          setSideMenuActive(stateKey)
        }
        else if (jobData.grid_data.claims.length > 0 && name == "Claim") {
          setSideMenuActive(stateKey)
          jobData?.grid_data?.claims?.length > 0 && setActiveClaim({
            curentClaimId: jobData?.grid_data?.claims[0]?.id,
            previousClaimId: null,
            nextClaimId: null,
            isClicked: false,
          })
        }
        else if (maskingDetails?.order?.length > 0 && name === "Span" && hasTrueValueExceptSpanBlackout) {
          if (colorState.isSpan) {
            if (rectangleArr?.some(i => i.isCLaim) && jobData.grid_data.claims.length > 1 && activeClaim?.curentClaimId == jobData.grid_data.claims[0]?.id) {
              setActiveClaim({ ...activeClaim, isClicked: false, curentClaimId: jobData.grid_data.claims[1]?.id, previousClaimId: jobData.grid_data.claims[0]?.id })
            } else {
              setActiveClaim({ ...activeClaim, isClicked: false, curentClaimId: activeClaim.nextClaimId ? activeClaim.nextClaimId : jobData.grid_data.claims[0]?.id, previousClaimId: activeClaim.curentClaimId })
            }
          } else {
            cleareDottedState()
            setActiveClaim({ ...activeClaim, isClicked: false, curentClaimId: activeClaim.previousClaimId ? activeClaim.previousClaimId : jobData.grid_data.claims[0]?.id, nextClaimId: activeClaim.curentClaimId })
          }
          setColorState({ ...colorState, isSpan: !colorState.isSpan })
          setContextMenuVisible(false)
        }
        // else {
        //   setContextMenuVisible(false)
        // }
      }}
      // onContextMenu={(event) => {
      //   name !== "Span" && colorState[stateKey] && handleContextMenu(event)
      // }}
      isActive={colorState[stateKey]} // Pass isActive prop to determine whether to apply hover styles
      color={color}
      isSelected={rectangleArr?.some((i) => i[stateKey])}
      className="btn-hove"
    >
      <div
        style={{
          marginRight: "10px",
        }}
      >
        {/* {name === "Span" ?
          Icon && <Icon style={{ color: color }} />
          :
        } */}
        <span>{order}</span>
      </div>
      <div>{name}</div>
      {rectangleArr?.some((i) => i[stateKey]) &&
        <DeleteContainer onClick={(e) => {
          e.stopPropagation()
          const deletedList = rectangleArr.filter((i) => i[stateKey])
          deletedList.forEach((item) => {
            removeCurentElement(`.rectangle-box-${item.unique_id}`)
          })
          setRectangleArr(rectangleArr.filter((i) => !i[stateKey]))
          cleareDottedState()
          setActiveClaim({
            curentClaimId: jobData?.grid_data?.claims[0]?.id,
            previousClaimId: null,
            nextClaimId: null,
            isClicked: false,
          })
          setColorState((prevState) => {
            const newState = Object.keys(prevState).reduce((acc, currentStateKey) => {
              acc[currentStateKey] = false;
              return acc;
            }, {});
            newState[stateKey] = true;
            return newState;
          });
        }} height={colorState[stateKey] && contextMenuVisible ? "100px" : "0px"}>
          <div className="delete-container">
            <button>
              <MdDelete />
            </button>
          </div>
        </DeleteContainer>
      }
    </SideMenuItem>
  );

  const SingleClaimComponent = ({ id, index, item, isActive }) => (
    <ClaimSingleDivBox id={id} onClick={() => {
      if (!colorState.isCLaim) {
        setSideMenuActive("isCLaim")
      }
      // activeClaim.curentClaimId != item?.id &&
      setActiveClaim({
        curentClaimId: item?.id,
        previousClaimId: item?.id,
        nextClaimId: item?.id,
        isClicked: true,
      })

    }} isActive={isActive}>
      <div
      >
        {item.claim_number ? item.claim_number : "-----"}
      </div>
      <div style={{ paddingRight: '25px' }}>{`(${item.page_from ? item.page_from : ""} - ${item.page_to ?? ""})`}</div>
      {/* <DeleteClaimContainer onClick={(e) => {
        e.stopPropagation()
      }} isActive={isActive}>
        <div className="delete-container">
          <button>
            <MdDelete />
          </button>
        </div>
      </DeleteClaimContainer> */}
    </ClaimSingleDivBox>
  );

  const getActiveElementId = (prevId, type) => {
    const listPages = document.querySelectorAll(".common-list-rectangle-box");
    const currentIndex = Array.from(listPages).findIndex(item => item.getAttribute("data-id") === prevId);

    if (currentIndex !== -1) {
      let nextIndex = -1;

      if (type === "next") {
        nextIndex = (currentIndex + 1) % listPages.length; // Circular next
      } else {
        nextIndex = (currentIndex - 1 + listPages.length) % listPages.length; // Circular previous
      }

      const nextElement = listPages[nextIndex];
      const nextElementId = nextElement.getAttribute("data-id");
      return nextElementId;
    } else {
      return null;
    }
  };

  // Example usage
  const lastId = getActiveElementId("6", "next"); // Moving from the last to the first
  const firstId = getActiveElementId("1", "previous"); // Moving from the first to the last
  window.onkeyup = function (event) {
    const keyCode = event.keyCode;
    if (keyCode === 27) {
      setTimeout(() => {
        setSingleZoomValue(100)
        cleareDottedState()
        setSingleView({
          id: null,
          isOpen: false,
          index: null,
          canvas: null,
        });
      }, 1)

    }
  };
  window.onkeydown = function (event) {
    const keyCode = event.keyCode;
    if (!assignTooltip && (keyCode === 49 || keyCode === 97)) {
      setSideMenuActive("isCheck");
    } else if (!assignTooltip && (keyCode === 50 || keyCode === 98)) {
      setSideMenuActive("isPayer");
    } else if (!assignTooltip && (keyCode === 51 || keyCode === 99)) {
      setSideMenuActive("isPayee");
    } else if (!assignTooltip && (keyCode === 52 || keyCode === 100)) {
      setSideMenuActive("isClaimHeader");
    } else if (!assignTooltip && (keyCode === 53 || keyCode === 101)) {
      if (colorState.isCLaim) { setActiveClaim({ isClicked: false, curentClaimId: null, previousClaimId: null, nextClaimId: null }) }
      else {
        setActiveClaim({ ...activeClaim, isClicked: false, curentClaimId: jobData.grid_data.claims[0]?.id, previousClaimId: null })
      }

      setSideMenuActive("isCLaim");
    } else if (!assignTooltip && (keyCode === 54 || keyCode === 102)) {
      setSideMenuActive("isFooter");
    } else if (!assignTooltip && (keyCode === 55 || keyCode === 103)) {
      if (maskingDetails?.order?.length > 0 && hasTrueValueExceptSpanBlackout) {
        if (colorState.isSpan) {
          if (rectangleArr?.some(i => i.isCLaim) && jobData.grid_data.claims.length > 1 && activeClaim?.curentClaimId == jobData.grid_data.claims[0]?.id) {
            setActiveClaim({ ...activeClaim, isClicked: false, curentClaimId: jobData.grid_data.claims[1]?.id, previousClaimId: jobData.grid_data.claims[0]?.id })
          } else {
            setActiveClaim({ ...activeClaim, isClicked: false, curentClaimId: activeClaim.nextClaimId ? activeClaim.nextClaimId : jobData.grid_data.claims[0]?.id, previousClaimId: activeClaim.curentClaimId })
          }
        } else {
          cleareDottedState()
          setActiveClaim({ ...activeClaim, isClicked: false, curentClaimId: activeClaim.previousClaimId ? activeClaim.previousClaimId : jobData.grid_data.claims[0]?.id, nextClaimId: activeClaim.curentClaimId })
        }
        setColorState({ ...colorState, isSpan: !colorState.isSpan })
      }

    } else if (!assignTooltip && (keyCode === 56 || keyCode === 104)) {
      setSideMenuActive("isBlackOut");
    } else if (keyCode === 39) {
      setTimeout(() => {
        setSingleView({
          id: null,
          isOpen: false,
          index: null,
          canvas: null,
        });
      }, 50)

      setTimeout(() => {
        cleareDottedState()
        const id = getActiveElementId(signleView.id, "next")
        addInitialDataToCanvas(id)
        document.getElementById(`${id}-rectangle-box-shortcut`).click()
      }, 100)
      // RIGHT
    }
    else if (keyCode === 37) {
      // LEFT
      setTimeout(() => {
        setSingleView({
          id: null,
          isOpen: false,
          index: null,
          canvas: null,
        });
      }, 50)

      setTimeout(() => {
        cleareDottedState()
        const id = getActiveElementId(signleView.id, "prev")
        addInitialDataToCanvas(id)
        document.getElementById(`${id}-rectangle-box-shortcut`).click()
      }, 100)
    }
    else if (event.altKey && keyCode === 72) {
      setShowShortCut(true)
    }
    else if (event.key == 'Tab' && event.ctrlKey && event.shiftKey) {
      parent.document.getElementById('id-ctrl-tab-shift-switch').click()
    }
    else if (event.key == 'Tab' && event.ctrlKey) {
      parent.document.getElementById('id-ctrl-tab-switch').click()
    }
    else if (event.altKey && keyCode == 78) {
      parent.document.getElementById('bottom-tab-add').click()
    }
    else if (event.altKey && keyCode == 88) {
      parent.document.getElementById('id-ctrl-x-remove-tab').click()
    }
    else if (event.ctrlKey && (event.key === "+" || event.key === "-" || event.key === "=")) {
      event.preventDefault(); // Prevent the default browser behavior
      cleareDottedState()
      event.key === "-" ? handleZoomIn() : handleZoomOut()
    }
    else if (event.ctrlKey && (keyCode === 48 || keyCode === 96)) {
      event.preventDefault(); // Prevent the default browser behavior
    }
    else if (event.altKey && keyCode === 83) {
      if (!(!currentJobVerified || snippetLoading)) {
        handleSaveSnippet(false)
      }
    }
    else if (keyCode == 9 && (errorAlert.status == "reset" || errorAlert.status == "save" || errorAlert.status == true)) {
      event.preventDefault();
      // Get the currently focused element
      const currentlyFocusedElement = document.activeElement;

      // Check if the currently focused element is one of the buttons
      if (currentlyFocusedElement.classList.contains('btn')) {
        // If currently focused on the "No" button
        if (currentlyFocusedElement.textContent.trim().toLowerCase() === 'no') {
          const yesButton = document.querySelector('.btn-primary');
          if (yesButton) {
            yesButton.focus();
          }
        } else if (currentlyFocusedElement.textContent.trim().toLowerCase() === 'yes') {
          // If currently focused on the "Yes" button
          const noButton = document.querySelector('.btn-light');
          if (noButton) {
            noButton.focus();
          }
        }
      }
    }
  };

  document.getElementById('root').addEventListener('wheel', event => {
    if (event.ctrlKey) {
      event.preventDefault()
    }
  }, true)

  const addInitialDataToCanvas = (isSingleView = false, isDragedItem = false) => {

    let copyRectangleArr = [...rectangleArr]
    const curentColorState = {
      isCheck: true,
      isPayer: false,
      isPayee: false,
      isClaimHeader: false,
      isCLaim: false,
      isFooter: false,
      isDraging: false,
      isBlackOut: false,
      isSpan: false,
    }
    if (isSingleView) {
      copyRectangleArr = copyRectangleArr.filter(i => i.pageId == signleView.id)
    }
    copyRectangleArr.map((item) => {
      const { startX, startY, height, width, isDottedClickAndIsCLaim, isClickAbove, pageId, unique_id } = item
      const parentBoxDiv = document.getElementById(`${pageId}-rectangle-box`);
      const imageElement = parentBoxDiv.querySelector('img')
      // if (signleView.id == pageId && parentBoxDiv) {

      const canvasWidthHeight = canvasDetails.find((i) => i.id == pageId)
      const parentRect = parentBoxDiv?.getBoundingClientRect();
      // const mainParentRect = parentBoxDiv.querySelector("canvas");

      // const mainParentRect = mainParentBoxDiv.getBoundingClientRect();

      const newState = Object.keys(curentColorState).reduce((acc, currentState) => {
        if (item[currentState]) {
          acc[currentState] = item[currentState];
        } else {
          acc[currentState] = false;
        }
        return acc;
      }, {})
      const { color, className, colorKey } = getMenuColorDetails(newState, maskingDetails)
      let currentBox = document.querySelector(`.rectangle-box-${unique_id}.${className}`)
      if (currentBox == null) {


        currentBox = document.createElement("div");
        currentBox.className = `rectangle-box-${unique_id} ${className} common-rectangle-box`;
        currentBox.setAttribute('data-unique-id', unique_id);
        // Create and append the four resize handles
        for (let i = 0; i < 4; i++) {
          const resizeHandle = document.createElement("div");
          resizeHandle.className = "resize-handle";

          // Add specific classes based on the position
          if (i === 0) {
            resizeHandle.classList.add("top-left");
          } else if (i === 1) {
            resizeHandle.classList.add("top-right");
          } else if (i === 2) {
            resizeHandle.classList.add("bottom-left");
          } else if (i === 3) {
            resizeHandle.classList.add("bottom-right");
          }

          currentBox.appendChild(resizeHandle);
        }


        const childLeft = startX * (parentRect.width / canvasWidthHeight?.imageSize.width);
        const childTop = startY * (parentRect.height / canvasWidthHeight?.imageSize.height);
        const childWidth = width * (parentRect.width / canvasWidthHeight?.imageSize.width);
        const childHeight = height * (parentRect.height / canvasWidthHeight?.imageSize.height);


        if (!maskingDetails.isMasking && !item?.isBlackOut) {

          const orderBox = document.createElement("div");
          orderBox.className = `order-item-box`;
          // const boxWidth = 20 * (parentRect.width / mainParentRect.width);
          // const boxHeight = 20 * (parentRect.height / mainParentRect.height);
          if (childHeight < 20) {
            orderBox.style.width = `${childHeight}px`
            orderBox.style.height = `${childHeight}px`
          } else {
            orderBox.style.width = "20px"
            orderBox.style.height = "20px"
          }
          if (item.isCLaim && item?.span_order) {
            if (item?.span_order == 1) {
              currentBox.setAttribute('data-order', `${item.order}`);
              orderBox.textContent = `${item.order}`
            } else {
              currentBox.setAttribute('data-order', `${item.order}.${item.span_order}`);
              orderBox.textContent = `${item.order}.${item.span_order}`
            }
          } else {
            orderBox.textContent = item?.order ?? ""
          }
          currentBox.appendChild(orderBox);
        }


        // const childLeft = startX * (parentRect.width / mainParentRect.width);
        // const childTop = startY * (parentRect.height / mainParentRect.height);
        // const childWidth = width * (parentRect.width / mainParentRect.width);
        // const childHeight = height * (parentRect.height / mainParentRect.height);

        // Set the position and dimensions of the div
        currentBox.style.left = `${childLeft}px`;
        currentBox.style.top = `${childTop}px`;
        currentBox.style.width = `${childWidth}px`;
        currentBox.style.height = `${childHeight}px`;
        currentBox.style.position = `absolute`;
        // Set styles for the rectangle
        const hasTrueValueColorState = Object.keys(colorState).find(key => {
          return colorState[key] && key !== 'isBlackOut' && key !== 'isDraging' && key !== 'isSpan';
        });
        if (color !== null) {
          if (item.isBlackOut) {
            currentBox.style.background = color

          }
          else if (maskingDetails.isMasking) {
            currentBox.style.background = `rgb(219 143 143 / 64%)`
          }
          else if (colorState.isCLaim) {
            if (item.claim_id == activeClaim.curentClaimId) {
              currentBox.classList.add('span-container');
              if (colorState.isSpan) {
                currentBox.style.background = 'linear-gradient(rgb(101 ,161 ,180,0.5), rgb(101, 161, 180,0.5))'
              } else {
                currentBox.style.background = 'linear-gradient(rgb(207 229 22 / 31%), rgb(207 229 22 / 31%))'
              }
            }
            else {
              currentBox.classList.remove('span-container');
              currentBox.style.background = `linear-gradient(to bottom, ${color})`


            }
            // })


          }
          else if (item[hasTrueValueColorState] && colorState.isSpan) {
            currentBox.classList.add('span-container');
            currentBox.style.background = 'linear-gradient(rgb(101 ,161 ,180,0.5), rgb(101, 161, 180,0.5))'

          }
          else {
            currentBox.style.background = `linear-gradient(to bottom, ${color})`;
          }
          if (isDottedClickAndIsCLaim) {
            if (isClickAbove === true) {
              currentBox.style.borderTop = "2px groove white";
            } else {
              currentBox.style.borderBottom = "2px groove white";
            }
          }

        }
        parentBoxDiv.appendChild(currentBox);

      } else {
        const childLeft = startX * (parentRect.width / canvasWidthHeight?.imageSize.width);
        const childTop = startY * (parentRect.height / canvasWidthHeight?.imageSize.height);
        const childWidth = width * (parentRect.width / canvasWidthHeight?.imageSize.width);
        const childHeight = height * (parentRect.height / canvasWidthHeight?.imageSize.height);
        // Set the position and dimensions of the div
        currentBox.style.left = `${childLeft}px`;
        currentBox.style.top = `${childTop}px`;
        currentBox.style.width = `${childWidth}px`;
        currentBox.style.height = `${childHeight}px`;
        currentBox.style.position = `absolute`;

        if (isDragedItem) {
          parentBoxDiv.appendChild(currentBox);
        }

      }
      // Append the currentBox to the container
      // }
      // }
    })
  };
  const addDottedStyleForList = (id, element) => {
    const canvasConatiner = document.getElementById(`${id}-rectangle-box`);
    canvasConatiner.appendChild(element);

  }
  useEffect(() => {
    setTimeout(() => {
      rectangleArr.length > 0 && addInitialDataToCanvas(signleView.isOpen)
    }, 2)
  }, [zoomValue, singleZoomValue, signleView.isOpen])

  useEffect(() => {
    if (signleView.isOpen) {
      setScrollPosition(signleView.id)
      setTimeout(() => {
        const element = document.getElementById(`${signleView.id}-rectangle-box`)
        if (element) {
          element.scrollIntoView(true)
        }
      }, 0)
    }
    else if (signleView.isOpen == false && scrollPosition) {
      setTimeout(() => {
        const element = document.getElementById(`${scrollPosition}-rectangle-box`)
        if (element) {
          element.scrollIntoView(true)
          setScrollPosition(0)
        }
      }, 0)
    }
  }, [signleView])

  useEffect(() => {

    setTimeout(() => {
      const spanClassItem = document.querySelectorAll('.span-container')
      const IsSpan = colorState.isSpan
      if (colorState.isCLaim) {
        const { color, className, colorKey } = getMenuColorDetails(colorState, maskingDetails)
        // const groupedClaims = rectangleArr.filter((i) => i.isCLaim)
        const groupedClaims = [...rectangleArr]
        groupedClaims.forEach(claim_item => {

          const elements = document.querySelector(`[data-unique-id="${claim_item?.unique_id}"]`)
          if (claim_item.isCLaim && claim_item.claim_id == activeClaim.curentClaimId) {
            if (elements) {
              elements.classList.add('span-container');
              if (IsSpan) {

                elements.style.background = 'linear-gradient(rgb(101 ,161 ,180,0.5), rgb(101, 161, 180,0.5))'
              } else {
                elements.style.background = 'linear-gradient(rgb(207 229 22 / 31%), rgb(207 229 22 / 31%))'

              }
            }
          }
          else {
            if (elements) {
              let copyColorState = { ...colorState }
              const uniqueID = claim_item?.unique_id
              if (uniqueID) {
                const curentKey = uniqueID.split('-').pop();
                copyColorState = { ...Object.fromEntries(Object.keys(copyColorState).map(key => [key, false])), [curentKey]: true };
              }
              const { color, className, colorKey } = getMenuColorDetails(copyColorState, maskingDetails)

              elements.classList.remove('span-container');
              elements.style.background = `linear-gradient(to bottom, ${color})`

            }
          }
        })


      } else if (IsSpan) {
        const { color, className, colorKey } = getMenuColorDetails(colorState, maskingDetails)
        let listBoxes = document.querySelectorAll(`.${className}`)
        listBoxes.length > 0 && Array.from(listBoxes)?.forEach((item) => {
          item.classList.add('span-container');
          item.style.background = 'linear-gradient(rgb(101 ,161 ,180,0.5), rgb(101, 161, 180,0.5))'

        })
      } else if (spanClassItem.length > 0) {
        Array.from(spanClassItem)?.forEach((item) => {
          let copyColorState = { ...colorState }
          const uniqueID = item.getAttribute("data-unique-id");
          if (uniqueID) {
            const curentKey = uniqueID.split('-').pop();
            copyColorState = { ...Object.fromEntries(Object.keys(copyColorState).map(key => [key, false])), [curentKey]: true };
          }
          const { color, className, colorKey } = getMenuColorDetails(copyColorState, maskingDetails)
          item.classList.remove('span-container');
          item.style.background = `linear-gradient(to bottom, ${color})`
        })
      }
    }, 0)


  }, [rectangleArr, colorState, activeClaim])

  useEffect(() => {
    if (activeClaim.curentClaimId && !skelitonLoader) {
      const element = document.getElementById(`single-claim-${activeClaim.curentClaimId}`);
      const claimData = rectangleArr.find((i) => i.isCLaim && i.claim_id == activeClaim.curentClaimId)
      const claimElement = document.querySelector(`.rectangle-box-${claimData?.unique_id}`)
      if (claimElement && colorState.isCLaim) {
        setTimeout(() => {
          claimElement.scrollIntoView({
            behavior: 'auto', // or 'smooth' for smooth scrolling
            block: 'center', // Scroll to the center of the viewport
            inline: 'center' // Horizontally center the element
          });
        }, 0)
      }
      if (element && colorState.isCLaim) {
        element.scrollIntoView({
          behavior: 'auto', // or 'smooth' for smooth scrolling
          block: 'center', // Scroll to the center of the viewport
          inline: 'center' // Horizontally center the element
        });
      }
    }
  }, [activeClaim, skelitonLoader]);

  const selectedClaimData = rectangleArr.filter((i) => i.isCLaim)
  const handleClearAllData = () => {
    setZoomValue(30)
    cleareDottedState()
    jobData?.grid_data?.claims.length > 0 && setActiveClaim({
      curentClaimId: jobData?.grid_data?.claims[0]?.id,
      previousClaimId: null,
      nextClaimId: null,
      isClicked: false,
    })
    rectangleArr.forEach((item) => {
      removeCurentElement(`.rectangle-box-${item.unique_id}`)
    })
    setRectangleArr([])

    setColorState({
      isCheck: false,
      isPayer: false,
      isPayee: false,
      isClaimHeader: false,
      isCLaim: false,
      isFooter: false,
      isBlackOut: false,
      isDraging: false,
      isSpan: false,
    })
  }
  const handleReset = (title, type = null, rotate = null) => {
    setErrorAlert({
      status: "reset",
      message: title,
      type: type,
      rotate: rotate

    })
    // Swal.fire({
    //   backdrop: true,
    //   allowOutsideClick: false,
    //   title: type == "reset" ? title : "Are you sure?",
    //   text: type != "reset" ? title : null,
    //   icon: "warning",
    //   showConfirmButton: true,
    //   confirmButtonText: "Yes",
    //   confirmButtonColor: "#6ba2b6",
    //   denyButtonColor: "#575757",
    //   showDenyButton: true,

    //   className: {
    //     popup: 'my-custom-popup',
    //   },
    // }).then((response) => {
    //   if (response.isConfirmed) {
    //     handleClearAllData()
    //     if (type == "rotate") {
    //       setTimeout(() => {
    //         handleRotate(rotate)
    //       }, 0)
    //     } else if (type == "drag") {
    //       setSideMenuActive("isDraging")
    //     } else {
    //       setCanvasDetails(prevState => prevState.map(item => ({ ...item, image: item.original_image, rotate: 0 })));
    //     }
    //   } else {

    //   }
    // })
  }

  const handleReassignSnippet = () => {
    formReassign.validateForm()
    setAssignTooltip(true)
  }

  function base64ImageToImageCanvas(image, imageSize) {
    const { width, height } = imageSize
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');


        ctx.drawImage(img, 0, 0);
        const rotatedImageData = {
          c: canvas,
          ctx
        };
        resolve(rotatedImageData);
      };

      img.src = image;
      img.onerror = (error) => reject(error);
    });
  }
  const handleBlackoutPage = async () => {
    const copyRectangleArr = [...rectangleArr]
    const finalArr = []

    await Promise.all(canvasDetails.map(async (item) => {
      const { rotate, imageSize, canvas, image, id } = item;
      const blackOutedData = copyRectangleArr.filter(i => i.pageId == id && i.isBlackOut)
      const perPageData = copyRectangleArr.filter(i => i.pageId == id && !i.isBlackOut)

      const { c, ctx } = await base64ImageToImageCanvas(image, imageSize);

      blackOutedData.map((blacked_item) => {
        ctx.fillRect(blacked_item.startX, blacked_item.startY, blacked_item.width, blacked_item.height);
      });

      await Promise.all(perPageData.map(async (page_item) => {
        const imgData = ctx?.getImageData(page_item.startX, page_item.startY, page_item.width, page_item.height);
        const newCanvas = document.createElement("canvas");
        newCanvas.width = imgData.width;
        newCanvas.height = imgData.height;
        const newCtx = newCanvas.getContext("2d");

        newCtx.putImageData(imgData, 0, 0);

        const dataURL = newCanvas.toDataURL();
        page_item.image = dataURL;
        finalArr.push(page_item);
      }));
    }));
    return finalArr;
  }

  const saveFunctionSnippet = async (is_save_Exit = false) => {
    const RedirectId = getRedirectTabId()

    canvasDetails2 == null && setPdfLoaded(true)
    setSnippetLoading(true)
    if (!is_save_Exit) {
      setLoadData(true)

    }
    // const imageList = document.getElementById("main-canavs-container").querySelectorAll("img")
    const finalMaskedCanvasList = []
    const isInvert = maskingDetails.isInvert

    let data = {}
    if (maskingDetails.isMasking) {
      const copyRectangleArr = [...rectangleArr]
      await Promise.all(canvasDetails.map(async (item) => {
        const pageList = rectangleArr.filter((i) => i.pageId == item.id);
        const canvasContainer = document.getElementById(`${item.id}-rectangle-box`);
        const img = canvasContainer.querySelector("img")

        const { rotate, imageSize, canvas, image, id } = item;
        const { c, ctx } = await base64ImageToImageCanvas(image, imageSize);

        // var c = canvas;
        // const ctx = c.getContext("2d");
        // ctx.drawImage(img, 0, 0);

        // make_base();

        // function make_base() {
        //   let base_image = new Image();
        //   base_image.src = image;
        //   base_image.onload = function () {
        //     ctx.drawImage(base_image, 0, 0);
        //   }
        // }

        let ncanvas = null
        let nctx = null
        if (!isInvert) {
          ctx.fillStyle = maskingDetails.color;
        } else {

          ncanvas = document.createElement('canvas');
          ncanvas.width = canvas.width
          ncanvas.height = canvas.height
          nctx = ncanvas.getContext("2d");
        }

        // Draw rectangles
        let isBlackoutExist = false;
        if (pageList?.some((j) => j.pageId == id)) {
          isBlackoutExist = true
          await Promise.all(pageList.map(async (i) => {
            const { startX, startY, width, height } = i;
            if (isInvert) {
              // Create a temporary canvas for the current portion
              const tempCanvas = document.createElement("canvas");
              tempCanvas.width = width;
              tempCanvas.height = height;
              const tempCtx = tempCanvas.getContext("2d");
              // Draw the marked portion on the temporary canvas
              tempCtx.drawImage(img, -startX, -startY, canvas.width, canvas.height);
              // Draw the temporary canvas on the main canvas at the specified position and size
              nctx.drawImage(tempCanvas, startX, startY, width, height);
            } else {
              ctx.fillRect(startX, startY, width, height);
            }
          }));
        }
        const dataURL = !isInvert ? c.toDataURL() : ncanvas.toDataURL(); // Converts the canvas content to a data URL
        finalMaskedCanvasList.push(dataURL);
      }));
      data = { is_masking: true, job: jobData?.job, transaction_type: jobData?.grid_data?.transaction_type, snipping_check: {}, payer: {}, payee: {}, claim_header: {}, claims: [], footer: {}, masked_images: finalMaskedCanvasList };
    } else {
      const finalData = await handleBlackoutPage()
      const selectedCheck = finalData.find(i => i.isCheck) || {};
      const selectedPayer = finalData.find(i => i.isPayer) || {};
      const selectedPayee = finalData.find(i => i.isPayee) || {};
      const selectedClaimHeader = finalData.find(i => i.isClaimHeader) || {};
      const selectedFooter = finalData.find(i => i.isFooter) || {};

      const getCombinedBase64 = async (imageArr) => {
        return await combineMultipleBase64Images(imageArr?.length > 0 ? imageArr : []);
      };

      // const checkCombinedBase64 = await getCombinedBase64(finalData?.filter(i => i.isCheck && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean));
      // const payerCombinedBase64 = await getCombinedBase64(finalData?.filter(i => i.isPayer && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean));
      // const payeeCombinedBase64 = await getCombinedBase64(finalData?.filter(i => i.isPayee && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean));
      // const claimHeaserCombinedBase64 = await getCombinedBase64(finalData?.filter(i => i.isClaimHeader && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean));
      // const footerCombinedBase64 = await getCombinedBase64(finalData?.filter(i => i.isFooter && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean));

      // selectedCheck.image = checkCombinedBase64 == "data:," ? "" : checkCombinedBase64;
      // selectedPayer.image = payerCombinedBase64 == "data:," ? "" : payerCombinedBase64;
      // selectedPayee.image = payeeCombinedBase64 == "data:," ? "" : payeeCombinedBase64;
      // selectedClaimHeader.image = claimHeaserCombinedBase64 == "data:," ? "" : claimHeaserCombinedBase64;
      // selectedFooter.image = footerCombinedBase64 == "data:," ? "" : footerCombinedBase64;
      selectedCheck.image = finalData?.filter(i => i.isCheck && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean);
      selectedPayer.image = finalData?.filter(i => i.isPayer && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean);
      selectedPayee.image = finalData?.filter(i => i.isPayee && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean);
      selectedClaimHeader.image = finalData?.filter(i => i.isClaimHeader && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean);
      selectedFooter.image = finalData?.filter(i => i.isFooter && i.image)?.sort((a, b) => a.order - b.order)?.map(i => i.image)?.filter(Boolean);

      await Promise.all(
        (jobData?.grid_data?.claims || []).map(async (i) => {
          if (selectedClaimData?.some((j) => j.claim_id === i.id)) {
            const imageArr = selectedClaimData
              .filter((j) => j.claim_id === i.id)
              .map((matchingClaim) => matchingClaim?.image);
            // i.image = imageArr.length > 0 ? await getCombinedBase64(imageArr) : "";
            i.image = imageArr.length > 0 ? imageArr : [];
          } else {
            i.image = [];
          }
        })
      );
      data = { is_masking: false, job: jobData?.job, transaction_type: jobData?.grid_data?.transaction_type, snipping_check: selectedCheck, payer: selectedPayer, payee: selectedPayee, claim_header: selectedClaimHeader, claims: jobData?.grid_data?.claims, footer: selectedFooter, masked_images: [] };
    }

    saveSnippet(data)((response) => {
      if (response.status) {
        setSnippetLoading(false)
        if (is_save_Exit) {
          changeAllTitleFunction('Dashboard', DASHBOARD, RedirectId)
        } else {
          setSaveSuccess(true)
          setShowToster({
            status: true,
            message: "Success",
            type: 'success'
          })
          setTimeout(() => {
            setShowToster({
              status: false,
              message: null,
              type: null
            })
          }, 1000)
        }
      }
      else if (response?.status_code == 400 && response?.errors && response?.errors?.assign_user?.length > 0) {
        setSaveSuccess(true)
        setSnippetLoading(false)
        setErrorAlert({
          status: true,
          type: "danger",
          message: response?.errors?.assign_user[0],
          is_exit: is_save_Exit
        })
      }
      else {
        setSaveSuccess(true)
        setSnippetLoading(false)
        setErrorAlert({
          status: true,
          message: 'Error while saving previous job',
          type: '',
          is_exit: is_save_Exit

        });

      }
    })
  }
  const handleSaveSnippet = async (is_save_Exit) => {
    let showPopup = false
    let activeSideMenu = [...maskingDetails?.order]
    let isNotCompletedClaim = false

    if (maskingDetails.isMasking) {
      showPopup = rectangleArr.length == 0
    } else {
      if (jobData.grid_data.claims.length === 0) {
        // Filter the dict array to remove objects with id 5
        activeSideMenu = activeSideMenu?.filter(item => item.id !== 5);
      } else if (activeSideMenu?.some(item => item.id == 5)) {
        const claimIds = jobData?.grid_data?.claims.map((item) => item.id)
        const claimList = rectangleArr
          .filter((i) => i.isCLaim)
        isNotCompletedClaim = !(claimIds?.every(id => claimList?.some(item => item.claim_id === id)));

      }
      activeSideMenu?.length > 0 && activeSideMenu?.map(item => {
        const keyItem = getKeyNameId(item.id);
        const isNotCompleted = rectangleArr
          .filter((i) => i[keyItem.name])
        let isEmpty = true
        if (isNotCompleted.length > 0) {
          // Continue processing if isNotCompleted is not empty
          isEmpty = false;  // Update the flag variable
          // Your other logic here
        }
        if (isEmpty || isNotCompletedClaim) {

          showPopup = true
          return;
        }
      });

    }
    if (showPopup == true) {
      let text = ""
      let confirmButtonText = "Okay"
      let title = "NOTHING TO SAVE"
      let showDenyButton = false
      if (rectangleArr.length > 0) {
        title = "SNIPPING NOT COMPLETED"
        text = "Do you want to continue to Save"
        confirmButtonText = "Yes"
        showDenyButton = true

      }
      setErrorAlert({
        type: "save",
        status: "save",
        message: title,
        text: text,
        is_exit: is_save_Exit,
        confirmBtnText: confirmButtonText,
        showCancel: showDenyButton

      })
      // Swal.fire({
      //   backdrop: true,
      //   allowOutsideClick: false,
      //   title: title,
      //   text: text,
      //   icon: "warning",
      //   showConfirmButton: true,
      //   confirmButtonText: confirmButtonText,
      //   confirmButtonColor: "#6ba2b6",
      //   denyButtonColor: "#575757",
      //   showDenyButton: showDenyButton,

      //   className: {
      //     popup: 'my-custom-popup',
      //   },
      // }).then((response) => {
      //   if (response.isConfirmed) {
      //     if (rectangleArr.length > 0) {
      //       saveFunctionSnippet(is_save_Exit)
      //     } else {
      //     }
      //   } else {

      //   }
      // })

    } else {
      saveFunctionSnippet(is_save_Exit)

    }
  };
  const handleClick = (event, id) => {
    // Define the canvas and its context
    let parent = event.target.parentNode;
    if (parent.classList.contains('common-list-rectangle-box')) {
      const singleClickData = {};
      let c = null
      let rectangleBoxDiv = null
      const canvasConatiner = document.getElementById(`${id}-rectangle-box`);
      // if (signleView.isOpen) {
      //   c = document.getElementById(`capturedCanvas`);
      //   rectangleBoxDiv = document.querySelector(
      //     ".single-canvas-container"
      //   );
      // } else {
      c = canvasConatiner;
      rectangleBoxDiv = canvasConatiner
      // }

      // Calculate the clicked coordinates relative to the canvas
      const clickedX = event.clientX - c.getBoundingClientRect().left;
      const clickedY = event.clientY - c.getBoundingClientRect().top;

      // Set the line width to the full width of the canvas
      const lineWidth = c.getBoundingClientRect().width;
      if (dottedState.isFirstClicked == false || dottedState?.dottedElement?.id != id) {
        const dottedRectangleBoxDiv = document.querySelector(
          ".dotted-stright-line"
        );
        dottedRectangleBoxDiv && dottedRectangleBoxDiv.remove();
        const currentBox = document.createElement("div");
        currentBox.className = "dotted-stright-line";


        // Set the position and dimensions of the div
        currentBox.style.left = `${0}px`;
        currentBox.style.top = `${clickedY}px`;
        currentBox.style.width = `${lineWidth}px`;
        currentBox.style.height = `${1}px`;
        currentBox.style.position = `absolute`;
        // Set styles for the rectangle
        currentBox.style.borderTop = "2px dotted black";
        rectangleBoxDiv.appendChild(currentBox);
        setDottedState({
          ...dottedState,
          isFirstClicked: true,
          topVal: clickedY,
          dottedElement: { element: currentBox, id }
        });
      } else if (dottedState?.dottedElement?.id == id) {


        let isClickedAbouve = true;
        let curentH = dottedState.topVal - clickedY;
        let curentY = clickedY;
        if (dottedState.topVal < clickedY) {
          curentH = clickedY - dottedState.topVal;
          curentY = dottedState.topVal;
          isClickedAbouve = false;
        }
        if (curentH != 0) {
          const dottedRectangleBoxDiv = document.querySelector(
            ".dotted-stright-line"
          );
          dottedRectangleBoxDiv && dottedRectangleBoxDiv.remove();
          singleClickData.isDottedClick = true;
          singleClickData.isClickedAbouve = isClickedAbouve;
          handleRectangleDraw(
            0,
            curentY,
            lineWidth,
            curentH,
            rectangleArr,
            setRectangleArr,
            colorState,
            id,
            singleClickData,
            { isResize: false, resizeType: null, resizeElement: null },
            jobData,
            setActiveClaim,
            activeClaim,
            signleView,
            canvasDetails,
            maskingDetails
          );
          setDottedState({
            ...dottedState,
            isFirstClicked: colorState.isCLaim && !colorState.isSpan ? true : false,
            topVal: colorState.isCLaim && !colorState.isSpan ? clickedY : 0,
            dottedElement: colorState.isCLaim && !colorState.isSpan ? dottedState.dottedElement : null
          });
        }
      }
    }
  };
  // Function to check if the mouse coordinates are inside the box
  function exceededSides(mouseX, mouseY, boxRect) {
    const exceeded = {};

    if (mouseX < boxRect.left) {
      exceeded.left = true;
    } else if (mouseX > Math.round(boxRect.right)) {
      exceeded.right = true;
    }

    if (mouseY < boxRect.top) {
      exceeded.top = true;
    } else if (mouseY > Math.round(boxRect.bottom)) {
      exceeded.bottom = true;
    }

    return exceeded;
  }

  const handleClickRectangle = (event, id, resizeDict = { isResize: false, resizeType: null }) => {
    const box = document.getElementById(`${id}-rectangle-box`);
    const capturedCanvas = document.getElementById(`capturedCanvas`);
    const isOpen = signleView.isOpen;

    const boxRect = box.getBoundingClientRect();

    // if(event.movementX ===0 && event.movementY ===0) return
    const { isResize, resizeType } = resizeDict
    const canvasConatiner = document.getElementById(`${id}-rectangle-box`);
    const c = canvasConatiner.querySelector('img');

    let curentLeft = c.getBoundingClientRect().left
    let curentTop = c.getBoundingClientRect().top
    let curentRight = c.getBoundingClientRect().right
    let curentBottom = c.getBoundingClientRect().bottom
    const left = curentLeft
    const top = curentTop
    const right = curentRight
    const bottom = curentTop

    const startX = event.clientX - left;
    const startY = event.clientY - top;

    let width, height;
    let newStartX = startX;
    let newStartY = startY;

    // const { className } = getMenuColorDetails(colorState, maskingDetails)


    // const resizeRectangleBox = event?.target?.closest(`.${className}`);
    // const resizeRectangleBox = event?.target?.parentElement;
    const resizeRectangleBox = event?.target?.parentElement;


    const resizeWidth = resizeRectangleBox ? parseInt(resizeRectangleBox?.style.width) : 0
    const resizeHeight = resizeRectangleBox ? parseInt(resizeRectangleBox?.style.height) : 0
    // const resizeWidth = resizeRectangleBox ? resizeRectangleBox.getBoundingClientRect().width : 0
    // const resizeHeight = resizeRectangleBox ? resizeRectangleBox.getBoundingClientRect().height : 0

    resizeDict.resizeElement = resizeRectangleBox
    let newWidth = width;
    let newHeight = height;

    const handleMouseMove = (moveEvent) => {
      setIsDraging(true);
      const currentX = moveEvent.clientX - left;
      const currentY = moveEvent.clientY - top;

      // Calculate the new width and height
      width = currentX - startX;
      height = currentY - startY;

      // Determine if resizing is happening from the left or right
      const isResizingLeft = width < 0;
      const isResizingRight = !isResizingLeft;

      // Determine if resizing is happening from the top or bottom
      const isResizingTop = height < 0;
      const isResizingBottom = !isResizingTop;

      newStartX = startX;
      newWidth = width;
      newStartY = startY;
      newHeight = height;
      if (isResizingLeft) {
        newStartX = startX + width; // Move the starting point to the left
        newWidth = -width; // Make the width negative to adjust the position
      }

      if (isResizingTop) {
        newStartY = startY + height; // Move the starting point up
        newHeight = -height; // Make the height negative to adjust the position
      }
      // Draw the rectangle as the mouse moves
      // const exceedValues = exceededSides(moveEvent.clientX, moveEvent.clientY, signleView.isOpen ? document.getElementById(`capturedCanvas`).getBoundingClientRect() : document.getElementById(`${id}-rectangle-box`).getBoundingClientRect())
      const imageElement = document.getElementById(`${id}-rectangle-box`).querySelector('img')
      const exceedValues = exceededSides(moveEvent.clientX, moveEvent.clientY, imageElement.getBoundingClientRect())
      const widthResizeRectangleBox = resizeWidth
      const heightResizeRectangleBox = resizeHeight


      if (isResizingTop && isResizingLeft) {
        if (resizeType == "bottom-left") {
          newHeight -= isResize && heightResizeRectangleBox;
          newWidth += isResize && widthResizeRectangleBox;
        }
        else if (resizeType == "top-right") {
          newHeight += isResize && heightResizeRectangleBox;
          newWidth -= isResize && widthResizeRectangleBox;
        }
        else if (resizeType == "top-left") {
          newHeight += isResize && heightResizeRectangleBox;
          newWidth += isResize && widthResizeRectangleBox;
        }
        else {
          newHeight -= isResize && heightResizeRectangleBox;
          newWidth -= isResize && widthResizeRectangleBox;
        }

      } else if (isResizingBottom && isResizingRight) {
        if (resizeType == "bottom-left") {
          newWidth -= isResize && widthResizeRectangleBox;
          newHeight += isResize && heightResizeRectangleBox;
        }
        else if (resizeType == "top-right") {
          newHeight -= isResize && heightResizeRectangleBox;
          newWidth += isResize && widthResizeRectangleBox;

        }
        else if (resizeType == "top-left") {
          newHeight -= isResize && heightResizeRectangleBox;
          newWidth -= isResize && widthResizeRectangleBox;

        }
        else {
          newHeight += isResize && heightResizeRectangleBox;
          newWidth += isResize && widthResizeRectangleBox;
        }


      } else if (isResizingBottom && isResizingLeft) {
        if (resizeType == "bottom-left") {
          newWidth += isResize && widthResizeRectangleBox;
          newHeight += isResize && heightResizeRectangleBox;
        }
        else if (resizeType == "top-right") {
          newWidth -= isResize && widthResizeRectangleBox;
          newHeight -= isResize && heightResizeRectangleBox;

        }
        else if (resizeType == "top-left") {
          newWidth += isResize && widthResizeRectangleBox;
          newHeight -= isResize && heightResizeRectangleBox;

        }
        else {
          newWidth -= isResize && widthResizeRectangleBox;
          newHeight += isResize && heightResizeRectangleBox;
        }


      } else if (isResizingTop && isResizingRight) {
        if (resizeType == "bottom-left") {

          newWidth -= isResize && widthResizeRectangleBox - 1;
          newHeight -= isResize && heightResizeRectangleBox;

        }
        else if (resizeType == "top-right") {
          newWidth += isResize && widthResizeRectangleBox;
          newHeight += isResize && heightResizeRectangleBox;

        }
        else if (resizeType == "top-left") {
          newWidth -= isResize && widthResizeRectangleBox;
          newHeight += isResize && heightResizeRectangleBox;

        }
        else {
          newWidth += isResize && widthResizeRectangleBox;
          newHeight -= isResize && heightResizeRectangleBox;

        }

      }

      newWidth = Math.abs(newWidth);
      newHeight = Math.abs(newHeight);

      if (isResize && resizeRectangleBox) {
        // Get the computed style of the element
        // const computedStyle = window.getComputedStyle(resizeRectangleBox);
        const computedStyle = resizeRectangleBox?.style;
        // const boxRect = resizeRectangleBox.getBoundingClientRect();
        const boxRect = { top: parseInt(computedStyle.top), left: parseInt(computedStyle.left), width: parseInt(computedStyle.width), right: parseInt(computedStyle.left) + parseInt(computedStyle.width), bottom: parseInt(computedStyle.top) + parseInt(computedStyle.height) };
        const exceedTop = currentY - boxRect.top;
        const exceedBottom = boxRect.bottom - currentY;
        const exceedLeft = currentX - boxRect.left;
        const exceedRight = boxRect.right - currentX;
        // Extract the numeric value of the 'top' property
        if (resizeType == "bottom-left") {
          newStartY = parseInt(computedStyle.top);

          // const exceedTop = moveEvent.clientY - resizeRectangleBox.getBoundingClientRect().top;
          // const exceedRight = resizeRectangleBox.getBoundingClientRect().right - 10 - moveEvent.clientX;
          const isExceedTop = isResize && exceedTop <= 0;
          const isExceedRight = isResize && exceedRight <= 0;
          if (isExceedTop) {
            newHeight = 10;
          }

          if (isExceedRight) {
            newWidth = 10;
            newStartX = parseInt(computedStyle.left) + resizeWidth - 10;

          } else {
            newStartX = startX + width;

          }

        }
        else if (resizeType == "top-right") {
          newStartY = startY + height;
          newStartX = parseInt(computedStyle.left);

          // const exceedTop = resizeRectangleBox.getBoundingClientRect().bottom - moveEvent.clientY;
          // const exceedRight = moveEvent.clientX - resizeRectangleBox.getBoundingClientRect().left - 10;
          const isExceedTop = isResize && exceedBottom <= 0;
          const isExceedRight = isResize && exceedLeft <= 0;
          if (isExceedTop) {
            newHeight = 10;
            newStartY = parseInt(computedStyle.top) + resizeHeight - 10
          }
          if (isExceedRight) {
            newWidth = 10;
          }
        }
        else if (resizeType == "top-left") {
          newStartY = startY + height;

          // const exceedTop = resizeRectangleBox.getBoundingClientRect().bottom - moveEvent.clientY
          // const exceedLeft = resizeRectangleBox.getBoundingClientRect().right - moveEvent.clientX

          const isExceedTop = isResize && exceedBottom <= 0;
          const isExceedLeft = isResize && exceedRight <= 0;

          if (isExceedTop) {
            newHeight = 10;
            newStartY = parseInt(computedStyle.top) + resizeHeight - 10;
          } else {
            newStartX = startX + width;
          }

          if (isExceedLeft) {
            newWidth = 10;
            newStartX = parseInt(computedStyle.left) + resizeWidth - 10;
          } else {
            newStartX = startX + width;
          }

        }
        else {
          // const exceedTop = moveEvent.clientY - resizeRectangleBox.getBoundingClientRect().top
          // const exceedLeft = moveEvent.clientX - resizeRectangleBox.getBoundingClientRect().left
          const isExceedTop = isResize && exceedTop <= 0
          const isExceedLeft = isResize && exceedLeft <= 0

          if (isExceedTop) {
            newHeight = 10
          }
          if (isExceedLeft) {
            newWidth = 10
          }
          newStartY = parseInt(computedStyle.top);
          newStartX = parseInt(computedStyle.left);

        }

      }

      const mouseY = moveEvent.clientY
      const mouseX = moveEvent.clientX

      let preventResizeRight = true
      let preventResizeLeft = true
      let preventResizeTop = true
      let preventResizeBottom = true

      if (resizeType?.includes("bottom") && isResize) {
        preventResizeTop = false
        if (resizeType == "bottom-left") {
          preventResizeRight = false
        } else if (resizeType == "bottom-right") {
          preventResizeLeft = false
        }
      } else if (resizeType?.includes("top") && isResize) {
        preventResizeBottom = false
        if (resizeType == "top-left") {
          preventResizeRight = false
        } else if (resizeType == "top-right") {
          preventResizeLeft = false
        }
      }

      if (preventResizeLeft && exceedValues.left) {
        const left = boxRect.left;
        const exceedWidth = newWidth + (mouseX - left);
        newStartX = 0
        newWidth = exceedWidth;
      } else if (preventResizeRight && exceedValues.right) {
        console.log(newWidth, Math.round(imageElement.getBoundingClientRect().right), "**********************MELE**************************", isResizingBottom, isResizingRight)
        const right = boxRect.right;
        const exceedWidth = newWidth + (right - mouseX);
        const exceedX = newStartX - (right - mouseX);
        newWidth = exceedWidth;
        console.log(newWidth, Math.round(imageElement.getBoundingClientRect().right), "**********************THAZHEEE**************************", isResizingBottom, isResizingRight)

        // newStartX = exceedX
      }

      if (preventResizeTop && exceedValues.top) {
        const top = boxRect.top;
        const exceedY = 0;
        const exceedHeight = newHeight + (mouseY - top);
        newStartY = 0;
        newHeight = exceedHeight;
      } else if (preventResizeBottom && exceedValues.bottom) {
        const bottom = boxRect.bottom;
        const exceedX = newStartY - (mouseY - bottom);
        const exceedHeight = newHeight - (mouseY - bottom);
        newStartY = exceedX + (mouseY - bottom);
        newHeight = exceedHeight;
      }
      handleDrawing(newStartX, newStartY, newWidth, newHeight, id);
    };

    const handleMouseUp = () => {
      setTimeout(() => {
        setIsDraging(false);
      }, 100)
      // Remove the mousemove and mouseup event listeners when the drawing is complete
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      if (isResize) {
        setDottedState({
          ...dottedState,
          isFirstClicked: false,
          topVal: 0,
        });
      }
      // Capture the entire drawn rectangle as an image
      if (newHeight && newWidth) {
        const dottedRectangleBoxDiv = document.querySelector(
          ".dotted-stright-line"
        );
        dottedRectangleBoxDiv && dottedRectangleBoxDiv.remove();
        cleareDottedState()
        handleRectangleDraw(
          newStartX,
          newStartY,
          newWidth,
          newHeight,
          rectangleArr,
          setRectangleArr,
          colorState,
          id,
          null,
          resizeDict,
          jobData,
          setActiveClaim,
          activeClaim,
          signleView,
          canvasDetails,
          maskingDetails
        );
      } else {
        const dottedRectangleBoxDiv = canvasConatiner.querySelector(".rectangle-box");
        dottedRectangleBoxDiv.style.border = "none";
      }
    };

    // Add event listeners for mousemove and mouseup to continue drawing the rectangle
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };
  const handleDrawing = (startX, startY, width, height, id) => {
    const canvasConatiner = document.getElementById(`${id}-rectangle-box`);
    // const canvasConatiner = signleView.isOpen ? document.getElementById("pdfContainer") : document.getElementById(`${id}-rectangle-box`);
    // Define the div for drawing the rectangle
    const rectangleBoxDiv = canvasConatiner.querySelector(".rectangle-box");

    // Set the position and dimensions of the div
    rectangleBoxDiv.style.left = `${startX}px`;
    rectangleBoxDiv.style.top = `${startY}px`;
    rectangleBoxDiv.style.width = `${Math.abs(width)}px`; // Use the absolute value for positive width
    rectangleBoxDiv.style.height = `${Math.abs(height)}px`; // Use the absolute value for positive height

    // Set styles for the rectangle
    rectangleBoxDiv.style.border = "2px dotted black";
  };

  const formReassign = useFormik({
    initialValues: {
      // user_status: "",
      re_assign_reason: "",
      // assign_to: "",
    },
    validationSchema: Yup.object().shape({
      re_assign_reason: Yup.string().required('Required'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      canvasDetails2 == null && setPdfLoaded(true)
      setSnippetLoading(true)
      setLoadData(true)


      const data = {
        reason: values.re_assign_reason,
        is_masking: maskingDetails.isMasking,
        job: jobData.job
      }
      reassignSnippet(data)((response) => {
        if (response.status) {
          setSubmitting(false)
          formReassign.setValues({
            re_assign_reason: ""
          })
          setSaveSuccess(true)
          setSnippetLoading(false)
          setShowToster({
            status: true,
            message: "Success",
            type: 'success'
          })
        }
        else if (response?.status_code == 400 && response?.errors && response?.errors?.reassign_user?.length > 0) {
          setSubmitting(false)
          formReassign.setValues({
            re_assign_reason: ""
          })
          setSaveSuccess(true)
          setSnippetLoading(false)
          setErrorAlert({
            status: true,
            type: "danger",
            message: response?.errors?.reassign_user[0],
            is_exit: false
          })
        }
        else {
          console.log("<<<<<<<<<<<<<<<ERROR>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
        }
      })
      // reAssignUser(values, { setSubmitting });
    }
  })
  // ==============================================================IDEAL TIME OUT FUN========================================================
  const user = JSON.parse(localStorage.getItem("user"));
  const user_idle_time_out = user?.settings?.filter((i) => i?.key_name === 'ideal_user_timeOut')[0]?.value
  useEffect(() => {
    let alive = false
    let minute = 1;
    let gridExited = false;
    let timeout = setInterval(() => {
      if (alive) {
        alive = false
        minute = 1
      }
      else {
        minute = minute + 1
        if (minute > user_idle_time_out && !gridExited) {
          exitGrid(jobData?.job, 3, true)((response) => {
            if (response?.status && response?.status_code == 200) {
              gridExited = true;
              setErrorAlert({
                status: true,
                message: "Idle Timeout",
                type: 'Idle',
                is_exit: false

              })
            }
          })
        }
      }
    }, 60000);
    const resetTimer = () => {
      if (!alive) {
        alive = true
      }
    };
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);
    window.addEventListener('scroll', resetTimer);

    return () => {
      clearInterval(timeout);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('scroll', resetTimer);
    };
  }, [pdfLoaded])
  // ==============================================================IDEAL TIME OUT FUN========================================================
  // ==========================================DRAG======================================
  function dragStart(event) {

  }

  function dragOver(event) {
    event.preventDefault();
    console.log("dragOver")
  }

  function dragEnter(event) {
    event.preventDefault();
    let parent = event.target.parentNode;
    if (parent.classList.contains('common-list-rectangle-box')) {
      parent.classList.add('hovered');
      hoverCanvas(activePage, event.target.parentNode?.getAttribute('data-id'))
      setDragHoverId(event.target.parentNode?.getAttribute('data-id'))
    }
  }

  function dragLeave(event) {
    let parent = event.target.parentNode;
    if (parent.classList.contains('common-list-rectangle-box')) {
      parent.classList.remove('hovered');
      clearHoverSideLine()
    }
  }

  function dragDrop(event) {
    moveCanvas(activePage, dragHoverId)
    cleareDottedState()
    clearHoverSideLine()
    setTimeout(() => {
      addInitialDataToCanvas(false, true)
    }, 0)


  }

  function dragEnd() {
    stopScroll()
  }
  // =============================DRAG====================================
  // ================================ROTATE DELETE=========================
  const rotateRemoveItems = () => {
    const finalData = []
    const data = [...rectangleArr]
    const keysSet = new Set();
    const curentPageClaimID = new Set();
    data?.map((item) => {
      const { unique_id, pageId } = item
      if (activePage.includes(pageId)) {
        Object.keys(item).forEach(key => {
          if (item.isCLaim) {
            curentPageClaimID.add(item.claim_id)
          }
          else if (item[key] === true && !(key == "isDottedClickAndIsCLaim" || key == "isClickAbove" || key == "isBlackOut" || key == "isCLaim")) {
            keysSet.add(key);
          }
        });
        removeCurentElement(`.rectangle-box-${unique_id}`)
      } else {
        finalData.push(item)
      }
    })
    if (!maskingDetails.isMasking) {
      const activePageKeys = Array.from(keysSet)
      const activePageClaimIDs = Array.from(curentPageClaimID)
      const filteredData = finalData?.filter(item => {
        for (const key of activePageKeys) {
          if (item.hasOwnProperty(key)) {
            removeCurentElement(`.rectangle-box-${item?.unique_id}`)
            return false; // Remove this item
          }
        }
        return true; // Keep this item
      });
      filteredData.forEach(item => activePageClaimIDs.includes(item.claim_id) && removeCurentElement(`.rectangle-box-${item?.unique_id}`))
      setRectangleArr(filteredData.filter(item => !activePageClaimIDs.includes(item.claim_id)))
    } else[
      setRectangleArr(finalData)
    ]
    // // Function to extract all unique keys with value true from an array of objects
    // const extractKeysWithTrueValue = (dataArray, pageIds) => {
    //   const keysSet = new Set();
    //   const curentPageClaimID = new Set();
    //   dataArray.forEach(item => {
    //     if (pageIds.includes(item.pageId)) {
    //       Object.keys(item).forEach(key => {
    //         if (item[key] === true && !(key == "isDottedClickAndIsCLaim" || key == "isClickAbove" || key == "isBlackOut")) {
    //           keysSet.add(key);
    //           if (item.isCLaim) {
    //             curentPageClaimID.add(item.claim_id)
    //           }
    //         }
    //       });
    //     }
    //   });
    //   return { activePageKeys: Array.from(keysSet), activePageClaimIDs: Array.from(curentPageClaimID) };
    // };

    // // Extract all unique keys with value true from activePage objects
    // const { activePageKeys, activePageClaimIDs } = extractKeysWithTrueValue(data, activePage);

    // // Filter out objects with the keys from activePage set to true in other pageIds

    // const checkKeyInPages = (item) => {
    //   for (const key of activePageKeys) {
    //     if (item[key] === true && key != "isCLaim") {
    //       return true;
    //     }
    //   }
    //   return false;
    // }

    // let finalData = [...data];
    // const deletedBlackOutList = data.filter(item => item.isBlackOut && activePage.includes(item.pageId));
    // deletedBlackOutList.length > 0 && deletedBlackOutList.forEach((item) => {
    //   removeCurentElement(`.rectangle-box-${item.unique_id}`)
    // })
    // finalData = finalData.filter(item => item.isBlackOut && activePage.includes(item.pageId));

    // const deletedList = data.filter(item => checkKeyInPages(item));
    // deletedList.forEach((item) => {
    //   removeCurentElement(`.rectangle-box-${item.unique_id}`)
    // })
    // finalData = finalData.filter(item => !checkKeyInPages(item));



    // if (activePageKeys.includes("isCLaim")) {
    //   const claimData = finalData.filter(item => activePageClaimIDs.includes(item.claim_id) && item.isCLaim)
    //   const claimIds = claimData.map(item => item.claim_id)
    //   const removedDuplicatesIds = [...new Set(claimIds)]
    //   finalData = finalData.filter(item => !removedDuplicatesIds.includes(item.claim_id))
    //   const cleaimdeletedList = data.filter(item => removedDuplicatesIds.includes(item.claim_id))
    //   cleaimdeletedList.forEach((item) => {
    //     removeCurentElement(`.rectangle-box-${item.unique_id}`)
    //   })
    // }
    // setRectangleArr(finalData)

  }
  // ================================ROTATE DELETE=========================
  // const skelitonLoader = activeJob === 1 ? pdfLoaded : activeJob === 2 ? pdfLoaded2 : false
  const skelitonLoader = pdfLoaded
  // const hasTrueValueExceptSpan = Object.values(colorState).some((value, key) => key !== 'isSpan' && value);
  const hasTrueValueExceptSpan = Object.keys(colorState).some(key => {
    return colorState[key] && key !== 'isDraging' && key !== 'isSpan';
  });
  const hasTrueValueExceptSpanBlackout = Object.keys(colorState).some(key => {
    return colorState[key] && key !== 'isBlackOut' && key !== 'isDraging' && key !== 'isSpan';
  });
  const selectedCanavsIndex = signleView?.id && canvasDetails.findIndex(dict => dict.id == signleView.id);
  const completedClaimCount = jobData?.grid_data?.claims?.filter((claimItem, claimIndex) =>
    selectedClaimData?.some((i, ind) => i.claim_id == claimItem.id)
  )
  // const hasTrueValueExceptSpanBlackOut = Object.values(colorState).some((value, key) => key !== 'isBlackOut' && key !== 'isSpan' && value);
  console.log(rectangleArr)
  console.log(canvasDetails, loadData, signleView, activeJob, canvasDetails2)
  const scrollIntervalRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const handleScroll = (scrollType) => {
    if (scrollType == 'stop') {
      stopScroll();
    } else {
      startScroll(scrollType);
    }
  };
  const stopScroll = () => {
    clearInterval(scrollIntervalRef.current);
    scrollIntervalRef.current = null;
    setIsScrolling(false);
  };


  const startScroll = (scrollType) => {
    if (!scrollIntervalRef.current) {
      scrollIntervalRef.current = setInterval(() => { scrollPage(scrollType) }, 20); // Adjust the scroll speed here
      setIsScrolling(true);
    }
  };
  const scrollPage = (scrollType) => {
    const conta = document.getElementById('main-canavs-container')
    if (scrollType == 'bottom') {
      conta.scrollBy(0, 20); // Adjust scroll speed here
    } else if (scrollType == 'top') {
      conta.scrollBy(0, -20); // Adjust scroll speed here
    }
  };
  return (
    <SnippetMainContainer onWheel={(event) => {
      if (event.ctrlKey) {
        cleareDottedState()
        if (event.deltaY > 0) {
          // setZoomValue((prevScale) => prevScale > 100 ? prevScale - 100 : 100);
          handleZoomIn()

        } else if (event.deltaY < 0) {
          handleZoomOut()
          // setZoomValue((prevScale) => prevScale + 100);
        }
      }

    }}>
      <>
        <Header job={jobData?.job} />
        <ToastContainer containerPosition="fixed" position={"top-end"}>
          <Toast bg={showToster.type} onClose={() => setShowToster({ ...showToster, status: false, type: null, message: null })} show={showToster.status} delay={19000} autohide animation
            style={{
              position: 'fixed',
              top: 35,
              right: 20,
              minWidth: 200,
              zIndex: '99999'
            }}
          >
            <Toast.Body className='text-white'>{showToster.message}</Toast.Body>
          </Toast>
        </ToastContainer>
      </>
      {
        noJob ? <NoJobPage saveSuccess={saveSuccess} /> :
          skelitonLoader ?
            <SnippetViewSkeleton />
            :
            <>
              <DndProvider backend={HTML5Backend}>
                <MainDivContainer isVisible={singleImageLoader} className="App">
                  <div className="page-full-freeze"></div>
                  {!maskingDetails.isMasking &&
                    <LeftDivContainer>
                      <TopColorDivConatiner>
                        <SidebarItem
                          hasTrueValueExceptSpanBlackout={hasTrueValueExceptSpanBlackout}
                          contextMenuVisible={contextMenuVisible}
                          setContextMenuVisible={setContextMenuVisible}
                          rectangleArr={rectangleArr}
                          setColorState={setColorState}
                          colorState={colorState}
                          setSideMenuActive={setSideMenuActive}
                          color={"#ff3130"}
                          Icon={rectangleArr?.some((i) => i?.isCheck) ? FaCircleCheck : FaDotCircle}
                          stateKey="isCheck"
                          name="Check"
                          order="1"

                        />
                        <SidebarItem
                          hasTrueValueExceptSpanBlackout={hasTrueValueExceptSpanBlackout}
                          contextMenuVisible={contextMenuVisible}
                          setContextMenuVisible={setContextMenuVisible}
                          rectangleArr={rectangleArr}
                          setColorState={setColorState}
                          colorState={colorState}
                          setSideMenuActive={setSideMenuActive}
                          color={"#4472c4"}
                          Icon={rectangleArr?.some((i) => i?.isPayer) ? FaCircleCheck : FaDotCircle}

                          stateKey="isPayer"
                          name="Payer"
                          order="2"

                        />
                        <SidebarItem
                          hasTrueValueExceptSpanBlackout={hasTrueValueExceptSpanBlackout}
                          contextMenuVisible={contextMenuVisible}
                          setContextMenuVisible={setContextMenuVisible}
                          rectangleArr={rectangleArr}
                          setColorState={setColorState}
                          colorState={colorState}
                          setSideMenuActive={setSideMenuActive}
                          color={"#ffbf00"}
                          Icon={rectangleArr?.some((i) => i?.isPayee) ? FaCircleCheck : FaDotCircle}

                          stateKey="isPayee"
                          name="Payee"
                          order="3"

                        />
                        <SidebarItem
                          hasTrueValueExceptSpanBlackout={hasTrueValueExceptSpanBlackout}
                          contextMenuVisible={contextMenuVisible}
                          setContextMenuVisible={setContextMenuVisible}
                          rectangleArr={rectangleArr}
                          setColorState={setColorState}
                          colorState={colorState}
                          setSideMenuActive={setSideMenuActive}
                          color={"#5e18eb"}
                          Icon={rectangleArr?.some((i) => i?.isClaimHeader) ? FaCircleCheck : FaDotCircle}

                          stateKey="isClaimHeader"
                          name="Claim Header"
                          order="4"
                        />
                        <SidebarItem
                          hasTrueValueExceptSpanBlackout={hasTrueValueExceptSpanBlackout}
                          contextMenuVisible={contextMenuVisible}
                          setContextMenuVisible={setContextMenuVisible}
                          rectangleArr={rectangleArr}
                          setColorState={setColorState}
                          colorState={colorState}
                          setSideMenuActive={setSideMenuActive}
                          color={"#61b66a"}
                          Icon={rectangleArr?.some((i) => i?.isCLaim) ? FaCircleCheck : FaDotCircle}

                          stateKey="isCLaim"
                          name="Claim"
                          order="5"

                        />
                        <SidebarItem
                          hasTrueValueExceptSpanBlackout={hasTrueValueExceptSpanBlackout}
                          contextMenuVisible={contextMenuVisible}
                          setContextMenuVisible={setContextMenuVisible}
                          rectangleArr={rectangleArr}
                          setColorState={setColorState}
                          colorState={colorState}
                          setSideMenuActive={setSideMenuActive}
                          color={"#faf508"}
                          Icon={rectangleArr?.some((i) => i?.isFooter) ? FaCircleCheck : FaDotCircle}

                          stateKey="isFooter"
                          name="Claim Footer"
                          order="6"
                        />
                        <SidebarItem
                          hasTrueValueExceptSpanBlackout={hasTrueValueExceptSpanBlackout}
                          contextMenuVisible={contextMenuVisible}
                          setContextMenuVisible={setContextMenuVisible}
                          rectangleArr={rectangleArr}
                          setColorState={setColorState}
                          colorState={colorState}
                          setSideMenuActive={setSideMenuActive}
                          color={"#a5a5a5"}
                          Icon={FaDotCircle}
                          order="7"
                          stateKey="isSpan"
                          name="Span"
                        />
                      </TopColorDivConatiner>
                      <BottomClaimContainer>
                        <ClaimHeader>CLAIM DETAILS <span>({completedClaimCount.length}/{jobData.grid_data.claims.length})</span></ClaimHeader>
                        <div className="claim-list-container">
                          {jobData?.grid_data?.claims?.map((claimItem, claimIndex) =>
                            <SingleClaimComponent id={`single-claim-${claimItem?.id}`} index={claimIndex} isActive={activeClaim.curentClaimId == claimItem?.id && colorState.isCLaim ? "active" : selectedClaimData?.some((i, ind) => i.claim_id == claimItem.id) ? "completed" : ""} item={claimItem} />
                          )}
                        </div>

                      </BottomClaimContainer>

                    </LeftDivContainer>
                  }
                  <div style={{ width: !maskingDetails.isMasking ? "85%" : "100%" }}>
                    <ButtonIconConatiner isOpen={signleView.isOpen}>

                      < Tooltip disableInteractive title={"Zoom Out"} placement='top' arrow>

                        <div onClick={() => {
                          cleareDottedState()
                          handleZoomIn()
                        }}>
                          <FiZoomOut />
                        </div>
                      </Tooltip>

                      < Tooltip disableInteractive title={"Zoom In"} placement='top' arrow>
                        <div onClick={() => {
                          cleareDottedState()
                          handleZoomOut()
                        }} >
                          <FiZoomIn />
                        </div>
                      </Tooltip>
                      <Tooltip disableInteractive title={"Left"} placement='top' arrow>
                        <div onClick={() => {
                          if (rectangleArr.length > 0 && rectangleArr?.some((i) => activePage.includes(i.pageId))) {
                            handleReset("Activating rotate will reset completed actions on selected pages. Proceed?", "rotate", "left")
                            // handleReset("To activate the rotate option, all completed actions will be reset on the selected pages. Do you wish to proceed?", "rotate", "left")

                          } else {

                            handleRotate("left")

                          }
                        }}>
                          <FaArrowRotateLeft />
                        </div>
                      </Tooltip>
                      < Tooltip disableInteractive title={"Right"} placement='top' arrow>

                        <div onClick={() => {
                          if (rectangleArr.length > 0 && rectangleArr?.some((i) => activePage.includes(i.pageId))) {
                            handleReset("Activating rotate will reset completed actions on selected pages. Proceed?", "rotate", "right")

                          } else {
                            handleRotate("right")
                          }
                        }}>
                          <FaArrowRotateRight />
                        </div>
                      </Tooltip>
                      < Tooltip disableInteractive title={"Drag"} placement='top' arrow>
                        <DragContainer isActive={colorState.isDraging} onClick={() => {
                          setSideMenuActive("isDraging")
                        }
                        }>
                          <RiDragDropFill />
                        </DragContainer>
                      </Tooltip>

                      <SaveButton
                        // disabled={snippetLoading ? true : !currentJobVerified ? true : false}
                        type='submit' onClick={() => handleReset("Reset All Actions?", 'reset')}>
                        Reset
                      </SaveButton>
                      <SaveButton disabled={snippetLoading ? true : !currentJobVerified ? true : false} type='submit' onClick={() => handleSaveSnippet(false)}>
                        {/* {snippetLoading ?

                            <div class="spinner-border spinner-border-sm" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                            : !currentJobVerified ?
                              <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>

                              :
                              
                              "Save"} */}
                        Save
                      </SaveButton>
                      <SaveButton className="save-exit-button" disabled={snippetLoading ? true : !currentJobVerified ? true : false} type='submit' onClick={() => handleSaveSnippet(true)}>
                        {/* {snippetLoading ?

                            <div class="spinner-border spinner-border-sm" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                            : !currentJobVerified ?
                              <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>

                              : "Save & Exit"} */}

                        Save & Exit
                      </SaveButton>
                      <SaveButton
                        // disabled={snippetLoading ? true : !currentJobVerified ? true : false} ref={reAssignRef}
                        ref={reAssignRef} type='submit' className="re-assign" onClick={() => handleReassignSnippet()}>
                        Re Assign
                      </SaveButton>
                      < Tooltip disableInteractive title={"Blackout(8)"} placement='top' arrow>
                        <div>

                          <input checked={colorState.isBlackOut} type="checkbox" onChange={() => { setSideMenuActive("isBlackOut") }} />
                        </div>
                      </Tooltip>

                      < Tooltip disableInteractive title={"Info"} placement='top' arrow>
                        <div onClick={() => setInfoModal(true)}>
                          <IoIosInformationCircle />
                        </div>
                      </Tooltip>


                      <TextAreaField
                        rootClose={true}
                        onHide={() => {
                          setAssignTooltip(false);
                          formReassign.setValues({
                            re_assign_reason: ""
                          })
                        }}
                        show={assignTooltip}
                        target={reAssignRef.current}
                        placement="right"
                      >
                        <Popover className={"pc-popover-assign"} >
                          <Popover.Body className="">
                            <React.Fragment>


                              <Form onSubmit={formReassign.handleSubmit}>

                                <Form.Control
                                  type="text"
                                  as="textarea"
                                  name={"re_assign_reason"}
                                  isInvalid={formReassign?.errors?.re_assign_reason}
                                  className="b-r-10 text-uppercase"
                                  onChange={formReassign.handleChange}
                                  rows={4}
                                  value={formReassign?.values?.re_assign_reason}
                                  style={{ resize: 'none', width: '100%', }}

                                />
                                <Form.Control.Feedback type='invalid'>
                                  {formReassign.errors?.re_assign_reason}
                                </Form.Control.Feedback>
                                <div className='d-flex flex-row justify-content-end'>
                                  <button type="submit" disabled={!formReassign.isValid || formReassign.isSubmitting} className="btn btn-reject text-end " data-bs-display="static" aria-expanded="false">Re Assign</button>
                                </div>
                              </Form>
                            </React.Fragment>


                          </Popover.Body>
                        </Popover>
                      </TextAreaField>


                    </ButtonIconConatiner>
                    {console.log(colorState?.isDraging, 'colorState?.isDraging')}
                    <MainCanvasContainer isOpen={signleView.isOpen} isDraging={colorState?.isDraging}

                      id="main-canavs-container"
                      onDragOver={(e) => {
                        if (colorState.isDraging) {
                          const container = document.getElementById('main-canavs-container')
                          const rect = container.getBoundingClientRect();
                          const mouseY = e.clientY - rect.top; // Mouse Y position relative to the container
                          if (mouseY < 50) {
                            handleScroll('top')
                          } else if (mouseY > ((window.innerHeight || document.documentElement.clientHeight) - 150)) {
                            handleScroll('bottom')
                          } else {
                            handleScroll('stop')
                          }
                        }
                      }}
                      onMouseMove={(e) => {
                        // const imageList = document.getElementById('main-canavs-container').querySelectorAll('img');
                        // let closestImage = null;
                        // let minDistance = Number.MAX_VALUE;
                        // imageList.forEach((item) => {
                        //   const rect = item.getBoundingClientRect();
                        //   const centerX = rect.left + rect.width / 2;
                        //   const centerY = rect.top + rect.height / 2;
                        //   const distance = Math.sqrt(Math.pow(e.clientX - centerX, 2) + Math.pow(e.clientY - centerY, 2));

                        //   if (distance < minDistance) {
                        //     minDistance = distance;
                        //     closestImage = item;
                        //   }
                        // });

                      }}
                    >

                      {/* {!loadData && canvasDetails.map((canvas, index) => {
                        return signleView.isOpen && selectedCanavsIndex == index ?
                          <Canvas hasTrueValueExceptSpan={hasTrueValueExceptSpan} key={index} index={index} {...canvas} scale={scale} rotation={canvas.rotate} moveCanvas={moveCanvas} dragEnabled={colorState.isDraging} />
                          : !signleView.isOpen ? <Canvas hasTrueValueExceptSpan={hasTrueValueExceptSpan} key={index} index={index} {...canvas} scale={scale} rotation={canvas.rotate} moveCanvas={moveCanvas} dragEnabled={colorState.isDraging} /> : null

                      })} */}

                      {!loadData && canvasDetails.map((canvas, index) => {

                        return signleView.isOpen && selectedCanavsIndex == index ?
                          <MainSingleContainer
                            zoomWidth={singleZoomValue > 100 ? 100 : singleZoomValue}
                            rotateVal={canvas.rotate}
                            rotateHeight={Math.abs(canvas.rotate) === 90 || Math.abs(canvas.rotate) === 270 ? `${zoomValue}%` : "auto"}

                          >
                            <CanvasContainer
                              countBoxColor={maskingDetails.isMasking ? "black" : "white"}
                              // scale={scale}
                              className="common-list-rectangle-box"
                              data-id={canvas?.canvas?.getAttribute("data-id")}
                              id={`${canvas?.canvas?.getAttribute("data-id")}-rectangle-box`}
                              // ref={(node) => ref(drop(node))}
                              onContextMenu={(event) => {
                                if (!colorState.isDraging) {
                                  if (event.ctrlKey) {
                                    handleDeleteSingleBox(event)
                                  } else if (hasTrueValueExceptSpan && !event.ctrlKey) {
                                    const element = document.getElementById(`${canvas?.canvas?.getAttribute("data-id")}-rectangle-box`)
                                    const { top, left, width, height } = element.getBoundingClientRect()
                                    handleRectangleDraw(
                                      0,
                                      0,
                                      width,
                                      height - 1,
                                      rectangleArr,
                                      setRectangleArr,
                                      colorState,
                                      canvas?.canvas?.getAttribute("data-id"),
                                      null,
                                      { isResize: false, resizeType: null, resizeElement: null },
                                      jobData,
                                      setActiveClaim,
                                      activeClaim,
                                      signleView,
                                      canvasDetails,
                                      maskingDetails
                                    );

                                  }
                                }
                              }}
                              // onDoubleClick={() => {
                              //   if (!hasTrueValueExceptSpan) {
                              //     const id = canvas?.canvas?.getAttribute("data-id")
                              //     // fullViewFun()
                              //     cleareDottedState()
                              //     setSingleView({
                              //       id,
                              //       isOpen: true,
                              //       index,
                              //     })


                              //   }
                              // }}
                              onClick={(e) => {
                                const id = canvas?.canvas?.getAttribute("data-id")

                                if (!isDraging) {
                                  if (hasTrueValueExceptSpan) {
                                    if (e.shiftKey) {
                                      const id = canvas?.canvas?.getAttribute("data-id")
                                      // fullViewFun()
                                      cleareDottedState()
                                      setSingleView({
                                        id,
                                        isOpen: true,
                                        index,
                                      })
                                    }

                                    else if (!e.ctrlKey && !colorState.isDraging) {
                                      handleClick(e, id);
                                    }
                                  } else {
                                    if (e.ctrlKey) {
                                      setActivePage((prevState) => {
                                        // Check if the ID is already in the active pages
                                        const isPageActive = prevState.includes(id);

                                        if (isPageActive) {
                                          // If the page is active, remove it
                                          return prevState.filter((pageId) => pageId !== id);
                                        } else {
                                          // If the page is not active, add it
                                          return [...prevState, id];
                                        }
                                      });

                                    } else {
                                      // setActivePage((prevState) => {
                                      //   // Check if the ID is already in the active pages
                                      //   const isPageActive = prevState.includes(id);

                                      //   if (isPageActive) {
                                      //     // If the page is active, remove it
                                      //     return prevState.filter((pageId) => pageId !== id);
                                      //   } else {
                                      //     // If the page is not active, add it
                                      //     return [id];
                                      //   }
                                      // });
                                    }
                                  }
                                }
                              }}

                              onMouseDown={(e) => {
                                const id = canvas?.canvas?.getAttribute("data-id")
                                const clickedElement = e.target;
                                // Check if the clicked element has the desired class
                                if (!colorState.isDraging) {
                                  if (e.button === 0) {
                                    if (hasTrueValueExceptSpan && e.ctrlKey) {
                                      handleClickRectangle(e, id);
                                    } else if (clickedElement.classList.contains('resize-handle') && clickedElement.classList.contains('top-left')) {
                                      handleClickRectangle(e, id, { isResize: true, resizeType: "top-left" });
                                    }
                                    else if (clickedElement.classList.contains('resize-handle') && clickedElement.classList.contains('top-right')) {
                                      handleClickRectangle(e, id, { isResize: true, resizeType: "top-right" });
                                    }
                                    else if (clickedElement.classList.contains('resize-handle') && clickedElement.classList.contains('bottom-left')) {
                                      handleClickRectangle(e, id, { isResize: true, resizeType: "bottom-left" });
                                    }
                                    else if (clickedElement.classList.contains('resize-handle') && clickedElement.classList.contains('bottom-right')) {
                                      handleClickRectangle(e, id, { isResize: true, resizeType: "bottom-right" });
                                    }
                                  }
                                }
                              }}

                              style={{
                                position: "relative",
                                // marginRight: "10px",
                                // marginBottom: signleView.isOpen ? "unset" : "10px",
                                // flexBasis: `calc(${zoomValue}% - 20px)`,
                                // width: '20%',
                                width: '100%',
                                // transform: `scale(${scale}) rotate(${canvas.rotate}deg)`,

                                transition: "transform 0.5s ease-in-out",
                              }}
                            >
                              <div id={`${canvas?.canvas?.getAttribute("data-id")}-rectangle-box-shortcut`} onClick={(e) => {
                                e.stopPropagation()
                                handleDoubleClick(index)
                              }}></div>
                              <CanvasReactangleBox width={canvas.imageSize.width} height={canvas.imageSize.height} id={`${index}-main-rectangle-box`} />


                              <img
                                draggable={false}
                                src={canvas.image}
                                alt={`Canvas ${index}`}
                                // width={imageSize.width}
                                // height={imageSize.height}
                                style={{
                                  border: "1px solid #000",
                                  display: "block",
                                  width: "100%",
                                  height: "100%",
                                  transition: "transform 0.6s ease-in-out 0s",
                                  userSelect: "none",

                                  // position: 'absolute',
                                  // top: '0',
                                  boxShadow: activePage.length > 0 && activePage?.some(i => i == canvas?.canvas?.getAttribute("data-id")) === true ? '#65a1b4 1px 0px 3px 4px' : 'unset'
                                }}

                              />
                              <div className="rectangle-box">

                              </div>
                              {/* <div
                                // ref={rectRef}
                                style={{
                                  position: "absolute",
                                  border: "2px dashed #FF0000",
                                  pointerEvents: "none",
                                }}
                              /> */}
                            </CanvasContainer>
                          </MainSingleContainer>
                          : !signleView.isOpen ?
                            <MainSingleContainer
                              zoomWidth={() => {
                                let finAlZoomVal = zoomValue
                                if (canvas.rotate == 270 || canvas.rotate == 90) {
                                  if (canvas.page_size) {
                                    finAlZoomVal = zoomValue / 2
                                  }
                                }

                                return finAlZoomVal
                              }}
                              rotateVal={canvas.rotate}
                            >
                              <BlurBackground isVisible={activePage?.some((i) => i == canvas?.id) && singleImageLoader ? "flex" : "none"}>
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              </BlurBackground>

                              <CanvasContainer
                                draggable={colorState.isDraging && activePage.length > 0 && activePage.includes(canvas?.canvas?.getAttribute("data-id"))}
                                onDragStart={colorState.isDraging && activePage.length > 0 ? dragStart : null}
                                onDragOver={colorState.isDraging && activePage.length > 0 ? dragOver : null}
                                onDragEnter={colorState.isDraging && activePage.length > 0 ? dragEnter : null}
                                onDragLeave={colorState.isDraging && activePage.length > 0 ? dragLeave : null}
                                onDrop={colorState.isDraging && activePage.length > 0 ? dragDrop : null}
                                onDragEnd={colorState.isDraging && activePage.length > 0 ? dragEnd : null}
                                countBoxColor={maskingDetails.isMasking ? "black" : "white"}
                                className="common-list-rectangle-box"
                                data-id={canvas?.canvas?.getAttribute("data-id")}
                                id={`${canvas?.canvas?.getAttribute("data-id")}-rectangle-box`}
                                onContextMenu={(event) => {
                                  if (!colorState.isDraging) {
                                    if (event.ctrlKey) {
                                      handleDeleteSingleBox(event)
                                    } else if (hasTrueValueExceptSpan && !event.ctrlKey) {
                                      const element = document.getElementById(`${canvas?.canvas?.getAttribute("data-id")}-rectangle-box`)
                                      const { top, left, width, height } = element.getBoundingClientRect()
                                      handleRectangleDraw(
                                        0,
                                        0,
                                        width,
                                        height - 1,
                                        rectangleArr,
                                        setRectangleArr,
                                        colorState,
                                        canvas?.canvas?.getAttribute("data-id"),
                                        null,
                                        { isResize: false, resizeType: null, resizeElement: null },
                                        jobData,
                                        setActiveClaim,
                                        activeClaim,
                                        signleView,
                                        canvasDetails,
                                        maskingDetails
                                      );

                                    }
                                  }
                                }}
                                onDoubleClick={() => {
                                  if (!hasTrueValueExceptSpan && !colorState.isDraging) {
                                    const id = canvas?.canvas?.getAttribute("data-id")
                                    // fullViewFun()
                                    cleareDottedState()
                                    setSingleView({
                                      id,
                                      isOpen: true,
                                      index,
                                    })
                                  }

                                }}
                                onClick={(e) => {
                                  const id = canvas?.canvas?.getAttribute("data-id")
                                  if (!isDraging) {
                                    if (hasTrueValueExceptSpan) {
                                      if (e.shiftKey) {
                                        const id = canvas?.canvas?.getAttribute("data-id")
                                        // fullViewFun()
                                        cleareDottedState()
                                        setSingleView({
                                          id,
                                          isOpen: true,
                                          index,
                                        })
                                      }
                                      // else if (e.altKey) {
                                      //   setActivePage((prevState) => {
                                      //     // Check if the ID is already in the active pages
                                      //     const isPageActive = prevState.includes(id);

                                      //     if (isPageActive) {
                                      //       // If the page is active, remove it
                                      //       return prevState.filter((pageId) => pageId !== id);
                                      //     } else {
                                      //       // If the page is not active, add it
                                      //       return [id];
                                      //     }
                                      //   });
                                      // }
                                      else if (!e.ctrlKey && !colorState.isDraging) {
                                        handleClick(e, id);
                                      }
                                    } else {
                                      if (e.ctrlKey) {
                                        setActivePage((prevState) => {
                                          // Check if the ID is already in the active pages
                                          const isPageActive = prevState.includes(id);

                                          if (isPageActive) {
                                            // If the page is active, remove it
                                            return prevState.filter((pageId) => pageId !== id);
                                          } else {
                                            // If the page is not active, add it
                                            return [...prevState, id];
                                          }
                                        });

                                      } else {
                                        // setActivePage((prevState) => {
                                        //   // Check if the ID is already in the active pages
                                        //   const isPageActive = prevState.includes(id);

                                        //   if (isPageActive) {
                                        //     // If the page is active, remove it
                                        //     return prevState.filter((pageId) => pageId !== id);
                                        //   } else {
                                        //     // If the page is not active, add it
                                        //     return [id];
                                        //   }
                                        // });
                                      }
                                    }
                                  }
                                }}

                                onMouseDown={(e) => {

                                  const id = canvas?.canvas?.getAttribute("data-id")
                                  const clickedElement = e.target;
                                  // Check if the clicked element has the desired class
                                  if (!colorState.isDraging) {
                                    if (e.button === 0) {
                                      if (hasTrueValueExceptSpan && e.ctrlKey) {
                                        handleClickRectangle(e, id);
                                      } else if (clickedElement.classList.contains('resize-handle') && clickedElement.classList.contains('top-left')) {
                                        handleClickRectangle(e, id, { isResize: true, resizeType: "top-left" });
                                      }
                                      else if (clickedElement.classList.contains('resize-handle') && clickedElement.classList.contains('top-right')) {
                                        handleClickRectangle(e, id, { isResize: true, resizeType: "top-right" });
                                      }
                                      else if (clickedElement.classList.contains('resize-handle') && clickedElement.classList.contains('bottom-left')) {
                                        handleClickRectangle(e, id, { isResize: true, resizeType: "bottom-left" });
                                      }
                                      else if (clickedElement.classList.contains('resize-handle') && clickedElement.classList.contains('bottom-right')) {
                                        handleClickRectangle(e, id, { isResize: true, resizeType: "bottom-right" });
                                      }
                                    }
                                  }
                                }}

                                style={{
                                  position: "relative",
                                  // marginRight: "10px",
                                  // marginBottom: signleView.isOpen ? "unset" : "10px",
                                  // flexBasis: `calc(${zoomValue}% - 20px)`,
                                  // width: '20%',
                                  width: '100%',
                                  // transform: `scale(${scale}) rotate(${canvas.rotate}deg)`,

                                  transition: "transform 0.5s ease-in-out",
                                }}
                              >
                                <div id={`${canvas?.canvas?.getAttribute("data-id")}-rectangle-box-shortcut`} onClick={(e) => {
                                  e.stopPropagation()
                                  handleDoubleClick(index)
                                }}></div>
                                <CanvasReactangleBox width={canvas.imageSize.width} height={canvas.imageSize.height} id={`${index}-main-rectangle-box`} />
                                {colorState.isDraging &&
                                  <div className='hover-drag-line'>
                                    <img className="left-arrow" src={leftArrow} />
                                    <img className="right-arrow" src={rightArrow} />
                                  </div>
                                }

                                <img
                                  draggable={false}
                                  src={canvas.image}
                                  alt={`Canvas ${index}`}
                                  // width={imageSize.width}
                                  // height={imageSize.height}
                                  style={{
                                    border: "1px solid #000",
                                    display: "block",
                                    width: "100%",
                                    height: "100%",
                                    transition: "transform 0.6s ease-in-out 0s",
                                    userSelect: "none",
                                    // position: 'absolute',
                                    // top: '0',
                                    boxShadow: activePage.length > 0 && activePage?.some(i => i == canvas?.canvas?.getAttribute("data-id")) === true ? '#65a1b4 1px 0px 3px 4px' : 'unset'
                                  }}

                                />
                                <div className="rectangle-box">

                                </div>
                                {/* <div
                                  // ref={rectRef}
                                  style={{
                                    position: "absolute",
                                    border: "2px dashed #FF0000",
                                    pointerEvents: "none",
                                  }}
                                /> */}
                              </CanvasContainer>
                            </MainSingleContainer>
                            : null

                      })}
                    </MainCanvasContainer>
                  </div>
                </MainDivContainer>

              </DndProvider>

              {/* {signleView.isOpen && (
                <>
                  <BlurBackground />
                  <SingleCanvas
                    rectangleArr={rectangleArr}
                    setRectangleArr={setRectangleArr}
                    colorState={colorState}
                    signleView={signleView}
                    setSideMenuActive={setSideMenuActive}
                    jobData={jobData}
                    setActiveClaim={setActiveClaim}
                    activeClaim={activeClaim}
                    handleRectangleDraw={handleRectangleDraw}
                    handleClick={handleClick}
                    handleClickRectangle={handleClickRectangle}
                    isDraging={isDraging}
                    canvasDetails={canvasDetails}
                    maskingDetails={maskingDetails}
                    handleDeleteSingleBox={handleDeleteSingleBox}
                    hasTrueValueExceptSpan={hasTrueValueExceptSpan}
                  />
                </>
              )} */}

            </>

      }
      <ShortCutPage hotKey={showShortCut} setHotKey={setShowShortCut} />
      {errorAlert.status == true &&

        <SweetAlert
          danger
          confirmBtnText={"Ok!"}
          confirmBtnCssClass="custom-confirm-button-class"
          onConfirm={() => {
            setErrorAlert({
              status: false,
              message: null,
              type: null,
              is_exit: false

            })
            const RedirectId = getRedirectTabId()
            if (errorAlert.type === "Idle" || errorAlert.is_exit) { changeAllTitleFunction('Dashboard', '/admin/dashboard', RedirectId) }
          }}
        >
          <span className='fw-bolder'>{errorAlert?.message}</span>
        </SweetAlert>
      }
      {errorAlert.status == "reset" ?

        <SweetAlert
          warning
          closeOnClickOutside={false}
          showCancel={errorAlert.status == "save" ? errorAlert.showCancel : true}
          confirmBtnText={errorAlert.status == "save" ? errorAlert.confirmBtnText : "Yes"}
          cancelBtnText="No"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="light"
          title={errorAlert.type == "reset" || errorAlert.type == "save" ? errorAlert?.message : "Are you sure?"}
          focusCancelBtn
          onConfirm={() => {

            if (errorAlert.type == "rotate") {
              // const deletedList = rectangleArr.filter((item) => activePage.includes(item.pageId))
              // deletedList.forEach((item) => {
              //   removeCurentElement(`.rectangle-box-${item.unique_id}`)
              // })
              // setRectangleArr((prevState) => prevState.filter((item) => !activePage.includes(item.pageId)))
              rotateRemoveItems()
              setTimeout(() => {
                handleRotate(errorAlert?.rotate)
              }, 0)
            } else if (errorAlert.type == "save") {
              if (rectangleArr.length > 0) {
                saveFunctionSnippet(errorAlert.is_exit)
              }
            } else {
              setTimeout(() => {
                const mainCanvasContainer = document.getElementById("main-canavs-container");
                mainCanvasContainer.scrollTop = 0;
              }, 0)
              handleClearAllData()
              setActivePage([])
              canvasOrginalDetails.length > 0 ? setCanvasDetails(canvasOrginalDetails.map(item => ({ ...item, image: item.original_image, rotate: 0 }))) : setCanvasDetails(prevState => prevState.map(item => ({ ...item, image: item.original_image, rotate: 0 })));
            }

            setErrorAlert({
              status: false,
              message: null,
              type: null,
              is_exit: false

            })
          }}
          onCancel={() => {
            setErrorAlert({
              status: false,
              message: null,
              type: null,
              is_exit: false

            })
          }}

        >
          {errorAlert.type == "save" ? errorAlert.text : errorAlert.type != "reset" ? errorAlert.message : null}
        </SweetAlert>


        : errorAlert.status == "save" ?
          <SweetAlert
            warning
            closeOnClickOutside={false}
            showCancel={errorAlert.status == "save" ? errorAlert.showCancel : true}
            confirmBtnText={errorAlert.status == "save" ? errorAlert.confirmBtnText : "Yes"}
            cancelBtnText="No"
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="light"
            title={errorAlert.type == "reset" || errorAlert.type == "save" ? errorAlert?.message : "Are you sure?"}
            focusCancelBtn
            onConfirm={() => {

              if (errorAlert.type == "rotate") {
                const deletedList = rectangleArr.filter((item) => activePage.includes(item.pageId))
                deletedList.forEach((item) => {
                  removeCurentElement(`.rectangle-box-${item.unique_id}`)
                })
                setRectangleArr((prevState) => prevState.filter((item) => !activePage.includes(item.pageId)))
                setTimeout(() => {
                  handleRotate(errorAlert?.rotate)
                }, 0)
              } else if (errorAlert.type == "save") {
                if (rectangleArr.length > 0) {
                  saveFunctionSnippet(errorAlert.is_exit)
                }
              } else {
                handleClearAllData()
                setCanvasDetails(prevState => prevState.map(item => ({ ...item, image: item.original_image, rotate: 0 })));
              }

              setErrorAlert({
                status: false,
                message: null,
                type: null,
                is_exit: false

              })
            }}
            onCancel={() => {
              setErrorAlert({
                status: false,
                message: null,
                type: null,
                is_exit: false

              })
            }}
          >
            {errorAlert.type == "save" ? errorAlert.text : errorAlert.type != "reset" ? errorAlert.message : null}
          </SweetAlert>
          : null
      }

      <FileInfoModal infoModal={infoModal} setInfoModal={setInfoModal} jobData={jobData} canvasDetails={canvasDetails} />
    </SnippetMainContainer>

  );
}
const SnippetMainContainer = styled.div`
height: 100vh;
a{
/* color:white !important */
}
a.btn.btn-lg.btn-light{
  color:black !important
} 
`;
const MainDivContainer = styled.div`
  position: relative;

  display: flex;
  position: relative;
  margin-top: 10px;
  padding-left: 20px;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* WebKit/Blink browsers */
  -moz-user-select: none; /* Gecko browsers */
  -ms-user-select: none; /* Internet Explorer/Edge */
.page-full-freeze{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: ${(props) => props.isVisible ? "block" : "none"};
  :hover{
    cursor: not-allowed;
  }
;
}
`;
const LeftDivContainer = styled.div`
  width: 15%;
  /* margin-right: 20px; */
`;
const TopColorDivConatiner = styled.div`
  box-shadow: 0px 1px 2px 1px rgb(232 232 232);
  border-radius: 5px;
`;
const BottomClaimContainer = styled.div`
  margin-top: 10px;
  box-shadow: 0px 1px 2px 1px rgb(232 232 232);
  border-radius: 5px;
  padding: 10px 5px;
  .claim-list-container{
    max-height: 50vh;
    overflow-y: auto;

  }
`;
const ClaimHeader = styled.div`
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
  color: #036b87;
`;
const ClaimSingleDivBox = styled.div`
    border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px;
  position: relative;
  border: 1px solid;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  background: green;
  
  background: ${(props) =>
    props.isActive == "completed"
      ? "linear-gradient(rgba(97, 182, 106, 0.5), rgba(97, 182, 106, 0.5))" : props.isActive == "active" ? "rgb(222, 235, 255);"
        : "transparent"};

color:  ${(props) =>
    props.isActive == "completed"
      ? "white" : props.isActive == "active" ? "black"
        : "black"};

  :hover{
    background: rgb(222, 235, 255);;
    color: black;
  }
`;
const DeleteContainer = styled.div`

position: absolute;
  background: rgba(0, 0, 0, 0.09);
  opacity: 1;
  transform: none;
  transition: opacity 223ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 149ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 0;
  right: 0;
  transform-origin: 0px 0px;
  .delete-container{
      display: flex;
      flex-direction: column;
      button{
        color: #575757;
        text-transform: none;
        font-size: 14px;
        text-align: left;
        justify-content: flex-start;
  }
}
`
const DeleteClaimContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  position: absolute;
  display: ${(props) => props.isActive == "completed" ? "block" : 'none'};
  background: rgba(0, 0, 0, 0.09);
  opacity: 1;
  transform: none;
  transition: opacity 223ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 149ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 0;
  right: 0;
  transform-origin: 0px 0px;
  .delete-container{
      display: flex;
      flex-direction: column;
      button{
        color: #575757;
        text-transform: none;
        font-size: 14px;
        text-align: left;
        justify-content: flex-start;
  }
}
`
const SideMenuItem = styled.div`

  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 8px;
  position: relative;
  background: ${(props) =>
    props.isActive
      ? "rgb(222, 235, 255)" :
      !props.disabled ? "rgba(0, 0, 0, 0.09)"
        : "transparent"}; /* Apply background based on isActive prop */
  border-radius: 5px;

  .btn-hove::before,
  ::after {
    content: "";
    position: absolute;
    background-color: ${(prop) => prop.color}; /* Set the color you desire */
    height: 2px; /* Set the height of the line */
    width: 0;
    transition-duration: 0.4s;
  }

  ::before {
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }

  ::after {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  :hover::before,
  :hover::after {
    width: 100%;
    width:${(prop) => !prop.disabled ? "0%" : "100%"}

  }
  ::before,
  ::after {
    width: ${(props) => (props.isActive ? "100%" : "0")};
  }

  span{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid ${(prop) => !prop.disabled ? "rgba(0, 0, 0, 0.09)" : prop.color};
    background-color: ${(prop => prop.isSelected ? prop.color : "unset")};
    color:${(prop) => prop.isSelected ? "white" : "black"}
  }
`;

const CanvasReactangleBox = styled.div`
  position: absolute;
  // width: ${(prop) => prop.width}px;
  // height: ${(prop) => prop.height}px;
`;
const BlurBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.5)
  );
  z-index: 1;
  display: ${(props) => props.isVisible};
  align-items: center;
  justify-content: center;
  color: white;
`;
const PageSelectedStyle = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.5)
  );  z-index: 1;
  display: ${(props) => props.isVisible};
  align-items: center;
  justify-content: center;
  color: white;

  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)) 1;
  z-index: 1;
  display: ${(props) => props.isVisible};
  align-items: center;
  justify-content: center;
  color: white; */
`;
const ButtonIconConatiner = styled.div`
  padding-left:15px;
  /* display: flex; */
  display: ${(props) => props.isOpen ? "none" : "flex"};
  justify-content: space-between;
  margin-bottom: 10px;
  width: 40%;

  transition: all 0.4s ease-in-out;
  align-items: center;
  button.re-assign{
      width: 95px !important;

    }
  button.save-exit-button{
      width: 95px !important;

    }

  div:hover {
    color: #000000;
    box-shadow: 0px 0px 9px #595959;
  }
  div {
    width: 36px;
    height: 36px;
    background: rgba(0, 0, 0, 0.09);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    svg {
      font-size: 16px;
    }
  }
`;
const DragContainer = styled.div`
/* background: rgba(0, 0, 0, 0.09) */
background: ${(props) => props.isActive ? "rgb(222, 235, 255)" : "rgba(0, 0, 0, 0.09)"} !important;
box-shadow: ${(props) => props.isActive ? "0px 0px 9px #595959" : "unset"} !important;

`

const MainSingleContainer = styled.div`
position: relative;
  /* width: 200px; */
  transition: transform 0.5s ease-in-out;
  width: ${(props) => props.zoomWidth}%;
  /* height: ${(props) => props.rotateHeight}; */

  /* height: 200px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .loader-container{
    width: 100%;
    height: 100%;
    background-color: black;
  }
`
const CanvasContainer = styled.div`
    .dragging {
        opacity: 0.5;
    }

    .selected {
        border: 1px solid blue !important;
    }
  img{
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  .rectangle-box {
    position: absolute;
    top: 0;

  }
.common-rectangle-box{
  display: flex;
  align-items: flex-start;
  justify-content: end;
  .order-item-box{
    font-weight: bold;
    font-size: 10px;
    /* width: 5%;
    height: 5%; */
    border-radius: 10%;
    background: linear-gradient(rgb(209 205 205 / 50%), rgb(213 206 206 / 50%));
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.countBoxColor};
  }
}
.rectangle-check-container, .rectangle-payer-container,.rectangle-payee-container,.rectangle-claim-header-container,.rectangle-claim-container,.rectangle-footer-container,.rectangle-blackout-container{
    display: flex;
    align-items: flex-start;
    justify-content: end;

    .resize-handle {
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #fff;
    border: 1px solid #000;
    cursor: pointer;
    display: none; /* Initially hide the resize handles */
}

.resize-handle.top-left {
    top: -2px;
    left: -2px;
    cursor: nwse-resize; /* Resize cursor for top-left corner */
}

.resize-handle.top-right {
    top: -2px;
    right: -2px;
    cursor: nesw-resize; /* Resize cursor for top-right corner */
}

.resize-handle.bottom-left {
    bottom: -2px;
    left: -2px;
    cursor: nesw-resize; /* Resize cursor for bottom-left corner */
}

.resize-handle.bottom-right {
    bottom: -2px;
    right: -2px;
    cursor: nwse-resize; /* Resize cursor for bottom-right corner */
}
.order-item-box{
    /* position: absolute;
    top: 50%;
    left:50%; */
    font-weight: bold;
    font-size: 12px;
    /* width: 25px;
    height: 25px; */
    border-radius: 10%;
    background: linear-gradient(rgb(209 205 205 / 50%), rgb(213 206 206 / 50%));
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.countBoxColor};
}
:hover{
    border: 2px dotted;
}
:hover .resize-handle {
    display: block; 
}
  }
  .hover-drag-line{
  font-size: 60px;
  color:rgb(107, 162, 182);
  height: 100%;
  position: absolute;
  .left-arrow{
    display: none;
    height: 100%;
    width: 14px;

  }
  .right-arrow{
    display: none;
    height: 100%;
    width: 14px;


  }
}
`
const SaveButton = styled.button`   
    color: #fff;
    border-color: #6ba2b6;
    background-color: #6ba2b6 !important;
    font-size: 0.9375rem;
    font-weight: 500;
    border-radius: 5px;
    width: 60px;
    height: 30px;


`

const TextAreaField = styled(Overlay)`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;

const MainCanvasContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: ${({ isOpen }) => (isOpen ? 'center' : 'left')};
  overflow-y: scroll;
  padding-left: ${({ isOpen }) => (isOpen ? '5px' : '15px')};
  padding-right: ${({ isOpen }) => (isOpen ? '5px' : 'unset')};
  gap: 25px;
  max-height: 90vh;
  min-height: 20vh;
  scroll-behavior: ${({ isDraging }) => (isDraging ? 'unset !important' : 'smooth')};

`;