import { Plugin, RenderViewer } from '@react-pdf-viewer/core';

export function today_date() {
  var today = new Date();
  today.setDate(today.getDate());
  var Today = today.toISOString().substr(0, 10);
  return Today;
}
export const get_index = (list, id) => {
  let index = null;
  if (list) {
    index = list.findIndex((i) => i.id === id);
  }
  return index;
};



export interface PageThumbnailPluginProps {
  PageThumbnail: React.ReactElement;
}

export const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
  const { PageThumbnail } = props;

  return {
    renderViewer: (renderProps: RenderViewer) => {
      let { slot } = renderProps;

      slot.children = PageThumbnail;

      slot.subSlot.attrs = {};
      slot.subSlot.children = <></>;

      return slot;
    },
  };
};

export const getPageHead = (link) => {
  if (link == '/home') {
    return 'DashBoard';
  }
  else if (link == '/admin/dashboard') {
    return 'DashBoard';
  }
  else if (link == '/view/roles' || link == '/view/create' || link == '/edit-role/') {
    return 'Roles';
  }
  else if (link == '/view/groups') {
    return 'Groups';
  }
  else if (link == '/view/users') {
    return 'Users';
  }
  else if (link == '/view/payees') {
    return 'Payee';
  }
  else if (link == '/view/payers') {
    return 'Payer';
  }
  else if (link == '/my-work') {
    return 'My-Work';
  }
  else if (link == '/view/clients') {
    return 'Clients';
  }
  else if (link == '/view/client-groups') {
    return 'Client Groups';
  }
  else if (link == '/view/sftp-client-list') {
    return 'Clients';
  }
  else if (link == '/view/file-split') {
    return 'File Split';
  }
  else if (link == '/all/lockbox-files') {
    return 'Lockbox';
  }
  else if (link == '/view/reason-codes') {
    return 'Reason Codes';
  }
  else if (link == '/view/x12-codes') {
    return 'X12 Codes';
  }
  else if (link == '/view/correspondence-categories') {
    return 'Correspondence Category';
  }
  else if (link == '/view/reject-reasons') {
    return 'Reject Reasons';
  }
  else if (link == '/view/holiday-calendar') {
    return 'Holiday Calendar';
  }
  else if (link == '/view/settings') {
    return 'Settings';
  }
  else if (link == '/queue') {
    return 'Queue';
  }
  else if (link == '/payment/get-patient-pay') {
    return 'Patient Pay';
  }
  else if (link == '/payment/get-correspondence') {
    return 'Correspondence';
  }
  else if (link == '/encounter/get-encounter-list') {
    return 'Encounter List';
  }
  else if (link == '/payment/get-output-file-schema') {
    return 'Output Schema';
  }
  else if (link == '/payment/get-835-lite-schema') {
    return '835 Lite';
  }

}
export async function DownloadJsonUrl(event, fileUrl) {
  event.preventDefault();
  try {
    const response = await fetch(fileUrl, { mode: "no-cors" });
    const data = await response.text();
    const blob = new Blob([data], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "sample_input_file_schema.json";
    link.click();
  } catch (err) {
  }
}

function extractFileNameAndExtension(url) {
  const fileNameStartIndex = url.lastIndexOf('/') + 1;
  const fileNameEndIndex = url.lastIndexOf('?');
  const fileName = url.substring(fileNameStartIndex, fileNameEndIndex);

  const extensionStartIndex = url.lastIndexOf('.') + 1;
  const extension = url.substring(extensionStartIndex, fileNameEndIndex);

  return {
    fileName,
    extension,
  };
}

export async function DownloadXmlUrl(event, fileUrl) {
  event.preventDefault();
  try {
    const response = await fetch(fileUrl);
    const xmlData = await response.text();
    const blob = new Blob([xmlData], { type: 'text/xml' });

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    const { fileName, extension } = extractFileNameAndExtension(fileUrl)
    link.download = `${fileName}.${extension}`;

    link.click();

    URL.revokeObjectURL(link.href);
  } catch (err) {
  }
}

export const checkDbkValidation = (value1, dbk_value1, is_permission, type = "text") => {
  let value = value1 == null ? "" : value1
  let dbk_value = dbk_value1 == null ? "" : dbk_value1
  let is_valid = false

  if (is_permission) {
    if (type === "text" && String(value).toUpperCase() != String(dbk_value).toUpperCase()) {
      is_valid = true
    } else if (type === "number" && String(value).replace(NaN, "") != String(dbk_value).replace(NaN, "")) {
      is_valid = true
    } else if (type === "date" && value?.replace("__/__/____", "") !=
      dbk_value?.replace("__/__/____", "")) {
      is_valid = true
    }
  }
  return is_valid
}


export function isActiveDbkField(obj, formik, isFieldPermission) {

  let isActive = Object?.values(obj)?.some(val => val === true) ||
    obj?.claims?.some(claim => Object?.values(claim)?.some(val => val === true));


  let isError = Object.values(formik?.errors)?.some(val => val === 'Dont Match') ||

    formik?.errors && formik?.errors?.claims && formik?.errors?.claims?.some(claim => claim ? Object.values(claim)?.some(val => val === 'Dont Match') : false);

  return isActive && isError
}

export function disablingBasedOnDbk(isView, isDbkValid, isButtonValidate, container, subJobEobContainer, isSubJob, isFirstJob) {
  if (!isView && isDbkValid && container) {
    const main_buttons_list = container.getElementsByTagName('button');

    for (let i = 0; i < main_buttons_list.length; i++) {
      if (i > 6) {
        main_buttons_list[i].disabled = true;
      }
    }
    const inputs = container.getElementsByTagName("input");
    const focusedElement = document.activeElement;

    for (var i = 0; i < inputs.length; i++) {

      if (inputs[i] !== focusedElement && !inputs[i].closest('.dbk-main-container') && !inputs[i].classList.contains('never-disable-field')) {
        inputs[i].disabled = true;

      }
    }

    const selects = container.getElementsByTagName("select");

    for (var i = 0; i < selects.length; i++) {
      selects[i].disabled = true;
    }
  }
  else if (isView && container) {

    const main_buttons_list = container.getElementsByTagName('button');

    for (let i = 0; i < main_buttons_list.length; i++) {
      if (!main_buttons_list[i].closest('.exclude-disabled')) {
        main_buttons_list[i].disabled = true;
      }

    }
    const inputs = container.getElementsByTagName("input");

    for (var i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true;
    }
    const selects = container.getElementsByTagName("select");
    for (var i = 0; i < selects.length; i++) {
      selects[i].disabled = true;
    }
  }
  else if (container) {

    const main_buttons_list = container.getElementsByTagName('button');
    const inputs = container.getElementsByTagName("input");

    const eobinputs = subJobEobContainer?.getElementsByTagName("input");
    const eobselects = subJobEobContainer?.getElementsByTagName("select");
    for (var i = 0; i < inputs.length; i++) {
      const name = inputs[i].name;
      if (!inputs[i].closest('.remainingbln-wi')) {
        if (inputs[i].getAttribute('data-disabled') == 'true') {
          inputs[i].disabled = true;
        } else {
          if (inputs[i].getAttribute('data-corress-disable') == 'true') {
            inputs[i].disabled = true;
          }
          else {
            inputs[i].disabled = false;
          }
        }
      }
      else {
        if (inputs[i].getAttribute('data-corress-disable') == 'true') {
          inputs[i].disabled = true;
        }
      }
      if (inputs[i]) {
        inputs[i].setAttribute('autocomplete', 'one-time-code')
      }
      if (inputs[i].getAttribute('data-corress-disable') == 'true') {
        inputs[i].disabled = true;
      }
    }

    const selects = container.getElementsByTagName("select");

    for (var i = 0; i < selects.length; i++) {
      selects[i].disabled = false;
      if (selects[i].getAttribute('data-corress-disable') == 'true') {
        selects[i].disabled = true;
      }
    }

    const button_len = isButtonValidate ? main_buttons_list.length : main_buttons_list.length - 2

    for (let i = 0; i < button_len; i++) {
      if (main_buttons_list[i].getAttribute('data-save-loading') == 'true') {
        main_buttons_list[i].disabled = true;
      }
      else if (main_buttons_list[i].getAttribute('data-check-view') == 'true') {
        main_buttons_list[i].disabled = true;
      }
      else {
        main_buttons_list[i].disabled = false;
      }
    }

    if (!isSubJob && isFirstJob || isSubJob && !isFirstJob && eobinputs?.length > 0) {

      for (var i = 0; i < eobinputs?.length; i++) {
        eobinputs[i].disabled = true;
      }

      for (var i = 0; i < eobselects?.length; i++) {
        eobselects[i].disabled = true;
      }
      const buttonElement = document.getElementById("plb-adjustment-button")
      if (buttonElement) {
        buttonElement.disabled = true;
      }
    }
  }
}

export function disablingBasedOnGridView(container) {

  if (container) {

    const main_buttons_list = container.getElementsByTagName('button');

    for (let i = 0; i < main_buttons_list.length; i++) {

      main_buttons_list[i].disabled = true;

    }
    const inputs = container.getElementsByTagName("input");
    const selects = container.getElementsByTagName("select");

    for (var i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true;


    }
    for (var i = 0; i < selects.length; i++) {
      selects[i].disabled = true;


    }
  }
}

export function getDataFromDataTabs(id) {
  var iframe = parent.document.getElementById(`myIframe${id}`);
  let data = null
  if (iframe) {
    data = iframe.getAttribute('data-tabs');
    alert('INSIDE FUNCTION')
    data = JSON.parse(bbtabs);
  }
  return data
}


export function downloadFile(url, name) {
  fetch(url)
    .then(async response => {
      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition ? contentDisposition.split('filename=')[1] : name;

      return response.blob().then(blob => {
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute('download', filename);

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
}


export const changeAllTitleFunction = (linkText, linkUrl, isRedirectId = null) => {
  window.parent.localStorage.setItem('currentTitle', linkText)
  window.parent.localStorage.setItem('currentUrl', linkUrl)
  window.parent.localStorage.setItem('isRedirectId', isRedirectId)
  var titlebtn = parent.document.getElementById('frame-title-btn');
  if (titlebtn) {
    titlebtn.click()
  }
}

export const setTriggerIframePageLoad = () => {
  var titlebtn = parent.document.getElementById('frame-page-load');
  if (titlebtn) {
    titlebtn.click()
  }
}

export const getRedirectTabId = () => {
  const copiedData = structuredClone(window.parent.localStorage.getItem('bottomTab'))
  return JSON.parse(copiedData)?.currentTab?.id
}

export const setfiltrationHistoryBottomTab = (data) => {
  window.parent.localStorage.setItem('filterData', data)
  const filtration_button = parent.document.getElementById('frame-filtration-tabs-btn');
  if (filtration_button) {
    filtration_button.click()
  }
}


export const disableBasedOnSubJob = (subJobEobContainer) => {
  if (subJobEobContainer) {

    const inputs = subJobEobContainer.getElementsByTagName("input");
    const selects = subJobEobContainer.getElementsByTagName("select");


    for (var i = 0; i < inputs.length; i++) {
      inputs[i].disabled = true;
    }

    for (var i = 0; i < selects.length; i++) {
      selects[i].disabled = true;
    }



  }
}

export const toCamelCase = (str) => {
  return str && str
    ?.split(/[_-\s]/)
    .map((word, index) =>
      index === 0
        ? word.charAt(0).toLowerCase() + word.slice(1)
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join('');
};

export function getNextFocusableElement(currentElement, type) {
  const gridContainer = document.getElementById("grid-full-container");
  if (type == 'tab') {
    const focusableElements = Array.from(gridContainer.querySelectorAll('[tabindex="0"]:not([data-corress-disable="true"])'));

    var currentIndex = focusableElements.indexOf(currentElement);
    if (currentIndex !== -1) {
      var nextIndex = (currentIndex + 1) % focusableElements.length;
      return focusableElements[nextIndex];
    }

  } else if (type == 'shift') {
    var inputs = Array.prototype.slice.call(gridContainer.querySelectorAll("input, select"));
    var filteredInputs = inputs.filter(function (input) {
      return !input.classList.contains("prevent-shift") && input?.getAttribute('data-corress-disable') !== "true";
    });
    const currInputIndex = filteredInputs.indexOf(currentElement);
    const input = filteredInputs[currInputIndex - 1];
    return input
  }

  return null;
}