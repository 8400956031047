import React from 'react'
import Modal from 'react-modal';

const HotKey = (props) => {
    const { hotKey, setHotKey, setIsDrag } = props;
    function __afterOpenModal() {
        subtitle.style.color = '#f00';
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "90%",
        },
    };
    return (
        <React.Fragment>
            <Modal
                isOpen={hotKey}
                onAfterOpen={__afterOpenModal}
                onRequestClose={() => { setHotKey(false); setIsDrag(true) }}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <ul className="w-600 list-group">
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-6">Shift+D</div>
                        <div className="col-lg-6 text-nowrap">Move to discount field in the service line. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Shift+P</div>
                        <div className="col-lg-6 text-nowrap">Move to paid field in the service line. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Ctrl+Shift+S</div>
                        <div className="col-lg-6 text-nowrap">Add a new service line. </div>
                    </li>
                    {/* <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Shift +</div>
                        <div className="col-lg-6 text-nowrap">Add a new service line. </div>
                    </li> */}
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Ctrl+Mouse Click</div>
                        <div className="col-lg-6 text-nowrap">Press "Ctrl" in the service line section to open a new popup "Small Box" and enter the needed number inside the box to create the mentioned service line. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Alt+Shift+C</div>
                        <div className="col-lg-6 text-nowrap">Add a New claim. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Alt+Shift+Y</div>
                        <div className="col-lg-6 text-nowrap">It adds a new Patient Pay transaction. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Alt+Shift+P</div>
                        <div className="col-lg-6 text-nowrap">Creates a new Payment835 transaction. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Alt+Shift+R</div>
                        <div className="col-lg-6 text-nowrap">Adds a New Correspondence Transaction. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">F7</div>
                        <div className="col-lg-6 text-nowrap">To check and pull  837, Payer and Payee </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Tab</div>
                        <div className="col-lg-6 text-nowrap">Move to next field</div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Shift + Tab</div>
                        <div className="col-lg-6">moved back to the previous field</div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Shift + Delete</div>
                        <div className="col-lg-6">To delete Adjustment</div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Alt + R</div>
                        <div className="col-lg-6">To open the reject option</div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Esc</div>
                        <div className="col-lg-6 text-nowrap">To disable Payer, Payee, 837, Claim Summary, Pc Popup, Key Box.</div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Alt + H</div>
                        <div className="col-lg-6">To open the Hotkeys menu.</div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Ctrl + Z</div>
                        <div className="col-lg-6">Undo the previous action.</div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Alt + S</div>
                        <div className="col-lg-6">Save.</div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Ctrl + Space</div>
                        <div className="col-lg-6">Get default value.</div>
                    </li>
                    <li className="row d-flex flex-row align-items-center  mb-2 ">
                        <div className="col-lg-6">Alt+N</div>
                        <div className="col-lg-6 text-nowrap">Add a New Bottom Tab. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Alt+X</div>
                        <div className="col-lg-6 text-nowrap">Remove The Current Tab. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Ctrl+Tab</div>
                        <div className="col-lg-6 text-nowrap">Switch bottom tab To Forward. </div>
                    </li>
                    <li className="row d-flex flex-row align-items-center mb-2">
                        <div className="col-lg-6">Ctrl+Shift+Tab</div>
                        <div className="col-lg-6 text-nowrap">Switch Bottom Tab To Backward. </div>
                    </li>

                    <div className="checkviewbtn text-start">
                        <button className="btn btn-primary" onClick={() => { setHotKey(false); setIsDrag(true) }}>close</button>
                    </div>
                </ul>
            </Modal>
        </React.Fragment>

    )
}

export default HotKey