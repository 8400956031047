import { useFormik, Field } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Select from "react-select";
import { createOrUpdateDbk } from "../../../Redux/client/action";

const DbkForm = (props) => {
  // let { is_payer_lockbox } = useParams();
  const [data, setData] = useState("");
  const [state, setState] = useState({
    error: false,
    error_message: "",
  });
  const navigate = useNavigate();
  const {
    dbk_data,
    client,
    __toggleToast,
    setLoading,
    fetchUserWiseDbk,
    handleChange,
    values,
    errors,
    setValues,
    setErrors,
  } = props;

  const [selectedValues, SetSelectedValues] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  // const dbkformik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     payment_check_number: false,
  //     payment_check_date: false,
  //     payment_check_amount: false,
  //     payment_payer_name: false,
  //     payment_payer_address: false,
  //     payment_payer_city: false,
  //     payment_payer_state: false,
  //     payment_payer_zip: false,
  //     payment_payee_name: false,
  //     payment_payee_npi: false,
  //     payment_payee_address: false,
  //     payment_payee_city: false,
  //     payment_payee_state: false,
  //     payment_payee_zip: false,
  //     payment_patient_acc_number: false,
  //     payment_payer_claim_number: false,
  //     payment_mrn_number: false,
  //     payment_statement_number: false,
  //     payment_statement_date: false,
  //     payment_patient_first_name: false,
  //     payment_patient_middle_name: false,
  //     payment_patient_last_name: false,
  //     payment_patient_identifier_code: false,
  //     payment_subscriber_first_name: false,
  //     payment_subscriber_middle_name: false,
  //     payment_subscriber_last_name: false,
  //     payment_subscriber_identifier_code: false,
  //     payment_rendering_provider_first_name: false,
  //     payment_rendering_provider_last_name: false,
  //     payment_rendering_provider_identifier: false,
  //     payment_serviceline_from_date: false,
  //     payment_serviceline_to_date: false,
  //     payment_serviceline_procedure: false,
  //     payment_serviceline_rev_code: false,
  //     payment_serviceline_units: false,
  //     payment_serviceline_billed: false,
  //     payment_serviceline_discount: false,
  //     payment_serviceline_allowed: false,
  //     payment_serviceline_deduct: false,
  //     payment_serviceline_coins: false,
  //     payment_serviceline_copay: false,
  //     payment_serviceline_patres: false,
  //     payment_service_adjust_amt: false,
  //     payment_service_adjust_pc: false,
  //     payment_service_adjust_rc: false,
  //     payment_service_adjust_rmk: false,
  //     payment_service_line_cob: false,
  //     payment_service_line_balance: false,

  //     paymentlite_check_number: false,
  //     paymentlite_check_date: false,
  //     paymentlite_check_amount: false,
  //     paymentlite_payer_name: false,
  //     paymentlite_payee_name: false,
  //     paymentlite_patient_acc_number: false,
  //     paymentlite_patient_first_name: false,
  //     paymentlite_patient_middle_name: false,
  //     paymentlite_patient_last_name: false,
  //     paymentlite_serviceline_period_start: false,
  //     paymentlite_serviceline_period_end: false,
  //     paymentlite_serviceline_billed: false,
  //     paymentlite_serviceline_paid: false,

  //     patientpay_check_number: false,
  //     patientpay_check_date: false,
  //     patientpay_check_amount: false,
  //     patientpay_patient_acc_number: false,
  //     patientpay_patient_first_name: false,
  //     patientpay_patient_middle_name: false,
  //     patientpay_patient_last_name: false,
  //     patientpay_payment_amt_due: false,
  //     patientpay_payment_statement_amount: false,
  //     patientpay_payment_statement_number: false,
  //     patientpay_payment_statement_date: false,

  //     correspondence_payer_name: false,
  //     correspondence_letter_date: false,
  //     correspondence_patient_acc_number: false,
  //     correspondence_period_start: false,
  //     correspondence_period_end: false,
  //     correspondence_billed_amount: false,
  //     correspondence_patient_first_name: false,
  //     correspondence_patient_middle_name: false,
  //     correspondence_patient_last_name: false,
  //   },
  //   DbkSchema,
  //   onSubmit: (values) => {
  //     setIsSubmit(true);
  //     setLoading(true);
  //     // console.log(props.client_id);
  //     let formData = new FormData(); //formdata object

  //     // formData.append('client', client);
  //     console.log(values);
  //     values.client = client;
  //     if (dbk_data?.dbk_fields?.id) {
  //       values.dbk_id = dbk_data?.dbk_fields?.id;
  //     }
  //     createOrUpdateDbk(values)((response) => {
  //       if (
  //         !response?.data?.status &&
  //         response?.status &&
  //         response?.status_code === 200
  //       ) {
  //         __toggleToast("success", response?.message, true);
  //         setLoading(false);
  //         setIsSubmit(false);
  //         fetchUserWiseDbk();
  //       } else {
  //         const errors = response.response?.data?.errors;
  //         setErrors({ errors });
  //         // props.changeState(response);
  //         setLoading(false);
  //         setIsSubmit(false);
  //         __toggleToast(
  //           "danger",
  //           response?.response?.data?.message
  //             ? response?.response?.data?.message
  //             : "Something Went Wrong",
  //           true
  //         );
  //       }
  //     });
  //   },
  // });

  useEffect(() => {
    if (dbk_data?.dbk) {
      setValues({
        payment_check_number: dbk_data?.dbk?.payment_check_number,
        payment_check_date: dbk_data?.dbk?.payment_check_date,
        payment_check_amount: dbk_data?.dbk?.payment_check_amount,
        payment_payer_name: dbk_data?.dbk?.payment_payer_name,
        payment_payer_address: dbk_data?.dbk?.payment_payer_address,
        payment_payer_city: dbk_data?.dbk?.payment_payer_city,
        payment_payer_state: dbk_data?.dbk?.payment_payer_state,
        payment_payer_zip: dbk_data?.dbk?.payment_payer_zip,
        payment_payee_name: dbk_data?.dbk?.payment_payee_name,
        payment_payee_npi: dbk_data?.dbk?.payment_payee_npi,
        payment_payee_address: dbk_data?.dbk?.payment_payee_address,
        payment_payee_city: dbk_data?.dbk?.payment_payee_city,
        payment_payee_state: dbk_data?.dbk?.payment_payee_state,
        payment_payee_zip: dbk_data?.dbk?.payment_payee_zip,
        payment_patient_acc_number:
          dbk_data?.dbk?.payment_patient_acc_number,
        payment_payer_claim_number:
          dbk_data?.dbk?.payment_payer_claim_number,
        payment_mrn_number:
          dbk_data?.dbk?.payment_mrn_number,
        payment_statement_number:
          dbk_data?.dbk?.payment_statement_number,
        payment_statement_date:
          dbk_data?.dbk?.payment_statement_date,
        payment_patient_first_name:
          dbk_data?.dbk?.payment_patient_first_name,
        payment_patient_middle_name:
          dbk_data?.dbk?.payment_patient_middle_name,
        payment_patient_last_name: dbk_data?.dbk?.payment_patient_last_name,
        payment_patient_identifier_code:
          dbk_data?.dbk?.payment_patient_identifier_code,
        payment_subscriber_first_name:
          dbk_data?.dbk?.payment_subscriber_first_name,
        payment_subscriber_middle_name:
          dbk_data?.dbk?.payment_subscriber_middle_name,
        payment_subscriber_last_name:
          dbk_data?.dbk?.payment_subscriber_last_name,
        payment_subscriber_identifier_code:
          dbk_data?.dbk?.payment_subscriber_identifier_code,
        payment_rendering_provider_first_name:
          dbk_data?.dbk?.payment_rendering_provider_first_name,
        payment_rendering_provider_last_name:
          dbk_data?.dbk?.payment_rendering_provider_last_name,
        payment_rendering_provider_identifier:
          dbk_data?.dbk?.payment_rendering_provider_identifier,
        payment_serviceline_from_date:
          dbk_data?.dbk?.payment_serviceline_from_date,
        payment_serviceline_to_date:
          dbk_data?.dbk?.payment_serviceline_to_date,
        payment_serviceline_procedure:
          dbk_data?.dbk?.payment_serviceline_procedure,
        payment_serviceline_rev_code:
          dbk_data?.dbk?.payment_serviceline_rev_code,
        payment_serviceline_units: dbk_data?.dbk?.payment_serviceline_units,
        payment_serviceline_billed:
          dbk_data?.dbk?.payment_serviceline_billed,
        payment_serviceline_discount:
          dbk_data?.dbk?.payment_serviceline_discount,
        payment_serviceline_allowed:
          dbk_data?.dbk?.payment_serviceline_allowed,
        payment_serviceline_deduct:
          dbk_data?.dbk?.payment_serviceline_deduct,
        payment_serviceline_coins: dbk_data?.dbk?.payment_serviceline_coins,
        payment_serviceline_copay: dbk_data?.dbk?.payment_serviceline_copay,
        payment_serviceline_patres:
          dbk_data?.dbk?.payment_serviceline_patres,
        payment_service_adjust_amt:
          dbk_data?.dbk?.payment_service_adjust_amt,
        payment_service_adjust_pc: dbk_data?.dbk?.payment_service_adjust_pc,
        payment_service_adjust_rc: dbk_data?.dbk?.payment_service_adjust_rc,
        payment_service_adjust_rmk:
          dbk_data?.dbk?.payment_service_adjust_rmk,
        payment_service_line_cob: dbk_data?.dbk?.payment_service_line_cob,
        payment_service_line_balance:
          dbk_data?.dbk?.payment_service_line_balance,
        paymentlite_check_number: dbk_data?.dbk?.paymentlite_check_number,
        paymentlite_check_date: dbk_data?.dbk?.paymentlite_check_date,
        paymentlite_check_amount: dbk_data?.dbk?.paymentlite_check_amount,
        paymentlite_payer_name: dbk_data?.dbk?.paymentlite_payer_name,
        paymentlite_payee_name: dbk_data?.dbk?.paymentlite_payee_name,
        paymentlite_patient_acc_number:
          dbk_data?.dbk?.paymentlite_patient_acc_number,
        paymentlite_patient_first_name:
          dbk_data?.dbk?.paymentlite_patient_first_name,
        paymentlite_patient_middle_name:
          dbk_data?.dbk?.paymentlite_patient_middle_name,
        paymentlite_patient_last_name:
          dbk_data?.dbk?.paymentlite_patient_last_name,
        paymentlite_serviceline_period_start:
          dbk_data?.dbk?.paymentlite_serviceline_period_start,
        paymentlite_serviceline_period_end:
          dbk_data?.dbk?.paymentlite_serviceline_period_end,
        paymentlite_serviceline_billed:
          dbk_data?.dbk?.paymentlite_serviceline_billed,
        paymentlite_serviceline_paid:
          dbk_data?.dbk?.paymentlite_serviceline_paid,
        patientpay_check_number: dbk_data?.dbk?.patientpay_check_number,
        patientpay_check_date: dbk_data?.dbk?.patientpay_check_date,
        patientpay_check_amount: dbk_data?.dbk?.patientpay_check_amount,
        patientpay_patient_acc_number:
          dbk_data?.dbk?.patientpay_patient_acc_number,
        patientpay_patient_first_name:
          dbk_data?.dbk?.patientpay_patient_first_name,
        patientpay_patient_middle_name:
          dbk_data?.dbk?.patientpay_patient_middle_name,
        patientpay_patient_last_name:
          dbk_data?.dbk?.patientpay_patient_last_name,
        patientpay_payment_amt_due:
          dbk_data?.dbk?.patientpay_payment_amt_due,
        patientpay_payment_statement_amount:
          dbk_data?.dbk?.patientpay_payment_statement_amount,
        patientpay_payment_statement_number:
          dbk_data?.dbk?.patientpay_payment_statement_number,
        patientpay_payment_statement_date:
          dbk_data?.dbk?.patientpay_payment_statement_date,
        correspondence_payer_name: dbk_data?.dbk?.correspondence_payer_name,
        correspondence_letter_date:
          dbk_data?.dbk?.correspondence_letter_date,
        correspondence_patient_acc_number:
          dbk_data?.dbk?.correspondence_patient_acc_number,
        correspondence_period_start:
          dbk_data?.dbk?.correspondence_period_start,
        correspondence_period_end: dbk_data?.dbk?.correspondence_period_end,
        correspondence_billed_amount:
          dbk_data?.dbk?.correspondence_billed_amount,
        correspondence_patient_first_name:
          dbk_data?.dbk?.correspondence_patient_first_name,
        correspondence_patient_middle_name:
          dbk_data?.dbk?.correspondence_patient_middle_name,
        correspondence_patient_last_name:
          dbk_data?.dbk?.correspondence_patient_last_name,
      });
    }
  }, [dbk_data?.dbk]);
  // console.log(dbk_data?.dbk_fields);

  const DbkSchema = Yup.object().shape({
    payment_check_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_check_date: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_check_amount: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payer_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payer_address: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payer_city: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payer_state: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payer_zip: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payee_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payee_npi: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payee_address: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payee_city: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payee_state: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payee_zip: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_patient_acc_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_payer_claim_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_mrn_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_statement_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_statement_date: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_patient_first_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_patient_middle_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_patient_last_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_patient_identifier_code: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_subscriber_first_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_subscriber_middle_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_subscriber_last_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_subscriber_identifier_code: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_rendering_provider_first_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_rendering_provider_last_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_rendering_provider_identifier: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_from_date: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_to_date: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_procedure: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_rev_code: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_units: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_billed: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_discount: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_allowed: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_deduct: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_coins: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_copay: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_serviceline_patres: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_service_adjust_amt: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_service_adjust_pc: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_service_adjust_rc: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_service_adjust_rmk: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_service_line_cob: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    payment_service_line_balance: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_check_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_check_date: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_check_amount: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_payer_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_payee_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_patient_acc_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_patient_first_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_patient_middle_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_patient_last_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_serviceline_period_start: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_serviceline_period_end: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_serviceline_billed: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    paymentlite_serviceline_paid: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_check_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_check_date: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_check_amount: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_patient_acc_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_patient_first_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_patient_middle_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_patient_last_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_payment_amt_due: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_payment_statement_amount: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_payment_statement_date: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    patientpay_payment_statement_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    correspondence_payer_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    correspondence_letter_date: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    correspondence_patient_acc_number: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    correspondence_period_start: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    correspondence_period_end: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    correspondence_billed_amount: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    correspondence_patient_first_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    correspondence_patient_middle_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
    correspondence_patient_last_name: Yup.bool().oneOf(
      [true, false],
      "Select either on of these"
    ),
  });
  const __handleDBKSubmit = (values) => {
    setIsSubmit(true);
    setLoading(true);
    let formData = new FormData(); //formdata object

    // formData.append('client', client);
    values.client = client;
    if (dbk_data?.dbk_fields?.id) {
      values.dbk_id = dbk_data?.dbk_fields?.id;
    }
    createOrUpdateDbk(values)((response) => {
      if (
        !response?.data?.status &&
        response?.status &&
        response?.status_code === 200
      ) {
        __toggleToast("success", response?.message, true);
        setLoading(false);
        setIsSubmit(true);
        fetchUserWiseDbk();
      } else {
        const errors = response.response?.data?.errors;
        setErrors({ errors });
        // props.changeState(response);
        setLoading(false);
        setIsSubmit(false);
        __toggleToast(
          "danger",
          response?.response?.data?.message
            ? response?.response?.data?.message
            : "Something Went Wrong",
          true
        );
      }
    });
  };

  const checkUserPermission = (check) => {
    var permissions = JSON.parse(localStorage.getItem("permissions"));
    var data = permissions.find((element) => {
      return element == check;
    });
    // // console.log(data)
    if (data && data != undefined) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      {/* <Form onSubmit={handleSubmit}> */}
      <div id="dbk-tab-container" className="group">
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="pills-EOB-835"
              data-bs-toggle="pill"
              data-bs-target="#EOB-835"
              type="button"
              role="tab"
              aria-controls="EOB-835"
              aria-selected="true"
            >
              EOB 835
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-EOB-835-Lite"
              data-bs-toggle="pill"
              data-bs-target="#EOB-835-Lite"
              type="button"
              role="tab"
              aria-controls="EOB-835-Lite"
              aria-selected="false"
            >
              EOB 835 Lite
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-patient-pay"
              data-bs-toggle="pill"
              data-bs-target="#patient-pay"
              type="button"
              role="tab"
              aria-controls="patient-pay"
              aria-selected="false"
            >
              Patient Pay
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="pills-correspondence"
              data-bs-toggle="pill"
              data-bs-target="#correspondence"
              type="button"
              role="tab"
              aria-controls="correspondence"
              aria-selected="false"
            >
              Correspondence
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="EOB-835"
            role="tabpanel"
            aria-labelledby="pills-EOB-835"
          >
            {/* EOB 835 Start Heare */}
            <Form.Label className="fw-bold clr-heading">EOB 835</Form.Label>
            <div className="dbk-grid-repeat gap-3 pb-3 border-bottom-dashed">

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_check_number}
                  isInvalid={
                    errors?.payment_check_number &&
                    !!errors?.payment_check_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_check_number &&
                    errors?.payment_check_number
                  }

                  name="payment_check_number"
                  label="Check Number"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_check_date}
                  isInvalid={
                    errors?.payment_check_date &&
                    !!errors?.payment_check_date
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_check_date &&
                    errors?.payment_check_date
                  }

                  name="payment_check_date"
                  label="Check Date"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_check_amount}
                  isInvalid={
                    errors?.payment_check_amount &&
                    !!errors?.payment_check_amount
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_check_amount &&
                    errors?.payment_check_amount
                  }

                  name="payment_check_amount"
                  label="Check Amount"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payer_name}
                  isInvalid={
                    errors?.payment_payer_name &&
                    !!errors?.payment_payer_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payer_name &&
                    errors?.payment_payer_name
                  }

                  name="payment_payer_name"
                  label="Payer Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payer_address}
                  isInvalid={
                    errors?.payment_payer_address &&
                    !!errors?.payment_payer_address
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payer_address &&
                    errors?.payment_payer_address
                  }

                  name="payment_payer_address"
                  label="Payer Address"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payer_city}
                  isInvalid={
                    errors?.payment_payer_city &&
                    !!errors?.payment_payer_city
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payer_city &&
                    errors?.payment_payer_city
                  }

                  name="payment_payer_city"
                  label="Payer City"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payer_state}
                  isInvalid={
                    errors?.payment_payer_state &&
                    !!errors?.payment_payer_state
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payer_state &&
                    errors?.payment_payer_state
                  }

                  name="payment_payer_state"
                  label="Payer State"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payer_zip}
                  isInvalid={
                    errors?.payment_payer_zip &&
                    !!errors?.payment_payer_zip
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payer_zip &&
                    errors?.payment_payer_zip
                  }

                  name="payment_payer_zip"
                  label="Payer Zip"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payee_name}
                  isInvalid={
                    errors?.payment_payee_name &&
                    !!errors?.payment_payee_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payee_name &&
                    errors?.payment_payee_name
                  }

                  name="payment_payee_name"
                  label="Payee Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payee_npi}
                  isInvalid={
                    errors?.payment_payee_npi &&
                    !!errors?.payment_payee_npi
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payee_npi &&
                    errors?.payment_payee_npi
                  }

                  name="payment_payee_npi"
                  label="Payee NPI"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payee_address}
                  isInvalid={
                    errors?.payment_payee_address &&
                    !!errors?.payment_payee_address
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payee_address &&
                    errors?.payment_payee_address
                  }

                  name="payment_payee_address"
                  label="Payee Address"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payee_city}
                  isInvalid={
                    errors?.payment_payee_city &&
                    !!errors?.payment_payee_city
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payee_city &&
                    errors?.payment_payee_city
                  }

                  name="payment_payee_city"
                  label="Payee City"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payee_state}
                  isInvalid={
                    errors?.payment_payee_state &&
                    !!errors?.payment_payee_state
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payee_state &&
                    errors?.payment_payee_state
                  }

                  name="payment_payee_state"
                  label="Payee State"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payee_zip}
                  isInvalid={
                    errors?.payment_payee_zip &&
                    !!errors?.payment_payee_zip
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payee_zip &&
                    errors?.payment_payee_zip
                  }

                  name="payment_payee_zip"
                  label="Payee Zip"
                />
              </div>

            </div>
            <Form.Label className="mt-2 fw-bold clr-heading">
              Claim
            </Form.Label>
            <div className="dbk-grid-repeat gap-3  pb-3 border-bottom-dashed">
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_patient_acc_number}
                  isInvalid={
                    errors?.payment_patient_acc_number &&
                    !!errors?.payment_patient_acc_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_patient_acc_number &&
                    errors?.payment_patient_acc_number
                  }

                  name="payment_patient_acc_number"
                  label="Patient Account Number"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_payer_claim_number}
                  isInvalid={
                    errors?.payment_payer_claim_number &&
                    !!errors?.payment_payer_claim_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_payer_claim_number &&
                    errors?.payment_payer_claim_number
                  }

                  name="payment_payer_claim_number"
                  label="Payer Claim Number"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_mrn_number}
                  isInvalid={
                    errors?.payment_mrn_number &&
                    !!errors?.payment_mrn_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_mrn_number &&
                    errors?.payment_mrn_number
                  }

                  name="payment_mrn_number"
                  label="MRN Number"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_statement_number}
                  isInvalid={
                    errors?.payment_statement_number &&
                    !!errors?.payment_statement_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_statement_number &&
                    errors?.payment_statement_number
                  }

                  name="payment_statement_number"
                  label=" Statement Number"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_statement_date}
                  isInvalid={
                    errors?.payment_statement_date &&
                    !!errors?.payment_statement_date
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_statement_date &&
                    errors?.payment_statement_date
                  }

                  name="payment_statement_date"
                  label=" Statement Date"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_patient_first_name}
                  isInvalid={
                    errors?.payment_patient_first_name &&
                    !!errors?.payment_patient_first_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_patient_first_name &&
                    errors?.payment_patient_first_name
                  }

                  name="payment_patient_first_name"
                  label="Patient First Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_patient_middle_name}
                  isInvalid={
                    errors?.payment_patient_middle_name &&
                    !!errors?.payment_patient_middle_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_patient_middle_name &&
                    errors?.payment_patient_middle_name
                  }

                  name="payment_patient_middle_name"
                  label="Patient Middle Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_patient_last_name}
                  isInvalid={
                    errors?.payment_patient_last_name &&
                    !!errors?.payment_patient_last_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_patient_last_name &&
                    errors?.payment_patient_last_name
                  }

                  name="payment_patient_last_name"
                  label="Patient Last Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_patient_identifier_code}
                  isInvalid={
                    errors?.payment_patient_identifier_code &&
                    !!errors?.payment_patient_identifier_code
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_patient_identifier_code &&
                    errors?.payment_patient_identifier_code
                  }

                  name="payment_patient_identifier_code"
                  label="Patient Identifier"
                />
              </div>

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_subscriber_first_name}
                  isInvalid={
                    errors?.payment_subscriber_first_name &&
                    !!errors?.payment_subscriber_first_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_subscriber_first_name &&
                    errors?.payment_subscriber_first_name
                  }

                  name="payment_subscriber_first_name"
                  label="Subscriber First Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_subscriber_middle_name}
                  isInvalid={
                    errors?.payment_subscriber_middle_name &&
                    !!errors?.payment_subscriber_middle_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_subscriber_middle_name &&
                    errors?.payment_subscriber_middle_name
                  }

                  name="payment_subscriber_middle_name"
                  label="Subscriber Middle Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.payment_subscriber_last_name}
                  isInvalid={
                    errors?.payment_subscriber_last_name &&
                    !!errors?.payment_subscriber_last_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_subscriber_last_name &&
                    errors?.payment_subscriber_last_name
                  }

                  name="payment_subscriber_last_name"
                  label="Subscriber Last Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.payment_subscriber_identifier_code
                  }
                  isInvalid={
                    errors?.payment_subscriber_identifier_code &&
                    !!errors?.payment_subscriber_identifier_code
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_subscriber_identifier_code &&
                    errors?.payment_subscriber_identifier_code
                  }

                  name="payment_subscriber_identifier_code"
                  label="Subscriber Identifier"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.payment_rendering_provider_first_name
                  }
                  isInvalid={
                    errors?.payment_rendering_provider_first_name &&
                    !!errors?.payment_rendering_provider_first_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_rendering_provider_first_name &&
                    errors?.payment_rendering_provider_first_name
                  }

                  name="payment_rendering_provider_first_name"
                  label="Rendering Provider First Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.payment_rendering_provider_last_name
                  }
                  isInvalid={
                    errors?.payment_rendering_provider_last_name &&
                    !!errors?.payment_rendering_provider_last_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_rendering_provider_last_name &&
                    errors?.payment_rendering_provider_last_name
                  }

                  name="payment_rendering_provider_last_name"
                  label="Rendering Provider Last Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.payment_rendering_provider_identifier
                  }
                  isInvalid={
                    errors?.payment_rendering_provider_identifier &&
                    !!errors?.payment_rendering_provider_identifier
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.payment_rendering_provider_identifier &&
                    errors?.payment_rendering_provider_identifier
                  }

                  name="payment_rendering_provider_identifier"
                  label="Rendering Provider Identifier"
                />
              </div>
            </div>
            {/* EOB 835 End Heare */}
          </div>
          <div
            className="tab-pane fade"
            id="EOB-835-Lite"
            role="tabpanel"
            aria-labelledby="pills-EOB-835-Lite"
          >
            {/* EOB 835 Lite Start Heare */}
            <Form.Label className="fw-bold clr-heading">
              EOB 835 Lite
            </Form.Label>

            <div className="dbk-grid-repeat gap-3   pb-3 border-bottom-dashed">
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.paymentlite_check_number}
                  isInvalid={
                    errors?.paymentlite_check_number &&
                    !!errors?.paymentlite_check_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.paymentlite_check_number &&
                    errors?.paymentlite_check_number
                  }

                  name="paymentlite_check_number"
                  label="Check Number"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.paymentlite_check_date}
                  isInvalid={
                    errors?.paymentlite_check_date &&
                    !!errors?.paymentlite_check_date
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.paymentlite_check_date &&
                    errors?.paymentlite_check_date
                  }

                  name="paymentlite_check_date"
                  label="Check Date"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.paymentlite_check_amount}
                  isInvalid={
                    errors?.paymentlite_check_amount &&
                    !!errors?.paymentlite_check_amount
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.paymentlite_check_amount &&
                    errors?.paymentlite_check_amount
                  }

                  name="paymentlite_check_amount"
                  label="Check Amount"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.paymentlite_payer_name}
                  isInvalid={
                    errors?.paymentlite_payer_name &&
                    !!errors?.paymentlite_payer_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.paymentlite_payer_name &&
                    errors?.paymentlite_payer_name
                  }

                  name="paymentlite_payer_name"
                  label="Payer Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.paymentlite_payee_name}
                  isInvalid={
                    errors?.paymentlite_payee_name &&
                    !!errors?.paymentlite_payee_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.paymentlite_payee_name &&
                    errors?.paymentlite_payee_name
                  }

                  name="paymentlite_payee_name"
                  label="Payee Name"
                />
              </div>
            </div>
            <Form.Label className="mt-2 fw-bold clr-heading">
              Claim
            </Form.Label>

            <div className="dbk-grid-repeat gap-3   pb-3 border-bottom-dashed">
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.paymentlite_patient_acc_number}
                  isInvalid={
                    errors?.paymentlite_patient_acc_number &&
                    !!errors?.paymentlite_patient_acc_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.paymentlite_patient_acc_number &&
                    errors?.paymentlite_patient_acc_number
                  }

                  name="paymentlite_patient_acc_number"
                  label="Patient Account Number"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.paymentlite_patient_first_name}
                  isInvalid={
                    errors?.paymentlite_patient_first_name &&
                    !!errors?.paymentlite_patient_first_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.paymentlite_patient_first_name &&
                    errors?.paymentlite_patient_first_name
                  }

                  name="paymentlite_patient_first_name"
                  label="Patient First Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.paymentlite_patient_middle_name}
                  isInvalid={
                    errors?.paymentlite_patient_middle_name &&
                    !!errors?.paymentlite_patient_middle_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.paymentlite_patient_middle_name &&
                    errors?.paymentlite_patient_middle_name
                  }

                  name="paymentlite_patient_middle_name"
                  label="Patient Middle Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.paymentlite_patient_last_name}
                  isInvalid={
                    errors?.paymentlite_patient_last_name &&
                    !!errors?.paymentlite_patient_last_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.paymentlite_patient_last_name &&
                    errors?.paymentlite_patient_last_name
                  }

                  name="paymentlite_patient_last_name"
                  label="Patient Last Name"
                />
              </div>
              {/* <div className=''>
        <Form.Check
            type="switch"
            reverse={true}
            onChange={handleChange}
            checked={values?.paymentlite_serviceline_period_start}
            isInvalid={errors?.paymentlite_serviceline_period_start && !!errors?.paymentlite_serviceline_period_start}
            feedbackType="invalid"
            feedback={errors?.paymentlite_serviceline_period_start && errors?.paymentlite_serviceline_period_start}
            
            name="paymentlite_serviceline_period_start"
            label="serviceline_period_start"
        />
    </div>
    <div className=''>
        <Form.Check
            type="switch"
            reverse={true}
            onChange={handleChange}
            checked={values?.paymentlite_serviceline_period_end}
            isInvalid={errors?.paymentlite_serviceline_period_end && !!errors?.paymentlite_serviceline_period_end}
            feedbackType="invalid"
            feedback={errors?.paymentlite_serviceline_period_end && errors?.paymentlite_serviceline_period_end}
            
            name="paymentlite_serviceline_period_end"
            label="serviceline_period_end"
        />
    </div>
    <div className=''>
        <Form.Check
            type="switch"
            reverse={true}
            onChange={handleChange}
            checked={values?.paymentlite_serviceline_billed}
            isInvalid={errors?.paymentlite_serviceline_billed && !!errors?.paymentlite_serviceline_billed}
            feedbackType="invalid"
            feedback={errors?.paymentlite_serviceline_billed && errors?.paymentlite_serviceline_billed}
            
            name="paymentlite_serviceline_billed"
            label="serviceline_billed"
        />
    </div>
    <div className=''>
        <Form.Check
            type="switch"
            reverse={true}
            onChange={handleChange}
            checked={values?.paymentlite_serviceline_paid}
            isInvalid={errors?.paymentlite_serviceline_paid && !!errors?.paymentlite_serviceline_paid}
            feedbackType="invalid"
            feedback={errors?.paymentlite_serviceline_paid && errors?.paymentlite_serviceline_paid}
            
            name="paymentlite_serviceline_paid"
            label="serviceline_paid"
        />
    </div> */}
            </div>
            {/* EOB 835 Lite End Heare */}
          </div>
          <div
            className="tab-pane fade"
            id="correspondence"
            role="tabpanel"
            aria-labelledby="pills-correspondence"
          >
            {/* Correspondence Start Heare */}
            <Form.Label className="fw-bold clr-heading">
              Correspondence
            </Form.Label>
            <div className="dbk-grid-repeat gap-3  pb-3 border-bottom-dashed  pb-3 border-bottom-dashed">
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.correspondence_payer_name}
                  isInvalid={
                    errors?.correspondence_payer_name &&
                    !!errors?.correspondence_payer_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.correspondence_payer_name &&
                    errors?.correspondence_payer_name
                  }

                  name="correspondence_payer_name"
                  label="Payer Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  checked={values?.correspondence_letter_date}
                  onChange={handleChange}
                  isInvalid={
                    errors?.correspondence_letter_date &&
                    !!errors?.correspondence_letter_date
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.correspondence_letter_date &&
                    errors?.correspondence_letter_date
                  }
                  name="correspondence_letter_date"
                  label="Letter Date"
                />
              </div>
            </div>
            <Form.Label className="mt-2 fw-bold clr-heading">
              Claim
            </Form.Label>
            <div className="dbk-grid-repeat gap-3  pb-3 border-bottom-dashed">
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.correspondence_patient_acc_number
                  }
                  isInvalid={
                    errors?.correspondence_patient_acc_number &&
                    !!errors?.correspondence_patient_acc_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.correspondence_patient_acc_number &&
                    errors?.correspondence_patient_acc_number
                  }
                  name="correspondence_patient_acc_number"
                  label="Patient Account Number"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.correspondence_period_start}
                  isInvalid={
                    errors?.correspondence_period_start &&
                    !!errors?.correspondence_period_start
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.correspondence_period_start &&
                    errors?.correspondence_period_start
                  }
                  name="correspondence_period_start"
                  label="Period Start"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.correspondence_period_end}
                  isInvalid={
                    errors?.correspondence_period_end &&
                    !!errors?.correspondence_period_end
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.correspondence_period_end &&
                    errors?.correspondence_period_end
                  }
                  name="correspondence_period_end"
                  label="Period End"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.correspondence_billed_amount}
                  isInvalid={
                    errors?.correspondence_billed_amount &&
                    !!errors?.correspondence_billed_amount
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.correspondence_billed_amount &&
                    errors?.correspondence_billed_amount
                  }
                  name="correspondence_billed_amount"
                  label="Billed Amount"
                />
              </div>

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.correspondence_patient_first_name
                  }
                  isInvalid={
                    errors?.correspondence_patient_first_name &&
                    !!errors?.correspondence_patient_first_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.correspondence_patient_first_name &&
                    errors?.correspondence_patient_first_name
                  }
                  name="correspondence_patient_first_name"
                  label="Patient First Name"
                />
              </div>

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.correspondence_patient_middle_name
                  }
                  isInvalid={
                    errors?.correspondence_patient_middle_name &&
                    !!errors?.correspondence_patient_middle_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.correspondence_patient_middle_name &&
                    errors?.correspondence_patient_middle_name
                  }
                  name="correspondence_patient_middle_name"
                  label="Patient Middle Name"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.correspondence_patient_last_name
                  }
                  isInvalid={
                    errors?.correspondence_patient_last_name &&
                    !!errors?.correspondence_patient_last_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.correspondence_patient_last_name &&
                    errors?.correspondence_patient_last_name
                  }
                  name="correspondence_patient_last_name"
                  label="Patient Last Name"
                />
              </div>
            </div>
            {/* Correspondence End Heare */}
          </div>
          <div
            className="tab-pane fade"
            id="patient-pay"
            role="tabpanel"
            aria-labelledby="pills-patient-pay"
          >
            {/* Patient Pay Start Heare */}
            <Form.Label className="fw-bold clr-heading">
              Patient Pay
            </Form.Label>
            <div className="dbk-grid-repeat gap-3  pb-3 border-bottom-dashed">
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.patientpay_check_number}
                  isInvalid={
                    errors?.patientpay_check_number &&
                    !!errors?.patientpay_check_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_check_number &&
                    errors?.patientpay_check_number
                  }

                  name="patientpay_check_number"
                  label="Check Number"
                />
              </div>

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.patientpay_check_date}
                  isInvalid={
                    errors?.patientpay_check_date &&
                    !!errors?.patientpay_check_date
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_check_date &&
                    errors?.patientpay_check_date
                  }

                  name="patientpay_check_date"
                  label="Check Date"
                />
              </div>

              {/* <div className="">
                  <Form.Check
                    type="switch"
                    reverse={true}
                    onChange={handleChange}
                    checked={values?.patientpay_check_amount}
                    isInvalid={
                      errors?.patientpay_check_amount &&
                      !!errors?.patientpay_check_amount
                    }
                    feedbackType="invalid"
                    feedback={
                      errors?.patientpay_check_amount &&
                      errors?.patientpay_check_amount
                    }
                    
                    name="patientpay_check_amount"
                    label="Check Amount"
                  />
                </div> */}

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.patientpay_patient_acc_number}
                  isInvalid={
                    errors?.patientpay_patient_acc_number &&
                    !!errors?.patientpay_patient_acc_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_patient_acc_number &&
                    errors?.patientpay_patient_acc_number
                  }

                  name="patientpay_patient_acc_number"
                  label="Patient Account Number"
                />
              </div>

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.patientpay_patient_first_name}
                  isInvalid={
                    errors?.patientpay_patient_first_name &&
                    !!errors?.patientpay_patient_first_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_patient_first_name &&
                    errors?.patientpay_patient_first_name
                  }

                  name="patientpay_patient_first_name"
                  label="Patient First Name"
                />
              </div>

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.patientpay_patient_middle_name}
                  isInvalid={
                    errors?.patientpay_patient_middle_name &&
                    !!errors?.patientpay_patient_middle_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_patient_middle_name &&
                    errors?.patientpay_patient_middle_name
                  }

                  name="patientpay_patient_middle_name"
                  label="Patient Middle Name"
                />
              </div>

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.patientpay_patient_last_name}
                  isInvalid={
                    errors?.patientpay_patient_last_name &&
                    !!errors?.patientpay_patient_last_name
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_patient_last_name &&
                    errors?.patientpay_patient_last_name
                  }

                  name="patientpay_patient_last_name"
                  label="Patient Last Name"
                />
              </div>

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={values?.patientpay_payment_amt_due}
                  isInvalid={
                    errors?.patientpay_payment_amt_due &&
                    !!errors?.patientpay_payment_amt_due
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_payment_amt_due &&
                    errors?.patientpay_payment_amt_due
                  }

                  name="patientpay_payment_amt_due"
                  label="Amount Due"
                />
              </div>

              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.patientpay_payment_statement_amount
                  }
                  isInvalid={
                    errors?.patientpay_payment_statement_amount &&
                    !!errors?.patientpay_payment_statement_amount
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_payment_statement_amount &&
                    errors?.patientpay_payment_statement_amount
                  }

                  name="patientpay_payment_statement_amount"
                  label=" Statement Amount"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.patientpay_payment_statement_number
                  }
                  isInvalid={
                    errors?.patientpay_payment_statement_number &&
                    !!errors?.patientpay_payment_statement_number
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_payment_statement_number &&
                    errors?.patientpay_payment_statement_number
                  }

                  name="patientpay_payment_statement_number"
                  label=" Statement Number"
                />
              </div>
              <div className="">
                <Form.Check
                  type="switch"
                  reverse={true}
                  onChange={handleChange}
                  checked={
                    values?.patientpay_payment_statement_date
                  }
                  isInvalid={
                    errors?.patientpay_payment_statement_date &&
                    !!errors?.patientpay_payment_statement_date
                  }
                  feedbackType="invalid"
                  feedback={
                    errors?.patientpay_payment_statement_date &&
                    errors?.patientpay_payment_statement_date
                  }

                  name="patientpay_payment_statement_date"
                  label=" Statement Date"
                />
              </div>
            </div>
            {/* Patient Pay End Heare */}
          </div>
        </div>
      </div>

      <div className="mb-3"></div>

      <div>
        {state.error && (
          <span className="text-danger">{state.error_message}</span>
        )}
      </div>

      {/* </Form> */}
    </React.Fragment>
  );
};

export default DbkForm;
