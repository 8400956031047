import React, { useRef, useState } from "react";
import { ScrollMode, SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import Modal from "react-modal";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Draggable from "react-draggable";
const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
        {function (slots: ToolbarSlot) {
            const {
                CurrentPageInput, EnterFullScreen, GoToFirstPage, GoToNextPage, GoToPreviousPage, GoToLastPage, NumberOfPages, Zoom, ZoomIn, ZoomOut,
            } = slots;

            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >


                    {/* <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '100px' }}>
                        <GoToFirstPage />
                        <GoToPreviousPage />
                        <CurrentPageInput /> / <NumberOfPages />
                        <GoToNextPage />
                        <GoToLastPage />

                    </div> */}
                    <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '42rem' }}>
                        <ZoomOut />
                        <Zoom />
                        <ZoomIn />
                    </div>
                    <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                        <EnterFullScreen />
                    </div>
                </div>
            );
        }}
    </Toolbar>
);

const CheckImageModal = ({ fileUrl, showCheckView, setShowCheckView }) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: "90%",
        },
    };
    const defaultLayoutPluginInstance = defaultLayoutPlugin({

        renderToolbar, toolbarPlugin: {
            pageNavigationPlugin: {
                enableShortcuts: true,
            },
        },
        fullScreenPlugin: {
            onEnterFullScreen: (zoom) => {
                zoom(SpecialZoomLevel.PageFit);
                defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                    ScrollMode.Wrapped
                );
            },
            onExitFullScreen: (zoom) => {
                zoom(SpecialZoomLevel.PageWidth);
                defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                    ScrollMode.Vertical
                );
            },
        },
        sidebarTabs: (defaultTabs) => [],



    });
    return <Draggable>
        <div
            style={{
                flex: 1,
                overflow: 'hidden',
                position: 'absolute',
                top: '350px',
                bottom: '0',
                left: '473px',
                right: '0',
                // width: '100%',
                height: 'auto',
            }}
        >

            {/* <button className='btn btn-light btn-active-primary'>
                <i className="bi bi-arrows-move"></i>
            </button> */}
            <div className="modal-custome-header">
                <span style={{ cursor: 'pointer' }} onClick={() => { setShowCheckView(false) }} className='close-icon'><i className="bi bi-x-square"></i></span>
            </div>
            {/* <Cover getPageIndex={() => currentPage} /> */}
            <Worker workerUrl="../../../assets/js/pdf.worker.min.js">

            <Viewer
                // scrollMode={ScrollMode.Page}
                defaultScale={SpecialZoomLevel.PageWidth}
                fileUrl={fileUrl}
                plugins={[defaultLayoutPluginInstance]}
            />
            </Worker>
        </div></Draggable>
}
export default CheckImageModal;




