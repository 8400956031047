import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import search from "../../../../../assets/img/searchicon.png";
import InputMask from "react-input-mask";
import { handleKeyPress, handleWheel, handlePreventEKeyPress } from "../gridFunctions";
import { DbkField } from "../Elements/DbkField";
import { isActiveDbkField } from "../../../Dashboard/functions";
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW } from "../../../../../routeNames";
import moment from "moment";

const Lite = (props) => {
  const {
    formik,
    __handlePayerLiteLookupSearch,
    __handlePayeeLiteLookupSearch,
    setPayerLiteFocus,
    setPayeeLiteFocus,
    payerLiteFocus,
    payeeLiteFocus,
    // DBK
    eobLiteFocus,
    setEobLiteFocus,
    handleFocusEobLite,
    addingNewEobLiteClaimDbk,
    handlePlbAmount,
    handlePLbFloatValue,
    removeEobLiteClaimDbk,
    handlePlbDeleteAmount,
    isDbkPermission,
    totalPages,
    insertRemainingAmount,
    handleLiteRemainingBalanceCalculation,
    jobData,
    log_id,
    job_id,
    isPayerPage,
    isPayeePage,
    currentPage,
    pdfLoaded,
    isViewOnllyGrid,
    setDefaultValueMainGrid
  } = props;

  const changePulledPayerName = (e) => {
    formik.setValues({
      ...formik.values,
      payer_id: "",
      payer_name: e.target.value,
    });
  };

  const changePulledPayeeName = (e) => {
    formik.setValues({
      ...formik.values,
      payee_id: "",
      payee_name: e.target.value,
    });
  };

  const handleChangeCheckAmount = async (e) => {
    const value = e.target.value;
    if (value.includes(".") && value.split(".")[1].length > 2) {
      return;
    }
    var payment_method = "";
    if (parseFloat(value) == parseFloat(0) || parseFloat(value) == "") {
      payment_method = "NON";
    } else {
      payment_method = formik.values?.payment_method;

    }

    let remaining = 0;
    let paid_total = 0;
    let plb_total = 0;
    formik.values.claims?.map((claim, ind) => {
      paid_total =
        parseFloat(paid_total ? paid_total : 0) +
        parseFloat(claim?.service_lines?.paid ? claim?.service_lines?.paid : 0);
    });
    formik.values?.plb_adjustments?.map((plb, ind) => {
      plb_total =
        parseFloat(paid_total ? plb_total : 0) +
        parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
    });
    remaining = parseFloat(
      parseFloat(value ? value : 0) -
      parseFloat(paid_total ? paid_total : 0) +
      parseFloat(plb_total ? plb_total : 0)
    ).toFixed(2);

    if (remaining == -0.0) {
      remaining = parseFloat(0).toFixed(2);
    }

    await formik.setValues({
      ...formik.values,
      check_amount: e.target.value,
      payment_method: payment_method,
      remaining_balance: remaining,
    });
  };
  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  const qaCorrectedData = gridData?.qa_corrected_data;
  const handleChangeFromTo = (name) => {
    formik.setValues((prevState) => ({
      ...prevState,
      [name]: currentPage + 1
    }));
  };

  const renderClass = (name, type, defaultClass) => {
    if (formik.errors?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' && moment(formik.values?.[name]).format("MM/DD/YYYY") != moment(gridData?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' && formik.values?.[name]?.toUpperCase() !=
        gridData?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' && parseFloat(formik.values?.[name] ? formik.values?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.[name] ?
          gridData?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }

      else if (gridData?.transaction_type != "PAYMENT835_LITE") {
        return defaultClass + ' qa-field'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.[name]) {
      return defaultClass + ' qa-field'
    }
    else {
      return defaultClass;
    }
  }
  const renderPlbClass = (name, type, defaultClass, index) => {
    if (formik.errors?.plb_adjustments &&
      formik.errors?.plb_adjustments[index] &&
      formik.errors?.plb_adjustments[index]?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.plb_adjustments &&
        qaCorrectedData?.plb_adjustments[index] &&
        qaCorrectedData?.plb_adjustments[index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[index] &&
        moment(formik.values?.plb_adjustments[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.plb_adjustments[index]?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[index] &&
        formik.values?.plb_adjustments[index]?.[name]?.toUpperCase() !=
        gridData?.plb_adjustments[index]?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'STRING' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[index] &&
        formik.values?.plb_adjustments[index]?.[name] !=
        gridData?.plb_adjustments[index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' &&
        gridData?.plb_adjustments &&
        gridData?.plb_adjustments[index] &&
        parseFloat(formik.values?.plb_adjustments[index]?.[name] ? formik.values?.plb_adjustments[index]?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.plb_adjustments[index]?.[name] ?
          gridData?.plb_adjustments[index]?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }
      else if (gridData?.plb_adjustments &&
        !gridData?.plb_adjustments[index]) {
        return defaultClass + ' qa-field'
      }

      else if (gridData?.transaction_type != "PAYMENT835_LITE") {
        return defaultClass + ' qa-field'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.plb_adjustments &&
      qaCorrectedData?.plb_adjustments[index] &&
      qaCorrectedData?.plb_adjustments[index]?.[name]) {
      return defaultClass + ' qa-field'
    }
    else {
      return defaultClass;
    }
  }
  return (
    <React.Fragment>
      <div
        className="topblock-wrapper eoblite_block accordion-collapse collapse show form-lite-eob-container"
        id="collapseOne"
        aria-labelledby="headingOne"
        data-bs-parent="#payment835"
      >
        <div className="sub-section">
          <div className="">
            <label className="btn btn-payment835">Payment835 Lite</label>
          </div>
          <div className="paymentmethod-wi">
            <InputGroup>
              <InputGroup.Text>Payment Method</InputGroup.Text>
              <Form.Control
                data-prevent-shift-tab="true"
                as="select"
                name="payment_method"
                tabIndex={formik.values?.payment_method == '' || formik.values?.payment_method == '0' ? 0 : -1}
                onChange={formik.handleChange}
                value={formik.values?.payment_method}
                className={renderClass('payment_method', 'TEXT', 'selc-bg-1')}
              >
                <option value="0">Select an Option</option>
                <option value="ACH">ACH</option>
                <option
                  value="CHK"
                  selected={formik.values?.check_amount > parseFloat(0)}
                >
                  CHK
                </option>
                <option
                  value="NON"
                  selected={formik.values?.check_amount == parseFloat(0)}
                >
                  NON
                </option>
                <option
                  value="VCP"
                >
                  VCP
                </option>
              </Form.Control>
            </InputGroup>
          </div>
          <div className="checknum-wi">
            <InputGroup>
              <InputGroup.Text>Check Number</InputGroup.Text>
              <div className="dbk-main-container">
                <Form.Control
                  type="text"
                  className={renderClass('check_number', 'TEXT', 'w-78')}
                  autoComplete={"off"}
                  name="check_number"
                  onChange={formik.handleChange}
                  value={formik.values?.check_number}
                  tabIndex={!formik?.values?.check_number?.includes("CUSTOM_CHECK_NUMBER") && formik.values?.check_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                  onFocus={() => {
                    handleFocusEobLite("check_number", "main", null, isDbkPermission?.payment_lite?.paymentlite_check_number);
                  }}
                  onKeyDown={(e) => {
                    setDefaultValueMainGrid(e, 'check_number', formik);
                  }}
                  disabled={isActiveDbkField(eobLiteFocus, formik, isDbkPermission?.payment_lite?.paymentlite_check_number) ? !eobLiteFocus.check_number : false}
                />

                {
                  isDbkPermission?.payment_lite?.paymentlite_check_number &&
                    formik.errors?.check_number === "Dont Match" &&
                    eobLiteFocus.check_number ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="text"
                      fieldName="check_number"
                      fieldNameDbk="dbk_check_number"
                      setCorresPondanceFocus={setEobLiteFocus}
                      corresPondanceFocus={eobLiteFocus}
                    />
                  ) : null}
              </div>
            </InputGroup>
          </div>
          <div className="checkdate-wi">
            <InputGroup>
              <InputGroup.Text>Check Date</InputGroup.Text>
              <div className="dbk-main-container">
                <InputMask
                  mask="99/99/9999"
                  // className={
                  //   formik.errors?.check_date ? "w-48 error-field" : "w-48"
                  // }
                  className={renderClass('check_date', 'DATE', 'w-48')}


                  style={{ fontSize: "0.7em" }}
                  onChange={formik.handleChange}
                  value={formik.values?.check_date}
                  tabIndex={formik.values?.check_date != '' && formik.values?.check_date != '__/__/____' && pathname == DATA_ENTRY ? -1 : 0}
                  name="check_date"
                  placeholder={"mm/dd/yyyy"}
                  onFocus={() => {
                    handleFocusEobLite("check_date", "main", null, isDbkPermission?.payment_lite?.paymentlite_check_date);
                  }}
                  disabled={isActiveDbkField(eobLiteFocus, formik, isDbkPermission?.payment_lite?.paymentlite_check_date) ? !eobLiteFocus.check_date : false}

                />

                {isDbkPermission?.payment_lite?.paymentlite_check_date &&
                  formik.errors?.check_date === "Dont Match" &&
                  eobLiteFocus.check_date ? (
                  <DbkField
                    formik={formik}
                    index=""
                    fieldType="main"
                    type="date"
                    fieldName="check_date"
                    fieldNameDbk="dbk_check_date"
                    setCorresPondanceFocus={setEobLiteFocus}
                    corresPondanceFocus={eobLiteFocus}
                  />
                ) : null}
              </div>
            </InputGroup>
          </div>
          <div className="checkamt-wi">
            <InputGroup>
              <InputGroup.Text>Check Amount</InputGroup.Text>
              <div className="dbk-main-container">
                <Form.Control
                  type="number"
                  onChange={(e) => handleChangeCheckAmount(e)}
                  onBlur={(e) =>
                    formik.setValues({
                      ...formik.values,
                      check_amount: e.target.value ? parseFloat(e.target.value).toFixed(2) : "",
                    })
                  }
                  aria-describedby="empty"
                  value={formik.values?.check_amount}
                  tabIndex={formik.values?.check_amount != '' && pathname == DATA_ENTRY ? -1 : 0}
                  onKeyDown={(e) => {
                    handlePreventEKeyPress(e);
                    handleKeyPress(e)
                  }}
                  className={renderClass('check_amount', 'NUMBER', 'w-78')}
                  name="check_amount"
                  pattern={/[0-9]+([\.][0-9]{2})?/}
                  onWheel={handleWheel}
                  onFocus={() => {
                    handleFocusEobLite("check_amount", "main", null, isDbkPermission?.payment_lite?.paymentlite_check_amount);
                  }}
                  disabled={isActiveDbkField(eobLiteFocus, formik, isDbkPermission?.payment_lite?.paymentlite_check_amount) ? !eobLiteFocus.check_amount : false}
                />


                {
                  isDbkPermission?.payment_lite?.paymentlite_check_amount &&
                    formik.errors?.check_amount == "Dont Match" &&
                    eobLiteFocus.check_amount ? (
                    <DbkField
                      formik={formik}
                      index=""
                      fieldType="main"
                      type="number"
                      fieldName="check_amount"
                      fieldNameDbk="dbk_check_amount"
                      setCorresPondanceFocus={setEobLiteFocus}
                      corresPondanceFocus={eobLiteFocus}
                    />
                  ) : null}
              </div>
            </InputGroup>
          </div>
          <div className="remainingbln-wi">
            <InputGroup>
              <InputGroup.Text>Remaining Balance</InputGroup.Text>
              <Form.Control
                type="number"

                aria-describedby="empty"
                readOnly
                value={formik.values?.remaining_balance}
                tabIndex={formik.values?.remaining_balance != '' && pathname == DATA_ENTRY ? -1 : 0}
                onKeyDown={(e) => {
                  handlePreventEKeyPress(e);
                  handleKeyPress(e)
                }}
                className={
                  formik.errors?.remaining_balance
                    ? "w-78 error-field text-end prevent-shift"
                    : "w-78 text-end prevent-shift"
                }
                name="check_amount"
                pattern={/[0-9]+([\.][0-9]{2})?/}
                onWheel={handleWheel}
              />

            </InputGroup>
          </div>
        </div>

        <div className="sub-section">
          <div className="">
            <label className="btn btn-payer">Payer </label>
          </div>
          <div className="wrapper-payer">
            <InputGroup>
              <InputGroup.Text>
                Name{" "}
                <a
                  type="button"
                  onClick={__handlePayerLiteLookupSearch}
                  className="payersearch"
                >
                  <img src={search} alt="" />
                </a>
              </InputGroup.Text>
              <div className="dbk-main-container">
                <Form.Control
                  type="text"
                  onChange={(e) =>
                    formik.values.payer_id != ""
                      ? changePulledPayerName(e)
                      : formik.handleChange(e)
                  }
                  onFocus={() => {
                    handleFocusEobLite("payer_name", "main", null, isDbkPermission?.payment_lite?.paymentlite_payer_name),
                      setPayerLiteFocus(true);
                  }}
                  onBlur={() => setPayerLiteFocus(false)}
                  autoFocus={payerLiteFocus}
                  tabIndex={formik.values?.payer_name == '' || formik.values?.payer_name == undefined ? 0 : -1}
                  autoComplete={"off"}
                  value={formik.values?.payer_name}
                  className={renderClass('payer_name', 'TEXT', 'w-payer text-uppercase')}
                  name="payer_name"
                  disabled={isActiveDbkField(eobLiteFocus, formik, isDbkPermission?.payment_lite?.paymentlite_payer_name) ? !eobLiteFocus.payer_name : false}

                />

                {isDbkPermission?.payment_lite?.paymentlite_payer_name &&
                  formik.errors?.payer_name == "Dont Match" &&
                  eobLiteFocus.payer_name ? (
                  <DbkField
                    formik={formik}
                    index=""
                    fieldType="main"
                    type="text"
                    fieldName="payer_name"
                    fieldNameDbk="dbk_payer_name"
                    setCorresPondanceFocus={setEobLiteFocus}
                    corresPondanceFocus={eobLiteFocus}
                  />
                ) : null}
              </div>
            </InputGroup>
          </div>
          {isPayerPage &&
            <div className='pagefrom-corress'>
              <div className="input-group">
                <label className="input-group-text">
                  Page From
                </label>
                <Form.Control
                  data-disabled={!pdfLoaded}
                  className={renderClass('payer_page_from', 'NUMBER', 'pagefrom')}
                  onFocus={() => {
                    handleChangeFromTo('payer_page_from');
                  }}
                  onChange={formik.handleChange}
                  min={1}
                  type="number"
                  value={formik.values?.payer_page_from}
                  name="payer_page_from"
                  onKeyDown={(e) => {
                    handlePreventEKeyPress(e);
                  }}
                />
              </div>
            </div>
          }
          {isPayerPage &&

            <div className='pageto-corress'>
              <div className="input-group">
                <label className="input-group-text">
                  Page To
                </label>
                <Form.Control
                  data-disabled={!pdfLoaded}
                  className={renderClass('payer_page_to', 'NUMBER', 'pageto')}


                  onFocus={() => {
                    handleChangeFromTo('payer_page_to');
                  }}
                  onChange={formik.handleChange}
                  min={1}
                  type="number"
                  onKeyDown={(e) => {
                    handlePreventEKeyPress(e);
                  }}
                  value={formik.values?.payer_page_to}
                  // tabIndex={formik.values?.payer_page_to != '' && pathname == DATA_ENTRY ? -1 : 0}
                  name={"payer_page_to"}
                // data-disabled={!pdfLoaded}
                />
              </div>
            </div>
          }

        </div>
        <div className="sub-section">
          <div className="">
            <label className="btn btn-payer">Payee </label>
          </div>
          <div className="wrapper-payer">
            <InputGroup>
              <InputGroup.Text>
                Name{" "}
                <a className="payersearch">
                  <img
                    src={search}
                    onClick={__handlePayeeLiteLookupSearch}
                    alt=""
                  />
                </a>
              </InputGroup.Text>
              <div className="dbk-main-container">

                <Form.Control
                  type="text"
                  onChange={(e) =>
                    formik.values.payee_id != ""
                      ? changePulledPayeeName(e)
                      : formik.handleChange(e)
                  }
                  onFocus={() => {
                    handleFocusEobLite("payee_name", "main", null, isDbkPermission?.payment_lite?.paymentlite_payee_name),
                      setPayeeLiteFocus(true);
                  }}
                  className={renderClass('payee_name', 'TEXT', 'w-payer')}
                  onBlur={() => setPayeeLiteFocus(false)}
                  autoFocus={payeeLiteFocus}
                  value={formik.values?.payee_name}
                  tabIndex={formik.values?.payee_name == '' || formik.values?.payee_name == undefined ? 0 : -1}
                  name="payee_name"
                  disabled={isActiveDbkField(eobLiteFocus, formik, isDbkPermission?.payment_lite?.paymentlite_payee_name) ? !eobLiteFocus.payee_name : false}

                />


                {isDbkPermission?.payment_lite?.paymentlite_payee_name &&
                  formik.errors?.payee_name == "Dont Match" &&
                  eobLiteFocus.payee_name ? (
                  <DbkField
                    formik={formik}
                    index=""
                    fieldType="main"
                    type="text"
                    fieldName="payee_name"
                    fieldNameDbk="dbk_payee_name"
                    setCorresPondanceFocus={setEobLiteFocus}
                    corresPondanceFocus={eobLiteFocus}
                  />
                ) : null}
              </div>
            </InputGroup>
          </div>
          {isPayeePage &&

            <div className='pagefrom-corress'>
              <div className="input-group">
                <label className="input-group-text">
                  Page From
                </label>
                <Form.Control
                  data-disabled={!pdfLoaded}
                  className={renderClass('payee_page_from', 'NUMBER', 'pagefrom')}
                  onFocus={() => {
                    handleChangeFromTo('payee_page_from');
                  }}
                  onChange={formik.handleChange}
                  min={1}
                  type="number"
                  onKeyDown={(e) => {
                    handlePreventEKeyPress(e);
                  }}
                  value={formik.values?.payee_page_from}
                  name={`payee_page_from`}
                />
              </div>
            </div>
          }
          {isPayeePage &&

            <div className='pageto-corress'>
              <div className="input-group">
                <label className="input-group-text">
                  Page To
                </label>
                <Form.Control
                  data-disabled={!pdfLoaded}
                  className={renderClass('payee_page_to', 'NUMBER', 'pageto')}
                  onFocus={() => {
                    handleChangeFromTo('payee_page_to');
                  }}
                  onChange={formik.handleChange}
                  min={1}
                  type="number"
                  onKeyDown={(e) => {
                    handlePreventEKeyPress(e);
                  }}
                  value={formik.values?.payee_page_to}
                  name={`payee_page_to`}
                />
              </div>
            </div>
          }
        </div>
        <React.Fragment>
          <div className="row">
            {formik.values?.plb_adjustments?.map((plb, index) => (
              <React.Fragment key={index}>
                <div className="col-lg-6">
                  <div className="sub-section">
                    <div className="">
                      <div className="label-claim">
                        <button
                          type="button"
                          tabIndex="-1"
                          onClick={() => {
                            handlePlbDeleteAmount(index);
                          }}
                          className="btn btn-outline-danger"
                        >
                          ×
                        </button>
                        PLB Adjustment{" "}
                      </div>
                    </div>
                    <div className="wrapper-plb">
                      <InputGroup>
                        <InputGroup.Text>NPI </InputGroup.Text>
                        <Form.Control
                          type="text"
                          onChange={formik.handleChange}
                          disabled={isViewOnllyGrid}
                          className={renderPlbClass('npi', 'TEXT', 'w-npi', index)}
                          value={plb?.npi}
                          tabIndex={plb?.npi != '' && pathname == DATA_ENTRY ? -1 : 0}
                          maxLength={10}
                          name={`plb_adjustments[${index}].npi`}
                        />
                      </InputGroup>
                    </div>

                    <div className="wrapper-add">
                      <InputGroup>
                        <InputGroup.Text>PLB Page</InputGroup.Text>
                        <Form.Control
                          as="select"
                          type="text"
                          onChange={formik.handleChange}
                          disabled={isViewOnllyGrid}
                          className={renderPlbClass('plb_page', 'STRING', 'select-pan', index)}
                          value={plb?.plb_page}
                          tabIndex={plb?.plb_page != '' && pathname == DATA_ENTRY ? -1 : 0}
                          name={`plb_adjustments[${index}].plb_page`}
                        >
                          {Array.from(Array(totalPages).keys()).map(
                            (i, index) => (
                              <option key={index} value={i + 1}>
                                {i + 1}
                              </option>
                            )
                          )}
                        </Form.Control>
                      </InputGroup>
                    </div>
                    <div className="wrapper-add">
                      <InputGroup>
                        <InputGroup.Text>Reason</InputGroup.Text>
                        <Form.Control
                          disabled={isViewOnllyGrid}
                          as="select"
                          type="text"
                          onChange={formik.handleChange}
                          value={formik.values?.plb_adjustments[index]?.reason}
                          tabIndex={formik.values?.plb_adjustments[index]?.reason != '' && pathname == DATA_ENTRY ? -1 : 0}
                          className={renderPlbClass('reason', 'STRING', 'select-pan', index)}
                          name={`plb_adjustments[${index}].reason`}
                        >
                          <option value="">---Select---</option>
                          <option value="L6">L6 - Interest</option>
                          <option value="WO">WO - Overpayment Recovery</option>
                          <option value="FB">FB - Forward Balance</option>
                          <option value="BD">BD - Bad Debt</option>
                          <option value="72">72 - Authorized Return</option>
                          <option value="BN">BN - Bonus</option>
                          <option value="CS">CS - Adjustment</option>
                          <option value="AH">AH - Origination</option>
                          <option value="AM">AM - Borrower's Account</option>
                          <option value="AP">
                            AP - Acceleration of Benefits
                          </option>
                          <option value="B2">B2 - Rebate</option>
                          <option value="B3">B3 - Recovery</option>
                          <option value="C5">C5 - Temporary Allowance</option>
                          <option value="CR">CR - Capitation Interest</option>
                          <option value="CT">CT - Capitation Payment</option>
                          <option value="CV">CV - Capital Passthru</option>
                          <option value="CW">
                            CW - Registered Nurse Passthru
                          </option>
                          <option value="DM">
                            DM - Direct Medical Passthru
                          </option>
                          <option value="E3">E3 - Withholding</option>
                          <option value="FC">FC - Fund Allocation</option>
                          <option value="GO">GO - Graduate Passthru</option>
                          <option value="HM">HM - Hemophilia Clotting</option>
                          <option value="IP">IP - Incentive Payment</option>
                          <option value="IR">IR - IRS withholding</option>
                          <option value="IS">IS - Interim Settlement</option>
                          <option value="J1">J1 - Nonreimbursable</option>
                          <option value="L3">L3 - Penalty</option>
                          <option value="LE">LE - Levy</option>
                          <option value="LS">LS - Lump Sum</option>
                          <option value="OA">OA - Organ Passthru</option>
                          <option value="OB">OB - Provider Offset</option>
                          <option value="PI">PI - Periodic Payment</option>
                          <option value="PL">PL - Final Payment</option>
                          <option value="RA">
                            RA - Retroactive Adjustment
                          </option>
                          <option value="RE">RE - Return on Equity</option>
                          <option value="SL">
                            SL - Student Loan Repayment
                          </option>
                          <option value="TL">TL - Third Party Liability</option>
                          <option value="WU">WU - Unspecified Recovery</option>
                          <option value="50">50 - Late charge</option>
                          <option value="51">51 - Interest</option>
                          <option value="90">90 - Early Payment</option>
                        </Form.Control>
                      </InputGroup>
                    </div>
                    <div className="wrap-pan">
                      <InputGroup>
                        <InputGroup.Text>
                          Patient Account Number
                        </InputGroup.Text>
                        <Form.Control
                          disabled={isViewOnllyGrid}
                          type="text"
                          onChange={formik.handleChange}
                          value={
                            formik.values?.plb_adjustments[index]
                              ?.patient_account_number
                          }
                          tabIndex={formik.values?.plb_adjustments[index]?.patient_account_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                          className={renderPlbClass('patient_account_number', 'TEXT', 'w-pat-a-n', index)}
                          name={`plb_adjustments[${index}].patient_account_number`}
                        />
                      </InputGroup>
                    </div>
                    <div className="wrap-amt">
                      <InputGroup>
                        <InputGroup.Text>Amt</InputGroup.Text>
                        <Form.Control
                          disabled={isViewOnllyGrid}
                          type="number"
                          onChange={(e) => handlePlbAmount(e, index)}
                          onWheel={handleWheel}
                          onKeyDown={(e) => {
                            handlePreventEKeyPress(e);
                            handleKeyPress(e)
                          }}
                          onDoubleClick={() => insertRemainingAmount(index)}
                          onBlur={(e) => handlePLbFloatValue(e, index)}
                          value={formik.values?.plb_adjustments[index]?.plb_amt}
                          tabIndex={formik.values?.plb_adjustments[index]?.plb_amt != '' && pathname == DATA_ENTRY ? -1 : 0}
                          className={renderPlbClass('plb_amt', 'NUMBER', 'w-82', index)}
                          name={`plb_adjustments[${index}].plb_amt`}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default Lite;
