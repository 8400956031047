import React, { useState, useEffect, useMemo } from 'react'
import Master from '../../../Layout/admin/Master/index';
import { Button, Form, Modal, OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap';
import { deletePayerIdList, exportPayerIdList, getPayerIdList, importCsvPayerIdList } from '../../../Redux/user/action';
import { Link } from 'react-router-dom';
import { CREATE_PAYER_ID_LIST, CREATE_REASON_CODES, EDIT_PAYER_ID_LIST } from '../../../routeNames';
import DataTable from 'react-data-table-component';
import { BiExport, BiFile, BiReset, BiSearch, BiTimer, BiUserPlus } from 'react-icons/bi';
import datatableCustomStyles from '../../../Layout/Elements/DatatableCustomStyles';
import DeleteSweetAlert from '../../SweetAlert/DeleteSweetAlert';
import { approveFunction } from '../../../Redux/common/action';
import { useFormik } from 'formik';
import Select from 'react-select'
import * as Yup from 'yup';
import payerIdListSample from "../../../assets/sample/sample-availity-payer-list.csv"
import { changeAllTitleFunction, downloadFile } from '../Dashboard/functions';
import { Tooltip } from '@mui/material';
import { Divider, Stack } from 'rsuite';
import DateRangePicker from 'rsuite/DateRangePicker';
import { getAllClientDropdown } from '../../../Redux/client/action';
import { HiChevronDown } from 'react-icons/hi';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import { setHours, setMinutes, setSeconds, startOfYear, endOfYear } from 'date-fns';
import addMonths from 'date-fns/addMonths';
import { components } from 'react-select';
import { TbGridDots, TbHandGrab } from 'react-icons/tb';
import moment from 'moment';

const PayerIdList = () => {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [clientList, setClientList] = useState();
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);

    const [isSwal, setIsSwal] = useState({
        show: false,
        id: ''
    })
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [pageno, setPageNo] = useState(1);
    const [colorRow, setColorRow] = useState({});
    const [toaster, setToaster] = useState(false)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [modalUpload, setModalUpload] = useState(false);
    const [searchedData, setSearchedData] = useState({
        search: "",
        status: "",
        updated_date_range: "",
        clients: "",
        sort_key: "",
        sort_order: "",
    })
    const [filterState, setFilterState] = useState([])
    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {filterState && filterState[state] && filterState[state].length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date().setHours(0, 0, 0, 0), new Date().setHours(23, 59, 59, 59)],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date().setHours(0, 0, 0, 0), -1), addDays(new Date().setHours(23, 59, 59, 59), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date().setHours(0, 0, 0, 0)), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date().setHours(0, 0, 0, 0), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date().setHours(0, 0, 0, 0), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date().setHours(0, 0, 0, 0)), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date().setHours(0, 0, 0, 0), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [setSeconds(setMinutes(setHours(startOfYear(new Date(new Date().getFullYear() - 1, 0, 1)), 0), 0), 0), setSeconds(setMinutes(setHours(endOfYear(new Date(new Date().getFullYear(), 0, 0)), 23), 59), 59)],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date().setHours(0, 0, 0, 0)] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];
    useEffect(() => {
        setLoading(true);
        getPayerIdList(1, limit)((response) => {
            setData(response?.data);
            setTotalRows(response?.data?.count);
            setLoading(false);
        });
        getAllClientDropdown()((response) => {
            setClientList(response?.data)
        })
    }, []);
    const fetchPayerIdList = (page, limit, search, updated_date_range, clients, sort_order, sort_key) => {
        setLoading(true);
        //setLimit(limit);
        getPayerIdList(page, limit, search, updated_date_range, clients, sort_order, sort_key)((response) => {
            setData(response?.data);
            setLimit(limit);
            setTotalRows(response?.data?.count);
            setLoading(false);
        });
    };
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setLimit(newPerPage);
        setPageNo(1)
        fetchPayerIdList(1, newPerPage, searchedData.search, searchedData.updated_date_range, searchedData.clients, searchedData.sort_order, searchedData.sort_key)
        setLoading(false);
        handleResetDataTable()

    };
    const handlePageChange = page => {
        setPageNo(page)
        fetchPayerIdList(page, limit, searchedData.search, searchedData.updated_date_range, searchedData.clients, searchedData.sort_order, searchedData.sort_key);
    };

    const handleNewSingleTab = async (name, url) => {
        let new_tab = {
            id: 0,
            route: `${window.location.origin}` + url,
            label: name,
            prevTabs: [],
            isNewTab: false,
            isCurrentTab: true,
            title: name
        }
        // window.parent.localStorage.setItem('bbtabs', JSON.stringify(data))
        window.parent.localStorage.setItem('newTab', JSON.stringify(new_tab))

        parent.document.getElementById('bottom-tab-add').click()
    }
    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                width: "40px",
                //grow: 0,

            },

            {

                id: 'Payer ID (TRN03)',
                name: 'Payer ID (TRN03)',
                selector: row => row.unique_code,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "300px" }}>
                        <Tooltip disableInteractive title={row.unique_code} placement='top' arrow>
                            <span className='text-truncate'> {row?.unique_code}</span>
                        </Tooltip>
                    </div>,

                left: true,
                width: "360px",
                wrap: true,


            },
            {

                id: 'Payer Name',
                name: 'Payer Name',
                selector: row => row.payer_name,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "300px" }}>
                        <Tooltip disableInteractive title={row.payer_name} placement='top' arrow>
                            <span className='text-truncate'> {row?.payer_name}</span>
                        </Tooltip>
                    </div>,

                left: true,
                width: "360px",
                wrap: true,


            },
            {
                id: 'client',
                name: 'Client',
                selector: row => row.client,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "400px" }}>
                        <Tooltip disableInteractive title={row?.client} placement='top' arrow>
                            <span className='text-truncate '> {row?.client}</span>
                        </Tooltip>
                    </div>,
                // sortable: true,
                left: true,
                wrap: true,
                minWidth: '400px',
                maxWidth: '400px',


            },
            {
                id: 'modified_date',
                name: 'Updated Date',
                selector: row => row.modified_date,
                cell: (row) =>
                    <div className='d-flex flex-row  flex-wrap text-truncate '
                        onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                        <Tooltip disableInteractive title={row?.modified_date} placement='top' arrow>
                            <span className='text-truncate '> {row?.modified_date}</span>
                        </Tooltip>
                    </div>,
                sortable: true,
                left: true,
                wrap: true,
                maxWidth: '100px',
                minWidth: '100px'


            },
            {
                id: 'edit',
                name: 'Actions',
                //grow: 0.6,

                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0" onClick={() => handleRowClick(row)}>

                        <Tooltip disableInteractive title="Edit" placement='top' arrow>
                            <div style={{ color: '#6ba2b6' }} onClick={(e) => {
                                if (e.ctrlKey) {
                                    handleNewSingleTab("Edit Payer ID List", EDIT_PAYER_ID_LIST + row.id)
                                } else {
                                    changeAllTitleFunction('Edit Payer ID List', EDIT_PAYER_ID_LIST + row.id)
                                }
                            }
                            } className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2" >
                                <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </div>
                        </Tooltip>
                        {/* } */}
                        {checkUserPermission('delete_payer_id_list') &&
                            <Tooltip disableInteractive title="Delete" placement='top' arrow>
                                <Link className={row.is_active ? "btn btn-icon btn-bg-light btn-active-color-primary btn-sm " : "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"} onClick={() => setIsSwal({ ...isSwal, show: true, id: row.id })}>
                                    <span className="svg-icon svg-icon-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </Link>
                            </Tooltip>
                        }
                    </div>,
                ignoreRowClick: false,
                allowOverflow: true,
                right: true,

                //left: false,
                button: false,
            },

        ],

        [pageno],

    );


    const onClickDeleteReasoncode = (id) => {

        deletePayerIdList(id)((response) => {
            if (response?.status) {
                fetchPayerIdList(1, limit, searchedData.search)

                setToaster('success')
                setTimeout(() => {
                    setToaster('')

                }, 2000);
            } else {
                setToaster('error')
                setTimeout(() => {
                    setToaster('')

                }, 2000);
            }
        });
    }


    const handleDelete = (pk) => {
        // console.log(pk);
        setIsSwal({ ...isSwal, show: 2 })
        onClickDeleteReasoncode(pk)
        // console.log('******');
    }

    const handleClear = () => {
        if (filterText) {
            // setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            fetchPayerIdList(1, limit);
        }
    };

    const searchGroups = async (e) => {
        setFilterText(e.target.value)
        let data = e.target.value ? e.target.value : '';
        //await fetchPayerIdList(pageno, limit, data)
        // const response = await axiosInstance.get(GET_REASON_CODES_URL + "?limit=" + limit + "&search=" + e.target.value);
        // // console.log('====================================');
        // // console.log(response);
        // // console.log('====================================');
        // setData(response?.data);


        // getPayerIdList(1, limit, data)((response) => {
        //     setData(response?.data);
        //     setTotalRows(response?.data?.count);
        // });
    }

    const handleApproveResonCodes = (id, url) => {
        approveFunction(id, url)((response) => {
            if (response && response.status) {
                // console.log(response);
                setToaster('success')
                fetchPayerIdList(1)
            } else {
                setToaster('error')
            }
        })
    }
    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster(false)
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [toaster])

    const csvFormik = useFormik({
        initialValues: {
            csv_file: "",
            client: ''
        },
        validationSchema: Yup.object().shape({
            client: Yup.mixed()
                .required('Required'),
        }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            const formData = new FormData();
            formData.append('csv_file', values?.csv_file[0]);
            formData.append('client', values?.client);
            importCsvPayerIdList({ formData })((response) => {
                if (response && response?.status) {
                    setSubmitting(false)
                    setToaster('success')
                    setModalUpload(!modalUpload)
                }
                else {
                    setSubmitting(false)
                    setToaster('error')

                }
            })
        }
    })
    const handleSort = async (column, order) => {
        payerIdListFormik.setValues({
            ...payerIdListFormik.values,
            sort_order: order,
            sort_key: column.id
        })
        setSearchedData({
            ...searchedData,
            sort_order: order,
            sort_key: column.id
        })
        fetchPayerIdList(pageno, limit, searchedData.search, searchedData.updated_date_range, searchedData.clients, order, column.id);
    };
    const converToStrDate = (date) => {
        let updated_date_range = ''
        if (date) {
            const datefrom = moment(date['0']).format('MM/DD/YYYY')
            const dateto = moment(date['1']).format('MM/DD/YYYY')
            updated_date_range = datefrom + " - " + dateto
        }
        return updated_date_range
    }
    const payerIdListFormik = useFormik({
        initialValues: {
            search: "",
            updated_date_range: "",
            clients: "",
            sort_key: "",
            sort_order: "",
        },
        onSubmit: (values) => {
            const dict = { ...values }
            dict.updated_date_range = converToStrDate(values.updated_date_range)
            searchData(dict)
            setSearchedData(dict)

            handleResetDataTable()

        },


    })

    const searchData = async ({ search, updated_date_range, clients, sort_order, sort_key }) => {
        fetchPayerIdList(1, limit, search, updated_date_range, clients, sort_order, sort_key)
    }

    const handleFileChange = (event) => {

        let selectedFile = event.target.files;
        // let file = null;
        // let fileName = "";
        // //Check File is not Empty
        // if (selectedFile.length > 0) {
        //     // Select the very first file from list
        //     let fileToLoad = selectedFile[0];
        //     fileName = fileToLoad.name;
        //     // FileReader function for read the file.
        //     let fileReader = new FileReader();
        //     // Onload of file read the file content
        //     fileReader.onload = function (fileLoadedEvent) {
        //         file = fileLoadedEvent.target.result;
        //         // Print data in // console
        csvFormik.setValues({
            ...csvFormik.values,
            csv_file: selectedFile
        })
        //     };
        //     // Convert data to base64
        //     fileReader.readAsDataURL(fileToLoad);
    }
    // console.log(csvFormik.values);
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.id == row.id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.id == row.id ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
    };

    const options = [
        // { label: '--Select--' },
        { value: 'true', label: 'Approved' },
        { value: 'false', label: 'Not Approved' },

    ]
    const data_from_options = [
        { value: '1', label: 'Other' },
        { value: '2', label: 'Grid' },
        // { value: '3', label: '837' },
        { value: '4', label: 'CSV' },

    ]
    const handleSelectChange = async (e, name) => {
        payerIdListFormik.setValues({ ...payerIdListFormik.values, [name]: e?.value ? e?.value : "" })
    }

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }
    const handleExportReasonCode = (search, status, updated_date_range, clients, sort_order, sort_key) => {
        setExportLoading(true)
        exportPayerIdList({ search, status, updated_date_range, clients, sort_order, sort_key })((response) => {
            setExportLoading(false)
            const currentDate = new Date();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
            const day = currentDate.getDate().toString().padStart(2, '0');
            const year = currentDate.getFullYear();

            const strDate = `${month}/${day}/${year}`;
            downloadFile(response?.data, `reason-code-${strDate}`)
        })
    };
    const handleCloseCsvModal = () => {
        csvFormik.setValues({
            client: "",
            csv_file: ""
        })
        setModalUpload(!modalUpload)
    }
    return (
        <React.Fragment>
            <Master ToastMessage={toaster} />
            <main id="main" className="main">
                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster && toaster == 'approved' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Reason code approve</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster && toaster == 'error' ? true : false} animation={true} delay={2000} autohide={200}>
                        <Toast.Body ><span className='text-white'>Something went wrong</span></Toast.Body>
                    </Toast>
                </ToastContainer> */}
                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster === true ? true : false} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide={200}>
                        <Toast.Body ><span className='text-white'>Something went wrong</span></Toast.Body>
                    </Toast>
                </ToastContainer> */}
                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Payer ID List</h1>
                </div>
                <Modal show={modalUpload} onHide={() => {
                    handleCloseCsvModal()
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>

                            <div className='d-flex flex-row justify-content-around gap-4'>
                                <span>Payer ID List CSV Upload</span>
                                <a className='' style={{ cursor: 'pointer' }} target="_blank" download href={payerIdListSample}>
                                    <Tooltip disableInteractive title="Sample Csv" placement='top' arrow>

                                        <span className=''><i className='bi bi-download'></i></span>
                                    </Tooltip>
                                </a>
                            </div>

                        </Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={csvFormik.handleSubmit}>

                        <Modal.Body>
                            <div className='row'>
                                <div className='col-md-5 mt-3'>
                                    <Select
                                        tabSelectsValue={false}
                                        closeMenuOnSelect={true}
                                        options={clientList}
                                        className="select-search"
                                        classNamePrefix="select"
                                        placeholder="Client"
                                        isClearable
                                        name="client"
                                        onChange={(e) => {
                                            csvFormik.setValues({ ...csvFormik.values, client: e?.value })
                                        }}
                                        value={clientList?.find((i) => i.value == csvFormik?.values?.client)}

                                        styles={{
                                            container: (styles, state) => ({
                                                ...styles,
                                                borderRadius: "9px",
                                            }),



                                        }} />

                                    {!!csvFormik.errors?.client &&
                                        <span className='text-danger'>{csvFormik.errors?.client}</span>
                                    }
                                </div>
                                <div className='col-md-7'>
                                    <Form.Control
                                        type="file"
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        className='form-control form-control-solid-file mt-3'
                                        required
                                        onChange={(e) => handleFileChange(e)}
                                        name="csv_file"
                                    />
                                </div>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => {
                                handleCloseCsvModal()
                            }}>
                                Close
                            </Button>
                            <Button className='btn btn-primary btnHover' disabled={csvFormik.isSubmitting} variant="primary" type="submit" >
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
                <section className="section mt-5" >
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <Form onSubmit={payerIdListFormik.handleSubmit} onReset={() => window.location.reload()}>

                                    <div className="card-header border-0 ">
                                        <div className="d-flex align-items-center ms-3 position-relative reasonCode-search">
                                            <Form.Control
                                                id="search"
                                                className="form-control form-control-solid  b-r-10"
                                                name='search'
                                                type="search"
                                                style={{ width: "100%" }}
                                                placeholder="Search"
                                                aria-label="Search Input"
                                                value={payerIdListFormik.values?.search}
                                                // value={input.file}
                                                onChange={payerIdListFormik.handleChange}
                                            />
                                        </div>
                                        <div className='d-flex p-2  align-items-center'>
                                            <Select
                                                tabSelectsValue={false}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                options={clientList}
                                                className="select-search"
                                                components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                classNamePrefix="select"
                                                placeholder="Clients"
                                                isClearable
                                                name="clients"
                                                onFocus={() => setIsFocusedClient(true)}
                                                onBlur={() => setIsFocusedClient(false)}
                                                onChange={(e) => {
                                                    setFilterState({
                                                        ...filterState,
                                                        clients: e.map(i => i.value)
                                                    })
                                                    payerIdListFormik.setValues({ ...payerIdListFormik.values, clients: e?.map(i => i.value) })
                                                }}
                                                value={clientList?.filter((i) => payerIdListFormik?.values?.clients?.includes(i?.value))}

                                                styles={{
                                                    container: (styles, state) => ({
                                                        ...styles,
                                                        borderRadius: "9px",
                                                    }),
                                                    control: (styles, state) => ({
                                                        ...styles,
                                                        backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                        borderStyle: "none", boxShadow: "none",
                                                        borderRadius: "10px",
                                                        width: '250px',
                                                        maxHeight: state.isFocused ? "auto" : "30px",
                                                    }),
                                                    indicatorSeparator: styles => ({
                                                        ...styles, width: "0px",
                                                        minHeight: "20px",
                                                    }),

                                                    valueContainer: (styles, state) => ({
                                                        ...styles,
                                                        maxHeight: isFocusedClient ? "auto" : "30px",
                                                    }),
                                                }} />
                                        </div>


                                        <div className="correspondenceDatePicker d-flex me-1 p-2  align-items-center">
                                            <Stack direction="column" spacing={8} alignItems="flex-start">

                                                <DateRangePicker
                                                    ranges={predefinedBottomRanges}
                                                    cleanable={true}
                                                    appearance="default" format={"MM/dd/yyyy"}
                                                    className="form-date-control form-control-solid b-r-1 text-dark"
                                                    placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                                                    name="deposit_date_range"
                                                    onChange={(e) => {
                                                        payerIdListFormik.setValues({
                                                            ...payerIdListFormik.values,
                                                            updated_date_range: e
                                                        })
                                                    }} value={payerIdListFormik?.values?.updated_date_range} />
                                            </Stack>
                                        </div>
                                        <div className='d-flex me-auto p-2 align-items-center gap-2'>
                                            {/* <div className="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1"> */}

                                            <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                <Button className="btn btn-light btn-active-primary  " type="submit">
                                                    <span className="svg-icon svg-icon-3">
                                                        <BiSearch />
                                                    </span>

                                                </Button>
                                            </Tooltip>
                                            {/* <div className="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1"> */}
                                            <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                <Button className="btn btn-light btn-active-primary " type="reset">
                                                    <span className="svg-icon svg-icon-3">
                                                        <BiReset />
                                                    </span>

                                                </Button>
                                            </Tooltip>
                                            {/* <Tooltip disableInteractive title="Export" placement='top' arrow>
                                                {exportLoading ?
                                                    <Button className="btn btn-light btn-active-primary">
                                                        <span className="svg-icon svg-icon-3">
                                                            <div class="spinner-border spinner-border-sm" role="status">
                                                                <span class="sr-only"></span>
                                                            </div>
                                                        </span>

                                                    </Button>
                                                    :
                                                    <Button className="btn btn-light btn-active-primary " onClick={() => { handleExportReasonCode(searchedData.search, searchedData.updated_date_range, searchedData.clients, searchedData.sort_order, searchedData.sort_key) }}>
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiExport />
                                                        </span>

                                                    </Button>


                                                }
                                            </Tooltip> */}
                                        </div>
                                        <div className="card-toolbar gap-2 me-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                            <div className='btn btn-light btn-active-primary' onClick={() => setModalUpload(true)}>
                                                File Upload
                                            </div>
                                            {checkUserPermission('create_payer_id_list') &&
                                                <Link onClick={() => changeAllTitleFunction('Create Payer ID List', CREATE_PAYER_ID_LIST)} className="btn btn-light btn-active-primary">
                                                    <span className="svg-icon svg-icon-3">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                    Create
                                                </Link>
                                            }
                                        </div>
                                    </div>
                                </Form>

                                <div className="card-body py-3">
                                    <div className="table-responsive">
                                        <div className='dashboardtable'>
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    data={data?.results}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    fixedHeader={true}
                                                    pagination
                                                    paginationServer
                                                    paginationPerPage={limit}
                                                    paginationTotalRows={totalRows}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    // subHeader
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}

                                                    sortServer
                                                    onSort={handleSort}

                                                />
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <DeleteSweetAlert handleDelete={handleDelete} isSwal={isSwal} setIsSwal={setIsSwal} />
        </React.Fragment>
    )
}

export default PayerIdList;