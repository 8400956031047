import React, { useState } from "react";
import newicon from "../../../../../../assets/img/new-icon.svg";
import deleteicon from "../../../../../../assets/img/delete-icon.svg";
import copyicon from "../../../../../../assets/img/copy-icon.svg";
import { Form, InputGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import {
  BiChevronLeft,
  BiChevronRight,
  BiFirstPage,
  BiLastPage,
} from "react-icons/bi";
import { DATA_ENTRY, EDIT_WORK, QA_REVIEW } from "../../../../../../routeNames";
import { DbkField } from "../../Elements/DbkField";
import moment from "moment";
import { handleKeyPress, handlePreventEKeyPress, preventWhiteSpaceValidate } from "../../gridFunctions";
import { isActiveDbkField } from "../../../../Dashboard/functions";

const CorresClaim = (props) => {
  const {
    job_id,
    log_id,
    setDefaultValueAssignedWorkClaim,
    addingNewCorrespondanceClaim,
    corresPondanceFocus,
    setCorresPondanceFocus,
    handleFocusCorresPondance,
    addingNewCorrespondanceClaimDbk,
    removeCorrespondanceClaimDbk,
    formik,
    arrayHelpers,
    currentPage,
    setClaim835Focus,
    claimCorresIndex,
    setClaimCorresIndex,
    handleClaimPagination,
    handleBackspace,
    totalPages,
    handleWheel,
    jobData,
    isDbkPermission,
    setState,
    state,
    handlePageNavigate,
    pdfLoaded


  } = props;
  const copyClaim = async () => {
    if (claimCorresIndex < 0) {
      setToast({
        status: true,
        type: "danger",
        message: "Please select a claim",
      });
    } else {
      await formik.setValues(prevValues => {
        const data = [...prevValues.claims];

        const copyData = {
          ...prevValues.claims[claimCorresIndex],
          corrected_claim: true,
          page_from: currentPage + 1,
          page_to: currentPage + 1,
          claim_id: "",
          patient_id: "",
        };

        data.splice(claimCorresIndex + 1, 0, copyData);

        return {
          ...prevValues,
          claims: [...data],
        };
      });

      setClaimCorresIndex(claimCorresIndex + 1);
    }
    addingNewCorrespondanceClaimDbk();
  };

  const resetClaimData = () => {
    var data = formik.values?.claims;
    data[claimCorresIndex] = {
      corrected_claim: true,
      claim_id: "",
      patient_account_number: "",
      period_start: "",
      period_end: "",
      billed: "",
      patient_id: "",
      patient_first_name: "",
      patient_middle_name: "",
      patient_last_name: "",
      page_from: "1",
      page_to: "1",
    };
    formik.setValues({
      ...formik.values,
      claims: data,
    });
  };
  const handleFloatValue = (e, type) => {
    var value = e.target.value ? parseFloat(e.target.value).toFixed(2) : ""
    var datas = formik.values.claims;
    datas[claimCorresIndex][type] = value;
    formik.setValues({ ...formik.values, claims: datas });
  };

  const handleChangeFrom = () => {
    formik.setValues((prevState) => ({
      ...prevState,
      claims: prevState.claims.map((claim, i) => {
        if (i === claimCorresIndex) {
          return {
            ...claim,
            page_from: currentPage + 1
          };
        }
        return claim;
      })
    }));


  };
  const handleChangeTo = () => {
    formik.setValues((prevState) => ({
      ...prevState,
      claims: prevState.claims.map((claim, i) => {
        if (i === claimCorresIndex) {
          return {
            ...claim,
            page_to: currentPage + 1
          };
        }
        return claim;
      })
    }));
  };
  const pathname = window.location.pathname;
  const gridData = jobData?.grid_data;
  const qaCorrectedData = gridData?.qa_corrected_data;
  const renderClass = (name, type, defaultClass, index) => {
    if (formik.errors &&
      formik.errors?.claims &&
      formik.errors?.claims[index] &&
      formik.errors?.claims[index]?.[name]) {
      return defaultClass + ' error-field'
    }
    if (pathname == QA_REVIEW) {

      if (qaCorrectedData &&
        qaCorrectedData?.claims &&
        qaCorrectedData?.claims[index] &&
        qaCorrectedData?.claims[index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'DATE' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
        moment(formik.values?.claims[index]?.[name]).format("MM/DD/YYYY") != moment(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]).format("MM/DD/YYYY")) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'TEXT' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
        formik.values?.claims[index]?.[name]?.toUpperCase() !=
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]?.toUpperCase()) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'STRING' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
        formik.values?.claims[index]?.[name] !=
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name]) {
        return defaultClass + ' qa-field'
      }
      else if (type == 'NUMBER' &&
        gridData?.claims &&
        gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index] &&
        parseFloat(formik.values?.claims[index]?.[name] ? formik.values?.claims[index]?.[name] : 0).toFixed(2) !=
        parseFloat(gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name] ?
          gridData?.claims[formik.values?.claims[index]?.claim_corrected_index ? formik.values?.claims[index]?.claim_corrected_index : index]?.[name] : 0).toFixed(2)) {
        return defaultClass + ' qa-field'
      }

      else if (gridData?.transaction_type != "CORRESPONDENCE" || formik.values?.claims[index]?.corrected_claim) {
        return defaultClass + ' qa-field'
      }
      else {
        return defaultClass;
      }


    }
    else if ((pathname == EDIT_WORK + job_id || pathname == EDIT_WORK + job_id + "?log_id=" + log_id) &&
      qaCorrectedData &&
      qaCorrectedData?.claims &&
      qaCorrectedData?.claims[index] &&
      qaCorrectedData?.claims[index]?.[name]) {
      return defaultClass + ' qa-field'
    }
    else {
      return defaultClass;
    }
  }
  return (
    <React.Fragment>
      <div className="claim835-mt">
        <div className="healthcare-sub">
          <div className="healthcare-top">
            <div className="leftside-block">
              <div className="accordion" id="claim">
                <button
                  tabIndex="-1"
                  type="button"
                  className="btn btn-healthcare accordion-icon"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Corres Claim
                </button>
              </div>
              <div className="">
                <button
                  tabIndex="-1"
                  type="button"
                  className="btn btn-healthcare"
                  id="btn-clam-corress-add"
                  onClick={() => {
                    addingNewCorrespondanceClaim();
                    setClaimCorresIndex(claimCorresIndex + 1);
                  }}
                >
                  {" "}
                  <img src={newicon} alt="" />
                  New
                </button>
              </div>
              {formik.values?.claims?.length == 1 ? (
                <button
                  type="button"
                  tabIndex="-1"

                  className="btn btn-healthcare"
                  onClick={() => {
                    removeCorrespondanceClaimDbk(claimCorresIndex),
                      resetClaimData();
                  }}
                >
                  {" "}
                  <img src={deleteicon} alt="" /> Delete
                </button>
              ) : (
                <button
                  type="button"
                  tabIndex="-1"
                  className="btn btn-healthcare"
                  disabled={formik.values?.claims?.length == 1}
                  onClick={() => {
                    removeCorrespondanceClaimDbk(claimCorresIndex),
                      arrayHelpers.remove(claimCorresIndex);
                    setClaimCorresIndex(
                      claimCorresIndex + 1 == formik.values.claims.length
                        ? claimCorresIndex - 1
                        : claimCorresIndex
                    );
                  }}
                >
                  {" "}
                  <img src={deleteicon} alt="" /> Delete
                </button>
              )}
              {/* <div className="">
                                <button tabIndex="-1" type="button" className="btn btn-healthcare" disabled={formik.values?.claims?.length == 1 || claimCorresIndex + 1 == 1} onClick={() => { arrayHelpers.remove(claimCorresIndex); setClaimCorresIndex(claimCorresIndex - 1) }}> <img src={deleteicon} alt="" /> Delete</button>
                            </div> */}
              <div className="" >
                <button
                  onClick={copyClaim}
                  tabIndex="-1"
                  type="button"
                  className="btn btn-healthcare"
                >
                  {" "}
                  <img src={copyicon} alt="" /> Copy
                </button>
              </div>
            </div>
            <div className="rightside-block exclude-disabled">
              <button
                tabIndex="-1"
                type="button"
                className="py-0 m-0 btn pagination-btn btn-sm "
                disabled={claimCorresIndex + 1 === 1 ? true : false}
                onClick={(e) => {
                  setClaimCorresIndex(0);
                  setState({
                    ...state,
                    updateDisable: !state.updateDisable,
                  })

                }}
              >
                <span>
                  <BiFirstPage
                    size="1.3em"
                    style={
                      claimCorresIndex + 1 === 1
                        ? { color: "#ffefef" }
                        : { color: "black" }
                    }
                  />
                </span>
              </button>
              <button
                tabIndex="-1"
                type="button"
                className="py-0 m-0 btn pagination-btn btn-sm"
                disabled={claimCorresIndex + 1 === 1 ? true : false}
                onClick={(e) => {
                  setClaimCorresIndex(claimCorresIndex - 1);
                  setState({
                    ...state,
                    updateDisable: !state.updateDisable,
                  })
                }}
              >
                <BiChevronLeft
                  size="1.3em"
                  style={
                    claimCorresIndex + 1 === 1
                      ? { color: "#ffefef" }
                      : { color: "black" }
                  }
                />
              </button>
              <div className="pagenum-sub">
                <input
                  data-not-take-field-count="true"
                  tabIndex="-1"
                  className="prevent-shift viewer-toolbar-current-page-input w-82"
                  type="text"
                  value={claimCorresIndex + 1}
                  onKeyDown={(e) => handleBackspace(e)}
                  onChange={(e) => {
                    handleClaimPagination(e.target.value, e);
                  }}
                />{" "}
                / {formik?.values?.claims?.length}
              </div>
              <button
                type="button"
                tabIndex="-1"
                className="py-0 m-0 pagination-btn btn btn-sm"
                disabled={
                  formik.values?.claims?.length === claimCorresIndex + 1
                    ? true
                    : false
                }
                onClick={(e) => {
                  setClaimCorresIndex(claimCorresIndex + 1);
                  setState({
                    ...state,
                    updateDisable: !state.updateDisable,
                  })
                }}
              >
                <BiChevronRight
                  size="1.3em"
                  style={
                    formik.values?.claims?.length - 1 === claimCorresIndex
                      ? { color: "#ffefef" }
                      : { color: "black" }
                  }
                />
              </button>
              <button
                type="button"
                tabIndex="-1"
                className="py-0  btn pagination-btn btn-sm "
                disabled={
                  !formik.values?.claims?.length < claimCorresIndex &&
                    formik.values?.claims?.length - 1 === claimCorresIndex
                    ? true
                    : false
                }
                onClick={(e) => {
                  setClaimCorresIndex(formik.values?.claims.length - 1);
                  setState({
                    ...state,
                    updateDisable: !state.updateDisable,
                  })
                }}
              >
                <span>
                  <BiLastPage
                    size="1.3em"
                    style={
                      formik.values?.claims?.length - 1 === claimCorresIndex
                        ? { color: "#ffefef" }
                        : { color: "black" }
                    }
                  />
                </span>
              </button>
            </div>
          </div>
        </div>
        {formik?.values?.claims?.map((claim, index) => (
          <React.Fragment key={index}>
            {claimCorresIndex == index && (
              <div
                className="bottom-wrapper patient_correspondence_details accordion-collapse collapse show"
                id="collapseTwo"
                aria-labelledby="headingTwo"
                data-bs-parent="#claim"
              >
                <div className="sub-section">
                  <div className="">
                    <Form.Label
                      tabIndex="-1"
                      type="button"
                      className="btn btn-claim"
                    >
                      Claim Details{" "}
                    </Form.Label>
                  </div>
                  <div className="wrapper-patient">
                    <InputGroup>

                      <InputGroup.Text>Patient Account Number</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          type="text"
                          // isInvalid={formik.errors?.claims && formik.errors?.claims[index] && !!formik.errors?.claims[index].patient_account_number}
                          onChange={(event) => {
                            const e = preventWhiteSpaceValidate(event)
                            formik.handleChange(e)
                          }}
                          className={renderClass('patient_account_number', 'TEXT', 'w-patient', index)}
                          onDoubleClick={() => handlePageNavigate(index, 'CORRES')}
                          onFocus={() => {
                            handleFocusCorresPondance(
                              "patient_account_number",
                              "claims",
                              index,
                              isDbkPermission?.correspondence?.correspondence_patient_acc_number
                            ),
                              setClaim835Focus(true);
                          }}
                          onKeyUp={(e) => {
                            setDefaultValueAssignedWorkClaim(e, index);
                          }}
                          value={
                            formik.values?.claims[index].patient_account_number
                          }
                          tabIndex={formik.values?.claims[index].patient_account_number != '' && pathname == DATA_ENTRY ? -1 : 0}
                          name={`claims[${index}].patient_account_number`}
                          disabled={isActiveDbkField(corresPondanceFocus, formik, isDbkPermission?.correspondence?.correspondence_patient_acc_number) ? !corresPondanceFocus.claims[index]?.patient_account_number : false}

                        />
                        {isDbkPermission?.correspondence
                          ?.correspondence_patient_acc_number &&
                          formik.errors?.claims &&
                          formik.errors?.claims[index] &&
                          formik.errors?.claims[index].patient_account_number ===
                          "Dont Match" &&
                          corresPondanceFocus.claims[index]
                            ?.patient_account_number ? (
                          <DbkField
                            formik={formik}
                            index={index}
                            type="text"
                            fieldName="patient_account_number"
                            fieldNameDbk="dbk_patient_account_number"
                            fieldType="claims"
                            setCorresPondanceFocus={setCorresPondanceFocus}
                            corresPondanceFocus={corresPondanceFocus}
                          />
                        ) : null}
                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper">
                    <InputGroup>
                      <InputGroup.Text>Period Start</InputGroup.Text>
                      <div className="dbk-main-container">
                        <InputMask
                          mask="99/99/9999"
                          className={renderClass('period_start', 'DATE', 'patient-date', index)}
                          onChange={formik.handleChange}
                          onDoubleClick={() => handlePageNavigate(index, 'CORRES')}
                          onFocus={() => {
                            handleFocusCorresPondance(
                              "period_start",
                              "claims",
                              index,
                              isDbkPermission?.correspondence?.correspondence_period_start
                            ),
                              setClaim835Focus(true);
                          }}
                          onKeyUp={(e) => {
                            setDefaultValueAssignedWorkClaim(e, index);
                          }}
                          value={formik.values?.claims[index].period_start}
                          tabIndex={formik.values?.claims[index].period_start != '' && formik.values?.claims[index].period_start != '__/__/____' && pathname == DATA_ENTRY ? -1 : 0}
                          name={`claims[${index}].period_start`}
                          placeholder={"mm/dd/yyyy"}
                          disabled={isActiveDbkField(corresPondanceFocus, formik, isDbkPermission?.correspondence?.correspondence_period_start) ? !corresPondanceFocus.claims[index]?.period_start : false}

                        />
                        {isDbkPermission?.correspondence
                          ?.correspondence_period_start &&
                          formik.errors?.claims &&
                          formik.errors?.claims[index] &&
                          formik.errors?.claims[index].period_start ===
                          "Dont Match" &&
                          corresPondanceFocus.claims[index]?.period_start ? (
                          <DbkField
                            formik={formik}
                            index={index}
                            type="date"
                            fieldName="period_start"
                            fieldNameDbk="dbk_period_start"
                            fieldType="claims"
                            setCorresPondanceFocus={setCorresPondanceFocus}
                            corresPondanceFocus={corresPondanceFocus}
                          />
                        ) : null}
                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper">
                    <InputGroup>
                      <InputGroup.Text>Period End</InputGroup.Text>
                      <div className="dbk-main-container">
                        <InputMask
                          mask="99/99/9999"
                          className={renderClass('period_end', 'DATE', 'patient-date', index)}
                          onDoubleClick={() => handlePageNavigate(index, 'CORRES')}
                          onFocus={() => {
                            handleFocusCorresPondance(
                              "period_end",
                              "claims",
                              index,
                              isDbkPermission?.correspondence?.correspondence_period_end
                            ),
                              setClaim835Focus(true);
                          }}
                          onKeyUp={(e) => {
                            setDefaultValueAssignedWorkClaim(e, index);
                          }}
                          onChange={formik.handleChange}
                          tabIndex={formik.values?.claims[index].period_end != '' && formik.values?.claims[index].period_end != '__/__/____' && pathname == DATA_ENTRY ? -1 : 0}
                          value={formik.values?.claims[index].period_end}
                          name={`claims[${index}].period_end`}
                          placeholder={"mm/dd/yyyy"}
                          disabled={isActiveDbkField(corresPondanceFocus, formik, isDbkPermission?.correspondence?.correspondence_period_end) ? !corresPondanceFocus.claims[index]?.period_end : false}

                        />

                        {isDbkPermission?.correspondence
                          ?.correspondence_period_end &&
                          formik.errors?.claims &&
                          formik.errors?.claims[index] &&
                          formik.errors?.claims[index].period_end ===
                          "Dont Match" &&
                          corresPondanceFocus.claims[index]?.period_end ? (
                          <DbkField
                            formik={formik}
                            index={index}
                            type="date"
                            fieldName="period_end"
                            fieldNameDbk="dbk_period_end"
                            fieldType="claims"
                            setCorresPondanceFocus={setCorresPondanceFocus}
                            corresPondanceFocus={corresPondanceFocus}
                          />
                        ) : null}
                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper-miname">
                    <InputGroup>
                      <InputGroup.Text>Billed Amount</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          className={renderClass('billed', 'NUMBER', 'w-miname text-end', index)}
                          onKeyUp={(e) => {
                            setDefaultValueAssignedWorkClaim(e, index);
                          }}
                          onDoubleClick={() => handlePageNavigate(index, 'CORRES')}
                          onBlur={(e) => handleFloatValue(e, "billed")}
                          onKeyDown={(e) => {
                            handlePreventEKeyPress(e);
                            handleKeyPress(e)
                          }}
                          type="number"
                          onWheel={handleWheel}
                          onFocus={() => {
                            handleFocusCorresPondance(
                              "billed",
                              "claims",
                              index,
                              isDbkPermission?.correspondence?.correspondence_billed_amount
                            ),
                              setClaim835Focus(true);
                          }}
                          onChange={formik.handleChange}
                          value={formik.values?.claims[index].billed}
                          tabIndex={formik.values?.claims[index].billed != '' && pathname == DATA_ENTRY ? -1 : 0}
                          name={`claims[${index}].billed`}
                          disabled={isActiveDbkField(corresPondanceFocus, formik, isDbkPermission?.correspondence?.correspondence_billed_amount) ? !corresPondanceFocus.claims[index]?.billed : false}

                        />
                        {isDbkPermission?.correspondence
                          ?.correspondence_billed_amount &&
                          formik.errors?.claims &&
                          formik.errors?.claims[index] &&
                          formik.errors?.claims[index].billed === "Dont Match" &&
                          corresPondanceFocus.claims[index]?.billed ? (
                          <DbkField
                            formik={formik}
                            index={index}
                            type="number"
                            fieldName="billed"
                            fieldNameDbk="dbk_billed"
                            fieldType="claims"
                            setCorresPondanceFocus={setCorresPondanceFocus}
                            corresPondanceFocus={corresPondanceFocus}
                          />
                        ) : null}
                      </div>
                    </InputGroup>
                  </div>
                </div>
                <div className="sub-section">
                  <div className="">
                    <Form.Label
                      tabIndex="-1"
                      type="button"
                      className="btn btn-claim"
                    >
                      Patient
                    </Form.Label>
                  </div>
                  <div className="wrapper-patient">
                    <InputGroup>
                      <InputGroup.Text>First Name</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          className={renderClass('patient_first_name', 'TEXT', 'w-fsname', index)}
                          type="text"
                          onDoubleClick={() => handlePageNavigate(index, 'CORRES')}
                          onFocus={() => {
                            handleFocusCorresPondance(
                              "patient_first_name",
                              "claims",
                              index,
                              isDbkPermission?.correspondence?.correspondence_patient_first_name
                            ),
                              setClaim835Focus(true);
                          }}
                          onKeyUp={(e) => {
                            setDefaultValueAssignedWorkClaim(e, index);
                          }}
                          onChange={formik.handleChange}
                          value={
                            formik.values?.claims[index].patient_first_name
                          }
                          tabIndex={formik.values?.claims[index].patient_first_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                          name={`claims[${index}].patient_first_name`}
                          disabled={isActiveDbkField(corresPondanceFocus, formik, isDbkPermission?.correspondence?.correspondence_patient_first_name) ? !corresPondanceFocus.claims[index]?.patient_first_name : false}

                        />

                        {isDbkPermission?.correspondence
                          ?.correspondence_patient_first_name &&
                          formik.errors?.claims &&
                          formik.errors?.claims[index] &&
                          formik.errors?.claims[index].patient_first_name ===
                          "Dont Match" &&
                          corresPondanceFocus.claims[index]
                            ?.patient_first_name ? (
                          <DbkField
                            formik={formik}
                            index={index}
                            type="text"
                            fieldName="patient_first_name"
                            fieldNameDbk="dbk_patient_first_name"
                            fieldType="claims"
                            setCorresPondanceFocus={setCorresPondanceFocus}
                            corresPondanceFocus={corresPondanceFocus}
                          />
                        ) : null}
                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper-miname">
                    <InputGroup>
                      <InputGroup.Text>Middle Name</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          className={renderClass('patient_middle_name', 'TEXT', 'w-miname', index)}
                          type="text"
                          onDoubleClick={() => handlePageNavigate(index, 'CORRES')}
                          onChange={formik.handleChange}
                          onFocus={() => {
                            handleFocusCorresPondance(
                              "patient_middle_name",
                              "claims",
                              index,
                              isDbkPermission?.correspondence?.correspondence_patient_middle_name
                            ),
                              setClaim835Focus(true);
                          }}
                          value={
                            formik.values?.claims[index].patient_middle_name
                          }
                          tabIndex={formik.values?.claims[index].patient_middle_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                          name={`claims[${index}].patient_middle_name`}
                          disabled={isActiveDbkField(corresPondanceFocus, formik, isDbkPermission?.correspondence?.correspondence_patient_middle_name) ? !corresPondanceFocus.claims[index]?.patient_middle_name : false}

                        />
                        {isDbkPermission?.correspondence
                          ?.correspondence_patient_middle_name &&
                          formik.errors?.claims &&
                          formik.errors?.claims[index] &&
                          formik.errors?.claims[index].patient_middle_name ===
                          "Dont Match" &&
                          corresPondanceFocus?.claims[index]
                            ?.patient_middle_name ? (
                          <DbkField
                            formik={formik}
                            index={index}
                            type="text"
                            fieldName="patient_middle_name"
                            fieldNameDbk="dbk_patient_middle_name"
                            fieldType="claims"
                            setCorresPondanceFocus={setCorresPondanceFocus}
                            corresPondanceFocus={corresPondanceFocus}
                          />
                        ) : null}
                      </div>
                    </InputGroup>
                  </div>
                  <div className="wrapper-patient">
                    <InputGroup>
                      <InputGroup.Text>Last Name</InputGroup.Text>
                      <div className="dbk-main-container">
                        <Form.Control
                          className={renderClass('patient_last_name', 'TEXT', 'w-fsname', index)}
                          type="text"
                          onDoubleClick={() => handlePageNavigate(index, 'CORRES')}
                          onFocus={() => {
                            handleFocusCorresPondance(
                              "patient_last_name",
                              "claims",
                              index,
                              isDbkPermission?.correspondence?.correspondence_patient_last_name
                            ),
                              setClaim835Focus(true);
                          }}
                          onKeyUp={(e) => {
                            setDefaultValueAssignedWorkClaim(e, index);
                          }}
                          onChange={formik.handleChange}
                          value={formik.values?.claims[index].patient_last_name}
                          tabIndex={formik.values?.claims[index].patient_last_name != '' && pathname == DATA_ENTRY ? -1 : 0}
                          name={`claims[${index}].patient_last_name`}
                          disabled={isActiveDbkField(corresPondanceFocus, formik, isDbkPermission?.correspondence?.correspondence_patient_last_name) ? !corresPondanceFocus.claims[index]?.patient_last_name : false}

                        />
                        {isDbkPermission?.correspondence
                          ?.correspondence_patient_last_name &&
                          formik.errors?.claims &&
                          formik.errors?.claims[index] &&
                          formik.errors?.claims[index].patient_last_name ===
                          "Dont Match" &&
                          corresPondanceFocus.claims[index]?.patient_last_name ? (
                          <DbkField
                            formik={formik}
                            index={index}
                            type="text"
                            fieldName="patient_last_name"
                            fieldNameDbk="dbk_patient_last_name"
                            fieldType="claims"
                            setCorresPondanceFocus={setCorresPondanceFocus}
                            corresPondanceFocus={corresPondanceFocus}
                          />
                        ) : null}
                      </div>
                    </InputGroup>
                  </div>
                </div>
                <div className="sub-section">
                  <div className="">
                    <Form.Label
                      tabIndex="-1"
                      type="button"
                      className="btn btn-claim"
                    >
                      Additionalinfo{" "}
                    </Form.Label>
                  </div>
                  <div className="pagefrom-corress">
                    <InputGroup>
                      <InputGroup.Text>Page From</InputGroup.Text>

                      <Form.Control
                        data-disabled={!pdfLoaded}
                        className={renderClass('page_from', 'NUMBER', 'w-fsname-corress', index)}
                        onFocus={() => {
                          setClaim835Focus(true);
                          handleChangeFrom();
                        }}
                        onKeyDown={(e) => {
                          handlePreventEKeyPress(e);
                        }}
                        onClick={handleChangeFrom}

                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values?.claims[index].page_from}
                        // tabIndex={formik.values?.claims[index].page_from != '' && pathname == DATA_ENTRY ? -1 : 0}
                        name={`claims[${index}].page_from`}
                      />
                    </InputGroup>
                  </div>
                  <div className="pageto-corress">
                    <InputGroup>
                      <InputGroup.Text>Page To</InputGroup.Text>
                      <Form.Control
                        data-disabled={!pdfLoaded}
                        className={renderClass('page_to', 'NUMBER', 'w-fsname-corress', index)}
                        onClick={handleChangeTo}
                        onFocus={() => {
                          setClaim835Focus(true);
                          handleChangeTo();
                        }}
                        onKeyDown={(e) => {
                          handlePreventEKeyPress(e);
                        }}
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values?.claims[index].page_to}
                        name={`claims[${index}].page_to`}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
};

export default CorresClaim;
