import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import AdminMaster from '../../../Layout/admin/Master'
import { createClaimsFiling, getSingleClaimsFiling, getAllGroupDropdown } from '../../../Redux/claimsFiling/action';


import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { CLAIMS_FILING, EMAIL_TEMPLATE } from '../../../routeNames';
import Select from "react-select";
import { id } from 'date-fns/locale';
import ReasonCode from '../ResonCodes';
import { createOrUpdateEmailTemplate, getAllEmailTemplates, getSingleEmailTemplate } from '../../../Redux/emailTemplate/action';
import CkEditor from '../../CkEditor/CkEditor';
import { useRef } from 'react';
import { changeAllTitleFunction, getRedirectTabId } from '../Dashboard/functions';



const CreateUpdateEmailTemplate = (props) => {

    const navigate = useNavigate()
    const editorRef = useRef(null)
    const { template_id, type } = useParams();

    const [state, setState] = useState({
        error: false,
        error_message: "",
        removed_ids: []

    })
    const [toaster, setToaster] = useState(false)
    const [message, setMessage] = useState(false)
    const [loader, setLoader] = useState(null);
    const [codeDefinition, setCodeDefinition] = useState([])
    const [ckContent, setCkContent] = useState("")
    const [toast, setToast] = useState({
        type: "",
        message: "",
        toast: false,

    });


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            template: '',
            email_type: '',
            subject: '',
            contents: ckContent ?? "",


        },

        validationSchema: Yup.object().shape({
            email_type: Yup.string().trim().required('Required'),
            subject: Yup.string().trim().required('Required'),
            contents: Yup.string().trim().required('Required'),

        }),
        // validate: (values) => {
        //     let errors = {}
        //     if (values.client === 'null') {
        //         errors.client = "Required"
        //     }
        //     return errors;
        // },

        onSubmit: (values, { setSubmitting }) => {
            const RedirectId = getRedirectTabId()
            setLoader(true)
            createOrUpdateEmailTemplate(values)((response) => {

                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    setLoader(false)

                    setToaster('success')
                    const timer = setTimeout(() => {
                        setToaster(false)
                        setSubmitting(false)
                        changeAllTitleFunction("Email Templates", EMAIL_TEMPLATE, RedirectId)
                    }, 1000);
                    return () => clearTimeout(timer);


                } else {
                    setLoader(false)

                    if (response?.response?.data?.errors?.email_type[0] && response?.response?.data?.errors?.email_type[0]) {
                        setMessage(response?.response?.data?.errors?.email_type[0])
                    }
                    setToaster('error')

                    const timer = setTimeout(() => {
                        setToaster(false)
                        setSubmitting(false)
                        setMessage(false)
                    }, 2000);
                    return () => clearTimeout(timer);

                }

            });

        },
    });

    // useEffect(() => {

    //     getAllEmailTemplates()((response) => {
    //         if (response?.status) {
    //             setTemplateList(response?.data)
    //         }
    //     })

    // }, []);
    useEffect(() => {
        if (template_id) {
            getSingleEmailTemplate(template_id)((response) => {
                if (response?.status && response?.data) {
                    formik.setValues({
                        template: response?.data?.id,
                        email_type: response?.data?.email_type,
                        subject: response?.data?.subject,
                        // contents: response?.data?.contents,
                        contents: "",

                    })
                    setCkContent(response?.data?.contents)
                }

            })
        }
    }, [template_id])


    // const addSubjectFormat = (type, name) => {
    //     formik.setValues({
    //         ...formik.values,
    //         [name]: formik.values?.[name] + type,
    //     })
    // }
    const addSubjectFormat = (content, field) => {
        const fieldContent = formik.values[field] || "";
        const newContent = `${fieldContent}${content}`;
        formik.setFieldValue(field, newContent);
    };
    // const addCkEditorSubjectFormat = (content, field) => {
    //     const editor = editorRef.current.editor;
    //     const model = editor.model;

    //     const selection = model.document.selection;
    //     if (selection) {
    //         const selectedElement = selection.getFirstPosition().nodeAfter;
    //         const selectedText = selectedElement ? selectedElement.data : "";

    //         const newText = selectedText.replace(content, "");
    //         const newContent = `${newText}${content}`;

    //         model.change(writer => {
    //             writer.insertText(newContent, selection.getFirstPosition());
    //         });

    //         const data = editor.getData();
    //         formik.setFieldValue(field, data);
    //     }
    // };

    const addCkEditorSubjectFormat = (content, field) => {
        const editor = editorRef.current.editor;
        const model = editor.model;

        const selection = model.document.selection;
        if (selection) {
            const selectedElement = selection.getFirstPosition().nodeAfter;
            const selectedText = selectedElement ? selectedElement.data : "";

            // const newText = selectedText.replace(content, "");
            const newText = "";
            const newContent = `${content}${newText}`;
            model.change(writer => {
                writer.insertText(newContent, selection.getFirstPosition());
            });

            const data = editor.getData();
            formik.setFieldValue(field, data);
        }
    };




    return (
        <React.Fragment>

            <AdminMaster ToastMessage={toaster} message={message} />

            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1>{template_id ? "Edit Email Template" : "Create Email Template"}</h1>

                </div>

                <section className="section mt-5">

                    <div className="card card-flush py-4">

                        <FormikProvider value={formik}>


                            <Form
                                onSubmit={formik.handleSubmit}
                                enableReinitialize
                            >
                                <div className=' px-4 py-2'>
                                    <div className="d-flex justify-content-between flex-wrap" >

                                        <div className='col-lg-5 mb-3'>
                                            <div className="mb-10">
                                                <Form.Label className='fw-bold'>Templates</Form.Label>
                                                <Form.Control as="select"
                                                    disabled={template_id ? true : false}
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name="email_type"
                                                    //isValid={formik.touched.client && !formik?.errors?.client}
                                                    isInvalid={!!formik?.errors?.claim_id}
                                                    maxLength={30}
                                                    placeholder="Template"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.email_type}
                                                >
                                                    <option value="">Select an Template</option>
                                                    <option key={1} value={"Arrival_of_FTP_File"}>{"Arrival of FTP File"}</option>
                                                    <option key={2} value={"Absence_of_Expected_File"}>{"Absence of Expected File"}</option>
                                                    <option key={3} value={"Non_Recognized_File"}>{"Non recognized file"}</option>
                                                    <option key={4} value={"File_Transfer_To_Client"}>{"File transfer to client"}</option>
                                                    <option key={5} value={"File_Split"}>{"File split"}</option>
                                                    <option key={6} value={"Arrival_Of_Duplicate_837_Or_Self_Pay_File"}>{"Arrival Of Duplicate 837/Self Pay File"}</option>
                                                    {/* <option key={6} value={"Others"}>{"Others"}</option> */}


                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{formik.errors.email_type}</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 mb-3'>
                                            <Form.Group className="" >

                                                <div className='d-flex flex-row align-items-center'>

                                                    <Form.Label className='fw-bold'>Subject</Form.Label>
                                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addSubjectFormat('[Client ID]', 'subject')}>Client ID</Button>


                                                </div>


                                                <Form.Control
                                                    name='subject'
                                                    type="text"
                                                    autoComplete="false"
                                                    className='b-r-10 form-control-solid'
                                                    maxLength="255"
                                                    placeholder="Subject"
                                                    isValid={formik.touched?.subject && !formik?.errors?.subject}
                                                    isInvalid={!!formik.errors?.subject}
                                                    value={formik.values?.subject}
                                                    onChange={formik.handleChange}
                                                />
                                                <Form.Control.Feedback type="invalid">{formik?.errors?.subject}</Form.Control.Feedback>

                                            </Form.Group>
                                        </div>
                                        <div className='col-lg-12'>
                                            <div className='d-flex flex-row align-items-center flex-wrap'>

                                                <Form.Label className='fw-bold'>Mail Content</Form.Label>
                                                {["Arrival_Of_Duplicate_837_Or_Self_Pay_File", "Arrival_of_FTP_File", "Absence_of_Expected_File", "Non_Recognized_File", "File_Split"].includes(formik.values.email_type) &&
                                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Client Name]', 'contents')}>Client Name</Button>
                                                }
                                                {["Arrival_of_FTP_File", "Absence_of_Expected_File", "File_Split"].includes(formik.values.email_type) &&
                                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[File received]', 'contents')}>File received</Button>
                                                }
                                                {["Arrival_of_FTP_File", "File_Transfer_To_Client", "Non_Recognized_File", "File_Split"].includes(formik.values.email_type) &&
                                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[File Name]', 'contents')}>File Name</Button>
                                                }
                                                {["File_Transfer_To_Client"].includes(formik.values.email_type) &&
                                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[File Type]', 'contents')}>File Type</Button>
                                                }
                                                {["Arrival_of_FTP_File", "File_Transfer_To_Client", "Absence_of_Expected_File", "Non_Recognized_File", "File_Split"].includes(formik.values.email_type) &&
                                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[File Size]', 'contents')}>File Size</Button>
                                                }
                                                {["Absence_of_Expected_File"].includes(formik.values.email_type) &&
                                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Missing Files]', 'contents')}>Missing Files</Button>
                                                }
                                                {["File_Split"].includes(formik.values.email_type) &&
                                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Split Count]', 'contents')}>Split Count</Button>
                                                }
                                                {["Arrival_of_FTP_File", "Absence_of_Expected_File", "Non_Recognized_File", "File_Split"].includes(formik.values.email_type) &&
                                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Time Stamp]', 'contents')}>Time Stamp</Button>
                                                }
                                                {["Arrival_Of_Duplicate_837_Or_Self_Pay_File"].includes(formik.values.email_type) &&
                                                    <>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[837_Files_Received]', 'contents')}>837_Files_Received</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[837_File_Size]', 'contents')}>837_File_Size</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Actual_837_File_Name]', 'contents')}>Actual_837_File_Name</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Duplicate_837_File_Name]', 'contents')}>Duplicate_837_File_Name</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Duplicate_837_File_Counts]', 'contents')}>Duplicate_837_File_Counts</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[837_Timestamp]', 'contents')}>837_Timestamp</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Self-Pay_Files_Received]', 'contents')}>Self-Pay_Files_Received</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Self-Pay_File_Size]', 'contents')}>Self-Pay_File_Size</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Actual_Self-Pay_File_Name]', 'contents')}>Actual_Self-Pay_File_Name</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Duplicate_Self-Pay_File_Name]', 'contents')}>Duplicate_Self-Pay_File_Name</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Duplicate_Self-Pay_File_Counts]', 'contents')}>Duplicate_Self-Pay_File_Counts</Button>
                                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addCkEditorSubjectFormat('[Self-Pay_Timestamp]', 'contents')}>Self-Pay_Timestamp</Button>
                                                    </>
                                                }
                                            </div>
                                            <CkEditor editorRef={editorRef} ckContent={ckContent} setCkContent={setCkContent} formik={formik} />
                                        </div>

                                    </div>


                                    <div className='d-flex flex-row justify-content-end mt-2'>

                                        <div className=''>
                                            <button
                                                type='button'
                                                className='btn btn-secondary btn-active-primary me-2'
                                                onClick={() => { changeAllTitleFunction("Email Templates", EMAIL_TEMPLATE) }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className=''>
                                            <button
                                                disabled={!formik.isValid || formik.isSubmitting}
                                                type='submit'
                                                className='btn btn-primary btnHover'
                                            >
                                                {template_id ?

                                                    formik.isSubmitting ? 'Updating' : "Update"

                                                    :
                                                    formik.isSubmitting ? "Creating" : 'Create'
                                                }

                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                        </FormikProvider>



                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default CreateUpdateEmailTemplate