import React, { useEffect, useState } from 'react'
import { Worker, Viewer, SpecialZoomLevel, ScrollMode, RotateDirection, MinimalButton, DocumentLoadEvent } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { ToolbarSlot } from "@react-pdf-viewer/toolbar";
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import { ThumbnailIcon } from '@react-pdf-viewer/default-layout';

function CustomeSecondViewer({ reRenderViewer, calculatedZoom, activePdf, fileUrl, handleDocumentLoad, handlePageChange, updateJumb, jumbNo, setUpdateJumb, currentPage }) {
  // const [currentPage, setCurrentPage] = useState(0);
  const [rotatePosition, setRotatePosition] = useState('stright')
  const thumbnailPluginInstance = thumbnailPlugin();
  const rotatePluginInstance = rotatePlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const scrollModePluginInstance = scrollModePlugin();
  const { jumpToPage } = pageNavigationPluginInstance;


  const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
      {function (slots: ToolbarSlot) {
        const {
          CurrentPageInput, EnterFullScreen, GoToFirstPage, GoToNextPage, GoToPreviousPage, GoToLastPage, NumberOfPages, Zoom, ZoomIn, ZoomOut,
        } = slots;

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >


            <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '100px' }}>

              <GoToFirstPage />
              <GoToPreviousPage />
              <CurrentPageInput />
              / <NumberOfPages />
              <GoToNextPage />
              <GoToLastPage />

            </div>
            <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '42rem' }}>
              <ZoomOut />
              <Zoom />
              <ZoomIn />
            </div>
            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
              <EnterFullScreen />
            </div>
          </div>
        );
      }}
    </Toolbar>
  );
  const defaultLayoutPluginInstance = defaultLayoutPlugin({

    renderToolbar, toolbarPlugin: {
      pageNavigationPlugin: {
        enableShortcuts: true,
      },

    },

    fullScreenPlugin: {
      onEnterFullScreen: (zoom) => {
        zoom(SpecialZoomLevel.PageFit);
        defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
          ScrollMode.Wrapped
        );
      },

      onExitFullScreen: (zoom) => {
        zoom(SpecialZoomLevel.PageWidth);
        defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
          ScrollMode.Vertical
        );
      },
    },

    sidebarTabs: (defaultTabs) => [
      {
        content: <Thumbnails />,
        icon: <ThumbnailIcon />,
        title: 'Thumbnails',
      },
    ],

    thumbnailPlugin: {
      thumbnailWidth: 50,
    },

  });
  const { Thumbnails } = thumbnailPluginInstance;
  const { RotatePage } = rotatePluginInstance;
  const handlePageRotate = (props, position, currentPage) => {
    if (position == 'left') {
      setRotatePosition(rotatePosition == 'stright' ? 'left' : rotatePosition == 'left' ? 'bottom' : rotatePosition == 'bottom' ? 'right' : rotatePosition == 'right' ? 'stright' : null)
      props.onRotatePage(currentPage, RotateDirection.Backward)
    }
    else if (position == 'right') {
      setRotatePosition(rotatePosition == 'stright' ? 'right' : rotatePosition == 'right' ? 'bottom' : rotatePosition == 'bottom' ? 'left' : rotatePosition == 'left' ? 'stright' : null)
      props.onRotatePage(currentPage, RotateDirection.Forward)
    }

  };
  // const handlePageChange = (e) => {
  //   setCurrentPage(e.currentPage)

  //   // setInitialPage(e.currentPage)
  // };
  useEffect(() => {
    if (updateJumb && activePdf == 'SECOND') {
      jumpToPage(jumbNo)
      setUpdateJumb(false)
    }
  }, [updateJumb])
  return (



    <>

      <div

        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          left: '20rem',
          zIndex: '99'
        }}
      >
        <div
          className='thumbnail-container'

          style={{ padding: '0px 2px', marginLeft: 'auto' }}>

          <RotatePage>
            {(props) => (
              <MinimalButton onClick={() => handlePageRotate(props, 'left', currentPage)}>
                <svg
                  width="15px"
                  height="15px"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-rotate-ccw"
                >
                  <polyline points="1 4 1 10 7 10"></polyline>
                  <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
                </svg>
              </MinimalButton>
            )}
          </RotatePage>
        </div>
        <div style={{ padding: '0px 2px', }}>
          <RotatePage>
            {(props) => (
              <MinimalButton onClick={() => handlePageRotate(props, 'right', currentPage)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ffffff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M21 2v6h-6" />
                  <path d="M21 13a9 9 0 11-3-7.7L21 8" />
                </svg>

              </MinimalButton>
            )}
          </RotatePage>
        </div>
      </div>


      <div className='second-pdf' style={{ height: activePdf == 'SECOND' ? '100%' : '0%' }}>
        {reRenderViewer &&
          <Viewer
            plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance, scrollModePluginInstance, rotatePluginInstance, thumbnailPluginInstance]}
            fileUrl={fileUrl}

            onDocumentLoad={handleDocumentLoad}
            onPageChange={handlePageChange}
            defaultScale={calculatedZoom}


          />
        }

      </div>
    </>
    // <Viewer
    //   plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance, scrollModePluginInstance, rotatePluginInstance, thumbnailPluginInstance]}
    //   fileUrl={fileUrl}

    // // onDocumentLoad={handleDocumentLoad}
    // // onPageChange={handlePageChange}

    // />
  )
}

export default CustomeSecondViewer