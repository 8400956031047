import axios from 'axios';
import bowser from 'bowser';
import axiosInstance from '../../axiosInstance';

export const registerDevice = (currentToken) => {

    const browser = bowser.getParser(navigator.userAgent);


    const data = {
        "firebase_token": currentToken,
        "unique_device": "IO7psJZPyVTnq9CoObKIgePeWXY2",
        "browser_details": Object.entries(browser.parsedResult)
    }


    try {
        axiosInstance.post("/users/device-registration", data)
            .then((response) => {
            })
            .catch((err) => {
            });

    } catch (error) {

    }

}

