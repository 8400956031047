import React, { useRef } from 'react';
import { Worker, Viewer, SpecialZoomLevel, ScrollMode, RotateDirection, MinimalButton, DocumentLoadEvent } from '@react-pdf-viewer/core';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import type { RenderThumbnailItemProps } from '@react-pdf-viewer/thumbnail';
import { ThumbnailIcon } from '@react-pdf-viewer/default-layout';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { ToolbarSlot } from "@react-pdf-viewer/toolbar";
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { scrollModePlugin } from '@react-pdf-viewer/scroll-mode';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import Header from '../../../../Elements/Header';
import Grid from './../Grid/index';
import { useState } from 'react';
import { useEffect } from 'react';
import { getJobDetails, getQareview, updateJobUserStartTime, verificationJobUser } from '../../../../Redux/client/action';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD, DATA_ENTRY, EDIT_WORK, QA_REVIEW } from '../../../../routeNames';
import { exitGrid, getEditGridData, getSubJobUserData } from '../../../../Redux/grid/action';
import { Toast, ToastContainer, Button } from 'react-bootstrap';
import { onMessageListener } from '../../../../firebase';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getJobDetailReport } from '../../../../Redux/reports/action';
import { changeAllTitleFunction } from '../../Dashboard/functions';
import { getKeepAliveApi } from '../../../../Redux/user/action';
import CustomeFirstViewer from './CustomeFirstViewer';
import CustomeSecondViewer from './CustomeSecondViewer';
import Swal from 'sweetalert2';




const PdfViewer = () => {
    const pageNavigationPluginInstance = pageNavigationPlugin();
    // const { jumpToPage } = pageNavigationPluginInstance;
    const [jumbNo, setJumbNo] = useState('')
    const [updateJumb, setUpdateJumb] = useState(false)
    const [plugins, setPlugins] = useState([])
    const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
        <Toolbar>
            {function (slots: ToolbarSlot) {
                const {
                    CurrentPageInput, EnterFullScreen, GoToFirstPage, GoToNextPage, GoToPreviousPage, GoToLastPage, NumberOfPages, Zoom, ZoomIn, ZoomOut,
                } = slots;

                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                        }}
                    >


                        <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '100px' }}>

                            <GoToFirstPage />
                            <GoToPreviousPage />
                            <CurrentPageInput />
                            / <NumberOfPages />
                            <GoToNextPage />
                            <GoToLastPage />

                        </div>
                        <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '42rem' }}>
                            <ZoomOut />
                            <Zoom />
                            <ZoomIn />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <EnterFullScreen />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    const user = JSON.parse(localStorage.getItem("user"));
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const log_id = queryParams.get('log_id');

    let { job_id } = useParams();

    //grid states
    const [isUpdateUserStartTime, setIsUpdateUserStartTime] = useState(false);
    const [multipleJobIds, setMultipleJobIds] = useState([]);
    const [jobData, setJobData] = useState("");
    const [fileUrl, setFileUrl] = useState("");
    const [fileUrl1, setFileUrl1] = useState("");
    const [fileUrl2, setFileUrl2] = useState("");
    const [noJob, setNoJOb] = useState(false);
    const [noJob2, setNoJOb2] = useState(false);
    const [secondData, setSecondData] = useState({});
    const [loadData, setLoadData] = useState(false)
    const [firstLoad, setFirstLoad] = useState(false)
    const [secondLoad, setSecondLoad] = useState(false)
    const [formapiLoaded, setformApiLoaded] = useState(false)
    const [saveSuccess, setSaveSuccess] = useState(false)
    const [currentJobVerified, setCurrentJobVerified] = useState(true)

    const [firstSaveLoad, setFirstSaveLoad] = useState(false)
    const [secondSaveLoad, setSecondSaveLoad] = useState(false)

    const [activePdf, setActivePdf] = useState('FIRST')

    const [firstPdfLoad, setFirstPdfLoad] = useState(false)
    const [secondPdfLoad, setSecondPdfLoad] = useState(false)

    //page states
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalPages2, setTotalPages2] = useState(0);
    const [initialPage, setInitialPage] = useState();
    const [isServerError, setServerError] = useState(false);
    //notification states
    const [notification, setNotification] = useState({ title: '', body: '', job: '' });
    const [show, setShow] = useState(false);
    const [toast, setToast] = useState({
        status: false,
        type: "",
        message: ""
    });
    const user_idle_time_out = user?.settings?.filter((i) => i?.key_name === 'ideal_user_timeOut')[0]?.value

    const jumpToPage = (pageNumber) => {
        setJumbNo(pageNumber)
        setTimeout(() => {

            setUpdateJumb(false)
        }, 200);
        setUpdateJumb(true)
    }

    useEffect(() => {
        if (log_id) {
            getGridLogData()
        } else {
            getGridData();
        }

    }, [log_id]);

    useEffect(() => {
        if (!noJob2 && loadData && secondData.job != jobData?.job) {
            setCurrentJobVerified(false)
            if (isServerError) {
                window.location.reload()
            }
            if (activePdf == 'FIRST') {
                setCalculatedZoom(0)
                setCurrentPage(0)
                setFirstSaveLoad(false)

                setActivePdf('SECOND')
                setJobData(secondData)
                setFileUrl(secondData?.file_url)
                setNoJOb(noJob2)
                setTotalPages(totalPages2)
                setNoJOb2(false)
                // setSecondData({})
                setFileUrl1("")
                if (window.location.pathname == QA_REVIEW) {
                    setIsSubJob(false)
                    setIsFirstJob(false);
                } else {
                    setIsSubJob(secondData?.is_sub_job)
                    setIsFirstJob(secondData?.is_first_sub_job);
                }



            }
            else if (activePdf == 'SECOND') {
                setCalculatedZoom2(0)
                setCurrentPage(0)
                setSecondSaveLoad(false)
                setActivePdf('FIRST')
                setJobData(secondData)
                setFileUrl(secondData?.file_url)
                setNoJOb(noJob2)
                setTotalPages(totalPages2)
                setNoJOb2(false)
                // setSecondData({})
                setFileUrl2("")
                if (window.location.pathname == QA_REVIEW) {
                    setIsSubJob(false)
                    setIsFirstJob(false);
                } else {
                    setIsSubJob(secondData?.is_sub_job)
                    setIsFirstJob(secondData?.is_first_sub_job);
                }


            }
            setLoadData(false)
        } else if (noJob2 == true) {
            setNoJOb(true)
        }

    }, [loadData, secondData.job, saveSuccess]);
    useEffect(() => {
        if (!loadData && saveSuccess && secondData.job == jobData?.job) {
            let task_type = "1";

            if (window.location.pathname == DATA_ENTRY) {

                task_type = "1"
            }
            else if (window.location.pathname == QA_REVIEW) {
                task_type = "2"
            }
            else if (window.location.pathname == EDIT_WORK + job_id) {
                task_type = "2"
            }
            if (jobData?.job) {
                verificationJobUser(task_type, jobData?.job)((response) => {
                    if (response.data == false) {
                        setErrorAlert({
                            status: true,
                            is_recently_loaded_job: true,
                            message: 'You have no permission on the recently loaded job',
                            type: '',
                        });
                    }
                    else {
                        updateJobUserStartTime(task_type, jobData?.job)((response) => {
                            setCurrentJobVerified(true)
                            setSaveSuccess(false)
                            getJobDetails(task_type, job_id, true)((response) => {
                                if (response && response?.status_code == 400 && response?.errors == "No job remaining") {
                                    setNoJOb2(true)
                                } else if (response && response?.response && response?.response?.data?.status_code == 500) {
                                    setServerError(true)
                                }
                                else {
                                    setSecondData(response?.data)
                                    if (activePdf == 'FIRST') {
                                        setFileUrl2(response?.data?.file_url)
                                    }
                                    else if (activePdf == 'SECOND') {
                                        setFileUrl1(response?.data?.file_url)
                                    }

                                }
                            })
                        })
                    }
                })
            }
        }
    }, [saveSuccess, jobData?.job, secondData?.job, loadData])

    const getGridLogData = () => {
        getJobDetailReport(log_id)((response) => {
            if (response && response.status) {
                setNoJOb(false)
                setJobData(response?.data)
                setFileUrl(response?.data?.file_url)
                setFileUrl1(response?.data?.file_url)
            }
            else if (response && response.status_code == 204) {
                setToast({ status: true, type: "danger", message: response?.message });
                Swal.fire({
                    icon: 'error',
                    confirmButtonColor: '#6ba2b6',
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    backdrop: true,
                    title: response?.message,
                    customClass: {
                        title: 'swal-titleText fs-5'
                    }
                }).then((result) => {
                    if (result?.isConfirmed) {
                        parent.document.getElementById(`delete-current-tab`).click()
                    }
                    else {

                    }
                })


            }
        })
    }

    const getGridData = () => {
        let task_type = "1";

        if (window.location.pathname == DATA_ENTRY) {

            task_type = "1"
        }
        else if (window.location.pathname == QA_REVIEW) {
            task_type = "2"
        }
        else if (window.location.pathname == EDIT_WORK + job_id) {
            task_type = "2"
        }

        setCurrentJobVerified(false)
        const response = getJobDetails(task_type, job_id)((response) => {

            if (response && response?.status_code == 400 && response?.errors == "No job remaining") {
                setNoJOb(true)
            }
            else {
                // setFirstLoad(true)
                setNoJOb(false)
                setNoJOb2(false)
                setJobData(response?.data)
                setFileUrl(response?.data?.file_url)
                setFileUrl1(response?.data?.file_url)

                if (window.location.pathname == QA_REVIEW) {
                    setIsSubJob(false)
                    setIsFirstJob(false);
                } else {
                    setIsSubJob(response?.data?.is_sub_job)
                    setIsFirstJob(response?.data?.is_first_sub_job);
                }
                getJobDetails(task_type, job_id, true)((response) => {
                    if (response && response?.status_code == 400 && response?.errors == "No job remaining") {
                        setNoJOb2(true)
                        setCurrentJobVerified(true)
                    }
                    else {
                        setCurrentJobVerified(true)
                        setSecondData(response?.data)
                        setFileUrl2(response?.data?.file_url)
                    }
                })

            }

        })
    }


    const [isSubJob, setIsSubJob] = useState(false);
    const [isFirstJob, setIsFirstJob] = useState(false);
    const jumpRef = useRef(null)


    const scrollModePluginInstance = scrollModePlugin();
    const rotatePluginInstance = rotatePlugin();
    const { RotatePage } = rotatePluginInstance;

    //custom layout instance for pdf viewer
    const defaultLayoutPluginInstance = defaultLayoutPlugin({

        renderToolbar, toolbarPlugin: {
            pageNavigationPlugin: {
                enableShortcuts: true,
            },

        },

        fullScreenPlugin: {
            onEnterFullScreen: (zoom) => {
                zoom(SpecialZoomLevel.PageFit);
                defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                    ScrollMode.Wrapped
                );
            },

            onExitFullScreen: (zoom) => {
                zoom(SpecialZoomLevel.PageWidth);
                defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                    ScrollMode.Vertical
                );
            },
        },

        sidebarTabs: (defaultTabs) => [
            {
                content: <Thumbnails />,
                icon: <ThumbnailIcon />,
                title: 'Thumbnails',
            },
        ],

        thumbnailPlugin: {
            thumbnailWidth: 50,
        },

    });


    //trigger fucntion for page scroll or change events
    const handlePageChange1 = (e) => {
        if (activePdf == 'FIRST') {
            // alert("HANDLEPAGE FIRST" + "  " + String(activePdf) + "  " + String(e.currentPage))
            setCurrentPage(e.currentPage)
        }
    };
    const handlePageChange2 = (e) => {
        if (activePdf == 'SECOND') {
            // alert("HANDLEPAGE SECOND" + "  " + String(activePdf) + "  " + String(e.currentPage))
            setCurrentPage(e.currentPage)
        }
    };
    const [stop, setStop] = useState(false)
    const [pdfLoaded, setPdfLoaded] = useState(false)



    const thumbnailPluginInstance = thumbnailPlugin();
    const { Thumbnails } = thumbnailPluginInstance;

    const renderThumbnailItem = (props: RenderThumbnailItemProps) => (
        <div key={props.key}>
            {/* Jump to the corresponding page when clicking the thumbnail */}
            <div className='p-2' onClick={() => handleThumbnailChange(props.pageIndex)}>
                {/* Thumbnail image */}
                {props.renderPageThumbnail}
            </div>

            {/* Page label */}
            {props.renderPageLabel}
        </div>
    );

    // fucntion for check rotation and other fucntions
    const [rotatePosition, setRotatePosition] = useState('stright')

    const handlePageRotate = (props, position, currentPage) => {
        if (position == 'left') {
            setRotatePosition(rotatePosition == 'stright' ? 'left' : rotatePosition == 'left' ? 'bottom' : rotatePosition == 'bottom' ? 'right' : rotatePosition == 'right' ? 'stright' : null)
            props.onRotatePage(currentPage, RotateDirection.Backward)
        }
        else if (position == 'right') {
            setRotatePosition(rotatePosition == 'stright' ? 'right' : rotatePosition == 'right' ? 'bottom' : rotatePosition == 'bottom' ? 'left' : rotatePosition == 'left' ? 'stright' : null)
            props.onRotatePage(currentPage, RotateDirection.Forward)
        }

    };

    // notification funciton for firebase listener
    onMessageListener().then(payload => {
        if (payload.data.notification_type == 10) {
            setShow(true);
            setNotification({ title: payload.data.title, body: payload.data.body, job: payload?.data?.job_instance ? payload?.data?.job_instance : "" })
        }
    });

    const handlePermissionJob = () => {
        if (errorAlert?.is_errorJob) {
            setjobErrorAlert(false)
            localStorage.setItem("is_job_error", true);
            localStorage.removeItem('grid_data')
            localStorage.removeItem('grid_state_data')
            localStorage.removeItem('grid_qdx_data')
            localStorage.removeItem('grid_overall_data')
            window.location.reload()
        } else if (errorAlert?.type == "Idle") {
            setErrorAlert({
                status: false,
                message: '',
                type: '',
            });
            changeAllTitleFunction('Dashboard', '/admin/dashboard')

        }
        else if (errorAlert.message.includes("You have no permission to save this job") || errorAlert?.is_recently_loaded_job == true) {
            if (errorAlert?.is_recently_loaded_job == true) {
                localStorage.removeItem('grid_data')
                localStorage.removeItem('grid_state_data')
                localStorage.removeItem('grid_qdx_data')
                localStorage.removeItem('grid_overall_data')
                window.location.reload()
            }
            else if (localStorage.getItem("SAVE_EXIT")) {
                setErrorAlert({
                    status: false,
                    message: '',
                    type: '',
                });
                localStorage.removeItem("SAVE_EXIT");
                localStorage.removeItem('grid_data')
                localStorage.removeItem('grid_state_data')
                localStorage.removeItem('grid_qdx_data')
                localStorage.removeItem('grid_overall_data')
                changeAllTitleFunction('Dashboard', '/admin/dashboard')
            }
            else {
                // window.location.reload()
                // setLoadData(true)
                setSaveSuccess(true)
            }
            setErrorAlert({
                status: false,
                message: '',
                type: '',
            });
        }
        else {

            setErrorAlert({
                status: false,
                message: '',
                type: '',
            });
            window.location.reload();
        }
    }


    const [commentAlert, setCommentAlert] = useState(false)
    const [errorAlert, setErrorAlert] = useState({
        status: false,
        message: "",
        type: '',
    })
    const [error837, setError837] = useState({
        status: false,
        message: "",
        type: '',
    })
    useEffect(() => {

        if (jobData && jobData?.file_info && jobData?.file_info?.comment) {
            setCommentAlert(true)
        }

    }, [jobData])

    const [jobUserData, setJobUserData] = useState({})
    const [subJobOptions, setSubJobOptions] = useState([])
    const [jobErrorAlert, setjobErrorAlert] = useState(false)

    useEffect(() => {

        if (jobData?.is_large_batch_job) {
            getSubJobUserData(jobData?.job)((response) => {
                setJobUserData(response?.data);
                const subjobs = [];
                response?.data?.map((user, index) => {
                    subjobs.push({
                        value: user?.pk,
                        label: user?.unique + "|" + user?.from_page + "-" + user?.to_page + "|" + user?.staff_name,
                        staff_name: user?.staff_name,
                        staff_id: user?.staff_id,
                        page_range: user?.from_page + "-" + user?.to_page,
                        is_select: false,
                        is_active: user?.is_active,
                        assign_reason: "",
                        assignTo: user?.is_active ? "user" : "common"

                    })
                })
                setCommentAlert(true)
                setSubJobOptions(subjobs)
            });
        }

    }, [jobData?.is_large_batch_job])
    const [containerWidth, setContainerWidth] = useState(1800);
    const [calculatedZoom, setCalculatedZoom] = useState(0);
    const [reRenderViewer, setReRenderViewer] = useState(true);
    const [reRenderViewer2, setReRenderViewer2] = useState(true);
    const [calculatedZoom2, setCalculatedZoom2] = useState(0);


    const setZoomScale = (e, setStateCalcZoom, setReRender) => {
        const { doc } = e;
        const numPages = doc.numPages;

        const getPagePromises = [];

        // Iterate through all pages to find the one with the maximum width
        for (let pageIndex = 1; pageIndex <= numPages; pageIndex++) {
            const getPagePromise = doc.getPage(pageIndex).then((page) => {
                const viewport = page.getViewport({ scale: 1 });
                const pageWidth = viewport.width;

                return pageWidth;
            });

            getPagePromises.push(getPagePromise);
        }

        // Wait for all promises to resolve
        Promise.all(getPagePromises).then((pageWidths) => {
            const maxPageWidth = Math.max(...pageWidths);

            const containerToPageWidthRatio = containerWidth / maxPageWidth;
            const initialZoom = containerToPageWidthRatio;

            // Use the `zoom` function from the library to set the initial zoom level
            // Replace this with the actual function provided by the library to set the zoom level
            // For example, if the library provides a `zoom` prop for the Viewer component
            // You might do something like:
            // setZoom(initialZoom);
            setStateCalcZoom(initialZoom);
            setReRender(false)
            setTimeout(() => {
                setReRender(true)
            }, 200);
        });
    };

    const handleDocumentLoad = function (e: DocumentLoadEvent) {
        if (!firstLoad) {
            setTotalPages(e.doc.numPages);
            setStop(true)
            setPdfLoaded(true)
            setFirstLoad(true)
        } else if (activePdf == 'FIRST') {
            setTotalPages(e.doc.numPages);
            // setTotalPages2(e.doc.numPages);

        }
        else {
            setTotalPages2(e.doc.numPages);
            // setTotalPages(e.doc.numPages);
            setPdfLoaded(true)
        }
        setFirstSaveLoad(true)
        if (calculatedZoom == 0) {
            setZoomScale(e, setCalculatedZoom, setReRenderViewer)
        }
    };
    const handleDocumentLoad2 = function (e: DocumentLoadEvent) {
        if (!secondLoad) {
            setTotalPages2(e.doc.numPages);
            setSecondLoad(true)

        }
        else if (activePdf == 'SECOND') {

            setTotalPages(e.doc.numPages);
            // setTotalPages2(e.doc.numPages);

        }
        else {
            setTotalPages2(e.doc.numPages);
        }
        setSecondSaveLoad(true)
        if (calculatedZoom2 == 0) {
            setZoomScale(e, setCalculatedZoom2, setReRenderViewer2)
        }

    };

    const [isExitGrid, setIsExitGrid] = useState(false)
    //////////////////////////////////// grid idle timeout start///////////////////////////////////
    useEffect(() => {
        if (window.location.pathname !== EDIT_WORK + job_id && user?.is_ideal_timeout) {
            let alive = false
            let minute = 1;
            let gridExited = false;
            let timeout = setInterval(() => {
                if (alive) {
                    alive = false
                    minute = 1
                }
                else {
                    minute = minute + 1
                    if (minute > user_idle_time_out && !isExitGrid && !gridExited) {
                        let task_type = ""
                        if (window.location.pathname == DATA_ENTRY) {
                            task_type = "1"
                        }
                        else if (window.location.pathname == QA_REVIEW) {
                            task_type = "2"
                        }
                        exitGrid(jobData?.job, task_type, true)((response) => {
                            if (response?.status && response?.status_code == 200) {
                                setIsExitGrid(true)
                                gridExited = true;
                                setErrorAlert({
                                    status: true,
                                    message: "Idle Timeout",
                                    type: 'Idle',
                                })
                            }
                        })
                    }
                }
            }, 60000);
            const resetTimer = () => {
                if (!alive) {
                    alive = true
                }
            };
            window.addEventListener('mousemove', resetTimer);
            window.addEventListener('keydown', resetTimer);
            window.addEventListener('click', resetTimer);
            window.addEventListener('scroll', resetTimer);

            return () => {
                clearInterval(timeout);
                window.removeEventListener('mousemove', resetTimer);
                window.removeEventListener('keydown', resetTimer);
                window.removeEventListener('click', resetTimer);
                window.removeEventListener('scroll', resetTimer);
            };
        }
    }, [pdfLoaded])
    //////////////////////////////////// grid idle timeout end ///////////////////////////////////

    useEffect(() => {

        if (jobData?.grid_data?.status == "partial_save") {
            setTimeout(() => {
                const strsavedData = localStorage.getItem('grid_data')
                const savedData = strsavedData ? JSON.parse(strsavedData) : null
                if (savedData !== null && activePdf == 'FIRST' && firstSaveLoad && savedData?.claims?.length > 0 || savedData !== null && activePdf == 'SECOND' && secondSaveLoad && savedData?.claims?.length > 0) {
                    if (savedData?.claims[savedData?.claims.length - 1]?.page_to > 1) {
                        jumpToPage(savedData?.claims[savedData?.claims.length - 1]?.page_to - 1)
                    }
                }
                else if (activePdf == 'FIRST' && firstSaveLoad && jobData?.grid_data?.claims?.length > 0 || activePdf == 'SECOND' && secondSaveLoad && jobData?.grid_data?.claims?.length > 0) {
                    if (jobData?.grid_data?.claims[jobData?.grid_data?.claims.length - 1]?.page_to > 1) {
                        jumpToPage(jobData?.grid_data?.claims[jobData?.grid_data?.claims.length - 1]?.page_to - 1)
                    }
                }

            }, 200);

        }
    }, [activePdf, firstSaveLoad, secondSaveLoad])

    const handleNavigateDashBoard = () => {
        changeAllTitleFunction('Dashboard', DASHBOARD)
    }
    useEffect(() => {
        setPlugins([defaultLayoutPluginInstance, pageNavigationPluginInstance, scrollModePluginInstance, rotatePluginInstance, thumbnailPluginInstance])

    }, [activePdf])
    useEffect(() => {
        const error_strsavedData = localStorage.getItem('error_grid_data')
        const error_savedData = error_strsavedData ? JSON.parse(error_strsavedData) : null
        const error_strstateData = localStorage.getItem('error_grid_state_data')
        const error_stateData = error_strstateData ? JSON.parse(error_strstateData) : null
        if (jobData && jobData != null && jobErrorAlert && !(window.location.pathname === EDIT_WORK + job_id)) {

            // Adding Error Job
            let message = 'ERROR WHILE SAVING PREVIOUS JOB'
            if (localStorage.getItem("SAVE_EXIT") || error_savedData?.status == "partial_save") {
                message = 'ERROR WHILE SAVING THIS JOB'
            }
            setErrorAlert({
                status: true,
                message: message,
                is_errorJob: true,
                type: "danger",
            });
            // Swal.fire({
            //     icon: 'error',
            //     confirmButtonColor: '#6ba2b6',
            //     confirmButtonText: 'RELOAD DATA',
            //     showCancelButton: false,
            //     allowOutsideClick: false,
            //     backdrop: true,
            //     title: 'ERROR WHILE SAVING PREVIOUS JOB',
            //     customClass: {
            //         title: 'swal-titleText fs-5'
            //     }
            // }).then((result) => {
            //     if (result?.isConfirmed) {
            //         localStorage.setItem("is_job_error", true);
            //         window.location.reload()


            //     }
            //     else {

            //     }
            // })
        }

        // INITIALIZE DBK FIELDS PERMISSIONS END
    }, [jobErrorAlert, jobData, job_id]);
    // Add an event listener to the document
    return (

        <React.Fragment>

            {show === true && notification.title && notification.job == jobData?.file_info?.job_unique_id && window.location.pathname == DATA_ENTRY &&
                <SweetAlert
                    warning
                    confirmBtnText="Ok!"
                    confirmBtnCssClass="custom-confirm-button-class"
                    title={notification.title}
                    onConfirm={() => { handleNavigateDashBoard() }}
                >
                </SweetAlert>

            }
            {show === true && notification?.title && notification?.job == jobData?.file_info?.job_unique_id && window.location.pathname == QA_REVIEW &&
                <SweetAlert
                    warning
                    confirmBtnText="Ok!"
                    confirmBtnCssClass="custom-confirm-button-class"
                    title={notification.title}
                    onConfirm={() => { handleNavigateDashBoard() }}
                >

                </SweetAlert>
            }

            {commentAlert && window.location.pathname == DATA_ENTRY &&
                <SweetAlert
                    warning
                    confirmBtnText="Ok!"
                    confirmBtnCssClass="custom-confirm-button-class"
                    onConfirm={() => setCommentAlert(false)}
                >
                    {jobData?.is_large_batch_job &&
                        <React.Fragment>
                            <span className='fw-bold '>
                                Large Batch File
                            </span>
                            <br />
                        </React.Fragment>

                    }

                    {jobData?.file_info?.comment &&
                        <React.Fragment>
                            <div className='' style={{ width: '100%' }}>

                                {jobData?.file_info?.comment ?

                                    <span className='pt-5 text-uppercase '>
                                        <div className='d-flex flex-column '>

                                            {jobData?.file_info?.comment.split(',\n\r').map((line, index) => (
                                                <React.Fragment>
                                                    <div>
                                                        {line}
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </span>
                                    :
                                    <span className='pt-5 text-uppercase'>

                                        {jobData?.file_info?.comment}
                                    </span>
                                }
                            </div>

                        </React.Fragment>
                    }
                </SweetAlert>
            }

            {commentAlert && window.location.pathname == QA_REVIEW &&
                <SweetAlert
                    warning
                    confirmBtnText="Ok!"
                    confirmBtnCssClass="custom-confirm-button-class"
                    onConfirm={() => setCommentAlert(false)}
                >

                    <div className='d-flex flex-column'>

                        {jobData?.is_large_batch_job &&
                            <React.Fragment>

                                <span className='fw-bold'>
                                    Large Batch
                                </span>

                            </React.Fragment>
                        }

                        {jobData?.file_info?.comment &&
                            <React.Fragment>
                                <div className='' style={{ width: '100%' }}>

                                    {jobData?.file_info?.comment ?

                                        <span className='pt-5 text-uppercase '>
                                            <div className='d-flex flex-column '>

                                                {jobData?.file_info?.comment.split(',\n\r').map((line, index) => (
                                                    <React.Fragment>
                                                        <div>
                                                            {line}
                                                        </div>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </span>
                                        :
                                        <span className='pt-5 text-uppercase'>

                                            {jobData?.file_info?.comment}
                                        </span>
                                    }
                                </div>

                            </React.Fragment>
                        }
                    </div>

                </SweetAlert>
            }

            {errorAlert?.status &&
                <SweetAlert
                    danger
                    confirmBtnText={errorAlert?.is_errorJob ? "RELOAD DATA" : "Ok!"}
                    confirmBtnCssClass="custom-confirm-button-class"
                    onConfirm={() => handlePermissionJob()}
                >
                    <span className='fw-bolder'>{errorAlert?.message}</span>
                </SweetAlert>
            }

            {error837?.status &&
                <SweetAlert
                    danger
                    confirmBtnText="Ok!"
                    confirmBtnCssClass="custom-confirm-button-class"
                    onConfirm={() => setError837({
                        status: false,
                        message: "",
                        type: '',
                    })}
                >
                    <span className='fw-bolder'>{error837?.message}</span>
                </SweetAlert>
            }

            {/* header */}

            <Header job={jobData?.job} />

            {
                toast &&
                <React.Fragment>
                    <ToastContainer containerPosition="position-fixed" position="top-end" className="p-3">
                        <Toast bg={toast.type} onClose={() => setToast(!toast.status)} show={toast.status} delay={2000} autohide>

                            <Toast.Body><span className='text-white fw-bold'>{toast.message}</span></Toast.Body>
                        </Toast>
                    </ToastContainer>
                </React.Fragment>
            }

            {
                noJob ? (
                    <React.Fragment>
                        <div className='d-flex flex-column justify-content-center align-items-center height-100'>
                            <span className='text-primary badge badge-light-primary fs-1'>
                                NO PENDING JOBS
                            </span>
                            <button disabled={formapiLoaded} onClick={() => {
                                // setFirstLoad(false)
                                // setSecondLoad(false)
                                // setActivePdf('FIRST')
                                window.location.reload()
                            }} className='btn btn-light btn-lg rounded-1 mt-3 btn-active-primary '>
                                Refresh
                            </button>
                        </div>
                    </React.Fragment>
                ) : (

                    <div>
                        <Grid
                            jobErrorAlert={jobErrorAlert}
                            setjobErrorAlert={setjobErrorAlert}
                            setformApiLoaded={setformApiLoaded}
                            pdfLoaded={pdfLoaded}
                            jumpToPage={jumpToPage}
                            initialPage={initialPage}
                            setInitialPage={setInitialPage}
                            job_id={job_id}
                            log_id={log_id}
                            fileUrl={fileUrl}
                            jobData={jobData}
                            setNoJOb={setNoJOb}
                            setJobData={setJobData}
                            jobUserData={jobUserData}
                            setFileUrl={setFileUrl}
                            toast={toast}
                            setToast={setToast}
                            currentPage={currentPage}
                            totalPages={totalPages}
                            rotatePosition={rotatePosition}
                            getGridData={getGridData}
                            setLoadData={setLoadData}
                            setErrorAlert={setErrorAlert}
                            error837={error837}
                            setError837={setError837}
                            errorAlert={errorAlert}
                            isSubJob={isSubJob}
                            setIsSubJob={setIsSubJob}
                            isFirstJob={isFirstJob}
                            setIsFirstJob={setIsFirstJob}
                            jobUserData={jobUserData}
                            subJobOptions={subJobOptions}
                            jumpRef={jumpRef}
                            activePdf={activePdf}
                            firstSaveLoad={firstSaveLoad}
                            secondSaveLoad={secondSaveLoad}
                            setFirstSaveLoad={setFirstSaveLoad}
                            setSecondSaveLoad={setSecondSaveLoad}
                            setSaveSuccess={setSaveSuccess}
                            currentJobVerified={currentJobVerified}
                            loadData={loadData}
                            firstLoad={firstLoad}
                        />


                        {/* pdf viewer starts here */}
                        {/* <Worker workerUrl="/assets/js/pdf.worker.min.js">
                            <div
                                id="magnifier-box"

                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.3)',
                                    height: '990px',
                                    maxHeight: '990px',
                                }}>
                                <div

                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        position: 'absolute',
                                        left: '20rem',
                                        zIndex: '99'
                                    }}
                                >
                                    <div
                                        className='thumbnail-container'

                                        style={{ padding: '0px 2px', marginLeft: 'auto' }}>

                                        <RotatePage>
                                            {(props) => (
                                                <MinimalButton onClick={() => handlePageRotate(props, 'left', currentPage)}>
                                                    <svg
                                                        width="15px"
                                                        height="15px"
                                                        viewBox="0 0 24 24"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        stroke="#ffffff"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-rotate-ccw"
                                                    >
                                                        <polyline points="1 4 1 10 7 10"></polyline>
                                                        <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
                                                    </svg>
                                                </MinimalButton>
                                            )}
                                        </RotatePage>
                                    </div>
                                    <div style={{ padding: '0px 2px', }}>
                                        <RotatePage>
                                            {(props) => (
                                                <MinimalButton onClick={() => handlePageRotate(props, 'right', currentPage)}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="15"
                                                        height="15"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#ffffff"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <path d="M21 2v6h-6" />
                                                        <path d="M21 13a9 9 0 11-3-7.7L21 8" />
                                                    </svg>

                                                </MinimalButton>
                                            )}
                                        </RotatePage>
                                    </div>
                                </div>

                                {fileUrl &&
                                    <div className='first-pdf active-pdf' style={{ height: activePdf == 'FIRST' ? '100%' : '0%' }}>
                                        <Viewer
                                            plugins={plugins}
                                            fileUrl={fileUrl}

                                            onDocumentLoad={handleDocumentLoad}
                                            onPageChange={handlePageChange}

                                        />
                                    </div>
                                }
                                {fileUrl2 &&
                                    <div className='second-pdf' style={{ height: activePdf == 'SECOND' ? '100%' : '0%' }}>
                                        <Viewer
                                            plugins={plugins}
                                            fileUrl={fileUrl2}

                                            onDocumentLoad={handleDocumentLoad2}
                                            onPageChange={handlePageChange}

                                        />
                                    </div>

                                }

                            </div>

                        </Worker> */}

                        <Worker workerUrl="/assets/js/pdf.worker.min.js">
                            <div
                                id="magnifier-box"

                                style={{
                                    border: '1px solid rgba(0, 0, 0, 0.3)',
                                    // height: '990px',
                                    // maxHeight: '990px',
                                    height: '96.6vh',
                                    maxHeight: '96.6vh',
                                }}>
                                {fileUrl1 &&
                                    <div className='first-pdf active-pdf' style={{ height: activePdf == 'FIRST' ? '100%' : '0%', visibility: activePdf == 'FIRST' ? 'visible' : 'hidden' }}>
                                        <CustomeFirstViewer
                                            calculatedZoom={calculatedZoom}
                                            reRenderViewer={reRenderViewer}

                                            plugins={plugins}
                                            fileUrl={fileUrl1}
                                            activePdf={activePdf}

                                            handleDocumentLoad={handleDocumentLoad}
                                            handlePageChange={handlePageChange1}
                                            setUpdateJumb={setUpdateJumb}
                                            updateJumb={updateJumb}
                                            jumbNo={jumbNo}
                                            currentPage={currentPage}

                                        />
                                    </div>
                                }
                                {fileUrl2 &&
                                    <div className='second-pdf' style={{ height: activePdf == 'SECOND' ? '100%' : '0%', visibility: activePdf == 'SECOND' ? 'visible' : 'hidden' }}>
                                        <CustomeSecondViewer
                                            calculatedZoom={calculatedZoom2}
                                            reRenderViewer={reRenderViewer2}
                                            plugins={plugins}
                                            fileUrl={fileUrl2}
                                            activePdf={activePdf}
                                            handleDocumentLoad={handleDocumentLoad2}
                                            handlePageChange={handlePageChange2}
                                            setUpdateJumb={setUpdateJumb}
                                            updateJumb={updateJumb}
                                            jumbNo={jumbNo}
                                            currentPage={currentPage}

                                        />
                                    </div>

                                }
                            </div>

                        </Worker>
                    </div>
                )
            }


        </React.Fragment>
    );

}
export default PdfViewer;
