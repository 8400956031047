import moment from "moment";

export const handleWheel = (e) => {
    e.target.blur();
}

const handleKeyAmt = (e) => {
    if (e.keyCode == 38 || e.keyCode == 40) {
        e.preventDefault()
    }
}

export const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.keyCode == 38 || event.keyCode == 40) {

        event.preventDefault();
    }
};
export const handlePreventEKeyPress = (event) => {

    if (event.keyCode == 69) {
        event.preventDefault();

    }

};


export const removeKeysFromForEobForQDXPatientPay = (claim) => {
    const removeKeyArr = ['claim_copay', 'claim_coins', 'claim_allowed', 'claim_cob', 'claim_deduct', 'claim_patres', 'claim_discount', 'sl_discount', 'sl_allowed', 'sl_deduct', 'sl_coins', 'sl_copay', 'sl_cob', 'sl_patres', 'sl_balance', 'sl_total', 'sl_amount', 'sl_billed', 'sl_paid']
    for (const key of removeKeyArr) {
        delete claim[key];
    }

};

export const preventEFromNumber = (e) => {
    var invalidChars = [
        "-",
        "+",
        "e",
    ];
    if (invalidChars.includes(e.key)) {
        e.preventDefault();
    }
}

export const minValidDate = moment("01/01/1900", "MM/DD/YYYY");

export function preventWhiteSpaceValidate(e) {
    const { value } = e.target; // Get the current value
    if (value.startsWith(' ')) {
        e.target.value = value;
        value = value.trimStart();
    }
    return e
}
