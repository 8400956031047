import React from 'react'
import Master from '../../../../Layout/admin/Master'
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { Toast, ToastContainer } from 'react-bootstrap';
import { editGroup, getRoles, getRolesDropdown, getSingleGroupDetails } from '../../../../Redux/user/action';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import { GROUPS } from '../../../../routeNames';


function EditGroup() {
    const navigate = useNavigate()
    let { group_id } = useParams();
    const [state, setState] = useState({
        error: false,
        error_message: ""
    })
    const [data, setData] = useState([]);
    const [toaster, setToaster] = useState(false)
    const [roles, setRoles] = useState({})
    const [options, setoOptions] = useState([])

    useEffect(() => {

        getRolesDropdown()((response) => {
            if (response?.status && response?.data) {
                const data = [];
                response?.data.forEach((item) => {
                    data.push({ value: item.id, label: item.name })
                })
                setoOptions(data)
            }
        });

        getSingleGroupDetails(group_id)((response) => {
            if (response?.status && response?.data?.group) {
                setData(response?.data?.group);
                setRoles(response?.data?.group?.roles.map((val) => val.value));
                // setRoles(response?.data?.group?.roles?.map((val) => val.value))


                // const role_ids = response?.data?.group?.roles.map((val) => val.value);

                // console.log(role_ids)
                formik.setValues({
                    ...formik.values,
                    groupName: response?.data?.group?.name,
                    roles: response?.data?.group?.roles,
                })
            }
        });

    }, []);


    const __showDataEntry = (e) => {
        e.preventDefault();
        const RedirectId = getRedirectTabId()
        editGroup(data.id, formik.values.groupName, roles)((response) => {
            if (response?.status) {

                setToaster('success')
                const timer = setTimeout(() => {
                    setToaster(false)
                    changeAllTitleFunction("Groups", GROUPS, RedirectId)
                }, 2000);
                return () => clearTimeout(timer);
            }
            else {
                formik.setErrors({

                    groupName: response?.response?.data?.errors?.name,
                    roles: response?.data?.errors?.roles,


                })

            }
        });
    }

    // const SignupSchema = Yup.object().shape({
    //     groupName: Yup.string()
    //         .required('Required'),
    //     roles: Yup.array().max(1, 'Required')
    //         .required('Required'),
    // });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            groupName: "", roles: []
        },
        validationSchema: Yup.object().shape({
            groupName: Yup.string()
                .required('Required'),
            roles: Yup.array().min(1, 'At least one value is required')
                .of(
                    Yup.object().shape({
                        value: Yup.number().required(),
                        label: Yup.string().required()
                    })
                ),
        }),

        onSubmit: (values) => {
            __handleEditUser(values)
        },
    });
    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {roles?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}

            </components.DropdownIndicator>
        );
    };
    const handleOnSelectChange = ({ name, value }) => {

        formik.setValues({ ...formik.values, roles: value })
        setRoles(value.map((val) => val.value))
    }


    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    const [isFocusedClient, setIsFocusedClient] = useState(false);

    return (
        <React.Fragment>
            <Master />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Failed</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header">
                    <h1>Edit Group</h1>

                </div>

                <section className="section mt-5">
                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-body py-3 ">
                                    <Form
                                        onSubmit={(e) => __showDataEntry(e)}
                                    >
                                        <div className="mb-3 row">
                                            <div className='col-lg-6'>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className='fw-bold'>Group Name</Form.Label>
                                                    {/* <input
                                                type="text"
                                                name='groupName'
                                                value={formik.values.groupName}
                                                onChange={formik.handleChange}
                                                className="form-control form-control-solid"
                                                placeholder="Enter Group Name"
                                            /> */}


                                                    <Form.Control
                                                        type="text"
                                                        name='groupName'
                                                        value={formik.values.groupName}
                                                        onChange={formik.handleChange}
                                                        className="form-control form-control-solid b-r-10"
                                                        placeholder="Enter Group Name"
                                                        isValid={formik.touched?.groupName && !!formik.errors?.groupName}
                                                        isInvalid={!!formik.errors?.groupName}
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.groupName}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-6'>
                                                <Form.Group className="mb-3">
                                                    <Form.Label className='fw-bold'>Roles</Form.Label>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isMulti
                                                        value={formik.values.roles}
                                                        name="roles"
                                                        options={options}
                                                        onFocus={() => setIsFocusedClient(true)}
                                                        onBlur={() => setIsFocusedClient(false)}
                                                        components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="roles" /> }}
                                                        onChange={(e) => { handleOnSelectChange({ name: 'roles', value: e }) }}
                                                        placeholder="Select Roles"
                                                        styles={{
                                                            container: (styles, state) => ({
                                                                ...styles,
                                                                borderRadius: "9px",
                                                            }),
                                                            control: (styles, state) => ({
                                                                ...styles,
                                                                backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                borderStyle: "none", boxShadow: "none",
                                                                borderRadius: "10px",
                                                                maxHeight: state.isFocused ? "auto" : "30px",
                                                            }),
                                                            indicatorSeparator: styles => ({
                                                                ...styles, width: "0px", minHeight: "20px"
                                                            }),

                                                            valueContainer: (styles, state) => ({
                                                                ...styles,
                                                                maxHeight: isFocusedClient ? "auto" : "30px",
                                                            }),
                                                        }}
                                                    />
                                                </Form.Group>
                                                {formik.errors?.roles &&

                                                    <span className='text-danger'>{formik.errors.roles}</span>
                                                }
                                            </div>
                                        </div>
                                        {state.error &&
                                            <div className='text-center mt-3'>

                                                <span className='text-danger fw-bold '>{state.error_message}</span>
                                            </div>
                                        }
                                        {checkUserPermission('edit_group') &&
                                            <div className='row mt-2'>
                                                <div className='col-lg-12 text-end'>
                                                    <button
                                                        onClick={() => { changeAllTitleFunction("Groups", GROUPS) }}
                                                        type='button'
                                                        className='btn me-1 btn-secondary new-window'
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        disabled={!formik.isValid}
                                                        type='submit'
                                                        className='btn btn-primary btnHover new-window'
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default EditGroup