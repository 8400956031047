import axiosInstance from "../../axiosInstance";
import { GET_NEW_CLAIMS_FILING_URL, POST_NEW_CREATE_UPDATE_CLAIMS_FILING_URL, GET_NEW_SINGLE_CLAIMS_FILING_URL, GET_NEW_CLAIMS_FILING_DROPDOWN, DELETE_NEW_CLAIMS_FILING } from "../../api";


export const getClaimsFiling = (page, limit, search) => onResponse => {
    try {

        let data = search ? search : "";
        axiosInstance.get(GET_NEW_CLAIMS_FILING_URL + "?page=" + page + "&limit=" + limit + "&search=" + data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const createClaimsFiling = (props, removed_ids) => onResponse => {
    const claim_id = props.claim_id ? props.claim_id : ""
    const client = props.client ? props.client : ""
    const group = props.group ? props.group : ""
    const code_definition = props.data ? props.data : []
    try {

        axiosInstance.post(POST_NEW_CREATE_UPDATE_CLAIMS_FILING_URL, { claim_id, client, group, code_definition, removed_ids })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }
};

export const getSingleClaimsFiling = (claimsFiling) => onResponse => {
    try {
        axiosInstance.get(GET_NEW_SINGLE_CLAIMS_FILING_URL + '?claim_id=' + claimsFiling)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getAllGroupDropdown = () => onResponse => {
    try {
        axiosInstance.get(GET_NEW_CLAIMS_FILING_DROPDOWN)
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }
}


export const getDeleteClaimsFiling = (id) => onResponse => {
    try {
        axiosInstance.put(DELETE_NEW_CLAIMS_FILING, {
            id: id

        })
            .then((response) => {
                onResponse(response.data)
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {

    }
} 