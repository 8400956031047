import React, { useMemo } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import AdminMaster from '../../../Layout/admin/Master';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Toast, ToastContainer, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import axiosInstance from './../../../axiosInstance';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BiFile, BiSearch, BiReset, BiPencil } from 'react-icons/bi';
import Modal from 'react-bootstrap/Modal';
import DateTimePicker from 'react-datetime-picker';
import Form from 'react-bootstrap/Form';
import { getChangeJobTat, getUserlist, getUserManualAssign } from '../../../Redux/qms/action';
import { GET_QUEUE_LISTING_URL } from '../../../api';
import moment from 'moment/moment';
import { filterQueueDetails } from './../../../Redux/qms/action';
import { EDIT_WORK } from '../../../routeNames';
import { IoIosSwitch } from 'react-icons/io';
import EditCorrespondenceCategory from './../CorrespondenceCategory/Edit/index';



const TextField = styled.input`
    height: 32px;F
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
var input = {
    client: '',
    file: ''
};

const QaCompleted = () => {

    //////////////////////////////////////////////////////////////////
    const [userlist, setUserlist] = useState();
    // useEffect(() => {
    //     getUserlist()((response) => {
    //         setUserlist(response?.data?.results)
    //     });
    // }, []);

    const assignUser = () => {
        let form = new FormData();
        form.append('staff',)
        getUserManualAssign()((response) => {
            setUserassign(response?.data)
            formik.setValues({ ...formik.values, data: response?.data })
            setShowModal(false);
        });
    }
    //////////////////////////////////////////////////////////////////
    const [state, setState] = useState({
        client: true,
        lockbox: true,
        filename: true,
        internal_tat: true,
        external_tat: true,
        amount: true,
        check_number: true,
        payer: true,
        transaction_type: true,
        facility: true,
        reason_rejection: true,
        lockbox_payee_name: true,
        batch_id: true,
        no_of_autofilled_fields: true,
        no_of_claims: true,
        no_of_corrected_fields: true,
        no_of_fields: true,
        no_of_key_fields: true,
        no_of_serviceLines: true,
        entry_start_time: true,
        entry_end_time: true,
        file_received_on: true,
        status: true,

    });

    const [settings, setSettings] = useState();
    const [toaster, setToaster] = useState(null);

    /////////////////////////////////////////////////////////////////


    const changeTat = () => {
        getChangeJobTat(moment(value).format('YYYY-MM-DD[T]HH:mm'), settings?.id)((response) => {
            setShow(false);
            if (response && response.status) {
                fetchQueueListing(1)
                setToaster('update')
                // const timer = setTimeout(() => {
                //     setToaster(false)
                // }, 2000);
                // return () => clearTimeout(timer);
            }
            else {
                setToaster('error')
                // const timer = setTimeout(() => {
                //     setToaster(false)
                // }, 2000);
                return () => clearTimeout(timer);
            }
        });
    }


    // const validationSchema = Yup.object().shape({
    //     data: Yup.array()
    //         .required('Required'),
    // });
    const formik = useFormik({
        initialValues: {
            assignedqueue: "",
            staff: "",
        },
        // validationSchema,
        onSubmit: (values) => {
            __formSubmit(values)
        },
    });
    const __formSubmit = (values) => {
        // let formData = new FormData();    //formdata object
        // formData.append("staff", values.staff);
        // formData.append("assignedqueue", values.assignedqueue);
        getUserManualAssign(values?.staff, values?.assignedqueue)((response) => {
            setShowModal(false);
            if (response && response.status) {
                setToaster('assign')
                // const timer = setTimeout(() => {
                //     setToaster(false)
                // }, 2000);
                // return () => clearTimeout(timer);
            }
            else {
                setToaster('error')
                // const timer = setTimeout(() => {
                //     setToaster(false)
                // }, 2000);
                // return () => clearTimeout(timer);
            }

        });
    };


    /////////////////////////////////////////////////////////////

    const [value, onChange] = useState(new Date());

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);

    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);

    const handleShowModal = (id) => {

        formik.setValues({
            ...formik.values,
            assignedqueue: id,
        })
        setShowModal(true)
    };

    const searchTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Search
        </Tooltip>
    );
    const resetTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Reset
        </Tooltip>
    );
    const HeadingsTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Table Headings
        </Tooltip>
    );
    const assignTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Assign
        </Tooltip>
    );
    const tatTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Tat
        </Tooltip>
    );

    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(10);
    const [limit, setLimit] = useState(10);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [data, setData] = useState({})

    const [filterText, setFilterText] = React.useState('');
    const [filterClient, setFilterClient] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const columns = useMemo(
        () => [

            {
                id: 'client',
                name: 'Client',
                selector: row => row?.job?.eob_sftp_id?.client,
                sortable: true,
                wrap: true,
                grow: 0.1,
                reorder: true,
                omit: !state.client,

            },

            {
                id: 'lockbox',
                name: 'Lockbox',
                selector: row => row?.job?.eob_sftp_id?.lockbox_details?.lockbox_no,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                grow: 0.1,
                omit: !state.lockbox,
            },

            {
                id: 'filename',
                name: 'Filename',
                selector: row => row?.job?.eob_sftp_id?.file_name,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                grow: 0.2,
                omit: !state.filename,
            },

            {
                id: 'internal_tat',
                name: 'Internal Tat',
                selector: row => row.internal_tat ? moment(row.internal_tat).format('M/D/Y, h:mm a') : "---",
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.internal_tat,
            },
            {
                id: 'external_tat',
                name: 'External Tat',
                selector: row => row.external_tat ? moment(row.external_tat).format('M/D/Y, h:mm a') : "---",
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.external_tat,
            },
            {
                id: 'amount',
                name: 'Amount',
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap'>
                    {row?.job?.eob_sftp_id?.payment_method?.map((payment, index) => (

                        <span span className='badge badge-light-primary' >{payment?.check_amount}</span >
                    ))}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.amount,
            },
            {
                id: 'check_number',
                name: 'Check Number',
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap'>
                    {row?.job?.eob_sftp_id?.payment_method?.map((payment, index) => (

                        <span span className='badge badge-light-primary' >{payment?.check_number}</span >
                    ))}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.check_number,
            },
            {
                id: 'payer',
                name: 'Payer',
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap'>
                    {row?.job?.eob_sftp_id?.payment_method?.map((payment, index) => (

                        <React.Fragment>
                            {payment && payment?.payer && payment?.payer?.map((payer, index) => (
                                <React.Fragment>
                                    <span span className='badge badge-light-primary' >{payer?.payer?.full_name}</span >

                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    ))}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.payer,
            },
            {
                id: 'transaction_type',
                name: 'Transaction Type',
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap'>
                    {row?.job?.eob_sftp_id?.payment_method?.map((payment, index) => (

                        <span span className='badge badge-light-primary' >{payment?.transaction_type}</span >
                    ))}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.transaction_type,
            },
            {
                id: 'facility',
                name: 'Facility',
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap'>
                    {row?.job?.eob_sftp_id?.facility?.map((facilityy, index) => (

                        <span span className='badge badge-light-primary' >{facilityy?.name}</span >
                    ))}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.facility,
            },
            {
                id: 'reason_rejection',
                name: 'Reason Rejection',
                cell: (row) => <div className='d-flex flex-row justify-content-center flex-wrap'>
                    {row?.job?.eob_sftp_id?.payment_method?.map((payment, index) => (

                        <span span className='badge badge-light-primary' >{payment?.reason_rejection}</span >
                    ))}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.reason_rejection,
            },
            {
                id: 'lockbox_payee_name',
                name: 'Lockbox Payee Name',
                selector: row => row?.job?.lockbox_payee_name,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.lockbox_payee_name,
            },
            {
                id: 'batch_id',
                name: 'Batch ID',
                selector: row => row?.job?.eob_sftp_id?.batch_id,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.batch_id,
            },
            {
                id: 'no_of_autofilled_fields',
                name: 'No of Auto filled Fields',
                selector: row => row?.no_of_autofilled_fields,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_autofilled_fields,
            },

            {
                id: 'no_of_claims',
                name: 'No of Claims',
                selector: row => row?.job?.eob_sftp_id?.no_of_claims,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_claims,
            },
            {
                id: 'no_of_corrected_fields',
                name: 'No of Corrected Fields',
                selector: row => row?.job?.eob_sftp_id?.no_of_corrected_fields,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_corrected_fields,
            },
            {
                id: 'no_of_fields',
                name: 'No of Fields',
                selector: row => row?.job?.eob_sftp_id?.no_of_fields,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_fields,
            },
            {
                id: 'no_of_key_fields',
                name: 'No of Key Fields',
                selector: row => row?.job?.eob_sftp_id?.no_of_key_fields,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_key_fields,
            },
            {
                id: 'no_of_serviceLines',
                name: 'No of Service Lines',
                selector: row => row?.job?.eob_sftp_id?.no_of_serviceLines,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.no_of_serviceLines,
            },

            {
                id: 'entry_end_time',
                name: 'Entry End Time',
                selector: row => row?.entry_end_time,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.entry_end_time,
            },
            {
                id: 'entry_start_time',
                name: 'Entry Start Time',
                selector: row => row?.entry_start_time,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 0.1,
                omit: !state.entry_start_time,
            },

            {
                id: 'status',
                name: 'Assignee/Status',
                grow: 1,
                omit: !state.status,
                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0 ">
                        <React.Fragment>
                            <div className="qms-btn me-1" >
                                <Link className='btn btn-outline-light' to={EDIT_WORK + row.id}>
                                    <BiPencil />
                                </Link>

                            </div>

                        </React.Fragment>
                    </div>,
                ignoreRowClick: true,
                allowOverflow: false,
                button: true,
            },

        ],
        [state],
    );

    const fetchQueueListing = async (page) => {
        setLoading(true);

        setLimit(limit);

        const response = await axiosInstance.get(GET_QUEUE_LISTING_URL + "?page=" + page + "&limit=" + limit + "&status=datacaptured");
        setData(response?.data);
        setLimit(limit);
        setTotalRows(response?.data?.data?.count);
        setLoading(false);
    };
    const handlePageChange = page => {

        fetchQueueListing(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        const response = await axiosInstance.get(GET_QUEUE_LISTING_URL + "?page=" + page + "&limit=" + newPerPage);
        setData(response?.data);
        setLimit(newPerPage);
        setTotalRows(response?.data?.data?.count);
        setLoading(false);

        setLoading(false);
    };
    useEffect(() => {
        // getAllPayees()((response) => {
        //     setData(response);
        // });
        fetchQueueListing(1, limit);
    }, []);


    const searchGroups = async (e) => {
        setFilterText(e.target.value)
        setFilterClient(e.target.value)
        let data = e.target.value ? e.target.value : '';
        const response = await axiosInstance.get(GET_QUEUE_LISTING_URL + "?limit=" + data?.data?.count + "&search=" + data);
        setData(response?.data);
    }
    // const filteredItems = data?.data?.results?.filter(
    //     item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
    // );
    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, filterClient, resetPaginationToggle]);
    const handleClear = () => {
        if (filterText, filterClient) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            setFilterClient('');
        }
    };


    const queueformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            filename: "",
            client: "",
            status: "Data Capture Completed",

        },
        // validationSchema,
        onSubmit: (values) => {
            // let formData = new FormData();    //formdata object
            // if (client_data?.id)
            //     formData.append('client', client_data?.id);
            // formData.append('client_id', values.client_id);
            // formData.append('name', values.name);
            // formData.append('address', values.address);
            // formData.append('city', values.city);

            filterQueueDetails(values.filename, values.client, values.status)((response) => {

                if (!response?.data?.status && response?.status) {
                    // setState({ clientValidated: true })F
                    // alert('success')

                    setData(response);

                } else {
                    // alert('error')

                    // const errors = response?.response?.data?.errors;
                    queueformik.setErrors({
                        ...queueformik.values,
                        filename: response?.response?.data?.errors?.username,
                        client: response?.response?.data?.errors?.client_id,
                        // status: response?.response?.data?.errors?.name,

                    })
                }
            });
        },
    });
    const handleRowSelected = React.useCallback(state => {
        var ids = [];
        state.selectedRows.map((row, index) => {
            ids.push(row.id);
        })
        setSelectedRows(ids);
    }, []);
    return (
        <React.Fragment>
            <AdminMaster />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster ? true : false} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>{toaster == "assign" ? 'TAT Assign' : 'TAT Update'}</span></Toast.Body>
                    </Toast>
                    {/* <Toast bg="success" show={toaster && toaster == 'successful' ? true : false} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>User Assigned </span></Toast.Body>
                    </Toast> */}
                    {/* <Toast bg="danger" show={toaster && toaster == 'error' ? true : false} animation={true} delay={2000} autohide={200}>
                        <Toast.Body ><span className='text-white'>Something went wrong</span></Toast.Body>
                    </Toast> */}
                </ToastContainer>



                <section className="section mt-5">
                    <div className="row align-items-top py-5 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <Form onSubmit={queueformik.handleSubmit} onReset={() => { queueformik.handleReset, fetchQueueListing(1) }}>
                                    <div className=" ">
                                        <div className="row  py-3">
                                            <div className='col-lg-2'>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid ms-3 b-r-10"
                                                    name='filename'
                                                    type="search"
                                                    style={{ width: "100%" }}
                                                    placeholder="Filename"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.filename}
                                                    // value={input.file}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>
                                            <div className='col-lg-2'>

                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid w-250px ms-3 b-r-10"
                                                    type="search"
                                                    name='client'
                                                    style={{ width: "100%" }}
                                                    placeholder="Client"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.client}
                                                    // value={input.client}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>


                                            <div className='col-lg-3 d-flex justify-content-start gap-2'>
                                                {/* <div className="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1"> */}


                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 50, hide: 100 }}
                                                    overlay={searchTooltip}
                                                >
                                                    <Button className="btn btn-light btn-active-primary  " type="submit">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiSearch />
                                                        </span>

                                                    </Button>
                                                </OverlayTrigger>
                                                {/* <div className="" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1"> */}

                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 50, hide: 100 }}
                                                    overlay={resetTooltip}
                                                >
                                                    <Button className="btn btn-light btn-active-primary " type="reset">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiReset />
                                                        </span>

                                                    </Button>
                                                </OverlayTrigger>

                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 50, hide: 100 }}
                                                    overlay={resetTooltip}
                                                >
                                                    <div className="dropdown ">
                                                        <button className="btn btn-light btn-active-primary pb-2 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <IoIosSwitch />
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.client} onChange={() => setState({ ...state, client: !state.client })} type="checkbox" id="client" />
                                                                        <label className="form-check-label" htmlFor="client">
                                                                            Client
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.lockbox} onChange={() => setState({ ...state, lockbox: !state.lockbox })} type="checkbox" id="lockbox" />
                                                                        <label className="form-check-label" htmlFor="lockbox">
                                                                            Lockbox
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.filename} onChange={() => setState({ ...state, filename: !state.filename })} type="checkbox" id="filename" />
                                                                        <label className="form-check-label" htmlFor="filename">
                                                                            Filename
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.internal_tat} onChange={() => setState({ ...state, internal_tat: !state.internal_tat })} type="checkbox" id="internal_tat" />
                                                                        <label className="form-check-label" htmlFor="internal_tat">
                                                                            Internal Tat
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.external_tat} onChange={() => setState({ ...state, external_tat: !state.external_tat })} type="checkbox" id="external_tat" />
                                                                        <label className="form-check-label" htmlFor="external_tat">
                                                                            External Tat
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.amount} onChange={() => setState({ ...state, amount: !state.amount })} type="checkbox" id="amount" />
                                                                        <label className="form-check-label" htmlFor="amount">
                                                                            Amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.check_number} onChange={() => setState({ ...state, check_number: !state.check_number })} type="checkbox" id="check_number" />
                                                                        <label className="form-check-label" htmlFor="check_number">
                                                                            Check Number
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.payer} onChange={() => setState({ ...state, payer: !state.payer })} type="checkbox" id="payer" />
                                                                        <label className="form-check-label" htmlFor="payer">
                                                                            Payer
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.transaction_type} onChange={() => setState({ ...state, transaction_type: !state.transaction_type })} type="checkbox" id="transaction_type" />
                                                                        <label className="form-check-label" htmlFor="transaction_type">
                                                                            Transaction Type
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.facility} onChange={() => setState({ ...state, facility: !state.facility })} type="checkbox" id="facility" />
                                                                        <label className="form-check-label" htmlFor="facility">
                                                                            Facility
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.reason_rejection} onChange={() => setState({ ...state, reason_rejection: !state.reason_rejection })} type="checkbox" id="reason_rejection" />
                                                                        <label className="form-check-label" htmlFor="reason_rejection">
                                                                            Reason Rejection
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.lockbox_payee_name} onChange={() => setState({ ...state, lockbox_payee_name: !state.lockbox_payee_name })} type="checkbox" id="lockbox_payee_name" />
                                                                        <label className="form-check-label" htmlFor="lockbox_payee_name">
                                                                            Lockbox Payee name
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.batch_id} onChange={() => setState({ ...state, batch_id: !state.batch_id })} type="checkbox" id="client" />
                                                                        <label className="form-check-label" htmlFor="batch_id">
                                                                            Batch Id
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_autofilled_fields} onChange={() => setState({ ...state, no_of_autofilled_fields: !state.no_of_autofilled_fields })} type="checkbox" id="no_of_autofilled_fields" />
                                                                        <label className="form-check-label" htmlFor="no_of_autofilled_fields">
                                                                            No of auto filled fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_claims} onChange={() => setState({ ...state, no_of_claims: !state.no_of_claims })} type="checkbox" id="no_of_claims" />
                                                                        <label className="form-check-label" htmlFor="no_of_claims">
                                                                            No of claims
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_corrected_fields} onChange={() => setState({ ...state, no_of_corrected_fields: !state.no_of_corrected_fields })} type="checkbox" id="no_of_corrected_fields" />
                                                                        <label className="form-check-label" htmlFor="no_of_corrected_fields">
                                                                            No of corrected fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_fields} onChange={() => setState({ ...state, no_of_fields: !state.no_of_fields })} type="checkbox" id="no_of_fields" />
                                                                        <label className="form-check-label" htmlFor="no_of_fields">
                                                                            No of fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_key_fields} onChange={() => setState({ ...state, no_of_key_fields: !state.no_of_key_fields })} type="checkbox" id="no_of_key_fields" />
                                                                        <label className="form-check-label" htmlFor="no_of_fields">
                                                                            No of key fields
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_serviceLines} onChange={() => setState({ ...state, no_of_serviceLines: !state.no_of_serviceLines })} type="checkbox" id="no_of_serviceLines" />
                                                                        <label className="form-check-label" htmlFor="no_of_serviceLines">
                                                                            No of service lines
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.batch_id} onChange={() => setState({ ...state, batch_id: !state.batch_id })} type="checkbox" id="batch_id" />
                                                                        <label className="form-check-label" htmlFor="batch_id">
                                                                            File Recieved On
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.entry_start_time} onChange={() => setState({ ...state, entry_start_time: !state.entry_start_time })} type="checkbox" id="entry_start_time" />
                                                                        <label className="form-check-label" htmlFor="entry_start_time">
                                                                            Entry Start Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.entry_end_time} onChange={() => setState({ ...state, entry_end_time: !state.entry_end_time })} type="checkbox" id="entry_end_time" />
                                                                        <label className="form-check-label" htmlFor="entry_end_time">
                                                                            Entry End Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.status} onChange={() => setState({ ...state, status: !state.status })} type="checkbox" id="client" />
                                                                        <label className="form-check-label" htmlFor="status">
                                                                            Status
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </OverlayTrigger>

                                                {selectedRows?.length > 0 &&
                                                    <React.Fragment>

                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 50, hide: 100 }}
                                                            overlay={resetTooltip}
                                                        >
                                                            {/* <div className='col-lg-1 d-flex justify-content-between'> */}
                                                            <Button className="btn btn-light btn-active-primary " type="button" onClick={() => handleShowModal(selectedRows)}>
                                                                <span className="svg-icon svg-icon-3">
                                                                    <BiUserPlus />
                                                                </span>

                                                            </Button>

                                                        </OverlayTrigger>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            delay={{ show: 50, hide: 100 }}
                                                            overlay={resetTooltip}
                                                        >
                                                            <Button className="btn btn-light btn-active-primary  " type="button" onClick={() => { handleShowTat(); setSettingsTat(selectedRows) }}>
                                                                <span className="svg-icon svg-icon-3">
                                                                    <BiTimer />
                                                                </span>

                                                            </Button>
                                                        </OverlayTrigger>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </Form>

                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            <DataTable
                                                data={data?.data?.data}
                                                columns={columns}
                                                progressPending={loading}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                // subHeader
                                                // subHeaderComponent={subHeaderComponentMemo}
                                                theme="solarized"
                                                customStyles={datatableCustomStyles}

                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* Manual TAT  Modal Starts*/}


                <Modal Modal show={show} onHide={handleClose} centered className='manual-tat' >
                    <Modal.Header closeButton>
                        <Modal.Title> Change TAT</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className=''>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Current TAT</Form.Label>
                                    <Form.Control type="text" value={moment(settings?.tat).format('MMMM Do YYYY, h:mm a')} disabled />
                                </Form.Group>
                                <Form.Group className="mb-3 d-grid" controlId="formBasicEmail">
                                    <Form.Label>Change TAT</Form.Label>
                                    <DateTimePicker
                                        onChange={onChange}
                                        value={value}
                                        className='b-r-10' />
                                </Form.Group>
                            </div>
                            <div className='d-flex flex-row justify-content-center'>
                                <Button type="submit" className='btn btn-light btn-active-primary' onClick={changeTat} variant="primary" >Submit</Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal >
                {/* Manual TAT  Modal Ends*/}

                {/* Manual TAT  Modal Starts*/}

                <Modal show={showModal} onHide={handleCloseModal} centered className='assign'>
                    <Modal.Header closeButton>
                        <Modal.Title>Assign</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-4'>
                        <Form onSubmit={formik.handleSubmit}>
                            <div className='row d-flex align-items-start'>
                                <Form.Control onChange={formik.handleChange} name="staff" as="select" aria-label="Default select example">
                                    <option>Choose User</option>
                                    {userlist?.map((results, index) =>
                                        <option key={index} value={results?.value}>{results?.label}</option>
                                    )}
                                </Form.Control>
                            </div>
                            <div className='d-flex flex-row justify-content-center mt-4'>
                                <Button type="submit" className='btn btn-light btn-active-primary' variant="primary">Submit</Button>
                            </div>
                        </Form>

                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            Save Changes
                        </Button>
                    </Modal.Footer> */}
                </Modal>
                {/* Manual TAT  Modal Ends*/}

            </main >

        </React.Fragment >
    )
}

export default QaCompleted;