import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import AdminMaster from '../../../Layout/admin/Master';
import { Link } from 'react-router-dom';
import { getAllUsers, importCsvUserCode, toggleUser } from './../../../Redux/user/action';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import toggle from "../../../assets/img/toggle.svg";
import axiosInstance from './../../../axiosInstance';
import { GET_ALL_USER_URL } from '../../../api';
import { CREATE_USERS, EDIT_USERS } from '../../../routeNames';
import Modal from 'react-modal'

import ChangePasswordForm from '../Password/ChangePasswordForm';
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';
import { userDifferenceReport } from '../../../Redux/reports/action';
import UserDiffrenceReportModal from '../../BootstrapModal/UserDiffrenceReportModal';
import CsvUploaderModal from './CsvUploaderModal';
import { useFormik } from 'formik';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useSelector } from 'react-redux';
import { changeAllTitleFunction } from '../Dashboard/functions';
import { Tooltip } from '@mui/material';


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Filter By Name / Email"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;

const User = () => {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const permissions = useSelector((state) => state?.auth?.permissions ?? state?.auth?.user?.permissions);
    const [loaderUser, setLoaderUser] = useState({
        id: null,
        toggleLoader: false,
    })
    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
        showToastPassword: false
    });
    const [loading, setLoading] = useState(false);
    const [colorRow, setColorRow] = useState({});
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [data, setData] = useState({})
    const [userId, setUserId] = useState(null)
    const [toaster, setToaster] = useState(false)

    const [toggleReportPopup, setToggleReportPopup] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [loader, setLoader] = useState(false);

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [pageno, setPageNo] = useState(1);


    function closeModal() {
        setIsOpen(false);
        setUserId(null)
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const [modalUpload, setModalUpload] = useState(false);

    // const renderEditTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Edit
    //     </Tooltip>
    // );
    // const renderchangePasswordTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Change Password
    //     </Tooltip>
    // );
    // const renderToggleTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Status
    //     </Tooltip>
    // );
    // const renderReportTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Report
    //     </Tooltip>
    // );
    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );
    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                width: "70px",
                grow: 0,

            },

            {
                id: 'employee_id',
                name: 'Employee ID',
                selector: row => row.emp_id,
                cell: (row) => <div style={{ width: "120px", overflow: 'hidden' }} onClick={() => handleRowClick(row)}>
                    <span className='text-truncate '>
                        {row?.emp_id}
                    </span>
                </div>,
                sortable: true,
                wrap: true,
                // width: "120px",
                // width: "120px",
                //grow: 0.3,

            },
            {
                id: 'username',
                name: 'Username',
                selector: row => row.username,
                cell: (row) => <div>
                    {row?.username &&
                        <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '170px' }}>
                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' style={{ width: "120px" }} onClick={() => handleRowClick(row)}>
                                <Tooltip disableInteractive title={row?.username} placement='top' arrow>
                                    <span className='text-truncate'> {row?.username}</span>
                                </Tooltip>
                            </div>
                        </div>
                    }
                </div>,
                sortable: true,
                wrap: true,
                // width: "160px",
                //grow: 0.3,

            },

            {
                id: 'email',
                name: 'Email',
                selector: row => row.email,
                cell: (row) => <div>
                    {row?.email &&
                        <div className='d-flex flex-row justify-content-between align-items-center gap-2' style={{ width: '240px' }}>
                            <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' style={{ width: "240px" }} onClick={() => handleRowClick(row)}>
                                <Tooltip disableInteractive title={row?.email} placement='top' arrow>
                                    <span className='text-truncate' > {row?.email}</span>
                                </Tooltip>
                            </div>
                        </div>
                    }
                </div>,
                sortable: true,
                left: true,
                wrap: true,
                //width: "400px",
                //grow: 0.6,


            },

            // {
            //     id: 'role',
            //     name: 'User Type',
            //     selector: row => row.roles,
            //     sortable: true,
            //     left: true,
            //     fixed: true,
            //     wrap: true,
            //     grow: 0.5,


            //     cell: (row) => (
            //         <React.Fragment>
            //             {/* { // console.log(row)} */}
            //             {row &&
            //                 <span className="badge badge-light-primary fs-7 fw-bold">
            //                     {row.is_admin ? <span className='badge badge-light-primary fs-7 fw-bold'>ADMIN</span> : <span className='badge badge-light-primary fs-7 fw-bold'>STAFF</span>}
            //                 </span>
            //             }

            //         </React.Fragment>
            //     )
            // },

            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0 align-items-center gap-2" onClick={() => { handleRowClick(row) }}>
                        <Tooltip disableInteractive title="Edit" placement='top' arrow>
                            <Link onClick={() => changeAllTitleFunction('Edit User', EDIT_USERS + row.pk)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                        <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </Link>
                        </Tooltip>

                        {checkUserPermission('change_user_password') &&
                            <Tooltip disableInteractive title="Change Password" placement='top' arrow>

                                <Link onClick={() => { setIsOpen(true), setUserId(row.pk) }} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                    <span className="svg-icon svg-icon-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-key" viewBox="0 0 16 16">
                                            <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
                                            <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                        </svg>
                                    </span>
                                </Link>
                            </Tooltip>
                        }

                        {checkUserPermission('enable_disable_user') &&

                            <Tooltip disableInteractive title="Status" placement='top' arrow>

                                {loaderUser.id == row?.pk && loaderUser.toggleLoader ?
                                    <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                        <div className="svg-icon svg-icon-3 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    <div className="ms-2 form-check form-switch  ">
                                        <input className="form-check-input" type="checkbox" checked={row.is_active} role="switch" id="flexSwitchCheckDefault" onClick={(e) => __toggleUser(row, e)} />
                                    </div>
                                }
                            </Tooltip>

                        }
                        <Tooltip disableInteractive title="Report" placement='top' arrow>
                            {loaderUser.id == row?.pk && loaderUser.reportLoader ?
                                <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                                    <div className="svg-icon svg-icon-3 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                                :
                                <Link onClick={() => handleUserReport(row?.pk)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                    <span className="svg-icon svg-icon-3">
                                        <BsLayoutTextSidebarReverse />
                                    </span>
                                </Link>
                            }
                        </Tooltip>
                    </div>,
                // ignoreRowClick: false,

                // width: '280px',
                // allowOverflow: true,
                button: false,
                right: true
                //button: true,
            },

        ],
        [pageno, loading, data, loaderUser],
    );
    const [message, setMessage] = useState("")

    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })
    const [isSwal, setIsSwal] = useState({
        show: false,
        message: '',
    })

    const csvFormik = useFormik({
        initialValues: {
            csv_file: ""
        },
        // validationSchema: Yup.object().shape({
        //     csv_file: Yup.mixed()
        //         .required('Required'),
        // }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            const formData = new FormData();
            formData.append('csv_file', values?.csv_file[0]);
            importCsvUserCode({ formData })((response) => {
                if (response && response?.status) {
                    setSubmitting(false)
                    setToastMessage({
                        status: true,
                        message: response?.message,
                        type: 'success',
                    })



                    setModalUpload(!modalUpload)
                    setTimeout(() => {

                        window.location.reload()
                    }, 3000);
                }
                else {

                    setSubmitting(false)
                    setModalUpload(!modalUpload)
                    setIsSwal({
                        show: true,
                        message: response?.message,
                    })
                    // setToaster('error')
                    // setMessage(response?.response?.data?.message);

                }
            })
        }
    })
    const fetchUsers = async (page, limit, search) => {

        setLoading(true);

        //setLimit(limit);
        await getAllUsers({ page, limit, search })((response) => {
            setData(response?.data);
            setPageNo(page)
            setLimit(limit);
            setTotalRows(response?.data?.count);
            setLoading(false);
        })

    };
    const handlePageChange = page => {
        setPageNo(page)
        fetchUsers(page, limit, filterText);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchUsers(1, newPerPage, filterText)
        setLimit(newPerPage);
        setPageNo(1)
        handleResetDataTable()


    };
    useEffect(() => {
        // getAllUsers()((response) => {
        //     setData(response);
        // });
        fetchUsers(1, limit);
    }, []);
    const updateIsActiveStatus = (pk) => {
        // Create a copy of the state
        const newData = { ...data };

        // Find the user with the specified pk
        const user = newData.results.find(user => user.pk === pk);

        // Update the is_active status if the user is found
        if (user) {
            user.is_active = !user.is_active;

            // Update the state with the new data
            setData(newData);
        } 
    };

    const __toggleUser = (row, e) => {
        setLoaderUser({
            id: row.pk,
            toggleLoader: true,
            reportLoader: false
        })
        let formData = new FormData();    //formdata object
        const user = row.pk
        const isActive = row.is_active
        formData.append('user', user);

        toggleUser({ formData })((response) => {
            if (response?.status && response?.status_code === 201) {
                updateIsActiveStatus(user)
                setState({ showToast: !state.showToast })
                setToaster('success')
                setLoaderUser({
                    id: null,
                    toggleLoader: false,
                    reportLoader: false,
                })
                // getAllUsers()((response) => {
                //     setData(response);
                // });
            } else {
                setState({ error: true, error_message: response?.message });
            }
        });
    }
    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster('')
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [toaster])

    const searchGroups = async (e) => {
        setFilterText(e.target.value)
        let data = e.target.value ? e.target.value : '';
        await fetchUsers(1, limit, data)
        // const response = await axiosInstance.get(GET_ALL_USER_URL + "?limit=" + data?.data?.count + "&search=" + data);
        // setData(response?.data);
        handleResetDataTable()
    }
    // const filteredItems = data?.data?.results?.filter(
    //     item => item?.username?.toLowerCase().includes(filterText.toLowerCase()),
    // );
    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, resetPaginationToggle]);
    const handleClear = () => {
        if (filterText) {
            //setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            fetchUsers(1, limit);

        }
    };
    const __passwordToast = (status, message, status_code) => {
        // console.log(message);
        setState({ ...state, showToastPassword: true })
        setToaster('success')
        closeModal()
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions?.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const __closeToast = () => {
        setState({
            showToast: false,
            showToastPassword: false
        })
    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.pk == row.pk ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
        // // console.log(row);
    };
    const handleUserReport = (id) => {
        setLoaderUser({
            id: id,
            toggleLoader: false,
            reportLoader: true,
        })
        userDifferenceReport(id)((response) => {
            if (response && response.status) {
                setReportData(response?.data)
                setToggleReportPopup(true)
                setLoaderUser({
                    id: null,
                    toggleLoader: false,
                    reportLoader: false,
                })
            } else {
                setToggleReportPopup(false)
                setToaster("error")
            }
        })
    }
    const handleFileChange = (event) => {


        let selectedFile = event.target.files;

        csvFormik.setValues({
            ...csvFormik.values,
            csv_file: selectedFile
        })

    }

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }

    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} message={message} />
            <main id="main" className="main">
                {isSwal.show === true &&
                    <SweetAlert
                        danger
                        confirmBtnText="Ok!"
                        //confirmBtnBsStyle="danger"
                        confirmBtnCssClass='#6ba2b6'
                        onConfirm={() => { setIsSwal({ ...isSwal, show: false, message: "" }); window.location.reload() }}
                    >
                        <div >
                            {isSwal?.message}

                        </div>
                    </SweetAlert>
                }
                <div className="pagetitle tab-fixed-header ">
                    <h1 id='page-title'>Users</h1>
                </div>
                <CsvUploaderModal csvFormik={csvFormik} handleFileChange={handleFileChange} setModalUpload={setModalUpload} modalUpload={modalUpload} />
                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={state.showToast} onClose={__closeToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                </ToastContainer> 
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={state.showToastPassword} onClose={__closeToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                </ToastContainer>*/}

                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                        {
                            status: false,
                            message: "",
                            type: "",
                        }

                    )}
                        show={ToastMessage?.status} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                    </Toast>

                </ToastContainer>
                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0">
                                    <div className='d-flex align-items-center ms-3'>
                                        <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
                                    </div>
                                    <div className="card-toolbar gap-2 me-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                        <button className='btn btn-light btn-active-primary' onClick={() => setModalUpload(true)}>
                                            File Upload
                                        </button>
                                        {checkUserPermission('create_users') &&
                                            <div className="card-toolbar" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">
                                                <Link onClick={() => changeAllTitleFunction('Create User', CREATE_USERS)} className="btn btn-light btn-active-primary">
                                                    <span className="svg-icon svg-icon-3">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                            <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                        </svg>
                                                    </span>
                                                    Create
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="card-body py-3">
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}

                                                    data={data?.results}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    // subHeader
                                                    // subHeaderComponent={subHeaderComponentMemo}
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}

                                                />
                                            }
                                            {/* <table id="example" className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" >
                                                <thead>
                                                    <tr className="fw-bold text-muted">
                                                        <th className="min-w-150px">User Name</th>
                                                        <th className="min-w-150px">Email</th>
                                                        <th className="min-w-150px">Role</th>
                                                        <th className="min-w-100px text-end">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.status && data?.data.results && data?.data?.results.map((user, index) =>

                                                        <tr key={index}>
                                                            <td>{user.username}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.is_admin ? <span className='badge badge-light-primary fs-7 fw-bold'>ADMIN</span> : <span className='badge badge-light-primary fs-7 fw-bold'>STAFF</span>}</td>
                                                            <td className='text-end'>
                                                                <div className='btn-group'>
                                                                    <Link to={"/edit-user/" + user.pk} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">

                                                                        <span className="svg-icon svg-icon-3">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Link>
                                                                    <Link className={user.is_active ? "btn btn-icon btn-bg-light btn-active-color-primary btn-sm " : "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"} onClick={() => __toggleUser(user.pk)}>
                                                                        <span className="svg-icon svg-icon-3">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                                                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                                                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Change Password  Modal Starts*/}

                <Modal
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                    onRequestClose={() => { closeModal() }}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <ChangePasswordForm closeModal={closeModal} __passwordToast={__passwordToast} UserId={userId} />

                </Modal>
                {/* Change Password  Modal End*/}
                {/* User Report  Modal Starts*/}


                <UserDiffrenceReportModal
                    toggleReportPopup={toggleReportPopup}
                    setToggleReportPopup={setToggleReportPopup}
                    reportData={reportData}
                    setReportData={setReportData}
                />
                {/* User Report Modal End*/}

            </main>

        </React.Fragment>
    )
}

export default User;