import React from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { Formik, useFormik, Field, ErrorMessage } from 'formik';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import * as Yup from 'yup';
import { createUser, getGroupsForUser } from '../../../../Redux/user/action';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleUserDetails } from './../../../../Redux/user/action';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import { USERS } from '../../../../routeNames';
import moment from 'moment';
import { DatePicker } from 'rsuite';
import isAfter from 'date-fns/isAfter';

const EditUser = (props) => {
    const navigate = useNavigate()
    let { user_id } = useParams();
    const [data, setData] = useState('');

    const [state, setState] = useState({
        showToast: false,
        error: false,
        error_message: "",
    })
    const [groups, setGroups] = useState();

    const [options, setOptions] = useState({});

    const SignupSchema = Yup.object().shape({
        name: Yup.string().matches(/^[a-zA-Z0-9@._]+$/, 'Invalid characters'),
        emp_id: Yup.string().matches(/^[a-zA-Z0-9@._]+$/, 'Invalid characters'),
        username: Yup.string()
            .min(3, ' at least 1 character')
            .matches(/^[a-zA-Z0-9@._]+$/, 'Invalid characters')
            .required('Required'),
        email: Yup.string()
            .matches(/^[a-zA-Z0-9@._]+$/, 'Invalid characters')
            .email('Invalid email'),
        groups: Yup.array().min(1, 'At least one option is required').required('Required'),

    });
    const __handleEditUser = (values) => {

        let formData = new FormData();    //formdata object

        formData.append('user', user_id);   //append the values with key, value pair
        formData.append('username', values.username);
        formData.append('date_of_birth', values.date_of_birth);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('emp_id', values.emp_id)
        formData.append('name', values.name)

        const group_data = groups;
        const RedirectId = getRedirectTabId()
        createUser({ values, group_data })((response) => {
            if (!response?.data?.status && response?.status && response?.status_code === 201) {
                setState({ showToast: !state.showToast })
                setTimeout(() => {

                    changeAllTitleFunction("Users", USERS, RedirectId)
                }, 1000);
            } else {
                formik.setErrors({
                    name: response?.response?.data?.errors?.name,
                    emp_id: response?.response?.data?.errors?.emp_id,
                    date_of_birth: response?.response?.data?.errors?.date_of_birth[0],
                    username: response?.response?.data?.errors?.username,
                    groups: response?.response?.data?.errors?.groups,
                    email: response?.response?.data?.errors?.email,
                })
                // setState({ error: true, error_message: response?.message });
            }
        });
    }
    const __toggleToast = () => {
        setState({ showToast: !state.showToast })

    }
    const __toggleError = () => {
        setState({ showToast: !state.showToast })

    }




    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            emp_id: "",
            name: "",
            date_of_birth: "",
            username: "",
            email: "",
            password: "",
            groups: [],
            is_admin_dashboard: false
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "Enter a valid name. Only alphabets, numbers, and space are allowed."),
            emp_id: Yup.string().matches(/^[a-zA-Z0-9_-]*$/, "Enter a valid Emp Id. Only alphabets, numbers, '_', and '-' are allowed."),
            date_of_birth: Yup.date().required('Required'),
            username: Yup.string()
                .min(1, ' at least 1 character')
                .matches(/^[a-zA-Z0-9@_.]*$/, "Enter a valid Username. Only alphabets, numbers, '@', '_', and '.' are allowed.")
                .required('Required'),
            email: Yup.string()
                .matches(/^[a-zA-Z0-9@._]+$/, 'Invalid Email')
                .email('Invalid email'),
            groups: Yup.array().min(1, 'At least one option is required').required('Required'),

        }),

        onSubmit: (values) => {
            __handleEditUser(values)
        },
    });


    useEffect(() => {
        getGroupsForUser()((response) => {

            setOptions(response?.data?.groups);
            // setGroups(response?.data?.groups);
        });
        getSingleUserDetails(user_id)((response) => {

            if (response?.status && response?.data) {
                setData(response?.data?.user);
                setGroups(response?.data?.user?.user_groups.map((val) => val.value))
                setDateTime(moment(response?.data?.user?.date_of_birth).format("MM/DD/YYYY"))
                formik.setValues({
                    ...formik.values,
                    user: user_id,
                    emp_id: response?.data?.user?.emp_id,
                    username: response?.data?.user?.username,
                    date_of_birth: response?.data?.user?.date_of_birth ? response?.data?.user?.date_of_birth : null,
                    email: response?.data?.user?.email,
                    admin: response?.data?.user?.is_admin,
                    staff: response?.data?.user?.is_staff,
                    groups: response?.data?.user?.user_groups,
                    name: response?.data?.user?.name,
                    is_admin_dashboard: response?.data?.user?.is_superuser_dashboard

                })
            }
        });


    }, []);
    // const handleChangeSelect = (e) => {
    //     setGroups(e.map((value) => value.value))
    //     // // console.log()
    // }
    // const handleChangeSelect = (e) => {
    //     // setGroups(e.map((value) => value.value))
    //     const selectedGroups = [];
    //     // console.log(e.map((value) =>
    //         selectedGroups.push(value)
    //     ))
    //     e.map((value) => selectedGroups.push(value.value))
    //     // console.log(selectedGroups);
    //     // selectedGroups.push({

    //     // })
    //     formik.setValues({
    //         ...values,
    //         groups: selectedGroups,
    //     })
    const handleOnSelectChange = ({ name, value }) => {
        formik.setValues({ ...formik.values, [name]: value })
        setGroups(value.map((val) => val.value))
    }
    // // console.log()
    // }
    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {formik.values?.groups?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const [isFocusedClient, setIsFocusedClient] = useState(false);

    const [datetime, setDateTime] = useState("");

    const handleOnDateTimeChange = ({ name, value }) => {

        let created_date = ""
        if (value !== null) {
            const dateFrom = moment(value).format('MM/DD/YYYY')

            created_date = dateFrom
        }
        setDateTime(created_date ?? "")

        formik.setValues({
            ...formik.values,
            date_of_birth: created_date ?? "",
        })
    }

    return (
        <React.Fragment>

            <AdminMaster />
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header ">
                    <h1> Edit User</h1>
                </div>
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" onClose={__toggleToast} show={state.showToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                </ToastContainer>

                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">

                                    <Form
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="mb-3 row" >
                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Name</Form.Label>
                                                    <Form.Control
                                                        name='name'
                                                        type="name"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.name && !formik.errors?.name}
                                                        isInvalid={!!formik.errors.name}
                                                        value={formik.values.name}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength="255"
                                                        placeholder="Enter Name"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.name}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Employee Id</Form.Label>
                                                    <Form.Control
                                                        name='emp_id'
                                                        type="emp_id"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.emp_id && !formik.errors.emp_id}
                                                        isInvalid={!!formik.errors.emp_id}
                                                        value={formik.values.emp_id}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength="255"
                                                        placeholder="Employee Id"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.emp_id}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-4 color-black-user'>
                                                <Form.Group className="mb-3 d-flex flex-column" >

                                                    <Form.Label className='fw-bold'>Date of birth</Form.Label>

                                                    <DatePicker
                                                        oneTap
                                                        shouldDisableDate={date => isAfter(date, new Date().setFullYear(new Date().getFullYear() - 16))}
                                                        calendarDefaultDate={new Date().setFullYear(new Date().getFullYear() - 16)}

                                                        limitEndYear={-15}
                                                        placeholder="Select Date of birth"
                                                        name="date_of_birth"
                                                        format={"MM/dd/yyyy"}

                                                        onChange={(e) => { handleOnDateTimeChange({ name: "date_of_birth", value: e }); }}
                                                        value={formik.values.date_of_birth != null && formik.values.date_of_birth != "" ? new Date(formik.values.date_of_birth) : null}
                                                        isValid={formik.touched.username && !formik.errors.username}
                                                        isInvalid={!!formik.errors.username}

                                                    />
                                                    {formik.errors?.date_of_birth &&
                                                        <span className='custome-text-danger text-danger'>
                                                            {formik.errors?.date_of_birth}

                                                        </span>
                                                    }

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Username</Form.Label>
                                                    <Form.Control
                                                        name='username'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.username && !formik.errors.username}
                                                        isInvalid={!!formik.errors.username}
                                                        value={formik.values.username}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength="255"
                                                        placeholder="Enter Username"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors?.username}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Email</Form.Label>
                                                    <Form.Control
                                                        name='email'
                                                        type="email"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.email && !formik.errors.email}
                                                        isInvalid={!!formik.errors.email}
                                                        value={formik.values.email}
                                                        className='b-r-10 form-control-solid custom-padding'
                                                        maxLength="255"
                                                        placeholder="Enter Email"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.email}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-4'>
                                                <Form.Label className='fw-bold'>Groups</Form.Label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    isMulti
                                                    value={formik.values?.groups}
                                                    name="groups"
                                                    options={options}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} /> }}
                                                    onChange={(e) => { handleOnSelectChange({ name: 'groups', value: e }) }}
                                                    placeholder="Select User Group..."
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px", minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),
                                                    }} />

                                                {formik.errors?.groups &&
                                                    <span className='custome-text-danger text-danger'>
                                                        {formik.errors?.groups}

                                                    </span>
                                                }
                                                {/* {formik.errors?.groups && formik.touched.groups &&
                                                <ErrorMessage name="groups" component="div" />
                                                } */}
                                            </div>
                                            <div className='col-lg-4'>

                                                <div className="">
                                                    <Form.Label className='fw-bold'>Is supervisor dashboard</Form.Label>
                                                    <Form.Check
                                                        type="switch"
                                                        // reverse
                                                        onChange={() => formik.setFieldValue('is_admin_dashboard', !formik?.values?.is_admin_dashboard)}
                                                        checked={formik?.values?.is_admin_dashboard}
                                                        isInvalid={
                                                            formik?.errors?.is_admin_dashboard &&
                                                            !!formik?.errors?.is_admin_dashboard
                                                        }
                                                        feedbackType="invalid"
                                                        feedback={
                                                            formik?.errors?.is_admin_dashboard &&
                                                            formik?.errors?.is_admin_dashboard
                                                        }

                                                        name="is_admin_dashboard"
                                                    // label='Is supervisor dashboard'
                                                    />
                                                </div>

                                            </div>

                                        </div>
                                        <div>
                                            {/* {state.error &&
                                                <span className='text-danger'>{state.error_message}</span>} */}
                                        </div>
                                        <div className='d-flex flex-row justify-content-end'>
                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("Users", USERS) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            {checkUserPermission('edit_users') &&
                                                <div className=''>
                                                    <button
                                                        disabled={!formik.isValid}
                                                        type='submit'
                                                        className='btn btn-primary btnHover'
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </React.Fragment>
    )
}

export default EditUser