import { GET_REASON_CLASSIFICAITON_URL, GET_REASON_CLASSIFICATION_DROP_DOWN_URL, GET_SINGLE_REASON_CLASSIFICATION_URL, POST_CREATE_UPDATE_REASON_CLASSIFICAITON_URL } from "../../api";
import axiosInstance from "../../axiosInstance";
import { DELETE_INCOMPLETE_STATUS } from "../../routeNames";

export const getReasonList = (page, limit, search) => onResponse => {
    try {

        let data = search ? search : "";
        axiosInstance.get(GET_REASON_CLASSIFICAITON_URL + "?page=" + page + "&limit=" + limit + "&search=" + data)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createReason = (client, incompletestatus) => onResponse => {
    try {

        axiosInstance.post(POST_CREATE_UPDATE_REASON_CLASSIFICAITON_URL, { client, incompletestatus })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const updateReason = (client, id, status) => onResponse => {
    try {
        axiosInstance.post(POST_CREATE_UPDATE_REASON_CLASSIFICAITON_URL, {
            client,
            incompletestatus: [{
                id: id, status: status,
            }]
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleReasonDetail = (status_data) => onResponse => {
    try {

        axiosInstance.get(GET_SINGLE_REASON_CLASSIFICATION_URL + "?status_data=" + status_data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getReasonsDropdown = (client) => onResponse => {
    try {

        axiosInstance.get(GET_REASON_CLASSIFICATION_DROP_DOWN_URL + "?client=" + client)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getDeleteIncomplete = (id) => onResponse => {
    console.log(id);
    try {

        axiosInstance.put(DELETE_INCOMPLETE_STATUS, {
            id: id
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

