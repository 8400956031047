import React, { useEffect } from 'react'
import { FormikProvider } from 'formik'
import { Form } from 'react-bootstrap';
import Eob from './eob';
import PayerSearch from '../Elements/PayerSearch';
import PayeeSearch from '../Elements/PayeeSearch';
import ClaimHeaderEob from '../Elements/ClaimHeaderEob';
import Claims from './claims';
import Servicelines from './servicelines';
import PatientSearch from './../Elements/PatientSearch';
import moment from 'moment';
import ClaimSummary from './claimsummary';
import { handleKeyPress } from '../gridFunctions';

const Eob835 = (props) => {

    const {
        formik,

        handleEob835Data,

        payerLookup,

        payeeLookup,

        disableCorresField,
        claim835Index,
        claim835,
        setClaim835,

        patientLookup,
        copyClaim,

        // DBK
        cleareEobFocus,
        eobFocus,
        setEobFocus,
        handleFocusEob,
        addingNewEobClaimDbk,
        removeEobClaimDbk,
        isDbkPermission,
        jobData,
        setClaim835Focus,
        handleColumnCalculation,
        handleRowCalculation,
        handleClaimCalculation,
        handleSlCalculation,
        handleRemainingBalanceCalculation,
        qdxTransaction,
        handlePageChange,
        initialPage,
        setInitialPage,
        jumpToPage,
        setClaim835Index,
        doubleClicked,
        setState,
        isViewOnllyGrid
    } = props;


    const setDefaultValueAssignedWorkClaim = async (event, index, fieldName) => {
        if (event.ctrlKey == true && event.keyCode == 32) {
            var claims = formik.values?.claims;
            if (fieldName == 'patient_account_number' && jobData?.default?.account_number) {
                claims[index] = {
                    ...claims[index],
                    patient_account_number: jobData?.default?.account_number,
                    dbk_patient_account_number: jobData?.default?.account_number,

                }
            }
            else if (fieldName == 'payer_claim_number' && jobData?.default?.payer_claim_number) {
                claims[index] = {
                    ...claims[index],
                    payer_claim_number: jobData?.default?.payer_claim_number,
                    dbk_payer_claim_number: jobData?.default?.payer_claim_number,

                }
            }
            else if (fieldName == 'patient_first_name' && jobData?.default?.first_name) {
                claims[index] = {
                    ...claims[index],
                    patient_first_name: jobData?.default?.first_name,
                    dbk_patient_first_name: jobData?.default?.first_name,

                }
            }
            else if (fieldName == 'patient_last_name' && jobData?.default?.last_name) {
                claims[index] = {
                    ...claims[index],
                    patient_last_name: jobData?.default?.last_name,
                    dbk_patient_last_name: jobData?.default?.last_name,

                }
            }
            else if (fieldName == 'period_start' && jobData?.default?.date_of_service) {
                let date = moment(jobData?.default?.date_of_service).format("MM/DD/YYYY")

                claims[index] = {
                    ...claims[index],
                    period_start: date,
                    dbk_period_start: date,

                }
            }
            else if (fieldName == 'period_end' && jobData?.default?.date_of_service) {
                let date = moment(jobData?.default?.date_of_service).format("MM/DD/YYYY")

                claims[index] = {
                    ...claims[index],
                    period_end: date,
                    dbk_period_end: date,

                }
            }

            formik.setValues({
                ...formik.values,
                totalclaims: claims,
            })
        }
    }
    const setDefaultValueAssignedWorkClaimAdj = async (event, index, claimIndex, fieldName) => {
        if (event.ctrlKey == true && event.keyCode == 32) {
            var claims = formik.values?.claims;
            if (fieldName == 'pc' && jobData?.default?.reason_codes) {

                let reason_codes = jobData?.default?.reason_codes

                let adj_data = [...claims[index].claim_adjustments]
                adj_data[claimIndex].pc = reason_codes

                claims[index] = {
                    ...claims[index],
                    claim_adjustments: adj_data
                }
            }

            formik.setValues({
                ...formik.values,
                totalclaims: claims,
            })
        }
    }

    const setDefaultValueAssignedWorkServiceLine = async (event, index, sfindex, adjIndex, fieldName) => {
        if (event.ctrlKey == true && event.keyCode == 32) {
            var claims = formik.values?.claims;
            if (fieldName == 'from_date' && jobData?.default?.date_of_service) {
                let date = moment(jobData?.default?.date_of_service).format("MM/DD/YYYY")
                let service_data = [...claims[index].service_lines]
                service_data[sfindex].from_date = date
                claims[index] = {
                    ...claims[index],
                    service_lines: service_data
                }
            }
            if (fieldName == 'to_date' && jobData?.default?.date_of_service) {
                let date = moment(jobData?.default?.date_of_service).format("MM/DD/YYYY")
                let service_data = [...claims[index].service_lines]
                service_data[sfindex].to_date = date
                claims[index] = {
                    ...claims[index],
                    service_lines: service_data
                }
            }
            if (fieldName == 'billed' && jobData?.default?.charge) {
                let charge = jobData?.default?.charge

                let service_data = [...claims[index].service_lines]
                service_data[sfindex].billed = charge
                claims[index] = {
                    ...claims[index],
                    service_lines: service_data
                }
                handleColumnCalculation(sfindex, "billed")
                handleRowCalculation(sfindex)
                handleClaimCalculation()
                setTimeout(() => {

                    handleSlCalculation();
                }, 200);
                // triggerCalculation()
            }
            if (fieldName == 'allowed' && jobData?.default?.allowed_amount) {
                let allowed = jobData?.default?.allowed_amount

                let service_data = [...claims[index].service_lines]
                service_data[sfindex].allowed = allowed
                claims[index] = {
                    ...claims[index],
                    service_lines: service_data
                }
                handleColumnCalculation(sfindex, "allowed")
                // triggerCalculation()
            }
            if (fieldName == 'code' && jobData?.default?.cpt_codes) {
                let cpt_codes = jobData?.default?.cpt_codes

                let service_data = [...claims[index].service_lines]
                service_data[sfindex].code = cpt_codes
                claims[index] = {
                    ...claims[index],
                    service_lines: service_data
                }
            }
            if (fieldName == 'pc' && jobData?.default?.reason_codes) {

                let reason_codes = jobData?.default?.reason_codes

                let service_data = [...claims[index].service_lines]
                service_data[sfindex].adjustments[adjIndex].pc = reason_codes

                claims[index] = {
                    ...claims[index],
                    service_lines: service_data
                }
            }
            formik.setValues({
                ...formik.values,
                claims: claims,
            })
            // setTimeout(() => {
            //     triggerServiceLine(sfindex)
            // }, [200]);
        }
    }



    useEffect(() => {
        handleEob835Data()
    }, [jobData])


    //-------------------Main Calculations--------------------------------\\
    const handlePlbAmount = async (e, plbindex) => {
        const value = e.target.value;

        if (value.includes('.') && value.split('.')[1].length > 2) {
            return;
        }
        await formik.setValues((prevValues) => ({
            ...prevValues,
            plb_adjustments: prevValues?.plb_adjustments.map((plb, plbIndex) => {
                if (plbIndex === plbindex) {
                    return {
                        ...plb,
                        plb_amt: isNaN(value) ? "" : value
                    };
                }
                return plb;
            })
        }));
        await handleRemainingBalanceCalculation()
    }




    // ================================= CHECK CALCULATION AND OTHER FUNCTIONS =================================//

    const handleFloatValue = (e, type, index) => {
        const value = e.target.value ? parseFloat(e.target.value).toFixed(2) : "";
        if (type == "check_amount") {

            formik.setValues({
                ...formik.values,
                check_amount: value ?? "",
            })
        }
        else if (type == "plb_amt") {
            const plb_data = formik.values.plb_adjustment
            plb_data[index].plb_amt = value ?? ""
            formik.setValues({
                ...formik.values,
                plb_adjustment: plb_data,
            })
        }

    }


    // ================================= CHECK CALCULATION AND OTHER FUNCTIONS =================================//

    // ================================= PLB CALCULATION AND OTHER FUNCTIONS =================================//


    const handlePLbFloatValue = async (e, index) => {

        // const value = parseFloat(e.target.value).toFixed(2);
        // var data = formik.values?.plb_adjustments;

        // data[index].plb_amt = value;

        // formik.setValues({
        //     ...formik.values,
        //     plb_adjustments: data,
        // })

        // const _ = require('lodash');


        // await formik.setValues((prevState) => {
        //     const value = parseFloat(e.target.value).toFixed(2);
        //     // const data = prevState?.plb_adjustments;
        //     const data = _.cloneDeep(prevState?.plb_adjustments);

        //     // data[index].plb_amt = value ?? "";

        //     return {
        //         ...prevState,
        //         plb_adjustments: prevState.plb_adjustments?.map((plb, ind) => {
        //             if (ind == index) {
        //                 return {
        //                     ...plb,
        //                     plb_amt: value ?? ""
        //                 }
        //             } else {

        //                 return { plb },
        //             }
        //         }),
        //     };
        // });
        await formik.setValues((prevState) => {
            const value = parseFloat(e.target.value).toFixed(2);
            const data = _.cloneDeep(prevState?.plb_adjustments);

            return {
                ...prevState,
                plb_adjustments: data?.map((plb, ind) => {
                    if (ind === index) {
                        return {
                            ...plb,
                            plb_amt: isNaN(value) ? "" : value
                        };
                    } else {
                        return plb;
                    }
                }),
            };
        });

    }

    const handlePlbDeleteAmount = async (index) => {
        // var datas = formik?.values?.plb_adjustments;
        const _ = require('lodash');
        const datas = _.cloneDeep(formik?.values?.plb_adjustments);

        await datas.splice(index, 1)
        await formik.setValues((prevState) => {
            return {

                ...prevState,
                plb_adjustments: datas,
            }
        })

        const remaining = await handleRemainingBalanceCalculation()
    }

    const insertRemainingAmount = async (index) => {
        // var datas = formik?.values?.plb_adjustments;
        // if (parseFloat(formik.values?.remaining_balance).toFixed(2) !== parseFloat(0).toFixed(2))
        //     datas[index].plb_amt = -formik.values?.remaining_balance;
        // await formik.setValues({
        //     ...formik.values,
        //     plb_adjustments: datas,
        // })
        await formik.setValues((prevState) => {
            if (parseFloat(prevState?.remaining_balance).toFixed(2) !== parseFloat(0).toFixed(2)) {
                return {
                    ...prevState,
                    plb_adjustments: prevState.plb_adjustments?.map((plb, ind) => {
                        if (index === ind) {
                            return {
                                ...plb,
                                plb_amt: -prevState.remaining_balance,
                            };
                        } else {
                            return plb;
                        }
                    }),
                };
            }
            else {
                return {
                    ...prevState,
                }
            }
        });



        const remaining = await handleRemainingBalanceCalculation()


    }
    // ================================= PLB CALCULATION AND OTHER FUNCTIONS =================================//



    // ===================================    FORMIK CHANGE IDS ================================= //
    // function for changing ids of payer, payee and patient when fetched datas has changed manually
    const handleChangeIds = (e, value) => {
        // const name = e.target.name
        if (value == "payer") {

            formik.setValues({

                ...formik.values,

                payer_id: "",
                payer_name: e.target.name == "payer_name" ? e.target.value : formik.values?.payer_name,
                payer_address: e.target.name == "payer_address" ? e.target.value : formik.values?.payer_address,
                payer_city: e.target.name == "payer_city" ? e.target.value : formik.values?.payer_city,
                payer_state: e.target.name == "payer_state" ? e.target.value : formik.values?.payer_state,
                payer_zip: e.target.name == "payer_zip" ? e.target.value : formik.values?.payer_zip,
            })
            // setPropCode(true)
        }
        else if (value == "payee") {
            formik.setValues({
                ...formik.values,
                payee_id: "",
                payee_name: e.target.name == "payee_name" ? e.target.value : formik.values?.payee_name,
                payee_npi: e.target.name == "payee_npi" ? e.target.value : formik.values?.payee_npi,
                payee_address: e.target.name == "payee_address" ? e.target.value : formik.values?.payee_address,
                payee_city: e.target.name == "payee_city" ? e.target.value : formik.values?.payee_city,
                payee_state: e.target.name == "payee_state" ? e.target.value : formik.values?.payee_state,
                payee_zip: e.target.name == "payee_zip" ? e.target.value : formik.values?.payee_zip,

            })
        }

        // else if (value == "patient") {

        //     var data = formik.values?.totalclaims;

        //     data[claim835Index] = {
        //         ...formik.values?.totalclaims[claim835Index],
        //         patient_id: "",
        //         patient_first_name: e.target.name == `totalclaims[${claim835Index}].patient_first_name` ? e.target.value : formik.values.totalclaims[claim835Index]?.patient_first_name,
        //         patient_middle_name: e.target.name == `totalclaims[${claim835Index}].patient_middle_name` ? e.target.value : formik.values.totalclaims[claim835Index]?.patient_middle_name,
        //         patient_last_name: e.target.name == `totalclaims[${claim835Index}].patient_last_name` ? e.target.value : formik.values.totalclaims[claim835Index]?.patient_last_name,
        //         patient_identifier: e.target.name == `totalclaims[${claim835Index}].patient_identifier` ? e.target.value : formik.values.totalclaims[claim835Index]?.patient_identifier,
        //     }

        //     formik.setValues({
        //         ...formik.values,
        //         totalclaims: data,




        //     })
        // }
        // else if (value == "subscriber") {
        //     var data = formik.values?.totalclaims;

        //     data[claim835Index] = {
        //         ...formik.values?.totalclaims[claim835Index],
        //         subscriber_id: "",
        //         subscriber_first_name: e.target.name == `totalclaims[${claim835Index}].subscriber_first_name` ? e.target.value : formik.values.totalclaims[claim835Index]?.subscriber_first_name,
        //         subscriber_middle_name: e.target.name == `totalclaims[${claim835Index}].subscriber_middle_name` ? e.target.value : formik.values.totalclaims[claim835Index]?.subscriber_middle_name,
        //         subscriber_last_name: e.target.name == `totalclaims[${claim835Index}].subscriber_last_name` ? e.target.value : formik.values.totalclaims[claim835Index]?.subscriber_last_name,
        //         subscriber_identifier: e.target.name == `totalclaims[${claim835Index}].subscriber_identifier` ? e.target.value : formik.values.totalclaims[claim835Index]?.subscriber_identifier,
        //     }

        //     formik.setValues({
        //         ...formik.values,
        //         totalclaims: data,




        //     })
        // }
        // else if (value == "rendering_provider") {

        //     var data = formik.values?.totalclaims;

        //     data[claim835Index] = {
        //         ...formik.values?.totalclaims[claim835Index],
        //         rendering_provider_id: "",
        //         rendering_provider_first_name: e.target.name == `totalclaims[${claim835Index}].rendering_provider_first_name` ? e.target.value : formik.values.totalclaims[claim835Index]?.rendering_provider_first_name,
        //         rendering_provider_last_name: e.target.name == `totalclaims[${claim835Index}].rendering_provider_last_name` ? e.target.value : formik.values.totalclaims[claim835Index]?.rendering_provider_last_name,
        //         rendering_provider_identifier_code: e.target.name == `totalclaims[${claim835Index}].rendering_provider_identifier_code` ? e.target.value : formik.values.totalclaims[claim835Index]?.rendering_provider_identifier_code,
        //     }

        //     formik.setValues({
        //         ...formik.values,
        //         totalclaims: data,




        //     })
        // }


    }
    // ===================================    FORMIK CHANGE IDS ================================= //

    return (
        <React.Fragment>
            <FormikProvider value={formik} >
                <Form
                    autoComplete={"off"}
                    onKeyDown={handleKeyPress}
                    noValidate id="payment-835-form"
                    onSubmit={formik.handleSubmit}
                // onReset={resertFormikData}
                >
                    {payerLookup ?
                        (
                            <React.Fragment>
                                <PayerSearch
                                    {...props}
                                />
                            </React.Fragment>

                        ) : payeeLookup ?
                            (
                                <React.Fragment>
                                    <PayeeSearch
                                        {...props}
                                    />
                                </React.Fragment>

                            ) : patientLookup ?
                                (
                                    <React.Fragment>
                                        <PatientSearch
                                            {...props}
                                        />
                                    </React.Fragment>
                                ) : claim835 ?
                                    (
                                        <React.Fragment>
                                            <ClaimSummary
                                                setClaim835={setClaim835}
                                                setClaim835Index={setClaim835Index}
                                                claim835={claim835}
                                                setState={setState}
                                                isViewOnllyGrid={isViewOnllyGrid}

                                                jumpToPage={jumpToPage}
                                                formik={formik} />
                                        </React.Fragment>
                                    ) :
                                    (
                                        <React.Fragment>
                                            <div>
                                                <Eob
                                                    {...props}
                                                    handleFloatValue={handleFloatValue}
                                                    handleChangeIds={handleChangeIds}
                                                    handlePlbAmount={handlePlbAmount}
                                                    handlePLbFloatValue={handlePLbFloatValue}
                                                    handlePlbDeleteAmount={handlePlbDeleteAmount}
                                                    insertRemainingAmount={insertRemainingAmount}
                                                />
                                            </div>
                                            {formik?.values?.grid_customization == 'NORMAL' &&
                                                <div className="claim835-mt UBAISYYT">
                                                    <ClaimHeaderEob
                                                        {...props}
                                                        copyClaim={copyClaim}


                                                    />
                                                    {formik.values?.claims?.map((claim, index) => (
                                                        <React.Fragment key={index}>

                                                            {claim835Index == index &&
                                                                <React.Fragment >
                                                                    <Claims
                                                                        {...props}
                                                                        handleChangeIds={handleChangeIds}
                                                                        index={claim835Index}
                                                                        setDefaultValueAssignedWorkClaim={setDefaultValueAssignedWorkClaim}
                                                                        setClaim835Focus={setClaim835Focus}
                                                                        qdxTransaction={qdxTransaction}
                                                                    />
                                                                    {qdxTransaction != 'PATIENT_PAY' &&
                                                                        <Servicelines
                                                                            {...props}
                                                                            index={claim835Index}
                                                                            setDefaultValueAssignedWorkClaimAdj={setDefaultValueAssignedWorkClaimAdj}
                                                                            setDefaultValueAssignedWorkServiceLine={setDefaultValueAssignedWorkServiceLine}
                                                                        />
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            }
                                        </React.Fragment>


                                    )}

                </Form>

            </FormikProvider>
        </React.Fragment>
    )
}

export default Eob835