import React from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { Formik, Field } from 'formik';
import { Form, InputGroup, OverlayTrigger, Toast, ToastContainer, Tooltip } from 'react-bootstrap';
import * as Yup from 'yup';
import { createUser, getGroupsForUser } from '../../../../Redux/user/action';
import { useState, useEffect } from 'react';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { useNavigate } from 'react-router-dom';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import { USERS } from '../../../../routeNames';
import { DatePicker } from 'rsuite';
import moment from 'moment';
// import { createUserWithEmailAndPassword } from 'firebase/auth';
// import { getAuth } from 'firebase/auth';
// import { app } from '../../../../firebase';
import isAfter from 'date-fns/isAfter';


const CreateUser = () => {
    const navigate = useNavigate()
    const [state, setState] = useState({
        showToast: false,
        error: false,
        error_message: "",
    })
    const [groups, setGroups] = useState();
    const [showPassword, setShowPassword] = useState(false)

    const [options, setOptions] = useState({});

    const SignupSchema = Yup.object().shape({
        name: Yup.string().matches(/^[a-zA-Z0-9 ]*$/, "Enter a valid name. Only alphabets, numbers, and space are allowed."),
        emp_id: Yup.string().matches(/^[a-zA-Z0-9_-]*$/, "Enter a valid Emp Id. Only alphabets, numbers, '_', and '-' are allowed."),
        date_of_birth: Yup.date().required('Required'),
        username: Yup.string()
            .min(3, ' at least 1 character')
            // .matches(/^[a-zA-Z0-9@._]+$/, 'Invalid characters')
            .matches(/^[a-zA-Z0-9@_.]*$/, "Enter a valid Username. Only alphabets, numbers, '@', '_', and '.' are allowed.")
            .required('Required'),
        email: Yup.string()
            .matches(/^[a-zA-Z0-9@._]+$/, 'Invalid Email')
            .email('Invalid email'),
        password: Yup.string().required('Please Enter your password')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
            ),
        // role: Yup.string()
        //     .required('Required'),
        groups: Yup.array().min(1, 'At least one value is required'),
    });

    useEffect(() => {
        getGroupsForUser()((response) => {
            setOptions(response.data.groups);
        });

    }, []);


    const [toaster, setToaster] = useState('')

    const __handleCreateUser = (values, actions) => {
        // alert("EEEEEEEEEEEEE")
        // console.log('====================================');
        // console.log(values);
        // console.log('====================================');
        let formData = new FormData();    //formdata object

        formData.append('username', values.username);
        formData.append('email', values.email);
        formData.append('date_of_birth', values.date_of_birth);
        formData.append('password', values.password);
        // formData.append('is_admin', values.role == "ADMIN" ? true : false);
        // formData.append('is_staff', values.role == "STAFF" ? true : false);
        formData.append('is_superuser', values.role == "SUPERUSER" ? true : false);

        // {
        //     values.role == "ADMIN" && formData.append('is_admin', true)
        // }
        // {
        //     values.role == "STAFF" && formData.append('is_staff', true)
        // }


        const group_data = groups;
        const RedirectId = getRedirectTabId()
        // console.log(groups);
        createUser({ values, group_data })((response) => {
            // console.log(response, 'data');
            if (!response?.data?.status && response?.status && response?.status_code === 201) {



                // const auth = getAuth(app);
                // createUserWithEmailAndPassword(auth, values?.username + '@zaparetech.com', values?.username + '@zaparetech.com')
                //     .then((userCredential) => {
                //         // Signed in 
                //         const user = userCredential.user;
                //         console.log(user);
                //         // ...
                //     })
                //     .catch((error) => {
                //         const errorCode = error.code;
                //         const errorMessage = error.message;
                //         // ..
                //         console.log(errorCode, errorMessage);

                //     });
                setToaster('success')
                setTimeout(() => {

                    setToaster('')

                    changeAllTitleFunction("Users", USERS, RedirectId)
                }, 2000);
                // setState({ showToast: !state.showToast })
            } else {
                // setState({ error: true, error_message: response?.message });
                actions.setErrors({
                    name: response?.response?.data?.errors?.name,
                    emp_id: response?.response?.data?.errors?.emp_id,
                    date_of_birth: response?.response?.data?.errors?.date_of_birth,
                    username: response?.response?.data?.errors?.username,
                    groups: response?.response?.data?.errors?.groups,
                    email: response?.response?.data?.errors?.email,
                })
                setToaster('error')
                setTimeout(() => {

                    setToaster('')

                }, 2000);
            }
        });
    }
    const __toggleToast = () => {
        setState({ showToast: !state.showToast })

    }
    const __toggleError = () => {
        setState({ showToast: !state.showToast })

    }
    // const formik = Formik
    const handleChangeSelect = (e) => {
        setGroups(e.map((value) => value.value))


        // // console.log()
    }

    const passwordValidationMsg = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character
        </Tooltip>
    );
    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {groups?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    const [datetime, setDateTime] = useState("");

    const handleOnDateTimeChange = ({ name, value, setFieldValue }) => {

        let created_date = ""
        if (value !== null) {
            const dateFrom = moment(value).format('MM/DD/YYYY')

            created_date = dateFrom
        }
        setDateTime(created_date)
        setFieldValue('date_of_birth', created_date)

        //  formik.setFieldValue(start_date,created_date ?? "")
    }

    const [isFocusedClient, setIsFocusedClient] = useState(false);
    // console.log();
    return (
        <React.Fragment>

            <AdminMaster ToastMessage={toaster} />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" onClose={__toggleToast} show={state.showToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle tab-fixed-header ">
                    <h1>Create User</h1>
                </div>
                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">
                                    <Formik
                                        initialValues={{
                                            name: "",
                                            emp_id: "",
                                            date_of_birth: "",
                                            username: "",
                                            email: "",
                                            password: "",
                                            role: "",
                                            groups: [],
                                            is_admin_dashboard: false
                                        }}
                                        onSubmit={(initialValues, actions) => {
                                            // console.log(initialValues);
                                            __handleCreateUser(initialValues, actions)
                                        }}

                                        validationSchema={SignupSchema}

                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            setFieldValue,
                                            values,
                                            touched,
                                            isValid,
                                            errors,
                                        }) => (

                                            <Form
                                                onSubmit={handleSubmit}
                                            >
                                                <div className="mb-3 row" >

                                                    <div className='col-lg-4'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Name</Form.Label>
                                                            <Form.Control
                                                                name='name'
                                                                type="name"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.name && !errors.name}
                                                                isInvalid={!!errors.name}
                                                                value={values.name}
                                                                className='b-r-10 form-control-solid'
                                                                maxLength="255"
                                                                placeholder="Enter Name"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors?.name}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Employee Id</Form.Label>
                                                            <Form.Control
                                                                name='emp_id'
                                                                type="emp_id"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.emp_id && !errors.emp_id}
                                                                isInvalid={!!errors.emp_id}
                                                                value={values.emp_id}
                                                                className='b-r-10 form-control-solid'
                                                                maxLength="255"
                                                                placeholder="Employee Id"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors?.emp_id}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4 color-black-user'>
                                                        <Form.Group className="mb-3 d-flex flex-column" >

                                                            <Form.Label className='fw-bold'>Date of birth</Form.Label>
                                                            <DatePicker
                                                                oneTap
                                                                shouldDisableDate={date => isAfter(date, new Date().setFullYear(new Date().getFullYear() - 16))}
                                                                calendarDefaultDate={new Date().setFullYear(new Date().getFullYear() - 16)}

                                                                limitEndYear={-15}
                                                                placeholder="Select Date of birth"
                                                                name="date_of_birth"
                                                                format={"MM/dd/yyyy"}

                                                                onChange={(e) => { handleOnDateTimeChange({ name: "date_of_birth", value: e, setFieldValue: setFieldValue }); }}
                                                                defaultValue={datetime}


                                                            />
                                                            {errors?.date_of_birth &&
                                                                <span className='custome-text-danger text-danger'>
                                                                    {errors?.date_of_birth}

                                                                </span>
                                                            }
                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Username</Form.Label>
                                                            <Form.Control
                                                                name='username'
                                                                type="text"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.username && !errors.username}
                                                                isInvalid={!!errors.username}
                                                                value={values.username}
                                                                className='b-r-10 form-control-solid'
                                                                maxLength="255"
                                                                required
                                                                placeholder="Enter Username"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors?.username}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4' >
                                                        <Form.Group className=""  >
                                                            <Form.Label className='fw-bold'>Password</Form.Label>
                                                            {errors.password &&
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    delay={{ show: 50, hide: 100 }}
                                                                    overlay={passwordValidationMsg}
                                                                >
                                                                    <span className='ms-2'><i className="bi bi-info-circle text-danger"></i></span>
                                                                </OverlayTrigger>}
                                                            <InputGroup className='p-0 m-0'>

                                                                <Form.Control
                                                                    name='password'
                                                                    type={showPassword ? "text" : "password"}
                                                                    onChange={handleChange}
                                                                    autoComplete="false"
                                                                    isValid={touched.password && !errors.password}
                                                                    isInvalid={!!errors.password}
                                                                    value={values.password}
                                                                    className=' form-control-solid password-padding'
                                                                    // maxLength="255"
                                                                    required
                                                                    placeholder="Enter Password"
                                                                />
                                                                <InputGroup.Text className='eye-input-group pe-3' onClick={() => setShowPassword(!showPassword)}  >
                                                                    <i className={showPassword ? 'bi bi-eye' : "bi bi-eye-slash"}></i>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                            <Form.Control.Feedback type="invalid">{errors?.password}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>
                                                    <div className='col-lg-4'>
                                                        <Form.Group className="mb-3" >

                                                            <Form.Label className='fw-bold'>Email</Form.Label>
                                                            <Form.Control
                                                                name='email'
                                                                type="email"
                                                                onChange={handleChange}
                                                                autoComplete="false"
                                                                isValid={touched.email && !errors.email}
                                                                isInvalid={!!errors.email}
                                                                value={values.email}
                                                                className='b-r-10 form-control-solid custom-padding'
                                                                maxLength="255"
                                                                placeholder="Enter Email"
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors?.email}</Form.Control.Feedback>

                                                        </Form.Group>
                                                    </div>

                                                    <div className='col-lg-4'>

                                                        <Form.Label className='fw-bold'>Groups</Form.Label>
                                                        <Field
                                                            className="custom-select"
                                                            name="groups"
                                                            options={options}
                                                            components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} /> }}
                                                            onChange={(e) => { handleChangeSelect(e); setFieldValue('groups', e.map((el) => el.value)) }}
                                                            component={Select}
                                                            placeholder="Select multi Groups"
                                                            isMulti={true}
                                                            onFocus={() => setIsFocusedClient(true)}
                                                            onBlur={() => setIsFocusedClient(false)}
                                                            styles={{
                                                                container: (styles, state) => ({
                                                                    ...styles,
                                                                    borderRadius: "9px",
                                                                }),
                                                                control: (styles, state) => ({
                                                                    ...styles,
                                                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                    borderStyle: "none", boxShadow: "none",
                                                                    borderRadius: "10px",
                                                                    maxHeight: state.isFocused ? "auto" : "30px",
                                                                }),
                                                                indicatorSeparator: styles => ({
                                                                    ...styles, width: "0px", minHeight: "20px"
                                                                }),

                                                                valueContainer: (styles, state) => ({
                                                                    ...styles,
                                                                    maxHeight: isFocusedClient ? "auto" : "30px",
                                                                }),
                                                            }}
                                                        />
                                                        {/* <Form.Control.Feedback type="invalid">Select Groups</Form.Control.Feedback> */}
                                                        {errors?.groups &&
                                                            <span className='custome-text-danger text-danger'>{errors?.groups}</span>
                                                        }
                                                    </div>
                                                    <div className='col-lg-4'>

                                                        <div className="">
                                                            <Form.Label className='fw-bold'>Is supervisor dashboard</Form.Label>
                                                            <Form.Check
                                                                type="switch"
                                                                // reverse
                                                                onChange={() => setFieldValue('is_admin_dashboard', !values?.is_admin_dashboard)}
                                                                checked={values?.is_admin_dashboard}
                                                                isInvalid={
                                                                    errors?.is_admin_dashboard &&
                                                                    !!errors?.is_admin_dashboard
                                                                }
                                                                feedbackType="invalid"
                                                                feedback={
                                                                    errors?.is_admin_dashboard &&
                                                                    errors?.is_admin_dashboard
                                                                }

                                                                name="is_admin_dashboard"
                                                            // label='Is supervisor dashboard'
                                                            />
                                                        </div>

                                                    </div>


                                                </div>
                                                <div>
                                                    {state.error &&
                                                        <span className='text-danger'>{state.error_message}</span>}
                                                </div>
                                                <div className='d-flex flex-row justify-content-end'>
                                                    <div className=''>
                                                        <button
                                                            type='button'
                                                            className='btn btn-secondary btn-active-primary me-2'
                                                            onClick={() => { changeAllTitleFunction("Users", USERS) }}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>

                                                    <div className=''>
                                                        <button
                                                            type='submit'
                                                            disabled={!isValid}
                                                            className='btn btn-primary btnHover'
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </div>
                </section >


            </main >
        </React.Fragment >
    )
}

export default CreateUser