import React, { useMemo } from 'react'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../../Layout/admin/Includes/Footer';
import Master from '../../../Layout/admin/Master/index';
import { getDashboardLoggedStaffList, getUserDashboardDailyData } from '../../../Redux/dashboard/action';
import powerbtn from '../../../assets/img/power-button.svg';
import DataTable from 'react-data-table-component';
import { GET_USER_DASHBOARD_DAILY_DATA } from '../../../api';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import styled from 'styled-components/macro';
import Flatpickr from "react-flatpickr";
import Select from 'react-select'
import "flatpickr/dist/themes/material_blue.css";
import { today_date } from './functions';
import { GrUserAdmin } from 'react-icons/gr';
import { FiUsers } from 'react-icons/fi';
import { RiUserSettingsLine } from 'react-icons/ri';
import PieChart from "./componenets/PieChart"
import { getDashboardGridData, getDashboardUserData } from '../../../Redux/auth/action';
import TimeLine from './componenets/TimeLine';
import DashboardDataTable from './DashboardDataTable';
import UserDashboardCard from './UserDashboardCard';
import AdminDashboardCard from './AdminDashboardCard';


import bottile from "../../../assets/img/wish.png";
import peoples from "../../../assets/img/peoples.svg";
import img__avatar from "../../../assets/img/img_avatar.png";
import { toCamelCase } from "./functions"
import OwlCarousel from 'react-owl-carousel';
import { Tooltip } from '@mui/material';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import animationData from '../../../assets/animations/birthday1.json'; // Replace with the path to your animation.json file
import animationData1 from '../../../assets/animations/birthday2.json'; // Replace with the path to your animation.json file
import Lottie from 'lottie-react';
import Confetti from 'react-confetti'
import DashBoardSkeleton from './componenets/DashBoardSkeleton';


const Dashboard = () => {



    const [isBirthdaySeen, setIsBirthdaySeen] = useState(localStorage.getItem('is_birthday_seen') == 'true' ? true : false);
    const [isBirthdayToday, setIsBirthdayToday] = useState(false);
    const [isConfilty, setConfilty] = useState(false);
    const [dashboardData, setDashboardData] = useState({});
    const [dashboardDataLoader, setDashboardDataLoader] = useState(false);
    const [shiftOption, setShiftOption] = useState([{ value: 'morning', label: 'MORNING' }]);
    const [update, setUpdate] = useState(false)
    const user = JSON.parse(localStorage.getItem("user"));

    const handleChangeShift = (e) => {
        localStorage.setItem('shift', JSON.stringify(e));
        setShiftOption(e)
        setUpdate(!update)
    }

    useEffect(() => {
        const shift = localStorage.getItem('shift', '') ? JSON.parse(localStorage.getItem('shift', '')) : '';
        setDashboardDataLoader(true)
        getDashboardGridData({ type: shift?.value ? shift?.value : 'morning' })((response) => {
            if (response?.status && response.status_code == 200) {
                setIsBirthdayToday(response?.data?.date_of_birth?.some(data => data.username === user?.username))
                setConfilty(response?.data?.date_of_birth?.some(data => data.username === user?.username))
                setDashboardDataLoader(false)
                setDashboardData(response?.data)
            }
            else {

            }
        })
        if (shift) {
            setShiftOption(shift)
        }
        setTimeout(() => {
            if (!localStorage.getItem('permission_reload')) {
                // parent.location.reload();
                localStorage.setItem('permission_reload', true)
            }
        }, 200);
    }, [update]);

    useEffect(() => {
        if (isBirthdayToday) {
            setTimeout(() => {
                setIsBirthdayToday(false)
                localStorage.setItem('is_birthday_seen', true)
            }, 4000);
        }
    }, [isBirthdayToday]);


    const currentDate = new Date();

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = currentDate.getDay();

    // Get the current time in hours (12-hour format)
    let currentHours = currentDate.getHours();
    // Determine whether it's morning, evening, or night
    let timeOfDay1;
    if (currentHours >= 5 && currentHours < 12) {
        timeOfDay1 = "Morning";
    } else if (currentHours >= 12 && currentHours < 17) {
        timeOfDay1 = "Afternoon";
    } else if (currentHours >= 17 && currentHours < 21) {
        timeOfDay1 = "Evening";
    } else {
        timeOfDay1 = "Day";

    }

    const timeOfDay = currentHours >= 12 ? "PM" : "AM";

    if (currentHours > 12) {
        currentHours -= 12;
    } else if (currentHours === 0) {
        currentHours = 12; // 0 should be displayed as 12 AM
    }

    // Get the current time in minutes
    const currentMinutes = currentDate.getMinutes();


    // Format the date string
    const formattedDate = `${getDayName(dayOfWeek)}, ${padZero(currentHours)}:${padZero(currentMinutes)} ${timeOfDay}`;


    // Function to get the day name from the day index
    function getDayName(dayIndex) {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return daysOfWeek[dayIndex];
    }

    // Function to pad single digits with a leading zero
    function padZero(number) {
        return number < 10 ? `0${number}` : number;
    }


    return (
        <React.Fragment>
            <Master />
            <main id="main" className="main">

                {/* <div className="pagetitle tab-fixed-header">
                    <h1>Dashboard</h1>
                    
                </div> */}

                <h1 id='page-title' className='d-none' >Dashboard</h1>
                {isConfilty &&
                    <Confetti
                        height={900}
                        numberOfPieces={50}
                    />
                }
                <div style={dashboardData?.date_of_birth?.length > 0 ? { display: "grid" } : { display: 'block' }} className='twocard-grid mb-4'>
                    <div className='p-3  card  left-birthcard-card-contaner'>
                        <span>{formattedDate}</span>
                        <h4 className='mt-1 mb-1'>Fantastic {timeOfDay1}, {user?.name}!</h4>
                        <span>{dashboardData?.daily_quotes}</span>
                    </div>
                    {isBirthdayToday && !isBirthdaySeen ?
                        <div style={dashboardData?.date_of_birth?.length > 0 ? {
                            display: "grid",
                            // alignContent: 'center',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            // justifyItems: 'center',
                        } : { display: 'none' }} className='p-3 card right-birthcard-card-contaner current-birthday-card'>
                            <div className='ballon-lottie-container'>
                                <Lottie animationData={animationData} width={900} height={300} />
                            </div>
                            <Confetti
                                height={200}
                                numberOfPieces={50}
                            />
                            {dashboardData?.date_of_birth && dashboardData?.date_of_birth?.length > 0 &&
                                <div className='scroll-birth-conatiner d-flex'>
                                    <div className='right-profile-card-contaner scroll-content'>

                                        <div className='left-birthcard-card-contaner'>
                                            <h4 className='mt-1 mb-1'>Happy Birthday To You...</h4>
                                            <span>{user?.username}</span>
                                            <p className='mt-2'>Wishing you a year filled with joy, love, endless possibilities. Enjoy your special day to the fullest!</p>
                                        </div>
                                        <div className='people-img-container'>
                                            <div className='left-bottile-continer'>

                                                <Lottie animationData={animationData1} width={300} height={300} />

                                            </div>
                                            <img alt='left-img' src={peoples}></img>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div style={dashboardData?.date_of_birth?.length > 0 ? {
                            display: "grid",
                            // alignContent: 'center',
                            // justifyContent: 'center',
                            alignItems: 'center',
                            justifyItems: 'center',
                        } : { display: 'none' }} className='p-3 card right-birthcard-card-contaner'>
                            <div className='left-bottile-continer'>
                                <div className='left-bottile-image'>
                                    <img alt='left-img' src={bottile}></img>
                                </div>
                                <p className='mt-2' >Wishes</p>
                            </div>
                            {dashboardData?.date_of_birth && dashboardData?.date_of_birth?.length > 0 &&
                                <div className='scroll-birth-conatiner d-flex'>
                                    <div className='right-profile-card-contaner scroll-content'>
                                        <OwlCarousel
                                            items={4}
                                            className="owl-theme"
                                            nav={false}
                                            dots={false}
                                            autoplay={true}
                                            autoplayTimeout={5000}
                                            margin={4}
                                        >
                                            {dashboardData?.date_of_birth?.map((item, index) =>
                                                <div className='signle-card-container'>
                                                    <div className='left-img-avatar'>
                                                        <img src={img__avatar} />
                                                    </div>
                                                    <div className='right-profile'>
                                                        <Tooltip disableInteractive title={item?.name} placement='top' arrow>
                                                            <p className='main text-truncate' style={{ width: "150px" }}>{item.name}</p>
                                                        </Tooltip>
                                                        <p>It's birthday</p>

                                                    </div>
                                                </div>
                                            )}
                                        </OwlCarousel>

                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
                {!dashboardDataLoader ?
                    <div className='row mb-5'>

                        {dashboardData?.is_superuser_dashboard ?
                            <AdminDashboardCard dashboardData={dashboardData} dashboardDataLoader={dashboardDataLoader} />
                            :
                            <UserDashboardCard shiftOption={shiftOption} handleChangeShift={handleChangeShift} dashboardData={dashboardData} />
                        }
                        {dashboardData?.is_superuser_dashboard &&
                            <div style={{ borderRadius: '10px' }} className='dashboard-main-table-container mt-4'>
                                {/* <h4 className='mb-2'>Report Table:</h4> */}
                                <DashboardDataTable />
                            </div>
                        }
                    </div> :
                    <DashBoardSkeleton />
                }


            </main>
        </React.Fragment >
    )
}

export default Dashboard
