import React, { useEffect, useState } from 'react'
import AdminMaster from './../../Layout/admin/Master/index';
import { Toast, ToastContainer, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { getAllSettings, updateSettings, getAllTimezone } from '../../Redux/user/action';
import { useNavigate } from 'react-router-dom';
import { getSelectedTimezone } from '../../Redux/user/action';
import { changeAllTitleFunction } from '../DashBoard/Dashboard/functions';
import { DASHBOARD, SETTINGS } from '../../routeNames';

function Settings() {
    const [settings, setSettings] = useState([]);
    const [timezoneAllowedData, setTimezoneAllowedData] = useState([]);
    const [isFocusedTimezone, setIsFocusedTimezone] = useState(false);
    const [fullData, setFullData] = useState([]);
    const [timezoneFullData, setTimezoneFullData] = useState([]);
    const [timeValues, setTimeValue] = useState([]);
    const [toaster, setToaster] = useState(false)
    const [selectedTimezoneOptions, setSelectedTimezoneOptions] = React.useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        getAllSettings()((response) => {
            setFullData(response?.data)
            setSettings(response?.data?.Settings)
            const timzonse = response?.data?.Settings.find(timezone => timezone.key_name == "user_timezone");
            setTimeValue(timzonse?.value)

            let input = "value";
            const settings = response?.data?.Settings;
            const userTimezoneIndex = settings?.findIndex(item => item.key_name === "user_timezone");

            let selectIds = [];
            settings[userTimezoneIndex][input]?.map((option) => {

                selectIds.push(option?.value);

            })

            settings[userTimezoneIndex][input] = JSON.stringify(selectIds);

            formik.setValues({
                ...formik.values,
                data: settings,
            })
        });
        getAllTimezone()((response) => {
            setTimezoneAllowedData(response?.data)
            const data = [];
            response?.data?.results?.map((item) => {
                data.push({ value: item.id, label: item.label })
            })

            setTimezoneFullData(data)
            //setTimezoneSettings(response?.data?.results)
            //formik.setValues({ ...formik.values, data: response?.data?.Settings })

        })


    }, []);
    const validationSchema = Yup.object().shape({
        data: Yup.array()
            .required('Required'),
    });

    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })

    const formik = useFormik({
        initialValues: {
            data: [{
                keyname: "",
                value: "",
                label: "",
            }],


        },
        validationSchema,
        onSubmit: (values) => {
            __formSubmit(values)
            //navigate(-1)
            // window.location.reload()
        },
    });
    const __formSubmit = (values) => {

        updateSettings(values?.data)((response) => {
            if (response && response.status) {
                setToaster('success')
                const timer = setTimeout(() => {
                    setToaster(false);
                }, 2000);
                window.location.reload()
                return () => clearTimeout(timer);
            }
            else if (response?.response?.data?.errors && response?.response?.data?.errors?.number_of_bottom_tabs_allowed_per_user) {
                setToastMessage({
                    status: true,
                    message: response?.response?.data?.errors?.number_of_bottom_tabs_allowed_per_user[0],
                    type: "danger",
                })
            }
            else {

                // setToaster('error')


                setToastMessage({
                    status: true,
                    message: response?.message,
                    type: "danger",
                })


                // const timer = setTimeout(() => {
                //     setToaster(false)
                // }, 2000);

                return () => clearTimeout(timer);
            }

        });
    };

   

    const handleInputChange = (value, index, input) => {
        settings[index][input] = value.target.value;
        formik.setValues({ ...formik.values, data: settings })
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    const handleTimezoneChange = (e, index, input) => {

        let selectIds = [];
        let selectOptions = [];
        e.map((option) => {
            selectIds.push(option?.value);
            selectOptions.push({ value: option?.value, label: option?.label });
        })
        settings[index][input] = JSON.stringify(selectIds);
        formik.setValues({ ...formik.values, data: settings });
        setTimeValue(selectOptions);



    }


    const SelectComponent = ({ index, value }) => {
        let options = []
        let dropdown_array = value.dropdown_array
        for (const key in fullData) {
            if (key == dropdown_array) {
                options = fullData[key]
            }
        }
        return (
            <Form.Control as="select"
                className='form-control form-control-solid b-r-10'
                value={formik.values.data[index].value}

                onChange={(e) => handleInputChange(e, index, 'value')}
            >
                <option>Select an Option</option>
                {options && options?.length > 0 && options?.map((option, index) => (
                    <option value={option?.id}>{option?.name}</option>
                ))}
            </Form.Control>
        )
    }

    const ReactSelectComponent = ({ index, value }) => {

        return (

            <Select
                isMulti
                classNamePrefix='select'
                value={timeValues}
                options={timezoneFullData}
                onChange={(e) => handleTimezoneChange(e, index, 'value')}
                onFocus={() => { setIsFocusedTimezone(true);  }}
                onBlur={() => setIsFocusedTimezone(false)}
                styles={{
                    container: (styles, state) => ({
                        ...styles,
                        borderRadius: "9px",
                    }),

                }}
            />

        )
    }
    return (
        <React.Fragment>
            <React.Fragment>
                <AdminMaster ToastMessage={toaster} />
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                        {
                            status: false,
                            message: "",
                            type: "",
                        }

                    )}
                        show={ToastMessage?.status} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                    </Toast>

                </ToastContainer>
                <main id="main" className="main">

                    <div className="pagetitle tab-fixed-header ">
                        <h1 id='page-title'>Settings</h1>

                    </div>
                    <section className="section mt-5">
                        <div className="row align-items-top  gap-2 gap-md-5">
                            <div className="col-lg-12">
                                <div className="card mb-5 mb-xl-8 pt-3">
                                    <div className="card-body">
                                        <div>
                                            <Form onSubmit={formik.handleSubmit}>
                                                {settings?.map((value, index) =>
                                                    <div key={index} className='row d-flex align-items-start'>
                                                        <Form.Group className='col-lg-3 my-2' >
                                                            {index === 0 &&
                                                                <Form.Label className='fw-bold' >Settings Name</Form.Label>
                                                            }
                                                            <div>
                                                                <Form.Label className=' mt-2' >{value.label}</Form.Label>
                                                            </div>

                                                        </Form.Group>
                                                        {/* <Form.Group className='col-lg-3 my-2' >
                                                            {index === 0 &&
                                                                <Form.Label className='fw-bold' >Settings Label</Form.Label>
                                                            }
                                                            <Form.Control
                                                                type="text"
                                                                name="label"
                                                                className=' b-r-10 form-control-solid mt-2'
                                                                value={formik.values.data[index].label}
                                                                onChange={(e) => handleInputChange(e, index, 'label')}
                                                                // isInvalid={!!formik.errors.label}
                                                                // isValid={formik?.touched?.label && !formik?.errors?.label}
                                                                autoComplete="false"
                                                                maxLength="255"
                                                                required
                                                                placeholder="Enter Key Name Label"
                                                            />
                                                            <Form.Control.Feedback type='invalid'>{formik?.errors?.label}</Form.Control.Feedback>
                                                        </Form.Group> */}
                                                        <Form.Group className='col-lg-3 my-2' >
                                                            {index === 0 &&
                                                                <Form.Label className='fw-bold' >Settings Value</Form.Label>
                                                            }
                                                            {value.type == 5 ?
                                                                <div className=''>
                                                                    <ReactSelectComponent index={index} value={value} />
                                                                </div> :
                                                                value.type == 4 ?
                                                                    <SelectComponent index={index} value={value} />

                                                                    :

                                                                    <Form.Control
                                                                        type="text"
                                                                        name="value"
                                                                        className='b-r-10 form-control-solid mt-2'
                                                                        value={formik.values.data[index].value}
                                                                        onChange={(e) => handleInputChange(e, index, 'value')}
                                                                        // isInvalid={!!formik.errors.value}
                                                                        // isValid={formik?.touched?.value && !formik?.errors?.value}
                                                                        autoComplete="false"
                                                                        maxLength="255"
                                                                        required
                                                                    //placeholder="Enter Value"
                                                                    />
                                                            }
                                                            {/* {index === 0 &&
                                                                <Form.Label className='fw-bold' >Settings Value</Form.Label>
                                                            } */}
                                                            {/* {value.type == 5 ?
                                                                <div className='mt-4'>
                                                                    <ReactSelectComponent index={index} value={value} />

                                                                </div> : ''

                                                            } */}
                                                            <Form.Control.Feedback type='invalid'>{formik?.errors?.value}</Form.Control.Feedback>
                                                        </Form.Group>

                                                    </div>
                                                )}
                                                {checkUserPermission('edit_system_settings') &&
                                                    <div className='d-flex flex-row justify-content-end mt-4'>
                                                        <div>
                                                            <button
                                                                type='button'
                                                                className='btn btn-secondary btn-active-primary me-2'
                                                                onClick={() => { changeAllTitleFunction('Dashboard', DASHBOARD) }}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <Button type="submit" className='btn btn-light btn-active-primary' variant="primary">Update</Button>
                                                        </div>

                                                    </div>
                                                }
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </React.Fragment>
        </React.Fragment>
    )
}

export default Settings
