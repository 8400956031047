import {
    ASSIGN_FROM_QA_URL,
    CHECK_EXCLUDED_PAYER_URL,
    GET_CORRESPONDENCE_CATEGORY_DROPDOWN,
    GET_EDIT_WORK_URL,
    GET_PAYEE_LOOKUP_TABLE_URL,
    GET_PAYER_LOOKUP_TABLE_URL,
    GET_SEARCH_837_FILE_ALL_URL,
    JOB_STAFF_STATUS_URL,
    POST_CREATE_UPDATE_835_LITE_URL,
    POST_CREATE_UPDATE_CORRES_URL,
    POST_CREATE_UPDATE_PATIENT_PAY_URL,
    POST_CREATE_UPDATE_PAYMENT835_URL,
    POST_GRID_EXIT_URL,
    POST_VERIFY_PAYER_RC_URL,
    REJECT_FILE_URL,
    REJECT_JOB_URL,
    POST_GRID_JOB_TIMEOUT,
    GET_SUB_JOB_USER_DATA,
    GET_V2_QA_REVIEW_URL
} from "../../api";
import axiosInstance from './../../axiosInstance';
import { GET_SEARCH_837_FILE_URL } from './../../api/index';
import { on } from "events";


// payer lookup 
export const searchPayerLookup = (props) => onResponse => {
    try {
        axiosInstance.get(GET_PAYER_LOOKUP_TABLE_URL + "?search=" + props.search + "&client=" + props.client)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

// payee lookup 
export const searchPayeeLookup = (props) => onResponse => {
    try {
        axiosInstance.get(GET_PAYEE_LOOKUP_TABLE_URL + "?search=" + props.search + "&client=" + props.client)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const search837file = (props) => onResponse => {
    try {
        axiosInstance.get(GET_SEARCH_837_FILE_URL + "?search1=" + props.search1 + "&client_id=" + props.client_id + "&filter_type=" + props.filter_type)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const search837fileAll = (id) => onResponse => {
    try {
        axiosInstance.get(GET_SEARCH_837_FILE_ALL_URL + "?id=" + id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createUpdate835Lite = (values) => onResponse => {
    try {
        axiosInstance.post(POST_CREATE_UPDATE_835_LITE_URL, values)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getCorrespondeceCategoryDropdown = () => onResponse => {
    try {
        axiosInstance.get(GET_CORRESPONDENCE_CATEGORY_DROPDOWN)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdateCorres = (data) => onResponse => {
    try {
        axiosInstance.post(POST_CREATE_UPDATE_CORRES_URL, data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdatePayment835 = (data) => onResponse => {
    try {
        axiosInstance.post(POST_CREATE_UPDATE_PAYMENT835_URL, data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createOrUpdatePatientPay = (props) => onResponse => {
    try {
        axiosInstance.post(POST_CREATE_UPDATE_PATIENT_PAY_URL, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getEditGridData = (job) => onResponse => {
    try {
        axiosInstance.get(GET_EDIT_WORK_URL + "?job=" + job)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const verifyPayerRc = (reasoncodes, payer_id) => onResponse => {
    try {
        axiosInstance.post(POST_VERIFY_PAYER_RC_URL, { reasoncodes, payer_id })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const rejectFile = (job, reason, staff, description, qdx_reasons) => onResponse => {
    try {
        axiosInstance.post(REJECT_FILE_URL, { job, reason, staff, description, qdx_reasons })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const rejectJob = (pk) => onResponse => {
    try {
        axiosInstance.post(REJECT_JOB_URL, { pk })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const checkExcludedPayer = (job_id, payer_id) => onResponse => {
    try {
        axiosInstance.get(CHECK_EXCLUDED_PAYER_URL + '?job_id=' + job_id + '&payer_id=' + payer_id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getJobStaffStatus = (job_id) => onResponse => {
    try {
        axiosInstance.get(JOB_STAFF_STATUS_URL + '?job=' + job_id)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const assignJobFromQa = (props) => onResponse => {
    try {
        axiosInstance.post(ASSIGN_FROM_QA_URL, props.formData)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};
export const assignJobFromQaV2 = (data) => onResponse => {
    try {
        axiosInstance.post(GET_V2_QA_REVIEW_URL, data)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });
    } catch (error) {

    }

};
export const exitGrid = (job, work_type,session_timeout = false) => onResponse => {
    try {
        axiosInstance.post(POST_GRID_EXIT_URL, { job, work_type ,session_timeout})
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const jobTimeout = (job) => onResponse => {
    try {
        axiosInstance.post(POST_GRID_JOB_TIMEOUT, { job })
            .then((response) => {
                onResponse(response?.data)
            }).catch((err) => {
                onResponse(err)
            })
    } catch (error) {

    }
};
export const getSubJobUserData = (job) => onResponse => {
    try {
        axiosInstance.get(GET_SUB_JOB_USER_DATA + "?job=" + job)
            .then((response) => {
                onResponse(response?.data)
            }).catch((err) => {
                onResponse(err)
            })
    } catch (error) {

    }
};
