import React, { useState } from "react";
import DataTable from "react-data-table-component";
import datatableCustomStyles from "../../../Layout/Elements/DatatableCustomStyles";
import { GET_835_LIST, GET_CORRESPONDANCE } from "../../../routeNames";
import styled from 'styled-components/macro';
import AdminMaster from "../../../Layout/admin/Master";
import { useMemo } from "react";
import { getAcknowledgementListData, sendAcknowledgementFile } from "../../../Redux/outputSchema/action";
import { useEffect } from "react";
import { GET_835_LIST_URL } from "../../../api";
import axiosInstance from "../../../axiosInstance";
import { Button, Form, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import { useFormik } from "formik";
import { DateRangePicker } from "rsuite";
import { BiReset, BiSearch } from "react-icons/bi";
import { getAllClientDropdown } from "../../../Redux/client/action";
import "../../../assets/css/dashboard.css"
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { TbGridDots } from "react-icons/tb";
import { HiChevronDown } from "react-icons/hi";
import Select, { components, DropdownIndicatorProps } from 'react-select';
import SweetAlert from "react-bootstrap-sweetalert";
import { Tooltip } from '@mui/material';
import { DownloadJsonUrl, DownloadXmlUrl } from "../Dashboard/functions";
import Swal from 'sweetalert2';


const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Filter By Name"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);

const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;
function ListAcknowledgement(params) {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [pageno, setPageNo] = useState(1);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(100);
    const [filterText, setFilterText] = useState('');
    const [colorRow, setColorRow] = React.useState({});
    const [data, setData] = useState({})
    const [limit, setLimit] = useState(100)
    const [searchedData, setSearchedData] = useState({
        search: "",
        client: "",
        created_date: "",
        deposit_date_range: "",
    })
    const [toaster, setToaster] = useState(false)

    //const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];
    const handleClear = () => {
        if (filterText) {
            //setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            fetchData(1, limit)
        }
    };
    const handleSearchSplits = async (e) => {
        setFilterText(e.target.value)
        let data = e.target.value ? e.target.value : '';
        fetchData(page, newPerPage, searchFormik?.values?.search, selectValue, datetime);

    }

    const handlePerRowsChange = async (newPerPage, page) => {

        setLoading(true);
        // const response = await axiosInstance.get(GET_835_LIST_URL + "?page=" + page + "&limit=" + newPerPage);
        // // console.log(response);
        // setData(response?.data?.data);
        setLimit(newPerPage);
        setPageNo(1)
        // setTotalRows(response?.data?.data?.count);

        fetchData(1, newPerPage, searchedData?.search, searchedData?.client, searchedData?.created_date);
        setLoading(false);
        handleResetDataTable()

    };

    const handlePageChange = page => {
        setPageNo(page)
        fetchData(page, limit, searchedData?.search, searchedData?.client, searchedData?.created_date);
    };
    const [clientList, setClientList] = useState();
    const [selectValue, setSelectValue] = useState([])

    useEffect(() => {
        fetchData(1, limit)
        // console.log(response?.data?.count)
        getAllClientDropdown()((response) => {

            setClientList(response?.data)
        })
    }, []);


    const fetchData = (page, limit, search, client, deposit_date_range) => {
        setLoading(true)
        getAcknowledgementListData({ page, limit, search, client, deposit_date_range })((response) => {
            setData(response?.data);
            setLimit(limit);
            setPageNo(page)
            setTotalRows(response?.data?.count);
            setLoading(false);
            //console.log(response);
        });

    }

    const onButtonClick = (file) => {
        // using Java Script method to get PDF file
        const fileURL = file.replace('http://', 'https://');
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();
    }
    const handleDblClickCopy = async (filename) => {
        navigator.clipboard.writeText(filename)

    };

    const handleRowClickCheck = (row) => {

        data?.results.map((item, index) => {
            if (row?.pk == item.pk) {
                setColorRow(row)
            }
        })
    }

    const columns = useMemo(
        () => [
            {
                id: 'S.NO',
                name: 'S.NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                width: "40px",
                //grow: 0.1,

            },
            {
                id: 'CLIENT NAME',
                name: 'CLIENT NAME',
                selector: row => row?.client_name,
                cell: (row) => <div className="" >
                    {row?.client_name &&

                        <div className='overflow-hidden d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: "120px" }}>
                            <Tooltip disableInteractive title={row?.client_name} placement='top' arrow>
                                <span className='text-truncate' > {row?.client_name}</span>
                            </Tooltip>
                        </div>

                    }

                </div>,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                minWidth: "130px",
                //grow: 0.1,
            },
            {
                id: 'ACKNOWLEDGMENT TYPE',
                name: 'ACKNOWLEDGMENT TYPE',
                selector: row => row?.acknowledgment_type,
                cell: (row) => <span> {row?.acknowledgment_type}</span>,
                sortable: true,
                left: true,
                wrap: true,
                minWidth: "200px",
                grow: 0.3,
                reorder: true,
            },
            {
                id: 'STATUS',
                name: 'STATUS',
                selector: row => row?.status,
                cell: (row) => <div>
                    {row?.status &&
                        <span className=''> {row?.status}</span>
                    }

                </div>,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                width: "200px",
                //grow: 0.3,
            },
            {
                id: 'CREATED DATE',
                name: 'CREATED DATE',
                selector: row => row?.created_date,
                cell: (row) => <div>
                    {row?.created_date &&
                        <span className=''> {row?.created_date}</span>
                    }

                </div>,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                width: "200px",
                //grow: 0.3,
            },
            {
                id: 'SENT STATUS',
                name: 'SENT STATUS',
                selector: row => row?.is_send_ack_file,
                cell: (row) => <div>
                    <span className=''> {row?.is_send_ack_file == "true" ? "True" : "False"}</span>


                </div>,
                sortable: true,
                left: true,
                wrap: true,
                reorder: true,
                width: "200px",
                //grow: 0.3,
            },




            {
                name: 'ACTIONS',
                cell: (row) => <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                    <div onClick={(e) => DownloadXmlUrl(e, row?.ack_file)} role="button"><i className="bi bi-download "></i> </div>
                    {checkUserPermission('send_acknowledgement_file') &&
                        <button onClick={() => handleSendAcknowledgment(row?.pk)} className={row?.edi_validation == "False" ? "btn btn-danger btn-sm" : "btn btn-outline-secondary btn-sm  "} style={{ fontSize: "0.7em" }}>
                            RE SEND
                        </button>
                    }
                </div>,
                // //sortable: true,
                right: true,
                // wrap: true,
                width: '200px',
                button: false
            },
        ],
        [pageno],
    );
    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster('')
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [toaster])
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })

        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const handleSendAcknowledgment = (id) => {
        Swal.fire({
            icon: 'question',
            confirmButtonColor: '#6ba2b6',
            confirmButtonText: 'YES',
            showCancelButton: true,
            allowOutsideClick: false,
            backdrop: true,
            title: 'ARE YOU SURE',
            customClass: {
                title: 'swal-titleText fs-5'
            }
        }).then((result) => {
            if (result?.isConfirmed) {

                sendAcknowledgementFile(id)((response) => {
                    if (response?.status) {
                        setToaster('success')
                    }
                    else {
                        setToaster('error')

                    }
                })
            }
            else {

            }
        })


    };
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.pk == row.pk ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };


    const searchFormik = useFormik({
        initialValues: {
            search: "",
            client: "",
            created_date: "",
            deposit_date_range: "",

        },
        onSubmit: (values) => {
            searchData(values)

            setSearchedData({
                search: values?.search ? values?.search : "",
                client: values?.client ? values?.client : "",
                created_date: values?.created_date ? values?.created_date : "",
                deposit_date_range: values?.deposit_date_range ? values?.deposit_date_range : "",
            })
            handleResetDataTable()
        }
    })

    const [datetime, setDateTime] = useState([]);

    const handleOnDateTimeChange = ({ name, value }) => {
        let created_date = ""
        if (value !== null) {
            const datefrom = moment(value['0']).format('MM/DD/YYYY')
            const dateto = moment(value['1']).format('MM/DD/YYYY')

            created_date = datefrom + " - " + dateto
        }

        setDateTime(created_date)

        searchFormik.setValues({ ...searchFormik.values, created_date, deposit_date_range: value ?? "" })


    }

    const searchData = ({ search, client, deposit_date_range }) => {
        fetchData(1, limit, search, selectValue, datetime)
    }

    const handleOnSelectClientChange = async (e, name) => {
        let selectedId = []
        let selectedOptons = []

        e.map((option) => {
            selectedId.push(option.value)
            selectedOptons.push({ option })
        })

        searchFormik.setValues({ ...searchFormik.values, [name]: selectedId })
        setSelectValue(selectedId)

    }


    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {searchFormik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };
    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }
    const [isFocusedClient, setIsFocusedClient] = useState(false);

    const [isSwal, setIsSwal] = useState({
        status: false,
        data: "",
    });

    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} />
            <main id="main" className="main">

                <div className="pagetitle tab-fixed-header">
                    <h1 id='page-title'>Acknowledgement</h1>
                </div>

                <section className="section mt-5 ">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8 ">

                                <Form onSubmit={searchFormik.handleSubmit} onReset={() => window.location.reload()}>
                                    <div className=" ">
                                        <div className="px-3 py-3 d-flex flex-row justify-content-start gap-2 align-items-center flex-wrap">
                                            {/* <div className="">
                                                <Form.Control
                                                    className="form-control-solid b-r-10 outputSchema-search"
                                                    onChange={searchFormik.handleChange}
                                                    name="search"
                                                    type="search"
                                                    style={{ width: '220px', fontSize: "14px" }}
                                                    placeholder="Filename / Check Number"
                                                    value={searchFormik.values?.search}
                                                />
                                            </div> */}

                                            <div>
                                                <Select
                                                    isMulti
                                                    tabSelectsValue={false}
                                                    closeMenuOnSelect={false}
                                                    options={clientList}
                                                    className="select-search-835-list"
                                                    classNamePrefix="select"
                                                    placeholder="Select Client"
                                                    // values={selectValue}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client" /> }}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={300}
                                                    isClearable
                                                    name="client"
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    onChange={(e) => handleOnSelectClientChange(e, 'client')}
                                                    defaultValue={searchFormik?.values?.client}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px", minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),
                                                    }} />
                                            </div>

                                            <div className="correspondenceDatePicker">
                                                <Stack direction="column" spacing={8} alignItems="flex-start">

                                                    <DateRangePicker
                                                        // style={{ backgroundColor: "#000" }}
                                                        ranges={predefinedBottomRanges}
                                                        cleanable={true}
                                                        placement="auto"
                                                        appearance="default" format={"MM/dd/yyyy"}
                                                        className="form-date-control form-control-solid b-r-1 text-dark"
                                                        placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                                                        name="deposit_date_range"
                                                        onChange={(e) => { handleOnDateTimeChange({ name: 'deposit_date_range', value: e }) }} value={searchFormik?.values?.deposit_date_range} />
                                                </Stack>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary" type="submit">
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiSearch />
                                                        </span>

                                                    </Button>
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                    <Button className="btn btn-light btn-active-primary " type='reset' >
                                                        <span className="svg-icon svg-icon-3">
                                                            <BiReset />
                                                        </span>

                                                    </Button>
                                                </Tooltip>

                                            </div>
                                        </div>
                                    </div>

                                </Form>


                                <div className="card-body" style={{ padding: "0px 2.25rem" }}>
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}
                                                    progressPending={loading}
                                                    data={data?.results}
                                                    columns={columns}
                                                    pointerOnHover={true}
                                                    pagination
                                                    paginationServer
                                                    onRowClicked={handleRowClickCheck}
                                                    highlightOnHover
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    // subHeaderComponent={subHeaderComponentMemo}
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}

                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </main>
        </React.Fragment>
    )
}
export default ListAcknowledgement




