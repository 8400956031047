import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import AdminMaster from '../../../Layout/admin/Master'
import { DOCUMENT_CLASSIFICATION, PARTNER_LIST } from '../../../routeNames';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { createDocument } from '../../../Redux/document/action';
import { getAllClientDropdown } from '../../../Redux/client/action';
import * as Yup from 'yup';
import { changeAllTitleFunction, getRedirectTabId } from '../Dashboard/functions';

const CreateUpdateDocument = (props) => {


    const [state, setState] = useState({
        showToast: false,
        error: false,
        error_message: "",
    })
    const [toaster, setToaster] = useState(null);
    const [message, setMessage] = useState(null);



    const formik = useFormik({
        initialValues: {
            client: "",
            classifications: [{
                classification: "",
            }],
        },

        validationSchema: Yup.object().shape({
            classifications: Yup.array().of(
                Yup.object().shape({
                    classification: Yup.string().required("Required"),
                }),
            ),



        }),


        onSubmit: (values, { setSubmitting }) => {
            const RedirectId = getRedirectTabId()
            createDocument(values?.client, values?.classifications)((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    setToaster('success')
                    setTimeout(() => {
                        changeAllTitleFunction("Document Classification", DOCUMENT_CLASSIFICATION, RedirectId)
                    }, 3000);

                } else {
                    formik.setErrors({
                        classification: response?.response?.data?.errors?.classification,
                    })
                    setToaster('error')
                    if (response?.response?.data?.errors?.classification && response?.response?.data?.errors?.classification[0]) {
                        setMessage(response?.response?.data?.errors?.classification[0])
                    }

                    setState({ error: true, error_message: response?.message });
                    const timer = setTimeout(() => {
                        setToaster(false)
                        setSubmitting(false)
                    }, 2000);

                }
            });

        },
    });

    const [clients, setClients] = useState([])

    useEffect(() => {

        getAllClientDropdown()((response) => {
            if (response?.status) {
                setClients(response?.data)
            }
        })

    }, []);


    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} message={message} />
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1>Create Document Classification</h1>

                </div>

                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">

                                    <FormikProvider value={formik}>


                                        <Form
                                            onSubmit={formik.handleSubmit}
                                        >
                                            <div className="mb-3 " >





                                                <div className='' style={{ width: '30rem' }}>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold'>Client</Form.Label>
                                                        <Form.Control as="select"
                                                            name='client'
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            autoComplete="false"
                                                            isValid={formik.touched.client && !!formik.errors.client}
                                                            isInvalid={!!formik.errors.client}
                                                            value={formik.values.client}
                                                            className='b-r-10 form-control-solid'
                                                            maxLength="255"
                                                            placeholder="client"
                                                        >
                                                            <option value="">Select An Option </option>
                                                            {clients && clients?.map((client, index) => (
                                                                <option key={index} value={client?.value}>{client?.label}</option>
                                                            ))}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">{formik.errors.client}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>
                                                <div className='pt-3 '>
                                                    <Form.Label className='fw-bold'>Classifications</Form.Label>

                                                </div>
                                                <FieldArray name="classifications"
                                                    render={(arrayHelpers) => (

                                                        <React.Fragment>
                                                            <div className='d-flex flex-row gap-2'>

                                                                <div className='d-flex flex-column justify-content-start  gap-2 flex-wrap' >
                                                                    {formik?.values?.classifications?.map((data, index) => (
                                                                        <React.Fragment>
                                                                            <div key={index} className='d-flex flex-row justify-content-start gap-3 flex-wrap' >
                                                                                <div style={{ width: '38rem' }}>
                                                                                    <div>
                                                                                        <Form.Group className="" >
                                                                                            <Form.Control
                                                                                                name={`classifications.[${index}].classification`}
                                                                                                type="text"
                                                                                                onChange={formik.handleChange}
                                                                                                autoComplete="false"
                                                                                                isInvalid={formik.errors && formik.errors.classifications
                                                                                                    && formik.errors.classifications[index] && !!formik.errors.classifications[index]?.classification}
                                                                                                value={data?.classification}
                                                                                                className='form-control b-r-10 form-control-solid'
                                                                                                maxLength="255"
                                                                                                placeholder="Classification"
                                                                                            />
                                                                                            <Form.Control.Feedback type="invalid">{formik.errors && formik.errors.classifications && formik.errors.classifications[index] && formik.errors.classifications[index]?.classification}</Form.Control.Feedback>

                                                                                        </Form.Group>
                                                                                    </div>
                                                                                </div>

                                                                                <div className=''>

                                                                                    <button
                                                                                        className='btn btn-danger'
                                                                                        type="button"
                                                                                        disabled={formik?.values?.classifications?.length == 1 ? true : false}
                                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                                    >
                                                                                        <i className='bi bi-trash'></i>
                                                                                    </button>
                                                                                </div>
                                                                                {formik?.values?.classifications?.length - 1 == index ?
                                                                                    <div className=''>

                                                                                        <button
                                                                                            className='btn  btn-primary '
                                                                                            style={{ backgroundColor: '#6ba2b6', borderColor: '#6ba2b6', height: '36px' }}
                                                                                            type="button"
                                                                                            onClick={() => arrayHelpers.push({
                                                                                                classification: "",
                                                                                            })}
                                                                                        >
                                                                                            +

                                                                                        </button>
                                                                                    </div>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </div>

                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                        </React.Fragment>
                                                    )
                                                    }
                                                />
                                            </div>

                                            <div className='d-flex flex-row justify-content-end'>

                                                <div className=''>
                                                    <button
                                                        type='button'
                                                        className='btn btn-secondary btn-active-primary me-2'
                                                        onClick={() => { changeAllTitleFunction("Document Classification", DOCUMENT_CLASSIFICATION) }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className=''>
                                                    <button
                                                        disabled={!formik.isValid || formik.isSubmitting}
                                                        type='submit'
                                                        className='btn btn-primary btnHover'
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </FormikProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment >
    )
}

export default CreateUpdateDocument