// import { useEffect, useState } from 'react';
import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components/macro";
import { getPositionPdf } from "./functions";

const Page = styled.div``;

const Container = styled.div`
  overflow-y: scroll;
  // height: 78vh;
  /* height: ${(props) =>
    props.dragType === "multy_check"
      ? "25vh"
      : props.dragType === "multy_common"
        ? "30vh"
        : props.dragType === "normal"
          ? "50vh"
          : "55vh"} */
  height: ${(props) =>
    props.dragType === "multy_check"
      ? "25vh"
      : props.dragType === "multy_common" || props.dragType === "multy_header"
        ? "30vh"
        : props.dragType === "normal"
          ? "50vh"
          : "80vh"};
`;

const BoxGroup = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;
const ParentDiv = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${(props) =>
    props.aspectRatio ? `${props.aspectRatio}%` : "100%"};
`;
const Card = styled.div`
  // width: ${(props) => props.initialWidthValue}%;
  width: ${(props) => props.initialWidthValue}px;

  position: relative;
  // width: ${(props) =>
    props.initialWidth ? `${props.initialWidth}px` : "unset"};
  // width: ${(props) => props.initialWidthValue}px;
  box-shadow: var(--rpv-thumbnail__container-box-shadow);
  padding: 5px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: transform 0.5s ease-in-out 0s;
`;
const PdfImg = styled.img`
  // transform: rotate(${(props) => props.rotateVal}deg);
  // transform: rotate(40deg);
  display: block;
  border: 1px solid;
  // margin: 0 auto;
  transition: transform 0.5s ease-in-out;
  width:100%;
`;
const CheckedMark = styled.div`
  font-size: 28px;
  color: white;
  position: absolute;
  font-family: "Denk One", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  background: #dc3545;
  top: 10px;
  // left: 10px;
  width:100%;
  opacity: 0.2;
`;
const Content = styled.div``;
const RotateBox = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    cursor: pointer;
  }
  width: 100%;
`;
const PageNumberContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .actual-page-index {
    font-weight: bold;
  }
`;

const Box = ({
  canvasDetails,
  dragType,
  box,
  index,
  showIndex,
  draggable,
  onDragStart,
  onDragOver,
  onDrop,
  setAddedElemnts,
  addedElemnts,
  swapDiv,
  setCheckPopup,
  setActiveCheckID,
  formik,
  finaldata,
  handleEditCheck,
  initialWidthValue,
  handleRotate
}) => {
  const [initialWidth, setInitialWidth] = useState(null);
  const [initialHeight, setInitialHeight] = useState(null);


  const handleClickPdf = (e) => {
    if (e.ctrlKey && !dragType) {
      let items = document.getElementsByClassName("single-splited-pdf");
      let selected_items = Array.from(items).map((i) => {
        return Number(i.getAttribute("data-index"));
      });

      let splitedFiles = [...addedElemnts.splitedFiles];
      let changedOrderedValue = [];
      splitedFiles.map((i) => {
        if (i.index === box.index) {
          if (i.is_check) {
            i.is_check = false;
          } else {
            i.is_check = true;
          }
        } else {
          i.is_check = false;
        }
      });
      selected_items.map((i, index) => {
        splitedFiles.filter((j) => {
          if (j.index === i) {
            changedOrderedValue.push(j);
          }
        });
      });
      // let selected_index = splitedFiles.findIndex(obj => obj.index === activecheckID);
      // splitedFiles[selected_index].is_check = true
      setAddedElemnts((prevState) => {
        return {
          ...prevState,
          splitedFiles: changedOrderedValue,
        };
      });
      setActiveCheckID(box.index);
    }
  };
  const [rotateValue, setRotateValue] = useState(box?.position ?? 0);
  const setLeftRotateValue = (val) => {
    const rotate = rotateValue - 90;

    setRotateValue(rotate);

    return rotate;
  };
  const setRightRotateValue = (val) => {
    const rotate = rotateValue + 90;
    setRotateValue(rotate);


    return rotate;
  };

  const isOddOrEven = (value) => {
    const value_index = value / 90;
    const remainder = value_index % 4;
    if (value_index % 2 === 0) {
      return "even";
    } else {
      return "odd";
    }
  };

  // const handleRotate = (e, index, type) => {
  //   if (!e.ctrlKey) {
  //     let items = document.getElementsByClassName("single-splited-pdf");
  //     let rotated_item = null;
  //     let rotate = "0";
  //     Array.from(items).map((i) => {
  //       if (Number(i.getAttribute("data-index")) === index) {
  //         rotated_item = i;
  //         return rotated_item;
  //       }
  //     });
  //     if (type === "left") {
  //       rotate = setLeftRotateValue(rotated_item.firstChild.style.transform);
  //     } else {
  //       rotate = setRightRotateValue(rotated_item.firstChild.style.transform);
  //     }

  //     rotated_item.firstChild.style.transform = `rotate(${String(rotate) + "deg"
  //       })`;
  //     rotated_item.setAttribute("data-position", getPositionPdf(rotate));
  //     // // Adjust parent div dimensions
  //     const parentDiv = rotated_item;
  //     const img = rotated_item.firstChild;
  //     const imgWidth = img.offsetWidth;
  //     const imgHeight = img.offsetHeight;


  //     if ([270, 90].includes(getPositionPdf(rotate))) {
  //       const new_width = parseInt(imgHeight) + 25;

  //       parentDiv.style.transition = 'width 0.5s ease-in-out';
  //     } else if ([0, 180].includes(getPositionPdf(rotate))) {
  //       parentDiv.style.transition = 'width 0.5s ease-in-out';

  //       const new_width = parseInt(initialWidth);

  //     }
  //     setAddedElemnts((prevState) => {
  //       return {
  //         ...prevState,
  //         splitedFiles: prevState.splitedFiles.map((item, ind) => ({
  //           ...item,
  //           position:
  //             ind === index ? getPositionPdf(rotate) : item?.position ?? 0,
  //         })),
  //       };
  //     });
  //   }
  // };

  const handleSingleRemove = (e, ind) => {
    if (!e.ctrlKey) {
      swapDiv(ind, true);
    }
  };
  var common_count = 0;
  var header_count = 0;
  var transaction_count = 0;
  if (box.is_multyTransactionCommon) {
    common_count += index;
  }
  if (box.is_multyTransactionHeader) {
    header_count += index;
  } else if (
    !box.is_multyTransactionCommon &&
    !box.is_multyTransactionCheck &&
    !box.is_multyTransactionHeader
  ) {
    transaction_count += index;
  }

  const parentRef = useRef(null);
  const imgRef = useRef(null);
  const SingPDFitems = document.getElementsByClassName("single-splited-pdf");

  useEffect(() => {
    if (parentRef.current && SingPDFitems) {
      let rotated_item = null;
      let rotate = "0";
      Array.from(SingPDFitems).map((i) => {


        if (Number(i.getAttribute("data-index")) === index) {
          rotated_item = i;
          return rotated_item;
        }
      });

      setInitialWidth(parentRef.current.offsetWidth);
      setInitialHeight(parentRef.current.offsetHeight - 25);

    }
  }, [parentRef, SingPDFitems]);

  return (
    <Card

      initialWidthValue={initialWidthValue
        // box?.position == "0" || box?.position == "180"
        //   ? initialWidthValue
        //   : box?.position == "270" || box?.position == "90"
        //     ? initialHeight
        //     : initialWidth

        // (box?.position == "270" || box?.position == "90") && canvasDetails[box.index]?.page_size ? "15" : "30"
      }
      ref={parentRef}
      className="single-splited-pdf"
      data-index={box.index}
      data-position={box?.position ?? 0}
      data-multi-check={box.is_multyTransactionCheck}
      data-multi-common={box.is_multyTransactionCommon}
      data-multi-header={box.is_multyTransactionHeader}
      data-order={index}
      data-ischeck={box.is_check}
      isCheck={box.is_check ? 1 : 0}
      draggable={draggable}
      onDragStart={onDragStart({ id: box.id })}
      onDragOver={onDragOver({ id: box.id })}
      onDrop={onDrop({ id: box.id })}
      onClick={(e) => {
        handleClickPdf(e);
      }}
    >
      {/* <Content>{box.name}</Content> */}
      <PdfImg
        rotateVal={getPositionPdf(rotateValue ?? null)}
        ref={imgRef}
        src={box.src}
      />
      {/* <div>

      </div> */}
      {box.is_check && <CheckedMark>CHECK</CheckedMark>}
      <RotateBox>
        <span
          onClick={(e) => handleRotate("left", box.index + 1)}
          className="swich-bi-list"
        >
          <i className="bi bi-arrow-counterclockwise"></i>
        </span>

        <span onClick={(e) => handleRotate("right", box.index + 1)}>
          <i className="bi bi-arrow-clockwise"></i>
        </span>
      </RotateBox>

      <PageNumberContainer>
        <span className="actual-page-index">
          {box.is_multyTransactionCheck
            ? 1
            : box.is_multyTransactionCommon
              ? common_count
              : box.is_multyTransactionHeader
                ? header_count
                : !box.is_multyTransactionCommon &&
                  !box.is_multyTransactionCheck &&
                  !box.is_multyTransactionHeader
                  ? transaction_count
                  : showIndex.length + index}
        </span>
        <span
          style={{ cursor: "pointer", zIndex: '1' }}
          onClick={(e) => handleSingleRemove(e, box.index)}
        >
          <i className="bi bi-trash"></i>
        </span>
        {box.is_multyTransactionCheck && finaldata.find((i) => i.is_multyTransactionCheck) &&
          <span
            style={{ cursor: "pointer" }}
            onClick={(e) => handleEditCheck(e, box.index)}
          >
            <i className="bi bi-pencil-square"></i>
          </span>
        }
        <span className="">{box.index + 1}</span>
      </PageNumberContainer>
    </Card>
  );
};

const swapBoxes = (
  fromBox,
  toBox,
  boxes,
  setBoxes,
  setAddedElemnts,
  addedElemnts
) => {
  let bx = [...boxes];
  let fromIndex = -1;
  let toIndex = -1;

  bx.forEach((box, index) => {
    if (box.id === fromBox.id) {
      fromIndex = index;
    }
    if (box.id === toBox.id) {
      toIndex = index;
    }
  });

  if (fromIndex !== -1 && toIndex !== -1) {
    let fromRest = bx[fromIndex];
    let toRest = bx[toIndex];

    if (fromIndex < toIndex) {
      for (var i = fromIndex; i < toIndex - 1; i++) {
        bx[i] = bx[i + 1];
      }

      bx[toIndex] = { id: toBox.id, ...fromRest };
      bx[toIndex - 1] = { id: toRest.id, ...toRest };
    } else if (fromIndex > toIndex) {
      for (var i = fromIndex; i > toIndex + 1; i--) {
        bx[i] = bx[i - 1];
      }

      bx[toIndex] = { id: toBox.id, ...fromRest };
      bx[toIndex + 1] = { id: toRest.id, ...toRest };
    }


    setAddedElemnts({
      ...addedElemnts,
      splitedFiles: bx,
    });

    setBoxes(bx);
  }
};

const handleDrop =
  (data, boxes, setBoxes, setAddedElemnts, addedElemnts) => (event) => {
    event.preventDefault();
    let fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
    let toBox = { id: data.id };

    swapBoxes(fromBox, toBox, boxes, setBoxes, setAddedElemnts, addedElemnts);
    return false;
  };

const handleDragStart = (data) => (event) => {
  let fromBox = JSON.stringify({ id: data.id });
  event.dataTransfer.setData("dragContent", fromBox);
};

const handleDragOver = () => (event) => {
  event.preventDefault();
  return false;
};

const props = [
  { id: 1, name: "Box 1" },
  { id: 2, name: "Box 2" },
  { id: 3, name: "Box 3" },
  { id: 4, name: "Box 4" },
  { id: 5, name: "Box 5" },
  { id: 6, name: "Box 6" },
];

const Boxes = ({
  canvasDetails,
  dragType,
  pdfPagesList,
  setActiveCheckID,
  setCheckPopup,
  swapDiv,
  allpages,
  addedElemnts,
  setAddedElemnts,
  formik,
  finaldata,
  handleEditCheck,
  initialWidthValue,
  handleRotate
}) => {
  const [boxes, setBoxes] = useState(addedElemnts.splitedFiles);
  useEffect(() => {
    setBoxes(addedElemnts.splitedFiles);
  }, [addedElemnts.splitedFiles]);
  //   addedElemnts.map((i)=>document.getElementById('drag-container-box').appendChild(i))
  // console.log(addedElemnts.splitedFiles);

  return (
    <Container dragType={dragType}>
      <BoxGroup id="drag-container-box">
        <>
          {pdfPagesList?.map((box, index) => (
            <Box
              handleRotate={handleRotate}
              initialWidthValue={initialWidthValue}
              canvasDetails={canvasDetails}
              formik={formik}
              finaldata={finaldata}
              handleEditCheck={handleEditCheck}
              dragType={dragType}
              box={box}
              index={index + 1}
              showIndex={allpages}
              key={box.id}
              draggable="true"
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={(data) =>
                handleDrop(data, boxes, setBoxes, setAddedElemnts, addedElemnts)
              }
              setAddedElemnts={setAddedElemnts}
              addedElemnts={addedElemnts}
              swapDiv={swapDiv}
              setCheckPopup={setCheckPopup}
              setActiveCheckID={setActiveCheckID}
            />
          ))}
        </>
      </BoxGroup>
    </Container>
  );
};

const DragContainer = (props) => {
  const pdfPagesFiles = props.addedElemnts.splitedFiles;
  let pdfPagesList = [];
  if (props.dragType === "multy_check") {
    pdfPagesList = pdfPagesFiles.filter(
      (i) => i.is_multyTransactionCheck === true
    );
  } else if (props.dragType === "multy_common") {
    pdfPagesList = pdfPagesFiles.filter(
      (i) => i.is_multyTransactionCommon === true
    );
  } else if (props.dragType === "multy_header") {
    pdfPagesList = pdfPagesFiles.filter(
      (i) => i.is_multyTransactionHeader === true
    );
  }
  // else if(props.dragType === 'normal'){
  else {
    pdfPagesList = pdfPagesFiles.filter(
      (i) =>
        i.is_multyTransactionCommon === false &&
        i.is_multyTransactionCheck === false &&
        i.is_multyTransactionHeader === false
    );
  }
  // console.log(props.addedElemnts.splitedFiles,"TEST!!");
  // console.log(pdfPagesList,props.dragType);
  return (
    <Page>
      <Boxes
        canvasDetails={props.canvasDetails}
        dragType={props.dragType}
        pdfPagesList={pdfPagesList}
        setActiveCheckID={props.setActiveCheckID}
        setCheckPopup={props.setCheckPopup}
        swapDiv={props.swapDiv}
        allpages={props.allpages}
        setAddedElemnts={props.setAddedElemnts}
        addedElemnts={props.addedElemnts}
        formik={props.formik}
        finaldata={props.finaldata}
        handleEditCheck={props.handleEditCheck}
        initialWidthValue={props.initialWidthValue}
        handleRotate={props.handleRotate}
      />
    </Page>
  );
};
export default DragContainer;
