import { GET_835_LIST_URL, GET_835_LITE_LIST_URL, GET_ENCOUNTER_LIST_URL, GET_ACKNOWLEDGMENT_LIST_URL, RESEND_ACKNOWLEDGMENT_LIST_URL } from "../../api";
import axiosInstance from "../../axiosInstance";
import { GET_835_LIST, GET_CORRESPONDANCE, GET_PATIENT_PAY } from "../../routeNames";


// correspondance list 
export const getCorrespondanceData = (props) => onResponse => {
    try {
        console.log(props);
        const search = props.search ? props.search : ""
        const client = props?.client ? props.client : ""
        const deposit_date_range = props?.deposit_date_range ? props?.deposit_date_range : ""
        axiosInstance.get(GET_CORRESPONDANCE + "?page=" + props.page + "&limit=" + props.pageLimit + "&search=" + search + "&client=" + client + "&deposit_date_range=" + deposit_date_range)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};
// correspondance list 
export const getPatientPayData = (props) => onResponse => {
    try {
        console.log(props);


        const search = props.search ? props.search : ""
        const client = props?.client ? props.client : ""
        const deposit_date_range = props?.deposit_date_range ? props?.deposit_date_range : ""
        axiosInstance.get(GET_PATIENT_PAY + "?page=" + props.page + "&limit=" + props.pageLimit + "&search=" + search + "&client=" + client + "&deposit_date_range=" + deposit_date_range)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};

// encounter list 837
export const getEncounterData = (props) => onResponse => {
    // try {
    const search = props?.search ? props?.search : "";
    const client = props?.client ? props?.client : "";
    const recieved_date_range = props.recieved_date_range ? props.recieved_date_range : "";
    const patient_account_number = props.patient_account_number ? props.patient_account_number : "";

    axiosInstance.get(GET_ENCOUNTER_LIST_URL + "?page=" + props.page + "&limit=" + props.pageLimit + "&search=" + search + "&client=" + client + "&recieved_date_range=" + recieved_date_range + "&patient_account_number=" + patient_account_number)
        .then((response) => {
            // console.log(response)
            onResponse(response.data);
        })
        .catch((err) => {

            console.log(err)
            onResponse(err);
        });

    //     } catch (error) {
    // console.log("ERORR");
    //     }
};

// encounter list 835
export const get835ListData = (props) => onResponse => {
    try {
        console.log(props);
        // const page_limt = NULL
        // if (props.pageLimit != undefined) {
        //     page_limt = props.pageLimit
        // }
        // else {
        //     page_limt = ""
        // }

        const search = props.search ? props.search : ""
        const client = props?.client ? props.client : ""
        const deposit_date_range = props?.deposit_date_range ? props?.deposit_date_range : ""

        axiosInstance.get(GET_835_LIST_URL + "?page=" + props.page + "&limit=" + props.limit + "&search=" + search + "&client=" + client + "&deposit_date_range=" + deposit_date_range)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};
// Acknowledgement list
export const getAcknowledgementListData = (props) => onResponse => {
    try {
        const search = props.search ? props.search : ""
        const client = props?.client ? props.client : ""
        const deposit_date_range = props?.deposit_date_range ? props?.deposit_date_range : ""

        axiosInstance.get(GET_ACKNOWLEDGMENT_LIST_URL + "?page=" + props.page + "&limit=" + props.limit + "&search=" + search + "&client=" + client + "&deposit_date_range=" + deposit_date_range)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};
// Acknowledgement list
export const sendAcknowledgementFile = (props) => onResponse => {
    try {
        console.log(props)

        axiosInstance.post(RESEND_ACKNOWLEDGMENT_LIST_URL, { acknowledgement: props })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};
export const get835LiteListData = (props) => onResponse => {
    try {
        // console.log(props);
        let search = props.search ? props.search : ""
        const client = props?.client ? props.client : ""
        const deposit_date_range = props?.deposit_date_range ? props?.deposit_date_range : ""
        axiosInstance.get(GET_835_LITE_LIST_URL + "?page=" + props.page + "&limit=" + props.pageLimit + "&search=" + search + "&client=" + client + "&deposit_date_range=" + deposit_date_range)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};