import { GET_SNIPPET_VIEW_URL, RE_ASSIGN_SNIPPET_URL, SAVE_SNIPPET_URL } from "../../api";
import axiosInstance from "../../axiosInstance";


export const getSnippetView = (props) => onResponse => {
    try {
        axiosInstance.post(GET_SNIPPET_VIEW_URL, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const saveSnippet = (props) => onResponse => {
    try {
        axiosInstance.post(SAVE_SNIPPET_URL, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const reassignSnippet = (props) => onResponse => {
    console.log(props);
    try {
        axiosInstance.post(RE_ASSIGN_SNIPPET_URL, props)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};