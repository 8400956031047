export const getMenuColorDetails = (state, maskingDetails) => {
    const conditions = [
        { is: maskingDetails.isMasking, color: maskingDetails.color, className: "rectangle-check-container", colorKey: "isMasking" },
        { is: state.isCheck, color: "rgba(255, 0, 0, 0.5), rgba(255, 0, 0, 0.5)", className: "rectangle-check-container", colorKey: "isCheck" },
        { is: state.isDraging, color: "", className: "", colorKey: "isDraging" },
        { is: state.isPayer, color: "rgba(68, 114, 196, 0.5), rgba(68, 114, 196, 0.5)", className: "rectangle-payer-container", colorKey: "isPayer" },
        { is: state.isPayee, color: "rgba(255, 191, 0, 0.5), rgba(255, 191, 0, 0.5)", className: "rectangle-payee-container", colorKey: "isPayee" },
        { is: state.isClaimHeader, color: "rgba(94, 24, 235, 0.5), rgba(94, 24, 235, 0.5)", className: "rectangle-claim-header-container", colorKey: "isClaimHeader" },
        { is: state.isCLaim, color: "rgba(97, 182, 106,0.5),rgba(97, 182, 106,0.5)", className: "rectangle-claim-container", colorKey: "isCLaim" },
        { is: state.isFooter, color: "rgba(250, 245, 8,0.5),rgba(250, 245, 8,0.5)", className: "rectangle-footer-container", colorKey: "isFooter" },
        { is: state.isBlackOut, color: "linear-gradient(rgb(0 0 0), rgb(0 0 0))", className: "rectangle-blackout-container", colorKey: "isBlackOut" },
        { is: state.isSpan, color: "rgba(165, 165, 165,0.5),rgba(165, 165, 165,0.5)", className: "rectangle-span-container", colorKey: "isSpan" },
    ];

    const matchedCondition = conditions.find(condition => condition.is);

    // debugger
    if (matchedCondition) {
        const { color, className, colorKey } = matchedCondition;
        return { color, className, colorKey };
    }
    return { color: null, className: null, colorKey };
}


export const removeCurentElement = (className) => {
    // debugger
    // Get the HTML element with the specified class name
    const canvasConatiner = document.getElementById("main-canavs-container")
    const elementToRemove = canvasConatiner.querySelector(`${className}`);
    // Check if the element is found
    if (elementToRemove) {
        // Remove the element from its parent node
        elementToRemove.parentNode.removeChild(elementToRemove);
    } else {
        console.log("Element not found");
    }

}


function combineImages(images, callback) {
    // Create a canvas
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Set the gap between images
    const gap = 10; // Adjust the gap as needed

    // Calculate the dimensions of the canvas
    const canvasWidth = Math.max(...images.map(img => img.width));
    const canvasHeight = images.reduce((sum, img) => sum + img.height, 0) + (images.length - 1) * gap;

    // Set canvas dimensions
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Draw each image on the canvas
    let offsetY = 0;
    images.forEach((img, index) => {
        context.drawImage(img, 0, offsetY);
        offsetY += img.height + (index < images.length - 1 ? gap : 0);
    });

    // Convert the canvas content to base64
    const combinedBase64 = canvas.toDataURL('image/png');

    // Invoke the callback with the combined base64 string
    callback(combinedBase64);
}

export function combineMultipleBase64Images(base64Images) {
    return new Promise((resolve, reject) => {
        const images = [];

        function loadImage(index) {
            if (index < base64Images.length) {
                const img = new Image();
                img.src = base64Images[index];
                img.onload = () => {
                    images.push(img);
                    loadImage(index + 1);
                };
            } else {
                combineImages(images, resolve);
            }
        }

        loadImage(0);
    });
}


export const generateUniqueNextKey = (dataList) => {
    let maxRandomId = Math.max(...dataList?.map(item => item?.random_id), 0);
    let nextKey;
    do {
        nextKey = maxRandomId + 1;
        maxRandomId++;
    } while (dataList.some(item => item?.random_id === nextKey));
    return nextKey;
};
