import {
    GET_GROUPS_URL,
    CREATE_PAYEE_URL,
    GET_ALL_PAYEE_URL,
    TOGGLE_PAYEE_URL,
    GET_ROLES_URL,
    DELETE_GROUP_URL,
    CREATE_GROUPS_URL,
    CREATE_ROLES_URL,
    GET_PERMISSIONS_URL,
    GET_SINGLE_PAYEE_DETAIL_URL,
    GET_ALL_PAYER_URL,
    CREATE_PAYER_URL,
    TOGGLE_PAYER_URL,
    GET_SINGLE_PAYER_DETAIL_URL,
    CREATE_USER_URL,
    GET_ALL_USER_URL,
    TOGGLE_USER_URL,
    DELETE_ROLES_URL,
    GET_SINGLE_USER_DETAIL_URL,
    GET_GROUPS_FOR_USER_URL,
    GET_GROUP_DETAILS_URL,
    GET_SINGLE_ROLE_DETAIL_URL,
    GET_REASON_CODES_URL,
    CREATE_REASON_CODES_URL,
    GET_X12_CODES_URL,
    CREATE_X12_CODE_URL,
    TOGGLE_X12_CODE_URL,
    GET_CORRESPONDENCE_CATEGORY,
    GET_X12_CODE_DROPDOWN_URL,
    CREATE_CORRESPONDENCE_CATEGORY,
    GET_PAYER_DROPDOWN,
    GET_PAYER_DROPDOWN_V2,
    DELETE_REASON_CODES_URL,
    GET_SINGLE_X12_CODE_DROPDOWN_URL,
    DELETE_CORRESPONDENCE_CATEGORY,
    GET_SINGLE_REASON_CODE_URL,
    GET_SINGLE_CORRESPONDENCE_CATEGORY,
    GET_ALL_SETTINGS_URL,
    UPDATE_SETTINGS_URL,
    GET_ALL_TIMEZONE,
    GET_CLIENTS_PDF_URL,
    POST_PROP_CODE_CREATE_URL,
    GET_ROLES_DROPDOWN_URL,
    GET_REASON_CODE_CSV_IMPORT_URL,
    GET_PAYER_CSV_IMPORT_URL,
    GET_PAYEE_CSV_IMPORT_URL,
    GET_CLIENT_FACILITY_URL,
    GET_USER_CSV_IMPORT_URL,
    GET_SELECTED_TIMEZONE,
    GET_PAYER_DEFFERENCE_REPORT_URL,
    EXPORT_PAYEE_URL,
    EXPORT_PAYER_URL,
    EXPORT_REASON_CODES_URL,
    GET_DEFAULT_PAYEE_PAYER_URL,
    CREATE_PAYER_ID_LIST_URL,
    DELETE_PAYER_ID_LIST_URL,
    GET_SINGLE_PAYER_ID_LIST_DETAIL_URL,
    GET_ALL_PAYER_ID_LIST_URL,
    GET_PAYER_ID_LIST_CSV_IMPORT_URL,
    EXPORT_PAYER_ID_LIST_URL
} from './../../api/index';
import axiosInstance from '../../axiosInstance';
import moment from 'moment';
import { KEEP_ALIVE } from '../../routeNames';


export const getGroups = (props) => onResponse => {
    try {
        let data = props.search ? props.search : ''
        axiosInstance.get(GET_GROUPS_URL + "?page=" + props.page + "&limit=" + props.limit + '&search=' + data)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createGroup = (name, roles) => onResponse => {
    try {


        axiosInstance.post(CREATE_GROUPS_URL, {
            name: name,
            roles: roles

        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const editGroup = (id, name, roles) => onResponse => {
    try {


        axiosInstance.post(CREATE_GROUPS_URL, {
            group: id,
            name: name,
            roles: roles

        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteGroup = (group_id) => onResponse => {
    // console.log(props, 'trgreretry')
    try {
        axiosInstance.delete(DELETE_GROUP_URL, {
            data: { group: group_id }
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};


// payee Managment
export const createPayee = (props) => onResponse => {
    // console.log(props);
    try {
        axiosInstance.post(CREATE_PAYEE_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                // console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getPayerPayeeDefaultvalue = (props) => onResponse => {
    try {
        axiosInstance.get(GET_DEFAULT_PAYEE_PAYER_URL + "?client=" + props)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err);
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getAllPayees = (props) => onResponse => {
    try {
        const data = props.search ? props.search : ''
        const approve = props.status ? props.status : ''
        const updated_date_range = props.updated_date_range ? props.updated_date_range : ""
        const sort_key = props.sort_key ? props.sort_key : ""
        const sort_order = props.sort_order ? props.sort_order : ""
        const data_from = props.data_from ? props.data_from : ""


        const clients = props.clients ? props.clients : []
        axiosInstance.get(GET_ALL_PAYEE_URL + "?page=" + props.page + "&limit=" + props.limit + '&search=' + data + '&is_approved=' + approve + '&updated_date_range=' + updated_date_range + '&clients=' + clients + '&data_from=' + data_from + '&sort_order=' + sort_order + '&sort_key=' + sort_key)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const exportAllPayees = (props) => onResponse => {
    try {
        const data = props.search ? props.search : ''
        const approve = props.status ? props.status : ''
        const updated_date_range = props.updated_date_range ? props.updated_date_range : ""
        const sort_key = props.sort_key ? props.sort_key : ""
        const sort_order = props.sort_order ? props.sort_order : ""
        const clients = props.clients ? props.clients : []
        const data_from = props.data_from ? props.data_from : ""

        axiosInstance.get(EXPORT_PAYEE_URL + '?search=' + data + '&is_approved=' + approve + '&updated_date_range=' + updated_date_range + '&clients=' + clients + '&data_from=' + data_from + '&sort_order=' + sort_order + '&sort_key=' + sort_key)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const togglePayee = (props) => onResponse => {
    try {
        axiosInstance.put(TOGGLE_PAYEE_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getSinglePayeeDetails = (props) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_PAYEE_DETAIL_URL + "?payee=" + props)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err);
                onResponse(err);
            });

    } catch (error) {

    }

};

// user Managment
export const createUser = (props) => onResponse => {
    console.log(props?.group_data);
    // try {
    // if (props?.values?.password) {

    axiosInstance.post(CREATE_USER_URL, {
        user: props?.values?.user ? props?.values?.user : "",
        username: props?.values?.username,
        name: props?.values?.name == "" || props?.values?.name === null ? "" : props?.values?.name,
        email: props?.values?.email == "" || props?.values?.email === null ? "" : props?.values?.email,
        date_of_birth: props?.values?.date_of_birth == "" || props?.values?.date_of_birth === null ? '' : moment(props?.values?.date_of_birth).format("YYYY-MM-DD"),
        emp_id: props?.values?.emp_id == "" || props?.values?.emp_id === null ? '' : props?.values?.emp_id,
        password: props?.values?.password == "" || props?.values?.password === null ? undefined : props?.values?.password,
        is_superuser_dashboard: props?.values?.is_admin_dashboard,

        // is_admin: props?.values.role == "ADMIN" ? true : false,
        // is_staff: props?.values.role == "STAFF" ? true : false,
        // is_superuser: props?.values.role == "SUPERUSER" ? true : false,
        // password: props?.values?.password ? props?.values?.password : "",
        groups: props?.group_data,
        is_active: true
    })
        .then((response) => {
            // console.log(response)
            onResponse(response.data);
        })
        .catch((err) => {
            console.log(err)
            onResponse(err);
        });

    // }
    // else {
    //     axiosInstance.post(CREATE_USER_URL, {
    //         user: props?.values?.user ? props?.values?.user : "",
    //         username: props?.values?.username,
    //         email: props?.values?.email == "" || props?.values?.email === null ?undefined:props?.values?.email,
    //         name: props?.values?.name == "" || props?.values?.name === null ?undefined:props?.values?.name,
    //         emp_id: props?.values?.emp_id == "" || props?.values?.emp_id === null ?undefined:props?.values?.emp_id,
    //         groups: props?.group_data,
    //         is_active: true
    //     })
    //         .then((response) => {
    //             // console.log(response)
    //             onResponse(response.data);
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //             onResponse(err);
    //         });
    // }
    // } catch (error) {

    // }

};

export const getAllUsers = (props) => onResponse => {
    try {
        let data = props.search ? props.search : ''
        axiosInstance.get(GET_ALL_USER_URL + "?page=" + props.page + "&limit=" + props.limit + '&search=' + data)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};



export const toggleUser = (props) => onResponse => {
    try {
        axiosInstance.put(TOGGLE_USER_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const importCsvUserCode = (props) => onResponse => {
    try {
        // console.log(csv_file);
        axiosInstance.post(GET_USER_CSV_IMPORT_URL, props.formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getSingleUserDetails = (props) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_USER_DETAIL_URL + "?user=" + props)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err);
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleGroupDetails = (props) => onResponse => {
    try {
        axiosInstance.get(GET_GROUP_DETAILS_URL + "?group=" + props)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err);
                onResponse(err);
            });

    } catch (error) {

    }

};


export const getRoles = (props) => onResponse => {
    // console.log(props);
    try {
        let data = props.search ? props.search : ''
        axiosInstance.get(GET_ROLES_URL + "?page=" + props.page + "&limit=" + props.limit + '&search=' + data)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getRolesDropdown = () => onResponse => {
    // console.log(props);
    try {
        axiosInstance.get(GET_ROLES_DROPDOWN_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};




// payer Managment
export const createPayer = (props) => onResponse => {
    try {
        axiosInstance.post(CREATE_PAYER_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getAllPayers = (props) => onResponse => {
    try {
        let data = props.search ? props.search : ''
        let approve = props.status ? props.status : ""
        const updated_date_range = props.updated_date_range ? props.updated_date_range : ""
        const sort_key = props.sort_key ? props.sort_key : ""
        const sort_order = props.sort_order ? props.sort_order : ""
        const clients = props.clients ? props.clients : []
        const data_from = props.data_from ? props.data_from : ""

        axiosInstance.get(GET_ALL_PAYER_URL + "?page=" + props.page + "&limit=" + props.limit + '&search=' + data + '&is_approved=' + approve + '&updated_date_range=' + updated_date_range + '&clients=' + clients + '&data_from=' + data_from + '&sort_order=' + sort_order + '&sort_key=' + sort_key)
            .then((response) => {
                console.log(response.data.data)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {
    }

};
export const exportAllPayers = (props) => onResponse => {
    try {
        let data = props.search ? props.search : ''
        let approve = props.status ? props.status : ""
        const updated_date_range = props.updated_date_range ? props.updated_date_range : ""
        const sort_key = props.sort_key ? props.sort_key : ""
        const sort_order = props.sort_order ? props.sort_order : ""
        const clients = props.clients ? props.clients : []
        const data_from = props.data_from ? props.data_from : []

        axiosInstance.get(EXPORT_PAYER_URL + "?page=" + props.page + "&limit=" + props.limit + '&search=' + data + '&is_approved=' + approve + '&updated_date_range=' + updated_date_range + '&clients=' + clients + '&data_from=' + data_from + '&sort_order=' + sort_order + '&sort_key=' + sort_key)
            .then((response) => {
                console.log(response.data.data)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const payerDifferenceReport = (props) => onResponse => {
    try {
        axiosInstance.get(GET_PAYER_DEFFERENCE_REPORT_URL + "?payer=" + props)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const togglePayer = (props) => onResponse => {
    try {
        axiosInstance.put(TOGGLE_PAYER_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getSinglePayerDetails = (props) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_PAYER_DETAIL_URL + "?payer=" + props)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err);
                onResponse(err);
            });

    } catch (error) {

    }

};

export const createRoles = (name, permissions) => onResponse => {
    try {
        axiosInstance.post(CREATE_ROLES_URL, {
            name, permissions
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const editRoles = (role, name, permissions) => onResponse => {
    try {
        axiosInstance.post(CREATE_ROLES_URL, {
            role: role,
            name: name,
            permissions: permissions

        }
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getSingleRoleDetails = (props) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_ROLE_DETAIL_URL + "?role=" + props)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err);
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getGroupsForUser = () => onResponse => {
    try {
        axiosInstance.get(GET_GROUPS_FOR_USER_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err);
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getPermissions = () => onResponse => {
    try {
        axiosInstance.get(GET_PERMISSIONS_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteRoles = (role) => onResponse => {
    try {
        axiosInstance.delete(DELETE_ROLES_URL, {
            data: { role }
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getReasonCodes = (page, limit, search, status, updated_date_range, clients, data_from, sort_order, sort_key) => onResponse => {
    try {
        let data = search ? search : ''
        let approve = status ? status : ''
        const new_updated_date_range = updated_date_range ? updated_date_range : ""
        const new_sort_key = sort_key ? sort_key : ""
        const new_sort_order = sort_order ? sort_order : ""
        const new_clients = clients ? clients : []
        const new_data_from = data_from ? data_from : ""

        axiosInstance.get(GET_REASON_CODES_URL + "?page=" + page + "&limit=" + limit + '&search=' + data + '&is_approved=' + approve + '&updated_date_range=' + new_updated_date_range + '&clients=' + new_clients + '&data_from=' + new_data_from + '&sort_order=' + new_sort_order + '&sort_key=' + new_sort_key)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const exportReasonCodes = (props) => onResponse => {
    const { search, status, updated_date_range, clients, data_from, sort_key, sort_order } = props
    try {
        let data = search ? search : ''
        let approve = status ? status : ''
        const new_updated_date_range = updated_date_range ? updated_date_range : ""
        const new_sort_key = sort_key ? sort_key : ""
        const new_sort_order = sort_order ? sort_order : ""
        const new_clients = clients ? clients : []
        const new_data_from = data_from ? data_from : ""


        axiosInstance.get(EXPORT_REASON_CODES_URL + '?search=' + data + '&is_approved=' + approve + '&updated_date_range=' + new_updated_date_range + '&clients=' + new_clients + '&data_from=' + new_data_from + '&sort_order=' + new_sort_order + '&sort_key=' + new_sort_key)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const importCsvReasonCode = (props) => onResponse => {
    try {
        // console.log(csv_file);
        axiosInstance.post(GET_REASON_CODE_CSV_IMPORT_URL, props.formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const importCsvPayeeCode = (props) => onResponse => {
    try {
        // console.log(csv_file);
        axiosInstance.post(GET_PAYEE_CSV_IMPORT_URL, props.formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const importCsvPayerCode = (props) => onResponse => {
    try {
        // console.log(csv_file);
        axiosInstance.post(GET_PAYER_CSV_IMPORT_URL, props.formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const importCsvX12Code = (props) => onResponse => {
    try {
        // console.log(csv_file);
        axiosInstance.post(GET_PAYER_CSV_IMPORT_URL, props.formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};


// ========PAYER ID LIST==========
export const createPayerIdList = (unique_code, data, payer_list_id, removed_ids, client) => onResponse => {
    let dict = { unique_code, data, removed_ids, client }
    if (payer_list_id) {
        dict = { unique_code, payer_list_id, data, removed_ids, client }
    }
    try {
        axiosInstance.post(CREATE_PAYER_ID_LIST_URL, dict)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const deletePayerIdList = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_PAYER_ID_LIST_URL, {
            data: { payer_list_id: id }
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSinglePayerIdList = (payer_list_id) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_PAYER_ID_LIST_DETAIL_URL + '?payer_list_id=' + payer_list_id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getPayerIdList = (page, limit, search, updated_date_range, clients, sort_order, sort_key) => onResponse => {
    try {
        let data = search ? search : ''
        const new_updated_date_range = updated_date_range ? updated_date_range : ""
        const new_sort_key = sort_key ? sort_key : ""
        const new_sort_order = sort_order ? sort_order : ""
        const new_clients = clients ? clients : []

        axiosInstance.get(GET_ALL_PAYER_ID_LIST_URL + "?page=" + page + "&limit=" + limit + '&search=' + data + '&updated_date_range=' + new_updated_date_range + '&clients=' + new_clients + '&sort_order=' + new_sort_order + '&sort_key=' + new_sort_key)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const exportPayerIdList = (props) => onResponse => {
    const { search, updated_date_range, clients, sort_key, sort_order } = props
    try {
        let data = search ? search : ''
        const new_updated_date_range = updated_date_range ? updated_date_range : ""
        const new_sort_key = sort_key ? sort_key : ""
        const new_sort_order = sort_order ? sort_order : ""
        const new_clients = clients ? clients : []


        axiosInstance.get(EXPORT_PAYER_ID_LIST_URL + '?search=' + data + '&updated_date_range=' + new_updated_date_range + '&clients=' + new_clients + '&sort_order=' + new_sort_order + '&sort_key=' + new_sort_key)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const importCsvPayerIdList = (props) => onResponse => {
    try {
        // console.log(csv_file);
        axiosInstance.post(GET_PAYER_ID_LIST_CSV_IMPORT_URL, props.formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
// ====================


export const createReasonCodes = (reason_code, payer_id, data, data_from, reasoncodes, removed_ids, is_approved, client, facility) => onResponse => {
    try {
        if (!facility || facility == "") {
            facility = [];
        }

        axiosInstance.post(CREATE_REASON_CODES_URL, { reason_code, payer_id, data, data_from, reasoncodes, removed_ids, is_approved, client, facility })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};


export const createPropCode = (job, payer_id, last_name, n3_address_1, n4_city_name, n4_state_code, n4_postal_code, reason_code, data_from, description, rarc, carc, group_code) => onResponse => {
    try {
        axiosInstance.post(POST_PROP_CODE_CREATE_URL, {
            job, payer_id, last_name, n3_address_1, n4_city_name, n4_state_code, n4_postal_code, reason_code, data_from, description, rarc, carc, group_code
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};


export const deleteReasonCodes = (id) => onResponse => {
    try {
        axiosInstance.delete(DELETE_REASON_CODES_URL, {
            data: { pk: id }
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleReasonCode = (reasoncodes) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_REASON_CODE_URL + '?reasoncodes=' + reasoncodes)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const updateReasonCodes = (reasoncodes, reason_code, description, x12_code, identification_code) => onResponse => {
    try {
        axiosInstance.post(CREATE_REASON_CODES_URL, {
            reasoncodes, reason_code, description, x12_code, identification_code
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getX12Codes = (page, limit, description, code) => onResponse => {
    try {
        axiosInstance.get(GET_X12_CODES_URL + '?page=' + page + '&limit=' + limit + '&description=' + description + '&code=' + code)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createX12Code = (type, code, description, start_date, stop_date) => onResponse => {
    try {
        axiosInstance.post(CREATE_X12_CODE_URL, {
            type, code, description, start_date, stop_date
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const toggleX12Code = (props) => onResponse => {
    try {
        axiosInstance.post(TOGGLE_X12_CODE_URL, props.formData)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleX12Code = (x12code) => onResponse => {
    console.log(x12code);
    try {
        axiosInstance.get(GET_SINGLE_X12_CODE_DROPDOWN_URL + '?x12code=' + x12code)
            .then((response) => {
                console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const updateX12Code = (pk, type, code, description, start_date, stop_date) => onResponse => {
    try {
        axiosInstance.post(CREATE_X12_CODE_URL, {
            pk, type, code, description, start_date, stop_date
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getCorrespondenceCategories = (page, limit, search) => onResponse => {

    try {

        let data = search ? search : ''
        axiosInstance.get(GET_CORRESPONDENCE_CATEGORY + "?page=" + page + "&limit=" + limit + '&search=' + data)

            .then((response) => {
                // console.log(response)
                onResponse(response?.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const createCorrespondenceCategory = (client, facility, categories) => onResponse => {
    try {
        axiosInstance.post(CREATE_CORRESPONDENCE_CATEGORY, {
            client, facility,
            categories

        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const deleteCorrespondenceCategory = (CorrespondenceCategory) => onResponse => {
    try {
        axiosInstance.delete(DELETE_CORRESPONDENCE_CATEGORY, {
            data: { CorrespondenceCategory: CorrespondenceCategory }
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getSingleCorrespondenceCategory = (id) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_CORRESPONDENCE_CATEGORY + "?CorrespondenceCategory=" + id)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const updateCorrespondenceCategory = (id, client, facility, category) => onResponse => {
    try {
        axiosInstance.post(CREATE_CORRESPONDENCE_CATEGORY, {
            categories: [{
                id: id, category: category,
            }],
            client: client,
            facility: facility,
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getX12CodeDropdown = (page) => onResponse => {
    try {
        axiosInstance.get(GET_X12_CODE_DROPDOWN_URL + "?page=" + page)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getPayerDropdown = (page, search) => onResponse => {
    try {
        axiosInstance.get(GET_PAYER_DROPDOWN + "?page=" + page + "&search=" + search)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getPayerDropdownV2 = (page, search, client) => onResponse => {
    try {
        axiosInstance.get(GET_PAYER_DROPDOWN_V2 + "?page=" + page + "&search=" + search + "&client=" + client)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getPdf = (client) => onResponse => {
    try {
        axiosInstance.get(GET_CLIENTS_PDF_URL + "?client=" + client)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getAllSettings = (page) => onResponse => {
    try {
        axiosInstance.get(GET_ALL_SETTINGS_URL + "?page=" + page)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const updateSettings = (settings) => onResponse => {
    try {
        axiosInstance.post(UPDATE_SETTINGS_URL, {
            settings
        })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const getAllTimezone = () => onResponse => {
    try {
        axiosInstance.get(GET_ALL_TIMEZONE)
            .then((response) => {
                console.log(response);

                onResponse(response?.data)
            })
            .catch((err) => {
                onResponse(err)
            })
    } catch (error) {

    }
}

export const getSelectedTimezone = () => onResponse => {
    try {
        axiosInstance.get(GET_SELECTED_TIMEZONE)
            .then((response) => {
                onResponse(response?.data)
            })
            .catch((err) => {
                onResponse(err)
            })
    } catch (error) {

    }
}

export const getFacilityDropdown = (client) => onResponse => {
    try {
        axiosInstance.post(GET_CLIENT_FACILITY_URL, {
            client
        })
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

//KEEP ALIVE API
export const getKeepAliveApi = () => onResponse => {
    try {
        axiosInstance.get(KEEP_ALIVE)
            .then((response) => {
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

