import React from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { Formik, useFormik } from 'formik';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import * as Yup from 'yup';
import { createPayee, getFacilityDropdown, getPayerPayeeDefaultvalue } from '../../../../Redux/user/action';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getSinglePayeeDetails } from './../../../../Redux/user/action';
import { getAllClientDropdown } from '../../../../Redux/client/action';
import CheckImageModal from '../checkImageModal';
import Draggable from 'react-draggable';
import { ScrollMode, SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { TbGridDots } from 'react-icons/tb';
import { HiChevronDown } from 'react-icons/hi';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { PAYEE } from '../../../../routeNames';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import styled from 'styled-components';

// import { pageThumbnailPlugin } from '../../Dashboard/functions';
const renderToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
        {function (slots: ToolbarSlot) {
            const {
                CurrentPageInput, EnterFullScreen, GoToFirstPage, GoToNextPage, GoToPreviousPage, GoToLastPage, NumberOfPages, Zoom, ZoomIn, ZoomOut,
            } = slots;

            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >


                    {/* <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '100px' }}>
                        <GoToFirstPage />
                        <GoToPreviousPage />
                        <CurrentPageInput /> / <NumberOfPages />
                        <GoToNextPage />
                        <GoToLastPage />

                    </div> */}
                    <div className='d-flex flex-row justify-content-between align-items-center' style={{ padding: '0px 2px', marginLeft: '42rem' }}>
                        <ZoomOut />
                        <Zoom />
                        <ZoomIn />
                    </div>
                    <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                        <EnterFullScreen />
                    </div>
                </div>
            );
        }}
    </Toolbar>
);
const pageThumbnailPlugin = (props: PageThumbnailPluginProps): Plugin => {
    const { PageThumbnail } = props;

    return {
        renderViewer: (renderProps: RenderViewer) => {
            let { slot } = renderProps;

            slot.children = PageThumbnail;

            // Reset the sub slot
            slot.subSlot.attrs = {};
            slot.subSlot.children = <></>;

            return slot;
        },
    };
};

const EditPayee = (props) => {

    let navigate = useNavigate();
    const [showCheckView, setShowCheckView] = useState(false)
    const [defaultData, setDefaultData] = useState(null)
    const [clients, setClients] = useState([])
    const [facilities, setFacilities] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [selectedFacility, setSelectedFacility] = useState([])
    let { payee_id, type } = useParams();
    const [data, setData] = useState('');

    const [state, setState] = useState({
        showToast: false,
        error: '',
        error_message: "",
    })
    const fetchDefaultvalue = (clientId) => {
        getPayerPayeeDefaultvalue(clientId)((response) => {
            if (response?.status && response?.data?.default_payee_code_qualifier) {
                setDefaultData(response?.data)

            }
        })
    }
    useEffect(() => {

        getAllClientDropdown()((response) => {
            if (response?.status) {


                setClients(response?.data)
            }
        })

    }, []);
    useEffect(() => {
        if (formik?.values?.clients) {
            getFacilityDropdown([formik?.values?.clients])((response) => {
                if (response && response.status) {
                    setFacilities(response.data.facilities?.map(result => { return { options: result?.facilities, label: result?.client } }))
                }
            });
        }

    }, [selectValue])
    const __handleEditPayee = (values) => {
        const RedirectId = getRedirectTabId()
        let formData = new FormData();    //formdata object

        formData.append('payee', payee_id);   //append the values with key, value pair
        formData.append('payee_id', values.payee_id.toUpperCase());
        formData.append('entity_identifier_code', values.entity_identifier_code.toUpperCase());
        formData.append('data_from', 1);
        formData.append('id_code_qualifier', values.id_code_qualifier.toUpperCase());
        formData.append('payee_identification', values.payee_identification);
        formData.append('name', values.name.toUpperCase());
        formData.append('address', values.address.toUpperCase());

        if (type == 'approve')
            formData.append('is_approved', true);
        formData.append('city', values.city.toUpperCase());
        formData.append('state', values.state.toUpperCase());
        formData.append('zip_code', values.zip_code.toUpperCase());
        formData.append('country', values.country.toUpperCase());
        formData.append('country_subdivision_code', values.country_subdivision_code.toUpperCase());
        formData.append('client', values.clients);
        // values.clients?.map((client) => {
        //     formData.append('client', client);
        // })
        values.facility?.map((data) => {
            formData.append('facility', data);
        })

        createPayee({ formData })((response) => {
            // console.log(response, 'data');
            if (!response?.data?.status && response?.status && response?.status_code === 201) {
                setState({ showToast: !state.showToast, error: 'success', error_message: 'Success' })
                setTimeout(() => {
                    changeAllTitleFunction("Payee", PAYEE, RedirectId)
                }, 1000);

            } else {
                // setState({ error: true, error_message: response?.message });
                // console.log(response?.response?.data?.errors?.city);
                formik.setErrors({
                    payee_id: response?.response?.data?.errors?.payee_id,
                    entity_identifier_code: response?.response?.data?.errors?.entity_identifier_code,
                    id_code_qualifier: response?.response?.data?.errors?.id_code_qualifier,
                    payee_identification: response?.response?.data?.errors?.payee_identification,
                    name: response?.response?.data?.errors?.payee,
                    address: response?.response?.data?.errors?.address,
                    city: response?.response?.data?.errors?.city,
                    state: response?.response?.data?.errors?.state,
                    zip_code: response?.response?.data?.errors?.zip_code,
                    country: response?.response?.data?.errors?.country,
                    country_subdivision_code: response?.response?.data?.errors?.country_subdivision_code,
                })
                setState({ showToast: !state.showToast, error: 'danger', error_message: 'Duplicate Entry!' })

            }
        });
    }
    const __toggleToast = () => {
        setState({ showToast: !state.showToast })

    }
    const __toggleError = () => {
        setState({ showToast: !state.showToast })

    }




    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            payee_id: "",
            entity_identifier_code: "",
            id_code_qualifier: "",
            payee_identification: "",
            name: "",
            address: "",
            city: "",
            state: "",
            zip_code: "",
            country: "",
            country_subdivision_code: "",
            clients: "",
            facility: ""
        },
        validationSchema: Yup.object().shape({
            clients: Yup.string()
                .required('Required'),
            payee_id: Yup.string()
                .min(1, ' At least 1 character')
                .required('Required'),
            entity_identifier_code: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            id_code_qualifier: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            payee_identification: Yup.string()
                .matches(/^[0-9]+$/, "Only digits allowed")
                .min(10, 'Only 10 digits')
                .max(10, 'Only 10 digits')
                .required('Required'),
            name: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            address: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            entity_identifier_code: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            city: Yup.string()
                .min(1, 'At least 1 character')
                .required('Required'),
            state: Yup.string()
                .matches(/^[A-Za-z]+$/, 'Invalid State Abbrevation')
                .min(2, 'Only two characters')
                .max(2, 'Only two characters')
                .required('Required'),
            zip_code: Yup.string()
                .matches(/(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{5}\d{4}$)/, 'Invalid Zip Code')
                .required('Required'),
            country: Yup.string()
                .matches(/^[a-zA-Z ]*$/, 'Invalid Country Name')
                .min(1, 'At least 1 character'),
            country_subdivision_code: Yup.string()
                .min(1, 'At least 1 character'),

        }),
        onSubmit: (values) => {
            __handleEditPayee(values)
        },
    });


    useEffect(() => {
        getSinglePayeeDetails(payee_id)((response) => {
            if (response?.status && response?.data) {
                fetchDefaultvalue(response?.data?.payee?.client?.[0]?.value)
                setData(response?.data?.payee);
                formik.setValues({
                    ...formik.values,
                    payee: payee_id,
                    payee_id: response?.data?.payee?.payee_id,
                    entity_identifier_code: response?.data?.payee?.entity_identifier_code,
                    id_code_qualifier: response?.data?.payee?.id_code_qualifier,
                    payee_identification: response?.data?.payee?.payee_identification,
                    name: response?.data?.payee?.name,
                    address: response?.data?.payee?.address,
                    city: response?.data?.payee?.city,
                    state: response?.data?.payee?.state,
                    zip_code: response?.data?.payee?.zip_code,
                    country: response?.data?.payee?.country,
                    country_subdivision_code: response?.data?.payee?.country_subdivision_code,
                    clients: response?.data?.payee?.client?.[0]?.value,
                    facility: response?.data?.payee?.facility?.map((data) => data?.value),
                })
                setSelectedFacility(response?.data?.payee?.facility);
                setSelectValue(response?.data?.payee?.client)
            }
        });

    }, []);
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    const options = [
        // { value: "all", label: "All" },
        ...clients.map((client) => ({
            label: client.label,
            value: client.value,
        })),
    ];
    const handleSelectChange = (selected) => {
        fetchDefaultvalue(selected?.value)
        formik.setValues({
            ...formik.values, "clients": selected?.value, "facility": []
        });
        setSelectValue(selected ?? "")
        setSelectedFacility([])
    };

    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedFacility, setIsFocusedFacility] = useState(false);

    const handleFacilityChange = (selected) => {
        formik.setFieldValue("facility", selected.map((option) => option?.value));
        setSelectedFacility(selected)


    };
    const defaultLayoutPluginInstance = defaultLayoutPlugin({

        renderToolbar, toolbarPlugin: {
            pageNavigationPlugin: {
                enableShortcuts: true,
            },
        },
        fullScreenPlugin: {
            onEnterFullScreen: (zoom) => {
                zoom(SpecialZoomLevel.PageFit);
                defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                    ScrollMode.Wrapped
                );
            },
            onExitFullScreen: (zoom) => {
                zoom(SpecialZoomLevel.PageWidth);
                defaultLayoutPluginInstance.toolbarPluginInstance.scrollModePluginInstance.switchScrollMode(
                    ScrollMode.Vertical
                );
            },
        },
        sidebarTabs: (defaultTabs) => [],



    });

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {formik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };

    // Shortcut Key
    window.onkeyup = function (event) {
        if (event.keyCode === 27) {
            setShowCheckView(false)
        }
    }
    return (
        <React.Fragment>

            <AdminMaster />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={state.error} onClose={__toggleToast} show={state.showToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>{state.error_message}</span></Toast.Body>
                    </Toast>
                </ToastContainer>
                <div className="pagetitle">
                    {type == 'approve' ?
                        <h1 className='text-uppercase'>Approve Payee</h1>
                        :
                        <h1 className='text-uppercase'>Edit Payee</h1>
                    }

                </div>
                <MainContainer className="section ">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">

                                    <Form
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="mb-3 row" >
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold' >Payee ID</Form.Label>

                                                    <Form.Control
                                                        name='payee_id'
                                                        type="text"
                                                        autoComplete="false"
                                                        isValid={formik.touched.payee_id && !formik.errors.payee_id}
                                                        isInvalid={!!formik.errors.payee_id}
                                                        value={formik.values?.payee_id}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        onChange={formik.handleChange}
                                                        maxLength="255"
                                                        placeholder=" Payee Id"

                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.payee_id}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold' >Entity Identifier Code</Form.Label>

                                                    <Form.Control
                                                        name='entity_identifier_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.entity_identifier_code && !formik.errors.entity_identifier_code}
                                                        isInvalid={!!formik?.errors?.entity_identifier_code}
                                                        value={formik.values?.entity_identifier_code}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder=" Entity Identifier Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.entity_identifier_code}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>ID Code Qualifier</Form.Label>
                                                    <Form.Control
                                                        name='id_code_qualifier'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.id_code_qualifier && !formik.errors.id_code_qualifier}
                                                        isInvalid={!!formik?.errors?.id_code_qualifier}
                                                        value={formik.values?.id_code_qualifier}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder=" ID Code Qualifier"
                                                        onKeyUp={(e) => {
                                                            if (e.ctrlKey && e.key === " ") {
                                                                defaultData?.default_payee_code_qualifier && formik.values?.id_code_qualifier == '' && formik.setValues({
                                                                    ...formik.values,
                                                                    id_code_qualifier: defaultData?.default_payee_code_qualifier,
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.id_code_qualifier}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Payee Identification</Form.Label>
                                                    <Form.Control
                                                        name='payee_identification'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.payee_identification && !formik.errors.payee_identification}
                                                        isInvalid={!!formik?.errors?.payee_identification}
                                                        value={formik.values?.payee_identification}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder=" Payee Identification"
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.payee_identification}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Name</Form.Label>
                                                    <Form.Control
                                                        name='name'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.name && !formik.errors.name}
                                                        isInvalid={!!formik?.errors?.name}
                                                        value={formik.values?.name}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder=" Name"
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.name}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Address</Form.Label>
                                                    <Form.Control
                                                        name='address'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.address && !formik.errors.address}
                                                        isInvalid={!!formik?.errors?.address}
                                                        value={formik.values?.address}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder=" Address"
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.address}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>City</Form.Label>
                                                    <Form.Control
                                                        name='city'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.city && !formik.errors.city}
                                                        isInvalid={!!formik.errors.city}
                                                        value={formik.values?.city}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder=" City"
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.city}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-3'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>State</Form.Label>
                                                    <Form.Control
                                                        name='state'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.state && !formik.errors.state}
                                                        isInvalid={!!formik?.errors?.state}
                                                        value={formik.values?.state}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength={2}
                                                        placeholder=" State"
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.state}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Zip Code</Form.Label>
                                                    <Form.Control
                                                        name='zip_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.zip_code && !formik.errors.zip_code}
                                                        isInvalid={!!formik?.errors?.zip_code}
                                                        value={formik.values?.zip_code}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength={10}
                                                        placeholder=" Zip Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.zip_code}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>

                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Country</Form.Label>
                                                    <Form.Control
                                                        name='country'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.country && !formik.errors.country}
                                                        isInvalid={!!formik?.errors?.country}
                                                        value={formik.values?.country}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder=" Country"
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.country}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-4'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Country Subdivision Code</Form.Label>
                                                    <Form.Control
                                                        name='country_subdivision_code'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        isValid={formik.touched.country_subdivision_code && !formik.errors.country_subdivision_code}
                                                        isInvalid={!!formik?.errors?.country_subdivision_code}
                                                        value={formik.values?.country_subdivision_code}
                                                        className='b-r-10 form-control-solid text-uppercase'
                                                        maxLength="255"
                                                        placeholder=" Country Subdivision Code"
                                                    />
                                                    <Form.Control.Feedback type="invalid"> {formik.errors.country_subdivision_code}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-5'>
                                                <div className="mb-10">
                                                    <label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Clients</label>


                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        // isMulti
                                                        value={selectValue}
                                                        name="clients"
                                                        options={options}
                                                        components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="clients" /> }}
                                                        onChange={handleSelectChange}
                                                        placeholder="Select Clients..."
                                                        onFocus={() => setIsFocusedClient(true)}
                                                        onBlur={() => setIsFocusedClient(false)}
                                                        styles={{
                                                            container: (styles, state) => ({
                                                                ...styles,
                                                                borderRadius: "9px",
                                                            }),
                                                            control: (styles, state) => ({
                                                                ...styles,
                                                                backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                borderStyle: "none", boxShadow: "none",
                                                                borderRadius: "10px",
                                                                maxHeight: state.isFocused ? "auto" : "30px",
                                                            }),
                                                            indicatorSeparator: styles => ({
                                                                ...styles, width: "0px", minHeight: "20px"
                                                            }),

                                                            valueContainer: (styles, state) => ({
                                                                ...styles,
                                                                maxHeight: isFocusedClient ? "auto" : "30px",
                                                            }),
                                                            placeholder: (styles) => ({
                                                                ...styles,
                                                                textTransform: 'uppercase' // Change placeholder text to uppercase
                                                            })
                                                        }}
                                                    />

                                                    {!!formik.errors?.clients &&
                                                        <span className='text-danger'>{formik.errors?.clients}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="mb-10">
                                                    <Form.Group>
                                                        <Form.Label htmlhtmlFor="exampleFormControlInput1" className=" form-label">Facility</Form.Label>
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isMulti
                                                            value={selectedFacility}
                                                            name="facility"
                                                            options={facilities}
                                                            components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="facility" /> }} onChange={handleFacilityChange}
                                                            isDisabled={selectValue.length > 1 ? true : false}
                                                            onFocus={() => setIsFocusedFacility(true)}
                                                            onBlur={() => setIsFocusedFacility(false)}
                                                            styles={{
                                                                container: (styles, state) => ({
                                                                    ...styles,
                                                                    borderRadius: "9px",
                                                                }),
                                                                control: (styles, state) => ({
                                                                    ...styles,
                                                                    backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                                    borderStyle: "none", boxShadow: "none",
                                                                    borderRadius: "10px",
                                                                    maxHeight: state.isFocused ? "auto" : "30px",
                                                                }),
                                                                indicatorSeparator: styles => ({
                                                                    ...styles, width: "0px", minHeight: "20px"
                                                                }),

                                                                valueContainer: (styles, state) => ({
                                                                    ...styles,
                                                                    maxHeight: isFocusedFacility ? "auto" : "30px",
                                                                }),
                                                                placeholder: (styles) => ({
                                                                    ...styles,
                                                                    textTransform: 'uppercase' // Change placeholder text to uppercase
                                                                })


                                                            }}
                                                            placeholder="Select Facility..."
                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik?.errors?.facility}</Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div>
                                            {state.error &&
                                                <span className='text-danger'>{state.error_message}</span>}
                                        </div> */}
                                        <div className='d-flex flex-row justify-content-end'>
                                            {data?.job_id &&
                                                <div className='me-2'>
                                                    <p
                                                        className='pt-2'
                                                        style={{ fontSize: '0.9375rem', color: '#6ba2b6' }}
                                                    >
                                                        {data?.job_id}
                                                    </p>
                                                </div>
                                            }

                                            {data?.image_url &&
                                                <div className=''>
                                                    <button
                                                        type='button'
                                                        className='text-uppercase btn btn-secondary btn-active-primary me-2'
                                                        onClick={() => { setShowCheckView(true) }}
                                                    >
                                                        Image
                                                    </button>
                                                </div>
                                            }
                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='text-uppercase btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("Payee", PAYEE) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            {checkUserPermission('edit_payee') &&
                                                <div className=''>
                                                    <button
                                                        type='submit'
                                                        className='text-uppercase btn btn-primary btnHover'
                                                        disabled={!formik.isValid}
                                                    >
                                                        {type == 'approve' ? "Approve" : "Update"} Payee
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </MainContainer>
                {/* Check View Modal Start */}
                {showCheckView &&
                    <CheckImageModal fileUrl={data.image_url} setShowCheckView={setShowCheckView} showCheckView={showCheckView} />

                }

                {/* Check View Modal Start */}

            </main>
        </React.Fragment >
    )
}

export default EditPayee

const MainContainer = styled.div`
    label{
        text-transform:uppercase
    }
`