import React, { useEffect, useState } from 'react';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { createClientGroup } from '../../../../../Redux/clientGroup/action';
import { useNavigate } from 'react-router-dom';
import { CLIENT_GROUP, CREATE_CLIENT_GROUP } from '../../../../../routeNames';
import { changeAllTitleFunction, getRedirectTabId } from '../../../Dashboard/functions';

function ClientGroupForm(props) {


    const navigate = useNavigate()
    const { client_data, __toggleToast, setLoading } = props;
    const [isSubmit, setIsSubmit] = useState(false)

    useEffect(() => {
        if (props?.client_data) {
            clientformik.setValues({
                name: client_data?.group_name,

            })
        }

    }, [client_data]);

    const clientformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
        },
        validationSchema: Yup.object().shape({

            name: Yup.string()
                .min(3, ' at least 3')
                .required('Required'),

        }),
        onSubmit: (values) => {
            setLoading(true)
            setIsSubmit(true)

            let formData = new FormData();    //formdata object
            if (client_data?.id) {
                formData.append('client_group_id', client_data.id);
            }
            formData.append('group_name', values.name);
            const RedirectId = getRedirectTabId()
            createClientGroup({ formData })((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    // setState({ clientValidated: true })
                    // alert('success')
                    changeAllTitleFunction('Client Group', CLIENT_GROUP, RedirectId)

                    // props.changeState(response?.data);

                    // __toggleToast("success", response?.message, true)
                    // setIsSubmit(true)
                    // setLoading(false)


                } else {
                    // alert('error')

                    // const errors = response?.response?.data?.errors;
                    clientformik.setErrors({
                        ...clientformik,
                        name: response?.response?.data?.errors?.name,

                    })
                    setLoading(false)
                    props.changeState(response);
                    __toggleToast("danger", response?.response?.data?.errors?.name ? response?.response?.data?.errors?.name : "Something Went Wrong", true)
                    setIsSubmit(false)

                }
            });
        },
    });


    // const __toggleToast = () => {
    //     setState({ showToast: !state.showToast })

    // }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    return (
        <React.Fragment>


            <Form
                onSubmit={clientformik.handleSubmit}
            >
                <Form.Label className='fw-bold clr-heading' >Client Group</Form.Label>
                <div className="mb-3 row border-bottom-dashed" >


                    <div className='col-lg-12'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Name</Form.Label>
                            <Form.Control
                                name='name'
                                type="text"
                                onChange={clientformik.handleChange}
                                // onBlur={clientformik.handleBlur}
                                autoComplete="false"
                                isValid={clientformik.touched?.name && !clientformik.errors?.name}
                                isInvalid={!!clientformik.errors?.name}
                                value={clientformik.values?.name}
                                className='b-r-10 form-control-solid'
                                // 
                                placeholder=" Name"
                            />
                            <Form.Control.Feedback type="invalid"> {clientformik.errors?.name}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                </div>





                <div className='d-flex flex-row justify-content-end'>
                    <div className=''>
                        <button
                            type='button'
                            className='btn btn-secondary btn-active-primary me-2'
                            onClick={() => { changeAllTitleFunction('Client Group', CLIENT_GROUP) }}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className=''>
                        {!client_data ?
                            <button
                                disabled={!clientformik.isValid || isSubmit}
                                type='submit'
                                className='btn btn-light btn-active-primary'
                            >
                                Create Group
                            </button>
                            :
                            checkUserPermission('create_payee') ?
                                <button
                                    disabled={!clientformik.isValid}
                                    type='submit'
                                    className='btn btn-light btn-active-primary'
                                >
                                    Update Group
                                </button>
                                :
                                <React.Fragment></React.Fragment>

                        }
                    </div>
                </div>
            </Form>
        </React.Fragment>
    )
}

export default ClientGroupForm