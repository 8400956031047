import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import AdminMaster from '../../../Layout/admin/Master'
import { createClaimsFiling, getSingleClaimsFiling, getAllGroupDropdown } from '../../../Redux/claimsFiling/action';
import { Toast, ToastContainer } from 'react-bootstrap';
import { getAllClientDropdown } from '../../../Redux/client/action';

import { FieldArray, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { CLAIMS_FILING } from '../../../routeNames';
import Select from "react-select";
import { id } from 'date-fns/locale';
import ReasonCode from '../ResonCodes';
import { changeAllTitleFunction, getRedirectTabId } from '../Dashboard/functions';



const CreateUpdateClaimsFiling = (props) => {

    const navigate = useNavigate()

    const { claimsFiling_id, type } = useParams();

    const [state, setState] = useState({
        error: false,
        error_message: "",
        removed_ids: []

    })
    const [toaster, setToaster] = useState(false)
    const [message, setMessage] = useState(false)
    const [loader, setLoader] = useState(null);
    const [codeDefinition, setCodeDefinition] = useState([])
    const [selectValue, setSelectValue] = useState([])
    const [toast, setToast] = useState({
        type: "",
        message: "",
        toast: false,

    });


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            claim_id: '',
            client: '',
            group: '',
            data: [{
                //code_id: '',
                id: "",
                code: '',
                definition: '',
            }]
        },

        validationSchema: Yup.object().shape({
            //client: Yup.string().required('Required'),
            group: Yup.string().trim().required('Required'),
            //codeFiling: Yup.string().trim().required('Required'),
            data: Yup.array().of(
                Yup.object().shape({
                    code: Yup.string().required('Required'),
                    definition: Yup.string().required('Required')
                })
            )
        }),
        // validate: (values) => {
        //     let errors = {}
        //     if (values.client === 'null') {
        //         errors.client = "Required"
        //     }
        //     return errors;
        // },

        onSubmit: (values, { setSubmitting }) => {
            // setSubmitting(true)
            // let formData = new FormData();    //formdata object
            // formData.append('id', values.id)
            // formData.append('client', values.client)
            // formData.append('code', values.code)
            // formData.append('definition', values.definition)
            // formData.append('removed_ids', state.removed_ids)
            // if (type == 'approve') {

            //     var is_approved = true
            // }
            // else {
            //     var is_approved = false

            // }
            //alert('12')
            const RedirectId = getRedirectTabId()
            createClaimsFiling(values, state.removed_ids)((response) => {

                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    setToaster('success')
                    const timer = setTimeout(() => {
                        setToaster(false)
                        setSubmitting(false)
                        changeAllTitleFunction("Allowed Values", CLAIMS_FILING, RedirectId)
                    }, 1000);
                    return () => clearTimeout(timer);


                } else {
                    //__toggleToast("error", response?.response?.data?.errors?.code[0] ? response?.response?.data?.errors?.code[0] : "12123123", true)
                    if (response?.response?.data?.errors?.code[0] && response?.response?.data?.errors?.code[0]) {
                        setMessage(response?.response?.data?.errors?.code[0])
                    }
                    setToaster('error')

                    const timer = setTimeout(() => {
                        setToaster(false)
                        setSubmitting(false)
                        setMessage(false)
                    }, 2000);
                    return () => clearTimeout(timer);

                }

            });

        },
    });

    const [clients, setClients] = useState([])
    const [groupList, setGroupList] = useState([])

    useEffect(() => {

        getAllClientDropdown()((response) => {
            if (response?.status) {
                setClients(response?.data)
            }
        })

        getAllGroupDropdown()((response) => {

            if (response?.status) {
                setGroupList(response?.data)
            }
        })

        // if (classification) {

        //     getSingleDocumentDetail(classification)((response) => {
        //         if (response?.status) {
        //             formik.setValues({
        //                 ...formik.values,
        //                 classification: response?.data?.partner?.classification ? response?.data?.partner?.classification : "",
        //             })
        //         }
        //     })

        // }
    }, []);


    // useEffect(() => {

    //     getAllGroupDropdown()((response) => {
    //             if (response?.status) {
    //                 setGroupList(response?.data)
    //             }
    //         })

    // }, [])

    // const options = [
    //     // { value: "all", label: "All" },
    //     ...group.map((group) => ({
    //         label: group.label,
    //         value: group.value,
    //     })),
    // ];


    useEffect(() => {
        if (claimsFiling_id) {
            getSingleClaimsFiling(claimsFiling_id)((response) => {

                if (response?.status && response?.data) {
                    const data = []
                    response.data?.code_definition?.map((claim, index) => {
                        data.push({
                            id: claim.id,
                            code: claim.code,
                            definition: claim.definition
                        })
                    })

                    // setCodeDefinition(response?.data?.code_definition)
                    formik.setValues({
                        ...formik.values,
                        claim_id: response?.data?.id,
                        client: response?.data?.client,
                        data: data,
                        group: response?.data?.group,
                        //data: response?.data?.code_definition,
                    })
                }

            })
        }
    }, [claimsFiling_id])


    const __toggleToast = (type, message, toast) => {
        setToast({
            type: type,
            message: message,
            toast: toast,
        })
        const timer = setTimeout(() => {
            setToast({
                type: false,
                message: '',
                toast: false,
            })
        }, 3000);
        return () => clearTimeout(timer);

    }

    const handleRemoveItems = (index, arrayHelpers, value) => {
        let removed_ids = [...state.removed_ids]
        var data = formik.values.data;
        data = data.filter((i) => i != value)
        formik.setValues({ ...formik.values, 'data': data })
        if (value?.id) {
            removed_ids.push(String(value?.id))
            setState({ ...state, removed_ids })
        }
    }

    return (
        <React.Fragment>

            <AdminMaster ToastMessage={toaster} message={message} />
            {/* <ToastContainer className="p-3" position={"top-end"}>
                <Toast bg="success" show={toast.type == 'success' ? true : false} animation={true} delay={2000} autohide>

                    <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                </Toast>
                <Toast bg="danger" show={toast.type == 'error' ? true : false} onClose={() => setToast({ toast: false })} animation={true} delay={2000} autohide>

                    <Toast.Body ><span className='text-white'>{toast.message ? toast.message : "Failed"}</span></Toast.Body>
                </Toast>
            </ToastContainer> */}
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1>{claimsFiling_id ? 'Edit Allowed Value' : 'Create Allowed Value'}</h1>

                </div>

                <section className="section mt-5">

                    <div className="card card-flush py-4">

                        <FormikProvider value={formik}>


                            <Form
                                onSubmit={formik.handleSubmit}
                            >
                                <div className=' px-4 py-2'>
                                    <div className="d-flex justify-content-between" >





                                        <div className='col-lg-5'>
                                            <div className="mb-10">
                                                <Form.Label className='fw-bold'>Client</Form.Label>
                                                <Form.Control as="select"
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name="client"
                                                    //isValid={formik.touched.client && !formik?.errors?.client}
                                                    isInvalid={!!formik?.errors?.claim_id}
                                                    maxLength={30}
                                                    placeholder="Client"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.client}
                                                >
                                                    <option value="">Select an Option</option>
                                                    {clients?.map((option) => (
                                                        <option key={option.value
                                                        } value={option.value}>{option.label}</option>
                                                    ))}

                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{formik.errors.client}</Form.Control.Feedback>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="mb-10">
                                                <Form.Label className='fw-bold'>Groups</Form.Label>
                                                <Form.Control as="select"
                                                    className="form-control form-control-solid b-r-10"
                                                    classNamePrefix="select"
                                                    name='group'

                                                    isValid={formik?.touched?.group && !formik?.errors?.group}
                                                    isInvalid={!!formik?.errors?.group}
                                                    //maxLength={30}
                                                    //placeholder="Client"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.group}
                                                >
                                                    <option value="">Select an Option</option>
                                                    {groupList && groupList?.map((option, index) => (
                                                        <option key={index} value={option?.value}>{option?.label}</option>
                                                    ))}
                                                    {/* <option value="1">sajith</option>
                                                    <option value="2">ajith</option>
                                                    <option value="3">raihan</option>
                                                    <option value="4">uvais</option> */}

                                                </Form.Control>
                                                <Form.Control.Feedback type="invalid">{formik.errors.group}</Form.Control.Feedback>
                                            </div>




                                        </div>
                                    </div>
                                    <FieldArray name='data'
                                        render={(arrayHelpers) => (
                                            <React.Fragment>
                                                <div className='d-flex flex-row justify-content-start gap-4 flex-wrap' style={{ width: "100%" }}>
                                                    <div>
                                                        {formik?.values?.data.map((value, index) => (

                                                            <div key={index}>

                                                                <div className='d-flex flex-row justify-content-start gap-4 flex-wrap mt-2' >
                                                                    <div className='' style={{ width: '20rem' }}>


                                                                        <Form.Group className="mt-3">
                                                                            {index == 0 &&

                                                                                <Form.Label className='fw-bold'>Code</Form.Label>
                                                                            }
                                                                            <Form.Control
                                                                                name={`data[${index}].code`}
                                                                                type="text"
                                                                                onChange={formik.handleChange}
                                                                                autoComplete="false"
                                                                                isValid={formik?.errors && formik?.errors.data && !formik?.errors.data[index]?.code}
                                                                                isInvalid={formik?.errors && formik?.errors.data && !!formik.errors?.data[index]?.code}
                                                                                value={formik.values?.data[index]?.code}
                                                                                className='b-r-10 form-control-solid'
                                                                                maxLength="255"
                                                                                placeholder="Code"
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.data && formik.errors.data[index]?.code}</Form.Control.Feedback>

                                                                        </Form.Group>

                                                                    </div>

                                                                    <div className='' style={{ width: '20rem' }}>
                                                                        <Form.Group className="mt-3">

                                                                            {/* <Form.Label className='fw-bold'>Definition</Form.Label> */}
                                                                            {index == 0 &&

                                                                                <Form.Label className='fw-bold'>Definition</Form.Label>
                                                                            }
                                                                            <Form.Control
                                                                                name={`data[${index}].definition`}
                                                                                type="text"
                                                                                onChange={formik.handleChange}
                                                                                autoComplete="false"
                                                                                isValid={formik?.errors && formik?.errors.data && !formik?.errors.data[index]?.definition}
                                                                                isInvalid={formik?.errors && formik?.errors.data && !!formik.errors?.data[index]?.definition}
                                                                                value={formik.values?.data[index]?.definition}
                                                                                className='b-r-10 form-control-solid'
                                                                                maxLength="255"
                                                                                placeholder="Definition"
                                                                            />
                                                                            <Form.Control.Feedback type="invalid">{formik?.errors && formik?.errors?.data && formik.errors.data[index]?.code}</Form.Control.Feedback>

                                                                        </Form.Group>
                                                                    </div>





                                                                    {index == 0 ? <div className='mt-3'>

                                                                        <button
                                                                            className='me-2 btn btn-danger claims-delete-add-btn'
                                                                            type="button"
                                                                            disabled={formik.values?.data?.length === 1}
                                                                            onClick={() => handleRemoveItems(index, arrayHelpers, value)}
                                                                        >
                                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                            </svg> */}
                                                                            <i className='bi bi-trash'></i>
                                                                        </button>

                                                                        {formik?.values?.data?.length - 1 === index &&
                                                                            <button
                                                                                className='btn btn-light btn-active-primary claims-delete-add-btn'
                                                                                type="button"
                                                                                onClick={() => arrayHelpers.push({ code: "", definition: '', })}
                                                                            >
                                                                                <i className='bi bi-plus'></i>
                                                                            </button>
                                                                        }
                                                                    </div> :
                                                                        <div className='mt-3'>

                                                                            <button
                                                                                className='me-2 btn btn-danger'
                                                                                type="button"
                                                                                disabled={formik.values?.data?.length === 1}
                                                                                onClick={() => handleRemoveItems(index, arrayHelpers, value)}
                                                                            >
                                                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                                                                <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                                                                            </svg> */}
                                                                                <i className='bi bi-trash'></i>
                                                                            </button>
                                                                            {formik?.values?.data?.length - 1 === index &&

                                                                                <button
                                                                                    className='btn btn-light btn-active-primary '
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.push({ code: "", definition: '', })}
                                                                                >
                                                                                    <i className='bi bi-plus'></i>
                                                                                </button>
                                                                            }
                                                                        </div>}


                                                                </div>

                                                            </div>



                                                        ))}
                                                    </div>
                                                    {/* <div className='claims-add-add-btn mt-4'>
                                                        <button
                                                            className='btn btn-light btn-active-primary '
                                                            type="button"
                                                            onClick={() => arrayHelpers.push({ code: "", definition: '', })}
                                                        >
                                                            <i className='bi bi-plus'></i>
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </React.Fragment>
                                        )}

                                    />





                                    <div className='d-flex flex-row justify-content-end mt-2'>

                                        <div className=''>
                                            <button
                                                type='button'
                                                className='btn btn-secondary btn-active-primary me-2'
                                                onClick={() => { changeAllTitleFunction("Allowed Values", CLAIMS_FILING) }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div className=''>
                                            <button
                                                disabled={!formik.isValid || formik.isSubmitting}
                                                type='submit'
                                                className='btn btn-primary btnHover'
                                            >
                                                {claimsFiling_id ?
                                                    type == 'approve' ?
                                                        'Approve'
                                                        :
                                                        formik.isSubmitting ? 'Updating' : "Update"

                                                    :
                                                    formik.isSubmitting ? "Creating" : 'Create'
                                                }

                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </Form>
                        </FormikProvider>



                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default CreateUpdateClaimsFiling