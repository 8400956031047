
import {
  LOGIN
} from "./actionType";
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : ["uvais"],
};

// export default function (state = initialState, action) {
//     switch (action.type) {
//         case LOGIN:
//             return { ...state, user: action.payload };
//         default:
//             return state;
//     }
// }

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      // console.log(action.payload);
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.email = action.payload.email;


      state.is_admin = action.payload.is_admin,
        state.is_staff = action.payload.is_staff,
        state.is_superuser = action.payload.is_superuser,
        state.is_verified = action.payload.is_verified,
        state.is_active = action.payload.is_active,
        state.settings = action.payload.settings,
        state.permissions = action.payload.permissions,
        state.is_password_reset_required = action.payload.is_password_reset_required,

        localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logoutSuccess: (state, action) => {
      state.id = null;
      state.username = null;
      state.email = null;
      state.is_admin = null;
      state.is_staff = null;
      state.is_superuser = null;
      state.is_verified = null;
      state.is_active = null;
      state.settings = null;
      state.permissions = null;


      localStorage.removeItem("user");
    },
  },
});

export const { loginSuccess, logoutSuccess } = userSlice.actions;

export const selectUserName = (state) => state.user.name;
export const selectUserEmail = (state) => state.user.email;
//   export const accessToken = (state) => state.user.access;
//   export const selectAuthenticated = (state) => state.user.isAuth;

export default userSlice.reducer;
