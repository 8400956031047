import { FormikProvider, useFormik } from 'formik';
import React from 'react'
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AdminMaster from '../../../Layout/admin/Master';
import { getAllClientDropdown } from '../../../Redux/client/action';
import { getSingleDocumentDetail, updateDocument } from '../../../Redux/document/action';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { DOCUMENT_CLASSIFICATION } from '../../../routeNames';
import { changeAllTitleFunction, getRedirectTabId } from '../Dashboard/functions';

const EditDocument = () => {

    let { classification } = useParams();

    const navigate = useNavigate()
    const [state, setState] = useState({
        showToast: false,
        error: false,
        error_message: "",
    })

    const [toaster, setToaster] = useState(null);

    const [message, setMessage] = useState(null);


    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            client: "",
            // clients: [],
            classification: "",
            // datas: [{
            //     name: "",
            // }],
        },

        validationSchema: Yup.object().shape({

            // facility: Yup.object().shape({
            //     name: Yup.string().required("Required"),
            // }),
            //client: Yup.string().nullable().required('Required'),
            classification: Yup.string().required("Required"),
        }),

        onSubmit: (values) => {
            const RedirectId = getRedirectTabId()
            // let formData = new FormData();    //formdata object

            // formData.append('name', values?.name);


            updateDocument(values?.client, classification, values?.classification)((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    setState({ showToast: !state.showToast })
                    setToaster('success')
                    setTimeout(() => {
                        changeAllTitleFunction("Document Classification", DOCUMENT_CLASSIFICATION, RedirectId)
                    }, 3000);
                } else {

                    formik.setErrors({
                        // payee_id: response?.response?.data?.errors?.payee_id,
                        classification: response?.response?.data?.errors?.classification,
                    })
                    setToaster('error')
                    if (response?.response?.data?.errors?.classification && response?.response?.data?.errors?.classification[0]) {
                        setMessage(response?.response?.data?.errors?.classification[0])
                    }
                    setState({ error: true, error_message: response?.message });
                    const timer = setTimeout(() => {
                        setToaster(false)
                    }, 2000);
                }
            });

        },
    });

    const [clients, setClients] = useState([])

    useEffect(() => {

        getAllClientDropdown()((response) => {
            if (response?.status) {
                setClients(response?.data)
            }
        })

        if (classification) {

            getSingleDocumentDetail(classification)((response) => {
                if (response?.status) {
                    formik.setValues({
                        ...formik.values,
                        classification: response?.data?.document_classifications?.classification ? response?.data?.document_classifications?.classification : "",
                        client: response?.data?.document_classifications?.client ? response?.data?.document_classifications?.client : "",
                    })
                }
            })

        }
    }, []);


    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} message={message} />
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1>Edit Document Classification</h1>

                </div>

                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">

                                    <FormikProvider value={formik}>


                                        <Form
                                            onSubmit={formik.handleSubmit}
                                        >
                                            <div className="mb-3 row" >





                                                <div className='col-lg-6'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold'>Client</Form.Label>
                                                        <Form.Control as="select"
                                                            name='client'
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            autoComplete="false"
                                                            isValid={formik.touched.client && !formik.errors.client}
                                                            isInvalid={!!formik.errors.client}
                                                            value={formik.values.client}
                                                            className='b-r-10 form-control-solid'
                                                            maxLength="255"
                                                            placeholder="client"
                                                        >
                                                            <option value="">Select An Option </option>
                                                            {clients && clients?.map((client, index) => (
                                                                <option key={index} value={client?.value}>{client?.label}</option>
                                                            ))}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">{formik.errors.client}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold'>Classification</Form.Label>
                                                        <Form.Control
                                                            name='classification'
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            autoComplete="false"
                                                            isValid={formik.touched.classification && !formik.errors.classification}
                                                            isInvalid={!!formik.errors.classification}
                                                            value={formik.values.classification}
                                                            className='b-r-10 form-control-solid'
                                                            maxLength="255"
                                                            placeholder="classification"

                                                        />
                                                        <Form.Control.Feedback type="invalid">{formik.errors.classification}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>
                                            </div>

                                            <div className='d-flex flex-row justify-content-end'>

                                                <div className=''>
                                                    <button
                                                        type='button'
                                                        className='btn btn-secondary btn-active-primary me-2'
                                                        onClick={() => { changeAllTitleFunction("Document Classification", DOCUMENT_CLASSIFICATION) }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className=''>
                                                    <button
                                                        disabled={!formik.isValid}
                                                        type='submit'
                                                        className='btn btn-primary btnHover'
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </FormikProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment>
    )
}

export default EditDocument
