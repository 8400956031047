import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import AdminMaster from '../../../Layout/admin/Master';
import { Link } from 'react-router-dom';
import { getAllUsers, importCsvUserCode, toggleUser, } from './../../../Redux/user/action';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Toast, ToastContainer } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import datatableCustomStyles from './../../../Layout/Elements/DatatableCustomStyles';
import toggle from "../../../assets/img/toggle.svg";
import axiosInstance from './../../../axiosInstance';
import { GET_ALL_USER_URL } from '../../../api';
import { CREATE_DBK, CREATE_USERS, EDIT_DBK, EDIT_USERS } from '../../../routeNames';
import Modal from 'react-modal'

import ChangePasswordForm from '../Password/ChangePasswordForm';
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';
import { userDifferenceReport } from '../../../Redux/reports/action';
import UserDiffrenceReportModal from '../../BootstrapModal/UserDiffrenceReportModal';
import { useFormik } from 'formik';
import SweetAlert from 'react-bootstrap-sweetalert';
import { deleteUserWiseDbk, getListUserWiseDbk } from '../../../Redux/userWiseDbk/action';
import moment from 'moment';
import { changeAllTitleFunction } from '../Dashboard/functions';
import DeleteSweetAlert from '../../SweetAlert/DeleteSweetAlert';
import { Tooltip } from '@mui/material';

const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <>
        <TextField

            id="search"
            className="form-control form-control-solid w-250px filter-btn-custom"
            type="text"
            placeholder="Filter By Comment"
            aria-label="Search Input"
            value={filterText}
            onChange={onFilter}
        />
        <ClearButton type="button" onClick={onClear}>
            X
        </ClearButton>
    </>
);
const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;
const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
    border-left: 0;
    color: #1ba1a7;
	border-bottom-right-radius: 5px;
	height: 35px;
	width: 32px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
    background-color: #f5f8fa;
    border-color: #d9d9d9;
    &:hover {
        background-color: #f5f8fa;
        border-color: #d9d9d9;
        color: #1ba1a7;
    }
    &:focus {
        background-color: #f5f8fa !important;
        border-color: #d9d9d9 !important;
        color: #1ba1a7;
    }`;

const ViewDbk = () => {
    const [reRenderDataTable, setRerenderDataTable] = useState(false)
    const [state, setState] = useState({
        error: false,
        error_message: "",
        showToast: false,
        showToastPassword: false
    });
    const [loading, setLoading] = useState(false);
    const [colorRow, setColorRow] = useState({});
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [data, setData] = useState({})
    const [userId, setUserId] = useState(null)
    const [toaster, setToaster] = useState(false)

    const [toggleReportPopup, setToggleReportPopup] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [loader, setLoader] = useState(false);

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [pageno, setPageNo] = useState(1);


    function closeModal() {
        setIsOpen(false);
        setUserId(null)
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };

    const [modalUpload, setModalUpload] = useState(false);

    // const renderEditTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Edit
    //     </Tooltip>
    // );
    // const renderDeleteTooltip = (props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         Delete
    //     </Tooltip>
    // );
    // const showTooltip = (text, props) => (
    //     <Tooltip id="button-tooltip" {...props}>
    //         {text}
    //     </Tooltip>
    // );

    const columns = useMemo(
        () => [
            {
                id: 'id',
                name: 'S NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                // sortable: true,
                wrap: true,
                width: "50px",
                grow: 0,

            },

            {
                id: 'comment',
                name: 'comment',
                selector: row => row.comment,
                cell: (row) =>
                    <div className='text-truncate ' style={{ width: "120px", overflow: 'hidden' }} onClick={() => handleRowClick(row)}>
                        <Tooltip disableInteractive title={row?.comment} placement='top' arrow>
                            <span className='text-truncate '>
                                {row?.comment}
                            </span>
                        </Tooltip>
                    </div>
                ,
                sortable: true,
                wrap: true,
                width: "480px",
                grow: 0.3,

            },
            {
                id: 'created_date',
                name: 'Created Date',
                sort_key: "created_date",
                selector: row => moment(row?.created_date).format('M/DD/YY, H:mm'),
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.created_date ? moment(row?.created_date).format('M/DD/YY, H:mm') : ""}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                grow: 1,
                // omit: !state.created_date,
            },

            // {
            //     id: 'role',
            //     name: 'User Type',
            //     selector: row => row.roles,
            //     sortable: true,
            //     left: true,
            //     fixed: true,
            //     wrap: true,
            //     grow: 0.5,


            //     cell: (row) => (
            //         <React.Fragment>
            //             {/* { // console.log(row)} */}
            //             {row &&
            //                 <span className="badge badge-light-primary fs-7 fw-bold">
            //                     {row.is_admin ? <span className='badge badge-light-primary fs-7 fw-bold'>ADMIN</span> : <span className='badge badge-light-primary fs-7 fw-bold'>STAFF</span>}
            //                 </span>
            //             }

            //         </React.Fragment>
            //     )
            // },

            {
                id: 'edit',
                name: 'Actions',

                cell: (row) =>
                    <div className="d-flex justify-content-end flex-shrink-0 align-items-center gap-2" onClick={() => { handleRowClick(row) }}>

                        {checkUserPermission('edit_userwise_dbk') &&

                            <Tooltip disableInteractive title="Edit" placement='top' arrow>
                                <Link onClick={() => changeAllTitleFunction("Edit DBK", EDIT_DBK + row.pk)} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" >
                                    <span className="svg-icon svg-icon-3">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                            <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                        </svg>
                                    </span>
                                </Link>
                            </Tooltip>
                        }

                        <Tooltip disableInteractive title="Delete" placement='top' arrow>
                            <Link className={"btn btn-icon btn-bg-light btn-active-color-primary btn-sm "} onClick={() => setIsSwal({ ...isSwal, show: true, id: row.pk })}>
                                <span className="svg-icon svg-icon-3">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                        <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                        <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                        <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                            </Link>
                        </Tooltip>
                        {/* } */}


                    </div>,
                ignoreRowClick: false,
                width: '120px',
                allowOverflow: true,
                //button: true,
                right: true
            },

        ],
        [pageno, loading],
    );
    const [message, setMessage] = useState("")


    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })
    const [isSwal, setIsSwal] = useState({
        show: false,
        id: '',
    })

    const csvFormik = useFormik({
        initialValues: {
            csv_file: ""
        },
        // validationSchema: Yup.object().shape({
        //     csv_file: Yup.mixed()
        //         .required('Required'),
        // }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            const formData = new FormData();
            formData.append('csv_file', values?.csv_file[0]);
            importCsvUserCode({ formData })((response) => {
                if (response && response?.status) {
                    setSubmitting(false)
                    setToastMessage({
                        status: true,
                        message: response?.message,
                        type: 'success',
                    })



                    setModalUpload(!modalUpload)
                    setTimeout(() => {

                        window.location.reload()
                    }, 3000);
                }
                else {

                    setSubmitting(false)
                    setModalUpload(!modalUpload)
                    setIsSwal({
                        show: true,
                        message: response?.message,
                    })

                }
            })
        }
    })
    const fetchUserWiseDbk = async (page, limit, search) => {

        setLoading(true);

        //setLimit(limit);
        await getListUserWiseDbk({ page, limit, search })((response) => {
            setData(response?.data);
            setPageNo(page)
            setLimit(limit);
            setTotalRows(response?.data?.count);
            setLoading(false);
            //console.log(response);
        })
        // const response = await axiosInstance.get(GET_ALL_USER_URL + "?page=" + page + "&limit=" + limit);

    };
    const handlePageChange = page => {
        setPageNo(page)
        fetchUserWiseDbk(page, limit, filterText);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        // setLoading(true);

        // const response = await axiosInstance.get(GET_ALL_USER_URL + "?page=" + page + "&limit=" + newPerPage);
        // setData(response?.data);
        // setLimit(newPerPage);
        // setTotalRows(response?.data?.data?.count);
        fetchUserWiseDbk(1, newPerPage, filterText)
        setLimit(newPerPage);
        setPageNo(1)
        // setLoading(false);
        handleResetDataTable()


    };
    useEffect(() => {
        fetchUserWiseDbk(1, limit);
    }, []);


    useEffect(() => {
        if (toaster) {
            const timer = setTimeout(() => {
                setToaster('')
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [toaster])

    const searchGroups = async (e) => {
        setFilterText(e.target.value)
        let data = e.target.value ? e.target.value : '';
        await fetchUserWiseDbk(1, limit, data)
        // const response = await axiosInstance.get(GET_ALL_USER_URL + "?limit=" + data?.data?.count + "&search=" + data);
        // setData(response?.data);
        handleResetDataTable()
    }
    // const filteredItems = data?.data?.results?.filter(
    //     item => item?.username?.toLowerCase().includes(filterText.toLowerCase()),
    // );
    const subHeaderComponentMemo = React.useMemo(() => {


        // return (
        //     <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
        // );
    }, [filterText, resetPaginationToggle]);
    const handleClear = () => {
        if (filterText) {
            //setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            fetchUserWiseDbk(1, limit);

        }
    };
    const __passwordToast = (status, message, status_code) => {
        // console.log(message);
        setState({ ...state, showToastPassword: true })
        setToaster('success')
        closeModal()
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const __closeToast = () => {
        setState({
            showToast: false,
            showToastPassword: false
        })
    }
    var i = 0;
    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.pk == row.pk ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.pk == row.pk ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];
    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };

    const handleRowCheckClick = (row) => {
        data?.results.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
        // // console.log(row);
    };
    const handleUserReport = (id) => {
        setLoader(true)
        userDifferenceReport(id)((response) => {
            if (response && response.status) {
                setReportData(response?.data)
                setToggleReportPopup(true)
                setLoader(false)

            } else {
                setToggleReportPopup(false)
                setToaster("error")
            }
        })
    }
    const handleFileChange = (event) => {


        let selectedFile = event.target.files;

        csvFormik.setValues({
            ...csvFormik.values,
            csv_file: selectedFile
        })

    }

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }
    const handleDelete = (pk) => {
        // console.log(pk);
        setIsSwal({ ...isSwal, show: 2 })
        deleteUserWiseDbk(pk)((response) => {

            if (response?.status) {


                fetchUserWiseDbk(pageno, limit, filterText);

                setToaster('success')
                setTimeout(() => {
                    setToaster('')

                }, 2000);

            } else {
                setToaster('error')
                setTimeout(() => {
                    setToaster('')

                }, 2000);
            }
        });
    }
    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} message={message} />
            <main id="main" className="main">
                {isSwal.show === true &&
                    <SweetAlert
                        danger
                        confirmBtnText="Ok!"
                        //confirmBtnBsStyle="danger"
                        confirmBtnCssClass='#6ba2b6'
                        onConfirm={() => { setIsSwal({ ...isSwal, show: false, message: "" }); window.location.reload() }}
                    >
                        <div >
                            {isSwal?.message}

                        </div>
                    </SweetAlert>
                }
                <div className="pagetitle tab-fixed-header ">
                    <h1 id='page-title'>Userwise DBK</h1>
                </div>

                {/* <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={state.showToast} onClose={__closeToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                </ToastContainer> 
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={state.showToastPassword} onClose={__closeToast} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                </ToastContainer>*/}

                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                        {
                            status: false,
                            message: "",
                            type: "",
                        }

                    )}
                        show={ToastMessage?.status} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                    </Toast>

                </ToastContainer>
                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <div className="card-header border-0">
                                    <div className='d-flex align-items-center ms-3'>
                                        <FilterComponent onFilter={(e) => searchGroups(e)} onClear={handleClear} filterText={filterText} />
                                    </div>
                                    <div className="card-toolbar gap-2 me-4" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-trigger="hover" data-bs-original-title="Click to add a user" data-kt-initialized="1">

                                        {checkUserPermission('create_userwise_dbk') &&

                                            <Link onClick={() => changeAllTitleFunction("Create DBK", CREATE_DBK)} className="btn btn-light btn-active-primary">
                                                <span className="svg-icon svg-icon-3">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                                    </svg>
                                                </span>
                                                Create
                                            </Link>

                                        }
                                    </div>
                                </div>
                                <div className="card-body py-3">
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}

                                                    data={data?.results}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    // subHeader
                                                    // subHeaderComponent={subHeaderComponentMemo}
                                                    theme="solarized"
                                                    customStyles={datatableCustomStyles}

                                                />
                                            }
                                            {/* <table id="example" className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4" >
                                                <thead>
                                                    <tr className="fw-bold text-muted">
                                                        <th className="min-w-150px">User Name</th>
                                                        <th className="min-w-150px">Email</th>
                                                        <th className="min-w-150px">Role</th>
                                                        <th className="min-w-100px text-end">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data?.status && data?.data.results && data?.data?.results.map((user, index) =>

                                                        <tr key={index}>
                                                            <td>{user.username}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.is_admin ? <span className='badge badge-light-primary fs-7 fw-bold'>ADMIN</span> : <span className='badge badge-light-primary fs-7 fw-bold'>STAFF</span>}</td>
                                                            <td className='text-end'>
                                                                <div className='btn-group'>
                                                                    <Link to={"/edit-user/" + user.pk} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">

                                                                        <span className="svg-icon svg-icon-3">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"></path>
                                                                                <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Link>
                                                                    <Link className={user.is_active ? "btn btn-icon btn-bg-light btn-active-color-primary btn-sm " : "btn btn-icon btn-bg-light btn-active-color-primary btn-sm"} onClick={() => __toggleUserWiseDbk(user.pk)}>
                                                                        <span className="svg-icon svg-icon-3">
                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                                                                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                                                                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Change Password  Modal Starts*/}

                <Modal
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                    onRequestClose={() => { closeModal() }}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <ChangePasswordForm closeModal={closeModal} __passwordToast={__passwordToast} UserId={userId} />

                </Modal>
                {/* Change Password  Modal End*/}
                {/* User Report  Modal Starts*/}


                <UserDiffrenceReportModal
                    toggleReportPopup={toggleReportPopup}
                    setToggleReportPopup={setToggleReportPopup}
                    reportData={reportData}
                    setReportData={setReportData}
                />
                {/* User Report Modal End*/}
                <DeleteSweetAlert handleDelete={handleDelete} isSwal={isSwal} setIsSwal={setIsSwal} />
            </main>

        </React.Fragment>
    )
}

export default ViewDbk;