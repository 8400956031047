import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import AdminMaster from '../../../Layout/admin/Master';
import { Link, useNavigate } from 'react-router-dom';
import { Toast, ToastContainer, OverlayTrigger, Modal, Form } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useFormik } from 'formik';
import { BiComment, BiFile, BiHistory, BiPaperPlane, BiReset, BiSearch, BiTimer, BiUserPlus } from 'react-icons/bi';
import { ediValidate835List, fetchAllLockboxFiles, generateOperationLog, generateOutputZip, getChangeJobTat, getLockboxLogDetailsReport, getUserlist, getUserManualAssign, lockboxComment, resendEobOutputFIleSchema, toggleLockbox } from '../../../Redux/qms/action';
import { IoIosSwitch } from 'react-icons/io';
import moment from 'moment';
import { rejectSftpFile } from '../../../Redux/fileSplit/action';
import queueDatatableCustomStyles from '../../../Layout/Elements/QueueDatatableCustomStyles';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select, { components } from 'react-select';
import { getAllClientDropdown } from '../../../Redux/client/action';
import * as Yup from 'yup';
import 'rsuite/dist/rsuite.min.css';
import { Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import DateRangePicker from 'rsuite/DateRangePicker';

import { LOCKBOX_FILES_QMS, SINGLE_LOCKBOX_LOCKBOX_FILES } from '../../../routeNames';
import { HiChevronDown } from 'react-icons/hi';
import { TbGridDots } from 'react-icons/tb';
import { changeAllTitleFunction } from '../Dashboard/functions';
import { GrSend } from 'react-icons/gr';
import { Tooltip } from '@mui/material';
const TextField = styled.input`
    height: 32px;
    width: 200px;
    &:hover {
        cursor: pointer;
    }
    `;


const AllLockbox = () => {
    const [datetime, setDateTime] = useState([]);
    //////////////////////////////////////////////////////////////////
    const [state, setState] = useState({
        s_no: true,
        client: true,
        facility: true,
        lbx_file_name: true,
        lbx_file_count: true,
        total_file: true,
        index_file_amount: true,
        check_amount: true,
        balance: true,
        eft_amount: true,
        plb_amount: true,
        no_of_check: true,
        total_pages: true,
        status: true,
        internal_tat: true,
        lbx_file_size: true,
        file_received_on: true,
        output_generated_time: true,
        split_type: true,
        reason: true,
        actions: true,
        deposit_date: true,

    });

    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(null);
    const [totalRows, setTotalRows] = useState(100);
    const [limit, setLimit] = useState(100);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [data, setData] = useState({})
    const [colorRow, setColorRow] = React.useState({});

    const [clientName, setClientName] = useState("")
    const [selectValue, setSelectValue] = useState([])
    const [sendPopup, setSendPopup] = useState({
        state: false,
        file: '',
        title: ''
    })

    const [isSwal, setIsSwal] = useState({
        show: false,
        id: '',
        data: '',
    })
    const [isEdi, setIsEdi] = useState({
        show: false,
        id: '',
        data: '',
    })

    const [pageno, setPageNo] = useState(1);
    const [userlist, setUserlist] = useState();
    const [clientList, setClientList] = useState();

    const [toaster, setToaster] = useState(null);

    const [ToastMessage, setToastMessage] = useState({
        status: false,
        message: "",
        type: "",
    })

    useEffect(() => {
        getUserlist(1)((response) => {
            setUserlist(response?.data?.results)
        });

        getAllClientDropdown()((response) => {

            setClientList(response?.data)
        })

    }, []);




    const formik = useFormik({
        initialValues: {
            assignedqueue: "",
            staff: "",
        },
        onSubmit: (values) => {
            __formSubmit(values)
        },
    });

    const __formSubmit = (values) => {
        getUserManualAssign(values?.staff, values?.assignedqueue)((response) => {
            setShowModal(false);
            if (response && response.status) {
                setToaster('success')
            }
            else {
                setToaster('error')
            }

        });
    };

  

    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    const handleDownloadOperationLog = (file) => {
        const fileURL = file;
        let created_date = datetime
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();


        fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)
    }

    const handleDownloadSourceFile = (file) => {
        const fileURL = file;
        let created_date = datetime
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();

        fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)
    }

    const predefinedBottomRanges = [
        {
            label: 'Today',
            value: [new Date(), new Date()],
            placement: 'left'
        },
        {
            label: 'Yesterday',
            value: [addDays(new Date(), -1), addDays(new Date(), -1)],
            placement: 'left'
        },
        {
            label: 'This week',
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left'
        },
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
        {
            label: 'This month',
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left'
        },
        {
            label: 'Last month',
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
            placement: 'left'
        },
        {
            label: 'This year',
            value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
            placement: 'left'
        },
        {
            label: 'Last year',
            value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
            placement: 'left'
        },

        {
            label: 'Last week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
                ];
            },
            appearance: 'default'
        },
        {
            label: 'Next week',
            closeOverlay: false,
            value: value => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
                ];
            },
            appearance: 'default'
        }
    ];

    const handleDownloadOutputFile = (file) => {
        const fileURL = file;
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'SamplePDF.pdf';
        alink.target = '_blank';
        alink.click();

        let created_date = datetime

        fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)
    }

    const handleDblClickCopy = async (filename) => {
        navigator.clipboard.writeText(filename)
    };

    const [logShow, setLogShow] = useState(false)

    const [commentShow, setCommentShow] = useState(false)

    const commentFormik = useFormik({
        initialValues: {
            lock_box: "",
            reason: "",
        },
        validate: values => {
            let errors = {};
            if (values?.reason == "") {
                errors.reason = "Required";
            }
            return errors;
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required('Required'),
        }),

        onSubmit: (values, { setSubmitting }) => {

            setSubmitting(true)
            lockboxComment(values?.lock_box, values?.reason)((response) => {
                if (response?.status) {


                    let created_date = datetime

                    fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)
                    setCommentShow(false)

                    setToaster('success');
                    const timer = setTimeout(() => {
                        setToaster(false)
                    }, 2000);
                    return () => clearTimeout(timer);
                }
                else {
                    setToaster('error');
                    const timer = setTimeout(() => {
                        setSubmitting(false)
                        setToaster(false)
                    }, 2000);
                    return () => clearTimeout(timer);
                }

            })

        }
    })

    const [logData, setLogData] = useState([]);

    const getLockboxLogDetails = (lockbox) => {
        getLockboxLogDetailsReport(lockbox)((response) => {

            if (response && response?.data) {

                setLogShow(true)
                setLogData(response?.data)
            }
            else {

                setLogShow(false)

            }
        })
    }

    const navigate = useNavigate();

    const handleFilterJobmonitor = (lockbox) => {

        changeAllTitleFunction("Job Monitor", `${LOCKBOX_FILES_QMS + lockbox}`)
    }

    const columns = useMemo(
        () => [
            {
                id: 'S.NO',
                name: 'S.NO',
                selector: (row, index) => pageno == 1 ? index + 1 : (pageno - 1) * limit + index + 1,
                wrap: true,
                width: "60px",
                grow: 0,
            },

            {
                id: 'CLIENT',
                name: 'CLIENT',
                selector: row => row?.client,
                cell: (row) => <div>
                    {row?.client &&

                        <div className='d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                            <Tooltip disableInteractive title={row?.client} placement='top' arrow>

                                <span className='text-truncate' onDoubleClick={() => handleDblClickCopy(row?.client)}>{row?.client}</span>
                            </Tooltip>
                        </div>
                    }
                </div>,
                sortable: true,
                wrap: true,
                width: "160px",
                grow: 0.3,
                reorder: true,
                omit: !state.client,

            },

            {
                id: 'FACILITY',
                name: 'FACILITY',
                selector: row => row?.facility,
                cell: (row, index) => <div>
                    {row?.facility &&
                        <div className='d-flex flex-row  flex-wrap text-truncate' onClick={() => handleRowClick(row)} style={{ width: '120px' }}>
                            <Tooltip disableInteractive title={row?.facility} placement='top' arrow>
                                {row?.facility &&
                                    <span key={index} className='text-truncate' onDoubleClick={() => handleDblClickCopy(row?.facility)}>{row?.facility}</span>
                                }
                            </Tooltip>
                        </div>
                    }
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "160px",
                // grow: 0.3,
                omit: !state.facility,
            },

            {
                id: 'LBX FILE NAME',
                name: 'LBX FILE NAME',
                // selector: row => row?.file,
                cell: (row) =>

                    <div className='d-flex flex-row gap-2 align-items-center justify-content-between ' style={{ width: '120px' }}>
                        {row?.lbx_file_name &&

                            <div
                                className='text-truncate d-flex flex-row  flex-wrap'
                                style={{ width: '120px' }}
                                onClick={() => handleRowClick(row)}
                                onDoubleClick={() => handleFilterJobmonitor(row?.lbx_file_name)}
                            >
                                <Tooltip
                                    disableInteractive
                                    title={row?.lbx_file_name} placement='top' arrow>
                                    <span className='text-truncate' style={{ color: '#6ba2b6 ' }}>{row?.lbx_file_name}</span>
                                </Tooltip>
                            </div>
                        }
                    </div>
                ,

                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "160px",
                //grow: 0.3,
                omit: !state.lbx_file_name,
            },

            {
                id: 'LBX FILE COUNT',
                name: 'LBX FILE COUNT',
                selector: row => row?.lbx_file_count,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.lbx_file_count}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                //width: "100px",
                //grow: 0.1,
                omit: !state.lbx_file_count,
            },

            {
                id: 'TOTAL FILE',
                name: 'TOTAL FILE',
                selector: row => row?.total_file,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total_file}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                width: "80px",
                //grow: 0.1,
                omit: !state.total_file,
            },

            {
                id: 'INDEX FILE AMOUNT',
                name: ' INDEX FILE AMOUNT',
                selector: row => row?.index_file_amount,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {parseFloat(row?.index_file_amount ? row?.index_file_amount : 0).toFixed(2)}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                width: "120px",
                //grow: 0.4,
                omit: !state.index_file_amount,
            },

            {
                id: 'CHECK AMOUNT',
                name: 'CHECK AMOUNT',
                selector: row => row?.check_amount,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {parseFloat(row?.check_amount ? row?.check_amount : 0).toFixed(2)}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                //width: "120px",
                //grow: 0.1,
                omit: !state.check_amount,
            },

            {
                id: 'BALANCE',
                name: 'BALANCE',
                //cell: row => row?.balance,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {parseFloat(row?.balance ? row?.balance : 0).toFixed(2)}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                //width: "100px",
                //grow: 0.3,
                omit: !state.balance,
            },

            {
                id: 'EFT AMOUNT',
                name: 'EFT AMOUNT',
                selector: row => row?.eft_amount,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {parseFloat(row?.eft_amount ? row?.eft_amount : 0).toFixed(2)}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                //width: "100px",
                // grow: 0.3,
                omit: !state.eft_amount,
            },
            {
                id: 'PLB AMOUNT',
                name: ' PLB AMOUNT',
                selector: row => row?.plb_amount,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.plb_amount}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                // width: "100px",
                //grow: 0.3,
                omit: !state.plb_amount,
            },
            {
                id: '# CHECKS',
                name: '# CHECKS',
                selector: row => row?.no_of_check,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.no_of_check}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                // width: "70px",
                // grow: 0.3,
                omit: !state.no_of_check,
            },
            {
                id: '# PAGES',
                name: '# PAGES',
                selector: row => row?.total_pages,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.total_pages}</span>,
                sortable: true,
                reorder: true,
                right: true,
                wrap: true,
                // width: "60px",
                // grow: 0.3,
                omit: !state.total_pages,
            },

            {
                id: 'STATUS',
                name: 'STATUS',

                cell: (row) => <div className='text-truncate ' style={{ width: "120px" }} onClick={() => handleRowClick(row)}>
                    {row?.status_name}
                </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                // width: "80px",
                // grow: 0.6,
                omit: !state.status,
            },

            {
                id: 'INTERNAL TAT',
                name: 'INTERNAL TAT',
                selector: row => row.internal_tat ? moment(row.internal_tat).format('M/DD/YY, H:mm') : "---",
                cell: (row) => <span onClick={() => handleRowClick(row)}>{row.internal_tat ? moment(row.internal_tat).format('M/DD/YY, H:mm') : "---"}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                // width: "90px",
                //grow: 0.6,
                omit: !state.internal_tat,
            },

            {
                id: 'LBX FILE SIZE',
                name: 'LBX FILE SIZE',
                selector: row => row?.lbx_file_size,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.lbx_file_size}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                //  width: "80px",
                // grow: 0.5,
                omit: !state.lbx_file_size,
            },

            {
                id: 'FILE RECIEVED DATE & TIME',
                name: 'FILE RECIEVED DATE & TIME',
                cell: (row) => <span onClick={() => handleRowClick(row)}>{row?.file_received_on ? moment(row?.file_received_on).format('M/DD/YY, H:mm') : '-----'}</span>,
                sortable: true,
                reorder: true,
                left: true,
                //width: "140px",
                wrap: true,
                //grow: 0.8,
                omit: !state.file_received_on,
            },
            {
                id: 'DEPOSIT_DATE',
                name: 'DEPOSIT DATE',
                cell: (row) => <span onClick={() => handleRowClick(row)}>{row?.deposit_date ? moment(row?.deposit_date).format('M/DD/YY') : '-----'}</span>,
                sortable: true,
                reorder: true,
                left: true,
                //width: "140px",
                wrap: true,
                //grow: 0.8,
                omit: !state.deposit_date,
            },

            {
                id: 'OUTPUT GENERATED TIME',
                name: 'OUTPUT GENERATED TIME',
                selector: row => row?.output_generated_time ? moment(row?.output_generated_time).format("M/DD/YY HH:mm") : "-------",
                cell: (row) => <div className='' onClick={() => handleRowClick(row)}>
                    {row?.output_generated_time ? moment(row?.output_generated_time).format("M/DD/YY HH:mm") : "-------"}

                </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "140px",
                // grow: 0.9,
                omit: !state.output_generated_time,
            },

            // {
            //     id: 'aaction',
            //     name: 'action',
            //     cell: (row) => <div>
            //         {row?.is_active ?

            //             <button className='btn btn-success btn-sm ' onClick={() => { toggleFormik.setValues({ ...toggleFormik.values, reason: "", lock_box: row?.file_id }); setOpen(true); toggleFormik.validateForm() }}><i className='bi bi-power fs-7'></i></button>
            //             :

            //             <button className='btn btn-danger btn-sm ' onClick={() => { toggleFormik.setValues({ ...toggleFormik.values, reason: "", lock_box: row?.file_id }); setOpen(true); toggleFormik.validateForm() }}><i className='bi bi-power fs-7'></i></button>
            //         }
            //     </div>,
            //     sortable: true,
            //     reorder: true,
            //     left: true,
            //     wrap: true,
            //     grow: 0.4,
            //     omit: !state.status,
            // },

            {
                id: 'SPLIT TYPE',
                name: 'SPLIT TYPE',
                selector: row => row?.split_type,
                cell: (row) => <span onClick={() => handleRowClick(row)}> {row?.split_type}</span>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                // width: "140px",
                //  grow: 0.8,
                omit: !state.split_type,
            },

            {
                id: 'REASON',
                name: 'REASON',
                // selector: row => row?.file,
                cell: (row) =>
                    <div className='d-flex flex-row gap-2 align-items-center justify-content-between  '>
                        <div
                            className='text-truncate d-flex flex-row  flex-wrap text-uppercase '
                        >
                            <Tooltip disableInteractive title={row?.reason} placement='top' arrow>
                                <span className='text-truncate' style={{ color: '#6ba2b6 ' }} onClick={() => handleRowClick(row)}>{row?.reason}</span>
                            </Tooltip>
                        </div>

                    </div>,
                sortable: true,
                reorder: true,
                left: true,
                wrap: true,
                width: "160px",
                // grow: 0.6,
                omit: !state.reason,
            },

            // {
            //     id: '',
            //     name: '',
            //     sortable: true,
            //     omit: !state.actions,
            //     reorder: true,
            //     cell: (row) => <React.Fragment>

            //     </React.Fragment>,
            //     left: true,
            //     wrap: true,
            //     width: '40px',
            // },

            {
                id: 'ACTIONS',
                name: 'ACTIONS',
                omit: !state.actions,
                width: "700px",
                cell: (row) =>
                    <React.Fragment>
                        <div>
                            <div className=" d-flex flex-row gap-2  justify-content-start align-items-center no-wrap" onClick={() => handleRowClick(row)}>
                                {!row?.is_freeze ?
                                    <div>
                                        <Tooltip disableInteractive title={row?.is_active ? "Active" : "InActive"} placement='top' arrow>
                                            <div className='d-flex flex-row justify-content-end'>
                                                {row?.is_active ?
                                                    <div className="form-check form-switch " onClick={() => { toggleFormik.setValues({ ...toggleFormik.values, reason: "", lock_box: row?.file_id }); setOpen(true); toggleFormik.validateForm() }}>
                                                        <input className="form-check-input" type="checkbox" checked={row.is_active} defaultChecked={row.is_active} role="switch" id="flexSwitchCheckDefault" />
                                                    </div>
                                                    // <button className='btn btn-success btn-sm ' onClick={() => { toggleFormik.setValues({ ...toggleFormik.values, reason: "", lock_box: row?.file_id }); setOpen(true); toggleFormik.validateForm() }}><i className='bi bi-power fs-7'></i></button>
                                                    :
                                                    <div className="form-check form-switch " onClick={() => { toggleFormik.setValues({ ...toggleFormik.values, reason: "", lock_box: row?.file_id }); setOpen(true); toggleFormik.validateForm() }}>
                                                        <input className="form-check-input" type="checkbox" checked={row.is_active} defaultChecked={row.is_active} role="switch" id="flexSwitchCheckDefault" />
                                                    </div>
                                                }
                                            </div>
                                        </Tooltip>
                                    </div>
                                    :
                                    <div className="form-check form-switch " style={{ visibility: "hidden" }}>
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                    </div>
                                }


                                <div className=''>

                                    <Tooltip disableInteractive title="Comment" placement='top' arrow>
                                        <button className='btn fs-7 fw-bold btn-sm' onClick={() => { setCommentShow(true); commentFormik.setValues({ ...commentFormik.values, reason: "", lock_box: row?.file_id }) }} >
                                            <BiComment className='icon-style' />
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className=''>

                                    <Tooltip disableInteractive title="Comment History" placement='top' arrow>
                                        <button className='btn fs-7 fw-bold btn-sm' onClick={() => getLockboxLogDetails(row?.file_id)} >
                                            <BiHistory className='icon-style' />
                                        </button>
                                    </Tooltip>
                                </div>
                                {row?.is_active && !row?.is_freeze ?
                                    <div className='d-flex gap-2 justify-content-start align-items-center no-wrap ' >
                                        {row?.lbx_file_name ?
                                            <div>
                                                {checkUserPermission('view_files') &&
                                                    <React.Fragment>
                                                        <div onClick={() => { changeAllTitleFunction('LockBox Files', `${SINGLE_LOCKBOX_LOCKBOX_FILES + decodeURIComponent(row?.lbx_file_name).replace(/%20/g, ' ')}`) }} className=" me-1" >
                                                            <button style={{ width: '50px' }} className='btn  fs-7 fw-bold btn-sm'  >
                                                                <span className='text-style-icon'>
                                                                    Files
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                            :
                                            <React.Fragment>
                                                <Link className=" me-1" >
                                                    <button className='btn  fs-7 fw-bold btn-sm' style={{ width: '40px', visibility: "hidden" }} >
                                                        <span className='text-style-icon'>
                                                            Files
                                                        </span>
                                                    </button>
                                                </Link>
                                            </React.Fragment>}



                                        {checkUserPermission('split_file') &&
                                            <React.Fragment>
                                                {row?.status != "generated" ?
                                                    <button onClick={() => setIsSwal({ id: row?.file_id, show: true, data: row })} style={{ width: '50px' }} className='btn fs-7 fw-bold btn-sm '  >
                                                        <span className='text-style-icon'>

                                                            Reject
                                                        </span>
                                                    </button>
                                                    :
                                                    <button className='btn fs-7 fw-bold btn-sm ' style={{ width: '50px', visibility: "hidden", border: "none" }}>
                                                        <span className='text-style-icon'>

                                                            Reject
                                                        </span>
                                                    </button>
                                                }
                                                {/* </Link> */}
                                            </React.Fragment>
                                        }

                                        {row?.is_operation_log_available ?
                                            <React.Fragment>
                                                <React.Fragment>
                                                    {row?.operation_log != "" ?
                                                        <Tooltip disableInteractive title="Regenerate Operation Log" placement='top' arrow>

                                                            <button onClick={() => handleGenerateLockBbox(row)} className='btn fs-7 fw-bold  btn-sm '  >
                                                                {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                                <BiFile className='icon-style' />
                                                            </button>
                                                        </Tooltip>
                                                        :
                                                        row?.operation_log == "" ?

                                                            <Tooltip disableInteractive title="Generate Operation Log" placement='top' arrow>

                                                                <button onClick={() => handleGenerateLockBbox(row)} className='btn fs-7 fw-bold  btn-sm '  >
                                                                    {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                                    <BiFile className='icon-style' />
                                                                </button>
                                                            </Tooltip>
                                                            :
                                                            <button className='btn fs-7 fw-bold  btn-sm' style={{ visibility: "hidden" }} >
                                                                {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                                <BiFile style={{ visibility: "hidden" }} className='icon-style' />
                                                            </button>
                                                    }
                                                </React.Fragment>

                                                {row?.operation_log != "" ?
                                                    <React.Fragment>
                                                        <Tooltip disableInteractive title="Download Operation Log" placement='top' arrow>
                                                            <button onClick={() => handleDownloadOperationLog(row?.operation_log)} className='btn fs-7 fw-bold  btn-sm '  >
                                                                <span className='icon-style'>

                                                                    <i className='bi bi-download'></i>
                                                                </span>
                                                            </button>
                                                        </Tooltip>
                                                    </React.Fragment>
                                                    :
                                                    <div style={{ visibility: "hidden" }}>

                                                        <button className='btn fs-7 fw-bold  btn-sm ' style={{ visibility: "hidden" }} ><span className='icon-style'><i style={{ visibility: "hidden" }} className='bi bi-download'></i> </span> </button>
                                                    </div>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment>

                                                <button className='btn fs-7 fw-bold  btn-sm ' style={{ visibility: "hidden" }} >
                                                    {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                    <BiFile style={{ visibility: "hidden" }} className='icon-style' />
                                                </button>
                                                <button className='btn fs-7 fw-bold  btn-sm ' style={{ visibility: "hidden" }} >
                                                    {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                    <BiFile style={{ visibility: "hidden" }} className='icon-style' />
                                                </button>
                                            </React.Fragment>
                                        }

                                        <React.Fragment>
                                            {row?.bulk_835_zip != "" ?
                                                <Tooltip disableInteractive title="Re Generate Output File" placement='top' arrow>

                                                    <button onClick={() => { handleGenerateOutputZip(row) }} className='btn fs-7 fw-bold  btn-sm '  >
                                                        {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                        <BiFile className='icon-style' />
                                                    </button>
                                                </Tooltip>
                                                : row?.bulk_835_zip == "" ?
                                                    <Tooltip disableInteractive title="Generate Output File" placement='top' arrow>

                                                        <button onClick={() => handleGenerateOutputZip(row)} className='btn fs-7 fw-bold  btn-sm '  >
                                                            {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                            <BiFile className='icon-style' />
                                                        </button>
                                                    </Tooltip> :
                                                    <button style={{ visibility: "hidden" }} className='btn fs-7 fw-bold  btn-sm'  >
                                                        {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                        <BiFile className='icon-style' />
                                                    </button>
                                            }
                                        </React.Fragment>

                                        {row?.bulk_835_zip != "" ?
                                            <React.Fragment>
                                                <Tooltip disableInteractive title="Download Output File" placement='top' arrow>
                                                    <button onClick={() => handleDownloadOutputFile(row?.bulk_835_zip)} className='btn fs-7 fw-bold  btn-sm '  >

                                                        <span className='icon-style'>

                                                            <i className='bi bi-download'></i>
                                                        </span>
                                                    </button>
                                                </Tooltip>

                                                {row?.source_file != "" ?
                                                    <Tooltip disableInteractive title="Download Source File" placement='top' arrow>
                                                        <button onClick={() => handleDownloadSourceFile(row?.source_file)} className='btn fs-7 fw-bold  btn-sm'  >
                                                            <span className='icon-style'>

                                                                <i className='bi bi-download'></i>
                                                            </span>
                                                        </button>
                                                    </Tooltip>


                                                    :
                                                    <button style={{ visibility: "hidden", }} className='btn fs-7 fw-bold  btn-sm '  >
                                                        <span className='icon-style'>

                                                            <i className='bi bi-download'></i>
                                                        </span>
                                                    </button>

                                                }



                                                {loader == row?.file_id ?

                                                    <div style={{ width: "32px" }}>
                                                        <div className="spinner-border spinner-border-sm me-3" style={{ position: "relative", left: "9px" }} role="status">
                                                            {/* <span className="sr-only">Loading...</span> */}
                                                        </div>
                                                    </div>


                                                    :

                                                    <Tooltip title="Send" placement='top' arrow>
                                                        <button disableInteractive style={{ width: "32px" }} className='btn fs-7 fw-bold  btn-sm' onClick={() => row?.is_file_sent_completed ? setSendPopup({ state: true, file: row?.file_id, title: 'Already Sent' }) : row?.edi_validation == "False" ? setSendPopup({ state: true, file: row?.file_id, title: 'EDI Validation Error' }) : handleSendOutputLockbox(row?.file_id)}>
                                                            <BiPaperPlane className='icon-style' />
                                                        </button>
                                                    </Tooltip>

                                                }

                                                {row?.edi_validation != "" ?
                                                    <button disabled={row?.edi_validation == "False" ? false : true} onClick={() => handleEdiValidate(row?.edi_validation_id)} className={row?.edi_validation == "False" ? "btn btn-danger btn-sm edi-text-style-icon-false" : "btn  btn-sm edi-text-style-icon-true "}>
                                                        <span className='edi-text-style-icon'>

                                                            EDI
                                                        </span>
                                                    </button>
                                                    :
                                                    <button disabled={row?.edi_validation == "False" ? false : true} onClick={() => handleEdiValidate(row?.edi_validation_id)} className={row?.edi_validation == "False" ? "btn btn-danger btn-sm edi-text-style-icon-false" : "btn  btn-sm edi-text-style-icon-true "} style={{ fontSize: "0.7em", visibility: "hidden" }}>
                                                        <span className='edi-text-style-icon'>

                                                            EDI
                                                        </span>
                                                    </button>
                                                }
                                            </React.Fragment> :

                                            <React.Fragment>

                                                <button style={{ visibility: "hidden" }} className='btn fs-7 fw-bold btn-sm'  >
                                                    <span className='icon-style'>

                                                        <i className='bi bi-download'></i>
                                                    </span>
                                                </button>
                                                <button style={{ visibility: "hidden" }} className='btn fs-7 fw-bold btn-sm'  >
                                                    <span className='icon-style'>

                                                        <i className='bi bi-download'></i>
                                                    </span>
                                                </button>
                                                <React.Fragment>
                                                    <button style={{ visibility: "hidden", width: "32px" }} className='btn fs-7 fw-bold  btn-sm'>
                                                        <GrSend className='icon-style' />
                                                    </button>
                                                </React.Fragment>

                                                <button className="btn btn-danger btn-sm" style={{ fontSize: "0.7em", visibility: "hidden" }}>
                                                    <span className='text-style-icon'>

                                                        EDI
                                                    </span>
                                                </button>

                                            </React.Fragment>
                                        }

                                    </div>
                                    :
                                    <div className='d-flex gap-2 justify-content-start align-items-center no-wrap ' >
                                        <React.Fragment>
                                            <Link className="me-1" >
                                                <button className='btn  fs-7 fw-bold btn-sm' style={{ width: '50px', visibility: "hidden" }} >
                                                    <span className='text-style-icon'>

                                                        Files
                                                    </span>
                                                </button>
                                            </Link>
                                        </React.Fragment>




                                        <React.Fragment>
                                            <button className='btn fs-7 fw-bold btn-sm' style={{ width: '50px', border: "none", visibility: "hidden" }}>
                                                <span className='text-style-icon'>

                                                    Reject
                                                </span>
                                            </button>

                                            {/* </Link> */}
                                        </React.Fragment>



                                        <React.Fragment>
                                            <React.Fragment>


                                                <button className='btn fs-7 fw-bold  btn-sm' style={{ visibility: "hidden" }} >
                                                    {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                    <BiFile className='icon-style' />
                                                </button>

                                            </React.Fragment>

                                            <div className='btn fs-7 fw-bold  btn-sm ' style={{ visibility: "hidden" }}>
                                                <span className='icon-style'>

                                                    <i className='bi bi-download'></i>
                                                </span>
                                            </div>

                                        </React.Fragment>


                                        <React.Fragment>

                                            <button style={{ visibility: "hidden" }} className='btn fs-7 fw-bold  btn-sm'  >
                                                {/* <i className='bi bi-files fw-bolder text-black'></i> */}
                                                <BiFile className='icon-style' />
                                            </button>
                                            <button style={{ visibility: "hidden" }} className='btn fs-7 fw-bold btn-sm'>
                                                <span className='icon-style'>

                                                    <i className='bi bi-download'></i>
                                                </span>
                                            </button>
                                            <button style={{ visibility: "hidden" }} className='btn fs-7 fw-bold btn-sm'>
                                                <span className='icon-style'>

                                                    <i className='bi bi-download'></i>
                                                </span>
                                            </button>

                                            <React.Fragment>
                                                <button style={{ visibility: "hidden", width: "32px" }} className='btn fs-7 fw-bold  btn-sm'>
                                                    <GrSend className='icon-style' />
                                                </button>
                                            </React.Fragment>
                                            <React.Fragment>

                                                <button className="btn btn-danger btn-sm" style={{ fontSize: "0.7em", visibility: "hidden" }}>
                                                    <span className='text-style-icon'>

                                                        EDI
                                                    </span>
                                                </button>

                                            </React.Fragment>

                                        </React.Fragment>





                                    </div>
                                }

                                {row?.conversion_report != "" &&
                                    <React.Fragment>
                                        <Tooltip disableInteractive title="Download Conversion Report" placement='top' arrow>
                                            <button onClick={() => handleDownloadOperationLog(row?.conversion_report)} className='btn fs-7 fw-bold  btn-sm '  >
                                                <span className='icon-style'>

                                                    <i className='bi bi-download'></i>
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                                {row?.exception_report != "" &&
                                    <React.Fragment>
                                        <Tooltip disableInteractive title="Download Exception Report" placement='top' arrow>
                                            <button onClick={() => handleDownloadOperationLog(row?.exception_report)} className='btn fs-7 fw-bold  btn-sm '  >
                                                <span className='icon-style'>

                                                    <i className='bi bi-download'></i>
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                                {row?.credit_card_report != "" &&
                                    <React.Fragment>
                                        <Tooltip disableInteractive title="Download Credit Card Report" placement='top' arrow>
                                            <button onClick={() => handleDownloadOperationLog(row?.credit_card_report)} className='btn fs-7 fw-bold  btn-sm '  >
                                                <span className='icon-style'>

                                                    <i className='bi bi-download'></i>
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                                {row?.image_snippets_report != "" &&
                                    <React.Fragment>
                                        <Tooltip disableInteractive title="Download Indexing/Image Snippets Report" placement='top' arrow>
                                            <button onClick={() => handleDownloadOperationLog(row?.image_snippets_report)} className='btn fs-7 fw-bold  btn-sm '  >
                                                <span className='icon-style'>

                                                    <i className='bi bi-download'></i>
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                                {row?.epic_output != "" &&
                                    <React.Fragment>
                                        <Tooltip disableInteractive title="Epic Output" placement='top' arrow>
                                            <button onClick={() => handleDownloadOperationLog(row?.epic_output)} className='btn fs-7 fw-bold  btn-sm '  >
                                                <span className='icon-style'>

                                                    <i className='bi bi-download'></i>
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                                {row?.bulk_snippet_zip != "" &&
                                    <React.Fragment>
                                        <Tooltip disableInteractive title="Bulk Snippet Zip" placement='top' arrow>
                                            <button onClick={() => handleDownloadOperationLog(row?.bulk_snippet_zip)} className='btn fs-7 fw-bold  btn-sm '  >
                                                <span className='icon-style'>

                                                    <i className='bi bi-download'></i>
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                            </div>
                        </div>

                    </React.Fragment>
                ,
            },


        ],
        [state, pageno, loader, datetime, loading],
    );

    const toggleFormik = useFormik({
        initialValues: {
            lock_box: "",
            reason: "",
        },
        validate: values => {
            let errors = {};
            if (values?.reason == "") {
                errors.reason = "Required";
            }
            return errors;
        },
        validationSchema: Yup.object().shape({
            reason: Yup.string().required('Required'),
        }),

        onSubmit: (values, { setSubmitting }) => {

            setSubmitting(true)
            // toggleActive(values?.lock_box, values?.reason, setSubmitting);
            toggleLockbox(values?.lock_box, values?.reason ? values?.reason?.toUpperCase() : values?.reason)((response) => {
                if (response?.status) {



                    let created_date = datetime

                    fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)
                    setSubmitting(false)
                    setOpen(false)
                    setToaster('success');
                    const timer = setTimeout(() => {
                        setToaster(false)
                    }, 2000);
                    return () => clearTimeout(timer);
                }
                else {
                    setSubmitting(false)
                    setToaster('error');
                    const timer = setTimeout(() => {
                        setToaster(false)
                    }, 2000);
                    return () => clearTimeout(timer);
                }

            })

        }
    })

    const [open, setOpen] = useState(false);

    const handleSendOutputLockbox = (lockbox) => {
        setLoader(lockbox)
        resendEobOutputFIleSchema(lockbox)((response) => {
            if (response?.status) {
                setToaster('success');
                queueformik.handleSubmit()
                const timer = setTimeout(() => {
                    setLoader(null)
                    setToaster(false)
                }, 2000);
                return () => clearTimeout(timer);
            }
            else {
                setToaster('error');
                const timer = setTimeout(() => {
                    setLoader(null)
                    setToaster(false)
                }, 2000);
                return () => clearTimeout(timer);
            }
        })
    }

    const [defaultValues, setDefaultValues] = useState({})

    const fetchQueueListing = async (page, page_limit, search, client, deposit_date_range, status) => {
        console.log(client);
        setLoading(true);

        fetchAllLockboxFiles({ page, page_limit, search, client, deposit_date_range, status })((response) => {
            if (response?.status) {
                setData(response?.data);
                setPageNo(page)
                setDefaultValues(response?.data?.results?.default_values);
                setLimit(page_limit);
                setTotalRows(response?.data?.count);
                setLoading(false);
            }
        })
    };

    const [checking, setChecking] = useState(true);

    useEffect(() => {

        if (!!checking && defaultValues?.client) {

            setState({
                s_no: defaultValues?.s_no,
                client: defaultValues?.client,
                facility: defaultValues?.facility,
                lbx_file_name: defaultValues?.lbx_file_name,
                lbx_file_count: defaultValues?.lbx_file_count,
                total_file: defaultValues?.total_file,
                index_file_amount: defaultValues?.index_file_amount,
                check_amount: defaultValues?.check_amount,
                balance: defaultValues?.balance,
                eft_amount: defaultValues?.eft_amount,
                plb_amount: defaultValues?.plb_amount,
                no_of_check: defaultValues?.no_of_check,
                total_pages: defaultValues?.total_pages,
                status: defaultValues?.status,
                internal_tat: defaultValues?.internal_tat,
                lbx_file_size: defaultValues?.lbx_file_size,
                file_received_on: defaultValues?.file_received_on,
                output_generated_time: defaultValues?.output_generated_time,
                split_type: defaultValues?.split_type,
                reason: defaultValues?.reason,
                actions: defaultValues?.actions,
                deposit_date: defaultValues?.deposit_date,

            })
            setChecking(false)
        }

    }, [defaultValues]);



    const handlePageChange = page => {
        setPageNo(page)
        let created_date = datetime
        // if (queueformik.values?.deposit_date_range != "") {

        //     const datefrom = moment(queueformik.values?.deposit_date_range['0']).format('MM/DD/YYYY')
        //     const dateto = moment(queueformik.values?.deposit_date_range['1']).format('MM/DD/YYYY')

        //     created_date = datefrom + " - " + dateto
        // }
        fetchQueueListing(page, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status);
    };

    const handlePerRowsChange = async (newPerPage, page) => {

        // let created_date = ""
        // if (queueformik.values?.deposit_date_range != "") {

        //     const datefrom = moment(queueformik.values?.deposit_date_range['0']).format('MM/DD/YYYY')
        //     const dateto = moment(queueformik.values?.deposit_date_range['1']).format('MM/DD/YYYY')

        //     created_date = datefrom + " - " + dateto
        // }
        let created_date = datetime

        fetchQueueListing(1, newPerPage, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)
        setLimit(newPerPage)
        handleResetDataTable()

    };
    useEffect(() => {
        // fetchQueueListing(1, limit,);
        fetchQueueListing(1, limit, queueformik.values?.search, clientName, queueformik.values.created_date, queueformik.values?.status)

    }, []);

    const [errorMessage, setErrorMessage] = useState(false);

    const handleRejectSplit = (pk) => {
        if (rejectText == "Delete") {

            rejectSftpFile(pk)((response) => {
                setIsSwal({ show: false })
                if (response.status) {
                    setToaster('success')
                    const timer = setTimeout(() => {
                        setToaster(false)
                    }, 2000);
                    fetchQueueListing(pageno, limit, queueformik.values?.search, clientName, datetime, queueformik.values?.status)

                    return () => clearTimeout(timer);
                }
                else {
                    setToastMessage({
                        status: true,
                        message: response?.errors?.lockbox,
                        type: 'danger',
                    })

                    // setToaster('error')
                    const timer = setTimeout(() => {
                        setToastMessage(
                            {
                                status: false,
                                message: "",
                                type: "",
                            }

                        )
                    }, 2000);

                    fetchQueueListing(pageno, limit, queueformik.values?.search, clientName, datetime, queueformik.values?.status)

                    // return () => clearTimeout(timer);
                }
            });
        } else {
            setErrorMessage(true)
        }
    }


    const [searchedData, setSearchedData] = useState({
        search: "",
        client: "",
        deposit_date_range: "",
        created_date: "",
        status: "",
    })
    const [reRenderDataTable, setRerenderDataTable] = useState(false)

    const handleResetDataTable = () => {
        setRerenderDataTable(true)
        setTimeout(() => {
            setRerenderDataTable(false)
        }, 200);
    }
    const queueformik = useFormik({
        enableReinitialize: true,
        initialValues: {
            search: "",
            client: '',
            deposit_date_range: [],
            created_date: "",
            status: "",

        },

        onSubmit: (values) => {


            fetchQueueListing(1, limit, values?.search, values?.client, values.created_date, values?.status)
            setSearchedData({
                search: values?.search ? values?.search : "",
                client: values?.client ? values?.client : "",
                deposit_date_range: values?.deposit_date_range ? values?.deposit_date_range : "",
                created_date: values?.created_date ? values?.created_date : "",
                status: values?.status ? values?.status : "",
            })
            handleResetDataTable()
        },

    });

    const handleRowSelected = React.useCallback(state => {
        var ids = [];
        state.selectedRows.map((row, index) => {
            ids.push(row.id);
        })
        setSelectedRows(ids);
    }, []);

    const handleOnDateTimeChange = ({ name, value }) => {
        let created_date = ""
        if (value !== null) {
            const datefrom = moment(value['0']).format('MM/DD/YYYY')
            const dateto = moment(value['1']).format('MM/DD/YYYY')

            created_date = datefrom + " - " + dateto
        }
        setDateTime(created_date)

        queueformik.setValues({ ...queueformik.values, created_date, deposit_date_range: value ?? "" })


    }

    const handleOnSelectClientChange = async (e, name) => {
        let selectedId = []
        let selectedOptons = []

        e.map((option) => {
            selectedId.push(option.value)
            selectedOptons.push({ option })
        })

        queueformik.setValues({ ...queueformik.values, [name]: selectedId })
        setSelectValue(selectedOptons)
        setClientName(selectedId)
    }
    console.log(searchedData, ':::::::::');
    var i = 0;

    const conditionalRowStyles = [

        {
            when: row => { i = i + 1; return i % 2 == 0 || colorRow.file_id == row.file_id ? true : false },
            style: (row, index) => ({
                backgroundColor: colorRow?.file_id == row.file_id ? '#dbf4f5' : '#f2f2f2',
            }),
        },

    ];

    const handleRowClick = (row) => {
        setColorRow(row);
        // // console.log(row);
    };
    const handleRowCheckClick = (row) => {
        data?.results.data.map((item, index) => {
            if (row?.id == item.id) {
                setColorRow(row)
            }
        })
    };

    const DropdownIndicator = ({ state, ...props }) => {
        return (
            <components.DropdownIndicator {...props}>

                {queueformik.values[state]?.length > 1 ? (
                    <TbGridDots style={{ fontSize: "1.5em" }} />
                ) : (
                    <HiChevronDown style={{ fontSize: "1.5em" }} />
                )}



            </components.DropdownIndicator>
        );
    };


    const handleGenerateOutputZip = (row) => {
        setLoading(true)

        generateOutputZip(row?.file_id)((response) => {
            if (response && response?.status) {

                setToastMessage({
                    status: true,
                    message: response?.message,
                    type: "success",
                });

                // let created_date = ""
                // if (queueformik.values?.deposit_date_range) {

                //     const datefrom = moment(queueformik.values?.deposit_date_range['0']).format('MM/DD/YYYY')
                //     const dateto = moment(queueformik.values?.deposit_date_range['1']).format('MM/DD/YYYY')

                //     created_date = datefrom + " - " + dateto
                // }

                let created_date = datetime;

                setTimeout(() => {

                    fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)
                    setLoading(false)

                }, 200);
            }
            else {
                setToastMessage({
                    status: true,
                    message: response?.message,
                    type: "danger",
                })
                fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)
                setLoading(false)

            }

        })
    }

    const handleGenerateLockBbox = (row) => {
        generateOperationLog(row?.file_id)((response) => {
            if (response && response?.status) {
                setToastMessage({
                    status: true,
                    message: response?.message,
                    type: "success",
                });
                // let created_date = "";
                // if (queueformik.values?.deposit_date_range != "") {

                //     const datefrom = moment(queueformik.values?.deposit_date_range['0']).format('MM/DD/YYYY')
                //     const dateto = moment(queueformik.values?.deposit_date_range['1']).format('MM/DD/YYYY')

                //     created_date = datefrom + " - " + dateto
                // }
                let created_date = datetime;
                setTimeout(() => {
                    fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)                // setToaster('success')
                }, 200);

            }
            else {
                setToastMessage({
                    status: true,
                    message: response?.message,
                    type: "danger",
                })
                setTimeout(() => {
                    fetchQueueListing(pageno, limit, searchedData?.search, searchedData?.client, searchedData?.created_date, searchedData?.status)                // setToaster('success')
                }, 200);

            }

        })
    }

    const statusOption = [
        { value: "received", label: "New" },
        { value: "inprocess", label: "In process" },
        { value: "completed", label: "Completed" },
        { value: "generated", label: "Generated" },
        { value: "rejected", label: "Rejected" },
    ];

    const handleEdiValidate = (id) => {
        ediValidate835List(id)((response) => {
            if (response?.status) {
                setIsEdi({
                    status: true,
                    data: response?.data?.edi_validation,
                })
            }
            else {
                setIsEdi({
                    status: true,
                    data: "",

                })
            }
        })

    };

    const [rejectText, setRejectText] = useState("")
    const [isFocusedClient, setIsFocusedClient] = useState(false);
    const [isFocusedStatus, setIsFocusedStatus] = useState(false);
    window.onkeyup = function (event) {
        console.log(event);
        if (event.key === 'Tab') {
            // Prevent the default behavior of the tab key
            let cancel = document.getElementsByClassName('cancel-btn');
            let confirm = document.getElementsByClassName('btn-confirm');
            if (cancel[0] && confirm[0]) {
                event.preventDefault();
                if (cancel[0] && cancel[0].classList.contains('btn-focus-active')) {
                    cancel[0].classList.remove('btn-focus-active')
                    confirm[0].classList.add('btn-focus-active')
                    confirm[0].focus();
                }
                else if (confirm[0] && confirm[0].classList.contains('btn-focus-active')) {
                    confirm[0].classList.remove('btn-focus-active')
                    cancel[0].classList.add('btn-focus-active')
                    cancel[0].focus();
                }
                else {
                    cancel[0].classList.add('btn-focus-active')
                    cancel[0].focus();
                }
            }

        }
    }
    // function handleTabKey(event) {
    //     // Check if the pressed key is the tab key
    //     if (event.key === 'Tab') {
    //         // Prevent the default behavior of the tab key
    //         event.preventDefault();

    //         // Manually manage focus inside the SweetAlert dialog
    //         const focusableElements = Swal.getPopup().querySelectorAll('button, input, select, textarea, a, [tabindex]:not([tabindex="-1"])');
    //         const firstElement = focusableElements[0];
    //         const lastElement = focusableElements[focusableElements.length - 1];

    //         if (event.shiftKey) {
    //             if (document.activeElement === firstElement) {
    //                 lastElement.focus();
    //             } else {
    //                 const index = Array.from(focusableElements).indexOf(document.activeElement);
    //                 focusableElements[index - 1].focus();
    //             }
    //         } else {
    //             if (document.activeElement === lastElement) {
    //                 firstElement.focus();
    //             } else {
    //                 const index = Array.from(focusableElements).indexOf(document.activeElement);
    //                 focusableElements[index + 1].focus();
    //             }
    //         }
    //     }
    // }
    console.log(data)
    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} />

            <main id="main" className="main">

                {sendPopup?.state &&
                    <SweetAlert
                        // custom
                        warning
                        // showCloseButton
                        closeOnClickOutside={false}
                        showCancel
                        focusCancelBtn
                        confirmBtnText="Send"
                        cancelBtnText='Cancel'
                        // reverseButtons
                        cancelBtnCssClass="btnHover cancel-btn btn-focus-active"
                        confirmBtnCssClass='btn-confirm'
                        // cancelButtonText="Cancel"
                        cancelBtnBsStyle="primary"
                        confirmBtnBsStyle="secondary"
                        title=<div> <div className='text-danger'>{sendPopup?.title} </div><div className='fs-8'>Do you want to continue? </div></div>
                        onCancel={() => setSendPopup({ state: false, file: '', title: '' })}
                        onConfirm={() => { handleSendOutputLockbox(sendPopup?.file); setSendPopup({ state: false, file: '' }) }}
                    // onOpen={() =>
                    //     // Add an event listener to capture the tab key press
                    //     document.addEventListener('keydown', handleTabKey)
                    // }
                    // onClose={() =>
                    //     // Clean up the event listener when the dialog is closed
                    //     document.removeEventListener('keydown', handleTabKey)
                    // }

                    >
                    </SweetAlert>
                }


                {isSwal.show === true &&
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Reject!"
                        //confirmBtnBsStyle="danger"
                        confirmBtnCssClass="custom-confirm-button-class"
                        cancelBtnCssClass="custom-cancel-button-class"
                        title="Are you sure?"
                        onConfirm={() => { handleRejectSplit(isSwal.id) }}
                        onCancel={() => { setIsSwal({ ...isSwal, show: false }) }}
                        focusCancelBtn
                    >
                        <div className='user-select-none'>

                            <p>
                                There are <span className='fw-bold'>{isSwal?.data?.file_completed} jobs</span> completed in this lockbox.
                                Reject action can't be reverted back.
                            </p>

                            <br />

                            <p className='my-3 fw-bold'>Type <span className='text-danger'>"Delete"</span> to Reject  <br /><span className='color-xtract'>{isSwal.data?.lbx_file_name}</span></p>

                            <Form.Control onChange={(e) => {
                                setRejectText(e.target.value); setErrorMessage(false)
                            }} type="text" className={errorMessage ? "form-control-solid text-center b-r-10 my-3 border-danger" : 'form-control-solid text-center b-r-10 my-3'} />

                        </div>

                    </SweetAlert>
                }

                {isEdi?.status && (
                    <SweetAlert
                        confirmBtnText="Ok!"
                        //confirmBtnBsStyle="success"
                        style={{ width: '110em' }}
                        confirmBtnCssClass="custom-confirm-button-class"
                        onConfirm={() => {
                            setIsEdi({
                                status: false,
                                data: "",
                            });
                        }}
                    >
                        <span className="text-dark fw-bold fs-6">
                            {isEdi?.data.split('|').map((line, index) => (
                                <React.Fragment key={index}>
                                    <div className="" style={{ textAlign: 'left' }}>

                                        {line}
                                    </div>
                                    <br />
                                </React.Fragment>
                            ))}
                        </span>

                    </SweetAlert>
                )}
                <div className="pagetitle tab-fixed-header ">
                    <h1 id='page-title'>LockBox</h1>
                </div>

                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg={ToastMessage?.type} onClose={() => setToastMessage(
                        {
                            status: false,
                            message: "",
                            type: "",
                        }

                    )}
                        show={ToastMessage?.status} animation={true} delay={2000} autohide>
                        <Toast.Body ><span className='text-white'>{ToastMessage?.message}</span></Toast.Body>
                    </Toast>

                </ToastContainer>

                <section className="section mt-5">
                    <div className="row align-items-top py-2 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">
                                <Form onSubmit={queueformik.handleSubmit} onReset={() => {
                                    window.location.reload()
                                }}>
                                    <div className='' >
                                        <div className="d-flex flex-row justify-content-start gap-2 align-items-center px-4 py-4 flex-wrap py-3">
                                            <div className='jobmonitor-select'>
                                                <TextField
                                                    id="search"
                                                    className="form-control form-control-solid fileManagePadding b-r-10"
                                                    name='search'
                                                    type="search"
                                                    style={{ width: "270px", fontSize: "17px" }}
                                                    placeholder="Filename / Client / Facility"
                                                    aria-label="Search Input"
                                                    value={queueformik.values?.search}
                                                    onChange={queueformik.handleChange}
                                                />
                                            </div>
                                            <div className='jobmonitor-select'>
                                                <Select
                                                    isMulti
                                                    options={clientList}
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="client" /> }}
                                                    className="select-search-client "
                                                    classNamePrefix="select"
                                                    placeholder="Select Client"
                                                    values={selectValue}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={300}
                                                    isClearable
                                                    name="client"
                                                    onChange={(e) => handleOnSelectClientChange(e, 'client')}
                                                    defaultValue={queueformik?.values?.client}
                                                    onFocus={() => setIsFocusedClient(true)}
                                                    onBlur={() => setIsFocusedClient(false)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedClient ? "auto" : "30px",
                                                        }),

                                                    }} />
                                            </div>
                                            <div className='' >
                                                <Stack direction="column" spacing={8} alignItems="flex-start">

                                                    <DateRangePicker
                                                        ranges={predefinedBottomRanges}
                                                        // style={{ backgroundColor: "#000" }}
                                                        cleanable={true}
                                                        placement='auto'
                                                        appearance="default"
                                                        format={"MM/dd/yyyy"}
                                                        placeholder={"MM/DD/YYYY - MM/DD/YYYY"}
                                                        className="form-date-control form-control-solid b-r-1 text-dark jobmonitor-datepicker"
                                                        name="deposit_date_range"
                                                        onChange={(e) => { handleOnDateTimeChange({ name: 'deposit_date_range', value: e }) }} value={queueformik?.values?.deposit_date_range} />

                                                </Stack>
                                            </div>

                                            <div className='jobmonitor-select' >
                                                <Select
                                                    isMulti
                                                    options={statusOption}
                                                    className="select-search-client lockbox-search-select"
                                                    classNamePrefix="select"
                                                    components={{ DropdownIndicator: (props) => <DropdownIndicator {...props} state="status" /> }}
                                                    placeholder="Select Status"
                                                    values={selectValue}
                                                    menuPlacement="auto"
                                                    maxMenuHeight={300}
                                                    isClearable
                                                    name="status"
                                                    onChange={(e) => handleOnSelectClientChange(e, 'status')}
                                                    defaultValue={queueformik?.values?.status}
                                                    onFocus={() => setIsFocusedStatus(true)}
                                                    onBlur={() => setIsFocusedStatus(false)}
                                                    styles={{
                                                        container: (styles, state) => ({
                                                            ...styles,
                                                            borderRadius: "9px",
                                                        }),
                                                        placeholder: styles => ({
                                                            ...styles,
                                                            fontSize: '17px'
                                                        }),
                                                        control: (styles, state) => ({
                                                            ...styles,
                                                            backgroundColor: "hsl(204deg 33.33% 97.06%)",
                                                            borderStyle: "none", boxShadow: "none",
                                                            borderRadius: "10px",
                                                            maxHeight: state.isFocused ? "auto" : "30px",
                                                        }),
                                                        indicatorSeparator: styles => ({
                                                            ...styles, width: "0px",
                                                            minHeight: "20px"
                                                        }),

                                                        valueContainer: (styles, state) => ({
                                                            ...styles,
                                                            maxHeight: isFocusedStatus ? "auto" : "30px",
                                                        }),

                                                    }}
                                                />
                                            </div>



                                            <div className='' style={{ width: '' }}>
                                                <div className='d-flex justify-content-start gap-2'>
                                                    <Tooltip disableInteractive title="Search" placement='top' arrow>
                                                        <Button className="btn btn-light btn-active-primary  " type="submit">
                                                            <span className="svg-icon svg-icon-3">
                                                                <BiSearch />
                                                            </span>

                                                        </Button>
                                                    </Tooltip>
                                                    <Tooltip disableInteractive title="Reset" placement='top' arrow>
                                                        <Button className="btn btn-light btn-active-primary " type='reset' >
                                                            <span className="svg-icon svg-icon-3">
                                                                <BiReset />
                                                            </span>

                                                        </Button>
                                                    </Tooltip>


                                                    <div className="dropdown" >
                                                        <Tooltip disableInteractive title="Table Headings" placement='top' arrow>
                                                            <button className="btn btn-light btn-active-primary pb-2 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <IoIosSwitch />
                                                            </button>
                                                        </Tooltip>

                                                        <ul className="dropdown-menu" style={{ height: '500px', overflow: 'scroll' }} onClick={(e) => e.stopPropagation()}>
                                                            {/* <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.s_no} defaultChecked={state.s_no} onChange={() => setState({ ...state, s_no: !state.s_no })} type="checkbox" id="s_no" />
                                                                        <label className="form-check-label" htmlFor="s_no">
                                                                            S.NO
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li> */}
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.client} defaultChecked={state.client} onChange={() => setState({ ...state, client: !state.client })} type="checkbox" id="client" />
                                                                        <label className="form-check-label" htmlFor="client">
                                                                            Client
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.facility} defaultChecked={state.facility} onChange={() => setState({ ...state, facility: !state.facility })} type="checkbox" id="amount" />
                                                                        <label className="form-check-label" htmlFor="amount">
                                                                            Facility
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.lbx_file_name} defaultChecked={state.lbx_file_name} onChange={() => setState({ ...state, lbx_file_name: !state.lbx_file_name })} type="checkbox" id="lbx_file_name" />
                                                                        <label className="form-check-label" htmlFor="lbx_file_name">
                                                                            LBX FILE NAME
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.lbx_file_count} defaultChecked={state.lbx_file_count} onChange={() => setState({ ...state, lbx_file_count: !state.lbx_file_count })} type="checkbox" id="lbx_file_count" />
                                                                        <label className="form-check-label" htmlFor="lbx_file_count">
                                                                            LBX FILE COUNT
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.total_file} defaultChecked={state.total_file} onChange={() => setState({ ...state, total_file: !state.total_file })} type="checkbox" id="total_file" />
                                                                        <label className="form-check-label" htmlFor="facility">
                                                                            TOTAL FILE
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.index_file_amount} defaultChecked={state.index_file_amount} onChange={() => setState({ ...state, index_file_amount: !state.index_file_amount })} type="checkbox" id="index_file_amount" />
                                                                        <label className="form-check-label" htmlFor="index_file_amount">
                                                                            Index File Amount                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.check_amount} defaultChecked={state.check_amount} onChange={() => setState({ ...state, check_amount: !state.check_amount })} type="checkbox" id="check_amount" />
                                                                        <label className="form-check-label" htmlFor="check_number">
                                                                            Check Amount
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>



                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.balance} defaultChecked={state.balance} onChange={() => setState({ ...state, balance: !state.balance })} type="checkbox" id="balance" />
                                                                        <label className="form-check-label" htmlFor="balance">
                                                                            Balance
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>


                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.eft_amount} defaultChecked={state.eft_amount} onChange={() => setState({ ...state, eft_amount: !state.eft_amount })} type="checkbox" id="eft_amount" />
                                                                        <label className="form-check-label" htmlFor="eft_amount">
                                                                            EFT AMOUNT
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.plb_amount} defaultChecked={state.plb_amount} onChange={() => setState({ ...state, plb_amount: !state.plb_amount })} type="checkbox" id="plb_amount" />
                                                                        <label className="form-check-label" htmlFor="plb_amount">
                                                                            PLB AMOUNT
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>



                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.no_of_check} defaultChecked={state.no_of_check} onChange={() => setState({ ...state, no_of_check: !state.no_of_check })} type="checkbox" id="no_of_check" />
                                                                        <label className="form-check-label" htmlFor="facility">
                                                                            # CHECKS
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.total_pages} defaultChecked={state.total_pages} onChange={() => setState({ ...state, total_pages: !state.total_pages })} type="checkbox" id="total_pages" />
                                                                        <label className="form-check-label" htmlFor="total_pages">
                                                                            # PAGES
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.status} defaultChecked={state.status} onChange={() => setState({ ...state, status: !state.status })} type="checkbox" id="status" />
                                                                        <label className="form-check-label" htmlFor="status">
                                                                            STATUS
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.internal_tat} defaultChecked={state.internal_tat} onChange={() => setState({ ...state, internal_tat: !state.internal_tat })} type="checkbox" id="internal_tat" />
                                                                        <label className="form-check-label" htmlFor="internal_tat">
                                                                            Internal Tat
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.lbx_file_size} defaultChecked={state.lbx_file_size} onChange={() => setState({ ...state, lbx_file_size: !state.lbx_file_size })} type="checkbox" id="lockbox" />
                                                                        <label className="form-check-label" htmlFor="lockbox">
                                                                            LBX File Size
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.file_received_on} defaultChecked={state.file_received_on} onChange={() => setState({ ...state, file_received_on: !state.file_received_on })} type="checkbox" id="file_received_on" />
                                                                        <label className="form-check-label" htmlFor="reason_rejection">
                                                                            File Recieved Date & time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.deposit_date} defaultChecked={state.deposit_date} onChange={() => setState({ ...state, deposit_date: !state.deposit_date })} type="checkbox" id="file_received_on" />
                                                                        <label className="form-check-label" htmlFor="deposit_date">
                                                                            Deposit Date
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.output_generated_time} defaultChecked={state.output_generated_time} onChange={() => setState({ ...state, output_generated_time: !state.output_generated_time })} type="checkbox" id="output_generated_time" />
                                                                        <label className="form-check-label" htmlFor="output_generated_time">
                                                                            Output Generate Time
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.split_type} defaultChecked={state.split_type} onChange={() => setState({ ...state, split_type: !state.split_type })} type="checkbox" id="split_type" />
                                                                        <label className="form-check-label" htmlFor="split_type">
                                                                            Split Type
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.reason} defaultChecked={state.reason} onChange={() => setState({ ...state, reason: !state.reason })} type="checkbox" id="reason" />
                                                                        <label className="form-check-label" htmlFor="reason">
                                                                            Reason
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='dropdown-item'>

                                                                    <div className="form-check">
                                                                        <input className="form-check-input" checked={state.actions} defaultChecked={state.actions} onChange={() => setState({ ...state, actions: !state.actions })} type="checkbox" id="actions" />
                                                                        <label className="form-check-label" htmlFor="actions">
                                                                            Actions
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </Form>
                                <Modal size="lg" show={logShow} onHide={() => { setLogShow(false), setLogData([]) }} centered className='manual-tat'>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Logs</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <table className="table history-log-table" >
                                            <thead>
                                                <tr >
                                                    <th >LockBox</th>
                                                    <th >File Status</th>
                                                    <th >Comment</th>
                                                    <th >User Done</th>
                                                    {/* <th ></th> */}
                                                </tr>
                                            </thead>
                                            {logData && logData?.map((log, index) =>
                                                <tbody key={index}>
                                                    <tr >
                                                        <td>{log?.lockbox}</td>
                                                        <td>{log?.file_status}</td>
                                                        <td className='text-break text-uppercase'>{log?.comment}</td>
                                                        <td>{log?.user_done}</td>

                                                    </tr>
                                                </tbody>
                                            )}
                                        </table>
                                    </Modal.Body>
                                </Modal>

                                <Modal show={open} onHide={() => { toggleFormik.handleReset(); setOpen(false) }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Comment</Modal.Title>
                                    </Modal.Header>
                                    <Form className='py-3' onSubmit={toggleFormik.handleSubmit} onReset={() => { toggleFormik.handleReset(); setOpen(false) }} >
                                        <Modal.Body>
                                            <Form.Group>
                                                <Form.Control onChange={toggleFormik.handleChange} isInvalid={!!toggleFormik.errors.reason} className='b-r-10 text-uppercase' name='reason' as="textarea" rows={3} />
                                                <Form.Control.Feedback type='invalid'>{toggleFormik.errors.reason}</Form.Control.Feedback>
                                            </Form.Group>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button type='button' onClick={() => { toggleFormik.handleReset(); setOpen(false) }} variant="secondary" >
                                                Close
                                            </Button>
                                            <Button type="submit" disabled={toggleFormik.isSubmitting} className='btn btn-light btn-active-primary' >
                                                Submit
                                            </Button>
                                        </Modal.Footer>
                                    </Form>
                                </Modal>

                                <Modal show={commentShow} onHide={() => { setCommentShow(false) }}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Comment</Modal.Title>
                                    </Modal.Header>
                                    <Form className='py-3' onSubmit={commentFormik.handleSubmit} onReset={() => { commentFormik.handleReset(); setCommentShow(false) }} >
                                        <Modal.Body>
                                            <Form.Group>
                                                <Form.Control onChange={commentFormik.handleChange} isInvalid={!!commentFormik.errors.reason} className='b-r-10 text-uppercase' name='reason' as="textarea" rows={3} />
                                                <Form.Control.Feedback type='invalid'>{commentFormik.errors.reason}</Form.Control.Feedback>
                                            </Form.Group>

                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button type='button' onClick={() => { commentFormik.handleReset(); setCommentShow(false) }} variant="secondary" >
                                                Close
                                            </Button>
                                            <Button type="submit" disabled={commentFormik.isSubmitting} className="btn btn-light btn-active-primary  ">
                                                Submit
                                            </Button>
                                        </Modal.Footer>
                                    </Form>
                                </Modal>
                                <div className="card-body" style={{ padding: "0px 2.25rem" }}>
                                    <div className="table-responsive">
                                        <div className="dashboardtable">
                                            {!reRenderDataTable &&
                                                <DataTable
                                                    className="custom-datatable"
                                                    fixedHeader={true}

                                                    data={data?.results?.data}
                                                    columns={columns}
                                                    progressPending={loading}
                                                    pagination
                                                    paginationServer
                                                    onSelectedRowsChange={handleRowSelected}
                                                    paginationTotalRows={totalRows}
                                                    paginationPerPage={limit}
                                                    paginationRowsPerPageOptions={[25, 50, 100]}
                                                    onChangeRowsPerPage={handlePerRowsChange}
                                                    onChangePage={handlePageChange}
                                                    conditionalRowStyles={conditionalRowStyles}
                                                    onRowClicked={handleRowCheckClick}
                                                    theme="solarized"
                                                    pointerOnHover
                                                    customStyles={queueDatatableCustomStyles}
                                                    responsive={true}
                                                // onSort={handleSort}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        </React.Fragment >
    )
}

export default AllLockbox;
