import axiosInstance from "../../axiosInstance";
import { GET_NEW_CLAIMS_FILING_URL, POST_NEW_CREATE_UPDATE_CLAIMS_FILING_URL, GET_NEW_SINGLE_CLAIMS_FILING_URL, GET_NEW_CLAIMS_FILING_DROPDOWN, DELETE_NEW_CLAIMS_FILING, GET_ALL_USER_WISE_DBK_URL, CREATE_UPDATE_USER_WISE_DBK_URL, DELETE_USER_WISE_DBK_URL, ACTIVE_USER_WISE_DBK_URL, GET_SINGLE_USER_WISE_DBK_URL } from "../../api";


export const getListUserWiseDbk = ({page, limit, search}) => onResponse => {
    try {

        let data = search ? search : "";
        axiosInstance.get(GET_ALL_USER_WISE_DBK_URL + "?page=" + page + "&limit=" + limit + "&search=" + data)
            .then((response) => {
                console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const createUpdateUserWiseDbk = (data) => onResponse => {
    // const claim_id = props.claim_id ? props.claim_id : ""
    // const client = props.client ? props.client : ""
    // const group = props.group ? props.group : ""
    // const code_definition = props.data ? props.data : []
    console.log(data);
    try {

        axiosInstance.post(CREATE_UPDATE_USER_WISE_DBK_URL, data )
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }
};

export const getSingleUserWiseDbk = (dbk) => onResponse => {
    try {
        axiosInstance.get(GET_SINGLE_USER_WISE_DBK_URL + '?dbk=' + dbk)
            .then((response) => {
                //console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};


export const deleteUserWiseDbk = (id) => onResponse => {
    console.log(id)
    try {
        axiosInstance.put(ACTIVE_USER_WISE_DBK_URL, {
            dbk: id

        })
            .then((response) => {
                console.log(response)
                onResponse(response.data)
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });
    } catch (error) {

    }
} 