import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import { Toast, ToastContainer, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AdminMaster from '../../../Layout/admin/Master'
import { DOCUMENT_CLASSIFICATION, PARTNER_LIST, REASON } from '../../../routeNames';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { createReason } from '../../../Redux/reason/action';
import { getAllClientDropdown } from '../../../Redux/client/action';
import * as Yup from 'yup';
import { changeAllTitleFunction, getRedirectTabId } from '../Dashboard/functions';

const CreateUpdateReason = (props) => {

    // let { status } = useParams();

    const navigate = useNavigate()
    const [state, setState] = useState({
        showToast: false,
        error: false,
        error_message: "",
    })
    const [toaster, setToaster] = useState(null);
    const [message, setMessage] = useState(null);



    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            client: "",
            // clients: [],
            incompletestatus: [{
                status: "",
            }],
        },

        validationSchema: Yup.object().shape({
            incompletestatus: Yup.array().of(
                Yup.object().shape({
                    status: Yup.string().required("Required"),
                }),
            ),
        }),




        onSubmit: (values) => {
            // let formData = new FormData();    //formdata object

            // formData.append('status', values?.incompletestatus);


            const RedirectId = getRedirectTabId()
            createReason(values?.client, values?.incompletestatus)((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    // setState({ showToast: !state.showToast })
                    setToaster('success')
                    setTimeout(() => {
                        changeAllTitleFunction("Incomplete Status", REASON, RedirectId)
                    }, 3000);
                } else {
                    formik.setErrors({
                        // payee_id: response?.response?.data?.errors?.payee_id,
                        status: response?.response?.data?.errors?.status,
                    })
                    setToaster('error')
                    if (response?.response?.data?.errors?.status && response?.response?.data?.errors?.status[0]) {
                        setMessage(response?.response?.data?.errors?.status[0])
                    } else {
                        setToaster('error')
                    }
                    setState({ error: true, error_message: response?.message });
                    setTimeout(() => {
                        setToaster('')

                    }, 2000);
                }
            });

        },
    });

    const [clients, setClients] = useState([])

    useEffect(() => {

        getAllClientDropdown()((response) => {
            if (response?.status) {
                setClients(response?.data)
            }
        })

        // if (status) {

        //     getSingleReasonDetail(status)((response) => {
        //         if (response?.status) {
        //             formik.setValues({
        //                 ...formik.values,
        //                 status: response?.data?.partner?.status ? response?.data?.partner?.status : "",
        //             })
        //         }
        //     })

        // }
    }, []);


    return (
        <React.Fragment>
            <AdminMaster ToastMessage={toaster} message={message} />
            <main id="main" className="main">
                <div className="pagetitle tab-fixed-header">
                    <h1>Create Reason </h1>

                </div>

                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">

                                    <FormikProvider value={formik}>


                                        <Form
                                            onSubmit={formik.handleSubmit}
                                        >
                                            <div className="mb-3 " >





                                                <div className='' style={{ width: '30rem' }}>
                                                    <Form.Group className="mb-3" >

                                                        <Form.Label className='fw-bold'>Client</Form.Label>
                                                        <Form.Control as="select"
                                                            name='client'
                                                            type="text"
                                                            onChange={formik.handleChange}
                                                            autoComplete="false"
                                                            isValid={formik.touched.client && !formik.errors.client}
                                                            isInvalid={!!formik.errors.client}
                                                            value={formik.values.client}
                                                            className='b-r-10 form-control-solid'
                                                            maxLength="255"
                                                            placeholder="client"
                                                        >
                                                            <option value="">Select An Option </option>
                                                            {clients && clients?.map((client, index) => (
                                                                <option key={index} value={client?.value}>{client?.label}</option>
                                                            ))}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">{formik.errors.client}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </div>
                                                <div className=''>
                                                    <Form.Label className='fw-bold'>Incomplete Status</Form.Label>
                                                </div>
                                                <FieldArray name="incompletestatus"
                                                    render={(arrayHelpers) => (

                                                        <React.Fragment>
                                                            <div className='d-flex flex-row justify-content-start  gap-2 flex-wrap' style={{ width: '100%' }}>
                                                                <div className=''>
                                                                    {formik?.values?.incompletestatus?.map((data, index) => (
                                                                        <React.Fragment>
                                                                            <div className='d-flex flex-row justify-content-start gap-2 flex-wrap pt-2' >
                                                                                <div className='' style={{ width: '40rem' }}>

                                                                                    <Form.Group className="" >

                                                                                        {/* <Form.Label className='fw-bold'>status</Form.Label> */}
                                                                                        <Form.Control
                                                                                            name={`incompletestatus.[${index}].status`}
                                                                                            type="text"
                                                                                            onChange={formik.handleChange}
                                                                                            autoComplete="false"
                                                                                            isValid={formik.touched.incompletestatus && formik.touched.incompletestatus[index] && formik.touched.incompletestatus[index]?.status && formik.errors.incompletestatus && formik.errors.incompletestatus[index] && !!formik.errors.incompletestatus[index]?.status}
                                                                                            isInvalid={!!formik.errors.incompletestatus && formik.errors.incompletestatus[index] && !!formik.errors.incompletestatus[index]?.status}
                                                                                            value={data?.status}
                                                                                            className='b-r-10 form-control-solid'
                                                                                            maxLength="255"
                                                                                            placeholder="Reason"
                                                                                        />
                                                                                        <Form.Control.Feedback type="invalid">{formik.errors.incompletestatus && formik.errors.incompletestatus[index] && formik.errors.incompletestatus[index]?.status}</Form.Control.Feedback>

                                                                                    </Form.Group>
                                                                                </div>
                                                                                <div className=''>

                                                                                    <button
                                                                                        className='btn btn-danger'
                                                                                        type="button"
                                                                                        disabled={formik?.values?.incompletestatus?.length == 1 ? true : false}
                                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                                    >
                                                                                        <i className='bi bi-trash'></i>
                                                                                    </button>
                                                                                </div>
                                                                                { }
                                                                                <div className=''>
                                                                                    {formik?.values?.incompletestatus.length - 1 == index ?
                                                                                        <button
                                                                                            className='btn  btn-primary '
                                                                                            style={{ backgroundColor: '#6ba2b6', borderColor: '#6ba2b6', height: '36px' }}
                                                                                            type="button"
                                                                                            onClick={() => arrayHelpers.push({
                                                                                                status: "",
                                                                                            })}
                                                                                        >
                                                                                            +

                                                                                        </button>
                                                                                        :
                                                                                        <></>
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        </React.Fragment>
                                                                    ))}
                                                                </div>
                                                            </div>

                                                        </React.Fragment>
                                                    )
                                                    }
                                                />
                                            </div>

                                            <div className='d-flex flex-row justify-content-end'>

                                                <div className=''>
                                                    <button
                                                        type='button'
                                                        className='btn btn-secondary btn-active-primary me-2'
                                                        onClick={() => { changeAllTitleFunction("Incomplete Status", REASON) }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div className=''>
                                                    <button
                                                        disabled={!formik.isValid}
                                                        type='submit'
                                                        className='btn btn-primary btnHover'
                                                    >
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    </FormikProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </React.Fragment >
    )
}

export default CreateUpdateReason