import React, { useEffect, useState, useRef } from "react";
import Draggable from "react-draggable";
import {
    assignJobFromQa,
    assignJobFromQaV2,
    checkExcludedPayer,
    createOrUpdateCorres,
    createOrUpdatePatientPay,
    createOrUpdatePayment835,
    createUpdate835Lite,
    rejectFile,
    search837file,
    search837fileAll,
    searchPayeeLookup,
    searchPayerLookup,
    verifyPayerRc,
} from "../../../../Redux/grid/action";
import GridHeader from "./GridHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import Eob835 from "./Eob835/eob835";
import PatientPay from "./PatientPay/patientPay";
import moment from "moment";
import Correspondence from "./Correspondence/corresPondence";
import GridFooter from "./GridFooter/gridFooter";
import { useNavigate } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import EobLite from "./EobLite/eobLite";
import { DASHBOARD, DATA_ENTRY, EDIT_WORK, QA_REVIEW } from "../../../../routeNames";
import Servicelines from "./Eob835/servicelines";
import HotKey from "./Elements/hotkey";
import { createPropCode } from "../../../../Redux/user/action";
import { changeAllTitleFunction, checkDbkValidation, disableBasedOnSubJob, disablingBasedOnDbk, disablingBasedOnGridView, getNextFocusableElement, getRedirectTabId, isActiveDbkField } from "../../Dashboard/functions";
import { minValidDate, removeKeysFromForEobForQDXPatientPay } from "./gridFunctions";
import Swal from 'sweetalert2';
import styled from 'styled-components'
import QaErrorModal from "./GridHeader/qaErrorModal";
import Modal from "react-modal";

const Grid = (props) => {
    const {
        jobErrorAlert,
        setjobErrorAlert,
        job_id,
        log_id,
        fileUrl,
        jobData,
        setJobData,
        setNoJOb,
        toast,
        setToast,
        currentPage,
        totalPages,
        rotatePosition,
        getGridData,
        setFileUrl,
        setErrorAlert,
        errorAlert,
        isSubJob,
        setIsSubJob,
        isFirstJob,
        setIsFirstJob,
        subJobOptions,
        initialPage,
        setInitialPage,
        jumpToPage,
        error837,
        setError837,
        pdfLoaded,
        jobUserData,
        setLoadData,
        activePdf,
        firstSaveLoad,
        secondSaveLoad,
        setFirstSaveLoad,
        setSecondSaveLoad,
        setformApiLoaded,
        setSaveSuccess,
        currentJobVerified,
        loadData,
        firstLoad
    } = props;




    const navigate = useNavigate();
    const pathname = window.location.pathname;
    window.scrollTo(0, document.body.scrollHeight);

    // ========================= GRID STATES AND FUNCTIONS =========================//
    const [qaErrorModal, setQaErrorModal] = useState(false)
    const [qaErrorModalSave, setQaErrorModalSave] = useState(false)
    const gridRef = useRef(null);
    //state for transaction types and summary
    const [state, setState] = useState({
        updateDisable: false,
        // header states
        Payment835: false,
        Payment835Lite: false,
        PatientPay: false,
        Correspondence: false,

        EobBlock: false,
        EobLiteBlock: true,
        PatientPayBlock: false,

        claim_details: true,
        claimlite_details: true,
        patient_claim_details: false,
        correspondence_details: false,
        patient_correspondence_details: false,
    });

    const [loading, setLoading] = useState(true);
    const [hoveredIndex, setHoveredIndex] = useState();

    const [isSwal, setIsSwal] = useState(false);
    const [payerLiteFocus, setPayerLiteFocus] = useState(false);
    const [payeeLiteFocus, setPayeeLiteFocus] = useState(false);
    const [claimCorresIndex, setClaimCorresIndex] = useState(0);
    const [claim835Index, setClaim835Index] = useState(0);
    const [serviceIndex, setServiceIndex] = useState(0);
    const [hotKey, setHotKey] = useState(false);
    const [isCorres835, setIsCorres835] = useState(false)
    const [isPP835, setIsPP835] = useState(false)

    const [isAdditional, setIsAdditional] = useState(false)
    const [overallPage, setOverallPage] = useState({
        from: "1", to: "1"
    })

    const [isGeneratedCheckNumber, setIsGeneratedCheckNumber] = useState(false)
    const [isIncompleteCheckNumber, setIsIncompleteCheckNumber] = useState(false)

    const [isOverallPage, setIsOverallPage] = useState(false)
    const [isPayerPage, setIsPayerPage] = useState(false)
    const [isPayeePage, setIsPayeePage] = useState(false)
    const [isPatientPageFromTo, setIsPatientPageFromTo] = useState(false)

    const [isAdditionalInfoOptions, setIsAdditionalInfoOptions] = useState({
        status: false,
        reason: false,
        document_classification: false,
        extra_info: false,
    })

    const [qdxTransaction, setQdxTransaction] = useState('PAYMENT835')
    const [plbOnlyValue, setPlbOnlyValue] = useState('NORMAL')

    const handleNavigateDashBoard = (RedirectId) => {
        changeAllTitleFunction('Dashboard', DASHBOARD, RedirectId)
    }
    const __payment835Handler = async () => {
        if (state.Payment835) return;
        await cleareEobFocus();
        setState({
            ...state,
            Payment835: true,
            Payment835Lite: false,
            PatientPay: false,
            Correspondence: false,
        });
        setClaim835Index(0)
        handleChangeGridPosition({ x: -10, y: 550 });
    };
    const __payment835LiteHandler = async () => {
        if (state.Payment835Lite) return;
        cleareEobLiteFocus();
        setState({
            ...state,
            Payment835: false,
            Payment835Lite: true,
            PatientPay: false,
            Correspondence: false,
        });
        setClaimLiteIndex(0)
        handleChangeGridPosition({ x: -10, y: 700 });
    };
    const __patientPayHandler = () => {
        if (state.PatientPay) return;
        clearePatientPayFocus();
        setState({
            ...state,
            Payment835: false,
            Payment835Lite: false,
            PatientPay: true,
            Correspondence: false,
        });
        handleChangeGridPosition({ x: -10, y: 770 });
    };
    const __correspondenceHandler = () => {
        if (state.Correspondence) return;
        cleareCorresPondanceFocus();
        setState({
            ...state,
            Payment835: false,
            Payment835Lite: false,
            PatientPay: false,
            Correspondence: true,
        });
        handleChangeGridPosition({ x: -10, y: 730 });
    };

    useEffect(() => {
        if (errorAlert && errorAlert?.status && errorAlert?.type == 'Idle') {
            Swal.close()
        }
    }, [errorAlert])

    // ========================= GRID STATES AND FUNCTIONS =========================//

    window.onkeyup = function (event) {
        if (event.key == 'Tab' && event.ctrlKey && event.shiftKey) {
            parent.document.getElementById('id-ctrl-tab-shift-switch').click()
        }
        else if (event.key == 'Tab' && event.ctrlKey) {
            parent.document.getElementById('id-ctrl-tab-switch').click()
        }
        else if (event.altKey && event.keyCode == 78) {
            parent.document.getElementById('bottom-tab-add').click()
        }
        else if (event.altKey && event.keyCode == 88) {
            parent.document.getElementById('id-ctrl-x-remove-tab').click()
        }
        const isDbkShortCutView = isActiveDbkField(eobFocus, formEob) || isActiveDbkField(eobLiteFocus, eobLiteFormik) || isActiveDbkField(corresPondanceFocus, formCorres) || isActiveDbkField(patientPayFocus, formPP)
        // if (event.keyCode == 9) {
        //     const activeElement = document.activeElement;
        //     const inputBoxRect = activeElement.getBoundingClientRect();
        //     const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        //     const viewportHeight = window.innerHeight || document.documentElement.clientHeight;

        //     // Check if the input box is completely outside the viewport
        //     if (
        //         inputBoxRect.right < 0 || // Input box is to the left of the viewport
        //         inputBoxRect.left > viewportWidth || // Input box is to the right of the viewport
        //         inputBoxRect.bottom < 0 || // Input box is above the viewport
        //         inputBoxRect.top > viewportHeight // Input box is below the viewport
        //     ) {
        //         if (payerLiteLookup) {
        //             setControlledPosition({ x: -10, y: 700 });
        //         } else if (payeeLiteLookup) {
        //             setControlledPosition({ x: -10, y: 700 });
        //         }
        //         else {
        //             if (state.Payment835 && qdxTransaction != "PATIENT_PAY") {
        //                 setControlledPosition({ x: -10, y: 560 });
        //             }
        //             else if (state.Payment835Lite) {
        //                 setControlledPosition({ x: -10, y: 700 });
        //             } else if (state.PatientPay) {
        //                 setControlledPosition({ x: -10, y: 770 });
        //             } else if (state.Correspondence) {
        //                 setControlledPosition({ x: -10, y: 730 });
        //             } else if (state.Payment835 && qdxTransaction == "PATIENT_PAY") {
        //                 setControlledPosition({ x: -10, y: 580 });
        //             }
        //         }
        //     }

        // }
        if (event.keyCode === 27) {
            if (isViewOnllyGrid) {
                setState({
                    ...state,
                    updateDisable: !state.updateDisable,
                })

            }

            if (
                payerLookup ||
                payeeLookup ||
                patientLookup
            ) {
                setState({
                    ...state,
                    updateDisable: !state.updateDisable
                })
                setPayerLookup(false);
                setPayeeLookup(false);

                if (payerLookup) {
                    setPayerFocus(true);
                    setPayerData("");
                } else if (payeeLookup) {
                    setPayeeFocus(true);
                    setPayeeData("");
                } else if (patientLookup) {
                    setPatientFocus(true);
                    setPatientLookup(false);
                }
            } else {

                if (claim835) {
                    setClaim835(false)
                }

                if (payerLiteLookup) {
                    setPayerLiteLookup(false);

                    setPayerLiteFocus(true);
                    setPayeeLiteFocus(false);
                    // handleChangeGridPosition({ x: -10, y: 800 });
                    // if (eobLiteFormik?.values?.grid_customization == "PLB_ONLY") {
                    // } else {
                    //     handleChangeGridPosition({ x: -10, y: 700 });
                    // }

                } else if (payeeLiteLookup) {

                    setPayeeLiteLookup(false);

                    setPayerLiteFocus(false);
                    setPayeeLiteFocus(true);
                    // handleChangeGridPosition({ x: -10, y: 800 });
                    // if (eobLiteFormik?.values?.gri d_customization == "PLB_ONLY") {
                    // } else {
                    //     handleChangeGridPosition({ x: -10, y: 700 });
                    // }
                }
                else if (newPoupUp) {
                    setNewPoupUp(false);
                }

                else {
                    if (additionalModal == true) {
                        setAdditionalModal(false); setIsDrag(true);
                    } else
                        if (state.Payment835 && qdxTransaction != "PATIENT_PAY") {
                            handleChangeGridPosition({ x: -10, y: 770 });

                        }
                        else if (state.Payment835Lite) {
                            handleChangeGridPosition({ x: -10, y: 800 });

                        } else if (state.PatientPay) {
                            handleChangeGridPosition({ x: -10, y: 770 });
                        } else if (state.Correspondence) {
                            handleChangeGridPosition({ x: -10, y: 730 });
                        } else if (state.Payment835 && qdxTransaction == "PATIENT_PAY") {
                            handleChangeGridPosition({ x: -10, y: 770 });

                        }
                }
            }
            // setTimeout(() => {
            //     setActiveInputNotVisibleGrid(true)
            // }, 500)
        }

        if (payerLookup && event.keyCode === 13) {
            if (event.ctrlKey == true) {
                activatePayerLookup();
            }
        }

        if (payerFocus && event.keyCode === 118 && !isViewOnllyGrid) {
            handleChangeGridPosition("payer_payee_popup");
            setPayerLookup(true);
            let event = {
                target: {
                    value: payerValue,
                }
            }
            // __handleSearchPayer(event)
        }

        if (payerLookup && event.keyCode === 40) {
            if (payerIndex < payerData?.length - 1) setPayerIndex(payerIndex + 1);
        }

        if (payerLookup && event.keyCode === 38) {
            if (payerIndex > 0) setPayerIndex(payerIndex - 1);
        }

        if (payeeFocus && event.keyCode === 118 && !isViewOnllyGrid) {
            handleChangeGridPosition("payer_payee_popup");
            setPayeeLookup(true);
            setPayeeIndex(0);
            let event = {
                target: {
                    value: payeeValue,
                }
            }
            // __handleSearchPayee(event)
        }

        if (payeeLookup && event.keyCode === 13) {
            if (event.ctrlKey == true) {
                activatePayeeLookup();
            }
        }

        if (payeeLookup && event.keyCode === 38) {
            if (payeeIndex > 0) setPayeeIndex(payeeIndex - 1);
        }

        if (payeeLookup && event.keyCode === 40) {
            if (payeeIndex < payeeData?.length - 1) setPayeeIndex(payeeIndex + 1);
        }

        if (patientFocus && event.keyCode === 118 && !isViewOnllyGrid) {
            handleChangeGridPosition("payer_payee_popup")
            setPatientLookup(true);
            setPatientIndex(0);
            let event = {
                target: {
                    value: patientValue,
                }
            }
            // __handleSearchData837()
        }

        if (patientLookup && event.keyCode === 40) {
            if (patientIndex < data_837?.length - 1) {
                setPatientIndex(patientIndex + 1);
                setCl837Index(patientIndex + 1);
                handleSelectedServiceLines(patientIndex + 1)
            }
        }

        if (patientLookup && event.keyCode === 38) {
            if (patientIndex > 0) {
                setPatientIndex(patientIndex - 1);
                setCl837Index(patientIndex - 1);
                handleSelectedServiceLines(patientIndex - 1)
            }
        }
        if (patientLookup && event.keyCode === 13) {
            if (event.ctrlKey == true && !doubleClicked) {
                activate837();

            }
        }


        if (payerLiteLookup && !payeeLiteLookup && !payeeLiteFocus && event.keyCode === 13) {
            if (event.ctrlKey == true) {
                activatePayerLiteLookup(payerLiteData[payerLiteIndex])
                setPayerLiteFocus(true)
                setPayeeLiteFocus(false)
            }
        }

        if (!payerLiteLookup && payeeLiteLookup && !payerLiteFocus && payeeLiteFocus && event.keyCode === 13) {
            if (event.ctrlKey == true) {
                activatePayeeLiteLookup(payeeLiteData[payeeLiteIndex])
                setPayeeLiteFocus(true)
                setPayerLiteFocus(false)
            }
        }


        if (payerLiteLookup && event.keyCode === 40) {
            if (payerLiteIndex < payerLiteData?.length - 1)
                setPayerLiteIndex(payerLiteIndex + 1);
        }

        if (payerLiteLookup && event.keyCode === 38) {
            if (payerLiteIndex > 0)
                setPayerLiteIndex(payerLiteIndex - 1);
        }

        if (payeeLiteLookup && event.keyCode === 40) {
            if (payeeLiteIndex < payeeLiteData?.length - 1)
                setPayeeLiteIndex(payeeLiteIndex + 1);
        }

        if (payeeLiteLookup && event.keyCode === 38) {
            if (payeeLiteIndex > 0)
                setPayeeLiteIndex(payeeLiteIndex - 1);
        }

        if (payerLiteFocus && event.keyCode === 118 && !isViewOnllyGrid) {
            handleChangeGridPosition("payer_payee_popup");
            setPayeeLiteFocus(false);
            setPayerLiteLookup(true);
        }

        if (payeeLiteFocus && event.keyCode === 118 && !isViewOnllyGrid) {
            handleChangeGridPosition("payer_payee_popup");
            setPayerLiteFocus(false);
            setPayeeLiteLookup(true);
        }

        if (isDbkShortCutView) {
            return
        } else {


            if (event.keyCode == 83 && event.ctrlKey && event.shiftKey) {
                document.getElementById('tooltip-button').click();
            }

            if (event.keyCode == 67 && event.shiftKey && event.altKey && claim835Focus) {
                setClaim835Focus(false);
                if (state.Payment835) {
                    createNewClaim()
                }
                else if (state.Correspondence) {
                    addingNewCorrespondanceClaim();
                    setClaimCorresIndex(formCorres?.values?.claims?.length);
                }
                else if (state.Payment835Lite) {
                    handleNewClaim()

                    // setClaim835Focus(false)
                }
                // else
            }
            if (event.keyCode == 83 && event.altKey) {
                const saveButton835 = isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formEob.isValid
                const saveButton835Lite = isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !eobLiteFormik.isValid
                const saveButtonCorres = isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !eobLiteFormik.isValid
                const saveButtonPP = isViewOnllyGrid ? true : activePdf == 'FIRST' && !firstSaveLoad ? true : activePdf == 'SECOND' && !secondSaveLoad ? true : additionalModal ? true : currentJobVerified == false ? true : !formPP.isValid
                if (state.Payment835 && !saveButton835 && !formEob.isSubmitting) {
                    qaErrorModal ? qaErrorformik.handleSubmit() : formEob.handleSubmit();
                }
                else if (state.Payment835Lite && !saveButton835Lite && !eobLiteFormik.isSubmitting) {
                    qaErrorModal ? qaErrorformik.handleSubmit() : eobLiteFormik.handleSubmit();
                }
                else if (state.PatientPay && !saveButtonCorres && !formPP.isSubmitting) {
                    qaErrorModal ? qaErrorformik.handleSubmit() : formPP.handleSubmit();

                }
                else if (state.Correspondence && !saveButtonPP && !formCorres.isSubmitting) {
                    qaErrorModal ? qaErrorformik.handleSubmit() : formCorres.handleSubmit();

                }
            }


            if (event.keyCode == 82 && event.altKey) {
                if (event.shiftKey) {
                    __correspondenceHandler()
                }
                else {
                    document.getElementById('grid-reject-button').click();
                }
            }


            if (event.keyCode == 72 && event.altKey) {
                setHotKey(true);
                setIsDrag(false)
            }

            if (event.keyCode == 89 && event.shiftKey && event.altKey) {
                __patientPayHandler()
            }

            if (event.keyCode == 80 && event.shiftKey && event.altKey) {
                __payment835Handler()
            }
            if (event.keyCode == 82 && event.shiftKey && event.altKey) {
                __correspondenceHandler()
            }
        }

    };

    const setActiveInputNotVisibleGrid = (isEsc = false) => {

        const grid = document.getElementById('grid-full-container')
        let values = formEob?.values
        if (state.Payment835 && qdxTransaction != "PATIENT_PAY") {
            values = formEob?.values
        }
        else if (state.Payment835Lite) {
            values = formEob?.values
        } else if (state.PatientPay) {
            values = formPP?.values
        } else if (state.Correspondence) {
            values = formCorres?.values
        }
        if (grid) {
            // const activeTab = grid.querySelector('[tabindex="0"]')
            const activeTab = grid.querySelector('[tabindex="0"]:not([data-corress-disable="true"])');

            const firstRow = values?.payment_method &&
                values?.check_number &&
                values?.check_date &&
                values?.check_amount &&
                values?.check_page_number
            let claimAccountNo = document.getElementsByName(`claims[${0}].patient_account_number`);
            if (jobData?.grid_data?.transaction_type == "PAYMENT835" && jobData?.grid_data?.status == "partial_save" && values?.claims?.length > 0) {
                claimAccountNo = document.getElementsByName(`claims[${values?.claims?.length - 1}].patient_account_number`);
            }

            if (firstRow && claimAccountNo[0]?.value == '' && pathname == DATA_ENTRY) {
                claimAccountNo[0].focus();
            }
            else if (activeTab && pathname == DATA_ENTRY) {
                activeTab.focus();
            } else if (pathname == QA_REVIEW) {
                const formFields = grid.querySelectorAll('form input, form select');

                if (formFields.length > 0) {
                    const firstFormField = formFields[0];
                    firstFormField.focus();
                    // Do something with the focused element
                } else {
                }
            }
        }
    }
    function isElementInViewport(el, type) {
        if (
            (!(el.tagName === "INPUT" ||
                el.tagName === "SELECT" ||
                el.tagName === "BUTTON"))
        ) {
            return
        }
        const nextFocusInput = getNextFocusableElement(el, type)
        var rect = nextFocusInput.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
    window.onkeydown = function (event) {
        var activeElement = document.activeElement;
        if (event.key == 'Tab' && !isElementInViewport(activeElement, event.shiftKey ? "shift" : "tab")) {
            const nextFocusInput = getNextFocusableElement(activeElement, event.shiftKey ? "shift" : "tab")
            var rect = nextFocusInput.getBoundingClientRect();
            if (!(rect.top >= 0) && !(rect.right <= (window.innerWidth || document.documentElement.clientWidth))) {
                let rightPosition = rect.right - window.innerWidth || document.documentElement.clientWidth
                setControlledPosition({ ...controlledPosition, y: controlledPosition.y + Math.abs(rect.y) + 5, x: controlledPosition.x - rightPosition - 15 });
                nextFocusInput.focus()

            }
            else if (!(rect.left >= 0) && !(rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))) {
                let bottomPosition = rect.bottom - window.innerHeight || document.documentElement.clientHeight
                setControlledPosition({ ...controlledPosition, x: controlledPosition.x - rect.x + 15, y: controlledPosition.y - bottomPosition });
                nextFocusInput.focus()

            }

            else if (!(rect.top >= 0)) {
                setControlledPosition({ ...controlledPosition, y: controlledPosition.y + Math.abs(rect.y) + 5 });
                nextFocusInput.focus()
            }
            else if (!(rect.left >= 0)) {
                setControlledPosition({ ...controlledPosition, x: controlledPosition.x - rect.x + 15 });
                nextFocusInput.focus()
            }
            else if (!(rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))) {
                let bottomPosition = rect.bottom - window.innerHeight || document.documentElement.clientHeight

                setControlledPosition({ ...controlledPosition, y: controlledPosition.y - bottomPosition });
                nextFocusInput.focus()

            }
            else if (!(rect.right <= (window.innerWidth || document.documentElement.clientWidth))) {
                let rightPosition = rect.right - window.innerWidth || document.documentElement.clientWidth
                setControlledPosition({ ...controlledPosition, x: controlledPosition.x - rightPosition - 15 });
                nextFocusInput.focus()
            }
            event.preventDefault();

            // setGridHeightPosition()
        }
        else
            if (event.key == 'Tab' && event.shiftKey && pathname == DATA_ENTRY) {
                if (!['save-exit-btn', 'save-btn'].includes(event.target.id)) {
                    const currInput = document.activeElement;
                    if (currInput.tagName !== "BUTTON" && currInput.type !== "checkbox") {
                        event.preventDefault();
                        if (currInput?.getAttribute('data-prevent-shift-tab') !== "true") {
                            var divElem = document.getElementById("grid-full-container");
                            var inputs = Array.prototype.slice.call(divElem.querySelectorAll("input, select"));
                            var filteredInputs = inputs.filter(function (input) {
                                return !input.classList.contains("prevent-shift") && !input.disabled && input?.getAttribute('data-corress-disable') !== "true";
                            });
                            const currInputIndex = filteredInputs.indexOf(currInput);
                            const input = filteredInputs[currInputIndex - 1];
                            if (input) {
                                if (input.type === "number" || input.type === "text") {
                                    // Temporarily change the input type to "text"
                                    const originalType = input.type;
                                    input.type = "text";

                                    // Set the cursor to the end
                                    const inputLength = input.value.toString().length;
                                    input.setSelectionRange(inputLength, inputLength);

                                    // Revert the input type back to "number"
                                    input.type = originalType;
                                }
                                input.focus();

                                setTimeout(() => {
                                    input.scrollIntoViewIfNeeded();
                                }, 10)
                            }
                        }
                    }
                }
            }
    }

    // Shorcut Key

    //pdf viewer and drag draggable fuctions starts

    // '<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>'

    const [controlledPosition, setControlledPosition] = React.useState({
        x: -10,
        y: 450,
    });

    const [isDrag, setIsDrag] = useState(true);


    // '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>'

    function __onControlledDrag(e, position) {
        setControlledPosition({ x: position.x, y: position.y });
    }

    // '<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>'

    // ============================ RE ASSIGN ======================================//
    const [assignTooltip, setAssignTooltip] = useState(false);
    const [userStatus, setUserStatus] = useState({});
    const [assignTo, setAssignTo] = useState("");
    const [assignReason, setAssignReason] = useState("");

    const [selectedSubJob, setSelectedSubJob] = useState({})
    const formReassign = useFormik({
        initialValues: {
            // user_status: "",
            assign_reason: "",
            // assign_to: "",
        },
        validationSchema: Yup.object().shape({
            assign_reason: Yup.string().required('Required'),
            // assign_to: Yup.string().required('Required'),
            // user_status: Yup.string().required('Required'),
        }),
        onSubmit: (values, { setSubmitting }) => {
            localStorage.removeItem('837_pulled')
            setLoadData(true)
            setformApiLoaded(true)
            reAssignUser(values, { setSubmitting });
        }
    })
    const formReassignMulty = useFormik({
        initialValues: [
            {
                value: "",
                label: "",
                staff_name: "",
                staff_id: "",
                page_range: "",
                is_select: false,
                assign_reason: "",
                assignTo: "",
            }
        ],
        validate: (values) => {
            let errors = {};

            values?.forEach((item, index) => {
                if (values[index].is_select == true && values[index].assign_reason == "") {
                    if (errors) {
                        if (errors[index]) {
                            errors[index].assign_reason = "Required";
                        } else {
                            errors[index] = {};
                            errors[index].assign_reason = "Required";
                        }
                    } else {
                        errors = [];
                        errors[index] = {};
                        errors[index].assign_reason = "Required";
                    }
                }

            })
            return errors;
        },
        // validationSchema: Yup.array().of(
        //     Yup.object().shape({
        //         // assign_reason: Yup.string().required('Required'),

        //     })
        // ),
        onSubmit: (values, { setSubmitting }) => {

            setformApiLoaded(true)
            const final_data = {}

            const valuesss = [];



            values?.map((value, index) => {

                if (value.is_select) {
                    valuesss.push({
                        user: value?.assignTo == "user" ? true : false,
                        reason: value?.assign_reason ? value?.assign_reason : "",
                        sub_job: value?.value ? value?.value : "",
                    })
                }
            })

            final_data.job = jobData?.job;
            final_data.reason = "";
            final_data.user = "";
            final_data.is_sub_job_reassign = true;

            final_data.data = valuesss;


            // let formData = new FormData();

            // formData.append("job", jobData?.job);
            // formData.append("reason", "");
            // formData.append("user", "");
            // formData.append("is_sub_job_reassign", true);
            // formData.append("data", !jobData?.is_large_batch_job ? "" : data ?? "");

            if (valuesss.length > 0) {
                setLoadData(true)
            }

            assignJobFromQaV2(final_data)((response) => {
                setSubmitting(true)
                if (response?.status) {
                    setSaveSuccess(true)

                    setformApiLoaded(false)
                    // formReassign.resetForm()
                    setAssignTooltip(false);
                    setIsDrag(true);
                    // setAssignReason()
                    setSubmitting(false);
                    setToast({ status: true, type: "success", message: "Success" });

                    setTimeout(() => {
                        // window.location.reload()
                        localStorage.removeItem('transaction_type')
                        // resetFormAndInsertData();
                    }, 50);
                }
                else if (response?.status_code == 400 && response?.errors && response?.errors?.reassign_user?.length > 0) {
                    setformApiLoaded(false)
                    // setToast({ status: true, type: "danger", message: response?.errors?.reassign_user[0] });
                    setAssignTooltip(false);
                    setSubmitting(false)
                    setErrorAlert({
                        status: true,
                        type: "danger",
                        message: response?.errors?.reassign_user[0]
                    })
                }
                else {
                    setErrorJobData()

                    setformApiLoaded(false)
                    setSubmitting(false)
                    setToast({ status: true, type: "danger", message: "Failed" });
                    // setSubmitting(false);
                }
            });
            //setSubmitting(false)--sajith

            // reAssignUser(values);
        }
    })
    const handlePageNavigate = (index, type) => {
        if (type == "835") {
            const claims = formEob.values.claims
            if (claims && claims.length > 0) {
                jumpToPage(claims[index].page_from - 1)
            }
        }
        else if (type == "LITE") {
            const claims = eobLiteFormik.values.claims
            if (claims && claims.length > 0) {
                jumpToPage(claims[index].page_from - 1)
            }
        }
        else if (type == "CORRES") {
            const claims = formCorres.values.claims
            if (claims && claims.length > 0) {
                jumpToPage(claims[index].page_from - 1)
            }
        }
    }

    useEffect(() => {
        formReassignMulty.setValues(subJobOptions)
    }, [subJobOptions])
    // useEffect(() => {
    //     alert('gridd')
    // }, [])
    const reAssignUser = (values, { setSubmitting }) => {
        setSubmitting(true)
        // let formData = new FormData();
        // formData.append("job", jobData?.job);
        // formData.append("reason", values?.assign_reason);
        // formData.append("user", assignTo == "user" ? true : false);
        // formData.append("is_sub_job_reassign", false);
        // formData.append("sub_job", selectedSubJob?.value ?? "");
        const final_data = {}
        final_data.job = jobData?.job;
        final_data.reason = values?.assign_reason;
        final_data.user = assignTo == "user" ? true : false;
        final_data.is_sub_job_reassign = false;

        final_data.data = [];
        assignJobFromQaV2(final_data)((response) => {
            if (response?.status) {
                setSaveSuccess(true)
                setformApiLoaded(false)
                // formReassign.resetForm()
                setAssignTooltip(false);
                setIsDrag(true);
                // setAssignReason();
                setSubmitting(false)
                setToast({ status: true, type: "success", message: "Success" });
                localStorage.removeItem('grid_data')
                localStorage.removeItem('grid_state_data')
                localStorage.removeItem('grid_qdx_data')
                localStorage.removeItem('grid_overall_data')
                setTimeout(() => {

                    // setLoadData(true)
                    // window.location.reload()
                    formReassign.setValues({
                        assign_reason: ''
                    })

                    localStorage.removeItem('transaction_type')
                    // resetFormAndInsertData();

                }, 50);
            }
            else if (response?.status_code == 400 && response?.errors && response?.errors?.reassign_user?.length > 0) {
                setformApiLoaded(false)
                // setToast({ status: true, type: "danger", message: response?.errors?.reassign_user[0] });
                setAssignTooltip(false);
                setSubmitting(false)
                setErrorAlert({
                    status: true,
                    type: "danger",
                    message: response?.errors?.reassign_user[0]
                })
            }
            else {
                setErrorJobData()
                setformApiLoaded(false)
                setSubmitting(false)
                setToast({ status: true, type: "danger", message: "Failed" });
                // setSubmitting(false);
            }
        });
    };

    // const reAssignUser = (values) => {
    //     let formData = new FormData();
    //     formData.append("job", jobData?.job);
    //     formData.append("reason", values?.assign_reason);
    //     formData.append("user", assignTo == "user" ? true : false);

    //     assignJobFromQa({ formData })((response) => {
    //         if (response?.status) {
    //             setAssignTooltip(false);
    //             setIsDrag(true);
    //             // setAssignReason();
    //             setToast({ status: true, type: "success", message: "Success" });

    //             setTimeout(() => {
    //                 window.location.reload()
    //                 localStorage.removeItem('transaction_type')

    //                 // resetFormAndInsertData();
    //             }, 50);
    //         } else if (response?.status_code == 400 && response?.errors) {
    //             // setToast({ status: true, type: "danger", message: response?.errors?.reassign_user[0] });
    //             setAssignTooltip(false);

    //             setErrorAlert({
    //                 status: true,
    //                 type: "danger",
    //                 message: response?.errors?.reassign_user[0]
    //             })
    //         }
    //         else {

    //             setToast({ status: true, type: "danger", message: "Failed" });
    //             setSubmitting(false);
    //         }
    //     });
    // };

    // ============================  RE ASSIGN ======================================//

    //pdf viewer and drig draggable fuctions ends

    // payer lookup search funcitons and states

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    const [payerLookup, setPayerLookup] = useState(false);
    const [payerFocus, setPayerFocus] = useState("");
    const [payerValue, setPayerValue] = useState("");
    const [payerIndex, setPayerIndex] = useState(0);
    const [payerData, setPayerData] = useState();

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // activate payer lookup or remove datas from data if close
    const __handlePayerLookupSearch = () => {
        if (!isViewOnllyGrid) {
            handleChangeGridPosition("payer_payee_popup")
            setPayerLookup(!payerLookup);
            setPayerValue(formEob.values.payer_name)
            setPayerData("");
            setPayerFocus(true);
            setDoubleClicked(false)
        }
    };

    // search payer api functions
    const __handleSearchPayer = async () => {
        setLoading(true);
        // e.preventDefault();
        // setPayerValue(e.target.value);
        if (payerValue.length >= 3) {
            await searchPayerLookup({ search: payerValue.toUpperCase(), client: jobData.client_id })((response) => {
                if (!response?.data?.status && response?.status) {
                    setLoading(false);
                    setPayerData(response?.data?.results);
                }
            });
        } else {
            setLoading(false);
            setPayerData('')
        }
    };

    const togglePayerHoverEnter = (ind) => {
        setPayerIndex(ind);
    };

    const activatePayerLookup = async () => {
        setDoubleClicked(true)
        //  setSelectedPayer(payerData[payerIndex]);
        await insertSelectedPayer(payerData[payerIndex]);
        // __handlePayerLookupSearch();
    };

    const insertSelectedPayer = async (selectedPayer) => {
        if (selectedPayer?.name) {
            if (
                jobData &&
                jobData?.job &&
                selectedPayer?.name &&
                selectedPayer &&
                selectedPayer?.id
            ) {
                checkExcludedPayer(
                    jobData?.job,
                    selectedPayer?.id
                )((response) => {
                    if (response?.status) {
                        if (response?.is_excluded_payer) {
                            // alert("payer is excluded")
                            setIsSwal(true);
                            setDoubleClicked(false)
                        } else {
                            formEob.setValues({
                                ...formEob.values,
                                payer_id: selectedPayer?.id ? selectedPayer?.id : "",
                                payer_pulled_id: selectedPayer?.id ? selectedPayer?.id : "",
                                payer_name: selectedPayer?.name ? selectedPayer?.name : "",
                                payer_address: selectedPayer?.address
                                    ? selectedPayer?.address
                                    : "",
                                payer_city: selectedPayer?.city ? selectedPayer?.city : "",
                                payer_state: selectedPayer?.state ? selectedPayer?.state : "",
                                payer_zip: selectedPayer?.zip ? selectedPayer?.zip : "",
                                // data_837: [{
                                //     ...formEob.values?.data_837[0],
                                //     payer_id: "",
                                //     payer_name: "",
                                //     payer_address: "",
                                //     payer_city: "",
                                //     payer_state: "",
                                //     payer_zip: "",
                                // }],
                                dbk_payer_name: selectedPayer?.name ? selectedPayer?.name : "",
                                dbk_payer_address: selectedPayer?.address
                                    ? selectedPayer?.address
                                    : "",
                                dbk_payer_city: selectedPayer?.city ? selectedPayer?.city : "",
                                dbk_payer_state: selectedPayer?.state
                                    ? selectedPayer?.state
                                    : "",
                                dbk_payer_zip: selectedPayer?.zip ? selectedPayer?.zip : "",
                            });
                            // if (formEob?.values?.grid_customization == "PLB_ONLY") {
                            //     handleChangeGridPosition({ x: -10, y: 770 });
                            // }
                            __handlePayerLookupSearch();
                        }
                    }
                });
            }
        }
        else {
            setDoubleClicked(false)
        }
    };

    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // payer lookup search funcitons and states

    // payee lookup search funcitons and states

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    const [payeeLookup, setPayeeLookup] = useState(false);
    const [payeeIndex, setPayeeIndex] = useState(0);
    const [payeeValue, setPayeeValue] = useState("");
    const [payeeData, setPayeeData] = useState();
    const [payeeFocus, setPayeeFocus] = useState();

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // activate payee lookup or remove datas from data if close
    const __handlePayeeLookupSearch = () => {
        if (!isViewOnllyGrid) {
            handleChangeGridPosition("payer_payee_popup")
            setPayeeLookup(!payeeLookup);
            setPayeeValue(formEob.values.payee_name);
            setPayeeData();
            setPayeeFocus(true);
            setPayeeIndex();
            setDoubleClicked(false)

        }
    };

    // search payee api functions
    const __handleSearchPayee = async () => {
        setLoading(true);
        // e.preventDefault();
        // setPayeeValue(e.target.value);
        if (payeeValue.length >= 3) {
            await searchPayeeLookup({ search: payeeValue.toUpperCase(), client: jobData.client_id })((response) => {
                if (!response?.data?.status && response?.status) {
                    setLoading(false);
                    setPayeeData(response?.data?.results);
                }
            });
        }
        else if (payeeValue.length < 3) {
            setLoading(false);
            setPayeeData('')
        }
    };

    const togglePayeeHoverEnter = (ind) => {
        setPayeeIndex(ind);
    };

    const activatePayeeLookup = () => {
        setDoubleClicked(true)
        // setSelectedPayee(payeeData[payeeIndex]);
        insertSelectedPayee(payeeData[payeeIndex]);
        // __handlePayeeLookupSearch();
    };
    const insertSelectedPayee = async (selectedPayee) => {
        if (selectedPayee?.name) {
            formEob.setValues({
                ...formEob.values,
                payee_id: selectedPayee?.id ? selectedPayee?.id : "",
                payee_pulled_id: selectedPayee?.id ? selectedPayee?.id : "",
                payee_name: selectedPayee?.name ? selectedPayee?.name : "",
                payee_address: selectedPayee?.address ? selectedPayee?.address : "",
                payee_city: selectedPayee?.city ? selectedPayee?.city : "",
                payee_state: selectedPayee?.state ? selectedPayee?.state : "",
                payee_zip: selectedPayee?.zip ? selectedPayee?.zip : "",
                payee_npi: selectedPayee?.n1_identification_code
                    ? selectedPayee?.n1_identification_code
                    : "",
                // data_837: [{
                //     ...formEob.values?.data_837[0],
                //     payee_id: "",
                //     payee_name: "",
                //     payee_address: "",
                //     payee_city: "",
                //     payee_state: "",
                //     payee_zip: "",
                //     payee_npi: "",
                // }],
                dbk_payee_name: selectedPayee?.name ? selectedPayee?.name : "",
                dbk_payee_address: selectedPayee?.address ? selectedPayee?.address : "",
                dbk_payee_city: selectedPayee?.city ? selectedPayee?.city : "",
                dbk_payee_state: selectedPayee?.state ? selectedPayee?.state : "",
                dbk_payee_zip: selectedPayee?.zip ? selectedPayee?.zip : "",
                dbk_payee_npi: selectedPayee?.n1_identification_code
                    ? selectedPayee?.n1_identification_code
                    : "",
            });

            // if (formEob?.values?.grid_customization == "PLB_ONLY") {
            //     handleChangeGridPosition({ x: -10, y: 770 });
            // }

            await __handlePayeeLookupSearch();
        }
        else {
            setDoubleClicked(false)
        }
    };
    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // payee lookup search funcitons and states

    // Claim835 lookup search funcitons and states

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const [claim835, setClaim835] = useState(false);
    const [claim835Focus, setClaim835Focus] = useState(false);

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // activate claimSummary lookup or remove datas from data if close

    // search claimSummary api functions
    const closeClaim = () => {
        setClaim835(false);
        if (state.Payment835) {
            handleChangeGridPosition({ x: -10, y: 440 });
        } else {
            handleChangeGridPosition({ x: -10, y: 581 });
        }
    };
    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // claimSummary lookup search funcitons and states

    // payee lite lookup search funcitons and states

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    const [payeeLiteLookup, setPayeeLiteLookup] = useState(false);
    const [selectedPayeeLite, setSelectedPayeeLite] = useState({});
    const [payeeLiteIndex, setPayeeLiteIndex] = useState(0);
    const [payeeLiteValue, setPayeeLiteValue] = useState("");
    const [payeeLiteData, setPayeeLiteData] = useState();

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // activate payee lite lookup or remove datas from data if close

    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // payee lite lookup search funcitons and states

    // payer lite lookup search funcitons and states

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    const [payerLiteLookup, setPayerLiteLookup] = useState(false);
    const [selectedPayerLite, setSelectedPayerLite] = useState({});
    const [payerLiteIndex, setPayerLiteIndex] = useState(0);
    const [payerLiteValue, setPayerLiteValue] = useState("");
    const [payerLiteData, setPayerLiteData] = useState();

    // <<<<<<<<<<<<<<<<<<<<<<<<states>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // activate payer lite lookup or remove datas from data if close

    const __handlePayerLiteLookupSearch = () => {
        setPayerLiteLookup(!payerLiteLookup);
        // if (eobLiteFormik?.values?.grid_customization == "PLB_ONLY") {
        //     handleChangeGridPosition({ x: -10, y: 800 });
        // } else {

        handleChangeGridPosition("payer_payee_popup");
        // }

        // payerLiteFocus(true)
    };

    // <<<<<<<<<<<<<<<<<<<<<<<<functions>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // payer lite lookup search funcitons and states

    // patient lookup search funcitons and states
    const [selectedServiceLines, setSelectedServiceLines] = useState([]);

    const [patientLookup, setPatientLookup] = useState(false);
    const [patientFocus, setPatientFocus] = useState(false);
    const [patientValue, setPatientValue] = useState(false);
    const [patientValue1, setPatientValue1] = useState(false);
    const [patientValue2, setPatientValue2] = useState(false);
    const [data_837, setData837] = useState(false);
    const [patientIndex, setPatientIndex] = useState(0);
    const [cl837Index, setCl837Index] = useState(0);
    const [selected837, setSelected837] = useState();
    const [swalPopup, setSwalPopup] = useState(false)

    const __handleSearchData837 = async () => {

        setLoading(true);

        // setPatientValue(e.target.value);
        let trimValue = patientValue.trim();
        if (trimValue.length >= 3) {
            let search = patientValue.split(" ");

            let search1 = patientValue.toUpperCase();
            let client_id = jobData?.client_id ?? "";
            setPatientValue1(search1);
            // setPatientValue2(search2);

            const filter_type = qdxTransaction == "PATIENT_PAY" ? 2 : 1;

            await search837file({ search1, client_id, filter_type })((response) => {
                if (response?.status && response?.status_code == 200) {
                    setLoading(false);

                    setData837(response?.data?.results);
                    var seletedIndex = [];
                    response?.data?.results[0]?.procedure_codes?.map((patient, index) => {
                        seletedIndex.push(index)
                    })

                    setSelectedServiceLines(seletedIndex)
                }
            });
        }
        else if (trimValue.length < 3) {
            setLoading(false);
            setData837(false)
        }
    };

    const togglePatientHoverEnter = (ind) => {
        setHoveredIndex(ind);
    };

    const handlePatientIndex = (index) => {
        // alert(index)
        setCl837Index(index);
        setPatientIndex(index);


        if (checkRef && checkRef.current) {
            var selectedIndexes = []
            checkRef.current.map(el => {
                if (el && el?.checked) {
                    selectedIndexes.push(el.value);
                }

            })


            setTimeout(() => {

                setSelectedServiceLines(selectedIndexes)
            }, 100);
        }

    };

    const [moonji, setMoonji] = useState(false);

    const handleSelectedServiceLines = (index) => {
        var seletedIndex = [];
        data_837[index]?.procedure_codes?.map((patient, index) => {
            seletedIndex.push(index)
        })

        setSelectedServiceLines(seletedIndex)
    }
    const [doubleClicked, setDoubleClicked] = useState(false)
    const activate837 = () => {
        setDoubleClicked(true)
        const id = data_837[patientIndex]?.id;
        if (id) {

            search837fileAll(id)(async (response) => {
                if (response?.status) {
                    // handleSelectedServiceLines(0)
                    handleInsert837Data(response);
                }
            });
        } else {

            setError837({
                status: true,
                message: "INVALID",
                type: ""
            })
            setDoubleClicked(false)
            // return false
        }
    };

    const handleChange837ClaimData = (claimIndex, type) => {

        let claims = formEob.values?.claims


        if (claims[claimIndex].color_data[type] != claims[claimIndex][type]) {

            formEob.setValues({
                ...formEob.values,
            })
        }

    }



    // =============================================== SELECTED SERVICELINES 837 ==================================//

    const checkRef = useRef([]);
    const checkAllRef = useRef();

    const handleCheckServiceLines = () => {
        if (checkRef && checkRef.current) {
            var selectedIndexes = []
            checkRef.current.map(el => {
                if (el && el?.checked) {
                    selectedIndexes.push(el.value);
                }

            })
            setSelectedServiceLines(selectedIndexes)
        }

    }

    // =============================================== SELECTED SERVICELINES 837 ==================================//


    // const handleInsert837Data1 = async (response) => {

    //     // const data = response?.data;

    //     const _ = require('lodash');

    //     const data = _.cloneDeep(response?.data);
    //     const claims = _.cloneDeep(formEob.values.claims);

    //     // selectedServiceLines

    //     var service_lines = [];

    //     const data_837 = _.cloneDeep(formEob.values?.data_837);

    //     const sL837 = data?.service_lines; // pulled data


    //     var filteredArray = [];

    //     // if (data && data?.payer_id) {
    //     const excluded = await handlePayerExcludedPayer(data).then((payload) => {

    //         if (payload?.response && payload?.response?.is_excluded_payer) {
    //             setIsSwal(true)
    //             return true
    //         }
    //         else {
    //             return false

    //         }
    //     })
    //     // }
    //     // else {

    //     if (excluded) {
    //         return false
    //     }
    //     else {

    //         if (checkRef && checkRef.current) {
    //             var selectedIndexes = []
    //             checkRef.current.map(el => {
    //                 if (el && el?.checked) {
    //                     selectedIndexes.push(el.value);
    //                 }

    //             })


    //             // setTimeout(() => {

    //             //     setSelectedServiceLines(selectedIndexes)
    //             // }, 100);
    //         }
    //         if (qdxTransaction != "PATIENT_PAY") {

    //             selectedIndexes.map((el, index) => {
    //                 filteredArray.push(data?.service_lines[el])
    //             });



    //             if (filteredArray?.length > 0) {

    //                 filteredArray.map((serviceline, index) => {

    //                     service_lines.push({
    //                         ...serviceline,
    //                         color_data: serviceline,
    //                         from_date: serviceline?.from_date
    //                             ? moment(serviceline?.from_date)?.format("MM/DD/YYYY")
    //                             : "",
    //                         to_date: serviceline?.to_date
    //                             ? moment(serviceline?.to_date)?.format("MM/DD/YYYY")
    //                             : "",
    //                         code: serviceline?.code ? serviceline?.code : "",
    //                         modifier1: serviceline?.modifier1 ? serviceline?.modifier1 : "",
    //                         modifier2: serviceline?.modifier2 ? serviceline?.modifier2 : "",
    //                         modifier3: serviceline?.modifier3 ? serviceline?.modifier3 : "",
    //                         modifier4: serviceline?.modifier4 ? serviceline?.modifier4 : "",
    //                         units: serviceline?.units ? serviceline?.units : "",
    //                         procedure_type: serviceline?.procedure_type
    //                             ? serviceline?.procedure_type
    //                             : "",
    //                         rev_code: "",
    //                         billed: parseFloat(serviceline?.billed) ? serviceline?.billed : "",
    //                         adjustments: [
    //                             {
    //                                 adjustments_id: "",
    //                                 pc: "",
    //                                 group_code: "",
    //                                 reason_code: "",
    //                                 rmk: "",
    //                                 amount: "",
    //                             },
    //                         ],
    //                     });
    //                 });
    //             }
    //             else {
    //                 service_lines.push({
    //                     color_index: "",
    //                     order: "",
    //                     service_line_id: "",
    //                     from_date: "",
    //                     to_date: "",
    //                     code: "",
    //                     procedure_type: "HC",
    //                     modifier1: "",
    //                     modifier2: "",
    //                     modifier3: "",
    //                     modifier4: "",
    //                     rev_code: "",
    //                     units: "",
    //                     patres: "",
    //                     billed: "",
    //                     discount: "",
    //                     allowed: "",
    //                     deduct: "",
    //                     coins: "",
    //                     copay: "",
    //                     cob: "",
    //                     paid: "",
    //                     color_data: [],

    //                     balance: "",
    //                     adjustments: [
    //                         {
    //                             adjustment_id: "",
    //                             pc: "",
    //                             group_code: "",
    //                             reason_code: "",
    //                             rmk: "",
    //                             amount: "",
    //                         },
    //                     ],
    //                 })
    //             }

    //             claims[claim835Index].encounter_id = data?.encounter_id;
    //             claims[claim835Index].color_data = data;
    //             claims[claim835Index].patient = true;
    //             claims[claim835Index].subscriber = true;
    //             claims[claim835Index].rendering_provider = true;

    //             claims[claim835Index].encounter = data?.encounter_id;
    //             claims[claim835Index].patient_account_number = data?.patient_account_number
    //                 ? data?.patient_account_number
    //                 : "";
    //             claims[claim835Index].status_code = data?.status_code
    //                 ? data?.status_code
    //                 : "";
    //             claims[claim835Index].patient_first_name = data?.patient_first_name
    //                 ? data?.patient_first_name
    //                 : "";
    //             claims[claim835Index].patient_middle_name = data?.patient_middle_name
    //                 ? data?.patient_middle_name
    //                 : "";
    //             claims[claim835Index].patient_last_name = data?.patient_last_name
    //                 ? data?.patient_last_name
    //                 : "";
    //             claims[claim835Index].patient_identifier_code =
    //                 data?.patient_identifier_code ? data?.patient_identifier_code : "";
    //             claims[claim835Index].subscriber_first_name = data?.subscriber_first_name
    //                 ? data?.subscriber_first_name
    //                 : "";
    //             claims[claim835Index].subscriber_middle_name = data?.subscriber_middle_name
    //                 ? data?.subscriber_middle_name
    //                 : "";
    //             claims[claim835Index].subscriber_last_name = data?.subscriber_last_name
    //                 ? data?.subscriber_last_name
    //                 : "";
    //             claims[claim835Index].subscriber_identifier_code =
    //                 data?.subscriber_identifier_code ? data?.subscriber_identifier_code : "";
    //             claims[claim835Index].rendering_provider_first_name =
    //                 data?.rendering_provider_first_name
    //                     ? data?.rendering_provider_first_name
    //                     : "";
    //             claims[claim835Index].rendering_provider_last_name =
    //                 data?.rendering_provider_last_name
    //                     ? data?.rendering_provider_last_name
    //                     : "";
    //             claims[claim835Index].rendering_provider_identifier_code =
    //                 data?.rendering_provider_identifier_code
    //                     ? data?.rendering_provider_identifier_code
    //                     : formEob?.values?.claims[claim835Index]?.rendering_provider_identifier_code
    //                         ? formEob?.values?.claims[claim835Index]?.rendering_provider_identifier_code : "";

    //             // DBK START
    //             claims[claim835Index].dbk_patient_account_number =
    //                 data?.patient_account_number ? data?.patient_account_number : "";
    //             claims[claim835Index].dbk_patient_first_name = data?.patient_first_name
    //                 ? data?.patient_first_name
    //                 : "";
    //             claims[claim835Index].dbk_patient_middle_name = data?.patient_middle_name
    //                 ? data?.patient_middle_name
    //                 : "";
    //             claims[claim835Index].dbk_patient_last_name = data?.patient_last_name
    //                 ? data?.patient_last_name
    //                 : "";
    //             claims[claim835Index].dbk_patient_identifier_code =
    //                 data?.patient_identifier_code ? data?.patient_identifier_code : "";
    //             claims[claim835Index].dbk_subscriber_first_name =
    //                 data?.subscriber_first_name ? data?.subscriber_first_name : "";
    //             claims[claim835Index].dbk_subscriber_middle_name =
    //                 data?.subscriber_middle_name ? data?.subscriber_middle_name : "";
    //             claims[claim835Index].dbk_subscriber_last_name = data?.subscriber_last_name
    //                 ? data?.subscriber_last_name
    //                 : "";
    //             claims[claim835Index].dbk_subscriber_identifier_code =
    //                 data?.subscriber_identifier_code ? data?.subscriber_identifier_code : "";
    //             claims[claim835Index].dbk_rendering_provider_first_name =
    //                 data?.rendering_provider_first_name
    //                     ? data?.rendering_provider_first_name
    //                     : "";
    //             claims[claim835Index].dbk_rendering_provider_last_name =
    //                 data?.rendering_provider_last_name
    //                     ? data?.rendering_provider_last_name
    //                     : "";
    //             claims[claim835Index].dbk_rendering_provider_identifier_code =
    //                 data?.rendering_provider_identifier_code
    //                     ? data?.rendering_provider_identifier_code
    //                     : formEob?.values?.claims[claim835Index]?.rendering_provider_identifier_code
    //                         ? formEob?.values?.claims[claim835Index]?.rendering_provider_identifier_code : "";
    //             claims[claim835Index].sl_discount = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_allowed = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_deduct = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_coins = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_copay = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_cob = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_patres = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_amount = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_paid = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_balance = parseFloat(0).toFixed(2);
    //             claims[claim835Index].sl_total = parseFloat(0).toFixed(2);

    //             // DBK END


    //             claims[claim835Index].service_lines = service_lines;

    //             if (claims[claim835Index] && claims[claim835Index].service_lines && claims[claim835Index].service_lines?.length > 0) {
    //                 claims[claim835Index].period_start =
    //                     claims[claim835Index].service_lines[0].from_date;
    //                 claims[claim835Index].period_end =
    //                     claims[claim835Index].service_lines[0].to_date;
    //                 claims[claim835Index].service_lines?.map((service, sindex) => {
    //                     if (
    //                         claims[claim835Index].service_lines[sindex] &&
    //                         claims[claim835Index].service_lines[sindex].from_date &&
    //                         new Date(
    //                             claims[claim835Index].service_lines[sindex].from_date &&
    //                             !isNaN(
    //                                 new Date(
    //                                     claims[claim835Index].service_lines[sindex].from_date
    //                                 ).getDate()
    //                             )
    //                         )
    //                     ) {
    //                         if (
    //                             claims[claim835Index] &&
    //                             claims[claim835Index].period_start &&
    //                             new Date(claims[claim835Index].period_start)
    //                         ) {
    //                             if (
    //                                 new Date(claims[claim835Index].service_lines[sindex].from_date) <=
    //                                 new Date(claims[claim835Index].period_start)
    //                             ) {
    //                                 claims[claim835Index].period_start =
    //                                     claims[claim835Index].service_lines[sindex].from_date;
    //                             }
    //                         }
    //                     }

    //                     if (
    //                         claims[claim835Index].service_lines[sindex] &&
    //                         claims[claim835Index].service_lines[sindex].to_date &&
    //                         new Date(claims[claim835Index].service_lines[sindex].to_date) &&
    //                         !isNaN(
    //                             new Date(
    //                                 claims[claim835Index].service_lines[sindex].to_date
    //                             ).getDate()
    //                         )
    //                     ) {
    //                         if (
    //                             new Date(claims[claim835Index].service_lines[sindex].to_date) >=
    //                             new Date(claims[claim835Index].period_end)
    //                         ) {
    //                             claims[claim835Index].period_end =
    //                                 claims[claim835Index].service_lines[sindex].to_date;
    //                         }
    //                     }
    //                 });
    //             }
    //         }
    //         else {
    //             claims[claim835Index].encounter_id = data?.encounter_id;
    //             claims[claim835Index].color_data = data;
    //             claims[claim835Index].patient = true;
    //             claims[claim835Index].subscriber = true;
    //             claims[claim835Index].rendering_provider = true;

    //             claims[claim835Index].encounter_id = data?.encounter_id;
    //             claims[claim835Index].patient_account_number = data?.patient_account_number
    //                 ? data?.patient_account_number
    //                 : "";
    //             claims[claim835Index].patient_first_name = data?.patient_first_name
    //                 ? data?.patient_first_name
    //                 : "";

    //             claims[claim835Index].patient_last_name = data?.patient_last_name
    //                 ? data?.patient_last_name
    //                 : "";
    //             claims[claim835Index].period_start = data?.date_of_service
    //                 ? moment(data?.date_of_service).format("MM/DD/YYYY")
    //                 : "";
    //             claims[claim835Index].period_end = data?.date_of_service
    //                 ? moment(data?.date_of_service).format("MM/DD/YYYY")
    //                 : "";
    //             claims[claim835Index].mrn = data?.mrn_number
    //                 ? data?.mrn_number
    //                 : "";
    //             claims[claim835Index].statement_number = data?.statement_number
    //                 ? data?.statement_number
    //                 : "";
    //             claims[claim835Index].statement_date = data?.statement_date
    //                 ? moment(data?.statement_date).format('MM/DD/YYYY')
    //                 : "";
    //             claims[claim835Index].claim_billed = data?.statement_amount
    //                 ? parseFloat(data?.statement_amount ? data?.statement_amount : 0).toFixed(2)
    //                 : "";


    //             // DBK START
    //             claims[claim835Index].dbk_patient_account_number =
    //                 data?.patient_account_number ? data?.patient_account_number : "";
    //             claims[claim835Index].dbk_patient_first_name = data?.patient_first_name
    //                 ? data?.patient_first_name
    //                 : "";

    //             claims[claim835Index].dbk_patient_last_name = data?.patient_last_name
    //                 ? data?.patient_last_name
    //                 : "";
    //             claims[claim835Index].dbk_mrn = data?.mrn_number
    //                 ? data?.mrn_number
    //                 : "";
    //             claims[claim835Index].dbk_statement_number = data?.statement_number
    //                 ? data?.statement_number
    //                 : "";
    //             claims[claim835Index].dbk_statement_date = data?.statement_date
    //                 ? moment(data?.statement_date).format('MM/DD/YYYY')
    //                 : "";


    //             // DBK END




    //         }



    //         if (data_837?.length == 0) {

    //             if (!isSubJob && isFirstJob || isSubJob && isFirstJob || !isSubJob && !isFirstJob) {

    //                 data_837.push({
    //                     payee_name: data?.payee_name ? data?.payee_name : "",
    //                     payee_npi: data?.payee_npi ? data?.payee_npi : "",
    //                     payee_address: data?.payee_address ? data?.payee_address : "",
    //                     payee_city: data?.payee_city ? data?.payee_city : "",
    //                     payee_state: data?.payee_state ? data?.payee_state : "",
    //                     payee_zip: data?.payee_zip ? data?.payee_zip : "",

    //                     payer_name: data?.payer_name ? data?.payer_name : "",
    //                     payer_address: data?.payer_address ? data?.payer_address : "",
    //                     payer_city: data?.payer_city ? data?.payer_city : "",
    //                     payer_state: data?.payer_state ? data?.payer_state : "",
    //                     payer_zip: data?.payer_zip ? data?.payer_zip : "",

    //                     //     // DBK
    //                     dbk_payee_name: data?.payee_name ? data?.payee_name : "",
    //                     dbk_payee_npi: data?.payee_npi ? data?.payee_npi : "",
    //                     dbk_payee_address: data?.payee_address ? data?.payee_address : "",
    //                     dbk_payee_city: data?.payee_city ? data?.payee_city : "",
    //                     dbk_payee_state: data?.payee_state ? data?.payee_state : "",
    //                     dbk_payee_zip: data?.payee_zip ? data?.payee_zip : "",

    //                     dbk_payer_name: data?.payer_name ? data?.payer_name : "",
    //                     dbk_payer_address: data?.payer_address ? data?.payer_address : "",
    //                     dbk_payer_city: data?.payer_city ? data?.payer_city : "",
    //                     dbk_payer_state: data?.payer_state ? data?.payer_state : "",
    //                     dbk_payer_zip: data?.payer_zip ? data?.payer_zip : "",
    //                 });

    //                 await formEob.setValues((prevState) => {
    //                     return {

    //                         ...prevState,

    //                         payee_id: data?.payee_id ? data?.payee_id : "",
    //                         payee_name: data?.payee_name ? data?.payee_name : "",
    //                         payee_npi: data?.payee_npi ? data?.payee_npi : "",
    //                         payee_address: data?.payee_address ? data?.payee_address : "",
    //                         payee_city: data?.payee_city ? data?.payee_city : "",
    //                         payee_state: data?.payee_state ? data?.payee_state : "",
    //                         payee_zip: data?.payee_zip ? data?.payee_zip : "",

    //                         payer_id: data?.payer_id ? data?.payer_id : "",
    //                         payer_name: data?.payer_name ? data?.payer_name : "",
    //                         payer_address: data?.payer_address ? data?.payer_address : "",
    //                         payer_city: data?.payer_city ? data?.payer_city : "",
    //                         payer_state: data?.payer_state ? data?.payer_state : "",
    //                         payer_zip: data?.payer_zip ? data?.payer_zip : "",
    //                         reference_entity_identifier: data?.reference_entity_identifier ? data?.reference_entity_identifier : "",

    //                         // DBK
    //                         dbk_payee_name: data?.payee_name ? data?.payee_name : "",
    //                         dbk_payee_npi: data?.payee_npi ? data?.payee_npi : "",
    //                         dbk_payee_address: data?.payee_address ? data?.payee_address : "",
    //                         dbk_payee_city: data?.payee_city ? data?.payee_city : "",
    //                         dbk_payee_state: data?.payee_state ? data?.payee_state : "",
    //                         dbk_payee_zip: data?.payee_zip ? data?.payee_zip : "",

    //                         dbk_payer_name: data?.payer_name ? data?.payer_name : "",
    //                         dbk_payer_address: data?.payer_address ? data?.payer_address : "",
    //                         dbk_payer_city: data?.payer_city ? data?.payer_city : "",
    //                         dbk_payer_state: data?.payer_state ? data?.payer_state : "",
    //                         dbk_payer_zip: data?.payer_zip ? data?.payer_zip : "",

    //                         data_837: data_837 ? data_837 : [],
    //                         claims: claims,
    //                     }
    //                 });
    //             }

    //         } else {
    //             // formEob.setValues({
    //             //     ...formEob.values,
    //             //     data_837: data_837,
    //             //     claims: claims,
    //             // });


    //             await formEob.setValues((prevState) => {
    //                 return {
    //                     ...prevState,
    //                     data_837: data_837,
    //                     claims: claims,
    //                 }
    //             });

    //         }


    //         setMoonji(true);
    //         setSelectedServiceLines();
    //         setPatientLookup(false);
    //         setPatientFocus(true);
    //     }
    //     // }

    // };
    const [firstPull, setFirstPull] = useState(false)
    const handleInsert837Data = async (response) => {
        const _ = require('lodash');
        const data = _.cloneDeep(response?.data);
        const claims = _.cloneDeep(formEob.values.claims);
        const data_837 = _.cloneDeep(formEob.values?.data_837);
        const sL837 = data?.service_lines;
        const service_lines = [];
        const remaining = _.cloneDeep(formEob.values.remaining_balance)
        const oldValue = _.cloneDeep(formEob.values)
        const excluded = await handlePayerExcludedPayer(data).then((payload) => {
            if (payload?.response && payload?.response?.is_excluded_payer) {
                setIsSwal(true);
                setDoubleClicked(false)
                return true;
            } else {
                return false;
            }
        });

        if (excluded) {
            setDoubleClicked(false)
            return false;
        } else {
            const filteredArray = [];
            const selectedIndexes = checkRef.current
                .filter(el => el?.checked)
                .map(el => el.value);

            claims[claim835Index].is_pull_837_error = true;
            if (qdxTransaction !== "PATIENT_PAY") {
                selectedIndexes.forEach(el => {
                    filteredArray.push(data?.service_lines[el]);
                });
                // alert('asfd')

                const filterArray = _.cloneDeep(filteredArray);
                let sl_balance = 0
                if (filterArray.length > 0) {
                    filterArray.forEach((serviceline, sfindex) => {



                        let value = serviceline?.billed
                        // let old_claim = claims[claim835Index].claim_billed
                        // let oldval = claims[claim835Index]?.service_lines[sfindex]?.billed
                        // let old_balance = claims[claim835Index]?.service_lines[sfindex]?.balance

                        let balance = parseFloat(value ? value : 0).toFixed(2)
                        sl_balance = parseFloat(parseFloat(sl_balance ? sl_balance : 0) + parseFloat(balance ? balance : 0)).toFixed(2);







                        service_lines.push({
                            ...serviceline,
                            color_data: serviceline,
                            from_date: serviceline?.from_date
                                ? moment(serviceline?.from_date)?.format("MM/DD/YYYY")
                                : "",
                            to_date: serviceline?.to_date
                                ? moment(serviceline?.to_date)?.format("MM/DD/YYYY")
                                : "",
                            code: serviceline?.code ? serviceline?.code : "",
                            modifier1: serviceline?.modifier1 ? serviceline?.modifier1 : "",
                            modifier2: serviceline?.modifier2 ? serviceline?.modifier2 : "",
                            modifier3: serviceline?.modifier3 ? serviceline?.modifier3 : "",
                            modifier4: serviceline?.modifier4 ? serviceline?.modifier4 : "",
                            units: serviceline?.units ? serviceline?.units : "",
                            procedure_type: serviceline?.procedure_type
                                ? serviceline?.procedure_type
                                : "",
                            rev_code: serviceline?.rev_code ? serviceline?.rev_code : "",
                            billed: parseFloat(serviceline?.billed) ? serviceline?.billed : "",
                            discount: "",
                            allowed: "",
                            deduct: "",
                            coins: "",
                            copay: "",
                            cob: "",
                            paid: "",
                            balance: parseFloat(balance ? balance : 0).toFixed(2),
                            adjustments: [
                                {
                                    adjustments_id: "",
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                },
                            ],
                        });

                    });
                } else {
                    service_lines.push({
                        color_index: "",
                        order: "",
                        service_line_id: "",
                        from_date: "",
                        to_date: "",
                        code: "",
                        procedure_type: "HC",
                        modifier1: "",
                        modifier2: "",
                        modifier3: "",
                        modifier4: "",
                        rev_code: "",
                        units: "",
                        patres: "",
                        billed: "",
                        discount: "",
                        allowed: "",
                        deduct: "",
                        coins: "",
                        copay: "",
                        cob: "",
                        paid: "",
                        color_data: [],

                        balance: "",
                        adjustments: [
                            {
                                adjustment_id: "",
                                pc: "",
                                group_code: "",
                                reason_code: "",
                                rmk: "",
                                amount: "",
                            },
                        ],
                    });
                }

                claims[claim835Index].sl_balance = parseFloat(sl_balance ? sl_balance : 0).toFixed(2);
                claims[claim835Index].claim_billed = parseFloat(sl_balance ? sl_balance : 0).toFixed(2);
                claims[claim835Index].sl_billed = parseFloat(sl_balance ? sl_balance : 0).toFixed(2);
                claims[claim835Index].claim_balance = parseFloat(sl_balance ? sl_balance : 0).toFixed(2)
                claims[claim835Index].encounter_id = data?.encounter_id;
                claims[claim835Index].color_data = data;
                claims[claim835Index].patient = true;
                claims[claim835Index].subscriber = true;
                claims[claim835Index].rendering_provider = true;

                claims[claim835Index].encounter = data?.encounter_id;
                claims[claim835Index].patient_account_number = data?.patient_account_number
                    ? data?.patient_account_number
                    : "";
                claims[claim835Index].status_code = data?.status_code
                    ? data?.status_code
                    : "";
                claims[claim835Index].patient_first_name = data?.patient_first_name
                    ? data?.patient_first_name
                    : "";
                claims[claim835Index].patient_middle_name = data?.patient_middle_name
                    ? data?.patient_middle_name
                    : "";
                claims[claim835Index].patient_last_name = data?.patient_last_name
                    ? data?.patient_last_name
                    : "";
                claims[claim835Index].patient_identifier_code =
                    data?.patient_identifier_code ? data?.patient_identifier_code : "";
                claims[claim835Index].subscriber_first_name = data?.subscriber_first_name
                    ? data?.subscriber_first_name
                    : "";
                claims[claim835Index].subscriber_middle_name = data?.subscriber_middle_name
                    ? data?.subscriber_middle_name
                    : "";
                claims[claim835Index].subscriber_last_name = data?.subscriber_last_name
                    ? data?.subscriber_last_name
                    : "";
                claims[claim835Index].subscriber_identifier_code =
                    data?.subscriber_identifier_code ? data?.subscriber_identifier_code : "";
                claims[claim835Index].rendering_provider_first_name =
                    data?.rendering_provider_first_name
                        ? data?.rendering_provider_first_name
                        : "";
                claims[claim835Index].rendering_provider_last_name =
                    data?.rendering_provider_last_name
                        ? data?.rendering_provider_last_name
                        : "";
                claims[claim835Index].rendering_provider_identifier_code =
                    data?.rendering_provider_identifier_code
                        ? data?.rendering_provider_identifier_code
                        : formEob?.values?.claims[claim835Index]?.rendering_provider_identifier_code
                            ? formEob?.values?.claims[claim835Index]?.rendering_provider_identifier_code
                            : "";

                // DBK START
                claims[claim835Index].dbk_patient_account_number =
                    data?.patient_account_number ? data?.patient_account_number : "";
                claims[claim835Index].dbk_patient_first_name = data?.patient_first_name
                    ? data?.patient_first_name
                    : "";
                claims[claim835Index].dbk_patient_middle_name = data?.patient_middle_name
                    ? data?.patient_middle_name
                    : "";
                claims[claim835Index].dbk_patient_last_name = data?.patient_last_name
                    ? data?.patient_last_name
                    : "";
                claims[claim835Index].dbk_patient_identifier_code =
                    data?.patient_identifier_code ? data?.patient_identifier_code : "";
                claims[claim835Index].dbk_subscriber_first_name =
                    data?.subscriber_first_name ? data?.subscriber_first_name : "";
                claims[claim835Index].dbk_subscriber_middle_name =
                    data?.subscriber_middle_name ? data?.subscriber_middle_name : "";
                claims[claim835Index].dbk_subscriber_last_name = data?.subscriber_last_name
                    ? data?.subscriber_last_name
                    : "";
                claims[claim835Index].dbk_subscriber_identifier_code =
                    data?.subscriber_identifier_code ? data?.subscriber_identifier_code : "";
                claims[claim835Index].dbk_rendering_provider_first_name =
                    data?.rendering_provider_first_name
                        ? data?.rendering_provider_first_name
                        : "";
                claims[claim835Index].dbk_rendering_provider_last_name =
                    data?.rendering_provider_last_name
                        ? data?.rendering_provider_last_name
                        : "";
                claims[claim835Index].dbk_rendering_provider_identifier_code =
                    data?.rendering_provider_identifier_code
                        ? data?.rendering_provider_identifier_code
                        : formEob?.values?.claims[claim835Index]?.rendering_provider_identifier_code
                            ? formEob?.values?.claims[claim835Index]?.rendering_provider_identifier_code
                            : "";
                claims[claim835Index].sl_discount = parseFloat(0).toFixed(2);
                claims[claim835Index].sl_allowed = parseFloat(0).toFixed(2);
                claims[claim835Index].sl_deduct = parseFloat(0).toFixed(2);
                claims[claim835Index].sl_coins = parseFloat(0).toFixed(2);
                claims[claim835Index].sl_copay = parseFloat(0).toFixed(2);
                claims[claim835Index].sl_cob = parseFloat(0).toFixed(2);
                claims[claim835Index].sl_patres = parseFloat(0).toFixed(2);
                claims[claim835Index].sl_amount = parseFloat(0).toFixed(2);
                claims[claim835Index].sl_paid = parseFloat(0).toFixed(2);
                // claims[claim835Index].sl_balance = parseFloat(0).toFixed(2);
                claims[claim835Index].sl_total = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_discount = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_allowed = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_deduct = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_coins = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_copay = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_cob = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_patres = parseFloat(0).toFixed(2);
                claims[claim835Index].fake_sl_patres = parseFloat(0).toFixed(2);
                claims[claim835Index].adjustment_total = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_adjustment_total = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_adjustment_total_pr = parseFloat(0).toFixed(2);
                // claims[claim835Index].claim_amount = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_paid = parseFloat(0).toFixed(2);
                // claims[claim835Index].claim_balance = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_total = parseFloat(0).toFixed(2);
                claims[claim835Index].claim_adjustments = [
                    {
                        pc: "",
                        group_code: "",
                        reason_code: "",
                        rmk: "",
                        amount: "",
                        claim_adjustment_id: "",
                        description: "",
                    },
                ];

                // DBK END

                claims[claim835Index].service_lines = service_lines;

                if (
                    claims[claim835Index] &&
                    claims[claim835Index].service_lines &&
                    claims[claim835Index].service_lines?.length > 0
                ) {
                    claims[claim835Index].period_start =
                        claims[claim835Index].service_lines[0].from_date;
                    claims[claim835Index].period_end =
                        claims[claim835Index].service_lines[0].to_date;
                    claims[claim835Index].service_lines?.forEach((service, sindex) => {
                        if (
                            claims[claim835Index].service_lines[sindex] &&
                            claims[claim835Index].service_lines[sindex].from_date &&
                            new Date(
                                claims[claim835Index].service_lines[sindex].from_date &&
                                !isNaN(
                                    new Date(
                                        claims[claim835Index].service_lines[sindex].from_date
                                    ).getDate()
                                )
                            )
                        ) {
                            if (
                                claims[claim835Index] &&
                                claims[claim835Index].period_start &&
                                new Date(claims[claim835Index].period_start)
                            ) {
                                if (
                                    new Date(
                                        claims[claim835Index].service_lines[sindex].from_date
                                    ) <= new Date(claims[claim835Index].period_start)
                                ) {
                                    claims[claim835Index].period_start =
                                        claims[claim835Index].service_lines[sindex].from_date;
                                }
                            }
                        }

                        if (
                            claims[claim835Index].service_lines[sindex] &&
                            claims[claim835Index].service_lines[sindex].to_date &&
                            new Date(claims[claim835Index].service_lines[sindex].to_date) &&
                            !isNaN(
                                new Date(
                                    claims[claim835Index].service_lines[sindex].to_date
                                ).getDate()
                            )
                        ) {
                            if (
                                new Date(
                                    claims[claim835Index].service_lines[sindex].to_date
                                ) >= new Date(claims[claim835Index].period_end)
                            ) {
                                claims[claim835Index].period_end =
                                    claims[claim835Index].service_lines[sindex].to_date;
                            }
                        }
                    });
                }
            } else {
                claims[claim835Index].encounter_id = data?.encounter_id;
                claims[claim835Index].color_data = data;
                claims[claim835Index].patient = true;
                claims[claim835Index].subscriber = true;
                claims[claim835Index].rendering_provider = true;

                claims[claim835Index].encounter = data?.encounter_id;
                claims[claim835Index].patient_account_number = data?.patient_account_number
                    ? data?.patient_account_number
                    : "";
                claims[claim835Index].patient_first_name = data?.patient_first_name
                    ? data?.patient_first_name
                    : "";

                claims[claim835Index].patient_last_name = data?.patient_last_name
                    ? data?.patient_last_name
                    : "";
                claims[claim835Index].period_start = data?.period_start
                    ? moment(data?.period_start).format("MM/DD/YYYY")
                    : "";
                claims[claim835Index].period_end = data?.period_end
                    ? moment(data?.period_end).format("MM/DD/YYYY")
                    : "";
                claims[claim835Index].mrn = data?.mrn
                    ? data?.mrn
                    : "";
                claims[claim835Index].statement_number = data?.statement_number
                    ? data?.statement_number
                    : "";
                claims[claim835Index].statement_date = data?.statement_date
                    ? moment(data?.statement_date).format('MM/DD/YYYY')
                    : "";
                claims[claim835Index].claim_billed = data?.claim_billed
                    ? parseFloat(data?.claim_billed ? data?.claim_billed : 0).toFixed(2)
                    : "";
                claims[claim835Index].claim_balance = parseFloat(parseFloat(claims[claim835Index].claim_billed ? claims[claim835Index].claim_billed : 0) - parseFloat(claims[claim835Index].claim_paid ? claims[claim835Index].claim_paid : 0)).toFixed(2);


                // DBK START
                claims[claim835Index].dbk_patient_account_number =
                    data?.patient_account_number ? data?.patient_account_number : "";
                claims[claim835Index].dbk_patient_first_name = data?.patient_first_name
                    ? data?.patient_first_name
                    : "";

                claims[claim835Index].dbk_patient_last_name = data?.patient_last_name
                    ? data?.patient_last_name
                    : "";
                claims[claim835Index].dbk_mrn = data?.mrn
                    ? data?.mrn_number
                    : "";
                claims[claim835Index].dbk_statement_number = data?.statement_number
                    ? data?.statement_number
                    : "";
                claims[claim835Index].dbk_statement_date = data?.statement_date
                    ? moment(data?.statement_date).format('MM/DD/YYYY')
                    : "";

                // DBK END
            }
            localStorage.setItem('837_pulled', true)
            const is_master_job = !isSubJob && isFirstJob
            const is_first_job = isSubJob && isFirstJob
            const is_normat_job = !isSubJob && !isFirstJob
            const is_child_job = isSubJob && !isFirstJob
            if (data_837.length === 0 || window.location.pathname == QA_REVIEW
                // (window.location.pathname == QA_REVIEW &&
                //     (!firstPull ||
                //         (formEob.values?.payee_name == '' &&
                //             formEob.values?.payee_npi == '' &&
                //             formEob.values?.payee_address == '' &&
                //             formEob.values?.payee_city == '' &&
                //             formEob.values?.payee_state == '' &&
                //             formEob.values?.payee_zip == '' &&
                //             formEob.values?.payer_name == '' &&
                //             formEob.values?.payer_address == '' &&
                //             formEob.values?.payer_city == '' &&
                //             formEob.values?.payer_state == '' &&
                //             formEob.values?.payer_zip == '')
                //     )
                // )
            ) {
                if ((is_master_job) || (is_first_job) || (!isSubJob && !isFirstJob)) {
                    data_837.push({
                        payee_name: data?.payee_name ? data?.payee_name : "",
                        payee_npi: data?.payee_npi ? data?.payee_npi : "",
                        payee_address: data?.payee_address ? data?.payee_address : "",
                        payee_city: data?.payee_city ? data?.payee_city : "",
                        payee_state: data?.payee_state ? data?.payee_state : "",
                        payee_zip: data?.payee_zip ? data?.payee_zip : "",

                        payer_name: data?.payer_name ? data?.payer_name : "",
                        payer_address: data?.payer_address ? data?.payer_address : "",
                        payer_city: data?.payer_city ? data?.payer_city : "",
                        payer_state: data?.payer_state ? data?.payer_state : "",
                        payer_zip: data?.payer_zip ? data?.payer_zip : "",

                        // DBK
                        dbk_payee_name: data?.payee_name ? data?.payee_name : "",
                        dbk_payee_npi: data?.payee_npi ? data?.payee_npi : "",
                        dbk_payee_address: data?.payee_address ? data?.payee_address : "",
                        dbk_payee_city: data?.payee_city ? data?.payee_city : "",
                        dbk_payee_state: data?.payee_state ? data?.payee_state : "",
                        dbk_payee_zip: data?.payee_zip ? data?.payee_zip : "",

                        dbk_payer_name: data?.payer_name ? data?.payer_name : "",
                        dbk_payer_address: data?.payer_address ? data?.payer_address : "",
                        dbk_payer_city: data?.payer_city ? data?.payer_city : "",
                        dbk_payer_state: data?.payer_state ? data?.payer_state : "",
                        dbk_payer_zip: data?.payer_zip ? data?.payer_zip : "",
                    });
                    await formEob.setValues((prevState) => ({
                        ...prevState,
                        // remaining_balance: parseFloat(parseFloat(remaining ? remaining : 0) + parseFloat(oldValue?.claims[claim835Index]?.claim_paid ? oldValue?.claims[claim835Index]?.claim_paid : 0)).toFixed(2),
                        payee_id: data?.payee_id ? data?.payee_id : "",
                        payee__pulled_id: data?.payee_id ? data?.payee_id : "",
                        payee_name: data?.payee_name ? data?.payee_name : "",
                        payee_npi: data?.payee_npi ? data?.payee_npi : "",
                        payee_address: data?.payee_address ? data?.payee_address : "",
                        payee_city: data?.payee_city ? data?.payee_city : "",
                        payee_state: data?.payee_state ? data?.payee_state : "",
                        payee_zip: data?.payee_zip ? data?.payee_zip : "",

                        payer_id: data?.payer_id ? data?.payer_id : "",
                        payer_pulled_id: data?.payer_id ? data?.payer_id : "",
                        payer_name: data?.payer_name ? data?.payer_name : "",
                        payer_address: data?.payer_address ? data?.payer_address : "",
                        payer_city: data?.payer_city ? data?.payer_city : "",
                        payer_state: data?.payer_state ? data?.payer_state : "",
                        payer_zip: data?.payer_zip ? data?.payer_zip : "",
                        reference_entity_identifier: data?.reference_entity_identifier ? data?.reference_entity_identifier : "",

                        // DBK
                        dbk_payee_name: data?.payee_name ? data?.payee_name : "",
                        dbk_payee_npi: data?.payee_npi ? data?.payee_npi : "",
                        dbk_payee_address: data?.payee_address ? data?.payee_address : "",
                        dbk_payee_city: data?.payee_city ? data?.payee_city : "",
                        dbk_payee_state: data?.payee_state ? data?.payee_state : "",
                        dbk_payee_zip: data?.payee_zip ? data?.payee_zip : "",

                        dbk_payer_name: data?.payer_name ? data?.payer_name : "",
                        dbk_payer_address: data?.payer_address ? data?.payer_address : "",
                        dbk_payer_city: data?.payer_city ? data?.payer_city : "",
                        dbk_payer_state: data?.payer_state ? data?.payer_state : "",
                        dbk_payer_zip: data?.payer_zip ? data?.payer_zip : "",

                        data_837: data_837 ? data_837 : [],
                        claims: claims,
                    }));
                    setTimeout(() => {
                        formEob.validateForm();
                    }, 300);
                }
                else if (is_child_job) {
                    await formEob.setValues((prevState) => ({
                        ...prevState,
                        // remaining_balance: parseFloat(parseFloat(remaining ? remaining : 0) + parseFloat(prevState?.claims[claim835Index]?.claim_paid ? prevState?.claims[claim835Index]?.claim_paid : 0)).toFixed(2),
                        data_837: data_837,
                        claims: claims,
                    }));
                    setTimeout(() => {
                        formEob.validateForm();
                    }, 300);
                }
                setFirstPull(true)
            } else {
                await formEob.setValues((prevState) => ({
                    ...prevState,
                    // remaining_balance: parseFloat(parseFloat(remaining ? remaining : 0) + parseFloat(prevState?.claims[claim835Index]?.claim_paid ? prevState?.claims[claim835Index]?.claim_paid : 0)).toFixed(2),
                    data_837: data_837,
                    claims: claims,
                }));
                setTimeout(() => {
                    formEob.validateForm();
                }, 300);
            }


            setSelectedServiceLines();
            setPatientLookup(false);
            setPatientFocus(true);
            setDoubleClicked(false)
        }
        setTimeout(() => {
            if (pathname == DATA_ENTRY && claim835Index == 0) {
                const claimAccountNo = document.querySelector(`[name="payer_name"]`);
                if (claimAccountNo) {
                    claimAccountNo.focus();
                }
            }
        }, 50)
    };


    const handlePayerExcludedPayer = (payer) => {
        return new Promise((resolve, reject) => {
            new checkExcludedPayer(jobData?.job, payer?.payer_id)((response) => {
                if (response) {
                    resolve({ success: true, response: response });
                } else {
                    reject({ success: false, message: response });
                }
            })
        });
    };
    // const handlePayerExcludedPayer = (payer) => {
    //     checkExcludedPayer(
    //         jobData?.job,
    //         payer?.payer_id
    //     )((response) => {
    //         if (response?.status) {
    //             // setIsSwal(true);
    //             return new Promise((resolve, reject) => {
    //                 resolve(response);
    //             })

    //         }
    //     });
    // }

    // patient lookup search funcitons and states
    // useEffect(() => {

    //     if (moonji == true) {
    //         if (qdxTransaction != "PATIENT_PAY") {

    //             formEob.values.claims[claim835Index].service_lines?.map(
    //                 (serviceLine, index) => {
    //                     handleColumnRowCalculation(index, 'billed')
    //                 }
    //             );
    //             setTimeout(() => {

    //                 handleClaimCalculation();
    //             }, 100);

    //             setTimeout(() => {

    //                 handleSlCalculation();
    //             }, 200);
    //             setMoonji(false);
    //         } else {
    //             handleClaimCalculation();
    //             setMoonji(false);

    //         }
    //     }
    // }, [moonji]);




    // ================================= REJECT OF 4 TRANSACTION TYPE ============================ //

    //reject functions and its states

    const [rejectPopup, setRejectPopup] = useState(false);
    const [rejectLabel, setRejectLabel] = useState(null);
    const rejectRef = useRef(null);

    const rejectForm = useFormik({
        // enableReinitialize: true,
        initialValues: {
            rejection_reason: "",
            reject_message: "",
        },
        validationSchema: Yup.object().shape({
            reject_message: Yup.string().required("Required"),
        }),
        onSubmit: (values, { setSubmitting }) => {
            setformApiLoaded(true)
            setSaveSuccess(true)
            setSubmitting(true)
            localStorage.removeItem('837_pulled')
            setLoadData(true)
            submitRejection(values, setSubmitting);
        },
    });

    const submitRejection = async (values, setSubmitting) => {

        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user["id"]) {
            var user_id = user["id"];
        }
        if (jobData && jobData.job) var job = jobData && jobData.job;
        const qdx_reasons = aditionalInfoFormik.values?.reason.length > 0 ? aditionalInfoFormik.values?.reason?.map((i) => i.value) : []
        rejectFile(
            job,
            values.rejection_reason,
            user_id,
            values.reject_message,
            qdx_reasons

        )(async (response) => {
            if (response?.status) {
                setformApiLoaded(false)
                setSubmitting(false)

                setToast({ status: true, type: "success", message: "Success" });

                // window.location.reload()
                rejectForm.setValues({
                    rejection_reason: "",
                    reject_message: "",
                })
                localStorage.removeItem('transaction_type')


            }
            else if (response?.status_code == 400 && response?.errors && response?.errors?.rejection_user?.length > 0) {

                // setErrorJobData()
                setformApiLoaded(false)
                setErrorAlert({
                    status: true,
                    type: "danger",
                    message: response?.errors?.rejection_user[0]
                })

                // setToast({ status: true, type: "danger", message: response?.errors?.rejection_user[0] });
            }
            else {
                setErrorJobData()
                setformApiLoaded(false)
                setToast({ status: true, type: "danger", message: "Failed" });
                setSubmitting(false)

            }
        });
        setRejectPopup(false);
    };

    const handleRejectFormClose = async () => {
        await rejectForm.handleReset();
        await rejectForm.validateForm();
    };

    // ================================= REJECT OF 4 TRANSACTION TYPE ============================ //

    // ================================= PARTIAL SAVE 4 TRANSACTION TYPE ============================ //

    const [partialSave, setPartialSave] = useState(false);
    const [partialSavePopUp, setPartialSavePopUp] = useState(false);
    const formPartial = useFormik({
        // enableReinitialize: true,
        initialValues: {
            partial_reason: "",
        },
        validationSchema: Yup.object().shape({
            partial_reason: Yup.string().required("Required"),
        }),

        onSubmit: (values, { setSubmitting }) => {

            setSubmitting(true)
            localStorage.removeItem('837_pulled')
            if (state.Payment835) {
                var data = formEob.values;
                data.work_type = window.location.pathname == DATA_ENTRY ? 1 : window.location.pathname == QA_REVIEW ? 2 : ""

                data.transaction_type = "PAYMENT835";
                data.status = "partial_save";
                data.rejection_reason = values?.partial_reason;
                data.payment_id = jobData?.grid_data?.payment_id ? jobData?.grid_data?.payment_id : "";
                data.is_custom_check_number = isCheckNumber;
                if (isNaN(data?.check_amount)) {
                    data.check_amount = isNaN(data?.check_amount) ? 0 : data.check_amount;
                }

                data.overall_page_from = isOverallPage ? overallPage?.from : 0;
                data.overall_page_to = isOverallPage ? overallPage?.to : 0;

                data.qdx_tranansaction_type = qdxTransaction;
                data.qdx_status = aditionalInfoFormik?.values?.status ? aditionalInfoFormik?.values?.status == "0" ? "" : aditionalInfoFormik?.values?.status : ""
                data.qdx_reason = aditionalInfoFormik?.values?.reason ? aditionalInfoFormik?.values?.reason == "0" ? "" : aditionalInfoFormik?.values?.reason : ""
                data.qdx_reasons = aditionalInfoFormik?.values?.reason.length > 0 ? aditionalInfoFormik?.values?.reason?.map((i) => i.value) : []

                data.qdx_classification = aditionalInfoFormik?.values?.document_classification ? aditionalInfoFormik?.values?.document_classification == "0" ? "" : aditionalInfoFormik?.values?.document_classification : ""


                data.rejection_reason = data?.rejection_reason ? data.rejection_reason.toUpperCase() : ""
                data.payment_method = data?.payment_method ? data.payment_method.toUpperCase() : ""
                data.payee_name = data?.payee_name ? data.payee_name.toUpperCase() : ""
                data.payee_npi = data?.payee_npi ? data.payee_npi.toUpperCase() : ""
                data.payee_address = data?.payee_address ? data.payee_address.toUpperCase() : ""
                data.payee_city = data?.payee_city ? data.payee_city.toUpperCase() : ""
                data.payee_state = data?.payee_state ? data.payee_state.toUpperCase() : ""

                data.payer_name = data?.payer_name ? data.payer_name.toUpperCase() : ""
                data.payer_address = data?.payer_address ? data.payer_address.toUpperCase() : ""
                data.payer_city = data?.payer_city ? data.payer_city.toUpperCase() : ""
                data.payer_state = data?.payer_state ? data.payer_state.toUpperCase() : ""
                if (data.grid_customization != "PLB_ONLY") {
                    if (data?.claims.length > 0) {

                        data.claims.map((claim, index) => {

                            claim.order = index + 1;
                            // claim.corrected_claim = claim?.payment_id ? true : false;
                            claim.corrected_claim = claim?.corrected_claim ? true : false;


                            claim.encounter = claim?.encounter ? claim?.encounter : "";
                            claim.patient_account_number = claim?.patient_account_number ? claim?.patient_account_number.toUpperCase() : "";
                            claim.patient_first_name = claim?.patient_first_name ? claim?.patient_first_name.toUpperCase() : "";
                            claim.patient_middle_name = claim?.patient_middle_name ? claim?.patient_middle_name.toUpperCase() : "";
                            claim.patient_last_name = claim?.patient_last_name ? claim?.patient_last_name.toUpperCase() : "";
                            claim.patient_identifier_code = claim?.patient_identifier_code ? claim?.patient_identifier_code.toUpperCase() : "";

                            claim.subscriber_first_name = claim?.subscriber_first_name ? claim?.subscriber_first_name.toUpperCase() : "";
                            claim.subscriber_middle_name = claim?.subscriber_middle_name ? claim?.subscriber_middle_name.toUpperCase() : "";
                            claim.subscriber_last_name = claim?.subscriber_last_name ? claim?.subscriber_last_name.toUpperCase() : ""
                            claim.subscriber_identifier_code = claim?.subscriber_identifier_code ? claim?.subscriber_identifier_code.toUpperCase() : "";

                            claim.rendering_provider_first_name = claim?.rendering_provider_first_name ? claim?.rendering_provider_first_name.toUpperCase() : "";
                            claim.rendering_provider_last_name = claim?.rendering_provider_last_name ? claim?.rendering_provider_last_name.toUpperCase() : "";
                            claim.rendering_provider_identifier_code = claim?.rendering_provider_identifier_code ? claim?.rendering_provider_identifier_code.toUpperCase() : "";

                            claim.service_lines?.map((serviceline, index) => {
                                serviceline.order = index + 1;
                            })
                        })

                    }
                } else {
                    data.claims = []
                }
                if (data?.plb_adjustments?.length > 0) {
                    data?.plb_adjustments?.map((plb, index) => {
                        plb.plb_amt = plb?.plb_amt > 0 || plb?.plb_amt < 0 ? parseFloat(plb?.plb_amt ? plb?.plb_amt : 0).toFixed(2) : parseFloat(0).toFixed(2);
                    })
                }
                const RedirectId = getRedirectTabId()

                createOrUpdatePayment835(data)((response) => {
                    if (response?.status && response?.status_code === 200) {
                        // navigate(DASHBOARD);
                        setToast({ status: true, type: "success", message: "Success" });

                        localStorage.removeItem('grid_data')
                        localStorage.removeItem('grid_state_data')
                        localStorage.removeItem('grid_qdx_data')
                        localStorage.removeItem('grid_overall_data')
                        handleNavigateDashBoard(RedirectId)
                    }
                    else if (response?.status_code == 400 && response?.errors && response?.errors?.assign_user?.length > 0) {
                        // setToast({ status: true, type: "danger", message: response?.errors?.assign_user[0] });

                        setErrorAlert({
                            status: true,
                            type: "danger",
                            message: response?.errors?.assign_user[0]
                        })
                        setSubmitting(false);

                    } else {
                        setErrorJobData()
                        setToast({ status: true, type: "danger", message: "Failed" });
                        setSubmitting(false)
                    }
                });
            } else if (state.Payment835Lite) {
                var data = eobLiteFormik.values;

                data.transaction_type = "PAYMENT835_LITE";
                data.status = "partial_save";
                data.work_type = window.location.pathname == DATA_ENTRY ? 1 : window.location.pathname == QA_REVIEW ? 2 : ""
                data.overall_page_from = isOverallPage ? overallPage?.from : 0;
                data.overall_page_to = isOverallPage ? overallPage?.to : 0;

                data.qdx_status = aditionalInfoFormik?.values?.status ? aditionalInfoFormik?.values?.status == "0" ? "" : aditionalInfoFormik?.values?.status : ""
                // data.qdx_reason = aditionalInfoFormik?.values?.reason ? aditionalInfoFormik?.values?.reason == "0" ? "" : aditionalInfoFormik?.values?.reason : ""
                data.qdx_reasons = aditionalInfoFormik?.values?.reason.length > 0 ? aditionalInfoFormik?.values?.reason?.map((i) => i.value) : []

                data.qdx_classification = aditionalInfoFormik?.values?.document_classification ? aditionalInfoFormik?.values?.document_classification == "0" ? "" : aditionalInfoFormik?.values?.document_classification : ""

                data.rejection_reason = values?.partial_reason;
                data.payment_id = jobData?.grid_data?.payment_id
                    ? jobData?.grid_data?.payment_id
                    : "";
                data.check_amount = data?.check_amount > 0 ? parseFloat(data?.check_amount ? data?.check_amount : 0) : parseFloat(0).toFixed(2)
                data.check_date = moment(data.check_date, "MM/DD/YYYY", true).isValid()
                    ? moment(data.check_date).format("MM/DD/YYYY")
                    : "";
                if (data.grid_customization != "PLB_ONLY") {
                    data.claims.map((claim, index) => {
                        claim.order = index + 1;
                        claim.corrected_claim = claim?.corrected_claim ? claim?.corrected_claim : false;

                        // claim.service_lines.period_start = moment(
                        //     claim.service_lines.period_start,
                        //     "MM/DD/YYYY",
                        //     true
                        // ).isValid()
                        //     ? moment(claim.service_lines.period_start).format("MM/DD/YYYY")
                        //     : "";
                        // claim.service_lines.period_end = moment(
                        //     claim.service_lines.period_end,
                        //     "MM/DD/YYYY",
                        //     true
                        // ).isValid()
                        //     ? moment(claim.service_lines.period_end).format("MM/DD/YYYY")
                        //     : "";
                    });
                } else {

                    data.claims = []
                }
                const RedirectId = getRedirectTabId()
                createUpdate835Lite(data)((response) => {
                    if (response?.status && response?.status_code === 200) {
                        // navigate(DASHBOARD);

                        // if (qaData || job_id) {
                        //     getQaReviewJob();
                        //     setToast({ status: true, type: "success", message: "Success" })
                        //     resertFormikData();
                        // }
                        // else {
                        //     getjobs();
                        setToast({ status: true, type: "success", message: "Success" });

                        localStorage.removeItem('grid_data')
                        localStorage.removeItem('grid_state_data')
                        localStorage.removeItem('grid_qdx_data')
                        localStorage.removeItem('grid_overall_data')
                        handleNavigateDashBoard(RedirectId)
                        // }
                        // resertFormikData();
                    }
                    else if (response?.status_code == 400 && response?.errors && response?.errors?.assign_user?.length > 0) {
                        // setToast({ status: true, type: "danger", message: response?.errors?.assign_user[0] });
                        setErrorAlert({
                            status: true,
                            type: "danger",
                            message: response?.errors?.assign_user[0]
                        })
                        setSubmitting(false);
                    } else {
                        setErrorJobData()
                        // alert('moonji')
                        setToast({ status: true, type: "danger", message: "Failed" });
                        setSubmitting(false)

                        // const errors = response.response?.data?.errors;
                        // formik.setErrors({ errors })
                    }
                });
            } else if (state.PatientPay) {
                var data = formPP.values;
                data.status = "partial_save"
                // data.transaction_type = 'PATIENT_PAY';
                // data.job = jobData?.job ? jobData?.job : "";
                // data.status = "partial_save"
                // data.payment_id = jobData?.grid_data?.payment_id ? jobData?.grid_data?.payment_id : "";
                // data.rejection_reason = values?.partial_reason;

                let formData = new FormData();

                // formData.append("job", data?.job);
                // formData.append("transaction_type", "PATIENT_PAY");
                // formData.append("status", "partial_save");
                // formData.append("rejection_reason", values?.partial_reason);
                // formData.append("work_type", window.location.pathname == DATA_ENTRY ? 1 : window.location.pathname == QA_REVIEW ? 2 : "");

                // formData.append("overall_page_from", isOverallPage ? overallPage?.from : 0);
                // formData.append("overall_page_to", isOverallPage ? overallPage?.to : 0);

                // formData.append("qdx_status", aditionalInfoFormik?.values?.status ? aditionalInfoFormik?.values?.status == "0" ? "" : aditionalInfoFormik?.values?.status : "");
                // formData.append("qdx_reason", aditionalInfoFormik?.values?.reason ? aditionalInfoFormik?.values?.reason == "0" ? "" : aditionalInfoFormik?.values?.reason : "");
                // formData.append("qdx_classification", aditionalInfoFormik?.values?.document_classification ? aditionalInfoFormik?.values?.document_classification == "0" ? "" : aditionalInfoFormik?.values?.document_classification : "");


                // formData.append("payment_id", data?.payment_id ? data?.payment_id : "");
                // formData.append("check_number", data?.check_number);
                // formData.append("check_date", data?.check_date);
                // formData.append("check_amount", data?.check_amount > 0 ? parseFloat(data?.check_amount ? data?.check_amount : 0).toFixed(2) : parseFloat(0).toFixed(2));
                // formData.append("patient_id", data?.patient_id);
                // formData.append("patient_account_number", data?.patient_account_number);
                // formData.append("patient_first_name", data?.patient_first_name);
                // formData.append("patient_middle_name", data?.patient_middle_name);
                // formData.append("patient_last_name", data?.patient_last_name);
                // formData.append("amount_due", data?.amount_due > 0 ? data?.amount_due : parseFloat(0).toFixed(2));
                // formData.append("statement_amount", data?.statement_amount > 0 ? data?.statement_amount : parseFloat(0).toFixed(2));
                // formData.append("statement_number", data?.statement_number > 0 ? data?.statement_number : parseFloat(0).toFixed(2));
                // formData.append("statement_date", data?.statement_date);
                const data1 = {}
                data1.job = data?.job;
                data1.transaction_type = "PATIENT_PAY";
                data1.status = "partial_save";
                data1.rejection_reason = values?.partial_reason;
                data1.work_type = window.location.pathname == DATA_ENTRY ? 1 : window.location.pathname == QA_REVIEW ? 2 : "";

                data1.overall_page_from = isOverallPage ? overallPage?.from : 0;
                data1.overall_page_to = isOverallPage ? overallPage?.to : 0;

                data1.qdx_status = aditionalInfoFormik?.values?.status ? aditionalInfoFormik?.values?.status == "0" ? "" : aditionalInfoFormik?.values?.status : "";
                // data1.qdx_reason = aditionalInfoFormik?.values?.reason ? aditionalInfoFormik?.values?.reason == "0" ? "" : aditionalInfoFormik?.values?.reason : "";
                data1.qdx_reasons = aditionalInfoFormik?.values?.reason.length > 0 ? aditionalInfoFormik?.values?.reason?.map((i) => i.value) : [];

                data1.qdx_classification = aditionalInfoFormik?.values?.document_classification ? aditionalInfoFormik?.values?.document_classification == "0" ? "" : aditionalInfoFormik?.values?.document_classification : "";


                data1.payment_id = data?.payment_id ? data?.payment_id : "";
                data1.check_number = data?.check_number;
                data1.check_date = data?.check_date;
                data1.check_amount = data?.check_amount > 0 ? parseFloat(data?.check_amount ? data?.check_amount : 0).toFixed(2) : parseFloat(0).toFixed(2);
                data1.patient_id = data?.patient_id;
                data1.patient_account_number = data?.patient_account_number;
                data1.patient_first_name = data?.patient_first_name;
                data1.patient_middle_name = data?.patient_middle_name;
                data1.patient_last_name = data?.patient_last_name;
                data1.amount_due = data?.amount_due > 0 ? data?.amount_due : parseFloat(0).toFixed(2);
                data1.statement_amount = data?.statement_amount > 0 ? data?.statement_amount : parseFloat(0).toFixed(2);
                data1.statement_number = data?.statement_number > 0 ? data?.statement_number : parseFloat(0).toFixed(2);
                data1.statement_date = data?.statement_date;
                data1.patient_page_from = data?.patient_page_from;
                data1.patient_page_to = data?.patient_page_to;
                const RedirectId = getRedirectTabId()
                createOrUpdatePatientPay(data1)((response) => {
                    if (response?.status && response?.status_code === 200) {
                        // navigate(DASHBOARD);

                        // if (qaData || job_id) {
                        //     getQaReviewJob();
                        //     setToast({ status: true, type: "success", message: "Success" })
                        //     resertFormikData();
                        // }
                        // else {
                        //     getjobs();
                        setToast({ status: true, type: "success", message: "Success" });

                        localStorage.removeItem('grid_data')
                        localStorage.removeItem('grid_state_data')
                        localStorage.removeItem('grid_qdx_data')
                        localStorage.removeItem('grid_overall_data')
                        handleNavigateDashBoard(RedirectId)
                        // }
                        // resertFormikData();
                    } else if (response?.status_code == 400 && response?.errors && response?.errors?.assign_user?.length > 0) {
                        setErrorAlert({
                            status: true,
                            type: "danger",
                            message: response?.errors?.assign_user[0]
                        })
                        setSubmitting(false);

                    } else {
                        setErrorJobData(data)
                        // alert('moonji')
                        setToast({ status: true, type: "danger", message: "Failed" });
                        setSubmitting(false)

                        // const errors = response.response?.data?.errors;
                        // formik.setErrors({ errors })
                    }
                });
            } else if (state.Correspondence) {
                var data = formCorres.values;

                data.transaction_type = "CORRESPONDENCE";
                data.status = "partial_save";
                data.work_type = window.location.pathname == DATA_ENTRY ? 1 : window.location.pathname == QA_REVIEW ? 2 : ""

                data.overall_page_from = isOverallPage ? overallPage?.from : 0;
                data.overall_page_to = isOverallPage ? overallPage?.to : 0;

                data.payment_id = jobData?.grid_data?.payment_id
                    ? jobData?.grid_data?.payment_id
                    : "";

                data.qdx_status = aditionalInfoFormik?.values?.status ? aditionalInfoFormik?.values?.status == "0" ? "" : aditionalInfoFormik?.values?.status : ""
                // data.qdx_reason = aditionalInfoFormik?.values?.reason ? aditionalInfoFormik?.values?.reason == "0" ? "" : aditionalInfoFormik?.values?.reason : ""
                data.qdx_reasons = aditionalInfoFormik?.values?.reason.length > 0 ? aditionalInfoFormik?.values?.reason?.map((i) => i.value) : [];

                data.qdx_classification = aditionalInfoFormik?.values?.document_classification ? aditionalInfoFormik?.values?.document_classification == "0" ? "" : aditionalInfoFormik?.values?.document_classification : ""
                data.rejection_reason = values?.partial_reason;

                if (data?.claims?.length > 0) {
                    data?.claims?.map((claim, index) => {
                        claim.order = index + 1;
                        claim.corrected_claim = claim?.corrected_claim ? claim?.corrected_claim : false;
                        claim.billed = claim?.billed > 0 ? parseFloat(claim?.billed ? claim?.billed : 0).toFixed(2) : parseFloat(0).toFixed(2);
                    })
                }
                const RedirectId = getRedirectTabId()
                createOrUpdateCorres(data)((response) => {
                    if (response?.status && response?.status_code === 200) {
                        // navigate(DASHBOARD);

                        // if (qaData || job_id) {
                        //     getQaReviewJob();
                        //     setToast({ status: true, type: "success", message: "Success" })
                        //     resertFormikData();
                        // }
                        // else {
                        //     getjobs();
                        setToast({ status: true, type: "success", message: "Success" });

                        localStorage.removeItem('grid_data')
                        localStorage.removeItem('grid_state_data')
                        localStorage.removeItem('grid_qdx_data')
                        localStorage.removeItem('grid_overall_data')
                        handleNavigateDashBoard(RedirectId)
                        // }
                        // resertFormikData();
                    }
                    else if (response?.status_code == 400 && response?.errors && response?.errors?.assign_user?.length > 0) {
                        // setToast({ status: true, type: "danger", message: response?.errors?.assign_user[0] });
                        setErrorAlert({
                            status: true,
                            type: "danger",
                            message: response?.errors?.assign_user[0]
                        })
                        setSubmitting(false);

                    } else {
                        setErrorJobData()
                        // alert('moonji')
                        setToast({ status: true, type: "danger", message: "Failed" });
                        setSubmitting(false)

                        // const errors = response.response?.data?.errors;
                        // formik.setErrors({ errors })
                    }
                });
            }
            setPartialSavePopUp(false);
            setIsDrag(true);
            //  actions.resetForm()
        },
    });
    const handlePartialFormClose = async () => {
        await formPartial.handleReset();
        await formPartial.validateForm();
    };





    // ================================= PARTIAL SAVE 4 TRANSACTION TYPE ============================ //

    // formiks here
    // ====================================  EOB 835 FORM STARTS ======================================//
    const [isDisabled, setIsDisabled] = useState(true);


    const changeStructureForLargeBatch = (isNew, isOld, isLength, claim, totalCount, isClaim = false, isServiceLines = false, isClaimAdjustments = false, isAdjustmentsPerServiceLine = false, array = []) => {
        if (jobData?.is_large_batch_job) {


            const { sub_job_id } = claim;
            if (sub_job_id) {
                if (!totalCount[sub_job_id]) {
                    if (isClaim) {
                        totalCount[sub_job_id] = {
                            new_claimCount: isLength ? claim.length : 1,
                        };

                    }
                    else if (isServiceLines) {
                        totalCount[sub_job_id] = {
                            new_serviceLinesCount: isLength ? claim.service_lines.length : 1,
                        };
                    }
                    else if (isClaimAdjustments) {
                        totalCount[sub_job_id] = {
                            new_claimAdjustmentsCount: 1,
                        };
                    }
                    else if (isAdjustmentsPerServiceLine) {
                        totalCount[sub_job_id] = {
                            new_adjustmentsPerServiceLineCount: 1,
                        };
                    }
                }

                else if (isClaim) {
                    totalCount[sub_job_id].new_claimCount += isLength ? claim.length : 1;

                }

                else if (isServiceLines) {
                    let serviceLineCount = isLength ? claim.service_lines.length : 1
                    if (totalCount[sub_job_id].new_serviceLinesCount) {
                        totalCount[sub_job_id].new_serviceLinesCount += serviceLineCount;
                    } else {
                        totalCount[sub_job_id].new_serviceLinesCount = serviceLineCount
                    }
                }

                else if (isClaimAdjustments) {
                    let claimAdjustmentsCount = isLength ? claim.claim_adjustments.length : 1
                    if (totalCount[sub_job_id].new_claimAdjustmentsCount) {
                        totalCount[sub_job_id].new_claimAdjustmentsCount += claimAdjustmentsCount;
                    } else {
                        totalCount[sub_job_id].new_claimAdjustmentsCount = claimAdjustmentsCount;
                    }

                }

                else if (isAdjustmentsPerServiceLine) {
                    let adjustmentsPerServiceLineCount = isLength ? array.length : 1
                    if (totalCount[sub_job_id].new_adjustmentsPerServiceLineCount) {
                        totalCount[sub_job_id].new_adjustmentsPerServiceLineCount += adjustmentsPerServiceLineCount;
                    } else {
                        totalCount[sub_job_id].new_adjustmentsPerServiceLineCount = adjustmentsPerServiceLineCount;
                    }
                    // alert("EXIST new_adjustmentsPerServiceLineCount")
                }
            } else {

            }
        }

    }
    function subtractDicts(list1, list2) {
        const result = [];

        for (const dict1 of list1) {
            const sub_job_id = dict1["sub_job_id"];
            const matching_dict2 = list2.find((dict2) => dict2["sub_job_id"] === sub_job_id);

            if (matching_dict2) {
                const subtracted_dict = {
                    sub_job_id,
                    deleted_claims: Math.abs(dict1["new_claimCount"] - matching_dict2["new_claimCount"]),
                    deleted_service_lines: Math.abs(dict1["new_serviceLinesCount"] - matching_dict2["new_serviceLinesCount"]),
                    deleted_claim_adjustments: Math.abs(dict1["new_claimAdjustmentsCount"] - matching_dict2["new_claimAdjustmentsCount"]),
                    deleted_service_adjustments: Math.abs(dict1["new_adjustmentsPerServiceLineCount"] - matching_dict2["new_adjustmentsPerServiceLineCount"]),
                };
                result.push(subtracted_dict);
            }
        }

        return result;
    }


    const getOldGridDataCount = (old_data, new_data) => {

        // taking new counts
        const claimArr = [];
        const serviceLinesArr = [];
        const claimAdjArr = [];
        const serviceLineAdjArr = [];

        const pulledCliamIds = new_data.claims?.filter(i => i.is_pull_837_error)?.map(i => i.claim_id)

        const new_claimsData = new_data.claims?.filter(i => !i.is_pull_837_error)
        let new_plbAdjustmentsCount = 0;
        let new_claimCount = 0;
        let new_serviceLinesCount = 0;
        let new_claimAdjustmentsCount = 0;
        let new_adjustmentsPerServiceLineCount = 0;

        new_data.plb_adjustments?.forEach((plb_adj) => {
            if (plb_adj.plb_adjustment_id)
                new_plbAdjustmentsCount += 1
        })

        const new_totalCount = {};
        const old_totalCount = {};
        const pulled_true_ids = []
        new_claimsData?.forEach((claim) => {

            if (claim.claim_id) {
                // ==========NEW================

                changeStructureForLargeBatch(true, false, false, claim, new_totalCount, true)
                //   =========================
                claimArr.push(claim.claim_id)
                new_claimCount += 1
            }
            if (new_data.transaction_type === "PAYMENT835") {
                claim.service_lines?.forEach((serviceLine) => {
                    if (serviceLine.service_line_id) {
                        serviceLinesArr.push(String(claim.claim_id) + String(serviceLine.service_line_id))
                    }

                    // new_serviceLinesCount += 1
                    serviceLine.adjustments?.forEach((adjustment) => {
                        if (adjustment.adjustment_id) {
                            serviceLineAdjArr.push(String(serviceLine.service_line_id) + String(adjustment.adjustment_id))

                            // new_adjustmentsPerServiceLineCount += 1;
                        }
                    })
                });
                claim.claim_adjustments?.forEach((claimadj) => {
                    if (claimadj.claim_adjustment_id) {
                        // new_claimAdjustmentsCount += 1
                        claimAdjArr.push(String(claim.claim_id) + String(claimadj.claim_adjustment_id))

                    }


                });
            }

        });

        // taking old counts
        const old_claimsData = old_data.claims?.filter(i => !pulledCliamIds.includes(i?.claim_id))
        let old_plbAdjustmentsCount = old_data?.plb_adjustments?.length ?? 0;
        if (new_data.transaction_type === "CORRESPONDENCE" || new_data.transaction_type === "PATIENT_PAY") {
            let old_plbAdjustmentsCount = 0;
        }
        let old_claimCount = old_claimsData?.length;
        let old_serviceLinesCount = 0;
        let old_claimAdjustmentsCount = 0;
        let old_adjustmentsPerServiceLineCount = 0;


        old_claimsData?.forEach((claim) => {
            // ==========OLD CLAIM COUNT================
            changeStructureForLargeBatch(false, true, false, claim, old_totalCount, true)
            //   =========================

            if (new_data.transaction_type === "PAYMENT835") {
                old_claimAdjustmentsCount += claim?.claim_adjustments?.length;
                old_serviceLinesCount += claim?.service_lines?.length;

                // ==========OLD SERVICELINE COUNT================
                changeStructureForLargeBatch(false, true, true, claim, old_totalCount, false, true)
                //   =========================
                // ==========OLD CLAIMADJUSTMENT COUNT================
                changeStructureForLargeBatch(false, true, true, claim, old_totalCount, false, false, true)
                //   =========================
            }

            const isClaimExists = claimArr.includes(claim.claim_id)

            if (!isClaimExists && claim.claim_id && claimArr?.length > 0) {


                if (new_data.transaction_type === "PAYMENT835") {
                    new_claimAdjustmentsCount += claim.claim_adjustments?.length;
                    // =======NEW CLAIMADJUSTMENT============
                    changeStructureForLargeBatch(true, false, true, claim, new_totalCount, false, false, true)
                    // ===================

                    new_serviceLinesCount += claim.service_lines?.length;
                    // =======NEW SERVICELINE============
                    changeStructureForLargeBatch(true, false, true, claim, new_totalCount, false, true)
                    // ===================

                }
            }
            if (new_data.transaction_type === "PAYMENT835") {
                claim && claim?.service_lines?.length > 0 && claim?.service_lines?.forEach((serviceLine) => {

                    const isServiceLineExists = serviceLinesArr?.includes(String(claim?.claim_id) + String(serviceLine?.service_line_id))
                    old_adjustmentsPerServiceLineCount += serviceLine?.adjustments?.length;
                    // ==========OLD SERVICELINEADJUSTMENT COUNT================
                    changeStructureForLargeBatch(false, true, true, claim, old_totalCount, false, false, false, true, serviceLine.adjustments)
                    //   =========================
                    if (!isServiceLineExists && serviceLinesArr?.length > 0) {


                        new_adjustmentsPerServiceLineCount += serviceLine.adjustments.length;
                        // =======NEW SERVICELINEADJUSTMENT COUNT============
                        changeStructureForLargeBatch(true, false, true, claim, new_totalCount, false, false, false, true, serviceLine.adjustments)
                        // ===================

                    } else if (serviceLinesArr.length > 0) {
                        new_serviceLinesCount += 1;
                        // =======NEW SERVICELINE COUNT===========
                        changeStructureForLargeBatch(true, false, false, claim, new_totalCount, false, true)
                        // ==================

                        serviceLine.adjustments?.forEach((adjustment) => {
                            const isServiceLineAdjExists = serviceLineAdjArr.includes(String(serviceLine?.service_line_id) + String(adjustment.adjustment_id))
                            if (isServiceLineAdjExists) {
                                new_adjustmentsPerServiceLineCount += 1;
                                // =======NEW SERVICELINEADJUSTMENT COUNT============
                                changeStructureForLargeBatch(true, false, false, claim, new_totalCount, false, false, false, true, [])
                                // ===================
                            }
                        })
                    }

                });
                claim?.claim_adjustments?.forEach((adj) => {

                    const isClaimAdjExists = claimAdjArr.includes(String(claim.claim_id) + String(adj.claim_adjustment_id))
                    if (isClaimAdjExists) {
                        new_claimAdjustmentsCount += 1;
                        // =======NEW CLAIMADJUSTMENT============
                        changeStructureForLargeBatch(true, false, false, claim, new_totalCount, false, false, true)
                        // ===================

                    }

                });
            }

        });

        // taking latest count from substracting the old count and new count
        const deleted_plb = jobData?.is_large_batch_job ? 0 : old_plbAdjustmentsCount - new_plbAdjustmentsCount
        const deleted_claims = old_claimCount - new_claimCount
        const deleted_service_lines = qdxTransaction === "PATIENT_PAY" ? 0 : old_serviceLinesCount - new_serviceLinesCount
        const deleted_claim_adjustments = qdxTransaction === "PATIENT_PAY" ? 0 : old_claimAdjustmentsCount - new_claimAdjustmentsCount
        const deleted_service_adjustments = qdxTransaction === "PATIENT_PAY" ? 0 : old_adjustmentsPerServiceLineCount - new_adjustmentsPerServiceLineCount


        const new_result = Object.keys(new_totalCount).map((sub_job_id) => ({
            sub_job_id: parseInt(sub_job_id),
            new_claimCount: new_totalCount[sub_job_id].new_claimCount ?? 0,
            new_serviceLinesCount: new_totalCount[sub_job_id].new_serviceLinesCount ?? 0,
            new_claimAdjustmentsCount: new_totalCount[sub_job_id].new_claimAdjustmentsCount ?? 0,
            new_adjustmentsPerServiceLineCount: new_totalCount[sub_job_id].new_adjustmentsPerServiceLineCount ?? 0,
        }));
        const old_result = Object.keys(old_totalCount).map((sub_job_id) => ({
            sub_job_id: parseInt(sub_job_id),
            new_claimCount: old_totalCount[sub_job_id].new_claimCount,
            new_serviceLinesCount: old_totalCount[sub_job_id].new_serviceLinesCount,
            new_claimAdjustmentsCount: old_totalCount[sub_job_id].new_claimAdjustmentsCount,
            new_adjustmentsPerServiceLineCount: old_totalCount[sub_job_id].new_adjustmentsPerServiceLineCount,
        }));

        const subjob_details = subtractDicts(new_result, old_result)
        new_data.subjob_details = subjob_details

        return {
            deleted_claims,
            deleted_service_lines,
            deleted_plb,
            deleted_claim_adjustments,
            deleted_service_adjustments,
        };
    };

    const handleClaimSubJobPageRange = (claims, oldData) => {

        const subJobsData = jobUserData
        const oldClaim = oldData?.grid_data?.claims
        if (claims && claims?.length > 0 && oldData?.is_large_batch_job) {



            const matchingClaims = [];

            subJobsData.forEach((obj) => {
                // NEW CLAIM
                claims.forEach((claim) => {
                    const fromPage = obj.from_page;

                    const toPage = obj.to_page;

                    const headerRange = oldData?.header_range;
                    const footerRange = oldData?.footer_range;

                    if (claim.page_from >= fromPage && claim.page_to <= toPage) {

                        claim.sub_job_id = obj.pk;
                    }
                    else if (headerRange?.includes(claim?.page_from) || headerRange?.includes(claim?.page_to) || oldData?.grid_data?.check_page_number == claim?.page_from || oldData?.grid_data?.check_page_number == claim?.page_to) {
                        claim.sub_job_id = subJobsData[0]?.pk;
                    }
                    else if (footerRange?.includes(claim?.page_from) || footerRange?.includes(claim?.page_to)) {
                        claim.sub_job_id = subJobsData[subJobsData?.length - 1]?.pk;
                    } else {

                    }

                });
                // OLD CLAIM
                oldClaim.forEach((claim) => {

                    const fromPage = obj.from_page;

                    const toPage = obj.to_page;

                    const headerRange = oldData?.header_range;
                    const footerRange = oldData?.footer_range;

                    if (claim.page_from >= fromPage && claim.page_to <= toPage) {

                        claim.sub_job_id = obj.pk;
                    }
                    else if (headerRange?.includes(claim?.page_from) || headerRange?.includes(claim?.page_to) || oldData?.grid_data?.check_page_number == claim?.page_from || oldData?.grid_data?.check_page_number == claim?.page_to) {
                        claim.sub_job_id = subJobsData[0]?.pk;
                    }
                    else if (footerRange?.includes(claim?.page_from) || footerRange?.includes(claim?.page_to)) {
                        claim.sub_job_id = subJobsData[subJobsData?.length - 1]?.pk;
                    }

                });


            });
            oldData.grid_data.claim = oldClaim
            return claims;
        } else {
            return claims
        }
    }

    const [isIncomplete, setIsIncomplete] = useState(false)

    const getTotalFieldsCount = (formik) => {
        const gridContainer = document.getElementById('grid-full-container');
        let additionalInfoCount = 0
        if (jobData?.client_level_customization?.is_additional_info_available) {
            additionalInfoCount += isAdditionalInfoOptions?.status ? 1 : 0
            additionalInfoCount += isAdditionalInfoOptions?.reason ? 1 : 0
            additionalInfoCount += isAdditionalInfoOptions?.document_classification ? 1 : 0
            additionalInfoCount += isAdditionalInfoOptions?.extra_info ? 5 : 0
        }

        const isExcluded = element => element.getAttribute('data-not-take-field-count') === 'true';
        const dropdownButtons = Array.from(gridContainer.querySelectorAll('button[data-bs-toggle="dropdown"]')).filter(el => !isExcluded(el));
        const dropDownCount = Math.min(dropdownButtons.length, 3);

        if (!gridContainer) {
            console.error("Element with ID 'grid-full-container' not found.");
            return 0;
        }

        if (state.Payment835) {
            const eobDiv = gridContainer.querySelector('.form-eob-container');
            const eobDivinputFields = Array.from(eobDiv.querySelectorAll('input:not([data-corress-disable="true"])')).filter(el => !isExcluded(el));
            const eobSelectDropdowns = Array.from(eobDiv.querySelectorAll('select:not([data-corress-disable="true"])')).filter(el => !isExcluded(el));
            // const claimFistRow = gridContainer.querySelector('.claim835-mt.UBAISYYT .sub-section');
            // const claimFistRowinputFields = Array.from(claimFistRow.querySelectorAll('input:not([data-corress-disable="true"])')).filter(el => !isExcluded(el));
            // const claimFistRowselectFields = Array.from(claimFistRow.querySelectorAll('select:not([data-corress-disable="true"])')).filter(el => !isExcluded(el));
            let formEobCount = eobDivinputFields.length + eobSelectDropdowns.length + dropDownCount
            formEobCount += jobData?.client_level_customization?.is_overall_page_from_to ? 2 : 0
            let claimCount = 0
            if (formik.values?.grid_customization !== "PLB_ONLY") {
                formik?.values?.claims?.map((item) => {
                    if (qdxTransaction == "CORRESPONDENCE") {
                        if (item.encounter != '' && item.encounter != undefined) {
                            claimCount += 13
                        }
                        else {
                            claimCount += 12
                        }
                    }
                    else {
                        claimCount += 9
                    }
                    if (qdxTransaction == "CORRESPONDENCE") {
                        if (item.encounter != '' && item.encounter != undefined) {
                            claimCount += item?.patient ? 4 : 0
                            claimCount += item?.subscriber ? 4 : 0
                            claimCount += item?.rendering_provider ? 3 : 0
                        }
                        else {
                            claimCount += item?.patient ? 3 : 0

                        }
                    }
                    else {
                        claimCount += item?.patient ? 4 : 0
                        claimCount += item?.subscriber ? 4 : 0
                        claimCount += item?.rendering_provider ? 3 : 0

                        // clsim billed to claim paid
                        claimCount += 12
                    }
                    if (state.Payment835 && qdxTransaction != "PATIENT_PAY") {

                        item?.service_lines?.map((serv) => {
                            if (qdxTransaction == "CORRESPONDENCE") {
                                if (item.encounter != '' && item.encounter != undefined) {
                                    claimCount += 13
                                }
                                else {
                                    claimCount += 5
                                }
                            }
                            else {
                                claimCount += 19
                            }
                            serv?.adjustments?.map((s_adj) => {
                                if (qdxTransaction == "CORRESPONDENCE") {
                                    if (item.encounter != '' && item.encounter != undefined) {
                                        claimCount += 5
                                    }
                                    else {
                                        claimCount += 1
                                    }
                                }
                                else {
                                    claimCount += 5
                                }

                            })
                        })
                        item?.claim_adjustments?.map((c_adj) => {
                            if (qdxTransaction == "CORRESPONDENCE") {
                                if (item.encounter != '' && item.encounter != undefined) {
                                    claimCount += 5
                                }
                                else {
                                    claimCount += 1
                                }
                            }
                            else {
                                claimCount += 5
                            }

                        })
                    } else {
                        // PaymentDetails
                        claimCount += 3
                        // encounter_id
                    }
                })
            }
            return formEobCount + claimCount + additionalInfoCount
        }
        else if (state.Payment835Lite) {

            const eobLiteDiv = gridContainer.querySelector('.form-lite-eob-container');
            const inputsAndSelects = eobLiteDiv.querySelectorAll('input, select');


            let liteCount = 0
            if (formik.values?.grid_customization !== "PLB_ONLY") {
                formik?.values?.claims?.map((item) => {
                    liteCount += 10
                })
            }
            liteCount += jobData?.client_level_customization?.is_overall_page_from_to ? 2 : 0

            return inputsAndSelects.length + liteCount + additionalInfoCount + dropDownCount

        }
        else if (state.Correspondence) {

            let coresCount = 0
            const coressDiv = gridContainer.querySelector('.correspondence_details ');
            const inputsAndSelectsCorres = coressDiv.querySelectorAll('input, select');
            const coressClaimDiv = gridContainer.querySelector('.claim835-mt ');
            const inputsAndSelectsClaim = coressClaimDiv.querySelectorAll('input:not([data-not-take-field-count="true"]), select:not([data-not-take-field-count="true"])');

            formik?.values?.claims?.map((item) => {
                coresCount += inputsAndSelectsClaim.length
            })
            coresCount += jobData?.client_level_customization?.is_overall_page_from_to ? 2 : 0

            return inputsAndSelectsCorres.length + coresCount + additionalInfoCount + dropDownCount

        }
        else {

            const inputFields = Array.from(gridContainer.querySelectorAll('input:not([data-corress-disable="true"])')).filter(el => !isExcluded(el));
            const selectDropdowns = Array.from(gridContainer.querySelectorAll('select:not([data-corress-disable="true"])')).filter(el => !isExcluded(el));


            return inputFields.length + selectDropdowns.length + dropDownCount + additionalInfoCount;
        }


    };

    function showQaErrorModal(data, data1) {
        // Define a helper function to recursively search for true values
        function searchForTrue(obj) {
            // Iterate over each key-value pair in the object
            for (let key in obj) {
                // If the value is an object, recursively search within it
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    if (searchForTrue(obj[key])) {
                        return true; // If true value found within nested object, return true
                    }
                } else if (obj[key] === true) {
                    return true; // If true value found, return true
                }
            }
            return false; // If no true value found, return false
        }

        // Call the helper function with the data object
        return searchForTrue(data);
    }
    const closeQaErrorModal = () => {
        setQaErrorModal(false);
        setIsDrag(true);
        qaErrorformik.setValues({ qa_error_type: [], qa_error_type_comment: "" });
        setQaErrorModalSave(false)
    }
    const qaErrorformik = useFormik({
        initialValues: {
            qa_error_type: [],
            qa_error_type_comment: "",
        },
        validate: (values) => {
            let errors = {};

            // if (values?.qa_error_type?.length == 0) {
            //     errors.qa_error_type = "Reason required";
            // }


            return errors;
        },
        onSubmit: (values, { setSubmitting }) => {
            setQaErrorModalSave(true)
            if (state.Payment835) {
                formEob.handleSubmit()
            }
            else if (state.Payment835Lite) {
                eobLiteFormik.handleSubmit()

            }
            else if (state.PatientPay) {
                formPP.handleSubmit()
            }
            else if (state.Correspondence) {
                formCorres.handleSubmit()

            }
        }
    })
    function checkForFromDateToDate(data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].hasOwnProperty('from_date') && data[i].hasOwnProperty('to_date') &&
                data[i]['from_date'] !== "" && data[i]['to_date'] !== "") {
                return true;
            }
        }
        return false;
    }

    const getPullError837Count = (currected_data, data, old_data) => {
        const data_837 = data?.data_837
        const claims_list = data?.claims
        const subjob_details = data?.subjob_details
        const new_currected_data = structuredClone(currected_data)
        let pull_837_error_count = 0;
        const pulled_claim_ids_arr = []

        // Iterate through the arrays and compare elements
        for (let i = 0; i < claims_list.length; i++) {
            // If both elements are not null and not equal, increment count
            if (claims_list[i]?.claim_id && claims_list[i]?.is_pull_837_error === true) {
                // Large Batch-----------------
                const sub_index = subjob_details.findIndex(detail => detail.sub_job_id === claims_list[i].sub_job_id);
                if (sub_index !== -1) {
                    if (!subjob_details[sub_index].pull_837_error_count) {
                        subjob_details[sub_index].pull_837_error_count = 0
                    }
                    subjob_details[sub_index].pull_837_error_count++;
                }
                // END------------

                pull_837_error_count++;
                pulled_claim_ids_arr.push(i)
                const singleClaim = claims_list[i]?.color_data
                const obj = new_currected_data?.claims[i]
                const hasFromDateToDate = checkForFromDateToDate(singleClaim?.service_lines)

                if ((obj.period_end) && hasFromDateToDate) {
                    obj.period_end = false
                }
                if ((obj.period_start) && hasFromDateToDate) {
                    obj.period_start = false
                }

                Object.keys(singleClaim).forEach(key => {
                    // If the key exists in the object, remove it


                    if (obj.hasOwnProperty(key) && obj[key] === true && singleClaim[key] != "") {
                        obj[key] = false;
                    }
                    else if (key === 'service_lines' && obj[key]?.length > 0) {
                        obj.service_lines?.forEach((s_obj, s_ind) => {
                            const singleServ = singleClaim?.service_lines[s_ind]
                            Object.keys(singleServ).forEach(s_key => {
                                if (s_obj.hasOwnProperty(s_key) && s_obj[s_key] === true && singleServ[s_key] != "") {
                                    s_obj[s_key] = false;
                                }
                            })
                        })
                    }
                });
            }
        }
        pulled_claim_ids_arr.length > 0 && Object.keys(claims_list[0].color_data).forEach(key => {
            if (new_currected_data.hasOwnProperty(key) && new_currected_data[key] === true && data_837[data_837?.length - 1][key] != "") {
                new_currected_data[key] = false;
            }
        })
        return { pull_837_error_count, pull_837_error_data: pull_837_error_count > 0 ? new_currected_data : [] };

    }

    const formEob = useFormik({
        // enableReinitialize: true,
        initialValues: {
            job: "",
            client_id: "",
            rejection_reason: "",

            payment_id: "",
            transaction_type: "PAYMENT835",
            payment_method: "",
            is_first_sub_job: isFirstJob,
            check_number: "",
            check_date: "",
            check_amount: "",
            remaining_balance: "",
            check_page_number: "",
            correspondance: "",
            reference_entity_identifier: "",

            grid_customization: "NORMAL",

            qdx_tranansaction_type: "",
            qdx_status: "",
            qdx_reason: "",
            qdx_classification: "",

            data_837: [],

            payee_id: "",
            payee_pulled_id: "",
            payee_name: "",
            payee_npi: "",
            payee_address: "",
            payee_city: "",
            payee_state: "",
            payee_zip: "",
            payee_page_from: "1",
            payee_page_to: "1",

            payer_id: "",
            payer_pulled_id: "",
            payer_name: "",
            payer_address: "",
            payer_city: "",
            payer_state: "",
            payer_zip: "",
            payer_page_from: "1",
            payer_page_to: "1",

            // DBK
            dbk_check_number: "",
            dbk_check_date: "",
            dbk_check_amount: "",

            dbk_payee_name: "",
            dbk_payee_npi: "",
            dbk_payee_address: "",
            dbk_payee_city: "",
            dbk_payee_state: "",
            dbk_payee_zip: "",

            dbk_payer_name: "",
            dbk_payer_address: "",
            dbk_payer_city: "",
            dbk_payer_state: "",
            dbk_payer_zip: "",


            plb_adjustments: [
                // {
                //     plb_adjustment_id: "",
                //     npi: "",
                //     plb_page: "",
                //     reason: "",
                //     patient_account_number: "",
                //     plb_amt: ""
                // }
            ],
            claims: [
                {
                    patient: true,
                    subscriber: true,
                    rendering_provider: true,
                    corrected_claim: false,

                    order: 1,
                    color_index: "",
                    claim_corrected_index: "",

                    encounter: "",
                    claim_id: "",
                    patient_account_number: "",
                    payer_claim_number: "",
                    period_start: "",
                    period_end: "",
                    status_code: "",
                    mrn: "",
                    statement_number: "",
                    statement_date: "",
                    page_from: "1",
                    page_to: "1",

                    claim_billed: parseFloat(0).toFixed(2),
                    claim_discount: parseFloat(0).toFixed(2),
                    claim_allowed: parseFloat(0).toFixed(2),
                    claim_deduct: parseFloat(0).toFixed(2),
                    claim_coins: parseFloat(0).toFixed(2),
                    claim_copay: parseFloat(0).toFixed(2),
                    fake_sl_patres: parseFloat(0).toFixed(2),
                    adjustment_total: parseFloat(0).toFixed(2),
                    claim_adjustment_total: parseFloat(0).toFixed(2),
                    claim_adjustment_total_pr: parseFloat(0).toFixed(2),
                    claim_cob: parseFloat(0).toFixed(2),
                    claim_patres: parseFloat(0).toFixed(2),
                    claim_paid: parseFloat(0).toFixed(2),
                    claim_balance: parseFloat(0).toFixed(2),

                    sl_billed: parseFloat(0).toFixed(2),
                    sl_discount: parseFloat(0).toFixed(2),
                    sl_allowed: parseFloat(0).toFixed(2),
                    sl_deduct: parseFloat(0).toFixed(2),
                    sl_coins: parseFloat(0).toFixed(2),
                    sl_copay: parseFloat(0).toFixed(2),
                    sl_cob: parseFloat(0).toFixed(2),
                    sl_patres: parseFloat(0).toFixed(2),
                    sl_amount: parseFloat(0).toFixed(2),
                    sl_paid: parseFloat(0).toFixed(2),
                    sl_balance: parseFloat(0).toFixed(2),
                    sl_total: parseFloat(0).toFixed(2),

                    color_data: [],
                    changes_data: [],

                    patient_id: "",
                    patient_first_name: "",
                    patient_middle_name: "",
                    patient_last_name: "",
                    patient_identifier_code: "",

                    subscriber_id: "",
                    subscriber_first_name: "",
                    subscriber_middle_name: "",
                    subscriber_last_name: "",
                    subscriber_identifier_code: "",

                    rendering_provider_id: "",
                    rendering_provider_first_name: "",
                    rendering_provider_last_name: "",
                    rendering_provider_identifier_code: "",

                    // DBK
                    dbk_patient_account_number: "",
                    dbk_payer_claim_number: "",

                    dbk_patient_first_name: "",
                    dbk_patient_middle_name: "",
                    dbk_patient_last_name: "",
                    dbk_patient_identifier_code: "",

                    dbk_subscriber_first_name: "",
                    dbk_subscriber_middle_name: "",
                    dbk_subscriber_last_name: "",
                    dbk_subscriber_identifier_code: "",

                    dbk_rendering_provider_first_name: "",
                    dbk_rendering_provider_last_name: "",
                    dbk_rendering_provider_identifier_code: "",

                    dbk_mrn: "",
                    dbk_statement_number: "",
                    dbk_statement_date: "",

                    claim_adjustments: [
                        {
                            pc: "",
                            group_code: "",
                            reason_code: "",
                            rmk: "",
                            amount: "",
                            claim_adjustment_id: "",
                            description: "",
                        },
                    ],

                    service_lines: [
                        {
                            color_index: "",
                            order: 1,
                            service_line_id: "",
                            from_date: "",
                            to_date: "",
                            code: "",
                            corrected_serviceline: true,
                            procedure_type: "HC",
                            modifier1: "",
                            modifier2: "",
                            modifier3: "",
                            modifier4: "",
                            rev_code: "",
                            units: "",
                            patres: "",
                            billed: "",
                            discount: "",
                            allowed: "",
                            deduct: "",
                            coins: "",
                            copay: "",
                            cob: "",
                            paid: "",
                            color_data: [],

                            balance: "",
                            adjustments: [
                                {
                                    adjustment_id: "",
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                    description: ""
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        validate: (values) => {

            let errors = {};

            // let datas = formEob.values.claims;
            // if (values?.qdx_status == "INCOMPLETE") {

            //     return errors;

            // }
            // else {
            if (values?.qdx_status != "INCOMPLETE") {
                // Payment Method
                if (values?.qdx_status != "INCOMPLETE") {
                    if (isSubJob && isFirstJob || (!isSubJob && !isFirstJob)) {
                        if ((values.payment_method === "") || (!isSubJob && values.payment_method === "")) {
                            errors.payment_method = "Select an option";
                        } else if (!values.isSubJob && !values.payment_method) {
                            errors.payment_method = "Required";
                        }

                        // Check Number
                        if ((!values.check_number) || (!values.isSubJob && !values.check_number)) {
                            errors.check_number = "Required";
                        }

                        // Check Amount and Payment Method
                        const checkAmount = parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2);

                        if (checkAmount === parseFloat(0).toFixed(2) && values?.payment_method !== "NON" && values?.payment_method !== "COR") {
                            errors.check_amount = "Invalid Selection";
                            errors.payment_method = "Invalid Selection";
                        }
                        if (values?.check_amount == "" && values?.payment_method === "NON") {
                            errors.check_amount = "Invalid Selection";
                            errors.payment_method = "Invalid Selection";

                        }
                        if (checkAmount > parseFloat(0).toFixed(2) && !["CHK", "ACH", "VCP", "OTH", "CCI"].includes(values?.payment_method)) {
                            errors.check_amount = "Invalid Selection";
                            errors.payment_method = "Invalid Selection";
                        }

                        // Payment Method and Transaction Type

                        if (values?.payment_method === "COR" && values?.qdx_tranansaction_type !== "CORRESPONDENCE") {
                            errors.payment_method = "Wrong Transaction Type";
                        }

                        if (values?.payment_method !== "COR" && values?.qdx_tranansaction_type === "CORRESPONDENCE") {
                            errors.payment_method = "Wrong Transaction Type";
                        }

                        // Negative Check Amount
                        if (checkAmount < parseFloat(0).toFixed(2)) {
                            errors.check_amount = "Negative value";
                            errors.payment_method = "Negative value";
                        }

                        // Remaining Balance
                        if (!isSubJob && parseFloat(values?.remaining_balance).toFixed(2) !== parseFloat(0).toFixed(2)) {
                            errors.remaining_balance = "Remaining balance must be zero";
                        }

                        // Check Page Number
                        if (!disableCorresField('check_page_number') && (totalPages && values?.check_page_number > totalPages) || (values?.payment_method == 'CHK' && (values?.check_page_number == 0 || values?.check_page_number === ""))) {
                            errors.check_page_number = "Invalid check page number";
                        }
                        if (!moment(values.check_date, "MM/DD/YYYY", true).isValid() || !moment(values.check_date, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                            errors.check_date = "Invalid date format";
                        }
                        if (totalPages > 0) {
                            if (isPayerPage) {
                                if (
                                    values?.payer_page_from == 0 ||
                                    values?.payer_page_from == "" ||
                                    values?.payer_page_from > totalPages ||
                                    values?.payer_page_from > values?.payer_page_to
                                ) {
                                    errors.payer_page_from = "Invalid Page Number";
                                    errors.payer_page_to = "Invalid Page Number";
                                }
                                if (
                                    values?.payer_page_to == 0 ||
                                    values?.payer_page_to == "" ||
                                    values?.payer_page_to > totalPages ||
                                    values?.payer_page_from > values?.payer_page_to
                                ) {
                                    errors.payer_page_from = "Invalid Page Number";
                                    errors.payer_page_to = "Invalid Page Number";
                                }
                            }
                            if (isPayeePage) {
                                if (
                                    values?.payee_page_from == 0 ||
                                    values?.payee_page_from == "" ||
                                    values?.payee_page_from > totalPages ||
                                    values?.payee_page_from > values?.payee_page_to
                                ) {
                                    errors.payee_page_from = "Invalid Page Number";
                                    errors.payee_page_to = "Invalid Page Number";
                                }
                                if (
                                    values?.payee_page_to == 0 ||
                                    values?.payee_page_to == "" ||
                                    values?.payee_page_to > totalPages ||
                                    values?.payee_page_from > values?.payee_page_to
                                ) {
                                    errors.payee_page_from = "Invalid Page Number";
                                    errors.payee_page_to = "Invalid Page Number";
                                }
                            }
                        }


                        if (!isFirstJob) {

                            // Payer State
                            if (values?.payer_state != "" && !/^[a-zA-Z]{2}$/.test(values?.payer_state)) {
                                errors.payer_state = "Enter a valid State Abbreviation";
                            }

                            // Payer Zip
                            if (values?.payer_zip != "" && !/(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{5}\d{4}$)/.test(values?.payer_zip)) {
                                errors.payer_zip = "Enter a valid Zip code";
                            }

                            // Payee NPI
                            if (values?.payee_npi != "" && !/^[0-9]{10}$/.test(values?.payee_npi)) {
                                errors.payee_npi = "Must be 10 digits";
                            }

                            // Payee State
                            if (values?.payee_state != "" && !/^[a-zA-Z]{2}$/.test(values?.payee_state)) {
                                errors.payee_state = "Enter a valid State Abbreviation";
                            }

                            // Payee Zip
                            if (values?.payee_zip != "" && !/(^\d{5}$)|(^\d{5}-\d{4}$)|(^\d{5}\d{4}$)/.test(values?.payee_zip)) {
                                errors.payee_zip = "Enter a valid Zip code";
                            }
                        }
                    }
                }

                // Check Date Format


                // Payer Page and Payee Page

                if (values.plb_adjustments && values.plb_adjustments.length > 0) {
                    if (values?.qdx_status != "INCOMPLETE") {

                        values.plb_adjustments.forEach((plb, index) => {
                            // Reason
                            if (values.plb_adjustments[index].reason == "") {
                                if (errors && errors.plb_adjustments) {
                                    if (errors.plb_adjustments[index]) {
                                        errors.plb_adjustments[index].reason = "select a valid option";
                                    } else {
                                        errors.plb_adjustments[index] = {};
                                        errors.plb_adjustments[index].reason = "select a valid option";
                                    }
                                } else {
                                    errors.plb_adjustments = [];
                                    errors.plb_adjustments[index] = {};
                                    errors.plb_adjustments[index].reason = "select a valid option";
                                }
                            }

                            // PLB Amount
                            if (
                                values.plb_adjustments[index].plb_amt === "" ||
                                values.plb_adjustments[index].plb_amt === null ||
                                parseFloat(values.plb_adjustments[index].plb_amt).toFixed(2) === parseFloat(0).toFixed(2)
                            ) {
                                if (!errors.plb_adjustments) {
                                    errors.plb_adjustments = [];
                                }
                                errors.plb_adjustments[index] = errors.plb_adjustments[index] || {};
                                errors.plb_adjustments[index].plb_amt = "Select a valid option";
                            }

                            // if (!/^[0-9]{10}$/.test(values.plb_adjustments[index].npi)) {
                            //     errors.plb_adjustments = errors.plb_adjustments || [];
                            //     errors.plb_adjustments[index] = errors.plb_adjustments[index] || {};
                            //     errors.plb_adjustments[index].npi = "Must be 10 digits";
                            // }
                            if (!/^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/.test(values.plb_adjustments[index].plb_amt)) {
                                errors.plb_adjustments = errors.plb_adjustments || [];
                                errors.plb_adjustments[index] = errors.plb_adjustments[index] || {};
                                errors.plb_adjustments[index].plb_amt = "Must be a valid amount";
                            }

                            if (values.plb_adjustments[index].npi != "" && !/^[0-9]{10}$/.test(values.plb_adjustments[index].npi)) {
                                if (errors && errors.plb_adjustments) {
                                    if (errors.plb_adjustments[index]) {
                                        errors.plb_adjustments[index].npi = "Must be only digits";
                                    } else {
                                        errors.plb_adjustments[index] = {};
                                        errors.plb_adjustments[index].npi = "Must be only digits";
                                    }
                                } else {
                                    errors.plb_adjustments = [];
                                    errors.plb_adjustments[index] = {};
                                    errors.plb_adjustments[index].npi = "Must be only digits";
                                }
                            }


                        });
                    }
                }

                if (isAdditional) {
                    if (isAdditionalInfoOptions?.status && values?.qdx_status == "") {
                        errors.qdx_status = "Required"
                        errors.qdx_reason = "Required"
                    }

                    if (isAdditionalInfoOptions?.status && isAdditionalInfoOptions?.reason && values?.qdx_status == "INCOMPLETE" && values?.qdx_reason == "") {
                        errors.qdx_reason = "Required"
                    }
                }
            }
            if (values?.grid_customization != 'PLB_ONLY') {
                values?.claims?.forEach((claim, index) => {
                    if (values?.qdx_status != "INCOMPLETE") {
                        if (claim?.patient_account_number == "") {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].patient_account_number = "Required";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].patient_account_number = "Required";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].patient_account_number = "Required";
                            }
                        }
                        if (!disableCorresField1('payer_claim_number', claim) && claim?.payer_claim_number == "") {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].payer_claim_number = "Required";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].payer_claim_number = "Required";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].payer_claim_number = "Required";
                            }
                        }
                        if (claim?.status_code == "") {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].status_code = "select an option";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].status_code = "select an option";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].status_code = "select an option";
                            }
                        }

                        if (!moment(claim?.period_start, "MM/DD/YYYY", true).isValid() || !moment(claim?.period_start, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].period_start = "Invalid date";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].period_start = "Invalid date";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].period_start = "Invalid date";
                            }
                        }

                        if (parseFloat(claim?.claim_balance ? claim?.claim_balance : 0).toFixed(2) != parseFloat(0).toFixed(2)) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].claim_balance = "Calculation Mistake";
                                    errors.claims[index].claim_billed = "Calculation Mistake";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].claim_balance = "Calculation Mistake";
                                    errors.claims[index].claim_billed = "Calculation Mistake";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].claim_balance = "Calculation Mistake";
                                errors.claims[index].claim_billed = "Calculation Mistake";
                            }
                        }

                        if (!moment(claim?.period_end, "MM/DD/YYYY", true).isValid() || !moment(claim?.period_end, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].period_end = "Invalid date";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].period_end = "Invalid date";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].period_end = "Invalid date";
                            }
                        }

                        if (
                            !moment(claim?.period_end, "MM/DD/YYYY").isSameOrAfter(
                                claim?.period_start
                            )
                        ) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].period_end = "Invalid date";
                                    errors.claims[index].period_start = "Invalid date";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].period_end = "Invalid date";
                                    errors.claims[index].period_start = "Invalid date";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].period_end = "Invalid date";
                                errors.claims[index].period_start = "Invalid date";
                            }
                        }

                        if (
                            !disableCorresField1('rendering_provider_identifier_code', claim) &&
                            claim?.rendering_provider == true &&
                            claim.rendering_provider_identifier_code == ""
                        ) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].rendering_provider_identifier_code =
                                        "required";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].rendering_provider_identifier_code =
                                        "required";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].rendering_provider_identifier_code =
                                    "required";
                            }
                        }

                        if (
                            !disableCorresField1('rendering_provider_identifier_code', claim) &&
                            claim?.rendering_provider == true &&
                            !/^[0-9]{9,10}$/.test(claim.rendering_provider_identifier_code)) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].rendering_provider_identifier_code =
                                        "Must be only digits";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].rendering_provider_identifier_code =
                                        "Must be only digits";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].rendering_provider_identifier_code =
                                    "Must be only digits";
                            }
                        }

                        //
                        if (totalPages > 0) {

                            if (
                                claim?.page_from == 0 ||
                                claim?.page_from == "" ||
                                claim?.page_from > totalPages ||
                                claim?.page_from > claim?.page_to
                            ) {
                                if (errors && errors.claims) {
                                    if (errors.claims[index]) {
                                        errors.claims[index].page_from = "1";
                                        errors.claims[index].page_to = "2";
                                    } else {
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "3";
                                        errors.claims[index].page_to = "4";
                                    }
                                } else {
                                    errors.claims = [];
                                    errors.claims[index] = {};
                                    errors.claims[index].page_from = "5";
                                    errors.claims[index].page_to = "6";
                                }
                            }
                            if (
                                claim?.page_from == 0 ||
                                claim?.page_from == "" ||
                                claim?.page_to > totalPages ||
                                claim?.page_from > claim?.page_to
                            ) {
                                if (errors && errors.claims) {
                                    if (errors.claims[index]) {
                                        errors.claims[index].page_from = "11";
                                        errors.claims[index].page_to = "12";
                                    } else {
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "13";
                                        errors.claims[index].page_to = "14";
                                    }
                                } else {
                                    errors.claims = [];
                                    errors.claims[index] = {};
                                    errors.claims[index].page_from = "15";
                                    errors.claims[index].page_to = "16";
                                }
                            }

                            if (jobData?.is_large_batch_job) {
                                if (window.location.pathname == DATA_ENTRY && jobData?.grid_data?.check_page_number && !jobData?.is_first_sub_job && claim?.page_from == jobData?.grid_data?.check_page_number) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_from = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_from = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "5";
                                    }
                                }

                                if (window.location.pathname == DATA_ENTRY && jobData?.grid_data?.check_page_number && !jobData?.is_first_sub_job && claim?.page_to == jobData?.grid_data?.check_page_number) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_to = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_to = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_to = "5";
                                    }
                                }

                                if (window.location.pathname == DATA_ENTRY && claim?.page_from && !jobData?.is_first_sub_job && jobData?.header_range && jobData?.header_range?.length > 0 && jobData?.header_range?.length + 1 >= claim?.page_from) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_from = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_from = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "5";
                                    }
                                }

                                if (window.location.pathname == DATA_ENTRY && claim?.page_to && !jobData?.is_first_sub_job && jobData?.header_range && jobData?.header_range?.length > 0 && jobData?.header_range?.length + 1 >= claim?.page_to) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_to = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_to = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_to = "5";
                                    }
                                }

                                if (window.location.pathname == DATA_ENTRY && claim?.page_from && jobData?.footer_range && !jobData?.is_last_sub_job && jobData?.footer_range?.length > 0 && totalPages - jobData?.footer_range?.length < claim?.page_from) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_from = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_from = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "5";
                                    }
                                }
                                if (window.location.pathname == DATA_ENTRY && claim?.page_to && jobData?.footer_range && !jobData?.is_last_sub_job && jobData?.footer_range?.length > 0 && totalPages - jobData?.footer_range?.length < claim?.page_to) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_to = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_to = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_to = "5";
                                    }
                                }
                            }
                        }

                        if (qdxTransaction != "PATIENT_PAY") {
                            if (values?.qdx_status != "INCOMPLETE") {

                                if (claim?.service_lines?.length > 0) {
                                    let total = parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0) + parseFloat(claim?.claim_coins ? claim?.claim_coins : 0) + parseFloat(claim?.claim_copay ? claim?.claim_copay : 0)
                                    if (parseFloat(claim?.claim_patres ? claim?.claim_patres : 0).toFixed(2) != parseFloat(claim?.fake_sl_patres ? claim?.fake_sl_patres : 0).toFixed(2)) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                errors.claims[index].claim_patres = 'Amount Mismatch';
                                            }
                                            else {
                                                errors.claims[index] = {};
                                                errors.claims[index].claim_patres = 'Amount Mismatch';
                                            }
                                        }
                                        else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].claim_patres = 'Amount Mismatch';
                                        }
                                    }
                                }

                                claim?.claim_adjustments?.map((cladj, clInd) => {
                                    if (cladj?.group_code != '' && cladj?.reason_code == '') {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].claim_adjustments) {
                                                    if (errors.claims[index].claim_adjustments[clInd]) {
                                                        errors.claims[index].claim_adjustments[clInd].reason_code = 'Required'
                                                    }
                                                    else {
                                                        errors.claims[index].claim_adjustments[clInd] = {}
                                                        errors.claims[index].claim_adjustments[clInd].reason_code = 'Required'
                                                    }
                                                }
                                                else {
                                                    errors.claims[index].claim_adjustments = []
                                                    errors.claims[index].claim_adjustments[clInd] = {}
                                                    errors.claims[index].claim_adjustments[clInd].reason_code = 'Required'
                                                }
                                            }
                                            else {
                                                errors.claims[index] = {};
                                                errors.claims[index].claim_adjustments = []
                                                errors.claims[index].claim_adjustments[clInd] = {}
                                                errors.claims[index].claim_adjustments[clInd].reason_code = 'Required'
                                            }
                                        }
                                        else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].claim_adjustments = []
                                            errors.claims[index].claim_adjustments[clInd] = {}
                                            errors.claims[index].claim_adjustments[clInd].reason_code = 'Required'
                                        }
                                    }
                                })
                                const coseGrayField = !disableCorresField1('code', claim)
                                claim?.service_lines?.forEach((service_line, ind) => {
                                    if (values?.qdx_status != "INCOMPLETE" && service_line?.procedure_type === "") {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].procedure_type =
                                                            "Invalid option";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].procedure_type =
                                                            "Invalid option";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].procedure_type =
                                                        "Invalid option";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].procedure_type =
                                                    "Invalid option";
                                            }
                                        }
                                        else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].procedure_type =
                                                "Invalid option";
                                        }
                                    }

                                    if (values?.qdx_status != "INCOMPLETE" && !service_line?.procedure_type) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].procedure_type =
                                                            "Invalid option";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].procedure_type =
                                                            "Invalid option";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].procedure_type =
                                                        "Invalid option";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].procedure_type =
                                                    "Invalid option";
                                            }
                                        }
                                        else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].procedure_type =
                                                "Invalid option";
                                        }
                                    }
                                    if (values?.qdx_status != "INCOMPLETE" && service_line?.code == '' && coseGrayField) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].code =
                                                            "Required";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].code =
                                                            "Required";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].code =
                                                        "Required";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].code =
                                                    "Required";
                                            }
                                        }
                                        else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].code =
                                                "Required";
                                        }
                                    }


                                    if (!moment(service_line?.from_date, "MM/DD/YYYY", true).isValid() || !moment(service_line?.from_date, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                                        // errors.check_date = 'Invalid date format';
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].from_date =
                                                            "Invalid date";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].from_date =
                                                            "Invalid date";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].from_date =
                                                        "Invalid date";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].from_date =
                                                    "Invalid date";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].from_date =
                                                "Invalid date";
                                        }
                                    }
                                    if (!moment(service_line?.to_date, "MM/DD/YYYY", true).isValid() || !moment(service_line?.to_date, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].to_date =
                                                            "Invalid date";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].to_date =
                                                            "Invalid date";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].to_date =
                                                        "Invalid date";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].to_date =
                                                    "Invalid date";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].to_date = "Invalid date";
                                        }
                                    }
                                    if (
                                        !moment(service_line?.to_date, "MM/DD/YYYY").isSameOrAfter(
                                            service_line?.from_date
                                        )
                                    ) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].to_date =
                                                            "Invalid date";
                                                        errors.claims[index].service_lines[ind].from_date =
                                                            "Invalid date";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].to_date =
                                                            "Invalid date";
                                                        errors.claims[index].service_lines[ind].from_date =
                                                            "Invalid date";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].to_date =
                                                        "Invalid date";
                                                    errors.claims[index].service_lines[ind].from_date =
                                                        "Invalid date";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].to_date =
                                                    "Invalid date";
                                                errors.claims[index].service_lines[ind].from_date =
                                                    "Invalid date";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].to_date = "Invalid date";
                                            errors.claims[index].service_lines[ind].from_date =
                                                "Invalid date";
                                        }
                                    }

                                    if (service_line.code != "" && !/^[ A-Za-z0-9_@./#&+-]{5}$/.test(service_line.code)) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].code =
                                                            "Enter a Valid Code";
                                                        errors.claims[index].service_lines[ind].code =
                                                            "Enter a Valid Code";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].code =
                                                            "Enter a Valid Code";
                                                        errors.claims[index].service_lines[ind].code =
                                                            "Enter a Valid Code";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].code =
                                                        "Enter a Valid Code";
                                                    errors.claims[index].service_lines[ind].code =
                                                        "Enter a Valid Code";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].code =
                                                    "Enter a Valid Code";
                                                errors.claims[index].service_lines[ind].code =
                                                    "Enter a Valid Code";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].code = "Enter a Valid Code";
                                            errors.claims[index].service_lines[ind].code =
                                                "Enter a Valid Code";
                                        }
                                    }

                                    if (service_line.modifier1 != "" && !/^[A-Za-z0-9]{2,2}$/.test(service_line.modifier1)) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].modifier1 =
                                                            "Enter a Valid modifier1";
                                                        errors.claims[index].service_lines[ind].modifier1 =
                                                            "Enter a Valid modifier1";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].modifier1 =
                                                            "Enter a Valid modifier1";
                                                        errors.claims[index].service_lines[ind].modifier1 =
                                                            "Enter a Valid modifier1";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].modifier1 =
                                                        "Enter a Valid modifier1";
                                                    errors.claims[index].service_lines[ind].modifier1 =
                                                        "Enter a Valid modifier1";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].modifier1 =
                                                    "Enter a Valid modifier1";
                                                errors.claims[index].service_lines[ind].modifier1 =
                                                    "Enter a Valid modifier1";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].modifier1 = "Enter a Valid modifier1";
                                            errors.claims[index].service_lines[ind].modifier1 =
                                                "Enter a Valid modifier1";
                                        }
                                    }

                                    if (service_line.modifier2 != "" && !/^[A-Za-z0-9]{2,2}$/.test(service_line.modifier2)) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].modifier2 =
                                                            "Enter a Valid modifier2";
                                                        errors.claims[index].service_lines[ind].modifier2 =
                                                            "Enter a Valid modifier2";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].modifier2 =
                                                            "Enter a Valid modifier2";
                                                        errors.claims[index].service_lines[ind].modifier2 =
                                                            "Enter a Valid modifier2";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].modifier2 =
                                                        "Enter a Valid modifier2";
                                                    errors.claims[index].service_lines[ind].modifier2 =
                                                        "Enter a Valid modifier2";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].modifier2 =
                                                    "Enter a Valid modifier2";
                                                errors.claims[index].service_lines[ind].modifier2 =
                                                    "Enter a Valid modifier2";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].modifier2 = "Enter a Valid modifier2";
                                            errors.claims[index].service_lines[ind].modifier2 =
                                                "Enter a Valid modifier2";
                                        }
                                    }

                                    if (service_line.modifier3 != "" && !/^[A-Za-z0-9]{2,2}$/.test(service_line.modifier3)) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].modifier3 =
                                                            "Enter a Valid modifier3";
                                                        errors.claims[index].service_lines[ind].modifier3 =
                                                            "Enter a Valid modifier3";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].modifier3 =
                                                            "Enter a Valid modifier3";
                                                        errors.claims[index].service_lines[ind].modifier3 =
                                                            "Enter a Valid modifier3";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].modifier3 =
                                                        "Enter a Valid modifier3";
                                                    errors.claims[index].service_lines[ind].modifier3 =
                                                        "Enter a Valid modifier3";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].modifier3 =
                                                    "Enter a Valid modifier3";
                                                errors.claims[index].service_lines[ind].modifier3 =
                                                    "Enter a Valid modifier3";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].modifier3 = "Enter a Valid modifier3";
                                            errors.claims[index].service_lines[ind].modifier3 =
                                                "Enter a Valid modifier1";
                                        }
                                    }

                                    if (service_line.modifier4 != "" && !/^[A-Za-z0-9]{2,2}$/.test(service_line.modifier4)) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].modifier4 =
                                                            "Enter a Valid modifier4";
                                                        errors.claims[index].service_lines[ind].modifier4 =
                                                            "Enter a Valid modifier4";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].modifier4 =
                                                            "Enter a Valid modifier4";
                                                        errors.claims[index].service_lines[ind].modifier4 =
                                                            "Enter a Valid modifier4";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].modifier4 =
                                                        "Enter a Valid modifier4";
                                                    errors.claims[index].service_lines[ind].modifier4 =
                                                        "Enter a Valid modifier4";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].modifier4 =
                                                    "Enter a Valid modifier4";
                                                errors.claims[index].service_lines[ind].modifier4 =
                                                    "Enter a Valid modifier4";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].modifier4 = "Enter a Valid modifier4";
                                            errors.claims[index].service_lines[ind].modifier4 =
                                                "Enter a Valid modifier4";
                                        }
                                    }

                                    if (service_line.rev_code != "" && !/^[ A-Za-z0-9_@./#&+-]{3,4}$/.test(service_line.rev_code)) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].rev_code =
                                                            "Enter a Valid rev_code";
                                                        errors.claims[index].service_lines[ind].rev_code =
                                                            "Enter a Valid rev_code";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].rev_code =
                                                            "Enter a Valid rev_code";
                                                        errors.claims[index].service_lines[ind].rev_code =
                                                            "Enter a Valid rev_code";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].rev_code =
                                                        "Enter a Valid rev_code";
                                                    errors.claims[index].service_lines[ind].rev_code =
                                                        "Enter a Valid rev_code";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].rev_code =
                                                    "Enter a Valid rev_code";
                                                errors.claims[index].service_lines[ind].rev_code =
                                                    "Enter a Valid rev_code";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].rev_code = "Enter a Valid rev_code";
                                            errors.claims[index].service_lines[ind].rev_code =
                                                "Enter a Valid rev_code";
                                        }
                                    }


                                    if (
                                        parseFloat(service_line?.balance).toFixed(2) !=
                                        parseFloat(0).toFixed(2)
                                    ) {
                                        if (errors && errors.claims) {
                                            if (errors.claims[index]) {
                                                if (errors.claims[index].service_lines) {
                                                    if (errors.claims[index].service_lines[ind]) {
                                                        errors.claims[index].service_lines[ind].billed =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].discount =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].allowed =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].deduct =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].coins =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].copay =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].cob =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].paid =
                                                            "Balance mismatch";
                                                    } else {
                                                        errors.claims[index].service_lines[ind] = {};
                                                        errors.claims[index].service_lines[ind].billed =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].discount =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].allowed =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].deduct =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].coins =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].copay =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].cob =
                                                            "Balance mismatch";
                                                        errors.claims[index].service_lines[ind].paid =
                                                            "Balance mismatch";
                                                    }
                                                } else {
                                                    errors.claims[index].service_lines = [];
                                                    errors.claims[index].service_lines[ind] = {};
                                                    errors.claims[index].service_lines[ind].billed =
                                                        "Balance mismatch";
                                                    errors.claims[index].service_lines[ind].discount =
                                                        "Balance mismatch";
                                                    errors.claims[index].service_lines[ind].allowed =
                                                        "Balance mismatch";
                                                    errors.claims[index].service_lines[ind].deduct =
                                                        "Balance mismatch";
                                                    errors.claims[index].service_lines[ind].coins =
                                                        "Balance mismatch";
                                                    errors.claims[index].service_lines[ind].copay =
                                                        "Balance mismatch";
                                                    errors.claims[index].service_lines[ind].cob =
                                                        "Balance mismatch";
                                                    errors.claims[index].service_lines[ind].paid =
                                                        "Balance mismatch";
                                                }
                                            } else {
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].billed =
                                                    "Balance mismatch";
                                                errors.claims[index].service_lines[ind].discount =
                                                    "Balance mismatch";
                                                errors.claims[index].service_lines[ind].allowed =
                                                    "Balance mismatch";
                                                errors.claims[index].service_lines[ind].deduct =
                                                    "Balance mismatch";
                                                errors.claims[index].service_lines[ind].coins =
                                                    "Balance mismatch";
                                                errors.claims[index].service_lines[ind].copay =
                                                    "Balance mismatch";
                                                errors.claims[index].service_lines[ind].cob =
                                                    "Balance mismatch";
                                                errors.claims[index].service_lines[ind].paid =
                                                    "Balance mismatch";
                                            }
                                        } else {
                                            errors.claims = [];
                                            errors.claims[index] = {};
                                            errors.claims[index].service_lines = [];
                                            errors.claims[index].service_lines[ind] = {};
                                            errors.claims[index].service_lines[ind].billed =
                                                "Balance mismatch";
                                            errors.claims[index].service_lines[ind].discount =
                                                "Balance mismatch";
                                            errors.claims[index].service_lines[ind].allowed =
                                                "Balance mismatch";
                                            errors.claims[index].service_lines[ind].deduct =
                                                "Balance mismatch";
                                            errors.claims[index].service_lines[ind].coins =
                                                "Balance mismatch";
                                            errors.claims[index].service_lines[ind].copay =
                                                "Balance mismatch";
                                            errors.claims[index].service_lines[ind].cob = "Balance mismatch";
                                            errors.claims[index].service_lines[ind].paid = "Balance mismatch";
                                        }
                                    }
                                    service_line?.adjustments?.map((adjustment, adjInd) => {
                                        if (!isIncomplete && adjustment?.group_code != '' && adjustment?.reason_code == '') {
                                            if (errors && errors.claims) {
                                                if (errors.claims[index]) {
                                                    if (errors.claims[index].service_lines) {
                                                        if (errors.claims[index].service_lines[ind]) {
                                                            if (errors.claims[index].service_lines[ind].adjustments) {
                                                                if (errors.claims[index].service_lines[ind].adjustments[adjInd]) {
                                                                    errors.claims[index].service_lines[ind].adjustments[adjInd].reason_code = 'Required'
                                                                }
                                                                else {
                                                                    errors.claims[index].service_lines[ind].adjustments[adjInd] = {}
                                                                    errors.claims[index].service_lines[ind].adjustments[adjInd].reason_code = 'Required'
                                                                }
                                                            }
                                                            else {
                                                                errors.claims[index].service_lines[ind].adjustments = []
                                                                errors.claims[index].service_lines[ind].adjustments[adjInd] = {}
                                                                errors.claims[index].service_lines[ind].adjustments[adjInd].reason_code = 'Required'
                                                            }

                                                        } else {
                                                            errors.claims[index].service_lines[ind] = {}
                                                            errors.claims[index].service_lines[ind].adjustments = []
                                                            errors.claims[index].service_lines[ind].adjustments[adjInd] = {}
                                                            errors.claims[index].service_lines[ind].adjustments[adjInd].reason_code = 'Required'
                                                        }
                                                    } else {
                                                        errors.claims[index].service_lines = []
                                                        errors.claims[index].service_lines[ind] = {}
                                                        errors.claims[index].service_lines[ind].adjustments = []
                                                        errors.claims[index].service_lines[ind].adjustments[adjInd] = {}
                                                        errors.claims[index].service_lines[ind].adjustments[adjInd].reason_code = 'Required'
                                                    }
                                                } else {
                                                    errors.claims[index] = {};
                                                    errors.claims[index].service_lines = []
                                                    errors.claims[index].service_lines[ind] = {}
                                                    errors.claims[index].service_lines[ind].adjustments = []
                                                    errors.claims[index].service_lines[ind].adjustments[adjInd] = {}
                                                    errors.claims[index].service_lines[ind].adjustments[adjInd].reason_code = 'Required'
                                                }
                                            }
                                            else {
                                                errors.claims = [];
                                                errors.claims[index] = {};
                                                errors.claims[index].service_lines = [];
                                                errors.claims[index].service_lines[ind] = {};
                                                errors.claims[index].service_lines[ind].adjustments = []
                                                errors.claims[index].service_lines[ind].adjustments[adjInd] = {}
                                                errors.claims[index].service_lines[ind].adjustments[adjInd].reason_code = 'Required'
                                            }
                                        }
                                    })

                                });

                            }

                        } else if (qdxTransaction == "PATIENT_PAY") {
                            if ((claim?.statement_date && !moment(claim?.statement_date, "MM/DD/YYYY", true).isValid()) || (claim?.statement_date && !moment(claim?.statement_date, "MM/DD/YYYY", true).isAfter(minValidDate))) {
                                if (errors && errors.claims) {
                                    if (errors.claims[index]) {
                                        errors.claims[index].statement_date = "Invalid date";
                                    } else {
                                        errors.claims[index] = {};
                                        errors.claims[index].statement_date = "Invalid date";
                                    }
                                } else {
                                    errors.claims = [];
                                    errors.claims[index] = {};
                                    errors.claims[index].statement_date = "Invalid date";
                                }
                            }
                        }
                    }
                    // DBK VALIDATION START HERE
                    if (
                        String(claim?.dbk_patient_account_number).toUpperCase() != String(claim?.patient_account_number).toUpperCase() &&
                        isDbkPermission?.payment?.payment_patient_acc_number
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_account_number = "Dont Match";
                                errors.claims[index].dbk_patient_account_number = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_account_number = "Dont Match";
                                errors.claims[index].dbk_patient_account_number = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_account_number = "Dont Match";
                            errors.claims[index].dbk_patient_account_number = "Dont Match";
                        }

                        // errors.claims[index].patient_account_number = 'Dont Match';
                        // errors.claims[index].dbk_patient_account_number = 'Dont Match';
                    }
                    if (
                        String(claim?.dbk_payer_claim_number).toUpperCase() != String(claim?.payer_claim_number).toUpperCase() &&
                        isDbkPermission?.payment?.payment_payer_claim_number
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].payer_claim_number = "Dont Match";
                                errors.claims[index].dbk_payer_claim_number = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].payer_claim_number = "Dont Match";
                                errors.claims[index].dbk_payer_claim_number = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].payer_claim_number = "Dont Match";
                            errors.claims[index].dbk_payer_claim_number = "Dont Match";
                        }
                    }

                    //  Patient--------------

                    if (
                        String(claim?.dbk_patient_first_name).toUpperCase() != String(claim?.patient_first_name).toUpperCase() &&
                        isDbkPermission?.payment?.payment_patient_first_name
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_first_name = "Dont Match";
                                errors.claims[index].dbk_patient_first_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_first_name = "Dont Match";
                                errors.claims[index].dbk_patient_first_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_first_name = "Dont Match";
                            errors.claims[index].dbk_patient_first_name = "Dont Match";
                        }

                        // errors.claims[index].patient_first_name = 'Dont Match';
                        // errors.claims[index].dbk_patient_first_name = 'Dont Match';
                    }
                    if (
                        String(claim?.dbk_patient_middle_name).toUpperCase() != String(claim?.patient_middle_name).toUpperCase() &&
                        isDbkPermission?.payment?.payment_patient_middle_name
                    ) {


                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_middle_name = "Dont Match";
                                errors.claims[index].dbk_patient_middle_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_middle_name = "Dont Match";
                                errors.claims[index].dbk_patient_middle_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_middle_name = "Dont Match";
                            errors.claims[index].dbk_patient_middle_name = "Dont Match";
                        }


                        // errors.claims[index].patient_middle_name = "Dont Match";
                        // errors.claims[index].dbk_patient_middle_name = "Dont Match";
                    }

                    if (
                        String(claim?.dbk_patient_last_name).toUpperCase() != String(claim?.patient_last_name).toUpperCase() &&
                        isDbkPermission?.payment?.payment_patient_last_name
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_last_name = "Dont Match";
                                errors.claims[index].dbk_patient_last_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_last_name = "Dont Match";
                                errors.claims[index].dbk_patient_last_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_last_name = "Dont Match";
                            errors.claims[index].dbk_patient_last_name = "Dont Match";
                        }

                        // errors.claims[index].patient_last_name = "Dont Match";
                        // errors.claims[index].dbk_patient_last_name = "Dont Match";
                    }

                    if (
                        String(claim?.dbk_patient_identifier_code).toUpperCase() != String(claim?.patient_identifier_code).toUpperCase() &&
                        isDbkPermission?.payment?.payment_patient_identifier_code
                    ) {

                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_identifier_code = "Dont Match";
                                errors.claims[index].dbk_patient_identifier_code = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_identifier_code = "Dont Match";
                                errors.claims[index].dbk_patient_identifier_code = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_identifier_code = "Dont Match";
                            errors.claims[index].dbk_patient_identifier_code = "Dont Match";
                        }


                        // errors.claims[index].patient_identifier_code = "Dont Match";
                        // errors.claims[index].dbk_patient_identifier_code = "Dont Match";
                    }
                    //  SubScriber--------------
                    if (
                        String(claim?.dbk_subscriber_first_name).toUpperCase() != String(claim?.subscriber_first_name).toUpperCase() &&
                        isDbkPermission?.payment?.payment_subscriber_first_name
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].subscriber_first_name = "Dont Match";
                                errors.claims[index].dbk_subscriber_first_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].subscriber_first_name = "Dont Match";
                                errors.claims[index].dbk_subscriber_first_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].subscriber_first_name = "Dont Match";
                            errors.claims[index].dbk_subscriber_first_name = "Dont Match";
                        }
                    }
                    if (
                        String(claim?.dbk_subscriber_middle_name).toUpperCase() != String(claim?.subscriber_middle_name).toUpperCase() &&
                        isDbkPermission?.payment?.payment_subscriber_middle_name
                    ) {

                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].subscriber_middle_name = "Dont Match";
                                errors.claims[index].dbk_subscriber_middle_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].subscriber_middle_name = "Dont Match";
                                errors.claims[index].dbk_subscriber_middle_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].subscriber_middle_name = "Dont Match";
                            errors.claims[index].dbk_subscriber_middle_name = "Dont Match";
                        }



                        // errors.claims[index].subscriber_middle_name = "Dont Match";
                        // errors.claims[index].dbk_subscriber_middle_name = "Dont Match";
                    }

                    if (
                        String(claim?.dbk_subscriber_last_name).toUpperCase() != String(claim?.subscriber_last_name).toUpperCase() &&
                        isDbkPermission?.payment?.payment_subscriber_last_name
                    ) {

                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].subscriber_last_name = "Dont Match";
                                errors.claims[index].dbk_subscriber_last_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].subscriber_last_name = "Dont Match";
                                errors.claims[index].dbk_subscriber_last_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].subscriber_last_name = "Dont Match";
                            errors.claims[index].dbk_subscriber_last_name = "Dont Match";
                        }



                        // errors.claims[index].subscriber_last_name = "Dont Match";
                        // errors.claims[index].dbk_subscriber_last_name = "Dont Match";
                    }

                    if (
                        String(claim?.dbk_subscriber_identifier_code).toUpperCase() !=
                        String(claim?.subscriber_identifier_code).toUpperCase() &&
                        isDbkPermission?.payment?.payment_subscriber_identifier_code
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].subscriber_identifier_code = "Dont Match";
                                errors.claims[index].dbk_subscriber_identifier_code =
                                    "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].subscriber_identifier_code = "Dont Match";
                                errors.claims[index].dbk_subscriber_identifier_code =
                                    "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].subscriber_identifier_code = "Dont Match";
                            errors.claims[index].dbk_subscriber_identifier_code = "Dont Match";
                        }
                    }
                    //  Rendering Provider--------------
                    if (
                        String(claim?.dbk_rendering_provider_first_name).toUpperCase() !=
                        String(claim?.rendering_provider_first_name).toUpperCase() &&
                        isDbkPermission?.payment?.payment_rendering_provider_first_name
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].rendering_provider_first_name = "Dont Match";
                                errors.claims[index].dbk_rendering_provider_first_name =
                                    "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].rendering_provider_first_name = "Dont Match";
                                errors.claims[index].dbk_rendering_provider_first_name =
                                    "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].rendering_provider_first_name = "Dont Match";
                            errors.claims[index].dbk_rendering_provider_first_name =
                                "Dont Match";
                        }
                    }
                    if (
                        String(claim?.dbk_rendering_provider_last_name).toUpperCase() !=
                        String(claim?.rendering_provider_last_name).toUpperCase() &&
                        isDbkPermission?.payment?.payment_rendering_provider_last_name
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].rendering_provider_last_name = "Dont Match";
                                errors.claims[index].dbk_rendering_provider_last_name =
                                    "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].rendering_provider_last_name = "Dont Match";
                                errors.claims[index].dbk_rendering_provider_last_name =
                                    "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].rendering_provider_last_name = "Dont Match";
                            errors.claims[index].dbk_rendering_provider_last_name =
                                "Dont Match";
                        }
                    }
                    if (
                        String(claim?.dbk_rendering_provider_identifier_code).toUpperCase() !=
                        String(claim?.rendering_provider_identifier_code).toUpperCase() &&
                        isDbkPermission?.payment?.payment_rendering_provider_identifier
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].rendering_provider_identifier_code =
                                    "Dont Match";
                                errors.claims[index].dbk_rendering_provider_identifier_code =
                                    "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].rendering_provider_identifier_code =
                                    "Dont Match";
                                errors.claims[index].dbk_rendering_provider_identifier_code =
                                    "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].rendering_provider_identifier_code =
                                "Dont Match";
                            errors.claims[index].dbk_rendering_provider_identifier_code =
                                "Dont Match";
                        }
                    }
                    // MRN

                    if (
                        String(claim?.dbk_mrn).toUpperCase() != String(claim?.mrn).toUpperCase() &&
                        isDbkPermission?.payment?.payment_mrn_number
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].mrn = "Dont Match";
                                errors.claims[index].dbk_mrn = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].mrn = "Dont Match";
                                errors.claims[index].dbk_mrn = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].mrn = "Dont Match";
                            errors.claims[index].dbk_mrn = "Dont Match";
                        }
                    }

                    // Statement Number
                    if (
                        String(claim?.dbk_statement_number).toUpperCase() != String(claim?.statement_number).toUpperCase() &&
                        isDbkPermission?.payment?.payment_statement_number
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].statement_number = "Dont Match";
                                errors.claims[index].dbk_statement_number = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].statement_number = "Dont Match";
                                errors.claims[index].dbk_statement_number = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].statement_number = "Dont Match";
                            errors.claims[index].dbk_statement_number = "Dont Match";
                        }
                    }

                    // Statement Dtate

                    if (
                        qdxTransaction === 'PATIENT_PAY' && claim?.dbk_statement_date?.replace("__/__/____", "") !==
                        claim.statement_date?.replace("__/__/____", "")
                        &&
                        isDbkPermission?.payment?.payment_statement_date
                    ) {

                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].statement_date = "Dont Match";
                                errors.claims[index].dbk_statement_date = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].statement_date = "Dont Match";
                                errors.claims[index].dbk_statement_date = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].statement_date = "Dont Match";
                            errors.claims[index].dbk_statement_date = "Dont Match";
                        }
                    }
                });
            }
            // DBK VALIDATION START
            if (
                // values.check_number != jobData?.grid_data?.check_number &&
                values.check_number != values.dbk_check_number &&
                isDbkPermission?.payment?.payment_check_number
            ) {
                errors.check_number = "Dont Match";
            }

            if (
                // values.check_date != jobData?.grid_data?.check_date &&
                values.check_date.replace("__/__/____", "") !=
                values.dbk_check_date.replace("__/__/____", "") &&
                isDbkPermission?.payment?.payment_check_date
            ) {
                errors.check_date = "Dont Match";
            }

            if (
                // values.check_amount != jobData?.grid_data?.check_amount &&
                values.check_amount != values.dbk_check_amount &&
                isDbkPermission?.payment?.payment_check_amount
            ) {
                errors.check_amount = "Dont Match";
            }

            if (
                String(values?.payer_name).toUpperCase() != String(values?.dbk_payer_name.toUpperCase()) &&
                isDbkPermission?.payment?.payment_payer_name
            ) {
                errors.payer_name = "Dont Match";
            }

            if (
                String(values.payer_address).toUpperCase() != String(values.dbk_payer_address).toUpperCase() &&
                isDbkPermission?.payment?.payment_payer_address
            ) {
                errors.payer_address = "Dont Match";
            }

            if (
                String(values.payer_city).toUpperCase() != String(values.dbk_payer_city).toUpperCase() &&
                isDbkPermission?.payment?.payment_payer_city
            ) {
                errors.payer_city = "Dont Match";
            }

            if (
                String(values.payer_state).toUpperCase() != String(values.dbk_payer_state).toUpperCase() &&
                isDbkPermission?.payment?.payment_payer_state
            ) {
                errors.payer_state = "Dont Match";
            }

            if (
                String(values.payer_zip).toUpperCase() != String(values.dbk_payer_zip).toUpperCase() &&
                isDbkPermission?.payment?.payment_payer_zip
            ) {
                errors.payer_zip = "Dont Match";
            }

            if (
                String(values.payee_name).toUpperCase() != String(values.dbk_payee_name).toUpperCase() &&
                isDbkPermission?.payment?.payment_payee_name
            ) {
                errors.payee_name = "Dont Match";
            }

            if (
                String(values.payee_npi).toUpperCase() != String(values.dbk_payee_npi).toUpperCase() &&
                isDbkPermission?.payment?.payment_payee_npi
            ) {
                errors.payee_npi = "Dont Match";
            }

            if (
                String(values.payee_address).toUpperCase() != String(values.dbk_payee_address).toUpperCase() &&
                isDbkPermission?.payment?.payment_payee_address
            ) {
                errors.payee_address = "Dont Match";
            }

            if (
                String(values.payee_city).toUpperCase() != String(values.dbk_payee_city).toUpperCase() &&
                isDbkPermission?.payment?.payment_payee_city
            ) {
                errors.payee_city = "Dont Match";
            }

            if (
                String(values.payee_state).toUpperCase() != String(values.dbk_payee_state).toUpperCase() &&
                isDbkPermission?.payment?.payment_payee_state
            ) {
                errors.payee_state = "Dont Match";
            }

            if (
                String(values.payee_zip).toUpperCase() != String(values.dbk_payee_zip).toUpperCase() &&
                isDbkPermission?.payment?.payment_payee_zip
            ) {
                errors.payee_zip = "Dont Match";
            }
            return errors;


        },

        onSubmit: (values, { setSubmitting }) => {

            const old_data = jobData?.grid_data;
            const qa_corrected_data = {};
            localStorage.removeItem('837_pulled')
            if (old_data?.payment_id && window.location.pathname == QA_REVIEW) {

                const claimSubjobPageRange = handleClaimSubJobPageRange(values.claims, jobData);

                values.claims = claimSubjobPageRange;

                const { deleted_claims, deleted_service_lines, deleted_plb, deleted_claim_adjustments, deleted_service_adjustments } = getOldGridDataCount(old_data, values);


                const propertiesToCheck = [
                    'payment_method',
                    'check_number',
                    'check_date',
                    'check_amount',
                    'check_page_number',
                    'payee_name',
                    'payee_npi',
                    'payee_address',
                    'payee_city',
                    'payee_state',
                    'payee_zip',
                    'payer_name',
                    'payer_address',
                    'payer_city',
                    'payer_state',
                    'payer_zip',
                    'payee_page_from',
                    'payee_page_to',
                    'payer_page_from',
                    'payer_page_to',



                ];


                qa_corrected_data.overall_page_from = !jobData?.is_large_batch_job ? old_data?.overall_page_from?.toString().toUpperCase() != overallPage?.from?.toString().toUpperCase() : false;
                qa_corrected_data.overall_page_to = !jobData?.is_large_batch_job ? old_data?.overall_page_to?.toString().toUpperCase() != overallPage?.to?.toString().toUpperCase() : false;

                propertiesToCheck.forEach((property) => {
                    if (old_data?.transaction_type != "PAYMENT835") {
                        qa_corrected_data[property] = true;

                    }
                    else if (property == "check_amount") {
                        qa_corrected_data[property] = parseFloat(old_data?.[property]).toFixed(2) != parseFloat(values?.[property]).toFixed(2);
                    }
                    else if (property == "check_page_number" && !jobData?.is_large_batch_job) {
                        qa_corrected_data[property] = old_data?.[property] != values?.[property];
                    }
                    else if (property != "check_page_number") {
                        qa_corrected_data[property] = old_data?.[property]?.toString().toUpperCase() != values?.[property]?.toString().toUpperCase();
                    }

                });

                qa_corrected_data.is_transaction_type_changed = old_data?.transaction_type != "PAYMENT835" ? true : old_data?.grid_customization != values?.grid_customization ? true : false

                if (isAdditional) {
                    const AdditionalPropertiesToCheck = [
                        'qdx_status',
                        // 'qdx_reason',
                        'qdx_classification',


                    ];
                    AdditionalPropertiesToCheck.forEach((property) => {
                        qa_corrected_data[property] = old_data?.[property] != values?.[property] ? true : false;
                    });
                    const array1 = old_data?.qdx_reasons ? old_data?.qdx_reasons : [];
                    const array2 = aditionalInfoFormik.values?.reason ? aditionalInfoFormik.values?.reason : [];
                    const array1sort = array1.sort()
                    const array2sort = array2.sort()
                    if (JSON.stringify(array1sort) != JSON.stringify(array2sort)) {
                        qa_corrected_data.qdx_reasons = true;
                    }
                    else {
                        qa_corrected_data.qdx_reasons = false;
                    }
                    qa_corrected_data.qdx_reason = false;
                    qa_corrected_data.is_orphaned_check = old_data?.is_orphaned_check != aditionalInfoFormik?.values?.is_orphaned_check ? true : false;
                    qa_corrected_data.is_missing_check_image = old_data?.is_missing_check_image != aditionalInfoFormik?.values?.is_missing_check_image ? true : false;
                    qa_corrected_data.is_forced_balancing = old_data?.is_forced_balancing != aditionalInfoFormik?.values?.is_forced_balancing ? true : false;
                    qa_corrected_data.is_payer_black_listing = old_data?.is_payer_black_listing != aditionalInfoFormik?.values?.is_payer_black_listing ? true : false;
                    qa_corrected_data.is_payer_excluding_indexing = old_data?.is_payer_excluding_indexing != aditionalInfoFormik?.values?.is_payer_excluding_indexing ? true : false;

                }
                qa_corrected_data.plb_adjustments = [];
                if (!jobData?.is_large_batch_job) {

                    if (values?.plb_adjustments && values?.plb_adjustments?.length > 0) {
                        values?.plb_adjustments?.map((plb_adjustment, index) => {
                            if (old_data?.transaction_type != "PAYMENT835") {
                                qa_corrected_data?.plb_adjustments.push({
                                    npi: true,
                                    plb_page: true,
                                    reason: true,
                                    patient_account_number: true,
                                    plb_amt: true,
                                })

                            }
                            else if (plb_adjustment?.corrected_plb) {
                                qa_corrected_data?.plb_adjustments.push({
                                    npi: true,
                                    plb_page: true,
                                    reason: true,
                                    patient_account_number: true,
                                    plb_amt: true,
                                })
                            } else {

                                qa_corrected_data?.plb_adjustments.push({
                                    npi: old_data?.plb_adjustments[plb_adjustment?.plb_index ? plb_adjustment?.plb_index : index]?.npi != plb_adjustment?.npi ? true : false,
                                    plb_page: old_data?.plb_adjustments[plb_adjustment?.plb_index ? plb_adjustment?.plb_index : index]?.plb_page != plb_adjustment?.plb_page ? true : false,
                                    reason: old_data?.plb_adjustments[plb_adjustment?.plb_index ? plb_adjustment?.plb_index : index]?.reason != plb_adjustment?.reason ? true : false,
                                    patient_account_number: old_data?.plb_adjustments[plb_adjustment?.plb_index ? plb_adjustment?.plb_index : index]?.patient_account_number != plb_adjustment?.patient_account_number ? true : false,
                                    plb_amt: old_data?.plb_adjustments[plb_adjustment?.plb_index ? plb_adjustment?.plb_index : index]?.plb_amt != plb_adjustment?.plb_amt ? true : false,

                                })
                            }
                        })
                    }
                }
                if (values?.grid_customization != "PLB_ONLY" && values?.claims && values?.claims?.length > 0) {

                    qa_corrected_data.claims = values?.claims?.map((claim, index) => {
                        const isPulled = claim.encounter != '' && claim.encounter != undefined
                        const isGrayColored = values?.qdx_tranansaction_type === "CORRESPONDENCE" && !isPulled

                        let claimData = {};
                        if (old_data?.transaction_type != "PAYMENT835") {
                            claimData = {
                                patient_account_number: true,
                                payer_claim_number: true,
                                period_start: true,
                                period_end: true,
                                status_code: true,
                                mrn: true,
                                statement_number: true,
                                statement_date: values?.qdx_tranansaction_type === "PATIENT_PAY" ? true : false,
                                page_from: true,
                                page_to: true,
                                sub_job_id: claim.sub_job_id ? claim.sub_job_id : "",
                                claim_billed: true,
                                claim_discount: true,
                                claim_allowed: true,
                                claim_deduct: true,
                                claim_coins: true,
                                claim_copay: true,
                                claim_cob: true,
                                claim_patres: true,
                                claim_paid: true,
                                patient_first_name: true,
                                patient_middle_name: true,
                                patient_last_name: true,
                                patient_identifier_code: true,
                                subscriber_first_name: true,
                                subscriber_middle_name: true,
                                subscriber_last_name: true,
                                subscriber_identifier_code: true,
                                rendering_provider_first_name: true,
                                rendering_provider_last_name: true,
                                rendering_provider_identifier_code: true,
                                claim_adjustments: [],
                                service_lines: [],
                            };

                            if (values?.qdx_tranansaction_type !== "PATIENT_PAY" && claim?.claim_adjustments && claim?.claim_adjustments?.length > 0) {
                                const claimAdjustments = claim.claim_adjustments.map((adjustment, adjIndex) => ({
                                    pc: true,
                                    group_code: true,
                                    reason_code: true,
                                    rmk: true,
                                    amount: true,
                                }));

                                claimData.claim_adjustments = claimAdjustments;
                            }


                            if (values?.qdx_tranansaction_type !== "PATIENT_PAY" && claim?.service_lines && claim?.service_lines?.length > 0) {
                                claimData.service_lines = claim?.service_lines?.map((serviceLine, slIndex) => {
                                    const serviceLineData = {
                                        from_date: true,
                                        to_date: true,

                                        code: true,
                                        procedure_type: true,
                                        modifier1: true,
                                        modifier2: true,
                                        modifier3: true,
                                        modifier4: true,
                                        rev_code: true,
                                        units: true,
                                        billed: true,
                                        discount: true,
                                        allowed: true,
                                        deduct: true,
                                        coins: true,
                                        copay: true,
                                        cob: true,
                                        paid: true,
                                        balance: true,
                                        adjustments: []
                                    };

                                    if (serviceLine?.adjustments && serviceLine?.adjustments?.length > 0) {
                                        serviceLineData.adjustments = serviceLine?.adjustments?.map((adjustment, adjIndex) => ({
                                            pc: true,
                                            group_code: true,
                                            reason_code: true,
                                            rmk: true,
                                            amount: true,
                                        }));
                                    }

                                    return serviceLineData;
                                });
                            }
                            return claimData;

                        }

                        else if (claim.corrected_claim) {

                            claimData = {
                                patient_account_number: true,
                                payer_claim_number: isGrayColored ? false : true,
                                period_start: true,
                                period_end: true,
                                status_code: true,
                                mrn: isGrayColored ? false : true,
                                statement_number: isGrayColored ? false : true,
                                statement_date: values?.qdx_tranansaction_type === "PATIENT_PAY" ? true : false,
                                page_from: true,
                                page_to: true,
                                sub_job_id: claim.sub_job_id ? claim.sub_job_id : "",

                                claim_billed: true,
                                claim_discount: isGrayColored ? false : true,
                                claim_allowed: isGrayColored ? false : true,
                                claim_deduct: isGrayColored ? false : true,
                                claim_coins: isGrayColored ? false : true,
                                claim_copay: isGrayColored ? false : true,
                                claim_cob: isGrayColored ? false : true,
                                claim_patres: true,
                                claim_paid: true,
                                patient_first_name: true,
                                patient_middle_name: isGrayColored ? false : true,
                                patient_last_name: true,
                                patient_identifier_code: true,

                                subscriber_first_name: isGrayColored ? false : true,
                                subscriber_middle_name: isGrayColored ? false : true,
                                subscriber_last_name: isGrayColored ? false : true,
                                subscriber_identifier_code: isGrayColored ? false : true,

                                rendering_provider_first_name: isGrayColored ? false : true,
                                rendering_provider_last_name: isGrayColored ? false : true,
                                rendering_provider_identifier_code: isGrayColored ? false : true,

                                claim_adjustments: [],

                                service_lines: [],
                            };

                            if (values?.qdx_tranansaction_type !== "PATIENT_PAY" && claim?.claim_adjustments && claim?.claim_adjustments?.length > 0) {
                                const claimAdjustments = claim.claim_adjustments.map((adjustment, adjIndex) => ({
                                    pc: isGrayColored ? false : true,
                                    group_code: isGrayColored ? false : true,
                                    reason_code: isGrayColored ? false : true,
                                    rmk: isGrayColored ? false : true,
                                    amount: isGrayColored ? false : true,
                                }));

                                claimData.claim_adjustments = claimAdjustments;
                            }


                            if (values?.qdx_tranansaction_type !== "PATIENT_PAY" && claim?.service_lines && claim?.service_lines?.length > 0) {
                                claimData.service_lines = claim?.service_lines?.map((serviceLine, slIndex) => {

                                    const serviceLineData = {
                                        from_date: true,
                                        to_date: true,

                                        code: isGrayColored ? false : true,
                                        procedure_type: isGrayColored ? false : true,
                                        modifier1: isGrayColored ? false : true,
                                        modifier2: isGrayColored ? false : true,
                                        modifier3: isGrayColored ? false : true,
                                        modifier4: isGrayColored ? false : true,
                                        rev_code: isGrayColored ? false : true,
                                        units: isGrayColored ? false : true,
                                        billed: true,
                                        discount: isGrayColored ? false : true,
                                        allowed: isGrayColored ? false : true,
                                        deduct: isGrayColored ? false : true,
                                        coins: isGrayColored ? false : true,
                                        copay: isGrayColored ? false : true,
                                        cob: isGrayColored ? false : true,
                                        paid: true,
                                        adjustments: []
                                    };

                                    if (serviceLine?.adjustments && serviceLine?.adjustments?.length > 0) {
                                        serviceLineData.adjustments = serviceLine?.adjustments?.map((adjustment, adjIndex) => ({
                                            pc: isGrayColored ? false : true,
                                            group_code: isGrayColored ? false : true,
                                            reason_code: isGrayColored ? false : true,
                                            rmk: isGrayColored ? false : true,
                                            amount: isGrayColored ? false : true,
                                        }));
                                    }

                                    return serviceLineData;
                                });
                            }
                            return claimData;
                        }
                        else {
                            claimData = {
                                patient_account_number: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.patient_account_number?.toUpperCase() != claim?.patient_account_number?.toUpperCase() ? true : false,
                                payer_claim_number: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.payer_claim_number?.toUpperCase() != claim?.payer_claim_number?.toUpperCase() ? true : false,
                                period_start: moment(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.period_start).format("MM/DD/YYYY") != moment(claim?.period_start).format("MM/DD/YYYY") ? true : false,
                                period_end: moment(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.period_end).format("MM/DD/YYYY") != moment(claim?.period_end).format("MM/DD/YYYY") ? true : false,
                                status_code: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.status_code != claim?.status_code ? true : false,
                                mrn: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.mrn?.toUpperCase() != claim?.mrn?.toUpperCase() ? true : false,
                                statement_number: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.statement_number?.toUpperCase() != claim?.statement_number?.toUpperCase() ? true : false,
                                statement_date: values?.qdx_tranansaction_type === "PATIENT_PAY" && old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.statement_date != claim?.statement_date ? true : false,
                                page_from: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.page_from != claim?.page_from ? true : false,
                                page_to: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.page_to != claim?.page_to ? true : false,
                                sub_job_id: claim.sub_job_id ? claim.sub_job_id : "",

                                claim_billed: parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_billed ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_billed : 0).toFixed(2) != parseFloat(claim?.claim_billed ? claim?.claim_billed : 0).toFixed(2) ? true : false,
                                claim_discount: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_discount ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_discount : 0).toFixed(2) != parseFloat(claim?.claim_discount ? claim?.claim_discount : 0).toFixed(2) ? true : false,
                                claim_allowed: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_allowed ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_allowed : 0).toFixed(2) != parseFloat(claim?.claim_allowed ? claim?.claim_allowed : 0).toFixed(2) ? true : false,
                                claim_deduct: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_deduct ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_deduct : 0).toFixed(2) != parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0).toFixed(2) ? true : false,
                                claim_coins: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_coins ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_coins : 0).toFixed(2) != parseFloat(claim?.claim_coins ? claim?.claim_coins : 0).toFixed(2) ? true : false,
                                claim_copay: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_copay ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_copay : 0).toFixed(2) != parseFloat(claim?.claim_copay ? claim?.claim_copay : 0).toFixed(2) ? true : false,
                                claim_cob: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_cob ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_cob : 0).toFixed(2) != parseFloat(claim?.claim_cob ? claim?.claim_cob : 0).toFixed(2) ? true : false,
                                claim_patres: parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_patres ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_patres : 0).toFixed(2) != parseFloat(claim?.claim_patres ? claim?.claim_patres : 0).toFixed(2) ? true : false,
                                claim_paid: parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_paid ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_paid : 0).toFixed(2) != parseFloat(claim?.claim_paid ? claim?.claim_paid : 0).toFixed(2) ? true : false,
                                patient_first_name: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.patient_first_name?.toUpperCase() != claim?.patient_first_name?.toUpperCase() ? true : false,
                                patient_middle_name: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.patient_middle_name?.toUpperCase() != claim?.patient_middle_name?.toUpperCase() ? true : false,
                                patient_last_name: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.patient_last_name?.toUpperCase() != claim?.patient_last_name?.toUpperCase() ? true : false,
                                patient_identifier_code: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.patient_identifier_code?.toUpperCase() != claim?.patient_identifier_code?.toUpperCase() ? true : false,

                                subscriber_first_name: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.subscriber_first_name?.toUpperCase() != claim?.subscriber_first_name?.toUpperCase() ? true : false,
                                subscriber_middle_name: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.subscriber_middle_name?.toUpperCase() != claim?.subscriber_middle_name?.toUpperCase() ? true : false,
                                subscriber_last_name: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.subscriber_last_name?.toUpperCase() != claim?.subscriber_last_name?.toUpperCase() ? true : false,
                                subscriber_identifier_code: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.subscriber_identifier_code?.toUpperCase() != claim?.subscriber_identifier_code?.toUpperCase() ? true : false,

                                rendering_provider_first_name: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.rendering_provider_first_name?.toUpperCase() != claim?.rendering_provider_first_name?.toUpperCase() ? true : false,
                                rendering_provider_last_name: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.rendering_provider_last_name?.toUpperCase() != claim?.rendering_provider_last_name?.toUpperCase() ? true : false,
                                rendering_provider_identifier_code: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.rendering_provider_identifier_code?.toUpperCase() != claim?.rendering_provider_identifier_code?.toUpperCase() ? true : false,

                                claim_adjustments: [],

                                service_lines: [],
                            }
                            if (values?.qdx_tranansaction_type !== "PATIENT_PAY" && claim?.claim_adjustments && claim?.claim_adjustments?.length > 0) {
                                const claimAdjustments = claim.claim_adjustments.map((adjustment, adjIndex) => ({
                                    pc: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_adjustments[adjIndex]?.pc != adjustment.pc ? true : false,
                                    group_code: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_adjustments[adjIndex]?.group_code != adjustment.group_code ? true : false,
                                    reason_code: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_adjustments[adjIndex]?.reason_code != adjustment.reason_code ? true : false,
                                    rmk: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_adjustments[adjIndex]?.rmk != adjustment.rmk ? true : false,
                                    amount: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_adjustments[adjIndex]?.amount ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.claim_adjustments[adjIndex]?.amount : 0).toFixed(2) != parseFloat(adjustment.amount ? adjustment.amount : 0).toFixed(2) ? true : false,
                                }));
                                claimData.claim_adjustments = claimAdjustments;
                            }

                            if (values?.qdx_tranansaction_type !== "PATIENT_PAY" && claim?.service_lines && claim?.service_lines?.length > 0) {
                                claimData.service_lines = claim?.service_lines?.map((serviceLine, slIndex) => {
                                    let serviceLineData = {};
                                    if (serviceLine?.corrected_serviceline) {
                                        serviceLineData = {
                                            from_date: true,
                                            to_date: true,

                                            code: isGrayColored ? false : true,
                                            procedure_type: isGrayColored ? false : true,
                                            modifier1: isGrayColored ? false : true,
                                            modifier2: isGrayColored ? false : true,
                                            modifier3: isGrayColored ? false : true,
                                            modifier4: isGrayColored ? false : true,
                                            rev_code: isGrayColored ? false : true,
                                            units: isGrayColored ? false : true,
                                            billed: true,
                                            discount: isGrayColored ? false : true,
                                            allowed: isGrayColored ? false : true,
                                            deduct: isGrayColored ? false : true,
                                            coins: isGrayColored ? false : true,
                                            copay: isGrayColored ? false : true,
                                            cob: isGrayColored ? false : true,
                                            paid: true,
                                            adjustments: []
                                        };

                                        if (serviceLine?.adjustments && serviceLine?.adjustments?.length > 0) {
                                            serviceLineData.adjustments = serviceLine?.adjustments?.map((adjustment, adjIndex) => ({
                                                pc: isGrayColored ? false : true,
                                                group_code: isGrayColored ? false : true,
                                                reason_code: isGrayColored ? false : true,
                                                rmk: isGrayColored ? false : true,
                                                amount: isGrayColored ? false : true,
                                            }));
                                        }
                                    } else {
                                        serviceLineData = {
                                            from_date: moment(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.from_date).format("MM/DD/YYYY") != moment(serviceLine?.from_date).format("MM/DD/YYYY") ? true : false,
                                            to_date: moment(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.to_date).format("MM/DD/YYYY") != moment(serviceLine?.to_date).format("MM/DD/YYYY") ? true : false,

                                            code: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.code != serviceLine?.code ? true : false,
                                            procedure_type: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.procedure_type != serviceLine?.procedure_type ? true : false,
                                            modifier1: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.modifier1 != serviceLine?.modifier1 ? true : false,
                                            modifier2: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.modifier2 != serviceLine?.modifier2 ? true : false,
                                            modifier3: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.modifier3 != serviceLine?.modifier3 ? true : false,
                                            modifier4: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.modifier4 != serviceLine?.modifier4 ? true : false,
                                            rev_code: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.rev_code != serviceLine?.rev_code ? true : false,
                                            units: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.units != serviceLine?.units ? true : false,
                                            billed: parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.billed ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.billed : 0).toFixed(2) != parseFloat(serviceLine?.billed ? serviceLine?.billed : 0).toFixed(2) ? true : false,
                                            discount: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.discount ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.discount : 0).toFixed(2) != parseFloat(serviceLine?.discount ? serviceLine?.discount : 0).toFixed(2) ? true : false,
                                            allowed: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.allowed ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.allowed : 0).toFixed(2) != parseFloat(serviceLine?.allowed ? serviceLine?.allowed : 0).toFixed(2) ? true : false,
                                            deduct: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.deduct ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.deduct : 0).toFixed(2) != parseFloat(serviceLine?.deduct ? serviceLine?.deduct : 0).toFixed(2) ? true : false,
                                            coins: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.coins ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.coins : 0).toFixed(2) != parseFloat(serviceLine?.coins ? serviceLine?.coins : 0).toFixed(2) ? true : false,
                                            copay: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.copay ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.copay : 0).toFixed(2) != parseFloat(serviceLine?.copay ? serviceLine?.copay : 0).toFixed(2) ? true : false,
                                            cob: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.cob ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.cob : 0).toFixed(2) != parseFloat(serviceLine?.cob ? serviceLine?.cob : 0).toFixed(2) ? true : false,
                                            paid: parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.paid ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.paid : 0).toFixed(2) != parseFloat(serviceLine?.paid ? serviceLine?.paid : 0).toFixed(2) ? true : false,

                                            adjustments: []
                                        };

                                        if (serviceLine?.adjustments && serviceLine?.adjustments?.length > 0) {
                                            serviceLineData.adjustments = serviceLine?.adjustments?.map((adjustment, adjIndex) => ({
                                                pc: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.adjustments[adjIndex]?.pc != adjustment?.pc ? true : false,
                                                group_code: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.adjustments[adjIndex]?.group_code != adjustment?.group_code ? true : false,
                                                reason_code: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.adjustments[adjIndex]?.reason_code != adjustment?.reason_code ? true : false,
                                                rmk: isGrayColored ? false : old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.adjustments[adjIndex]?.rmk != adjustment?.rmk ? true : false,
                                                amount: isGrayColored ? false : parseFloat(old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.adjustments[adjIndex]?.amount ? old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.service_lines[serviceLine.serviceline_old_data_index ? serviceLine.serviceline_old_data_index : serviceLine.serviceline_old_data_index == 0 ? serviceLine.serviceline_old_data_index : slIndex]?.adjustments[adjIndex]?.amount : 0).toFixed(2) != parseFloat(adjustment?.amount ? adjustment?.amount : 0).toFixed(2) ? true : false,
                                            }));
                                        }
                                    }

                                    return serviceLineData;
                                });
                            }

                            return claimData;
                        }
                    });
                }
                qa_corrected_data.deleted_claims = deleted_claims
                qa_corrected_data.deleted_service_lines = deleted_service_lines
                qa_corrected_data.deleted_claim_adjustments = deleted_claim_adjustments
                qa_corrected_data.deleted_service_adjustments = deleted_service_adjustments
                qa_corrected_data.deleted_plb = deleted_plb
            }

            // =================QA ERROR MODAL======================
            const isShowQaErrorModal = window.location.pathname == QA_REVIEW && showQaErrorModal(qa_corrected_data, jobData?.grid_data?.qa_corrected_data) && !qaErrorModalSave
            if (isShowQaErrorModal) {
                setQaErrorModal(true);
                setSubmitting(false)
                return;
            }
            qa_corrected_data.qa_error_type = qaErrorformik?.values?.qa_error_type ? qaErrorformik?.values?.qa_error_type : []
            qa_corrected_data.qa_error_type_comment = qaErrorformik?.values?.qa_error_type_comment
            closeQaErrorModal()

            // =======================================
            // ================CALLING NEXT JOB=======================
            setformApiLoaded(true)

            if (!localStorage.getItem("SAVE_EXIT")) {
                // setJobData("no data")
                setLoadData(true)
            }
            // =======================================


            const data = values;

            data.job = jobData.job;
            if (window.location.pathname == QA_REVIEW) {
                // ========Add 837 pulled error data=========
                const { pull_837_error_count, pull_837_error_data } = getPullError837Count(qa_corrected_data, data, jobData?.grid_data)
                // const pull_837_error_data = getPullError837Data(qa_corrected_data, data?.claims, pulled_claim_ids_arr)
                qa_corrected_data.pull_837_error_count = pull_837_error_count
                qa_corrected_data.pull_837_error_data = pull_837_error_data
                data.qa_corrected_data = qa_corrected_data;
            }

            data.payment_id = values?.payment_id;
            data.work_type = window.location.pathname == DATA_ENTRY ? 1 : window.location.pathname == QA_REVIEW ? 2 : ""
            data.transaction_type = "PAYMENT835";

            if (qdxTransaction == 'PATIENT_PAY') {
                data.qdxTransaction = 'PATIENT_PAY'
            }
            data.remaining_balance = parseFloat(data?.remaining_balance ? data?.remaining_balance : 0).toFixed(2);
            data.is_custom_check_number = isCheckNumber;
            data.is_first_sub_job = isFirstJob;
            data.qdx_tranansaction_type = qdxTransaction;

            data.overall_page_from = isOverallPage ? overallPage?.from : 0;
            data.overall_page_to = isOverallPage ? overallPage?.to : 0;

            data.qdx_status = values?.qdx_status ? values?.qdx_status == "0" ? "" : values?.qdx_status : ""
            data.qdx_reason = values?.qdx_reason ? values?.qdx_reason == "0" ? "" : values?.qdx_reason : ""
            data.qdx_reasons = values?.qdx_reason.length > 0 ? values?.qdx_reason?.map((i) => i.value) : []
            data.qdx_classification = values?.qdx_classification ? values?.qdx_classification == "0" ? "" : values?.qdx_classification : ""

            data.is_orphaned_check = values?.is_orphaned_check ? true : false;
            data.is_missing_check_image = values?.is_missing_check_image ? true : false;
            data.is_forced_balancing = values?.is_forced_balancing ? true : false;
            data.is_payer_black_listing = values?.is_payer_black_listing ? true : false;
            data.is_payer_excluding_indexing = values?.is_payer_excluding_indexing ? true : false;

            if (!isSubJob && isFirstJob) {

                data.check_number = data?.check_number ? data.check_number?.toUpperCase() : ""
                data.correspondance = data?.correspondance ? data.correspondance : ""
                data.rejection_reason = data?.rejection_reason ? data.rejection_reason.toUpperCase() : ""
                data.payment_method = data?.payment_method ? data.payment_method.toUpperCase() : ""
                data.payee_name = data?.payee_name ? data.payee_name.toUpperCase() : ""
                data.payee_npi = data?.payee_npi ? data.payee_npi.toUpperCase() : ""
                data.payee_address = data?.payee_address ? data.payee_address.toUpperCase() : ""
                data.payee_city = data?.payee_city ? data.payee_city.toUpperCase() : ""
                data.payee_state = data?.payee_state ? data.payee_state.toUpperCase() : ""
                data.payee_page_from = data?.payee_page_from ? data.payee_page_from : ""
                data.payee_page_to = data?.payee_page_to ? data.payee_page_to : ""
                data.reference_entity_identifier = data?.reference_entity_identifier ? data?.reference_entity_identifier : ""

                data.payer_name = data?.payer_name ? data.payer_name.toUpperCase() : ""
                data.payer_address = data?.payer_address ? data.payer_address.toUpperCase() : ""
                data.payer_city = data?.payer_city ? data.payer_city.toUpperCase() : ""
                data.payer_state = data?.payer_state ? data.payer_state.toUpperCase() : ""
                data.payer_page_from = data?.payer_page_from ? data.payer_page_from : ""
                data.payer_page_to = data?.payer_page_to ? data.payer_page_to : ""
            }
            else {

                data.check_number = data?.check_number ? data.check_number?.toUpperCase() : ""
                data.correspondance = data?.correspondance ? data.correspondance : ""
                data.rejection_reason = data?.rejection_reason ? data.rejection_reason.toUpperCase() : ""
                data.payment_method = data?.payment_method ? data.payment_method.toUpperCase() : ""
                data.payee_name = data?.payee_name ? data.payee_name.toUpperCase() : ""
                data.payee_npi = data?.payee_npi ? data.payee_npi.toUpperCase() : ""
                data.payee_address = data?.payee_address ? data.payee_address.toUpperCase() : ""
                data.payee_city = data?.payee_city ? data.payee_city.toUpperCase() : ""
                data.payee_state = data?.payee_state ? data.payee_state.toUpperCase() : ""
                data.payee_page_from = data?.payee_page_from ? data.payee_page_from : ""
                data.payee_page_to = data?.payee_page_to ? data.payee_page_to : ""
                data.reference_entity_identifier = data?.reference_entity_identifier ? data?.reference_entity_identifier : ""

                data.payer_name = data?.payer_name ? data.payer_name.toUpperCase() : ""
                data.payer_address = data?.payer_address ? data.payer_address.toUpperCase() : ""
                data.payer_city = data?.payer_city ? data.payer_city.toUpperCase() : ""
                data.payer_state = data?.payer_state ? data.payer_state.toUpperCase() : ""
                data.payer_page_from = data?.payer_page_from ? data.payer_page_from : ""
                data.payer_page_to = data?.payer_page_to ? data.payer_page_to : ""
            }

            if (data.grid_customization != "PLB_ONLY") {
                if (data?.claims?.length > 0) {

                    data?.claims.map((claim, index) => {

                        claim.order = index + 1;

                        claim.corrected_claim = claim?.corrected_claim ? claim?.corrected_claim : false;

                        claim.encounter = claim?.encounter ? claim?.encounter : "";
                        claim.patient_account_number = claim?.patient_account_number ? claim?.patient_account_number.toUpperCase() : "";
                        claim.payer_claim_number = claim?.payer_claim_number ? claim?.payer_claim_number.toUpperCase() : "";
                        claim.patient_first_name = claim?.patient_first_name ? claim?.patient_first_name.toUpperCase() : "";
                        claim.patient_middle_name = claim?.patient_middle_name ? claim?.patient_middle_name.toUpperCase() : "";
                        claim.patient_last_name = claim?.patient_last_name ? claim?.patient_last_name.toUpperCase() : "";
                        claim.patient_identifier_code = claim?.patient_identifier_code ? claim?.patient_identifier_code.toUpperCase() : "";
                        claim.mrn = claim?.mrn ? claim?.mrn.toUpperCase() : "";
                        claim.statement_number = claim?.statement_number ? claim?.statement_number.toUpperCase() : "";

                        claim.subscriber_first_name = claim?.subscriber_first_name ? claim?.subscriber_first_name.toUpperCase() : "";
                        claim.subscriber_middle_name = claim?.subscriber_middle_name ? claim?.subscriber_middle_name.toUpperCase() : "";
                        claim.subscriber_last_name = claim?.subscriber_last_name ? claim?.subscriber_last_name.toUpperCase() : ""
                        claim.subscriber_identifier_code = claim?.subscriber_identifier_code ? claim?.subscriber_identifier_code.toUpperCase() : "";

                        claim.rendering_provider_first_name = claim?.rendering_provider_first_name ? claim?.rendering_provider_first_name.toUpperCase() : "";
                        claim.rendering_provider_last_name = claim?.rendering_provider_last_name ? claim?.rendering_provider_last_name.toUpperCase() : "";
                        claim.rendering_provider_identifier_code = claim?.rendering_provider_identifier_code ? claim?.rendering_provider_identifier_code.toUpperCase() : "";

                        if (qdxTransaction == 'PATIENT_PAY') {
                            claim.service_lines = [];
                            if (data?.qa_corrected_data?.claims?.length > 0) {
                                removeKeysFromForEobForQDXPatientPay(data?.qa_corrected_data?.claims[index])
                            }
                        }

                        if (claim.service_lines && claim.service_lines?.length > 0) {

                            claim.service_lines?.map((serviceline, index) => {

                                serviceline.order = index + 1;
                                serviceline.corrected_serviceline = serviceline?.corrected_serviceline ? serviceline?.corrected_serviceline : false;
                                serviceline.code = serviceline?.code ? serviceline?.code.toUpperCase() : "";
                                serviceline.modifier1 = serviceline?.modifier1 ? serviceline?.modifier1.toUpperCase() : "";
                                serviceline.modifier2 = serviceline?.modifier2 ? serviceline?.modifier2.toUpperCase() : "";
                                serviceline.modifier3 = serviceline?.modifier3 ? serviceline?.modifier3.toUpperCase() : "";
                                serviceline.modifier4 = serviceline?.modifier4 ? serviceline?.modifier4.toUpperCase() : "";
                                serviceline.rev_code = serviceline?.rev_code ? serviceline?.rev_code.toUpperCase() : "";

                                if (serviceline?.adjustments && serviceline?.adjustments?.length > 0) {
                                    serviceline?.adjustments?.map((adjustment, index) => {
                                        adjustment.rmk = adjustment.rmk ? adjustment.rmk.toUpperCase() : "";
                                        adjustment.pc = adjustment.pc ? adjustment.pc.toUpperCase() : "";
                                        adjustment.reason_code = adjustment.reason_code ? adjustment.reason_code.toUpperCase() : "";
                                    })
                                }
                            })
                        }


                        if (claim?.claim_adjustments && claim?.claim_adjustments?.length > 0) {
                            claim?.claim_adjustments?.map((claim_adjustment, index) => {
                                claim_adjustment.rmk = claim_adjustment.rmk ? claim_adjustment.rmk.toUpperCase() : "";
                                claim_adjustment.pc = claim_adjustment.pc ? claim_adjustment.pc.toUpperCase() : "";
                                claim_adjustment.reason_code = claim_adjustment.reason_code ? claim_adjustment.reason_code.toUpperCase() : "";
                            })
                        }

                    })
                }
            } else {
                data.claims = []

            }
            if (data?.plb_adjustments && data?.plb_adjustments?.length > 0) {
                data.plb_adjustments?.map((plb, index) => {
                    plb.patient_account_number = plb?.patient_account_number ? plb.patient_account_number.toUpperCase() : "";
                })
            }

            // localStorage.removeItem('grid_data')
            // localStorage.removeItem('grid_state_data')
            // localStorage.removeItem('grid_qdx_data')
            // localStorage.removeItem('grid_overall_data')
            data.is_save_exit = localStorage.getItem("SAVE_EXIT") ? true : false;
            values.status = ""
            data.grid_total_fields_count = getTotalFieldsCount(formEob)
            const RedirectId = getRedirectTabId()
            const response = createOrUpdatePayment835(data)(async (response) => {
                if (response?.status && response?.status_code === 200) {
                    setSaveSuccess(true)
                    setformApiLoaded(false)

                    if (localStorage.getItem("SAVE_EXIT")) {
                        localStorage.removeItem("SAVE_EXIT");
                        setToast({ status: true, type: "success", message: "Success" });
                        localStorage.removeItem('grid_data')
                        localStorage.removeItem('grid_state_data')
                        localStorage.removeItem('grid_qdx_data')
                        localStorage.removeItem('grid_overall_data')
                        handleNavigateDashBoard(RedirectId)

                    } else {
                        setSubmitting(false);
                        localStorage.removeItem('transaction_type')
                        // window.location.reload();
                        setToast({ status: true, type: "success", message: "Success" });
                    }
                } else if (response?.status_code == 400 && response?.errors && response?.errors?.assign_user?.length > 0) {
                    setformApiLoaded(false)

                    setErrorAlert({
                        status: true,
                        type: "danger",
                        message: response?.errors?.assign_user[0]
                    })
                    setSubmitting(false);

                    const errors = response.response?.data?.errors;
                    formEob.setErrors({ errors });
                }
                else {
                    setErrorJobData(values)
                    setformApiLoaded(false)

                    setToast({ status: true, type: "danger", message: "Failed" });
                    setSubmitting(false);

                    const errors = response.response?.data?.errors;
                    formEob.setErrors({ errors });
                }
            });

            // setLoadData(true)
        },

    });




    const clearServiceLines = async () => {

        const _ = require('lodash');
        const claims = _.cloneDeep(formEob.values.claims);
        if (claims?.length > 0) {
            claims.map((claim, index) => {

                claim.claim_discount = parseFloat(0).toFixed(2);
                claim.claim_allowed = parseFloat(0).toFixed(2);
                claim.claim_deduct = parseFloat(0).toFixed(2);
                claim.claim_coins = parseFloat(0).toFixed(2);
                claim.claim_copay = parseFloat(0).toFixed(2);
                claim.claim_cob = parseFloat(0).toFixed(2);
                claim.claim_patres = parseFloat(0).toFixed(2);
                claim.claim_balance = parseFloat(0).toFixed(2);

                claim.sl_billed = parseFloat(0).toFixed(2);
                claim.sl_discount = parseFloat(0).toFixed(2);
                claim.sl_allowed = parseFloat(0).toFixed(2);
                claim.sl_deduct = parseFloat(0).toFixed(2);
                claim.sl_coins = parseFloat(0).toFixed(2);
                claim.sl_copay = parseFloat(0).toFixed(2);
                claim.sl_cob = parseFloat(0).toFixed(2);
                claim.sl_patres = parseFloat(0).toFixed(2);
                claim.sl_amount = parseFloat(0).toFixed(2);
                claim.sl_paid = parseFloat(0).toFixed(2);
                claim.sl_balance = parseFloat(0).toFixed(2);
                claim.sl_total = parseFloat(0).toFixed(2);
                claim.claim_adjustments = [
                    {
                        pc: "",
                        group_code: "",
                        reason_code: "",
                        rmk: "",
                        amount: "",
                        claim_adjustment_id: "",
                    },
                ];


                if (claim?.service_lines?.length > 0) {
                    claim.service_lines = [
                        {
                            color_index: "",
                            order: "",
                            service_line_id: "",
                            from_date: "",
                            to_date: "",
                            code: "",
                            procedure_type: "HC",
                            modifier1: "",
                            modifier2: "",
                            modifier3: "",
                            modifier4: "",
                            rev_code: "",
                            units: "",
                            patres: "",
                            billed: "",
                            discount: "",
                            allowed: "",
                            deduct: "",
                            coins: "",
                            copay: "",
                            cob: "",
                            paid: "",
                            color_data: [],

                            balance: "",
                            adjustments: [
                                {
                                    adjustment_id: "",
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                },
                            ],
                        },
                    ]
                }
            })
        }
        setTimeout(() => {

            formEob.validateForm();
        }, 200);


    }
    const formRc = useFormik({
        initialValues: {
            reason_code: "",
            description: "",
            carc: "",
            rarc: "",
            group_code: "",
        },
        validate: (values) => {
            let errors = {};

            if (values.group_code == "" || values.group_code == "0") {
                errors.group_code = "Select an Option";
            }

            return errors;
        },
        validationSchema: Yup.object().shape({
            reason_code: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            carc: Yup.string().required("Required").max('10'),
            rarc: Yup.string().max('10'),
            group_code: Yup.string().required("Required"),
        }),

        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            if (formEob.values?.payer_id) {
                const data_from = 2;
                createPropCode(
                    jobData?.job,
                    formEob.values?.payer_id,
                    null,
                    null,
                    null,
                    null,
                    null,
                    values.reason_code?.toUpperCase(),
                    data_from,
                    values?.description?.toUpperCase(),
                    values?.rarc?.toUpperCase(),
                    values?.carc?.toUpperCase(),
                    values?.group_code
                )((response) => {
                    if (response && response.status) {
                        setPcPopup(false);

                        formEob.setValues((prevValues) => {
                            const claims = prevValues.claims.map((claim, index) => {
                                if (index === propCodeData.index) {
                                    const serviceLines = claim.service_lines.map((serviceLine, serviceIndex) => {
                                        if (serviceIndex === propCodeData.serviceIndex) {
                                            const adjustments = serviceLine.adjustments.map((adjustment, adjustmentIndex) => {
                                                if (adjustmentIndex === propCodeData.adjustmentIndex) {
                                                    return {
                                                        ...adjustment,
                                                        reason_code: formRc?.values?.carc ? formRc?.values?.carc.toUpperCase() : "",
                                                        rmk: formRc?.values?.rarc ? formRc?.values?.rarc.toUpperCase() : "",
                                                        group_code: formRc?.values?.group_code,
                                                        description: formRc?.values?.description,
                                                    };
                                                }
                                                return adjustment;
                                            });
                                            return {
                                                ...serviceLine,
                                                adjustments,
                                            };
                                        }
                                        return serviceLine;
                                    });
                                    return {
                                        ...claim,
                                        service_lines: serviceLines,
                                    };
                                }
                                return claim;
                            });

                            return {
                                ...prevValues,
                                claims,
                            };
                        }).then(() =>
                            setTimeout(() => formRc.handleReset()), 200);

                        const focusNext = document.querySelector(`input[name="claims[${propCodeData.index}].service_lines[${propCodeData.serviceIndex}].adjustments[${propCodeData.adjustmentIndex}].pc"]`)
                        if (focusNext && focusNext != undefined) {
                            focusNext.focus()
                        }
                        setTimeout(() => {
                            handlePatresCalculation();
                        }, 200);
                        formRc.validateForm();
                        setIsDrag(true)
                        setToast({ status: true, type: "success", message: "Success" });
                    } else if (response?.status_code == 400 && response?.errors) {
                        setErrorAlert({
                            status: true,
                            type: "danger",
                            message: response?.errors?.assign_user[0]
                        })
                    } else {
                        formRc.setErrors({
                            ...formRc.errors,
                            reason_code: response?.response?.data?.errors?.reason_code,
                            rarc: response?.response?.data?.errors?.rarc,
                        });
                        setIsDrag(false)
                        setPcPopup(true);
                    }
                });
            } else {
                const data_from = 2;
                const values = {
                    last_name: formEob.values?.payer_name ? formEob.values?.payer_name?.toUpperCase() : "",
                    n3_address_1: formEob.values?.payer_address ? formEob.values?.payer_address?.toUpperCase() : "",
                    n4_city_name: formEob.values?.payer_city ? formEob.values?.payer_city?.toUpperCase() : "",
                    n4_state_code: formEob.values?.payer_state ? formEob.values?.payer_state?.toUpperCase() : "",
                    n4_postal_code: formEob.values?.payer_zip ? formEob.values?.payer_zip?.toUpperCase() : "",

                    reason_code: formRc.values?.reason_code ? formRc.values?.reason_code?.toUpperCase() : "",
                    description: formRc.values?.description ? formRc.values?.description?.toUpperCase() : "",
                    rarc: formRc.values?.rarc ? formRc.values?.rarc?.toUpperCase() : "",
                    carc: formRc.values?.carc ? formRc.values?.carc?.toUpperCase() : "",
                    group_code: formRc.values?.group_code,
                };
                createPropCode(
                    jobData?.job,
                    null,
                    values.last_name?.toUpperCase(),
                    values.n3_address_1?.toUpperCase(),
                    values.n4_city_name?.toUpperCase(),
                    values.n4_state_code?.toUpperCase(),
                    values.n4_postal_code?.toUpperCase(),
                    values.reason_code?.toUpperCase(),
                    data_from,
                    values?.description?.toUpperCase(),
                    values?.rarc?.toUpperCase(),
                    values?.carc?.toUpperCase(),
                    values?.group_code
                )((response) => {
                    if (response && response.status) {
                        setPcPopup(false);

                        const claims = formEob.values?.claims;

                        claims[propCodeData.index].service_lines[propCodeData.serviceIndex].adjustments[propCodeData.adjustmentIndex].reason_code = formRc?.values?.carc ? formRc?.values?.carc?.toUpperCase() : "";
                        claims[propCodeData.index].service_lines[propCodeData.serviceIndex].adjustments[propCodeData.adjustmentIndex].rmk = formRc?.values?.rarc ? formRc?.values?.rarc?.toUpperCase() : "";
                        claims[propCodeData.index].service_lines[propCodeData.serviceIndex].adjustments[propCodeData.adjustmentIndex].group_code = formRc?.values?.group_code;
                        claims[propCodeData.index].service_lines[propCodeData.serviceIndex].adjustments[propCodeData.adjustmentIndex].description = formRc?.values?.description?.toUpperCase();
                        formEob
                            .setValues({
                                ...formEob.values,
                                payer_id: response?.data?.payer_id
                                    ? response?.data?.payer_id
                                    : "",
                                claims: claims,
                            })
                            .then(() => formRc.handleReset());
                        const focusNext = document.querySelector(`input[name="claims[${propCodeData.index}].service_lines[${propCodeData.serviceIndex}].adjustments[${propCodeData.adjustmentIndex}].pc"]`)
                        if (focusNext && focusNext != undefined) {
                            focusNext.focus()
                        }
                        setTimeout(() => {
                            handlePatresCalculation();
                        }, 200);
                        setIsDrag(true)

                        setToast({ status: true, type: "success", message: "Success" });
                        formRc.validateForm();
                    } else if (response?.status_code == 400 && response?.errors) {
                        setErrorAlert({
                            status: true,
                            type: "danger",
                            message: response?.errors?.assign_user[0]
                        })
                    } else {
                        formRc.setErrors({
                            ...formRc.errors,
                            reason_code: response?.response?.data?.errors?.reason_code,
                            rarc: response?.response?.data?.errors?.rarc,
                        });
                        setIsDrag(false)

                        setPcPopup(true);
                        setToast({ status: true, type: "danger", message: "Failed" });
                    }
                });
            }
        },
    });


    const setFormRc = async (event) => {
        await formRc.setValues({
            ...formRc.values,
            reason_code: event ? event.toUpperCase() : "",
        });
    };

    const setPcFormik = async (event, index, serviceIndex, adjustmentIndex) => {
        await formEob.setValues((prevValues) => {
            const updatedClaims = prevValues.claims.map((claim, ind) => {
                if (ind === index) {
                    const updatedServiceLines = claim.service_lines.map((serviceLine, sfIndex) => {
                        if (sfIndex === serviceIndex) {
                            const updatedAdjustments = serviceLine.adjustments.map((adjustment, adjIndex) => {
                                if (adjIndex === adjustmentIndex) {
                                    return {
                                        ...adjustment,
                                        pc: event ? event.toUpperCase() : "",
                                    };
                                }
                                return adjustment;
                            });
                            return {
                                ...serviceLine,
                                adjustments: updatedAdjustments,
                            };
                        }
                        return serviceLine;
                    });
                    return {
                        ...claim,
                        service_lines: updatedServiceLines,
                    };
                }
                return claim;
            });

            return {
                ...prevValues,
                claims: updatedClaims,
            };
        });
    };

    const formClaimRc = useFormik({
        // enableReinitialize: true,
        initialValues: {
            reason_code: "",
            description: "",
            carc: "",
            rarc: "",
            group_code: "",
        },
        validate: (values) => {
            let errors = {};

            if (values.group_code == "" || values.group_code == "0") {
                errors.group_code = "Select an Option";
            }
            return errors;
        },
        validationSchema: Yup.object().shape({
            reason_code: Yup.string().required("Required"),
            description: Yup.string().required("Required"),
            carc: Yup.string().required("Required"),
            rarc: Yup.string(),
            group_code: Yup.string().required("Required"),
        }),

        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            if (formEob.values?.payer_id) {
                const data_from = 2;
                createPropCode(
                    jobData?.job,
                    formEob.values?.payer_id,
                    null,
                    null,
                    null,
                    null,
                    null,
                    values.reason_code?.toUpperCase(),
                    data_from,
                    values?.description?.toUpperCase(),
                    values?.rarc?.toUpperCase(),
                    values?.carc?.toUpperCase(),
                    values?.group_code
                )((response) => {
                    if (response && response.status) {
                        var rmkField = document.querySelector(`[name="claims[${propCodeClaimData.index}].claim_adjustments[${propCodeClaimData.adjustmentIndex + 1}].amount"]`);
                        if (rmkField) {
                            rmkField.focus()
                        } else {
                            const aditionalInfoButton = document.getElementById("additional-btn");
                            aditionalInfoButton.focus()
                        }
                        setPcClaimPopup(false);
                        const claims = formEob.values?.claims;
                        claims[propCodeClaimData.index].claim_adjustments[propCodeClaimData.adjustmentIndex].reason_code =
                            formClaimRc?.values?.carc?.toUpperCase();
                        claims[propCodeClaimData.index].claim_adjustments[propCodeClaimData.adjustmentIndex].rmk =
                            formClaimRc?.values?.rarc?.toUpperCase();
                        claims[propCodeClaimData.index].claim_adjustments[propCodeClaimData.adjustmentIndex].group_code =
                            formClaimRc?.values?.group_code;
                        claims[propCodeClaimData.index].claim_adjustments[propCodeClaimData.adjustmentIndex].description =
                            formClaimRc?.values?.description;

                        formEob.setValues({
                            ...formEob.values,
                            // payer_id: response?.data?.payer_id
                            //     ? response?.data?.payer_id
                            //     : "",
                            claims: claims,
                        })
                            .then(() => formClaimRc.handleReset());
                        setTimeout(() => {
                            handleClaimPatresCalculation()
                        }, 200);
                        formClaimRc.validateForm();
                        setIsDrag(true)
                        setToast({ status: true, type: "success", message: "Success" });
                    } else if (response?.status_code == 400 && response?.errors) {
                        setErrorAlert({
                            status: true,
                            type: "danger",
                            message: response?.errors?.assign_user[0]
                        })
                    } else {
                        formClaimRc.setErrors({
                            ...formClaimRc.errors,
                            reason_code: response?.response?.data?.errors?.reason_code,
                            rarc: response?.response?.data?.errors?.rarc,
                        });
                        setIsDrag(false)
                        setPcClaimPopup(true);
                    }
                });
            } else {
                const data_from = 2;

                const values = {
                    last_name: formEob.values?.payer_name ? formEob.values?.payer_name?.toUpperCase() : "",
                    n3_address_1: formEob.values?.payer_address ? formEob.values?.payer_address?.toUpperCase() : "",
                    n4_city_name: formEob.values?.payer_city ? formEob.values?.payer_city?.toUpperCase() : "",
                    n4_state_code: formEob.values?.payer_state ? formEob.values?.payer_state?.toUpperCase() : "",
                    n4_postal_code: formEob.values?.payer_zip ? formEob.values?.payer_zip?.toUpperCase() : "",

                    reason_code: formClaimRc.values?.reason_code ? formClaimRc.values?.reason_code?.toUpperCase() : "",
                    description: formClaimRc.values?.description ? formClaimRc.values?.description?.toUpperCase() : "",
                    rarc: formClaimRc.values?.rarc ? formClaimRc.values?.rarc?.toUpperCase() : "",
                    carc: formClaimRc.values?.carc ? formClaimRc.values?.carc?.toUpperCase() : "",
                    group_code: formClaimRc.values?.group_code,
                };

                createPropCode(
                    jobData?.job,
                    null,
                    values.last_name?.toUpperCase(),
                    values.n3_address_1?.toUpperCase(),
                    values.n4_city_name?.toUpperCase(),
                    values.n4_state_code?.toUpperCase(),
                    values.n4_postal_code?.toUpperCase(),
                    values.reason_code?.toUpperCase(),
                    data_from,
                    values?.description?.toUpperCase(),
                    values?.rarc?.toUpperCase(),
                    values?.carc?.toUpperCase(),
                    values?.group_code
                )((response) => {
                    if (response && response.status) {
                        setPcClaimPopup(false);

                        const claims = formEob.values?.claims;
                        claims[propCodeClaimData.index].claim_adjustments[propCodeClaimData.adjustmentIndex].reason_code =
                            formClaimRc?.values?.carc ? formClaimRc.values?.carc?.toUpperCase() : "";
                        claims[propCodeClaimData.index].claim_adjustments[propCodeClaimData.adjustmentIndex].rmk =
                            formClaimRc?.values?.rarc ? formClaimRc.values?.rarc?.toUpperCase() : "";
                        claims[propCodeClaimData.index].claim_adjustments[propCodeClaimData.adjustmentIndex].group_code =
                            formClaimRc?.values?.group_code;
                        claims[propCodeClaimData.index].claim_adjustments[propCodeClaimData.adjustmentIndex].description =
                            formClaimRc?.values?.description;
                        formEob
                            .setValues({
                                ...formEob.values,
                                payer_id: response?.data?.payer_id
                                    ? response?.data?.payer_id
                                    : "",
                                claims: claims,
                            })
                            .then(() => formClaimRc.handleReset());
                        setIsDrag(true)
                        setTimeout(() => {
                            handleClaimPatresCalculation()
                        }, 200);

                        setToast({ status: true, type: "success", message: "Success" });
                        formClaimRc.validateForm();
                    } else if (response?.status_code == 400 && response?.errors) {
                        setErrorAlert({
                            status: true,
                            type: "danger",
                            message: response?.errors?.assign_user[0]
                        })
                    } else {
                        formClaimRc.setErrors({
                            ...formClaimRc.errors,
                            reason_code: response?.response?.data?.errors?.reason_code,
                            rarc: response?.response?.data?.errors?.rarc,
                        });
                        setIsDrag(false)

                        setPcClaimPopup(true);
                        setToast({ status: true, type: "danger", message: "Failed" });
                    }
                });
            }
        },
    });


    const setFormClaimRc = async (event) => {
        await formClaimRc.setValues({
            ...formClaimRc.values,
            reason_code: event ? event?.toUpperCase() : "",
        });
    };



    const setPcClaimFormik = async (event, index, adjustmentIndex) => {
        const claims = formEob.values.claims;

        claims[index].claim_adjustments[adjustmentIndex].pc = event ? event?.toUpperCase() : "";

        await formEob.setValues({
            ...formEob.values,
            claims: claims,
        });
    };

    const clearFormEob835 = async () => {
        await formEob.handleReset();
        await formEob.validateForm();
    };

    // const resetFormAndInsertData = async () => {
    //     let data = {};

    //     const task_type = window.location.pathname == DATA_ENTRY ? "1" : "2";

    //     const response = await getJobDetails(task_type)((response) => {
    //         if (
    //             response &&
    //             !response?.status &&
    //             response?.status_code == 400 &&
    //             response?.errors == "No job remaining"
    //         ) {
    //             setNoJOb(true);
    //         } else {
    //             setJobData(response?.data);

    //             // setTimeout(() => {

    //             //     resetClaimData();
    //             // }, 200);
    //             setTimeout(() => {

    //                 clearEob835Form()
    //             }, 100);

    //             setFileUrl(response?.data?.file_url);
    //             setNoJOb(false);

    //             const values = response?.data?.grid_data;

    //             if (state.Payment835 || values?.transaction_type == "PAYMENT835") {
    //                 if (values?.claims?.length > 0) {
    //                     var claims = values?.claims;
    //                     var plb_adjustments = values?.plb_adjustments;

    //                     if (plb_adjustments && plb_adjustments?.length > 0) {
    //                         plb_adjustments.map((plb) => {
    //                             plb.plb_amt = parseFloat(plb.plb_amt ? plb.plb_amt : 0).toFixed(
    //                                 2
    //                             );
    //                         });
    //                     }
    //                     claims.map((claim) => {
    //                         claim.patient = claim.patient_id ? true : false;
    //                         claim.subscriber = claim.subscriber_id ? true : false;
    //                         claim.rendering_provider = claim.rendering_provider_id
    //                             ? true
    //                             : false;
    //                         claim.period_start = moment(claim.period_start).format(
    //                             "MM/DD/YYYY"
    //                         );
    //                         claim.period_end = moment(claim.period_end).format("MM/DD/YYYY");
    //                         claim.claim_billed = parseFloat(
    //                             claim?.claim_billed ? claim?.claim_billed : 0
    //                         ).toFixed(2);
    //                         claim.claim_discount = parseFloat(
    //                             claim?.claim_discount ? claim?.claim_discount : 0
    //                         ).toFixed(2);
    //                         claim.claim_allowed = parseFloat(
    //                             claim?.claim_allowed ? claim?.claim_allowed : 0
    //                         ).toFixed(2);
    //                         claim.claim_deduct = parseFloat(
    //                             claim?.claim_deduct ? claim?.claim_deduct : 0
    //                         ).toFixed(2);
    //                         claim.claim_coins = parseFloat(
    //                             claim?.claim_coins ? claim?.claim_coins : 0
    //                         ).toFixed(2);
    //                         claim.claim_copay = parseFloat(
    //                             claim?.claim_copay ? claim?.claim_copay : 0
    //                         ).toFixed(2);
    //                         claim.claim_cob = parseFloat(
    //                             claim?.claim_cob ? claim?.claim_cob : 0
    //                         ).toFixed(2);
    //                         claim.claim_patres = parseFloat(
    //                             claim?.claim_patres ? claim?.claim_patres : 0
    //                         ).toFixed(2);
    //                         claim.claim_paid = parseFloat(
    //                             claim?.claim_paid ? claim?.claim_paid : 0
    //                         ).toFixed(2);
    //                         claim.claim_balance = parseFloat(
    //                             claim?.claim_balance ? claim?.claim_balance : 0
    //                         ).toFixed(2);

    //                         claim.sl_billed = parseFloat(
    //                             claim?.sl_billed ? claim?.sl_billed : 0
    //                         ).toFixed(2);
    //                         claim.sl_discount = parseFloat(
    //                             claim?.sl_discount ? claim?.sl_discount : 0
    //                         ).toFixed(2);
    //                         claim.sl_allowed = parseFloat(
    //                             claim?.sl_allowed ? claim?.sl_allowed : 0
    //                         ).toFixed(2);
    //                         claim.sl_deduct = parseFloat(
    //                             claim?.sl_deduct ? claim?.sl_deduct : 0
    //                         ).toFixed(2);
    //                         claim.sl_coins = parseFloat(
    //                             claim?.sl_coins ? claim?.sl_coins : 0
    //                         ).toFixed(2);
    //                         claim.sl_copay = parseFloat(
    //                             claim?.sl_copay ? claim?.sl_copay : 0
    //                         ).toFixed(2);
    //                         claim.sl_cob = parseFloat(
    //                             claim?.sl_cob ? claim?.sl_cob : 0
    //                         ).toFixed(2);
    //                         claim.sl_patres = parseFloat(
    //                             claim?.sl_patres ? claim?.sl_patres : 0
    //                         ).toFixed(2);
    //                         claim.sl_amount = parseFloat(
    //                             claim?.sl_amount ? claim?.sl_amount : 0
    //                         ).toFixed(2);
    //                         claim.sl_paid = parseFloat(
    //                             claim?.sl_paid ? claim?.sl_paid : 0
    //                         ).toFixed(2);
    //                         claim.sl_balance = parseFloat(
    //                             claim?.sl_balance ? claim?.sl_balance : 0
    //                         ).toFixed(2);
    //                         claim.sl_total = parseFloat(
    //                             claim?.sl_total ? claim?.sl_total : 0
    //                         ).toFixed(2);

    //                         claim?.claim_adjustments && claim?.claim_adjustments?.length > 0
    //                             ? claim?.claim_adjustments?.map((adjustment) => {
    //                                 adjustment.amount = parseFloat(
    //                                     adjustment.amount ? adjustment.amount : 0
    //                                 ).toFixed(2);
    //                             })
    //                             : (claim.claim_adjustments =
    //                                 formEob.initialValues.claims[0].claim_adjustments);
    //                         claim?.service_lines && claim?.service_lines?.length > 0
    //                             ? claim?.service_lines.map((serviceline) => {
    //                                 serviceline.from_date = moment(
    //                                     serviceline?.from_date
    //                                 ).format("MM/DD/YYYY");
    //                                 serviceline.to_date = moment(serviceline?.to_date).format(
    //                                     "MM/DD/YYYY"
    //                                 );

    //                                 serviceline.billed = parseFloat(
    //                                     serviceline?.billed ? serviceline?.billed : 0
    //                                 ).toFixed(2);
    //                                 serviceline.patres = parseFloat(
    //                                     serviceline?.patres ? serviceline?.patres : 0
    //                                 ).toFixed(2);
    //                                 serviceline.discount = parseFloat(
    //                                     serviceline?.discount ? serviceline?.discount : 0
    //                                 ).toFixed(2);
    //                                 serviceline.allowed = parseFloat(
    //                                     serviceline?.allowed ? serviceline?.allowed : 0
    //                                 ).toFixed(2);
    //                                 serviceline.deduct = parseFloat(
    //                                     serviceline?.deduct ? serviceline?.deduct : 0
    //                                 ).toFixed(2);
    //                                 serviceline.coins = parseFloat(
    //                                     serviceline?.coins ? serviceline?.coins : 0
    //                                 ).toFixed(2);
    //                                 serviceline.copay = parseFloat(
    //                                     serviceline?.copay ? serviceline?.copay : 0
    //                                 ).toFixed(2);
    //                                 serviceline.cob = parseFloat(
    //                                     serviceline?.cob ? serviceline?.cob : 0
    //                                 ).toFixed(2);
    //                                 serviceline.paid = parseFloat(
    //                                     serviceline?.paid ? serviceline?.paid : 0
    //                                 ).toFixed(2);
    //                                 serviceline.balance = parseFloat(
    //                                     serviceline?.balance ? serviceline?.balance : 0
    //                                 ).toFixed(2);

    //                                 serviceline?.adjustments.map((adjustment) => {
    //                                     adjustment.amount = parseFloat(
    //                                         adjustment.amount ? adjustment.amount : 0
    //                                     ).toFixed(2);
    //                                 });
    //                             })
    //                             : (claim.service_lines =
    //                                 formEob.initialValues.claims[0].service_lines);
    //                     });
    //                 } else {
    //                     claims = formEob.initialValues?.claims;
    //                 }
    //                 setTimeout(() => {
    //                     formEob.setValues({
    //                         ...formEob.values,

    //                         job: response?.data?.job,
    //                         client_id: response?.data?.client_id,
    //                         payment_id:
    //                             values?.payment_id && values?.payment_id
    //                                 ? values?.payment_id
    //                                 : "",

    //                         transaction_type:
    //                             values?.transaction_type && values?.transaction_type
    //                                 ? values?.transaction_type
    //                                 : "",
    //                         payment_method:
    //                             values?.check_amount && values?.check_amount > 0 ? "CHK" : "",
    //                         check_number:
    //                             values?.check_number && values?.check_number
    //                                 ? values?.check_number
    //                                 : "",
    //                         check_date:
    //                             values?.check_date && values?.check_date
    //                                 ? values?.check_date
    //                                 : "",
    //                         check_amount:
    //                             values?.check_amount && values?.check_amount
    //                                 ? parseFloat(values?.check_amount).toFixed(2)
    //                                 : "",
    //                         remaining_balance: parseFloat(
    //                             values?.remaining_balance ? values?.remaining_balance : 0
    //                         )
    //                             ? parseFloat(
    //                                 values?.remaining_balance ? values?.remaining_balance : 0
    //                             ).toFixed(2)
    //                             : parseFloat(0).toFixed(2),
    //                         check_page_number:
    //                             values?.check_page_number && values?.check_page_number
    //                                 ? values?.check_page_number
    //                                 : "",

    //                         payee_id:
    //                             values?.payee_id && values?.payee_id != ""
    //                                 ? values?.payee_id
    //                                 : "",
    //                         payee_name:
    //                             values?.payee_name && values?.payee_name != ""
    //                                 ? values?.payee_name
    //                                 : "",
    //                         payee_npi:
    //                             values?.payee_npi && values?.payee_npi != ""
    //                                 ? values?.payee_npi
    //                                 : "",
    //                         payee_address:
    //                             values?.payee_address && values?.payee_address != ""
    //                                 ? values?.payee_address
    //                                 : "",
    //                         payee_city:
    //                             values?.payee_city && values?.payee_city != ""
    //                                 ? values?.payee_city
    //                                 : "",
    //                         payee_state:
    //                             values?.payee_state && values?.payee_state != ""
    //                                 ? values?.payee_state
    //                                 : "",
    //                         payee_zip:
    //                             values?.payee_zip && values?.payee_zip != ""
    //                                 ? values?.payee_zip
    //                                 : "",

    //                         payer_id:
    //                             values?.payer_id && values?.payer_id != ""
    //                                 ? values?.payer_id
    //                                 : "",
    //                         payer_name:
    //                             values?.payer_name && values?.payer_name != ""
    //                                 ? values?.payer_name
    //                                 : "",
    //                         payer_address:
    //                             values?.payer_address && values?.payer_address != ""
    //                                 ? values?.payer_address
    //                                 : "",
    //                         payer_city:
    //                             values?.payer_city && values?.payer_city != ""
    //                                 ? values?.payer_city
    //                                 : "",
    //                         payer_state:
    //                             values?.payer_state && values?.payer_state != ""
    //                                 ? values?.payer_state
    //                                 : "",
    //                         payer_zip:
    //                             values?.payer_zip && values?.payer_zip != ""
    //                                 ? values?.payer_zip
    //                                 : "",

    //                         // DBK
    //                         dbk_check_number:
    //                             values?.check_number && values?.check_number
    //                                 ? values?.check_number
    //                                 : "",
    //                         dbk_check_date:
    //                             values?.check_date && values?.check_date
    //                                 ? values?.check_date
    //                                 : "",
    //                         dbk_check_amount:
    //                             values?.check_amount && values?.check_amount
    //                                 ? parseFloat(values?.check_amount).toFixed(2)
    //                                 : "",
    //                         dbk_payee_name: values?.payee_name ? values?.payee_name : "",
    //                         dbk_payee_npi: values?.payee_npi ? values?.payee_npi : "",
    //                         dbk_payee_address: values?.payee_address
    //                             ? values?.payee_address
    //                             : "",
    //                         dbk_payee_city: values?.payee_city ? values?.payee_city : "",
    //                         dbk_payee_state: values?.payee_state ? values?.payee_state : "",
    //                         dbk_payee_zip: values?.payee_zip ? values?.payee_zip : "",

    //                         dbk_payer_name: values?.payer_name ? values?.payer_name : "",
    //                         dbk_payer_address: values?.payer_address
    //                             ? values?.payer_address
    //                             : "",
    //                         dbk_payer_city: values?.payer_city ? values?.payer_city : "",
    //                         dbk_payer_state: values?.payer_state ? values?.payer_state : "",
    //                         dbk_payer_zip: values?.payer_zip ? values?.payer_zip : "",

    //                         plb_adjustments: plb_adjustments,
    //                         claims: claims,

    //                         // claims: claims,
    //                     });
    //                     // claims: claims,
    //                     // });

    //                     setcheckNum(values?.check_page_number);
    //                 }, 10);
    //                 // setTimeout(() => {

    //                 //     formEob.validateForm()
    //                 // }, 20);
    //             } else if (
    //                 state.Payment835Lite ||
    //                 values?.transaction_type == "PAYMENT835_LITE"
    //             ) {
    //                 var payment_method = "";
    //                 if (values?.check_amount == parseFloat(0).toFixed(2)) {
    //                     payment_method = "NON";
    //                 } else if (parseFloat(values?.check_amount) > parseFloat(0)) {
    //                     payment_method = "CHK";
    //                 } else {
    //                     payment_method = "ACH";
    //                 }

    //                 var claims = values.claims;

    //                 if (values.claims?.length > 0) {
    //                     claims.map((claim, index) => {
    //                         claim.claim_id = claim?.claim_id ? claim?.claim_id : "";
    //                         claim.order = index ? index : "";
    //                         claim.patient_id = claim?.patient_id ? claim?.patient_id : "";
    //                         claim.patient_account_number = claim?.patient_account_number
    //                             ? claim?.patient_account_number
    //                             : "";
    //                         claim.patient_first_name = claim?.patient_first_name
    //                             ? claim?.patient_first_name
    //                             : "";
    //                         claim.patient_middle_name = claim?.patient_middle_name
    //                             ? claim?.patient_middle_name
    //                             : "";
    //                         claim.patient_last_name = claim?.patient_last_name
    //                             ? claim?.patient_last_name
    //                             : "";

    //                         claim.page_from = claim.page_from ? claim.page_from : "1";
    //                         claim.page_to = claim.page_to ? claim.page_to : "1";

    //                         claim.service_lines.service_line_id = claim.service_lines
    //                             .service_line_id
    //                             ? claim.service_lines.service_line_id
    //                             : "";
    //                         claim.service_lines.period_start = moment(
    //                             claim.service_lines.period_start
    //                         ).format("MM/DD/YYYY")
    //                             ? moment(claim.service_lines.period_start).format("MM/DD/YYYY")
    //                             : "";
    //                         claim.service_lines.period_end = moment(
    //                             claim.service_lines.period_end
    //                         ).format("MM/DD/YYYY")
    //                             ? moment(claim.service_lines.period_end).format("MM/DD/YYYY")
    //                             : "";
    //                         claim.service_lines.billed = parseFloat(
    //                             claim.service_lines.billed
    //                         ).toFixed(2)
    //                             ? parseFloat(claim.service_lines.billed).toFixed(2)
    //                             : "";
    //                         claim.service_lines.paid = parseFloat(
    //                             claim.service_lines.paid
    //                         ).toFixed(2)
    //                             ? parseFloat(claim.service_lines.paid).toFixed(2)
    //                             : "";
    //                     });
    //                 } else {
    //                     claims = eobLiteFormik.initialValues.claims;
    //                 }

    //                 setTimeout(() => {
    //                     eobLiteFormik.setValues({
    //                         ...eobLiteFormik.values,

    //                         job: jobData?.job,
    //                         client: values?.client,
    //                         check_number: values?.check_number,
    //                         payment_id: values?.payment_id,
    //                         payment_method: payment_method,
    //                         check_date: values?.check_date,
    //                         check_amount: values?.check_amount,
    //                         dbk_check_amount: values?.check_amount,

    //                         dbk_check_number: values?.check_number,
    //                         dbk_check_date: values?.check_date,

    //                         dbk_payer_name: values?.payer_name,
    //                         dbk_check_amount: values?.check_amount,

    //                         payer_id: values?.payer_id,
    //                         payer_name: values?.payer_name,

    //                         payee_id: values?.payee_id,
    //                         payee_name: values?.payee_name,

    //                         claims: claims,
    //                     });
    //                 }, 10);
    //             } else if (
    //                 state.PatientPay ||
    //                 values?.transaction_type == "PATIENT_PAY"
    //             ) {
    //                 setTimeout(() => {

    //                     formPP.setValues({
    //                         ...formPP.values,
    //                         check_number: values?.check_number,
    //                         check_date: values?.check_date,
    //                         check_amount: values?.check_amount,
    //                         transaction_type: values?.transaction_type,
    //                         job: jobData?.job,
    //                         payment_id: values?.payment_id ? values?.payment_id : "",

    //                         patient_account_number: values?.patient_account_number ? values?.patient_account_number : "",
    //                         patient_first_name: values?.patient_first_name ? values?.patient_first_name : "",
    //                         patient_middle_name: values?.patient_middle_name ? values?.patient_middle_name : "",
    //                         patient_last_name: values?.patient_last_name ? values?.patient_last_name : "",
    //                         amount_due: values?.amount_due ? values?.amount_due : "",
    //                         statement_amount: values?.statement_amount ? values?.statement_amount : "",
    //                         statement_date: values?.statement_date ? values?.statement_date : "",

    //                         dbk_check_number: values?.check_number ? values?.check_number : "",
    //                         dbk_check_date: values?.check_date ? values?.check_date : "",
    //                         dbk_check_amount: values?.check_amount ? values?.check_amount : "",
    //                         dbk_patient_account_number: values?.patient_account_number ? values?.patient_account_number : "",
    //                         dbk_patient_first_name: values?.patient_first_name ? values?.patient_first_name : "",
    //                         dbk_patient_middle_name: values?.patient_middle_name ? values?.patient_middle_name : "",
    //                         dbk_patient_last_name: values?.patient_last_name ? values?.patient_last_name : "",
    //                         dbk_amount_due: values?.amount_due ? values?.amount_due : "",
    //                         dbk_statement_amount: values?.statement_amount ? values?.statement_amount : "",
    //                         dbk_statement_date: values?.statement_date ? values?.statement_date : "",
    //                     });
    //                 }, 200);

    //             } else if (
    //                 state.Correspondence ||
    //                 values?.transaction_type == "CORRESPONDENCE"
    //             ) {
    //                 var claims = values?.claims;
    //                 values?.claims && values?.claims?.length > 0
    //                     ? values?.claims?.map((claim, index) => {
    //                         claim.claim_id = claim && claim?.claim_id;
    //                         claim.order = claim && claim?.claim_id;
    //                         claim.patient_account_number =
    //                             claim && claim?.patient_account_number;
    //                         claim.period_start =
    //                             claim && moment(claim?.period_start).format("MM/DD/YYYY");
    //                         claim.period_end =
    //                             claim && moment(claim?.period_end).format("MM/DD/YYYY");
    //                         claim.billed = claim && claim?.billed;
    //                         claim.patient_id = claim && claim?.patient_id;
    //                         claim.patient_first_name = claim && claim?.patient_first_name;
    //                         claim.patient_middle_name = claim && claim?.patient_middle_name;
    //                         claim.patient_last_name = claim && claim?.patient_last_name;
    //                         claim.page_from = claim && claim?.page_from;
    //                         claim.page_to = claim && claim?.page_to;
    //                     })
    //                     : (claims = formCorres.initialValues.claims);
    //                 setTimeout(() => {

    //                     formCorres.setValues({
    //                         ...formCorres.values,
    //                         job: jobData?.job,
    //                         payment_id: values?.payment_id ? values?.payment_id : null,
    //                         payer_name: values?.payer_name ? values?.payer_name : null,
    //                         letter_date:
    //                             values && values?.check_date
    //                                 ? values?.check_date
    //                                 : values.letter_date
    //                                     ? values?.letter_date
    //                                     : null,
    //                         correspondance:
    //                             values && values?.correspondance ? values?.correspondance : null,
    //                         transaction_type: "CORRESPONDENCE",
    //                         claims: claims,
    //                     });
    //                 }, 200);

    //             }
    //         }
    //     });
    // };

    const clearEob835Form = async () => {

        // setTimeout(() => {

        //     resetClaimData()

        // }, 30);

        // await formEob.handleReset();


        await formEob.setValues({
            ...formEob.values,
            rejection_reason: "",

            // payment_id: "",
            transaction_type: "PAYMENT835",
            check_number: "",
            check_date: "",
            check_amount: "",
            remaining_balance: "",
            check_page_number: "",

            data_837: [],

            payee_id: "",
            payee_name: "",
            payee_npi: "",
            payee_address: "",
            payee_city: "",
            payee_state: "",
            payee_zip: "",

            payer_id: "",
            payer_name: "",
            payer_address: "",
            payer_city: "",
            payer_state: "",
            payer_zip: "",

            // DBF
            dbk_check_number: "",
            dbk_check_date: "",
            dbk_check_amount: "",

            dbk_payee_name: "",
            dbk_payee_npi: "",
            dbk_payee_address: "",
            dbk_payee_city: "",
            dbk_payee_state: "",
            dbk_payee_zip: "",

            dbk_payer_name: "",
            dbk_payer_address: "",
            dbk_payer_city: "",
            dbk_payer_state: "",
            dbk_payer_zip: "",

            // plb_adjustments: [
            // {
            //     plb_adjustment_id: "",
            //     npi: "",
            //     plb_page: "",
            //     reason: "",
            //     patient_account_number: "",
            //     plb_amt: ""
            // }
            // ],
            claims: [
                {
                    patient: true,
                    subscriber: true,
                    rendering_provider: true,

                    order: "",
                    color_index: "",

                    claim_id: "",
                    patient_account_number: "",
                    payer_claim_number: "",
                    period_start: "",
                    period_end: "",
                    status_code: "",
                    page_from: "1",
                    page_to: "1",

                    claim_billed: parseFloat(0).toFixed(2),
                    claim_discount: parseFloat(0).toFixed(2),
                    claim_allowed: parseFloat(0).toFixed(2),
                    claim_deduct: parseFloat(0).toFixed(2),
                    claim_coins: parseFloat(0).toFixed(2),
                    claim_copay: parseFloat(0).toFixed(2),
                    claim_cob: parseFloat(0).toFixed(2),
                    claim_patres: parseFloat(0).toFixed(2),
                    claim_paid: parseFloat(0).toFixed(2),
                    claim_balance: parseFloat(0).toFixed(2),

                    sl_billed: parseFloat(0).toFixed(2),
                    sl_discount: parseFloat(0).toFixed(2),
                    sl_allowed: parseFloat(0).toFixed(2),
                    sl_deduct: parseFloat(0).toFixed(2),
                    sl_coins: parseFloat(0).toFixed(2),
                    sl_copay: parseFloat(0).toFixed(2),
                    sl_cob: parseFloat(0).toFixed(2),
                    sl_patres: parseFloat(0).toFixed(2),
                    sl_amount: parseFloat(0).toFixed(2),
                    sl_paid: parseFloat(0).toFixed(2),
                    sl_balance: parseFloat(0).toFixed(2),
                    sl_total: parseFloat(0).toFixed(2),

                    color_data: [],

                    patient_id: "",
                    patient_first_name: "",
                    patient_middle_name: "",
                    patient_last_name: "",
                    patient_identifier_code: "",

                    subscriber_id: "",
                    subscriber_first_name: "",
                    subscriber_middle_name: "",
                    subscriber_last_name: "",
                    subscriber_identifier_code: "",

                    rendering_provider_id: "",
                    rendering_provider_first_name: "",
                    rendering_provider_last_name: "",
                    rendering_provider_identifier_code: "",

                    // DBK
                    dbk_patient_account_number: "",
                    dbk_payer_claim_number: "",

                    dbk_patient_first_name: "",
                    dbk_patient_middle_name: "",
                    dbk_patient_last_name: "",
                    dbk_patient_identifier_code: "",

                    dbk_subscriber_first_name: "",
                    dbk_subscriber_middle_name: "",
                    dbk_subscriber_last_name: "",
                    dbk_subscriber_identifier_code: "",

                    dbk_rendering_provider_first_name: "",
                    dbk_rendering_provider_last_name: "",
                    dbk_rendering_provider_identifier_code: "",

                    claim_adjustments: [
                        {
                            pc: "",
                            group_code: "",
                            reason_code: "",
                            rmk: "",
                            amount: "",
                            claim_adjustment_id: "",
                        },
                    ],

                    service_lines: [
                        {
                            color_index: "",
                            order: "",
                            service_line_id: "",
                            from_date: "",
                            to_date: "",
                            code: "",
                            procedure_type: "HC",
                            modifier1: "",
                            modifier2: "",
                            modifier3: "",
                            modifier4: "",
                            rev_code: "",
                            units: "",
                            patres: "",
                            billed: "",
                            discount: "",
                            allowed: "",
                            deduct: "",
                            coins: "",
                            copay: "",
                            cob: "",
                            paid: "",
                            color_data: [],

                            balance: "",
                            adjustments: [
                                {
                                    adjustment_id: "",
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                },
                            ],
                        },
                    ],
                },
            ],
        });
        setClaim835Index(0)


        // setTimeout(() => {

        //     formEob.validateForm();
        // }, 50);
        // }, 200);


        // await formEob.resetForm();
        // await formEob.validateForm();
        // formEob.setValues({



        //     transaction_type: "PAYMENT835",
        //     payment_method: "",
        //     check_number: "",
        //     check_date: "",
        //     check_amount: "",
        //     remaining_balance: "",
        //     check_page_number: "",

        //     data_837: [],

        //     payee_id: "",
        //     payee_name: "",
        //     payee_npi: "",
        //     payee_address: "",
        //     payee_city: "",
        //     payee_state: "",
        //     payee_zip: "",

        //     payer_id: "",
        //     payer_name: "",
        //     payer_address: "",
        //     payer_city: "",
        //     payer_state: "",
        //     payer_zip: "",

        //     // DBF
        //     dbk_check_number: "",
        //     dbk_check_date: "",
        //     dbk_check_amount: "",

        //     dbk_payee_name: "",
        //     dbk_payee_npi: "",
        //     dbk_payee_address: "",
        //     dbk_payee_city: "",
        //     dbk_payee_state: "",
        //     dbk_payee_zip: "",

        //     dbk_payer_name: "",
        //     dbk_payer_address: "",
        //     dbk_payer_city: "",
        //     dbk_payer_state: "",
        //     dbk_payer_zip: "",

        //     plb_adjustments: [
        //         // {
        //         //     plb_adjustment_id: "",
        //         //     npi: "",
        //         //     plb_page: "",
        //         //     reason: "",
        //         //     patient_account_number: "",
        //         //     plb_amt: ""
        //         // }
        //     ],
        //     claims: [{

        //         patient: true,
        //         subscriber: true,
        //         rendering_provider: true,

        //         order: "",
        //         color_index: "",

        //         claim_id: "",
        //         patient_account_number: "",
        //         payer_claim_number: "",
        //         period_start: "",
        //         period_end: "",
        //         status_code: "",
        //         page_from: "1",
        //         page_to: "1",

        //         claim_billed: "0.00",
        //         claim_discount: "0.00",
        //         claim_allowed: "0.00",
        //         claim_deduct: "0.00",
        //         claim_coins: "0.00",
        //         claim_copay: "0.00",
        //         claim_cob: "0.00",
        //         claim_patres: "0.00",
        //         claim_paid: "0.00",
        //         claim_balance: "0.00",

        //         sl_billed: "0.00",
        //         sl_discount: "0.00",
        //         sl_allowed: "0.00",
        //         sl_deduct: "0.00",
        //         sl_coins: "0.00",
        //         sl_copay: "0.00",
        //         sl_cob: "0.00",
        //         sl_patres: "0.00",
        //         sl_amount: "0.00",
        //         sl_paid: "0.00",
        //         sl_balance: "0.00",
        //         sl_total: "0.00",

        //         color_data: [],

        //         patient_id: "",
        //         patient_first_name: "",
        //         patient_middle_name: "",
        //         patient_last_name: "",
        //         patient_identifier_code: "",

        //         subscriber_id: "",
        //         subscriber_first_name: "",
        //         subscriber_middle_name: "",
        //         subscriber_last_name: "",
        //         subscriber_identifier_code: "",

        //         rendering_provider_id: "",
        //         rendering_provider_first_name: "",
        //         rendering_provider_last_name: "",
        //         rendering_provider_identifier_code: "",

        //         // DBK
        //         dbk_patient_account_number: "",
        //         dbk_payer_claim_number: "",

        //         dbk_patient_first_name: "",
        //         dbk_patient_middle_name: "",
        //         dbk_patient_last_name: "",
        //         dbk_patient_identifier_code: "",

        //         dbk_subscriber_first_name: "",
        //         dbk_subscriber_middle_name: "",
        //         dbk_subscriber_last_name: "",
        //         dbk_subscriber_identifier_code: "",

        //         dbk_rendering_provider_first_name: "",
        //         dbk_rendering_provider_last_name: "",
        //         dbk_rendering_provider_identifier_code: "",

        //         claim_adjustments: [{
        //             pc: "",
        //             group_code: "",
        //             reason_code: "",
        //             rmk: "",
        //             amount: "",
        //             claim_adjustment_id: ""
        //         }],

        //         service_lines: [{
        //             color_index: "",
        //             order: "",
        //             service_line_id: "",
        //             from_date: "",
        //             to_date: "",
        //             code: "",
        //             procedure_type: "HC",
        //             modifier1: "",
        //             modifier2: "",
        //             modifier3: "",
        //             modifier4: "",
        //             rev_code: "",
        //             units: "",
        //             patres: "",
        //             billed: "",
        //             discount: "",
        //             allowed: "",
        //             deduct: "",
        //             coins: "",
        //             copay: "",
        //             cob: "",
        //             paid: "",
        //             color_data: [],

        //             balance: "",
        //             adjustments: [{
        //                 adjustment_id: "",
        //                 pc: "",
        //                 group_code: "",
        //                 reason_code: "",
        //                 rmk: "",
        //                 amount: "",
        //             }],
        //         }]
        //     }],
        // })
        // }


    };



    // -------------------------------------------- ADJUSTMENT PROP CODE REASON CODE PAYER HANDLE FUNCTION --------------------------------------------//

    const [pcPopup, setPcPopup] = useState(false);
    // const [pcError, setPcError] = useState(false);

    const [propCodeData, setPropCodeData] = useState({
        index: "",
        serviceIndex: "",
        adjustmentIndex: "",
    });

    const handleDoubleClickServiceLineAdjustment = async (dict, e, index, serviceIndex, adjustmentIndex) => {
        if (formEob.values.payer_id != '' && formEob.values.payer_id || formEob.values.payer_name && formEob.values.payer_name != "") {

            setPropCodeData({
                index: index,
                serviceIndex: serviceIndex,
                adjustmentIndex: adjustmentIndex,
            });
            setIsDrag(false);
            setPcPopup(true)
            await formRc.setValues({
                ...formRc.values,
                rarc: dict?.rmk ? dict?.rmk : "",
                group_code: dict?.group_code,
                amount: dict?.amount,
                description: dict?.description,
                carc: dict?.reason_code,
                reason_code: dict?.pc,

            });


        }
    }

    const handleDoubleClickClaimAdjustment = async (dict, e, index, adjIndex) => {
        if (formEob.values.payer_id || formEob.values.payer_name) {
            setPropCodeClaimData({
                index: index,
                adjustmentIndex: adjIndex,
            });
            setIsDrag(false);
            setPcClaimPopup(true)
            await formClaimRc.setValues({
                ...formClaimRc.values,
                rarc: dict?.rmk ? dict?.rmk : "",
                group_code: dict?.group_code,
                amount: dict?.amount,
                description: dict?.description,
                carc: dict?.reason_code,
                reason_code: dict?.pc,

            });
        }
    }

    const handlePayerPcVerify = (e, index, serviceIndex, adjustmentIndex) => {
        const event = e.target.value

        if (event?.length >= 1 && (e.keyCode === 9 || e.keyCode === 37 || e.keyCode === 39)) {

            if (formEob.values?.payer_id || formEob.values?.payer_name) {
                const payer_id = formEob.values?.payer_id;
                const reasoncodes = event ? event?.toUpperCase() : "";

                verifyPayerRc(
                    reasoncodes,
                    payer_id
                )((response) => {
                    if (response?.status && response?.status_code == 200) {
                        setPcPopup(false);
                        // setPcError(false);
                        setIsDrag(true);
                        insertPropCodeFormik(
                            response?.data,
                            index,
                            serviceIndex,
                            adjustmentIndex,
                        );
                        setPropCodeData({
                            index: index,
                            serviceIndex: serviceIndex,
                            adjustmentIndex: adjustmentIndex,
                        });
                    } else {
                        formRc.validateForm();
                        if (formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex] && formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].description != '') {
                            if (event) {
                                formRc.setValues({
                                    description: formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].description ? formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].description : "",
                                    carc: formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].reason_code ? formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].reason_code : "",
                                    rarc: formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].rmk ? formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].rmk : "",
                                    group_code: formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].group_code ? formEob?.values?.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].group_code : "",
                                    reason_code: event
                                })
                            }
                        }
                        else {
                            if (event) {
                                formRc.setValues({
                                    description: "",
                                    carc: "",
                                    rarc: "",
                                    group_code: "",
                                    reason_code: event
                                })
                            }
                        }
                        setPcClaimPopup(false);
                        setPcPopup(true);
                        setx12Codes({
                            open: false, data: ""
                        })
                        // setPcError(true);
                        setIsDrag(false);
                        setPropCodeData({
                            index: index,
                            serviceIndex: serviceIndex,
                            adjustmentIndex: adjustmentIndex,
                        });
                        let errors = {};

                        if (errors.claims) {
                            if (errors.claims[index]) {
                                if (errors.claims[index].service_lines) {
                                    if (errors.claims[index].service_lines[serviceIndex]) {
                                        if (errors.claims[index].service_lines[serviceIndex].adjustments) {
                                            if (errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex]) {
                                                errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].pc = "REASON CODE ERROR"
                                            } else {
                                                errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex] = {};
                                                errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].pc = "REASON CODE ERROR"
                                            }
                                        } else {
                                            errors.claims[index].service_lines[serviceIndex].adjustments = [];
                                            errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex] = {};
                                            errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].pc = "REASON CODE ERROR"
                                        }
                                    } else {
                                        errors.claims[index].service_lines[serviceIndex] = {};
                                        errors.claims[index].service_lines[serviceIndex].adjustments = [];
                                        errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex] = {};
                                        errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].pc = "REASON CODE ERROR"
                                    }
                                } else {
                                    errors.claims[index].service_lines = [];
                                    errors.claims[index].service_lines[serviceIndex] = {};
                                    errors.claims[index].service_lines[serviceIndex].adjustments = [];
                                    errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex] = {};
                                    errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].pc = "REASON CODE ERROR";
                                }
                            }
                        }

                        else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].service_lines = [];
                            errors.claims[index].service_lines[serviceIndex] = {};
                            errors.claims[index].service_lines[serviceIndex].adjustments = [];
                            errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex] = {};
                            errors.claims[index].service_lines[serviceIndex].adjustments[adjustmentIndex].pc = "REASON CODE ERROR";
                        }

                        formEob.setErrors({
                            ...formEob.errors,
                            errors: errors,
                        })
                    }
                });
            }
        } else {
            setPcPopup(false);
            setx12Codes({
                open: false, data: ""
            })
        }
    };

    const [x12Codes, setx12Codes] = useState({ open: false, data: "" });

    const insertPropCodeFormik = async (
        values,
        index,
        serviceIndex,
        adjustmentIndex
    ) => {
        if (values?.x12codes?.length > 1) {
            setPcClaimPopup(false);
            setPcPopup(false)
            setx12ClaimCodes({
                open: false,
                data: ""
            })

            setx12Codes({
                open: true,
                reason_code: values?.reason_code?.toUpperCase(),
                data: values?.x12codes,
                index: index,
                serviceIndex: serviceIndex,
                adjustmentIndex: adjustmentIndex,
            });

            setIsDrag(false);
        } else {

            setx12Codes({
                open: false,
                data: ""
            })
            await formEob.setValues((prevState) => {
                const claims = formEob.values?.claims;

                claims[index].service_lines[serviceIndex].adjustments[
                    adjustmentIndex
                ].pc = claims[index].service_lines[serviceIndex].adjustments[
                    adjustmentIndex
                ].pc;
                claims[index].service_lines[serviceIndex].adjustments[
                    adjustmentIndex
                ].reason_code = values?.x12codes[0]?.carc;
                claims[index].service_lines[serviceIndex].adjustments[
                    adjustmentIndex
                ].rmk = values?.x12codes[0]?.rarc;
                claims[index].service_lines[serviceIndex].adjustments[
                    adjustmentIndex
                ].group_code = values?.x12codes[0]?.group_code;

                claims[index].service_lines[serviceIndex].adjustments[
                    adjustmentIndex
                ].description = values?.x12codes[0]?.description;


                return {
                    ...prevState,
                    claims: claims,
                }
            });
            setTimeout(() => {
                handlePatresCalculation()
            }, 200);
        }
    };

    const handleMultiplePropCode = async (
        code,
        x12Index,
        index,
        serviceIndex,
        adjustmentIndex
    ) => {
        const claims = formEob.values?.claims;

        claims[x12Codes.index].service_lines[x12Codes.serviceIndex].adjustments[
            x12Codes.adjustmentIndex
        ].pc = x12Codes?.reason_code ? x12Codes?.reason_code?.toUpperCase() : "";
        claims[x12Codes.index].service_lines[x12Codes.serviceIndex].adjustments[
            x12Codes.adjustmentIndex
        ].reason_code = x12Codes?.data[x12Index]?.carc ? x12Codes?.data[x12Index]?.carc?.toUpperCase() : "";
        claims[x12Codes.index].service_lines[x12Codes.serviceIndex].adjustments[
            x12Codes?.adjustmentIndex
        ].rmk = x12Codes.data[x12Index]?.rarc ? x12Codes.data[x12Index]?.rarc?.toUpperCase() : "";
        claims[x12Codes.index].service_lines[x12Codes.serviceIndex].adjustments[
            x12Codes.adjustmentIndex
        ].group_code = x12Codes.data[x12Index]?.group_code;

        claims[x12Codes.index].service_lines[x12Codes.serviceIndex].adjustments[
            x12Codes.adjustmentIndex
        ].description = x12Codes.data[x12Index]?.description;

        await formEob
            .setValues({
                ...formEob.values,
                claims: claims,
            })
            .then(() => {
                setx12Codes({ open: false, data: "" });
                setIsDrag(true)
            });


        setTimeout(() => {
            handlePatresCalculation()
        }, 200);
    };

    const [pcClaimPopup, setPcClaimPopup] = useState(false);

    // const [pcError, setPcError] = useState(false);
    const [propCodeClaimData, setPropCodeClaimData] = useState({
        index: "",
        adjustmentIndex: "",
    });

    const handleClaimPayerPcVerify = (e, index, adjustmentIndex) => {
        const event = e.target.value
        const isShwiftKey = (e.keyCode == 9 && e.shiftKey)
        if (event?.length >= 1 && (e.keyCode === 9 || e.keyCode === 37 || e.keyCode === 39)) {

            if (formEob.values?.payer_id || formEob.values?.payer_name) {
                const payer_id = formEob.values?.payer_id;
                const reasoncodes = event ? event?.toUpperCase() : "";

                verifyPayerRc(
                    reasoncodes,
                    payer_id
                )((response) => {
                    if (response?.status && response?.status_code == 200) {
                        setPcClaimPopup(false);
                        // setPcError(false);
                        setIsDrag(true);

                        insertClaimPropCodeFormik(
                            response?.data,
                            index,
                            adjustmentIndex,
                            isShwiftKey
                        );
                    } else {
                        if (event) {
                            formClaimRc.setValues({
                                description: "",
                                carc: "",
                                rarc: "",
                                group_code: "",
                                reason_code: event
                            })
                        }

                        setPcClaimPopup(true);
                        setx12ClaimCodes({
                            open: false,
                            data: ""
                        })
                        setPcPopup(false);
                        setx12Codes({
                            open: false, data: ""
                        })

                        // setPcError(true);
                        setIsDrag(false);
                        setPropCodeClaimData({
                            index: index,
                            adjustmentIndex: adjustmentIndex,
                        });
                        let errors = {};

                        if (errors.claims) {
                            if (errors.claims[index]) {
                                if (errors.claims[index].claim_adjustments) {
                                    if (errors.claims[index].claim_adjustments[adjustmentIndex]) {
                                        errors.claims[index].claim_adjustments[adjustmentIndex].pc = "REASON CODE ERROR"
                                    } else {
                                        errors.claims[index].claim_adjustments[adjustmentIndex] = {};
                                        errors.claims[index].claim_adjustments[adjustmentIndex].pc = "REASON CODE ERROR"
                                    }
                                } else {
                                    errors.claims[index].claim_adjustments = [];
                                    errors.claims[index].claim_adjustments[adjustmentIndex] = {};
                                    errors.claims[index].claim_adjustments[adjustmentIndex].pc = "REASON CODE ERROR";
                                }
                            }
                        }

                        else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].claim_adjustments = [];
                            errors.claims[index].claim_adjustments[adjustmentIndex] = {};
                            errors.claims[index].claim_adjustments[adjustmentIndex].pc = "REASON CODE ERROR";
                        }

                        formEob.setErrors({
                            ...formEob.errors,
                            errors: errors.claims[index].claim_adjustments[adjustmentIndex].pc,
                        })
                    }
                });
            }
        } else {
            setPcClaimPopup(false);
            setx12ClaimCodes({
                open: false,
                data: ""
            })
        }
    };

    const [x12ClaimCodes, setx12ClaimCodes] = useState({ open: false, data: "" });

    const insertClaimPropCodeFormik = async (
        values,
        index,
        adjustmentIndex,
        isShiftKey = false
    ) => {

        if (values?.x12codes?.length > 1) {

            setPcClaimPopup(false);
            setPcPopup(false);
            setx12Codes({
                open: false,
                data: ""
            })

            setx12ClaimCodes({
                open: true,
                reason_code: values?.reason_code,
                data: values?.x12codes,
                index: index,
                adjustmentIndex: adjustmentIndex,
            });

            setIsDrag(false);
        } else {
            setx12ClaimCodes({
                open: false,
                data: ""
            })
            const claims = formEob.values?.claims;
            claims[index].claim_adjustments[adjustmentIndex].pc = claims[index].claim_adjustments[adjustmentIndex].pc;
            claims[index].claim_adjustments[adjustmentIndex].reason_code = values?.x12codes[0]?.carc;
            claims[index].claim_adjustments[adjustmentIndex].rmk = values?.x12codes[0]?.rarc;
            claims[index].claim_adjustments[adjustmentIndex].group_code = values?.x12codes[0]?.group_code;
            claims[index].claim_adjustments[adjustmentIndex].description = values?.x12codes[0]?.description;

            await formEob.setValues({
                ...formEob.values,
                claims: claims,
            });
            setTimeout(() => {
                if (!isShiftKey) {
                    if (values?.x12codes[0]?.rarc == '') {
                        var rmkField = document.querySelector(`[name="claims[${index}].claim_adjustments[${adjustmentIndex}].rmk"]`);
                        if (rmkField) {
                            rmkField.focus()
                        }
                    } else {
                        var rmkField = document.querySelectorAll(`[name="claims[${index}].claim_adjustments[${adjustmentIndex + 1}].amount"]`);
                        var rmkField = document.querySelector(`[name="claims[${index}].claim_adjustments[${adjustmentIndex + 1}].amount"]`);
                        if (rmkField) {
                            rmkField.focus()
                        } else {
                            const aditionalInfoButton = document.getElementById("additional-btn");
                            aditionalInfoButton.focus()
                        }
                    }
                }
                handleClaimPatresCalculation()
            }, 200);
        }
    };

    const handleMultipleClaimPropCode = async (
        code,
        x12Index,
        index,
        adjustmentIndex
    ) => {
        const claims = formEob.values?.claims;
        claims[x12ClaimCodes.index].claim_adjustments[x12ClaimCodes.adjustmentIndex].pc = x12ClaimCodes?.reason_code;
        claims[x12ClaimCodes.index].claim_adjustments[x12ClaimCodes.adjustmentIndex].reason_code = x12ClaimCodes.data[x12Index]?.carc;
        claims[x12ClaimCodes.index].claim_adjustments[x12ClaimCodes.adjustmentIndex].rmk = x12ClaimCodes.data[x12Index]?.rarc;
        claims[x12ClaimCodes.index].claim_adjustments[x12ClaimCodes.adjustmentIndex].group_code = x12ClaimCodes.data[x12Index]?.group_code;
        claims[x12ClaimCodes.index].claim_adjustments[x12ClaimCodes.adjustmentIndex].description = x12ClaimCodes.data[x12Index]?.description;

        if (x12ClaimCodes.data[x12Index]?.rarc == '') {
            var rmkField = document.querySelector(`[name="claims[${index}].claim_adjustments[${adjustmentIndex}].rmk"]`);
            if (rmkField) {
                rmkField.focus()
            }
        } else {
            var rmkField = document.querySelector(`[name="claims[${index}].claim_adjustments[${adjustmentIndex + 1}].amount"]`);
            if (rmkField) {
                rmkField.focus()
            } else {
                const aditionalInfoButton = document.getElementById("additional-btn");
                aditionalInfoButton.focus()
            }
        }
        await formEob
            .setValues({
                ...formEob.values,
                claims: claims,
            })
            .then(() => setx12ClaimCodes({ open: false, data: "" }));

        setTimeout(() => {
            handleClaimPatresCalculation()
        }, 200);
    };

    // ====================================  EOB 835 FORM ENDS ======================================//


    // ====================================  EOB LITE FORM STARTS ======================================*\\
    const validationLiteSchema = Yup.object().shape({
        service_lines: Yup.object().shape({
            period_start: Yup.date(),
            period_end: Yup.date(),
        }),
    });

    const eobLiteFormik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            job: "",
            client: "",
            payment_id: "",
            transaction_type: "PAYMENT835_LITE",
            payment_method: "",
            remaining_balance: "",
            check_number: "",
            check_date: "",
            check_amount: "",
            payer_id: "",
            payer_name: "",
            payee_id: "",
            payee_name: "",

            grid_customization: "NORMAL",

            qdx_status: "",
            qdx_reason: "",
            qdx_classification: "",

            // dbk fields
            dbk_check_number: "",
            dbk_check_date: "",
            dbk_check_amount: "",
            dbk_payer_name: "",
            dbk_payee_name: "",
            payee_page_from: "1",
            payee_page_to: "1",
            payer_page_from: "1",
            payer_page_to: "1",

            plb_adjustments: [

            ],


            claims: [
                {
                    claim_id: "",
                    order: "",
                    corrected_claim: false,
                    patient_id: "",
                    patient_account_number: "",
                    patient_first_name: "",
                    patient_middle_name: "",
                    patient_last_name: "",
                    page_from: "1",
                    page_to: "1",
                    service_lines: {
                        service_line_id: "",
                        period_start: "",
                        period_end: "",
                        billed: "",
                        paid: "",
                    },

                    dbk_patient_account_number: "",
                    dbk_patient_first_name: "",
                    dbk_patient_middle_name: "",
                    dbk_patient_last_name: "",
                },
            ],
        },

        validate: (values) => {
            let errors = {};
            if (values?.qdx_status != "INCOMPLETE") {
                let total_paid = parseFloat(0).toFixed(2);
                if (values.payment_method == "0") {
                    errors.payment_method = "select an option";
                }
                if (values.check_number == "") {
                    errors.check_number = "Required";
                }
                if (values?.check_amount == parseFloat(0).toFixed(2) && values?.payment_method != "NON") {
                    errors.check_amount = "Invalid Selection";
                    errors.payment_method = "Invalid Selection";

                }
                if (parseFloat(values?.check_amount).toFixed(2) > parseFloat(0).toFixed(2) && values?.payment_method != "CHK" && values?.payment_method != "ACH" && values?.payment_method != "VCP") {
                    errors.check_amount = "Invalid Selection";
                    errors.payment_method = "Invalid Selection";

                }
                if (!moment(values.check_date, "MM/DD/YYYY", true).isValid() || !moment(values.check_date, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                    errors.check_date = "Invalid date format";
                }
                if (values.check_amount < 0) {
                    errors.check_amount = "negative value";

                }
                if (
                    parseFloat(values.remaining_balance).toFixed(2) !=
                    parseFloat(0).toFixed(2)
                ) {
                    errors.remaining_balance = "Remaining balance must be zero";
                }
                if (totalPages > 0) {
                    if (isPayerPage) {

                        if (
                            values?.payer_page_from == 0 ||
                            values?.payer_page_from == "" ||
                            values?.payer_page_from > totalPages ||
                            values?.payer_page_from > values?.payer_page_to
                        ) {
                            errors.payer_page_from = "Invalid Page Number";
                            errors.payer_page_to = "Invalid Page Number";
                        }
                        if (values?.payer_page_to == 0 ||
                            values?.payer_page_to == "" ||
                            values?.payer_page_to > totalPages ||
                            values?.payer_page_from > values?.payer_page_to) {

                            errors.payer_page_from = "Invalid Page Number";
                            errors.payer_page_to = "Invalid Page Number";
                        }
                    }
                    if (isPayeePage) {

                        if (values?.payee_page_from == 0 ||
                            values?.payee_page_from == "" ||
                            values?.payee_page_from > totalPages ||
                            values?.payee_page_from > values?.payee_page_to
                        ) {
                            errors.payee_page_from = "Invalid Page Number";
                            errors.payee_page_to = "Invalid Page Number";
                        }
                        if (values?.payee_page_to == 0 ||
                            values?.payee_page_to == "" ||
                            values?.payee_page_to > totalPages ||
                            values?.payee_page_from > values?.payee_page_to) {

                            errors.payee_page_from = "Invalid Page Number";
                            errors.payee_page_to = "Invalid Page Number";
                        }
                    }
                }
                if (values.plb_adjustments && values.plb_adjustments.length > 0) {
                    values.plb_adjustments?.forEach((plb, index) => {
                        if (values.plb_adjustments[index].reason == "0") {
                            if (errors && errors.plb_adjustments) {
                                if (errors.plb_adjustments[index]) {
                                    errors.plb_adjustments[index].reason = "select a valid option";
                                } else {
                                    errors.plb_adjustments[index] = {};
                                    errors.plb_adjustments[index].reason = "select a valid option";
                                }
                            } else {
                                errors.plb_adjustments = [];
                                errors.plb_adjustments[index] = {};
                                errors.plb_adjustments[index].reason = "select a valid option";
                            }
                        }
                        if (
                            values.plb_adjustments[index].plb_amt == "" ||
                            values.plb_adjustments[index].plb_amt == null ||
                            values.plb_adjustments[index].plb_amt == parseFloat(0)
                        ) {
                            if (errors && errors.plb_adjustments) {
                                if (errors.plb_adjustments[index]) {
                                    errors.plb_adjustments[index].plb_amt = "select a valid option";
                                } else {
                                    errors.plb_adjustments[index] = {};
                                    errors.plb_adjustments[index].plb_amt = "select a valid option";
                                }
                            } else {
                                errors.plb_adjustments = [];
                                errors.plb_adjustments[index] = {};
                                errors.plb_adjustments[index].plb_amt = "select a valid option";
                            }
                        }
                    });
                }

                if (isAdditional) {
                    if (isAdditionalInfoOptions?.status && values?.qdx_status == "") {
                        errors.qdx_status = "Required"
                        errors.qdx_reason = "Required"
                    }

                    if (isAdditionalInfoOptions?.status && isAdditionalInfoOptions?.reason && values?.qdx_status == "INCOMPLETE" && values?.qdx_reason == "") {
                        errors.qdx_reason = "Required"
                    }
                }
            }
            if (values?.grid_customization != 'PLB_ONLY') {
                values?.claims?.forEach((claim, index) => {
                    if (values?.qdx_status != "INCOMPLETE") {

                        if (claim?.service_lines?.billed == '') {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].service_lines.billed = "Required";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].service_lines = {};
                                    errors.claims[index].service_lines.billed = "Required";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].service_lines = {};
                                errors.claims[index].service_lines.billed = "Required";
                            }
                        }
                        if (claim?.service_lines?.paid == '') {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].service_lines.paid = "Required";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].service_lines = {};
                                    errors.claims[index].service_lines.paid = "Required";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].service_lines = {};
                                errors.claims[index].service_lines.paid = "Required";
                            }
                        }
                        if (!moment(claim?.service_lines?.period_start, "MM/DD/YYYY", true).isValid() || !moment(claim?.service_lines?.period_start, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].service_lines.period_start = "1";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].service_lines = {};
                                    errors.claims[index].service_lines.period_start = "2";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].service_lines = {};
                                errors.claims[index].service_lines.period_start = "3";
                            }
                        }

                        if (!moment(claim?.service_lines?.period_end, "MM/DD/YYYY", true).isValid() || !moment(claim?.service_lines?.period_end, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].service_lines.period_end = "4";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].service_lines = {};
                                    errors.claims[index].service_lines.period_end = "5";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].service_lines = {};
                                errors.claims[index].service_lines.period_end = "6";
                            }
                        }

                        if (!moment(claim?.service_lines?.period_end, "MM/DD/YYYY").isSameOrAfter(claim?.service_lines?.period_start)) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].service_lines.period_end = "7";
                                    errors.claims[index].service_lines.period_start = "8";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].service_lines = {};
                                    errors.claims[index].service_lines.period_end = "9";
                                    errors.claims[index].service_lines.period_start = "10";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].service_lines = {};
                                errors.claims[index].service_lines.period_end = "11";
                                errors.claims[index].service_lines.period_start = "12";
                            }
                        }
                        if (totalPages > 0) {
                            if (
                                claim?.page_from == 0 ||
                                claim?.page_from == "" ||
                                claim?.page_to == "" ||
                                claim?.page_to == 0 ||
                                claim?.page_from > totalPages ||
                                parseInt(claim?.page_from) > parseInt(claim?.page_to)
                            ) {
                                if (errors && errors.claims) {
                                    if (errors.claims[index]) {
                                        errors.claims[index].page_from = "1";
                                        errors.claims[index].page_to = "2";
                                    } else {
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "3";
                                        errors.claims[index].page_to = "4";
                                    }
                                } else {
                                    errors.claims = [];
                                    errors.claims[index] = {};
                                    errors.claims[index].page_from = "5";
                                    errors.claims[index].page_to = "6";
                                }
                            }
                            if (
                                claim?.page_from == 0 ||
                                claim?.page_from == "" ||
                                claim?.page_to == 0 ||
                                claim?.page_to == "" ||
                                claim?.page_to > totalPages ||
                                parseInt(claim?.page_from) > parseInt(claim?.page_to)
                            ) {
                                if (errors && errors.claims) {
                                    if (errors.claims[index]) {
                                        errors.claims[index].page_from = "11";
                                        errors.claims[index].page_to = "12";
                                    } else {
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "13";
                                        errors.claims[index].page_to = "14";
                                    }
                                } else {
                                    errors.claims = [];
                                    errors.claims[index] = {};
                                    errors.claims[index].page_from = "15";
                                    errors.claims[index].page_to = "16";
                                }
                            }
                        }

                        if (
                            claim.patient_account_number == "" || claim.patient_account_number == undefined

                        ) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].patient_account_number = "Required";
                                    errors.claims[index].dbk_patient_account_number = "Required";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].patient_account_number = "Required";
                                    errors.claims[index].dbk_patient_account_number = "Required";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].patient_account_number = "Required";
                                errors.claims[index].dbk_patient_account_number = "Required";
                            }
                        }

                    }

                    // DBK VALIDATION START HERE
                    if (
                        String(claim?.dbk_patient_first_name).toUpperCase() != String(claim?.patient_first_name).toUpperCase() &&
                        isDbkPermission?.payment_lite?.paymentlite_patient_first_name
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_first_name = "Dont Match";
                                errors.claims[index].dbk_patient_first_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_first_name = "Dont Match";
                                errors.claims[index].dbk_patient_first_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_first_name = "Dont Match";
                            errors.claims[index].dbk_patient_first_name = "Dont Match";
                        }
                    }

                    if (
                        String(claim?.dbk_patient_middle_name).toUpperCase() != String(claim?.patient_middle_name).toUpperCase() &&
                        isDbkPermission?.payment_lite?.paymentlite_patient_middle_name
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_middle_name = "Dont Match";
                                errors.claims[index].dbk_patient_middle_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_middle_name = "Dont Match";
                                errors.claims[index].dbk_patient_middle_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_middle_name = "Dont Match";
                            errors.claims[index].dbk_patient_middle_name = "Dont Match";
                        }
                    }


                    if (
                        String(claim?.dbk_patient_last_name).toUpperCase() != String(claim?.patient_last_name).toUpperCase() &&
                        isDbkPermission?.payment_lite?.paymentlite_patient_last_name
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_last_name = "Dont Match";
                                errors.claims[index].dbk_patient_last_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_last_name = "Dont Match";
                                errors.claims[index].dbk_patient_last_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_last_name = "Dont Match";
                            errors.claims[index].dbk_patient_last_name = "Dont Match";
                        }
                    }
                    if (
                        String(claim?.dbk_patient_account_number).toUpperCase() != String(claim.patient_account_number).toUpperCase() &&
                        isDbkPermission?.payment_lite?.paymentlite_patient_acc_number
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_account_number = "Dont Match";
                                errors.claims[index].dbk_patient_account_number = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_account_number = "Dont Match";
                                errors.claims[index].dbk_patient_account_number = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};

                            errors.claims[index].patient_account_number = "Dont Match";
                            errors.claims[index].dbk_patient_account_number = "Dont Match";
                        }
                    }

                });
            }

            // DBK VALIDATION START
            if (
                values.check_number != values.dbk_check_number &&
                isDbkPermission?.payment_lite?.paymentlite_check_number
            ) {
                errors.check_number = "Dont Match";
            }


            if (
                values.check_date.replace("__/__/____", "") !=
                values.dbk_check_date.replace("__/__/____", "") &&
                isDbkPermission?.payment_lite?.paymentlite_check_date
            ) {
                errors.check_date = "Dont Match";
            }
            if (
                String(values.check_amount).replace(NaN, "") !=
                String(values.dbk_check_amount).replace(NaN, "") &&
                isDbkPermission?.payment_lite?.paymentlite_check_amount
            ) {
                errors.check_amount = "Dont Match";
            }


            checkDbkValidation(values.payer_name, values.dbk_payer_name, isDbkPermission?.payment_lite?.paymentlite_payer_name) ? errors.payer_name = "Dont Match" : null
            checkDbkValidation(values.payee_name, values.dbk_payee_name, isDbkPermission?.payment_lite?.paymentlite_payee_name) ? errors.payee_name = "Dont Match" : null

            return errors;
        },

        validationSchema: Yup.object().shape({
            remaining_balance: Yup.number(),
            check_date: Yup.date(),
            check_amount: isIncomplete ? Yup.number() : Yup.number().required("Required"),
            payer_name: isIncomplete ? Yup.string() : Yup.string(),
            payee_name: isIncomplete ? Yup.string() : Yup.string(),

            plb_adjustments: Yup.array().of(
                Yup.object().shape({
                    npi: isIncomplete ? Yup.string() : Yup.string()
                        .matches(/^[0-9]+$/, "Must be only digits")
                        .min(10, "only 10 Digits")
                        .max(10, "only 10 Digits"),
                    reason: isIncomplete ? Yup.string() : Yup.string(),
                    plb_amt: isIncomplete ? Yup.string() : Yup.string().matches(
                        /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/,
                        "Must be only digits"
                    ),
                })
            ),
            claims: Yup.array().of(
                Yup.object().shape({
                    patient_first_name: isIncomplete ? Yup.string() : Yup.string().matches(
                        /([a-zA-Z])/,
                        "Enter a valid name"
                    ),
                    patient_middle_name: isIncomplete ? Yup.string() : Yup.string().matches(
                        /([a-zA-Z])/,
                        "Enter a valid name"
                    ),
                    patient_last_name: isIncomplete ? Yup.string() : Yup.string().matches(
                        /([a-zA-Z])/,
                        "Enter a valid name"
                    ),
                    from: Yup.string(),
                    to: Yup.string(),
                    // service_lines: Yup.object().shape({
                    //     period_start: Yup.date(),
                    //     period_end: Yup.date(),
                    //     billed: isIncomplete ? Yup.string() : Yup.string(),
                    //     paid: isIncomplete ? Yup.string() : Yup.string(),
                    // }),
                })
            ),
        }),

        onSubmit: (values, { setSubmitting }) => {

            setSubmitting(true);
            const old_data = jobData?.grid_data;
            const qa_corrected_data = {};
            if (old_data?.payment_id && window.location.pathname == QA_REVIEW) {
                const propertiesToCheck = [
                    'payment_method',
                    'check_number',
                    'check_date',
                    'check_amount',
                    'payee_name',
                    'payer_name',
                    'payee_page_from',
                    'payee_page_to',
                    'payer_page_from',
                    'payer_page_to',


                ];

                propertiesToCheck.forEach((property) => {

                    if (old_data?.transaction_type != "PAYMENT835_LITE") {
                        qa_corrected_data[property] = true;

                    }
                    else if (property == "check_amount") {
                        qa_corrected_data[property] = parseFloat(old_data?.[property]).toFixed(2) != parseFloat(values?.[property]).toFixed(2) ? true : false;
                    }

                    else {

                        qa_corrected_data[property] = old_data?.[property]?.toString().toUpperCase() != values?.[property]?.toString().toUpperCase() ? true : false;
                    }
                });

                qa_corrected_data.is_transaction_type_changed = old_data?.transaction_type != "PAYMENT835_LITE" ? true : old_data?.grid_customization != values?.grid_customization ? true : false
                qa_corrected_data.overall_page_from = !jobData?.is_large_batch_job ? old_data?.overall_page_from?.toString().toUpperCase() != overallPage?.from?.toString().toUpperCase() : false;
                qa_corrected_data.overall_page_to = !jobData?.is_large_batch_job ? old_data?.overall_page_to?.toString().toUpperCase() != overallPage?.to?.toString().toUpperCase() : false;



                if (isAdditional) {

                    const AdditionalPropertiesToCheck = [
                        'qdx_status',
                        // 'qdx_reason',
                        'qdx_classification',


                    ];
                    AdditionalPropertiesToCheck.forEach((property) => {
                        qa_corrected_data[property] = old_data?.[property] != values?.[property] ? true : false;
                    });
                    const array1 = old_data?.qdx_reasons ? old_data?.qdx_reasons : [];
                    const array2 = aditionalInfoFormik.values?.reason ? aditionalInfoFormik.values?.reason : [];
                    const array1sort = array1.sort()
                    const array2sort = array2.sort()
                    if (JSON.stringify(array1sort) != JSON.stringify(array2sort)) {
                        qa_corrected_data.qdx_reasons = true;
                    }
                    else {
                        qa_corrected_data.qdx_reasons = false;
                    }
                    qa_corrected_data.qdx_reason = false;
                    qa_corrected_data.is_orphaned_check = old_data?.is_orphaned_check != aditionalInfoFormik?.values?.is_orphaned_check ? true : false;
                    qa_corrected_data.is_missing_check_image = old_data?.is_missing_check_image != aditionalInfoFormik?.values?.is_missing_check_image ? true : false;
                    qa_corrected_data.is_forced_balancing = old_data?.is_forced_balancing != aditionalInfoFormik?.values?.is_forced_balancing ? true : false;
                    qa_corrected_data.is_payer_black_listing = old_data?.is_payer_black_listing != aditionalInfoFormik?.values?.is_payer_black_listing ? true : false;
                    qa_corrected_data.is_payer_excluding_indexing = old_data?.is_payer_excluding_indexing != aditionalInfoFormik?.values?.is_payer_excluding_indexing ? true : false;
                }

                qa_corrected_data.plb_adjustments = [];
                if (values?.plb_adjustments && values?.plb_adjustments?.length > 0) {
                    values?.plb_adjustments?.map((plb_adjustment, index) => {
                        if (old_data?.transaction_type != "PAYMENT835_LITE") {
                            qa_corrected_data?.plb_adjustments.push({
                                npi: true,
                                plb_page: true,
                                reason: true,
                                patient_account_number: true,
                                plb_amt: true,
                            })
                        } else if (plb_adjustment?.corrected_plb) {
                            qa_corrected_data?.plb_adjustments.push({
                                npi: true,
                                plb_page: true,
                                reason: true,
                                patient_account_number: true,
                                plb_amt: true,
                            })
                        } else {

                            qa_corrected_data?.plb_adjustments.push({
                                npi: old_data.plb_adjustments[index]?.npi != plb_adjustment?.npi ? true : false,
                                plb_page: old_data.plb_adjustments[index]?.plb_page != plb_adjustment?.plb_page ? true : false,
                                reason: old_data.plb_adjustments[index]?.reason != plb_adjustment?.reason ? true : false,
                                patient_account_number: old_data.plb_adjustments[index]?.patient_account_number != plb_adjustment?.patient_account_number ? true : false,
                                plb_amt: old_data?.plb_adjustments[index]?.plb_amt != plb_adjustment?.plb_amt ? true : false,

                            })
                        }
                    })
                }
                if (values?.grid_customization != "PLB_ONLY" && values?.claims && values?.claims?.length > 0) {

                    qa_corrected_data.claims = values?.claims?.map((claim, index) => {
                        let claimData = {};
                        if (old_data?.transaction_type != "PAYMENT835_LITE") {
                            claimData = {
                                patient_account_number: true,
                                patient_first_name: true,
                                patient_middle_name: true,
                                patient_last_name: true,
                                page_from: true,
                                page_to: true,
                                service_lines: {
                                    period_start: true,
                                    period_end: true,
                                    billed: true,
                                    paid: true,
                                },
                            };

                            return claimData;
                        } else if (claim.corrected_claim) {

                            claimData = {
                                patient_account_number: true,
                                patient_first_name: true,
                                patient_middle_name: true,
                                patient_last_name: true,
                                page_from: true,
                                page_to: true,
                                service_lines: {
                                    period_start: true,
                                    period_end: true,
                                    billed: true,
                                    paid: true,
                                },
                            };

                            return claimData;
                        } else {
                            const claim_field = old_data?.claims[claim?.claim_corrected_index ? claim?.claim_corrected_index : claim?.claim_corrected_index == 0 ? claim?.claim_corrected_index : index]
                            claimData = {
                                patient_account_number: claim_field?.patient_account_number != claim?.patient_account_number ? true : false,
                                patient_first_name: claim_field?.patient_first_name != claim?.patient_first_name ? true : false,
                                patient_middle_name: claim_field?.patient_middle_name != claim?.patient_middle_name ? true : false,
                                patient_last_name: claim_field?.patient_last_name != claim?.patient_last_name ? true : false,
                                page_from: claim_field?.page_from != claim?.page_from ? true : false,
                                page_to: claim_field?.page_to != claim?.page_to ? true : false,

                                service_lines: {
                                    period_start: !(!claim_field?.service_lines?.period_start && !claim?.service_lines?.period_start) && claim_field?.service_lines?.period_start != claim?.service_lines?.period_start ? true : false,
                                    period_end: !(!claim_field?.service_lines?.period_end && !claim?.service_lines?.period_end) && claim_field?.service_lines?.period_end != claim?.service_lines?.period_end ? true : false,
                                    billed: parseFloat(claim_field?.service_lines?.billed ? claim_field?.service_lines?.billed : 0).toFixed(2) != parseFloat(claim?.service_lines?.billed ? claim?.service_lines?.billed : 0).toFixed(2) ? true : false,
                                    paid: parseFloat(claim_field?.service_lines?.paid ? claim_field?.service_lines?.paid : 0).toFixed(2) != parseFloat(claim?.service_lines?.paid ? claim?.service_lines?.paid : 0).toFixed(2) ? true : false,
                                },
                            }
                            return claimData;
                        }
                    });
                }
                const { deleted_claims, deleted_service_lines, deleted_plb, deleted_claim_adjustments, deleted_service_adjustments } = getOldGridDataCount(old_data, values);
                qa_corrected_data.deleted_claims = deleted_claims
                qa_corrected_data.deleted_service_lines = deleted_service_lines
                qa_corrected_data.deleted_plb = deleted_plb
                qa_corrected_data.deleted_claim_adjustments = deleted_claim_adjustments
                qa_corrected_data.deleted_service_adjustments = deleted_service_adjustments

            }

            // =================QA ERROR MODAL======================
            const isShowQaErrorModal = window.location.pathname == QA_REVIEW && showQaErrorModal(qa_corrected_data, jobData?.grid_data?.qa_corrected_data) && !qaErrorModalSave
            if (isShowQaErrorModal) {
                setQaErrorModal(true);
                setSubmitting(false)
                return;
            }
            qa_corrected_data.qa_error_type = qaErrorformik?.values?.qa_error_type ? qaErrorformik?.values?.qa_error_type : []
            qa_corrected_data.qa_error_type_comment = qaErrorformik?.values?.qa_error_type_comment
            closeQaErrorModal()
            // =======================================
            // ================CALLING NEXT JOB=======================
            if (!localStorage.getItem("SAVE_EXIT")) {
                // setJobData("no data")
                setLoadData(true)
            }
            setformApiLoaded(true)
            // =======================
            const data = {
                job: jobData && jobData.job,
                grid_customization: values.grid_customization,
                transaction_type: "PAYMENT835_LITE",
                payment_method: values.payment_method == "0" && isIncomplete ? "" : values.payment_method,
                qa_corrected_data: qa_corrected_data,
                work_type: window.location.pathname == DATA_ENTRY ? 1 : window.location.pathname == QA_REVIEW ? 2 : "",

                qdx_status: values?.qdx_status ? values?.qdx_status == "0" ? "" : values?.qdx_status : "",
                qdx_reasons: values?.qdx_reason.length > 0 ? values?.qdx_reason?.map((i) => i.value) : [],
                qdx_reason: values?.qdx_reason ? values?.qdx_reason == "0" ? "" : values?.qdx_reason : "",
                qdx_classification: values?.qdx_classification ? values?.qdx_classification == "0" ? "" : values?.qdx_classification : "",
                is_orphaned_check: values?.is_orphaned_check ? true : false,
                is_missing_check_image: values?.is_missing_check_image ? true : false,
                is_forced_balancing: values?.is_forced_balancing ? true : false,
                is_payer_black_listing: values?.is_payer_black_listing ? true : false,
                is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? true : false,


                overall_page_from: isOverallPage && overallPage?.from ? overallPage?.from : "",
                overall_page_to: isOverallPage && overallPage?.to ? overallPage?.to : "",


                check_number: values.check_number ? values.check_number.toUpperCase() : "",
                remaining_balance: values.remaining_balance,
                check_date: values.check_date,
                check_amount: values.check_amount,
                payer_name: values.payer_name ? values.payer_name.toUpperCase() : "",
                payer_page_from: values.payer_page_from ? values.payer_page_from : '',
                payer_page_to: values.payer_page_to ? values.payer_page_to : '',
                payee_name: values.payee_name ? values.payee_name.toUpperCase() : "",
                payee_page_from: values.payee_page_from ? values.payee_page_from : '',
                payee_page_to: values.payee_page_to ? values.payee_page_to : '',
                payment_id: values.payment_id ? values.payment_id : "",
                claims: [],
                plb_adjustments: [],
            };
            if (values?.plb_adjustments) {
                values?.plb_adjustments?.map((plb, index) => {

                    data?.plb_adjustments.push({
                        plb_adjustment_id: plb.plb_adjustment_id ? plb.plb_adjustment_id : "",
                        npi: plb.npi ? plb.npi : "",
                        plb_page: plb.plb_page ? plb.plb_page : "",
                        reason: plb.reason ? plb.reason : "",
                        plb_amt: plb.plb_amt ? plb.plb_amt : "",
                        patient_account_number: plb.patient_account_number ? plb.patient_account_number.toUpperCase() : "",
                    })
                })
            }

            if (values.payer_id) {
                data.payer_id = values.payer_id;
            }

            if (values.payee_id) {
                data.payee_id = values.payee_id;
            }
            if (data.grid_customization != "PLB_ONLY") {
                if (values?.claims) {
                    values?.claims &&
                        values?.claims?.map((claim, index) => {
                            data?.claims.push({
                                order: index + 1,
                                corrected_claim: claim?.corrected_claim ? claim?.corrected_claim : false,
                                patient_account_number: claim?.patient_account_number ? claim?.patient_account_number.toUpperCase() : "",
                                page_from: claim?.page_from,
                                page_to: claim?.page_to,
                                patient_first_name: claim?.patient_first_name ? claim?.patient_first_name.toUpperCase() : "",
                                patient_middle_name: claim?.patient_middle_name ? claim?.patient_middle_name.toUpperCase() : "",
                                patient_last_name: claim?.patient_last_name ? claim?.patient_last_name.toUpperCase() : "",
                                service_lines: {
                                    period_start: claim?.service_lines?.period_start,
                                    period_end: claim?.service_lines?.period_end,
                                    billed: claim?.service_lines?.billed,
                                    paid: claim?.service_lines?.paid,
                                },
                            });
                            if (claim?.claim_id) data.claims[index].claim_id = claim?.claim_id;
                            if (claim?.service_lines?.service_line_id)
                                data.claims[index].service_lines.service_line_id =
                                    claim?.service_lines?.service_line_id;
                            if (claim?.patient_id)
                                data.claims[index].patient_id = claim?.patient_id;
                        });
                }
            } else {
                data.claims = []
            }
            values.status = ""
            data.grid_total_fields_count = getTotalFieldsCount(eobLiteFormik)

            const RedirectId = getRedirectTabId()
            createUpdate835Lite(data)((response) => {
                if (response?.status && response?.data) {
                    setformApiLoaded(false)
                    setSaveSuccess(true)
                    localStorage.removeItem('grid_data')
                    localStorage.removeItem('grid_state_data')
                    localStorage.removeItem('grid_qdx_data')
                    localStorage.removeItem('grid_overall_data')
                    if (localStorage.getItem("SAVE_EXIT")) {
                        localStorage.removeItem("SAVE_EXIT");
                        localStorage.removeItem('grid_data')
                        localStorage.removeItem('grid_state_data')
                        localStorage.removeItem('grid_qdx_data')
                        localStorage.removeItem('grid_overall_data')
                        setToast({ status: true, type: "success", message: "Success" });
                        handleNavigateDashBoard(RedirectId)
                    } else {

                        localStorage.removeItem('transaction_type')
                        // window.location.reload()
                        setToast({ status: true, type: "success", message: "Success" });

                        setSubmitting(false);
                    }
                }
                else if (response?.status_code == 400 && response?.errors && response?.errors?.assign_user?.length > 0) {
                    setformApiLoaded(false)

                    setErrorAlert({
                        status: true,
                        type: "danger",
                        message: response?.errors?.assign_user[0]
                    })
                    setSubmitting(false);

                }
                else {
                    setErrorJobData(values)
                    setformApiLoaded(false)


                    setToast({ status: true, type: "danger", message: "Failed" });
                    setSubmitting(false);
                }
            });
        },
    });
    const [claimLiteIndex, setClaimLiteIndex] = useState(0);

    const handleNewClaim = async () => {
        eobLiteFormik.setValues(prevValues => {
            const _ = require('lodash');
            const datas = _.cloneDeep(prevValues.claims);


            const new_claim = {
                claim_id: "",
                order: "",
                corrected_claim: true,
                patient_id: "",
                patient_account_number: "",
                patient_first_name: "",
                patient_middle_name: "",
                patient_last_name: "",

                page_from: currentPage + 1,
                page_to: currentPage + 1,
                service_lines: {
                    service_line_id: "",
                    period_start: "",
                    period_end: "",
                    billed: "",
                    paid: "",
                },

                dbk_patient_account_number: "",
                dbk_patient_first_name: "",
                dbk_patient_middle_name: "",
                dbk_patient_last_name: "",
            };

            datas.splice(claimLiteIndex + 1, 0, new_claim);

            return {
                ...prevValues,
                claims: datas,
            };
        });


        setClaimLiteIndex(claimLiteIndex + 1)

        // adding new dbk claim
        addingNewEobLiteClaimDbk()
    }

    const __handleSearchLitePayer = () => {
        setLoading(true);
        // e.preventDefault();
        // setPayerLiteValue(e.target.value);
        if (payerLiteValue.length >= 3) {
            searchPayerLookup({ search: payerLiteValue.toUpperCase(), client: jobData.client_id })((response) => {
                if (!response?.data?.status && response?.status) {
                    setLoading(false);
                    setPayerLiteData(response?.data?.results);
                }
            });
        } else if (payerLiteValue.length < 3) {
            setLoading(false);
            setPayerLiteData()
        }
    };

    const activatePayerLiteLookup = (payer) => {
        checkExcludedPayer(
            eobLiteFormik.values.job,
            payer?.id
        )((response) => {
            if (response?.status) {
                if (response?.is_excluded_payer) {
                    setIsSwal(true);
                } else {
                    eobLiteFormik.setValues({
                        ...eobLiteFormik.values,
                        payer_id: payer?.id ? payer?.id : "",
                        payer_name: payer?.name ? payer?.name?.toUpperCase() : "",

                        // dbK fields
                        dbk_payer_name: payer?.name ? payer?.name?.toUpperCase() : "",
                    });

                    __handlePayerLiteLookupSearch();
                }
            }
        });

    };
    const __handlePayeeLiteLookupSearch = () => {
        setPayeeLiteLookup(!payeeLiteLookup);
        // if (eobLiteFormik?.values?.grid_customization == "PLB_ONLY") {
        //     handleChangeGridPosition({ x: -10, y: 800 });
        // } else {
        handleChangeGridPosition("payer_payee_popup");
        // }
    };
    const __handleSearchLitePayee = () => {
        setLoading(true);
        // e.preventDefault();
        // setPayeeLiteValue(e.target.value);
        if (payeeLiteValue.length >= 3) {
            searchPayeeLookup({ search: payeeLiteValue.toUpperCase(), client: jobData.client_id })((response) => {
                if (!response?.data?.status && response?.status) {
                    setLoading(false);
                    setPayeeLiteData(response?.data?.results);
                }
            });
        }
        else {
            setLoading(false);
            setPayeeLiteData();
        }
    };

    const activatePayeeLiteLookup = (payee) => {
        eobLiteFormik.setValues({
            ...eobLiteFormik.values,
            payee_id: payee?.id ? payee?.id : "",
            payee_name: payee?.name ? payee?.name?.toUpperCase() : "",

            //   DBK
            dbk_payee_name: payee?.name ? payee?.name?.toUpperCase() : "",
        });
        // if (eobLiteFormik?.values?.grid_customization == "PLB_ONLY") {
        //     handleChangeGridPosition({ x: -10, y: 800 });
        // }
        __handlePayeeLiteLookupSearch();
    };
    // ====================================  EOB LITE FORM ENDS ======================================//

    // ====================================  PATIENT_PAY FORM STARTS ======================================*\\

    const formPP = useFormik({
        // enableReinitialize: true,
        initialValues: {
            job: "",
            payment_id: "",
            transaction_type: "PATIENT_PAY",

            patient_page_from: "1",
            patient_page_to: "1",

            check_number: "",
            check_date: "",
            is_check_amount: "",
            check_amount: "",
            patient_account_number: "",
            patient_id: "",
            patient_first_name: "",
            patient_middle_name: "",
            patient_last_name: "",

            amount_due: "",
            statement_amount: "",
            statement_date: "",
            statement_number: "",

            qdx_status: "",
            qdx_reason: "",
            qdx_classification: "",

            // dbk fields
            dbk_check_number: "",
            dbk_check_date: "",
            dbk_check_amount: "",
            dbk_patient_account_number: "",
            dbk_patient_first_name: "",
            dbk_patient_middle_name: "",
            dbk_patient_last_name: "",
            dbk_amount_due: "",
            dbk_statement_amount: "",
            dbk_statement_date: "",
        },
        validate: (values) => {
            let errors = {};
            if (values?.qdx_status != "INCOMPLETE") {
                // Apply validation based on isIncomplete status
                if (!values.check_number) {
                    errors.check_number = 'Required';
                }
                if (!values.check_date) {
                    errors.check_date = 'Required';
                }
                if (!values.check_amount) {
                    errors.check_amount = 'Required';
                }
                if (!values.patient_account_number) {
                    errors.patient_account_number = 'Required';
                }
                if (!values.statement_date) {
                    errors.statement_date = 'Required';
                }
                if (!moment(values.check_date, "MM/DD/YYYY", true).isValid() || !moment(values.check_date, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                    errors.check_date = "Invalid date format";
                }
                if (!moment(values.statement_date, "MM/DD/YYYY", true).isValid() || !moment(values.statement_date, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                    errors.statement_date = "Invalid date format";
                }

                if (values?.is_check_amount != "" && values?.is_check_amount != values?.check_amount) {
                    errors.check_amount = "Invalid check amount";

                } else if (!values.check_amount) {
                    errors.check_amount = "Required";

                }
                if (totalPages > 0) {
                    if (
                        values?.patient_page_from == 0 ||
                        values?.patient_page_from == "" ||
                        values?.patient_page_from > totalPages ||
                        values?.patient_page_from > values?.patient_page_to
                    ) {
                        errors.patient_page_from = "Invalid Page Number";
                        errors.patient_page_to = "Invalid Page Number";
                    }

                    if (values?.patient_page_to == 0 ||
                        values?.patient_page_to == "" ||
                        values?.patient_page_to > totalPages ||
                        values?.patient_page_from > values?.patient_page_to) {

                        errors.patient_page_from = "Invalid Page Number";
                        errors.patient_page_to = "Invalid Page Number";
                    }
                }
                if (isAdditional) {
                    if (isAdditionalInfoOptions?.status && values?.qdx_status == "") {
                        errors.qdx_status = "Required"
                        errors.qdx_reason = "Required"
                    }

                    if (isAdditionalInfoOptions?.status && isAdditionalInfoOptions?.reason && values?.qdx_status == "INCOMPLETE" && values?.qdx_reason == "") {
                        errors.qdx_reason = "Required"
                    }
                }
                // if (totalPages > 0) {
                //     if (isPayerPage) {

                //         if (
                //             values?.page_from == 0 ||
                //             values?.page_from == "" ||
                //             values?.page_from > totalPages ||
                //             values?.page_from > values?.page_to
                //         ) {
                //             errors.page_from = "Invalid Page Number";
                //             errors.page_to = "Invalid Page Number";
                //         }
                //         if (values?.page_to == 0 ||
                //             values?.page_to == "" ||
                //             values?.page_to > totalPages ||
                //             values?.page_from > values?.page_to) {

                //             errors.page_from = "Invalid Page Number";
                //             errors.page_to = "Invalid Page Number";
                //         }
                //     }

                // }
            }
            // DBK VALIDATION START
            checkDbkValidation(values.check_number, values.dbk_check_number, isDbkPermission?.patient_pay?.patientpay_check_number) ? errors.check_number = "Dont Match" : null
            checkDbkValidation(values.statement_date, values.dbk_statement_date, isDbkPermission?.patient_pay?.patientpay_payment_statement_date, 'date') ? errors.statement_date = "Dont Match" : null
            // checkDbkValidation(values.check_amount, values.dbk_check_amount, isDbkPermission?.patient_pay?.patientpay_check_amount) ? errors.check_amount = "Dont Match" : null
            checkDbkValidation(values.patient_account_number, values.dbk_patient_account_number, isDbkPermission?.patient_pay?.patientpay_patient_acc_number) ? errors.patient_account_number = "Dont Match" : null
            checkDbkValidation(values.patient_first_name, values.dbk_patient_first_name, isDbkPermission?.patient_pay?.patientpay_patient_first_name) ? errors.patient_first_name = "Dont Match" : null
            checkDbkValidation(values.patient_middle_name, values.dbk_patient_middle_name, isDbkPermission?.patient_pay?.patientpay_patient_middle_name) ? errors.patient_middle_name = "Dont Match" : null
            checkDbkValidation(values.patient_last_name, values.dbk_patient_last_name, isDbkPermission?.patient_pay?.patientpay_patient_last_name) ? errors.patient_last_name = "Dont Match" : null
            checkDbkValidation(values.amount_due, values.dbk_amount_due, isDbkPermission?.patient_pay?.patientpay_payment_amt_due, 'number') ? errors.amount_due = "Dont Match" : null
            checkDbkValidation(values.statement_number, values.dbk_statement_number, isDbkPermission?.patient_pay?.patientpay_payment_statement_number, 'number') ? errors.statement_number = "Dont Match" : null
            checkDbkValidation(values.statement_amount, values.dbk_statement_amount, isDbkPermission?.patient_pay?.patientpay_payment_statement_amount, 'number') ? errors.statement_amount = "Dont Match" : null
            checkDbkValidation(values.check_date, values.dbk_check_date, isDbkPermission?.patient_pay?.patientpay_check_date, 'date') ? errors.check_date = "Dont Match" : null
            // DBK VALIDATION END
            return errors;
        },

        validationSchema: Yup.object().shape({
            // check_number: isIncomplete ? Yup.string() : Yup.string().required("Required"),
            // check_date: isIncomplete ? Yup.date() : Yup.date().required("Required"),
            // check_amount: isIncomplete ? Yup.string() : Yup.number().required("Required").positive(),
            check_number: Yup.string(),
            check_date: Yup.date(),
            check_amount: Yup.string(),
            patient_account_number: Yup.string(),

            // patient_account_number: isIncomplete ? Yup.string() : Yup.string().required("Required"),
            patient_first_name: Yup.string(),
            patient_middle_name: Yup.string(),
            patient_last_name: Yup.string(),

            amount_due: Yup.string(),
            statement_amount: Yup.string(),
            // statement_date: isIncomplete ? Yup.date() : Yup.date().required("Required"),

            // dbk fields
            //  dbk_check_number: Yup.string(),
            //  dbk_check_date: Yup.string(),
            //  dbk_check_amount: Yup.string(),
            //  dbk_patient_account_number: Yup.string(),
            //  dbk_patient_first_name: Yup.string(),
            //  dbk_patient_middle_name: Yup.string(),
            //  dbk_patient_last_name: Yup.string(),
            //  dbk_amount_due: Yup.string(),
            //  dbk_statement_due: Yup.string(),
            //  dbk_statement_date: Yup.string(),
        }),
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)

            const old_data = jobData?.grid_data;

            const qa_corrected_data = {};
            if (old_data?.payment_id && window.location.pathname == QA_REVIEW) {

                const propertiesToCheck = [
                    'check_number',
                    'check_date',
                    'check_amount',
                    'patient_account_number',
                    'patient_first_name',
                    'patient_middle_name',
                    'patient_last_name',
                    'amount_due',
                    'statement_amount',
                    'statement_date',
                    'statement_number',

                    'patient_page_from',
                    'patient_page_to'


                ];

                propertiesToCheck.forEach((property) => {
                    if (old_data?.transaction_type != "PATIENT_PAY") {
                        qa_corrected_data[property] = true;

                    }
                    else if (property == "check_amount" || property == "amount_due" || property == "statement_amount") {
                        qa_corrected_data[property] = parseFloat(old_data?.[property]).toFixed(2) != parseFloat(values?.[property]).toFixed(2) ? true : false;
                    }
                    else {
                        qa_corrected_data[property] = old_data?.[property]?.toString().toUpperCase() != values?.[property]?.toString().toUpperCase() ? true : false;
                    }
                });
                qa_corrected_data.is_transaction_type_changed = old_data?.transaction_type != "PATIENT_PAY" ? true : false
                qa_corrected_data.overall_page_from = !jobData?.is_large_batch_job ? old_data?.overall_page_from?.toString().toUpperCase() != overallPage?.from?.toString().toUpperCase() : false;
                qa_corrected_data.overall_page_to = !jobData?.is_large_batch_job ? old_data?.overall_page_to?.toString().toUpperCase() != overallPage?.to?.toString().toUpperCase() : false;

                if (isAdditional) {

                    const AdditionalPropertiesToCheck = [
                        'qdx_status',
                        // 'qdx_reason',
                        'qdx_classification',

                    ];
                    AdditionalPropertiesToCheck.forEach((property) => {
                        qa_corrected_data[property] = old_data?.[property] != values?.[property] ? true : false;
                    });
                    const array1 = old_data?.qdx_reasons ? old_data?.qdx_reasons : [];
                    const array2 = aditionalInfoFormik.values?.reason ? aditionalInfoFormik.values?.reason : [];
                    const array1sort = array1.sort()
                    const array2sort = array2.sort()
                    if (JSON.stringify(array1sort) != JSON.stringify(array2sort)) {
                        qa_corrected_data.qdx_reasons = true;
                    }
                    else {
                        qa_corrected_data.qdx_reasons = false;
                    }
                    qa_corrected_data.qdx_reason = false;
                    qa_corrected_data.is_orphaned_check = old_data?.is_orphaned_check != aditionalInfoFormik?.values?.is_orphaned_check ? true : false;
                    qa_corrected_data.is_missing_check_image = old_data?.is_missing_check_image != aditionalInfoFormik?.values?.is_missing_check_image ? true : false;
                    qa_corrected_data.is_forced_balancing = old_data?.is_forced_balancing != aditionalInfoFormik?.values?.is_forced_balancing ? true : false;
                    qa_corrected_data.is_payer_black_listing = old_data?.is_payer_black_listing != aditionalInfoFormik?.values?.is_payer_black_listing ? true : false;
                    qa_corrected_data.is_payer_excluding_indexing = old_data?.is_payer_excluding_indexing != aditionalInfoFormik?.values?.is_payer_excluding_indexing ? true : false;
                }
            }
            // =================QA ERROR MODAL======================
            const isShowQaErrorModal = window.location.pathname == QA_REVIEW && showQaErrorModal(qa_corrected_data, jobData?.grid_data?.qa_corrected_data) && !qaErrorModalSave
            if (isShowQaErrorModal) {
                setQaErrorModal(true);
                setSubmitting(false)
                return;
            }
            qa_corrected_data.qa_error_type = qaErrorformik?.values?.qa_error_type ? qaErrorformik?.values?.qa_error_type : []
            qa_corrected_data.qa_error_type_comment = qaErrorformik?.values?.qa_error_type_comment
            closeQaErrorModal()
            // =======================================
            // ================CALLING NEXT JOB=======================
            setformApiLoaded(true)
            if (!localStorage.getItem("SAVE_EXIT")) {
                // setJobData("no data")
                setLoadData(true)
            }
            // =====================================
            const data1 = {}
            data1.job = values?.job;
            data1.transaction_type = "PATIENT_PAY";
            data1.qa_corrected_data = qa_corrected_data;
            data1.check_number = values?.check_number ? values?.check_number : "";
            data1.work_type = window.location.pathname == DATA_ENTRY ? 1 : window.location.pathname == QA_REVIEW ? 2 : "";
            data1.overall_page_from = isOverallPage && overallPage?.from ? overallPage?.from : "";
            data1.overall_page_to = isOverallPage && overallPage?.to ? overallPage?.to : "";
            data1.qdx_status = values?.qdx_status ? values?.qdx_status == "0" ? "" : values?.qdx_status : "";
            data1.qdx_reasons = values?.qdx_reason.length > 0 ? values?.qdx_reason?.map((i) => i.value) : [];
            data1.qdx_classification = values?.qdx_classification ? values?.qdx_classification == "0" ? "" : values?.qdx_classification : "";
            data1.is_orphaned_check = values?.is_orphaned_check ? true : false;
            data1.is_missing_check_image = values?.is_missing_check_image ? true : false;
            data1.is_forced_balancing = values?.is_forced_balancing ? true : false;
            data1.is_payer_black_listing = values?.is_payer_black_listing ? true : false;
            data1.is_payer_excluding_indexing = values?.is_payer_excluding_indexing ? true : false;
            data1.payment_id = values?.payment_id ? values?.payment_id : "";
            data1.check_date = values?.check_date ? values?.check_date : "";
            data1.check_amount = values?.check_amount !== undefined && values?.check_amount !== null ? values?.check_amount : 0;
            data1.patient_id = values?.patient_id ? values?.patient_id : "";
            data1.patient_account_number = values?.patient_account_number ? values?.patient_account_number.toUpperCase() : "";
            data1.patient_first_name = values?.patient_first_name ? values?.patient_first_name.toUpperCase() : "";
            data1.patient_middle_name = values?.patient_middle_name ? values?.patient_middle_name.toUpperCase() : "";
            data1.patient_last_name = values?.patient_last_name ? values?.patient_last_name.toUpperCase() : "";
            data1.amount_due = values?.amount_due >= 0 ? values?.amount_due : 0;
            data1.statement_amount = values?.statement_amount >= 0 ? values?.statement_amount : 0;
            data1.statement_number = values?.statement_number ? values?.statement_number.toUpperCase() : "";
            data1.statement_date = values?.statement_date ? values?.statement_date : "";
            data1.patient_page_from = values?.patient_page_from ? values?.patient_page_from : "";
            data1.patient_page_to = values?.patient_page_to ? values?.patient_page_to : "";
            values.status = ""
            data1.grid_total_fields_count = getTotalFieldsCount(formPP)

            const RedirectId = getRedirectTabId()
            createOrUpdatePatientPay(data1)((response) => {
                if (response?.status) {
                    setSaveSuccess(true)
                    setformApiLoaded(false)

                    localStorage.removeItem('grid_data')
                    localStorage.removeItem('grid_state_data')
                    localStorage.removeItem('grid_qdx_data')
                    localStorage.removeItem('grid_overall_data')
                    if (localStorage.getItem("SAVE_EXIT")) {
                        localStorage.removeItem("SAVE_EXIT");
                        localStorage.removeItem('grid_data')
                        localStorage.removeItem('grid_state_data')
                        localStorage.removeItem('grid_qdx_data')
                        localStorage.removeItem('grid_overall_data')
                        // navigate(DASHBOARD);
                        setToast({ status: true, type: "success", message: "Success" });
                        handleNavigateDashBoard(RedirectId)
                    } else {

                        // clearFormikData()
                        // getGridData()
                        setToast({ status: true, type: "success", message: "Success" });
                        // resetFormAndInsertData();
                        // window.location.reload()
                        localStorage.removeItem('transaction_type')
                        setSubmitting(false)

                    }
                }
                else if (response?.status_code == 400 && response?.errors && response?.errors?.assign_user?.length > 0) {
                    setformApiLoaded(false)

                    setErrorAlert({
                        status: true,
                        type: "danger",
                        message: response?.errors?.assign_user[0]
                    })
                    setSubmitting(false);

                }
                else {
                    setErrorJobData(values)
                    setformApiLoaded(false)


                    setToast({ status: true, type: "danger", message: "Failed" });
                    setSubmitting(false);
                }
            });
        },
    });


    const clearFormikData = async () => {
        await formPP.handleReset();
        await formPP.validateForm();
    };


    const [isDbkPermission, setisDbkPermission] = useState([]);
    // ====================================  EOB 835 FOCUS STATE & DBK FUNCTIONS START ======================================*\\
    const cleareEobFocus = () => {
        setEobFocus((prevState) => ({
            ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),
            claims: prevState.claims.map((claim) => ({
                ...claim,
                ...Object.fromEntries(Object.keys(claim).map((key) => [key, false])),
            })),
        }));
    };



    const [eobFocus, setEobFocus] = useState({
        check_number: false,
        check_date: false,
        check_amount: false,

        payer_name: false,
        payer_address: false,
        payer_city: false,
        payer_state: false,
        payer_zip: false,

        payee_name: false,
        payee_npi: false,
        payee_address: false,
        payee_city: false,
        payee_state: false,
        payee_zip: false,

        claims: [
            {
                patient_account_number: false,
                payer_claim_number: false,

                patient_first_name: false,
                patient_middle_name: false,
                patient_last_name: false,
                patient_identifier_code: false,

                subscriber_first_name: false,
                subscriber_middle_name: false,
                subscriber_last_name: false,
                subscriber_identifier_code: false,

                rendering_provider_first_name: false,
                rendering_provider_last_name: false,
                rendering_provider_identifier_code: false,

                mrn: false,
                statement_number: false,
                statement_date: false,


                // DBK START
                dbk_patient_account_number: false,
                dbk_payer_claim_number: false,

                dbk_patient_first_name: false,
                dbk_patient_middle_name: false,
                dbk_patient_last_name: false,
                dbk_patient_identifier_code: false,

                dbk_subscriber_first_name: false,
                dbk_subscriber_middle_name: false,
                dbk_subscriber_last_name: false,
                dbk_subscriber_identifier_code: false,

                dbk_rendering_provider_first_name: false,
                dbk_rendering_provider_last_name: false,
                dbk_rendering_provider_identifier_code: false,

                dbk_mrn: false,
                dbk_statement_number: false,
                dbk_statement_date: false
            },
        ],
    });

    const handleFocusEob = (fieldName, formType, climeIndex, isActive) => {
        if (formType == "main" && isActive) {
            setEobFocus((prevState) => ({
                ...Object.fromEntries(
                    Object.keys(prevState).map((key) => [key, false])
                ),
                [fieldName]: true,
                claims: prevState.claims.map((claim) => ({
                    ...claim,
                    ...Object.fromEntries(Object.keys(claim).map((key) => [key, false])),
                })),
            }));
        } else if (formType === "claims" && isActive) {
            setEobFocus((prevState) => {
                return {
                    ...Object.fromEntries(
                        Object.keys(prevState).map((key) => [key, false])
                    ),
                    claims: prevState.claims.map((claim, index) => {
                        if (index === climeIndex) {
                            return {
                                ...Object.fromEntries(
                                    Object.keys(claim).map((key) => [key, false])
                                ),
                                [fieldName]: true,
                            };
                        } else {
                            return {
                                ...Object.fromEntries(
                                    Object.keys(claim).map((key) => [key, false])
                                ),
                            };
                        }
                    }),
                };
            });
        }
    };
    const addingNewEobClaimDbk = () => {
        setEobFocus((prevState) => ({
            ...prevState,
            claims: [
                ...prevState.claims,
                {
                    patient_account_number: false,
                    payer_claim_number: false,

                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,
                    patient_identifier_code: false,

                    subscriber_first_name: false,
                    subscriber_middle_name: false,
                    subscriber_last_name: false,
                    subscriber_identifier_code: false,

                    rendering_provider_first_name: false,
                    rendering_provider_last_name: false,
                    rendering_provider_identifier_code: false,

                    // DBK START
                    dbk_patient_account_number: false,
                    dbk_payer_claim_number: false,

                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                    dbk_patient_identifier_code: false,

                    dbk_subscriber_first_name: false,
                    dbk_subscriber_middle_name: false,
                    dbk_subscriber_last_name: false,
                    dbk_subscriber_identifier_code: false,

                    dbk_rendering_provider_first_name: false,
                    dbk_rendering_provider_last_name: false,
                    dbk_rendering_provider_identifier_code: false,

                    dbk_mrn: false,
                    dbk_statement_number: false,
                    dbk_statement_date: false,
                },
            ],
        }));
    };
    const removeEobClaimDbk = (index) => {
        if (eobLiteFocus.claims.length != 1) {
            setEobFocus((prevState) => ({
                ...prevState,
                claims: prevState.claims.filter((claim, ind) => ind != index),
            }));
        }
    };
    // ====================================  EOB 835 FOCUS STATE & DBK FUNCTIONS END ======================================*\\

    // ====================================  EOB 835 LITE FOCUS STATE & DBK FUNCTIONS START ======================================*\\
    const cleareEobLiteFocus = () => {
        setEobLiteFocus((prevState) => ({
            ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),
            claims: prevState.claims.map((claim) => ({
                ...claim,
                ...Object.fromEntries(Object.keys(claim).map((key) => [key, false])),
            })),
        }));
    };
    const [eobLiteFocus, setEobLiteFocus] = useState({
        payer_name: false,
        payee_name: false,
        check_number: false,
        check_date: false,
        check_amount: false,

        claims: [
            {
                patient_account_number: false,
                patient_first_name: false,
                patient_middle_name: false,
                patient_last_name: false,

                // DBK START
                dbk_patient_account_number: false,
                dbk_patient_first_name: false,
                dbk_patient_middle_name: false,
                dbk_patient_last_name: false,
            },
        ],
    });

    const handleFocusEobLite = (fieldName, formType, climeIndex, isActive) => {
        if (isActive && formType == "main") {
            setEobLiteFocus((prevState) => ({
                ...Object.fromEntries(
                    Object.keys(prevState).map((key) => [key, false])
                ),
                [fieldName]: true,
                claims: prevState.claims.map((claim) => ({
                    ...claim,
                    ...Object.fromEntries(Object.keys(claim).map((key) => [key, false])),
                })),
            }));
        } else if (isActive && formType == "claims") {
            setEobLiteFocus((prevState) => {
                return {
                    ...Object.fromEntries(
                        Object.keys(prevState).map((key) => [key, false])
                    ),
                    claims: prevState.claims.map((claim, index) => {
                        if (index === climeIndex) {
                            return {
                                ...Object.fromEntries(
                                    Object.keys(claim).map((key) => [key, false])
                                ),
                                [fieldName]: true,
                            };
                        } else {
                            return {
                                ...Object.fromEntries(
                                    Object.keys(claim).map((key) => [key, false])
                                ),
                            };
                        }
                    }),
                };
            });
        }
    };
    const addingNewEobLiteClaimDbk = () => {
        setEobLiteFocus((prevState) => ({
            ...prevState,
            claims: [
                ...prevState.claims,
                {
                    patient_account_number: false,
                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,

                    // DBK START
                    dbk_patient_account_number: false,
                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                },
            ],
        }));
    };
    const removeEobLiteClaimDbk = (index) => {
        if (eobLiteFocus.claims.length != 1) {
            setEobLiteFocus((prevState) => ({
                ...prevState,
                claims: prevState.claims.filter((claim, ind) => ind != index),
            }));
        }
    };
    // ====================================  EOB 835 LITE FOCUS STATE & DBK FUNCTIONS END ======================================*\\

    // ====================================  CORRESSPONDENCE FOCUS STATE & DBK FUNCTIONS START ======================================*\\
    const cleareCorresPondanceFocus = () => {

        setCorresPondanceFocus((prevState) => ({
            ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),
            claims: prevState.claims.map((claim) => ({
                ...claim,
                ...Object.fromEntries(Object.keys(claim).map((key) => [key, false])),
            })),
        }));
    };
    const [corresPondanceFocus, setCorresPondanceFocus] = useState({
        payer_name: false,
        check_date: false,

        claims: [
            {
                patient_account_number: false,
                period_start: false,
                period_end: false,
                billed: false,

                patient_first_name: false,
                patient_middle_name: false,
                patient_last_name: false,

                // DBK START
                dbk_payer_name: false,
                dbk_check_date: false,

                dbk_patient_account_number: false,
                dbk_period_start: false,
                dbk_period_end: false,
                dbk_billed: false,

                dbk_patient_first_name: false,
                dbk_patient_middle_name: false,
                dbk_patient_last_name: false,
            },
        ],
    });

    const handleFocusCorresPondance = (fieldName, formType, climeIndex, isActive) => {
        if (isActive && formType == "main") {
            setCorresPondanceFocus((prevState) => ({
                ...Object.fromEntries(
                    Object.keys(prevState).map((key) => [key, false])
                ),
                [fieldName]: true,
                claims: prevState.claims.map((claim) => ({
                    ...claim,
                    ...Object.fromEntries(Object.keys(claim).map((key) => [key, false])),
                })),
            }));
        } else if (isActive && formType == "claims") {
            setCorresPondanceFocus((prevState) => {
                return {
                    ...Object.fromEntries(
                        Object.keys(prevState).map((key) => [key, false])
                    ),
                    claims: prevState.claims.map((claim, index) => {
                        // Set the first claim's patient_account_number field to true
                        // and all other fields to false
                        if (index === climeIndex) {
                            return {
                                ...Object.fromEntries(
                                    Object.keys(claim).map((key) => [key, false])
                                ),
                                [fieldName]: true,
                            };
                        } else {
                            return {
                                ...Object.fromEntries(
                                    Object.keys(claim).map((key) => [key, false])
                                ),
                            };
                        }
                    }),
                };
            });
        }
    };
    const addingNewCorrespondanceClaimDbk = () => {
        setCorresPondanceFocus((prevState) => ({
            ...prevState,
            claims: [
                ...prevState.claims,
                {
                    patient_account_number: false,
                    period_start: false,
                    period_end: false,
                    billed: false,
                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,
                    dbk_payer_name: false,
                    dbk_check_date: false,
                    dbk_patient_account_number: false,
                    dbk_period_start: false,
                    dbk_period_end: false,
                    dbk_billed: false,
                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                },
            ],
        }));
    };
    const removeCorrespondanceClaimDbk = (index) => {
        if (corresPondanceFocus.claims.length != 1) {
            setCorresPondanceFocus((prevState) => ({
                ...prevState,
                claims: prevState.claims.filter((claim, ind) => ind != index),
            }));
        }
    };
    // ====================================  CORRESSPONDENCE FOCUS STATE & DBK FUNCTIONS END ======================================*\\

    // ====================================  PATIENTPAY FOCUS STATE & DBK FUNCTIONS START ======================================*\\
    const [patientPayFocus, setPatientPayFocus] = useState({
        check_number: false,
        check_date: false,
        check_amount: false,
        patient_account_number: false,
        patient_first_name: false,
        patient_middle_name: false,
        patient_last_name: false,
        amount_due: false,
        statement_number: false,
        statement_amount: false,
        statement_date: false,

        // DBK
        dbk_check_number: false,
        dbk_check_date: false,
        dbk_check_amount: false,
        dbk_patient_account_number: false,
        dbk_patient_first_name: false,
        dbk_patient_middle_name: false,
        dbk_patient_last_name: false,
        dbk_amount_due: false,
        dbk_statement_number: false,
        dbk_statement_amount: false,
        dbk_statement_date: false,
    });

    const clearePatientPayFocus = () => {
        setPatientPayFocus((prevState) => ({
            ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),
        }));
    };

    const handleFocusPatientPay = (fieldName, isActive) => {
        if (isActive) {
            setPatientPayFocus((prevState) => ({
                ...Object.fromEntries(Object.keys(prevState).map((key) => [key, false])),
                [fieldName]: true,
            }));
        }
    };
    // ====================================  PATIENTPAY FOCUS STATE & DBK FUNCTIONS END ======================================*\\

    // ====================================  CORRESSPONDENCE FORMIK START ======================================*\\
    const

        formCorres = useFormik({
            // enableReinitialize: true,
            initialValues: {
                job: "",
                rejection_reason: "",
                payment_id: "",
                transaction_type: "CORRESPONDENCE",
                is_first_sub_job: isFirstJob,
                payer_name: "",
                letter_date: "",
                correspondance: "",

                dbk_payer_name: "",
                dbk_letter_date: "",

                qdx_status: "",
                qdx_reason: "",
                qdx_classification: "",
                payer_page_from: "1",
                payer_page_to: "1",

                claims: [
                    {
                        claim_id: "",
                        order: "1",
                        corrected_claim: false,
                        patient_account_number: "",
                        period_start: "",
                        period_end: "",
                        billed: "",

                        patient_id: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",

                        page_from: "1",
                        page_to: "1",

                        dbk_patient_account_number: "",
                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                        dbk_billed: "",
                        dbk_period_start: "",
                        dbk_period_end: "",
                    },
                ],
            },
            validate: (values) => {
                let errors = {};
                if (values?.qdx_status != "INCOMPLETE") {
                    if (!(isSubJob && !isFirstJob)) {
                        if (!values.payer_name) {
                            errors.payer_name = "Required";
                        }
                        if (!values.letter_date) {
                            errors.letter_date = "Required";
                        }
                        if (!values.correspondance) {
                            errors.correspondance = "Required";
                        }
                    }

                    if (!isSubJob) {

                        if (values.correspondance == "0" || values.correspondance == "") {
                            errors.correspondance = "select an option";
                        }
                        if (!moment(values.letter_date, "MM/DD/YYYY", true).isValid() || !moment(values.letter_date, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                            errors.letter_date = "Invalid date format";
                        }
                        checkDbkValidation(values.payer_name, values.dbk_payer_name, isDbkPermission?.correspondence?.correspondence_payer_name) ? errors.payer_name = "Dont Match" : null
                        checkDbkValidation(values.letter_date, values.dbk_letter_date, isDbkPermission?.correspondence?.correspondence_letter_date, 'date') ? errors.letter_date = "Dont Match" : null
                    }

                    if (isAdditional) {
                        if (isAdditionalInfoOptions?.status && values?.qdx_status == "") {
                            errors.qdx_status = "Required"
                            errors.qdx_reason = "Required"
                        }

                        if (isAdditionalInfoOptions?.status && isAdditionalInfoOptions?.reason && values?.qdx_status == "INCOMPLETE" && values?.qdx_reason == "") {
                            errors.qdx_reason = "Required"
                        }
                    }
                    if (totalPages > 0) {
                        if (isPayerPage) {

                            if (
                                values?.payer_page_from == 0 ||
                                values?.payer_page_from == "" ||
                                values?.payer_page_from > totalPages ||
                                values?.payer_page_from > values?.payer_page_to
                            ) {
                                errors.payer_page_from = "Invalid Page Number";
                                errors.payer_page_to = "Invalid Page Number";
                            }
                            if (values?.payer_page_to == 0 ||
                                values?.payer_page_to == "" ||
                                values?.payer_page_to > totalPages ||
                                values?.payer_page_from > values?.payer_page_to) {

                                errors.payer_page_from = "Invalid Page Number";
                                errors.payer_page_to = "Invalid Page Number";
                            }
                        }

                    }
                }

                // DBK VALIDATION END
                values?.claims.forEach((claim, index) => {
                    if (values?.qdx_status != "INCOMPLETE") {

                        if (!moment(claim?.period_start, "MM/DD/YYYY", true).isValid() || !moment(claim?.period_start, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].period_start = "Invalid date";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].period_start = "Invalid date";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].period_start = "Invalid date";
                            }
                        }
                        if (!moment(claim?.period_end, "MM/DD/YYYY", true).isValid() || !moment(claim?.period_end, "MM/DD/YYYY", true).isAfter(minValidDate)) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].period_end = "Invalid date";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].period_end = "Invalid date";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].period_end = "Invalid date";
                            }
                        }
                        if (
                            !moment(claim?.period_end, "MM/DD/YYYY").isSameOrAfter(
                                claim?.period_start
                            )
                        ) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].period_end = "Invalid date";
                                    errors.claims[index].period_start = "Invalid date";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].period_end = "Invalid date";
                                    errors.claims[index].period_start = "Invalid date";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].period_end = "Invalid date";
                                errors.claims[index].period_start = "Invalid date";
                            }
                        }
                        if (totalPages > 0) {

                            if (Number(claim?.page_from) > totalPages || Number(claim?.page_from) > Number(claim?.page_to) || claim?.page_from == "") {


                                if (errors && errors.claims) {
                                    if (errors.claims[index]) {
                                        errors.claims[index].page_from = "Invalid page";
                                        errors.claims[index].page_to = "Invalid page";
                                    } else {
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "Invalid page";
                                        errors.claims[index].page_to = "Invalid page";
                                    }
                                } else {
                                    errors.claims = [];
                                    errors.claims[index] = {};
                                    errors.claims[index].page_from = "Invalid page";
                                    errors.claims[index].page_to = "Invalid page";
                                }
                            }


                            if (Number(claim?.page_to) > totalPages || Number(claim?.page_from) > Number(claim?.page_to) || claim?.page_to == "") {
                                if (errors && errors.claims) {
                                    if (errors.claims[index]) {
                                        errors.claims[index].page_from = "Invalid page";
                                        errors.claims[index].page_to = "Invalid page";
                                    } else {
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "Invalid page";
                                        errors.claims[index].page_to = "Invalid page";
                                    }
                                } else {
                                    errors.claims = [];
                                    errors.claims[index] = {};
                                    errors.claims[index].page_from = "Invalid page";
                                    errors.claims[index].page_to = "Invalid page";
                                }
                            }
                            if (jobData?.is_large_batch_job) {

                                if (window.location.pathname == DATA_ENTRY && !jobData?.is_first_sub_job && jobData?.grid_data?.check_page_number && claim?.page_from == jobData?.grid_data?.check_page_number) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_from = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_from = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "5";
                                    }
                                }

                                if (window.location.pathname == DATA_ENTRY && !jobData?.is_first_sub_job && jobData?.grid_data?.check_page_number && claim?.page_to == jobData?.grid_data?.check_page_number) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_to = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_to = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_to = "5";
                                    }
                                }

                                if (window.location.pathname == DATA_ENTRY && !jobData?.is_first_sub_job && claim?.page_from && jobData?.header_range && jobData?.header_range?.length > 0 && jobData?.header_range?.length + 1 >= claim?.page_from) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_from = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_from = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "5";
                                    }
                                }

                                if (window.location.pathname == DATA_ENTRY && claim?.page_to && !jobData?.is_first_sub_job && jobData?.header_range && jobData?.header_range?.length > 0 && jobData?.header_range?.length + 1 >= claim?.page_to) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_to = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_to = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_to = "5";
                                    }
                                }

                                if (window.location.pathname == DATA_ENTRY && claim?.page_from && jobData?.footer_range && !jobData?.is_last_sub_job && jobData?.footer_range?.length > 0 && totalPages - jobData?.footer_range?.length < claim?.page_from) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_from = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_from = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_from = "5";
                                    }
                                }
                                if (window.location.pathname == DATA_ENTRY && claim?.page_to && jobData?.footer_range && !jobData?.is_last_sub_job && jobData?.footer_range?.length > 0 && totalPages - jobData?.footer_range?.length < claim?.page_to) {
                                    if (errors && errors.claims) {
                                        if (errors.claims[index]) {
                                            errors.claims[index].page_to = "1";
                                        } else {
                                            errors.claims[index] = {};
                                            errors.claims[index].page_to = "3";
                                        }
                                    } else {
                                        errors.claims = [];
                                        errors.claims[index] = {};
                                        errors.claims[index].page_to = "5";
                                    }
                                }
                            }
                        }
                        if (
                            claim.patient_account_number == "" || claim.patient_account_number == undefined

                        ) {
                            if (errors && errors.claims) {
                                if (errors.claims[index]) {
                                    errors.claims[index].patient_account_number = "Required";
                                    errors.claims[index].dbk_patient_account_number = "Required";
                                } else {
                                    errors.claims[index] = {};
                                    errors.claims[index].patient_account_number = "Required";
                                    errors.claims[index].dbk_patient_account_number = "Required";
                                }
                            } else {
                                errors.claims = [];
                                errors.claims[index] = {};
                                errors.claims[index].patient_account_number = "Required";
                                errors.claims[index].dbk_patient_account_number = "Required";
                            }
                        }
                    }
                    // DBK VALIDATION START HERE
                    // errors.claims = [];
                    // errors.claims[index] = {};

                    if (
                        String(claim?.dbk_patient_first_name).toUpperCase() != String(claim?.patient_first_name).toUpperCase() &&
                        isDbkPermission?.correspondence?.correspondence_patient_first_name
                    ) {

                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_first_name = "Dont Match";
                                errors.claims[index].dbk_patient_first_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_first_name = "Dont Match";
                                errors.claims[index].dbk_patient_first_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_first_name = "Dont Match";
                            errors.claims[index].dbk_patient_first_name = "Dont Match";
                        }

                    }
                    if (
                        String(claim?.dbk_patient_middle_name).toUpperCase() != String(claim?.patient_middle_name).toUpperCase() &&
                        isDbkPermission?.correspondence?.correspondence_patient_middle_name
                    ) {


                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_middle_name = "Dont Match";
                                errors.claims[index].dbk_patient_middle_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_middle_name = "Dont Match";
                                errors.claims[index].dbk_patient_middle_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_middle_name = "Dont Match";
                            errors.claims[index].dbk_patient_middle_name = "Dont Match";
                        }
                    }

                    if (
                        String(claim?.dbk_patient_last_name).toUpperCase() != String(claim?.patient_last_name).toUpperCase() &&
                        isDbkPermission?.correspondence?.correspondence_patient_last_name
                    ) {

                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_last_name = "Dont Match";
                                errors.claims[index].dbk_patient_last_name = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_last_name = "Dont Match";
                                errors.claims[index].dbk_patient_last_name = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_last_name = "Dont Match";
                            errors.claims[index].dbk_patient_last_name = "Dont Match";
                        }

                    }
                    if (
                        // claim?.dbk_billed != claim.billed &&
                        String(claim?.dbk_billed).replace([NaN, undefined, "NaN"], "") !==
                        String(claim.billed).replace([NaN, undefined, "NaN"], "") &&
                        isDbkPermission?.correspondence?.correspondence_billed_amount
                    ) {

                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].billed = "Dont Match";
                                errors.claims[index].dbk_billed = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].billed = "Dont Match";
                                errors.claims[index].dbk_billed = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].billed = "Dont Match";
                            errors.claims[index].dbk_billed = "Dont Match";
                        }
                    }

                    if (
                        claim?.dbk_period_end?.replace("__/__/____", "") !==
                        claim.period_end?.replace("__/__/____", "")
                        &&
                        isDbkPermission?.correspondence?.correspondence_period_end
                    ) {

                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].period_end = "Dont Match";
                                errors.claims[index].dbk_period_end = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].period_end = "Dont Match";
                                errors.claims[index].dbk_period_end = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].period_end = "Dont Match";
                            errors.claims[index].dbk_period_end = "Dont Match";
                        }



                        // errors.claims[index].period_end = "Dont Match";
                        // errors.claims[index].dbk_period_end = "Dont Match";
                    }
                    if (
                        claim?.dbk_period_start?.replace("__/__/____", "") !==
                        claim.period_start?.replace("__/__/____", "")
                        &&
                        isDbkPermission?.correspondence?.correspondence_period_start
                    ) {


                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].period_start = "Dont Match";
                                errors.claims[index].dbk_period_start = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].period_start = "Dont Match";
                                errors.claims[index].dbk_period_start = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].period_start = "Dont Match";
                            errors.claims[index].dbk_period_start = "Dont Match";
                        }
                    }

                    if (
                        claim?.dbk_patient_account_number != claim.patient_account_number &&
                        isDbkPermission?.correspondence?.correspondence_patient_acc_number
                    ) {
                        if (errors && errors.claims) {
                            if (errors.claims[index]) {
                                errors.claims[index].patient_account_number = "Dont Match";
                                errors.claims[index].dbk_patient_account_number = "Dont Match";
                            } else {
                                errors.claims[index] = {};
                                errors.claims[index].patient_account_number = "Dont Match";
                                errors.claims[index].dbk_patient_account_number = "Dont Match";
                            }
                        } else {
                            errors.claims = [];
                            errors.claims[index] = {};
                            errors.claims[index].patient_account_number = "Dont Match";
                            errors.claims[index].dbk_patient_account_number = "Dont Match";
                        }
                    }

                    // DBK VALIDATION END HERE
                });

                return errors;
            },
            validationSchema: Yup.object().shape({
                letter_date: Yup.date(),
                claims: Yup.array().of(
                    Yup.object().shape({
                        period_start: Yup.date(),
                        period_end: Yup.date(),
                        billed: Yup.string(),
                        patient_first_name: Yup.string(),
                        patient_middle_name: Yup.string(),
                        patient_last_name: Yup.string(),
                        page_from: Yup.string(),
                        page_to: Yup.string(),
                    })
                ),
            }),
            onSubmit: (values, { setSubmitting }) => {

                setSubmitting(true);
                const old_data = jobData?.grid_data;
                const qa_corrected_data = {};

                if (old_data?.payment_id && window.location.pathname == QA_REVIEW) {

                    const propertiesToCheck = [
                        'payer_name',
                        'letter_date',
                        'correspondance',
                        'payer_page_from',
                        'payer_page_to',
                    ];
                    qa_corrected_data.is_transaction_type_changed = old_data?.transaction_type != "CORRESPONDENCE" ? true : false
                    qa_corrected_data.overall_page_from = !jobData?.is_large_batch_job ? old_data?.overall_page_from?.toString().toUpperCase() != overallPage?.from?.toString().toUpperCase() : false;
                    qa_corrected_data.overall_page_to = !jobData?.is_large_batch_job ? old_data?.overall_page_to?.toString().toUpperCase() != overallPage?.to?.toString().toUpperCase() : false;

                    propertiesToCheck.forEach((property) => {
                        if (old_data?.transaction_type != "CORRESPONDENCE") {
                            qa_corrected_data[property] = true;
                        }
                        else {

                            qa_corrected_data[property] = old_data?.[property]?.toString().toUpperCase() != values?.[property]?.toString().toUpperCase() ? true : false;
                        }
                    });
                    if (isAdditional) {

                        const AdditionalPropertiesToCheck = [
                            'qdx_status',
                            // 'qdx_reason',
                            'qdx_classification',


                        ];
                        AdditionalPropertiesToCheck.forEach((property) => {
                            qa_corrected_data[property] = old_data?.[property] != values?.[property] ? true : false;
                        });
                        const array1 = old_data?.qdx_reasons ? old_data?.qdx_reasons : [];
                        const array2 = aditionalInfoFormik.values?.reason ? aditionalInfoFormik.values?.reason : [];
                        const array1sort = array1.sort()
                        const array2sort = array2.sort()
                        if (JSON.stringify(array1sort) != JSON.stringify(array2sort)) {
                            qa_corrected_data.qdx_reasons = true;
                        }
                        else {
                            qa_corrected_data.qdx_reasons = false;
                        }
                        qa_corrected_data.qdx_reason = false;
                        qa_corrected_data.is_orphaned_check = old_data?.is_orphaned_check != aditionalInfoFormik?.values?.is_orphaned_check ? true : false;
                        qa_corrected_data.is_missing_check_image = old_data?.is_missing_check_image != aditionalInfoFormik?.values?.is_missing_check_image ? true : false;
                        qa_corrected_data.is_forced_balancing = old_data?.is_forced_balancing != aditionalInfoFormik?.values?.is_forced_balancing ? true : false;
                        qa_corrected_data.is_payer_black_listing = old_data?.is_payer_black_listing != aditionalInfoFormik?.values?.is_payer_black_listing ? true : false;
                        qa_corrected_data.is_payer_excluding_indexing = old_data?.is_payer_excluding_indexing != aditionalInfoFormik?.values?.is_payer_excluding_indexing ? true : false;
                    }

                    if (values?.claims && values?.claims?.length > 0) {
                        const claimSubjobPageRange = handleClaimSubJobPageRange(values?.claims, jobData);

                        values.claims = claimSubjobPageRange;

                        qa_corrected_data.claims = values?.claims?.map((claim, index) => {
                            let claimData = {};

                            if (old_data?.transaction_type != "CORRESPONDENCE") {
                                claimData = {
                                    patient_account_number: true,
                                    period_start: true,
                                    period_end: true,
                                    billed: true,
                                    sub_job_id: claim?.sub_job_id ? claim?.sub_job_id : "",

                                    patient_first_name: true,
                                    patient_middle_name: true,
                                    patient_last_name: true,

                                    page_from: true,
                                    page_to: true,

                                };
                                return claimData;

                            }
                            else if (claim.corrected_claim) {

                                claimData = {
                                    patient_account_number: true,
                                    period_start: true,
                                    period_end: true,
                                    billed: true,
                                    sub_job_id: claim?.sub_job_id ? claim?.sub_job_id : "",

                                    patient_first_name: true,
                                    patient_middle_name: true,
                                    patient_last_name: true,

                                    page_from: true,
                                    page_to: true,

                                };


                                return claimData;

                            } else {
                                claimData = {
                                    patient_account_number: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.patient_account_number?.toUpperCase() != claim?.patient_account_number?.toUpperCase() ? true : false,
                                    period_start: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.period_start != claim?.period_start ? true : false,
                                    period_end: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.period_end != claim?.period_end ? true : false,
                                    billed: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.billed != claim?.billed ? true : false,
                                    sub_job_id: claim?.sub_job_id ? claim?.sub_job_id : "",
                                    patient_first_name: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.patient_first_name?.toUpperCase() != claim?.patient_first_name?.toUpperCase() ? true : false,
                                    patient_middle_name: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.patient_middle_name?.toUpperCase() != claim?.patient_middle_name?.toUpperCase() ? true : false,
                                    patient_last_name: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.patient_last_name?.toUpperCase() != claim?.patient_last_name?.toUpperCase() ? true : false,
                                    page_from: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.page_from != claim?.page_from ? true : false,
                                    page_to: old_data?.claims[claim.claim_corrected_index ? claim.claim_corrected_index : claim.claim_corrected_index == 0 ? claim.claim_corrected_index : index]?.page_to != claim?.page_to ? true : false,
                                }

                                return claimData;
                            }
                        });

                    }


                    const { deleted_claims, deleted_service_lines, deleted_plb, deleted_claim_adjustments, deleted_service_adjustments } = getOldGridDataCount(old_data, values);
                    qa_corrected_data.deleted_claims = deleted_claims
                    qa_corrected_data.deleted_service_lines = deleted_service_lines
                    qa_corrected_data.deleted_plb = deleted_plb
                    qa_corrected_data.deleted_claim_adjustments = deleted_claim_adjustments
                    qa_corrected_data.deleted_service_adjustments = deleted_service_adjustments
                }
                // =================QA ERROR MODAL======================
                const isShowQaErrorModal = window.location.pathname == QA_REVIEW && showQaErrorModal(qa_corrected_data, jobData?.grid_data?.qa_corrected_data) && !qaErrorModalSave
                if (isShowQaErrorModal) {
                    setQaErrorModal(true);
                    setSubmitting(false)
                    return;
                }
                qa_corrected_data.qa_error_type = qaErrorformik?.values?.qa_error_type ? qaErrorformik?.values?.qa_error_type : []
                qa_corrected_data.qa_error_type_comment = qaErrorformik?.values?.qa_error_type_comment
                closeQaErrorModal()
                // =======================================
                // ================CALLING NEXT JOB=======================
                setformApiLoaded(true)
                if (!localStorage.getItem("SAVE_EXIT")) {
                    // setJobData("no data")
                    setLoadData(true)
                }
                // ===================================
                values.transaction_type = "CORRESPONDENCE";
                values.work_type = window.location.pathname == DATA_ENTRY ? 1 : window.location.pathname == QA_REVIEW ? 2 : ""

                values.overall_page_from = isOverallPage && overallPage?.from ? overallPage?.from : "";
                values.overall_page_to = isOverallPage && overallPage?.to ? overallPage?.to : "";

                values.is_first_sub_job = isFirstJob;
                values.qa_corrected_data = qa_corrected_data;
                values.payer_name = values?.payer_name ? values?.payer_name?.toUpperCase() : "";
                values.payer_page_from = values?.payer_page_from ? values?.payer_page_from : "";
                values.payer_page_to = values?.payer_page_to ? values?.payer_page_to : "";

                values.qdx_status = values?.qdx_status ? values?.qdx_status == "0" ? "" : values?.qdx_status : ""
                // values.qdx_reason = values?.reason ? values?.reason == "0" ? "" : values?.reason : ""
                values.qdx_reasons = values?.qdx_reason.length > 0 ? values?.qdx_reason?.map((i) => i.value) : []
                values.qdx_classification = values?.qdx_classification ? values?.qdx_classification == "0" ? "" : values?.qdx_classification : ""

                values.is_orphaned_check = values?.is_orphaned_check ? true : false;
                values.is_missing_check_image = values?.is_missing_check_image ? true : false;
                values.is_forced_balancing = values?.is_forced_balancing ? true : false;
                values.is_payer_black_listing = values?.is_payer_black_listing ? true : false;
                values.is_payer_excluding_indexing = values?.is_payer_excluding_indexing ? true : false;


                const newData = values?.claims.map((item, index) => {
                    return {
                        ...item,
                        order: index + 1,
                        patient_account_number: item.patient_account_number ? item.patient_account_number?.toUpperCase() : "",

                        patient_first_name: item.patient_first_name ? item.patient_first_name?.toUpperCase() : "",
                        patient_middle_name: item.patient_middle_name ? item.patient_middle_name?.toUpperCase() : "",
                        patient_last_name: item.patient_last_name ? item.patient_last_name?.toUpperCase() : "",

                        billed: isNaN(item.billed) ? parseFloat(0).toFixed(2) : parseFloat(item.billed ? item.billed : 0).toFixed(2),

                    };
                });
                values.claims = newData;
                values.status = ""
                values.grid_total_fields_count = getTotalFieldsCount(formCorres)

                const RedirectId = getRedirectTabId()
                createOrUpdateCorres(values)((response) => {
                    if (response?.data && response?.status) {
                        setSaveSuccess(true)
                        setformApiLoaded(false)

                        localStorage.removeItem('grid_data')
                        localStorage.removeItem('grid_state_data')
                        if (localStorage.getItem("SAVE_EXIT")) {
                            localStorage.removeItem("SAVE_EXIT")
                            handleNavigateDashBoard(RedirectId)
                        } else {
                            setToast({ status: true, type: "success", message: "Success" });
                            // window.location.reload()
                            localStorage.removeItem('transaction_type')
                            setSubmitting(false)

                        }
                    }
                    else if (response?.status_code == 400 && response?.errors && response?.errors?.assign_user?.length > 0) {
                        setformApiLoaded(false)

                        setErrorAlert({
                            status: true,
                            type: "danger",
                            message: response?.errors?.assign_user[0]
                        })
                        setSubmitting(false);

                    }
                    else {
                        setErrorJobData(values)

                        setformApiLoaded(false)

                        setToast({ status: true, type: "danger", message: "Failed" });

                        setSubmitting(false)
                    }
                });
            },
        });

    // ====================================  CORRESSPONDENCE FORM ENDS ======================================*\\



    // ====================================  CORRESSPONDENCE ADDING NEW CORRES START ======================================*\\
    const addingNewCorrespondanceClaim = () => {
        addingNewCorrespondanceClaimDbk();
        formCorres.setValues({
            ...formCorres.values,
            claims: [
                ...formCorres.values?.claims?.slice(0, claimCorresIndex + 1), // copy the array up to the desired index
                {
                    claim_id: "",
                    order: "",
                    corrected_claim: true,
                    patient_account_number: "",
                    period_start: "",
                    period_end: "",
                    billed: "",
                    patient_id: "",
                    patient_first_name: "",
                    patient_middle_name: "",
                    patient_last_name: "",
                    page_from: currentPage + 1,
                    page_to: currentPage + 1,

                    dbk_patient_account_number: "",
                    dbk_patient_first_name: "",
                    dbk_patient_middle_name: "",
                    dbk_patient_last_name: "",
                    dbk_billed: "",
                    dbk_period_start: "",
                    dbk_period_end: "",
                },
                ...formCorres.values?.claims?.slice(claimCorresIndex + 1), // copy the array after the desired index
            ],
        });
    };
    // ====================================  CORRESSPONDENCE ADDING NEW CORRES END ======================================*\\
    // ====================================  CORRESSPONDENCE RESET FORM START ======================================*\\
    const resetFormikData = () => {
        formCorres.setValues({
            job: "",
            rejection_reason: "",
            payment_id: "",
            transaction_type: "",
            payer_name: "",
            check_date: "",
            correspondance: "",

            claims: [
                {
                    claim_id: "",
                    order: "1",
                    patient_account_number: "",
                    period_start: "",
                    period_end: "",
                    billed: "",

                    patient_id: "",
                    dbk_patient_first_name: "",
                    patient_first_name: "",
                    patient_middle_name: "",
                    patient_last_name: "",

                    page_from: "1",
                    page_to: "1",
                },
            ],
        });
    };
    // ====================================  CORRESSPONDENCE RESET FORM ENDS ======================================*\\

    // ==================================== TRANSACTION TYPE TRIGGERS =================================//



    // useeffect for data inserting into appropriate transaction types and choosding transaction types to be used
    useEffect(() => {
        if (jobData && jobData != null) {
            const strsavedData = localStorage.getItem('grid_data')
            const savedData = strsavedData ? JSON.parse(strsavedData) : null
            const strstateData = localStorage.getItem('grid_state_data')
            const stateData = strstateData ? JSON.parse(strstateData) : null
            // Adding Error Job
            const error_strsavedData = localStorage.getItem('error_grid_data')
            const error_savedData = error_strsavedData ? JSON.parse(error_strsavedData) : null
            const error_strstateData = localStorage.getItem('error_grid_state_data')
            const error_stateData = error_strstateData ? JSON.parse(error_strstateData) : null
            const is_job_error = localStorage.getItem('is_job_error')
            if (is_job_error == 'true' && error_savedData && error_savedData?.job != '' && error_savedData?.job == jobData?.job && error_stateData && !isViewOnllyGrid) {
                if (error_stateData?.Payment835) {
                    setClaim835Index(0)
                    handleChangeGridPosition({ x: -10, y: 550 });
                }
                else if (error_stateData?.Payment835Lite) {
                    setClaimLiteIndex(0)
                    handleChangeGridPosition({ x: -10, y: 700 });
                }
                else if (error_stateData?.PatientPay) {
                    handleChangeGridPosition({ x: -10, y: 650 });
                }
                else if (error_stateData?.Correspondence) {
                    handleChangeGridPosition({ x: -10, y: 730 });
                }
                setState(error_stateData)
            }
            else if (jobErrorAlert == false && savedData && savedData?.job != '' && savedData?.job == jobData?.job && stateData && !isViewOnllyGrid) {
                setSwalPopup(true)
                Swal.fire({
                    icon: 'success',
                    confirmButtonColor: '#6ba2b6',
                    confirmButtonText: 'YES',
                    cancelButtonText: 'NO',
                    showCancelButton: true,
                    allowOutsideClick: false,
                    backdrop: true,
                    title: 'LOAD SAVED DATA?',
                    customClass: {
                        title: 'swal-titleText fs-5'
                    }
                }).then((result) => {
                    if (result?.isConfirmed) {
                        setSwalPopup(false)
                        if (stateData?.Payment835) {
                            setClaim835Index(0)
                            handleChangeGridPosition({ x: -10, y: 550 });
                        }
                        else if (stateData?.Payment835Lite) {
                            setClaimLiteIndex(0)
                            handleChangeGridPosition({ x: -10, y: 700 });
                        }
                        else if (stateData?.PatientPay) {
                            handleChangeGridPosition({ x: -10, y: 650 });
                        }
                        else if (stateData?.Correspondence) {
                            handleChangeGridPosition({ x: -10, y: 730 });
                        }
                        setState(stateData)
                    }
                    else {
                        setSwalPopup(false)
                        if (!isViewOnllyGrid) {
                            localStorage.removeItem('grid_data')
                            localStorage.removeItem('grid_state_data')
                            localStorage.removeItem('grid_qdx_data')
                            localStorage.removeItem('grid_overall_data')

                        }
                        if (
                            jobData?.document_type?.eob &&
                            jobData?.grid_data?.transaction_type &&
                            jobData?.grid_data?.transaction_type == "PAYMENT835"
                        ) {
                            __payment835Handler();
                        } else if (
                            jobData?.document_type?.eob_lite &&
                            jobData?.grid_data?.transaction_type &&
                            jobData?.grid_data?.transaction_type == "PAYMENT835_LITE"
                        ) {
                            __payment835LiteHandler();
                        } else if (
                            jobData?.document_type?.patient_pay &&
                            jobData?.grid_data?.transaction_type &&
                            jobData?.grid_data?.transaction_type == "PATIENT_PAY"
                        ) {
                            __patientPayHandler();
                        } else if (
                            jobData?.document_type?.correspondence &&
                            jobData?.grid_data?.transaction_type &&
                            jobData?.grid_data?.transaction_type == "CORRESPONDENCE"
                        ) {
                            __correspondenceHandler();
                        } else {
                            if (jobData?.document_type?.eob) {
                                __payment835Handler();
                            } else if (jobData?.document_type?.eob_lite) {
                                __payment835LiteHandler();
                            } else if (jobData?.document_type?.patient_pay) {
                                __patientPayHandler();
                            } else if (jobData?.document_type?.correspondence) {
                                __correspondenceHandler();
                            }
                        }
                    }
                })
            }
            else {
                if (!isViewOnllyGrid) {
                    localStorage.removeItem('grid_data')
                    localStorage.removeItem('grid_state_data')
                }
                if (
                    jobData?.document_type?.eob &&
                    jobData?.grid_data?.transaction_type &&
                    jobData?.grid_data?.transaction_type == "PAYMENT835"
                ) {
                    __payment835Handler();
                } else if (
                    jobData?.document_type?.eob_lite &&
                    jobData?.grid_data?.transaction_type &&
                    jobData?.grid_data?.transaction_type == "PAYMENT835_LITE"
                ) {
                    __payment835LiteHandler();
                } else if (
                    jobData?.document_type?.patient_pay &&
                    jobData?.grid_data?.transaction_type &&
                    jobData?.grid_data?.transaction_type == "PATIENT_PAY"
                ) {
                    __patientPayHandler();
                } else if (
                    jobData?.document_type?.correspondence &&
                    jobData?.grid_data?.transaction_type &&
                    jobData?.grid_data?.transaction_type == "CORRESPONDENCE"
                ) {
                    __correspondenceHandler();
                } else {
                    if (jobData?.document_type?.eob) {
                        __payment835Handler();
                    } else if (jobData?.document_type?.eob_lite) {
                        __payment835LiteHandler();
                    } else if (jobData?.document_type?.patient_pay) {
                        __patientPayHandler();
                    } else if (jobData?.document_type?.correspondence) {
                        __correspondenceHandler();
                    }
                }
            }
            // INITIALIZE DBK FIELDS PERMISSIONS START
            if (window.location.pathname == DATA_ENTRY) {
                setisDbkPermission(jobData?.dbk_fields);
            }
        }
        // INITIALIZE DBK FIELDS PERMISSIONS END
    }, [jobData, job_id]);

    // =====================================  CHECK FUNCTIONS =========================== //

    /// check functions and its states here

    const [checkNum, setcheckNum] = useState("");

    // =====================================  CHECK FUNCTIONS =========================== //

    //=============================================== TRANSACTION TYPE  FORMIK DATA HANDLERS  starts ============================================//

    // in these functions we insert data into the appropriate transaction types

    const [isCheckNumber, setIsCheckNumber] = useState(false)
    const setErrorJobData = (data = null) => {

        if (state.Payment835) {
            localStorage.setItem('error_grid_data', JSON.stringify(data ? data : formEob.values))
        }
        else if (state.Payment835Lite) {
            localStorage.setItem('error_grid_data', JSON.stringify(data ? data : eobLiteFormik.values))
        }
        else if (state.Correspondence) {
            localStorage.setItem('error_grid_data', JSON.stringify(data ? data : formCorres.values))
        }
        else if (state?.PatientPay) {
            localStorage.setItem('error_grid_data', JSON.stringify(data ? data : formPP.values))
        }
        setjobErrorAlert(true)
        localStorage.setItem('error_grid_state_data', JSON.stringify(state))
        localStorage.setItem('error_grid_qdx_data', JSON.stringify(qdxTransaction))
        localStorage.setItem('error_grid_overall_data', JSON.stringify(overallPage))

        localStorage.removeItem('grid_data')
        localStorage.removeItem('grid_state_data')
        localStorage.removeItem('grid_qdx_data')
        localStorage.removeItem('grid_overall_data')
    }
    const handleEob835Data = async () => {
        const _ = require('lodash');
        const values = _.cloneDeep(jobData?.grid_data);



        setIsCorres835(jobData?.client_level_customization?.is_835_cors_available)
        setIsPP835(jobData?.client_level_customization?.is_835_pp_available)
        setIsAdditional(jobData?.client_level_customization?.is_additional_info_available)

        setIsGeneratedCheckNumber(jobData?.client_level_customization?.is_generated_check_number)
        setIsIncompleteCheckNumber(jobData?.client_level_customization?.is_incomplete_check_number)

        setIsOverallPage(jobData?.client_level_customization?.is_overall_page_from_to)
        setIsPayerPage(jobData?.client_level_customization?.is_payer_page_from_to)
        setIsPayeePage(jobData?.client_level_customization?.is_payee_page_from_to)
        setIsPatientPageFromTo(jobData?.client_level_customization?.is_patient_from_and_to)


        setIsAdditionalInfoOptions({
            status: jobData?.client_level_customization?.is_additional_info_status,
            reason: jobData?.client_level_customization?.is_additional_info_reason,
            document_classification: jobData?.client_level_customization?.is_additional_info_document_classification,
            extra_info: jobData?.client_level_customization?.is_additional_info_extra_info,
        })

        let plb_adjustments = values?.plb_adjustments ? values?.plb_adjustments : [];

        if (plb_adjustments && plb_adjustments?.length > 0) {
            plb_adjustments.map((plb, index) => {
                plb.plb_index = index;
                plb.plb_amt = plb.plb_amt == 0 ? parseFloat(0).toFixed(2) : plb.plb_amt > 0 || plb.plb_amt < 0 ? parseFloat(plb.plb_amt ? plb.plb_amt : 0).toFixed(2) : parseFloat(0).toFixed(2);
            });
        }

        var claims = []
        var pulled = false;
        let claim_initial_focus = []
        if (jobData && jobData?.grid_data && jobData?.grid_data?.transaction_type == "PAYMENT835") {

            if (jobData?.grid_data?.claims?.length > 0) {
                claims = values?.claims;
                claims.map((claim, index) => {
                    claim_initial_focus.push({
                        patient_account_number: false,
                        payer_claim_number: false,

                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,
                        patient_identifier_code: false,

                        subscriber_first_name: false,
                        subscriber_middle_name: false,
                        subscriber_last_name: false,
                        subscriber_identifier_code: false,

                        rendering_provider_first_name: false,
                        rendering_provider_last_name: false,
                        rendering_provider_identifier_code: false,

                        mrn: false,
                        statement_number: false,
                        statement_date: false,

                        // DBK START
                        dbk_patient_account_number: false,
                        dbk_payer_claim_number: false,

                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                        dbk_patient_identifier_code: false,

                        dbk_subscriber_first_name: false,
                        dbk_subscriber_middle_name: false,
                        dbk_subscriber_last_name: false,
                        dbk_subscriber_identifier_code: false,

                        dbk_rendering_provider_first_name: false,
                        dbk_rendering_provider_last_name: false,
                        dbk_rendering_provider_identifier_code: false,

                        dbk_mrn: false,
                        dbk_statement_number: false,
                        dbk_statement_date: false,
                    })
                    // DBK START
                    claim.corrected_claim = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;
                    claim.claim_corrected_index = index;
                    claim.page_from = claim && claim?.page_from ? claim && claim?.page_from : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.page_to = claim && claim?.page_to ? claim && claim?.page_to : jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "";
                    claim.dbk_patient_account_number = claim.patient_account_number ?? "";
                    claim.dbk_payer_claim_number = claim.payer_claim_number ?? "";

                    claim.dbk_patient_first_name = claim.patient_first_name ?? "";
                    claim.dbk_patient_middle_name = claim.patient_middle_name ?? "";
                    claim.dbk_patient_last_name = claim.patient_last_name ?? "";
                    claim.dbk_patient_identifier_code = claim.patient_identifier_code ?? "";

                    claim.dbk_subscriber_first_name = claim.subscriber_first_name ?? "";
                    claim.dbk_subscriber_middle_name = claim.subscriber_middle_name ?? "";
                    claim.dbk_subscriber_last_name = claim.subscriber_last_name ?? "";
                    claim.dbk_subscriber_identifier_code =
                        claim.subscriber_identifier_code ?? claim.subscriber_identifier_code;

                    claim.dbk_rendering_provider_first_name =
                        claim.rendering_provider_first_name ?? claim.rendering_provider_first_name;
                    claim.dbk_rendering_provider_last_name =
                        claim.rendering_provider_last_name ?? claim.rendering_provider_last_name;
                    claim.dbk_rendering_provider_identifier_code =
                        claim.rendering_provider_identifier_code ?? claim.rendering_provider_identifier_code;

                    claim.dbk_mrn =
                        claim.mrn ?? claim.mrn;
                    claim.dbk_statement_number =
                        claim.statement_number ?? claim.statement_number;
                    claim.dbk_statement_date =
                        claim.statement_date ?? claim.statement_date;


                    // DBK END
                    claim.patient = claim.patient_id ? true : false;
                    claim.subscriber = claim.subscriber_id ? true : false;
                    claim.rendering_provider = claim.rendering_provider_id ? true : false;
                    claim.period_start = claim.period_start != "" ? moment(claim.period_start).format("MM/DD/YYYY") : "";
                    claim.period_end = claim.period_end != "" ? moment(claim.period_end).format("MM/DD/YYYY") : "";
                    claim.encounter = claim?.encounter ? claim?.encounter : "";
                    claim.claim_billed = jobData?.grid_data?.qdx_tranansaction_type == 'PATIENT_PAY' && claim?.claim_billed == '' ? '' : parseFloat(claim?.claim_billed ? claim?.claim_billed : 0).toFixed(2);
                    claim.claim_discount = parseFloat(claim?.claim_discount ? claim?.claim_discount : 0).toFixed(2);
                    claim.claim_allowed = parseFloat(claim?.claim_allowed ? claim?.claim_allowed : 0).toFixed(2);
                    claim.claim_deduct = parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0).toFixed(2);
                    claim.claim_coins = parseFloat(claim?.claim_coins ? claim?.claim_coins : 0).toFixed(2);
                    claim.claim_copay = parseFloat(claim?.claim_copay ? claim?.claim_copay : 0).toFixed(2);
                    claim.claim_cob = parseFloat(claim?.claim_cob ? claim?.claim_cob : 0).toFixed(2);
                    claim.claim_patres = parseFloat(claim?.claim_patres ? claim?.claim_patres : 0).toFixed(2);
                    claim.claim_paid = jobData?.grid_data?.qdx_tranansaction_type == 'PATIENT_PAY' && claim?.claim_paid == '' ? '' : parseFloat(claim?.claim_paid ? claim?.claim_paid : 0).toFixed(2);
                    claim.claim_balance = parseFloat(claim?.claim_balance ? claim?.claim_balance : 0).toFixed(2);
                    claim.claim_adjustment_total = parseFloat(claim?.claim_adjustment_total ? claim?.claim_adjustment_total : 0).toFixed(2);
                    claim.claim_adjustment_total_pr = parseFloat(claim?.claim_adjustment_total_pr ? claim?.claim_adjustment_total_pr : 0).toFixed(2);

                    // claim.status_code = values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "";
                    claim.mrn = claim?.mrn ? claim?.mrn : "";
                    claim.statement_number = claim?.statement_number ? claim?.statement_number : "";
                    claim.statement_date = claim?.statement_date ? claim?.statement_date : "";

                    claim.sl_billed = parseFloat(claim?.sl_billed ? claim?.sl_billed : 0).toFixed(2);
                    claim.fake_sl_patres = parseFloat(claim?.fake_sl_patres ? claim?.fake_sl_patres : 0).toFixed(2);
                    claim.adjustment_total = parseFloat(claim?.adjustment_total ? claim?.adjustment_total : 0).toFixed(2);
                    claim.sl_allowed = parseFloat(claim?.sl_allowed ? claim?.sl_allowed : 0).toFixed(2);
                    claim.sl_deduct = parseFloat(claim?.sl_deduct ? claim?.sl_deduct : 0).toFixed(2);
                    claim.sl_coins = parseFloat(claim?.sl_coins ? claim?.sl_coins : 0).toFixed(2);
                    claim.sl_copay = parseFloat(claim?.sl_copay ? claim?.sl_copay : 0).toFixed(2);
                    claim.sl_cob = parseFloat(claim?.sl_cob ? claim?.sl_cob : 0).toFixed(2);
                    claim.sl_patres = parseFloat(claim?.sl_patres ? claim?.sl_patres : 0).toFixed(2);
                    claim.sl_amount = parseFloat(claim?.sl_amount ? claim?.sl_amount : 0).toFixed(2);
                    claim.sl_paid = parseFloat(claim?.sl_paid ? claim?.sl_paid : 0).toFixed(2);
                    claim.sl_balance = parseFloat(claim?.sl_balance ? claim?.sl_balance : 0).toFixed(2);
                    claim.sl_total = parseFloat(claim?.sl_total ? claim?.sl_total : 0).toFixed(2);

                    claim?.claim_adjustments && claim?.claim_adjustments?.length > 0
                        ? claim?.claim_adjustments?.map((adjustment) => {
                            adjustment.amount = parseFloat(adjustment.amount ? adjustment.amount : 0).toFixed(2);
                        })
                        : (claim.claim_adjustments =
                            formEob.initialValues.claims[0].claim_adjustments);
                    // claim?.service_lines && claim?.service_lines?.length > 0
                    //     ? 
                    claim?.service_lines.map((serviceline, index) => {
                        serviceline.serviceline_old_data_index = index;
                        serviceline.from_date = serviceline?.from_date != "" ? moment(serviceline?.from_date).format(
                            "MM/DD/YYYY"
                        ) : "";
                        serviceline.to_date = serviceline?.to_date != "" ? moment(serviceline?.to_date).format(
                            "MM/DD/YYYY"
                        ) : "";
                        serviceline.corrected_serviceline = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;

                        serviceline.serviceline_old_data_index = index;
                        serviceline.billed = parseFloat(serviceline?.billed ? serviceline?.billed : 0).toFixed(2);
                        serviceline.patres = parseFloat(serviceline?.patres ? serviceline?.patres : 0).toFixed(2);
                        serviceline.discount = parseFloat(serviceline?.discount ? serviceline?.discount : 0).toFixed(2);
                        serviceline.allowed = parseFloat(serviceline?.allowed ? serviceline?.allowed : 0).toFixed(2);
                        serviceline.deduct = parseFloat(serviceline?.deduct ? serviceline?.deduct : 0).toFixed(2);
                        serviceline.coins = parseFloat(serviceline?.coins ? serviceline?.coins : 0).toFixed(2);
                        serviceline.copay = parseFloat(serviceline?.copay ? serviceline?.copay : 0).toFixed(2);
                        serviceline.cob = parseFloat(serviceline?.cob ? serviceline?.cob : 0).toFixed(2);
                        serviceline.paid = parseFloat(serviceline?.paid ? serviceline?.paid : 0).toFixed(2);
                        serviceline.balance = parseFloat(serviceline?.balance ? serviceline?.balance : 0).toFixed(2);

                        serviceline?.adjustments.map((adjustment, ind) => {
                            adjustment.amount = parseFloat(
                                adjustment.amount ? adjustment.amount : 0
                            ).toFixed(2);
                            adjustment.old_index = ind
                        });
                    })
                    if (claim?.encounter && claim?.encounter != '') {
                        pulled = true
                    }
                    // : (claim.service_lines =
                    //     formEob.initialValues.claims[0].service_lines);
                });

            }
            else {
                claim_initial_focus.push({
                    patient_account_number: false,
                    payer_claim_number: false,

                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,
                    patient_identifier_code: false,

                    subscriber_first_name: false,
                    subscriber_middle_name: false,
                    subscriber_last_name: false,
                    subscriber_identifier_code: false,

                    rendering_provider_first_name: false,
                    rendering_provider_last_name: false,
                    rendering_provider_identifier_code: false,

                    mrn: false,
                    statement_number: false,
                    statement_date: false,

                    // DBK START
                    dbk_patient_account_number: false,
                    dbk_payer_claim_number: false,

                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                    dbk_patient_identifier_code: false,

                    dbk_subscriber_first_name: false,
                    dbk_subscriber_middle_name: false,
                    dbk_subscriber_last_name: false,
                    dbk_subscriber_identifier_code: false,

                    dbk_rendering_provider_first_name: false,
                    dbk_rendering_provider_last_name: false,
                    dbk_rendering_provider_identifier_code: false,

                    dbk_mrn: false,
                    dbk_statement_number: false,
                    dbk_statement_date: false,
                })
                claims = [
                    {
                        patient: true,
                        subscriber: true,
                        rendering_provider: true,

                        order: "",
                        color_index: "",

                        corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                        claim_id: "",
                        patient_account_number: "",
                        payer_claim_number: "",
                        period_start: "",
                        period_end: "",
                        status_code: values?.qdx_tranansaction_type == "PATIENT_PAY" ? "1" : "",
                        page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",
                        page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : "1",

                        claim_billed: jobData?.grid_data?.qdx_tranansaction_type == 'PATIENT_PAY' ? '' : parseFloat(0).toFixed(2),
                        claim_discount: parseFloat(0).toFixed(2),
                        claim_allowed: parseFloat(0).toFixed(2),
                        claim_deduct: parseFloat(0).toFixed(2),
                        claim_coins: parseFloat(0).toFixed(2),
                        claim_copay: parseFloat(0).toFixed(2),
                        claim_cob: parseFloat(0).toFixed(2),
                        claim_patres: parseFloat(0).toFixed(2),
                        claim_paid: jobData?.grid_data?.qdx_tranansaction_type == 'PATIENT_PAY' ? '' : parseFloat(0).toFixed(2),
                        claim_balance: parseFloat(0).toFixed(2),
                        claim_adjustment_total: parseFloat(0).toFixed(2),
                        claim_adjustment_total_pr: parseFloat(0).toFixed(2),

                        sl_billed: parseFloat(0).toFixed(2),
                        sl_discount: parseFloat(0).toFixed(2),
                        sl_allowed: parseFloat(0).toFixed(2),
                        sl_deduct: parseFloat(0).toFixed(2),
                        sl_coins: parseFloat(0).toFixed(2),
                        sl_copay: parseFloat(0).toFixed(2),
                        sl_cob: parseFloat(0).toFixed(2),
                        sl_patres: parseFloat(0).toFixed(2),
                        sl_amount: parseFloat(0).toFixed(2),
                        sl_paid: parseFloat(0).toFixed(2),
                        sl_balance: parseFloat(0).toFixed(2),
                        sl_total: parseFloat(0).toFixed(2),

                        color_data: [],

                        mrn: "",
                        statement_number: "",
                        statement_date: "",

                        patient_id: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",
                        patient_identifier_code: "",

                        subscriber_id: "",
                        subscriber_first_name: "",
                        subscriber_middle_name: "",
                        subscriber_last_name: "",
                        subscriber_identifier_code: "",

                        rendering_provider_id: "",
                        rendering_provider_first_name: "",
                        rendering_provider_last_name: "",
                        rendering_provider_identifier_code: "",

                        // DBK
                        dbk_patient_account_number: "",
                        dbk_payer_claim_number: "",

                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                        dbk_patient_identifier_code: "",

                        dbk_subscriber_first_name: "",
                        dbk_subscriber_middle_name: "",
                        dbk_subscriber_last_name: "",
                        dbk_subscriber_identifier_code: "",

                        dbk_rendering_provider_first_name: "",
                        dbk_rendering_provider_last_name: "",
                        dbk_rendering_provider_identifier_code: "",

                        dbk_mrn: '',
                        dbk_statement_number: '',
                        dbk_statement_date: '',

                        claim_adjustments: [
                            {
                                pc: "",
                                group_code: "",
                                reason_code: "",
                                rmk: "",
                                amount: "",
                                claim_adjustment_id: "",
                            },
                        ],

                        service_lines: [
                            {
                                color_index: "",
                                order: "",
                                service_line_id: "",
                                from_date: "",
                                to_date: "",
                                code: "",
                                corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                                procedure_type: "HC",
                                modifier1: "",
                                modifier2: "",
                                modifier3: "",
                                modifier4: "",
                                rev_code: "",
                                units: "",
                                patres: "",
                                billed: "",
                                discount: "",
                                allowed: "",
                                deduct: "",
                                coins: "",
                                copay: "",
                                cob: "",
                                paid: "",
                                color_data: [],

                                balance: "",
                                adjustments: [
                                    {
                                        adjustment_id: "",
                                        pc: "",
                                        group_code: "",
                                        reason_code: "",
                                        rmk: "",
                                        amount: "",
                                    },
                                ],
                            },
                        ],
                    },
                ]
                pulled = false
            }

            setEobFocus((prevState) => ({
                ...prevState,
                claims: claim_initial_focus,
            }));
        }
        else {
            claims = [
                {
                    corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                    patient: true,
                    subscriber: true,
                    rendering_provider: true,

                    order: "",
                    color_index: "",

                    claim_id: "",
                    patient_account_number: "",
                    payer_claim_number: "",
                    period_start: "",
                    period_end: "",
                    status_code: "",
                    page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : window.location.pathname == DATA_ENTRY ? "1" : "",
                    page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : window.location.pathname == DATA_ENTRY ? "1" : "",


                    claim_billed: jobData?.grid_data?.qdx_tranansaction_type == 'PATIENT_PAY' ? '' : parseFloat(0).toFixed(2),
                    claim_discount: parseFloat(0).toFixed(2),
                    claim_allowed: parseFloat(0).toFixed(2),
                    claim_deduct: parseFloat(0).toFixed(2),
                    claim_coins: parseFloat(0).toFixed(2),
                    claim_copay: parseFloat(0).toFixed(2),
                    claim_cob: parseFloat(0).toFixed(2),
                    claim_patres: parseFloat(0).toFixed(2),
                    claim_paid: jobData?.grid_data?.qdx_tranansaction_type == 'PATIENT_PAY' ? '' : parseFloat(0).toFixed(2),
                    claim_balance: parseFloat(0).toFixed(2),
                    claim_adjustment_total: parseFloat(0).toFixed(2),
                    claim_adjustment_total_pr: parseFloat(0).toFixed(2),

                    sl_billed: parseFloat(0).toFixed(2),
                    sl_discount: parseFloat(0).toFixed(2),
                    sl_allowed: parseFloat(0).toFixed(2),
                    sl_deduct: parseFloat(0).toFixed(2),
                    sl_coins: parseFloat(0).toFixed(2),
                    sl_copay: parseFloat(0).toFixed(2),
                    sl_cob: parseFloat(0).toFixed(2),
                    sl_patres: parseFloat(0).toFixed(2),
                    sl_amount: parseFloat(0).toFixed(2),
                    sl_paid: parseFloat(0).toFixed(2),
                    sl_balance: parseFloat(0).toFixed(2),
                    sl_total: parseFloat(0).toFixed(2),

                    color_data: [],

                    mrn: "",
                    statement_number: "",
                    statement_date: "",

                    patient_id: "",
                    patient_first_name: "",
                    patient_middle_name: "",
                    patient_last_name: "",
                    patient_identifier_code: "",

                    subscriber_id: "",
                    subscriber_first_name: "",
                    subscriber_middle_name: "",
                    subscriber_last_name: "",
                    subscriber_identifier_code: "",

                    rendering_provider_id: "",
                    rendering_provider_first_name: "",
                    rendering_provider_last_name: "",
                    rendering_provider_identifier_code: "",

                    // DBK
                    dbk_patient_account_number: "",
                    dbk_payer_claim_number: "",

                    dbk_patient_first_name: "",
                    dbk_patient_middle_name: "",
                    dbk_patient_last_name: "",
                    dbk_patient_identifier_code: "",

                    dbk_subscriber_first_name: "",
                    dbk_subscriber_middle_name: "",
                    dbk_subscriber_last_name: "",
                    dbk_subscriber_identifier_code: "",

                    dbk_rendering_provider_first_name: "",
                    dbk_rendering_provider_last_name: "",
                    dbk_rendering_provider_identifier_code: "",

                    dbk_mrn: '',
                    dbk_statement_number: '',
                    dbk_statement_date: '',

                    claim_adjustments: [
                        {
                            pc: "",
                            group_code: "",
                            reason_code: "",
                            rmk: "",
                            amount: "",
                            claim_adjustment_id: "",
                        },
                    ],

                    service_lines: [
                        {
                            corrected_serviceline: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                            color_index: "",
                            order: "",
                            service_line_id: "",
                            from_date: "",
                            to_date: "",
                            code: "",
                            procedure_type: "HC",
                            modifier1: "",
                            modifier2: "",
                            modifier3: "",
                            modifier4: "",
                            rev_code: "",
                            units: "",
                            patres: "",
                            billed: "",
                            discount: "",
                            allowed: "",
                            deduct: "",
                            coins: "",
                            copay: "",
                            cob: "",
                            paid: "",
                            color_data: [],

                            balance: "",
                            adjustments: [
                                {
                                    adjustment_id: "",
                                    pc: "",
                                    group_code: "",
                                    reason_code: "",
                                    rmk: "",
                                    amount: "",
                                },
                            ],
                        },
                    ],
                },
            ]
        }

        let remaining = 0;
        let paid_total = 0;
        let plb_total = 0;

        values?.claims?.map((claim, ind) => {
            paid_total =
                parseFloat(paid_total ? paid_total : 0) +
                parseFloat(claim.claim_paid ? claim.claim_paid : 0);
        });
        values?.plb_adjustments?.map((plb, ind) => {
            plb_total =
                parseFloat(plb_total ? plb_total : 0) +
                parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
        });

        remaining =
            parseFloat(values.check_amount ? values.check_amount : 0) -
            parseFloat(paid_total ? paid_total : 0) +
            parseFloat(plb_total ? plb_total : 0);

        if (parseFloat(remaining).toFixed(2) == -0.00) {
            remaining = parseFloat(0).toFixed(2);
        }
        let grid_customization = "NORMAL"
        let qdx_tranansaction_type = ""
        const strsavedData = localStorage.getItem('grid_data')
        const savedData = strsavedData ? JSON.parse(strsavedData) : null
        const strstateData = localStorage.getItem('grid_state_data')
        const stateData = strstateData ? JSON.parse(strstateData) : null
        const strqdxData = localStorage.getItem('grid_qdx_data')
        const qdxData = strqdxData ? JSON.parse(strqdxData) : null
        const strOverAllData = localStorage.getItem('grid_overall_data')
        const overAllData = strOverAllData ? JSON.parse(strOverAllData) : null
        // Adding Error Job
        const error_strsavedData = localStorage.getItem('error_grid_data')
        const error_savedData = error_strsavedData ? JSON.parse(error_strsavedData) : null
        const error_strstateData = localStorage.getItem('error_grid_state_data')
        const error_stateData = error_strstateData ? JSON.parse(error_strstateData) : null
        const error_strqdxData = localStorage.getItem('error_grid_qdx_data')
        const error_qdxData = error_strqdxData ? JSON.parse(error_strqdxData) : null
        const error_strOverAllData = localStorage.getItem('error_grid_overall_data')
        const error_overAllData = error_strOverAllData ? JSON.parse(error_strOverAllData) : null
        const is_job_error = localStorage.getItem('is_job_error')

        if (is_job_error == 'true' && error_savedData && error_savedData?.job != '' && error_savedData?.job == jobData?.job && !isViewOnllyGrid) {
            localStorage.setItem("is_job_error", false);
            localStorage.removeItem('error_grid_data')
            localStorage.removeItem('error_grid_state_data')
            localStorage.removeItem('error_grid_qdx_data')
            localStorage.removeItem('error_grid_overall_data')

            grid_customization = error_savedData?.grid_customization
            const save_claim_initial_focus = []
            if (error_savedData?.claims?.length > 0 && window.location.pathname == DATA_ENTRY) {
                claims = error_savedData?.claims;

                claims.map((claim, index) => {
                    save_claim_initial_focus.push({
                        patient_account_number: false,
                        payer_claim_number: false,

                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,
                        patient_identifier_code: false,

                        subscriber_first_name: false,
                        subscriber_middle_name: false,
                        subscriber_last_name: false,
                        subscriber_identifier_code: false,

                        rendering_provider_first_name: false,
                        rendering_provider_last_name: false,
                        rendering_provider_identifier_code: false,

                        mrn: false,
                        statement_number: false,
                        statement_date: false,

                        // DBK START
                        dbk_patient_account_number: false,
                        dbk_payer_claim_number: false,

                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                        dbk_patient_identifier_code: false,

                        dbk_subscriber_first_name: false,
                        dbk_subscriber_middle_name: false,
                        dbk_subscriber_last_name: false,
                        dbk_subscriber_identifier_code: false,

                        dbk_rendering_provider_first_name: false,
                        dbk_rendering_provider_last_name: false,
                        dbk_rendering_provider_identifier_code: false,

                        dbk_mrn: false,
                        dbk_statement_number: false,
                        dbk_statement_date: false,
                    })
                    // DBK START
                    if (claim?.encounter && claim?.encounter != '') {
                        pulled = true
                    }
                });
                localStorage.setItem('837_pulled', pulled)

            }
            else {
                claims = error_savedData?.claims;
                save_claim_initial_focus.push({
                    patient_account_number: false,
                    payer_claim_number: false,

                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,
                    patient_identifier_code: false,

                    subscriber_first_name: false,
                    subscriber_middle_name: false,
                    subscriber_last_name: false,
                    subscriber_identifier_code: false,

                    rendering_provider_first_name: false,
                    rendering_provider_last_name: false,
                    rendering_provider_identifier_code: false,

                    mrn: false,
                    statement_number: false,
                    statement_date: false,

                    // DBK START
                    dbk_patient_account_number: false,
                    dbk_payer_claim_number: false,

                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                    dbk_patient_identifier_code: false,

                    dbk_subscriber_first_name: false,
                    dbk_subscriber_middle_name: false,
                    dbk_subscriber_last_name: false,
                    dbk_subscriber_identifier_code: false,

                    dbk_rendering_provider_first_name: false,
                    dbk_rendering_provider_last_name: false,
                    dbk_rendering_provider_identifier_code: false,

                    dbk_mrn: false,
                    dbk_statement_number: false,
                    dbk_statement_date: false,
                })
                if (claims?.length > 0) {
                    claims.map((claim, index) => {
                        if (claim?.encounter && claim?.encounter != '') {
                            pulled = true
                        }
                    })
                    if (pulled) {
                        localStorage.setItem('837_pulled', true)

                    }
                    else {
                        localStorage.setItem('837_pulled', false)

                    }
                }
                else {
                    localStorage.setItem('837_pulled', false)
                }
            }
            setEobFocus((prevState) => ({
                ...prevState,
                claims: save_claim_initial_focus,
            }));
            formEob.setValues((prevState) => {
                return {

                    ...error_savedData
                }
            })
            setOverallPage(error_overAllData)
            if (jobData?.grid_data?.transaction_type == "PAYMENT835" && jobData?.grid_data?.status == "partial_save" && error_savedData?.claims?.length > 0) {
                setClaim835Index(error_savedData?.claims?.length - 1);
            } else {
                setClaim835Index(0)
            }

            setQdxTransaction(error_qdxData);
            // if (error_qdxData == "PATIENT_PAY") {
            //     setControlledPosition({ x: -10, y: 580 });
            // }
            qdx_tranansaction_type = error_qdxData

            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: error_savedData?.qdx_status ? error_savedData?.qdx_status : "",
                // reason: error_savedData?.qdx_reason ? error_savedData?.qdx_reason : "",
                reason: error_savedData?.qdx_reason?.length > 0 ? error_savedData?.qdx_reason : [],
                document_classification: error_savedData?.qdx_classification ? error_savedData?.qdx_classification : "",
                is_forced_balancing: error_savedData?.is_forced_balancing ? error_savedData?.is_forced_balancing : false,
                is_missing_check_image: error_savedData?.is_missing_check_image ? error_savedData?.is_missing_check_image : false,
                is_orphaned_check: error_savedData?.is_orphaned_check ? error_savedData?.is_orphaned_check : false,
                is_payer_black_listing: error_savedData?.is_payer_black_listing ? error_savedData?.is_payer_black_listing : false,
                is_payer_excluding_indexing: error_savedData?.is_payer_excluding_indexing ? error_savedData?.is_payer_excluding_indexing : false,
            })

            setcheckNum(error_savedData?.check_page_number);
            if (error_savedData?.check_number == "") {
                setIsCheckNumber(true);
            }
            else {
                setIsCheckNumber(false);
            }

            if (error_savedData?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)

            }

            setTimeout(() => {
                formEob.validateForm();
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    const firstRow = error_savedData?.payment_method &&
                        error_savedData?.check_number &&
                        error_savedData?.check_date &&
                        error_savedData?.check_amount &&
                        error_savedData?.check_page_number
                    let claimAccountNo = document.getElementsByName(`claims[${0}].patient_account_number`);
                    if (jobData?.grid_data?.transaction_type == "PAYMENT835" && jobData?.grid_data?.status == "partial_save" && error_savedData?.claims?.length > 0) {
                        claimAccountNo = document.getElementsByName(`claims[${error_savedData?.claims?.length - 1}].patient_account_number`);
                    }

                    if (firstRow && claimAccountNo[0]?.value == '' && pathname == DATA_ENTRY) {
                        claimAccountNo[0].focus();
                    }
                    else if (activeTab && pathname == DATA_ENTRY) {
                        activeTab.focus();
                    }
                }

            }, 1000)
        }
        else if (savedData && savedData?.job != '' && savedData?.job == jobData?.job && stateData && stateData?.Payment835 && !isViewOnllyGrid) {
            const save_claim_initial_focus = []
            grid_customization = savedData?.grid_customization
            if (savedData?.claims?.length > 0 && window.location.pathname == DATA_ENTRY) {
                claims = savedData?.claims;

                claims.map((claim, index) => {
                    save_claim_initial_focus.push({
                        patient_account_number: false,
                        payer_claim_number: false,

                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,
                        patient_identifier_code: false,

                        subscriber_first_name: false,
                        subscriber_middle_name: false,
                        subscriber_last_name: false,
                        subscriber_identifier_code: false,

                        rendering_provider_first_name: false,
                        rendering_provider_last_name: false,
                        rendering_provider_identifier_code: false,

                        mrn: false,
                        statement_number: false,
                        statement_date: false,

                        // DBK START
                        dbk_patient_account_number: false,
                        dbk_payer_claim_number: false,

                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                        dbk_patient_identifier_code: false,

                        dbk_subscriber_first_name: false,
                        dbk_subscriber_middle_name: false,
                        dbk_subscriber_last_name: false,
                        dbk_subscriber_identifier_code: false,

                        dbk_rendering_provider_first_name: false,
                        dbk_rendering_provider_last_name: false,
                        dbk_rendering_provider_identifier_code: false,

                        dbk_mrn: false,
                        dbk_statement_number: false,
                        dbk_statement_date: false,
                    })
                    // DBK START
                    if (claim?.encounter && claim?.encounter != '') {
                        pulled = true
                    }
                });
                localStorage.setItem('837_pulled', pulled)

            }
            else {
                claims = savedData?.claims;
                save_claim_initial_focus.push({
                    patient_account_number: false,
                    payer_claim_number: false,

                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,
                    patient_identifier_code: false,

                    subscriber_first_name: false,
                    subscriber_middle_name: false,
                    subscriber_last_name: false,
                    subscriber_identifier_code: false,

                    rendering_provider_first_name: false,
                    rendering_provider_last_name: false,
                    rendering_provider_identifier_code: false,

                    mrn: false,
                    statement_number: false,
                    statement_date: false,

                    // DBK START
                    dbk_patient_account_number: false,
                    dbk_payer_claim_number: false,

                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                    dbk_patient_identifier_code: false,

                    dbk_subscriber_first_name: false,
                    dbk_subscriber_middle_name: false,
                    dbk_subscriber_last_name: false,
                    dbk_subscriber_identifier_code: false,

                    dbk_rendering_provider_first_name: false,
                    dbk_rendering_provider_last_name: false,
                    dbk_rendering_provider_identifier_code: false,

                    dbk_mrn: false,
                    dbk_statement_number: false,
                    dbk_statement_date: false,
                })
                if (claims?.length > 0) {
                    claims.map((claim, index) => {
                        if (claim?.encounter && claim?.encounter != '') {
                            pulled = true
                        }
                    })
                    if (pulled) {
                        localStorage.setItem('837_pulled', true)

                    }
                    else {
                        localStorage.setItem('837_pulled', false)

                    }
                }
                else {
                    localStorage.setItem('837_pulled', false)
                }
            }

            setEobFocus((prevState) => ({
                ...prevState,
                claims: save_claim_initial_focus,
            }));
            formEob.setValues((prevState) => {
                return {

                    ...savedData
                }
            })
            setOverallPage(overAllData)
            if (jobData?.grid_data?.transaction_type == "PAYMENT835" && jobData?.grid_data?.status == "partial_save" && savedData?.claims?.length > 0) {
                setClaim835Index(savedData?.claims?.length - 1);
            } else {
                setClaim835Index(0)
            }

            setQdxTransaction(qdxData);
            // if (qdxData == "PATIENT_PAY") {
            //     setControlledPosition({ x: -10, y: 580 });
            // }
            qdx_tranansaction_type = qdxData
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: savedData?.qdx_status ? savedData?.qdx_status : "",
                // reason: savedData?.qdx_reason ? savedData?.qdx_reason : "",
                reason: savedData?.qdx_reason?.length > 0 ? savedData?.qdx_reason : [],
                document_classification: savedData?.qdx_classification ? savedData?.qdx_classification : "",
                is_forced_balancing: savedData?.is_forced_balancing ? savedData?.is_forced_balancing : false,
                is_missing_check_image: savedData?.is_missing_check_image ? savedData?.is_missing_check_image : false,
                is_orphaned_check: savedData?.is_orphaned_check ? savedData?.is_orphaned_check : false,
                is_payer_black_listing: savedData?.is_payer_black_listing ? savedData?.is_payer_black_listing : false,
                is_payer_excluding_indexing: savedData?.is_payer_excluding_indexing ? savedData?.is_payer_excluding_indexing : false,
            })

            setcheckNum(savedData?.check_page_number);
            if (savedData?.check_number == "") {
                setIsCheckNumber(true);
            }
            else {
                setIsCheckNumber(false);
            }

            if (savedData?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)

            }

            setTimeout(() => {
                formEob.validateForm();
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    const firstRow = savedData?.payment_method &&
                        savedData?.check_number &&
                        savedData?.check_date &&
                        savedData?.check_amount &&
                        savedData?.check_page_number
                    let claimAccountNo = document.getElementsByName(`claims[${0}].patient_account_number`);
                    if (jobData?.grid_data?.transaction_type == "PAYMENT835" && jobData?.grid_data?.status == "partial_save" && savedData?.claims?.length > 0) {
                        claimAccountNo = document.getElementsByName(`claims[${savedData?.claims?.length - 1}].patient_account_number`);
                    }

                    if (firstRow && claimAccountNo[0]?.value == '' && pathname == DATA_ENTRY) {
                        claimAccountNo[0].focus();
                    }
                    else if (activeTab && pathname == DATA_ENTRY) {
                        activeTab.focus();
                    }
                }

            }, 1000)
            if (values?.check_number == "") {
                setIsCheckNumber(true);
            }
            else {
                setIsCheckNumber(false);
            }
        }
        else {
            grid_customization = values?.grid_customization ? values?.grid_customization : "NORMAL"

            if (!isViewOnllyGrid) {
                localStorage.removeItem('grid_data')
                localStorage.removeItem('grid_state_data')
                localStorage.removeItem('grid_qdx_data')
                localStorage.removeItem('grid_overall_data')
            }
            localStorage.setItem('837_pulled', pulled)
            await formEob.setValues((prevState) => {


                return {

                    ...prevState,
                    grid_customization: values?.grid_customization ? values?.grid_customization : "NORMAL",
                    job: jobData?.job,
                    client_id: jobData?.client_id,
                    is_first_sub_job: isFirstJob,
                    payment_id:
                        values?.payment_id && values?.payment_id ? values?.payment_id : "",
                    data_837: values?.data_837 ? values?.data_837 : [],
                    transaction_type:
                        values?.transaction_type && values?.transaction_type
                            ? values?.transaction_type
                            : "",

                    payment_method: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.payment_method : values?.payment_method && values?.payment_method != "" ? values?.payment_method :
                        window.location.pathname == DATA_ENTRY && values?.check_amount && values?.check_amount > 0 ? "CHK" : window.location.pathname == DATA_ENTRY && (values?.check_amount == 0 || values?.check_amount == "") ? "NON" : window.location.pathname == DATA_ENTRY && (values?.check_amount == 0 || values?.check_amount == '') ? "COR" : "",
                    // payment_method: values?.payment_method,
                    check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                        ? values?.check_number : window.location.pathname == DATA_ENTRY && values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : window.location.pathname == DATA_ENTRY && values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : window.location.pathname == DATA_ENTRY && values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",

                    check_date: isSubJob && !isFirstJob ? values?.check_date :
                        values?.check_date && values?.check_date ? values?.check_date : "",
                    check_amount: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_amount :
                        values?.check_amount == 0
                            ? parseFloat(0).toFixed(2)
                            : parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                                ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2)
                                : "",
                    remaining_balance: parseFloat(remaining ? remaining : 0).toFixed(2),
                    check_page_number:
                        values?.check_page_number && values?.check_page_number
                            ? values?.check_page_number
                            : "",
                    correspondance:
                        values?.correspondance && values?.correspondance ? values?.correspondance : "",

                    payee_id:
                        values?.payee_id && values?.payee_id != "" ? values?.payee_id : "",
                    payee_name:
                        values?.payee_name && values?.payee_name != ""
                            ? values?.payee_name
                            : "",
                    payee_npi:
                        values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                    payee_address:
                        values?.payee_address && values?.payee_address != ""
                            ? values?.payee_address
                            : "",
                    payee_city:
                        values?.payee_city && values?.payee_city != ""
                            ? values?.payee_city
                            : "",
                    payee_state:
                        values?.payee_state && values?.payee_state != ""
                            ? values?.payee_state
                            : "",
                    payee_zip:
                        values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                    payer_page_from:
                        values?.payer_page_from && values?.payer_page_from != "" ? values?.payer_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payer_page_to:
                        values?.payer_page_to && values?.payer_page_to != "" ? values?.payer_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                    payer_id:
                        values?.payer_id && values?.payer_id != "" ? values?.payer_id : "",
                    payer_name:
                        values?.payer_name && values?.payer_name != ""
                            ? values?.payer_name
                            : "",
                    payer_address:
                        values?.payer_address && values?.payer_address != ""
                            ? values?.payer_address
                            : "",
                    payer_city:
                        values?.payer_city && values?.payer_city != ""
                            ? values?.payer_city
                            : "",
                    payer_state:
                        values?.payer_state && values?.payer_state != ""
                            ? values?.payer_state
                            : "",
                    payer_zip:
                        values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",
                    payee_page_from:
                        values?.payee_page_from && values?.payee_page_from != "" ? values?.payee_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payee_page_to:
                        values?.payee_page_to && values?.payee_page_to != "" ? values?.payee_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",

                    // DBK
                    dbk_check_number: isSubJob && !isFirstJob && window.location.pathname == DATA_ENTRY ? values?.check_number : values?.check_number
                        ? values?.check_number : values?.payment_method == "ACH" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : values?.check_amount == 0 && jobData?.client_level_customization?.is_generated_check_number ? "RX" + jobData?.grid_data?.timezone : values?.payment_method == "" && jobData?.client_level_customization?.is_generated_check_number ? "RM" + jobData?.grid_data?.timezone : "",
                    dbk_check_date: isSubJob && !isFirstJob ? values?.check_date :
                        values?.check_date && values?.check_date ? values?.check_date : "",
                    dbk_check_amount: isSubJob && !isFirstJob ? values?.check_amount :
                        values?.check_amount == 0
                            ? parseFloat(0).toFixed(2)
                            : parseFloat(values?.check_amount)
                                ? parseFloat(values?.check_amount).toFixed(2)
                                : "",
                    dbk_payee_name:
                        values?.payee_name && values?.payee_name != ""
                            ? values?.payee_name
                            : "",
                    dbk_payee_npi:
                        values?.payee_npi && values?.payee_npi != "" ? values?.payee_npi : "",
                    dbk_payee_address:
                        values?.payee_address && values?.payee_address != ""
                            ? values?.payee_address
                            : "",
                    dbk_payee_city:
                        values?.payee_city && values?.payee_city != ""
                            ? values?.payee_city
                            : "",
                    dbk_payee_state:
                        values?.payee_state && values?.payee_state != ""
                            ? values?.payee_state
                            : "",
                    dbk_payee_zip:
                        values?.payee_zip && values?.payee_zip != "" ? values?.payee_zip : "",
                    dbk_payer_name:
                        values?.payer_name && values?.payer_name != ""
                            ? values?.payer_name
                            : "",
                    dbk_payer_address:
                        values?.payer_address && values?.payer_address != ""
                            ? values?.payer_address
                            : "",
                    dbk_payer_city:
                        values?.payer_city && values?.payer_city != ""
                            ? values?.payer_city
                            : "",
                    dbk_payer_state:
                        values?.payer_state && values?.payer_state != ""
                            ? values?.payer_state
                            : "",
                    dbk_payer_zip:
                        values?.payer_zip && values?.payer_zip != "" ? values?.payer_zip : "",

                    qdx_tranansaction_type: values?.qdx_tranansaction_type ? values?.qdx_tranansaction_type : "PAYMENT835",
                    qdx_status: values?.qdx_status ? values?.qdx_status : "",
                    // qdx_reason: values?.qdx_reason ? values?.qdx_reason : "",
                    qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                    qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",
                    is_orphaned_check: values.is_orphaned_check ? values.is_orphaned_check : false,
                    is_missing_check_image: values.is_missing_check_image ? values.is_missing_check_image : false,
                    is_forced_balancing: values.is_forced_balancing ? values.is_forced_balancing : false,
                    is_payer_black_listing: values.is_payer_black_listing ? values.is_payer_black_listing : false,
                    is_payer_excluding_indexing: values.is_payer_excluding_indexing ? values.is_payer_excluding_indexing : false,

                    plb_adjustments: plb_adjustments,

                    claims: claims,
                }


            });
            setOverallPage({
                from: (jobData?.grid_data?.overall_page_from == "" || !jobData?.grid_data?.overall_page_from) && window.location.pathname == DATA_ENTRY ? 1 : jobData?.grid_data?.overall_page_from, to: (jobData?.grid_data?.overall_page_to == "" || !jobData?.grid_data?.overall_page_to) && window.location.pathname == DATA_ENTRY ? 1 : jobData?.grid_data?.overall_page_to,

            })
            if (jobData?.grid_data?.transaction_type == "PAYMENT835" && jobData?.grid_data?.status == "partial_save" && jobData?.grid_data?.claims?.length > 0) {
                setClaim835Index(jobData?.grid_data?.claims?.length - 1);
            } else {
                setClaim835Index(0)
            }

            if (jobData?.grid_data?.qdx_tranansaction_type == "CORRESPONDENCE") {

                setQdxTransaction("CORRESPONDENCE");
            } else if (jobData?.grid_data?.qdx_tranansaction_type == "PATIENT_PAY") {

                setQdxTransaction("PATIENT_PAY");
            } else if (jobData?.grid_data?.qdx_tranansaction_type == "PAYMENT835") {

                setQdxTransaction("PAYMENT835");
            } else {
                setQdxTransaction("PAYMENT835");




            }
            // if (values?.qdx_tranansaction_type == "PATIENT_PAY") {
            //     setControlledPosition({ x: -10, y: 580 });
            // }
            qdx_tranansaction_type = values?.qdx_tranansaction_type
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: values?.qdx_status ? values?.qdx_status : "",
                // reason: values?.qdx_reason ? values?.qdx_reason : "",
                reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                document_classification: values?.qdx_classification ? values?.qdx_classification : "",
                is_forced_balancing: values?.is_forced_balancing ? values?.is_forced_balancing : false,
                is_missing_check_image: values?.is_missing_check_image ? values?.is_missing_check_image : false,
                is_orphaned_check: values?.is_orphaned_check ? values?.is_orphaned_check : false,
                is_payer_black_listing: values?.is_payer_black_listing ? values?.is_payer_black_listing : false,
                is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? values?.is_payer_excluding_indexing : false,
            })
            setTimeout(() => {
                formEob.validateForm();
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    const firstRow = values?.payment_method &&
                        values?.check_number &&
                        values?.check_date &&
                        values?.check_amount &&
                        values?.check_page_number
                    var claimAccountNo = document.getElementsByName(`claims[${0}].patient_account_number`);
                    if (jobData?.grid_data?.transaction_type == "PAYMENT835" && jobData?.grid_data?.status == "partial_save" && jobData?.grid_data?.claims?.length > 0) {
                        claimAccountNo = document.getElementsByName(`claims[${jobData?.grid_data?.claims?.length - 1}].patient_account_number`);
                    }
                    if (firstRow && claimAccountNo[0]?.value == '' && pathname == DATA_ENTRY) {
                        claimAccountNo[0].focus();
                    }
                    else if (activeTab && pathname == DATA_ENTRY) {
                        activeTab.focus();
                    }

                }

            }, 1000)

            setcheckNum(values?.check_page_number);
            if (values?.check_number == "") {
                setIsCheckNumber(true);
            }
            else {
                setIsCheckNumber(false);
            }

            if (values?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)

            }

        }

        handleChangeGridPosition({ x: -10, y: 770 });
        // if (grid_customization == 'PLB_ONLY') {
        // } else {
        //     if (qdx_tranansaction_type == "PATIENT_PAY") {
        //         handleChangeGridPosition({ x: -10, y: 650 });
        //     } else {
        //         handleChangeGridPosition({ x: -10, y: 580 });
        //     }

        // }






        setPayerLookup(false)
        setPayerFocus("")
        setPayerValue("")
        setPayerIndex(0)
        setPayerData()

        setPayeeLookup(false)
        setPayeeFocus("")
        setPayeeValue("")
        setPayeeIndex(0)
        setPayeeData()


        setAdditionalModal(false);
        setIsDrag(true);
        setPcPopup(false);
        setPcClaimPopup(false)
        formRc.handleReset()
        formClaimRc.handleReset()
        rejectForm.handleReset()
        formReassign.handleReset()
        setAssignTooltip(false);
        setRejectPopup(false)

    };


    const handleEobLite835Data = async () => {
        const _ = require('lodash');
        const values = _.cloneDeep(jobData?.grid_data);

        setIsOverallPage(jobData?.client_level_customization?.is_overall_page_from_to)
        setIsPayerPage(jobData?.client_level_customization?.is_payer_page_from_to)
        setIsPayeePage(jobData?.client_level_customization?.is_payee_page_from_to)


        setIsAdditionalInfoOptions({
            status: jobData?.client_level_customization?.is_additional_info_status,
            reason: jobData?.client_level_customization?.is_additional_info_reason,
            document_classification: jobData?.client_level_customization?.is_additional_info_document_classification,
            extra_info: jobData?.client_level_customization?.is_additional_info_extra_info,
        })

        setIsAdditional(jobData?.client_level_customization?.is_additional_info_available)

        var payment_method = "";
        if (window.location.pathname == DATA_ENTRY) {
            if (values.check_amount == parseFloat(0).toFixed(2)) {
                payment_method = "NON";
            } else if (parseFloat(values.check_amount) > parseFloat(0)) {
                payment_method = "CHK";
            } else {
                payment_method = "ACH";
            }

        }
        else {
            payment_method = values.payment_method && ['ACH', 'CHK', 'NON', 'VCP'].includes(values.payment_method) ? values.payment_method : "";
        }
        var claims = [];
        let claim_initial_focus = []
        if (jobData && jobData?.grid_data && jobData?.grid_data?.transaction_type == "PAYMENT835_LITE") {
            var plb_adjustments = values?.plb_adjustments;


            if (plb_adjustments && plb_adjustments?.length > 0) {
                plb_adjustments.map((plb) => {
                    plb.plb_amt = parseFloat(plb.plb_amt ? plb.plb_amt : 0).toFixed(2);
                });
            }
            if (values.claims?.length > 0) {
                claims = values?.claims;
                claims.map((claim, index) => {

                    claim_initial_focus.push({
                        patient_account_number: false,
                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,

                        // DBK START
                        dbk_patient_account_number: false,
                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                    })



                    claim.corrected_claim = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;
                    claim.claim_corrected_index = index;
                    claim.claim_id = claim?.claim_id ? claim?.claim_id : "";
                    claim.order = index ? index : "";
                    claim.patient_id = claim?.patient_id ? claim?.patient_id : "";
                    claim.patient_account_number = claim?.patient_account_number ? claim?.patient_account_number : "";
                    claim.patient_first_name = claim?.patient_first_name ? claim?.patient_first_name : "";
                    claim.patient_middle_name = claim?.patient_middle_name ? claim?.patient_middle_name : "";
                    claim.patient_last_name = claim?.patient_last_name ? claim?.patient_last_name : "";

                    //   DBK
                    claim.dbk_patient_account_number = claim?.patient_account_number ? claim?.patient_account_number : "";
                    claim.dbk_patient_first_name = claim?.patient_first_name ? claim?.patient_first_name : "";
                    claim.dbk_patient_middle_name = claim?.patient_middle_name ? claim?.patient_middle_name : "";
                    claim.dbk_patient_last_name = claim?.patient_last_name ? claim?.patient_last_name : "";

                    claim.page_from = claim.page_from ? claim.page_from : window.location.pathname == DATA_ENTRY ? "1" : "";
                    claim.page_to = claim.page_to ? claim.page_to : window.location.pathname == DATA_ENTRY ? "1" : "";

                    claim.service_lines.service_line_id = claim.service_lines.service_line_id ? claim.service_lines.service_line_id : "";
                    claim.service_lines.period_start = claim.service_lines.period_start != "" && claim.service_lines.period_start != null ? moment(claim.service_lines.period_start).format("MM/DD/YYYY") ? moment(claim.service_lines.period_start).format("MM/DD/YYYY") : "" : "";
                    claim.service_lines.period_end = claim.service_lines.period_end != "" && claim.service_lines.period_end != null ? moment(claim.service_lines.period_end).format("MM/DD/YYYY") ? moment(claim.service_lines.period_end).format("MM/DD/YYYY") : "" : "";
                    claim.service_lines.billed = parseFloat(claim.service_lines.billed).toFixed(2) ? parseFloat(claim.service_lines.billed ? claim.service_lines.billed : 0).toFixed(2) : "";
                    claim.service_lines.paid = parseFloat(claim.service_lines.paid).toFixed(2) ? parseFloat(claim.service_lines.paid ? claim.service_lines.paid : 0).toFixed(2) : "";
                });
            } else {
                claims = [
                    {
                        corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                        claim_id: "",
                        order: "",
                        patient_id: "",
                        patient_account_number: "",
                        patient_first_name: "",
                        patient_middle_name: "",
                        patient_last_name: "",

                        page_from: window.location.pathname == DATA_ENTRY ? "1" : "",
                        page_to: window.location.pathname == DATA_ENTRY ? "1" : "",
                        service_lines: {
                            service_line_id: "",
                            period_start: "",
                            period_end: "",
                            billed: "",
                            paid: "",
                        },

                        dbk_patient_account_number: "",
                        dbk_patient_first_name: "",
                        dbk_patient_middle_name: "",
                        dbk_patient_last_name: "",
                    },
                ];
            }

            setEobLiteFocus((prevState) => ({
                ...prevState,
                claims: claim_initial_focus,
            }));

        } else {
            claims = [
                {
                    corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,
                    claim_id: "",
                    order: "",
                    patient_id: "",
                    patient_account_number: "",
                    patient_first_name: "",
                    patient_middle_name: "",
                    patient_last_name: "",

                    page_from: window.location.pathname == DATA_ENTRY ? "1" : "",
                    page_to: window.location.pathname == DATA_ENTRY ? "1" : "",
                    service_lines: {
                        service_line_id: "",
                        period_start: "",
                        period_end: "",
                        billed: "",
                        paid: "",
                    },

                    dbk_patient_account_number: "",
                    dbk_patient_first_name: "",
                    dbk_patient_middle_name: "",
                    dbk_patient_last_name: "",
                },
            ];
        }

        let remaining = 0;
        let paid_total = 0;
        let plb_total = 0;

        values?.claims?.map((claim, ind) => {
            paid_total +=
                parseFloat(claim?.service_lines?.paid ? claim?.service_lines?.paid : 0);
        });
        jobData?.grid_data?.transaction_type == "PAYMENT835_LITE" && values?.plb_adjustments?.map((plb, ind) => {
            plb_total =
                parseFloat(plb_total ? plb_total : 0) +
                parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
        });

        remaining =
            parseFloat(values.check_amount ? values.check_amount : 0) -
            parseFloat(paid_total ? paid_total : 0) +
            parseFloat(plb_total ? plb_total : 0);

        if (parseFloat(remaining).toFixed(2) == -0.00) {
            remaining = parseFloat(0).toFixed(2);
        }
        let grid_customization = "NORMAL"
        let qdx_tranansaction_type = ""
        const strsavedData = localStorage.getItem('grid_data')
        const savedData = strsavedData ? JSON.parse(strsavedData) : null
        const strstateData = localStorage.getItem('grid_state_data')
        const stateData = strstateData ? JSON.parse(strstateData) : null
        const strOverAllData = localStorage.getItem('grid_overall_data')
        const overAllData = strOverAllData ? JSON.parse(strOverAllData) : null
        // Adding Error Job
        const error_strsavedData = localStorage.getItem('error_grid_data')
        const error_savedData = error_strsavedData ? JSON.parse(error_strsavedData) : null
        const error_strstateData = localStorage.getItem('error_grid_state_data')
        const error_stateData = error_strstateData ? JSON.parse(error_strstateData) : null
        const error_strqdxData = localStorage.getItem('error_grid_qdx_data')
        const error_qdxData = error_strqdxData ? JSON.parse(error_strqdxData) : null
        const error_strOverAllData = localStorage.getItem('error_grid_overall_data')
        const error_overAllData = error_strOverAllData ? JSON.parse(error_strOverAllData) : null
        const is_job_error = localStorage.getItem('is_job_error')

        if (is_job_error == 'true' && error_savedData && error_savedData?.job != '' && error_savedData?.job == jobData?.job && !isViewOnllyGrid) {
            localStorage.setItem("is_job_error", false);
            localStorage.removeItem('error_grid_data')
            localStorage.removeItem('error_grid_state_data')
            localStorage.removeItem('error_grid_qdx_data')
            localStorage.removeItem('error_grid_overall_data')
            grid_customization = error_savedData?.grid_customization

            const save_claim_initial_focus = []
            if (error_savedData?.claims?.length > 0 && window.location.pathname == DATA_ENTRY) {
                claims = error_savedData?.claims;

                claims.map((claim, index) => {

                    save_claim_initial_focus.push({
                        patient_account_number: false,
                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,

                        // DBK START
                        dbk_patient_account_number: false,
                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                    })
                });

            }
            else {
                save_claim_initial_focus.push({
                    patient_account_number: false,
                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,

                    // DBK START
                    dbk_patient_account_number: false,
                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                })

            }

            setEobLiteFocus((prevState) => ({
                ...prevState,
                claims: save_claim_initial_focus,
            }));
            eobLiteFormik.setValues((prevState) => {
                return {

                    ...error_savedData
                }
            })
            setOverallPage(error_overAllData)
            if (jobData?.grid_data?.transaction_type == "PAYMENT835_LITE" && jobData?.grid_data?.status == "partial_save" && error_savedData?.claims?.length > 0) {
                setClaimLiteIndex(error_savedData?.claims?.length - 1);
            } else {
                setClaimLiteIndex(0)
            }
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: error_savedData?.qdx_status ? error_savedData?.qdx_status : "",
                reason: error_savedData?.qdx_reason?.length > 0 ? error_savedData?.qdx_reason : [],
                document_classification: error_savedData?.qdx_classification ? error_savedData?.qdx_classification : "",
                is_forced_balancing: error_savedData?.is_forced_balancing ? error_savedData?.is_forced_balancing : false,
                is_missing_check_image: error_savedData?.is_missing_check_image ? error_savedData?.is_missing_check_image : false,
                is_orphaned_check: error_savedData?.is_orphaned_check ? error_savedData?.is_orphaned_check : false,
                is_payer_black_listing: error_savedData?.is_payer_black_listing ? error_savedData?.is_payer_black_listing : false,
                is_payer_excluding_indexing: error_savedData?.is_payer_excluding_indexing ? error_savedData?.is_payer_excluding_indexing : false,

            })

            setTimeout(() => {
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    if (activeTab) {
                        activeTab.focus();
                    }
                }


            }, 1000)
            if (error_savedData?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)

            }
        }
        else if (savedData && savedData?.job != '' && savedData?.job == jobData?.job && stateData && stateData?.Payment835Lite && !isViewOnllyGrid) {
            const save_claim_initial_focus = []
            grid_customization = savedData?.grid_customization
            if (savedData?.claims?.length > 0 && window.location.pathname == DATA_ENTRY) {
                claims = savedData?.claims;

                claims.map((claim, index) => {

                    save_claim_initial_focus.push({
                        patient_account_number: false,
                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,

                        // DBK START
                        dbk_patient_account_number: false,
                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                    })
                });

            }
            else {
                save_claim_initial_focus.push({
                    patient_account_number: false,
                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,

                    // DBK START
                    dbk_patient_account_number: false,
                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                })

            }

            setEobLiteFocus((prevState) => ({
                ...prevState,
                claims: save_claim_initial_focus,
            }));
            eobLiteFormik.setValues((prevState) => {
                return {

                    ...savedData
                }
            })
            setOverallPage(overAllData)
            if (jobData?.grid_data?.transaction_type == "PAYMENT835_LITE" && jobData?.grid_data?.status == "partial_save" && savedData?.claims?.length > 0) {
                setClaimLiteIndex(savedData?.claims?.length - 1);
            } else {
                setClaimLiteIndex(0)
            }
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: savedData?.qdx_status ? savedData?.qdx_status : "",
                reason: savedData?.qdx_reason?.length > 0 ? savedData?.qdx_reason : [],
                document_classification: savedData?.qdx_classification ? savedData?.qdx_classification : "",
                is_forced_balancing: savedData?.is_forced_balancing ? savedData?.is_forced_balancing : false,
                is_missing_check_image: savedData?.is_missing_check_image ? savedData?.is_missing_check_image : false,
                is_orphaned_check: savedData?.is_orphaned_check ? savedData?.is_orphaned_check : false,
                is_payer_black_listing: savedData?.is_payer_black_listing ? savedData?.is_payer_black_listing : false,
                is_payer_excluding_indexing: savedData?.is_payer_excluding_indexing ? savedData?.is_payer_excluding_indexing : false,

            })

            setTimeout(() => {
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    if (activeTab) {
                        activeTab.focus();
                    }
                }


            }, 1000)
            if (savedData?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)

            }
        }
        else {
            grid_customization = values?.grid_customization ? values?.grid_customization : "NORMAL"

            if (!isViewOnllyGrid) {
                localStorage.removeItem('grid_data')
                localStorage.removeItem('grid_state_data')
                localStorage.removeItem('grid_qdx_data')
                localStorage.removeItem('grid_overall_data')
            }
            await eobLiteFormik.setValues((prevState) => {
                return {

                    ...prevState,
                    grid_customization: values?.grid_customization ? values?.grid_customization : "NORMAL",
                    job: jobData?.job,
                    client: values?.client,
                    check_number: values?.check_number,
                    payment_method: payment_method,
                    remaining_balance: parseFloat(remaining).toFixed(2),
                    payment_id: values?.payment_id,
                    check_date: values?.check_date ? moment(values?.check_date).format("MM/DD/YYYY") : "",
                    check_amount: parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2),
                    dbk_check_amount: parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2),
                    dbk_check_number: values?.check_number,
                    dbk_check_date: values?.check_date ? moment(values?.check_date).format("MM/DD/YYYY") : "",
                    dbk_payer_name: values?.payer_name,
                    dbk_payee_name: values?.payee_name,
                    payer_id: values?.payer_id,
                    payer_name: values?.payer_name,
                    payer_page_from: values?.payer_page_from ? values?.payer_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payer_page_to: values?.payer_page_to ? values?.payer_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payee_id: values?.payee_id,
                    payee_name: values?.payee_name,
                    payee_page_from: values?.payee_page_from ? values?.payee_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                    payee_page_to: values?.payee_page_to ? values?.payee_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",
                    qdx_status: values?.qdx_status ? values?.qdx_status : "",
                    qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                    qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",
                    is_orphaned_check: values.is_orphaned_check ? values.is_orphaned_check : false,
                    is_missing_check_image: values.is_missing_check_image ? values.is_missing_check_image : false,
                    is_forced_balancing: values.is_forced_balancing ? values.is_forced_balancing : false,
                    is_payer_black_listing: values.is_payer_black_listing ? values.is_payer_black_listing : false,
                    is_payer_excluding_indexing: values.is_payer_excluding_indexing ? values.is_payer_excluding_indexing : false,

                    plb_adjustments: plb_adjustments,
                    claims: claims,
                }

            });

            setOverallPage({
                from: (jobData?.grid_data?.overall_page_from == "" || !jobData?.grid_data?.overall_page_from) && window.location.pathname == DATA_ENTRY ? 1 : jobData?.grid_data?.overall_page_from, to: (jobData?.grid_data?.overall_page_to == "" || !jobData?.grid_data?.overall_page_to) && window.location.pathname == DATA_ENTRY ? 1 : jobData?.grid_data?.overall_page_to,

            })


            if (jobData?.grid_data?.transaction_type == "PAYMENT835_LITE" && jobData?.grid_data?.status == "partial_save" && jobData?.grid_data?.claims?.length > 0) {
                setClaimLiteIndex(jobData?.grid_data?.claims?.length - 1);
            } else {
                setClaimLiteIndex(0)
            }
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: values?.qdx_status ? values?.qdx_status : "",
                reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                document_classification: values?.qdx_classification ? values?.qdx_classification : "",
                is_forced_balancing: values?.is_forced_balancing ? values?.is_forced_balancing : false,
                is_missing_check_image: values?.is_missing_check_image ? values?.is_missing_check_image : false,
                is_orphaned_check: values?.is_orphaned_check ? values?.is_orphaned_check : false,
                is_payer_black_listing: values?.is_payer_black_listing ? values?.is_payer_black_listing : false,
                is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? values?.is_payer_excluding_indexing : false,

            })
            setTimeout(() => {
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    if (activeTab) {
                        activeTab.focus();
                    }
                }

            }, 1000)
        }
        handleChangeGridPosition({ x: -10, y: 800 });
        // if (grid_customization == "PLB_ONLY") {
        // } else {
        //     handleChangeGridPosition({ x: -10, y: 700 });
        // }
        setcheckNum("")
        setPayerLookup(false)
        setPayerFocus("")
        setPayerValue("")
        setPayerIndex(0)
        setPayerData()

        setPayeeLookup(false)
        setPayeeFocus("")
        setPayeeValue("")
        setPayeeIndex(0)
        setPayeeData()

        setAdditionalModal(false);
        setIsDrag(true);
        setPcPopup(false);
        setPcClaimPopup(false)
        formRc.handleReset()
        formClaimRc.handleReset()
        rejectForm.handleReset()
        formReassign.handleReset()
        setAssignTooltip(false);
        setRejectPopup(false)
    };

    const handlePatientPayData = async () => {
        const values = jobData?.grid_data;
        setIsAdditional(jobData?.client_level_customization?.is_additional_info_available)
        setIsOverallPage(jobData?.client_level_customization?.is_overall_page_from_to)
        setIsAdditionalInfoOptions({
            status: jobData?.client_level_customization?.is_additional_info_status,
            reason: jobData?.client_level_customization?.is_additional_info_reason,
            document_classification: jobData?.client_level_customization?.is_additional_info_document_classification,
            extra_info: jobData?.client_level_customization?.is_additional_info_extra_info,
        })
        setIsAdditional(jobData?.client_level_customization?.is_additional_info_available)
        const strsavedData = localStorage.getItem('grid_data')
        const savedData = strsavedData ? JSON.parse(strsavedData) : null
        const strstateData = localStorage.getItem('grid_state_data')
        const stateData = strstateData ? JSON.parse(strstateData) : null
        const strOverAllData = localStorage.getItem('grid_overall_data')
        const overAllData = strOverAllData ? JSON.parse(strOverAllData) : null
        // Adding Error Job
        const error_strsavedData = localStorage.getItem('error_grid_data')
        const error_savedData = error_strsavedData ? JSON.parse(error_strsavedData) : null
        const error_strOverAllData = localStorage.getItem('error_grid_overall_data')
        const error_overAllData = error_strOverAllData ? JSON.parse(error_strOverAllData) : null
        const is_job_error = localStorage.getItem('is_job_error')

        if (is_job_error == 'true' && error_savedData && error_savedData?.job != '' && error_savedData?.job == jobData?.job && !isViewOnllyGrid) {
            localStorage.setItem("is_job_error", false);
            localStorage.removeItem('error_grid_data')
            localStorage.removeItem('error_grid_state_data')
            localStorage.removeItem('error_grid_qdx_data')
            localStorage.removeItem('error_grid_overall_data')

            formPP.setValues((prevState) => {
                return {
                    ...error_savedData
                }
            })
            setOverallPage(error_overAllData)
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.error_savedData,
                status: error_savedData?.qdx_status ? error_savedData?.qdx_status : "",
                reason: error_savedData?.qdx_reason?.length > 0 ? error_savedData?.qdx_reason : [],
                document_classification: error_savedData?.qdx_classification ? error_savedData?.qdx_classification : "",
                is_forced_balancing: error_savedData?.is_forced_balancing ? error_savedData?.is_forced_balancing : false,
                is_missing_check_image: error_savedData?.is_missing_check_image ? error_savedData?.is_missing_check_image : false,
                is_orphaned_check: error_savedData?.is_orphaned_check ? error_savedData?.is_orphaned_check : false,
                is_payer_black_listing: error_savedData?.is_payer_black_listing ? error_savedData?.is_payer_black_listing : false,
                is_payer_excluding_indexing: error_savedData?.is_payer_excluding_indexing ? error_savedData?.is_payer_excluding_indexing : false,
            })
            if (error_savedData?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)
            } setTimeout(() => {
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    if (activeTab) {
                        activeTab.focus();
                    }
                }


            }, 300)

        }
        else if (savedData && savedData?.job != '' && savedData?.job == jobData?.job && stateData && stateData?.PatientPay && !isViewOnllyGrid) {

            const save_claim_initial_focus = []
            if (savedData?.claims?.length > 0 && window.location.pathname == DATA_ENTRY) {
                claims = savedData?.claims;

                claims.map((claim, index) => {

                    save_claim_initial_focus.push({
                        check_number: false,
                        check_date: false,
                        check_amount: false,
                        patient_account_number: false,
                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,
                        amount_due: false,
                        statement_number: false,
                        statement_amount: false,
                        statement_date: false,

                        // DBK
                        dbk_check_number: false,
                        dbk_check_date: false,
                        dbk_check_amount: false,
                        dbk_patient_account_number: false,
                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                        dbk_amount_due: false,
                        dbk_statement_number: false,
                        dbk_statement_amount: false,
                        dbk_statement_date: false,
                    })
                });

            }
            else {
                save_claim_initial_focus.push({
                    check_number: false,
                    check_date: false,
                    check_amount: false,
                    patient_account_number: false,
                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,
                    amount_due: false,
                    statement_number: false,
                    statement_amount: false,
                    statement_date: false,

                    // DBK
                    dbk_check_number: false,
                    dbk_check_date: false,
                    dbk_check_amount: false,
                    dbk_patient_account_number: false,
                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                    dbk_amount_due: false,
                    dbk_statement_number: false,
                    dbk_statement_amount: false,
                    dbk_statement_date: false,
                })

            }

            setPatientPayFocus((prevState) => ({
                ...prevState,
                claims: save_claim_initial_focus,
            }));
            // const strstateData = localStorage.getItem('grid_state_data')
            // const stateData = strsavedData ? JSON.parse(strstateData) : null
            // if (stateData?.PatientPay) {
            // setSwalPopup(true)
            // Swal.fire({
            //     icon: 'success',
            //     confirmButtonColor: '#6ba2b6',
            //     confirmButtonText: 'YES',
            //     cancelButtonText: 'NO',
            //     showCancelButton: true,
            //     backdrop: false,
            //     title: 'LOAD SAVED DATA?',
            //     customClass: {
            //         title: 'swal-titleText fs-5'
            //     }
            // }).then((result) => {
            //     if (result?.isConfirmed) {
            //         setSwalPopup(false)
            formPP.setValues((prevState) => {
                return {
                    ...savedData
                }
            })
            setOverallPage(overAllData)
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.savedData,
                status: savedData?.qdx_status ? savedData?.qdx_status : "",
                reason: savedData?.qdx_reason?.length > 0 ? savedData?.qdx_reason : [],
                document_classification: savedData?.qdx_classification ? savedData?.qdx_classification : "",
                is_forced_balancing: savedData?.is_forced_balancing ? savedData?.is_forced_balancing : false,
                is_missing_check_image: savedData?.is_missing_check_image ? savedData?.is_missing_check_image : false,
                is_orphaned_check: savedData?.is_orphaned_check ? savedData?.is_orphaned_check : false,
                is_payer_black_listing: savedData?.is_payer_black_listing ? savedData?.is_payer_black_listing : false,
                is_payer_excluding_indexing: savedData?.is_payer_excluding_indexing ? savedData?.is_payer_excluding_indexing : false,
            })
            if (savedData?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)
            } setTimeout(() => {
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    if (activeTab) {
                        activeTab.focus();
                    }
                }


            }, 300)

        }
        else {
            if (!isViewOnllyGrid) {
                localStorage.removeItem('grid_data')
                localStorage.removeItem('grid_state_data')
                localStorage.removeItem('grid_qdx_data')
                localStorage.removeItem('grid_overall_data')
            }
            await formPP.setValues({
                ...formPP.values,
                check_number: values?.check_number ? values?.check_number : "",
                check_date: values?.check_date ? values?.check_date : "",
                is_check_amount: values?.check_amount ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2) : "",
                check_amount: values?.check_amount !== undefined && values?.check_amount !== null ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2) : "",
                job: jobData?.job,
                payment_id: values?.payment_id ? values?.payment_id : "",
                patient_account_number: values?.patient_account_number
                    ? values?.patient_account_number
                    : "",
                patient_first_name: values?.patient_first_name
                    ? values?.patient_first_name
                    : "",
                patient_middle_name: values?.patient_middle_name
                    ? values?.patient_middle_name
                    : "",
                patient_last_name: values?.patient_last_name
                    ? values?.patient_last_name
                    : "",
                patient_page_from: values?.patient_page_from ? values?.patient_page_from : window.location.pathname == DATA_ENTRY ? 1 : '',
                patient_page_to: values?.patient_page_to ? values?.patient_page_to : window.location.pathname == DATA_ENTRY ? 1 : "",

                amount_due: parseFloat(values?.amount_due ? values?.amount_due : 0) ? parseFloat(values?.amount_due ? values?.amount_due : 0).toFixed(2) : "",
                statement_number: values?.statement_number ? values?.statement_number : "",
                statement_amount: parseFloat(values?.statement_amount ? values?.statement_amount : 0) ? parseFloat(values?.statement_amount ? values?.statement_amount : 0).toFixed(2) : "",
                statement_date: values?.statement_date ? values?.statement_date : "",
                dbk_check_number: values?.check_number ? values?.check_number : "",
                dbk_check_date: values?.check_date ? values?.check_date : "",
                dbk_check_amount: values?.check_amount ? parseFloat(values?.check_amount ? values?.check_amount : 0).toFixed(2) : "",
                dbk_patient_account_number: values?.patient_account_number
                    ? values?.patient_account_number
                    : "",
                dbk_patient_first_name: values?.patient_first_name
                    ? values?.patient_first_name
                    : "",
                dbk_patient_middle_name: values?.patient_middle_name
                    ? values?.patient_middle_name
                    : "",
                dbk_patient_last_name: values?.patient_last_name
                    ? values?.patient_last_name
                    : "",
                dbk_amount_due: values?.amount_due ? parseFloat(values?.amount_due ? values?.amount_due : 0).toFixed(2) : "",
                dbk_statement_number: values?.statement_number ? values?.statement_number : "",
                dbk_statement_amount: values?.statement_amount ? parseFloat(values?.statement_amount ? values?.statement_amount : 0).toFixed(2) : "",
                dbk_statement_date: values?.statement_date ? values?.statement_date : "",

                qdx_status: values?.qdx_status ? values?.qdx_status : "",
                qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                is_orphaned_check: values.is_orphaned_check ? values.is_orphaned_check : false,
                is_missing_check_image: values.is_missing_check_image ? values.is_missing_check_image : false,
                is_forced_balancing: values.is_forced_balancing ? values.is_forced_balancing : false,
                is_payer_black_listing: values.is_payer_black_listing ? values.is_payer_black_listing : false,
                is_payer_excluding_indexing: values.is_payer_excluding_indexing ? values.is_payer_excluding_indexing : false,


                qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",
            });
            setOverallPage({
                from: (jobData?.grid_data?.overall_page_from == "" || !jobData?.grid_data?.overall_page_from) && window.location.pathname == DATA_ENTRY ? 1 : jobData?.grid_data?.overall_page_from, to: (jobData?.grid_data?.overall_page_to == "" || !jobData?.grid_data?.overall_page_to) && window.location.pathname == DATA_ENTRY ? 1 : jobData?.grid_data?.overall_page_to,

            })
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: values?.qdx_status ? values?.qdx_status : "",
                reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                document_classification: values?.qdx_classification ? values?.qdx_classification : "",
                is_forced_balancing: values?.is_forced_balancing ? values?.is_forced_balancing : false,
                is_missing_check_image: values?.is_missing_check_image ? values?.is_missing_check_image : false,
                is_orphaned_check: values?.is_orphaned_check ? values?.is_orphaned_check : false,
                is_payer_black_listing: values?.is_payer_black_listing ? values?.is_payer_black_listing : false,
                is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? values?.is_payer_excluding_indexing : false,

            })
            if (values?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)

            }
            setTimeout(() => {
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    if (activeTab) {
                        activeTab.focus();
                    }
                }


            }, 300)
        }
        setPatientIndex(0)
        setcheckNum("")
        setAdditionalModal(false);
        setIsDrag(true);
        setPcPopup(false);
        setPcClaimPopup(false)
        formRc.handleReset()
        formClaimRc.handleReset()
        rejectForm.handleReset()
        formReassign.handleReset()
        setAssignTooltip(false);
        setRejectPopup(false)
    };

    const handleCorresspondenceData = async () => {
        const values = jobData?.grid_data;
        setIsOverallPage(jobData?.client_level_customization?.is_overall_page_from_to)
        setIsPayerPage(jobData?.client_level_customization?.is_payer_page_from_to)
        setIsPayeePage(jobData?.client_level_customization?.is_payee_page_from_to)
        setIsAdditionalInfoOptions({
            status: jobData?.client_level_customization?.is_additional_info_status,
            reason: jobData?.client_level_customization?.is_additional_info_reason,
            document_classification: jobData?.client_level_customization?.is_additional_info_document_classification,
            extra_info: jobData?.client_level_customization?.is_additional_info_extra_info,
        })
        setIsAdditional(jobData?.client_level_customization?.is_additional_info_available)
        const data = jobData;
        var claims = [];
        let claim_initial_focus = []
        if (jobData && jobData?.grid_data && jobData?.grid_data?.transaction_type == "CORRESPONDENCE") {
            claims = values?.claims;
            values?.claims && values?.claims?.length > 0
                ? values?.claims?.map((claim, index) => {

                    claim_initial_focus.push({
                        patient_account_number: false,
                        period_start: false,
                        period_end: false,
                        billed: false,

                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,

                        dbk_payer_name: false,
                        dbk_check_date: false,

                        dbk_patient_account_number: false,
                        dbk_period_start: false,
                        dbk_period_end: false,
                        dbk_billed: false,

                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                    })
                    claim.corrected_claim = window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false;

                    claim.claim_corrected_index = index;
                    claim.claim_id = claim && claim?.claim_id;
                    claim.order = claim && claim?.claim_id;
                    claim.patient_account_number = claim && claim?.patient_account_number;
                    claim.period_start =
                        claim && claim?.period_start ? moment(claim?.period_start).format("MM/DD/YYYY") : "";
                    claim.period_end =
                        claim && claim?.period_end ? moment(claim?.period_end).format("MM/DD/YYYY") : "";
                    claim.billed = claim && parseFloat(claim.billed ? claim.billed : 0).toFixed(2);
                    claim.patient_id = claim && claim?.patient_id;
                    claim.patient_first_name = claim && claim?.patient_first_name;
                    claim.patient_middle_name = claim && claim?.patient_middle_name;
                    claim.patient_last_name = claim && claim?.patient_last_name;
                    claim.page_from = claim && claim?.page_from;
                    claim.page_to = claim && claim?.page_to;
                    //   DBK
                    claim.dbk_patient_account_number =
                        claim && claim?.patient_account_number;
                    claim.dbk_period_start =
                        claim && claim?.period_start ? moment(claim?.period_start).format("MM/DD/YYYY") : "";;
                    claim.dbk_period_end =
                        claim && claim?.period_end ? moment(claim?.period_end).format("MM/DD/YYYY") : "";
                    claim.dbk_billed = claim && claim?.billed;
                    claim.dbk_patient_first_name = claim && claim?.patient_first_name;
                    claim.dbk_patient_middle_name = claim && claim?.patient_middle_name;
                    claim.dbk_patient_last_name = claim && claim?.patient_last_name;
                })
                :
                (
                    claim_initial_focus.push({
                        patient_account_number: false,
                        period_start: false,
                        period_end: false,
                        billed: false,

                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,

                        // DBK START
                        dbk_payer_name: false,
                        dbk_check_date: false,

                        dbk_patient_account_number: false,
                        dbk_period_start: false,
                        dbk_period_end: false,
                        dbk_billed: false,

                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                    }),

                    claims = [
                        {
                            claim_id: "",
                            order: "1",
                            patient_account_number: "",
                            period_start: "",
                            period_end: "",
                            billed: "",
                            corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,

                            patient_id: "",
                            patient_first_name: "",
                            patient_middle_name: "",
                            patient_last_name: "",

                            page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : window.location.pathname == DATA_ENTRY ? "1" : "",
                            page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : window.location.pathname == DATA_ENTRY ? "1" : "",

                            dbk_patient_account_number: "",
                            dbk_patient_first_name: "",
                            dbk_patient_middle_name: "",
                            dbk_patient_last_name: "",
                            dbk_billed: "",
                            dbk_period_start: "",
                            dbk_period_end: "",
                        },
                    ]);
            setCorresPondanceFocus((prevState) => ({
                ...prevState,
                claims: claim_initial_focus,
            }));
        }

        else {
            claims = [
                {
                    claim_id: "",
                    order: "1",
                    patient_account_number: "",
                    period_start: "",
                    period_end: "",
                    billed: "",
                    corrected_claim: window.location.pathname == DATA_ENTRY && !['partial_save', 'qa_complete', 'qa_review'].includes(values?.status) ? true : false,

                    patient_id: "",
                    patient_first_name: "",
                    patient_middle_name: "",
                    patient_last_name: "",

                    page_from: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : window.location.pathname == DATA_ENTRY ? "1" : "",
                    page_to: jobData?.is_large_batch_job && jobData?.header_range && jobData?.header_range?.length > 0 ? jobData?.header_range?.length + 2 : window.location.pathname == DATA_ENTRY ? "1" : "",

                    dbk_patient_account_number: "",
                    dbk_patient_first_name: "",
                    dbk_patient_middle_name: "",
                    dbk_patient_last_name: "",
                    dbk_billed: "",
                    dbk_period_start: "",
                    dbk_period_end: "",
                },
            ]
        }
        const strsavedData = localStorage.getItem('grid_data')
        const savedData = strsavedData ? JSON.parse(strsavedData) : null
        const strstateData = localStorage.getItem('grid_state_data')
        const stateData = strstateData ? JSON.parse(strstateData) : null
        const strOverAllData = localStorage.getItem('grid_overall_data')
        const overAllData = strOverAllData ? JSON.parse(strOverAllData) : null

        // Adding Error Job
        const error_strsavedData = localStorage.getItem('error_grid_data')
        const error_savedData = error_strsavedData ? JSON.parse(error_strsavedData) : null
        const error_strOverAllData = localStorage.getItem('error_grid_overall_data')
        const error_overAllData = error_strOverAllData ? JSON.parse(error_strOverAllData) : null
        const is_job_error = localStorage.getItem('is_job_error')

        if (is_job_error == 'true' && error_savedData && error_savedData?.job != '' && error_savedData?.job == jobData?.job && !isViewOnllyGrid) {
            localStorage.setItem("is_job_error", false);
            localStorage.removeItem('error_grid_data')
            localStorage.removeItem('error_grid_state_data')
            localStorage.removeItem('error_grid_qdx_data')
            localStorage.removeItem('error_grid_overall_data')
            const save_claim_initial_focus = []
            if (error_savedData?.claims?.length > 0 && window.location.pathname == DATA_ENTRY) {
                claims = error_savedData?.claims;

                claims.map((claim, index) => {

                    save_claim_initial_focus.push({
                        patient_account_number: false,
                        period_start: false,
                        period_end: false,
                        billed: false,

                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,

                        // DBK START
                        dbk_payer_name: false,
                        dbk_check_date: false,

                        dbk_patient_account_number: false,
                        dbk_period_start: false,
                        dbk_period_end: false,
                        dbk_billed: false,

                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                    })
                });

            }
            else {
                save_claim_initial_focus.push({
                    patient_account_number: false,
                    period_start: false,
                    period_end: false,
                    billed: false,

                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,

                    // DBK START
                    dbk_payer_name: false,
                    dbk_check_date: false,

                    dbk_patient_account_number: false,
                    dbk_period_start: false,
                    dbk_period_end: false,
                    dbk_billed: false,

                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                })

            }

            setCorresPondanceFocus((prevState) => ({
                ...prevState,
                claims: save_claim_initial_focus,
            }));
            // setSwalPopup(true)
            // Swal.fire({
            //     icon: 'success',
            //     confirmButtonColor: '#6ba2b6',
            //     confirmButtonText: 'YES',
            //     cancelButtonText: 'NO',
            //     showCancelButton: true,
            //     backdrop: false,
            //     title: 'LOAD SAVED DATA?',
            //     customClass: {
            //         title: 'swal-titleText fs-5'
            //     }
            // }).then((result) => {
            //     if (result?.isConfirmed) {
            //         setSwalPopup(false)
            formCorres.setValues((prevState) => {
                return {

                    ...error_savedData
                }
            })
            setOverallPage(error_overAllData)
            if (jobData?.grid_data?.transaction_type == "CORRESPONDENCE" && jobData?.grid_data?.status == "partial_save" && error_savedData?.claims?.length > 0) {
                setClaimCorresIndex(error_savedData?.claims?.length - 1);
            } else {
                setClaimCorresIndex(0)
            }
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: error_savedData?.qdx_status ? error_savedData?.qdx_status : "",
                reason: error_savedData?.qdx_reason?.length > 0 ? error_savedData?.qdx_reason : [],
                document_classification: error_savedData?.qdx_classification ? error_savedData?.qdx_classification : "",
                is_forced_balancing: error_savedData?.is_forced_balancing ? error_savedData?.is_forced_balancing : false,
                is_missing_check_image: error_savedData?.is_missing_check_image ? error_savedData?.is_missing_check_image : false,
                is_orphaned_check: error_savedData?.is_orphaned_check ? error_savedData?.is_orphaned_check : false,
                is_payer_black_listing: error_savedData?.is_payer_black_listing ? error_savedData?.is_payer_black_listing : false,
                is_payer_excluding_indexing: error_savedData?.is_payer_excluding_indexing ? error_savedData?.is_payer_excluding_indexing : false,

            })
            if (error_savedData?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)

            }
            setTimeout(() => {
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    if (activeTab) {
                        activeTab.focus();
                    }
                }
            }, 300)
        }
        else if (savedData && savedData?.job != '' && savedData?.job == jobData?.job && stateData && stateData?.Correspondence && !isViewOnllyGrid) {
            const save_claim_initial_focus = []
            if (savedData?.claims?.length > 0 && window.location.pathname == DATA_ENTRY) {
                claims = savedData?.claims;

                claims.map((claim, index) => {

                    save_claim_initial_focus.push({
                        patient_account_number: false,
                        period_start: false,
                        period_end: false,
                        billed: false,

                        patient_first_name: false,
                        patient_middle_name: false,
                        patient_last_name: false,

                        // DBK START
                        dbk_payer_name: false,
                        dbk_check_date: false,

                        dbk_patient_account_number: false,
                        dbk_period_start: false,
                        dbk_period_end: false,
                        dbk_billed: false,

                        dbk_patient_first_name: false,
                        dbk_patient_middle_name: false,
                        dbk_patient_last_name: false,
                    })
                });

            }
            else {
                save_claim_initial_focus.push({
                    patient_account_number: false,
                    period_start: false,
                    period_end: false,
                    billed: false,

                    patient_first_name: false,
                    patient_middle_name: false,
                    patient_last_name: false,

                    // DBK START
                    dbk_payer_name: false,
                    dbk_check_date: false,

                    dbk_patient_account_number: false,
                    dbk_period_start: false,
                    dbk_period_end: false,
                    dbk_billed: false,

                    dbk_patient_first_name: false,
                    dbk_patient_middle_name: false,
                    dbk_patient_last_name: false,
                })

            }

            setCorresPondanceFocus((prevState) => ({
                ...prevState,
                claims: save_claim_initial_focus,
            }));
            // setSwalPopup(true)
            // Swal.fire({
            //     icon: 'success',
            //     confirmButtonColor: '#6ba2b6',
            //     confirmButtonText: 'YES',
            //     cancelButtonText: 'NO',
            //     showCancelButton: true,
            //     backdrop: false,
            //     title: 'LOAD SAVED DATA?',
            //     customClass: {
            //         title: 'swal-titleText fs-5'
            //     }
            // }).then((result) => {
            //     if (result?.isConfirmed) {
            //         setSwalPopup(false)
            formCorres.setValues((prevState) => {
                return {

                    ...savedData
                }
            })
            setOverallPage(overAllData)
            if (jobData?.grid_data?.transaction_type == "CORRESPONDENCE" && jobData?.grid_data?.status == "partial_save" && savedData?.claims?.length > 0) {
                setClaimCorresIndex(savedData?.claims?.length - 1);
            } else {
                setClaimCorresIndex(0)
            }
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: savedData?.qdx_status ? savedData?.qdx_status : "",
                reason: savedData?.qdx_reason?.length > 0 ? savedData?.qdx_reason : [],
                document_classification: savedData?.qdx_classification ? savedData?.qdx_classification : "",
                is_forced_balancing: savedData?.is_forced_balancing ? savedData?.is_forced_balancing : false,
                is_missing_check_image: savedData?.is_missing_check_image ? savedData?.is_missing_check_image : false,
                is_orphaned_check: savedData?.is_orphaned_check ? savedData?.is_orphaned_check : false,
                is_payer_black_listing: savedData?.is_payer_black_listing ? savedData?.is_payer_black_listing : false,
                is_payer_excluding_indexing: savedData?.is_payer_excluding_indexing ? savedData?.is_payer_excluding_indexing : false,

            })
            if (savedData?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)

            }
            setTimeout(() => {
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    if (activeTab) {
                        activeTab.focus();
                    }
                }
            }, 300)
        }
        else {
            if (!isViewOnllyGrid) {
                localStorage.removeItem('grid_data')
                localStorage.removeItem('grid_state_data')
                localStorage.removeItem('grid_qdx_data')
                localStorage.removeItem('grid_overall_data')
            }
            await formCorres.setValues({
                ...formCorres.values,
                job: jobData?.job,
                payment_id: values?.payment_id ? values?.payment_id : null,
                payer_name: values?.payer_name ? values?.payer_name : "",
                payer_page_from: values?.payer_page_from ? values?.payer_page_from : window.location.pathname == DATA_ENTRY ? "1" : "",
                payer_page_to: values?.payer_page_to ? values?.payer_page_to : window.location.pathname == DATA_ENTRY ? "1" : "",
                is_first_sub_job: isFirstJob,
                letter_date:
                    values && values?.check_date
                        ? values?.check_date
                        : values.letter_date
                            ? values?.letter_date
                            : "",
                // DBK
                dbk_payer_name: values?.payer_name ? values?.payer_name : "",
                dbk_letter_date:
                    values && values?.check_date
                        ? values?.check_date
                        : values.letter_date
                            ? values?.letter_date
                            : "",
                correspondance:
                    values && values?.correspondance ? values?.correspondance : "",

                qdx_status: values?.qdx_status ? values?.qdx_status : "",
                qdx_reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                qdx_classification: values?.qdx_classification ? values?.qdx_classification : "",
                is_orphaned_check: values.is_orphaned_check ? values.is_orphaned_check : false,
                is_missing_check_image: values.is_missing_check_image ? values.is_missing_check_image : false,
                is_forced_balancing: values.is_forced_balancing ? values.is_forced_balancing : false,
                is_payer_black_listing: values.is_payer_black_listing ? values.is_payer_black_listing : false,
                is_payer_excluding_indexing: values.is_payer_excluding_indexing ? values.is_payer_excluding_indexing : false,

                transaction_type: "CORRESPONDENCE",
                claims: claims,
            });
            setOverallPage({
                from: (jobData?.grid_data?.overall_page_from == "" || !jobData?.grid_data?.overall_page_from) && window.location.pathname == DATA_ENTRY ? 1 : jobData?.grid_data?.overall_page_from, to: (jobData?.grid_data?.overall_page_to == "" || !jobData?.grid_data?.overall_page_to) && window.location.pathname == DATA_ENTRY ? 1 : jobData?.grid_data?.overall_page_to,

            })
            if (jobData?.grid_data?.transaction_type == "CORRESPONDENCE" && jobData?.grid_data?.status == "partial_save" && jobData?.grid_data?.claims?.length > 0) {
                setClaimCorresIndex(jobData?.grid_data?.claims?.length - 1);
            } else {
                setClaimCorresIndex(0)
            }
            await aditionalInfoFormik.setValues({
                ...aditionalInfoFormik.values,
                status: values?.qdx_status ? values?.qdx_status : "",
                reason: values?.qdx_reasons?.length > 0 ? values?.qdx_reasons : [],
                document_classification: values?.qdx_classification ? values?.qdx_classification : "",
                is_forced_balancing: values?.is_forced_balancing ? values?.is_forced_balancing : false,
                is_missing_check_image: values?.is_missing_check_image ? values?.is_missing_check_image : false,
                is_orphaned_check: values?.is_orphaned_check ? values?.is_orphaned_check : false,
                is_payer_black_listing: values?.is_payer_black_listing ? values?.is_payer_black_listing : false,
                is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? values?.is_payer_excluding_indexing : false,

            })
            if (values?.qdx_status == "INCOMPLETE") {
                setIsIncomplete(true)
            } else {
                setIsIncomplete(false)

            }
            setTimeout(() => {
                const grid = document.getElementById('grid-full-container')
                if (grid) {
                    const activeTab = grid.querySelector('[tabindex="0"]')
                    if (activeTab) {
                        activeTab.focus();
                    }
                }
            }, 300)
        }


        setcheckNum("")
        setAdditionalModal(false);
        setIsDrag(true);
        setPcPopup(false);
        setPcClaimPopup(false)
        formRc.handleReset()
        formClaimRc.handleReset()
        rejectForm.handleReset()
        formReassign.handleReset()
        setAssignTooltip(false);
        setRejectPopup(false)
    };


    //===============================================   TRANSACTION TYPE  FORMIK DATA HANDLERS ends ============================================//

    // ============================================== PLB ADJUSTMENTS FUNCTIONS starts ============================================= //
    const handleAddPlbAdjustment = async () => {

        await formEob.setValues((prevState) => {
            const _ = require('lodash');
            let data = _.cloneDeep(prevState.plb_adjustments);

            if (data && data?.length > 0) {
                data.push({
                    plb_adjustment_id: "",
                    corrected_plb: true,
                    npi: "",
                    plb_page: "1",
                    reason: 0,
                    patient_account_number: "",
                    plb_amt: "",
                });
            } else {
                data = [
                    {
                        plb_adjustment_id: "",
                        corrected_plb: true,
                        npi: "",
                        plb_page: "1",
                        reason: 0,
                        patient_account_number: "",
                        plb_amt: "",
                    },
                ];
            }

            return {
                ...prevState,
                plb_adjustments: data,
            };
        });


    };

    const handleLiteAddPlbAdjustment = () => {
        var data = eobLiteFormik.values.plb_adjustments;
        if (data && data?.length > 0) {
            data?.push({
                plb_adjustment_id: "",
                corrected_plb: true,
                npi: "",
                plb_page: "1",
                reason: 0,
                patient_account_number: "",
                plb_amt: "",
            });
        } else {
            data = [
                {
                    plb_adjustment_id: "",
                    corrected_plb: true,
                    npi: "",
                    plb_page: "1",
                    reason: 0,
                    patient_account_number: "",
                    plb_amt: "",
                },
            ];
        }

        eobLiteFormik.setValues({
            ...eobLiteFormik.values,
            plb_adjustments: data,
        });
    };
    // ============================================== PLB ADJUSTMENTS FUNCTIONS ends ============================================= //

    // ============================================== NEW CLAIMS OF 4 TRANSACTION TYPE FUNCTIONS =================================//
    // new claim create on its near the current Index
    const createNewClaim = async () => {

        setClaim835Index(claim835Index + 1);

        await formEob.setValues((prevValues) => {
            const data = [...prevValues.claims];
            const new_claim = {
                patient: true,
                subscriber: true,
                rendering_provider: true,
                corrected_claim: true,

                order: "",
                color_index: "",

                claim_id: "",
                patient_account_number: "",
                payer_claim_number: "",
                period_start: "",
                period_end: "",
                status_code: "",
                page_from: currentPage + 1,
                page_to: currentPage + 1,

                claim_billed: qdxTransaction == "PATIENT_PAY" ? '' : parseFloat(0).toFixed(2),
                claim_discount: "",
                claim_allowed: "",
                claim_deduct: "",
                claim_coins: "",
                claim_copay: "",
                claim_cob: "",
                claim_patres: parseFloat(0).toFixed(2),
                claim_paid: qdxTransaction == "PATIENT_PAY" ? '' : parseFloat(0).toFixed(2),
                claim_balance: "",

                sl_billed: parseFloat(0).toFixed(2),
                sl_discount: parseFloat(0).toFixed(2),
                sl_allowed: parseFloat(0).toFixed(2),
                sl_deduct: parseFloat(0).toFixed(2),
                sl_coins: parseFloat(0).toFixed(2),
                sl_copay: parseFloat(0).toFixed(2),
                sl_cob: parseFloat(0).toFixed(2),
                sl_patres: parseFloat(0).toFixed(2),
                sl_paid: parseFloat(0).toFixed(2),
                sl_balance: parseFloat(0).toFixed(2),
                sl_total: parseFloat(0).toFixed(2),

                color_data: [],

                patient_id: "",
                patient_first_name: "",
                patient_middle_name: "",
                patient_last_name: "",
                patient_identifier_code: "",

                subscriber_id: "",
                subscriber_first_name: "",
                subscriber_middle_name: "",
                subscriber_last_name: "",
                subscriber_identifier_code: "",

                rendering_provider_id: "",
                rendering_provider_first_name: "",
                rendering_provider_last_name: "",
                rendering_provider_identifier_code: "",
                status_code: qdxTransaction == "PATIENT_PAY" ? '1' : '',
                mrn: '',
                statement_number: '',
                statement_date: '',

                dbk_patient_account_number: "",
                dbk_payer_claim_number: "",

                dbk_patient_first_name: "",
                dbk_patient_middle_name: "",
                dbk_patient_last_name: "",
                dbk_patient_identifier_code: "",

                dbk_subscriber_first_name: "",
                dbk_subscriber_middle_name: "",
                dbk_subscriber_last_name: "",
                dbk_subscriber_identifier_code: "",

                dbk_rendering_provider_first_name: "",
                dbk_rendering_provider_last_name: "",
                dbk_rendering_provider_identifier_code: "",

                dbk_mrn: '',
                dbk_statement_number: '',
                dbk_statement_date: '',

                claim_adjustments: [
                    {
                        pc: "",
                        group_code: "",
                        reason_code: "",
                        rmk: "",
                        amount: "",
                        claim_adjustment_id: "",
                    },
                ],

                service_lines: [
                    {
                        color_index: "",
                        order: "",
                        service_line_id: "",
                        from_date: "",
                        to_date: "",
                        code: "",
                        corrected_serviceline: true,
                        procedure_type: "HC",
                        modifier1: "",
                        modifier2: "",
                        modifier3: "",
                        modifier4: "",
                        rev_code: "",
                        units: "",
                        billed: "",
                        patres: "",
                        discount: "",
                        allowed: "",
                        deduct: "",
                        coins: "",
                        copay: "",
                        cob: "",
                        paid: "",
                        color_data: [],

                        balance: "",
                        adjustments: [
                            {
                                adjustment_id: "",
                                pc: "",
                                group_code: "",
                                reason_code: "",
                                rmk: "",
                                amount: "",
                            },
                        ],
                    },
                ],
            };

            data.splice(claim835Index + 1, 0, new_claim);

            return {
                ...prevValues,
                claims: data,
            };
        });


        await addingNewEobClaimDbk();
    };

    const copyClaim = async () => {


        await formEob.setValues(prevValues => {
            const data = [...prevValues.claims];
            const initialValues = prevValues.claims[claim835Index];

            const new_claim = {
                ...initialValues,
                corrected_claim: true,
                claim_id: "",
                color_index: "",
                page_from: currentPage + 1,
                page_to: currentPage + 1,
                color_data: [],
                patient_id: "",
                subscriber_id: "",
                rendering_provider_id: "",
                claim_adjustments: initialValues.claim_adjustments.map(adjustment => ({
                    ...adjustment,
                    claim_adjustment_id: "",
                })),
                service_lines: qdxTransaction == "PATIENT_PAY" ? [] : initialValues.service_lines.map(serviceLine => ({
                    ...serviceLine,
                    service_line_id: "",
                    color_data: [],
                    procedure_type: "HC",
                    corrected_serviceline: true,
                    adjustments: serviceLine.adjustments.map(adjustment => ({
                        ...adjustment,
                        adjustment_id: "",
                    })),
                })),
            };

            data.splice(claim835Index + 1, 0, new_claim);

            setClaim835Index(claim835Index + 1);

            return {
                ...prevValues,
                claims: [...data],
            };
        });


        //    adding New Eob DBK Focus Fields
        await addingNewEobClaimDbk();
        await handleRemainingBalanceCalculation();
    };


    // ============================================== NEW CLAIMS OF 4 TRANSACTION TYPE FUNCTIONS =================================//

    // ============================================== NEW SERVICELINE FUNCTIONS =================================//

    const [newPoupUp, setNewPoupUp] = useState(false);
    const [slCount, setSlCount] = useState(0);

    const handleNewServiceLine = async (e) => {

        const new_service_line = {
            color_index: "",
            order: "",
            service_line_id: "",
            corrected_serviceline: true,
            from_date: "",
            to_date: "",
            code: "",
            procedure_type: "HC",
            modifier1: "",
            modifier2: "",
            modifier3: "",
            modifier4: "",
            rev_code: "",
            units: "",
            billed: "",
            patres: "",
            discount: "",
            allowed: "",
            deduct: "",
            coins: "",
            copay: "",
            cob: "",
            paid: "",
            color_data: [],
            balance: "",
            adjustments: [
                {
                    adjustment_id: "",
                    pc: "",
                    group_code: "",
                    reason_code: "",
                    rmk: "",
                    amount: "",
                },
            ],
        };
        await formEob.setValues((prevValues) => {
            if (e.ctrlKey) {
                setNewPoupUp(true);
                setIsDrag(false);
                return prevValues; // Return the unchanged values
            } else {



                return {
                    ...prevValues,
                    claims: prevValues.claims.map((claim, index) => {
                        if (index === claim835Index) {
                            return {
                                ...claim,
                                service_lines: [
                                    ...claim.service_lines?.slice(0, serviceIndex + 1),
                                    new_service_line,
                                    ...claim.service_lines?.slice(serviceIndex + 1),
                                ],
                            };
                        }
                        return claim;
                    }),
                };
            }


        });



    };
    // ============================ EOB 835 CLACULATION ============================//
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    const handleRemainingBalanceCalculation = async () => {
        await formEob.setValues((prevState) => {
            let remaining = 0;
            let paid_total = 0;
            let plb_total = 0;

            prevState?.claims?.map((claim, ind) => {
                paid_total =
                    parseFloat(paid_total ? paid_total : 0) +
                    parseFloat(claim.claim_paid ? claim.claim_paid : 0);
            });
            prevState?.plb_adjustments?.map((plb, ind) => {
                plb_total =
                    parseFloat(plb_total ? plb_total : 0) +
                    parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
            });

            remaining =
                parseFloat(prevState.check_amount ? prevState.check_amount : 0) -
                parseFloat(paid_total ? paid_total : 0) +
                parseFloat(plb_total ? plb_total : 0);


            return {
                ...prevState,
                remaining_balance: parseFloat(remaining).toFixed(2),
            };
        });
    };


    const handleLiteRemainingBalanceCalculation = async () => {
        await eobLiteFormik.setValues((prevValues) => {
            let remaining = 0;
            let paid_total = 0;
            let plb_total = 0;

            prevValues.claims?.forEach((claim) => {
                paid_total +=
                    parseFloat(claim?.service_lines?.paid ? claim?.service_lines?.paid : 0);
            });

            prevValues?.plb_adjustments?.forEach((plb) => {
                plb_total += parseFloat(plb?.plb_amt ? plb?.plb_amt : 0);
            });

            remaining =
                parseFloat(prevValues.check_amount ? prevValues.check_amount : 0) -
                parseFloat(paid_total ? paid_total : 0) +
                parseFloat(plb_total ? plb_total : 0);

            if (parseFloat(remaining).toFixed(2) === "-0.00") {
                remaining = parseFloat(0).toFixed(2);
            }

            return {
                ...prevValues,
                remaining_balance: parseFloat(remaining).toFixed(2),
            };
        });
        return true;
    };
    const handleColumnRowCalculation = async (sfIndex, type) => {
        await formEob.setValues((prevState) => {
            let sl_total = 0;
            const datas = prevState.claims;

            if (type === "amount") {
                datas[claim835Index].service_lines.map((service_line, ind) => {
                    service_line?.adjustments?.map((adjustment, adj) => {
                        sl_total =
                            parseFloat(sl_total ? sl_total : 0) +
                            parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                    });
                });
            } else {
                datas[claim835Index]?.service_lines?.map((service_line, ind) => {
                    sl_total =
                        parseFloat(sl_total ? sl_total : 0) +
                        parseFloat(service_line[type] ? service_line[type] : 0);
                });
            }

            switch (type) {
                case "billed":
                    datas[claim835Index].claim_billed = parseFloat(sl_total).toFixed(2);
                    datas[claim835Index].sl_billed = parseFloat(sl_total).toFixed(2);
                    break;
                case "discount":
                    datas[claim835Index].claim_discount = parseFloat(sl_total).toFixed(2);
                    datas[claim835Index].sl_discount = parseFloat(sl_total).toFixed(2);
                    break;
                case "allowed":
                    datas[claim835Index].claim_allowed = parseFloat(sl_total).toFixed(2);
                    datas[claim835Index].sl_allowed = parseFloat(sl_total).toFixed(2);
                    break;
                case "deduct":
                    datas[claim835Index].claim_deduct = parseFloat(sl_total).toFixed(2);
                    datas[claim835Index].sl_deduct = parseFloat(sl_total).toFixed(2);
                    break;
                case "coins":
                    datas[claim835Index].claim_coins = parseFloat(sl_total).toFixed(2);
                    datas[claim835Index].sl_coins = parseFloat(sl_total).toFixed(2);
                    break;
                case "copay":
                    datas[claim835Index].claim_copay = parseFloat(sl_total).toFixed(2);
                    datas[claim835Index].sl_copay = parseFloat(sl_total).toFixed(2);
                    break;
                case "cob":
                    datas[claim835Index].claim_cob = parseFloat(sl_total).toFixed(2);
                    datas[claim835Index].sl_cob = parseFloat(sl_total).toFixed(2);
                    break;
                case "paid":
                    datas[claim835Index].claim_paid = parseFloat(sl_total).toFixed(2);
                    datas[claim835Index].sl_paid = parseFloat(sl_total).toFixed(2);
                    break;
                case "amount":
                    datas[claim835Index].sl_amount = parseFloat(sl_total).toFixed(2);
                    break;
                default:
                    break;
            }

            let total = 0;
            let amount_total = 0;
            const serviceLine = datas[claim835Index]?.service_lines[sfIndex];



            serviceLine?.adjustments?.map((adjustment, ind) => {
                amount_total =
                    parseFloat(amount_total ? amount_total : 0) +
                    parseFloat(adjustment?.amount ? adjustment?.amount : 0);
            });

            total =
                parseFloat(serviceLine?.billed ? serviceLine?.billed : 0) -
                parseFloat(serviceLine?.discount ? serviceLine?.discount : 0) -
                parseFloat(serviceLine?.deduct ? serviceLine?.deduct : 0) -
                parseFloat(serviceLine?.coins ? serviceLine?.coins : 0) -
                parseFloat(serviceLine?.copay ? serviceLine?.copay : 0) -
                parseFloat(serviceLine?.cob ? serviceLine?.cob : 0) -
                parseFloat(serviceLine?.paid ? serviceLine?.paid : 0) -
                parseFloat(amount_total ? amount_total : 0);

            if (parseFloat(total).toFixed(2) == -0.0) {
                total = parseFloat(0).toFixed(2);
            }

            return {
                ...prevState,
                claims: prevState.claims.map((claim, index) => {
                    if (index === claim835Index) {
                        const updatedServiceLines = claim.service_lines.map((line, sfIndex) => {
                            if (sfIndex === sfIndex) {
                                return {
                                    ...line,
                                    balance: parseFloat(total).toFixed(2),
                                };
                            }
                            return line;
                        });

                        return {
                            ...claim,
                            service_lines: updatedServiceLines,
                        };
                    }
                    return claim;
                }),
            };
        });




    }
    const handleColumnCalculation = async (sfindex, type) => {

        await formEob.setValues((prevState) => {


            return {
                ...prevState,
                claims: prevState.claims.map((claim, claimIndex) => {
                    if (claimIndex === claim835Index) {
                        const cclaims = [...prevState.claims];
                        let sl_total = 0;

                        if (type === "amount") {
                            claim.service_lines.forEach(service_line => {
                                service_line?.adjustments?.forEach(adjustment => {
                                    sl_total += parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                                });
                            });
                        } else {
                            claim?.service_lines?.forEach(service_line => {
                                sl_total += parseFloat(service_line[type] ? service_line[type] : 0);
                            });
                        }

                        switch (type) {
                            case "billed":
                                claim.claim_billed = parseFloat(sl_total).toFixed(2);
                                claim.sl_billed = parseFloat(sl_total).toFixed(2);
                                break;
                            case "discount":
                                claim.claim_discount = parseFloat(sl_total).toFixed(2);
                                claim.sl_discount = parseFloat(sl_total).toFixed(2);
                                break;
                            case "allowed":
                                claim.claim_allowed = parseFloat(sl_total).toFixed(2);
                                claim.sl_allowed = parseFloat(sl_total).toFixed(2);
                                break;
                            case "deduct":
                                claim.claim_deduct = parseFloat(sl_total).toFixed(2);
                                claim.sl_deduct = parseFloat(sl_total).toFixed(2);
                                break;
                            case "coins":
                                claim.claim_coins = parseFloat(sl_total).toFixed(2);
                                claim.sl_coins = parseFloat(sl_total).toFixed(2);
                                break;
                            case "copay":
                                claim.claim_copay = parseFloat(sl_total).toFixed(2);
                                claim.sl_copay = parseFloat(sl_total).toFixed(2);
                                break;
                            case "cob":
                                claim.claim_cob = parseFloat(sl_total).toFixed(2);
                                claim.sl_cob = parseFloat(sl_total).toFixed(2);
                                break;
                            case "paid":
                                claim.claim_paid = parseFloat(sl_total).toFixed(2);
                                claim.sl_paid = parseFloat(sl_total).toFixed(2);
                                break;
                            case "amount":
                                claim.sl_amount = parseFloat(sl_total).toFixed(2);
                                break;
                            default:
                                break;
                        }

                        return claim;
                    } else {
                        return claim;
                    }
                }),
            };

        });


    };

    const handleSlAndClaimDifference = async (claim) => {

        let sl_total = 0;
        let claim_total = 0;
        let diff = 0;
        const _ = require('lodash');
        sl_total = parseFloat(sl_total ? sl_total : 0) +
            parseFloat(claim?.sl_deduct ? claim?.sl_deduct : 0) +
            parseFloat(claim?.sl_coins ? claim?.sl_coins : 0) +
            parseFloat(claim?.sl_copay ? claim?.sl_copay : 0);


        claim_total = parseFloat(claim_total ? claim_total : 0) +
            parseFloat(claim?.claim_deduct ? claim?.claim_deduct : 0) +
            parseFloat(claim?.claim_coins ? claim?.claim_coins : 0) +
            parseFloat(claim?.claim_copay ? claim?.claim_copay : 0);


        if (Math.abs(parseFloat(sl_total).toFixed(2) - parseFloat(claim_total).toFixed(2)) != parseFloat(0).toFixed(2)) {
            diff = Math.abs(parseFloat(sl_total).toFixed(2) - parseFloat(claim_total).toFixed(2))

        }


        return diff;
    }


    const handleClaimCalculation = async () => {
        await formEob.setValues((prevState) => {

            return {
                ...prevState,
                claims: prevState.claims.map((claim, ind) => {
                    if (ind === claim835Index) {
                        let total = 0;
                        let amount_total = 0;

                        claim.claim_adjustments?.map((adjustment, ind) => {
                            amount_total = parseFloat(amount_total ? amount_total : 0) + parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                        });

                        total = parseFloat(claim.claim_billed ? claim.claim_billed : 0) -
                            parseFloat(claim.claim_discount ? claim.claim_discount : 0) -
                            parseFloat(claim.claim_deduct ? claim.claim_deduct : 0) -
                            parseFloat(claim.claim_coins ? claim.claim_coins : 0) -
                            parseFloat(claim.claim_copay ? claim.claim_copay : 0) -
                            parseFloat(claim.claim_cob ? claim.claim_cob : 0) -
                            parseFloat(claim.claim_paid ? claim.claim_paid : 0) -
                            parseFloat(claim.adjustment_total ? claim.adjustment_total : 0) -
                            parseFloat(amount_total ? amount_total : 0);

                        return {
                            ...claim,
                            claim_balance: parseFloat(total ? total : 0).toFixed(2),
                        };
                    }
                    return claim;
                }),
            };
        });

        await handleClaimPatresCalculation()

        return true;




    }


    const handleSlCalculation = async () => {
        await formEob.setValues((prevValues) => {


            return {
                ...prevValues,
                claims: prevValues.claims.map((claim, claimIndex) => {
                    if (claimIndex === claim835Index) {
                        const datas = Object.assign({}, claim);
                        let total = 0;
                        let amount_total = 0;

                        datas.claim_adjustments?.map((adjustment, ind) => {
                            amount_total = parseFloat(amount_total ? amount_total : 0) + parseFloat(adjustment.amount ? adjustment.amount : 0);
                        });

                        total =
                            parseFloat(datas.sl_billed ? datas.sl_billed : 0) -
                            parseFloat(datas.sl_discount ? datas.sl_discount : 0) -
                            parseFloat(datas.sl_deduct ? datas.sl_deduct : 0) -
                            parseFloat(datas.sl_coins ? datas.sl_coins : 0) -
                            parseFloat(datas.sl_copay ? datas.sl_copay : 0) -
                            parseFloat(datas.sl_cob ? datas.sl_cob : 0) -
                            parseFloat(datas.sl_paid ? datas.sl_paid : 0) -
                            parseFloat(datas.sl_amount ? datas.sl_amount : 0);

                        datas.sl_balance = parseFloat(total ? total : 0).toFixed(2);

                        if (parseFloat(total).toFixed(2) == -0.0) {
                            total = parseFloat(0).toFixed(2);
                        }

                        return datas;
                    } else {
                        return claim;
                    }
                }),
            };

        });

        return true;


    }

    const handleRowCalculation = async (sfIndex) => {
        await formEob.setValues((prevState) => {


            let total = 0;
            let amount_total = 0;

            const serviceLine = prevState?.claims[claim835Index]?.service_lines[sfIndex];

            serviceLine?.adjustments?.map((adjustment, ind) => {
                amount_total =
                    parseFloat(amount_total ? amount_total : 0) +
                    parseFloat(adjustment?.amount ? adjustment?.amount : 0);
            });
            total =
                parseFloat(serviceLine?.billed ? serviceLine?.billed : 0) -
                parseFloat(serviceLine?.discount ? serviceLine?.discount : 0) -
                parseFloat(serviceLine?.deduct ? serviceLine?.deduct : 0) -
                parseFloat(serviceLine?.coins ? serviceLine?.coins : 0) -
                parseFloat(serviceLine?.copay ? serviceLine?.copay : 0) -
                parseFloat(serviceLine?.cob ? serviceLine?.cob : 0) -
                parseFloat(serviceLine?.paid ? serviceLine?.paid : 0) -
                parseFloat(amount_total ? amount_total : 0);

            if (parseFloat(total).toFixed(2) == -0.0) {
                total = parseFloat(0).toFixed(2);
            }

            const newData = {
                ...prevState,
                claims: prevState.claims.map((claim, index) => {
                    if (index === claim835Index) {
                        const updatedServiceLines = claim.service_lines.map(
                            (line, sfindex) => {
                                if (sfindex === sfIndex) {
                                    return {
                                        ...line,
                                        balance: parseFloat(total).toFixed(2),
                                    };
                                }
                                return line;
                            }
                        );

                        return {
                            ...claim,
                            service_lines: updatedServiceLines,
                        };
                    }
                    return claim;
                }),
            };

            return newData;
        });

    };

    const handlePatresCalculation = async () => {


        await formEob.setValues((prevState) => {


            return {
                ...prevState,
                claims: prevState.claims.map((claim, claimIndex) => {
                    if (claimIndex === claim835Index) {
                        let total = 0;
                        let fake_patres = 0;
                        let adjustment_total = 0;

                        claim.service_lines?.forEach((service_line, ind) => {
                            total =
                                parseFloat(total ? total : 0) +
                                parseFloat(service_line.deduct ? service_line.deduct : 0) +
                                parseFloat(service_line.coins ? service_line.coins : 0) +
                                parseFloat(service_line.copay ? service_line.copay : 0);

                            service_line?.adjustments?.forEach((adjustment, ind) => {
                                if (adjustment?.group_code == 'PR') {
                                    total =
                                        parseFloat(total ? total : 0) +
                                        parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                                }
                            });

                            service_line?.adjustments?.forEach((adjustment, ind) => {
                                adjustment_total =
                                    parseFloat(adjustment_total ? adjustment_total : 0) +
                                    parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                            });

                            fake_patres =
                                parseFloat(fake_patres ? fake_patres : 0) +
                                parseFloat(service_line.deduct ? service_line.deduct : 0) +
                                parseFloat(service_line.coins ? service_line.coins : 0) +
                                parseFloat(service_line.copay ? service_line.copay : 0);

                            service_line?.adjustments?.forEach((adjustment, ind) => {
                                if (adjustment?.group_code == 'PR') {
                                    fake_patres =
                                        parseFloat(fake_patres ? fake_patres : 0) +
                                        parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                                }
                            });
                        });

                        claim?.claim_adjustments?.forEach((adjustment, ind) => {
                            if (adjustment?.group_code == 'PR') {
                                fake_patres =
                                    parseFloat(fake_patres ? fake_patres : 0) +
                                    parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                            }
                        });

                        handleSlAndClaimDifference(claim).then((payload) => {
                            fake_patres = parseFloat(fake_patres ? fake_patres : 0) + parseFloat(payload ? payload : 0);
                            claim.adjustment_total = parseFloat(adjustment_total).toFixed(2);
                            claim.sl_patres = parseFloat(total).toFixed(2);
                            claim.fake_sl_patres = parseFloat(fake_patres).toFixed(2);
                            claim.claim_patres = parseFloat(fake_patres).toFixed(2);
                        });

                        return claim;
                    } else {
                        return claim;
                    }
                }),
            };

        });





    }

    const handleClaimPatresCalculation = async () => {
        await formEob.setValues((prevState) => {
            return {
                ...prevState,
                claims: prevState.claims.map((claim, claimIndex) => {
                    if (claimIndex === claim835Index) {
                        let total = 0;
                        let fake_patres = 0;
                        let adjustment_total = 0;

                        claim.service_lines?.forEach((service_line, ind) => {
                            total =
                                parseFloat(total ? total : 0) +
                                parseFloat(service_line.deduct ? service_line.deduct : 0) +
                                parseFloat(service_line.coins ? service_line.coins : 0) +
                                parseFloat(service_line.copay ? service_line.copay : 0);

                            service_line?.adjustments?.forEach((adjustment, ind) => {
                                if (adjustment?.group_code === 'PR') {
                                    total =
                                        parseFloat(total ? total : 0) +
                                        parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                                }
                            });

                            service_line?.adjustments?.forEach((adjustment, ind) => {
                                adjustment_total =
                                    parseFloat(adjustment_total ? adjustment_total : 0) +
                                    parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                            });

                            fake_patres =
                                parseFloat(fake_patres ? fake_patres : 0) +
                                parseFloat(service_line.deduct ? service_line.deduct : 0) +
                                parseFloat(service_line.coins ? service_line.coins : 0) +
                                parseFloat(service_line.copay ? service_line.copay : 0);

                            service_line?.adjustments?.forEach((adjustment, ind) => {
                                if (adjustment?.group_code === 'PR') {
                                    fake_patres =
                                        parseFloat(fake_patres ? fake_patres : 0) +
                                        parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                                }
                            });
                        });

                        claim?.claim_adjustments?.forEach((adjustment, ind) => {
                            if (adjustment?.group_code === 'PR') {
                                fake_patres =
                                    parseFloat(fake_patres ? fake_patres : 0) +
                                    parseFloat(adjustment?.amount ? adjustment?.amount : 0);
                            }
                        });

                        handleSlAndClaimDifference(claim).then((payload) => {
                            // alert('payload', payload
                            // )
                            fake_patres = parseFloat(fake_patres ? fake_patres : 0) + parseFloat(payload ? payload : 0);
                            claim.adjustment_total = parseFloat(adjustment_total).toFixed(2);
                            claim.sl_patres = parseFloat(total).toFixed(2);
                            claim.fake_sl_patres = parseFloat(fake_patres).toFixed(2);
                            claim.claim_patres = parseFloat(fake_patres).toFixed(2);
                        });

                        // debugger

                        return claim;
                    } else {
                        return claim;
                    }
                }),
            };
        });


        return true
    }

    // ============================================== NEW SERVICELINE FUNCTIONS END =================================//


    const [additionalModal, setAdditionalModal] = useState(false);

    const handleAdditionalCloseButton = () => {
        if (state.Payment835) {
            if (Object.keys(aditionalInfoFormik?.errors).length !== 0) {

                formEob.setErrors({
                    ...formEob.errors,
                    qdx_reason: "Required",
                })
            }
        }
        if (state.Payment835Lite) {

            if (Object.keys(aditionalInfoFormik?.errors).length !== 0) {

                eobLiteFormik.setErrors({
                    ...eobLiteFormik.errors,
                    qdx_reason: "Required",
                })
            }
        }
        if (state.PatientPay) {

            if (Object.keys(aditionalInfoFormik?.errors).length !== 0) {

                formPP.setErrors({
                    ...formPP.errors,
                    qdx_reason: "Required",
                })
            }
        }
        if (state.Correspondence) {

            if (Object.keys(aditionalInfoFormik?.errors).length !== 0) {

                formCorres.setErrors({
                    ...formCorres.errors,
                    qdx_reason: "Required",
                })
            }
        }
    }
    const aditionalInfoFormik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            status: "",
            reason: [],
            document_classification: "",
            is_orphaned_check: false,
            is_missing_check_image: false,
            is_forced_balancing: false,
            is_payer_black_listing: false,
            is_payer_excluding_indexing: false,

        },
        validate: (values) => {
            let errors = {};

            if (isAdditionalInfoOptions?.status && values?.status == "") {
                errors.status = "Required"
            }
            if (isAdditionalInfoOptions?.status && isAdditionalInfoOptions?.reason && values?.status == 'INCOMPLETE' && values?.reason.length == 0) {
                errors.reason = "Reason Required";
            }


            return errors;
        },
        validationSchema: Yup.object().shape({
            status: isAdditionalInfoOptions?.status ? Yup.string().required('Required') : Yup.string(),
            // reason: Yup.string(),
            document_classification: Yup.string(),

        }),

        onSubmit: (values, { setSubmitting }) => {

            setAdditionalModal(false)

            if (state.Payment835) {
                let check_number = formEob.values.check_number;
                if (qdxTransaction == 'CORRESPONDENCE') {
                    if (isIncompleteCheckNumber && isGeneratedCheckNumber) {
                        if (!isSubJob || (isSubJob && isFirstJob)) {
                            if (formEob.values.qdx_status != values?.status) {
                                if (values?.status == 'INCOMPLETE') {
                                    if (formEob.values.check_number.trim() == '' || formEob.values.check_number.trim().slice(0, 2).toUpperCase() == 'LT') {
                                        check_number = "RX" + jobData?.grid_data?.timezone
                                    }
                                }
                                else if (values?.status == 'COMPLETE') {
                                    if (formEob.values.check_number.trim() == '' || formEob.values.check_number.trim().slice(0, 2).toUpperCase() == 'RX') {
                                        check_number = "LT" + jobData?.grid_data?.timezone
                                    }
                                }
                            }
                            else {
                                if (formEob.values.check_number.trim() == '') {
                                    if (values?.status == 'INCOMPLETE') {
                                        check_number = "RX" + jobData?.grid_data?.timezone
                                    }
                                    else if (values?.status == 'COMPLETE') {
                                        check_number = "LT" + jobData?.grid_data?.timezone
                                    }
                                }
                            }
                        }
                    }

                }
                formEob.setValues({

                    ...formEob.values,
                    qdx_status: values?.status,
                    qdx_reason: values?.reason,
                    qdx_classification: values?.document_classification,
                    is_orphaned_check: values?.is_orphaned_check ? true : false,
                    is_missing_check_image: values?.is_missing_check_image ? true : false,
                    is_forced_balancing: values?.is_forced_balancing ? true : false,
                    is_payer_black_listing: values?.is_payer_black_listing ? true : false,
                    is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? true : false,
                    check_number: check_number,
                    dbk_check_number: check_number
                })
            }
            else if (state.Payment835Lite) {
                eobLiteFormik.setValues({

                    ...eobLiteFormik.values,
                    qdx_status: values?.status,
                    qdx_reason: values?.reason,
                    qdx_classification: values?.document_classification,
                    is_orphaned_check: values?.is_orphaned_check ? true : false,
                    is_missing_check_image: values?.is_missing_check_image ? true : false,
                    is_forced_balancing: values?.is_forced_balancing ? true : false,
                    is_payer_black_listing: values?.is_payer_black_listing ? true : false,
                    is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? true : false,

                })
            }
            else if (state.PatientPay) {
                formPP.setValues({

                    ...formPP.values,
                    qdx_status: values?.status,
                    qdx_reason: values?.reason,
                    qdx_classification: values?.document_classification,
                    is_orphaned_check: values?.is_orphaned_check ? true : false,
                    is_missing_check_image: values?.is_missing_check_image ? true : false,
                    is_forced_balancing: values?.is_forced_balancing ? true : false,
                    is_payer_black_listing: values?.is_payer_black_listing ? true : false,
                    is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? true : false,

                })
            }
            else if (state.Correspondence) {
                formCorres.setValues({

                    ...formCorres.values,
                    qdx_status: values?.status,
                    qdx_reason: values?.reason,
                    qdx_classification: values?.document_classification,
                    is_orphaned_check: values?.is_orphaned_check ? true : false,
                    is_missing_check_image: values?.is_missing_check_image ? true : false,
                    is_forced_balancing: values?.is_forced_balancing ? true : false,
                    is_payer_black_listing: values?.is_payer_black_listing ? true : false,
                    is_payer_excluding_indexing: values?.is_payer_excluding_indexing ? true : false,

                })
            }

            setIsDrag(true)
            const additionalBtn = document.getElementById('additional-btn')
            if (additionalBtn && additionalBtn != undefined) {
                additionalBtn.focus()
            }



        },
    });
    const handleAdditionalInfoChange = (e) => {
    }


    // ============================================== DBK OTHER FIELDS ARE DISABLING FUNCTIONS START =================================//
    const container = document.getElementById("grid-full-container");
    let isDbkValidCondition = false
    let isButtonValidate = false
    let isViewOnllyGrid = false

    if (state?.Payment835) {
        isDbkValidCondition = container && isActiveDbkField(eobFocus, formEob)
        isButtonValidate = formEob.isValid

    }

    else if (state?.Payment835Lite) {
        isDbkValidCondition = container && isActiveDbkField(eobLiteFocus, eobLiteFormik)
        isButtonValidate = eobLiteFormik.isValid

    }

    else if (state?.PatientPay) {
        isDbkValidCondition = container && isActiveDbkField(patientPayFocus, formPP)
        isButtonValidate = formPP.isValid

    }

    else if (state?.Correspondence) {
        isDbkValidCondition = container && isActiveDbkField(corresPondanceFocus, formCorres)
        isButtonValidate = formCorres.isValid

    }

    if (window.location.pathname === EDIT_WORK + job_id) {
        isViewOnllyGrid = true
    }

    const subJobEobContainer = document.querySelector('.sub-job-eob');

    useEffect(() => {
        if (subJobEobContainer && container && (jobData?.grid_data?.transaction_type == "PAYMENT835" || jobData?.grid_data?.transaction_type == "CORRESPONDENCE") || container) {

            disablingBasedOnDbk(isViewOnllyGrid, isDbkValidCondition, isButtonValidate, container, subJobEobContainer, isSubJob, isFirstJob)

        }

    }, [state, isViewOnllyGrid, isDbkValidCondition, isButtonValidate, container, subJobEobContainer, pdfLoaded, isOverallPage, qdxTransaction])

    useEffect(() => {
        if (!isSubJob && isFirstJob || isSubJob && !isFirstJob) {
            if (subJobEobContainer) {

                disableBasedOnSubJob(subJobEobContainer);
            }
        }

    }, [subJobEobContainer, isSubJob, isFirstJob])

    // ============================================== DBK OTHER FIELDS ARE DISABLING FUNCTIONS END =================================//

    const [isAlert, setIsAlert] = useState(false)





    const formikValueRef = useRef({});
    const stateValueRef = useRef({});
    const qdxValueRef = useRef({});
    const overAllValueRef = useRef({});
    useEffect(() => {
        if (pdfLoaded && !swalPopup) {
            formEob.validateForm();
        }
        let timeout = setInterval(() => {
            if (pdfLoaded && !swalPopup && !isViewOnllyGrid && currentJobVerified) {
                saveToLocal()
            }

        }, 5000);
        return () => {
            clearInterval(timeout);
        };
    }, [pdfLoaded, swalPopup])

    const saveToLocal = () => {
        localStorage.setItem('grid_data', JSON.stringify(formikValueRef.current))
        localStorage.setItem('grid_state_data', JSON.stringify(stateValueRef.current))
        localStorage.setItem('grid_qdx_data', JSON.stringify(qdxValueRef.current))
        localStorage.setItem('grid_overall_data', JSON.stringify(overAllValueRef.current))

    }
    useEffect(() => {
        if (state.Payment835) {
            formikValueRef.current = formEob.values;
            qdxValueRef.current = qdxTransaction;
            overAllValueRef.current = overallPage;
        }
        else if (state.Payment835Lite) {
            formikValueRef.current = eobLiteFormik.values;
            qdxValueRef.current = '';
            overAllValueRef.current = overallPage;
        }
        else if (state.Correspondence) {
            formikValueRef.current = formCorres.values;
            qdxValueRef.current = '';
            overAllValueRef.current = overallPage;
        }
        else if (state?.PatientPay) {
            formikValueRef.current = formPP.values;
            qdxValueRef.current = '';
            overAllValueRef.current = overallPage;
        }
        stateValueRef.current = state;

    }, [formEob.values, eobLiteFormik.values, formCorres.values, formPP.values, overallPage?.from, overallPage?.to]);

    useEffect(() => {
        if (state.Payment835) {
            formEob.validateForm()
        }
    }, [totalPages, state])

    const disableCorresField = (field) => {
        const pulled837 = ['check_page_number', 'discount', 'mrn', 'statement_number', 'allowed', 'deduct', 'coins', 'copay', 'cob', 'claim_discount', 'claim_allowed', 'claim_deduct', 'claim_coins', 'claim_copay', 'claim_cob'];
        const notPulled837 = ['check_page_number', 'payer_claim_number', 'mrn', 'statement_number', 'patient_middle_name', 'subscriber_first_name', 'subscriber_middle_name', 'subscriber_last_name', 'subscriber_identifier_code', 'rendering_provider_first_name', 'rendering_provider_last_name', 'rendering_provider_identifier_code', 'code', 'procedure_type', 'modifier1', 'modifier2', 'modifier3', 'modifier4', 'rev_code', 'units', 'pc', 'group_code', 'rmk', 'reason_code', 'discount', 'allowed', 'deduct', 'coins', 'copay', 'cob', 'claim_discount', 'claim_allowed', 'claim_deduct', 'claim_coins', 'claim_copay', 'claim_cob', 'claim_pc', 'claim_group_code', 'claim_reason_code', 'claim_rmk'];
        const anyPulled = ['payer_address', 'payer_city', 'payer_state', 'payer_zip', 'payee_address', 'payee_city', 'payee_state', 'payee_zip'];
        if (qdxTransaction == "CORRESPONDENCE") {
            if (formEob.values?.claims[claim835Index] && formEob.values?.claims[claim835Index].encounter != '' && formEob.values?.claims[claim835Index].encounter != undefined) {

                if (pulled837.includes(field)) {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                if (notPulled837.includes(field)) {
                    return true
                }
                else if (anyPulled.includes(field)) {

                    if (localStorage.getItem('837_pulled') == 'true') {
                        return false
                    }
                    else {
                        return true
                    }
                }
                else {
                    return false
                }
            }
        }
        else {
            return false
        }
    }
    const disableCorresField1 = (field, claim) => {
        const pulled837 = ['check_page_number', 'discount', 'mrn', 'statement_number', 'allowed', 'deduct', 'coins', 'copay', 'cob', 'claim_discount', 'claim_allowed', 'claim_deduct', 'claim_coins', 'claim_copay', 'claim_cob'];
        const notPulled837 = ['check_page_number', 'payer_claim_number', 'mrn', 'statement_number', 'patient_middle_name', 'subscriber_first_name', 'subscriber_middle_name', 'subscriber_last_name', 'subscriber_identifier_code', 'rendering_provider_first_name', 'rendering_provider_last_name', 'rendering_provider_identifier_code', 'code', 'procedure_type', 'modifier1', 'modifier2', 'modifier3', 'modifier4', 'rev_code', 'units', 'pc', 'group_code', 'rmk', 'reason_code', 'discount', 'allowed', 'deduct', 'coins', 'copay', 'cob', 'claim_discount', 'claim_allowed', 'claim_deduct', 'claim_coins', 'claim_copay', 'claim_cob', 'claim_pc', 'claim_group_code', 'claim_reason_code', 'claim_rmk'];
        const anyPulled = ['payer_address', 'payer_city', 'payer_state', 'payer_zip', 'payee_address', 'payee_city', 'payee_state', 'payee_zip'];
        if (qdxTransaction == "CORRESPONDENCE") {
            if (claim && claim.encounter != '' && claim.encounter != undefined) {

                if (pulled837.includes(field)) {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                if (notPulled837.includes(field)) {
                    return true
                }
                else if (anyPulled.includes(field)) {

                    if (localStorage.getItem('837_pulled') == 'true') {
                        return false
                    }
                    else {
                        return true
                    }
                }
                else {
                    return false
                }
            }
        }
        else {
            return false
        }
    }
    const setDefaultValueMainGrid = (event, name, formik) => {
        const ctrlSpace = event.ctrlKey == true && event.keyCode == 32
        if (ctrlSpace && name == 'check_number' && jobData?.default?.check_number) {
            formik.setValues((prevState) => {
                return {
                    ...prevState,
                    [name]: jobData?.default?.check_number,
                    [`dbk_${name}`]: jobData?.default?.check_number
                }
            })
        }


    }
    const handleChangeGridPosition = (position_type) => {
        const grid_container = document.getElementById("grid-full-container")
        const boxid = document.getElementById("boxid")
        const curentBottom = boxid.getBoundingClientRect()?.bottom
        const grid_right = grid_container.getBoundingClientRect()?.right
        if (position_type == "payer_payee_popup") {
            if (curentBottom < 445) {
                setControlledPosition({ x: -10, y: 445 });
            }
            else if (grid_right >= (window.innerWidth || document.documentElement.clientWidth)) {
                setControlledPosition({ ...controlledPosition, x: 0 });

            }

        }
        else {
            setTimeout(() => {
                // Calculate the initial position based on the screen height
                const screenHeight = window.innerHeight;

                if (isViewOnllyGrid) {
                    boxid.style.bottom = `${screenHeight + 50}px`;
                } else {
                    boxid.style.bottom = `${screenHeight + 10}px`;

                }
                setControlledPosition({ x: -10, y: screenHeight });
                setActiveInputNotVisibleGrid()
            }, 100)
        }
    }
    // useEffect(() => {
    //     handleChangeGridPosition()
    // }, []);
    const timeZonecustomStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            overflow: 'none'
        },
    };

    console.log(formEob.values)
    return (

        <React.Fragment>
            {/* QA Error Modal Start */}
            <Modal
                isOpen={qaErrorModal}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={false}
                onRequestClose={() => { closeQaErrorModal() }}
                style={timeZonecustomStyles}
                contentLabel="Example Modal"
            >
                <QaErrorModal
                    options={jobData?.qa_error_types}
                    setQaErrorModal={setQaErrorModal}
                    setIsDrag={setIsDrag}
                    formik={qaErrorformik}
                    closeModal={closeQaErrorModal}
                />
            </Modal>
            {/* QA Error Modal End */}
            {isSwal && (

                <SweetAlert
                    error
                    confirmBtnText="Ok!"
                    //confirmBtnBsStyle="danger"
                    confirmBtnCssClass="custom-confirm-button-class"
                    title="Payer is Excluded"
                    onConfirm={() => {
                        setIsSwal(false);
                    }}
                ></SweetAlert>
            )}

            {isAlert && (
                <SweetAlert
                    error
                    confirmBtnText="Ok!"
                    //confirmBtnBsStyle="danger"
                    confirmBtnCssClass="custom-confirm-button-class"
                    title="Wrong Transaction Type"
                    onConfirm={() => {
                        setIsAlert(false);
                    }}
                ></SweetAlert>
            )}

            <Draggable
                position={controlledPosition}
                onStart={() => isDrag}
                onDrag={__onControlledDrag}
                cancel="input"
            >
                <div id="boxid" className="draggable" style={{ zIndex: "999" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div ref={gridRef} id='grid-full-container' className="grid shadow" style={{ position: 'relative', overflow: 'hidden' }}>
                                    {loadData ?
                                        <BlurBackground>
                                            <Loader>
                                                <div className="spinner-border" role="status"></div>
                                            </Loader>
                                        </BlurBackground>
                                        : !firstLoad ?
                                            <BlurBackground>
                                                <Loader>
                                                    <div className="spinner-border" role="status"></div>
                                                </Loader>
                                            </BlurBackground>
                                            : null
                                    }
                                    <div className={
                                        state.Payment835Lite && payerLiteLookup || state.Payment835Lite && payeeLiteLookup ? "lookup-lite"
                                            : state.Payment835Lite && claim835 ? "claim-summary-header"
                                                : state.Payment835Lite && !payeeLiteLookup || state.Payment835Lite && !payerLiteLookup ? "bg-color-lite"
                                                    : state.Payment835 && qdxTransaction == "PATIENT_PAY" ? "bg-color-pp" : state.Payment835 && claim835 ? "claim-summary-header" : payerLiteLookup ? "claim-summary-header" : state.PatientPay ? "bg-color-pap" : state.Correspondence ? "bg-color-pap" : "bg-color"}
                                    >
                                        <GridHeader
                                            {...props}
                                            log_id={log_id}
                                            aditionalInfoFormik={aditionalInfoFormik}
                                            isIncomplete={isIncomplete}
                                            setIsIncomplete={setIsIncomplete}
                                            overallPage={overallPage}
                                            setOverallPage={setOverallPage}
                                            isOverallPage={isOverallPage}
                                            isGeneratedCheckNumber={isGeneratedCheckNumber}
                                            isPayerPage={isPayerPage}
                                            isPayeePage={isPayeePage}
                                            isAdditionalInfoOptions={isAdditionalInfoOptions}
                                            isViewOnllyGrid={isViewOnllyGrid}
                                            checkNum={checkNum}
                                            handleAddPlbAdjustment={handleAddPlbAdjustment}
                                            clearEob835Form={clearEob835Form}
                                            formEob={formEob}
                                            formPP={formPP}
                                            eobLiteFormik={eobLiteFormik}
                                            handleRemainingBalanceCalculation={handleRemainingBalanceCalculation}
                                            selectedSubJob={selectedSubJob}
                                            setSelectedSubJob={setSelectedSubJob}
                                            activePdf={activePdf}
                                            firstSaveLoad={firstSaveLoad}
                                            secondSaveLoad={secondSaveLoad}
                                            setFirstSaveLoad={setFirstSaveLoad}
                                            setSecondSaveLoad={setSecondSaveLoad}
                                            //states
                                            state={state}
                                            setState={setState}
                                            __payment835Handler={__payment835Handler}
                                            __payment835LiteHandler={__payment835LiteHandler}
                                            __patientPayHandler={__patientPayHandler}
                                            __correspondenceHandler={__correspondenceHandler}
                                            setControlledPosition={handleChangeGridPosition}
                                            setIsDrag={setIsDrag}
                                            payeeLookup={payeeLookup}
                                            payerLookup={payerLookup}
                                            payeeLiteLookup={payeeLiteLookup}
                                            payerLiteLookup={payerLiteLookup}
                                            patientLookup={patientLookup}
                                            setPatientFocus={setPatientFocus}
                                            setPatientValue={setPatientValue}
                                            claim835={claim835}
                                            setClaim835={setClaim835}
                                            loading={loading}
                                            setLoading={setLoading}
                                            formPartial={formPartial}
                                            partialSave={partialSave}
                                            partialSavePopUp={partialSavePopUp}
                                            setPartialSave={setPartialSave}
                                            setPartialSavePopUp={setPartialSavePopUp}
                                            formCorres={formCorres}
                                            cleareCorresPondanceFocus={cleareCorresPondanceFocus}
                                            cleareEobFocus={cleareEobFocus}
                                            cleareEobLiteFocus={cleareEobLiteFocus}
                                            clearePatientPayFocus={clearePatientPayFocus}
                                            // handlePartialTransactionType={handlePartialTransactionType}
                                            assignTooltip={assignTooltip}
                                            setAssignTooltip={setAssignTooltip}
                                            userStatus={userStatus}
                                            setUserStatus={setUserStatus}

                                            formReassign={formReassign}
                                            formReassignMulty={formReassignMulty}

                                            assignTo={assignTo}
                                            setAssignTo={setAssignTo}
                                            assignReason={assignReason}
                                            setAssignReason={setAssignReason}
                                            reAssignUser={reAssignUser}
                                            setClaimLiteIndex={setClaimLiteIndex}
                                            claimLiteIndex={claimLiteIndex}

                                            claim835Index={claim835Index}
                                            setClaim835Index={setClaim835Index}
                                            // showAssignTooltip={showAssignTooltip}
                                            // setPartialSaveToolTip={setPartialSaveToolTip}

                                            // setRejectReasson={setRejectReasson}
                                            // setRejectTooltip={setRejectTooltip}
                                            // setRejectMessage={setRejectMessage}
                                            rejectRef={rejectRef}
                                            rejectPopup={rejectPopup}
                                            setRejectPopup={setRejectPopup}
                                            rejectLabel={rejectLabel}
                                            setRejectLabel={setRejectLabel}
                                            rejectForm={rejectForm}
                                            handleRejectFormClose={handleRejectFormClose}
                                            handlePartialFormClose={handlePartialFormClose}
                                            setClaimCorresIndex={setClaimCorresIndex}
                                            handleLiteAddPlbAdjustment={handleLiteAddPlbAdjustment}
                                            handleEobLite835Data={handleEobLite835Data}

                                            setQdxTransaction={setQdxTransaction}
                                            qdxTransaction={qdxTransaction}
                                            setPlbOnlyValue={setPlbOnlyValue}
                                            plbOnlyValue={plbOnlyValue}
                                            isCorres835={isCorres835}
                                            setIsCorres835={setIsCorres835}
                                            isPP835={isPP835}
                                            setIsPP835={setIsPP835}
                                            isAdditional={isAdditional}
                                            setIsAdditional={setIsAdditional}

                                            isCheckNumber={isCheckNumber}
                                            setIsCheckNumber={setIsCheckNumber}

                                            clearServiceLines={clearServiceLines}
                                            // aditionalInfoFormik={aditionalInfoFormik}
                                            additionalModal={additionalModal}
                                        // rejectMessage={rejectMessage}
                                        // submitRejection={submitRejection}
                                        // setRejectLabel={setRejectLabel}

                                        // __partialSave={__partialSave}

                                        // setAssignTo={setAssignTo}
                                        // assignTo={assignTo}
                                        // reAssignUser={reAssignUser}
                                        // assignReason={assignReason}
                                        // setAssignReason={setAssignReason}
                                        // assignRef={assignRef}
                                        // setInfoModal={setInfoModal}

                                        // userStatus={userStatus}
                                        />

                                        {/* Eob835 component STARTS */}

                                        {state.Payment835 && (

                                            <Eob835

                                                {...props}
                                                // containerRef={containerRef}
                                                setDefaultValueMainGrid={setDefaultValueMainGrid}
                                                gridRef={gridRef}
                                                handleDoubleClickClaimAdjustment={handleDoubleClickClaimAdjustment}
                                                handleDoubleClickServiceLineAdjustment={handleDoubleClickServiceLineAdjustment}
                                                isGeneratedCheckNumber={isGeneratedCheckNumber}
                                                isOverallPage={isOverallPage}
                                                isPayerPage={isPayerPage}
                                                isPayeePage={isPayeePage}
                                                isAdditionalInfoOptions={isAdditionalInfoOptions}
                                                isViewOnllyGrid={isViewOnllyGrid}
                                                setState={setState}
                                                state={state}
                                                formik={formEob}
                                                handleEob835Data={handleEob835Data}
                                                setcheckNum={setcheckNum}
                                                isCheckNumber={isCheckNumber}
                                                setIsCheckNumber={setIsCheckNumber}
                                                totalPages={totalPages}
                                                __handlePayerLookupSearch={__handlePayerLookupSearch}
                                                __handleSearchPayer={__handleSearchPayer}
                                                togglePayerHoverEnter={togglePayerHoverEnter}
                                                activatePayerLookup={activatePayerLookup}
                                                setPayerValue={setPayerValue}
                                                setPayerFocus={setPayerFocus}
                                                setPayerLookup={setPayerLookup}
                                                setPayerIndex={setPayerIndex}
                                                payerValue={payerValue}
                                                payerFocus={payerFocus}
                                                payerLookup={payerLookup}
                                                payerData={payerData}
                                                payerIndex={payerIndex}
                                                __handlePayeeLookupSearch={__handlePayeeLookupSearch}
                                                __handleSearchPayee={__handleSearchPayee}
                                                togglePayeeHoverEnter={togglePayeeHoverEnter}
                                                activatePayeeLookup={activatePayeeLookup}
                                                setPayeeLookup={setPayeeLookup}
                                                setPayeeIndex={setPayeeIndex}
                                                setPayeeValue={setPayeeValue}
                                                setPayeeData={setPayeeData}
                                                setPayeeFocus={setPayeeFocus}
                                                payeeLookup={payeeLookup}
                                                payeeIndex={payeeIndex}
                                                payeeValue={payeeValue}
                                                payeeData={payeeData}
                                                payeeFocus={payeeFocus}
                                                patientFocus={patientFocus}
                                                setPatientFocus={setPatientFocus}
                                                patientValue={patientValue}
                                                patientLookup={patientLookup}
                                                setPatientValue={setPatientValue}
                                                patientValue1={patientValue1}
                                                setPatientValue1={setPatientValue1}
                                                patientValue2={patientValue2}
                                                setPatientValue2={setPatientValue2}
                                                patientIndex={patientIndex}
                                                setPatientIndex={setPatientIndex}
                                                __handleSearchData837={__handleSearchData837}
                                                handleChange837ClaimData={handleChange837ClaimData}
                                                data_837={data_837}
                                                togglePatientHoverEnter={togglePatientHoverEnter}
                                                handlePatientIndex={handlePatientIndex}
                                                handlePageNavigate={handlePageNavigate}
                                                hoveredIndex={hoveredIndex}
                                                cl837Index={cl837Index}
                                                activate837={activate837}
                                                createNewClaim={createNewClaim}
                                                copyClaim={copyClaim}
                                                setClaim835Index={setClaim835Index}
                                                claim835Index={claim835Index}
                                                claim835={claim835}
                                                setClaim835={setClaim835}
                                                serviceIndex={serviceIndex}
                                                setServiceIndex={setServiceIndex}
                                                handleNewServiceLine={handleNewServiceLine}
                                                newPoupUp={newPoupUp}
                                                setNewPoupUp={setNewPoupUp}
                                                slCount={slCount}
                                                setSlCount={setSlCount}
                                                handleRemainingBalanceCalculation={handleRemainingBalanceCalculation}
                                                handleSlAndClaimDifference={handleSlAndClaimDifference}
                                                handleColumnCalculation={handleColumnCalculation}
                                                handleRowCalculation={handleRowCalculation}
                                                loading={loading}
                                                setLoading={setLoading}
                                                setMoonji={setMoonji}
                                                cleareEobFocus={cleareEobFocus}
                                                eobFocus={eobFocus}
                                                setEobFocus={setEobFocus}
                                                handleFocusEob={handleFocusEob}
                                                addingNewEobClaimDbk={addingNewEobClaimDbk}
                                                removeEobClaimDbk={removeEobClaimDbk}
                                                isDbkPermission={isDbkPermission}
                                                pcPopup={pcPopup}
                                                setData837={setData837}
                                                formRc={formRc}
                                                setFormRc={setFormRc}
                                                setPcFormik={setPcFormik}
                                                setPcPopup={setPcPopup}
                                                handlePayerPcVerify={handlePayerPcVerify}
                                                propCodeData={propCodeData}
                                                x12Codes={x12Codes}
                                                setx12Codes={setx12Codes}
                                                handleMultiplePropCode={handleMultiplePropCode}

                                                x12ClaimCodes={x12ClaimCodes}
                                                setx12ClaimCodes={setx12ClaimCodes}
                                                pcClaimPopup={pcClaimPopup}
                                                setPcClaimPopup={setPcClaimPopup}
                                                formClaimRc={formClaimRc}
                                                setPcClaimFormik={setPcClaimFormik}
                                                handleClaimPayerPcVerify={handleClaimPayerPcVerify}
                                                propCodeClaimData={propCodeClaimData}
                                                handleMultipleClaimPropCode={handleMultipleClaimPropCode}
                                                setFormClaimRc={setFormClaimRc}



                                                jobData={jobData}
                                                setIsDrag={setIsDrag}
                                                handleClaimCalculation={handleClaimCalculation}
                                                handleSlCalculation={handleSlCalculation}
                                                handlePatresCalculation={handlePatresCalculation}
                                                handleClaimPatresCalculation={handleClaimPatresCalculation}
                                                setClaim835Focus={setClaim835Focus}
                                                checkAllRef={checkAllRef}
                                                checkRef={checkRef}
                                                selectedServiceLines={selectedServiceLines}
                                                setSelectedServiceLines={setSelectedServiceLines}
                                                handleCheckServiceLines={handleCheckServiceLines}

                                                setQdxTransaction={setQdxTransaction}
                                                qdxTransaction={qdxTransaction}
                                                isCorres835={isCorres835}
                                                setIsCorres835={setIsCorres835}
                                                isPP835={isPP835}
                                                setIsPP835={setIsPP835}
                                                isAdditional={isAdditional}
                                                setIsAdditional={setIsAdditional}
                                                isAlert={isAlert}
                                                setIsAlert={setIsAlert}
                                                disableCorresField={disableCorresField}
                                                selectedSubJob={selectedSubJob}
                                                setSelectedSubJob={setSelectedSubJob}
                                                handleAdditionalCloseButton={handleAdditionalCloseButton}
                                                doubleClicked={doubleClicked}

                                            />
                                        )}
                                        {/* Eob835 component ends */}
                                        {/* Eob835Lite component ends */}
                                        {state.Payment835Lite && (
                                            <EobLite
                                                {...props}
                                                isViewOnllyGrid={isViewOnllyGrid}
                                                isGeneratedCheckNumber={isGeneratedCheckNumber}
                                                isOverallPage={isOverallPage}
                                                isPayerPage={isPayerPage}
                                                isPayeePage={isPayeePage}
                                                isAdditionalInfoOptions={isAdditionalInfoOptions}
                                                state={state}
                                                setControlledPosition={handleChangeGridPosition}
                                                setState={setState}
                                                formik={eobLiteFormik}
                                                jobData={jobData}
                                                totalPages={totalPages}
                                                setClaim835Focus={setClaim835Focus}
                                                currentPage={currentPage}
                                                handleEobLite835Data={handleEobLite835Data}
                                                __handlePayerLiteLookupSearch={__handlePayerLiteLookupSearch}
                                                payerLiteLookup={payerLiteLookup}
                                                activatePayerLiteLookup={activatePayerLiteLookup}
                                                __handleSearchLitePayer={__handleSearchLitePayer}
                                                loading={loading}
                                                payerLiteData={payerLiteData}
                                                payerLiteIndex={payerLiteIndex}
                                                payerLiteValue={payerLiteValue}
                                                setPayerLiteValue={setPayerLiteValue}
                                                setPayerLiteIndex={setPayerLiteIndex}
                                                setPayerLiteFocus={setPayerLiteFocus}
                                                setPayeeLiteFocus={setPayeeLiteFocus}
                                                __handlePayeeLiteLookupSearch={__handlePayeeLiteLookupSearch}
                                                setClaimIndex={setClaimLiteIndex}
                                                claimIndex={claimLiteIndex}
                                                handleLiteRemainingBalanceCalculation={handleLiteRemainingBalanceCalculation}
                                                claimLiteIndex={claimLiteIndex}
                                                setClaimLiteIndex={setClaimLiteIndex}
                                                handleNewClaim={handleNewClaim}
                                                payeeLiteLookup={payeeLiteLookup}
                                                __handleSearchLitePayee={__handleSearchLitePayee}
                                                payeeLiteData={payeeLiteData}
                                                activatePayeeLiteLookup={activatePayeeLiteLookup}
                                                payeeLiteIndex={payeeLiteIndex}
                                                payeeLiteValue={payeeLiteValue}
                                                setPayeeLiteValue={setPayeeLiteValue}
                                                setPayeeLiteIndex={setPayeeLiteIndex}
                                                claim835={claim835}
                                                setClaim835={setClaim835}
                                                payerLiteFocus={payerLiteFocus}
                                                payeeLiteFocus={payeeLiteFocus}
                                                eobLiteFocus={eobLiteFocus}
                                                setEobLiteFocus={setEobLiteFocus}
                                                handleFocusEobLite={handleFocusEobLite}
                                                addingNewEobLiteClaimDbk={addingNewEobLiteClaimDbk}
                                                removeEobLiteClaimDbk={removeEobLiteClaimDbk}
                                                isDbkPermission={isDbkPermission}
                                                handlePageNavigate={handlePageNavigate}
                                                setDefaultValueMainGrid={setDefaultValueMainGrid}
                                            />
                                        )}

                                        {/* Eob835Lite component ends */}

                                        {/* Correspondence component starts */}
                                        {state.Correspondence && (
                                            <Correspondence
                                                pdfLoaded={pdfLoaded}
                                                totalPages={totalPages}
                                                isGeneratedCheckNumber={isGeneratedCheckNumber}
                                                isOverallPage={isOverallPage}
                                                isPayerPage={isPayerPage}
                                                isPayeePage={isPayeePage}
                                                isAdditionalInfoOptions={isAdditionalInfoOptions}
                                                state={state}
                                                setState={setState}
                                                currentPage={currentPage}
                                                formik={formCorres}
                                                jobData={jobData}
                                                job_id={job_id}
                                                handleCorresspondenceData={handleCorresspondenceData}
                                                setClaim835Focus={setClaim835Focus}
                                                addingNewCorrespondanceClaim={addingNewCorrespondanceClaim}
                                                handlePageNavigate={handlePageNavigate}
                                                isViewOnllyGrid={isViewOnllyGrid}
                                                claimCorresIndex={claimCorresIndex}
                                                setClaimCorresIndex={setClaimCorresIndex}
                                                corresPondanceFocus={corresPondanceFocus}
                                                setCorresPondanceFocus={setCorresPondanceFocus}
                                                handleFocusCorresPondance={handleFocusCorresPondance}
                                                addingNewCorrespondanceClaimDbk={addingNewCorrespondanceClaimDbk}
                                                removeCorrespondanceClaimDbk={removeCorrespondanceClaimDbk}
                                                isDbkPermission={isDbkPermission}
                                            />
                                        )}

                                        {/* Correspondence component ends */}

                                        {/* PATIENT_PAY component starts */}
                                        {state?.PatientPay && (
                                            <PatientPay
                                                state={state}
                                                setState={setState}
                                                formik={formPP}
                                                jobData={jobData}
                                                handlePatientPayData={handlePatientPayData}
                                                patientPayFocus={patientPayFocus}
                                                setPatientPayFocus={setPatientPayFocus}
                                                handleFocusPatientPay={handleFocusPatientPay}
                                                isDbkPermission={isDbkPermission}
                                                job_id={job_id}

                                                isPatientPageFromTo={true}
                                                pdfLoaded={pdfLoaded}
                                                isViewOnllyGrid={isViewOnllyGrid}
                                                currentPage={currentPage}
                                                setDefaultValueMainGrid={setDefaultValueMainGrid}
                                            />
                                        )}

                                        {/* PATIENT_PAY component ends */}
                                    </div>

                                    <GridFooter
                                        isFirstJob={isFirstJob}
                                        isGeneratedCheckNumber={isGeneratedCheckNumber}
                                        isOverallPage={isOverallPage}
                                        isPayerPage={isPayerPage}
                                        isPayeePage={isPayeePage}
                                        isAdditionalInfoOptions={isAdditionalInfoOptions}
                                        log_id={log_id}
                                        jobData={jobData}
                                        handleAdditionalInfoChange={handleAdditionalInfoChange}
                                        job_id={job_id}
                                        handleAdditionalCloseButton={handleAdditionalCloseButton}
                                        state={state}
                                        isDisabled={isDisabled}
                                        formEob={formEob}
                                        formPP={formPP}
                                        formCorres={formCorres}
                                        eobLiteFormik={eobLiteFormik}
                                        isIncomplete={isIncomplete}
                                        setIsIncomplete={setIsIncomplete}
                                        aditionalInfoFormik={aditionalInfoFormik}
                                        additionalModal={additionalModal}
                                        setAdditionalModal={setAdditionalModal}
                                        setIsDrag={setIsDrag}
                                        isCorres835={isCorres835}
                                        setIsCorres835={setIsCorres835}
                                        isPP835={isPP835}
                                        setIsPP835={setIsPP835}
                                        isAdditional={isAdditional}
                                        setIsAdditional={setIsAdditional}
                                        isViewOnllyGrid={isViewOnllyGrid}
                                        activePdf={activePdf}
                                        firstSaveLoad={firstSaveLoad}
                                        secondSaveLoad={secondSaveLoad}
                                        setFirstSaveLoad={setFirstSaveLoad}
                                        setSecondSaveLoad={setSecondSaveLoad}
                                        claim835Index={claim835Index}
                                        currentJobVerified={currentJobVerified}
                                    />
                                    <HotKey hotKey={hotKey} setHotKey={setHotKey} setIsDrag={setIsDrag} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </React.Fragment>
    );
};
export default Grid;

const BlurBackground = styled.div`
  /* filter: blur(5px); */
  box-shadow: rgb(67, 70, 71) -1px 0px 5px 0px;
    position: fixed;
  top: 0;
  left: 10;
  min-width: 1900px !important;
  max-width: 1920px !important;
  height: 100%;
  /* background: linear-gradient(rgb(173 173 173 / 80%), rgb(193 193 193 / 50%)); */
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1000000000000000000000000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = styled.div`
  .spinner-border {
    color: rgba(0, 0, 0, 0.4); // Adjust the color as needed
  }
  div{
    width: 3rem;
     height: 3rem;
  }
`;
