


import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import styled from "styled-components/macro";

const LargeBatchSPlitSkeleton = () => {
  return (
    <div>
      <LeftListContainer style={{ display: "flex" }}>
        <div>
          <Skeleton height={73} width={365} />
        </div>
        <div>
          <Skeleton height={54} width={365} />
        </div>
        <div>
          <Skeleton height={280} width={365} />
        </div>
        <div>
          <Skeleton height={54} width={365} />
        </div>
        <div>
          <Skeleton height={54} width={365} />
        </div>
        <div>
          <Skeleton height={54} width={365} />
        </div>

      </LeftListContainer>
    </div>
  );
};

export default LargeBatchSPlitSkeleton;


const LeftListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

`