import { Formik, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import TimePicker from 'react-time-picker';
import * as Yup from 'yup';
import Button from 'react-bootstrap/Button';
import { createOutBound } from '../../../../../Redux/client/action';
import { useNavigate } from 'react-router-dom';
import { changeAllTitleFunction } from '../../../Dashboard/functions';
import { CLIENT } from '../../../../../routeNames';


const OutboundForm = (props) => {

    const { client, client_data, outbond, __toggleToast, setLoading, fetchClientDetails } = props;
    const [state, setState] = useState({
        error: false,
        error_message: "",

    });
    const navigate = useNavigate()
    const [isSubmit, setIsSubmit] = useState(false)
    const [togglePassword, setTogglePassword] = useState(false);

    useEffect(() => {
        if (props?.outbond) {
            outboundformik.setValues({
                file_transfer_period: outbond?.file_transfer_period ? outbond?.file_transfer_period : "",
                transfer_time: outbond?.transfer_time ? outbond?.transfer_time : "",
                source_file_transfered: outbond?.source_file_transfered == 0 ? "0" : outbond?.source_file_transfered == 1 ? "1" : outbond?.source_file_transfered == 2 ? "2" : "",

                output_schema_xsd: '',
                output_schema_xsd_url: outbond?.output_schema_xsd ? outbond?.output_schema_xsd : "",

                source_file_format: outbond?.source_file_format ? outbond?.source_file_format : "",
                source_file_path: outbond?.source_file_path ? outbond?.source_file_path : "",
                source_file_grouping: outbond?.source_file_grouping ? outbond?.source_file_grouping : "",


                eob_file_grouping: outbond?.eob_file_grouping ? outbond?.eob_file_grouping : "",
                schema_version: outbond?.schema_version ? outbond?.schema_version : "",
                eob_file_formats: outbond?.eob_file_formats ? outbond?.eob_file_formats : "",
                eob_file_path: outbond?.eob_file_path ? outbond?.eob_file_path : "",

                output_zip_file_format: outbond?.output_zip_file_format ? outbond?.output_zip_file_format : "",


                patient_file_grouping: outbond?.patient_file_grouping ? outbond?.patient_file_grouping : "",
                patient_file_format: outbond?.patient_file_format ? outbond?.patient_file_format : "",
                patient_pay_file_path: outbond?.patient_pay_file_path ? outbond?.patient_pay_file_path : "",
                patient_output_file_format: outbond?.patient_output_file_format ? outbond?.patient_output_file_format : "",

                eob_lite_file_grouping: outbond?.eob_lite_file_grouping ? outbond?.eob_lite_file_grouping : "",
                eob_lite_file_format: outbond?.eob_lite_file_format ? outbond?.eob_lite_file_format : "",
                eob_lite_file_path: outbond?.eob_lite_file_path ? outbond?.eob_lite_file_path : "",
                eob_lite_output_file_format: outbond?.eob_lite_output_file_format ? outbond?.eob_lite_output_file_format : "",

                correspondence_file_grouping: outbond?.correspondence_file_grouping ? outbond?.correspondence_file_grouping : "",
                correspondence_file_format: outbond?.correspondence_file_format ? outbond?.correspondence_file_format : "",
                correspondence_file_path: outbond?.correspondence_file_path ? outbond?.correspondence_file_path : "",
                correspondence_output_file_format: outbond?.correspondence_output_file_format ? outbond?.correspondence_output_file_format : "",

                sftp_server: outbond?.sftp_server ? outbond?.sftp_server : "",
                sftp_address: outbond?.sftp_address ? outbond?.sftp_address : "",
                sftp_port: outbond?.sftp_port ? outbond?.sftp_port : "",
                sftp_username: outbond?.sftp_username ? outbond?.sftp_username : "",
                sftp_password: outbond?.sftp_password ? outbond?.sftp_password : "",
            })
        }

    }, [outbond, client]);

    const handleOnTimeChange = ({ name, value }) => {
        outboundformik.setValues({ ...outboundformik.values, [name]: value ?? "" })
    }
    const outboundformik = useFormik({
        initialValues: {
            file_transfer_period: "",
            transfer_time: "",

            source_file_transfered: '',

            output_schema_xsd: '',
            output_schema_xsd_url: '',

            source_file_format: "",
            source_file_path: "",
            source_file_grouping: '',

            eob_file_grouping: "",
            schema_version: "",
            eob_file_formats: "",
            eob_file_path: "",

            output_zip_file_format: "",


            patient_file_grouping: "",
            patient_file_format: "",
            patient_pay_file_path: "",
            patient_output_file_format: "",

            eob_lite_file_grouping: "",
            eob_lite_file_format: "",
            eob_lite_file_path: "",
            eob_lite_output_file_format: "",

            correspondence_file_grouping: "",
            correspondence_file_format: "",
            correspondence_file_path: "",
            correspondence_output_file_format: "",

            // sftp_id: "",
            sftp_server: "",
            sftp_address: "",
            sftp_port: "",
            sftp_username: "",
            sftp_password: "",

        },

        validate: (values) => {
            let errors = {};

            if (values?.file_transfer_period == "0") {
                errors.file_transfer_period = "Required"
            }
            if (values?.file_transfer_period == "1" && values.transfer_time == '') {

                errors.transfer_time = "Required"

            }

            if (values.source_file_transfered === '1') {
                if (values?.source_file_grouping === "") {
                    errors.source_file_grouping = "Required"
                }
                if (values?.source_file_format === "") {
                    errors.source_file_format = "Required"
                }
                if (!values?.source_file_path) {
                    errors.source_file_path = 'Required';
                } else if (!/^(\/[A-Za-z0-9_.-]+)+$/.test(values.source_file_path)) {
                    errors.source_file_path = 'Invalid folder path';
                }

            } else if (values.source_file_transfered === '2') {
                ""
            }



            if (client_data?.eob) {



                if (client_data?.eob && values?.eob_file_grouping == "null") {
                    errors.eob_file_grouping = "Required"
                }
                if (client_data?.eob && values?.schema_version == "null") {
                    errors.schema_version = "Required"
                }

                if (client_data?.eob && values?.eob_file_formats == "") {
                    errors.eob_file_formats = "Required"
                }
                if (client_data.eob && !values?.eob_file_path) {
                    errors.eob_file_path = 'Required';
                } else if (!/^(\/[A-Za-z0-9_.-]+)+$/.test(client_data.eob && values.eob_file_path)) {
                    errors.eob_file_path = 'Invalid folder path';
                }
            }

            if (client_data?.patient_pay) {

                // patient pay schema
                if (client_data?.patient_pay && values?.patient_file_grouping == "null") {
                    errors.patient_file_grouping = "Required"
                }
                if (client_data?.patient_pay && values?.patient_output_file_format == "null") {
                    errors.patient_output_file_format = "Required"
                }

                if (client_data?.patient_pay && values?.patient_file_format == "") {
                    errors.patient_file_format = "Required"
                }
                if (client_data.patient_pay && !values?.patient_pay_file_path) {
                    errors.patient_pay_file_path = 'Required';
                } else if (!/^(\/[A-Za-z0-9_.-]+)+$/.test(client_data.patient_pay && values.patient_pay_file_path)) {
                    errors.patient_pay_file_path = 'Invalid folder path';
                }
            }

            if (client_data?.eob_lite) {



                // EOB Lite Schema
                if (client_data?.eob_lite && values?.eob_lite_file_grouping == "null") {
                    errors.eob_lite_file_grouping = "Required"
                }
                if (client_data?.eob_lite && values?.eob_lite_output_file_format == "null") {
                    errors.eob_lite_output_file_format = "Required"
                }

                if (client_data?.eob_lite && values?.eob_lite_file_format == "") {
                    errors.eob_lite_file_format = "Required"
                }
                if (client_data.eob_lite && !values?.eob_lite_file_path) {
                    errors.eob_lite_file_path = 'Required';
                } else if (!/^(\/[A-Za-z0-9_.-]+)+$/.test(client_data.eob_lite && values.eob_lite_file_path)) {
                    errors.eob_lite_file_path = 'Invalid folder path';
                }
            }

            if (client_data?.correspondence) {


                // corresponding
                if (client_data?.correspondence && values?.correspondence_file_grouping == "null") {
                    errors.correspondence_file_grouping = "Required"
                }
                if (client_data?.correspondence && values?.correspondence_output_file_format == "null") {
                    errors.correspondence_output_file_format = "Required"
                }

                if (client_data?.correspondence && values?.correspondence_file_format == "") {
                    errors.correspondence_file_format = "Required"
                }
                if (client_data.correspondence && !values?.correspondence_file_path) {
                    errors.correspondence_file_path = 'Required';
                } else if (!/^(\/[A-Za-z0-9_.-]+)+$/.test(client_data.correspondence && values.correspondence_file_path)) {
                    errors.correspondence_file_path = 'Invalid folder path';
                }
            }

            if (values?.output_zip_file_format == "") {
                errors.output_zip_file_format = "Required"
            }
            return errors;
        },

        validationSchema: Yup.object().shape({
            eob_file_grouping: Yup.string(),
            schema_version: Yup.string(),
            eob_file_formats: Yup.string(),
            eob_file_path: Yup.string(),

            patient_file_grouping: Yup.string(),
            patient_file_format: Yup.string(),
            patient_pay_file_path: Yup.string(),
            patient_output_file_format: Yup.string(),

            eob_lite_file_grouping: Yup.string(),
            eob_lite_file_format: Yup.string(),
            eob_lite_file_path: Yup.string(),
            eob_lite_output_file_format: Yup.string(),

            correspondence_file_grouping: Yup.string(),
            correspondence_file_format: Yup.string(),
            correspondence_file_path: Yup.string(),
            correspondence_output_file_format: Yup.string(),

            sftp_server: Yup.string().required('Required'),
            sftp_address: Yup.string().required('Required'),
            sftp_port: Yup.string().required('Required'),
            sftp_username: Yup.string().required('Required'),
            sftp_password: props?.outbond ? Yup.string() : Yup.string().required('Required'),
        }),
        onSubmit: (values, { setSubmitting }) => {
            setIsSubmit(true)
            setLoading(true)
            let formData = new FormData();    //formdata object
            formData.append('client', !client == 0 ? client : "");
            if (outbond?.id)
                formData.append('outbound', outbond?.id);
            formData.append('file_transfer_period', values?.file_transfer_period);
            if (values?.transfer_time)
                formData.append('transfer_time', values?.transfer_time);
            formData.append('source_file_transfered', values?.source_file_transfered);

            if (values?.source_file_transfered == "1") {

                formData.append('source_file_format', values?.source_file_format);
                formData.append('source_file_path', values?.source_file_path);
                formData.append('source_file_grouping', values?.source_file_grouping);

            } else {

                formData.append('source_file_format', "");
                formData.append('source_file_path', "");
                formData.append('source_file_grouping', "");
            }
            formData.append('output_schema_xsd', values?.output_schema_xsd);

            formData.append('eob_file_formats', values?.eob_file_formats);
            formData.append('eob_file_path', values?.eob_file_path);
            if (values?.schema_version !== undefined && values?.schema_version !== null && values?.schema_version !== '') {
                formData.append('schema_version', values?.schema_version);
            }
            if (values?.eob_file_grouping !== undefined && values?.eob_file_grouping !== null && values?.eob_file_grouping !== '') {
                formData.append('eob_file_grouping', values?.eob_file_grouping);
            }

            if (values?.patient_file_grouping !== undefined && values?.patient_file_grouping !== null && values?.patient_file_grouping !== '') {
                formData.append('patient_file_grouping', values?.patient_file_grouping);
            }
            if (values?.patient_output_file_format !== undefined && values?.patient_output_file_format !== null && values?.patient_output_file_format !== '') {
                formData.append('patient_output_file_format', values?.patient_output_file_format);
            }

            if (values?.eob_lite_file_grouping !== undefined && values?.eob_lite_file_grouping !== null && values?.eob_lite_file_grouping !== '') {
                formData.append('eob_lite_file_grouping', values?.eob_lite_file_grouping);
            }
            if (values?.eob_lite_output_file_format !== undefined && values?.eob_lite_output_file_format !== null && values?.eob_lite_output_file_format !== '') {
                formData.append('eob_lite_output_file_format', values?.eob_lite_output_file_format);
            }

            if (values?.correspondence_file_grouping !== undefined && values?.correspondence_file_grouping !== null && values?.correspondence_file_grouping !== '') {
                formData.append('correspondence_file_grouping', values?.correspondence_file_grouping);
            }
            if (values?.correspondence_output_file_format !== undefined && values?.correspondence_output_file_format !== null && values?.correspondence_output_file_format !== '') {
                formData.append('correspondence_output_file_format', values?.correspondence_output_file_format);
            }

            formData.append('patient_file_format', values?.patient_file_format);
            formData.append('patient_pay_file_path', values?.patient_pay_file_path);

            formData.append('eob_lite_file_format', values?.eob_lite_file_format);
            formData.append('eob_lite_file_path', values?.eob_lite_file_path);

            formData.append('correspondence_file_format', values?.correspondence_file_format);
            formData.append('correspondence_file_path', values?.correspondence_file_path);

            formData.append('sftp_server', values?.sftp_server);
            formData.append('sftp_address', values?.sftp_address);
            formData.append('sftp_port', values?.sftp_port);
            formData.append('sftp_username', values?.sftp_username);
            formData.append('sftp_password', values?.sftp_password);

            formData.append('output_zip_file_format', values?.output_zip_file_format);


            createOutBound({ formData })((response) => {
                if (!response?.data?.status && response?.status && response?.status_code === 201) {
                    setIsSubmit(false)
                    setLoading(false)
                    __toggleToast("success", response?.message, true)
                    fetchClientDetails()

                } else {
                    outboundformik.setErrors({
                        ...outboundformik,
                        file_transfer_period: response?.response?.data?.errors?.file_transfer_period,
                        transfer_time: response?.response?.data?.errors?.transfer_time,
                        source_file_transfered: response?.response?.data?.errors?.source_file_transfered,

                        output_schema_xsd: response?.response?.data?.errors?.output_schema_xsd,

                        eob_file_grouping: response?.response?.data?.errors?.eob_file_grouping,
                        schema_version: response?.response?.data?.errors?.schema_version,
                        eob_file_formats: response?.response?.data?.errors?.eob_file_formats,
                        eob_file_path: response?.response?.data?.errors?.eob_file_path,

                        patient_file_grouping: response?.response?.data?.errors?.patient_file_grouping,
                        patient_file_format: response?.response?.data?.errors?.patient_file_format,
                        patient_pay_file_path: response?.response?.data?.errors?.patient_pay_file_path,
                        patient_output_file_format: response?.response?.data?.errors?.patient_output_file_format,

                        eob_lite_file_grouping: response?.response?.data?.errors?.eob_lite_file_grouping,
                        eob_lite_file_format: response?.response?.data?.errors?.eob_lite_file_format,
                        eob_lite_file_path: response?.response?.data?.errors?.eob_lite_file_path,
                        eob_lite_output_file_format: response?.response?.data?.errors?.eob_lite_output_file_format,

                        correspondence_file_grouping: response?.response?.data?.errors?.correspondence_file_grouping,
                        correspondence_file_format: response?.response?.data?.errors?.correspondence_file_format,
                        correspondence_file_path: response?.response?.data?.errors?.correspondence_file_path,
                        correspondence_output_file_format: response?.response?.data?.errors?.correspondence_output_file_format,

                        sftp_server: response?.response?.data?.errors?.sftp_server,
                        sftp_address: response?.response?.data?.errors?.sftp_address,
                        sftp_port: response?.response?.data?.errors?.sftp_port,
                        sftp_username: response?.response?.data?.errors?.sftp_username,
                        sftp_password: response?.response?.data?.errors?.sftp_password,

                        output_zip_file_format: response?.response?.data?.errors?.output_zip_file_format,

                    })

                    setIsSubmit(false)
                    setLoading(false)
                    __toggleToast("danger", response?.response?.data?.message ? response?.response?.data?.message : "Something Went Wrong", true)
                }
            });
        },
    });
    const addFileFormatSourceFile = (type) => {

        outboundformik.setValues({
            ...outboundformik.values,
            source_file_format: outboundformik.values?.source_file_format + type,
        })
    }
    const addReportFileFormat = (type, name) => {

        outboundformik.setValues({
            ...outboundformik.values,
            [name]: outboundformik.values?.[[name]] + type,
        })
    }
    const addFileFormatEob = (type) => {

        outboundformik.setValues({
            ...outboundformik.values,
            eob_file_formats: outboundformik.values?.eob_file_formats + type,
        })
    }

    const addFileFormatPatient = (type) => {
        outboundformik.setValues({
            ...outboundformik.values,
            patient_file_format: outboundformik.values?.patient_file_format + type,
        })
    }
    const addFileFormaEobLite = (type) => {
        outboundformik.setValues({
            ...outboundformik.values,
            eob_lite_file_format: outboundformik.values?.eob_lite_file_format + type,
        })
    }
    const addFileFormatCorress = (type) => {
        outboundformik.setValues({
            ...outboundformik.values,
            correspondence_file_format: outboundformik.values?.correspondence_file_format + type,
        })
    }
    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }
    const handleBatchIdSelected = (e) => {
        if (e.target.value == "4") {
            outboundformik.setValues({
                ...outboundformik.values,
                eob_file_grouping: "4",
                schema_version: "2",

                patient_file_grouping: "4",
                patient_output_file_format: "2",

                eob_lite_file_grouping: "4",
                eob_lite_output_file_format: "2",

                correspondence_file_grouping: "4",
                correspondence_output_file_format: "2",

            })
        } else {
            outboundformik.setValues({
                ...outboundformik.values,
                eob_file_grouping: e.target.value,
                schema_version: "",

                patient_file_grouping: "",
                patient_output_file_format: "",

                eob_lite_file_grouping: "",
                eob_lite_output_file_format: "",

                correspondence_file_grouping: "",
                correspondence_output_file_format: "",

            })
        }
    }

    const handleFileChange = (event) => {

        let selectedFile = event.target.files;
        let file = null;
        let fileName = "";
        if (selectedFile.length > 0) {
            let fileToLoad = selectedFile[0];

            fileName = fileToLoad.name;
            let fileReader = new FileReader();
            fileReader.onload = function (fileLoadedEvent) {
                file = fileLoadedEvent.target.result;
                outboundformik.setValues({
                    ...outboundformik.values,
                    output_schema_xsd: file ?? "",
                })
            };
            fileReader.readAsDataURL(fileToLoad);
        }
    }
    const onButtonClick = (file) => {
        const fileURL = file;
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Sample';
        alink.target = '_blank';
        alink.click();
    }



    return (
        <div>
            <Form
                onSubmit={outboundformik.handleSubmit}
            >
                <div className="mb-3 row border-bottom-dashed" >

                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>File Transfer Period</Form.Label>
                            <Form.Control as="select"
                                name='file_transfer_period'
                                className='b-r-10 form-control-solid '
                                maxLength="255"
                                isValid={outboundformik.touched?.file_transfer_period && !outboundformik?.errors?.file_transfer_period}
                                isInvalid={!!outboundformik.errors?.file_transfer_period}
                                value={outboundformik.values?.file_transfer_period}
                                onChange={outboundformik.handleChange}
                                required
                                placeholder="Select Roles"
                            >
                                <option value='0'>Select an Option</option>
                                <option value="1" >Fixed Time</option>
                                <option value="2">AnyTime</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.file_transfer_period}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                    {outboundformik.values?.file_transfer_period == "1" &&
                        <div className='col-lg-3'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Transfer Time (UTC)</Form.Label>

                                <div>
                                    <TimePicker
                                        className="b-r-10 "
                                        disableClock={true}

                                        onChange={(e) => { handleOnTimeChange({ name: "transfer_time", value: e }) }}
                                        value={outboundformik.values?.transfer_time} />
                                    {console.log(outboundformik.values?.transfer_time)}

                                </div>
                                {outboundformik.errors?.transfer_time &&
                                    <Form.Text className='text-danger fw-bold'>{outboundformik.errors?.transfer_time}</Form.Text>
                                }
                            </Form.Group>
                        </div>
                    }
                </div>
                <div className='mb-3 row border-bottom-dashed'>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Source File Required To Be Transferred</Form.Label>
                            <Form.Control as="select"
                                name='source_file_transfered'
                                className='b-r-10 form-control-solid form-control'
                                maxLength="255"
                                value={outboundformik.values?.source_file_transfered}
                                onChange={outboundformik.handleChange}

                            >
                                <option value="">Select an Option</option>
                                <option value="1" >Yes</option>
                                <option value="2">No</option>


                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.source_file_transfered}</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    {outboundformik.values.source_file_transfered === "1" &&
                        <React.Fragment>
                            <div className='col-lg-2'>
                                <Form.Group className="mb-3" >

                                    <Form.Label className='fw-bold'>File Grouping</Form.Label>
                                    <Form.Control as="select"
                                        name='source_file_grouping'
                                        className='b-r-10 form-control-solid form-control'
                                        isValid={outboundformik.touched?.source_file_grouping && !outboundformik?.errors?.source_file_grouping}
                                        isInvalid={!!outboundformik.errors?.source_file_grouping}
                                        value={outboundformik.values?.source_file_grouping} maxLength="255"
                                        onChange={(e) => { outboundformik.handleChange(e); }}
                                        required={client_data?.eob}
                                    >
                                        <option value="">Select an Option</option>
                                        <option value="1">File</option>
                                        <option value="2">Job</option>


                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">{outboundformik?.errors?.source_file_grouping}</Form.Control.Feedback>

                                </Form.Group>
                            </div>
                            <div className='col-lg-5'>
                                <Form.Group className="" >

                                    <div className='d-flex flex-row align-items-center'>

                                        <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatSourceFile('[Client Name]')}>Client Name</Button>{' '}
                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatSourceFile('[Date]')}>Date</Button>{' '}
                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatSourceFile('[LockBox File Name]')}>LockBox File Name</Button>{' '}
                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatSourceFile('[File Name]')}>File Name</Button>{' '}

                                    </div>


                                    <Form.Control
                                        name='source_file_format'
                                        type="text"
                                        autoComplete="false"
                                        className='b-r-10 form-control-solid'
                                        maxLength="255"
                                        placeholder="File Naming Formats"
                                        isValid={outboundformik.touched?.source_file_format && !outboundformik?.errors?.source_file_format}
                                        isInvalid={!!outboundformik.errors?.source_file_format}
                                        value={outboundformik.values?.source_file_format}
                                        onChange={outboundformik.handleChange}
                                    //required={client_data?.eob}
                                    />

                                    <Form.Control.Feedback type="invalid">{outboundformik?.errors?.source_file_format}</Form.Control.Feedback>

                                </Form.Group>
                            </div>
                            <div className='col-lg-2'>
                                <Form.Group className="mb-3" >

                                    <Form.Label className='fw-bold'>Folder Path</Form.Label>
                                    <Form.Control
                                        name='source_file_path'
                                        className='b-r-10 form-control-solid form-control'
                                        maxLength="255"
                                        isValid={outboundformik.touched?.source_file_path && !outboundformik?.errors?.source_file_path}
                                        isInvalid={!!outboundformik.errors?.source_file_path}
                                        value={outboundformik.values?.source_file_path}
                                        onChange={outboundformik.handleChange}
                                        //required={client_data?.eob}
                                        placeholder="Folder Path"
                                    />
                                    <Form.Control.Feedback type="invalid">{outboundformik?.errors?.source_file_path}</Form.Control.Feedback>

                                </Form.Group>
                            </div>
                        </React.Fragment>}

                </div>
                <div className='mb-3 row border-bottom-dashed'>

                    <div className='mb-3 row'>
                        <div className='col-lg-4'>
                            <Form.Label className='fw-bold'>Output Schema</Form.Label>
                            <Form.Group className="mb-3" >

                                <Form.Control
                                    name='output_schema_xsd'
                                    type="file"
                                    // onChange={lockboxFormik.handleChange}
                                    onChange={(e) => handleFileChange(e)}
                                    // accept={acceptAttribute}
                                    autoComplete="false"
                                    isValid={outboundformik.touched.output_schema_xsd && !!outboundformik?.errors?.output_schema_xsd}
                                    isInvalid={!!outboundformik?.errors?.output_schema_xsd}
                                    // value={outboundformik?.values?.output_schema_xsd}
                                    className='b-r-10 form-control-solid browse-file-padd'
                                />
                                <span className='text-danger'>{outboundformik?.errors?.output_schema_xsd}</span>

                            </Form.Group>
                        </div>
                        {outboundformik.values.output_schema_xsd_url &&
                            <div className='col-lg-2'>
                                <Form.Group className="mb-3" >

                                    <Form.Label className='fw-bold'>Current  File  : </Form.Label>

                                    <div className='overflow-hidden'>
                                        <div>

                                            <button className='btn btn-dark' onClick={() => { onButtonClick(outboundformik.values.output_schema_xsd_url); }} type="button"  ><i className='bi bi-download text-white'></i></button>
                                        </div>
                                        {/* <span className='fw-bold'>{formik?.values?.facilities[index]?.image_file[state.lockboxindex]?.index_file_schema}</span> */}
                                    </div>

                                </Form.Group>
                            </div>
                        }
                    </div>
                </div>

                <div className='mb-3 row border-bottom-dashed'>

                    <div className='mb-3 row'>
                        <div className='col-lg-5'>

                            <Form.Group className="" >

                                <div className='d-flex flex-row align-items-center'>

                                    <Form.Label className='fw-bold'>Output Zip File Naming Formats</Form.Label>
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[LockBox File Name]', 'output_zip_file_format')}>LockBox File Name</Button>{' '}
                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[Client Name]', 'output_zip_file_format')}>Client Name</Button>{' '}
                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addReportFileFormat('[Date]', 'output_zip_file_format')}>Date</Button>{' '}
                                </div>


                                <Form.Control
                                    name='output_zip_file_format'
                                    type="text"
                                    autoComplete="false"
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    placeholder="Output Zip File Naming Formats"
                                    isValid={outboundformik.touched?.output_zip_file_format && !outboundformik?.errors?.output_zip_file_format}
                                    isInvalid={!!outboundformik.errors?.output_zip_file_format}
                                    value={outboundformik.values?.output_zip_file_format}
                                    onChange={outboundformik.handleChange}
                                    required={client_data?.eob}
                                />
                                <Form.Control.Feedback type="invalid">{outboundformik?.errors?.output_zip_file_format}</Form.Control.Feedback>

                            </Form.Group>
                        </div>

                    </div>
                </div>
                {client_data && client_data?.eob &&
                    <div className='mb-3 row border-bottom-dashed'>
                        <Form.Label className='fw-bold clr-heading'>EOB Schema</Form.Label>
                        <div className='col-lg-4'>
                            <div>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" >

                                            <Form.Label className='fw-bold'>File Grouping</Form.Label>
                                            <Form.Control as="select"
                                                name='eob_file_grouping'
                                                className='b-r-10 form-control-solid form-control'
                                                isValid={outboundformik.touched?.eob_file_grouping && !outboundformik?.errors?.eob_file_grouping}
                                                isInvalid={!!outboundformik.errors?.eob_file_grouping}
                                                value={outboundformik.values?.eob_file_grouping} maxLength="255"
                                                onChange={(e) => { outboundformik.handleChange(e); }}
                                                required={client_data?.eob}
                                            >
                                                <option value="null">Select an Option</option>
                                                {/* <option value="1">EOB Batch</option> */}
                                                <option value="1">LockBox</option>
                                                <option value="2">File</option>
                                                {/* <option value="3">Payer</option> */}
                                                {/* <option value="4">Payee</option> */}
                                                <option value="3">Job</option>
                                                <option value="4">Batch ID</option>


                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.eob_file_grouping}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>

                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" >

                                            <Form.Label className='fw-bold'>Schema Version</Form.Label>
                                            <Form.Control as="select"
                                                name='schema_version'
                                                className='b-r-10 form-control-solid form-control'
                                                maxLength="255"
                                                // disabled={outboundformik.values?.eob_file_grouping == "4" ? true : false}
                                                isValid={outboundformik.touched?.schema_version && !outboundformik?.errors?.schema_version}
                                                isInvalid={!!outboundformik.errors?.schema_version}
                                                value={outboundformik.values?.schema_version}
                                                onChange={outboundformik.handleChange}
                                                required={client_data?.eob}
                                            >
                                                <option value='null' >Select an Option</option>
                                                <option value="1">5010</option>
                                                <option value="2" >XML</option>
                                                {/* <option value="2 ">4010</option> */}

                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.schema_version}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Group className="" >

                                <div className='d-flex flex-row align-items-center'>

                                    <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatEob('[Client Name]')}>Client Name</Button>{' '}
                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatEob('[Date]')}>Date</Button>{' '}
                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatEob('[Grouping]')}>Grouping</Button>{' '}
                                    <Button variant="outline-info" className='ms-2 fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatEob('[File type]')}>File Type</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatEob('[LockBox File Name]')}>LockBox File Name</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatEob('[File Name]')}>File Name</Button>{' '}
                                    {outboundformik.values?.eob_file_grouping == "4" &&
                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatEob('[Batch ID]')}>Batch ID</Button>
                                    }
                                </div>


                                <Form.Control
                                    name='eob_file_formats'
                                    type="text"
                                    autoComplete="false"
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    placeholder="File Naming Formats"
                                    isValid={outboundformik.touched?.eob_file_formats && !outboundformik?.errors?.eob_file_formats}
                                    isInvalid={!!outboundformik.errors?.eob_file_formats}
                                    value={outboundformik.values?.eob_file_formats}
                                    onChange={outboundformik.handleChange}
                                    required={client_data?.eob}
                                />
                                <Form.Control.Feedback type="invalid">{outboundformik?.errors?.eob_file_formats}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                        <div className='col-lg-2'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Folder Path</Form.Label>
                                <Form.Control
                                    name='eob_file_path'
                                    className='b-r-10 form-control-solid form-control'
                                    maxLength="255"
                                    isValid={outboundformik.touched?.eob_file_path && !outboundformik?.errors?.eob_file_path}
                                    isInvalid={!!outboundformik.errors?.eob_file_path}
                                    value={outboundformik.values?.eob_file_path}
                                    onChange={outboundformik.handleChange}
                                    required={client_data?.eob}
                                    placeholder="Folder Path"
                                />
                                <Form.Control.Feedback type="invalid">{outboundformik?.errors?.eob_file_path}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                    </div>
                }
                {client_data && client_data?.patient_pay &&
                    <div className='mb-3 row border-bottom-dashed'>
                        <Form.Label className='fw-bold clr-heading'>Patient Pay Schema</Form.Label>

                        <div className='col-lg-4'>
                            <div>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" >

                                            <Form.Label className='fw-bold'>File Grouping</Form.Label>
                                            <Form.Control as="select"
                                                name='patient_file_grouping'
                                                className='b-r-10 form-control-solid form-control'
                                                isValid={outboundformik.touched?.patient_file_grouping && !outboundformik?.errors?.patient_file_grouping}
                                                // disabled={outboundformik.values?.eob_file_grouping == "4" ? true : false}
                                                isInvalid={!!outboundformik.errors?.patient_file_grouping}
                                                value={outboundformik.values?.patient_file_grouping}
                                                maxLength="255"
                                                onChange={outboundformik.handleChange}
                                                required={client_data?.patient_pay}
                                            >
                                                <option value="null">Select an Option</option>
                                                {/* <option value="1">EOB Batch</option> */}
                                                <option value="1">LockBox</option>
                                                <option value="2">File</option>
                                                {/* <option value="3">Payer</option> */}
                                                {/* <option value="4">Payee</option> */}
                                                <option value="3">Job</option>
                                                <option value="4">Batch ID</option>
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.patient_file_grouping}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" >

                                            <Form.Label className='fw-bold'> Output File Format</Form.Label>
                                            <Form.Control as="select"
                                                name='patient_output_file_format'
                                                className='b-r-10 form-control-solid form-control'
                                                maxLength="255"
                                                isValid={outboundformik.touched?.patient_output_file_format && !outboundformik?.errors?.patient_output_file_format}
                                                isInvalid={!!outboundformik.errors?.patient_output_file_format}
                                                value={outboundformik.values?.patient_output_file_format}
                                                // disabled={outboundformik.values?.eob_file_grouping == "4" ? true : false}
                                                onChange={outboundformik.handleChange}
                                                required={client_data?.patient_pay}
                                            >
                                                <option value="null">Select an Option</option>
                                                <option value="1">EDI835</option>
                                                <option value="2">XML</option>
                                                <option value="3">JSON</option>
                                                <option value="4">EPIC</option>
                                                <option value="5">CSV</option>

                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.patient_output_file_format}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Group className="" >

                                <div className='d-flex flex-row align-items-center'>

                                    <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatPatient('[Client Name]')}>Client Name</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatPatient('[Date]')}>Date</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatPatient('[Grouping]')}>Grouping</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatPatient('[File type]')}>File Type</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatPatient('[LockBox File Name]')}>LockBox File Name</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatPatient('[File Name]')}>File Name</Button>{' '}

                                    {outboundformik.values?.patient_file_grouping == "4" &&
                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatPatient('[Batch ID]')}>Batch ID</Button>
                                    }
                                </div>


                                <Form.Control
                                    name='patient_file_format'
                                    type="text"
                                    autoComplete="false"
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    placeholder="File Naming Formats"
                                    isValid={outboundformik.touched?.patient_file_format && !outboundformik?.errors?.patient_file_format}
                                    isInvalid={!!outboundformik.errors?.patient_file_format}
                                    value={outboundformik.values?.patient_file_format}
                                    onChange={outboundformik.handleChange}
                                    required={client_data?.eob}
                                />

                                <Form.Control.Feedback type="invalid">{outboundformik?.errors?.patient_file_format}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                        <div className='col-lg-2'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Folder Path</Form.Label>
                                <Form.Control
                                    name='patient_pay_file_path'
                                    className='b-r-10 form-control-solid form-control'
                                    maxLength="255"
                                    isValid={outboundformik.touched?.patient_pay_file_path && !outboundformik?.errors?.patient_pay_file_path}
                                    isInvalid={!!outboundformik.errors?.patient_pay_file_path}
                                    value={outboundformik.values?.patient_pay_file_path}
                                    onChange={outboundformik.handleChange}
                                    required={client_data?.eob}
                                    placeholder="Folder Path"
                                />
                                <Form.Control.Feedback type="invalid">{outboundformik?.errors?.patient_pay_file_path}</Form.Control.Feedback>

                            </Form.Group>
                        </div>

                    </div>
                }

                {client_data && client_data?.eob_lite &&
                    <div className='mb-3 row border-bottom-dashed'>
                        <Form.Label className='fw-bold clr-heading'>EOB Lite Schema</Form.Label>
                        <div className='col-lg-4'>
                            <div>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" >

                                            <Form.Label className='fw-bold'>File Grouping</Form.Label>
                                            <Form.Control as="select"
                                                name='eob_lite_file_grouping'
                                                className='b-r-10 form-control-solid form-control'
                                                isValid={outboundformik.touched?.eob_lite_file_grouping && !outboundformik?.errors?.eob_lite_file_grouping}
                                                isInvalid={!!outboundformik.errors?.eob_lite_file_grouping}
                                                // disabled={outboundformik.values?.eob_file_grouping == "4" ? true : false}
                                                value={outboundformik.values?.eob_lite_file_grouping} maxLength="255"
                                                onChange={outboundformik.handleChange}
                                                required={client_data?.eob_lite}
                                            >
                                                <option value="null">Select an Option</option>
                                                {/* <option value="1">EOB Batch</option> */}
                                                <option value="1">LockBox</option>
                                                <option value="2">File</option>
                                                {/* <option value="3">Payer</option> */}
                                                {/* <option value="4">Payee</option> */}
                                                <option value="3">Job</option>
                                                <option value="4">Batch ID</option>


                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.eob_lite_file_grouping}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" >

                                            <Form.Label className='fw-bold'> Output File Format</Form.Label>
                                            <Form.Control as="select"
                                                name='eob_lite_output_file_format'
                                                className='b-r-10 form-control-solid form-control'
                                                maxLength="255"
                                                isValid={outboundformik.touched?.eob_lite_output_file_format && !outboundformik?.errors?.eob_lite_output_file_format}
                                                isInvalid={!!outboundformik.errors?.eob_lite_output_file_format}
                                                // disabled={outboundformik.values?.eob_file_grouping == "4" ? true : false}
                                                value={outboundformik.values?.eob_lite_output_file_format}
                                                onChange={outboundformik.handleChange}
                                                required={client_data?.eob_lite}
                                            >
                                                <option value="null">Select an Option</option>
                                                <option value="1">EDI835</option>
                                                <option value="2">XML</option>
                                                <option value="3">JSON</option>

                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.eob_lite_output_file_format}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Group className="" >

                                <div className='d-flex flex-row align-items-center'>

                                    <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormaEobLite('[Client Name]')}>Client Name</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormaEobLite('[Date]')}>Date</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormaEobLite('[Grouping]')}>Grouping</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormaEobLite('[File type]')}>File Type</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormaEobLite('[LockBox File Name]')}>LockBox File Name</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormaEobLite('[File Name]')}>File Name</Button>{' '}

                                    {outboundformik.values?.eob_lite_file_grouping == "4" &&
                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormaEobLite('[Batch ID]')}>Batch ID</Button>
                                    }
                                </div>


                                <Form.Control
                                    name='eob_lite_file_format'
                                    type="text"
                                    autoComplete="false"
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    placeholder="File Naming Formats"
                                    isValid={outboundformik.touched?.eob_lite_file_format && !outboundformik?.errors?.eob_lite_file_format}
                                    isInvalid={!!outboundformik.errors?.eob_lite_file_format}
                                    value={outboundformik.values?.eob_lite_file_format}
                                    onChange={outboundformik.handleChange}
                                    required={client_data?.eob_lite}
                                />
                                <Form.Control.Feedback type="invalid">{outboundformik?.errors?.eob_lite_file_format}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                        <div className='col-lg-2'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Folder Path</Form.Label>
                                <Form.Control
                                    name='eob_lite_file_path'
                                    className='b-r-10 form-control-solid form-control'
                                    maxLength="255"
                                    isValid={outboundformik.touched?.eob_lite_file_path && !outboundformik?.errors?.eob_lite_file_path}
                                    isInvalid={!!outboundformik.errors?.eob_lite_file_path}
                                    value={outboundformik.values?.eob_lite_file_path}
                                    onChange={outboundformik.handleChange}
                                    required={client_data?.eob}
                                    placeholder="Folder Path"
                                />
                                <Form.Control.Feedback type="invalid">{outboundformik?.errors?.eob_lite_file_path}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                    </div>
                }

                {client_data && client_data?.correspondence &&
                    <div className='mb-3 row border-bottom-dashed'>
                        <Form.Label className='fw-bold clr-heading'>Correspondence Schema</Form.Label>
                        <div className='col-lg-4'>
                            <div>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" >

                                            <Form.Label className='fw-bold'>File Grouping</Form.Label>
                                            <Form.Control as="select"
                                                name='correspondence_file_grouping'
                                                className='b-r-10 form-control-solid form-control'
                                                isValid={outboundformik.touched?.correspondence_file_grouping && !outboundformik?.errors?.correspondence_file_grouping}
                                                isInvalid={!!outboundformik.errors?.correspondence_file_grouping}
                                                // disabled={outboundformik.values?.eob_file_grouping == "4" ? true : false}
                                                value={outboundformik.values?.correspondence_file_grouping} maxLength="255"
                                                onChange={outboundformik.handleChange}
                                                required={client_data?.correspondence}
                                            >
                                                <option value="null">Select an Option</option>
                                                {/* <option value="1">EOB Batch</option> */}
                                                <option value="1">LockBox</option>
                                                <option value="2">File</option>
                                                {/* <option value="3">Payer</option> */}
                                                {/* <option value="4">Payee</option> */}
                                                <option value="3">Job</option>
                                                <option value="4">Batch ID</option>


                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.correspondence_file_grouping}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>
                                    <div className='col-lg-6'>
                                        <Form.Group className="mb-3" >

                                            <Form.Label className='fw-bold'>Output File Format</Form.Label>
                                            <Form.Control as="select"
                                                name='correspondence_output_file_format'
                                                className='b-r-10 form-control-solid form-control'
                                                maxLength="255"
                                                isValid={outboundformik.touched?.correspondence_output_file_format && !outboundformik?.errors?.correspondence_output_file_format}
                                                // disabled={outboundformik.values?.eob_file_grouping == "4" ? true : false}
                                                isInvalid={!!outboundformik.errors?.correspondence_output_file_format}
                                                value={outboundformik.values?.correspondence_output_file_format}
                                                onChange={outboundformik.handleChange}
                                                required={client_data?.correspondence}
                                            >
                                                <option value="null">Select an Option</option>
                                                {/* <option value="1">EDI835</option> */}
                                                <option value="2">XML</option>
                                                <option value="3">JSON</option>

                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.correspondence_output_file_format}</Form.Control.Feedback>

                                        </Form.Group>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Group className="" >

                                <div className='d-flex flex-row align-items-center'>

                                    <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatCorress('[Client Name]')}>Client Name</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatCorress('[Date]')}>Date</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatCorress('[Grouping]')}>Grouping</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatCorress('[File type]')}>File Type</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatCorress('[LockBox File Name]')}>LockBox File Name</Button>{' '}
                                    <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatCorress('[File Name]')}>File Name</Button>{' '}

                                    {outboundformik.values?.correspondence_file_grouping == "4" &&
                                        <Button variant="outline-info" className=' fw-bolder' style={{ fontSize: '0.8em', border: 'none' }} size='sm' onDoubleClick={() => addFileFormatCorress('[Batch ID]')}>Batch ID</Button>
                                    }
                                </div>


                                <Form.Control
                                    name='correspondence_file_format'
                                    type="text"
                                    autoComplete="false"
                                    className='b-r-10 form-control-solid'
                                    maxLength="255"
                                    placeholder="File Naming Formats"
                                    isValid={outboundformik.touched?.correspondence_file_format && !outboundformik?.errors?.correspondence_file_format}
                                    isInvalid={!!outboundformik.errors?.correspondence_file_format}
                                    value={outboundformik.values?.correspondence_file_format}
                                    onChange={outboundformik.handleChange}
                                    required={client_data?.eob}
                                />
                                <Form.Control.Feedback type="invalid">{outboundformik?.errors?.correspondence_file_format}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                        <div className='col-lg-2'>
                            <Form.Group className="mb-3" >

                                <Form.Label className='fw-bold'>Folder Path</Form.Label>
                                <Form.Control
                                    name='correspondence_file_path'
                                    className='b-r-10 form-control-solid form-control'
                                    maxLength="255"
                                    isValid={outboundformik.touched?.correspondence_file_path && !outboundformik?.errors?.correspondence_file_path}
                                    isInvalid={!!outboundformik.errors?.correspondence_file_path}
                                    value={outboundformik.values?.correspondence_file_path}
                                    onChange={outboundformik.handleChange}
                                    required={client_data?.eob}
                                    placeholder="Folder Path"
                                />
                                <Form.Control.Feedback type="invalid">{outboundformik?.errors?.correspondence_file_path}</Form.Control.Feedback>

                            </Form.Group>
                        </div>
                    </div>
                }

                <div className='mb-3 row border-bottom-dashed'>
                    <Form.Label className='fw-bold clr-heading'>Output SFTP Credentials</Form.Label>
                    <div className='col-lg-4'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>SFTP Server</Form.Label>
                            <Form.Control as="select"
                                name='sftp_server'
                                className='b-r-10 form-control-solid form-control'
                                maxLength="255"
                                required
                                isValid={outboundformik.touched?.sftp_server && !outboundformik?.errors?.sftp_server}
                                isInvalid={!!outboundformik.errors?.sftp_server}
                                value={outboundformik.values?.sftp_server}
                                onChange={outboundformik.handleChange}
                                placeholder="sftp_server"
                            >
                                <option value=''>Select an Option</option>
                                <option value="1">Client</option>
                                <option value="2">Our Own</option>


                            </Form.Control>
                            <Form.Control.Feedback type="invalid">{outboundformik?.errors?.sftp_server}</Form.Control.Feedback>

                        </Form.Group>
                    </div>

                    <div className='col-lg-8'>
                        <Form.Group className="mb-3" >


                            <div className='row'>
                                <Form.Group className='col-lg-3'>
                                    <Form.Label className='fw-bold'>Address</Form.Label>
                                    <Form.Control
                                        name='sftp_address'
                                        type="address"
                                        autoComplete="false"
                                        className='b-r-10 form-control-solid'
                                        maxLength="255"
                                        required
                                        placeholder="Address"
                                        isValid={outboundformik.touched?.sftp_address && !outboundformik?.errors?.sftp_address}
                                        isInvalid={!!outboundformik.errors?.sftp_address}
                                        value={outboundformik.values?.sftp_address}
                                        onChange={outboundformik.handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">{outboundformik?.errors?.sftp_address}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className='col-lg-3'>
                                    <Form.Label className='fw-bold'>Port</Form.Label>
                                    <Form.Control
                                        name='sftp_port'
                                        type="text"
                                        autoComplete="false"
                                        className='b-r-10 form-control-solid'
                                        maxLength="255"
                                        required
                                        placeholder="Port"
                                        isValid={outboundformik.touched?.sftp_port && !outboundformik?.errors?.sftp_port}
                                        isInvalid={!!outboundformik.errors?.sftp_port}
                                        value={outboundformik.values?.sftp_port}
                                        onChange={outboundformik.handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">{outboundformik?.errors?.sftp_port}</Form.Control.Feedback>

                                </Form.Group>
                                <Form.Group className='col-lg-3'>
                                    <Form.Label className='fw-bold'>Username</Form.Label>
                                    <Form.Control
                                        name='sftp_username'
                                        type="text"
                                        autoComplete="false"
                                        className='b-r-10 form-control-solid'
                                        maxLength="255"
                                        required
                                        placeholder="Username"
                                        isValid={outboundformik.touched?.sftp_username && !outboundformik?.errors?.sftp_username}
                                        isInvalid={!!outboundformik.errors?.sftp_username}
                                        value={outboundformik.values?.sftp_username}
                                        onChange={outboundformik.handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">{outboundformik?.errors?.sftp_username}</Form.Control.Feedback>

                                </Form.Group>

                                <Form.Group className='col-lg-3'>
                                    <Form.Label className='fw-bold'>Password</Form.Label>
                                    <InputGroup className='input-group-client-password' >
                                        <Form.Control
                                            name='sftp_password'
                                            type={togglePassword ? "text" : "password"}
                                            autoComplete="false"
                                            className=' form-control-solid border-radius-password'
                                            maxLength="255"
                                            placeholder="Password"
                                            isValid={outboundformik.touched?.sftp_password && !outboundformik?.errors?.sftp_password}
                                            isInvalid={!!outboundformik.errors?.sftp_password}
                                            value={outboundformik.values?.sftp_password}
                                            onChange={outboundformik.handleChange}
                                        />
                                        <InputGroup.Text className='btn btn-secondary border-radius-eye text-white input-group-text-password-client' onClick={() => setTogglePassword(!togglePassword)} ><i className={togglePassword ? 'bi bi-eye text-whtie' : 'bi bi-eye-slash text-whtie'}></i></InputGroup.Text>
                                        <Form.Control.Feedback type="invalid">{outboundformik?.errors?.sftp_password}</Form.Control.Feedback>
                                    </InputGroup>

                                </Form.Group>

                            </div>

                        </Form.Group>
                    </div>




                </div>
                {/* File Transfer Period Ends 6/}
                {/* <div className='row'>
                    <div className='col-lg-12'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>File Naming Formats</Form.Label>
                            <div className='mb-2'>
                                <Button variant="outline-info" className='ms-5' onDoubleClick={() => addFileFormat('[Client Name]')}>Client Name</Button>{' '}
                                <Button variant="outline-info" className='ms-5' onDoubleClick={() => addFileFormat('[Date]')}>Date</Button>{' '}
                                <Button variant="outline-info" className='ms-5' onDoubleClick={() => addFileFormat('[Grouping]')}>Grouping</Button>{' '}
                                <Button variant="outline-info" className='ms-5' onDoubleClick={() => addFileFormat('[File type]')}>File type</Button>{' '}
                            </div>

                            <Form.Control
                                name='name_format'
                                type="text"
                                autoComplete="false"
                                className='b-r-10 form-control-solid'
                                value={outboundformik.values?.name_format}
                                onChange={outboundformik.handleChange}
                                maxLength="255"
                                required
                                placeholder="File Naming Formats"
                            />
                        </Form.Group>
                    </div>
                </div> */}

                <div>
                    {state.error &&
                        <span className='text-danger'>{state.error_message}</span>}
                </div>
                <div className='d-flex flex-row justify-content-end'>
                    <div className=''>
                        <button
                            type='button'
                            className='btn btn-secondary btn-active-primary me-2'
                            onClick={() => { changeAllTitleFunction('Client Setup', CLIENT) }}
                        >
                            Cancel
                        </button>
                    </div>
                    {client_data ? (
                        checkUserPermission('edit_client') &&
                        <div className=''>
                            <button
                                disabled={!outboundformik.isValid || isSubmit}
                                type='submit'
                                className='btn btn-primary btnHover'
                            >
                                {isSubmit ? "Updating" : "Update"}
                            </button>
                        </div>
                    ) : (
                        <div className=''>
                            <button
                                disabled={!outboundformik.isValid || isSubmit}
                                type='submit'
                                className='btn btn-primary btnHover'
                            >
                                {isSubmit ? "Saving" : "Save"}
                            </button>
                        </div>
                    )}
                    {/* <div className=''>
                        <button
                            type='submit'
                            disabled={!outboundformik.isValid}
                            className='btn btn-light btn-active-primary'
                        >
                            Save
                        </button>
                    </div> */}
                </div>
            </Form>
        </div>
    )
}

export default OutboundForm;
