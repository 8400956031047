
import {
    GET_NOTIFICATION_LIST_URL, GET_NOTIFICATION_MARK_ALL_AS_READ_URL, GET_NOTIFICATION_SINGLE_DETAIL_URL,
} from '../../api';
import axiosInstance from './../../axiosInstance';


export const getNotificationList = () => onResponse => {
    try {
        axiosInstance.get(GET_NOTIFICATION_LIST_URL)
            .then((response) => {
                // console.log(response)
                onResponse(response?.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};

export const markAllAsRead = (notification) => onResponse => {
    try {
        axiosInstance.post(GET_NOTIFICATION_MARK_ALL_AS_READ_URL, { notification })
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};
export const getNotificationSingleDetail = (notification) => onResponse => {
    try {
        axiosInstance.get(GET_NOTIFICATION_SINGLE_DETAIL_URL + "?notification=" + notification)
            .then((response) => {
                // console.log(response)
                onResponse(response.data);
            })
            .catch((err) => {
                console.log(err)
                onResponse(err);
            });

    } catch (error) {

    }

};