import React from 'react'
import AdminMaster from './../../../../Layout/admin/Master/index';
import { useFormik } from 'formik';
import { Form, Toast, ToastContainer } from 'react-bootstrap';
import CheckboxTree from 'react-checkbox-tree';
import * as Yup from 'yup';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPermissions, editRoles, getSingleRoleDetails } from '../../../../Redux/user/action';
import { changeAllTitleFunction, getRedirectTabId } from '../../Dashboard/functions';
import { ROLES } from '../../../../routeNames';



const EditRole = () => {
    let { role_id } = useParams();
    const [data, setData] = useState('');
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [toaster, setToaster] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {
        if (checked?.length > 0) {
            setIsSubmit(true)
        }
        else {
            setIsSubmit(false)

        }
    }, [checked]);
    const [state, setState] = useState({
        showToast: false,
        error: false,
        error_message: "",
    })
    // const SignupSchema = Yup.object().shape({
    //     roleName: Yup.string()
    //         .min(1, ' at least 1 character')
    //         .required('Required'),
    // });
    const __handleEditRole = (values) => {
        const RedirectId = getRedirectTabId()
        editRoles(data.id, values.roleName, checked)((response) => {
            // console.log(values.roleName)
            if (response && response.status) {
                setToaster('success')
                const timer = setTimeout(() => {
                    setToaster(false)
                    changeAllTitleFunction("Roles", ROLES, RedirectId)
                }, 2000);
                return () => clearTimeout(timer);
            }
            else {
                setToaster('error')
                const timer = setTimeout(() => {
                    setToaster(false)
                    message = response.message
                }, 2000);
                return () => clearTimeout(timer);
            }
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            roleName: "",
        },
        validationSchema: Yup.object().shape({
            roleName: Yup.string()
                .min(1, ' at least 1 character')
                .required('Required'),
        }),
        onSubmit: (values) => {
            __handleEditRole(values)
        },
    });


    useEffect(() => {
        getPermissions()((response) => {
            // console.log(response);
            if (response && response.data && response.data.permissions) {
                setNodes(response.data.permissions)
            }

        });
        getSingleRoleDetails(role_id)((response) => {
            // console.log(response);
            if (response?.status && response?.data) {
                setData(response?.data?.role);
                formik.setValues({
                    ...formik.values,
                    role: role_id,
                    roleName: response?.data?.role?.name,

                })
                setChecked(response?.data?.role?.permissions)
            }
        });

    }, []);

    const checkUserPermission = (check) => {
        var permissions = JSON.parse(localStorage.getItem('permissions'))
        var data = permissions.find((element) => {
            return element == check;
        })
        // // console.log(data)
        if (data && data != undefined) {
            return true;
        }
        else {

            return false;
        }
    }

    return (
        <React.Fragment>

            <AdminMaster />
            <main id="main" className="main">
                <ToastContainer className="p-3" position={"top-end"}>
                    <Toast bg="success" show={toaster == 'success' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Success</span></Toast.Body>
                    </Toast>
                    <Toast bg="danger" show={toaster == 'error' ? true : false} animation={true} delay={2000} autohide>

                        <Toast.Body ><span className='text-white'>Failed</span></Toast.Body>
                    </Toast>
                </ToastContainer>

                <div className="pagetitle tab-fixed-header">
                    <h1>Edit Role</h1>

                </div>

                <section className="section mt-5">

                    <div className="row align-items-top py-3 gap-2 gap-md-5">
                        <div className="col-lg-12">
                            <div className="card mb-5 mb-xl-8">

                                <div className="card-body py-3 ">

                                    <Form
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <div className="mb-3 row" >
                                            <div className='col-lg-6'>
                                                <Form.Group className="mb-3" >

                                                    <Form.Label className='fw-bold'>Role Name</Form.Label>
                                                    <Form.Control
                                                        name='roleName'
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        autoComplete="false"
                                                        // isValid={formik.touched.roleName && !!formik.errors.roleName}
                                                        isInvalid={!!formik.errors.roleName}
                                                        value={formik.values.roleName}
                                                        className='b-r-10 form-control-solid'
                                                        maxLength="255"
                                                        required
                                                        placeholder="Enter Rolename"
                                                    />
                                                    <Form.Control.Feedback type="invalid">{formik.errors.roleName}</Form.Control.Feedback>

                                                </Form.Group>
                                            </div>
                                            <div className='col-lg-6'>
                                                <label htmlhtmlFor="inputName5" className="required form-label">Permissions</label>
                                                <CheckboxTree
                                                    nodes={nodes}
                                                    checked={checked}
                                                    expanded={expanded}
                                                    onCheck={checked => setChecked(checked)}
                                                    onExpand={expanded => setExpanded(expanded)}
                                                    icons={{
                                                        check: <i className="bi bi-check2-square" />,
                                                        uncheck: <span className="bi bi-square" />,
                                                        halfCheck: <span className="bi bi-dash-square" />,
                                                        expandClose: <span className="bi bi-chevron-right" />,
                                                        expandOpen: <span className="bi bi-chevron-down" />,
                                                        expandAll: <span className="rct-icon rct-icon-expand-all" />,
                                                        collapseAll: <span className="rct-icon rct-icon-collapse-all" />,
                                                        parentClose: <span className="bi bi-folder" />,
                                                        parentOpen: <span className="bi-folder2-open" />,
                                                        leaf: <span className="bi bi-file-earmark" />,
                                                    }}
                                                />
                                                {!isSubmit &&
                                                    <span className='text-danger '>
                                                        Required
                                                    </span>}

                                            </div>
                                            <div>
                                                {state.error &&
                                                    <span className='text-danger'>{state.error_message}</span>}
                                            </div>
                                        </div>

                                        <div className='d-flex flex-row justify-content-end'>
                                            <div className=''>
                                                <button
                                                    type='button'
                                                    className='btn btn-secondary btn-active-primary me-2'
                                                    onClick={() => { changeAllTitleFunction("Roles", ROLES) }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            {checkUserPermission('edit_role') &&
                                                <div className=''>
                                                    <button
                                                        disabled={!isSubmit} type='submit'
                                                        className='btn btn-primary btnHover'
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </Form>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>
        </React.Fragment >
    )
}

export default EditRole