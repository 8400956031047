import { useFormik, Field } from 'formik';
import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import Select from 'react-select';
import TimePicker from 'react-time-picker';
import { createInBound } from './../../../../../Redux/client/action';

function InboundForm(props) {

    const [state, setState] = useState({
        error: false,
        error_message: "",

    });

    const inboundformik = useFormik({
        initialValues: {
            index_file_included: "",
            index_file_schema: "",
            file_transfer_period: "",
            transfer_time: "",
            sftp_server: "",
            sftp_address: "",
            sftp_port: "",
            sftp_username: "",
            sftp_password: "",
        },
        InboundSchema,
        onSubmit: (values) => {
            __handleInboundSubmit(values)
        },
    });

    const InboundSchema = Yup.object().shape({

        index_file_included: Yup.string()
            .required('Required'),
        index_file: Yup.string()
            .required('Required'),
        index_file_schema: Yup.string()
            .required('Required'),
        file_transfer_period: Yup.string()
            .required('Required'),
        transfer_time: Yup.string()
            .required('Required'),
        sftp_server: Yup.string()
            .required('Required'),
        sftp_address: Yup.string()
            .required('Required'),
        sftp_port: Yup.string()
            .required('Required'),
        sftp_username: Yup.string()
            .required('Required'),
        sftp_password: Yup.string()
            .required('Required'),

    });


    const handleOnSelectChange = ({ name, value }) => {
        // console.log(name, value);
        inboundformik.setValues({ ...inboundformik.values, [name]: value.value })
        // setRoles(value.map((val) => val.value))
    }
    const handleOnTimeChange = ({ name, value }) => {
        // console.log(name, value);
        inboundformik.setValues({ ...inboundformik.values, [name]: value })
        // setRoles(value.map((val) => val.value))
    }


    const __handleInboundSubmit = (values) => {
        let formData = new FormData();    //formdata object
        formData.append('client', props.client_id);
        formData.append('index_file_included', values.index_file_included);
        formData.append('index_file_structure', values.index_file_structure);
        formData.append('index_file_schema', values.index_file_schema);
        formData.append('file_transfer_period', values.file_transfer_period);
        formData.append('transfer_time', values.transfer_time);
        formData.append('sftp_server', values.sftp_server);
        formData.append('sftp_address', values.sftp_address);
        formData.append('sftp_port', values.sftp_port);
        formData.append('sftp_username', values.sftp_username);
        formData.append('sftp_password', values.sftp_password);
        createInBound({ formData })((response) => {
            // console.log(response, 'data');
            if (!response?.data?.status && response?.status && response?.status_code === 201) {
                // setState({ clientValidated: true })
                // props.changeState(response?.data);

            } else {
                const errors = response.response?.data?.errors;
                inboundformik.setErrors({ errors })
                // props.changeState(response);
            }
        });
    }

    //options
    const [imgFormatOptions, SetImgFormatOptions] = useState([
        { value: "1", label: 'TIFF' },
        { value: "2", label: 'PDF' },
    ]);
    const [indexFileStructureOptions, SetIndexFileStructureOptions] = useState([
        { value: "1", label: 'Yes' },
        { value: "0", label: 'No' },

    ]);
    const [indexFileOptions, SetIndexFileOptions] = useState([
        { value: "1", label: 'CSV' },
        { value: "2", label: 'Text' },
        { value: "3", label: 'XML' },
        { value: "4", label: 'JSON' },
        { value: "5", label: 'PDF' },
        { value: "6", label: 'Other' },

    ]);

    const [fileTransferSchemeOptions, SetTransferSchemeOptions] = useState([
        { value: "1", label: 'Fixed Time' },
        { value: "2", label: 'Any Time' },
    ]);
    const [fileTransferPeriodOptions, SetFileTransferPeriodOptions] = useState([
        { value: "1", label: 'Fixed Time' },
        { value: "2", label: 'Any Time' },
    ]);
    const [sftpServerOptions, SetSftpServerOptions] = useState([
        { value: "1", label: 'Client' },
        { value: "2", label: 'Our Own' },
    ]);
    const [fileNamingFormat, SetFileNamingFormatOptions] = useState([
        { value: "1", label: 'Index' },
        { value: "2", label: 'Image' },
    ]);
    const [fileFormatOpitons, SetFileFormatOptions] = useState([
        { value: "1", label: 'EDI837 Primary' },
        { value: "2", label: 'EDI837 Insured' },
        { value: "2", label: 'EDI837 Dental' },
    ]);






    return (
        <React.Fragment>
            <Form
                onSubmit={inboundformik.handleSubmit}
            >
                <div className="mb-3 row" >
                    {/* <div className='col-lg-4'>

                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold' >Image Format</Form.Label>

                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check
                                        inline
                                        label="TIFF"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="PDF"
                                        name="group1"
                                        type={type}
                                        id={`inline-${type}-2`}
                                    />
                                </div>
                            ))}
                            <Form.Control.Feedback type="invalid">{inboundformik?.errors?.errors?.image_format}</Form.Control.Feedback>

                        </Form.Group>
                    </div> */}
                </div>



                <div className="mb-3 row" >

                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>SFTP Server</Form.Label>
                            <Select
                                placeholder="Select an Option"

                                className="basic-single"
                                classNamePrefix="select"
                                name="sftp_server"
                                value={inboundformik.values.sftp_server}
                                isValid={inboundformik.touched.sftp_server && !inboundformik?.errors?.errors?.sftp_server}
                                isInvalid={!!inboundformik.errors?.errors?.sftp_server}
                                required
                                options={sftpServerOptions}
                                onChange={(e) => { handleOnSelectChange({ name: 'sftp_server', value: e }) }}
                            />
                            {/* <Form.Control
                                name='sftp_server'
                                type="text"
                                onChange={inboundformik.handleChange}
                                autoComplete="false"
                                isValid={inboundformik.touched.sftp_server && !inboundformik?.errors.sftp_server}
                                // isInvalid={!!errors.sftp_server}
                                value={inboundformik.values?.sftp_server}
                                className='b-r-10 form-control-solid'
                                maxLength="255"
                                required
                                placeholder="Enter SftP SEr"
                            />
                            <Form.Control.Feedback type="invalid">Enter SftP SEr</Form.Control.Feedback> */}

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Address</Form.Label>
                            <Form.Control
                                name='sftp_address'
                                type="text"
                                onChange={inboundformik.handleChange}
                                autoComplete="false"
                                isValid={inboundformik.touched.sftp_address && !inboundformik?.errors.sftp_address}
                                // isInvalid={!!errors.sftp_address}
                                value={inboundformik.values?.sftp_address}
                                className='b-r-10 form-control-solid'
                                maxLength="255"
                                required
                                placeholder="Enter Address"
                            />
                            <Form.Control.Feedback type="invalid">Enter Address</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Port</Form.Label>
                            <Form.Control
                                name='sftp_port'
                                type="text"
                                onChange={inboundformik.handleChange}
                                autoComplete="false"
                                isValid={inboundformik.touched.sftp_port && !inboundformik?.errors.sftp_port}
                                // isInvalid={!!errors.sftp_port}
                                value={inboundformik.values?.sftp_port}
                                className='b-r-10 form-control-solid'
                                maxLength="255"
                                required
                                placeholder="Enter Port"
                            />
                            <Form.Control.Feedback type="invalid">Enter Port</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Username</Form.Label>
                            <Form.Control
                                name='sftp_username'
                                type="text"
                                onChange={inboundformik.handleChange}
                                autoComplete="false"
                                isValid={inboundformik.touched.sftp_username && !inboundformik?.errors.sftp_username}
                                // isInvalid={!!errors.sftp_username}
                                value={inboundformik.values?.sftp_username}
                                className='b-r-10 form-control-solid'
                                maxLength="255"
                                required
                                placeholder="Enter Username"
                            />
                            <Form.Control.Feedback type="invalid">Enter Username</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3" >

                            <Form.Label className='fw-bold'>Password</Form.Label>
                            <Form.Control
                                name='sftp_password'
                                type="password"
                                onChange={inboundformik.handleChange}
                                autoComplete="false"
                                isValid={inboundformik.touched.sftp_password && !inboundformik?.errors.sftp_password}
                                // isInvalid={!!errors.password}
                                value={inboundformik.values?.sftp_password}
                                className='b-r-10 form-control-solid'
                                maxLength="255"
                                required
                                placeholder="Enter Password"
                            />
                            <Form.Control.Feedback type="invalid">Enter Password</Form.Control.Feedback>

                        </Form.Group>
                    </div>
                </div>

                <div>
                    {state.error &&
                        <span className='text-danger'>{state.error_message}</span>}
                </div>
                <div className='d-flex flex-row justify-content-end'>

                    <div className=''>
                        <button
                            type='submit'
                            className='btn btn-light btn-active-primary'
                        >
                            Save
                        </button>
                    </div>
                </div>
            </Form>
        </React.Fragment >
    )
}

export default InboundForm